import staticAssetUrl from "../../../common/staticAssetUrl"
import Section, {
  SectionContainer,
  SectionContent,
  SectionHeader,
  SectionHeaderEm,
} from "../Section"

interface Props {}

export default function BrandsCommerce({}: Props) {
  return (
    <SectionContainer>
      <Section tw="bg-[#f9f7ff] pb-0">
        <SectionHeader tw="text-[#7a53c3]">
          <div>Sell the world&rsquo;s best brands.</div>
          <SectionHeaderEm>No inventory required.</SectionHeaderEm>
        </SectionHeader>
        <SectionContent>
          <p>
            The <strong tw="font-semibold">Commerce API</strong> lets you
            incorporate Goody&rsquo;s curated collection of thousands of
            products right into your website or app.
          </p>
          <p>
            Create revenue-generating ecommerce experiences without worrying
            about inventory management, logistics, or even payment collection.
          </p>
        </SectionContent>
        <div tw="relative pt-16">
          <img
            src={staticAssetUrl("static/web/landing/api/brands-trending.png")}
            tw="w-[140px] sm:w-[220px] md:w-[220px] lg:w-[283px] mx-auto md:pt-6"
          />
          <div tw="absolute top-6 left-0 w-full h-full">
            <div tw="-mx-10">
              <img
                src={staticAssetUrl(
                  "static/web/landing/api/brands-trending-overlay.png",
                )}
                tw="w-full max-w-[400px] md:max-w-[520px] lg:max-w-[693px] mx-auto"
              />
            </div>
          </div>
        </div>
      </Section>
    </SectionContainer>
  )
}
