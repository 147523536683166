import { gql } from "@apollo/client"

import { TRACK_GIFT_BATCH_GIFT_LINK_FRAGMENT } from "./components/giftLink/GiftLink"

export const STANDARD_GIFT_DISPENSER_FRAGMENT = gql`
  fragment StandardGiftDispenser on GiftDispenser {
    id
    status
    quantityTotal
    quantityAccepted
    quantityPendingNotExpired
    quantityAvailable
    sharedLink
  }
`

const TRACK_GIFT_BATCH_RECIPIENT_REQUEST_ITEM_FRAGMENT = gql`
  fragment TrackGiftBatchRecipientRequestItem on GiftBatchRecipientRequest {
    id
    firstName
    lastName
    email
    phone
    gift {
      id
      sharedLink
      senderLink
      status
      isExpired
      senderViewAccessId
      notifiedAt
      shipments {
        id
        brand {
          id
          name
        }
        status
        trackingCarrier
        trackingNumber
        trackingUrl
        deliveryEta
        deliveredAt
      }
    }
  }
`

export const GIFT_BATCH_RECIPIENTS_QUERY = gql`
  query Track_GiftBatchRecipients(
    $id: ID!
    $page: Int!
    $filter: GiftSeriesFilter!
  ) {
    workspace {
      giftBatch(id: $id) {
        id
        landingPageEnabled
        landingPageSlug
        landingPageSendNotifs
        pendingSends
        sendMethod
        failureMessages {
          title
          message
        }
        recipientSeries(page: $page, filter: $filter) {
          items {
            ...TrackGiftBatchRecipientRequestItem
          }
          totalCount
        }
        giftDispenser {
          ...StandardGiftDispenser
        }
        ...Track_GiftBatchGiftLink
      }
    }
  }
  ${STANDARD_GIFT_DISPENSER_FRAGMENT}
  ${TRACK_GIFT_BATCH_GIFT_LINK_FRAGMENT}
  ${TRACK_GIFT_BATCH_RECIPIENT_REQUEST_ITEM_FRAGMENT}
`

export const GIFT_BATCH_SMART_LINK_COUNTS = gql`
  query GiftBatch_SmartLinkCounts($id: ID!) {
    workspace {
      giftBatch(id: $id) {
        id
        isSmartLink
        smartLinkActive
        smartLinkClaimedCount
        smartLinkAwaitingApprovalCount
        smartLinkQuantity
      }
    }
  }
`

export const GIFT_BATCH_SMART_LINK_DEACTIVATE = gql`
  mutation GiftBatch_SmartLinkDeactivate($id: ID!) {
    giftBatchSmartLinkDeactivate(id: $id) {
      ok
      error
    }
  }
`

export const GIFT_BATCH_SMART_LINK_UPDATE_QUANTITY = gql`
  mutation GiftBatch_SmartLinkUpdateQuantity($id: ID!, $quantity: Int!) {
    giftBatchSmartLinkUpdateQuantity(id: $id, quantity: $quantity) {
      ok
      error
    }
  }
`

export const GIFT_USER_APPROVAL_MUTATION = gql`
  mutation Gift_UserApproval($id: ID!, $approved: Boolean!) {
    giftUserApproval(id: $id, approved: $approved) {
      ok
      error
    }
  }
`
