import { throttle } from "lodash-es"
import { useEffect, useState } from "react"
import tw from "twin.macro"

import { ReactComponent as ScrollToTopIcon } from "@/assets/icons/back-to-top.svg"
import { useGlobalState } from "@/common/GlobalState"

// Tall enough to not overlap with category sidebar
const SCROLL_SHOW_THRESHOLD = 1000

const SCROLL_THROTTLE_MS = 100

// Show a "Back to top" button when the user scrolls down past a certain point
export default function BackToTopButton() {
  function onClick() {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  const [show, setShow] = useState(false)
  const [currentRealm] = useGlobalState("currentRealm")

  useEffect(() => {
    const throttledOnScroll = throttle(() => {
      if (window.scrollY > SCROLL_SHOW_THRESHOLD) {
        if (!show) {
          setShow(true)
        }
      } else {
        if (show) {
          setShow(false)
        }
      }
    }, SCROLL_THROTTLE_MS)

    window.addEventListener("scroll", throttledOnScroll)

    return () => window.removeEventListener("scroll", throttledOnScroll)
  }, [show, setShow])

  return (
    <div
      tw="px-4 fixed top-8 w-[240px] hidden lg:block opacity-100 transition-opacity duration-300"
      css={[
        !show && tw`opacity-0 pointer-events-none`,
        currentRealm !== "plus" && tw`top-48`,
      ]}
    >
      <button
        tw="block py-4 border border-gray-200 w-full bg-white text-gray-500 hover:(border-gray-300 shadow-min text-gray-800) active:(bg-gray-050 scale-95) transition-all rounded-xl flex flex-row items-center justify-center gap-3"
        onClick={onClick}
      >
        <ScrollToTopIcon />
        Back to top
      </button>
    </div>
  )
}
