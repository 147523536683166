import { gql, useQuery } from "@apollo/client"
import React, { useMemo } from "react"
import { useHistory } from "react-router-dom"
import tw, { styled } from "twin.macro"

import { GiftBatchRecipientCards } from "./GiftBatchRecipientCards"
import sendingIcon from "../../../assets/icons/gift-status-created.svg"
import AcceptanceChart, { AcceptanceStats } from "../AcceptanceChart"
import { DeliveryStats } from "../DeliveryChart"
import GiftBatchErrorMessage from "../GiftBatchErrorMessage"
import GiftLink from "../giftLink/GiftLink"

import { generateRealmPath } from "@/common/realm"
import { Loader } from "@/common/UI"
import GiftBatchDeliveryStatus from "@/track/components/giftBatch/GiftBatchDeliveryStatus"
import SettingsSummary from "@/track/components/giftBatch/SettingsSummary"
import { BatchSendMethod, Track_GiftBatchFragment } from "@/types/graphql-types"
import {
  Track_GiftBatchQuery,
  Track_Overview_StatsQuery,
  Track_Overview_StatsQueryVariables,
} from "@/types/graphql-types"

interface Props {
  giftBatchID: string
  giftBatch?: Track_GiftBatchFragment | null
  data: Track_GiftBatchQuery | undefined
  loading: boolean
  loadingVisible: boolean
  refetch: () => void
}
const GiftBatchOverview: React.FC<Props> = ({
  giftBatchID,
  giftBatch,
  data,
  loading,
  loadingVisible,
  refetch,
}) => {
  const batch = data?.workspace?.giftBatch
  const history = useHistory()

  const { data: statsData } = useQuery<
    Track_Overview_StatsQuery,
    Track_Overview_StatsQueryVariables
  >(OVERVIEW_STATS_QUERY, {
    variables: {
      id: giftBatchID,
    },
  })

  const acceptanceStats = useMemo<AcceptanceStats | null>(() => {
    const stats = statsData?.workspace?.giftBatch

    if (stats) {
      return {
        accepted: stats.accepted.totalCount,
        opened: stats.opened.totalCount,
        waiting: stats.notified.totalCount,
      }
    }

    return null
  }, [statsData])

  const deliveryStats = useMemo<DeliveryStats | null>(() => {
    const stats = statsData?.workspace?.giftBatch

    if (stats && stats.accepted.totalCount > 0) {
      return {
        preparing:
          stats.accepted.totalCount -
          stats.shipped.totalCount -
          stats.delivered.totalCount,
        shipped: stats.shipped.totalCount,
        delivered: stats.delivered.totalCount,
      }
    }

    return null
  }, [statsData])

  const directSend =
    data?.workspace?.giftBatch?.sendMethod === BatchSendMethod.direct_send

  return (
    <Container>
      {batch && batch.failureMessages.length > 0 && (
        <div tw="px-5 pb-14 pt-0 pt-2">
          <GiftBatchErrorMessage
            variant="2"
            onAuthorizationGranted={() =>
              history.push(
                generateRealmPath("plus", `/track/${batch.id}/recipients`),
              )
            }
            giftBatch={batch}
          />
        </div>
      )}

      {loadingVisible && (
        <RoundedBox tw="py-24 flex flex-row items-center justify-center">
          <Loader />
        </RoundedBox>
      )}
      {data ? (
        <>
          {!directSend && <GiftLink giftBatch={data?.workspace?.giftBatch} />}
          <div>
            <RoundedBox>
              <div tw="flex flex-wrap lg:flex-row">
                <div tw="w-full lg:w-[70%] lg:border-r">
                  {!directSend && (
                    <div tw="p-5">
                      <div tw="md:text-lg text-gray-500 font-medium pb-6">
                        Gift acceptance
                      </div>
                      {acceptanceStats && (
                        <AcceptanceChart stats={acceptanceStats} />
                      )}
                    </div>
                  )}
                  {deliveryStats && (
                    <GiftBatchDeliveryStatus
                      deliveryStats={deliveryStats}
                      directSend={directSend}
                    />
                  )}
                </div>
                <div tw="w-full lg:w-[30%]">
                  <SettingsSummary
                    directSend={directSend}
                    giftBatch={giftBatch}
                  />
                </div>
              </div>
            </RoundedBox>
            {batch?.pendingSends && batch?.pendingSends > 0 ? (
              <div tw="mt-10 bg-gray-100 text-gray-800 p-6 py-4 rounded-lg flex flex-row items-center">
                <img src={sendingIcon} alt="" />
                <div tw="pl-2">
                  We’re sending out your gifts now ({batch.pendingSends}{" "}
                  recipient{batch.pendingSends !== 1 ? "s" : ""} remaining).{" "}
                  <button
                    tw="focus:outline-none border-b border-gray-300 hover:border-gray-400 text-gray-500 hover:text-gray-700 transition-colors"
                    onClick={() => refetch()}
                  >
                    {loading ? "Refreshing..." : "Refresh"}
                  </button>
                </div>
              </div>
            ) : null}
          </div>
          <div>
            <GiftBatchRecipientCards
              giftBatchID={giftBatchID}
              giftBatch={giftBatch}
            />
          </div>
        </>
      ) : (
        <div tw="py-20 px-20 text-center text-gray-500 text-lg">Loading...</div>
      )}
    </Container>
  )
}

const Container = tw.div`
  w-screen max-w-[1064px] mx-auto px-6 mt-4 lg:mt-12
`

const RoundedBox = styled.div`
  ${tw`bg-white rounded-lg`};
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.04);
  border: 1px solid rgba(236, 238, 241, 1);
`

const OVERVIEW_STATS_QUERY = gql`
  query Track_Overview_Stats($id: ID!) {
    workspace {
      giftBatch(id: $id) {
        notified: giftSeries(page: 1, perPage: 1, filter: notified) {
          totalCount
        }
        opened: giftSeries(page: 1, perPage: 1, filter: opened) {
          totalCount
        }
        accepted: giftSeries(page: 1, perPage: 1, filter: recently_accepted) {
          totalCount
        }
        shipped: giftSeries(page: 1, perPage: 1, filter: shipped) {
          totalCount
        }
        delivered: giftSeries(page: 1, perPage: 1, filter: delivered) {
          totalCount
        }
      }
    }
  }
`

export default GiftBatchOverview
