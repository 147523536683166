import { gql } from "@apollo/client"

export const CUSTOM_CARDS_FETCH = gql`
  query CustomCardsFetch {
    me {
      customCards {
        id
        imageLarge {
          height
          url
          width
        }
        name
        rounded
      }
    }
  }
`
