import React, { useState } from "react"
import { useDropzone } from "react-dropzone"
import tw from "twin.macro"

import { ReactComponent as FailedIcon } from "../assets/icons/failed.svg"
import { ReactComponent as UploadIcon } from "../common/images/upload.svg"

interface FileUploadProps {
  className?: string
  file: File | null
  fileExists: boolean
  onDrop: (file: File) => Promise<boolean>
}

const FileUpload: React.FC<FileUploadProps> = ({
  className,
  file,
  fileExists,
  onDrop,
}) => {
  const [validationError, setValidationError] = useState<string | null>(null)

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: async (acceptedFiles: any[]) => {
      setValidationError(null)
      const result = await onDrop(acceptedFiles[0])
      if (!result) {
        setValidationError("Image must be 800px by 1130px")
      }
    },
    accept: "image/png, image/jpeg",
  })

  const dropArea = (
    <>
      {validationError ? (
        <>
          <FailedIcon tw="stroke-current mb-3 mt-1 opacity-70" />
          <p tw="mb-1">
            <strong>{validationError}</strong>
          </p>
        </>
      ) : (
        <>
          <UploadIcon tw="mb-4 text-gray-500 stroke-current stroke-1.5" />
          <p tw="mb-1">
            <FileName>{file?.name ?? "Drop image or click to upload"}</FileName>
          </p>
        </>
      )}
    </>
  )

  const headerStyle = tw`leading-7`

  return (
    <div
      className={className}
      tw="rounded-lg p-2 flex flex-col flex-1 border border-transparent transition-all duration-75"
      css={{
        alignContent: "stretch",
        ...(isDragActive ? tw`bg-none border-gray-200` : tw`bg-gray-100`),
      }}
    >
      <div tw="opacity-50 mb-2">
        {fileExists ? (
          <h3 tw="text-center" css={headerStyle}>
            Replace image file
          </h3>
        ) : (
          <h3 tw="text-center" css={headerStyle}>
            Upload image file
          </h3>
        )}
      </div>
      <div
        {...getRootProps()}
        tw="p-4 flex-grow rounded-lg flex flex-col items-center transition-all duration-75 text-center"
        css={{
          justifyContent: "center",
          ...(isDragActive
            ? tw`bg-primary-000 border-2 border-dashed border-primary-300`
            : tw`bg-white`),
          ...(validationError ? tw`text-red-600` : {}),
        }}
      >
        <input {...getInputProps()} />
        {dropArea}
      </div>
    </div>
  )
}

const FileName = tw.strong`overflow-ellipsis overflow-hidden inline-block w-72`

export default FileUpload
