import { useEffect, useState } from "react"

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })

  const updateWindowSize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    })
  }

  useEffect(() => {
    window.addEventListener("resize", updateWindowSize)

    return () => {
      window.removeEventListener("resize", updateWindowSize)
    }
  }, [windowSize])

  const lessThen = (breakpoint: ScreenBreakpoints) => {
    return windowSize.width <= breakpointsInPx[breakpoint]
  }

  return { ...windowSize, lessThen: lessThen }
}

type ScreenBreakpoints = "sm" | "md" | "lg" | "xl"
const breakpointsInPx = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
}
