import React, { useState } from "react"
import Tilt from "react-parallax-tilt"
import tw, { styled } from "twin.macro"

import { CardContainer } from "./CardContainer"
import CustomCardForm from "./CustomCardForm"
import { ReactComponent as CustomCardIcon } from "../assets/icons/custom-card.svg"
import { ReactComponent as PencilIcon } from "../assets/icons/pencil.svg"
import ClickableCloserUnderlay from "../common/ClickableCloserUnderlay"
import SmallButton from "../common/SmallButton"

interface CustomCardProps {
  cardName?: string | null
  cardId: string
  imageURL: string
  useCard: () => void
}

const CustomCard: React.FC<CustomCardProps> = ({
  cardId,
  cardName,
  imageURL,
  useCard,
}) => {
  const [displayForm, setDisplayForm] = useState(false)
  const closeForm = () => setDisplayForm(false)

  return (
    <CardContainer>
      <Name>{cardName}</Name>
      <ImageContainer onClick={useCard}>
        <ImageTiltContainer>
          <Tilt
            css={[
              {
                height: "100%",
                width: "100%",
              },
            ]}
            scale={1.1}
            transitionSpeed={5000}
            tiltMaxAngleX={7.5}
            tiltMaxAngleY={15}
          >
            <Image src={imageURL} />
          </Tilt>
        </ImageTiltContainer>
      </ImageContainer>
      <Actions>
        <SmallButton
          label={
            <ButtonLabel>
              <CustomCardIcon tw="inline mr-4 stroke-current text-primary-400" />
              <p>Use Card</p>
            </ButtonLabel>
          }
          hideArrow={true}
          onClick={useCard}
        />
        <GraySmallButton
          label={
            <ButtonLabel>
              <PencilIcon tw="inline mr-4 stroke-current text-gray-400" />
              <p>Edit</p>
            </ButtonLabel>
          }
          light={true}
          hideArrow={true}
          onClick={() => setDisplayForm(true)}
        />
      </Actions>
      <CustomCardForm
        card={{ name: cardName, id: cardId, imageURL }}
        closeForm={closeForm}
        displayForm={displayForm}
      />
      {displayForm && <ClickableCloserUnderlay onClick={closeForm} />}
    </CardContainer>
  )
}

const Actions = tw.div`flex`

const ButtonLabel = styled.div`
  ${tw`flex items-center`};
`

// ImageContainer reserves space for the image, setting the width to 70% of the
// container width, and the height (using padding-bottom, where calc() with a
// percentage uses the width as the reference point) to 1.414 * width, which is
// the A4 ratio that is used with cards.
//
// The Image is absolutely positioned within this, so that the height can "cover"
// the height reserved by the padding-bottom. (If Image was not absolutely
// positioned, then the padding bottom would be stacked *below* the height of the
// Image, so we need to absolutely position Image so it's on top of the padding
// reserved by ImageContainer.)
const ImageContainer = styled.button`
  ${tw`block mb-8 mt-12 relative`};
  width: 70%;
  padding-bottom: calc(70% * 1.414);
`

const ImageTiltContainer = tw.div`absolute top-0 left-0 w-full`

const Image = styled.img`
  ${tw`bg-gray-100 rounded-2xl shadow-lg hover:shadow-xl transition-all`};
  width: 100%;
`

const GraySmallButton = tw(SmallButton)`text-gray-500 hover:bg-gray-200 ml-4`

const Name = tw.p`font-medium text-2xl`

export default CustomCard
