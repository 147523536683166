import styled from "@emotion/styled"
import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react"
import { createPortal } from "react-dom"
import tw from "twin.macro"

import CountryList from "./CountryList"
import { InternationalProPlanMessage } from "./InternationalProPlanMessage"
import { Countries, Flag } from "./InternationalShipping"

interface Props {
  popoverOpen: boolean
  setPopoverOpen: Dispatch<SetStateAction<boolean>>
  selectedShippingCountry: Countries
  setSelectedShippingCountry: Dispatch<SetStateAction<Countries>>
  triggerButtonY?: number
  triggerButtonDistanceFromRight?: number
}

const modalRoot = document.getElementById("modal-root")

const InternationalShippingFilterPopover: FC<Props> = ({
  popoverOpen,
  setPopoverOpen,
  selectedShippingCountry,
  setSelectedShippingCountry,
  triggerButtonY,
  triggerButtonDistanceFromRight,
}) => {
  const [display, setDisplay] = useState(false)
  const contentRef = useRef() as React.MutableRefObject<HTMLDivElement>

  useEffect(() => {
    if (!popoverOpen) {
      contentRef.current?.classList.add("scale-fade-out")
      setTimeout(() => setDisplay(popoverOpen), 200)
    } else {
      setDisplay(popoverOpen)
    }
  }, [popoverOpen])

  const onClick = (country: Countries) => {
    setPopoverOpen(false)
    setSelectedShippingCountry(country)
  }

  if (modalRoot === null) {
    return null
  }

  return createPortal(
    <>
      <ClosePopover
        isOpen={popoverOpen}
        onClick={() => setPopoverOpen(false)}
      />
      <Modal
        $display={display}
        ref={contentRef}
        top={triggerButtonY}
        $rightMargin={triggerButtonDistanceFromRight}
      >
        <InternationalProPlanMessage
          visible={display}
          onNavigate={() => setPopoverOpen(false)}
        />
        <div tw="flex flex-col lg:flex-row border-b border-gray-200 px-7 py-7">
          <div tw="flex flex-col lg:w-2/5 mb-4 lg:mb-0 pr-3">
            <Title>Destinations</Title>
            <h4 tw="text-gray-700">
              Easily browse gifts that ship to the US or our standard global
              destinations.
            </h4>
          </div>
          <div tw="flex lg:w-3/5 lg:pl-4">
            <DestinationsSection
              tw="mr-2"
              onClick={() => onClick(Countries.UNITED_STATES)}
              active={selectedShippingCountry === Countries.UNITED_STATES}
            >
              <Flag country={Countries.UNITED_STATES} size="m" />
              <h2>United States</h2>
              <p>Domestic shipping inside the US</p>
            </DestinationsSection>
            <DestinationsSection
              tw="ml-2"
              onClick={() => onClick(Countries.GLOBAL)}
              active={selectedShippingCountry === Countries.GLOBAL}
            >
              <GlobalFlag
                country={Countries.GLOBAL}
                active={selectedShippingCountry === Countries.GLOBAL}
              />
              <h2>Global</h2>
              <p>Ships to all of our 140+ supported countries</p>
            </DestinationsSection>
          </div>
        </div>
        <div tw="flex flex-col lg:flex-row h-80">
          <div tw="p-7 pb-0 lg:pr-3 lg:w-2/5 lg:mb-4 lg:mb-0">
            <Title>Find a country</Title>
            <h4 tw="text-gray-700">
              Select a country to see gifts for that country.
            </h4>
          </div>
          <CountryList
            selectedShippingCountry={selectedShippingCountry}
            onClick={onClick}
          />
        </div>
      </Modal>
    </>,
    modalRoot,
  )
}

const Modal = styled.div<{
  $display: boolean
  top?: number
  $rightMargin?: number
}>`
  ${tw`absolute hidden bg-white z-[2000] border border-gray-200 rounded-lg w-full origin-top-right`}
  max-width: 500px;
  top: 21.5rem;

  // When right margin is defined, set it.
  // Otherwise, center the modal.
  ${({ $rightMargin }) =>
    $rightMargin
      ? `right: ${$rightMargin}px;`
      : `
    margin: 0 auto;
    left: 0;
    right: 0;
  `}

  box-shadow: 0px 8px 8px rgba(20, 0, 0, 0.14);
  @media (min-width: 1024px) {
    top: ${({ top }) => (top ? top + 62 : 0)}px;
    width: 38.5rem;
    max-width: unset;

    // Set left when right margin is not defined.
    ${({ $rightMargin }) => !$rightMargin && `left: -12rem;`}
  }
  animation: scaleFadeIn 200ms;
  @keyframes scaleFadeIn {
    0% {
      transform: scale(0.5);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 100;
    }
  }
  &.scale-fade-out {
    animation: scaleFadeOut 400ms;
    @keyframes scaleFadeOut {
      0% {
        transform: scale(1);
        opacity: 100;
      }
      50% {
        transform: scale(0.5);
        opacity: 0;
      }
      100% {
        transform: scale(0.5);
        opacity: 0;
      }
    }
  }
  ${({ $display }) => $display && tw`block`}
`

const GlobalFlag = styled(Flag)<{ active: boolean }>`
  ${({ active }) =>
    active &&
    `filter: invert(42%) sepia(73%) saturate(1655%) hue-rotate(200deg) brightness(96%) contrast(102%);`}
`

const DestinationsSection = styled.button<{ active: boolean }>`
  ${tw`flex flex-col flex-1 border border-gray-300 rounded-lg items-center text-center text-gray-700 transition-colors`}
  padding: 24px 24px;
  img {
    ${tw`flex-1`}
  }
  h2 {
    ${tw`font-medium flex-1 py-3`}
  }
  p {
    ${tw`text-sm`}
    flex: 2;
    @media (max-width: 1023px) {
      max-width: 9rem;
    }
  }
  &:hover {
    ${tw`border-gray-350`}
  }
  &:active {
    ${tw`bg-gray-100`}
  }
  ${({ active }) =>
    active && tw`!border-primary-500 !text-primary-500 !bg-primary-050`}
  ${({ active }) =>
    active &&
    `border-width: 1.5px; box-shadow: 0px 2px 8px rgba(122, 104, 246, 0.1);`}
`

const Title = styled.h2`
  ${tw`text-lg font-medium mb-2`}
`

const ClosePopover = styled.div<{ isOpen: boolean }>`
  ${tw`fixed top-0 bottom-0 right-0 left-0 z-10 opacity-40 bg-white`}
  height: 100vh;
  width: 100vw;
  display: ${({ isOpen }) => (isOpen ? `block` : `none`)};
`

export default InternationalShippingFilterPopover
