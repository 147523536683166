import { ReactNode, useState } from "react"
import useCollapse from "react-collapsed"
import tw from "twin.macro"

import { ReactComponent as ChevronDown } from "../../../common/images/chevron-down.svg"

interface Props {
  name: string
  icon: any
  preview: ReactNode | string
  children?: ReactNode
  isTextInput?: boolean
  fullWidth?: boolean
}

export default function OptionBase({
  name,
  icon,
  preview,
  children,
  isTextInput,
  fullWidth = false,
}: Props) {
  const [isExpanded, setIsExpanded] = useState(false)
  const { getCollapseProps } = useCollapse({ isExpanded })

  const isButton = !isTextInput

  const mainContent = (
    <div tw="flex flex-col md:flex-row items-start px-5 py-4 gap-2 md:gap-4 w-full">
      <div tw="flex flex-row items-center gap-4 w-[14rem]">
        <div tw="text-gray-400" css={[isExpanded && tw`text-primary-500`]}>
          {icon}
        </div>
        <div
          tw="text-gray-500 font-medium"
          css={[isExpanded && tw`text-primary-600`]}
        >
          {name}
        </div>
      </div>
      <div tw="flex flex-row items-center justify-between gap-4 flex-1 w-full">
        <div tw="flex-1" css={[isExpanded && tw`text-primary-600 font-medium`]}>
          {preview}
        </div>
        {isButton && (
          <div
            tw="text-gray-400 transition-transform"
            css={[isExpanded && tw`transform rotate-180 text-primary-500`]}
          >
            <ChevronDown tw="stroke-current" />
          </div>
        )}
      </div>
    </div>
  )

  const mainContentWrapper = isButton ? (
    <button
      tw="block w-full text-left hover:bg-gray-050 active:bg-gray-100 transition-colors"
      onClick={() => setIsExpanded(!isExpanded)}
    >
      {mainContent}
    </button>
  ) : (
    mainContent
  )

  return (
    <div tw="border-b border-gray-200">
      {mainContentWrapper}
      {children && (
        <div {...getCollapseProps()}>
          <div
            css={[tw`p-5 pt-2`, fullWidth ? undefined : tw`md:pl-[16.25rem]`]}
          >
            {children}
          </div>
        </div>
      )}
    </div>
  )
}
