import { gql, useQuery } from "@apollo/client"
import { differenceBy, isNil } from "lodash-es"
import { useEffect, useMemo, useState } from "react"

import { useFeatureAccess } from "./featureAccess"
import { useGiftData } from "./giftData"
import { usePaymentMethods } from "./paymentMethods"
import { usePrevious } from "./previous"
import { useGlobalState } from "../GlobalState"
import { nameForCardBrand } from "../payment"
import { PaymentMethod } from "../PaymentMethodsManager"

import { PaymentSelector_GetBalanceAndStoredValueQuery } from "@/types/graphql-types"

export const BALANCE_PAYMENT_METHOD_NAME = "Balance"
export const BALANCE_PAYMENT_METHOD_ID = "BALANCE"
export const BALANCE_PAYMENT_METHOD_PAYLOAD: PaymentMethod = {
  __typename: "PaymentMethod",
  id: BALANCE_PAYMENT_METHOD_ID,
  cardBrand: "Balance",
  last4: "0000",
}

export const CORPORATE_ACCOUNT_PAYMENT_METHOD_ID = "CORPORATE_ACCOUNT"
export const CORPORATE_ACCOUNT_PAYMENT_METHOD_NAME = "Corporate Account"
export const CORPORATE_ACCOUNT_PAYMENT_METHOD_PAYLOAD: PaymentMethod = {
  __typename: "PaymentMethod",
  id: CORPORATE_ACCOUNT_PAYMENT_METHOD_ID,
  cardBrand: "Corporate Account",
  last4: "0000",
}

interface Props {
  selectedPaymentMethodID: string | null
  setSelectedPaymentMethod: (paymentMethod: PaymentMethod | null) => void
}

// Hook for setting the payment method on the global state
export const usePayment = ({
  selectedPaymentMethodID,
  setSelectedPaymentMethod,
}: Props) => {
  const { hasFeature } = useFeatureAccess()
  const [realm] = useGlobalState("currentRealm")

  const { paymentMethods, refreshPaymentMethods, paymentMethodsLoading } =
    usePaymentMethods()

  const {
    data: balanceAndStoredValueData,
    loading: balanceStoredValueLoading,
  } = useQuery<PaymentSelector_GetBalanceAndStoredValueQuery>(
    GET_BALANCE_AND_STORED_VALUE_QUERY,
  )

  const hasStoredValue = hasFeature("payment_stored_value")

  const balanceAmount = balanceAndStoredValueData?.me?.balance ?? null
  const storedValueAmount =
    balanceAndStoredValueData?.workspace?.organization?.storedValue ?? null

  // Only autoselect balance/stored value if we have some
  const canAutoselectPaymentMethod = (paymentMethod: PaymentMethod) => {
    switch (paymentMethod.id) {
      case BALANCE_PAYMENT_METHOD_ID:
        return !isNil(balanceAmount) && balanceAmount > 0
      case CORPORATE_ACCOUNT_PAYMENT_METHOD_ID:
        return (
          hasStoredValue && !isNil(storedValueAmount) && storedValueAmount > 0
        )
      default:
        return true
    }
  }

  // Finds selectable payment methods by realm
  const selectablePaymentMethods = useMemo(() => {
    if (paymentMethodsLoading) {
      return null
    } else if (realm === "consumer") {
      return paymentMethods
    } else if (!balanceStoredValueLoading && hasStoredValue) {
      return [
        BALANCE_PAYMENT_METHOD_PAYLOAD,
        CORPORATE_ACCOUNT_PAYMENT_METHOD_PAYLOAD,
        ...paymentMethods,
      ]
    } else if (!balanceStoredValueLoading) {
      return [BALANCE_PAYMENT_METHOD_PAYLOAD, ...paymentMethods]
    } else {
      return null
    }
  }, [paymentMethods, hasStoredValue, balanceStoredValueLoading, realm])

  const lastSelectablePaymentMethods = usePrevious(selectablePaymentMethods)

  // If we add a new payment method, auto select it
  useEffect(() => {
    if (
      lastSelectablePaymentMethods &&
      selectablePaymentMethods &&
      lastSelectablePaymentMethods?.length < selectablePaymentMethods?.length
    ) {
      const newPaymentMethod = differenceBy(
        paymentMethods,
        lastSelectablePaymentMethods,
        "id",
      )[0]
      if (newPaymentMethod) {
        setSelectedPaymentMethod(newPaymentMethod)
      }
    }
  }, [
    lastSelectablePaymentMethods?.length !== selectablePaymentMethods?.length,
    setSelectedPaymentMethod,
  ])

  const selectedPaymentMethod = useMemo(() => {
    return (
      selectablePaymentMethods?.find(
        (paymentMethod) => paymentMethod.id === selectedPaymentMethodID,
      ) ?? null
    )
  }, [selectablePaymentMethods, selectedPaymentMethodID])

  // Initializes state to use first selectable payment method
  useEffect(() => {
    // If we have valid payment methods and we don't have a selected payment method, autoselect
    if (!selectedPaymentMethod && selectablePaymentMethods) {
      const firstAutoselectPaymentMethod =
        selectablePaymentMethods.find(canAutoselectPaymentMethod) ?? null

      setSelectedPaymentMethod(firstAutoselectPaymentMethod)
    }
  }, [selectablePaymentMethods, selectedPaymentMethodID])

  return {
    isLoading: balanceStoredValueLoading || paymentMethodsLoading,
    paymentMethods,
    refreshPaymentMethods,
    selectedPaymentMethod,
    selectablePaymentMethods,
    selectedPaymentMethodID,
    balanceAmount,
    budgetRule: balanceAndStoredValueData?.me?.budgetRule,
    storedValueAmount,
    setSelectedPaymentMethod,
  }
}

export const useCurrentGiftPayment = () => {
  const { resetPaymentMethod, setPaymentMethod, currentGift } = useGiftData()
  return usePayment({
    setSelectedPaymentMethod: (paymentMethod) => {
      if (paymentMethod) {
        setPaymentMethod(paymentMethod, getPaymentMethodName(paymentMethod))
      } else {
        resetPaymentMethod()
      }
    },
    selectedPaymentMethodID: currentGift.autopayPaymentMethodID,
  })
}

export const useCurrentAutogiftRulePayment = () => {
  const [currentAutogiftRule, setCurrentAutogiftRule] = useGlobalState(
    "currentAutogiftRule",
  )
  return usePayment({
    setSelectedPaymentMethod: (paymentMethod) => {
      setCurrentAutogiftRule({
        ...currentAutogiftRule,
        autopayPaymentMethodID: paymentMethod?.id ?? null,
        autopayPaymentMethodName: paymentMethod
          ? getPaymentMethodName(paymentMethod)
          : null,
      })
    },
    selectedPaymentMethodID: currentAutogiftRule.autopayPaymentMethodID ?? null,
  })
}

export const useLocalStatePayment = (
  defaultPaymentMethod: PaymentMethod | null = null,
) => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState(defaultPaymentMethod)

  useEffect(() => {
    setSelectedPaymentMethod(defaultPaymentMethod)
  }, [defaultPaymentMethod])

  return usePayment({
    setSelectedPaymentMethod,
    selectedPaymentMethodID: selectedPaymentMethod?.id ?? null,
  })
}

export const getPaymentMethodName = (paymentMethod: PaymentMethod) => {
  switch (paymentMethod.id) {
    case BALANCE_PAYMENT_METHOD_ID:
      return BALANCE_PAYMENT_METHOD_NAME
    case CORPORATE_ACCOUNT_PAYMENT_METHOD_ID:
      return CORPORATE_ACCOUNT_PAYMENT_METHOD_NAME
    default:
      return `${nameForCardBrand(paymentMethod.cardBrand)} ${
        paymentMethod.last4
      }`
  }
}

const GET_BALANCE_AND_STORED_VALUE_QUERY = gql`
  query PaymentSelector_GetBalanceAndStoredValue {
    me {
      id
      balance
      budgetRule {
        id
        amount
        recurrence
      }
    }
    workspace {
      organization {
        id
        storedValue
      }
    }
  }
`
