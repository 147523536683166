import { useEffect } from "react"
import Modal from "react-modal"

import LimitedMemberFeatureGateBanner from "./LimitedMemberFeatureGateBanner"
import { ReactComponent as XIcon } from "../../assets/icons/x-close.svg"
import { disableScrolling, enableScrolling } from "../../common/scrolling"
import { useGlobalState } from "../GlobalState"
import { level2ModalStyle } from "../modal"

const LimitedMemberFeatureGateModal = () => {
  const [isOpen, setIsOpen] = useGlobalState(
    "isLimitedMemberFeatureGateModalOpen",
  )

  useEffect(() => {
    if (isOpen) {
      disableScrolling()
    } else {
      enableScrolling()
    }
  }, [isOpen])

  return (
    <Modal
      isOpen={isOpen}
      style={level2ModalStyle}
      closeTimeoutMS={500}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      onRequestClose={() => setIsOpen(false)}
    >
      <div tw="rounded-2xl bg-white mx-auto relative w-[43rem] max-w-[100vw] sm:max-w-[90vw]">
        <button
          tw="absolute top-5 sm:top-10 right-5 sm:right-10 text-gray-500"
          onClick={() => setIsOpen(false)}
        >
          <XIcon />
        </button>
        <LimitedMemberFeatureGateBanner />
      </div>
    </Modal>
  )
}

export default LimitedMemberFeatureGateModal
