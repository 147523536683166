import { gql, useMutation, useQuery } from "@apollo/client"
import { useCallback, useEffect, useState } from "react"

import { AddPaymentMethodFormFields } from "../../common/billing/useAddPaymentMethod"

import {
  Subscribe_UserSubscriptionPriceEstimateMutation,
  Subscribe_UserSubscriptionPriceEstimateMutationVariables,
  Subscriptions_PromotionCodeQuery,
  Subscriptions_PromotionCodeQueryVariables,
} from "@/types/graphql-types"

interface UseSubscriptionPriceEstimate {
  paymentMethodID: string | null
  price: number
  formFields: AddPaymentMethodFormFields
}

export const useSubscriptionPriceEstimate = (
  variables: UseSubscriptionPriceEstimate,
) => {
  const [priceEstimateMutation, { data, loading }] = useMutation<
    Subscribe_UserSubscriptionPriceEstimateMutation,
    Subscribe_UserSubscriptionPriceEstimateMutationVariables
  >(USER_SUBSCRIPTION_PRICE_ESTIMATE_MUTATION)

  const { paymentMethodID, price, formFields } = variables

  const runEffect = useCallback(() => {
    priceEstimateMutation({
      variables: {
        price: price,
        paymentMethodID,
        addressLine1: formFields.address1,
        addressLine2: formFields.address2,
        addressCity: formFields.city,
        addressState: formFields.state,
        addressPostalCode: formFields.postalCode,
      },
    })
  }, [price, paymentMethodID, formFields, priceEstimateMutation])

  return { runEffect, data, loading }
}

export const usePromotionCode = (code: string) => {
  const { data, error, loading } = useQuery<
    Subscriptions_PromotionCodeQuery,
    Subscriptions_PromotionCodeQueryVariables
  >(PROMOTION_CODE_QUERY, {
    skip: code === "",
    variables: {
      code: code,
    },
  })

  const [codeError, setCodeError] = useState("")
  useEffect(() => {
    setCodeError(code)
    if (error && !loading && code === codeError) {
      alert("Invalid promotion code.")
    }
  }, [code, codeError, setCodeError, loading, error])

  const promotionCode = data?.promotionCode

  return { promotionCode, loading }
}

const USER_SUBSCRIPTION_PRICE_ESTIMATE_MUTATION = gql`
  mutation Subscribe_UserSubscriptionPriceEstimate(
    $price: Int!
    $paymentMethodID: ID
    $addressLine1: String
    $addressLine2: String
    $addressCity: String
    $addressState: String
    $addressPostalCode: String
  ) {
    subscriptionPriceEstimate(
      price: $price
      paymentMethodId: $paymentMethodID
      addressLine1: $addressLine1
      addressLine2: $addressLine2
      addressCity: $addressCity
      addressState: $addressState
      addressPostalCode: $addressPostalCode
    ) {
      estimatedTax
      estimatedTotal
    }
  }
`

const PROMOTION_CODE_QUERY = gql`
  query Subscriptions_PromotionCode($code: String!) {
    promotionCode(code: $code) {
      code
      description
      discountAmount
      discountPercentage
    }
  }
`
