import { useState } from "react"
import tw from "twin.macro"

import DirectSendVariantsRequiredProduct from "./DirectSendVariantsRequiredProduct"
import Button from "../../common/Button"
import { useGiftCart } from "../../common/hooks/giftData"
import { successToast } from "../../common/toast"
import { validateVariants } from "../../common/validateVariants"

import { Details_ProductFragment } from "@/types/graphql-types"

interface Props {
  switchToEmailOrLink: () => void
  variantRequiredProducts: Details_ProductFragment[] | null | undefined
  sendV3?: boolean
}

export default function DirectSendVariantsRequired({
  switchToEmailOrLink,
  variantRequiredProducts,
  sendV3,
}: Props) {
  const { setVariantsForNullVariantsProduct } = useGiftCart()

  const [selectedVariantsMap, setSelectedVariantsMap] = useState<{
    [id: string]: string[] | null
  }>({})

  function saveOptions() {
    // Loop through selectedVariantsMap and extract product ID and selected variants
    for (const [productID, selectedVariants] of Object.entries(
      selectedVariantsMap,
    )) {
      // Find product in variantRequiredProducts
      const product = variantRequiredProducts?.find(
        (product) => product.id === productID,
      )

      // If product is found, validate selected variants
      if (product) {
        const res = validateVariants(selectedVariants, product, false)
        if (!res) {
          return
        }
      }
    }

    // If all validations pass, save selected variants to gift data
    for (const [productID, selectedVariants] of Object.entries(
      selectedVariantsMap,
    )) {
      setVariantsForNullVariantsProduct(productID, selectedVariants)
    }

    successToast("Options saved")
  }

  return (
    <div
      tw="flex flex-col md:flex-row items-stretch gap-6"
      css={[sendV3 && tw`md:flex-col`]}
    >
      <div tw="p-7 bg-white shadow-min flex-1 rounded-lg">
        <div tw="text-center text-xl font-medium">Select options</div>
        <div tw="pt-6">
          {variantRequiredProducts?.map((product, i) => (
            <DirectSendVariantsRequiredProduct
              key={product.id}
              product={product}
              selectedVariants={selectedVariantsMap[product.id] || null}
              setSelectedVariants={(variants) => {
                setSelectedVariantsMap({
                  ...selectedVariantsMap,
                  [product.id]: variants,
                })
              }}
            />
          ))}
        </div>
        <Button variant="updated" onClick={saveOptions} tw="mx-auto mt-4">
          Save
        </Button>
      </div>
      <div tw="p-7 bg-white shadow-min flex-1 rounded-lg">
        <div tw="text-center text-xl font-medium">
          Or, switch to send via email or gift link
        </div>
        <div tw="max-w-[400px] pt-5 text-gray-500 mx-auto text-center">
          You can let your recipient choose their gift when you send it via
          email or gift link. The recipient can select options for the gift, or
          swap it for something else.
        </div>
        <div tw="pt-8">
          <Button variant="updated" onClick={switchToEmailOrLink} tw="mx-auto">
            Send via email or gift link
          </Button>
        </div>
      </div>
    </div>
  )
}
