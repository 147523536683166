import React from "react"

import { useDismissedScreens } from "../hooks/useDismissedScreens"

export const DismissedScreensContext = React.createContext<null | ReturnType<
  typeof useDismissedScreens
>>(null)

export const DismissedScreensProvider = (
  props: Omit<
    React.ComponentProps<typeof DismissedScreensContext.Provider>,
    "value"
  >,
) => {
  return (
    <DismissedScreensContext.Provider
      value={useDismissedScreens()}
      {...props}
    />
  )
}
