import { gql } from "@apollo/client"

import { GIFT_SETTINGS_FRAGMENT } from "@/graphql/GiftSettingsFragment"
import { TRACK_GIFT_BATCH_GIFT_LINK_FRAGMENT } from "@/track/components/giftLink/GiftLink"
import { STANDARD_GIFT_DISPENSER_FRAGMENT } from "@/track/queries"

export const TRACK_GIFT_BATCH_FRAGMENT = gql`
  fragment Track_GiftBatch on GiftBatch {
    id
    sendStatus
    sendMethod
    customEmailSubject
    batchName
    batchNameFallback
    expiresAt
    recipientCount
    failureMessages {
      title
      message
    }
    pendingSends
    createdAt
    acceptedCount
    isExpired
    internationalShippingTier
    userFullName
    scheduledSendOn
    autopayPaymentMethodName
    autopayPaymentMethodId
    swapType
    userPublicId
    cartProducts {
      product {
        forceSwapEnabled
        isFlexGift
        customStore {
          id
        }
      }
    }
    priceEstimate {
      pricePreTax
    }
    giftDispenser {
      ...StandardGiftDispenser
    }
    meetingSettings {
      giftSetting
    }
    settings {
      ...GiftSettings
    }
    ...Track_GiftBatchGiftLink
  }
  ${STANDARD_GIFT_DISPENSER_FRAGMENT}
  ${TRACK_GIFT_BATCH_GIFT_LINK_FRAGMENT}
  ${GIFT_SETTINGS_FRAGMENT}
`
