import React from "react"
import tw, { styled } from "twin.macro"

import {
  AAPIFoundedIcon,
  BIPOCIcon,
  BlackFoundedIcon,
  FemaleFoundedIcon,
  GlutenFreeIcon,
  HispanicFoundedIcon,
  KosherIcon,
  LGBTQIcon,
  SocialImpactDrivenIcon,
  SustainableIcon,
  USAMadeIcon,
  VeganIcon,
} from "@/assets/icons/values"

export const brandValueKeys = [
  "USA Made",
  "Social Impact Driven",
  "Sustainable",
  "Gluten Free",
  "Vegan",
  "Kosher Certified",
  "Female Founded",
  "AAPI Founded",
  "BIPOC Founded",
  "Black Founded",
  "LGBTQ+ Founded",
  "Hispanic Founded",
] as const

export type BrandValueKey = (typeof brandValueKeys)[number]

export const isBrandValueKey = (value: string): value is BrandValueKey => {
  return !!brandValueKeys.find((brandValue) => brandValue === value)
}

export const brandValueIconMappings: {
  [key in BrandValueKey]: React.ReactNode
} = {
  "BIPOC Founded": <BIPOCIcon />,
  "Female Founded": <FemaleFoundedIcon />,
  "Gluten Free": <GlutenFreeIcon />,
  "Kosher Certified": <KosherIcon />,
  "LGBTQ+ Founded": <LGBTQIcon />,
  "Social Impact Driven": <SocialImpactDrivenIcon />,
  Sustainable: <SustainableIcon />,
  Vegan: <VeganIcon />,
  "AAPI Founded": <AAPIFoundedIcon />,
  "Hispanic Founded": <HispanicFoundedIcon />,
  "Black Founded": <BlackFoundedIcon />,
  "USA Made": <USAMadeIcon />,
}

// Return brandValues with those that appear in selectedBrandValues sorted to
// the beginning of the array.
export const sortBrandValuesBySelected = (
  brandValues: string[],
  selectedBrandValues: string[],
) => {
  return brandValues.slice().sort((a, b) => {
    return selectedBrandValues.indexOf(b) - selectedBrandValues.indexOf(a)
  })
}

interface BrandValueBadgeProps {
  active: boolean
  value: BrandValueKey
  small?: boolean
  inverted?: boolean
  gray?: boolean
  whiteBackground?: boolean
}

const BrandValueBadge = ({
  active,
  value,
  small,
  inverted,
  gray,
  whiteBackground,
}: BrandValueBadgeProps) => {
  return (
    <BrandValueBadgeContainer
      active={active}
      small={small || false}
      $inverted={inverted}
      css={[whiteBackground && tw`bg-white`]}
    >
      <div tw="mr-2.5 text-primary-500" css={[gray && tw`text-gray-450`]}>
        {brandValueIconMappings[value]}
      </div>
      {value}
    </BrandValueBadgeContainer>
  )
}

const BrandValueBadgeContainer = styled.div<{
  active: boolean
  small: boolean
  $inverted?: boolean
}>(({ active, small, $inverted }) => [
  tw`border border-gray-200 flex items-center mb-2 mx-1 px-3 py-1.5 rounded-full text-gray-500`,
  active && tw`bg-primary-000 border-primary-300 font-medium text-primary-500`,
  small && tw`text-sm px-2.5 py-1`,
  $inverted && tw`bg-white border-white`,
])

export default BrandValueBadge
