import React from "react"

interface FeatureProps {
  image: any
  title: string
  subtitle: string
}

const SubscriptionFeature = ({ image, title, subtitle }: FeatureProps) => {
  return (
    <div tw="pb-5 flex flex-row items-center">
      <div
        css={{
          width: 64,
          maxWidth: 64,
          height: 64,
          boxShadow:
            "0 2px 4px rgba(0, 0, 0, 0.04), 0 1px 2px rgba(0, 0, 0, 0.03)",
        }}
        tw="bg-white rounded-xl flex flex-shrink-0 items-center justify-center"
      >
        {image}
      </div>
      <div tw="pl-5">
        <div tw="text-xl">{title}</div>
        <div tw="pt-1 text-gray-450 font-text">{subtitle}</div>
      </div>
    </div>
  )
}

export default SubscriptionFeature
