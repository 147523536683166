import { useState } from "react"

export const useAnimatedDisplay = () => {
  const [open, setOpenInternal] = useState(false)
  const [displayed, setDisplayed] = useState(false)

  const setOpen = (newOpen: boolean) => {
    setOpenInternal(newOpen)
    if (newOpen) {
      // We just opened, so set displayed to match
      setDisplayed(true)
    } else {
      // We just closed, so set displayed to false after 300ms delay
      setTimeout(() => setDisplayed(false), 300)
    }
  }

  return { open, setOpen, displayed }
}
