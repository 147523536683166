import { useEffect, useMemo, useState } from "react"
import useCollapse from "react-collapsed"
import tw, { styled } from "twin.macro"

import { ArrowRightSmall } from "../../../assets/icons"
import { ReactComponent as CopyIcon } from "../../../assets/icons/copy-small-no-color.svg"
import { getCentsFromString } from "../../../common/currency"
import { formatPrice } from "../../../common/format"
import MoneyInput from "../../../common/MoneyInput"
import { PurpleGradientButton } from "../../../common/PurpleGradientLink"

import { BudgetRecurrence } from "@/types/graphql-types"
import {
  BudgetRule as BudgetRuleType,
  RecentBudgetSetting,
} from "@/types/graphql-types"

interface Props {
  budgetAmount: string
  setBudgetAmount: (amount: string) => void
  selectedRecurrence: BudgetRecurrence
  setSelectedRecurrence: (recurrence: BudgetRecurrence) => void
  handleSaveBudget: () => void
  recentBudgetSettings?: RecentBudgetSetting[] | null
  currentBudgetRule?: BudgetRuleType | null
  isLimited: boolean
}

export default function BudgetRule({
  budgetAmount,
  setBudgetAmount,
  selectedRecurrence,
  setSelectedRecurrence,
  handleSaveBudget,
  recentBudgetSettings,
  currentBudgetRule,
  isLimited,
}: Props) {
  const budgetAmountCents = useMemo(() => {
    return getCentsFromString(budgetAmount) || 0
  }, [budgetAmount])

  const budgetChanged = useMemo(() => {
    const currentBudgetRuleAmount = currentBudgetRule?.amount || 0
    const currentBudgetRuleRecurrence =
      currentBudgetRule?.recurrence || BudgetRecurrence.MONTHLY

    return (
      (budgetAmountCents || 0) !== currentBudgetRuleAmount ||
      selectedRecurrence !== currentBudgetRuleRecurrence
    )
  }, [budgetAmountCents, selectedRecurrence, currentBudgetRule])

  const amountDiff = useMemo(() => {
    const currentBudgetRuleAmount = currentBudgetRule?.amount || 0
    return (budgetAmountCents || 0) - currentBudgetRuleAmount
  }, [budgetAmountCents, selectedRecurrence])

  const amountDiffText = useMemo(() => {
    if (amountDiff < 0) {
      return (
        <span tw="text-red-600">
          Balance adjustment &ndash;{formatPrice(Math.abs(amountDiff))}
        </span>
      )
    } else if (amountDiff > 0) {
      return (
        <span tw="text-green-600">
          Balance adjustment +{formatPrice(amountDiff)}
        </span>
      )
    } else {
      return <span tw="text-gray-500">Current balance +$0</span>
    }
  }, [amountDiff])

  const [isExpanded, setIsExpanded] = useState(false)
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded })

  useEffect(() => {
    setIsExpanded(budgetChanged || false)
  }, [budgetAmountCents, budgetChanged])

  return (
    <div
      tw="px-16 pt-8"
      css={[isLimited && tw`opacity-50 pointer-events-none`]}
    >
      <div tw="rounded-xl bg-white py-9 px-8 shadow-min border border-gray-150 flex flex-row items-start">
        <div tw="flex-1">
          <div tw="text-lg font-semibold text-primary-new-600">
            Recurring budget
          </div>
          <RecentBudgetSettings
            recents={recentBudgetSettings}
            setBudgetAmount={setBudgetAmount}
            setSelectedRecurrence={setSelectedRecurrence}
          />
        </div>
        <div tw="w-[272px]">
          <div tw="flex flex-row items-stretch gap-4">
            <MoneyInput
              value={budgetAmount}
              onChange={(val) => setBudgetAmount(val)}
              inputCss={tw`w-[144px]`}
            />
            <div tw="flex-1">
              <RecurrenceButton
                selected={selectedRecurrence === BudgetRecurrence.MONTHLY}
                onClick={() => setSelectedRecurrence(BudgetRecurrence.MONTHLY)}
              >
                per month
              </RecurrenceButton>
              <div tw="pt-2" />
              <RecurrenceButton
                selected={selectedRecurrence === BudgetRecurrence.QUARTERLY}
                onClick={() =>
                  setSelectedRecurrence(BudgetRecurrence.QUARTERLY)
                }
              >
                per quarter
              </RecurrenceButton>
            </div>
          </div>
          <div {...getCollapseProps()}>
            <div tw="mt-4 border border-gray-150 p-2 rounded-lg">
              <div tw="flex flex-row items-center justify-center gap-1.5 text-gray-500 text-sm tabular-nums">
                {formatPrice(currentBudgetRule?.amount || 0)}{" "}
                <ArrowRightSmall tw="h-3 w-3" />{" "}
                {formatPrice(budgetAmountCents)}
              </div>
              <div tw="text-center font-medium tabular-nums">
                {amountDiffText}
              </div>
            </div>
            <PurpleGradientButton
              tw="rounded-full mt-4 h-10 w-full py-2"
              onClick={handleSaveBudget}
              disabled={!budgetChanged}
            >
              Save
            </PurpleGradientButton>
          </div>
        </div>
      </div>
    </div>
  )
}

interface RecentBudgetSettingsProps {
  recents?: RecentBudgetSetting[] | null
  setBudgetAmount: (amount: string) => void
  setSelectedRecurrence: (recurrence: BudgetRecurrence) => void
}

function RecentBudgetSettings({
  recents,
  setBudgetAmount,
  setSelectedRecurrence,
}: RecentBudgetSettingsProps) {
  if (recents && recents[0]) {
    const recent = recents[0]

    return (
      <div tw="pt-2">
        <button
          tw="py-1 inline-flex flex-row items-center gap-3 text-gray-500 hover:text-primary-new-600 active:text-primary-new-400 text-sm transition-colors"
          onClick={() => {
            setBudgetAmount(formatPrice(recent.amount))
            setSelectedRecurrence(recent.recurrence)
          }}
        >
          <CopyIcon tw="h-4 w-4 stroke-current" />
          <div>
            Clone recent budget&nbsp;&nbsp;&middot;&nbsp;&nbsp;
            {formatPrice(recent.amount)}/
            {recent.recurrence.toLowerCase().replace("ly", "")}
          </div>
        </button>
      </div>
    )
  }

  return null
}

const RecurrenceButton = styled.button<{ selected: boolean }>`
  ${tw`px-3 py-1 border border-gray-100 text-gray-450 block w-full text-center rounded-full hover:bg-primary-new-000 hover:text-primary-new-400 transition-colors`};

  ${(props) =>
    props.selected
      ? tw`bg-primary-new-050 border-primary-new-050 font-semibold text-primary-new-550 hover:bg-primary-new-050 hover:text-primary-new-550`
      : ""};
`
