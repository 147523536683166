import { useMutation } from "@apollo/client"

import ContactForm, { FormData, createScheduledEventInput } from "./ContactForm"
import {
  CONTACTS_QUERY,
  CONTACT_LISTS_QUERY,
  UPDATE_CONTACT_MUTATION,
} from "../queries"
import { SaveResult } from "../types/Contact"

import {
  Contacts_UpdateContactMutation,
  Contacts_UpdateContactMutationVariables,
} from "@/types/graphql-types"

interface NewContactFormProps {
  onSuccess: () => void
  currentListID: string | null
}

const NewContactForm: React.FC<NewContactFormProps> = ({
  onSuccess,
  currentListID,
}) => {
  const [updateContact] = useMutation<
    Contacts_UpdateContactMutation,
    Contacts_UpdateContactMutationVariables
  >(UPDATE_CONTACT_MUTATION)

  const onSave = async (formData: FormData): Promise<SaveResult> => {
    const {
      firstName,
      lastName,
      title,
      company,
      email,
      phone,
      birthday,
      workAnniversary,
      listIds,
    } = formData

    const birthdayInput = createScheduledEventInput(
      birthday.month,
      birthday.day,
      birthday.year,
    )
    const workAnniversaryInput = createScheduledEventInput(
      workAnniversary.month,
      workAnniversary.day,
      workAnniversary.year,
    )

    const res = await updateContact({
      variables: {
        contact: {
          firstName,
          lastName,
          title,
          company,
          email,
          phone,
          birthday: birthdayInput,
          workAnniversary: workAnniversaryInput,
        },
        listIds,
      },
      refetchQueries: [
        {
          query: CONTACTS_QUERY,
          variables: {
            listID: currentListID,
            searchTerm: "",
          },
        },
        // Update contact list counts
        {
          query: CONTACT_LISTS_QUERY,
        },
      ],
    })

    if (res.errors || !res.data) {
      return {
        ok: false,
        error: res.errors ? res.errors[0].message : "Unknown error",
      }
    }

    if (res.data.contactUpdate.ok) {
      onSuccess()
    }

    return res.data.contactUpdate
  }

  return (
    <ContactForm
      onSave={onSave}
      title="New contact"
      defaultListID={currentListID}
    />
  )
}

export default NewContactForm
