import { isEmpty } from "lodash-es"
import { NavLink } from "react-router-dom"
import tw, { styled } from "twin.macro"

import { CustomStoreItemHeader } from "./CustomStoreItemHeader"
import Button from "../../../../common/Button"
import { useCartDrawer } from "../../../../common/contexts/cartDrawer"
import { formatPriceRange } from "../../../../common/format"
import { useGiftCart } from "../../../../common/hooks/giftData"
import { generateRealmPath } from "../../../../common/realm"
import { useCartAdd } from "../../../CartAddContext"
import { useBrowseUrls } from "../../../StoreHelpers"
import {
  CustomStoreCard,
  DefaultMobileHeaderURL,
  numCollapsedOptions,
} from "../../common"

import { CustomStoreStatus } from "@/types/graphql-types"
import { CustomStoreBaseFragment } from "@/types/graphql-types"

interface Props {
  customStore: CustomStoreBaseFragment
  onSelectCustomStoreProductID?: Function
  onArchive: (customStoreId: string) => void
}

export const CustomStoreItem = ({
  customStore,
  onSelectCustomStoreProductID,
  onArchive,
}: Props) => {
  const { openCart } = useCartDrawer()
  const { addCustomStore } = useGiftCart()
  const { requestAddItem } = useCartAdd()
  const { isBrowseUrl } = useBrowseUrls()
  const shouldUseCart = isBrowseUrl()

  const handleSelectCustomStore = (customStore: CustomStoreBaseFragment) => {
    const product = customStore.product

    if (!!onSelectCustomStoreProductID) {
      onSelectCustomStoreProductID(product.id, null)
      return
    }

    addCustomStore({ customStore, defaultImageUrl: DefaultMobileHeaderURL })

    if (shouldUseCart) {
      openCart()
    }
  }

  const onRequestSelect = (customStore: CustomStoreBaseFragment) => {
    if (shouldUseCart) {
      requestAddItem({
        cartEligible: false,
        callback: () => handleSelectCustomStore(customStore),
        itemName: customStore.name,
      })
    } else {
      handleSelectCustomStore(customStore)
    }
  }

  return (
    <CustomStoreCard tw="h-full" key={customStore.id}>
      <CustomStoreItemHeader customStore={customStore} />

      <div tw="p-2 gap-y-1 flex flex-col">
        <div tw="flex justify-between">
          <div tw="text-lg font-medium items-center flex break-all">
            {customStore.name}
            {customStore.status === CustomStoreStatus.DRAFT && (
              <span tw="bg-gray-150 text-gray-500 rounded-full py-1 px-2 mx-2 text-xs">
                DRAFT
              </span>
            )}
          </div>
          <div tw="text-sm">
            {formatPriceRange(
              customStore.minimumPrice ?? null,
              customStore.maximumPrice ?? null,
            )}
          </div>
        </div>
        <div tw="text-sm text-gray-450 pb-5">{customStore.subtitle}</div>
        {!isEmpty(customStore.productPreviewList) && (
          <CustomStoreCardOptionList>
            {!!customStore?.productPreviewList ? (
              <div tw="text-sm gap-y-1 flex flex-col">
                {customStore.productPreviewList.map((product, index) => (
                  <div key={index}>
                    <span tw="text-gray-500">{`· ${product.name} `}</span>
                    <span tw="text-gray-400">{`· ${product.brandName}`}</span>
                  </div>
                ))}
              </div>
            ) : null}
            <div tw="w-min flex text-sm text-gray-400 items-end">
              {!!customStore.numProductsHidden &&
                numCollapsedOptions(customStore.numProductsHidden)}
            </div>
          </CustomStoreCardOptionList>
        )}
      </div>
      <div tw="flex flex-row gap-x-2 p-1 bottom-0 h-14 self-end">
        {customStore.status === CustomStoreStatus.DRAFT &&
        !onSelectCustomStoreProductID ? (
          <>
            <EditNavLink
              tw="w-1/2"
              to={generateRealmPath(
                "plus",
                `/store/custom/edit/${customStore.id}`,
              )}
            >
              Edit
            </EditNavLink>
            <LightButton
              tw="w-1/2 flex justify-center"
              onClick={() => {
                onArchive(customStore.id)
              }}
            >
              Archive
            </LightButton>
          </>
        ) : (
          <>
            <Button
              tw="bg-primary-600 text-white flex justify-center hover:bg-primary-500 active:bg-primary-700"
              css={{
                ...(!onSelectCustomStoreProductID ? tw`w-2/3` : tw`w-full`),
              }}
              onClick={() => onRequestSelect(customStore)}
            >
              Gift
            </Button>
            {!onSelectCustomStoreProductID && (
              <EditNavLink
                to={generateRealmPath(
                  "plus",
                  `/store/custom/edit/${customStore.id}`,
                )}
              >
                Edit
              </EditNavLink>
            )}
          </>
        )}
      </div>
    </CustomStoreCard>
  )
}

const CustomStoreCardOptionList = tw.div`
  flex flex-row justify-between pb-5
`

const EditNavLink = styled(NavLink)`
  ${tw`flex gap-3 items-center bg-white border border-gray-200 py-2 px-4 rounded-lg  hover:border-gray-300 focus-visible:border-gray-300 focus:outline-none active:bg-primary-050 transition-colors active:transition-border disabled:opacity-50 disabled:cursor-default transition-opacity text-gray-450 w-1/3 justify-center`}
  box-shadow: 0 2px 4px rgba(228, 216, 244, 0.3);
`

const LightButton = styled(Button)`
  ${tw`flex gap-3 items-center bg-white border border-gray-200 py-2 px-4 rounded-lg  hover:border-gray-300 focus-visible:border-gray-300 focus:outline-none active:bg-primary-050 transition-colors active:transition-border disabled:opacity-50 disabled:cursor-default transition-opacity text-gray-450 w-1/3 justify-center`}
  box-shadow: 0 2px 4px rgba(228, 216, 244, 0.3);
`
