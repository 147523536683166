import React, { ReactElement } from "react"
import { Link } from "react-router-dom"
import tw, { styled } from "twin.macro"

import ExclamationYieldIcon from "./assets/icons/exclamation-yield.svg"
import InformationCircleIcon from "./assets/icons/information-circle.svg"
import XCircleIcon from "./assets/icons/x-circle.svg"

import { generateRealmPath } from "@/common/realm"

export enum AlertColor {
  yellow = "yellow",
  blue = "blue",
  red = "red",
}

interface Props {
  primaryMessage: string
  secondaryMessage?: string | ReactElement
  color: AlertColor
  linkURL?: string
  linkText?: string
}

export const Alert = ({
  primaryMessage,
  secondaryMessage,
  color,
  linkURL,
  linkText,
}: Props) => {
  const getAlertIcon = (color: AlertColor) => {
    switch (color) {
      case AlertColor.yellow:
        return <img src={ExclamationYieldIcon} />
      case AlertColor.blue:
        return <img src={InformationCircleIcon} />
      case AlertColor.red:
        return <img src={XCircleIcon} />
      default:
        return null
    }
  }

  return (
    <Container $color={color}>
      <div tw="flex flex-row items-center">
        <div>{getAlertIcon(color)}</div>
        <PrimaryMessageContainer $color={color}>
          <p>{primaryMessage}</p>
          {linkURL && linkText && (
            <Link to={generateRealmPath("plus", linkURL)} tw="ml-auto">
              {linkText}
            </Link>
          )}
        </PrimaryMessageContainer>
      </div>
      {secondaryMessage && (
        <SecondaryMessageContainer $color={color}>
          <div tw="leading-[22.4px]">{secondaryMessage}</div>
        </SecondaryMessageContainer>
      )}
    </Container>
  )
}

const Container = styled.div<{
  $color: AlertColor
}>`
  ${({ $color }) =>
    $color === AlertColor.yellow
      ? tw`bg-yellow-50`
      : $color === AlertColor.blue
      ? tw`bg-blue-50`
      : $color === AlertColor.red
      ? tw`bg-red-50`
      : tw``};
  ${tw`p-4 rounded-md`};
`

const PrimaryMessageContainer = styled.div<{
  $color: string
}>`
  ${({ $color }) =>
    $color === AlertColor.yellow
      ? tw`text-yellow-800`
      : $color === AlertColor.blue
      ? tw`text-blue-800`
      : $color === AlertColor.red
      ? tw`text-red-800`
      : tw``};
  ${tw`ml-3 flex flex-row w-full font-medium`};
`

const SecondaryMessageContainer = styled.div<{
  $color: string
}>`
  ${({ $color }) =>
    $color === AlertColor.yellow
      ? tw`text-yellow-700`
      : $color === AlertColor.blue
      ? tw`text-blue-800`
      : $color === AlertColor.red
      ? tw`text-red-700`
      : tw``};
  ${tw`flex mt-2 ml-[27px]`};
`
