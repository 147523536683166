import React, { FC } from "react"
import tw, { styled } from "twin.macro"

import staticAssetUrl from "../../../common/staticAssetUrl"
import { Header, Paragraph, Subheading } from "../../common/SectionComponents"
import BellIcon from "../icons/bell.svg"
import SwapGiftIcon from "../icons/swapGift.svg"
import TruckIcon from "../icons/truck.svg"
import cardHomesickCandlesSrc from "../images/card_homesick_candles.png"
import grazaImgSrc from "../images/graza.png"
import SquigglyCirclesLeftCorner from "../images/squiggly_circles_left_corner.svg"
import SwapOfChoice from "../images/swap_of_choice.png"

const assetRoot = "landing/how_it_works/bulk_gifting"
const CircleBackground = staticAssetUrl(`${assetRoot}/CircleBackground.png`)
const Gong = staticAssetUrl(`${assetRoot}/gong.png`)
const PersonalizedDigitalGiftNotification = staticAssetUrl(
  `${assetRoot}/PersonalizedDigitalGiftNotification.png`,
)

const ProductDescription = () => {
  return (
    <div
      tw="px-5 pb-12 lg:pb-0 pt-12 sm:pt-12 mx-auto flex flex-col justify-center items-center"
      style={{ maxWidth: "1200px" }}
    >
      <Section tw="flex-col lg:flex-row-reverse">
        <RightTextContainer
          icon={BellIcon}
          title="Recipients get a personalized digital notification."
          content="Notify your recipients via automated emails, or get a gift link to send yourself. Include a digital card, gift message, and custom branding."
        />
        <div tw="flex-1 relative">
          <img
            tw="w-full"
            src={PersonalizedDigitalGiftNotification}
            alt="PersonalizedDigitalGiftNotification"
          />
        </div>
      </Section>
      <Section tw="flex-col lg:flex-row pt-12 lg:pt-0">
        <LeftTextContainer
          tw="lg:mr-24"
          icon={SwapGiftIcon}
          title="Recipients can swap for a gift of their choice."
          content="Let recipients swap for an equal or lower-priced gift (with no pricing shown). Or simply set a price, and let them choose from Goody's unique collection."
        />
        <div tw="flex-2 mt-12 lg:mt-0 mb-24 lg:pr-8 lg:pl-8 relative">
          <img tw="w-full" src={SwapOfChoice} alt="SwapOfChoice" />
          <GrazaImg src={grazaImgSrc} />
          <CardHomesickCandlesImg src={cardHomesickCandlesSrc} />
          <SquigglyCirclesLeftCornerImg src={SquigglyCirclesLeftCorner} />
        </div>
      </Section>
      <Section tw="flex-col lg:flex-row pt-4 lg:mb-[-2rem] lg:py-0 flex items-center justify-center">
        <LeftTextContainer
          tw="lg:py-0 flex-1"
          icon={TruckIcon}
          title="Leave the shipping and logistics to us."
          content="Gift recipients provide their shipping info when they accept a gift. We'll get it to their doorstep, and provide order updates directly to your recipients all along the way."
        />
        <div tw="flex-1 mb-24 lg:mb-0 lg:pr-8 relative">
          <TestimonialContainer>
            <div tw="text-2xl p-8 lg:px-20">
              "Goody made it possible to send 800+ high quality gifts to our
              employess during the holidays.{" "}
              <span tw="font-bold">
                It was so easy to administer and track"
              </span>
            </div>
            <div>
              Grace Lancaster, Employee Engagement
              <br />
              and Experience, Gong
            </div>
            <img src={Gong} />
          </TestimonialContainer>
        </div>
      </Section>
    </div>
  )
}

interface TextContainerProps {
  icon?: string
  title: string
  subheading?: string
  content?: string
  children?: React.ReactNode
}

const LeftTextContainer: FC<TextContainerProps> = ({
  icon,
  title,
  subheading,
  content,
  children,
  ...props
}) => (
  <div tw="flex-3 mb-12 lg:mb-0 xl:pr-0 xl:pb-24 lg:pt-24" {...props}>
    <div tw="lg:pr-20 xl:pr-16">
      {icon && <img src={icon} alt="icon" tw="mb-4 lg:mb-8" />}
      {subheading && <Subheading> {subheading} </Subheading>}
      <Header>{title}</Header>
      <Paragraph>{content}</Paragraph>
      {children}
    </div>
  </div>
)

const RightTextContainer: FC<TextContainerProps> = ({
  icon,
  title,
  content,
}) => (
  <div tw="flex-1 mb-12 lg:mb-24 xl:pr-0 xl:pb-24 lg:pt-24">
    <div tw="lg:pl-12">
      <img src={icon} alt="icon" tw="mb-4 lg:mb-8" />
      <Header>{title}</Header>
      <Paragraph>{content}</Paragraph>
    </div>
  </div>
)

const Section = styled.div`
  ${tw`flex lg:mb-12 max-w-[580px] lg:max-w-full`}
`

const GrazaImg = styled.img`
  ${tw`absolute`}
  top: -13%;
  right: 4%;
  width: 40%;
  filter: drop-shadow(0px 42px 54px rgba(6, 13, 37, 0.06));

  @media (min-width: 1024px) {
    top: -14%;
    right: -8%;
    width: 50%;
  }
`

const CardHomesickCandlesImg = styled.img`
  ${tw`absolute`}
  bottom: -8%;
  left: 8%;
  width: 40%;
  filter: drop-shadow(0px 42px 54px rgba(6, 13, 37, 0.06));

  @media (min-width: 1024px) {
    bottom: -10%;
    left: -16%;
    width: 50%;
  }
`

const SquigglyCirclesLeftCornerImg = styled.img`
  ${tw`absolute hidden lg:block`}

  top: -9.5%;
  left: -12%;
`

const TestimonialContainer = styled.div`
  ${tw`flex flex-col justify-center items-center text-white h-full rounded-[4rem] text-center py-12 sm:py-20 lg:py-32`}

  background-image: url(${CircleBackground});
`

export default ProductDescription
