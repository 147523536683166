import { useCallback, useState } from "react"

import { useGlobalState } from "@/common/GlobalState"
import { gtmEvent } from "@/common/gtm"
import { createHookContext } from "@/common/hooks/createHookContext"

const LOCALSTORAGE_KEY = "__goody_2024_holiday_email_gate_submitted"

const useHolidayEmailGateLocal = () => {
  const [user] = useGlobalState("user")

  const hasEnteredEmailGate = localStorage.getItem(LOCALSTORAGE_KEY) === "true"
  const [submitted, setSubmitted] = useState(false)
  const [emailGateTriggered, setEmailGateTriggered] = useState(false)

  const requiresEmailGate = !user?.email && (submitted || !hasEnteredEmailGate)
  const showEmailGate = requiresEmailGate && emailGateTriggered && !submitted

  const tryAccessGiftGuide = useCallback(() => {
    if (!submitted && requiresEmailGate) {
      setEmailGateTriggered(true)
      return false
    } else {
      return true
    }
  }, [setEmailGateTriggered, submitted, requiresEmailGate])

  const onFormSubmit = useCallback(() => {
    localStorage.setItem(LOCALSTORAGE_KEY, "true")
    gtmEvent("Plus_HolidayGiftGuide_SubmitEmail")
    setSubmitted(true)
  }, [setSubmitted, gtmEvent])

  return {
    requiresEmailGate,
    showEmailGate,
    onFormSubmit,
    tryAccessGiftGuide,
  }
}

export const {
  Provider: HolidayEmailGateProvider,
  useHook: useHolidayEmailGate,
  useHookUnsafe: useHolidayEmailGateUnsafe,
} = createHookContext("HolidayEmailGate", useHolidayEmailGateLocal)
