import { countBy } from "lodash-es"

import {
  isBlank,
  validateAndFormatPhoneE164,
  validateAndParseDate,
  validateEmail,
} from "../../../common/format"
import { ContactList } from "../../lib"

import { ContactUploadResult } from "@/types/graphql-types"

export type ImportStep =
  | "upload"
  | "match-columns"
  | "formatting-check"
  | "review-and-import"

// Type for all valid fields - "First name", "Last name", etc...
export type FieldType = (typeof FORMAT_LOOKUP)["name"]

export interface Issue {
  user: {
    firstName: string
    lastName: string
    email: string
  }
  row: number
  column: number
  value: string
}

// Get name of list to import to
export const getImportListName = (
  lists: ContactList[],
  importListId: string | null,
  newImportListName: string,
) => {
  if (importListId) {
    const name = lists.find((list) => list.id === importListId)?.name
    if (name) {
      return name
    }
  } else if (newImportListName.length > 0) {
    return newImportListName
  }
  return "All contacts"
}

// Order key/value data structure of all valid fields
// key: name of field
// name: name of field
// extraLabel: extra information for the field in the instruction box
// acceptedFormats: auto-match formats for the field after normalization.
//   We look at the header row, normalize, and see if it matches any of these fields
// toInputValue: changes the value of the field to the format accepted by the API
// getError: takes a value, validates it, and returns any error it finds
const FIELDS = new Map([
  [
    "First name",
    {
      name: "First name",
      extraLabel: "required",
      acceptedFormats: ["first", "firstname"],
      inputName: "firstName",
      toInputValue: (value: string) => value,
      getError: (value: string) =>
        isBlank(value) || value.trim().length === 0
          ? "First name is required."
          : null,
    },
  ],
  [
    "Last name",
    {
      name: "Last name",
      acceptedFormats: ["last", "lastname"],
      inputName: "lastName",
      toInputValue: (value: string) => value,
      getError: () => null,
    },
  ],
  [
    "Title",
    {
      name: "Title",
      acceptedFormats: ["title"],
      inputName: "title",
      toInputValue: (value: string) => value,
      getError: () => null,
    },
  ],
  [
    "Company",
    {
      name: "Company",
      acceptedFormats: ["company", "comp"],
      inputName: "company",
      toInputValue: (value: string) => value,
      getError: () => null,
    },
  ],
  [
    "Phone",
    {
      name: "Phone",
      acceptedFormats: ["phone", "phonenumber", "cellphone"],
      inputName: "phone",
      toInputValue: (value: string) =>
        value.length > 0 ? validateAndFormatPhoneE164(value) : "",
      getError: (value: string) => {
        try {
          if (value.length > 0) {
            validateAndFormatPhoneE164(value)
          }
          return null
        } catch (e) {
          return "Not a valid phone number"
        }
      },
    },
  ],
  [
    "Email",
    {
      name: "Email",
      acceptedFormats: ["email", "emailaddress"],
      inputName: "email",
      toInputValue: (value: string) => value,
      getError: (value: string) =>
        !isBlank(value) && !validateEmail(value) ? "Not a valid email." : null,
    },
  ],
  [
    "Birthday",
    {
      name: "Birthday",
      acceptedFormats: ["birthday", "birth", "birthdate"],
      inputName: "birthdayString",
      toInputValue: (value: string) => value,
      getError: (value: string) =>
        !isBlank(value) && !validateAndParseDate(value)
          ? "Not a valid birthday. Try entering MM/DD or MM/DD/YYYY format."
          : null,
      extraLabel: "MM/DD or MM/DD/YYYY",
    },
  ],
  [
    "Work Anniversary",
    {
      name: "Work Anniversary",
      acceptedFormats: ["workanniversary"],
      inputName: "workAnniversaryString",
      toInputValue: (value: string) => value,
      getError: (value: string) =>
        !isBlank(value) && !validateAndParseDate(value)
          ? "Not a valid work anniversary. Try entering MM/DD or MM/DD/YYYY format."
          : null,
      extraLabel: "MM/DD or MM/DD/YYYY",
    },
  ],
])

// Array of the field information
export const FIELD_LIST = Array.from(FIELDS.values())

// Lookup of a field by normalized format
const FORMAT_LOOKUP: { [key: string]: string } = {}
FIELD_LIST.forEach((header) => {
  header.acceptedFormats.forEach((format) => {
    FORMAT_LOOKUP[format] = header.name
  })
})

// Find a field for a header row name
export const getSuggestedField = (userHeader: string) => {
  // Replace all non alpha characters and make lower case
  const normalized = userHeader.toLowerCase().replace(/[^a-zA-Z]/g, "")
  return FORMAT_LOOKUP[normalized] ?? null
}

// Find any errors with the matched fields
export const getMatchedFieldError = (
  matchedFields: (string | null)[] | null,
) => {
  if (matchedFields) {
    const counts = countBy(matchedFields)

    // We require a first name
    if ((counts["First name"] ?? 0) === 0) {
      return `"First name" is required.`
    }

    // We can't have duplicate headers
    for (let key of Object.keys(counts)) {
      if (key !== "null" && counts[key] > 1) {
        return `"${key}" is assigned to multiple fields.`
      }
    }
  }

  return null
}

// Get field info from field name
export const getFieldInfo = (fieldName: FieldType) => FIELDS.get(fieldName)!

// Validates a value for a field by fieldName
export const getFieldError = (fieldName: FieldType, value: string) =>
  getFieldInfo(fieldName)?.getError(value.trim()) ?? null

// Get text for result changes on import
export const getUpdatedText = (update: ContactUploadResult) => {
  const items: string[] = [
    update.updateItems.map((item) => `update ${item.replace("_", " ")}`),
    update.newItems.map((item) => `add ${item.replace("_", " ")}`),
    update.addListId ? "add to list" : null,
  ]
    .flat()
    .filter((item) => !isBlank(item)) as string[]

  if (items.length === 0) {
    return ""
  }

  items[0] = items[0]?.charAt(0)?.toUpperCase() + items[0].slice(1)

  if (items.length < 3) {
    return items.join(" and ")
  }

  items[items.length - 1] = "and " + items[items.length - 1]

  return items.join(", ")
}

// Returns whether or not anything was changed in an import result
export const resultUpdated = (result: ContactUploadResult) =>
  result.newItems.length > 0 ||
  result.updateItems.length > 0 ||
  !!result.addListId
