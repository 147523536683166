import { gql } from "@apollo/client"

export const REFERRALS_QUERY = gql`
  query Account_Referrals {
    me {
      businessReferralInfo {
        referralUrl
        creditAwarded
      }
      credit
    }
  }
`
