import "twin.macro"

import FlexGiftCalculator from "./FlexGiftCalculator"
import FlexGiftValueSelector from "./FlexGiftValueSelector"

import { ReactComponent as CalculatorIcon } from "@/account/images/calculator.svg"
import { ReactComponent as ChevronLeftIcon } from "@/assets/icons/chevron-left.svg"

interface Props {
  amount: number
  setAmount: (amount: number) => void
  calculatorMode: boolean
  setCalculatorMode: (mode: boolean) => void
  isConsumer: boolean
}

export default function FlexGiftAmount({
  amount,
  setAmount,
  calculatorMode,
  setCalculatorMode,
  isConsumer,
}: Props) {
  return (
    <div>
      {calculatorMode && (
        <button
          tw="text-primary-new-500 flex flex-row items-center gap-2 hover:text-primary-new-600"
          onClick={() => {
            setCalculatorMode(false)
          }}
        >
          <ChevronLeftIcon tw="stroke-current text-primary-new-500 w-4 h-4" />
          Back to amounts
        </button>
      )}
      {!calculatorMode && (
        <>
          <FlexGiftValueSelector amount={amount} setAmount={setAmount} />
          {!isConsumer && (
            <button
              tw="flex flex-row items-center gap-3 mt-4 border border-gray-200 bg-white py-2 px-3 rounded-lg text-gray-700 hover:(border-primary-new-400 bg-[#FCFBFF] text-primary-550) active:scale-95 transition-all mx-auto"
              onClick={() => setCalculatorMode(true)}
            >
              <CalculatorIcon tw="stroke-current opacity-75 w-6 h-6" />
              Calculate from your total budget
            </button>
          )}
        </>
      )}
      {calculatorMode && (
        <FlexGiftCalculator amount={amount} setAmount={setAmount} />
      )}
    </div>
  )
}
