import React from "react"
import tw from "twin.macro"

interface ITable {
  name?: string | null
  role?: string | null
  children?: React.ReactElement | null
}

const TdName = ({ name, role, children }: ITable) => {
  return (
    <td className="data-hj-suppress ph-no-capture fs-exclude" tw="relative">
      <Name>{name}</Name>
      {role && <Role>{role}</Role>}

      {children && children}
    </td>
  )
}

const Name = tw.div`font-medium text-base`
const Role = tw.div`text-gray-500`

export default TdName
