import { formatPrice } from "../../../common/format"

import { ProductListFragment } from "@/types/graphql-types"

interface Props {
  product: ProductListFragment
}
export const ProductPrice = ({ product }: Props) => {
  const price = formattedPrice(product)

  return price ? <div tw="text-lg">{price}</div> : null
}

const formattedPrice = (product: ProductListFragment) => {
  const productIsBook = product.name === "Gift any book"
  const displayPrice = product.price

  if (product.price == null || productIsBook) {
    return null
  }

  if (product.isFlexGift) {
    return `${formatPrice(displayPrice)}+`
  }

  return formatPrice(product.price)
}
