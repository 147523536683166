import { formatDistanceToNowStrict, isToday } from "date-fns"
import { MouseEventHandler, useMemo } from "react"
import { Column, useTable } from "react-table"
import tw, { styled } from "twin.macro"

import { ReactComponent as BriefcaseIcon } from "./images/briefcase.svg"
import Button from "../../common/Button"
import { isBlank } from "../../common/format"
import { ReactComponent as CalendarIcon } from "../../common/images/calendar.svg"
import { ReactComponent as CheckIcon } from "../../common/images/check.svg"
import { ReactComponent as PlusIcon } from "../../common/images/plus.svg"
import { eventTypeLabels, nextEvent, useRecipients } from "../lib"

import { StandardContactFragment } from "@/types/graphql-types"

interface ContactsTableProps {
  contacts: StandardContactFragment[]
  loading: boolean
  onContactClick: (id: string) => void
  selectedContact: StandardContactFragment | null
}

const ContactsTable = ({
  contacts,
  loading: isLoading,
  onContactClick,
  selectedContact,
}: ContactsTableProps) => {
  const columns = useMemo<
    Column<{
      name: JSX.Element
      titleCompany: JSX.Element
      nextEvent: JSX.Element
      addToGift: JSX.Element
    }>[]
  >(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Title / Company",
        accessor: "titleCompany",
      },
      {
        Header: "Next event",
        accessor: "nextEvent",
      },
      {
        Header: "",
        accessor: "addToGift",
      },
    ],
    [],
  )

  const data = useMemo(
    () =>
      contacts.map((contact) => ({
        id: contact.id,
        name: (
          <>
            <div tw="font-medium truncate">
              {[contact.firstName, contact.lastName].join(" ")}
            </div>
            <div tw="pt-0.5 text-sm text-gray-400 truncate">
              {contact.email}
            </div>
          </>
        ),
        titleCompany: <Occupation contact={contact} />,
        nextEvent: <NextEvent contact={contact} />,
        addToGift: <AddToRecipients contact={contact} />,
      })),
    [contacts],
  )

  const { getTableProps, getTableBodyProps, headers, prepareRow, rows } =
    useTable({ columns, data })

  return (
    <StyledTable
      {...getTableProps()}
      className={isLoading ? "loading" : undefined}
    >
      <thead tw="hidden lg:flex bg-gray-050 h-12 rounded-lg items-center text-sm text-gray-500">
        <Row tw="flex-1">
          {headers.map((column) => (
            <th {...column.getHeaderProps()} tw="text-left font-medium p-0">
              {column.render("Header")}
            </th>
          ))}
        </Row>
      </thead>
      <tbody {...getTableBodyProps()} tw="block">
        {rows.map((row) => {
          prepareRow(row)
          return (
            <Row
              {...row.getRowProps()}
              tw="lg:h-18 lg:items-center cursor-pointer"
              onClick={(e) => {
                e.stopPropagation()
                onContactClick(row.original.id)
              }}
              className={[
                row.original.id === selectedContact?.id
                  ? "selected"
                  : undefined,
                "data-hj-suppress ph-no-capture fs-mask",
              ]
                .filter(Boolean)
                .join(" ")}
            >
              {row.cells.map((cell) => (
                <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
              ))}
            </Row>
          )
        })}
      </tbody>
    </StyledTable>
  )
}

const StyledTable = styled.table`
  ${tw`m-5 lg:ml-6 lg:mr-0 block transition-opacity duration-100`}

  &.loading {
    ${tw`opacity-30 pointer-events-none duration-500`}
  }
`

interface AddToRecipientsProps {
  contact: StandardContactFragment
}
const AddToRecipients = ({ contact }: AddToRecipientsProps) => {
  const { addRecipient, hasRecipient, removeRecipient } = useRecipients()
  const isSelected = hasRecipient(contact)

  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation()
    if (isSelected) {
      removeRecipient(contact)
    } else {
      addRecipient(contact)
    }
  }

  const icon = isSelected ? <CheckIcon tw="stroke-1" /> : <PlusIcon />

  return (
    <GiftButton onClick={handleClick} isSelected={isSelected}>
      {icon} {isSelected ? "Selected" : "Gift"}
    </GiftButton>
  )
}

const GiftButton = styled(Button)<{ isSelected: boolean }>`
  ${tw`text-primary-500 gap-2`}

  ${({ isSelected }) =>
    isSelected && tw`bg-primary-050 text-primary-300 opacity-100`}
`

interface OccupationProps {
  contact: StandardContactFragment
}
const Occupation = ({ contact }: OccupationProps) => {
  if (isBlank(contact.title) && isBlank(contact.company)) {
    return null
  }

  return (
    <div tw="flex gap-2">
      <BriefcaseIcon tw="lg:hidden" />
      <div tw="overflow-x-hidden">
        <div tw="text-sm truncate">{contact.title}</div>
        <div tw="pt-0.5 text-sm text-gray-400 truncate">{contact.company}</div>
      </div>
    </div>
  )
}

interface NextEventProps {
  contact: StandardContactFragment
}
const NextEvent = ({ contact }: NextEventProps) => {
  const event = nextEvent(contact)

  if (event == null) {
    return null
  }

  const distance = isToday(event.date)
    ? "Today"
    : `In ${formatDistanceToNowStrict(event.date)}`

  return (
    <div tw="flex gap-2">
      <CalendarIcon tw="lg:hidden text-gray-300 stroke-1.5" />
      <div>
        <h3 tw="text-xs text-gray-400 font-medium leading-relaxed lg:hidden mb-1">
          Next event
        </h3>
        <EventAnnouncement>
          <div className="distance">{distance}</div>
          <div className="type">{eventTypeLabels[event.type]}</div>
        </EventAnnouncement>
      </div>
    </div>
  )
}

const EventAnnouncement = styled.div`
  ${tw`flex items-baseline lg:block`}

  > .distance {
    ${tw`text-sm`}
  }

  > .type {
    ${tw`lg:pt-0.5 text-sm text-gray-400`}

    &::before {
      ${tw`lg:hidden`}
      content: "・";
    }
  }
`

const Row = styled.tr`
  ${tw`grid lg:gap-3 rounded-xl lg:rounded-none lg:shadow-none bg-white lg:bg-transparent lg:border border-transparent mt-5 lg:mt-0 lg:px-5 transition-colors transition-shadow relative overflow-hidden lg:overflow-visible`}
  grid-template-columns: 1fr 2fr 1fr 6rem;
  box-shadow:
    0 2px 3px 0 rgba(0, 0, 0, 0.04),
    0 8px 24px 0 rgba(0, 0, 0, 0.08);

  td {
    ${tw`block col-span-4 lg:col-span-1 p-4 lg:p-0`}

    // Name
    &:first-child {
      ${tw`col-span-3 lg:col-span-1 pb-0 truncate`}
    }

    // Title / Company
    &:nth-child(2) {
      ${tw`py-2 lg:p-0 truncate`}

      &:empty {
        ${tw`hidden lg:block`}
      }
    }

    // Next event
    &:nth-child(3) {
      ${tw`bg-gray-050 lg:bg-transparent`}

      &:empty {
        ${tw`hidden lg:block`}
      }
    }

    // Gift button
    &:last-child {
      ${tw`flex flex-row-reverse col-start-4 lg:col-start-auto row-start-1 lg:row-start-auto col-span-1`}
    }
  }

  // Row separator
  &:not(:first-child)::before {
    ${tw`border-t border-gray-200 h-0 absolute left-5 right-5`}
    content: " ";
    top: -1px; // Compensate transparent row border
  }

  tbody & {
    &:hover,
    &:active,
    &.selected {
      ${tw`lg:border-gray-150 lg:rounded-xl`}
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.04), 0 1px 16px rgba(0, 0, 0, 0.04);

      // Hide both row separators
      &::before,
      + tr::before {
        ${tw`hidden`}
      }
    }

    &:active {
      ${tw`bg-gray-050`}
    }
  }
`

export default ContactsTable
