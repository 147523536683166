import React from "react"
import SwiperCore, { Thumbs } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import tw, { styled } from "twin.macro"

import { ProductImageType, shouldScaleImage } from "../store/ProductImage"

interface SwiperThumbsProps {
  setThumbsSwiper: React.Dispatch<React.SetStateAction<SwiperCore | null>>
  images: { thumb: ProductImageType }[]
  scalable?: boolean
}

const SwiperThumbs = ({
  images,
  setThumbsSwiper,
  scalable,
}: SwiperThumbsProps) => {
  if (images.length <= 1) {
    return null
  }

  return (
    <StyledSwiperThumbs
      allowSlideNext={false}
      allowSlidePrev={false}
      modules={[Thumbs]}
      watchSlidesProgress
      onSwiper={(swiperInstance) => setThumbsSwiper(swiperInstance)}
      slidesPerView={5}
      spaceBetween={8}
      $useGrid={images.length >= 5}
    >
      {images.map(
        ({ thumb }, index) =>
          thumb && (
            <SwiperSlide key={index}>
              <SwiperThumb
                $thumbUrl={thumb.url}
                shouldScale={shouldScaleImage(thumb, scalable)}
              />
            </SwiperSlide>
          ),
      )}
    </StyledSwiperThumbs>
  )
}

const SwiperThumb = styled.div<{
  $thumbUrl: string
  shouldScale: boolean | undefined
}>`
  ${({ shouldScale }) =>
    shouldScale ? tw`bg-contain bg-white bg-no-repeat` : tw`bg-cover`};
  background-image: url("${({ $thumbUrl }) => $thumbUrl}");
  padding-bottom: 100%;
  background-position: center center;
`

const StyledSwiperThumbs = styled(Swiper)<{ $useGrid: boolean }>`
  ${tw`mt-4 overflow-visible`};

  .swiper-wrapper {
    ${tw`gap-y-1.5 justify-center overflow-visible`};
    ${({ $useGrid }) => ($useGrid ? tw`grid grid-cols-5` : `flex`)};
  }

  .swiper-slide {
    ${tw`border-2 border-transparent opacity-50 overflow-hidden cursor-pointer rounded-lg transition`};

    &.swiper-slide-thumb-active,
    &:hover {
      ${tw`border-2 border-gray-250 opacity-100`};
      filter: drop-shadow(0px 1px 12px rgba(0, 0, 0, 0.1));
    }
  }
`

export default SwiperThumbs
