import React, { forwardRef } from "react"
import tw from "twin.macro"

import Image from "../common/Image"

import { ImageFragment } from "@/types/graphql-types"

export type ProductImageType = ImageFragment

const MAX_ASPECT_WIDTH = 4
const MAX_ASPECT_HEIGHT = 5

export const shouldScaleImage = (
  image?: ProductImageType | null,
  scalable?: boolean | null,
) => {
  if (!image) {
    return false
  }

  const { width, height } = image

  return (
    !!scalable &&
    !!width &&
    !!height &&
    width / height < MAX_ASPECT_WIDTH / MAX_ASPECT_HEIGHT
  )
}

type Props = {
  scalable?: boolean | null
  image: ProductImageType
  addSpacing?: boolean | null
} & Omit<React.ComponentPropsWithoutRef<typeof Image>, "src" | "width">

export const ProductImage = forwardRef<HTMLImageElement, Props>(
  ({ scalable, image, css, addSpacing, ...restProps }, ref) => {
    const { width, height, url } = image

    const hasSize = !!width && !!height
    const shouldScale = shouldScaleImage(image, scalable)

    const aspectCss = (() => {
      if (addSpacing) {
        return [tw`object-contain bg-white p-[10%]`]
      } else if (!hasSize) {
        return [tw`object-cover`]
      } else if (shouldScale) {
        return [
          { aspectRatio: `${MAX_ASPECT_WIDTH} / ${MAX_ASPECT_HEIGHT}` },
          tw`object-contain bg-white`,
        ]
      } else {
        return [{ aspectRatio: `${width} / ${height}` }, tw`object-cover`]
      }
    })()

    return (
      <Image
        src={url}
        ref={ref}
        width={shouldScale ? undefined : width ?? undefined}
        css={[aspectCss, css]}
        {...restProps}
      />
    )
  },
)
