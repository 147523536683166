import { gql } from "@apollo/client"

import { BASE_ME_FRAGMENT } from "../common/me"
import { BASE_ORGANIZATION_FRAGMENT } from "../common/organization"

export const ROOT_DATA_QUERY = gql`
  query App_RootData {
    me {
      ...BaseMe
      email
      crmId
      createdAtUnix
      userHash
      hasCreatedAGiftBatch
    }
    organization {
      ...BaseOrganization
    }
  }
  ${BASE_ME_FRAGMENT}
  ${BASE_ORGANIZATION_FRAGMENT}
`
