import { isBlank } from "./format"
import { BatchRecipient } from "./GlobalState"
import { generateUUID } from "./utilities"

export function generateEmptyRecipient(): BatchRecipient {
  return {
    id: generateUUID(),
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    scheduledEventId: null,
    scheduledEventKind: null,
    eventDate: null,
    errors: {},
  }
}

export const generateEmptyRecipients = (count: number): BatchRecipient[] =>
  new Array(count).fill(null).map((_) => generateEmptyRecipient())

type SendViaStatus = "email" | "text" | "email+text" | "self-send" | null
interface SendViaAttrs {
  firstName: string
  lastName?: string | null
  email?: string | null
  phone?: string | null
}
export function getSendViaStatus({
  email,
  phone,
  firstName,
  lastName,
}: SendViaAttrs): SendViaStatus {
  if (
    isBlank(email) &&
    isBlank(phone) &&
    !(isBlank(firstName) && isBlank(lastName))
  ) {
    return "self-send"
  }

  if (!(isBlank(email) || isBlank(phone))) {
    return "email+text"
  }

  if (!isBlank(email) && isBlank(phone)) {
    return "email"
  }

  if (isBlank(email) && !isBlank(phone)) {
    return "text"
  }

  return null
}

export const isEmptyRecipient = (recipient: BatchRecipient) =>
  isBlank(recipient.firstName) &&
  isBlank(recipient.lastName) &&
  isBlank(recipient.phone) &&
  isBlank(recipient.email)
