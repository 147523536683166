import { useEffect, useState } from "react"

import AlertBubble, { AlertBubbleProps } from "@/common/AlertBubble"
import { track } from "@/common/analytics"
import { useGlobalState } from "@/common/GlobalState"
import {
  SendErrors,
  useBusinessSend,
  useBusinessSendUnsafe,
} from "@/common/hooks/send"

type Props = Omit<AlertBubbleProps, "open" | "onClose"> & {
  error: keyof SendErrors
  open?: (errors: SendErrors) => boolean
}

const SendErrorAlertBubbleWithContext = ({
  error,
  open,
  children,
  ...props
}: Props) => {
  const { errors, clearError } = useBusinessSend()
  const normalizeOpen = () => (open ? open(errors) : errors[error])
  const [isOpen, setIsOpen] = useState(normalizeOpen())

  useEffect(() => {
    const normalized = normalizeOpen()
    if (!isOpen && normalized) {
      track("Business - Send - Send Validation Error Shown", { error })
    } else if (isOpen && !normalized) {
      track("Business - Send - Send Validation Error Dismissed", { error })
    }
    setIsOpen(normalized)
  }, [open, errors, error])

  return (
    <AlertBubble
      open={isOpen}
      onClose={() => clearError(error)}
      className="send-error-alert-bubble"
      children={children}
      {...props}
    />
  )
}

const SendErrorAlertBubble = ({ children, ...props }: Props) => {
  const [currentRealm] = useGlobalState("currentRealm")
  const businessSend = useBusinessSendUnsafe()

  // Send error alert bubbles are not yet shown on consumer but they exist in components shared between both sides
  if (currentRealm === "consumer") {
    return <>{children}</>
  }

  if (!businessSend) {
    return null
  }

  return (
    <SendErrorAlertBubbleWithContext {...props}>
      {children}
    </SendErrorAlertBubbleWithContext>
  )
}

export default SendErrorAlertBubble
