import { addWeeks, set } from "date-fns"
import { utcToZonedTime } from "date-fns-tz"
import { cloneDeep, isEqual, isNil, merge } from "lodash-es"
import moment from "moment"
import { useCallback, useMemo } from "react"

import { useCurrentGift } from "./currentGift"
import { GiftTemplateType } from "../../send/components/giftTemplates/GiftTemplatesMenu"
import { track } from "../analytics"
import { clickstreamEvent } from "../clickstream"
import { isGiftCard as cartItemIsGiftCard, getProductPrice } from "../gifts"
import {
  ConsumerNewUserPaymentSelection,
  ExpireAtOption,
  Product,
  RecipientType,
  initialBusinessCurrentGiftState,
  initialConsumerCurrentGiftState,
  useGlobalState,
} from "../GlobalState"
import { PaymentMethod } from "../PaymentMethodsManager"
import { getRealmFromPath } from "../realm"
import {
  convertScheduledSendDateForGlobalState,
  convertScheduledSendTimeForGlobalState,
  daysFromDate,
  eightWeeksFromNow,
  joinWithCommaAnd,
  sixWeeksFromNowNoon,
} from "../utilities"
import { findShippingGroupIntersection } from "../utils/findShippingGroupIntersection"
import { omitSingleKey } from "../utils/omitSingleKey"

import { giftOptionIsGiftCard } from "@/store/utils"
import {
  BatchSendMethod,
  EmailDeliveryMethods,
  GiftSwapTypeEnum,
  GiftsSettingsInput,
  InternationalShippingTierEnum,
  SalesforceGiftBatchDataInput,
  SendAudience,
  ShippingCountryGroupFragment,
  flex_gift_product_price_type,
  gift_meeting_setting,
} from "@/types/graphql-types"
import {
  Base_GiftBatchFragment,
  CustomStoreBaseFragment,
  Details_ProductFragment,
  GiftBatchEditModal_GiftBatchQuery,
  GiftBatch_ProductFragmentFragment,
  Store_BookSearchMutation,
  Store_GiftOptionDataFragment,
  Store_GiftOptionPreviewFragment,
  Store_PDP_ProductDetailsFragment,
} from "@/types/graphql-types"

export const MAX_CART_ITEMS = 500
export const MAX_CART_PRODUCTS = 50
const NO_VARIANT_INDEX = "NO_VARIANT"

type GiftOptionType =
  | Store_GiftOptionDataFragment
  | Store_GiftOptionPreviewFragment

type PartialGiftOptionType =
  | GiftOptionType
  | Store_PDP_ProductDetailsFragment["brand"]["primaryGiftOption"]

type GiftBatchType = Base_GiftBatchFragment

export type ProductType =
  | Details_ProductFragment
  | GiftBatch_ProductFragmentFragment

export type VariantsArray = string[] | null

const isGiftOption = (
  giftOption: PartialGiftOptionType | null | undefined,
): giftOption is GiftOptionType => !!giftOption?.hasOwnProperty("brand")

const getProductImageThumb = (
  product: ProductType | null,
  variants?: VariantsArray,
) => {
  if (variants && product && "variants" in product) {
    const variantProduct = product?.variants.find((v) => v.name === variants[0])
    if (variantProduct?.imageThumb) {
      return variantProduct.imageThumb
    }
  }

  return product?.productImages[0]?.imageThumb ?? null
}

const getProductImageLarge = (product: ProductType | null) => {
  return product?.productImages[0]?.imageLarge ?? null
}

const getGiftBatchCard = (giftBatch: GiftBatchType) => {
  return !!giftBatch.card
    ? {
        id: giftBatch.card.id,
        image: giftBatch.card.image.url,
      }
    : null
}

export function eventEligibleForSchedule(eventDate: Date) {
  if (eventDate) {
    const eventInPast = moment(eventDate).isBefore(moment(), "day")
    const eventIsToday = moment(eventDate).isSame(moment(), "day")
    const eventAfterEightWeeks = moment(eventDate).isAfter(
      moment(eightWeeksFromNow()),
      "day",
    )
    return !eventInPast && !eventIsToday && !eventAfterEightWeeks
  } else {
    return false
  }
}

export const useGiftData = () => {
  const [currentGift, setCurrentGift] = useCurrentGift()

  const initialCurrentGiftState = () => {
    const realm = getRealmFromPath(window.location.pathname)
    return realm === "consumer"
      ? initialConsumerCurrentGiftState
      : initialBusinessCurrentGiftState
  }

  const resetCurrentGift = () => {
    // Retain audience when resetting.
    setCurrentGift({
      ...initialCurrentGiftState(),
      audience: currentGift.audience,
    })
  }

  const setExpireAtOption = (expireAtOption: ExpireAtOption) => {
    setCurrentGift((gift) => ({
      ...gift,
      expireAtOption,
      expiresAt: getExpiresAt({ expireAtOption }),
    }))
  }

  const setSendType = (recipientType: RecipientType) => {
    setCurrentGift((gift) => ({ ...gift, recipientType }))
  }

  const setEmailDeliveryMethod = (value: EmailDeliveryMethods) => {
    setCurrentGift({ ...currentGift, emailDeliveryMethod: value })
  }

  const setSalesforceData = (
    salesforceGiftBatchData: SalesforceGiftBatchDataInput,
  ) => {
    setCurrentGift({
      ...currentGift,
      isSalesforceSend: true,
      salesforceGiftBatchData,
    })
  }

  const incrementGiftDispenserQuantity = () => {
    let newVal = 1
    if (currentGift.giftDispenserQuantity !== null) {
      newVal = currentGift.giftDispenserQuantity - 1
    }
    setGiftDispenserQuantity(newVal)
  }

  const decrementGiftDispenserQuantity = () => {
    let newVal = 1
    if (currentGift.giftDispenserQuantity !== null) {
      newVal = currentGift.giftDispenserQuantity + 1
    }
    setGiftDispenserQuantity(newVal)
  }

  const setGiftDispenserQuantity = (quantity: number | null) => {
    setCurrentGift({
      ...currentGift,
      giftDispenserQuantity: quantity,
    })
  }

  const decrementSmartLinkQuantity = () => {
    let newVal = 1
    if (currentGift.smartLinkQuantity !== null) {
      newVal = currentGift.smartLinkQuantity - 1
    }
    setSmartLinkQuantity(newVal)
  }

  const incrementSmartLinkQuantity = () => {
    let newVal = 1
    if (currentGift.smartLinkQuantity !== null) {
      newVal = currentGift.smartLinkQuantity + 1
    }
    setSmartLinkQuantity(newVal)
  }

  const setSmartLinkQuantity = (quantity: number | null) => {
    setCurrentGift((oldCurrentGift) => ({
      ...oldCurrentGift,
      smartLinkQuantity: quantity,
    }))
  }

  const setCard = (card: { id: string; image: string }) => {
    setCurrentGift({
      ...currentGift,
      card,
    })
  }

  const setMessage = (message: string) => {
    setCurrentGift((gift) => ({
      ...gift,
      message,
    }))
  }

  const setTopUpAmount = (amount: number | null) => {
    setCurrentGift((oldCurrentGift) => ({
      ...oldCurrentGift,
      topUpAmount: amount,
    }))
  }

  const setTopUpPaymentMethod = (paymentMethod: PaymentMethod | null) => {
    setCurrentGift((oldCurrentGift) => ({
      ...oldCurrentGift,
      topUpPaymentMethod: paymentMethod,
    }))
  }

  const toggleAutopayEnabled = () => {
    setCurrentGift({
      ...currentGift,
      autopayEnabled: !currentGift.autopayEnabled,
    })
  }

  const onSetCVC = () => {
    if (currentGift.paymentMethod) {
      setCurrentGift({
        ...currentGift,
        paymentMethod: {
          ...currentGift.paymentMethod,
          externalLocationsAlcohol: true,
        },
      })
    }
  }

  const setPaymentMethod = (
    autopayPaymentMethod: PaymentMethod,
    autopayPaymentMethodName: string,
  ) => {
    setCurrentGift((gift) => ({
      ...gift,
      paymentMethod: autopayPaymentMethod,
      autopayPaymentMethodID: autopayPaymentMethod.id,
      autopayPaymentMethodName,
      autopayEnabled: true,
    }))
  }

  const resetPaymentMethod = () => {
    setCurrentGift((gift) => ({
      ...gift,
      autopayPaymentMethodID: null,
      autopayPaymentMethodName: null,
      paymentMethod: null,
    }))
  }

  const getExpiresAt = ({
    scheduledSendOnOption: scheduledSendOnOptionParam,
    scheduledSendOnDate: scheduledSendOnDateParam,
    expireAtOption: expireAtOptionParam,
  }: {
    scheduledSendOnOption?: boolean
    scheduledSendOnDate?: Date
    expireAtOption?: ExpireAtOption
  }) => {
    const expireAtOption = expireAtOptionParam ?? currentGift.expireAtOption
    const scheduledSendOption =
      scheduledSendOnOptionParam ?? currentGift.scheduledSendOnOption
    const scheduledSendDate =
      scheduledSendOnDateParam ?? currentGift.scheduledSendOnDate

    const anchorDate = scheduledSendOption ? scheduledSendDate : new Date()

    switch (expireAtOption) {
      case "sixWeeks":
        return daysFromDate(anchorDate, 7 * 6)
      case "fourWeeks":
        return daysFromDate(anchorDate, 7 * 4)
      case "twoWeeks":
        return daysFromDate(anchorDate, 7 * 2)
      case "custom":
        return currentGift.customExpirationDate
      case "none":
        return null
    }
  }

  const setScheduledSend = ({
    option,
    date,
    time,
  }: {
    option?: boolean
    date?: Date
    time?: any
  }) => {
    const scheduledSendOnOption = option ?? currentGift.scheduledSendOnOption
    const scheduledSendOnDate = date ?? currentGift.scheduledSendOnDate

    setCurrentGift({
      ...currentGift,
      scheduledSendOnOption,
      scheduledSendOnDate,
      scheduledSendOnTime: time ?? currentGift.scheduledSendOnTime,
      expiresAt: getExpiresAt({ scheduledSendOnOption, scheduledSendOnDate }),
    })
  }

  const setIsGiftDispenser = (isGiftDispenser: boolean) => {
    setCurrentGift({
      ...currentGift,
      isGiftDispenser,
    })
  }

  const setSwapType = (swapType: GiftSwapTypeEnum) => {
    setCurrentGift((prev) => ({
      ...prev,
      swapType,
    }))
  }

  const setBatchName = (batchName: string) => {
    setCurrentGift((gift) => ({
      ...gift,
      batchName,
    }))
  }

  const setLandingPage = ({
    enabled,
    sendNotifs,
  }: {
    enabled?: boolean
    sendNotifs?: boolean
  }) => {
    setCurrentGift((gift) => ({
      ...gift,
      landingPageEnabled: enabled ?? currentGift.landingPageEnabled,
      landingPageSendNotifs: sendNotifs ?? currentGift.landingPageSendNotifs,
    }))
  }

  const setInternationalShippingTier = (
    internationalShippingTier: InternationalShippingTierEnum,
  ) => {
    setCurrentGift((curr) => ({
      ...curr,
      internationalShippingTier,
    }))
  }

  const setGiftSettings = (settings: GiftsSettingsInput) => {
    setCurrentGift((curr) => ({
      ...curr,
      settings: {
        ...curr.settings,
        ...settings,
      },
    }))
  }

  const setCustomExpiration = (date: Date) => {
    setCurrentGift((gift) => ({
      ...gift,
      expiresAt: date,
      customExpirationDate: date,
    }))
  }

  const setFromData = (fromName: string, message: string) => {
    setCurrentGift({ ...currentGift, fromName, message })
  }

  const updateGiftCalendarSetting = (giftSetting: gift_meeting_setting) => {
    setCurrentGift({ ...currentGift, giftCalendarSetting: giftSetting })
  }

  const setHasRecentlyAddedBalance = (hasRecentlyAddedBalance: boolean) => {
    setCurrentGift((gift) => ({ ...gift, hasRecentlyAddedBalance }))
  }

  const setHasRecentlyAddedCreditCard = (
    hasRecentlyAddedCreditCard: boolean,
  ) => {
    setCurrentGift((gift) => ({ ...gift, hasRecentlyAddedCreditCard }))
  }

  const initializeWithEvent = (batchName: string, eventDate: Date) => {
    const baseGift = merge(cloneDeep(initialCurrentGiftState()), {
      batchName,
      audience: currentGift.audience,
    })

    const newDate = eventEligibleForSchedule(eventDate) ? eventDate : new Date()

    const expiration = set(addWeeks(newDate, 6), {
      hours: 12,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    })

    const newGift = merge(baseGift, {
      expireAtOption: "sixWeeks",
      customExpirationDate: expiration,
      expiresAt: expiration,
      scheduledSendOnDate: newDate,
    })

    setCurrentGift(newGift)
  }

  const setConsumerNewUserPaymentSelection = (
    consumerNewUserPaymentSelection: ConsumerNewUserPaymentSelection,
  ) => {
    setCurrentGift({ ...currentGift, consumerNewUserPaymentSelection })
  }

  const setAudience = (audience: SendAudience | null) => {
    setCurrentGift({ ...currentGift, audience })
  }

  const setSendMethod = (sendMethod: BatchSendMethod | null) => {
    setCurrentGift((gift) => ({
      ...gift,
      sendMethod,
      // Save this as the last selected link send method if it's a link method.
      lastSelectedLinkSendMethod: sendMethod?.startsWith("link_")
        ? sendMethod
        : gift.lastSelectedLinkSendMethod,
      isGiftDispenser: sendMethod === "link_multiple_anyone",
      isSmartLink: sendMethod === "direct_send" ? false : gift.isSmartLink,
    }))
  }

  const setOmitCredit = (omitCredit: boolean) => {
    setCurrentGift((gift) => ({
      ...gift,
      omitCredit,
    }))
  }

  const MAX_EMAIL_SUBJECT_LENGTH = 100

  const setCustomEmailSubject = (customEmailSubject: string | null) => {
    const trimmedSubject = customEmailSubject?.slice(
      0,
      MAX_EMAIL_SUBJECT_LENGTH,
    )
    const haveChanged = currentGift.customEmailSubject !== trimmedSubject

    if (!haveChanged) return

    setCurrentGift({
      ...currentGift,
      customEmailSubject: trimmedSubject,
    })
  }

  const getCartInput = () =>
    currentGift.cart.map((product: Product) => ({
      productId: product.id,
      quantity: product.quantity ?? 1,
      productDetails: {
        referringCategoryId: product.referringCategoryID,
        isBook: !!product?.bookData?.ean,
        bookEan: product?.bookData?.ean,
        bookTitle: product?.bookData?.title,
        referringSearchRecordId: product.referringSearchRecordID,
      },
      presetVariants: product.variants,
    }))

  const isDirectSend = currentGift.sendMethod === BatchSendMethod.direct_send

  const setIsSmartLink = (isSmartLink: boolean) => {
    setCurrentGift((oldCurrentGift) => ({ ...oldCurrentGift, isSmartLink }))
  }

  const setsmartLinkApprovalRequired = (smartLinkApprovalRequired: boolean) => {
    setCurrentGift((oldCurrentGift) => ({
      ...oldCurrentGift,
      smartLinkApprovalRequired,
    }))
  }

  return {
    setSendType,
    setMessage,
    setOmitCredit,
    toggleAutopayEnabled,
    onSetCVC,
    setPaymentMethod,
    resetPaymentMethod,
    setIsGiftDispenser,
    setBatchName,
    setSwapType,
    setScheduledSend,
    setLandingPage,
    setInternationalShippingTier,
    setGiftSettings,
    setCustomExpiration,
    setFromData,
    resetCurrentGift,
    setExpireAtOption,
    setEmailDeliveryMethod,
    setCard,
    setSalesforceData,
    incrementGiftDispenserQuantity,
    decrementGiftDispenserQuantity,
    setGiftDispenserQuantity,
    initializeWithEvent,
    updateGiftCalendarSetting,
    setHasRecentlyAddedBalance,
    setHasRecentlyAddedCreditCard,
    setConsumerNewUserPaymentSelection,
    setAudience,
    setSendMethod,
    setCustomEmailSubject,
    getCartInput,
    isDirectSend,
    setIsSmartLink,
    setSmartLinkQuantity,
    incrementSmartLinkQuantity,
    decrementSmartLinkQuantity,
    setsmartLinkApprovalRequired,
    setTopUpAmount,
    setTopUpPaymentMethod,
    currentGift,
  }
}

// This hook is used to manage writes to "currentGift"
export const useGiftCart = () => {
  const [currentGift, setCurrentGift] = useCurrentGift()
  const [referringCategoryID] = useGlobalState("referringCategoryID")
  const [referringSearchRecordID] = useGlobalState("referringSearchRecordID")
  const [storeSelections] = useGlobalState("storeSelections")

  const addBook = ({
    book,
    bookProductID,
    giftOption,
  }: {
    book: NonNullable<Store_BookSearchMutation["bookSearch"]["results"]>[0]
    bookProductID: string
    giftOption: GiftOptionType
  }) => {
    addToCart({
      product: {
        quantity: 1,
        id: bookProductID,
        brandHideMadeBySubtitle: true,
        brandFreeShippingMinimum: null,
        name: book.title,
        brandName: giftOption.brand.name,
        brandID: giftOption.brand.id,
        giftOptionSlug: giftOption.slug ?? undefined,
        imageThumb: giftOption.primaryImage?.imageLarge || null,
        imageLarge: giftOption.primaryImage?.imageLarge || null,
        forceSwapEnabled: false,
        priceProduct: book.price,
        priceShipping: giftOption.brand.shippingPrice || 0,
        isAlcohol: false,
        isFlexGift: false,
        isBook: true,
        bookData: {
          isbn: book.isbn,
          ean: book.ean,
          title: book.title,
          contributors: book.contributors?.join(", "),
          image: book.imageLarge,
          imageAvailable: book.imageAvailable,
        },
        shippingCountryGroup: giftOption.shippingCountryGroup,
        referringCategoryID,
        referringSearchRecordID,
        cartEligible: true,
        variants: null,
      },
    })
  }

  const addCustomStore = ({
    customStore,
    defaultImageUrl,
  }: {
    customStore: CustomStoreBaseFragment
    defaultImageUrl: string
  }) => {
    const product = customStore.product

    const productImageUrl =
      customStore.mobileHeaderImageThumb?.url ??
      customStore.desktopHeaderImage?.url ??
      defaultImageUrl

    const productImage = productImageUrl ? { url: productImageUrl } : null

    addToCart({
      internationalShippingTier: customStore.internationalShippingTier,
      settings: omitSingleKey("_typename", customStore.settings),
      product: {
        quantity: 1,
        id: product.id,
        name: customStore.name,
        brandName: product.brand.name,
        brandID: product.brand.id,
        brandFreeShippingMinimum: null,
        priceProduct: customStore.maximumPrice ?? 0,
        priceShipping: 0,
        imageThumb: productImage,
        imageLarge: productImage,
        isFlexGift: false,
        isBook: false,
        isAlcohol: false,
        forceSwapEnabled: true,
        brandHideMadeBySubtitle: true,
        priceType: product.swapStoreSettings?.priceType,
        customStore: customStore,
        referringCategoryID,
        referringSearchRecordID,
        cartEligible: false,
        variants: null,
        shippingCountryGroup: {},
      },
    })
  }

  const getBookData = ({
    cartProduct,
    isBook,
  }: {
    cartProduct?: Base_GiftBatchFragment["cartProducts"][0]
    isBook: boolean
  }) => {
    if (isBook && cartProduct?.bookResult) {
      return {
        isbn: cartProduct.bookResult.isbn,
        ean: cartProduct.bookResult.ean,
        title: cartProduct.bookResult.title,
        contributors: joinWithCommaAnd(
          cartProduct.bookResult.contributors ?? [],
        ),
        image: cartProduct.bookResult.imageLarge,
        imageAvailable: cartProduct.bookResult.imageAvailable,
      }
    }

    return undefined
  }

  const getFlexGiftPrice = ({
    giftBatch,
    product,
    flexGiftPrice,
  }: {
    giftBatch?: GiftBatchType
    product: ProductType
    flexGiftPrice?: number
  }) => {
    const flexGiftPriceFinal =
      giftBatch?.flexGiftPrice ?? flexGiftPrice ?? storeSelections.flexGiftPrice

    if (!product.isFlexGift) {
      return undefined
    } else if (
      flexGiftPriceFinal &&
      product?.swapStoreSettings?.priceType !==
        flex_gift_product_price_type.FIXED
    ) {
      return flexGiftPriceFinal
    } else {
      return product.price
    }
  }

  const getCartItem = ({
    product,
    giftCardAmount,
    giftCardCurrency,
    flexGiftPrice,
    giftOption,
    giftBatch,
    cartProduct,
    variants,
    cartTags,
  }: {
    product: ProductType
    giftOption?: PartialGiftOptionType
    giftBatch?: GiftBatchType
    giftCardAmount?: number
    giftCardCurrency?: string
    flexGiftPrice?: number
    cartProduct?: Base_GiftBatchFragment["cartProducts"][0]
    variants?: VariantsArray
    cartTags?: string[] | null
  }): Product => {
    const brand = isGiftOption(giftOption) ? giftOption?.brand : product.brand
    const isBook =
      cartProduct?.productDetails?.isBook ??
      (isGiftOption(giftOption)
        ? giftOption?.name === "Gift any book"
        : product.isBook)

    const productFragment = cartProduct?.productFragment

    return {
      id: product.id,
      slug: product.slug,
      quantity: cartProduct?.quantity ?? 1,
      name: productFragment?.name ?? product.name,
      giftOptionSlug: giftOption?.slug ?? undefined,
      brandName: productFragment?.brandName ?? brand.name,
      brandID: productFragment?.brandId ?? brand.id,
      brandSlug: productFragment?.brandSlug ?? brand.slug,
      brandFreeShippingMinimum: brand.freeShippingMinimum ?? null,
      priceProduct: (isBook && cartProduct?.bookResult?.price) || product.price,
      giftCardAmount,
      giftCardCurrency,
      priceShipping: brand.shippingPrice ?? 0,
      imageThumb:
        giftBatch?.customStoreImageThumb ??
        getProductImageThumb(product, variants) ??
        null,
      imageLarge: getProductImageLarge(product) ?? null,
      isAlcohol: product.isAlcohol,
      isFlexGift: product.isFlexGift,
      flexGiftPrice: getFlexGiftPrice({
        giftBatch,
        product,
        flexGiftPrice,
      }),
      isBook,
      bookData: getBookData({ cartProduct, isBook }),
      forceSwapEnabled: product.forceSwapEnabled,
      brandHideMadeBySubtitle: brand.hideMadeBySubtitle,
      priceType: product.swapStoreSettings?.priceType ?? undefined,
      customStore: product.customStore ?? null,
      referringCategoryID,
      referringSearchRecordID,
      cartEligible: product.cartEligible,
      variants: variants || null,
      cartTags,
      imagesScalable: product.imagesScalable,
      shippingCountryGroup: product.shippingCountryGroup,
    }
  }

  const addProduct = ({
    product,
    variants,
    internationalShippingTier,
    giftOption,
    giftCardDenomination,
    giftCardCurrency,
    flexGiftPrice,
    cartTags,
    isGiftCard,
    settings,
  }: {
    product: ProductType
    variants: VariantsArray
    giftOption?: PartialGiftOptionType
    internationalShippingTier?: InternationalShippingTierEnum
    giftCardDenomination?: number | null
    giftCardCurrency?: string
    flexGiftPrice?: number
    cartTags?: string[] | null
    isGiftCard?: boolean
    settings?: GiftsSettingsInput
  }) => {
    addToCart({
      product: getCartItem({
        product,
        giftOption,
        giftCardAmount:
          (isGiftCard ||
            (isGiftOption(giftOption) && giftOptionIsGiftCard(giftOption))) &&
          giftCardDenomination != null
            ? giftCardDenomination * 100
            : undefined,
        giftCardCurrency,
        flexGiftPrice,
        variants,
        cartTags,
      }),
      internationalShippingTier,
      settings,
    })
  }

  const cartQuantity = currentGift.cart.reduce(
    (amount, product) => amount + product.quantity,
    0,
  )

  const hasCartIneligibleItem = currentGift.cart.some(
    (cartItem) => !cartItem.cartEligible,
  )

  const hasCustomStore = currentGift.cart.some(
    (cartItem) => !!cartItem.customStore,
  )

  const hasForceSwapEnabledItem = currentGift.cart.some(
    (cartItem) => cartItem.forceSwapEnabled,
  )

  const hasFlexGiftItem =
    currentGift.cart.length > 0 &&
    currentGift.cart.some((cartItem) => cartItem.isFlexGift)

  const canAddProductToCart = currentGift.cart.every(
    (cartItem) => cartItem.cartEligible,
  )

  const hasGiftCardItem = currentGift.cart.every(cartItemIsGiftCard)

  const shippingClasses = useMemo(() => {
    const classes = []

    if (currentGift.settings.giftCardsEnabled || hasGiftCardItem) {
      classes.push("giftCard")
    }

    switch (currentGift.internationalShippingTier) {
      case "disabled":
        return [...classes, "domestic"]
      case "standard":
        return [...classes, "domestic", "passthrough", "digital"]
      case "full":
        return [...classes, "domestic", "passthrough", "digital", "globalRelay"]
      default:
        return classes
    }
  }, [
    currentGift.internationalShippingTier,
    currentGift.settings.giftCardsEnabled,
    hasGiftCardItem,
  ])

  const canShipNewProduct = useCallback(
    (shippingCountryGroup: ShippingCountryGroupFragment) => {
      const shippingCountryGroups = currentGift.cart.map(
        (product) => product.shippingCountryGroup,
      )
      const intersection = findShippingGroupIntersection([
        ...shippingCountryGroups,
        shippingCountryGroup,
      ])
      return Object.values(intersection).some(
        (countryCodes) => countryCodes.length > 0,
      )
    },
    [currentGift.cart],
  )

  const brandProducts: Product[][] = useMemo(() => {
    const sections: Product[][] = []
    let lastBrandId: string | null = null
    let lastBrandSection: Product[] | null = null

    currentGift.cart.forEach((product) => {
      if (lastBrandId !== product.brandID) {
        lastBrandId = product.brandID
        lastBrandSection = []
        sections.push(lastBrandSection)
      }
      lastBrandSection?.push(product)
    })
    return sections
  }, [currentGift.cart])

  // Doesn't include shipping
  const brandSubtotals = useMemo(
    () =>
      brandProducts.map((products) =>
        products.reduce(
          (amount, product) =>
            amount + getProductPrice(product) * product.quantity,
          0,
        ),
      ),
    [brandProducts],
  )

  const getProductInCart = ({
    productId,
    variants,
    bookEan,
    giftCardAmount,
    flexGiftPrice,
  }: {
    productId?: string | null
    variants: VariantsArray
    bookEan?: string | null
    giftCardAmount?: number | null
    flexGiftPrice?: number | null
  }) => {
    if (!productId) {
      return null
    }
    return currentGift.cart.find((cartItem) => {
      if (cartItem.id !== productId) {
        return false
      } else if (
        cartItemIsGiftCard(cartItem) &&
        cartItem.giftCardAmount !== giftCardAmount
      ) {
        return false
      } else if (cartItem.isBook && cartItem.bookData?.ean !== bookEan) {
        return false
      } else if (
        cartItem.isFlexGift &&
        cartItem.flexGiftPrice !== flexGiftPrice
      ) {
        return false
      } else if (
        // If either cartItem has variants, or we have been given specific variants
        (cartItem.variants || variants) &&
        // If the cart item variants does not equal the given variants array (comparing contents), fail
        !isEqual(
          isNil(cartItem.variants) ? null : cartItem.variants.sort(),
          isNil(variants) ? null : variants.sort(),
        )
      ) {
        return false
      }

      return true
    })
  }

  // TODO: switch to price estimate
  const cartSubtotal = useMemo(
    () =>
      brandSubtotals.reduce((amount, total, currentIndex) => {
        const baseProduct = brandProducts[currentIndex][0]

        if (
          isNil(baseProduct.brandFreeShippingMinimum) ||
          total < baseProduct.brandFreeShippingMinimum
        ) {
          return amount + total + baseProduct.priceShipping
        } else {
          return amount + total
        }
      }, 0),
    [brandSubtotals, brandProducts],
  )

  // Cart can have a maximum of MAX_CART_ITEMS total quantity, or
  // MAX_CART_PRODUCTS total products.
  const cartIsMaxQuantity =
    cartQuantity >= MAX_CART_ITEMS ||
    currentGift.cart.length >= MAX_CART_PRODUCTS

  // For the purposes of the lookup index, serialize the variants for the given
  // variants array. If variants are null, use the NO_VARIANT_INDEX constant.
  const serializeProductDetailsForIndex = function (
    variants: VariantsArray,
    bookData?: Product["bookData"],
  ) {
    if (bookData?.ean) {
      return bookData.ean
    } else if (!variants) {
      return NO_VARIANT_INDEX
    } else {
      return JSON.stringify(variants.sort())
    }
  }

  const cartIndices = useMemo(() => {
    return currentGift.cart.reduce(
      (lookup, product, index) => {
        lookup[product.id] = index
        lookup[product.brandID] = index
        return lookup
      },
      {} as { [key: string]: number },
    )
  }, [currentGift.cart])

  // Lookup index that maps [productID][variants] to the index in the cart
  // You can use this to look up the cart index for a particular product ID and
  // its associated variants (if any).
  const productVariantIndices = useMemo(() => {
    return currentGift.cart.reduce(
      (acc, product, index) => {
        if (!acc[product.id]) {
          acc[product.id] = {}
        }

        acc[product.id][
          serializeProductDetailsForIndex(product.variants, product.bookData)
        ] = index

        return acc
      },
      {} as { [key: string]: { [key: string]: number } },
    )
  }, [currentGift.cart])

  const getCartIndexForProductAndDetails = (
    productID: string,
    variants: VariantsArray,
    bookData?: Product["bookData"],
  ) => {
    if (productVariantIndices[productID]) {
      return productVariantIndices[productID][
        serializeProductDetailsForIndex(variants, bookData)
      ]
    }

    return null
  }

  // TODO: maybe no linear search
  const getProductShipping = (product: Product) => {
    const brandIndex = brandProducts.findIndex(
      (products) => products[0].brandID === product.brandID,
    )

    if (
      isNil(brandIndex) ||
      isNil(product.brandFreeShippingMinimum) ||
      brandSubtotals[brandIndex] < product.brandFreeShippingMinimum
    ) {
      return product.priceShipping
    } else {
      return 0
    }
  }

  const setGiftTemplate = (giftTemplate: GiftTemplateType) => {
    const image = giftTemplate.card.imageLarge.url ?? ""

    setCurrentGift((oldCurrentGift) => ({
      ...oldCurrentGift,
      card: { id: giftTemplate.card.id, image },
      cart: [
        getCartItem({
          product: giftTemplate.products[0],
          giftCardAmount: giftTemplate.giftCardAmount ?? undefined,
          flexGiftPrice: giftTemplate.flexGiftPrice ?? undefined,
        }),
      ],
      giftTemplate,
    }))
  }

  const resetGiftTemplate = (setGiftMessage: (message: string) => void) => {
    setGiftMessage("")
    setCurrentGift((oldCurrentGift) => ({
      ...oldCurrentGift,
      card: null,
      cart: [],
      giftTemplate: null,
    }))
  }

  const addToCart = ({
    product,
    internationalShippingTier,
    settings,
  }: {
    product: Product
    internationalShippingTier?: InternationalShippingTierEnum
    settings?: GiftsSettingsInput
  }) => {
    const clearCart =
      !canAddProductToCart ||
      !product.cartEligible ||
      !canShipNewProduct(product.shippingCountryGroup)

    const cart = (() => {
      if (clearCart) {
        return [product]
      } else {
        // Prevent changes if we're at max quantity
        if (cartIsMaxQuantity) {
          return currentGift.cart
        }

        const newCart = [...currentGift.cart]

        const productIndex = getCartIndexForProductAndDetails(
          product.id,
          product.variants,
          product.bookData,
        )
        const brandIndex = cartIndices[product.brandID]

        if (!isNil(productIndex)) {
          newCart[productIndex].quantity = newCart[productIndex].quantity + 1
        } else if (!isNil(brandIndex)) {
          newCart.splice(brandIndex + 1, 0, product)
        } else {
          newCart.push(product)
        }
        return newCart
      }
    })()

    setCurrentGift((prev) => ({
      ...prev,
      cart,
      internationalShippingTier: !isNil(internationalShippingTier)
        ? internationalShippingTier
        : currentGift.internationalShippingTier,
      settings: { ...currentGift.settings, ...settings },
      referringCategoryID,
    }))

    track("Cart - Add Product", {
      productID: product.id,
      name: product.name,
      brand: product.brandName,
      price: product.priceProduct,
    })

    clickstreamEvent("store.add_to_cart", product.id)
  }

  const removeFromCart = (product: Product, variants: VariantsArray) => {
    let cartIndex = getCartIndexForProductAndDetails(
      product.id,
      variants,
      product.bookData,
    )

    if (!isNil(cartIndex)) {
      const cart = [...currentGift.cart]
      cart.splice(cartIndex, 1)
      setCurrentGift({
        ...currentGift,
        cart,
      })
    }
  }

  const increaseQuantity = (product: Product, variants: VariantsArray) => {
    const cartIndex = getCartIndexForProductAndDetails(product.id, variants)
    if (!isNil(cartIndex)) {
      const totalQuantityAfterChange = cartQuantity + 1

      if (totalQuantityAfterChange > MAX_CART_ITEMS) {
        alert(`You can only send ${MAX_CART_ITEMS} items at a time.`)
        return
      }

      currentGift.cart[cartIndex].quantity += 1

      setCurrentGift({
        ...currentGift,
        cart: [...currentGift.cart],
      })
    }
  }

  const decreaseQuantity = (product: Product, variants: VariantsArray) => {
    const cartIndex = getCartIndexForProductAndDetails(product.id, variants)
    if (!isNil(cartIndex)) {
      const currentQuantity = currentGift.cart[cartIndex].quantity
      if (currentQuantity > 1) {
        currentGift.cart[cartIndex].quantity -= 1
        setCurrentGift({
          ...currentGift,
          cart: [...currentGift.cart],
        })
      } else {
        removeFromCart(product, variants)
      }
    }
  }

  const setQuantity = (
    product: Product,
    variants: VariantsArray,
    quantity: number,
  ) => {
    const cartIndex = getCartIndexForProductAndDetails(product.id, variants)

    if (!isNil(cartIndex)) {
      const effectiveQuantity = quantity > 0 ? quantity : 0
      const totalQuantityAfterChange =
        cartQuantity - currentGift.cart[cartIndex].quantity + effectiveQuantity

      if (totalQuantityAfterChange > MAX_CART_ITEMS) {
        alert(`You can only send ${MAX_CART_ITEMS} items at a time.`)
        return
      }

      if (quantity > 0) {
        currentGift.cart[cartIndex].quantity = quantity
        setCurrentGift({
          ...currentGift,
          cart: [...currentGift.cart],
        })
      } else {
        removeFromCart(product, variants)
      }
    }
  }

  const replaceCart = (cart: Product[]) => {
    setCurrentGift({
      ...currentGift,
      cart,
    })
  }

  const setVariantsForNullVariantsProduct = (
    productID: string,
    variants: VariantsArray,
  ) => {
    const cartIndex = getCartIndexForProductAndDetails(productID, null)
    if (!isNil(cartIndex)) {
      currentGift.cart[cartIndex].variants = variants

      setCurrentGift({
        ...currentGift,
        cart: [...currentGift.cart],
      })
    }
  }

  const getBaseGiftBatchData = ({
    giftBatch,
    giftCardAmount,
  }: {
    giftBatch: GiftBatchType
    giftCardAmount?: number
  }) => {
    const { cartProducts } = giftBatch

    return {
      message: giftBatch.message,
      fromName: giftBatch.fromName,
      card: getGiftBatchCard(giftBatch),
      customEmailSubject: giftBatch.customEmailSubject,
      internationalShippingTier:
        giftBatch.internationalShippingTier || "disabled",
      cart: cartProducts
        ? cartProducts.map((cartProduct) =>
            getCartItem({
              product: cartProduct.product,
              cartProduct,
              giftCardAmount,
              giftCardCurrency: cartProduct.product.giftCardInfo?.currency,
              giftBatch,
              variants:
                cartProduct.presetVariants &&
                cartProduct.presetVariants.length > 0
                  ? cartProduct.presetVariants
                  : null,
            }),
          )
        : [],
    }
  }

  const resetWithGiftBatch = (giftBatch: Base_GiftBatchFragment) => {
    setCurrentGift({
      ...currentGift,
      ...getBaseGiftBatchData({
        giftBatch,
        giftCardAmount: giftBatch.giftCardAmount || undefined,
      }),
    })
  }

  const editWithGiftBatch = (
    giftBatch: NonNullable<
      NonNullable<GiftBatchEditModal_GiftBatchQuery["workspace"]>["giftBatch"]
    >,
  ) => {
    setCurrentGift({
      ...currentGift,
      ...getBaseGiftBatchData({
        giftBatch,
        giftCardAmount:
          (giftBatch.cartProducts?.some(
            (item) => item.product.brand?.name === "Gift Cards",
          ) &&
            giftBatch.giftCardAmount) ||
          undefined,
      }),
      giftBatchId: giftBatch.id,
      batchName: giftBatch.batchName ?? null,
      expiresAt: giftBatch.expiresAt
        ? // We need to restore the UTC expiresAt as a Date with a time zone
          // attached to correctly restore it for the date picker. The
          // time zone should be Hawaii time, UTC-10:00.
          utcToZonedTime(giftBatch.expiresAt, "-10:00")
        : null,
      // We are unable to restore the "sixWeeks" ExpireAtOption since the two
      // weeks displayed at the time of gift send would be different when this
      // page is loaded on a different day, so if the user selected that at
      // first, it will become "custom" when restored.
      expireAtOption: giftBatch.expiresAt ? "custom" : "none",
      customExpirationDate: giftBatch.expiresAt
        ? utcToZonedTime(giftBatch.expiresAt, "-10:00")
        : sixWeeksFromNowNoon(),
      autopayPaymentMethodName: giftBatch.autopayPaymentMethodName ?? null,
      autopayPaymentMethodID: giftBatch.autopayPaymentMethodId ?? null,
      emailDeliveryMethod: giftBatch.emailDeliveryMethod || null,
      swapType:
        giftBatch.swapType ??
        (giftBatch.swapEnabled
          ? GiftSwapTypeEnum.swap_single
          : GiftSwapTypeEnum.swap_disabled),
      landingPageEnabled: giftBatch.landingPageEnabled ?? false,
      landingPageSendNotifs: giftBatch.landingPageSendNotifs ?? false,
      scheduledSendOnOption: true,
      scheduledSendOnDate: convertScheduledSendDateForGlobalState(
        giftBatch.scheduledSendOn,
      ),
      scheduledSendOnTime: convertScheduledSendTimeForGlobalState(
        giftBatch.scheduledSendOn,
      ),
      userPublicId: giftBatch.userPublicId,
      // TODO: Update this to work with flex gifts when updated flexGiftPrice API is merged to master
      isGiftDispenser: false,
      giftDispenserQuantity: 10,
      isAutogift: giftBatch.isAutogift,
      giftCalendarSetting: giftBatch.meetingSettings?.giftSetting ?? null,
      sendMethod: giftBatch.sendMethod ?? null,
      audience: giftBatch.sendAudience ?? null,
      customEmailSubject: giftBatch.customEmailSubject,
    })
  }

  return {
    currentGift,
    increaseQuantity,
    decreaseQuantity,
    setQuantity,
    removeFromCart,
    addProduct,
    setVariantsForNullVariantsProduct,
    resetWithGiftBatch,
    editWithGiftBatch,
    addBook,
    addCustomStore,
    canAddProductToCart,
    canShipNewProduct,
    shippingClasses,
    cartIsMaxQuantity,
    cartQuantity,
    hasCartIneligibleItem,
    hasForceSwapEnabledItem,
    hasCustomStore,
    hasFlexGiftItem,
    brandProducts,
    cartSubtotal,
    getProductShipping,
    getProductInCart,
    replaceCart,
    setGiftTemplate,
    resetGiftTemplate,
  }
}
