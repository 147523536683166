import { isValid, parse, parseISO } from "date-fns"
import { AsYouType, E164Number, parsePhoneNumber } from "libphonenumber-js/max"
import { snakeCase, transform } from "lodash-es"

export function formatPhone(phoneStr: string | E164Number) {
  // Remove +1 prefix if present.
  const phone = phoneStr.replace(/^\+1/, "")

  // Require the area code to be present first.
  // Hack; see https://github.com/catamphetamine/libphonenumber-js/issues/225
  const rawNumbers = phone.replace(/\D/g, "")
  if (
    (rawNumbers.charAt(0) === "1" && rawNumbers.length >= 5) ||
    (rawNumbers.charAt(0) !== "1" && rawNumbers.length >= 4)
  ) {
    return new AsYouType("US").input(phone)
  }

  return rawNumbers
}

export function formatInternationalPhone(phoneStr: string | E164Number) {
  // Hack; see https://github.com/catamphetamine/libphonenumber-js/issues/225
  if (phoneStr.includes("(") && !phoneStr.includes(")")) {
    return phoneStr.replace("(", "")
  }

  return new AsYouType("US").input(phoneStr as string)
}

// Validate and format a given phone number string and return in E164 format.
// If an error occurs during validation, it throws an error, to be caught by the
// consuming method.
//
// phoneStr can be like:
// - 1 (555) 555-5555
// - +15555555555
// - 555-555-5555
// - (555) 555-5555
// etc.
export function validateAndFormatPhoneE164(phoneStr: string) {
  const phoneNumber = parsePhoneNumber(phoneStr, "US")

  if (!phoneNumber.isPossible()) {
    throw new Error("Number not possible")
  }

  // E164 phone numbers starting with +11 are invalid.
  // +1 is the country code, but if the resulting number starts with +11, that
  // means the phone number given was like:
  // 1 (555) 555-555
  // or:
  // (155) 555-5555
  // In both cases, this would be translated to:
  // +11555555555 (with separators to visualize: +1_155_555_5555)
  // To prevent users from entering in 9 digits accidentally, we reject these
  // numbers. Note that in the NANP, area codes cannot start with 0 or 1.
  if (phoneNumber.number.startsWith("+11")) {
    throw new Error("Number not invalid")
  }

  return phoneNumber.number
}

export function validateEmail(email: string) {
  return !!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.exec(email)
}

export function validateAndParseDate(dateString: string) {
  let date = null
  let hasYear = false
  try {
    // First we try to parse with no year. We have to use a reference date with a
    // leap year to support parsing 2/29. We are not keeping the year anyways, so this
    // is fine.
    date = parse(dateString, "M/d", new Date(2000, 1, 1))
  } catch (e) {}

  // If we can't parse with no year, we attempt with a year. isValid handles nulls
  if (!isValid(date)) {
    try {
      date = parse(dateString, "M/d/yyyy", new Date())
      hasYear = true
    } catch (e) {}
  }

  // Try iso8601
  if (!isValid(date)) {
    try {
      date = parseISO(dateString)
      hasYear = true
    } catch (e) {}
  }

  if (!date || !isValid(date)) {
    return null
  }

  return {
    day: date.getDate(),
    month: date.getMonth() + 1, // JS dates have zero indexed months 🙄
    year: hasYear ? date.getFullYear() : null,
  }
}

export function isDefined(str?: string | null): str is string {
  if (str) {
    return true
  }

  return false
}

export function isBlank(str?: string | null) {
  if (!isDefined(str) || str.length === 0 || str.trim().length === 0) {
    return true
  }

  return false
}

export function formatPrice(priceInCents: number, requireCents?: boolean) {
  // Is it a whole dollar amount?
  if (priceInCents % 100 === 0 && !requireCents) {
    return "$" + (priceInCents / 100).toLocaleString()
  }

  return (
    "$" +
    (priceInCents / 100).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  )
}

export function formatPriceInternational(params: {
  usdAmountInCents?: number | null
  nativeCurrency?: string
  nativeAmountInCents?: number
}) {
  const { usdAmountInCents, nativeCurrency, nativeAmountInCents } = params

  if (nativeCurrency && nativeAmountInCents) {
    return new Intl.NumberFormat(undefined, {
      style: "currency",
      currency: nativeCurrency,
      maximumFractionDigits: 0,
    }).format(nativeAmountInCents / 100)
  } else if (usdAmountInCents) {
    return formatPrice(usdAmountInCents)
  }
}

export function formatPriceOrNull(
  priceInCents: number | null,
  requireCents?: boolean,
) {
  // Is it a whole dollar amount?
  if (priceInCents === null) {
    return ""
  }

  return formatPrice(priceInCents, requireCents)
}

// Format a price by showing the minimum in the range. If there are higher
// prices (i.e. priceMax != priceMin), then show a + to indicate higher amounts
// are present in the range
export function formatPriceMinRange(
  priceMin: number | null,
  priceMax: number | null,
) {
  let price = null
  if (priceMin !== null && priceMax !== null) {
    const productPricesAreSame = priceMin === priceMax

    price = formatPrice(priceMin)
    if (!productPricesAreSame) {
      price += "+"
    }
  }

  return price
}

// Format a price range. $priceMin-$priceMax or $priceMin if they are the same
export function formatPriceRange(
  priceMin: number | null,
  priceMax: number | null,
) {
  let price = null
  if (priceMin !== null && priceMax !== null) {
    const productPricesAreSame = priceMin === priceMax

    price = formatPrice(priceMin)
    if (!productPricesAreSame) {
      price = `${formatPriceOrNull(priceMin)}–${formatPriceOrNull(priceMax)}`
    }
  }

  return price
}

export function formatVariablePricing(hasVariablePricing: boolean) {
  if (hasVariablePricing) {
    return "+"
  }

  return null
}

export function trimIfPossible(str?: string | null) {
  if (!str || str.length === 0) {
    return str
  }

  return str.trim()
}

export function joinWithCommaAnd(arr: string[]) {
  if (arr.length === 1) {
    return arr[0]
  } else {
    const arrCopy = [...arr]
    const firstEl = arrCopy.pop()
    return (
      arrCopy.join(", ") + (arrCopy.length > 1 ? ", and " : " and ") + firstEl
    )
  }
}

export function formatScheduledSendDate(dateString: string) {
  try {
    const date = new Date(dateString)

    const formattedDateString = date.toLocaleString("en-US", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    })

    let hours = date.getHours()
    const ampm = hours >= 12 ? "pm" : "am"
    hours = hours % 12
    hours = hours ? hours : 12 // the hour '0' should be '12'

    const timeZoneAbbr = date
      .toLocaleTimeString("en-us", { timeZoneName: "short" })
      .split(" ")[2]

    return formattedDateString + " at " + hours + ampm + " " + timeZoneAbbr
  } catch (e) {
    return ""
  }
}

export function formatScheduledSendDateShort(dateString: string) {
  try {
    const date = new Date(dateString)

    return date.toLocaleString("en-US", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    })
  } catch (e) {
    return ""
  }
}

export function formatPhoneIfPossible(str: string) {
  // Remove all non-numeric from str.
  const digitsOnly = str.replace(/\D/g, "")
  if (
    digitsOnly.length === 10 ||
    (digitsOnly.startsWith("1") && digitsOnly.length === 11)
  ) {
    return formatPhone(str).toString()
  } else {
    return str
  }
}

export function formatNumberWithMaxOneDecimalPoint(val: number) {
  // Show 1 decimal point when needed, but if it's .0, just return 0
  return parseFloat(val.toFixed(1))
}

export function parameterize(str: string) {
  return (
    str
      .toLowerCase()
      // Remove special chars
      .replace(/([^A-Z0-9 ])/gi, "")
      // Squish extra spaces
      .replace(/\s+/g, " ")
      .trim()
      // Replace spaces with underscore
      .replaceAll(" ", "_")
  )
}

// Convert an object to snake-cased keys. Does not recurse; only works on
// single-level objects. Arrays are not supported.
export function snakecaseKeys(obj: Record<string, any>) {
  return transform(obj, (acc: Record<string, any>, value, key, target) => {
    const snakeKey = snakeCase(key)
    acc[snakeKey] = value
  })
}

// Convert an ISO date string like 2021-01-01 to a Date object in the current
// time zone
export function getDateAsCurrentTimeZone(date: string) {
  return new Date(date + "T00:00:00")
}
