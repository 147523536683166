import { useQuery } from "@apollo/client"
import React, { useState } from "react"

import NavText from "./NavText"
import Tabs, { TabButton } from "./Tabs"
import { useGlobalState } from "../../common/GlobalState"
import { ReactComponent as UserIcon } from "../../common/images/user.svg"
import { ReactComponent as WorkspaceIcon } from "../../common/images/workspace.svg"
import Pagination from "../../common/pagination/Pagination"
import {
  GradientHeader,
  GradientHeaderTextMain,
  GradientHeaderTextSub,
} from "../../common/UI"
import {
  GIFT_BATCHES_QUERY,
  GiftBatchBox,
  GiftBatches,
  perPage,
} from "../Index"

import {
  Track_GiftBatchesQuery,
  Track_GiftBatchesQueryVariables,
} from "@/types/graphql-types"

interface WorkspaceBatchesProps {
  workspaceId: string
}
const WorkspaceBatches: React.FC<WorkspaceBatchesProps> = ({ workspaceId }) => {
  const [user] = useGlobalState("user")
  const [pageIndex, setPageIndex] = useState(0)
  const [viewMode, setViewMode] = useState<"MY_GIFTS" | "ALL_GIFTS">("MY_GIFTS")
  const { data, loading, refetch } = useQuery<
    Track_GiftBatchesQuery,
    Track_GiftBatchesQueryVariables
  >(GIFT_BATCHES_QUERY, {
    fetchPolicy: "network-only",
    context: { workspaceId }, // Makes sure the query is run whenever the workspace changes.
    variables: {
      page: pageIndex + 1,
      perPage: perPage,
      onlyMyGifts: viewMode === "MY_GIFTS",
    },
  })

  const giftBatchesCount = data?.workspace?.giftBatchesCount || 0
  const giftBatches = data?.workspace?.giftBatches || []

  const workspaceName = user?.workspaces.find(
    (workspace) => workspace.id === workspaceId,
  )?.name

  return (
    <>
      <GradientHeader tw="pb-20">
        <GradientHeaderTextMain>Gifts Sent</GradientHeaderTextMain>
        <GradientHeaderTextSub>{workspaceName}</GradientHeaderTextSub>
      </GradientHeader>
      <Tabs>
        <TabButton
          active={viewMode === "MY_GIFTS"}
          onClick={() => {
            setPageIndex(0)
            setViewMode("MY_GIFTS")
          }}
        >
          <UserIcon />
          <NavText>My Gifts</NavText>
        </TabButton>
        <TabButton
          active={viewMode === "ALL_GIFTS"}
          onClick={() => {
            setPageIndex(0)
            setViewMode("ALL_GIFTS")
          }}
        >
          <WorkspaceIcon />
          <NavText>All Gifts</NavText>
        </TabButton>
      </Tabs>
      <GiftBatchBox>
        <GiftBatches
          loading={loading}
          giftBatches={giftBatches}
          showUser={viewMode === "ALL_GIFTS"}
          refetch={refetch}
        />

        {!loading && (
          <Pagination
            {...{
              setPageIndex,
              perPage,
              totalCount: giftBatchesCount,
              pageIndex,
            }}
          />
        )}
      </GiftBatchBox>
    </>
  )
}

export default WorkspaceBatches
