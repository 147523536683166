import React from "react"
import tw from "twin.macro"

import { WorkspaceReward } from "../../assets/icons"

interface Props {
  onClick: () => void
}

const WorkspacePromoCodeButton: React.FC<Props> = ({ onClick }) => {
  return (
    <button
      tw="rounded-lg border transition-colors border-[#DBD2F5] hover:(border-[#C1ADF7]) active:(border-[#C1ADF7])"
      onClick={onClick}
    >
      <div tw="rounded-lg flex font-text bg-white">
        <IconBackground>
          <WorkspaceReward
            style={{
              filter: "drop-shadow(0px 2px 4px #E4D8F480)",
            }}
          />
        </IconBackground>
        <div tw="pl-3 py-2.5 pr-3 rounded-r-lg font-normal text-[#776AF5] flex gap-9 bg-white transition-colors active:(bg-primary-000)">
          <div tw="text-base leading-tight text-left">
            <span tw="font-semibold">Earn $50</span> for each workspace member
            you invite
          </div>
          <a
            tw="text-sm leading-tight opacity-70 self-center"
            href="/workspace-referral-terms"
            target="_blank"
            rel="noopener"
            onClick={(e) => e.stopPropagation()}
          >
            Terms
          </a>
        </div>
      </div>
    </button>
  )
}

const IconBackground = tw.div`
  flex items-center rounded-l-lg py-1 px-2
  bg-gradient-to-r from-[rgba(70, 99, 251, 0.09)] to-[rgba(177, 91, 230, 0.09)]
`

export default WorkspacePromoCodeButton
