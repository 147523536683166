import React, { useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import tw, { styled } from "twin.macro"

import { ConditionalLink } from "./components/ConditionalNavLink"
import goodyLogo from "./images/goody-logo.svg"
import SOCImage from "./images/soc.png"
import { generateRealmPath } from "./realm"
import { ReactComponent as SocialInstagram } from "../assets/icons/social-instagram.svg"
import { ReactComponent as SocialLinkedIn } from "../assets/icons/social-linkedin.svg"
import { ReactComponent as SocialTwitter } from "../assets/icons/social-twitter.svg"
import appQR from "../assets/images/app-qr.png"

const Footer: React.FC = () => {
  const { pathname } = useLocation()
  const [hidden, setHidden] = useState(false)
  const currentCalendarYear = new Date().getFullYear()

  useEffect(() => {
    setHidden(
      pathname.startsWith("/browse") ||
        pathname.startsWith("/plus/browse") ||
        pathname.startsWith("/business/browse") ||
        pathname.startsWith("/plus/store/custom/edit"),
    )
  }, [pathname])

  if (hidden) {
    return null
  }

  return (
    <footer tw="mt-24 px-3 lg:px-6 py-3 pt-6">
      <div tw="grid grid-cols-1 lg:grid-cols-5 text-center lg:text-left">
        <FooterColumn>
          <div tw="pb-6">
            <img src={goodyLogo} alt="Goody" tw="inline" />
          </div>
          <a
            href="https://instagram.com/ongoody"
            target="_blank"
            rel="noopener noreferrer"
            tw="inline-block mr-2"
          >
            <SocialInstagram />
          </a>
          <a
            href="https://twitter.com/goodygifting"
            target="_blank"
            rel="noopener noreferrer"
            tw="inline-block mr-2"
          >
            <SocialTwitter />
          </a>
          <a
            href="https://www.linkedin.com/company/ongoody"
            target="_blank"
            rel="noopener noreferrer"
            tw="inline-block mr-2"
          >
            <SocialLinkedIn />
          </a>
          <div tw="pt-4">
            <a
              href="https://www.aicpa.org/soc4so"
              rel="nofollow noopener noreferrer"
              target="_blank"
              tw="inline-block"
            >
              <img src={SOCImage} width={120} />
            </a>
          </div>
        </FooterColumn>
        <FooterColumn>
          <div tw="font-medium pb-6">
            <Link to={generateRealmPath("business", "/how-it-works")}>
              How It Works
            </Link>
          </div>
          <FooterLink to={generateRealmPath("business", "/how-it-works")}>
            Platform Overview
          </FooterLink>
          <FooterLink
            to={generateRealmPath("business", "/how-it-works/bulk-gifting")}
          >
            Bulk Gifting
          </FooterLink>
          <FooterLink
            to={generateRealmPath("business", "/how-it-works/branded-swag")}
          >
            Branded Swag
          </FooterLink>
          <FooterLink to={generateRealmPath("business", "/pricing")}>
            Pricing
          </FooterLink>
          <FooterLink to={generateRealmPath("business", "/gift-api")}>
            Gift API
          </FooterLink>
          <FooterLink to={generateRealmPath("plus", "/account/referrals")}>
            Get Free Credits
          </FooterLink>
        </FooterColumn>
        <FooterColumn>
          <div tw="font-medium pb-6">Use Cases</div>
          <FooterLink
            to={generateRealmPath(
              "business",
              "/use-cases/employee-recognition",
            )}
          >
            Employee Recognition
          </FooterLink>
          <FooterLink
            to={generateRealmPath("business", "/use-cases/client-appreciation")}
          >
            Client Appreciation
          </FooterLink>
          <FooterLink
            to={generateRealmPath("business", "/use-cases/sales-prospecting")}
          >
            Sales Prospecting
          </FooterLink>
          <FooterLink to={generateRealmPath("business", "/ea")}>
            EAs &amp; Admins
          </FooterLink>
          <FooterLink
            to={generateRealmPath("business", "/browse/category/branded-swag")}
          >
            Custom Swags
          </FooterLink>
          <FooterLink to={generateRealmPath("business", "/holiday-gifts")}>
            Holiday Gifts
          </FooterLink>
        </FooterColumn>
        <FooterColumn>
          <div tw="font-medium pb-6">About Goody</div>
          <FooterA href="https://jobs.polymer.co/goody" target="_blank">
            We’re Hiring
          </FooterA>
          <FooterLink to={generateRealmPath(null, "/blog")}>Blog</FooterLink>
          <FooterLink to={generateRealmPath(null, "/partner")}>
            Become a Brand Partner
          </FooterLink>
          <FooterA href={"https://intercom.help/goody/en/"}>Support</FooterA>
          <FooterLink to={generateRealmPath(null, "/security")}>
            Security
          </FooterLink>
          <FooterLink to={generateRealmPath(null, "/contact")}>
            Contact Us
          </FooterLink>
        </FooterColumn>
        <FooterColumn>
          <div tw="font-medium pb-6">Personal Gifts</div>
          <FooterLink to={generateRealmPath("consumer", "/")}>
            Send a Personal Gift
          </FooterLink>
          <img
            src={appQR}
            tw="hidden lg:inline mt-4"
            css={{ maxWidth: "160px" }}
            alt=""
          />
          <div tw="pt-4 text-sm text-gray-600 hidden lg:block">
            Scan to download the Goody app
          </div>
          <a
            href={"https://get.ongoody.com/landing"}
            tw={
              "lg:hidden text-lg bg-gradient-to-r from-gradient-purple-light to-gradient-purple-dark px-10 py-3 text-white font-medium rounded-lg hover:opacity-80 active:opacity-90 transition-opacity inline-block"
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            Download the Goody app
          </a>
        </FooterColumn>
      </div>
      <div tw="pt-8 pb-4 text-center lg:text-left">
        <span tw="text-gray-600 lg:pr-4">
          &copy; {currentCalendarYear} Goody Technologies, Inc.
        </span>
        <Link
          tw="text-gray-600 lg:pr-4 block lg:inline"
          to={generateRealmPath(null, "/terms")}
        >
          Terms of Use
        </Link>
        <Link
          tw="text-gray-600 lg:pr-4 block lg:inline"
          to={generateRealmPath(null, "/privacy")}
        >
          Privacy Policy
        </Link>
        <a
          tw="text-gray-600 lg:pr-4 block lg:inline"
          target="_blank"
          rel="noopener noreferrer"
          href={
            "https://www.ongoody.com/static/terms/PlusTermsAndConditions.pdf"
          }
        >
          Enterprise Service Agreement
        </a>
      </div>
    </footer>
  )
}

const FooterColumn = tw.section`pt-12 lg:pt-0`

const FooterLink = styled(ConditionalLink)`
  ${tw`block text-gray-700 hover:text-primary-500 transition-colors pb-1`}
`

const FooterA = styled.a`
  ${tw`block text-gray-700 hover:text-primary-500 transition-colors pb-1`}
`

export default Footer
