import pluralize from "pluralize"

import { formatPrice } from "../../common/format"
import { SubscriptionPlan } from "../useTeamsSubscriptionPlanData"

import { Subscribe_UserSubscriptionPriceEstimateMutation } from "@/types/graphql-types"

interface Props {
  additionalSeatCount: number
  additionalSeatPrice: number
  selectedPlan: SubscriptionPlan
  estimateData?: Subscribe_UserSubscriptionPriceEstimateMutation | null
  isTrial: boolean | null | undefined
}

export default function TeamSubscriptionPriceEstimate({
  additionalSeatCount,
  additionalSeatPrice,
  selectedPlan,
  estimateData,
  isTrial,
}: Props) {
  const estimatedTax =
    estimateData?.subscriptionPriceEstimate.estimatedTax !== undefined &&
    estimateData?.subscriptionPriceEstimate.estimatedTax !== null
      ? `${formatPrice(estimateData.subscriptionPriceEstimate.estimatedTax)}`
      : "—"

  const estimatedTotal =
    estimateData?.subscriptionPriceEstimate.estimatedTotal !== undefined
      ? `${formatPrice(estimateData.subscriptionPriceEstimate.estimatedTotal)}`
      : null

  const intervalSuffixText =
    selectedPlan.interval === "monthly" ? "month" : "year"

  const intervalSuffix = (
    <span tw="text-gray-500">
      <span tw="px-1">/</span>
      <span>{intervalSuffixText}</span>
    </span>
  )

  return (
    <div tw="pt-12 font-text">
      <div tw="flex flex-row justify-between items-start font-semibold text-gray-400 pb-2">
        <div>Your subscription{isTrial ? " after your trial" : null}</div>
      </div>
      <div tw="flex flex-row justify-between items-start pb-1">
        <div>{selectedPlan.name}</div>
        <div>
          {formatPrice(selectedPlan.price)}
          {intervalSuffix}
        </div>
      </div>
      {additionalSeatCount > 0 && (
        <div tw="flex flex-row justify-between items-start pb-1">
          <div>
            {additionalSeatCount} x Additional{" "}
            {pluralize("Seat", additionalSeatCount)}
          </div>
          <div>
            {formatPrice(additionalSeatPrice)}
            {intervalSuffix}
          </div>
        </div>
      )}
      <div tw="flex flex-row justify-between items-start pb-1">
        <div>Tax</div>
        <div>
          {estimatedTax}
          {intervalSuffix}
        </div>
      </div>
      <div tw="flex flex-row justify-between items-start pb-1">
        <div>Total</div>
        <div>
          {estimatedTotal}
          {intervalSuffix}
        </div>
      </div>
    </div>
  )
}
