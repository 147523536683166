import staticAssetUrl from "../../common/staticAssetUrl"

export default function Brands() {
  return (
    <div tw="pt-24">
      <div tw="text-3xl md:text-4xl font-reckless-neue font-250 text-center px-5">
        Access 300+ brands, curated with love
      </div>
      <div tw="pt-12 px-5">
        <img
          src={staticAssetUrl("static/web/landing/api/brands.svg")}
          tw="mx-auto max-w-full"
        />
      </div>
    </div>
  )
}
