import type { ReactNode, Ref } from "react"
import tw, { styled } from "twin.macro"

import { ReactComponent as Chevron } from "@/assets/icons/chevron-8px-container.svg"

interface Props {
  onClick: () => void
  filterIsActive?: boolean
  filterDropdownIsOpen?: boolean
  children?: ReactNode
  hideChevron?: boolean
  buttonRef?: Ref<HTMLButtonElement>
  disableClick?: boolean
}

// Base filter button used by all filters
export default function FilterButton({
  onClick,
  filterIsActive,
  filterDropdownIsOpen,
  children,
  hideChevron,
  buttonRef,
  disableClick,
}: Props) {
  return (
    <ButtonBase
      onClick={onClick}
      $filterIsActive={filterIsActive || filterDropdownIsOpen || false}
      $filterDropdownIsOpen={filterDropdownIsOpen || false}
      $hideChevron={hideChevron || false}
      ref={buttonRef}
      disabled={disableClick}
    >
      {children}
      {!hideChevron && (
        <Chevron
          className="chevron"
          tw="text-gray-450 group-hover:text-primary-new-500 pt-px"
        />
      )}
    </ButtonBase>
  )
}

const ButtonBase = styled.button.attrs({
  className: "filter-button group",
})<{
  $filterIsActive: boolean
  $filterDropdownIsOpen: boolean
  $hideChevron: boolean
}>`
  ${tw`
    border border-gray-200 rounded-full px-4 py-2 flex items-center justify-center text-gray-500 gap-3 transition-all
    hover:(border-primary-new-200 bg-primary-new-000 cursor-pointer text-primary-new-500)
    active:(bg-primary-new-050)
  `}

  // When chevron is shown, reduce right padding to adjust space
  ${({ $hideChevron }) => !$hideChevron && tw`pr-3`}
  
  &:disabled {
    ${tw`pointer-events-none`};
  }

  ${({ $filterIsActive }) =>
    $filterIsActive &&
    tw`
    border-primary-new-200 bg-primary-new-000 text-primary-new-500
  `}

  svg.chevron {
    ${tw`transition-all`}
    ${({ $filterDropdownIsOpen }) => $filterDropdownIsOpen && tw`rotate-180`}
    ${({ $filterIsActive }) => $filterIsActive && tw`text-primary-new-500`}
  }
`
