import { useMutation } from "@apollo/client"
import * as Sentry from "@sentry/react"
import { toast } from "react-hot-toast"
import tw, { styled } from "twin.macro"

import Button from "../../common/Button"
import { ReactComponent as PlusSvg } from "../../common/images/plus.svg"
import { validateIsAlphanumeric } from "../../common/validations"
import { WORKSPACE_CREATE } from "../../workspaces/NewWorkspace"

import { GET_WORKSPACES } from "./index"

import {
  Workspace_CreateMutation,
  Workspace_CreateMutationVariables,
} from "@/types/graphql-types"

const CreateWorkspace = ({ ...props }) => {
  const [createWorkspace] = useMutation<
    Workspace_CreateMutation,
    Workspace_CreateMutationVariables
  >(WORKSPACE_CREATE)

  const onClick = async () => {
    const newWorkspaceName = prompt("Name the new Workspace")

    if (newWorkspaceName === null) {
      return
    }

    if (!validateIsAlphanumeric(newWorkspaceName)) {
      toast.error(`Workspace name can only have letters and numbers.`)
      return
    }

    const { data } = await createWorkspace({
      variables: {
        name: newWorkspaceName,
      },
      refetchQueries: [{ query: GET_WORKSPACES }],
    })

    if (data?.workspaceCreate.id) {
      toast.success(`Workspace created.`, {
        iconTheme: {
          primary: "#27ae60",
          secondary: "#fff",
        },
      })
    } else {
      Sentry.captureException(
        new Error(
          `Workspace creation error. Errors: ${data?.workspaceCreate?.errors}`,
        ),
      )
      alert(
        "An error occurred when creating the Workspace. Try again or reach out to our team for help.",
      )
    }
  }

  return (
    <Button variant="primary" onClick={onClick} {...props}>
      <PlusIcon />
      New workspace
    </Button>
  )
}

const PlusIcon = styled(PlusSvg)`
  ${tw`h-4 w-4 text-white`}
`

export default CreateWorkspace
