import Badge, { Variant } from "../common/Badge"

import {
  OrganizationMembershipAccess,
  OrganizationMembershipRole,
  WorkspaceMembershipRole,
} from "@/types/graphql-types"

interface Props {
  access: OrganizationMembershipAccess
  role: OrganizationMembershipRole | WorkspaceMembershipRole
  isTeamPlan?: boolean
}

const RoleBadge = ({ access, role, isTeamPlan = false }: Props) => {
  let roleString: string = role.toString()

  if (
    isTeamPlan &&
    role !== OrganizationMembershipRole.admin &&
    role !== WorkspaceMembershipRole.MANAGER
  ) {
    roleString = `${access} member`
  }
  roleString = roleString.toLowerCase()

  return (
    <Badge
      variant={roleToBadgeVariant[roleString] || "blue"}
      tw="capitalize w-32 text-center"
    >
      {roleString}
    </Badge>
  )
}

const roleToBadgeVariant: { [key: string]: Variant } = {
  admin: "blue",
  member: "gray",
  "full member": "lightBlue",
  "limited member": "gray",
}

export default RoleBadge
