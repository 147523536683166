import React, { useEffect } from "react"
import { UseFormReturn } from "react-hook-form"
import tw from "twin.macro"

import BrowseStoreGiftSelectButton from "./components/BrowseStoreGiftSelectButton"
import GiftSelectedButtonRow from "./components/giftTemplates/GiftSelectButtonRow"
import GiftTemplateDropdown from "./components/giftTemplates/GiftTemplateDropdown"
import { GiftTemplateType } from "./components/giftTemplates/GiftTemplatesMenu"
import { ProductGift } from "./ProductGift"
import { CurrentGiftSendForm } from "./sendTypes"
import { track } from "../common/analytics"
import { useCurrentGift } from "../common/hooks/currentGift"
import { useGiftCart } from "../common/hooks/giftData"
import { useAnimatedDisplay } from "../common/hooks/useAnimatedDisplay"
import GiftOfChoiceGiftSelectButton from "../send/components/GiftOfChoiceGiftSelectButton"

import { BatchSendMethod } from "@/types/graphql-types"

interface GiftProps {
  currentGiftForm: UseFormReturn<CurrentGiftSendForm>
  sendV3?: boolean
}

const Gift = ({ currentGiftForm: { setValue }, sendV3 }: GiftProps) => {
  const [currentGift] = useCurrentGift()
  const products = currentGift.cart
  const { open, setOpen, displayed } = useAnimatedDisplay()
  const {
    setGiftTemplate: setGiftTemplateOnCart,
    resetGiftTemplate: resetGiftTemplateOnCart,
  } = useGiftCart()

  useEffect(() => {
    if (currentGift.sendMethod === BatchSendMethod.direct_send) {
      setOpen(false)
    }
  }, [currentGift.sendMethod])

  async function setGiftTemplate(giftTemplate: GiftTemplateType) {
    setValue("message", giftTemplate.message)
    setGiftTemplateOnCart(giftTemplate)

    track("Send - Set Gift Template", {
      giftTemplateId: giftTemplate.id,
      category: giftTemplate.category,
      subcategory: giftTemplate.subcategory,
      cardId: giftTemplate.card.id,
      message: giftTemplate.message,
      products: giftTemplate.products.map((product) => {
        return product.id
      }),
      giftCardAmount: giftTemplate.giftCardAmount,
      flexGiftPrice: giftTemplate.flexGiftPrice,
    })
  }

  async function setGiftMessage(message: string) {
    setValue("message", message)
  }

  async function resetGiftTemplate() {
    resetGiftTemplateOnCart(setGiftMessage)
  }

  const productSelected = products.length > 0

  return (
    <div
      tw="border border-primary-200 rounded-lg"
      css={[sendV3 && tw`border-gray-200`]}
    >
      {productSelected ? (
        <>
          <div tw="flex flex-row md:items-center md:pr-3">
            <div tw="px-5 pt-4 md:pt-1 font-semibold flex-1 text-gray-500">
              Gift
            </div>
            <GiftSelectedButtonRow
              displayed={displayed}
              setOpen={setOpen}
              open={open}
              resetGiftTemplate={resetGiftTemplate}
            />
          </div>
          <div tw="mt-2">
            <GiftTemplateDropdown
              open={open}
              setOpen={setOpen}
              setGiftTemplate={setGiftTemplate}
            />
          </div>
          <div tw="mx-4 mt-8 md:mt-4 mb-6 md:grid md:grid-cols-2 gap-6">
            {products.map((product) => (
              <ProductGift product={product} key={product.id} />
            ))}
          </div>
        </>
      ) : (
        <>
          <GiftSelectButtonContainer
            showSecondaryGiftSelectButton={
              currentGift.sendMethod !== BatchSendMethod.direct_send
            }
          />
          <div tw="mr-3 -mt-1">
            <GiftTemplateDropdown
              open={open}
              setOpen={setOpen}
              setGiftTemplate={setGiftTemplate}
            />
          </div>
        </>
      )}
    </div>
  )
}

interface GiftSelectButtonContainerProps {
  showSecondaryGiftSelectButton: boolean
}

const GiftSelectButtonContainer = ({
  showSecondaryGiftSelectButton,
}: GiftSelectButtonContainerProps) => {
  return (
    <div tw="flex flex-col p-3 pb-[15px]">
      <div tw="py-1 px-2 mb-2 font-semibold flex-1 text-gray-500">Gift</div>
      <div tw="flex flex-col md:flex-row justify-center gap-3">
        <BrowseStoreGiftSelectButton />
        {showSecondaryGiftSelectButton ? (
          <GiftOfChoiceGiftSelectButton />
        ) : (
          <div tw="flex-1" />
        )}
      </div>
    </div>
  )
}

export default Gift
