import { gql, useMutation } from "@apollo/client"
import { useState } from "react"
import { Redirect } from "react-router-dom"

import BackButton from "../../common/BackButton"
import Button from "../../common/Button"
import { useGlobalState } from "../../common/GlobalState"
import { generateRealmPath } from "../../common/realm"
import { successToast } from "../../common/toast"
import Container from "../../sites/App/Container"

import {
  AccessRequestCreateMutation,
  AccessRequestCreateMutationVariables,
} from "@/types/graphql-types"

const RequestDataAccess = () => {
  const [user] = useGlobalState("user")
  const [createDataAccessRequest] = useMutation<
    AccessRequestCreateMutation,
    AccessRequestCreateMutationVariables
  >(REQUEST_DATA_ACCESS_MUTATION)

  const [submitted, setSubmitted] = useState(false)

  if (!user) {
    return <Redirect to={generateRealmPath("business", "/signin")} />
  }

  const requestDataAccess = async () => {
    const response = await createDataAccessRequest()
    if (response.data?.accessRequestCreate?.error) {
      alert(response.data?.accessRequestCreate?.error)
    } else {
      setSubmitted(true)
      successToast("Data access request made successfully")
    }
  }

  return (
    <Container>
      <div tw="px-6 pt-12">
        <div tw="font-medium text-3xl">Request data</div>
        {submitted ? (
          <div tw="mt-9 mb-6">
            You will receive an email with your data once it has been processed.
          </div>
        ) : (
          <>
            <div tw="mt-9 mb-6">
              By requesting your data, you will receive an email with your data,
              such as your personal information, gifts created, and other
              information.
            </div>
            <div tw="flex flex-row gap-3">
              <Button onClick={requestDataAccess}>Request data access</Button>
              <BackButton />
            </div>
          </>
        )}
      </div>
    </Container>
  )
}

const REQUEST_DATA_ACCESS_MUTATION = gql`
  mutation AccessRequestCreate {
    accessRequestCreate {
      ok
      error
      id
    }
  }
`

export default RequestDataAccess
