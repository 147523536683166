import { Link } from "react-router-dom"
import tw, { css, styled } from "twin.macro"

const PurpleGradientBase = css`
  ${tw`inline-flex flex-col items-center rounded-lg py-4 px-8 text-white font-semibold hover:opacity-80 active:opacity-90 transition-all bg-gradient-to-r from-gradient-purple-light to-gradient-purple-dark active:scale-95 text-center disabled:opacity-50 disabled:pointer-events-none`};

  small {
    ${tw`font-normal text-sm opacity-70`}
  }
`

const PurpleGradientLink = styled(Link)`
  ${PurpleGradientBase}
`

export const PurpleGradientExternalLink = styled.a`
  ${PurpleGradientBase}
`

export const PurpleGradientButton = styled.button`
  ${PurpleGradientBase}
`

export const InactivePurpleGradientContainer = styled.div`
  ${tw`rounded-lg py-4 px-8 font-semibold text-gray-400 border border-gray-300 w-full pointer-events-none text-center`}
`

export default PurpleGradientLink
