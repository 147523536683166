import { ReactNode } from "react"
import InView from "react-intersection-observer"

import { Loader } from "../../common/UI"

interface InfiniteScrollProps {
  loadMore: () => void
  loading: boolean
  hasNextPage: boolean
  children: ReactNode
}

export const InfiniteScroll = ({
  loadMore,
  loading,
  hasNextPage,
  children,
}: InfiniteScrollProps) => (
  <>
    {children}
    {!loading && hasNextPage && (
      <InView
        // Add some invisible margin to the element so it is considered
        // in view before the user actually sees the loading state
        rootMargin="1000px"
        // This visibility sensor fires onChange when the component
        // appears scrolled in, even partially.
        onChange={(isVisible) => isVisible && loadMore()}
        tw="flex items-center justify-center"
      >
        <Loader tw="self-center place-self-center" />
      </InView>
    )}
  </>
)
