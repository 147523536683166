import SendV3 from "./SendV3"

import { useExperimentConfig } from "@/common/hooks/experimentConfig"
import { SendBusinessProviders } from "@/send/components/SendBusinessProviders"

export const SendBusiness = () => {
  const { loading, v2: startGiftV2 } = useExperimentConfig(
    "send_-_start_gift_v2",
  )

  if (loading) {
    return (
      <div tw="pt-24 pb-[100vh] text-gray-500 text-center">Loading&hellip;</div>
    )
  }

  return (
    <SendBusinessProviders>
      <SendV3 startGiftV2={startGiftV2} />
    </SendBusinessProviders>
  )
}
