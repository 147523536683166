import { useLazyQuery } from "@apollo/client"
import { saveAs } from "file-saver"
import Papa from "papaparse"
import React, { useEffect, useState } from "react"

import { OutlinedButton } from "./components/common"
import { GIFT_BATCH_RECIPIENTS_QUERY } from "./queries"
import { giftStatus } from "./Recipients"
import { useCurrentWorkspace } from "../common/hooks"

import {
  GiftSeriesFilter,
  TrackGiftBatchRecipientRequestItemFragment,
  Track_GiftBatchRecipientsQuery,
  Track_GiftBatchRecipientsQueryVariables,
} from "@/types/graphql-types"

interface RecipientCSVDownloadProps {
  giftBatchId: string
  filter: GiftSeriesFilter
}

const RecipientCSVDownload = ({
  giftBatchId,
  filter,
}: RecipientCSVDownloadProps) => {
  const [recipients, setRecipients] = useState<
    TrackGiftBatchRecipientRequestItemFragment[]
  >([])
  const [page, setPage] = useState(1)
  const [allFetched, setAllFetched] = useState(false)
  const { currentWorkspace } = useCurrentWorkspace()

  const variables = {
    id: giftBatchId,
    page,
    filter,
  }

  const [getRecipients, { refetch }] = useLazyQuery<
    Track_GiftBatchRecipientsQuery,
    Track_GiftBatchRecipientsQueryVariables
  >(GIFT_BATCH_RECIPIENTS_QUERY, {
    context: {
      workspaceId: currentWorkspace?.id,
    },
    onCompleted: (data) => {
      const newRecipients =
        data.workspace?.giftBatch?.recipientSeries?.items || []
      if (newRecipients.length > 0) {
        setRecipients((prev) => [...prev, ...newRecipients])
        setPage(page + 1)
      } else {
        setAllFetched(true)
      }
    },
  })

  useEffect(() => {
    if (page > 1) {
      refetch(variables)
    }
  }, [page])

  useEffect(() => {
    if (allFetched) {
      downloadCSV()
      setPage(1)
      setRecipients([])
      setAllFetched(false)
    }
  }, [allFetched])

  const downloadCSV = () => {
    const csv = Papa.unparse({
      fields: [
        "First Name",
        "Last Name",
        "Email",
        "Phone",
        "Status",
        "Gift Link",
        "Gift ID",
      ],
      data: recipients.map((r) => [
        r.firstName,
        r.lastName,
        r.email,
        r.phone,
        giftStatus(r),
        r.gift?.sharedLink,
        r.gift?.id,
      ]),
    })

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8" })
    saveAs(blob, "goody_recipients.csv")
  }

  return (
    <OutlinedButton onClick={() => getRecipients({ variables })}>
      Download recipients
    </OutlinedButton>
  )
}

export default RecipientCSVDownload
