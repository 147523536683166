import styled from "@emotion/styled"
import React, { FC, useEffect, useRef } from "react"
import tw from "twin.macro"

import HomeIconGray from "@/assets/icons/home-gray.svg"
import HomeIconWhite from "@/assets/icons/home-white.svg"
import { useBrowseUrls } from "@/store/StoreHelpers"
import { Store_CategoryCommonFragment } from "@/types/graphql-types"

interface Props {
  categorySlug: string | null
  onSubcategoryClick: (subcategory: Store_CategoryCommonFragment | null) => void
  selectedSubcategorySlug: string | null
  subcategories: Store_CategoryCommonFragment[]
}

const SubcategoriesMobileList: FC<Props> = ({
  categorySlug,
  onSubcategoryClick,
  selectedSubcategorySlug,
  subcategories,
}) => {
  if (subcategories.length === 0) {
    return null
  }

  return (
    <SubcategoryContainer tw="flex mb-8 px-2 lg:hidden overflow-x-scroll">
      <HomeSubcategory
        categorySlug={categorySlug}
        selectedSubcategorySlug={selectedSubcategorySlug}
        onSubcategoryClick={onSubcategoryClick}
      />
      {subcategories.map((subcategory) => (
        <Subcategory
          subcategory={subcategory}
          selectedSubcategorySlug={selectedSubcategorySlug}
          onSubcategoryClick={onSubcategoryClick}
          key={subcategory.id}
        />
      ))}
    </SubcategoryContainer>
  )
}

interface HomeSubcategoryProps {
  categorySlug: string | null
  selectedSubcategorySlug: string | null
  onSubcategoryClick: (subcategory: Store_CategoryCommonFragment | null) => void
}

const HomeSubcategory: FC<HomeSubcategoryProps> = ({
  categorySlug,
  onSubcategoryClick,
  selectedSubcategorySlug,
}) => {
  const { generateBrowseUrl } = useBrowseUrls()

  const path = generateBrowseUrl({
    giftOptionSlug: null,
    categorySlug,
  })

  const onClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    onSubcategoryClick(null)
  }

  const isSelected = !selectedSubcategorySlug

  const iconSrc = isSelected ? HomeIconWhite : HomeIconGray

  return (
    <StyledLink tw="flex" isSelected={isSelected} onClick={onClick} href={path}>
      <img src={iconSrc} alt="Home" />
    </StyledLink>
  )
}

interface SubcategoryProps {
  onSubcategoryClick: (subcategory: Store_CategoryCommonFragment | null) => void
  selectedSubcategorySlug: string | null
  subcategory: Store_CategoryCommonFragment
}

const Subcategory: FC<SubcategoryProps> = ({
  onSubcategoryClick,
  selectedSubcategorySlug,
  subcategory,
}) => {
  const { generateBrowseUrl } = useBrowseUrls()

  const isSelected = subcategory.slug === selectedSubcategorySlug
  const linkRef = useRef<HTMLAnchorElement>(null)

  useEffect(() => {
    if (isSelected) {
      linkRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "nearest",
      })
    }
  }, [isSelected])

  const path = generateBrowseUrl({
    giftOptionSlug: null,
    categorySlug: subcategory.slug,
  })

  const onClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    onSubcategoryClick(subcategory)
  }

  return (
    <StyledLink
      ref={linkRef}
      onClick={onClick}
      isSelected={isSelected}
      href={path}
    >
      {subcategory.name}
    </StyledLink>
  )
}

const SubcategoryContainer = styled.div`
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`

const StyledLink = styled.a<{ isSelected: boolean }>`
  ${tw`mx-1 py-1 px-4 rounded-3xl text-gray-600 flex-shrink-0`}
  border: 1px solid #DCDFE4;
  scroll-margin: 0 2rem;

  ${({ isSelected }) =>
    isSelected && tw`bg-primary-600 text-white border-primary-600`}
`

export default SubcategoriesMobileList
