import { gql } from "@apollo/client"
import tw, { styled } from "twin.macro"

import TransactionCreatedBy from "./TransactionCreatedBy"
import TransactionDetails from "./TransactionDetails"
import { formatPrice } from "../../common/format"
import Pagination from "../../common/pagination/Pagination"
import RoundedTable from "../../common/RoundedTable"
import { titleCase } from "../../common/utilities"
import Container from "../../sites/App/Container"

import { BalanceTransactionFragment } from "@/types/graphql-types"

interface Props {
  page: number
  totalCount?: number | null
  onChangePage: (page: number) => void
  transactions?: BalanceTransactionFragment[]
  compact?: boolean
  preventScrollToTopOnPageChange?: boolean
  memberName?: string | null
}

const ActionToText = (action: string) => {
  switch (action) {
    case "swap_difference_award":
    case "swap_difference_refund":
      return "Swap Difference"
    default:
      return titleCase(action).replace("Post Accept", "Post-Accept")
  }
}

export default function BalanceTransactions({
  page,
  totalCount,
  onChangePage,
  transactions,
  compact,
  preventScrollToTopOnPageChange,
  memberName,
}: Props) {
  return (
    <div>
      <Container>
        <Table className={compact ? "compact" : ""}>
          <thead>
            <tr>
              <th>Date</th>
              <th>Details</th>
              <th tw="text-right">Amount</th>
              <th tw="text-right">Ending{!compact && " Amount"}</th>
            </tr>
          </thead>
          <tbody>
            {transactions?.length === 0 ? (
              <tr>
                <td tw="text-center text-gray-500 col-span-full">
                  No transactions
                </td>
              </tr>
            ) : null}
            {transactions?.map((item) => (
              <tr key={item.id}>
                <td>
                  <div tw="text-gray-450 text-sm" className="group">
                    {new Date(item.createdAt).toLocaleDateString()}
                    <span tw="text-gray-400 opacity-0 group-hover:opacity-100 transition-opacity pl-2">
                      {new Date(item.createdAt).toLocaleTimeString()}
                    </span>
                  </div>
                  <div tw="font-medium pb-1">{ActionToText(item.action)}</div>
                  <TransactionCreatedBy item={item} memberName={memberName} />
                </td>
                <td>
                  <TransactionDetails item={item} />
                </td>
                <td
                  tw="text-right tabular-nums"
                  css={[item.amountDiff > 0 && tw`text-green-600`]}
                >
                  {item.amountDiff > 0 ? "+ " : ""}
                  {formatPrice(item.amountDiff, true).replace("$-", "– $")}
                </td>
                <td tw="text-right tabular-nums">
                  {formatPrice(item.amountAfter, true)}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div tw="pt-8">
          {totalCount !== undefined && totalCount !== null && (
            <Pagination
              pageIndex={page}
              perPage={10}
              totalCount={totalCount}
              setPageIndex={onChangePage}
              preventScrollToTop={preventScrollToTopOnPageChange}
            />
          )}
        </div>
      </Container>
    </div>
  )
}

const Table = styled(RoundedTable)`
  tr {
    ${tw`transition-colors block lg:grid mb-14 lg:mb-0 rounded-lg lg:rounded-none shadow-1.5xl lg:shadow-none p-5 lg:p-0`}
    grid-template-columns: 30% 40% 15% 15%;
  }

  &.compact {
    ${tw`border-0`};
  }

  thead {
    ${tw`hidden lg:block`}
  }

  tbody tr:hover {
    ${tw`lg:bg-gray-50`}
  }

  tbody td {
    ${tw`py-0 lg:py-6 px-4`}
  }

  thead th {
    ${tw`px-4`}
  }

  &.compact {
    tbody td {
      ${tw`px-6`}
    }

    thead th {
      ${tw`px-6`}
    }
  }
`

export const BALANCE_TRANSACTIONS_FRAGMENT = gql`
  fragment BalanceTransaction on BalanceTransaction {
    id
    publicId
    ttype
    action
    createdAt
    amountDiff
    amountAfter
    createdByUser
    gift {
      id
      recipientFirstName
      recipientLastName
      senderName
      productFragment {
        name
      }
    }
    giftBatch {
      id
      batchName
      productFragment {
        name
      }
    }
  }
`
