import React from "react"
import tw, { styled } from "twin.macro"

import { DispenserMinus, DispenserPlus } from "@/assets/icons"
import {
  SelectionGroup,
  SelectionIndicator,
  SelectionLabel,
} from "@/common/SelectionGroup"

const MAX_SMART_LINK_QUANTITY = 1000

interface Props {
  smartLinkQuantity: number | null
  setSmartLinkQuantity: (quantity: number | null) => void
  decrementSmartLinkQuantity: () => void
  incrementSmartLinkQuantity: () => void
  smartLinkApprovalRequired: boolean
  setsmartLinkApprovalRequired: (approval: boolean) => void
}

const SmartLink = ({
  smartLinkQuantity,
  setSmartLinkQuantity,
  decrementSmartLinkQuantity,
  incrementSmartLinkQuantity,
  smartLinkApprovalRequired,
  setsmartLinkApprovalRequired,
}: Props) => {
  return (
    <div
      tw={
        "grid grid-cols-[auto 1fr] lg:border-none border border-gray-200 rounded-lg lg:rounded-none"
      }
    >
      <SmartLinkCellLabel>Maximum Gifts</SmartLinkCellLabel>
      <SmartLinkCell>
        <div tw="flex flex-row items-center">
          <AddSubtractButton
            onClick={() => decrementSmartLinkQuantity()}
            disabled={!smartLinkQuantity || smartLinkQuantity <= 1}
          >
            <DispenserMinus tw="h-9 w-9 text-primary-500" />
          </AddSubtractButton>
          <div tw="w-28 px-4 text-center">
            <NumInput
              type="number"
              onChange={(e) => {
                // When user sets this to empty, set null quantity.
                if (e.target.value === "") {
                  setSmartLinkQuantity(null)
                  return
                }

                const val = parseInt(e.target.value)
                if (val) {
                  // TODO: constant
                  setSmartLinkQuantity(Math.min(MAX_SMART_LINK_QUANTITY, val))
                }
              }}
              value={smartLinkQuantity === null ? "" : smartLinkQuantity}
              min={1}
              max={1000}
            />
          </div>
          <AddSubtractButton
            onClick={() => incrementSmartLinkQuantity()}
            disabled={
              !smartLinkQuantity || smartLinkQuantity >= MAX_SMART_LINK_QUANTITY
            }
          >
            <DispenserPlus tw="h-9 w-9 text-primary-500" />
          </AddSubtractButton>
        </div>
        <div tw={"mt-3 text-gray-500"}>Limit the number of accepted gifts.</div>
      </SmartLinkCell>
      <SmartLinkCellLabel>Approval</SmartLinkCellLabel>
      <SmartLinkCell>
        <SelectionGroup
          tw={"items-start"}
          onClick={() => setsmartLinkApprovalRequired(true)}
        >
          <SelectionIndicator selected={smartLinkApprovalRequired} />
          <SelectionLabel selected={smartLinkApprovalRequired}>
            <div tw={"pt-1"}>Require approval</div>
            <div tw="text-gray-400 pt-0.5">
              Manually approve each gift before it is processed. You’ll be
              notified via email.
            </div>
          </SelectionLabel>
        </SelectionGroup>
        <div tw={"h-5"} />
        <SelectionGroup
          onClick={() => setsmartLinkApprovalRequired(false)}
          tw="items-start"
        >
          <SelectionIndicator selected={!smartLinkApprovalRequired} />
          <SelectionLabel selected={!smartLinkApprovalRequired}>
            <div tw={"pt-1"}>No approval</div>
            <div tw="text-gray-400 pt-0.5">
              Process gifts immediately after recipients accept them.
            </div>
          </SelectionLabel>
        </SelectionGroup>
      </SmartLinkCell>
    </div>
  )
}

const SmartLinkCell = styled.div`
  ${tw`p-8 flex flex-col`}
`

const SmartLinkCellLabel = styled(SmartLinkCell)`
  ${tw`font-medium`}
`

const AddSubtractButton = tw.button`rounded-full bg-gray-100 hover:bg-gray-150 active:bg-gray-200 disabled:pointer-events-none disabled:opacity-50`

const NumInput = styled.input`
  ${tw`w-full focus:outline-none text-2xl px-2 py-2 text-center border border-gray-200 focus:border-primary-300 transition-colors rounded-lg`}

  // Disable spinner in input type="number".
  &[type="number"] {
    -moz-appearance: textfield;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`

export default SmartLink
