import PaymentForm from "./components/PaymentForm"
import PaymentHeader from "./components/PaymentHeader"
import PaymentSelector from "./components/PaymentSelector"
import { AddPaymentMethodFormFields } from "../../common/billing/useAddPaymentMethod"

interface Props {
  onBack: () => void
  formFields: AddPaymentMethodFormFields
  setFormField: (name: string, value: string) => void
  setFormFields: (fields: AddPaymentMethodFormFields) => void
  setPageReady: () => void
  reset: () => void
}

export default function SendPagePayment({
  onBack,
  formFields,
  setFormField,
  setFormFields,
  setPageReady,
  reset,
}: Props) {
  return (
    <div tw="pt-16">
      <PaymentHeader onBack={onBack} />
      <PaymentSelector />
      <PaymentForm
        formFields={formFields}
        setFormField={setFormField}
        setFormFields={setFormFields}
        setPageReady={setPageReady}
        reset={reset}
      />
    </div>
  )
}
