import { useState } from "react"
import Modal from "react-modal"

import { useAuth } from "./hooks"
import { modalStyle } from "./modal"

import { App_RootDataQuery } from "@/types/graphql-types"

interface Props {
  isOpen: boolean
  setClosed: () => void
  onSuccess?: (me: App_RootDataQuery["me"] | null) => void
  email?: string
  phone: string
  firstName?: string
  lastName?: string
}

const VerifyModal = ({
  isOpen,
  setClosed,
  email,
  phone,
  firstName,
  lastName,
  onSuccess,
}: Props) => {
  const [verifyCode, setVerifyCode] = useState("")
  const { handleVerify } = useAuth()

  return (
    <Modal
      isOpen={isOpen}
      closeTimeoutMS={500}
      onRequestClose={setClosed}
      onAfterClose={() => {}}
      shouldCloseOnOverlayClick={true}
      style={modalStyle}
    >
      <div className="modal-content">
        <div tw="p-6">
          <div tw="pb-2 text-center text-xl font-medium text-primary-500">
            Verify code
          </div>
          <div tw="pb-6 text-gray-400 text-center">
            Enter the 6-digit code sent to your phone.
          </div>
          <input
            type="text"
            value={verifyCode}
            inputMode={"numeric"}
            autoComplete={"one-time-code"}
            onChange={(e) => {
              const code = e.target.value
              setVerifyCode(code)
              if (e.target.value.length === 6) {
                setVerifyCode("")
                handleVerify({
                  code,
                  email,
                  phone,
                  firstName,
                  lastName,
                  onSuccess: (me) => {
                    setClosed()
                    onSuccess?.call(null, me)
                  },
                })
              }
            }}
            placeholder="000000"
            tw="block w-full text-center text-3xl border border-gray-200 py-4 focus:ring-4 focus:outline-none"
            autoFocus={true}
            className="data-hj-suppress ph-no-capture fs-exclude"
          />
        </div>
      </div>
    </Modal>
  )
}

export default VerifyModal
