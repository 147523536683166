import { isNil } from "lodash-es"
import React from "react"
import { Link } from "react-router-dom"
import tw, { styled } from "twin.macro"

import { useCartDrawer } from "../common/contexts/cartDrawer"
import { formatPrice } from "../common/format"
import { getProductName, getProductSubtitle } from "../common/gifts"
import { Product } from "../common/GlobalState"
import { summarizeVariants } from "../common/utilities"
import { useBrowseUrls } from "../store/StoreHelpers"

import { CartProductImage } from "@/common/cart/CartProductImage"

interface ProductGiftProps {
  product: Product
  preventClick?: boolean
  productPrice?: number | null
  clickOpensCart?: boolean
}
export const ProductGift: React.FC<ProductGiftProps> = ({
  product,
  preventClick,
  productPrice,
  clickOpensCart,
}) => {
  const { generateProductUrl } = useBrowseUrls()
  const { openCart } = useCartDrawer()

  return (
    <Link
      tw="flex flex-row flex-1 items-center mb-4 md:mb-0"
      css={[preventClick && tw`pointer-events-none cursor-default`]}
      to={generateProductUrl(product)}
      onClick={(e) => {
        if (clickOpensCart) {
          e.preventDefault()
          openCart()
        }
      }}
    >
      <div tw={"relative w-16 h-16 rounded-lg"}>
        <CartProductImage product={product} tw="w-16 h-16 rounded-lg" />
        {product.quantity > 1 && <QuantityTag>{product.quantity}</QuantityTag>}
      </div>
      <div tw="ml-5 flex-1 flex flex-row items-center justify-between gap-3">
        <div tw="flex-1 flex flex-col justify-center">
          <div tw="text-gray-450">{getProductName(product)}</div>
          <div>{getProductSubtitle(product)}</div>
          {product.variants && (
            <div tw="pt-1 text-sm text-gray-600">
              {summarizeVariants(product.variants)}
            </div>
          )}
        </div>
        {!isNil(productPrice) && <div>{formatPrice(productPrice)}</div>}
      </div>
    </Link>
  )
}

const QuantityTag = styled.div`
  ${tw`w-5 h-5 rounded-full absolute bg-white text-sm text-gray-700 top-1 right-1 flex justify-center items-center`}
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
`
