import Image from "@/common/Image"
import { PaymentMethod } from "@/common/PaymentMethodsManager"
import { cardImage } from "@/common/payments/PaymentMethod"
import { isBlank } from "@/common/utilities"

interface Props {
  paymentMethod: PaymentMethod
  displayLabel?: boolean
}

export const MiniPaymentMethodLabel = ({
  paymentMethod,
  displayLabel,
}: Props) => {
  return (
    <div tw="flex flex-row items-center">
      <div tw="mr-2 min-w-[32px]">
        <Image src={cardImage(paymentMethod.cardBrand)} tw="w-8 h-5" />
      </div>
      {displayLabel && !isBlank(paymentMethod.label) && (
        <div tw="mr-1 text-left font-medium">
          {paymentMethod.label?.substring(0, 14)}
          {paymentMethod.label?.length && paymentMethod.label.length > 14
            ? "..."
            : ""}
        </div>
      )}
      <div tw="mr-2 text-left text-gray-600">{paymentMethod.last4}</div>
    </div>
  )
}
