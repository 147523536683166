import React from "react"
import { Link } from "react-router-dom"
import tw, { styled } from "twin.macro"

import { useCartDrawer } from "../../../common/contexts/cartDrawer"
import { buttonStyle } from "../../../common/GradientButton"
import { useCurrentGift } from "../../../common/hooks/currentGift"
import { generateRealmPath } from "../../../common/realm"
import { ReactComponent as Undo } from "../../images/giftTemplates/undo.svg"
import { ReactComponent as Bag } from "../../images/shopping-bag.svg"

interface GiftSelectedButtonRowProps {
  displayed: boolean
  setOpen: (open: boolean) => void
  open: boolean
  resetGiftTemplate: () => void
}

const GiftSelectedButtonRow = ({
  displayed,
  setOpen,
  open,
  resetGiftTemplate,
}: GiftSelectedButtonRowProps) => {
  const { openCart } = useCartDrawer()
  const [currentGift] = useCurrentGift()

  function handleGiftTemplateReset() {
    if (window.confirm("Are you sure you want to reset your gift template?")) {
      resetGiftTemplate()
    }
  }

  return (
    <div tw="mt-3 px-1 md:px-0 flex flex-col md:flex-row items-center">
      {currentGift.giftTemplate && (
        <div
          tw="hidden md:flex flex-row items-center mr-3 cursor-pointer text-gray-400 rounded-lg p-2 h-[36px] hover:text-gray-500 hover:bg-gray-100 active:scale-95"
          onClick={handleGiftTemplateReset}
        >
          <Undo tw="mr-2" />
          <div>Reset Template</div>
        </div>
      )}
      <div tw="flex flex-row">
        <ChangeButton
          tw="mr-2 md:mr-3"
          css={[(open || displayed) && tw`z-[1020]`, open && tw`shadow-md`]}
          to="#"
          onClick={() => setOpen(!open)}
        >
          Templates
        </ChangeButton>
        <ChangeButton
          tw="mr-2 md:mr-3"
          to={generateRealmPath("plus", "/browse")}
        >
          Browse Store
        </ChangeButton>
        <ChangeButton tw="hidden md:flex" to="#" onClick={openCart}>
          <div tw="flex flex-row items-center">
            <Bag tw="mr-1" />
            <div>Bag</div>
          </div>
        </ChangeButton>
      </div>
    </div>
  )
}

export const ChangeButton = styled(Link)`
  ${buttonStyle}
  ${tw`bg-white border border-gray-200 rounded-lg text-base text-gray-600 font-normal cursor-pointer h-[36px] px-3 py-2 hover:z-[2000]`}
  box-shadow: 0px 2px 4px rgba(229, 231, 235, 0.3);
`

export default GiftSelectedButtonRow
