import React from "react"
import { UseFormReturn, useWatch } from "react-hook-form"
import tw from "twin.macro"

import { CurrentGiftSendForm } from "./sendTypes"
import MessageTextAreaWithPersonalizationTags from "../common/MessageTextAreaWithPersonalizationTags"

interface CardMessageProps {
  currentGiftForm: UseFormReturn<CurrentGiftSendForm>
  sendV3?: boolean
  hidePersonalizationTags?: boolean
  onChange: () => void
}

const CardMessage = ({
  currentGiftForm: { register, setValue, control },
  sendV3,
  hidePersonalizationTags,
  onChange,
}: CardMessageProps) => {
  const messageValue = useWatch({ control, name: "message" })
  const { onChange: currentGiftFormOnChange, ...inputProps } =
    register("message")

  return (
    <div tw="px-5 py-3 border-b lg:border-none" css={[sendV3 && tw`pb-6`]}>
      <div tw="text-sm font-semibold pb-1 text-gray-500">Message</div>
      <MessageTextAreaWithPersonalizationTags
        value={messageValue}
        inputProps={{
          onChange: (e) => {
            onChange()
            return currentGiftFormOnChange(e)
          },
          ...inputProps,
        }}
        setMessage={(message) => setValue("message", message)}
        textareaRows={sendV3 ? 4 : 5}
        hidePersonalizationTags={hidePersonalizationTags}
      />
    </div>
  )
}

export default CardMessage
