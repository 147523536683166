import { gql } from "@apollo/client"
import pluralize from "pluralize"
import React, { useState } from "react"
import { Helmet } from "react-helmet-async"
import Modal from "react-modal"
import { useHistory } from "react-router-dom"
import TimeAgo from "react-timeago"
import tw, { styled } from "twin.macro"

import GiftBatchEditModal from "./components/GiftBatchEditModal"
import GiftBatchErrorMessage from "./components/GiftBatchErrorMessage"
import IndividualBatches from "./components/IndividualBatches"
import ScheduledSendMessage from "./components/ScheduledSendMessage"
import WorkspaceBatches from "./components/WorkspaceBatches"
import { ReactComponent as NoteIcon } from "../assets/icons/note.svg"
import { getCartProductSubtitle } from "../common/cartProducts"
import { QuantityTagSmall } from "../common/components/QuantityTagSmall"
import { formatScheduledSendDate, isBlank } from "../common/format"
import { useCurrentWorkspace } from "../common/hooks"
import { CART_PRODUCT_SHIPMENT_FRAGMENT } from "../common/hooks/gifts"
import { modalStyle } from "../common/modal"
import { generateRealmPath } from "../common/realm"
import SmallButton from "../common/SmallButton"
import {
  Loader,
  RoundedBox,
  RoundedBoxLink,
  RoundedBoxLinkColorModifier,
  TrackStandardBox,
} from "../common/UI"
import { timeAgoFormatterWithDate } from "../common/utilities"
import { isIndividual } from "../common/workspaces"

import { getRecipientCount } from "@/common/utils/giftBatches"
import { IMAGE_FRAGMENT } from "@/graphql"
import { CartProductProductImage } from "@/track/components/CartProductProductImage"
import { GiftBatchSendStatus } from "@/types/graphql-types"
import {
  CartProductShipmentFragment,
  ImageFragment,
  Track_GiftBatchesQuery,
} from "@/types/graphql-types"

export const perPage = 30

const Index: React.FC = () => {
  const { currentWorkspace } = useCurrentWorkspace()

  if (!currentWorkspace) {
    return null
  }

  return (
    <>
      <Helmet>
        <title>Gifts Sent – Goody for Business</title>
      </Helmet>
      {isIndividual(currentWorkspace.ttype) ? (
        <IndividualBatches />
      ) : (
        <WorkspaceBatches workspaceId={currentWorkspace.id} />
      )}
    </>
  )
}

export const GiftBatchBox: React.FC = ({ children }) => (
  <div tw="container mx-auto">
    <TrackStandardBox tw="mt-20">{children}</TrackStandardBox>
  </div>
)

type GiftBatch = NonNullable<
  Track_GiftBatchesQuery["workspace"]
>["giftBatches"][0]

interface GiftBatchesProps {
  giftBatches: GiftBatch[]
  loading: boolean
  showUser: boolean
  refetch: () => void
}

export interface GiftBatchEditModalData {
  id: string
  batchName?: string | null
  customEmailSubject?: string | null
  batchNameFallback: string
  scheduledSendOn: any
  createdAt: any
}

export const GiftBatches: React.FC<GiftBatchesProps> = ({
  loading,
  giftBatches,
  showUser,
}) => {
  const history = useHistory()
  const [giftBatchEditModalOpen, setGiftBatchEditModalOpen] =
    useState<boolean>(false)

  // State used when clicking on a gift batch to open edit modal (for scheduled sends)
  const [currentEditingGiftBatch, setCurrentEditingGiftBatch] =
    useState<GiftBatchEditModalData | null>()

  const giftBatchName = (
    batchName: string | null,
    batchNameFallback: string,
  ) => {
    if (isBlank(batchName) || batchName === null) {
      return batchNameFallback
    } else {
      return batchName
    }
  }

  const acceptedPercentage = ({ statistics }: GiftBatch) => {
    const accepted = statistics.summaryAccepted
    const waiting = statistics.summaryWaiting
    const total = accepted + waiting

    if (total === 0) {
      return 0
    }

    return Math.round((accepted / total) * 100)
  }

  const waitingPercentage = ({ statistics }: GiftBatch) => {
    const accepted = statistics.summaryAccepted
    const waiting = statistics.summaryWaiting
    const total = accepted + waiting

    if (total === 0) {
      return 0
    }

    return Math.round((waiting / total) * 100)
  }

  const closeGiftBatchEditModal = () => {
    setGiftBatchEditModalOpen(false)
  }

  const handleGiftBatchEditRoute = (giftBatch: GiftBatch) => {
    if (giftBatch.scheduledSendOn) {
      if (giftBatch.sendStatus === "COMPLETE") {
        return generateRealmPath("plus", `/track/${giftBatch.id}`)
      } else {
        return "#"
      }
    } else {
      return generateRealmPath("plus", `/track/${giftBatch.id}`)
    }
  }

  if (loading) {
    return (
      <RoundedBox tw="py-24 flex flex-row items-center justify-center">
        <Loader />
      </RoundedBox>
    )
  }

  if (giftBatches.length === 0) {
    return (
      <RoundedBox tw="p-6 py-24 text-center">
        <div tw="pb-8 text-xl text-gray-500">No gifts sent yet.</div>
        <SmallButton
          onClick={() => {
            history.push(generateRealmPath("plus", "/send"))
          }}
          label={"Send a gift"}
        />
      </RoundedBox>
    )
  }

  return (
    <div>
      {loading && (
        <RoundedBox tw="py-24 flex flex-row items-center justify-center">
          <Loader />
        </RoundedBox>
      )}
      {giftBatches.length === 0 && (
        <RoundedBox tw="p-6 py-24 text-center">
          <div tw="pb-8 text-xl text-gray-500">No gifts sent yet.</div>
          <SmallButton
            onClick={() => {
              history.push(generateRealmPath("plus", "/send"))
            }}
            label={"Send a gift"}
          />
        </RoundedBox>
      )}
      {giftBatches.map((giftBatch) => (
        <div key={giftBatch.id}>
          <RoundedBoxLink
            // @ts-ignore
            to={handleGiftBatchEditRoute(giftBatch)}
            tw="mb-24"
            key={giftBatch.id}
            disabled={
              giftBatch.sendStatus === GiftBatchSendStatus.CANCELED ||
              giftBatch.sendStatus === GiftBatchSendStatus.FAILED
            }
          >
            <div
              onClick={() => {
                if (
                  giftBatch.scheduledSendOn &&
                  giftBatch.sendStatus === "PENDING"
                ) {
                  setGiftBatchEditModalOpen(true)
                  setCurrentEditingGiftBatch({
                    id: giftBatch.id,
                    batchName: giftBatch.batchName,
                    batchNameFallback: giftBatch.batchNameFallback,
                    scheduledSendOn: giftBatch.scheduledSendOn,
                    customEmailSubject: giftBatch.customEmailSubject,
                    createdAt: giftBatch.createdAt,
                  })
                }
              }}
            >
              {giftBatch.failureMessages.length > 0 && (
                <div tw="p-5">
                  <GiftBatchErrorMessage
                    onAuthorizationGranted={() =>
                      history.push(
                        generateRealmPath(
                          "plus",
                          `/track/${giftBatch.id}/recipients`,
                        ),
                      )
                    }
                    variant="2"
                    giftBatch={giftBatch}
                  />
                </div>
              )}

              <div tw="flex flex-col lg:flex-row">
                <div tw="border-b lg:border-r lg:border-b-0 border-gray-100 flex flex-1 lg:flex-4 p-5">
                  <div tw="flex flex-col flex-1 p-1 lg:p-5">
                    <div tw="flex flex-row items-center pb-3 justify-between">
                      <RoundedBoxLinkColorModifier
                        tw="text-2xl font-medium"
                        className="data-hj-suppress ph-no-capture fs-mask"
                      >
                        {giftBatchName(
                          giftBatch.batchName ?? null,
                          giftBatch.batchNameFallback,
                        )}
                      </RoundedBoxLinkColorModifier>
                    </div>
                    <div tw="leading-5 text-sm">
                      {notSent(giftBatch) ? (
                        <div css={{ color: "#bd8e00" }}>
                          Scheduled to {giftBatch.recipientCount}{" "}
                          {pluralize("recipient", giftBatch.recipientCount)}
                        </div>
                      ) : (
                        <div css={{ color: "#27ae60" }}>
                          Sent to {getRecipientCount(giftBatch)}{" "}
                          {pluralize("recipient", getRecipientCount(giftBatch))}
                        </div>
                      )}
                    </div>
                    <div tw="text-gray-500 text-sm">
                      <TimeAgo
                        date={new Date(giftBatch.createdAt)}
                        formatter={timeAgoFormatterWithDate(
                          new Date(giftBatch.createdAt),
                        )}
                      />{" "}
                      {showUser && (
                        <span className="data-hj-suppress ph-no-capture fs-mask">
                          by {giftBatch.userFullName}
                        </span>
                      )}
                    </div>
                    <div tw="flex flex-col flex-1 items-end mt-8 gap-4">
                      {giftBatch.cartProducts.map((cartProduct) => (
                        <ProductItem
                          key={cartProduct.id}
                          cartProduct={cartProduct}
                          customStoreImageThumb={
                            giftBatch.customStoreImageThumb
                          }
                        />
                      ))}
                    </div>
                  </div>
                </div>

                {notSent(giftBatch) ? (
                  <ScheduledSendMessage
                    sendStatus={giftBatch.sendStatus}
                    scheduledSendOn={giftBatch.scheduledSendOn}
                    productUnavailable={giftBatch.productUnavailable}
                    landingPageEnabled={giftBatch.landingPageEnabled}
                    giftLink={giftBatch.giftLink}
                  />
                ) : (
                  <StatisticsSummary>
                    <div tw="p-1 lg:p-5">
                      <div>
                        <div tw="relative">
                          <ProgressBarUnfilled>
                            <ProgressBarFilled
                              css={{
                                width: `${acceptedPercentage(giftBatch)}%`,
                              }}
                            />
                          </ProgressBarUnfilled>
                        </div>
                        <div tw="flex justify-between">
                          <div>
                            <div tw="font-medium text-black">
                              {giftBatch.statistics.summaryAccepted} accepted
                            </div>
                            <div>{acceptedPercentage(giftBatch)}%</div>
                          </div>
                          <div tw="text-right">
                            <div tw="text-black">
                              {giftBatch.statistics.summaryWaiting} waiting
                            </div>
                            <div>{waitingPercentage(giftBatch)}%</div>
                          </div>
                        </div>
                      </div>
                      {giftBatch.recentAccepts.length > 0 && (
                        <div tw="leading-6 mt-9">
                          <div>Recent accepts</div>
                          <div>
                            {giftBatch.recentAccepts.map(
                              ({
                                acceptedAt,
                                id,
                                recipientFirstName,
                                recipientLastName,
                                thankYouNote,
                              }) => (
                                <div tw="flex justify-between" key={id}>
                                  <div tw="flex items-center text-gray-600">
                                    <span className="data-hj-suppress ph-no-capture fs-mask">
                                      {[recipientFirstName, recipientLastName]
                                        .filter(Boolean)
                                        .join(" ")}{" "}
                                    </span>
                                    {thankYouNote && <NoteIcon tw="ml-2" />}
                                  </div>
                                  <div>
                                    <TimeAgo
                                      date={new Date(acceptedAt)}
                                      formatter={timeAgoFormatterWithDate(
                                        new Date(acceptedAt),
                                      )}
                                    />
                                  </div>
                                </div>
                              ),
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </StatisticsSummary>
                )}
              </div>
            </div>
          </RoundedBoxLink>
        </div>
      ))}
      <Modal
        isOpen={giftBatchEditModalOpen}
        closeTimeoutMS={500}
        onRequestClose={closeGiftBatchEditModal}
        shouldCloseOnOverlayClick={true}
        style={modalStyle}
      >
        {currentEditingGiftBatch && (
          <GiftBatchEditModal
            giftBatchId={currentEditingGiftBatch.id}
            giftBatchName={giftBatchName(
              currentEditingGiftBatch.batchName ?? null,
              currentEditingGiftBatch.batchNameFallback,
            )}
            scheduledSendOn={formatScheduledSendDate(
              currentEditingGiftBatch.scheduledSendOn,
            )}
            closeGiftBatchEditModal={closeGiftBatchEditModal}
          />
        )}
      </Modal>
    </div>
  )
}

const ProgressBarUnfilled = tw.div`bg-white border border-gray-200 h-1.5 rounded-md mb-2`

const ProgressBarFilled = styled.div`
  ${tw`h-1.5 absolute left-0 rounded-md top-0`}
  background: linear-gradient(90deg, #e4a1b8 30.58%, #b37dd5 100%), #e4d8f4;
`

const StatisticsSummary = tw.div`flex flex-col flex-1 lg:flex-5 justify-between leading-4 p-5 text-gray-400 text-sm`

const notSent = (giftBatch: GiftBatch) =>
  giftBatch.scheduledSendOn &&
  giftBatch.sendStatus !== GiftBatchSendStatus.COMPLETE

interface ProductItemProps {
  cartProduct: CartProductShipmentFragment
  customStoreImageThumb?: ImageFragment | null
}

const ProductItem = ({
  customStoreImageThumb,
  cartProduct,
}: ProductItemProps) => {
  const { quantity } = cartProduct
  const productSubtitle = getCartProductSubtitle(cartProduct)

  return (
    <div tw="w-full flex flex-row items-center">
      <div tw="mr-4 relative">
        <CartProductProductImage
          tw="w-10 h-10 rounded-md"
          customStoreImageThumb={customStoreImageThumb}
          cartProduct={cartProduct}
        />
        {quantity > 1 && <QuantityTagSmall>{quantity}</QuantityTagSmall>}
      </div>
      <div tw="w-full flex-1">
        <div tw="flex flex-row items-center leading-4 text-sm">
          <div tw="flex-1">
            <div tw="text-black">{cartProduct.productTitle}</div>
            <div tw="text-gray-500">{productSubtitle}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const GIFT_BATCHES_QUERY = gql`
  query Track_GiftBatches($page: Int!, $perPage: Int!, $onlyMyGifts: Boolean) {
    workspace {
      giftBatchesCount(onlyMyGifts: $onlyMyGifts)
      giftBatches(page: $page, perPage: $perPage, onlyMyGifts: $onlyMyGifts) {
        id
        sendStatus
        customStoreImageThumb {
          ...Image
        }
        failureMessages {
          title
          message
        }
        flexGiftPrice
        batchName
        batchNameFallback
        recipientCount
        createdAt
        userPublicId
        userFullName
        isScheduledSend
        scheduledSendOn
        productUnavailable
        customEmailSubject
        recentAccepts {
          id
          acceptedAt
          recipientFirstName
          recipientLastName
          thankYouNote
          senderViewAccessId
        }
        statistics {
          summaryAccepted
          summaryWaiting
        }
        cartProducts {
          ...CartProductShipment
        }
        landingPageEnabled
        giftLink
        isSmartLink
        smartLinkQuantity
      }
    }
  }
  ${CART_PRODUCT_SHIPMENT_FRAGMENT}
  ${IMAGE_FRAGMENT}
`

export default Index
