import { gql, useQuery } from "@apollo/client"
import { useEffect, useState } from "react"

import Activity, {
  BALANCE_ACTIVITY_FRAGMENT,
  YearAndMonth,
} from "./components/Activity"
import Container from "../sites/App/Container"

import {
  Balance_OverviewQuery,
  Balance_OverviewQueryVariables,
} from "@/types/graphql-types"

export default function Overview() {
  const [yearAndMonth, setYearAndMonth] = useState<YearAndMonth>({
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
  })

  const { data, loading, refetch } = useQuery<
    Balance_OverviewQuery,
    Balance_OverviewQueryVariables
  >(BALANCE_OVERVIEW_QUERY, {
    variables: {
      year: yearAndMonth.year,
      month: yearAndMonth.month,
    },
  })

  useEffect(() => {
    refetchActivity()
  }, [yearAndMonth])

  const refetchActivity = () => {
    refetch({
      year: yearAndMonth.year,
      month: yearAndMonth.month,
    })
  }

  return (
    <Container>
      <div tw="lg:w-3/5 mx-auto pb-12 min-h-[640px]">
        <Activity
          yearAndMonth={yearAndMonth}
          setYearAndMonth={setYearAndMonth}
          requestRefetch={refetchActivity}
          loading={loading}
          data={data?.me?.balanceAccount?.activity}
          isOwnActivity={true}
        />
      </div>
    </Container>
  )
}

const BALANCE_OVERVIEW_QUERY = gql`
  query Balance_Overview($year: Int!, $month: Int!) {
    me {
      balanceAccount {
        activity(year: $year, month: $month) {
          ...BalanceActivity
        }
      }
    }
  }

  ${BALANCE_ACTIVITY_FRAGMENT}
`
