import { gql, useMutation, useQuery } from "@apollo/client"
import { Link, useHistory } from "react-router-dom"
import { useIntercom } from "react-use-intercom"

import { USER_SUBSCRIPTION_QUERY } from "./UserSubscription"
import { ReactComponent as ChatBubble } from "../assets/icons/chat-bubble-black.svg"
import Button from "../common/Button"
import { generateRealmPath } from "../common/realm"
import { successToast } from "../common/toast"
import Container from "../sites/App/Container"

import {
  Organization_OrganizationSubscriptionRegisterCancellationMutation,
  Organization_OrganizationSubscriptionRegisterCancellationMutationVariables,
  Subscriptions_UserSubscriptionCancelQuery,
} from "@/types/graphql-types"

export default function UserSubscriptionCancel() {
  const { show } = useIntercom()

  const { data } = useQuery<Subscriptions_UserSubscriptionCancelQuery>(
    SUBSCRIPTION_CANCEL_QUERY,
  )

  const [registerCancellation, { loading: mutationLoading }] = useMutation<
    Organization_OrganizationSubscriptionRegisterCancellationMutation,
    Organization_OrganizationSubscriptionRegisterCancellationMutationVariables
  >(SUBSCRIPTION_CANCEL_MUTATION)

  const history = useHistory()

  const handleSubmit = async () => {
    const res = await registerCancellation({
      refetchQueries: [{ query: USER_SUBSCRIPTION_QUERY }],
    })

    const data = res?.data?.subscriptionRegisterCancellation

    if (data?.ok) {
      successToast(
        "Your subscription was canceled. Please allow a few minutes for the cancellation to take place.",
      )
      history.push(generateRealmPath("plus", "/account/subscription"))
      return
    } else {
      window.alert(
        data?.error ||
          "An unexpected error occurred. Please contact plus-support@ongoody.com.",
      )
    }
  }

  // Don't show page until query is complete.
  if (!data) {
    return null
  }

  return (
    <Container tw="px-5 pt-8" css={{ minHeight: "80vh" }}>
      <div tw="max-w-md mx-auto">
        <div tw="shadow-min p-9 border border-gray-150 rounded-xl">
          <h2 tw="font-medium text-xl">Cancel subscription</h2>
          <p tw="font-text pt-5 text-gray-700">
            Your plan will be downgraded to Starter (Free) at the end of your
            billing term. Here's what will happen at the end of your term:
          </p>
          <ul tw="font-text text-gray-700 list-disc pl-4">
            <li tw="pt-4">You won't be able to send most retail gift cards.</li>
            <li tw="pt-4">You won't be able to set custom logo branding.</li>
            <li tw="pt-4">
              You won't be able to add your Calendly meeting scheduler to gifts.
            </li>
            <li tw="pt-4">
              You won't be able to send international gifts outside the US.
            </li>
            <li tw="pt-4">
              You won't be able to send new gifts from your own Gmail account or
              use any custom cards you've uploaded.
            </li>
            <li tw="pt-4">
              Your subscription to Pro will end and will no longer automatically
              renew.
            </li>
          </ul>
          <div tw="pt-8 flex flex-col md:flex-row gap-2 items-center justify-between">
            <Button onClick={handleSubmit} disabled={mutationLoading}>
              {mutationLoading
                ? "Please wait..."
                : "Yes, cancel my subscription"}
            </Button>
            <Link
              to={generateRealmPath("plus", "/account/subscription")}
              tw="text-gray-500 hover:text-gray-700 transition-colors"
            >
              No, go back
            </Link>
          </div>
        </div>
        <div tw="mt-6 bg-gray-100 p-4 pl-6 rounded-xl flex flex-col gap-2 md:flex-row items-center justify-between">
          <div>Questions?</div>
          <Button onClick={show}>
            <ChatBubble />
            <div>Contact support</div>
          </Button>
        </div>
      </div>
    </Container>
  )
}

const SUBSCRIPTION_CANCEL_QUERY = gql`
  query Subscriptions_UserSubscriptionCancel {
    me {
      id
      subscription {
        id
      }
    }
  }
`

const SUBSCRIPTION_CANCEL_MUTATION = gql`
  mutation Subscriptions_UserSubscriptionRegisterCancellation {
    subscriptionRegisterCancellation(ownerType: USER) {
      ok
      error
    }
  }
`
