import { useQuery } from "@apollo/client"
import React from "react"

import { useFeatureAccess } from "@/common/hooks/featureAccess"
import { useFeatureFlags } from "@/common/hooks/featureFlags"
import useTeamPlan from "@/common/hooks/teamPlan"
import Receipts from "@/organization/settings/receipts/Receipts"
import RequireSSO from "@/organization/settings/requireSSO/RequireSSO"
import { GetSamlEnabled } from "@/organization/settings/saml/graphql/GetSamlEnabled"
import Saml from "@/organization/settings/saml/Saml"
import { GetSamlEnabledQuery } from "@/types/graphql-types"
const Settings = () => {
  const { onTeamPlan, loading: onTeamPlanLoading } = useTeamPlan()
  const { hasFeatureFlag } = useFeatureFlags()
  const hasSamlFeature = hasFeatureFlag("saml")
  const { hasFeature } = useFeatureAccess()

  const { data, loading: samlEnabledLoading } =
    useQuery<GetSamlEnabledQuery>(GetSamlEnabled)

  const samlEnabled = data?.organization?.samlEnabled || false
  const organizationId = data?.organization?.id

  const displayRequireSSO =
    (onTeamPlan || hasFeature("saml")) && !onTeamPlanLoading && organizationId

  return (
    <div>
      {hasSamlFeature && (
        <Saml
          samlEnabled={samlEnabled}
          samlEnabledLoading={samlEnabledLoading}
          organizationId={organizationId}
        />
      )}
      {displayRequireSSO && (
        <RequireSSO samlEnabled={samlEnabled} organizationId={organizationId} />
      )}
      <Receipts />
      <div tw="mb-[450px]" />
    </div>
  )
}

export default Settings
