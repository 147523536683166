import { gql, useMutation } from "@apollo/client"
import { useMergeLink } from "@mergeapi/react-merge-link"
import React, { useState } from "react"
import { toast } from "react-hot-toast"
import tw, { styled } from "twin.macro"

import { ReactComponent as LinkIcon } from "./images/link.svg"
import Button from "../../common/Button"
import { HRIS, MERGE } from "../../common/hris"

import {
  Contacts_CustomerIntegrationCompleteMutation,
  Contacts_CustomerIntegrationCompleteMutationVariables,
  Contacts_CustomerIntegrationCreateMutation,
  Contacts_CustomerIntegrationCreateMutationVariables,
} from "@/types/graphql-types"

interface ConnectHRButtonProps {
  className?: string
  relink?: boolean
  onIntegrationConnect?: () => void
}

const ConnectHRButton: React.FC<ConnectHRButtonProps> = ({
  className,
  children,
  relink,
  onIntegrationConnect,
}) => {
  const [createIntegration, { loading: createIntegrationLoading }] =
    useMutation<
      Contacts_CustomerIntegrationCreateMutation,
      Contacts_CustomerIntegrationCreateMutationVariables
    >(CUSTOMER_INTEGRATION_CREATE_MUTATION)

  const [confirmIntegration, { loading: confirmIntegrationLoading }] =
    useMutation<
      Contacts_CustomerIntegrationCompleteMutation,
      Contacts_CustomerIntegrationCompleteMutationVariables
    >(CUSTOMER_INTEGRATION_CONFIRM_MUTATION)

  const [integrationID, setIntegrationID] = useState<string | null>(null)
  const [mergeLinkToken, setMergeLinkToken] = useState<string | null>(null)

  // Start the connect process by requesting a linkToken and saving it along
  // with the integrationID.
  const handleConnect = async () => {
    const res = await createIntegration({
      variables: {
        integrationType: HRIS,
        integrationServiceName: MERGE,
        relink: relink,
      },
    })

    const resData = res.data?.customerIntegrationCreate
    if (resData?.linkToken && resData?.id) {
      setIntegrationID(resData.id)
      setMergeLinkToken(resData.linkToken)
    }
  }

  // Finish the connect process by sending the publicToken to our backend along
  // with the integrationID.
  const handleSuccess = async (publicToken: string) => {
    setMergeLinkToken(null)

    if (!integrationID) {
      return
    }

    const res = await confirmIntegration({
      variables: {
        id: integrationID,
        publicToken: publicToken,
        relink: relink,
      },
    })

    if (res.data?.customerIntegrationConfirm.ok) {
      !!onIntegrationConnect && onIntegrationConnect()
      toast.success("Connected!")
    } else {
      toast.error("Could not connect")
    }

    setIntegrationID(null)
  }

  const loading = createIntegrationLoading || confirmIntegrationLoading

  return (
    <>
      <ConnectHRMerge
        className={className}
        onClick={handleConnect}
        disabled={loading}
      >
        {children ?? (
          <div tw="flex items-center gap-3">
            <LinkIcon />
            {relink ? (
              <div>Relink HR</div>
            ) : (
              <div tw="leading-snug">Connect HR</div>
            )}
          </div>
        )}
      </ConnectHRMerge>
      {mergeLinkToken && (
        <IntegrationMergeLink
          linkToken={mergeLinkToken}
          onSuccess={handleSuccess}
        />
      )}
    </>
  )
}

interface IntegrationMergeLinkProps {
  linkToken: string
  onSuccess: (publicToken: string) => void
}

// useMergeLink needs to be initialized with a linkToken, so to avoid timing
// issues and complicated useEffects, we use a separate component that is only
// mounted when a linkToken is set.
const IntegrationMergeLink: React.FC<IntegrationMergeLinkProps> = ({
  linkToken,
  onSuccess,
}) => {
  const { open, isReady } = useMergeLink({
    linkToken,
    onSuccess,
  })

  // Opens Merge Link when ready
  if (isReady) {
    open()
  }

  return null
}

const ConnectHRToggleButton = styled(Button)`
  &.hide-mobile {
    ${tw`hidden xl:flex`}
  }
`

const ConnectHRMerge = styled(ConnectHRToggleButton)`
  &.hris-provider {
    ${tw`bg-white box-border p-4 lg:p-0 lg:w-48 items-center flex justify-center items-center`}

    height: 5.5rem;

    &.hide-mobile {
      ${tw`hidden xl:flex`}
    }
  }

  &.relink {
    ${tw`bg-white border border-primary-100 box-border rounded-lg text-gray-500 mb-2 px-4 hover:border-primary-200 active:border-primary-300 w-max`}

    height: min-content;
    padding-top: 0.5625rem;
    padding-bottom: 0.5625rem;
    box-shadow: 0px 2px 4px rgba(228, 216, 244, 0.3);
  }

  &:not(.hris-provider):not(.relink) {
    ${tw`bg-white border border-gray-200 rounded-lg`}

    &:hover,
    &:focus-visible,
    &:active {
      box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.05);
    }

    &:active {
      ${tw`bg-gray-050 border border-gray-200 rounded-lg`}
    }
  }
`

export const CUSTOMER_INTEGRATION_CREATE_MUTATION = gql`
  mutation Contacts_CustomerIntegrationCreate(
    $integrationType: String!
    $integrationServiceName: String!
    $relink: Boolean
  ) {
    customerIntegrationCreate(
      relink: $relink
      integrationType: $integrationType
      integrationServiceName: $integrationServiceName
    ) {
      ok
      id
      linkToken
    }
  }
`

const CUSTOMER_INTEGRATION_CONFIRM_MUTATION = gql`
  mutation Contacts_CustomerIntegrationComplete(
    $id: ID!
    $publicToken: String!
    $relink: Boolean
  ) {
    customerIntegrationConfirm(
      id: $id
      publicToken: $publicToken
      relink: $relink
    ) {
      ok
    }
  }
`

export default ConnectHRButton
