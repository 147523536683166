import { gql, useMutation } from "@apollo/client"
import * as Sentry from "@sentry/react"
import { useState } from "react"
import { toast } from "react-hot-toast"
import tw from "twin.macro"

import DeactivateMemberModal from "./DeactivateMemberModal"
import { ActionsDropdown, TdName } from "../../common/members/Table"
import useChangeOrganizationAccess from "../../common/members/useChangeOrganizationAccess"
import RoleBadge from "../RoleBadge"

import { GET_WORKSPACE } from "./index"

import {
  BaseMeFragment,
  OrganizationMembership,
  OrganizationMembershipChangeRoleMutation,
  OrganizationMembershipChangeRoleMutationVariables,
  OrganizationMembershipDeactivateMutation,
  OrganizationMembershipDeactivateMutationVariables,
  OrganizationMembershipRole,
} from "@/types/graphql-types"

const MEMBER = "Member"
const ADMIN = "Admin"

interface MemberRowProps
  extends Omit<OrganizationMembership, "memberTeamNames" | "status"> {
  currentUser: BaseMeFragment
  isTeamPlan: boolean
}

const MemberRow = ({
  id,
  access,
  role,
  member: { fullName, companyTitle, email, publicId },
  memberGiftCount,
  memberWorkspaceNames,
  currentUser,
  isTeamPlan,
  pendingGiftCount,
  scheduledGiftBatchCount,
}: MemberRowProps) => {
  const [deactivationModalIsOpen, setDeactivationModalIsOpen] = useState(false)

  const [deactivateOrganizationMembership] = useMutation<
    OrganizationMembershipDeactivateMutation,
    OrganizationMembershipDeactivateMutationVariables
  >(ORGANIZATION_MEMBERSHIP_DEACTIVATE)

  const [changeRoleOrganizationMembership] = useMutation<
    OrganizationMembershipChangeRoleMutation,
    OrganizationMembershipChangeRoleMutationVariables
  >(ORGANIZATION_MEMBERSHIP_CHANGE_ROLE)

  const setRole = async (id: string, role: OrganizationMembershipRole) => {
    const res = await changeRoleOrganizationMembership({
      variables: { id, role },
      refetchQueries: [{ query: GET_WORKSPACE }],
    })

    const organizationMembershipChangeRole =
      res.data?.organizationMembershipChangeRole

    if (organizationMembershipChangeRole?.ok) {
      toast.success(
        `Updated role to ${organizationMembershipChangeRole?.organizationMembership?.role}.`,
        {
          iconTheme: {
            primary: "#27ae60",
            secondary: "#fff",
          },
        },
      )
    } else if (organizationMembershipChangeRole?.error) {
      alert(organizationMembershipChangeRole.error)
    } else {
      alert("An unexpected error occurred.")
      Sentry.withScope((scope) => {
        scope.setExtra("response", res)
        Sentry.captureException(new Error("OrganizationMembers.setRole failed"))
      })
    }
  }

  const deactivate = async (id: string, fullName?: string | null) => {
    const res = await deactivateOrganizationMembership({
      variables: {
        id,
      },
      refetchQueries: [{ query: GET_WORKSPACE }],
    })

    if (res?.data?.organizationMembershipDeactivate?.ok) {
      toast.success(
        `${fullName || MEMBER} has been removed from the organization.`,
        {
          iconTheme: {
            primary: "#27ae60",
            secondary: "#fff",
          },
        },
      )
    }
  }

  const changeAccess = useChangeOrganizationAccess()

  const onClickDemoteRole = (id: string) => {
    if (
      window.confirm("Are you sure you want to demote this admin to a member?")
    ) {
      setRole(id, OrganizationMembershipRole.member)
    }
  }
  return (
    <tr key={id}>
      <TdName name={fullName} role={email}>
        <ActionsDropdown
          id={id}
          access={access}
          onlyDisplayOn="mobile"
          changeAccess={(newAccess) =>
            changeAccess({
              id,
              newAccess: newAccess,
              refetchQueries: [{ query: GET_WORKSPACE }],
            })
          }
          onClickDemoteRole={onClickDemoteRole}
          promoteRole={() => setRole(id, OrganizationMembershipRole.admin)}
          onClickDeactivate={() => setDeactivationModalIsOpen(true)}
          isMember={role === OrganizationMembershipRole.member}
          belongsToCurrentUser={publicId === currentUser.publicId}
          higherRoleLabel={ADMIN}
          groupLabel="organization"
          isTeamPlan={isTeamPlan}
        />
      </TdName>

      <td
        css={hideOnMobileIfEmpty(companyTitle)}
        tw="text-gray-500"
        className="data-hj-suppress ph-no-capture fs-exclude"
      >
        {companyTitle}
      </td>
      <td
        tw="text-gray-500"
        css={hideOnMobileIfEmpty(memberWorkspaceNames.join(", "))}
      >
        {memberWorkspaceNames.join(", ")}
      </td>
      <td>
        {memberGiftCount}
        <span tw="lg:hidden">&nbsp;gifts</span>
      </td>
      <td>
        <RoleBadge access={access} role={role} isTeamPlan={isTeamPlan} />
      </td>
      <td
        css={[
          // Hide for managers to collapse this space on mobile viewport
          tw`hidden!`,
          currentUser.publicId !== publicId && tw`lg:flex!`,
        ]}
      >
        <ActionsDropdown
          id={id}
          access={access}
          onlyDisplayOn="desktop"
          changeAccess={(newAccess) =>
            changeAccess({
              id,
              newAccess: newAccess,
              refetchQueries: [{ query: GET_WORKSPACE }],
            })
          }
          onClickDemoteRole={onClickDemoteRole}
          promoteRole={() => setRole(id, OrganizationMembershipRole.admin)}
          onClickDeactivate={() => setDeactivationModalIsOpen(true)}
          isMember={role === OrganizationMembershipRole.member}
          belongsToCurrentUser={publicId === currentUser.publicId}
          higherRoleLabel={ADMIN}
          groupLabel="organization"
          isTeamPlan={isTeamPlan}
        />
      </td>
      <DeactivateMemberModal
        isOpen={deactivationModalIsOpen}
        setIsOpen={setDeactivationModalIsOpen}
        fullName={fullName ?? ""}
        pendingGiftCount={pendingGiftCount}
        scheduledGiftBatchCount={scheduledGiftBatchCount}
        deactivate={() => {
          deactivate(id, fullName)
          setDeactivationModalIsOpen(false)
        }}
      />
    </tr>
  )
}

const hideOnMobileIfEmpty = (value: any) => {
  return [!value && tw`hidden!`, tw`lg:flex!`]
}

const ORGANIZATION_MEMBERSHIP_DEACTIVATE = gql`
  mutation OrganizationMembershipDeactivate($id: ID!) {
    organizationMembershipDeactivate(id: $id) {
      ok
    }
  }
`

const ORGANIZATION_MEMBERSHIP_CHANGE_ROLE = gql`
  mutation OrganizationMembershipChangeRole(
    $id: ID!
    $role: OrganizationMembershipRole!
  ) {
    organizationMembershipChangeRole(id: $id, role: $role) {
      ok
      error
      organizationMembership {
        role
      }
    }
  }
`

export default MemberRow
