import tw from "twin.macro"

import { Details_ProductFragment } from "@/types/graphql-types"

interface Props {
  product: Details_ProductFragment
}

export const ShippedAndSoldByBestBuy = ({ product }: Props) => {
  if (product.ecommerceIntegrationType !== "best_buy") {
    return null
  }

  return (
    <Container>
      <div> Shipped and sold by </div>

      <img
        alt={"FulfilledThroughBestBuy"}
        tw={"h-9 pl-1"}
        src={
          "https://assets.ongoody.com/ecommerce_integrations/stores/best_buy_logo_primary_pmwo95_resized_SM.png"
        }
      />
    </Container>
  )
}

const Container = tw.div`flex flex-row items-center text-gray-600 text-[15px] leading-[18px] mt-8 pl-2`
