import { Dispatch, SetStateAction, createContext } from "react"
import { Location } from "react-router-dom-v5-compat"

import { CategorySet } from "./categories/CategorySetToggle"
import { BrandValueKey } from "./components/BrandValueBadge"
import { PriceFilterValue, priceFilterInitialValue } from "./filters"
import {
  GenerateBrowseUrlParams,
  GeneratePdpUrlParams,
  ProductData,
} from "./StoreHelpers"
import { StoreMode } from "./types"

import {
  Store_CategoryCommonFragment,
  Store_CategoryFragment,
  Store_GiftOptionPreviewFragment,
} from "@/types/graphql-types"

// Home: /browse
// Category: /browse/category/:slug
// Custom: /browse/custom
// Recommender: /browse/recommender
// Search: /browse/search
// PDP Standalone: /browse/brands/:brandSlug/:productSlug
// Editorial: /editorial/:slug
export type StoreScreen =
  | "home"
  | "category"
  | "custom"
  | "recommender"
  | "search"
  | "pdp_standalone"
  | "editorial"

export type StoreCaller = "browse" | "autogift" | "custom_store"

export const defaultValue = {
  screen: null,
  pushNewUrl: () => {},
  generateBrowseUrl: () => "",
  generatePdpUrl: () => "",
  selectedCategorySlug: null,
  setSelectedCategorySlug: () => {},
  selectedCategory: null,
  selectedSupercategory: null,
  selectedSubcategory: null,
  selectedCategorySet: CategorySet.CATEGORIES,
  setSelectedCategorySet: () => {},
  isEmbeddedCustomStore: false,
  useVirtualUrl: false,
  brandValues: [],
  setBrandValues: () => {},
  priceFilterValue: priceFilterInitialValue,
  setPriceFilterValue: () => {},
  setSelectedGiftOptionPreview: () => {},
  setSelectedProductPreview: () => {},
  locationStackDownstackItem: null,
  mode: "products" as StoreMode,
  setMode: () => {},
  defaultMode: "products" as StoreMode,
  backgroundLocation: null,
  virtualLocation: {
    pathname: "",
    key: "",
    state: null,
    search: "",
    hash: "",
  },
  setVirtualLocation: () => {},
  caller: "browse" as StoreCaller,
}

const StoreContext = createContext<{
  screen: StoreScreen | null
  pushNewUrl: (path: string, state?: Record<string, string>) => void
  generateBrowseUrl: (params: GenerateBrowseUrlParams) => string
  generatePdpUrl: (params: GeneratePdpUrlParams) => string
  selectedCategorySlug: string | null
  setSelectedCategorySlug: (slug: string | null) => void
  selectedCategory: Store_CategoryCommonFragment | null
  selectedSupercategory: Store_CategoryFragment | null
  selectedSubcategory: Store_CategoryCommonFragment | null
  selectedCategorySet: CategorySet
  setSelectedCategorySet: Dispatch<SetStateAction<CategorySet>>
  isEmbeddedCustomStore: boolean
  useVirtualUrl: boolean
  brandValues: BrandValueKey[]
  setBrandValues: Dispatch<SetStateAction<BrandValueKey[]>>
  priceFilterValue: PriceFilterValue
  setPriceFilterValue: Dispatch<SetStateAction<PriceFilterValue>>
  setSelectedGiftOptionPreview: (
    giftOptionPreview: Store_GiftOptionPreviewFragment | null,
  ) => void
  setSelectedProductPreview: (productPreview: ProductData | null) => void
  locationStackDownstackItem: Location | null
  mode: StoreMode
  setMode: Dispatch<SetStateAction<StoreMode>>
  defaultMode: StoreMode
  backgroundLocation: Location | null
  virtualLocation: Location
  setVirtualLocation: Dispatch<SetStateAction<Location>>
  caller: StoreCaller
}>(defaultValue)

export default StoreContext
