import React from "react"

import { MenuItem } from "./MenuItem"
import { ReactComponent as CustomCard } from "../../../assets/icons/custom-card.svg"
import { ReactComponent as MenuContact } from "../../../assets/icons/menu-contact.svg"
import { ReactComponent as SubscriptionIcon } from "../../../assets/icons/menu-subscription.svg"
import { useAuth } from "../../../common/hooks"
import { ReactComponent as UserIcon } from "../../../common/images/user.svg"
import { generateRealmPath } from "../../../common/realm"
import { ReactComponent as LogOutIcon } from "../images/log-out.svg"

const AccountMenuItems: React.FC = () => {
  const { handleLogout } = useAuth()

  return (
    <>
      <MenuItem
        name="My account"
        icon={<UserIcon style={{ strokeWidth: 1.5 }} />}
        path={generateRealmPath("plus", "/account")}
        exact
      />
      <MenuItem
        name="Subscription"
        icon={<SubscriptionIcon style={{ strokeWidth: 1.5 }} />}
        path={generateRealmPath("plus", "/account/subscription")}
        exact
      />
      <MenuItem
        name="Custom cards"
        icon={<CustomCard />}
        path={generateRealmPath("plus", "/account/custom-cards")}
      />
      <MenuItem
        name="Contact us"
        icon={<MenuContact />}
        path={generateRealmPath(null, "/contact")}
      />
      <MenuItem
        name="Log out"
        icon={<LogOutIcon />}
        onClick={() =>
          handleLogout({
            onSuccess: () => {
              window.location.href = "/"
            },
          })
        }
      />
    </>
  )
}

export default AccountMenuItems
