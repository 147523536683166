import { gql } from "@apollo/client"

import { PRODUCT_LIST_FRAGMENT } from "./ProductListFragment"
import { PAGE_INFO_FRAGMENT } from "../../graphql"

export const RESTRICTED_PRODUCTS_QUERY = gql`
  query RestrictedProducts($afterCursor: String, $pageSize: Int!) {
    restrictedProductsConnection(after: $afterCursor, first: $pageSize) {
      pageInfo {
        ...PageInfo
      }
      nodes {
        ...ProductList
      }
    }
  }
  ${PRODUCT_LIST_FRAGMENT}
  ${PAGE_INFO_FRAGMENT}
`
