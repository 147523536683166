import React, { useState } from "react"
import tw, { css, styled } from "twin.macro"

import QRCode from "./icons/qr-code.svg"
import Share from "./icons/share.svg"
import { track } from "../../../common/analytics"
import { ReactComponent as CheckIcon } from "../../../common/images/check.svg"
import { ReactComponent as LinkIcon } from "../../../common/images/link.svg"
import { ReactComponent as CopyIcon } from "../../images/copy.svg"

import { GiftLinkQRCodeModal } from "@/track/components/giftLink/GiftLinkQRCodeModal"

interface Props {
  href: string
  onUrlPressCopy: (e: React.MouseEvent<HTMLButtonElement>) => void
  urlCopied: boolean
  small?: boolean
  showLinkButtons?: boolean
  name?: string
}

export default function GiftLinkURLContainer({
  href,
  onUrlPressCopy,
  urlCopied,
  small,
  showLinkButtons,
  name,
}: Props) {
  const [qRCodeModalOpen, setQRCodeModalOpen] = useState(false)

  const onPressQRCode = () => {
    setQRCodeModalOpen(true)
    track("Business - Track Gift Batch - Click QR Code")
  }

  const onPressShare = () => {
    if (window.navigator.share) {
      window.navigator.share({ url: href, title: name })
    }
    track("Business - Track Gift Batch - Click Share")
  }

  const showShare = showLinkButtons && !!window.navigator.share
  const showQR = showLinkButtons

  const filename = `qr-${
    name
      ?.toLowerCase()
      .replaceAll(/[_ ]+/g, "-")
      .replaceAll(/[^\w-]/g, "") || "gift"
  }.png`

  return (
    <>
      <URLContainerLargeGradient css={[small && tw`before:opacity-[0.2]`]}>
        <URLContainerSmallGradient tw="flex flex-col md:flex-row items-stretch">
          <div tw="text-gray-500 pl-4 flex-row items-center justify-center hidden md:flex rounded-t-xl md:rounded-none">
            <LinkIcon tw="stroke-current" />
          </div>
          <input
            readOnly
            value={href}
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              e.currentTarget.select()
              track("Business - Track Gift Batch - Click Gift Batch Link")
            }}
            className="data-hj-suppress ph-no-capture fs-exclude"
            tw="flex-1 pl-3 pr-3 focus:outline-none md:text-xl text-gray-700 truncate bg-transparent"
            css={[small && tw`md:text-base`]}
          />
          <div tw="border-t md:border-l border-primary-new-050" />
          <div tw="flex flex-row-reverse md:flex-row items-stretch flex-1 md:flex-initial">
            {showQR && (
              <>
                <ActionButton
                  tw="flex-initial rounded-br-xl md:rounded-br-none"
                  onClick={onPressQRCode}
                >
                  <img src={QRCode} alt={href} />
                </ActionButton>
                <div tw="border-l border-primary-new-050" />
              </>
            )}
            {showShare && (
              <>
                <ActionButton onClick={onPressShare} tw={"md:hidden"}>
                  <img src={Share} alt={"Share"} />
                  <div>Share</div>
                </ActionButton>
                <div tw="border-l border-primary-new-050 md:hidden" />
              </>
            )}
            <ActionButton
              css={[
                !showQR && tw`rounded-br-xl`,
                tw`rounded-bl-xl md:rounded-bl-none md:rounded-r-xl`,
              ]}
              onClick={onUrlPressCopy}
            >
              {urlCopied ? (
                <CheckIcon tw="stroke-current stroke-1.5 h-[20px] w-[20px]" />
              ) : (
                <CopyIcon tw="stroke-current" />
              )}
              <div>Copy</div>
            </ActionButton>
          </div>
        </URLContainerSmallGradient>
      </URLContainerLargeGradient>
      <GiftLinkQRCodeModal
        isOpen={qRCodeModalOpen}
        setOpen={setQRCodeModalOpen}
        href={href}
        filename={filename}
      />
    </>
  )
}

const ActionButton = tw.button`
  flex flex-row items-center justify-center text-gray-500 gap-3 pl-5 pr-4 transition-colors
  flex-1 md:flex-initial
  hover:(bg-primary-new-000 text-primary-new-500)
  active:(bg-primary-new-050 text-primary-new-600)
`

const GradientDropShadowBase = css`
  ${tw`relative w-full max-w-[700px] h-[120px] md:h-[60px] bg-white z-10 rounded-xl`};

  &:before {
    content: "";
    z-index: -1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: opacity 0.3s;
    border-radius: inherit;
  }

  &::after {
    content: "";
    z-index: -1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: inherit;
    border-radius: inherit;
  }
`

const URLContainerLargeGradient = styled.div`
  ${GradientDropShadowBase};

  &:before {
    background: linear-gradient(-45deg, #dcd7fc 0%, #eddcf1 60%);
    transform: translate3d(0px, 20px, 0);
    filter: blur(16px);
    opacity: 0.4;
  }
`

const URLContainerSmallGradient = styled.div`
  ${GradientDropShadowBase};

  &:before {
    background: linear-gradient(-45deg, #dcd7fc 0%, #eddcf1 100%);
    transform: translate3d(0px, 2px, 0);
    filter: blur(6px);
    opacity: 0.3;
  }
`
