import { useState } from "react"
import tw, { css } from "twin.macro"

import {
  DEFAULT_FLEX_GIFT_PRICE_IN_DOLLARS,
  MINIMUM_FLEX_GIFT_PRICE_IN_DOLLARS,
} from "./constants"

interface Props {
  amount: number
  setAmount: (amount: number) => void
}

const PRESET_AMOUNTS = [30, 50, 100, 150]
const MAX_CUSTOM_AMOUNT = 9999

export default function FlexGiftValueSelector({ amount, setAmount }: Props) {
  const amountIsPresetAmount = PRESET_AMOUNTS.includes(amount)
  const [customIsActive, setCustomIsActive] = useState(!amountIsPresetAmount)

  return (
    <>
      <div tw="flex flex-row items-stretch gap-3 xl:gap-4 flex-wrap xl:flex-nowrap">
        {PRESET_AMOUNTS.map((price) => (
          <button
            css={[priceBaseStyle, amount === price && priceActiveStyle]}
            onClick={() => {
              setAmount(price)
              setCustomIsActive(false)
            }}
          >
            ${price}
          </button>
        ))}
        <div tw="w-full md:hidden" />
        <div tw="flex-1 flex flex-row items-center min-w-0 relative">
          <input
            type="text"
            css={[priceBaseStyle, customIsActive && priceActiveStyle]}
            tw="outline-none focus:outline-none active:outline-none text-center w-auto min-w-0 relative"
            value={customIsActive ? `$${amount}` : ""}
            onChange={(e) => {
              const val = e.target.value.replace("$", "").trim()
              const parsed = parseInt(val)
              if (val === "") {
                setCustomIsActive(false)
                setAmount(DEFAULT_FLEX_GIFT_PRICE_IN_DOLLARS)
              } else if (!isNaN(parsed)) {
                if (parsed > MAX_CUSTOM_AMOUNT) {
                  return
                }

                setAmount(parsed)
                setCustomIsActive(true)
              }
            }}
          />
          {!customIsActive && (
            <div tw="absolute inset-0 flex flex-row items-center justify-center z-10 pointer-events-none text-[15px] text-gray-500">
              Custom
            </div>
          )}
        </div>
      </div>
      {amount < MINIMUM_FLEX_GIFT_PRICE_IN_DOLLARS && (
        <div tw="text-orange-alt-500 text-center pt-2">
          Amount must be at least ${MINIMUM_FLEX_GIFT_PRICE_IN_DOLLARS}.
        </div>
      )}
    </>
  )
}

const priceBaseStyle = css`
  ${tw`flex-1 text-gray-500 rounded-lg border border-gray-250 bg-white h-[60px] text-xl hover:(border-primary-new-200 text-primary-new-400 bg-[#FCFBFF]) active:scale-95 transition-all`};
`

const priceActiveStyle = css`
  ${tw`bg-primary-new-000 border-primary-new-300 text-primary-new-550 font-medium hover:(border-primary-new-300 bg-primary-new-000 text-primary-new-550)`};
`
