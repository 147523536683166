import styled from "@emotion/styled"
import React, { FC } from "react"
import tw from "twin.macro"

import HRCompaniesDesktopLeft from "./images/hr_companies_desktop_left.png"
import HRCompaniesDesktopRight from "./images/hr_companies_desktop_right.png"
import HRCompaniesMobile from "./images/hr_companies_mobile.png"
import PurpleGradientLink from "../../common/PurpleGradientLink"
import { generateRealmPath } from "../../common/realm"

const IntegrateHR: FC = () => {
  return (
    <Container>
      <EllipseBorder />
      <InnerContainer>
        <div tw="flex-1 hidden lg:flex relative">
          <div
            tw="absolute"
            style={{ right: 0, height: "100%", width: "200%" }}
          >
            <img
              tw="h-full float-right"
              src={HRCompaniesDesktopLeft}
              alt="HRCompaniesDesktopLeft"
            />
          </div>
        </div>
        <div tw="flex-1 text-center p-12 lg:px-0 lg:mb-12">
          <Subheading tw="text-2xl font-bold mb-4">For people teams</Subheading>
          <MainText>
            Integrate your HR stack for employee recognition at scale.
          </MainText>
          <SupportText>
            Automatically send gifts for employee milestones like birthdays,
            work anniversaries, or onboarding.
          </SupportText>
          <ViewPlansLink
            tw="hidden lg:inline-block mt-8 font-bold"
            to={generateRealmPath("business", "/use-cases/autogifting")}
          >
            Discover Autogifting
          </ViewPlansLink>
        </div>
        <div tw="flex-1 hidden lg:flex relative">
          <div tw="absolute" style={{ left: 0, height: "100%", width: "200%" }}>
            <img
              tw="h-full"
              src={HRCompaniesDesktopRight}
              alt="HRCompaniesDesktopRight"
            />
          </div>
        </div>
        <img tw="lg:hidden" src={HRCompaniesMobile} alt="HRCompaniesMobile" />
      </InnerContainer>
    </Container>
  )
}

const EllipseBorder = styled.div`
  ${tw`h-10`}
  background-color: white;
  position: absolute;

  height: 14%;
  width: 120%;
  top: -3rem;
  left: -5%;
  right: 10%;
  clip-path: ellipse(closest-side 40px);

  @media (min-width: 640px) {
    ${tw`h-60`}
    width: 110%;
    right: -5%;
    top: -7rem;
    clip-path: ellipse(closest-side 40px);
  }

  @media (min-width: 1024px) {
    ${tw`h-60`}
    width: 110%;
    right: -5%;
    top: -9rem;
    clip-path: ellipse(closest-side 98px);
  }

  @media (min-width: 1200px) {
    width: 110%;
    right: -5%;
  }
`

const Container = styled.div`
  ${tw`relative pt-12 sm:pt-36 lg:pt-32 sm:pb-24 lg:pb-0 overflow-hidden`}

  background: #f8f8f8;
`

const InnerContainer = styled.div`
  ${tw`mx-auto lg:flex`}
  max-width: 580px;

  @media (min-width: 1024px) {
    max-width: 1440px;
  }
`

const MainText = styled.h3`
  ${tw`text-4xl font-bold mb-6`}
  line-height: 3rem;
  @media (min-width: 640px) {
    min-width: 500px;
  }
`

const SupportText = styled.h4`
  ${tw`text-xl text-gray-600`}
  line-height: 2rem;
`

const ViewPlansLink = styled(PurpleGradientLink)`
  ${tw`py-4 px-9`}
`

const Subheading = styled.h1`
  ${tw`text-base md:text-xl mb-2 md:mb-4 font-semibold`}
  color: #A981FF;
`

export default IntegrateHR
