import { ButtonHTMLAttributes } from "react"

import { ReactComponent as Trash } from "@/assets/icons/trash-current-color.svg"

const RemoveFilterButton = (props: ButtonHTMLAttributes<HTMLButtonElement>) => (
  <button
    tw="flex items-center gap-3 px-[14px] py-[7px] leading-5 rounded-lg border border-gray-150 text-gray-500 outline-none"
    {...props}
  >
    <Trash />
    Remove filter
  </button>
)

export default RemoveFilterButton
