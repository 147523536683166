import React from "react"

import {
  BatchName,
  Expiration,
  InternationalShipping,
  MeetingCalendar,
  Swap,
} from "./index"

export const Options = () => {
  return (
    <div tw="max-w-full">
      <BatchName />
      <Expiration />
      <Swap />
      <InternationalShipping />
      <MeetingCalendar />
    </div>
  )
}
