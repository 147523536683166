import DialogModal from "@/common/DialogModal"

interface Props {
  open: boolean
  onClose: () => void
}

const DomesticShippingModal = ({ open, onClose }: Props) => (
  <DialogModal open={open} onClose={onClose}>
    <DialogModal.Header variant="info" />
    <DialogModal.Title>
      Domestic shipping for international gifts
    </DialogModal.Title>
    <DialogModal.Content tw="flex flex-col gap-4">
      <div>
        We’ve partnered with brands in Canada to provide gift options that ship
        domestically!
      </div>
      <div>
        This makes shipping{" "}
        <span tw="font-medium">
          cheaper, faster, and more environmentally friendly,
        </span>{" "}
        and gives your recipients gift options tailored to their local
        preferences.
      </div>
    </DialogModal.Content>

    <DialogModal.Actions>
      <DialogModal.Button variant="primary-new" onClick={onClose}>
        Got it
      </DialogModal.Button>
    </DialogModal.Actions>
  </DialogModal>
)

export default DomesticShippingModal
