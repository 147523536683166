import { gql } from "@apollo/client"

export const OrganizationUpdateRequireSsoSetting = gql`
  mutation OrganizationUpdateRequireSSOSetting(
    $organizationId: ID!
    $requireSsoSetting: OrganizationRequireSsoSetting!
  ) {
    organizationUpdateRequireSsoSetting(
      organizationId: $organizationId
      requireSsoSetting: $requireSsoSetting
    ) {
      ok
      error
    }
  }
`
