import { gql, useLazyQuery } from "@apollo/client"
import React, { useEffect } from "react"
import tw, { styled } from "twin.macro"

import { CONTACTS_WITH_EVENT_TODAY_QUERY } from "./AutogiftIndex"
import {
  AutogiftConfigSection,
  AutogiftConfigSectionContent,
  AutogiftConfigSectionHeaderDescription,
  AutogiftConfigSectionHeaderTitle,
  getDerivedTenureRange,
} from "./common"
import { TenureSlider } from "./TenureSlider"
import { ReactComponent as TenureIcon } from "../../assets/icons/autogift-section-tenure.svg"
import { ReactComponent as ContactsIcon } from "../../assets/icons/contacts-small.svg"
import {
  AutogiftRuleTenureClassification,
  useGlobalState,
} from "../../common/GlobalState"

import { EventKind } from "@/types/graphql-types"
import {
  Autogift_ContactsWithEventTodayQuery,
  Autogift_ContactsWithEventTodayQueryVariables,
  Autogift_CountByWorkTenureQuery,
  Autogift_CountByWorkTenureQueryVariables,
} from "@/types/graphql-types"

const AutogiftTenure = () => {
  const [currentAutogiftRule, setCurrentAutogiftRule] = useGlobalState(
    "currentAutogiftRule",
  )

  const [countByWorkTenure, { data, loading }] = useLazyQuery<
    Autogift_CountByWorkTenureQuery,
    Autogift_CountByWorkTenureQueryVariables
  >(COUNT_BY_WORK_TENURE_QUERY)

  const [loadContactsWithEventToday, { data: contactsWithEventTodayData }] =
    useLazyQuery<
      Autogift_ContactsWithEventTodayQuery,
      Autogift_ContactsWithEventTodayQueryVariables
    >(CONTACTS_WITH_EVENT_TODAY_QUERY)

  const contactsWithEventToday =
    contactsWithEventTodayData?.workspace?.contactList?.contactsWithEventToday

  useEffect(() => {
    if (currentAutogiftRule.contactListId) {
      const { min, max } = getDerivedTenureRange(currentAutogiftRule)
      countByWorkTenure({
        variables: {
          contactListID: currentAutogiftRule.contactListId,
          min,
          max,
        },
      })

      loadContactsWithEventToday({
        variables: {
          contactListId: currentAutogiftRule.contactListId,
          eventType: EventKind.WORK_ANNIVERSARY,
          tenureMin: currentAutogiftRule.tenureMin,
          tenureMax: currentAutogiftRule.tenureMax,
        },
      })
    }
  }, [
    currentAutogiftRule.contactListId,
    currentAutogiftRule.tenureClassification,
    currentAutogiftRule.tenureMin,
    currentAutogiftRule.tenureMax,
  ])

  useEffect(() => {
    if (contactsWithEventToday) {
      setCurrentAutogiftRule({
        ...currentAutogiftRule,
        contactsWithEventToday: contactsWithEventToday,
      })
    }
  }, [contactsWithEventToday])

  const countByWorkTenureNum = data?.workspace?.contactList?.countByWorkTenure

  return (
    <>
      <AutogiftConfigSection>
        <AutogiftConfigSectionHeaderTitle>
          <TenureIcon />
          Tenure level
        </AutogiftConfigSectionHeaderTitle>
        <AutogiftConfigSectionHeaderDescription>
          Which tenure levels should this autogift apply for? You can add more
          tenure levels on the sidebar.
        </AutogiftConfigSectionHeaderDescription>
        <AutogiftConfigSectionContent tw="p-6">
          <TenureSlider
            selectedClassification={currentAutogiftRule.tenureClassification}
            onSelectClassification={(classification) => {
              // When selecting tenure classification 'ALL', set tenure min and max back to null so
              // useEffect hooks that calculate number of contacts in tenure classification and same
              // day message are updated
              if (classification === AutogiftRuleTenureClassification.ALL) {
                setCurrentAutogiftRule({
                  ...currentAutogiftRule,
                  tenureClassification: classification,
                  tenureMin: null,
                  tenureMax: null,
                })
              } else {
                setCurrentAutogiftRule({
                  ...currentAutogiftRule,
                  tenureClassification: classification,
                })
              }
            }}
          />
          <div tw="pt-6">
            {currentAutogiftRule.tenureClassification ===
            AutogiftRuleTenureClassification.ALL ? (
              <>
                <div tw="text-center px-12 text-gray-500">
                  Applies for all contacts with a work anniversary set,
                  including those without a year.
                </div>
                <div tw="pt-3 flex flex-row items-center justify-center">
                  <CountByTenureDisplay
                    count={loading ? null : countByWorkTenureNum}
                  />
                </div>
              </>
            ) : currentAutogiftRule.tenureClassification ===
              AutogiftRuleTenureClassification.YEAR_SINGLE ? (
              <div tw="flex flex-row items-center justify-center">
                <div tw="flex-1">
                  <div tw="pr-4 text-right text-gray-400">Tenure year</div>
                </div>
                <TenureInput
                  value={currentAutogiftRule.tenureMin || ""}
                  type="number"
                  onChange={(e) => {
                    // Changing this input sets both the min and max.
                    let value = null

                    if (e.target.value !== "") {
                      value = parseInt(e.target.value)
                    }

                    setCurrentAutogiftRule({
                      ...currentAutogiftRule,
                      tenureMin: value,
                      tenureMax: value,
                    })
                  }}
                />
                <div tw="flex-1 text-gray-400">
                  <div tw="pl-4">
                    {currentAutogiftRule.tenureMin !== null && (
                      <CountByTenureDisplay count={countByWorkTenureNum} />
                    )}
                  </div>
                </div>
              </div>
            ) : currentAutogiftRule.tenureClassification ===
              AutogiftRuleTenureClassification.YEAR_RANGE ? (
              <div tw="flex flex-row items-center justify-center">
                <div tw="flex-1">
                  <div tw="pr-4 text-right text-gray-400">Tenure year</div>
                </div>
                <div tw="flex flex-row items-center">
                  <TenureInput
                    value={currentAutogiftRule.tenureMin || ""}
                    type="number"
                    onChange={(e) => {
                      let value = null

                      if (e.target.value !== "") {
                        value = parseInt(e.target.value)
                      }

                      setCurrentAutogiftRule({
                        ...currentAutogiftRule,
                        tenureMin: value,
                      })
                    }}
                  />
                  <div tw="text-gray-400 px-3">to</div>
                  <TenureInput
                    value={currentAutogiftRule.tenureMax || ""}
                    type="number"
                    onChange={(e) => {
                      let value = null

                      if (e.target.value !== "") {
                        value = parseInt(e.target.value)
                      }

                      setCurrentAutogiftRule({
                        ...currentAutogiftRule,
                        tenureMax: value,
                      })
                    }}
                  />
                </div>
                <div tw="flex-1 text-gray-400">
                  <div tw="pl-4">
                    {currentAutogiftRule.tenureMin !== null && (
                      <CountByTenureDisplay count={countByWorkTenureNum} />
                    )}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </AutogiftConfigSectionContent>
      </AutogiftConfigSection>
    </>
  )
}

const TenureInput = styled.input`
  ${tw`w-12 h-12 px-2 text-center text-primary-500 border border-primary-100 focus:outline-none focus:border-primary-300 transition-colors rounded-lg`};
  box-shadow: 0 2px 4px rgba(228, 216, 244, 0.3);
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`

const CountByTenureDisplay = ({ count }: { count?: number | null }) => {
  return (
    <div tw="h-6 flex flex-row items-center gap-2 text-gray-400">
      {count !== null && count !== undefined && (
        <>
          <ContactsIcon />
          {count} contact{count !== 1 ? "s" : ""}
        </>
      )}
    </div>
  )
}

const COUNT_BY_WORK_TENURE_QUERY = gql`
  query Autogift_CountByWorkTenure($contactListID: ID!, $min: Int, $max: Int) {
    workspace {
      contactList(id: $contactListID) {
        id
        countByWorkTenure(tenureMin: $min, tenureMax: $max)
      }
    }
  }
`

export default AutogiftTenure
