import React from "react"
import ScrollAnimation from "react-animate-on-scroll"
import { useHistory } from "react-router-dom"
import tw, { styled } from "twin.macro"

import RainbowHeading from "../../../common/RainbowHeading"
import { generateRealmPath } from "../../../common/realm"
import StartButton from "../../../common/StartButton"
import bannerImage from "../../images/banner-image.png"

const BannerContainer = styled.div`
  background: linear-gradient(80deg, #fffafc 14%, #f6f4ff 94%),
    rgba(125, 64, 200, 0.07);
  ${tw`col-span-12 grid grid-cols-12 grid-flow-row-dense rounded-3xl p-5 lg:py-8 lg:px-12 place-items-center mx-5 lg:mx-0 pb-10 md:pb-5`}
`

export const Banner: React.FC = () => {
  const history = useHistory()
  return (
    <BannerContainer>
      <img
        tw="col-span-12 lg:col-span-5 lg:col-start-8 "
        src={bannerImage}
        alt="Banner"
      />
      <div tw="col-span-12 lg:col-span-7 text-center lg:text-left lg:px-8">
        <ScrollAnimation animateIn="fadeInUp" duration={0.5} animateOnce>
          <RainbowHeading tw="text-3xl md:text-3xl lg:text-4xl xl:text-5xl pb-4 leading-tight!">
            Gifting made easy with&nbsp;Goody.
          </RainbowHeading>
        </ScrollAnimation>
        <ScrollAnimation
          animateIn="fadeInUp"
          duration={0.5}
          delay={200}
          animateOnce
        >
          <div tw="text-gray-900 opacity-80 text-1.5xl lg:text-2xl xl:text-3xl lg:flex flex-wrap pb-8 lg:pb-16 leading-normal! -mx-1">
            <div tw="mx-1">Send gifts.</div>
            <div tw="mx-1">Get thank you notes.</div>
            <div tw="mx-1">We handle the rest.</div>
          </div>
        </ScrollAnimation>
        <ScrollAnimation
          animateIn="fadeInUp"
          duration={0.5}
          delay={400}
          animateOnce
        >
          <div tw="flex flex-col lg:flex-row items-center">
            <StartButton
              tw="max-w-xs"
              onClick={() => {
                history.push(generateRealmPath("business", "/signup?via=ea"))
              }}
            />
          </div>
        </ScrollAnimation>
      </div>
    </BannerContainer>
  )
}
