import { useEffect } from "react"
import { Route, matchPath, useLocation } from "react-router-dom"

import { Realm } from "./realmTypes"
import { useSetRealm } from "./useSetRealm"
import { getRealmFromPath } from "./utils"

export const useRealm = (
  realm: Realm | Realm[] | null,
  props: Route["props"],
) => {
  const location = useLocation<{ allowRealmRedirect?: boolean }>()
  const setRealm = useSetRealm()

  useEffect(() => {
    const pathRealm = getRealmFromPath(location.pathname)

    if (
      realm &&
      pathRealm !== "consumer_redirect" &&
      matchPath(location.pathname, props)
    ) {
      setRealm(pathRealm)
    }
  }, [location.pathname, JSON.stringify(props.path), JSON.stringify(realm)])
}
