// Returns cents from a string like $15.90 => 1590
export function getCentsFromString(amount: string) {
  const amt = amount.replace(/[^0-9.]/g, "")
  const amtNum = Number(amt)
  if (isNaN(amtNum)) {
    return null
  }

  return Math.round(amtNum * 100)
}
