import pluralize from "pluralize"
import TimeAgo from "react-timeago"

import { BalanceGiftBatchStatus } from "@/types/graphql-types"
import { BalanceGiftBatch } from "@/types/graphql-types"

interface Props {
  balanceGiftBatch: BalanceGiftBatch
}

export default function ActivityGiftBatchSubtitle({ balanceGiftBatch }: Props) {
  const giftCount =
    balanceGiftBatch.acceptedGiftsCount + balanceGiftBatch.pendingGiftsCount

  if (balanceGiftBatch.status === BalanceGiftBatchStatus.FINALIZED) {
    return (
      <>
        <span tw="text-gray-450 pr-4">
          {balanceGiftBatch.acceptedGiftsCount +
            balanceGiftBatch.pendingGiftsCount}{" "}
          {pluralize("gift", giftCount)}
        </span>
        {balanceGiftBatch.expiresAt &&
        balanceGiftBatch.pendingGiftsCount > 0 ? (
          <span tw="text-gray-450">
            <TimeAgo
              date={new Date(balanceGiftBatch.expiresAt)}
              formatter={expiryFormatter}
            />
          </span>
        ) : null}
      </>
    )
  }

  if (balanceGiftBatch.status === BalanceGiftBatchStatus.SCHEDULED_PENDING) {
    return (
      <>
        <span tw="text-gray-450 pr-4">
          {balanceGiftBatch.scheduledRecipients}{" "}
          {pluralize("gift", balanceGiftBatch.scheduledRecipients || 0)}
        </span>
        {balanceGiftBatch.scheduledSendOn && (
          <span tw="text-gray-450">
            Scheduled for{" "}
            {new Date(
              balanceGiftBatch.scheduledSendOn,
              // @ts-ignore
            ).toLocaleDateString("en-US", { dateStyle: "full" })}
          </span>
        )}
      </>
    )
  }

  if (balanceGiftBatch.status === BalanceGiftBatchStatus.CANCELED) {
    return <span tw="text-gray-450">Canceled</span>
  }

  return null
}

// Formats as:
// Past date: Expired 1 month ago
// Future date: Expires in 2 weeks
function expiryFormatter(amount: number, unit: string, direction: string) {
  return `Expire${direction === "ago" ? "d" : "s"} ${
    direction === "from now" ? "in" : ""
  } ${amount} ${unit}${amount !== 1 ? "s" : ""} ${
    direction === "ago" ? "ago" : ""
  }`
}
