import React from "react"
import { useParams } from "react-router-dom"

import CustomStore from "./CustomStore"

import { CustomStoreProvider } from "@/store/custom/hooks"

interface Params {
  id: string
}

const EditCustomStore: React.FC = () => {
  const params = useParams<Params>()

  return (
    <CustomStoreProvider>
      <CustomStore customStoreParamID={params.id} />
    </CustomStoreProvider>
  )
}

export default EditCustomStore
