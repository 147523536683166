import { gql, useApolloClient } from "@apollo/client"
import { useState } from "react"
import { useLocation } from "react-router-dom"
import tw, { styled } from "twin.macro"

import GiftLinkHeader from "./GiftLinkHeader"
import GiftLinkPill from "./GiftLinkPill"
import GiftLinkSub from "./GiftLinkSub"
import { ComponentVariant, CopyEmailsState } from "./giftLinkTypes"
import GiftLinkURLContainer from "./GiftLinkURLContainer"
import { gradientBg } from "../../../assets/cdnAssets"
import { track } from "../../../common/analytics"

import GiftLinkSmartLinkSub from "@/track/components/giftLink/GiftLinkSmartLinkSub"
import {
  Track_GiftBatchGiftLinkEmailsQuery,
  Track_GiftBatchGiftLinkEmailsQueryVariables,
  Track_GiftBatchGiftLinkFragment,
} from "@/types/graphql-types"

interface Props {
  giftBatch: Track_GiftBatchGiftLinkFragment | null | undefined
}

// Shows a component to render a gift link.
//
// The gift link is typically a landing page link, but can be an individual gift
// link in the case of single-recipient gift batches.
//
// The following parameters affect this component's appearance:
// - Whether the user is seeing this post-send or not (`variant`)
// - If the gift has one or more recipients
// - If the gift has landing page notifications on or off
// - If the gift batch has emails available
//
// See "Landing Pages For Everyone" for documentation.
export default function GiftLink({ giftBatch }: Props) {
  const [urlCopied, setUrlCopied] = useState(false)
  const [copyEmailsState, setCopyEmailsState] =
    useState<CopyEmailsState>("idle")

  const href = giftBatch?.giftLink || ""

  const onUrlPressCopy = async () => {
    await navigator.clipboard.writeText(href)
    setUrlCopied(true)
    setTimeout(() => setUrlCopied(false), 3000)

    track("Business - Track Gift Batch - Copy Gift Batch Link")
  }

  let variant: ComponentVariant = "standard"
  const location = useLocation<undefined | { postSend: boolean }>()
  if (location.state?.postSend) {
    variant = "postSend"
  }

  const client = useApolloClient()

  const onEmailsPressCopy = async () => {
    if (!giftBatch) {
      return
    }

    setCopyEmailsState("loading")

    const res = await client.query<
      Track_GiftBatchGiftLinkEmailsQuery,
      Track_GiftBatchGiftLinkEmailsQueryVariables
    >({
      query: TRACK_GIFT_BATCH_GIFT_LINK_EMAILS_QUERY,
      variables: {
        id: giftBatch.id,
      },
    })

    const emails = res?.data?.workspace?.giftBatch?.recipientEmails
    if (emails) {
      await navigator.clipboard.writeText(emails)
      setCopyEmailsState("copied")
      setTimeout(() => setCopyEmailsState("idle"), 3000)

      track("Business - Track Gift Batch - Copy Emails")
    } else {
      alert("Sorry, an error occurred copying emails.")
      setCopyEmailsState("idle")
    }
  }

  if (
    !giftBatch ||
    !giftBatch.landingPageEnabled ||
    !giftBatch.giftLink ||
    (giftBatch.isSmartLink && !giftBatch.smartLinkActive)
  ) {
    return null
  }

  return (
    <div tw="pt-6 pb-10">
      <GiftLinkPill
        variant={variant}
        landingPageSendNotifs={giftBatch?.landingPageSendNotifs}
        recipientEmailsAvailable={giftBatch?.recipientEmailsAvailable}
      />
      <GiftLinkContainer
        css={{ marginTop: variant === "standard" ? "-14px" : "-24px" }}
      >
        <div tw="pt-4 flex flex-col items-center">
          {!giftBatch?.isSmartLink && (
            <GiftLinkHeader
              variant={variant}
              landingPageSendNotifs={giftBatch?.landingPageSendNotifs}
              recipientEmailsAvailable={giftBatch?.recipientEmailsAvailable}
            />
          )}
          <GiftLinkURLContainer
            href={href}
            onUrlPressCopy={onUrlPressCopy}
            urlCopied={urlCopied}
            showLinkButtons={giftBatch?.isSmartLink ?? false}
            name={giftBatch?.batchName ?? giftBatch?.batchNameFallback}
          />
        </div>
        {!giftBatch?.isSmartLink ? (
          <GiftLinkSub
            variant={variant}
            recipientCount={giftBatch?.recipientCount}
            copyEmailsState={copyEmailsState}
            onEmailsPressCopy={onEmailsPressCopy}
          />
        ) : (
          <GiftLinkSmartLinkSub giftBatch={giftBatch} />
        )}
      </GiftLinkContainer>
    </div>
  )
}

const GiftLinkContainer = styled.div`
  ${tw`bg-cover bg-top border border-primary-new-050 rounded-xl text-center p-6`};

  background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1)
    ),
    url(${gradientBg});
  background-size: cover;
`

export const TRACK_GIFT_BATCH_GIFT_LINK_FRAGMENT = gql`
  fragment Track_GiftBatchGiftLink on GiftBatch {
    id
    landingPageEnabled
    landingPageSendNotifs
    giftLink
    recipientCount
    recipientEmailsAvailable
    isSmartLink
    smartLinkActive
    smartLinkClaimedCount
    smartLinkAwaitingApprovalCount
    smartLinkQuantity
    batchName
    batchNameFallback
  }
`

const TRACK_GIFT_BATCH_GIFT_LINK_EMAILS_QUERY = gql`
  query Track_GiftBatchGiftLinkEmails($id: ID!) {
    workspace {
      giftBatch(id: $id) {
        id
        recipientEmails
      }
    }
  }
`
