import React from "react"
import { NavLink } from "react-router-dom"
import tw, { styled } from "twin.macro"

import { ReactComponent as SalesforceLogo } from "../../assets/images/integrations/salesforce-logo.svg"
import { generateRealmPath } from "../../common/realm"

const AllIntegrations = () => {
  return (
    <div tw={"gap-4 flex"}>
      <IntegrationContainer
        type="button"
        to={generateRealmPath("plus", "/organization/integrations/salesforce")}
      >
        <SalesforceLogo />
        <div tw={"mt-6 text-xl"}>Salesforce</div>
      </IntegrationContainer>
    </div>
  )
}

const IntegrationContainer = styled(NavLink)`
  ${tw`bg-white border border-gray-150 rounded-xl flex flex-col items-center justify-center transition-all`}

  width: 326px;
  height: 161px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.04);

  &:hover {
    --tw-scale-x: 1.02;
    --tw-scale-y: 1.02;
    transform: var(--tw-transform);
  }

  &:active {
    --tw-scale-x: 0.98;
    --tw-scale-y: 0.98;
    transform: var(--tw-transform);
  }
`

export default AllIntegrations
