import { useMutation } from "@apollo/client"
import { isNil } from "lodash-es"
import React, { ChangeEvent } from "react"
import tw from "twin.macro"

import CheckIcon from "./assets/icons/check.svg"

import CopyIcon from "@/assets/icons/copy.svg"
import GoodyIconGrayOutline from "@/assets/icons/goody-gray-outline.svg"
import QuestionMark from "@/assets/icons/question-mark.svg"
import Button from "@/common/Button"
import Image from "@/common/Image"
import TextCopy from "@/common/TextCopy"
import Tooltip from "@/common/Tooltip"
import { Loader } from "@/common/UI"
import { isPresent } from "@/common/utilities"
import ArrowRight from "@/organization/settings/saml/assets/icons/arrow-right.svg"
import { OrganizationDisableSamlIdentityProvider } from "@/organization/settings/saml/graphql/OrganizationDisableSamlIdentityProvider"
import { OrganizationUpdateSamlIdentityProvider } from "@/organization/settings/saml/graphql/OrganizationUpdateSamlIdentityProvider"
import { useSamlSettings } from "@/organization/settings/saml/hooks/samlSettings"
import {
  OrganizationDisableSamlIdentityProviderMutation,
  OrganizationDisableSamlIdentityProviderMutationVariables,
  OrganizationUpdateSamlIdentityProviderMutation,
  OrganizationUpdateSamlIdentityProviderMutationVariables,
} from "@/types/graphql-types"

interface Props {
  onSave: () => void
}
const SamlSettings = ({ onSave }: Props) => {
  const { identityProvider, setIdentityProvider } = useSamlSettings()

  const [samlIdentityProviderUpdate, loading] = useMutation<
    OrganizationUpdateSamlIdentityProviderMutation,
    OrganizationUpdateSamlIdentityProviderMutationVariables
  >(OrganizationUpdateSamlIdentityProvider)

  const [samlIdentityProviderDisable] = useMutation<
    OrganizationDisableSamlIdentityProviderMutation,
    OrganizationDisableSamlIdentityProviderMutationVariables
  >(OrganizationDisableSamlIdentityProvider)

  if (isNil(identityProvider)) {
    return <Loader />
  }

  const handleSave = async () => {
    if (
      isPresent(identityProvider.organizationId) &&
      isPresent(identityProvider.ssoUrl) &&
      isPresent(identityProvider.entityId) &&
      isPresent(identityProvider.certificate)
    ) {
      const result = await samlIdentityProviderUpdate({
        variables: {
          organizationId: identityProvider.organizationId,
          ssoUrl: identityProvider.ssoUrl,
          entityId: identityProvider.entityId,
          certificate: identityProvider.certificate,
        },
      })

      const data = result?.data?.samlUpdateIdentityProvider
      if (data?.ok) {
        if (data.samlIdentityProvider) {
          setIdentityProvider(data.samlIdentityProvider)
          onSave()
        }
      } else {
        const error = data?.error ?? "An error occurred."
        alert(error)
      }
    } else {
      alert("The SSO URL, Entity ID, and certificate are all required.")
    }
  }

  const handleDisable = async () => {
    if (
      window.confirm(
        "Are you sure you want to disable SAML? You'll need to go through setup to enable it again.",
      )
    ) {
      const result = await samlIdentityProviderDisable({
        variables: {
          organizationId: identityProvider.organizationId,
        },
      })

      const data = result?.data?.samlDisableIdentityProvider
      if (data?.ok) {
        setIdentityProvider(null)
        onSave()
      } else {
        const error = data?.error ?? "An error occurred."
        alert(error)
      }
    }
  }

  return (
    <Container>
      <SectionContainer>
        <div tw="font-medium text-xl pl-6 mb-2">
          Goody Service Provider Details
        </div>
        <div tw="text-gray-500 pl-6 mb-6">
          When requested, enter these details into your identity provider.
        </div>
        <div tw="h-px bg-gray-200 mb-6"></div>
        <div tw="flex flex-row items-center mb-6 pr-8">
          <LeftColumnContent>
            <div tw="mr-3">ACS URL</div>
            <Tooltip
              trigger={
                <span>
                  <Image src={QuestionMark} />
                </span>
              }
              placement="auto"
            >
              <p>
                Also known as Assertion Consumer Service URL or Service Provider
                Sign-In URL
              </p>
            </Tooltip>
          </LeftColumnContent>
          <div tw="mr-6">{identityProvider.acsUrl}</div>
          <TextCopy
            copiedIcon={<Image src={CheckIcon} />}
            preCopiedIcon={<Image src={CopyIcon} />}
            copyText={identityProvider.acsUrl}
          />
        </div>
        <div tw="h-px bg-gray-200 mb-6"></div>
        <div tw="flex flex-row items-center mb-6">
          <LeftColumnContent>
            <div tw="mr-3">SP Entity ID</div>
            <Tooltip
              trigger={
                <span>
                  <Image src={QuestionMark} />
                </span>
              }
              placement="auto"
            >
              <p>Also known as Audience</p>
            </Tooltip>
          </LeftColumnContent>
          <div>{identityProvider.spEntityId}</div>
          <TextCopy
            copiedIcon={<Image src={CheckIcon} tw="ml-6" />}
            preCopiedIcon={<Image src={CopyIcon} tw="ml-6" />}
            copyText={identityProvider.spEntityId}
          />
        </div>
        <div tw="h-px bg-gray-200 mb-6"></div>
        <div tw="flex flex-row items-center mb-6">
          <LeftColumnContent>Name ID Format</LeftColumnContent>
          <div>Email</div>
        </div>
        <div tw="h-px bg-gray-200 mb-6"></div>
        <div tw="flex flex-row items-center mb-6">
          <LeftColumnContent>App Icon</LeftColumnContent>
          <Image src={GoodyIconGrayOutline} tw="mr-3" />
          <a
            href="https://assets.ongoody.com/static/images/goody-logo-512.png"
            target="_blank"
            tw="text-gray-450 hover:text-gray-600 active:text-gray-700"
            rel="noreferrer"
          >
            Download (optional)
          </a>
        </div>
      </SectionContainer>
      <SectionContainer>
        <div tw="font-medium text-xl pl-6 mb-2">Identity Provider details</div>
        <div tw="text-gray-500 pl-6 mb-6">
          Fill in these details from your identity provider.
        </div>
        <div tw="h-px bg-gray-200 mb-6"></div>
        <div tw="flex flex-row items-center mb-6 pr-8">
          <LeftColumnContent>SSO URL</LeftColumnContent>
          <div tw="w-[646px] rounded-lg pl-4 py-2 border border-gray-200">
            <input
              tw="w-full p-0 focus:outline-none rounded-md"
              className="data-hj-suppress ph-no-capture fs-mask"
              value={identityProvider.ssoUrl || ""}
              placeholder={"SSO URL"}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const newSsoUrl = event.target.value

                // @ts-ignore
                setIdentityProvider((currentIdentityProvider) => ({
                  ...currentIdentityProvider,
                  ssoUrl: newSsoUrl,
                }))
              }}
            />
          </div>
        </div>
        <div tw="flex flex-row items-center mb-6">
          <LeftColumnContent>Entity ID</LeftColumnContent>
          <div tw="w-[646px] rounded-lg pl-4 py-2 border border-gray-200">
            <input
              tw="w-full p-0 focus:outline-none"
              className="data-hj-suppress ph-no-capture fs-mask"
              value={identityProvider.entityId || ""}
              placeholder={"Entity ID"}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const newEntityId = event.target.value

                // @ts-ignore
                setIdentityProvider((currentIdentityProvider) => ({
                  ...currentIdentityProvider,
                  entityId: newEntityId,
                }))
              }}
            />
          </div>
        </div>
        <div tw="flex flex-row mb-6">
          <LeftColumnContent tw="items-start pt-2">
            Certificate
          </LeftColumnContent>
          <div tw="w-[646px] h-[93px] rounded-lg pl-4 py-2 border border-gray-200">
            <textarea
              tw="w-full h-full p-0 pr-4"
              className="data-hj-suppress ph-no-capture fs-mask"
              value={identityProvider.certificate || ""}
              placeholder={"Certificate"}
              onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
                const newCertificate = event.target.value

                // @ts-ignore
                setIdentityProvider((currentIdentityProvider) => ({
                  ...currentIdentityProvider,
                  certificate: newCertificate,
                }))
              }}
            />
          </div>
        </div>
      </SectionContainer>
      <SectionContainer>
        <div tw="font-medium text-xl pl-6 mb-2">User attribute mapping</div>
        <div tw="text-gray-500 pl-6 mb-6">
          Ensure the following fields are sent from your identity provider.
        </div>
        <div tw="h-px bg-gray-200 mb-6"></div>
        <div tw="flex flex-row items-center mb-5">
          <LeftColumnContent>
            <div tw="mr-7">First name</div>
            <Image src={ArrowRight} />
          </LeftColumnContent>
          <div tw="mr-[21px]">first_name</div>
          <TextCopy
            copiedIcon={<Image src={CheckIcon} />}
            preCopiedIcon={<Image src={CopyIcon} />}
            copyText={"first_name"}
          />
        </div>
        <div tw="flex flex-row items-center mb-6">
          <LeftColumnContent>
            <div tw="mr-[30px]">Last name</div>
            <Image src={ArrowRight} />
          </LeftColumnContent>
          <div tw="mr-6">last_name</div>
          <TextCopy
            copiedIcon={<Image src={CheckIcon} />}
            preCopiedIcon={<Image src={CopyIcon} />}
            copyText={"last_name"}
          />
        </div>
      </SectionContainer>
      <div tw="flex flex-row items-center">
        <Button variant={"primary-new"} onClick={handleSave} tw="mr-6">
          Save {!identityProvider.enabled && "and enable SAML"}
        </Button>
        {identityProvider.enabled && (
          <button tw="text-[#EB5757]" onClick={handleDisable}>
            Disable SAML
          </button>
        )}
      </div>
    </Container>
  )
}

const Container = tw.div`
 flex flex-col items-start w-full gap-y-9
`

const SectionContainer = tw.div`
  rounded-xl border border-gray-200 pt-6 w-full
`

const LeftColumnContent = tw.div`
 flex flex-row items-center font-semibold pl-6 w-[173px]
`

export default SamlSettings
