import React from "react"

import Box from "../../../common/Box"
import {
  SelectionGroup,
  SelectionIndicator,
  SelectionLabel,
} from "../../../common/SelectionGroup"

import { BalanceDepositFundingMethod } from "@/types/graphql-types"

interface Props {
  method: string
  setMethod: (value: BalanceDepositFundingMethod) => void
}

export default function FundingMethod({ method, setMethod }: Props) {
  return (
    <Box tw="p-6 md:p-10 mt-8">
      <div tw="font-semibold text-xl">Funding method</div>
      <div tw="pt-6">
        <div>
          <SelectionGroup
            onClick={() => setMethod(BalanceDepositFundingMethod.CREDIT_CARD)}
          >
            <SelectionIndicator
              selected={method === BalanceDepositFundingMethod.CREDIT_CARD}
            />
            <SelectionLabel
              selected={method === BalanceDepositFundingMethod.CREDIT_CARD}
            >
              Credit card
            </SelectionLabel>
          </SelectionGroup>
        </div>
        <div>
          <SelectionGroup
            onClick={() => setMethod(BalanceDepositFundingMethod.INVOICE)}
          >
            <SelectionIndicator
              selected={method === BalanceDepositFundingMethod.INVOICE}
            />
            <SelectionLabel
              selected={method === BalanceDepositFundingMethod.INVOICE}
            >
              Request invoice
            </SelectionLabel>
          </SelectionGroup>
        </div>
      </div>
    </Box>
  )
}
