import React from "react"
import tw from "twin.macro"

import { SettingBase } from "../index"

import { useFeatureAccess } from "@/common/hooks/featureAccess"
import { useFeatureFlags } from "@/common/hooks/featureFlags"
import { InternationalShippingTierOptions } from "@/common/internationalShipping/InternationalShippingTierOptions"
import InternationalShippingOptions from "@/common/InternationalShippingOptions"
import { ProTag } from "@/common/ProTag"
import { SelectionGroupColorEnum } from "@/common/SelectionGroup/types"
import { ReactComponent as Icon } from "@/send/v3/options/icons/international-shipping.svg"
import { InternationalShippingTierEnum } from "@/types/graphql-types"

interface Props {
  selectedTier: InternationalShippingTierEnum
  setInternationalShippingTier: (
    internationalShippingTier: InternationalShippingTierEnum,
  ) => void
  giftCardsEnabled: boolean
  setGiftCardsEnabled: (giftCardsEnabled: boolean) => void
  hidden?: boolean
  isExpandable?: boolean
  showSwapMultipleWarning?: boolean
  disallowInternational?: boolean
  color?: SelectionGroupColorEnum
  shortenPreviewNames?: boolean
  alignPreviewName?: string
  useDefaultHeader?: boolean
  leftPadChildrenContainer?: boolean
}

export const InternationalShippingSetting = ({
  selectedTier,
  setInternationalShippingTier,
  giftCardsEnabled,
  setGiftCardsEnabled,
  hidden,
  isExpandable,
  showSwapMultipleWarning,
  disallowInternational,
  color,
  shortenPreviewNames,
  alignPreviewName,
  useDefaultHeader,
  leftPadChildrenContainer,
}: Props) => {
  const { hasFeature } = useFeatureAccess()
  const { hasFeatureFlag } = useFeatureFlags()
  const hasPro = hasFeature("pro_plan")

  if (hidden) {
    return null
  }

  const giftCardTitle = shortenPreviewNames
    ? "Gift cards only"
    : "International gift cards only"

  const getPreviewText = (selectedTier: InternationalShippingTierEnum) => {
    switch (selectedTier) {
      case InternationalShippingTierEnum.disabled:
        return "Disabled"
      case InternationalShippingTierEnum.gift_cards:
        return giftCardTitle
      case InternationalShippingTierEnum.global_relay:
        return "Full global catalog"
      case InternationalShippingTierEnum.standard:
        return "Standard"
      case InternationalShippingTierEnum.full:
        return "Global"
      default:
        return null
    }
  }

  const usingGlobalParters = hasFeatureFlag("global_partners")
  const showProUpsell = !usingGlobalParters && !hasPro

  return (
    <SettingBase
      name="International shipping"
      icon={<Icon />}
      preview={
        <div
          tw="flex flex-row items-center gap-3"
          // When non-pro, account for the extra padding added by ProTag
          css={[
            showProUpsell && tw`mt-[-2px]`,
            alignPreviewName === "right" && tw`md:justify-end`,
          ]}
        >
          {getPreviewText(selectedTier)}
          {showProUpsell && (
            <ProTag feature="international_shipping" stopPropagation={true} />
          )}
        </div>
      }
      color={color}
      isExpandable={isExpandable}
      leftPadChildrenContainer={leftPadChildrenContainer}
    >
      {usingGlobalParters ? (
        <InternationalShippingOptions
          showDisabledTier
          internationalShippingTier={selectedTier}
          setInternationalShippingTier={setInternationalShippingTier}
          giftCardsEnabled={giftCardsEnabled}
          setGiftCardsEnabled={setGiftCardsEnabled}
          color={color}
        />
      ) : (
        <InternationalShippingTierOptions
          useDefaultHeader={useDefaultHeader}
          selectedTier={selectedTier}
          setInternationalShippingTier={setInternationalShippingTier}
          disallowInternational={disallowInternational}
          showSwapMultipleWarning={showSwapMultipleWarning}
          color={color}
        />
      )}
    </SettingBase>
  )
}
