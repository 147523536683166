import { useContext, useState } from "react"
import tw from "twin.macro"

import InternationalShippingCountriesModal from "./components/InternationalShippingCountriesModal"
import InternationalShippingCountrySummary from "./components/InternationalShippingCountrySummary"
import { useGiftShippingCountries } from "./hooks/useGiftShippingCountries"
import { OptionsContext } from "../../OptionsContext"
import { ReactComponent as Icon } from "../icons/international-shipping.svg"
import OptionBase from "../OptionBase"

import { useFeatureAccess } from "@/common/hooks/featureAccess"
import { useFeatureFlags } from "@/common/hooks/featureFlags"
import { useGiftCart, useGiftData } from "@/common/hooks/giftData"
import InternationalShippingOptions from "@/common/InternationalShippingOptions"
import { ProTag } from "@/common/ProTag"
import InternationalShippingTierOptions from "@/internationalShipping/internationalShippingTierOptions"
import { GiftSwapTypeEnum } from "@/types/graphql-types"

export default function InternationalShipping() {
  const { currentGift, isDirectSend } = useContext(OptionsContext)
  const { cartQuantity } = useGiftCart()

  const { setInternationalShippingTier, setGiftSettings } = useGiftData()
  const {
    shipsToCountries,
    swapsToCountries,
    shippingSummaryText,
    cartIsGloballyEligible,
    shipsToGlobalCountries,
    swapsToGlobalCountries,
  } = useGiftShippingCountries()

  const { hasFeatureFlag } = useFeatureFlags()
  const { hasFeature } = useFeatureAccess()
  const hasPro = hasFeature("pro_plan")

  const [openGlobalModal, setOpenGlobalModal] = useState(false)

  if (isDirectSend) {
    return null
  }

  const usingGlobalParters = hasFeatureFlag("global_partners")
  const showProUpsell = !usingGlobalParters && !hasPro

  return (
    <OptionBase
      name="International shipping"
      icon={<Icon />}
      preview={
        <div
          tw="flex flex-row items-center gap-3"
          // When non-pro, account for the extra padding added by ProTag
          css={[showProUpsell && tw`mt-[-2px]`]}
        >
          {shippingSummaryText}
          {showProUpsell && (
            <ProTag feature="international_shipping" stopPropagation={true} />
          )}
        </div>
      }
      fullWidth={usingGlobalParters && shipsToCountries.length > 0}
    >
      {usingGlobalParters ? (
        <div tw="flex flex-col sm:flex-row gap-6">
          <InternationalShippingOptions
            internationalShippingTier={currentGift.internationalShippingTier}
            setInternationalShippingTier={setInternationalShippingTier}
            giftCardsEnabled={currentGift.settings.giftCardsEnabled || false}
            setGiftCardsEnabled={(giftCardsEnabled) =>
              setGiftSettings({ giftCardsEnabled })
            }
            swapType={currentGift.swapType}
            disableGlobalTier={cartQuantity >= 2}
            cartIsGloballyEligible={cartIsGloballyEligible}
            onGlobalCountriesClick={() => setOpenGlobalModal(true)}
          />
          <InternationalShippingCountrySummary
            shipsToCountries={shipsToCountries}
            swapsToCountries={swapsToCountries}
            internationalShippingTier={currentGift.internationalShippingTier}
            onGlobalInfoClick={() => setOpenGlobalModal(true)}
          />
          <InternationalShippingCountriesModal
            open={openGlobalModal}
            onClose={() => setOpenGlobalModal(false)}
            shipsToGlobalCountries={shipsToGlobalCountries}
            swapsToGlobalCountries={swapsToGlobalCountries}
          />
        </div>
      ) : (
        <InternationalShippingTierOptions
          object={currentGift}
          setInternationalShippingTier={setInternationalShippingTier}
          disallowInternational={cartQuantity >= 2}
          showSwapMultipleWarning={
            currentGift.swapType === GiftSwapTypeEnum.swap_multiple
          }
          header={<></>}
        />
      )}
    </OptionBase>
  )
}
