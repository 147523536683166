import "twin.macro"
import { useDeveloperModeContext } from "@/common/hooks/useDeveloperModeContext"

interface Props {
  productID: string
}

// When Developer Mode is enabled in Account > API Keys, the product ID on a
// PDP or a Gift of Choice PDP will be displayed so it can be copied.
export default function DevModeProductID({ productID }: Props) {
  const { enabled: devEnabled } = useDeveloperModeContext()

  if (!devEnabled) {
    return null
  }

  return (
    <div tw="mt-6 flex flex-row items-center gap-3">
      <div tw="text-gray-400 font-medium">Product ID</div>
      <input
        tw="focus:outline-none flex-1 text-gray-600 block"
        value={productID}
        readOnly
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          e.currentTarget.select()
        }}
      />
    </div>
  )
}
