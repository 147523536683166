import type { ReactNode } from "react"
import tw, { css } from "twin.macro"

interface Props {
  variant: "primary" | "secondary"
  children: ReactNode
  element?: "button" | "a"
  href?: string
  onClick?: () => void
  target?: string
  rel?: string
}

const COMMON_STYLES = tw`px-10 py-4 border rounded-full active:scale-95 transition-all`
const PRIMARY_STYLES = tw`bg-primary-new-600 font-semibold text-white border-transparent hover:bg-primary-new-700`
const SECONDARY_STYLES = tw`bg-white border-primary-new-400 text-primary-new-600 hover:bg-primary-new-000`

export default function RoundedButton({
  variant,
  children,
  element,
  ...restProps
}: Props) {
  const styles = css`
    ${COMMON_STYLES}
    ${variant === "primary" ? PRIMARY_STYLES : SECONDARY_STYLES}
  `

  if (element === "a") {
    return (
      <a css={styles} {...restProps}>
        {children}
      </a>
    )
  } else {
    return (
      <button css={styles} {...restProps}>
        {children}
      </button>
    )
  }
}
