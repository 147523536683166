import React from "react"
import ScrollAnimation from "react-animate-on-scroll"
import tw, { styled } from "twin.macro"

import apothekeLogoPng from "../../images/logos/apotheke.png"
import brooklinenLogoPng from "../../images/logos/brooklinen.png"
import dagneLogoSvg from "../../images/logos/dagne.svg"
import food52LogoSvg from "../../images/logos/food52.svg"
import levainLogoSvg from "../../images/logos/levain.svg"
import lulasLogoPng from "../../images/logos/lulas.png"
import apothekeProductJpg from "../../images/products/apotheke.jpg"
import brooklinenProductJpg from "../../images/products/brooklinen.jpg"
import dagneProductJpg from "../../images/products/dagne.jpg"
import food52ProductJpg from "../../images/products/food52.jpg"
import levainProductJpg from "../../images/products/levain.jpg"
import lulasProductJpg from "../../images/products/lulas.jpg"

interface BrandBoxProps {
  backgroundColor: string
  className?: string
  logoHeight: number
  logoSrc: string
  productImageSrc: string
}
const BrandBoxBase: React.FC<BrandBoxProps> = ({
  logoSrc,
  productImageSrc,
  className,
}) => (
  <div className={className}>
    <header>
      <figure>
        <img src={logoSrc} alt="Company logo" />
      </figure>
    </header>
    <img src={productImageSrc} alt="Product" />
  </div>
)

const BrandBox = styled(BrandBoxBase)`
  background-color: ${(props) => props.backgroundColor};
  ${tw`rounded-lg shadow-lg overflow-hidden flex flex-col justify-between`}

  header {
    ${tw`flex flex-col items-center p-3`}

    figure {
      ${tw`flex items-center justify-center h-12`}

      img {
        width: auto;
        height: ${(props) => props.logoHeight}px;
      }
    }
  }
`

export const Brands = () => {
  return (
    <div tw="w-full overflow-hidden lg:overflow-visible">
      <div tw="max-w-2xl mx-auto text-center">
        <h2 tw="text-gray-900 text-opacity-80 text-3xl lg:text-5xl mb-5 font-light px-8 md:px-0">
          Always send the perfect gift
        </h2>
        <div tw="text-black text-lg lg:text-1.5xl pb-20 md:pb-12 leading-normal px-4 md:px-0">
          Recipients have the option to choose from our curated selection
          in-trend gifts, with over{" "}
          <b tw="font-bold">100 brands to choose from</b>
        </div>
      </div>

      <div tw="grid grid-cols-2 gap-4 lg:grid-cols-6 lg:grid-flow-row-dense transform scale-110 pb-12 sm:pb-0 lg:pb-8 sm:scale-100 lg:scale-105">
        <ScrollAnimation animateIn="fadeInUpSmall" duration={0.4} animateOnce>
          <BrandBox
            backgroundColor="#F9F1EB"
            logoHeight={20}
            logoSrc={brooklinenLogoPng}
            productImageSrc={brooklinenProductJpg}
            tw="lg:col-start-3"
          />
        </ScrollAnimation>
        <ScrollAnimation
          animateIn="fadeInUpSmall"
          duration={0.6}
          delay={100}
          animateOnce
        >
          <BrandBox
            backgroundColor="#001786"
            logoHeight={24}
            logoSrc={levainLogoSvg}
            productImageSrc={levainProductJpg}
            tw="lg:col-start-2"
          />
        </ScrollAnimation>
        <ScrollAnimation
          animateIn="fadeInUpSmall"
          duration={0.6}
          delay={200}
          animateOnce
        >
          <BrandBox
            backgroundColor="#474756"
            logoHeight={15}
            logoSrc={apothekeLogoPng}
            productImageSrc={apothekeProductJpg}
            tw="lg:col-start-1"
          />
        </ScrollAnimation>
        <ScrollAnimation
          animateIn="fadeInUpSmall"
          duration={0.6}
          delay={300}
          animateOnce
        >
          <BrandBox
            backgroundColor="#4C5E61"
            logoHeight={15}
            logoSrc={food52LogoSvg}
            productImageSrc={food52ProductJpg}
          />
        </ScrollAnimation>
        <ScrollAnimation
          animateIn="fadeInUpSmall"
          duration={0.6}
          delay={400}
          animateOnce
        >
          <BrandBox
            backgroundColor="white"
            logoHeight={16}
            logoSrc={lulasLogoPng}
            productImageSrc={lulasProductJpg}
          />
        </ScrollAnimation>
        <ScrollAnimation
          animateIn="fadeInUpSmall"
          duration={0.6}
          delay={500}
          animateOnce
        >
          <BrandBox
            backgroundColor="#D4ECFB"
            logoHeight={10}
            logoSrc={dagneLogoSvg}
            productImageSrc={dagneProductJpg}
          />
        </ScrollAnimation>
      </div>
    </div>
  )
}
