import React from "react"
import { Helmet } from "react-helmet-async"

import GiftsOccasions from "./GiftsOccasions"
import BrandsCollection from "./images/BrandsCollection.png"
import HeroDesktop from "./images/HeroDesktop.png"
import HeroMobile from "./images/HeroMobile.png"
import HowGoodyIsDifferentDesktop from "./images/HowGoodyIsDifferentDesktop.png"
import HowGoodyIsDifferentMobile from "./images/HowGoodyIsDifferentMobile.png"
import WhyEmployeeGifting from "./WhyEmployeeGifting"
import { useGlobalState } from "../../../common/GlobalState"
import { useWindowSize } from "../../../common/hooks/useWindowSize"
import { generateRealmPath } from "../../../common/realm"
import Carousel from "../../business/Carousel"
import Brands from "../components/Brands"
import CallToAction from "../components/CallToAction"
import Divider from "../components/Divider"
import Hero from "../components/Hero"
import HowGoodyIsDifferent from "../components/HowGoodyIsDifferent"
import IntegrateHR from "../components/IntegrateHR"
import OurCollection from "../components/OurCollection"

const EmployeeRecognition: React.FC = () => {
  const [signedIn] = useGlobalState("signedIn")
  const goTo = (path: string) => generateRealmPath("business", path)

  const windowSize = useWindowSize()

  return (
    <>
      <Helmet>
        <title>Employee Recognition — Goody for Business</title>
        <meta
          name="description"
          content="Effortless, elevated employee gifts. Send amazing business gifts your team actually want and automate employee gifting."
        />
      </Helmet>

      <Hero
        heading="Employee Recognition"
        title="Effortless, elevated employee gifts."
        subtitle={
          windowSize.lessThen("lg")
            ? "Send amazing business gifts as easily as a text, all in one place."
            : undefined
        }
        checkItems={[
          "️Send curated gifts your team will actually want.",
          "Automate gifting for birthdays, work anniversaries, and onboarding.",
        ]}
        variant="how-it-works"
        primaryAction={{
          text: "Start gifting",
          linkTo: goTo("/signup"),
        }}
        image={{
          desktop: HeroDesktop,
          mobile: HeroMobile,
        }}
      />

      <div tw="mt-24">
        <Brands
          title="Thousands of companies use Goody for employee recognition"
          variant="secondary"
        />
      </div>

      <Divider tw="mt-16 md:mt-24 md:pt-0.5 mb-12 md:mb-20" />

      <div tw="mb-16 pb-2 lg:mb-40 lg:pb-0">
        <WhyEmployeeGifting />
      </div>

      <div tw="pb-12">
        <HowGoodyIsDifferent
          linkTo={goTo("/how-it-works")}
          options={[
            {
              title: "Employees can swap gifts.",
              content:
                "Recipients can swap for equal or lower-priced alternatives.",
            },
            {
              title: "Global gifting made easy.",
              content:
                "Send international employees curated gifts that cross borders — or local gift card brands.",
            },
            {
              title: "Automate gifts for employee milestones.",
              content:
                "Connect with 30+ HR platforms and send automatic birthday, work anniversary, and onboarding gifts.",
            },
          ]}
          image={{
            mobile: HowGoodyIsDifferentMobile,
            desktop: HowGoodyIsDifferentDesktop,
          }}
        />
      </div>

      <IntegrateHR
        heading="Autogifting"
        title="Thoughtful gifts. Effortless integrations."
        description="Goody integrates with 30+ HR platforms, powering automated birthday, work anniversary, and onboarding gifts."
        primaryAction={{
          text: "Discover Autogifting",
          linkTo: goTo("/autogifting"),
        }}
        background="dark"
      />

      <div tw="my-20 lg:py-2">
        <GiftsOccasions />
      </div>

      <div tw="md:mx-24 md:mb-20">
        <OurCollection
          title="Curated, on-trend gifts your team will really love."
          description="Plus, find and support brands whose values your team shares, like BIPOC, LGBTQ+ or female-founded, social-impact-driven, or sustainable."
          brandsImage={BrandsCollection}
          primaryAction={{ linkTo: goTo("/browse") }}
        />
      </div>

      <Carousel />

      <CallToAction
        signedIn={signedIn}
        title="Take Goody for a spin."
        description="When you sign up for Goody, you’ll get a free $20 gift credit you can use to treat yourself. No credit card required."
      />
      <Divider tw="mt-20" />
    </>
  )
}

export default EmployeeRecognition
