import { useLocation } from "react-router-dom"

import { PREFIX_REGEX } from "./realmConstants"
import { PathRealm, Realm } from "./realmTypes"

import { BusinessSegment, CategorySegment } from "@/types/graphql-types"

// Hook for use inside a RealmRoute
// Saves realm for paths that have specific realms.
//
// Parameters:
// validRealm: realm or list of realms that are valid for this route
// path: path for this route
//

// Helper to make sure links to pages have the right prefix attached it.
// The route would redirect anyways, but this way we don't have the
// url bar flash
export const generateRealmPath = (
  realm: PathRealm | "all" | null,
  path: string,
) => {
  const prefix =
    realm === "consumer" || realm === "consumer_redirect"
      ? "/personal"
      : realm === null || realm === "all"
      ? ""
      : `/${realm}`

  const basePath = extractBasePath(path)

  // Special root paths:

  // Business LP: This is the global homepage. We want to set users to the
  // business realm, but we don't want to add the /business prefix to the
  // path since we want it to be at the website root.
  if (realm === "business" && basePath === "/") {
    return ""
  }

  // Browse page: Normally, browse pages should be called with realm set to
  // null. However, some call sites might call this with a realm set to a
  // non-null value, so this is a stopgap.
  if (basePath.startsWith("/browse")) {
    return basePath
  }

  if (basePath === "/" && prefix !== "") {
    return `${prefix}`
  } else {
    return `${prefix}${basePath}`
  }
}

export const extractBasePath = (path: string) =>
  path?.replace(PREFIX_REGEX, "$2") ?? ""

// Gets the realm from current path
export const useCurrentPathRealm = () => {
  const location = useLocation()
  return getRealmFromPath(location.pathname)
}

// Gets the realm from the path prefix
export const getRealmFromPath = (pathname: string): PathRealm => {
  const match = pathname.match(PREFIX_REGEX)
  const prefix = match?.[1]

  if (prefix === "/plus") {
    return "plus"
  } else if (prefix === "/business") {
    return "business"
  } else if (prefix === "/personal") {
    return "consumer"
  } else {
    return "business"
  }
}

export const getSegmentFromRealm = (pathRealm: PathRealm | null) =>
  pathRealm === "consumer" || pathRealm === null
    ? CategorySegment.STANDARD
    : CategorySegment.PLUS

export const getBusinessSegmentFromRealm = (realm: Realm | null) =>
  realm === "consumer" || realm === null
    ? BusinessSegment.consumer
    : BusinessSegment.business
