import { ReactNode } from "react"
import tw from "twin.macro"

interface Props {
  scrollElemRef: React.RefObject<HTMLDivElement>
  decideIfNextPageShouldLoad: (index: number) => void
  giftOptionCount: number
  children: ReactNode
}

export const GiftOptionsListColumn = ({
  children,
  scrollElemRef,
  decideIfNextPageShouldLoad,
  giftOptionCount,
}: Props) => {
  const handleScroll = () => {
    if (scrollElemRef.current) {
      if (
        scrollElemRef.current.scrollTop >
        scrollElemRef.current.scrollHeight -
          scrollElemRef.current.clientHeight -
          100
      ) {
        decideIfNextPageShouldLoad(giftOptionCount)
      }
    }
  }

  return (
    <GiftOptionsListColumnContainer
      onScroll={handleScroll}
      ref={scrollElemRef}
      tw="overflow-y-visible"
    >
      <div tw="lg:p-0 lg:pb-12 grid md:grid-cols-2 xl:grid-cols-3 gap-x-10 gap-y-14">
        {children}
      </div>
    </GiftOptionsListColumnContainer>
  )
}

const GiftOptionsListColumnContainer = tw.div`overflow-y-auto w-full lg:mt-0`
