import { gql, useQuery } from "@apollo/client"
import { Link } from "react-router-dom"

import { track } from "../common/analytics"

import { BlogLatestPostsQuery } from "@/types/graphql-types"

interface Props {
  postSlug: string
}

export default function LatestPosts({ postSlug }: Props) {
  const { data } = useQuery<BlogLatestPostsQuery>(BLOG_LATEST_POSTS_QUERY)
  const posts = data?.webflowPosts

  if (!posts) return null

  return (
    <div tw="pt-36 pb-8">
      <div tw="pt-6 border-t border-gray-200">
        <div tw="text-lg font-medium text-gray-500 pb-6">Latest blog posts</div>
        <div tw="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-8">
          {posts?.map((post) => (
            <Link
              to={`/blog/${post.slug}`}
              key={post.slug}
              tw="mb-8 block hover:text-primary-new-600 transition-all active:(scale-95)"
              onClick={() =>
                track("Blog - Post - Click Latest Post", {
                  postSlug,
                  latestPostSlug: post.slug,
                })
              }
            >
              {post.thumbnailUrl && (
                <img
                  src={post.thumbnailUrl}
                  alt=""
                  tw="min-h-[150px] rounded-lg object-cover"
                  style={{ aspectRatio: "9 / 5" }}
                />
              )}
              <div tw="pt-4 font-arizona-mix text-xl leading-normal">
                {post.title}
              </div>
              {post.overview && (
                <div tw="text-gray-450 leading-normal pt-3">
                  {post.overview}
                </div>
              )}
            </Link>
          ))}
        </div>
      </div>
    </div>
  )
}

const BLOG_LATEST_POSTS_QUERY = gql`
  query BlogLatestPosts {
    webflowPosts(limit: 4) {
      title
      thumbnailUrl
      slug
      overview
      excerpt
    }
  }
`
