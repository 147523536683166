import {
  CartProductShipmentFragment,
  ImageFragment,
} from "@/types/graphql-types"

export const getCartProductImage = (
  cartProduct: CartProductShipmentFragment,
  customStoreImageThumb: ImageFragment | null | undefined = null,
) => {
  if (!!customStoreImageThumb) {
    return customStoreImageThumb
  } else if (
    cartProduct.productDetails.isBook &&
    cartProduct.productDetails.bookImage
  ) {
    return { url: cartProduct.productDetails.bookImage }
  } else if (!!cartProduct.productFragment.primaryImage?.imageThumb) {
    return cartProduct.productFragment.primaryImage.imageThumb
  }

  return null
}

export const getCartProductSubtitle = (
  cartProduct: CartProductShipmentFragment,
) =>
  cartProduct.productDetails.isBook
    ? cartProduct.productDetails.bookContributors
    : cartProduct.productFragment.brandName
