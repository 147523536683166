import { gql } from "@apollo/client"

export const OrganizationDisableSamlIdentityProvider = gql`
  mutation OrganizationDisableSamlIdentityProvider($organizationId: ID!) {
    samlDisableIdentityProvider(organizationId: $organizationId) {
      ok
      error
    }
  }
`
