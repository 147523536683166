import { gql, useApolloClient, useMutation } from "@apollo/client"
import { useEffect, useState } from "react"

import { ROOT_DATA_QUERY } from "../../graphql"
import { CurrentWorkspace, useGlobalState } from "../GlobalState"
import { setWorkspaceContext } from "../workspaceContext"
import { sortWorkspaces } from "../workspaces"

import { BaseMeFragment } from "@/types/graphql-types"

export type UpdateWorkspace = (updatedWorkspace: CurrentWorkspace) => void

export const SESSION_STORAGE_KEY = "currentWorkspaceId"

interface UseCurrentWorkspace {
  currentWorkspace: CurrentWorkspace | null
  updateCurrentWorkspace: UpdateWorkspace
  resetCurrentWorkspace: () => void
}

export const useCurrentWorkspace = (): UseCurrentWorkspace => {
  const [user] = useGlobalState("user")
  const [currentWorkspace, setCurrentWorkspace] =
    useGlobalState("currentWorkspace")
  const [persistLastUsedWorkspaceId] = useMutation(
    PERSIST_LAST_USED_WORKSPACE_ID,
  )
  const [needsWorkspaceReset, setNeedsWorkspaceReset] = useState(false)

  const client = useApolloClient()

  const updateCurrentWorkspace: UpdateWorkspace = (
    updatedWorkspace: CurrentWorkspace,
  ) => {
    sessionStorage.setItem(SESSION_STORAGE_KEY, updatedWorkspace.id)
    setCurrentWorkspace(updatedWorkspace)
    setWorkspaceContext(updatedWorkspace)

    const workspaceHasChanged =
      user?.lastUsedWorkspace?.id !== updatedWorkspace.id

    if (workspaceHasChanged) {
      // Some workspace queries (eventually all) will be under the { workspace } root field.
      // When switching workspaces, evict this field from the cache so no stale data
      // remains for subfields of this field.
      client.cache.evict({ id: "ROOT_QUERY", fieldName: "workspace" })
      // Garbage collect any objects that are no longer reachable.
      client.cache.gc()

      persistLastUsedWorkspaceId({
        variables: { lastUsedWorkspaceId: updatedWorkspace.id },
        refetchQueries: [{ query: ROOT_DATA_QUERY }],
      })
    }
  }

  const resetCurrentWorkspace = () => setNeedsWorkspaceReset(true)

  useEffect(() => {
    if (needsWorkspaceReset && user?.workspaces && user.workspaces.length > 0) {
      setToDefaultWorkspace()
      setNeedsWorkspaceReset(false)
    }
  }, [needsWorkspaceReset, user?.workspaces])

  const setToDefaultWorkspace = () => {
    if (user) {
      const { workspaces, lastUsedWorkspace } = user
      const sessionWorkspaceId = sessionStorage.getItem(SESSION_STORAGE_KEY)
      const validatedSessionWorkspace = workspaces.find(
        ({ id }) => id === sessionWorkspaceId,
      )

      // sortWorkspaces(workspaces)[0] could return undefined if the index is out of
      // range, so workspaceToSelect could be undefined.
      // https://stackoverflow.com/a/50647536
      const workspaceToSelect: BaseMeFragment["workspaces"][0] | undefined =
        validatedSessionWorkspace ||
        lastUsedWorkspace ||
        sortWorkspaces(workspaces)[0]

      if (workspaceToSelect) {
        updateCurrentWorkspace(workspaceToSelect)
      }
    }
  }

  useEffect(() => {
    setToDefaultWorkspace()
  }, [user])

  return { currentWorkspace, updateCurrentWorkspace, resetCurrentWorkspace }
}

const PERSIST_LAST_USED_WORKSPACE_ID = gql`
  mutation Persist_Last_Used_Workspace_Id($lastUsedWorkspaceId: ID!) {
    userLastUsedWorkspaceUpdate(lastUsedWorkspaceId: $lastUsedWorkspaceId) {
      ok
    }
  }
`
