import { gql, useMutation, useQuery } from "@apollo/client"
import { Link, useHistory } from "react-router-dom"
import { useIntercom } from "react-use-intercom"

import { ORGANIZATION_SUBSCRIPTION_QUERY } from "./OrganizationSubscription"
import { ReactComponent as ChatBubble } from "../assets/icons/chat-bubble-black.svg"
import Button from "../common/Button"
import { generateRealmPath } from "../common/realm"
import { successToast } from "../common/toast"
import Container from "../sites/App/Container"

import { SubscriptionManagement } from "@/types/graphql-types"
import {
  Organization_OrganizationSubscriptionCancelQuery,
  Organization_OrganizationSubscriptionRegisterCancellationMutation,
  Organization_OrganizationSubscriptionRegisterCancellationMutationVariables,
} from "@/types/graphql-types"

export default function SubscriptionCancel() {
  const { show } = useIntercom()

  const { data } = useQuery<Organization_OrganizationSubscriptionCancelQuery>(
    SUBSCRIPTION_CANCEL_QUERY,
  )

  const [registerCancellation, { loading: mutationLoading }] = useMutation<
    Organization_OrganizationSubscriptionRegisterCancellationMutation,
    Organization_OrganizationSubscriptionRegisterCancellationMutationVariables
  >(SUBSCRIPTION_CANCEL_MUTATION)

  // Trials cancel immediately.
  const cancelImmediately = !!data?.organization?.subscription?.isTrialActive
  const isManaged =
    data?.organization?.subscription?.managementType ===
    SubscriptionManagement.MANAGED

  const history = useHistory()

  const handleSubmit = async () => {
    const res = await registerCancellation({
      refetchQueries: [{ query: ORGANIZATION_SUBSCRIPTION_QUERY }],
    })

    const data = res?.data?.subscriptionRegisterCancellation

    if (data?.ok) {
      successToast(
        "Your subscription was canceled. Please allow a few minutes for the cancellation to take place.",
      )
      history.push(generateRealmPath("plus", "/organization/subscription"))
      return
    } else {
      window.alert(
        data?.error ||
          "An unexpected error occurred. Please contact plus-support@ongoody.com.",
      )
    }
  }

  // Don't show page until query is complete.
  if (!data) {
    return null
  }

  return (
    <Container tw="px-5 pt-8" css={{ minHeight: "80vh" }}>
      <div tw="max-w-md mx-auto">
        <div tw="shadow-min p-9 border border-gray-150 rounded-xl">
          <h2 tw="font-medium text-xl">Cancel subscription</h2>
          <p tw="font-text pt-5 text-gray-700">
            {isManaged
              ? "Your subscription is managed by the sales team. Please contact your account executive, or plus-support@ongoody.com, for assistance."
              : cancelImmediately
              ? "Your trial will end and your plan will be downgraded to Free immediately. Here's what will happen:"
              : "Your plan will be downgraded to Free at the end of your billing term. Here's what will happen at the end of your term:"}
          </p>
          {!isManaged && (
            <>
              <ul tw="font-text text-gray-700 list-disc pl-4">
                <li tw="pt-4">All integrations will be disconnected.</li>
                <li tw="pt-4">
                  Any remaining Organization Balance will be forfeited.
                </li>
                <li tw="pt-4">
                  Contacts and upcoming autogifts associated with your HR
                  integration will be deleted.
                </li>
                <li tw="pt-4">You'll lose access to your custom stores.</li>
                <li tw="pt-4">
                  You won't be able to access the Contacts or Budgeting page,
                  and the Dashboard Contacts Calendar.
                </li>
                {cancelImmediately && (
                  <li tw="pt-4">You won't be billed for your trial.</li>
                )}
              </ul>
              <div tw="pt-8 flex flex-col md:flex-row gap-2 items-center justify-between">
                <Button onClick={handleSubmit} disabled={mutationLoading}>
                  {mutationLoading
                    ? "Please wait..."
                    : "Yes, cancel my subscription"}
                </Button>
                <Link
                  to={generateRealmPath("plus", "/organization/subscription")}
                  tw="text-gray-500 hover:text-gray-700 transition-colors"
                >
                  No, go back
                </Link>
              </div>
            </>
          )}
        </div>
        <div tw="mt-6 bg-gray-100 p-4 pl-6 rounded-xl flex flex-col gap-2 md:flex-row items-center justify-between">
          <div>Questions?</div>
          <Button onClick={show}>
            <ChatBubble />
            <div>Contact support</div>
          </Button>
        </div>
      </div>
    </Container>
  )
}

const SUBSCRIPTION_CANCEL_QUERY = gql`
  query Organization_OrganizationSubscriptionCancel {
    organization {
      id
      subscription {
        id
        isTrialActive
        managementType
      }
    }
  }
`

const SUBSCRIPTION_CANCEL_MUTATION = gql`
  mutation Organization_OrganizationSubscriptionRegisterCancellation {
    subscriptionRegisterCancellation(ownerType: ORGANIZATION) {
      ok
      error
    }
  }
`
