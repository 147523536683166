import styled from "@emotion/styled"
import React, { ChangeEvent, useState } from "react"
import tw from "twin.macro"

import {
  Countries,
  CountriesLongName,
  Flag,
  allCountries,
  internationalCountries,
} from "./InternationalShipping"
import SearchField from "../../../common/SearchField"

interface Props {
  selectedShippingCountry: Countries
  onClick: (country: Countries) => void
  insideDropdown?: boolean
  onlyInternationalCountries?: boolean
}

const CountryList = ({
  selectedShippingCountry,
  onClick,
  insideDropdown,
  onlyInternationalCountries = true,
}: Props) => {
  const [searchTerm, setSearchTerm] = useState("")
  const handleSearch = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(value)
  }

  const eligibleCountries = onlyInternationalCountries
    ? internationalCountries
    : allCountries

  const filteredCountries = eligibleCountries.filter((country) =>
    CountriesLongName[country].toLowerCase().match(searchTerm.toLowerCase()),
  )

  const countryListItems = () => {
    if (filteredCountries.length === 0) {
      return <div tw="text-center text-gray-500 mt-4">No results</div>
    }

    return filteredCountries.map((country: Countries) => (
      <CountryListItem
        active={selectedShippingCountry === country}
        onClick={() => onClick(country)}
        key={country}
      >
        <Flag country={country} size="m" />
        <h2 tw="pl-3">{CountriesLongName[country]}</h2>
      </CountryListItem>
    ))
  }

  return (
    <Container insideDropdown={insideDropdown}>
      <SearchField
        onChange={handleSearch}
        value={searchTerm}
        tw="w-full mb-2"
      />
      {countryListItems()}
    </Container>
  )
}

const Container = styled.div<{ insideDropdown: boolean | undefined }>`
  ${({ insideDropdown }) =>
    !insideDropdown && tw`lg:w-3/5 p-7 lg:pl-4 overflow-y-scroll`}
`

const CountryListItem = styled.button<{ active: boolean }>`
  ${tw`flex items-center py-2 px-4 w-full rounded transition-colors`}
  ${({ active }) => active && tw`text-primary-500 bg-primary-050 font-medium`}
  &:hover {
    ${tw`bg-gray-100`}
  }
  &:active {
    ${tw`bg-gray-150`}
  }
`

export default CountryList
