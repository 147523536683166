import { useEffect } from "react"
import tw from "twin.macro"

import { screen } from "../common/analytics"
import Store from "../store/Store"

interface Props {
  visible: boolean
}

const Browse = ({ visible }: Props) => {
  useEffect(() => {
    if (!visible) {
      document.querySelector("body")?.classList.remove("no-scroll-mobile")
    }
  }, [visible])

  useEffect(() => {
    if (!visible) {
      return
    }

    screen("Browse")
  }, [visible])

  return (
    <div
      css={visible ? undefined : tw`hidden`}
      tw="lg:border-t border-primary-100"
    >
      <div
        tw="h-[200px] z-[-10] absolute translate-y-[-200px] w-full"
        style={{
          background: "linear-gradient(360deg, #FAFBFE 0%, #FDFDFF 100%)",
          animationDuration: "0.5s",
          animationTimingFunction: "ease-out",
        }}
        className="animated fadeInOpacity"
      />
      {/* Helmet is handled inside the store so we can have gift option/category names */}
      <div>
        <div tw="bg-white">
          <Store visible={visible} caller="browse" />
        </div>
      </div>
    </div>
  )
}

export default Browse
