import { SetStateAction, useEffect, useRef } from "react"

import { CurrentGift, useGlobalState } from "../GlobalState"

const SHARED_FIELDS: (keyof CurrentGift)[] = ["cart"]

export const getSharedFieldsFrom = (currentGift: CurrentGift) =>
  SHARED_FIELDS.reduce(
    (obj, field) => ({ ...obj, [field]: currentGift[field] }),
    {},
  )

type CurrentGiftState = readonly [
  CurrentGift,
  (u: SetStateAction<CurrentGift>) => void,
]

// Syncs the shared fields from one currentGift to another
const useSyncFields = (
  fromCurrentGiftState: CurrentGiftState,
  toCurrentGiftState: CurrentGiftState,
) => {
  const [fromCurrentGift] = fromCurrentGiftState
  const [toCurrentGift, setToCurrentGift] = toCurrentGiftState

  const toCurrentGiftRef = useRef(toCurrentGift)
  toCurrentGiftRef.current = toCurrentGift

  useEffect(
    () => {
      if (
        SHARED_FIELDS.some(
          (field) => toCurrentGiftRef.current[field] !== fromCurrentGift[field],
        )
      ) {
        setToCurrentGift((toCurrentGift) => ({
          ...toCurrentGift,
          ...getSharedFieldsFrom(fromCurrentGift),
        }))
      }
    },
    SHARED_FIELDS.map((field) => fromCurrentGift[field]),
  )
}

export const useCurrentGiftSync = () => {
  const consumerCurrentGiftState = useGlobalState("consumerCurrentGift")
  const businessCurrentGiftState = useGlobalState("businessCurrentGift")

  useSyncFields(businessCurrentGiftState, consumerCurrentGiftState)
  useSyncFields(consumerCurrentGiftState, businessCurrentGiftState)
}
