import { ReactElement } from "react"
import tw from "twin.macro"

import { useFeatureAccess } from "@/common/hooks/featureAccess"
import { useFeatureFlags } from "@/common/hooks/featureFlags"
import { ProPlanProtected } from "@/common/ProPlanModal"
import { FloatingProTag } from "@/common/ProTag"
import { SelectionGroupWithText } from "@/common/SelectionGroup/SelectionGroupWithText"
import { SelectionGroupColorEnum } from "@/common/SelectionGroup/types"
import { SettingInfo } from "@/common/settings/SettingInfo"
import { InternationalShippingTierEnum } from "@/types/graphql-types"

// This is the old version of the component and will be removed in the future
// New version is in src/common/InternationalShippingOptions.tsx
// This component is only being used inside Custom Stores

interface Props {
  selectedTier: InternationalShippingTierEnum
  header?: ReactElement
  useDefaultHeader?: boolean
  setInternationalShippingTier: (
    internationalShippingTier: InternationalShippingTierEnum,
  ) => void
  disallowInternational?: boolean
  showSwapMultipleWarning?: boolean
  color?: SelectionGroupColorEnum
}

export const InternationalShippingTierOptions = ({
  selectedTier,
  setInternationalShippingTier,
  header,
  useDefaultHeader,
  disallowInternational,
  showSwapMultipleWarning,
  color,
  ...props
}: Props) => {
  const { hasFeature } = useFeatureAccess()
  const { hasFeatureFlag } = useFeatureFlags()

  const shouldDisableSelection =
    disallowInternational || !hasFeature("pro_plan")
  const shouldBeUnclickable = hasFeature("pro_plan") && !!disallowInternational

  return (
    <div {...props}>
      {header ||
        (useDefaultHeader && (
          <div tw="flex flex-row items-center justify-start pb-2 gap-4">
            <div tw="font-medium text-gray-600">International</div>
            <FloatingProTag feature="international_shipping" />
          </div>
        ))}
      {disallowInternational && (
        <div tw={"bg-gray-100 rounded-lg inline-block px-4 py-2 mt-0.5 mb-2"}>
          International shipping isn’t available for gifts with multiple items.
        </div>
      )}

      <SelectionContainer>
        <SelectionGroupWithText
          selected={selectedTier === InternationalShippingTierEnum.disabled}
          text={"Disabled"}
          onClick={() =>
            setInternationalShippingTier(InternationalShippingTierEnum.disabled)
          }
          color={color}
        />
        <ProPlanProtected feature="international_shipping">
          <SelectionGroupWithText
            selected={selectedTier === InternationalShippingTierEnum.gift_cards}
            text={
              <>
                Gift cards only{" "}
                <span tw="text-gray-500">No additional fee</span>
              </>
            }
            onClick={() =>
              setInternationalShippingTier(
                InternationalShippingTierEnum.gift_cards,
              )
            }
            disabled={shouldDisableSelection}
            unclickable={shouldBeUnclickable}
            color={color}
          />
        </ProPlanProtected>
        {hasFeatureFlag("global_partners") && (
          <ProPlanProtected feature="international_shipping">
            <SelectionGroupWithText
              selected={
                selectedTier === InternationalShippingTierEnum.global_partners
              }
              text={
                <>
                  Local partners{" "}
                  <span tw="text-gray-500">
                    Economical international options
                  </span>
                </>
              }
              onClick={() =>
                setInternationalShippingTier(
                  InternationalShippingTierEnum.global_partners,
                )
              }
              disabled={shouldDisableSelection}
              unclickable={shouldBeUnclickable}
              color={color}
            />
          </ProPlanProtected>
        )}
        <ProPlanProtected feature="international_shipping">
          <SelectionGroupWithText
            selected={
              selectedTier === InternationalShippingTierEnum.global_relay
            }
            text={
              <>
                Full global catalog{" "}
                <span tw="text-gray-500">Up to $50 per recipient</span>
              </>
            }
            disabled={shouldDisableSelection}
            unclickable={shouldBeUnclickable}
            onClick={() =>
              setInternationalShippingTier(
                InternationalShippingTierEnum.global_relay,
              )
            }
            color={color}
          />
        </ProPlanProtected>
      </SelectionContainer>
      <SettingInfo
        message={
          <Disclaimer showSwapMultipleWarning={showSwapMultipleWarning} />
        }
      />
    </div>
  )
}

const SelectionContainer = tw.div`
  flex flex-col gap-y-2
`

interface DisclaimerProps {
  showSwapMultipleWarning?: boolean
}

const Disclaimer = ({ showSwapMultipleWarning }: DisclaimerProps) => (
  <div>
    <span tw="font-semibold">
      Our full international shipping catalog has an additional fee of up to $50
      per international recipient
    </span>{" "}
    for cross-border shipping, tariffs, and taxes in the destination country.
    {showSwapMultipleWarning && (
      <> Recipients outside the USA can only swap for one item</>
    )}
  </div>
)
