import React from "react"
import { toast } from "react-hot-toast"
import { useHistory, useLocation } from "react-router-dom"
import tw, { styled } from "twin.macro"

import MenuGroup from "./MenuGroup"
import { MenuItem, MenuItemHeaderNavLink } from "./MenuItem"
import { CurrentWorkspace } from "../../../common/GlobalState"
import { UpdateWorkspace } from "../../../common/hooks"
import { ReactComponent as PlusIcon } from "../../../common/images/plus.svg"
import { ReactComponent as WorkspaceIcon } from "../../../common/images/workspace.svg"
import { generateRealmPath } from "../../../common/realm"
import { isIndividual } from "../../../common/workspaces"
import { ReactComponent as LockIcon } from "../images/lock.svg"

import { BaseMeFragment } from "@/types/graphql-types"

// When switching workspaces, a user can stay on the same page if they're on one of
// the paths on this list. Otherwise, they're directed to /send.
const switchWorkspaceSamePagePaths = [
  "/send",
  "/track",
  "/browse",
  "/dashboard",
  "/contacts",
  "/organization/hr",
]

const WorkspacesMenuGroup = styled(MenuGroup)`
  h3 {
    ${tw`text-sm text-gray-400 ml-3 mb-1`}
  }

  ${MenuItemHeaderNavLink} {
    ${tw`gap-2`}
    svg {
      ${tw`order-last text-gray-300`}
    }

    &:hover svg {
      ${tw`text-primary-300`}
    }
  }
`
const SecondaryWorkspacesMenuGroup = styled(MenuGroup)`
  ${MenuItemHeaderNavLink} {
    ${tw`text-sm text-gray-500`}
  }

  svg {
    width: 16px;
    height: 16px;
  }
`

export type Workspace = Pick<
  BaseMeFragment["workspaces"][0],
  "id" | "name" | "ttype"
>

interface WorkspacesMenuItemsProps {
  workspaces: Workspace[]
  currentWorkspace: Workspace | null
  updateCurrentWorkspace: UpdateWorkspace
}

export const WorkspacesMenuItems: React.FC<WorkspacesMenuItemsProps> = ({
  workspaces,
  updateCurrentWorkspace,
}) => {
  const history = useHistory()
  const location = useLocation()

  const selectWorkspace = (workspace: CurrentWorkspace) => {
    updateCurrentWorkspace(workspace)

    toast.success(
      `Switched to ${
        isIndividual(workspace.ttype) ? "individual account" : workspace.name
      }`,
      {
        position: "top-right",
        duration: 1500,
        iconTheme: {
          primary: "#27ae60",
          secondary: "#fff",
        },
      },
    )

    // Go to the /send page unless they're on a page that they can stay on.
    if (switchWorkspaceSamePagePaths.indexOf(location.pathname) < 0) {
      history.push(generateRealmPath("plus", "/send"))
    }
  }

  return (
    <>
      <WorkspacesMenuGroup>
        <h3>Workspaces</h3>
        {workspaces.map((workspace) => (
          <MenuItem
            key={workspace.id}
            onClick={() => selectWorkspace(workspace)}
            name={workspace.name}
            icon={
              isIndividual(workspace.ttype) ? (
                <LockIcon className="last" />
              ) : undefined
            }
          />
        ))}
      </WorkspacesMenuGroup>
      <SecondaryWorkspacesMenuGroup>
        <MenuItem
          path={generateRealmPath("plus", "/workspaces")}
          name="Manage workspaces"
          icon={<WorkspaceIcon style={{ strokeWidth: 2 }} />}
          exact
        />
        <MenuItem
          path={generateRealmPath("plus", "/workspaces/new")}
          name="Create new workspace"
          icon={<PlusIcon />}
        />
      </SecondaryWorkspacesMenuGroup>
    </>
  )
}
