import { useMemo } from "react"

import { getProductPrice, isGiftCard } from "../gifts"
import { useCurrentGift } from "../hooks/currentGift"
import { useDeveloperModeContext } from "../hooks/useDeveloperModeContext"

interface CartItem {
  product_id: string
  quantity: number
  variable_price?: number
}

// Renders a textarea with the cart contents converted to the JSON CartInput
// format expected by the API.
export default function CartDeveloperInfo() {
  const { enabled: devEnabled } = useDeveloperModeContext()
  const [currentGift] = useCurrentGift()
  const cart = currentGift.cart

  const cartJSON = useMemo(() => {
    const products = cart.map((product) => {
      const cartItem: CartItem = {
        product_id: product.id,
        quantity: product.quantity,
      }

      if (isGiftCard(product) || product.isFlexGift) {
        cartItem["variable_price"] = getProductPrice(product)
      }

      return cartItem
    })

    return JSON.stringify(
      {
        items: products,
      },
      null,
      2,
    )
  }, [cart])

  if (!devEnabled) {
    return null
  }

  return (
    <div tw="pt-8">
      <div tw="text-center text-gray-500 text-sm font-medium">
        Developer – Cart Payload
      </div>
      <div tw="p-4 pt-3">
        <textarea
          rows={10}
          tw="w-full border border-gray-300 rounded-lg p-4 font-mono text-sm focus:(outline-none border-gray-400)"
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            e.currentTarget.select()
          }}
          value={cartJSON}
          readOnly
        />
      </div>
    </div>
  )
}
