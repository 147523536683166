import { gql, useApolloClient } from "@apollo/client"
import { format } from "date-fns"
import { useHistory } from "react-router-dom"

import Button from "../../../common/Button"

import { ReactComponent as ReportsIcon } from "@/assets/icons/reports.svg"
import { ProPlanProtected } from "@/common/ProPlanModal"
import { ProTag } from "@/common/ProTag"
import { generateRealmPath } from "@/common/realm"
import {
  Track_OpenInReportsButtonFirstGiftDateQuery,
  Track_OpenInReportsButtonFirstGiftDateQueryVariables,
} from "@/types/graphql-types"

interface Props {
  giftBatchId: string
}
export default function GiftBatchOpenInReportsButton({ giftBatchId }: Props) {
  const client = useApolloClient()

  const history = useHistory()

  const handleClick = async () => {
    const res = await client.query<
      Track_OpenInReportsButtonFirstGiftDateQuery,
      Track_OpenInReportsButtonFirstGiftDateQueryVariables
    >({
      query: OPEN_IN_REPORTS_BUTTON_FIRST_GIFT_DATE_QUERY,
      variables: { id: giftBatchId },
    })

    const params = new URLSearchParams()
    params.set("filter_gift_batch", giftBatchId)

    if (res.data.workspace?.giftBatch?.firstGiftDate) {
      params.set("date_range", "custom")
      params.set("date_start", res.data.workspace.giftBatch.firstGiftDate)
      params.set("date_end", format(new Date(), "yyyy-MM-dd"))
    }

    const hash = params.toString()

    history.push(generateRealmPath("plus", "/reports#" + hash))
  }

  return (
    <>
      <ProPlanProtected feature={"report_filters"}>
        <Button tw="text-gray-600" onClick={handleClick}>
          <ReportsIcon tw="self-center stroke-current" />
          Open in Reports
          <ProTag tw={"ml-3 pointer-events-none"} feature={"report_filters"} />
        </Button>
      </ProPlanProtected>
    </>
  )
}

const OPEN_IN_REPORTS_BUTTON_FIRST_GIFT_DATE_QUERY = gql`
  query Track_OpenInReportsButtonFirstGiftDate($id: ID!) {
    workspace {
      giftBatch(id: $id) {
        id
        firstGiftDate
      }
    }
  }
`
