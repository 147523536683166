import { gql } from "@apollo/client"

import { WORKSPACE_FRAGMENT } from "./WorkspaceFragment"

export const GET_WORKSPACE_QUERY = gql`
  query GetWorkspace($id: ID!) {
    me {
      workspace(id: $id) {
        ...Workspace
      }
      isFullOrganizationMember
    }
    organization {
      subscription {
        isTeamPlan
      }
    }
  }
  ${WORKSPACE_FRAGMENT}
`
