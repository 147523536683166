import React, { useEffect, useRef } from "react"
import tw, { styled } from "twin.macro"

import { useBrowseUrls } from "@/store/StoreHelpers"
import { Store_CategoryCommonFragment } from "@/types/graphql-types"

interface Props {
  isSelected: boolean
  isCustomCategory?: boolean
  onSubcategoryClick: (subcategory: Store_CategoryCommonFragment) => void
  selectedSubcategorySlug: string | null
  subcategories: Store_CategoryCommonFragment[]
  preventScrollIntoView?: boolean
}

const SubcategoriesList = ({
  isSelected,
  onSubcategoryClick,
  selectedSubcategorySlug,
  subcategories,
  isCustomCategory = false,
  preventScrollIntoView,
  ...props
}: Props) => {
  const contentSpace = useRef<HTMLDivElement>(null)

  return (
    <StyledSubcategoryList
      ref={contentSpace}
      isSelected={isSelected}
      maxHeight={`${contentSpace.current?.scrollHeight ?? 0}px`}
      {...props}
    >
      {subcategories.map((subcategory) => (
        <Subcategory
          subcategory={subcategory}
          selectedSubcategorySlug={selectedSubcategorySlug}
          onSubcategoryClick={onSubcategoryClick}
          key={subcategory.id}
          isCustomCategory={isCustomCategory}
          preventScrollIntoView={preventScrollIntoView}
        />
      ))}
    </StyledSubcategoryList>
  )
}

interface SubcategoryProps {
  onSubcategoryClick: (subcategory: Store_CategoryCommonFragment) => void
  selectedSubcategorySlug: string | null
  isCustomCategory?: boolean
  subcategory: Store_CategoryCommonFragment
  preventScrollIntoView?: boolean
}

const Subcategory = ({
  onSubcategoryClick,
  selectedSubcategorySlug,
  subcategory,
  isCustomCategory = false,
  preventScrollIntoView,
}: SubcategoryProps) => {
  const { generateBrowseUrl } = useBrowseUrls()
  const ref = useRef<HTMLAnchorElement>(null)
  const isSelected = subcategory.slug === selectedSubcategorySlug

  const href = generateBrowseUrl({
    giftOptionSlug: null,
    categorySlug: subcategory.slug,
    customSlug: subcategory.slug,
    categoryIsCustom: isCustomCategory,
  })

  const onClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    onSubcategoryClick(subcategory)
  }

  useEffect(() => {
    if (!isSelected || preventScrollIntoView) {
      return
    }

    setTimeout(
      () =>
        ref.current?.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "nearest",
        }),
    )
  }, [isSelected])

  return (
    <StyledSubcategory
      href={href}
      isSelected={isSelected}
      key={subcategory.id}
      onClick={onClick}
      ref={ref}
    >
      {subcategory.name}
    </StyledSubcategory>
  )
}

const StyledSubcategoryList = styled.div<{
  isSelected: boolean
  maxHeight: string
}>`
  ${tw`overflow-hidden`}

  flex-shrink: 0;
  margin-left: 1.875rem;
  transform-origin: top;
  transition:
    max-height 0.3s ease,
    opacity 0.3s ease;

  max-height: ${({ isSelected, maxHeight }) => (isSelected ? maxHeight : `0`)};
  opacity: ${({ isSelected }) => (isSelected ? 1 : 0)};
`

const StyledSubcategory = styled.a<{ isSelected: boolean }>`
  ${tw`border-l pl-6 border-primary-200 cursor-pointer block text-left w-full hover:text-primary-500 transition-colors`}

  padding-top: 0.375rem;
  padding-bottom: 0.375rem;

  ${({ isSelected }) =>
    isSelected && tw`border-primary-600 text-primary-600 font-medium`}
`

export default SubcategoriesList
