import tw, { styled } from "twin.macro"

export const RecipientTextInput = styled.input`
  ${tw`w-full p-4 py-3 bg-gray-100 border border-transparent rounded-lg focus:outline-none focus:ring ring-blue-500 ring-opacity-30`};

  &:disabled {
    ${tw`text-black opacity-50`};
  }
`
export const RecipientNameInput = styled.input`
  ${tw`w-full p-4 py-3 bg-gray-100 border border-transparent focus:outline-none focus:ring ring-blue-500 ring-opacity-30`};

  &:disabled {
    ${tw`text-black opacity-50`};
  }
`
export const RecipientFieldError = styled.div.attrs({
  className: "field-error",
})`
  ${tw`p-1 px-2 mt-2 text-sm font-medium text-red-500 bg-red-100 rounded`};
`
