import { gql } from "@apollo/client"

import { ORGANIZATION_MEMBER_FRAGMENT } from "./OrganizationMemberFragment"

export const BUDGETING_MEMBER_DETAIL_QUERY = gql`
  query Budgeting_MemberDetail($id: ID!) {
    organization {
      membership(id: $id) {
        id
        role
        access
        member {
          ...OrganizationMember
        }
        budgetRule {
          id
          amount
          recurrence
        }
        balanceAccount {
          id
          balance
        }
      }
    }
    me {
      recentBudgetSettings {
        id
        amount
        recurrence
      }
    }
  }
  ${ORGANIZATION_MEMBER_FRAGMENT}
`
