import { toast } from "react-hot-toast"

export const successToast = (
  message: string | JSX.Element,
  options: {} = {},
) => {
  toast.success(message, {
    iconTheme: {
      primary: "#27ae60",
      secondary: "#fff",
    },
    ...options,
  })
}

export const errorToast = (message: string | JSX.Element, options: {} = {}) => {
  toast.error(message, options)
}
