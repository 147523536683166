import { gql } from "@apollo/client"

import { CUSTOM_STORE_BASE_FRAGMENT } from "./queries"

export const CUSTOM_STORES_QUERY = gql`
  query Store_CustomStores {
    me {
      customStores {
        ...CustomStoreBase
      }
    }
  }
  ${CUSTOM_STORE_BASE_FRAGMENT}
`
