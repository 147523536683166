import tw, { styled } from "twin.macro"

import {
  AutogiftRuleTenureClassification,
  CurrentAutogiftRule,
} from "../../common/GlobalState"

export const AutogiftConfigSection = styled.div`
  width: 530px;
  background-color: #f9fafb;
  ${tw`rounded-xl pt-6`}
`

export const AutogiftConfigSectionHeaderTitle = tw.div`text-xl text-gray-700 mb-3 ml-6 flex flex-row items-center gap-3`

export const AutogiftConfigSectionHeaderDescription = styled.div`
  ${tw`mx-6 mb-6`}
  color: #838A97;
  line-height: 140%;
`

export const AutogiftConfigSectionContent = tw.div`bg-white rounded-xl border border-gray-200`

interface GetDerivedTenureRangeReturn {
  min?: number | null
  max?: number | null
}

// Get the min/max tenure range derived from the autogift tenure classification.
// We keep around old data in the currentAutogiftRule min/max when changing
// tenure classifications to avoid losing data when users switch between tenure
// classifications, but that means we need to derive cleaned-up data before
// sending to the server.
export const getDerivedTenureRange = (
  rule: CurrentAutogiftRule,
): GetDerivedTenureRangeReturn => {
  const classification = rule.tenureClassification
  const min = rule.tenureMin
  const max = rule.tenureMax

  switch (classification) {
    case AutogiftRuleTenureClassification.ALL:
      return { min: null, max: null }
    case AutogiftRuleTenureClassification.YEAR_SINGLE:
      return { min: min, max: min }
    case AutogiftRuleTenureClassification.YEAR_RANGE:
      return { min: min, max: max }
  }
}
