import { ReactNode, useEffect, useState } from "react"
import useCollapse from "react-collapsed"
import tw, { styled } from "twin.macro"

import { ReactComponent as ChevronDown } from "@/common/images/chevron-down.svg"
import { SelectionGroupColorEnum } from "@/common/SelectionGroup/types"

interface Props {
  name: string
  icon: any
  preview: ReactNode | string
  children?: ReactNode
  isTextInput?: boolean
  color?: SelectionGroupColorEnum
  isExpandable?: boolean
  leftPadChildrenContainer?: boolean
  autoExpand?: boolean
}

export const SettingBase = ({
  name,
  icon,
  preview,
  children,
  isTextInput,
  color,
  isExpandable,
  leftPadChildrenContainer,
  autoExpand,
}: Props) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const { getCollapseProps } = useCollapse({ isExpanded })

  const isButton = !isTextInput

  const handleClick = () => {
    if (!autoExpand) {
      setIsExpanded(!isExpanded)
    }
  }

  const mainContent = (
    <div tw="flex flex-col md:flex-row items-start px-5 py-4 gap-2 md:gap-4 w-full">
      <div tw="flex flex-row items-center gap-4">
        <div
          tw="text-gray-400"
          css={[
            isExpanded &&
              (color === SelectionGroupColorEnum.primaryNew
                ? tw`text-primary-new-600`
                : tw`text-primary-500`),
          ]}
        >
          {icon}
        </div>
        <div
          tw="text-gray-500 font-medium"
          css={[
            isExpanded &&
              (color === SelectionGroupColorEnum.primaryNew
                ? tw`text-primary-new-600`
                : tw`text-primary-600`),
          ]}
        >
          {name}
        </div>
      </div>
      <div tw="flex flex-row items-center justify-between gap-4 flex-1 w-full">
        <div
          tw="flex-1"
          css={[
            isExpanded &&
              (color === SelectionGroupColorEnum.primaryNew
                ? tw`text-primary-new-600`
                : tw`text-primary-600`),
          ]}
        >
          {preview}
        </div>
        {isButton && (
          <div
            tw="text-gray-400 transition-transform"
            css={[isExpanded && tw`transform rotate-180 text-primary-500`]}
          >
            <ChevronDown tw="stroke-current" />
          </div>
        )}
      </div>
    </div>
  )

  const mainContentWrapper = isButton ? (
    <button
      tw="block w-full text-left hover:bg-gray-050 active:bg-gray-100 transition-colors"
      onClick={handleClick}
    >
      {mainContent}
    </button>
  ) : (
    mainContent
  )

  useEffect(() => {
    if (autoExpand) {
      setIsExpanded(autoExpand)
    }
  }, [autoExpand])

  if (isExpandable) {
    return (
      <div tw="border-b border-gray-200">
        {mainContentWrapper}
        {children && (
          <div {...getCollapseProps()}>
            <ChildrenContainer
              leftPadChildrenContainer={leftPadChildrenContainer}
            >
              {children}
            </ChildrenContainer>
          </div>
        )}
      </div>
    )
  }

  return <>{children}</>
}

const ChildrenContainer = styled.div<{ leftPadChildrenContainer?: boolean }>`
  ${tw`p-5 pt-3`}
  ${({ leftPadChildrenContainer }) =>
    leftPadChildrenContainer && tw`md:pl-[16.25rem]`}
`
