// See: https://usehooks.com/usePrevious/

// Hook
import { useEffect, useRef } from "react"

export const usePrevious = <V>(
  value: V,
  shouldChange: (oldValue: V | undefined, newValue: V) => boolean = () => true,
) => {
  const ref = useRef<V>()
  useEffect(() => {
    if (shouldChange(ref.current, value)) {
      ref.current = value
    }
  })
  return ref.current
}
