import React from "react"
import tw, { styled } from "twin.macro"

import { DispenserMinus, DispenserPlus } from "../../assets/icons"
import { useCurrentGift } from "../../common/hooks/currentGift"
import { useGiftData } from "../../common/hooks/giftData"

const GiftDispenserConfigure: React.FC = () => {
  const [currentGift] = useCurrentGift()
  const {
    incrementGiftDispenserQuantity,
    decrementGiftDispenserQuantity,
    setGiftDispenserQuantity,
  } = useGiftData()

  return (
    <div tw="border border-gray-200 flex flex-row items-stretch rounded">
      <div tw="w-1/2 flex flex-col items-center justify-center">
        <div tw="text-center text-gray-500 pb-4">Giveaway units</div>
        <div tw="flex flex-row items-center">
          <AddSubtractButton
            onClick={() => incrementGiftDispenserQuantity()}
            disabled={
              !currentGift.giftDispenserQuantity ||
              currentGift.giftDispenserQuantity <= 1
            }
          >
            <DispenserMinus tw="h-9 w-9 text-primary-500" />
          </AddSubtractButton>
          <div tw="w-28 px-4 text-center">
            <NumInput
              type="number"
              onChange={(e) => {
                // When user sets this to empty, set null quantity.
                if (e.target.value === "") {
                  setGiftDispenserQuantity(null)
                  return
                }

                const val = parseInt(e.target.value)
                if (val) {
                  setGiftDispenserQuantity(val)
                }
              }}
              value={
                currentGift.giftDispenserQuantity === null
                  ? ""
                  : currentGift.giftDispenserQuantity
              }
              min={1}
              max={200}
            />
          </div>
          <AddSubtractButton
            onClick={() => decrementGiftDispenserQuantity()}
            disabled={
              !currentGift.giftDispenserQuantity ||
              currentGift.giftDispenserQuantity >= 200
            }
          >
            <DispenserPlus tw="h-9 w-9 text-primary-500" />
          </AddSubtractButton>
        </div>
      </div>
      <div tw="w-1/2 p-6 text-gray-500">
        <p tw="pb-2">
          Giveaways allow you to give an item away through a special page where
          recipients can reserve a gift. Recipients will be asked to reserve a
          gift by entering their name to personalize their gift before they
          accept.
        </p>
        <p>
          Once a recipient reserves their gift, they have 10 minutes to accept
          it before it expires.
        </p>
      </div>
    </div>
  )
}

const AddSubtractButton = tw.button`rounded-full bg-gray-100 hover:bg-gray-150 active:bg-gray-200 disabled:pointer-events-none disabled:opacity-50`

const NumInput = styled.input`
  ${tw`w-full focus:outline-none text-2xl px-2 py-2 text-center border border-gray-200 focus:border-primary-300 transition-colors rounded-lg`}

  // Disable spinner in input type="number".
  &[type="number"] {
    -moz-appearance: textfield;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`

export default GiftDispenserConfigure
