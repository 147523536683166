import { gql, useQuery } from "@apollo/client"
import { useEffect } from "react"

import { EDITORIAL_CTAS_FRAGMENT } from "../editorial/EditorialCTAs"

import {
  Store_EditorialCTAsQuery,
  Store_EditorialCTAsQueryVariables,
} from "@/types/graphql-types"

interface Params {
  categorySlug: string | null
}

const useEditorialCTAs = (params: Params) => {
  const categorySlug = params.categorySlug

  const { data, refetch } = useQuery<
    Store_EditorialCTAsQuery,
    Store_EditorialCTAsQueryVariables
  >(STORE_EDITORIAL_CTAS, {
    variables: {
      categorySlug,
    },
  })

  useEffect(() => {
    refetch({
      categorySlug,
    })
  }, [categorySlug])

  return {
    editorialItems: data?.category?.editorialItems || [],
  }
}

const STORE_EDITORIAL_CTAS = gql`
  query Store_EditorialCTAs($categorySlug: String) {
    category(slug: $categorySlug, defaultCategorySegment: PLUS) {
      id
      slug
      editorialItems(storePlatform: web) {
        ...Editorial_CTAs
      }
    }
  }
  ${EDITORIAL_CTAS_FRAGMENT}
`

export default useEditorialCTAs
