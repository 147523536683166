import tw, { styled } from "twin.macro"

import { ProductImage, ProductImageType } from "../ProductImage"
import { VariantsOrVariantGroupOptions } from "../types"

export default function VariantButtonInfo(props: {
  floatHorizontal: boolean
  image: ProductImageType | null
  variant: VariantsOrVariantGroupOptions | null
  name?: string | null
  subtitle: string | null
  horizontalPopupPosition?: "left" | "right"
  imagesScalable?: boolean
}) {
  return (
    <InfoPopup
      floatHorizontal={props.floatHorizontal}
      horizontalPopupPosition={props.horizontalPopupPosition}
    >
      <div tw="flex flex-row items-start">
        {props.image && (
          <ProductImage
            image={props.image}
            alt={props.variant?.name}
            tw="w-full max-w-[12rem] rounded-l-xl"
            scalable={props.imagesScalable}
          />
        )}
        <div tw="p-4 flex-1">
          <div tw="font-medium text-primary-new-550 text-lg">
            {props.variant ? props.name : "Let recipient choose"}
          </div>
          {props.subtitle ? (
            <div tw="pt-3 text-gray-450">{props.subtitle}</div>
          ) : null}
        </div>
      </div>
    </InfoPopup>
  )
}

export const InfoPopup = styled.div<{
  floatHorizontal?: boolean
  horizontalPopupPosition?: "left" | "right"
}>`
  ${tw`absolute bottom-[calc(100% + 4px)] left-0 w-[80vw] max-w-[32rem] z-[100] bg-white rounded-xl opacity-0 origin-top transition-all scale-90 pointer-events-none`}
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.03), 0 4px 12px rgba(0, 0, 0, 0.07);

  ${(props) =>
    props.floatHorizontal && tw`md:bottom-auto md:top-[-50%] md:w-[400px]`};

  ${(props) =>
    props.floatHorizontal &&
    (props.horizontalPopupPosition === "right"
      ? tw`md:left-[368px]`
      : tw`md:left-[-408px]`)};
`
