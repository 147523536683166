import React from "react"
import tw, { styled } from "twin.macro"

const defaultProps = {
  width: 24,
  height: 14,
  padding: 2,
}

type Props = {
  isOn: boolean
  onClick: () => void
} & Partial<typeof defaultProps>

// Green on/off switch
const ToggleSwitch: React.FC<Props> = ({ isOn, onClick, ...restProps }) => {
  const props = { ...defaultProps, ...restProps }
  return (
    <Container
      width={props.width}
      height={props.height}
      padding={props.padding}
      onClick={onClick}
      className={isOn ? "active" : "inactive"}
    >
      <Indicator size={props.height - 2 * props.padding} />
    </Container>
  )
}

ToggleSwitch.defaultProps = defaultProps

const Container = styled.button<{
  width: number
  height: number
  padding: number
}>`
  height: ${({ height }) => height}px;
  width: ${({ width }) => width}px;
  padding ${({ padding }) => padding}px;
  ${tw`rounded-full transition-colors`}

  &.active {
    background-color: #1cd068;
    & > div {
      transform: translate(${({ height, width }) => width - height}px);
    }
  }
  &.inactive {
    background-color: #dcdfe4;
  }
`

const Indicator = styled.div<{ size: number }>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  ${tw`bg-white rounded-full transition-transform`}
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
`

export default ToggleSwitch
