import React from "react"

import { useGiftBatchSettings } from "./hooks"

import { Alert, AlertColor, OptionBase } from "./index"

import DatePickerField from "@/common/DatePickerField"
import { ExpireAtOption } from "@/common/GlobalState"
import {
  SelectionGroup,
  SelectionIndicator,
  SelectionLabel,
} from "@/common/SelectionGroup"

export const Expiration = () => {
  const { settings, setSettings } = useGiftBatchSettings()
  const expireAtOption = settings.expireAtOption
  const isExpiredAndBalance = settings.isExpired && settings.isUsingBalance
  const isExpiredAndNotBalance = settings.isExpired && !settings.isUsingBalance

  if (settings.isDirectSend || settings.isSmartLink) {
    return null
  }

  const handleClick = (newExpireAtOption: ExpireAtOption) => {
    setSettings((currentSettings) => ({
      ...currentSettings,
      expireAtOption: newExpireAtOption,
      expiresAt:
        newExpireAtOption === "none" ? null : settings.customExpirationDate,
    }))
  }

  const handleDateChange = (date: Date) => {
    setSettings((currentSettings) => ({
      ...currentSettings,
      customExpirationDate: date,
      expiresAt: expireAtOption === "custom" ? date : settings.expiresAt,
    }))
  }

  const alertColor = isExpiredAndNotBalance
    ? AlertColor.blue
    : AlertColor.yellow

  const getAlertPrimaryMessage = () => {
    if (isExpiredAndBalance) {
      return "This gift was paid using balance and is already expired"
    }

    if (isExpiredAndNotBalance) {
      return "This gift has already expired"
    }
  }

  const getAlertSecondaryMessage = () => {
    if (isExpiredAndBalance) {
      return "You can’t extend gifts paid using balance that have already expired. To reactivate this gift, please send a new gift."
    }

    if (isExpiredAndNotBalance) {
      return "To extend this gift, set a new expiration date below."
    }
  }

  const alert = (
    <Alert
      primaryMessage={getAlertPrimaryMessage() ?? ""}
      secondaryMessage={getAlertSecondaryMessage()}
      color={alertColor}
    />
  )

  return (
    <OptionBase
      name="Expiration"
      disabled={isExpiredAndBalance}
      alert={settings.isExpired ? alert : null}
      hint={
        "The gift will expire at 11:59 PM Hawaii time on the given date. Expired gift cards " +
        "and messages can be viewed, but the product can’t be viewed or accepted."
      }
    >
      <div>
        <SelectionGroup
          onClick={() => handleClick("none")}
          disabled={settings.isUsingBalance}
          unclickable={settings.isUsingBalance}
        >
          <SelectionIndicator selected={expireAtOption === "none"} />
          <SelectionLabel selected={expireAtOption === "none"}>
            Doesn't expire
          </SelectionLabel>
        </SelectionGroup>
      </div>
      <div>
        <SelectionGroup onClick={() => handleClick("custom")}>
          <SelectionIndicator selected={expireAtOption === "custom"} />
          <SelectionLabel selected={expireAtOption === "custom"}>
            Custom expiration date
          </SelectionLabel>
        </SelectionGroup>
      </div>
      <div tw="ml-8">
        {settings.expireAtOption === "custom" && (
          <DatePickerField
            date={settings.customExpirationDate}
            onDateChange={(date: Date) => handleDateChange(date)}
            numDisabledDaysAhead={3}
          />
        )}
      </div>
    </OptionBase>
  )
}
