import { gql, useLazyQuery, useQuery } from "@apollo/client"
import nl2br from "react-nl2br"
import { Link } from "react-router-dom"
import TimeAgo from "react-timeago"
import tw, { styled } from "twin.macro"

import { ReactComponent as UserIcon } from "../common/images/avatar.svg"
import { generateRealmPath } from "../common/realm"
import { timeAgoFormatterWithDate } from "../common/utilities"
import { GIFT_BATCH_QUERY } from "../track/GiftBatch"

import {
  FetchRecentlyAcceptedQuery,
  Track_GiftBatchQuery,
  Track_GiftBatchQueryVariables,
} from "@/types/graphql-types"

const RecentlyAccepted = () => {
  const { data } = useQuery<FetchRecentlyAcceptedQuery>(FETCH_RECENTLY_ACCEPTED)
  const [fetchGiftBatch] = useLazyQuery<
    Track_GiftBatchQuery,
    Track_GiftBatchQueryVariables
  >(GIFT_BATCH_QUERY, {
    fetchPolicy: "network-only",
  })

  if (
    !data ||
    !data.workspace ||
    data.workspace.recentlyAcceptedGifts.length === 0
  ) {
    return null
  }

  const { recentlyAcceptedGifts } = data.workspace

  const fullName = (firstName: string | null, lastName: string | null) => {
    return [firstName, lastName].filter((name) => name !== null).join(" ")
  }

  return (
    <Container>
      <Title>Recently accepted</Title>
      <div tw="container mx-auto">
        <Feed>
          {recentlyAcceptedGifts.map(
            ({
              id,
              acceptedAt,
              giftBatchId,
              giftBatchName,
              giftBatchRecipientRequest,
              productFragment,
              recipientFirstName,
              senderFullName,
              recipientLastName,
              thankYouNote,
              isBook,
              bookTitle,
            }) => (
              <li
                key={id}
                onMouseEnter={() =>
                  giftBatchId &&
                  fetchGiftBatch({ variables: { id: giftBatchId } })
                }
              >
                <FeedItem
                  to={generateRealmPath(
                    "plus",
                    `/track/${giftBatchId}#gift_${id}`,
                  )}
                >
                  <div tw="flex justify-between">
                    <div tw="flex flex-col justify-between pb-4 pt-5 px-6">
                      <div tw="mb-4">
                        <RecipientName className="data-hj-suppress ph-no-capture fs-mask">
                          {fullName(
                            recipientFirstName ?? null,
                            recipientLastName ?? null,
                          )}
                        </RecipientName>
                        <RecipientEmail className="data-hj-suppress ph-no-capture fs-mask">
                          {giftBatchRecipientRequest?.email}
                        </RecipientEmail>
                      </div>
                      <div>
                        <GiftInfo>
                          {productFragment.brandName} –{" "}
                          {isBook ? bookTitle : productFragment.name}
                        </GiftInfo>
                        {giftBatchName && (
                          <GiftInfo className="data-hj-suppress ph-no-capture fs-mask">
                            {giftBatchName}
                          </GiftInfo>
                        )}
                      </div>
                    </div>
                    <div tw="flex">
                      <div tw="mt-5 mr-2 text-primary-500 text-sm text-right">
                        <TimeAgo
                          date={new Date(acceptedAt)}
                          formatter={timeAgoFormatterWithDate(
                            new Date(acceptedAt),
                          )}
                        />
                        <SenderInfo>
                          <UserIcon tw="ml-1 inline-block hidden md:inline-block" />
                          {senderFullName}{" "}
                        </SenderInfo>
                      </div>
                      <GiftImage
                        src={productFragment.primaryImage?.imageThumb.url}
                      />
                    </div>
                  </div>
                  {thankYouNote && (
                    <ThankYouNote className="data-hj-suppress ph-no-capture fs-mask">
                      {nl2br(thankYouNote)}
                    </ThankYouNote>
                  )}
                </FeedItem>
              </li>
            ),
          )}
        </Feed>
      </div>
    </Container>
  )
}

const Container = tw.div`border-t border-gray-100 py-16`
const Feed = tw.ul`mx-auto md:w-3/4 xl:w-1/2`
const GiftImage = tw.img`h-24 m-3 rounded-lg w-24 object-cover`
const GiftInfo = tw.div`text-sm text-gray-500`
const SenderInfo = tw.div`text-sm mt-1 text-gray-500 flex items-center flex-row-reverse`
const RecipientEmail = styled.div`
  ${tw`text-gray-500 pt-1`};
  line-height: 22px;
`
const RecipientName = styled.div`
  ${tw`text-xl transition`};
`
const FeedItem = styled(Link)`
  ${tw`border border-primary-050 block rounded-xl mt-6 transition-all active:bg-gray-050`};
  box-shadow:
    0 1px 4px rgba(79, 31, 137, 0.03),
    0 6px 20px rgba(79, 31, 137, 0.03);

  &:hover {
    box-shadow:
      0 4px 16px rgba(79, 31, 137, 0.05),
      0 16px 54px rgba(79, 31, 137, 0.05);
    transform: translateY(-4px);

    ${RecipientName} {
      ${tw`text-primary-600`}
    }
  }
`
const Title = styled.h2`
  ${tw`font-medium text-center mb-8`};
  font-size: 2rem;
  line-height: 45px;
`
const ThankYouNote = styled.div`
  ${tw`px-5 py-4 text-lg text-primary-500 rounded-b-xl`};
  background: linear-gradient(
    90deg,
    rgba(245, 174, 174, 0.1) 0%,
    rgba(163, 114, 223, 0.1) 100%
  );
`

const FETCH_RECENTLY_ACCEPTED = gql`
  query FetchRecentlyAccepted {
    workspace {
      recentlyAcceptedGifts {
        id
        acceptedAt
        isBook
        bookTitle
        giftBatchRecipientRequest {
          id
          email
        }
        giftBatchId
        giftBatchName
        productFragment {
          id
          name
          brandName
          primaryImage {
            imageThumb {
              url
            }
          }
        }
        recipientFirstName
        recipientLastName
        senderFullName
        thankYouNote
        senderViewAccessId
      }
    }
  }
`

export default RecentlyAccepted
