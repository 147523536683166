import { formatPrice } from "@/common/format"
import { useGiftData } from "@/common/hooks/giftData"
import {
  BALANCE_PAYMENT_METHOD_ID,
  CORPORATE_ACCOUNT_PAYMENT_METHOD_ID,
  useCurrentAutogiftRulePayment,
  useCurrentGiftPayment,
  usePayment,
} from "@/common/hooks/payment"
import { PaymentMethod as PaymentMethodType } from "@/common/PaymentMethodsManager"
import AddPaymentMethod from "@/common/payments/AddPaymentMethod"
import BalancePaymentMethod from "@/common/payments/BalancePaymentMethod"
import CreditCardPaymentMethod from "@/common/payments/CreditCardPaymentMethod"
import PaymentMethod, { cardImage } from "@/common/payments/PaymentMethod"
import PaymentMethodsContainer from "@/common/payments/PaymentMethodsContainer"

interface Props extends ReturnType<typeof usePayment> {
  title?: string
  balance?: boolean
  addBalance?: boolean
  selectable?: boolean
}

const PaymentMethods = ({
  title = "Payments",
  balance = true,
  addBalance = true,
  isLoading,
  selectedPaymentMethodID,
  setSelectedPaymentMethod,
  refreshPaymentMethods,
  paymentMethods,
  selectablePaymentMethods,
  balanceAmount,
  budgetRule,
  storedValueAmount,
  selectable = true,
}: Props) => {
  const { setTopUpAmount, setTopUpPaymentMethod } = useGiftData()
  const isSelected = (id: string) => selectedPaymentMethodID == id
  const paymentMethodComponent = (paymentMethod: PaymentMethodType) => {
    const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      setSelectedPaymentMethod(paymentMethod)

      if (paymentMethod.id !== BALANCE_PAYMENT_METHOD_ID) {
        setTopUpAmount(null)
        setTopUpPaymentMethod(null)
      }
    }

    switch (paymentMethod.id) {
      case CORPORATE_ACCOUNT_PAYMENT_METHOD_ID:
        return (
          <PaymentMethod
            key={paymentMethod.id}
            selected={isSelected(paymentMethod.id)}
            selectable={selectable}
            label="Corporate Account"
            sublabel={formatPrice(storedValueAmount || 0, true)}
            image={cardImage("goody")}
            onClick={onClick}
          />
        )
      case BALANCE_PAYMENT_METHOD_ID:
        if (!balance) return null
        return (
          <BalancePaymentMethod
            key={paymentMethod.id}
            selected={isSelected(paymentMethod.id)}
            selectable={selectable}
            image={cardImage("goody")}
            onClick={onClick}
            balanceAmount={balanceAmount}
            budgetRule={budgetRule}
          />
        )
      default:
        return (
          <CreditCardPaymentMethod
            key={paymentMethod.id}
            selected={isSelected(paymentMethod.id)}
            selectable={selectable}
            onClick={onClick}
            paymentMethod={paymentMethod}
            refreshPaymentMethods={refreshPaymentMethods}
            paymentMethods={paymentMethods}
          />
        )
    }
  }

  return (
    <PaymentMethodsContainer title={title}>
      {isLoading ? null : (
        <>
          {selectablePaymentMethods?.map(paymentMethodComponent)}
          <AddPaymentMethod
            key="new-card"
            label="Add new credit card"
            subtext={
              !(paymentMethods || []).length && !balanceAmount
                ? "Pay as gifts are accepted."
                : undefined
            }
            refreshPaymentMethods={refreshPaymentMethods}
          />
        </>
      )}
    </PaymentMethodsContainer>
  )
}

export default (props: { balance?: boolean; title?: string }) => (
  <PaymentMethods
    {...usePayment({
      selectedPaymentMethodID: null,
      setSelectedPaymentMethod: () => {},
    })}
    {...props}
    selectable={false}
  />
)

export const CurrentGiftPaymentMethods = (props: { addBalance?: boolean }) => (
  <PaymentMethods {...useCurrentGiftPayment()} {...props} />
)

export const AutogiftPaymentMethods = (props: { addBalance?: boolean }) => (
  <PaymentMethods {...useCurrentAutogiftRulePayment()} {...props} />
)
