import React, { Suspense } from "react"

const ReportsComponent = React.lazy(() => import("./Reports"))

export default function ReportsLoader() {
  return (
    <div>
      <Suspense fallback={<div tw="text-center text-gray-400">Loading...</div>}>
        <ReportsComponent />
      </Suspense>
    </div>
  )
}
