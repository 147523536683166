import { ApolloClient } from "@apollo/client"
import { parseISO } from "date-fns"
import { utcToZonedTime } from "date-fns-tz"
import { isNil } from "lodash-es"
import pluralize from "pluralize"
import { ReactElement } from "react"
import { Formatter } from "react-timeago"
import { v4 as uuidv4 } from "uuid"

import { BatchRecipient, Product } from "./GlobalState"
import { GIFT_OPTION_BY_SLUG_QUERY } from "../store/GiftOption/graphql/GiftOptionBySlugQuery"
import { GIFT_OPTION_QUERY } from "../store/GiftOption/graphql/GiftOptionQuery"

import {
  Store_GiftOption_GiftOptionQuery,
  Store_GiftOption_GiftOptionQueryVariables,
  Store_GiftOption_GiftOptionSlugQuery,
  Store_GiftOption_GiftOptionSlugQueryVariables,
} from "@/types/graphql-types"

export const isBlank = (string: string | null | undefined) =>
  isNil(string) || string.trim().length == 0

export const isPresent = (
  string: string | null | undefined,
): string is string => !isBlank(string)

export function generateUUID() {
  return uuidv4()
}

const DAYS_OF_WEEK = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
]

const DAYS_OF_WEEK_ABBR = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]

const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]

const MONTHS_ABBR = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
]

const pad = (number: number) => {
  if (number < 10) {
    return `0${number}`
  } else {
    return `${number}`
  }
}

// Example: 03/23/2021
export const formatDateUS = (date: Date) => {
  const month = date.getMonth() + 1
  const year = date.getFullYear()

  return `${pad(month)}/${pad(date.getDate())}/${year}`
}

// Example: January 3
export const formatDateWithoutYear = (
  date: Date,
  useHawaiiTZ: boolean = false,
) => {
  const newDate = new Date(date)

  // The parameter `useHawaiiTZ` is used to force the displayed date to be in the Hawaii time
  // zone. As a general rule, if we are retrieving the `expires_at` field from the API, we should
  // set this parameter to `true`.
  //
  // The `600` is the difference in minutes from UTC.  If `getTimezoneOffset` is less than 600
  // we should subtract a day because Hawaii is a day behind.
  if (useHawaiiTZ && newDate.getTimezoneOffset() < 600) {
    newDate.setDate(newDate.getDate() - 1)
  }

  const month = MONTHS[newDate.getMonth()]

  return `${month} ${newDate.getDate()}`
}

// Example: Tuesday, January 3
export const formatDayWithDate = (
  date: Date,
  useHawaiiTZ: boolean = false,
  abbreviate: boolean = false,
) => {
  const newDate = new Date(date)

  // The parameter `useHawaiiTZ` is used to force the displayed date to be in the Hawaii time
  // zone. As a general rule, if we are retrieving the `expires_at` field from the API, we should
  // set this parameter to `true`.
  //
  // The `600` is the difference in minutes from UTC.  If `getTimezoneOffset` is less than 600
  // we should subtract a day because Hawaii is a day behind.
  if (useHawaiiTZ && newDate.getTimezoneOffset() < 600) {
    newDate.setDate(newDate.getDate() - 1)
  }
  const dayOfWeek = abbreviate
    ? DAYS_OF_WEEK_ABBR[newDate.getDay()]
    : DAYS_OF_WEEK[newDate.getDay()]
  const month = abbreviate
    ? MONTHS_ABBR[newDate.getMonth()]
    : MONTHS[newDate.getMonth()]

  return `${dayOfWeek}, ${month} ${newDate.getDate()}`
}

// Example: PDT
export const timeZoneAbbr = (date: Date) => {
  return date
    .toLocaleTimeString("en-us", { timeZoneName: "short" })
    .split(" ")[2]
}

// Example: Tuesday, January 3 at 12 PM EDT
export const formatDayWithDateAndTime = (
  date: Date,
  time: string,
  useHawaiiTZ: boolean = false,
  abbreviate: boolean = false,
) => {
  const dayWithDate = formatDayWithDate(date, useHawaiiTZ, abbreviate)
  return `${dayWithDate} at ${time} ${
    abbreviate ? "" : timeZoneAbbr(date)
  }`.trim()
}

export const isPastDate = (date: Date) => {
  const currentDate = new Date()

  return date.getTime() < currentDate.getTime()
}

// Example: 2 weeks
// or
// Example: 8 days
export const relativeTime = (date: Date, useHawaiiTZ: boolean = false) => {
  const futureTime = toEndOfDayHawaii(date).getTime()
  const currentDate = new Date()

  // We calculate how many minutes we are into the new day. Then we subtract the
  // number of minutes from UTC the current time zone is from 600, which
  // is the number of minutes Hawaii is from UTC. This will let us know if the
  // current time zone is one day ahead of Hawaii.
  const minutesIntoDay = currentDate.getHours() * 60 + currentDate.getMinutes()
  if (useHawaiiTZ && minutesIntoDay < 600 - currentDate.getTimezoneOffset()) {
    currentDate.setDate(currentDate.getDate() - 1)
  }

  const currentTime = toEndOfDayHawaii(currentDate).getTime()

  const timeDifference = futureTime - currentTime

  // 1 day = 86400000 milliseconds
  const days = Math.floor(timeDifference / 86400000)

  if (days % 7 === 0 && days !== 0) {
    const total = days / 7
    return `${total} ${pluralize("week", total)}`
  } else {
    return `${days} ${pluralize("day", days)}`
  }
}

export const fromISOStringtoHawaiianTimeZoneDate = (
  expiresAtISOString: string,
) => {
  const expiresAt = parseISO(expiresAtISOString)
  const hawaiianTimeZone = "Pacific/Honolulu"
  const hawaiianDate = utcToZonedTime(expiresAt, hawaiianTimeZone)

  // when pulling the expiresAt value from the backend, we need to convert it to the equivalent date in
  // the Hawaiian Time Zone. utcToZonedTime does not set the timezone value correctly, so this sets the hour
  // to 0 to prevent the expiresAt day value from being changed when it's passed back to the backend
  hawaiianDate.setHours(0, 0, 0, 0)

  return hawaiianDate
}

export const toEndOfDayHawaii = (date: Date) => {
  const month = date.getMonth() + 1
  const monthWithPad = month.toString().padStart(2, "0")
  const dateWithPad = date.getDate().toString().padStart(2, "0")
  const dateString = `${date.getFullYear()}-${monthWithPad}-${dateWithPad}T23:59:59-10:00`

  return new Date(dateString)
}

export const eightWeeksFromNow = (): Date => daysFromNow(56)
export const fourWeeksFromDate = (date: Date): Date => {
  return daysFromDate(date, 7 * 4)
}

export const sixWeeksFromDate = (date: Date) => daysFromDate(date, 7 * 6)

export const fourWeeksFromNow = (): Date => daysFromNow(7 * 4)

export const sixWeeksFromNow = () => daysFromNow(7 * 6)

// There's an edge case somewhere here (not sure exactly where, but setting to noon fixes that)
export const sixWeeksFromNowNoon = () => {
  const date = daysFromNow(7 * 6)
  date.setHours(12, 0, 0, 0)
  return date
}

export const daysFromNow = (numberOfDays: number): Date => {
  return daysFromDate(new Date(), numberOfDays)
}

export const daysFromDate = (date: Date, numberOfDays: number): Date => {
  const newDate = new Date(date.getTime())
  newDate.setDate(newDate.getDate() + numberOfDays)
  return newDate
}

// convert a 12 hour format string to 24 hour format integer
//   Examples:
//     "12 PM" to 12
//     "7 PM" returns 19
//     "2 AM" returns 2
const convert12HourStringTo24HourInteger = (time: string) => {
  const [time12Hour, modifier] = time.split(" ")
  let convertedHour = parseInt(time12Hour, 10)

  // handle 12 AM case
  if (time12Hour === "12") {
    convertedHour = 0
  }

  if (modifier === "PM") {
    return convertedHour + 12
  }

  return convertedHour
}

// convert a 24 hour integer into a 12 hour string
//   Examples:
//     12 to "12 PM"
//     19 to "7 PM"
//     2 to "2 AM"
export const convert24HourIntegerTo12HourString = (date: Date) => {
  let hours = date.getHours()
  const ampm = hours >= 12 ? "PM" : "AM"
  hours = hours % 12
  hours = hours ? hours : 12 // the hour '0' should be '12'

  return hours + " " + ampm
}

// convert a separate date (Date) and time (String in "12 PM" form) to a single Date object
//  in the local timezone
export const convertDateAndTimeToDate = (date: Date, time: string) => {
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    convert12HourStringTo24HourInteger(time),
    0,
    0,
  )
}

const convertDateToUTC = (date: Date) => {
  return new Date(
    Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds(),
    ),
  )
}

// convert a separate date (Date) and time (String in "12 PM" form) to a single UTC Date object
export const convertDateAndTimeToUTCDate = (date: Date, time: string) => {
  // get selected date
  const selectedDate = convertDateAndTimeToDate(date, time)
  // convert to UTC
  const utcDate = convertDateToUTC(selectedDate)
  return utcDate
}

// convert the GraphQL ISO8601DateTime string to a Date object so that it can be saved in
//   the currentGift scheduledSendOnDate global state variable correctly
export const convertScheduledSendDateForGlobalState = (date: string) => {
  return new Date(date)
}

// convert the GraphQL ISO8601DateTime string to a Date object, extract the time, and return
//   it as a string in the form "12 PM" so that it can be saved in the currentGift
//   scheduledSendOnTime global state variable correctly
export const convertScheduledSendTimeForGlobalState = (date: string) => {
  const convertedDate = new Date(date)
  return convert24HourIntegerTo12HourString(convertedDate)
}

export const getToday9AMEST = () => {
  const date = new Date()
  const month = date.getMonth() + 1
  const monthWithPad = month.toString().padStart(2, "0")
  const dateWithPad = date.getDate().toString().padStart(2, "0")
  const dateString = `${date.getFullYear()}-${monthWithPad}-${dateWithPad}T09:00:00-05:00`

  return new Date(dateString)
}

export const timeAgoFormatterWithDate: (date: Date) => Formatter = (
  date: Date,
) => {
  return (value, unit, suffix, epochMilliseconds, nextFormatter) => {
    if (unit === "second" || unit === "minute") {
      return "Less than an hour ago"
    } else if (unit === "hour" || (unit === "day" && value < 7)) {
      if (nextFormatter) {
        return nextFormatter(value, unit, suffix, epochMilliseconds)
      }
    } else {
      return new Date(date).toLocaleString("en-US", {
        // @ts-ignore
        dateStyle: "full",
      })
    }
  }
}

// Get the month name for a month number, 1-indexed.
export const getMonthNameForNumber = (month: number) => {
  return MONTHS[month - 1]
}

export function joinWithCommaAnd(arr: string[]) {
  if (arr.length === 1) {
    return arr[0]
  } else {
    const arrCopy = [...arr]
    const firstEl = arrCopy.pop()
    return arrCopy.join(", ") + " and " + firstEl
  }
}

interface fetchGiftOptionParams {
  client: ApolloClient<Object>
  param: string
  isPrefetch?: boolean
}

const ID_REGEX = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/

export const fetchGiftOptionDataByParam = async ({
  client,
  param,
  isPrefetch,
}: fetchGiftOptionParams) => {
  const fetchPolicy = isPrefetch ? "network-only" : "cache-first"

  if (ID_REGEX.test(param)) {
    const res = await client.query<
      Store_GiftOption_GiftOptionQuery,
      Store_GiftOption_GiftOptionQueryVariables
    >({
      query: GIFT_OPTION_QUERY,
      variables: {
        id: param,
      },
      fetchPolicy,
    })
    return res?.data
  } else {
    const res = await client.query<
      Store_GiftOption_GiftOptionSlugQuery,
      Store_GiftOption_GiftOptionSlugQueryVariables
    >({
      query: GIFT_OPTION_BY_SLUG_QUERY,
      variables: {
        slug: param,
      },
      fetchPolicy,
    })
    return res?.data
  }
}

export const filterOutBlankRecipients = (recipients: BatchRecipient[]) =>
  recipients.filter(
    (rawRecipient: BatchRecipient) =>
      rawRecipient.firstName?.trim() ||
      rawRecipient.lastName?.trim() ||
      rawRecipient.email?.trim() ||
      rawRecipient.phone?.trim(),
  )

export const aspectRatioCSS = (image: {
  width?: number | null
  height?: number | null
}) => {
  if (image.width && image.height) {
    return {
      aspectRatio: `${image.width} / ${image.height}`,
    }
  }

  return null
}

export function titleCase(str: string) {
  return str.replaceAll("_", " ").replace(/\w\S*/g, function (txt: string) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

export function summarizeVariants(variants: string[]) {
  // variants is an array of strings that can contain duplicates.
  // The resulting string is a comma-separated list of variants with duplicates collapsed.
  // For variants with no duplicates, the list item should just be the variant.
  // For variants with duplicates, it is rendered as 2× variant or whatever the number is.
  // The ordering is maintained with the first variant in the list being the first one in the
  // resulting string.
  const variantCounts = variants.reduce<{ [id: string]: number }>(
    (acc, variant) => {
      if (acc[variant]) {
        acc[variant] += 1
      } else {
        acc[variant] = 1
      }
      return acc
    },
    {},
  )

  const variantList = Object.keys(variantCounts).map((variant) => {
    if (variantCounts[variant] === 1) {
      return variant
    } else {
      return `${variantCounts[variant]}× ${variant}`
    }
  })

  return variantList.join(", ")
}

// Serialize a given Product with a key using its ID and variants.
export function serializeProductForKey(product: Product) {
  return `${product.id}-${serializeProductDetails(product)}`
}

export const serializeProductDetails = (product: Product) =>
  `${product.variants?.join("-") ?? ""}${
    product.isBook ? `-${product.bookData?.ean ?? ""}` : ""
  }`

// Wraps matches of regex in string with components (or anything else)
export const wrapMatches = (
  str: string,
  regex: RegExp,
  wrapper: (str: string) => ReactElement | string,
) => {
  const pieces: (string | ReactElement)[] = []
  let strLeft = String(str)
  while (strLeft.length > 0) {
    const match = regex.exec(strLeft)

    if (match) {
      const word = match[0]
      pieces.push(strLeft.substring(0, match.index))
      pieces.push(wrapper(word))
      strLeft = strLeft.substring(match.index + word.length)
    } else {
      pieces.push(strLeft)
      strLeft = ""
    }
  }

  return pieces
}
