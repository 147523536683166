import { gql, useMutation } from "@apollo/client"
import { Drawer } from "@mui/material"
import * as Sentry from "@sentry/react"
import { Dispatch, SetStateAction, useState } from "react"
import { toast } from "react-hot-toast"
import { NavLink } from "react-router-dom"
import tw, { styled } from "twin.macro"

import { ReactComponent as XIcon } from "../../assets/icons/x-close.svg"
import Button from "../../common/Button"
import { generateRealmPath } from "../../common/realm"
import TextInputField from "../../common/TextInputField"
import { validateIsAlphanumeric } from "../../common/validations"

import {
  GetOrganizationWorkspacesQuery,
  Workspace_UpdateMutation,
  Workspace_UpdateMutationVariables,
} from "@/types/graphql-types"

interface Props {
  workspace: NonNullable<
    GetOrganizationWorkspacesQuery["organization"]
  >["workspaces"][0]
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

const EditWorkspaceDrawer = ({ workspace, open, setOpen }: Props) => {
  const [updateWorkspace] = useMutation<
    Workspace_UpdateMutation,
    Workspace_UpdateMutationVariables
  >(WORKSPACE_UPDATE)
  const [workspaceName, setWorkspaceName] = useState(workspace.name)

  const updateWorkspaceName = async () => {
    if (!validateIsAlphanumeric(workspaceName)) {
      toast.error(`Workspace name can only have letters and numbers.`)
      return
    }

    const { data } = await updateWorkspace({
      variables: {
        id: workspace.id,
        fields: {
          name: workspaceName,
        },
      },
    })

    if (data?.workspaceUpdate?.ok) {
      toast.success(`Workspace updated.`, {
        iconTheme: {
          primary: "#27ae60",
          secondary: "#fff",
        },
      })

      setOpen(false)
    } else if (data?.workspaceUpdate?.error) {
      Sentry.captureException(
        new Error(
          `Workspace update error. Errors: ${data.workspaceUpdate.error}`,
        ),
      )
      alert(data.workspaceUpdate.error)
    } else {
      Sentry.captureException(new Error("Unknown workspace update error."))
      alert("An unexpected error occurred.")
    }
  }

  return (
    <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
      <DrawerContent tw="p-14">
        <h2 tw="text-2xl mb-12 font-medium">{workspace.name}</h2>
        <button
          tw="absolute top-14 right-14 text-gray-500"
          onClick={() => setOpen(false)}
        >
          <XIcon />
        </button>

        <div tw="mb-12">
          <Subheader>Workspace name</Subheader>
          <TextInputField
            tw="mb-4 py-3"
            type="text"
            value={workspaceName}
            onChange={(e) => setWorkspaceName(e.target.value)}
          />
          <Button onClick={updateWorkspaceName}>Update</Button>
        </div>

        <div tw="mb-12">
          <Subheader>Members</Subheader>
          <SubheaderText>
            Workspace managers can add and remove members on the{" "}
            <NavLink
              tw="text-primary-500 hover:text-primary-700"
              to={generateRealmPath("plus", "/workspaces")}
            >
              Workspace management page
            </NavLink>
          </SubheaderText>
        </div>
        <div tw="mb-12">
          <Subheader>Delete workspace</Subheader>
          <SubheaderText>
            To delete this workspace, we'll need to first clean up any loose
            ends such as scheduled gifts, pending gifts, and Autogift. Please
            contact us using the Chat button.
          </SubheaderText>
        </div>
      </DrawerContent>
    </Drawer>
  )
}

export const WORKSPACE_UPDATE = gql`
  mutation Workspace_Update($id: ID!, $fields: WorkspaceUpdateInput!) {
    workspaceUpdate(id: $id, fields: $fields) {
      ok
      error
      workspace {
        id
        name
      }
    }
  }
`

const DrawerContent = styled.div`
  width: 34rem;
`

const Subheader = styled.h2`
  ${tw`text-lg font-medium mb-4`}
`

const SubheaderText = styled.h4`
  ${tw`text-gray-500`}
`

export default EditWorkspaceDrawer
