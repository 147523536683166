import { useMutation } from "@apollo/client"
import React, { useEffect, useRef, useState } from "react"
import ReactPlaceholder from "react-placeholder"
import { RectShape, TextRow } from "react-placeholder/lib/placeholders"
import tw, { styled } from "twin.macro"

import "react-placeholder/lib/reactPlaceholder.css"

import GiftOptionImageGallery from "./GiftOptionImageGallery"
import { BOOKS_SEARCH_QUERY } from "./graphql/BooksSearchQuery"
import { ReactComponent as ChevronRight } from "../../assets/icons/chevron-right-heavy.svg"
import { useCartDrawer } from "../../common/contexts/cartDrawer"
import { useGiftCart } from "../../common/hooks/giftData"
import { ReactComponent as SearchIcon } from "../../common/images/search.svg"
import { joinWithCommaAnd } from "../../common/utilities"
import { useCartAdd } from "../CartAddContext"
import { useBrowseUrls } from "../StoreHelpers"

import {
  Store_BookSearchMutation,
  Store_BookSearchMutationVariables,
  Store_GiftOptionDataFragment,
  Store_GiftOptionPreviewFragment,
} from "@/types/graphql-types"

const BookPlaceHolder = () => (
  <div className="show-loading-animation" tw="px-5 flex justify-center">
    <RectShape
      tw="flex-shrink-0"
      color="#F3F4F6"
      style={{ width: 76, height: 114, margin: 0 }}
    />

    <div tw="ml-5 w-full" style={{ maxWidth: "290px" }}>
      <TextRow
        color="#F3F4F6"
        style={{ width: "100%", maxWidth: 290, height: 27, marginTop: 0 }}
      />
      <TextRow
        color="#F3F4F6"
        style={{ width: "100%", maxWidth: 290, height: 19, marginTop: ".5rem" }}
      />
      <TextRow
        color="#F3F4F6"
        style={{ width: "100%", maxWidth: 104, height: 19, marginTop: "41px" }}
      />
    </div>
  </div>
)

const BookPlaceHolders: React.FC = () => (
  <div
    tw="flex flex-col gap-10 "
    // Custom padding from figma
    style={{ paddingTop: "67px" }}
  >
    <BookPlaceHolder />
    <BookPlaceHolder />
    <BookPlaceHolder />
  </div>
)

interface BookGiftOptionProps {
  giftOption: Store_GiftOptionDataFragment | Store_GiftOptionPreviewFragment
}

const BookGiftOption: React.FC<BookGiftOptionProps> = ({ giftOption }) => {
  const [bookSearchResult, setBookSearchResult] = useState<
    Store_BookSearchMutation | null | undefined
  >(null)
  const bookSearch = useRef<HTMLInputElement>(null)
  const [findBook, { loading: loadingBooks }] = useMutation<
    Store_BookSearchMutation,
    Store_BookSearchMutationVariables
  >(BOOKS_SEARCH_QUERY)

  const searchForBooks = async (searchText: string) => {
    if (searchText) {
      const payload = await findBook({
        variables: { query: searchText },
      })
      setBookSearchResult(payload.data)
    }
  }
  useEffect(() => {
    bookSearch?.current?.focus()
  }, [])

  const books = bookSearchResult?.bookSearch?.results || []

  const bookProductID = bookSearchResult?.bookSearch.productId || null

  return (
    <div tw="md:overflow-hidden h-full w-full">
      <BookHeaderImageContainer>
        <GiftOptionImageGallery giftOption={giftOption} />
        <GiftABookToken>
          <span tw="text-primary-500 mr-2 font-medium">Gift a book.</span>
          <span tw="text-gray-500">
            $
            {giftOption.brand.shippingPrice === null ||
            giftOption.brand.shippingPrice === undefined
              ? 5 // Default shipping price if brand shipping price is null
              : giftOption.brand.shippingPrice / 100}{" "}
            shipping
          </span>
        </GiftABookToken>
        <BookSearch>
          <SearchIcon tw={"stroke-current opacity-50"} />
          <input
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                searchForBooks(e.currentTarget.value)
              }
            }}
            placeholder="Search for a book"
            ref={bookSearch}
          />
        </BookSearch>
      </BookHeaderImageContainer>
      <ReactPlaceholder
        delay={0.2}
        showLoadingAnimation
        customPlaceholder={<BookPlaceHolders />}
        ready={!loadingBooks}
      >
        <div
          tw="md:overflow-y-scroll h-full"
          // Margin of books container gives 20pixels of top white space so only 47 is needed for the 67pixels to match figma
          style={{ paddingTop: "47px" }}
        >
          {bookSearchResult && books.length === 0 ? (
            <div tw="text-center text-gray-500 text-sm pt-8">
              <div tw="font-medium">No books found</div>
              <div>Please check your query or try another search.</div>
            </div>
          ) : null}
          {bookProductID &&
            books.map((book) => (
              <Book
                book={book}
                giftOption={giftOption}
                bookProductID={bookProductID}
                key={book.ean}
              />
            ))}
        </div>
      </ReactPlaceholder>
    </div>
  )
}

const Book = ({
  book,
  bookProductID,
  giftOption,
}: {
  book: NonNullable<Store_BookSearchMutation["bookSearch"]["results"]>[0]
  bookProductID: string
  giftOption: Store_GiftOptionDataFragment | Store_GiftOptionPreviewFragment
}) => {
  const { openCart } = useCartDrawer()
  const { addBook } = useGiftCart()
  const { requestAddItem } = useCartAdd()
  const { isBrowseUrl } = useBrowseUrls()
  const shouldUseCart = isBrowseUrl()

  const selectBook = () => {
    if (!book.inStock) {
      if (book.notYetAvailable) {
        alert("Sorry, this book is not yet on sale.")
      } else if (book.onOrder) {
        alert(
          "Sorry, this book is out of stock, but there are copies that are on the way to the warehouse. Please check back later.",
        )
      } else {
        alert("Sorry, this book is out of stock. Please check back later.")
      }
    } else {
      requestAddItem({
        cartEligible: true,
        callback: () => {
          addBook({ giftOption, book, bookProductID })
          if (shouldUseCart) {
            openCart()
          }
        },
        shippingCountryGroup: giftOption.shippingCountryGroup,
        itemName: book.title,
      })
    }
  }

  return (
    <BookContainer onClick={selectBook}>
      <BookImageContainer>
        {book.imageLarge && (
          <img
            tw={"w-full h-full object-cover "}
            src={book.imageLarge}
            alt={book.title}
          />
        )}
      </BookImageContainer>
      <div
        tw="gap-4 flex flex-1 flex-col justify-between"
        style={{ minHeight: "7.125rem" }} // For proper alignment with the book image but allowing for overflow if needed
      >
        <div style={{ paddingRight: "4.375rem" }}>
          <div
            tw={"text-lg font-medium leading-5"}
            style={{ letterSpacing: "-0.3px" }}
          >
            {book.title}
          </div>
          <div
            tw={"text-sm leading-p-130 opacity-60"}
            style={{ paddingTop: "0.4375rem" }}
          >
            {book.contributors && joinWithCommaAnd(book.contributors)}
          </div>
        </div>
        <div tw={"flex items-end flex-wrap gap-x-3"}>
          {book.inStock ? (
            <SelectionButton>
              <div tw={"flex items-center justify-around py-2 pl-3"}>
                <span tw={"mb-px text-sm"}>Gift</span>
                <ChevronRight
                  // Left margin is 2px since the width of the svg gives 6pixels of whitespace to the left
                  tw={"stroke-current  ml-0.5 inline w-4 mr-1.5 flex-shrink-0"}
                />
              </div>
              <div
                tw={"px-3 text-sm text-gray-100 font-normal"}
                style={{ color: "#333333" }}
              >
                ${book.price / 100}
              </div>
            </SelectionButton>
          ) : (
            <div tw={"text-sm w-full"}>
              {book.notYetAvailable
                ? "Not yet available"
                : `Out of stock${book.onOrder ? " (restock on the way)" : ""}`}
            </div>
          )}
          <div
            tw={"flex-1 flex flex-wrap gap-x-5 opacity-50 text-sm self-center"}
          >
            <div tw={"leading-p-140"}>{book.binding}</div>
            {book.inStock && (
              <div tw={"mr-4 whitespace-nowrap text-xs leading-5"}>
                {book.availableQuantity && book.availableQuantity >= 1000
                  ? "1k+ "
                  : `${book.availableQuantity} `}
                available
              </div>
            )}
          </div>
        </div>
      </div>
    </BookContainer>
  )
}

const BookSearch = styled.div`
  ${tw`absolute flex z-10 rounded-lg flex-nowrap mb-5 items-center outline-none left-5 right-5`}
  height: 3.3125rem;
  top: 11.1875rem;
  input {
    ${tw`justify-center absolute rounded-lg h-full w-full outline-none bg-white pl-14`}
  }

  box-shadow:
    0px 2px 8px rgba(79, 31, 137, 0.06),
    0px 12px 40px rgba(79, 31, 137, 0.1);
  svg {
    ${tw`z-30`}
    right: 1.25rem;
    margin-left: 1.25rem;
  }
`

const GiftABookToken = styled.div`
  ${tw`absolute justify-center items-center flex flex-nowrap left-5`}
  width: 14.125rem;
  height: 2.375rem;
  top: 8.0625rem;
  box-shadow:
    0px 2px 8px rgba(79, 31, 137, 0.06),
    0px 12px 40px rgba(79, 31, 137, 0.1);
  border-radius: 6.25rem;
  background: #faf9fc;
`

const BookHeaderImageContainer = styled.div`
  ${tw`w-full justify-center items-center flex relative`}
  height: 12.8125rem;

  img {
    ${tw`h-full object-cover`}
  }
`

const BookImageContainer = styled.div`
  ${tw`flex-shrink-0 self-start`}
  & > img {
    filter: drop-shadow(0px 2px 8px rgba(69, 69, 69, 0.06))
      drop-shadow(0px 7px 10px rgba(83, 83, 83, 0.1));
  }
  width: 4.75rem;
  height: 7.125rem;
`

const SelectionButton = styled.button`
  ${tw`flex-nowrap items-center font-medium text-sm flex text-primary-700 border-primary-200 border rounded-lg`}
  & > div:first-child {
    width: 3.8125rem;
    height: 1.875rem;
    background: #f5f1fa;
    border-radius: 7px 0px 0px 7px;
    line-height: 15px;
    letter-spacing: -0.3px;
  }

  width: 6.875rem;
  box-sizing: border-box;
`

const BookContainer = styled.div`
  ${tw`gap-5 relative cursor-pointer transition-all duration-200 ease-in-out bg-white flex-1 m-5 flex-nowrap items-center flex py-5 rounded-xl`}

  border: 1px solid #f3f4f6;
  box-sizing: border-box;
  box-shadow:
    0px 1px 8px rgba(0, 0, 0, 0.05),
    0px 1px 4px rgba(0, 0, 0, 0.02);

  // Create a larger bounding box to prevent flickering of hover animation
  &::after {
    top: -1.25rem;
    left: -1.25rem;
    right: -1.25rem;
    bottom: -1.25rem;
    position: absolute;
    content: "";
  }

  &:hover {
    box-shadow:
      0px 4px 16px rgba(0, 0, 0, 0.06),
      0px 1px 4px rgba(0, 0, 0, 0.02);
    transform: translate(0, -4px);
  }

  &:active {
    transform: translate(0, -2px);
  }
`

export default BookGiftOption
