import { useState } from "react"

import Button from "../../common/Button"
import useSendEmailVerification from "../../common/hooks/useSendEmailVerification"
import AccountSection from "../components/AccountSection"

export default function EmailVerification() {
  const { sendEmailVerification, sendEmailVerificationLoading } =
    useSendEmailVerification()

  const [sent, setSent] = useState(false)

  const handleSendEmailVerification = async () => {
    const ok = await sendEmailVerification()
    if (ok) {
      setSent(true)
    }
  }

  return (
    <AccountSection headline="Email verification">
      <div>
        {sent ? (
          <div>Email verification sent. Please check your email.</div>
        ) : (
          <Button
            onClick={handleSendEmailVerification}
            disabled={sendEmailVerificationLoading}
          >
            Re-send email verification
          </Button>
        )}
      </div>
    </AccountSection>
  )
}
