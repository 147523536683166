import { saveAs } from "file-saver"
import React from "react"

import { DownloadFileIcon } from "../assets/icons"
import Button from "../common/Button"
import CSVDropZone from "../common/components/CSVDropZone"
import { useCurrentGift } from "../common/hooks/currentGift"

import { BatchSendMethod } from "@/types/graphql-types"

interface Props {
  setRows: (rows: string[][]) => void
}

const CSV_TEMPLATE_WITH_PHONE = `"First name","Last name","Email","Phone"`
const CSV_TEMPLATE_WITHOUT_PHONE = `"First name","Last name","Email"`
const CSV_TEMPLATE_WITH_ADDRESS = `"First name","Last name","Email","Phone","Address 1","Address 2","City","State","ZIP"`

const CSVUpload: React.FC<Props> = ({ setRows }) => {
  const [currentGift] = useCurrentGift()

  // Default to showing the phone if sendMethod is not set
  const displayPhone =
    currentGift.sendMethod === BatchSendMethod.email_and_link ||
    currentGift.sendMethod === BatchSendMethod.direct_send ||
    currentGift.sendMethod === null

  const displayAddress = currentGift.sendMethod === BatchSendMethod.direct_send

  const downloadTemplate = () => {
    const blob = new Blob(
      [
        displayAddress
          ? CSV_TEMPLATE_WITH_ADDRESS
          : displayPhone
          ? CSV_TEMPLATE_WITH_PHONE
          : CSV_TEMPLATE_WITHOUT_PHONE,
      ],
      { type: "text/csv;charset=utf-8" },
    )
    saveAs(blob, "goody_template.csv")
  }

  return (
    <>
      <div tw="pb-4">You can upload a CSV or XLSX file. Supported columns:</div>
      <ul tw="pb-4 pl-4 list-disc">
        <li>
          First name
          {!displayAddress && (
            <span tw="text-gray-450">
              {" "}
              – You can also use a full name if you prefer
            </span>
          )}
        </li>
        <li>
          Last name
          {!displayAddress && <span tw="text-gray-450"> – optional</span>}
        </li>
        <li>
          Email
          {!currentGift.landingPageSendNotifs && (
            <span tw="text-gray-450"> – optional</span>
          )}
        </li>
        {displayPhone && (
          <li>
            Phone
            <span tw="text-gray-450">
              {" "}
              – optional, US mobile phone numbers only
            </span>
          </li>
        )}
        {displayAddress && (
          <>
            <li>Address 1</li>
            <li>
              Address 2<span tw="text-gray-450"> – optional</span>
            </li>
            <li>City</li>
            <li>
              State
              <span tw="text-gray-450"> – two-letter format, like AL</span>
            </li>
            <li>
              ZIP
              <span tw="text-gray-450"> – 5 digits</span>
            </li>
          </>
        )}
      </ul>
      <div tw="pb-4">
        <span tw="font-semibold">A header row is required,</span> but your file
        doesn&rsquo;t need to have all of these columns. We&rsquo;ll use any
        columns we can find in your file.
      </div>
      <div tw="pb-6 flex flex-row items-center justify-center">
        <Button onClick={downloadTemplate}>
          <DownloadFileIcon />
          Download CSV template
        </Button>
      </div>
      <div tw="flex" css={{ minHeight: 220 }}>
        <CSVDropZone setRows={setRows} />
      </div>
    </>
  )
}

export default CSVUpload
