import { useMutation } from "@apollo/client"
import React, { useEffect } from "react"
import { toast } from "react-hot-toast"
import tw, { styled } from "twin.macro"

import { SelectedWorkspaceProps } from "./HRISConfiguration"
import { ReactComponent as LinkIcon } from "./images/hr-integration/link.svg"
import { ReactComponent as PlusIcon } from "./images/hr-integration/plus.svg"
import { ReactComponent as TooltipIcon } from "./images/hr-integration/tooltip.svg"
import Button from "../../common/Button"
import { numSyncedTeamsHash } from "../../common/hris"
import Tooltip from "../../common/Tooltip"
import { ROOT_DATA_QUERY } from "../../graphql"
import { WORKSPACE_CREATE } from "../../workspaces/NewWorkspace"
import { ListSeparator, Sidebar } from "../../workspaces/Workspaces"

import { CustomerIntegrationWorkspaceConfigurationInput } from "@/types/graphql-types"
import {
  Workspace_CreateMutation,
  Workspace_CreateMutationVariables,
} from "@/types/graphql-types"

interface WorkspacesSidebarProps {
  serviceName: string
  workspaceConfigs:
    | CustomerIntegrationWorkspaceConfigurationInput[]
    | null
    | undefined
  selectedWorkspace: SelectedWorkspaceProps
  onWorkspaceSelect: (workspaceProps: SelectedWorkspaceProps) => void
}

const HRISWorkspacesSidebar: React.FC<WorkspacesSidebarProps> = ({
  serviceName,
  workspaceConfigs,
  selectedWorkspace,
  onWorkspaceSelect,
}) => {
  const [createWorkspace] = useMutation<
    Workspace_CreateMutation,
    Workspace_CreateMutationVariables
  >(WORKSPACE_CREATE)

  // Used to display number of synced workspaces in sidebar
  const numSyncedTeams = numSyncedTeamsHash(workspaceConfigs)

  const workspaces = (workspaceConfigs ?? []).map((workspace) => {
    const { hrisTeams, ...workspaceDetails } = workspace
    return workspaceDetails
  })

  useEffect(() => {
    if (selectedWorkspace.id === null) {
      const workspace = workspaces[0]
      if (!!workspace) {
        onWorkspaceSelect({ id: workspace.id, name: workspace.name })
      }
    }
  }, [selectedWorkspace.id === null])

  const createNewWorkspace = async () => {
    const name = window.prompt("New workspace name:")

    if (name === null) {
      return
    }

    const { data } = await createWorkspace({
      variables: { name },
      refetchQueries: [{ query: ROOT_DATA_QUERY }],
      awaitRefetchQueries: true,
    })

    if (data?.workspaceCreate.id) {
      toast.success(`Workspace created.`, {
        iconTheme: {
          primary: "#27ae60",
          secondary: "#fff",
        },
      })
    } else {
      alert("An error occurred.")
    }
  }

  return (
    <Sidebar tw="max-w-sm overflow-y-scroll pb-36">
      <div tw="px-6 lg:px-12 pb-9">
        <div tw="flex flex-row justify-between items-center">
          <h1 tw="text-2xl font-medium">Connect Workspaces</h1>
          <Tooltip
            trigger={
              <span>
                <TooltipIcon />
              </span>
            }
            title="Connect workspaces"
            placement="auto"
          >
            <p>
              You can see a list of your Goody workspaces on the left sidebar.
              When you click one, you can select which {serviceName} teams you
              want to sync with that workspace.
            </p>
            <p>
              When you sync a {serviceName} team with a Goody workspace,
              employees will be synced as contacts in Goody, and organized into
              contact lists based on their team in {serviceName}.
            </p>
          </Tooltip>
        </div>
        <p tw="text-gray-500 pr-6 pt-3">
          Select which {serviceName} teams you’d like to sync with the
          workspaces you’ve set up in Goody.
        </p>
      </div>
      {workspaces.length > 0 ? (
        <div tw="px-6 pb-12">
          <ListSeparator />
          {workspaces.map((workspace) => (
            <React.Fragment key={workspace.id}>
              <HRWorkspacesListItem
                id={workspace.id}
                selected={workspace.id === selectedWorkspace.id}
                name={workspace.name}
                onWorkspaceSelect={onWorkspaceSelect}
                numSyncedHrisTeams={numSyncedTeams[workspace.id]}
              />
              <ListSeparator />
            </React.Fragment>
          ))}
        </div>
      ) : (
        <div tw="px-6 lg:px-12 pb-12 text-gray-500">
          You aren't part of any workspaces.
        </div>
      )}
      <div tw="px-6 lg:px-12 pb-3">
        <NewWorkspaceButton onClick={() => createNewWorkspace()}>
          <PlusIcon tw="text-gray-500" />
          <div tw="text-gray-500">New workspace</div>
        </NewWorkspaceButton>
      </div>
    </Sidebar>
  )
}

const NewWorkspaceButton = styled(Button)`
  ${tw`border-primary-100 border box-border px-4 py-2 flex flex-row gap-x-3 items-center rounded-lg`}

  box-shadow: 0px 2px 4px rgba(228, 216, 244, 0.3);
`

const HRWorkspacesListItem: React.FC<{
  id: string
  name: string
  selected: boolean
  onWorkspaceSelect: (workspaceProps: SelectedWorkspaceProps) => void
  numSyncedHrisTeams: Number
}> = ({ id, name, selected, onWorkspaceSelect, numSyncedHrisTeams }) => {
  return (
    <div
      onClick={() => onWorkspaceSelect({ id: id, name: name })}
      className="group"
      css={[
        // Relative positioning allows us to use z-index.
        tw`bg-white relative block w-full text-left focus:outline-none transition-colors`,
        // When selected, overlap 1px above and 1px below.
        // -1px Y margin shifts this element 1px above and below.
        // +1px Y padding accounts for the 2px in lost space by making this item larger.
        selected && tw`-my-px py-px z-10`,
      ]}
    >
      <ListItemContainer className={selected ? "selected" : ""}>
        <div tw="px-6 py-3">
          <WorkspaceNameContainer className={selected ? "selected" : ""}>
            {name}
            <NumSyncedTeams className={selected ? "selected" : ""}>
              {numSyncedHrisTeams}
              <LinkIconStyled className={selected ? "selected" : ""} />
            </NumSyncedTeams>
          </WorkspaceNameContainer>
        </div>
      </ListItemContainer>
    </div>
  )
}

const ListItemContainer = styled.div`
  ${tw`cursor-pointer transition-colors`}

  &.selected {
    ${tw`bg-primary-050 rounded-lg`}
  }
`

const WorkspaceNameContainer = styled.div`
  ${tw`text-gray-500 group-hover:text-gray-700 transition-colors flex flex-row items-center justify-between`}

  &.selected {
    ${tw`font-medium text-primary-500 group-hover:text-primary-500`}
  }
`

const NumSyncedTeams = styled.div`
  ${tw`font-normal flex flex-row gap-2 items-center`}
  color: #B7BCC5;

  &.selected {
    color: #9ca3af;
  }
`

const LinkIconStyled = styled(LinkIcon)`
  color: #dcdfe4;

  &.selected {
    color: #b7bcc5;
  }
`

export default HRISWorkspacesSidebar
