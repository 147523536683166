import React from "react"
import tw from "twin.macro"

import { QuantityMinus, QuantityPlus } from "../../assets/icons"

export default function VariantButtonQuantity(props: {
  count: number
  canDecrement?: boolean
  onDecrement?: () => void
  canIncrement?: boolean
  onIncrement?: () => void
}) {
  return (
    <div tw="self-stretch ml-4 -my-1 -mr-3 py-1 pr-3 pl-4 flex flex-row items-center ml-auto absolute right-2 top-0 bottom-0 z-10">
      <button
        tw="p-2 transition-all hover:(bg-white shadow-min) active:scale-75 rounded-full relative"
        css={[
          props.count === 0 && tw`hover:(bg-primary-new-050)`,
          !props.canDecrement && tw`opacity-25 pointer-events-none`,
        ]}
        onClick={props.onDecrement}
      >
        <QuantityMinus tw="w-4 h-4" />
      </button>
      <div
        tw="min-w-[2rem] text-center pointer-events-none"
        css={[props.count === 0 ? tw`text-gray-500` : tw`text-primary-new-600`]}
      >
        {props.count}
      </div>
      <button
        tw="p-2 transition-all hover:(bg-white shadow-min) active:scale-75 rounded-full relative"
        css={[
          props.count === 0 && tw`hover:(bg-primary-new-050)`,
          !props.canIncrement && tw`opacity-25 pointer-events-none`,
        ]}
        onClick={props.onIncrement}
      >
        <QuantityPlus tw="w-4 h-4" />
      </button>
    </div>
  )
}
