import { gql } from "@apollo/client"

import { IMAGE_FRAGMENT } from "./ImageFragment"

export const VARIANT_GROUP_FRAGMENT = gql`
  fragment VariantGroup on VariantGroups {
    name
    variantGroupOptions {
      name
      subtitle
      color
      image {
        ...Image
      }
    }
  }
  ${IMAGE_FRAGMENT}
`
