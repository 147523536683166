import { gql, useQuery } from "@apollo/client"
import { Link } from "react-router-dom"
import { useIntercom } from "react-use-intercom"
import tw from "twin.macro"

import TrialUpgradePromo from "./components/TrialUpgradePromo"
import { PLANS } from "./useSubscriptionPlanData"
import { formatPrice } from "../common/format"
import { generateRealmPath } from "../common/realm"
import { formatDayWithDate, relativeTime } from "../common/utilities"
import PricingTabs from "../landing/pricing/PricingTabs"
import Container from "../sites/App/Container"

import { SubscriptionTrialCardStatus } from "@/types/graphql-types"
import { Subscriptions_UserSubscriptionQuery } from "@/types/graphql-types"

const UserSubscription = () => {
  const { data } = useQuery<Subscriptions_UserSubscriptionQuery>(
    USER_SUBSCRIPTION_QUERY,
  )

  const { show } = useIntercom()

  const subscription = data?.me?.subscription

  if (!data) {
    return null
  }

  return (
    <div css={{ minHeight: "80vh" }}>
      {subscription && (
        <Container tw="px-5 pt-8 pb-12">
          {subscription.isTrialActive &&
            subscription.trialCardStatus ===
              SubscriptionTrialCardStatus.CARDLESS &&
            !subscription.isCancelRegistered && <TrialUpgradePromo />}
          <div tw="flex flex-row items-stretch gap-6">
            <Box tw="flex-3">
              <BoxHeader>Plan</BoxHeader>
              <div tw="pt-6">
                <div tw="text-lg">Goody for Business Pro</div>
                <div tw="text-lg text-gray-400">
                  {getPlanTerms(subscription)}
                </div>
                <div tw="pt-6 mt-auto text-primary-600 font-text">
                  {getNextPlanEvent(subscription)}
                </div>
              </div>
            </Box>
            <Box tw="flex-1">
              <BoxHeader>Actions</BoxHeader>
              <button
                tw="font-text text-gray-500 hover:text-gray-800 block mt-1 transition-colors"
                onClick={show}
              >
                Message support
              </button>
              <a
                href="mailto:plus-support@ongoody.com"
                rel="noopener nofollow noreferrer"
                tw="font-text text-gray-500 hover:text-gray-800 block mt-1 transition-colors"
              >
                Email support
              </a>
              {!subscription?.isCancelRegistered && (
                <Link
                  to={generateRealmPath("plus", "/account/subscription-cancel")}
                  tw="font-text text-gray-500 hover:text-gray-800 block mt-1 transition-colors"
                >
                  Cancel subscription
                </Link>
              )}
            </Box>
          </div>
        </Container>
      )}
      <div>
        <PricingTabs upsellText={true} />
      </div>
    </div>
  )
}

type UserSubscriptionType = NonNullable<
  NonNullable<Subscriptions_UserSubscriptionQuery["me"]>["subscription"]
>

function getPlanTerms(subscription: UserSubscriptionType) {
  if (
    subscription.isTrialActive &&
    subscription.trialCardStatus === SubscriptionTrialCardStatus.CARDLESS
  ) {
    return "Free trial"
  }

  const planString = subscription.planSku.toString()
  const planData = PLANS[planString]

  if (planData) {
    let text = `${
      planData.interval === "monthly" ? "Monthly" : "Yearly"
    }, ${formatPrice(planData.price)}/${
      planData.interval === "monthly" ? "month" : "year"
    }`

    if (
      subscription.isTrialActive &&
      subscription.trialCardStatus === SubscriptionTrialCardStatus.CARD_ENTERED
    ) {
      text += " (trial)"
    }

    return text
  }

  return null
}

function getNextPlanEvent(subscription: UserSubscriptionType) {
  if (!subscription.isTrialActive && subscription.currentPeriodEnd) {
    const currentPeriodEndDate = new Date(subscription.currentPeriodEnd)

    const currentPeriodEndDateText = `${formatDayWithDate(
      currentPeriodEndDate,
    )}, ${currentPeriodEndDate.getFullYear()} (in about ${relativeTime(
      currentPeriodEndDate,
    )})`

    if (subscription.isCancelRegistered) {
      return `\u2715 Canceled, downgrading on ${currentPeriodEndDateText}`
    } else {
      return `Next billing on ${currentPeriodEndDateText}`
    }
  }
}

const Box = tw.div`border border-gray-150 p-6 shadow-min rounded-xl`
const BoxHeader = tw.div`font-medium text-gray-600`

export const USER_SUBSCRIPTION_QUERY = gql`
  query Subscriptions_UserSubscription {
    me {
      id
      subscription {
        id
        planSku
        isTrialActive
        trialEnd
        currentPeriodEnd
        isCancelRegistered
        trialCardStatus
        managementType
      }
    }
    organization {
      id
      subscription {
        id
        planSku
        isTrialActive
        trialEnd
        currentPeriodEnd
        isCancelRegistered
        trialCardStatus
        managementType
      }
    }
  }
`

export default UserSubscription
