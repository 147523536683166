import { Helmet } from "react-helmet-async"

import {
  AddBalanceModalProvider,
  useAddBalanceModal,
} from "@/common/hooks/addBalanceModal"
import { BusinessSendProvider } from "@/common/hooks/send"
import PaymentMethods from "@/common/payments/PaymentMethods"
import { AddBalanceModal } from "@/send/AddBalanceModal"

const PageContent = () => {
  const { addBalanceModalOpen, closeAddBalanceModal } = useAddBalanceModal()

  return (
    <>
      <div tw="container mx-auto">
        <div tw="max-w-[680px] mx-6 my-12">
          <PaymentMethods title={undefined} />
        </div>
      </div>
      <AddBalanceModal
        isOpen={addBalanceModalOpen}
        close={closeAddBalanceModal}
      />
    </>
  )
}

const PaymentMethodsPage = () => (
  <>
    <Helmet>
      <title>Account - Payment Methods – Goody for Business</title>
    </Helmet>
    <BusinessSendProvider>
      <AddBalanceModalProvider>
        <PageContent />
      </AddBalanceModalProvider>
    </BusinessSendProvider>
  </>
)

export default PaymentMethodsPage
