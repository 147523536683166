import { useEffect, useState } from "react"
import tw, { styled } from "twin.macro"

import { useCustomStore } from "@/store/custom/hooks"
import {
  Alert,
  AlertColor,
} from "@/track/components/giftBatch/GiftBatchSettings/Alert"
import { MultipleSelectModeEnum } from "@/types/graphql-types"

export const MultipleSelectError = () => {
  const {
    multipleSelectMode,
    containsGiftCard,
    containsAlcohol,
    internationalShippingGlobalRelayEnabled,
    internationalShippingGiftCardsEnabled,
    hasRestrictedProductOrSetting,
    disableMultipleSelection,
  } = useCustomStore()

  const [shouldDisplayError, setShouldDisplayError] = useState<boolean>(false)

  const getPrimaryMessage = () => {
    if (containsGiftCard) {
      return "You have gift cards in your custom store"
    }

    if (containsAlcohol) {
      return "You have alcohol in your custom store"
    }

    if (
      internationalShippingGiftCardsEnabled ||
      internationalShippingGlobalRelayEnabled
    ) {
      return "International shipping is enabled"
    }

    return ""
  }

  const secondaryMessageText = () => {
    if (containsGiftCard) {
      return (
        "Sorry, multiple gift selection isn’t supported for custom stores " +
        " containing gift cards. You can either remove the gift cards from your custom store, or "
      )
    }

    if (containsAlcohol) {
      return (
        "Sorry, multiple gift selection isn’t supported for custom stores containing alcohol. You can " +
        "either remove the alcohol from your custom store, or "
      )
    }

    if (
      internationalShippingGiftCardsEnabled ||
      internationalShippingGlobalRelayEnabled
    ) {
      return (
        "Sorry, multiple gift selection isn’t supported for custom stores using international shipping. " +
        "You can either disable international shipping, or "
      )
    }

    return ""
  }

  const getSecondaryMessage = () => {
    return (
      <div>
        {secondaryMessageText()}
        <DisableButton onClick={disableMultipleSelection}>
          disable multiple selection
        </DisableButton>
        .
      </div>
    )
  }

  useEffect(() => {
    setShouldDisplayError(
      multipleSelectMode !== MultipleSelectModeEnum.disabled &&
        hasRestrictedProductOrSetting,
    )
  }, [multipleSelectMode, hasRestrictedProductOrSetting])

  if (!shouldDisplayError) {
    return null
  }

  return (
    <div tw="mb-6">
      <Alert
        color={AlertColor.red}
        primaryMessage={getPrimaryMessage()}
        secondaryMessage={getSecondaryMessage()}
      />
    </div>
  )
}

const DisableButton = styled.button`
  ${tw`border-b border-[#B91C1C] pb-0 leading-[18px]`}
`
