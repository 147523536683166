import arrowDownToLine from "@/assets/icons/arrow-down-to-line.svg"
import squareArrowOutUpRight from "@/assets/icons/square-arrow-out-up-right.svg"
import triangleAlertSolid from "@/assets/icons/triangle-alert-solid.svg"
import Button from "@/common/CommonButton"
import { getEnvVars } from "@/common/environment"
import { formatPrice } from "@/common/format"
import Image from "@/common/Image"
import Table, { ColumnProps, TableProps } from "@/common/Table"
import { BundledReceipt } from "@/types/graphql-types"

type BundledReceiptsTableProps = Omit<TableProps<BundledReceipt>, "columns"> & {
  userColumn?: boolean
}

const BundledReceiptsTable = ({
  userColumn = false,
  ...props
}: BundledReceiptsTableProps) => {
  const columns: ColumnProps<BundledReceipt>[] = [
    {
      title: "Date",
      render: ({ closeDate }) => new Date(closeDate).toLocaleDateString(),
    },
    {
      title: "Receipt #",
      render: ({ id, isBatchBillingGroupFailed, accessToken }) =>
        isBatchBillingGroupFailed ? (
          <div tw="flex flex-col gap-2 leading-5">
            {id}
            <a
              href={`${getEnvVars().giftsUrl}/business_receipts/${accessToken}`}
              target="_blank"
              rel="noreferrer"
              tw="max-w-max flex items-center gap-[6px] rounded-lg bg-[#EB5757] px-2 py-1 text-white font-bold leading-5"
            >
              <Image src={triangleAlertSolid} />
              Payment failed, click to resolve
            </a>
          </div>
        ) : (
          id
        ),
    },
    {
      title: "Amount charged",
      render: ({ amountNetCharges, paymentMethodName }) => (
        <div>
          <div>{formatPrice(amountNetCharges)}</div>
          <div tw="text-gray-450">{paymentMethodName}</div>
        </div>
      ),
    },
    {
      title: "View",
      render: ({ accessToken }) => (
        <div tw="flex gap-3">
          <a
            href={`${getEnvVars().giftsUrl}/business_receipts/${accessToken}`}
            target="_blank"
            rel="noreferrer"
            tw="flex"
          >
            <Button leftIcon={squareArrowOutUpRight}>Open</Button>
          </a>
          <a
            href={`${
              getEnvVars().giftsUrl
            }/business_receipts/${accessToken}.pdf`}
            rel="noreferrer"
            tw="flex"
          >
            <Button leftIcon={arrowDownToLine}>PDF</Button>
          </a>
        </div>
      ),
    },
  ]

  if (userColumn)
    columns.splice(1, 0, {
      title: "User",
      render: ({ user }) => (
        <div>
          <div tw="capitalize">{user?.name}</div>
          <div tw="text-gray-450">{user?.email}</div>
        </div>
      ),
    })

  return <Table<BundledReceipt> columns={columns} {...props} />
}

export default BundledReceiptsTable
