import { formatPrice } from "@/common/format"
import { ReactComponent as ShippingIcon } from "@/common/images/icon-shipping.svg"
import GlobalShippingPriceExplanation from "@/store/components/shipping/GlobalShippingPriceExplanation"
import { GiftOptionType } from "@/store/types"
import { giftOptionIsGiftCard } from "@/store/utils"
import { flex_gift_product_price_type } from "@/types/graphql-types"

interface Props {
  giftOption: GiftOptionType
  swapStorePriceType: flex_gift_product_price_type | null
  withGlobalShipping?: boolean
  withDomesticShipping?: boolean
}

const GiftOptionShippingLabel = ({
  giftOption,
  swapStorePriceType,
  withGlobalShipping,
  withDomesticShipping,
}: Props) => {
  if (
    giftOption.brand.shippingPrice === null ||
    giftOption.brand.shippingPrice === undefined ||
    giftOptionIsGiftCard(giftOption)
  ) {
    return null
  }

  const freeShippingMinimumGiftOption =
    giftOption.brand.freeShippingMinimum &&
    giftOption.priceMin &&
    giftOption.priceMin >= giftOption.brand.freeShippingMinimum

  const freePrice =
    freeShippingMinimumGiftOption || giftOption.brand.shippingPrice === 0

  const formattedPrice = freePrice
    ? "Free"
    : formatPrice(giftOption.brand.shippingPrice)

  return (
    <>
      <div tw="flex flex-row items-start justify-center pt-3">
        <ShippingIcon tw="mr-2 mt-1 stroke-current text-gray-400" />
        <div tw="text-gray-600">
          {giftOption.isFlexGift &&
          swapStorePriceType === flex_gift_product_price_type.VARIABLE ? (
            <span tw="text-gray-400">Included</span>
          ) : (
            <>
              {!withGlobalShipping && `${formattedPrice} shipping`}
              {withGlobalShipping && (
                <GlobalShippingPriceExplanation
                  formattedPrice={!freePrice && `${formattedPrice} shipping`}
                  withDomesticSupport={withDomesticShipping}
                />
              )}
            </>
          )}
        </div>
      </div>
      {!giftOption.isFlexGift &&
        !freeShippingMinimumGiftOption &&
        giftOption.brand.freeShippingMinimum && (
          <div tw="text-center text-primary-600 mt-2">
            Free shipping over{" "}
            {formatPrice(giftOption.brand.freeShippingMinimum)}
          </div>
        )}
    </>
  )
}

export default GiftOptionShippingLabel
