import { useState } from "react"

export const useCopy = () => {
  const [copied, setCopied] = useState(false)
  const copy = async (text: string) => {
    await navigator.clipboard.writeText(text)
    setCopied(true)

    setTimeout(() => {
      setCopied(false)
    }, 3000)
  }

  const canCopy = !!navigator.clipboard

  return { copy, copied, canCopy }
}
