import BudgetingIndex from "./BudgetingIndex"
import { BudgetingOrganizationDeposit } from "./BudgetingOrganizationDeposit"
import RealmRoute from "../../common/RealmRoute"

export default function Budgeting() {
  return (
    <>
      <RealmRoute path="/organization/budgeting" exact realm="plus">
        <BudgetingIndex />
      </RealmRoute>
      <RealmRoute path="/organization/budgeting/deposit" exact realm="plus">
        <BudgetingOrganizationDeposit />
      </RealmRoute>
    </>
  )
}
