import BudgetingDeposit from "./BudgetingDeposit"
import { generateRealmPath } from "../../common/realm"

import { BalanceDepositAccount } from "@/types/graphql-types"

export const BudgetingOrganizationDeposit = () => (
  <BudgetingDeposit
    donePath={generateRealmPath("plus", "/organization/budgeting")}
    title="Add funds to organization balance"
    subtitle="Use funds for recurring budgets or one-off transfers to member balances."
    accountType={BalanceDepositAccount.organization}
    backText={"Back to Budgeting"}
  />
)
