import { gql, useMutation } from "@apollo/client"

import { errorToast, successToast } from "../toast"

import { SendEmailVerificationMutation } from "@/types/graphql-types"

export default function useSendEmailVerification() {
  const [sendEmailVerificationMutation, { loading }] =
    useMutation<SendEmailVerificationMutation>(SEND_EMAIL_VERIFICATION_MUTATION)

  const sendEmailVerification = async () => {
    const { data } = await sendEmailVerificationMutation()
    if (data?.emailVerificationSend?.ok) {
      successToast("Email verification sent. Please check your email.")
      return true
    } else {
      errorToast(
        data?.emailVerificationSend?.error ||
          "Error sending email verification.",
      )
      return false
    }
  }

  return {
    sendEmailVerification,
    sendEmailVerificationLoading: loading,
  }
}

const SEND_EMAIL_VERIFICATION_MUTATION = gql`
  mutation SendEmailVerification {
    emailVerificationSend {
      ok
      error
    }
  }
`
