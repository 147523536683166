import { isObject } from "lodash-es"
import { ButtonHTMLAttributes, ReactNode } from "react"
import { CSSObject } from "styled-components/macro"
import tw from "twin.macro"

import Image from "@/common/Image"

const VARIANTS: { [key: string]: CSSObject | CSSObject[] } = {
  default: tw`
    text-gray-700 bg-white border-gray-200
    hover:(bg-primary-new-000 border-primary-new-150)
    active:(bg-primary-new-050 border-primary-new-150)`,
  primaryNew: tw`
    text-white bg-primary-new-550 border-primary-new-600
    hover:(bg-primary-new-600 border-primary-new-600)
    disabled:hover:(text-white bg-primary-new-550 border-primary-new-600)
    active:(bg-primary-new-700 border-primary-new-700)
    disabled:active:(text-white bg-primary-new-550 border-primary-new-600)`,
}

export interface CommonButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  leftIcon?: ReactNode | string
  rightIcon?: ReactNode | string
  variant?: CSSObject | CSSObject[] | string
}

const resolveVariant = (
  variant?: CSSObject | CSSObject[] | keyof typeof VARIANTS,
) => (isObject(variant) ? variant : VARIANTS[variant ?? "default"])

const CommonButton = ({
  leftIcon,
  rightIcon,
  variant,
  children,
  ...props
}: CommonButtonProps) => (
  <button
    css={[
      tw`
        transition-all
        inline-flex items-center justify-center
        gap-2 px-4 py-[10px]
        rounded-xl border shadow-0-2-4-gray-350-10 outline-none
        disabled:(opacity-50 cursor-default)`,
      resolveVariant(variant),
    ]}
    {...props}
  >
    {leftIcon &&
      (typeof leftIcon == "string" ? <Image src={leftIcon} /> : leftIcon)}
    <div tw="whitespace-nowrap">{children}</div>
    {rightIcon &&
      (typeof rightIcon == "string" ? (
        <Image src={rightIcon} tw="-mr-1" />
      ) : (
        <div tw="-mr-1">{rightIcon}</div>
      ))}
  </button>
)

export default CommonButton
