import tw from "twin.macro"

import { CountryFlag } from "@/common/CountryFlag"
import { ShippingCountry } from "@/types/graphql-types"

interface Props {
  country: ShippingCountry
  selected: boolean
  onClick: (country: ShippingCountry) => void
}

export const CountrySelectorItem = ({ country, selected, onClick }: Props) => {
  return (
    <div
      css={[
        tw`flex flex-row gap-3 items-center py-2 px-4 rounded-lg cursor-pointer`,
        selected
          ? tw`bg-primary-new-050`
          : tw`hover:(bg-gray-100) active:(bg-gray-150)`,
      ]}
      onClick={() => onClick(country)}
    >
      <CountryFlag code={country.code} size="m" />
      <span tw="text-black text-base font-normal">{country.name}</span>
    </div>
  )
}
