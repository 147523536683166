import tw, { styled } from "twin.macro"

import PurpleGradientLink from "../../../common/PurpleGradientLink"
import { generateRealmPath } from "../../../common/realm"
import staticAssetUrl from "../../../common/staticAssetUrl"
import WhiteBackgroundLink from "../../../common/WhiteBackgroundLink"

const HeroMobile = staticAssetUrl(
  "landing/how_it_works/branded_swag/HeroMobile.png",
)
const HeroDesktop = staticAssetUrl(
  "landing/how_it_works/branded_swag/HeroDesktop.png",
)

const Hero = () => (
  <OuterContainer>
    <ImageContainer>
      <Image
        tw="lg:hidden h-[35rem] min-w-[400px] object-cover object-bottom"
        src={HeroMobile}
      />
      <Image
        tw="hidden lg:block mx-auto h-full object-cover"
        src={HeroDesktop}
      />
    </ImageContainer>
    <InnerContainer>
      <div tw="w-screen mx-auto">
        <div tw="px-5 mx-auto flex flex-col items-center">
          <div tw="lg:mx-auto text-center">
            <div tw="font-bold text-dark-blue text-3xl lg:text-5xl text-3xl lg:text-5xl lg:leading-[3.5rem] mb-4">
              A better way to send branded swag.
            </div>
          </div>
          <h1 tw="text-xl lg:text-2xl text-gray-600 leading-normal text-center mb-4 max-w-[40rem]">
            From global on-demand swag to custom digital stores, we're here to
            help bring your brand to life.
          </h1>
          <div tw="flex flex-col lg:flex-row items-center justify-center mt-6 gap-2 lg:gap-4 w-full">
            <PurpleGradientLink
              tw="lg:text-lg px-10 w-full sm:w-[14rem] lg:w-auto"
              to={generateRealmPath("business", "/business/signup")}
            >
              Get Started
            </PurpleGradientLink>
            <WhiteBackgroundLink
              tw="lg:text-lg px-10 w-full sm:w-[14rem] lg:w-auto text-center bg-white transition-all"
              to={generateRealmPath("business", "/book-a-call")}
            >
              Book a Call
            </WhiteBackgroundLink>
          </div>
        </div>
      </div>
    </InnerContainer>
  </OuterContainer>
)

const InnerContainer = tw.div`
  absolute h-full top-0 flex items-center
`

const OuterContainer = styled.div`
  ${tw`relative h-[35rem] overflow-x-hidden`}

  ${tw`lg:h-[36rem]`}
`

const ImageContainer = tw.div`
  absolute z-0 inset-0
`

const Image = styled.img`
  ${tw`w-full z-0`}
`

export default Hero
