import { Link } from "react-router-dom"

import { categoryNameToSlug } from "./category"
import { track } from "../common/analytics"

interface Props {
  postSlug: string
}

export default function SidebarMenu({ postSlug }: Props) {
  return (
    <div tw="md:pr-12 pb-6 text-center md:text-left">
      <Link
        to="/blog"
        tw="font-integral-cf text-xl text-primary-new-600 hover:text-primary-new-700 transition-colors block"
        onClick={() =>
          track("Blog - Post - Click Sidebar Menu Link", {
            postSlug,
            name: "Home",
          })
        }
      >
        Goody Knows What's Good!
      </Link>
      <Link
        to="/blog"
        tw="pt-2 text-gray-500 hover:text-gray-600 transition-colors block"
        onClick={() =>
          track("Blog - Post - Click Sidebar Menu Link", {
            postSlug,
            name: "Home",
          })
        }
      >
        The Goody Blog
      </Link>

      <div tw="pt-12 hidden md:block">
        {[
          "Home",
          "Gift Guides",
          "Founder Q&A",
          "Our Community",
          "What’s Goody",
          "Marketing",
        ].map((name) => (
          <SidebarLink key={name} name={name} postSlug={postSlug} />
        ))}
      </div>
    </div>
  )
}

function SidebarLink({ name, postSlug }: { name: string; postSlug: string }) {
  const linkTo = name != "Home" ? `/blog#${categoryNameToSlug(name)}` : "/blog"

  return (
    <Link
      to={linkTo}
      tw="text-gray-600 hover:text-primary-new-600 pb-3 block text-lg transition-colors"
      onClick={() =>
        track("Blog - Post - Click Sidebar Menu Link", {
          postSlug,
          name: name,
        })
      }
    >
      {name}
    </Link>
  )
}
