import { capitalize } from "lodash-es"
import { Dispatch, FC, SetStateAction } from "react"
import tw, { styled } from "twin.macro"

import Toggle from "@/common/components/Toggle"

interface Props {
  selectedCategorySet: CategorySet
  setSelectedCategorySet: Dispatch<SetStateAction<CategorySet>>
}

export enum CategorySet {
  CATEGORIES = "CATEGORIES",
  OCCASIONS = "OCCASIONS",
}

const CategorySetToggle: FC<Props> = ({
  selectedCategorySet,
  setSelectedCategorySet,
}) => (
  <div tw="py-6 px-4 lg:pb-0">
    <StyledToggle
      state={selectedCategorySet}
      leftState={CategorySet.CATEGORIES}
      leftText={capitalize(CategorySet.CATEGORIES)}
      rightText={capitalize(CategorySet.OCCASIONS)}
      onLeftClick={() => setSelectedCategorySet(CategorySet.CATEGORIES)}
      onRightClick={() => setSelectedCategorySet(CategorySet.OCCASIONS)}
      width="100%"
      bgColor="#f9fafb"
    />
  </div>
)

const StyledToggle = styled(Toggle)`
  border-color: transparent;

  .toggle-container {
    ${tw`lg:text-sm font-bold`}

    .toggle-button {
      color: #757c89;
    }
  }
`

export default CategorySetToggle
