import { gql } from "@apollo/client"

import { GIFT_OPTION_INTERFACE_DATA_FRAGMENT } from "./GiftOptionInterfaceDataFragment"
import { DETAILS_PRODUCT_FRAGMENT } from "../../../common/queries"

import { IMAGE_FRAGMENT } from "@/graphql"

export const GIFT_OPTION_DATA_FRAGMENT = gql`
  fragment Store_GiftOptionData on GiftOption {
    ...Store_GiftOptionInterfaceData
    products(shippingCountry: $shippingCountry) {
      ...Details_Product
      giftCardInfo {
        expiryAndValidity
        denominations
        description
        howToUse
        isSlidingScale
        termsAndConditions
        valueRestrictions {
          max
          min
        }
        currency
        currencyRate
      }
      giftCollectionTiers {
        id
        name
        colorToken
        priceMax
        products {
          id
          slug
          name
          price
          brand {
            id
            slug
          }
          productImages {
            id
            imageThumb {
              ...Image
            }
          }
        }
      }
    }
  }
  ${DETAILS_PRODUCT_FRAGMENT}
  ${GIFT_OPTION_INTERFACE_DATA_FRAGMENT}
  ${IMAGE_FRAGMENT}
`
