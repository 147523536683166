import tw from "twin.macro"

import squareArrowOutUpRight from "@/assets/icons/square-arrow-out-up-right.svg"
import Button from "@/common/CommonButton"
import { formatPrice } from "@/common/format"
import Table, { TableProps } from "@/common/Table"
import { SubscriptionInvoice } from "@/types/graphql-types"

export type SubscriptionInvoiceRow = SubscriptionInvoice & { planName: string }
type SubscriptionReceiptsTableProps = Omit<
  TableProps<SubscriptionInvoiceRow>,
  "columns"
>

const SubscriptionReceiptsTable = (props: SubscriptionReceiptsTableProps) => (
  <Table<SubscriptionInvoiceRow>
    columns={[
      {
        title: "Date",
        render: ({ created }) => new Date(created).toLocaleDateString(),
      },
      {
        title: "Receipt #",
        render: ({ number, planName }) => (
          <div>
            <div>{number}</div>
            <div tw="text-gray-450">{planName}</div>
          </div>
        ),
      },
      {
        title: "Amount charged",
        render: ({ total }) => formatPrice(total),
      },
      {
        title: "View",
        render: ({ hostedInvoiceUrl }) => {
          const disabled = typeof hostedInvoiceUrl != "string"
          return (
            <a
              href={hostedInvoiceUrl || "#"}
              target="_blank"
              rel="noreferrer"
              css={[tw`flex`, disabled ? tw`cursor-not-allowed` : undefined]}
              onClick={(e) => {
                if (disabled) e.preventDefault()
              }}
            >
              <Button leftIcon={squareArrowOutUpRight}>Open</Button>
            </a>
          )
        },
      },
    ]}
    {...props}
  />
)

export default SubscriptionReceiptsTable
