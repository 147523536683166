import Internationally from "./images/internationally.png"

const GiftingInternationally = () => {
  return (
    <div tw="flex flex-col lg:flex-row items-center justify-center bg-[#FAFAFA] py-24 px-5">
      <img src={Internationally} />
      <div tw="lg:ml-16">
        <div tw="text-3xl font-bold">
          <span tw="whitespace-nowrap">Gifting internationally?</span>{" "}
          <span tw="whitespace-nowrap">No problem.</span>
        </div>
        <div tw="text-xl pt-2 text-gray-600">
          Goody delivers gifts and gift cards in
        </div>
        <div tw="text-xl font-medium p-0 text-gray-800 pt-1">
          140+ supported countries.
        </div>
      </div>
    </div>
  )
}

export default GiftingInternationally
