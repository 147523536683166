import React, { useState } from "react"
import tw, { styled } from "twin.macro"

import GiftLinkURLContainer from "./giftLink/GiftLinkURLContainer"
import { ReactComponent as ArrowIconRed } from "../../assets/icons/arrow-right-red.svg"
import { ReactComponent as ArrowIconYellow } from "../../assets/icons/arrow-right-yellow.svg"
import { ReactComponent as ClockIconRed } from "../../assets/icons/clock-red.svg"
import { ReactComponent as ClockIconYellow } from "../../assets/icons/clock-yellow.svg"
import { track } from "../../common/analytics"
import { formatScheduledSendDate } from "../../common/format"

import { GiftBatchSendStatus } from "@/types/graphql-types"

interface Props {
  sendStatus: GiftBatchSendStatus
  scheduledSendOn: string
  productUnavailable: boolean
  landingPageEnabled?: boolean | null
  giftLink?: string | null
}

const hasGiftBatchError = (
  sendStatus: GiftBatchSendStatus,
  productUnavailable: boolean,
) => {
  return sendStatus === GiftBatchSendStatus.FAILED || productUnavailable
}

const displayMessage = (sendStatus: GiftBatchSendStatus) => {
  return (
    // Component caller will have verified that this is a scheduled send, so
    // PENDING can be used here without including non-scheduled sends.
    sendStatus === GiftBatchSendStatus.PENDING ||
    sendStatus === GiftBatchSendStatus.FAILED
  )
}

const canEdit = (sendStatus: GiftBatchSendStatus) => {
  return sendStatus === GiftBatchSendStatus.PENDING
}

// Display container, text and icons corresponding to the given scheduled send status.
const ScheduledSendMessage = ({
  sendStatus,
  scheduledSendOn,
  productUnavailable,
  landingPageEnabled,
  giftLink,
}: Props) => {
  const [urlCopied, setUrlCopied] = useState(false)

  const onUrlPressCopy = async () => {
    await navigator.clipboard.writeText(giftLink || "")
    setUrlCopied(true)
    setTimeout(() => setUrlCopied(false), 3000)

    track("Business - Track Gift Batch - Copy Gift Batch Link")
  }

  return (
    <div tw="flex-1 lg:flex-5 p-5">
      {displayMessage(sendStatus) && (
        <div tw="rounded-lg overflow-hidden">
          <div
            tw="p-5"
            css={{
              ...(hasGiftBatchError(sendStatus, productUnavailable)
                ? { backgroundColor: "rgba(235, 87, 87, 0.15)" }
                : { backgroundColor: "rgba(250, 239, 205, 0.3)" }),
            }}
          >
            <div tw="flex flex-col items-center">
              <div tw="flex justify-center items-center mb-1 mr-3">
                {hasGiftBatchError(sendStatus, productUnavailable) ? (
                  <ClockIconRed />
                ) : (
                  <ClockIconYellow />
                )}
              </div>
              <div tw="font-medium mb-4 mr-3">
                <div
                  tw=""
                  css={{
                    ...(hasGiftBatchError(sendStatus, productUnavailable)
                      ? { color: "#EB5757" }
                      : { color: "#BD8E00" }),
                  }}
                >
                  {sendStatus === GiftBatchSendStatus.FAILED
                    ? "Scheduled gift was not sent"
                    : productUnavailable
                    ? "Scheduled gift needs to be updated"
                    : "Scheduled"}
                </div>
              </div>
              {!hasGiftBatchError(sendStatus, productUnavailable) ? (
                <div tw="text-center">
                  {formatScheduledSendDate(scheduledSendOn)}
                </div>
              ) : (
                <div tw="text-center">
                  <div>{formatScheduledSendDate(scheduledSendOn)}</div>
                  <div tw="mt-3 px-12">
                    {sendStatus === GiftBatchSendStatus.FAILED ? (
                      <div>
                        We’re sorry, but this scheduled gift was not sent
                        because the product was no longer available and another
                        one wasn’t selected.
                      </div>
                    ) : productUnavailable ? (
                      <div>
                        We’re sorry, but this product is no longer available.
                        Please edit your scheduled gift to select another gift.
                      </div>
                    ) : null}
                  </div>
                </div>
              )}
              {canEdit(sendStatus) && (
                <EditButton productUnavailable={productUnavailable}>
                  <div tw="mr-4">View & edit</div>
                  <div tw="flex justify-center">
                    {productUnavailable ? (
                      <ArrowIconRed />
                    ) : (
                      <ArrowIconYellow />
                    )}
                  </div>
                </EditButton>
              )}
            </div>
          </div>
        </div>
      )}
      {landingPageEnabled && giftLink && (
        <div tw="pt-4">
          <GiftLinkURLContainer
            href={giftLink}
            onUrlPressCopy={(e) => {
              e.stopPropagation()
              e.preventDefault()
              onUrlPressCopy()
            }}
            urlCopied={urlCopied}
            small={true}
          />
        </div>
      )}
    </div>
  )
}

const EditButton = styled.div<{
  productUnavailable: boolean
}>(({ productUnavailable }) => [
  tw`px-2 py-1 rounded-lg flex-grow flex flex-row justify-end items-center mt-12`,
  productUnavailable ? tw`mt-4` : tw`mt-12`,
  !productUnavailable && "border: 1px solid rgba(189, 142, 0, 0.25);",
  productUnavailable && "border: 1px solid rgba(235, 87, 87, 0.25);",
  `color: ${productUnavailable ? "#EB5757" : "#BD8E00"};`,
])

export default ScheduledSendMessage
