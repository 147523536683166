import { getDaysInMonth } from "date-fns"
import React, { useCallback, useEffect, useRef, useState } from "react"
import Select from "react-select"
import tw, { styled } from "twin.macro"

import { ReactComponent as LinkIcon } from "./images/hr-integration/link.svg"
import { ReactComponent as LockIcon } from "./images/hr-integration/lock.svg"
import { ReactComponent as BuildingIcon } from "../../assets/icons/building.svg"
import Button from "../../common/Button"
import { formatPhone, isBlank } from "../../common/format"
import { ReactComponent as ChevronDown } from "../../common/images/chevron-down.svg"
import { ReactComponent as XIcon } from "../../common/images/x.svg"
import { handleGraphQLResponse, useContactLists } from "../lib"
import { ContactList } from "../lib"
import { SaveResult } from "../types/Contact"

import { ScheduledEventInput } from "@/types/graphql-types"
import { StandardContactFragment } from "@/types/graphql-types"

interface FormDate {
  month: string
  day: string
  year: string
}

export interface FormData {
  firstName: string
  lastName: string
  title: string
  company: string
  email: string
  phone: string
  birthday: FormDate
  workAnniversary: FormDate
  listIds: string[]
}

const defaultFormData: FormData = {
  firstName: "",
  lastName: "",
  title: "",
  company: "",
  email: "",
  phone: "",
  birthday: {
    month: "",
    day: "",
    year: "",
  },
  workAnniversary: {
    month: "",
    day: "",
    year: "",
  },
  listIds: [],
}

interface ContactFormProps {
  contact?: StandardContactFragment
  onDelete?: () => Promise<SaveResult>
  onSave: (formData: FormData) => Promise<SaveResult>
  title: JSX.Element | string
  defaultListID?: string | null
}

const ContactForm = ({
  contact,
  onDelete,
  onSave,
  title,
  defaultListID,
}: ContactFormProps) => {
  const { formData, setFormData, isDirty, clearFormData } = useFormData(
    contact,
    defaultListID,
  )
  const [firstNameValid, setFirstNameValid] = useState(true)
  const [nameEdited, setNameEdited] = useState(false)
  const { contactLists } = useContactLists()
  const listSelectRef = useRef(null)

  const setDateField =
    (field: "birthday" | "workAnniversary") => (date: FormDate) => {
      setFormData((data) => ({ ...data, [field]: date }))
    }

  const setFormDataField =
    (field: keyof FormData) =>
    ({
      target: { value },
    }: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      setFormData((data) => ({
        ...data,
        [field]: value,
      }))
    }

  const save = async (e: React.FormEvent) => {
    e.preventDefault()
    const response = await onSave(formData)

    handleGraphQLResponse(response, "Saved contact")

    if (response.ok) {
      clearFormData()
    }
  }

  const removeContactFromList = (listId: string) => {
    setFormData((data) => ({
      ...data,
      listIds: data.listIds.filter((id) => id !== listId),
    }))
  }

  const deleteContact = async (e: React.MouseEvent) => {
    if (onDelete == null) {
      return
    }

    const response = await onDelete()
    handleGraphQLResponse(response, "Deleted contact")
  }

  const { formRef } = useFormEscapeProtection(isDirty)

  const contactListOptions = contactLists
    .filter(
      (list) =>
        list.id != null &&
        !formData.listIds.includes(list.id) &&
        !list.hrisSynced,
    )
    .map((list) => ({
      label: list.name,
      value: list.id,
    }))

  const handleListAdd = useCallback(
    (newListId: string | null) => {
      if (newListId == null) {
        return
      }

      setFormData((data) => ({
        ...data,
        listIds: [...data.listIds, newListId],
      }))

      const listSelect = listSelectRef.current! as HTMLInputElement
      listSelect.focus()
    },
    [setFormData],
  )

  const hrisName = contact?.hrisName

  return (
    <>
      <form
        onSubmit={save}
        tw="flex flex-col h-full"
        ref={formRef}
        autoComplete="off"
      >
        <div tw="overflow-y-auto flex-1">
          <GradientHeader>
            <div tw="text-gray-500 text-sm mb-6">{title}</div>
            <FieldGroup>
              <Field>
                First name
                <input
                  value={formData.firstName}
                  onChange={(e) => {
                    setFormDataField("firstName")(e)
                    setFirstNameValid(true)
                    setNameEdited(true)
                    e.target.checkValidity()
                  }}
                  onInvalid={() => setFirstNameValid(false)}
                  required
                  className="data-hj-suppress ph-no-capture fs-exclude"
                />
              </Field>
              <Field>
                Last name
                <input
                  value={formData.lastName}
                  onChange={(e) => {
                    setFormDataField("lastName")(e)
                    setNameEdited(true)
                  }}
                  className="data-hj-suppress ph-no-capture fs-exclude"
                />
              </Field>
            </FieldGroup>
            {!firstNameValid && (
              <FieldError>
                <FieldErrorIcon>!</FieldErrorIcon> First name is required.
              </FieldError>
            )}
            {contact?.hasPendingAutogifts && nameEdited && (
              <PendingAutoGiftNotice />
            )}
          </GradientHeader>

          {hrisName && (
            <HRISBanner>
              <LinkIconStyled />
              <div tw="text-sm">
                <div tw="font-medium pb-1">
                  This contact is synced from {hrisName}
                </div>
                <div>
                  {`You can fill in fields that haven’t been synced from ${hrisName},
                  but if you edit any fields that are synced from ${hrisName},
                  it’ll be overwritten on the next sync.`}
                </div>
              </div>
            </HRISBanner>
          )}
          <FieldGroup>
            <Field>
              Title
              <input
                value={formData.title}
                onChange={setFormDataField("title")}
                className="data-hj-suppress ph-no-capture fs-exclude"
              />
            </Field>
            <Field>
              Company
              <input
                value={formData.company}
                onChange={setFormDataField("company")}
                className="data-hj-suppress ph-no-capture fs-exclude"
              />
            </Field>
          </FieldGroup>
          <FormSection>
            <h3>Details</h3>
            <FieldGroup vertical>
              <Field>
                Email
                <input
                  value={formData.email}
                  onChange={setFormDataField("email")}
                  type="email"
                  className="data-hj-suppress ph-no-capture fs-exclude"
                />
                <FieldError>
                  <FieldErrorIcon>!</FieldErrorIcon> Invalid email format
                </FieldError>
              </Field>
              <Field>
                Phone
                <input
                  value={formatPhone(formData.phone)}
                  onChange={setFormDataField("phone")}
                  className="data-hj-suppress ph-no-capture fs-exclude"
                />
              </Field>
            </FieldGroup>
          </FormSection>
          <FormSection>
            <h3>Events</h3>
            <FieldGroup>
              <legend
                css={{
                  backgroundImage:
                    "linear-gradient(90deg, #EEF0FE 0%, #F7E9F0 100%)",
                }}
              >
                Birthday
              </legend>
              <DateFields
                value={formData.birthday}
                onChange={setDateField("birthday")}
              />
            </FieldGroup>
            <FieldGroup>
              <legend
                css={{
                  backgroundImage:
                    "linear-gradient(90deg, #EBE6FC 0%, #E8F2F9 100%)",
                }}
              >
                Work anniversary
              </legend>
              <DateFields
                value={formData.workAnniversary}
                onChange={setDateField("workAnniversary")}
              />
            </FieldGroup>
          </FormSection>
          <FormSection tw="mb-8">
            <h3>Contact lists</h3>
            <ContactListList>
              {formData.listIds.map((listId) => (
                <li
                  tw="bg-gray-100 p-3 rounded-lg text-sm flex items-center justify-between"
                  key={listId}
                  className="data-hj-suppress ph-no-capture fs-mask"
                >
                  <ContactListItem
                    contactLists={contactLists}
                    listId={listId}
                    removeContactFromList={removeContactFromList}
                  />
                </li>
              ))}
            </ContactListList>
            <div tw="px-4 lg:px-8 flex items-stretch">
              <Select
                options={contactListOptions}
                styles={{
                  container: (styles) => ({ ...styles, ...tw`flex-grow` }),
                  control: (styles) => ({ ...styles, ...tw`h-full` }),
                }}
                onChange={(option) => {
                  handleListAdd(option?.value || null)
                }}
                placeholder={
                  contactListOptions.length > 0
                    ? "Select list"
                    : "No more lists"
                }
                value={null}
                isDisabled={contactListOptions.length === 0}
                ref={listSelectRef}
                onKeyDown={(e) => e.stopPropagation()}
                menuPlacement="top"
                className="data-hj-suppress ph-no-capture fs-exclude"
              />
            </div>
          </FormSection>
        </div>
        <ActionArea>
          <Button variant="primary">Save</Button>
          {onDelete && !contact?.hrisName && (
            <DeleteButton onClick={deleteContact} type="button">
              Delete
            </DeleteButton>
          )}
        </ActionArea>
      </form>
    </>
  )
}

const PendingAutoGiftNotice = () => {
  return (
    <PendingAutoGiftNoticeAnimationDiv>
      <PendingAutoGiftNoticeDiv>
        <div tw={"font-medium mb-2 leading-5"}>
          This contact has a pending autogift
        </div>
        <div tw={"leading-5"}>
          If you used their name to personalize the autogift message, you’ll
          need to update the message manually.
        </div>
      </PendingAutoGiftNoticeDiv>
    </PendingAutoGiftNoticeAnimationDiv>
  )
}

const useFormData = (
  contact: StandardContactFragment | undefined,
  defaultListID?: string | null,
) => {
  const [formData, setFormData] = useState<FormData>(defaultFormData)
  const [dirty, setDirty] = useState(false)

  const setFormDataFromInput: React.Dispatch<React.SetStateAction<FormData>> = (
    ...args
  ) => {
    setFormData(...args)
    setDirty(true)
  }

  // Set the default list in the list of IDs if it was passed.
  useEffect(() => {
    setFormData((data) => ({
      ...data,
      listIds: getListIDs(contact, defaultListID),
    }))
  }, [defaultListID, contact, setFormData])

  // Set form data from existing contact
  useEffect(() => {
    if (contact == null) {
      return
    }

    const { firstName, lastName, title, company, email, phone } = contact
    setFormData({
      firstName: firstName || "",
      lastName: lastName || "",
      title: title || "",
      company: company || "",
      email: email || "",
      phone: phone || "",
      birthday: {
        day: contact.birthday?.day?.toString() || "",
        month: contact.birthday?.month?.toString() || "",
        year: contact.birthday?.year?.toString() || "",
      },
      workAnniversary: {
        day: contact.workAnniversary?.day?.toString() || "",
        month: contact.workAnniversary?.month?.toString() || "",
        year: contact.workAnniversary?.year?.toString() || "",
      },
      listIds: getListIDs(contact, defaultListID),
    })
  }, [contact, defaultListID])

  const getListIDs = (
    contact: StandardContactFragment | undefined,
    defaultListID: string | null | undefined,
  ) => {
    const listIds = new Set<string>(
      contact?.contactLists.map((list) => list.id),
    )

    if (defaultListID != null) {
      listIds.add(defaultListID)
    }

    return Array.from(listIds)
  }

  const clearFormData = () => {
    setFormData(defaultFormData)
    setDirty(false)
  }

  return {
    formData,
    setFormData: setFormDataFromInput,
    isDirty: dirty,
    clearFormData,
  }
}

const useFormEscapeProtection = (isDirty: boolean) => {
  const formRef = useRef(null)

  useEffect(() => {
    const { current } = formRef

    if (current == null) {
      return
    }

    const form = current! as HTMLFormElement
    const escHandler = (e: KeyboardEvent) => {
      if (e.key === "Escape" && isDirty) {
        const shouldDiscard = window.confirm(
          "Do you really want to discard all changes?",
        )
        if (!shouldDiscard) {
          e.stopPropagation()
        }
      }
    }

    form.addEventListener("keydown", escHandler)

    return () => {
      form.removeEventListener("keydown", escHandler)
    }
  }, [isDirty])

  return { formRef }
}

// Use a parent container for the growIn animation to avoid needing to transition height and padding for PendingAutoGiftNoticeDiv
const PendingAutoGiftNoticeAnimationDiv = styled.div`
  ${tw`overflow-y-hidden`};
  @keyframes growIn {
    0% {
      max-height: 0;
      opacity: 0;
    }

    100% {
      height: auto;
      max-height: 8rem; // Slightly greater than the height of the child PendingAutoGiftNoticeDiv
      opacity: 1;
    }
  }

  animation: growIn 350ms ease-in-out;
`

const PendingAutoGiftNoticeDiv = styled.div`
  ${tw`flex-1 mt-1 text-sm overflow-y-hidden items-center rounded-lg pl-4 pr-5 pt-4 pb-3`}
  background-color: #ffe8a2;
`

interface ContactListItemProps {
  contactLists: ContactList[]
  listId: string
  removeContactFromList: (listId: string) => void
}

const ContactListItem: React.FC<ContactListItemProps> = ({
  contactLists,
  listId,
  removeContactFromList,
}) => {
  const contactList = contactLists.find((list) => list.id === listId)

  return (
    <>
      {contactList?.name}
      {contactList?.hrisSynced ? (
        <div tw="flex flex-row gap-2 items-center">
          <LockIcon />
          <BuildingIcon />
        </div>
      ) : (
        <button
          onClick={() => removeContactFromList(listId)}
          tw="text-gray-300 hover:text-gray-400"
          type="button"
        >
          <XIcon tw="stroke-current w-4 h-auto stroke-1.5" />
        </button>
      )}
    </>
  )
}

const ContactListList = styled.ul`
  ${tw`px-4 lg:px-8 flex flex-col gap-1`}

  &:not(:empty) {
    ${tw`mb-3`}
  }
`

const DeleteButton = tw.button`text-gray-500 hover:text-gray-700`

const FieldErrorIcon = styled.span`
  ${tw`text-red-500 text-xs font-medium bg-white rounded-full inline-block w-4 h-4 text-center leading-tight`}
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
`

const FieldError = styled.div`
  ${tw`bg-red-050 text-red-500 rounded-lg py-2 px-2 text-sm flex gap-2 items-center font-normal`}
`

interface DateFieldsProps {
  value: FormDate
  onChange: (date: FormDate) => void
}

const DateFields: React.FC<DateFieldsProps> = ({ value: date, onChange }) => {
  const daysInMonth =
    getDaysInMonth(
      new Date(parseInt(date.year) || 2000, parseInt(date.month) - 1),
    ) || 31

  useEffect(() => {
    if (parseInt(date.day) > daysInMonth) {
      onChange({
        day: daysInMonth.toString(),
        month: date.month,
        year: date.year,
      })
    }
  }, [date, daysInMonth, onChange])

  return (
    <>
      <Field css={{ flexGrow: 2 }}>
        Month
        <MonthSelect>
          <select
            value={date.month}
            onChange={({ target: { value } }) =>
              onChange({ ...date, month: value })
            }
            className="data-hj-suppress ph-no-capture fs-exclude"
          >
            <option value=""></option>
            <option value="1">January</option>
            <option value="2">February</option>
            <option value="3">March</option>
            <option value="4">April</option>
            <option value="5">May</option>
            <option value="6">June</option>
            <option value="7">July</option>
            <option value="8">August</option>
            <option value="9">September</option>
            <option value="10">October</option>
            <option value="11">November</option>
            <option value="12">December</option>
          </select>
          <div
            css={[
              tw`absolute top-0 bottom-0 right-0 flex flex-row items-center pointer-events-none pt-1`,
              { paddingBottom: "0.6rem" },
            ]}
          >
            <ChevronDown />
          </div>
        </MonthSelect>
      </Field>
      <Field>
        Day
        <input
          value={date.day}
          onChange={({ target: { value } }) =>
            onChange({ ...date, day: value })
          }
          type="number"
          min={1}
          max={daysInMonth}
          className="data-hj-suppress ph-no-capture fs-exclude"
        />
      </Field>
      <Field css={{ flexGrow: 2, position: "relative" }}>
        Year <span tw="pl-2 font-normal text-xs text-gray-400">optional</span>
        <input
          value={date.year}
          onChange={({ target: { value } }) =>
            onChange({ ...date, year: value })
          }
          type="number"
          min={1900}
          className="data-hj-suppress ph-no-capture fs-exclude"
        />
      </Field>
    </>
  )
}

const MonthSelect = styled.div`
  ${tw`relative flex items-center`}

  > select {
    ${tw`appearance-none`}
  }

  svg {
    ${tw`stroke-current pointer-events-none`}
  }
`

const Field = styled.label`
  ${tw`flex-1 border border-gray-200 rounded-lg text-sm font-medium text-gray-400 pt-2 bg-white px-4 transition-colors`}
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);

  &:focus-within {
    ${tw`z-10 border-primary-300`}
  }

  ${FieldError} {
    ${tw`-mx-3 mb-1`}
  }

  > input,
  select {
    ${tw`text-black bg-white pt-1 relative w-full focus:outline-none`}
    padding-bottom: 0.6rem;

    &:valid,
    &:focus {
      + ${FieldError} {
        ${tw`hidden`}
      }
    }
  }

  // Prevent up and down arrows
  input[type="number"] {
    appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      appearance: none;
      margin: 0;
    }
  }
`

const FieldGroup = styled.fieldset<{ vertical?: boolean }>`
  ${tw`flex flex-1 px-4 lg:px-8 py-3`}
  ${({ vertical }) => (vertical ? tw`flex-col` : tw`flex-row`)}

  ${Field}:not(:last-child) {
    ${({ vertical }) => (vertical ? tw`rounded-b-none` : tw`rounded-r-none`)}
  }

  ${Field} + ${Field} {
    ${({ vertical }) => (vertical ? tw`rounded-t-none` : tw`rounded-l-none`)}
    // 1px margin lets the borders overlap, so only one border is visible.
    ${({ vertical }) => (vertical ? "margin-top" : "margin-left")}: -1px;
  }

  > legend {
    ${tw`text-sm text-gray-600 px-4 pt-1 pb-3 rounded-t-lg w-full -mb-2`}
  }
`

const FormSection = styled.section`
  ${tw`border-t border-gray-200 mt-11`}

  > h3 {
    ${tw`ml-5 bg-white inline-block relative text-sm text-gray-400 font-medium px-3`}
    top: -0.9rem;
  }

  > ${FieldGroup} {
    ${tw`pt-0`}
  }
`

const GradientHeader = styled.header`
  ${tw`px-4 lg:px-8 pt-6 pb-4`}
  background-image: linear-gradient(90deg, #F6F7FE 0%, #FBF4F8 100%);

  ${Field} > input {
    ${tw`text-xl pt-1`}
    padding-bottom: 0.4rem;
  }

  > ${FieldGroup} {
    ${tw`px-0`}
  }
`

const HRISBanner = styled.div`
  ${tw`rounded-md px-5 py-3 box-border mb-3 mt-6 mx-8 grid grid-cols-2 gap-5`}

  grid-template-columns: 5% auto;
  background-color: rgba(242, 201, 76, 0.2);
`

const LinkIconStyled = styled(LinkIcon)`
  ${tw`self-center`}
  color: #c3960e;
`

const ActionArea = styled.div`
  ${tw`flex justify-between w-full p-3 bottom-0 bg-white px-4 md:px-8 z-20`}

  box-shadow: 0 -2px 16px rgba(129, 101, 163, 0.08), 0 -1px 4px rgba(129, 101, 163, 0.04);
`

// Convert string month/day/year to a ScheduledEventInput.
export const createScheduledEventInput = (
  month: string,
  day: string,
  year: string,
): ScheduledEventInput | null => {
  if (!isBlank(month) && !isBlank(day)) {
    return {
      month: parseInt(month),
      day: parseInt(day),
      year: parseInt(year),
    }
  }

  return null
}

export default ContactForm
