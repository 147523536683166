import React from "react"
import { Helmet } from "react-helmet-async"

interface StoreSEOProps {
  canonicalPath?: string | null
  metaDescription?: string | null
  ogImage?: string | null
  titlePrefix?: string | null
}

const StoreSEO = ({
  canonicalPath,
  metaDescription,
  ogImage,
  titlePrefix,
}: StoreSEOProps) => {
  return (
    <Helmet>
      <title>{titlePrefix} | Goody</title>
      <link
        rel="canonical"
        href={`https://www.ongoody.com${canonicalPath ?? ""}`}
      />
      {titlePrefix && (
        <meta
          name="title"
          property="og:title"
          content={titlePrefix + " | Goody"}
        />
      )}
      {metaDescription && (
        <meta
          name="description"
          property="og:description"
          content={metaDescription}
        />
      )}
      {ogImage && <meta property="og:image" content={ogImage} />}
    </Helmet>
  )
}

export default StoreSEO
