import React, { useEffect } from "react"

import { getEnvVars } from "../environment"

interface Props {
  checkoutKey: string
  onCheckoutComplete: () => void
  // Whether to append &autoconfirm=true at the end of the checkout URL.
  // This is used for 3DSecure.
  autoconfirm: boolean
}

// iframe for checking out on consumer web
const CheckoutBox: React.FC<Props> = ({
  checkoutKey,
  onCheckoutComplete,
  autoconfirm,
}) => {
  useEffect(() => {
    // Wait for checkout complete message
    const onMessage = (e: MessageEvent) => {
      if (e.origin != getEnvVars().checkoutUrl) {
        return
      }
      if (e.data === "checkout-complete") {
        onCheckoutComplete()
      }
    }
    window.addEventListener("message", onMessage)
    return () => {
      window.removeEventListener("message", onMessage)
    }
  }, [])

  let checkoutSrc = getEnvVars().checkoutUrl + `/checkout?key=${checkoutKey}`
  if (autoconfirm) {
    checkoutSrc += "&autoconfirm=true"
  }

  return (
    <iframe
      tw={"w-full mx-auto my-8"}
      css={"max-width: 414px; min-height: 960px"}
      sandbox="allow-scripts allow-same-origin allow-forms allow-modals"
      src={checkoutSrc}
    />
  )
}

export default CheckoutBox
