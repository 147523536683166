import React from "react"

import { ReactComponent as FailedIcon } from "../../assets/icons/failed.svg"
import { ReactComponent as WaitingIcon } from "../../assets/icons/gift-status-created.svg"
import { ReactComponent as DeliveredIcon } from "../../assets/icons/gift-status-delivered.svg"
import { ReactComponent as OpenedIcon } from "../../assets/icons/gift-status-opened.svg"
import { ReactComponent as PreparingIcon } from "../../assets/icons/gift-status-paid.svg"
import { ReactComponent as ShippedIcon } from "../../assets/icons/gift-status-shipped.svg"

import { GiftViewableStatus } from "@/types/graphql-types"

type StatusDisplaySpec = [string, typeof PreparingIcon]

const ViewableStatusToStatusDisplaySpec: {
  [status in GiftViewableStatus]: StatusDisplaySpec
} = {
  ACCEPTED: ["Preparing", PreparingIcon],
  NOTIFIED: ["Preparing", PreparingIcon],
  CREATED: ["Waiting", WaitingIcon],
  DELIVERED: ["Delivered", DeliveredIcon],
  FAILED: ["Failed", FailedIcon],
  OPENED: ["Opened", OpenedIcon],
  ORDERED: ["Preparing", PreparingIcon],
  PAID: ["Preparing", PreparingIcon],
  PENDING_PAYMENT: ["Preparing", PreparingIcon],
  SHIPPED: ["Shipped", ShippedIcon],
  UNKNOWN: ["Failed", FailedIcon],
  PENDING_USER_APPROVAL: ["Pending", WaitingIcon],
}

interface Props {
  status: GiftViewableStatus
}

// Display text and icon corresponding to the given status.
const StatusDisplay: React.FC<Props> = ({ status }) => {
  const display = ViewableStatusToStatusDisplaySpec[status]

  return (
    <div tw="flex flex-row items-center text-gray-400">
      <div tw="pr-2 text-sm">{display[0]}</div>
      {React.createElement(display[1], { style: { stroke: "currentColor" } })}
    </div>
  )
}

export default StatusDisplay
