import React from "react"
import tw from "twin.macro"

const CTABannerSubHeading: React.FC = ({ children }) => {
  return <SubHeader>{children}</SubHeader>
}

const SubHeader = tw.div`mt-4 opacity-[.65] font-normal text-lg lg:text-xl leading-p-140 lg:leading-p-140`

export default CTABannerSubHeading
