import tw from "twin.macro"

import { Bag } from "../../assets/icons"

export default function CartIcon({ hasItems }: { hasItems: boolean }) {
  return (
    <Bag
      css={[
        tw`stroke-current`,
        hasItems ? tw`text-white` : tw`text-primary-new-500`,
      ]}
    />
  )
}
