import React from "react"
import { Link } from "react-router-dom"

import BalanceMoreText from "./BalanceMoreText"
import MainMenuGroup from "./MainMenuGroup"
import { formatPrice } from "../../../common/format"
import { useGlobalState } from "../../../common/GlobalState"
import { generateRealmPath } from "../../../common/realm"

export default function MobileBalance() {
  const [user] = useGlobalState("user")

  if (!user) {
    return null
  }

  return (
    <MainMenuGroup tw="mx-0 px-5 py-4 border-t border-gray-200 lg:hidden flex flex-row items-center">
      <div tw="text-[15px] text-gray-400 font-medium">Balance</div>
      <div tw="text-lg font-medium">{formatPrice(user.balance!, true)}</div>
      <Link to={generateRealmPath("plus", "/balance")} tw="ml-auto">
        <BalanceMoreText />
      </Link>
    </MainMenuGroup>
  )
}
