import { Dispatch, SetStateAction } from "react"
import tw, { styled } from "twin.macro"

import { ReactComponent as IconBrands } from "../images/icon-brands.svg"
import { ReactComponent as IconProducts } from "../images/icon-products.svg"
import { StoreMode } from "../types"

import { track } from "@/common/analytics"

export interface Props {
  mode: StoreMode
  setMode: Dispatch<SetStateAction<StoreMode>>
}

export default function ModeSwitcherProductsDefault({ mode, setMode }: Props) {
  return (
    <Container mode={mode}>
      <ModeButton
        tw="w-[130px]"
        onClick={() => {
          if (mode === "brands") {
            track("Store - Mode - Click Products", {
              defaultMode: "products",
            })
          }

          setMode("products")
        }}
        isActive={mode === "products"}
      >
        <IconProducts />
        Products
      </ModeButton>
      <ModeButton
        tw="w-[106px]"
        onClick={() => {
          if (mode === "products") {
            track("Store - Mode - Click Brands", {
              defaultMode: "products",
            })
          }

          setMode("brands")
        }}
        isActive={mode === "brands"}
      >
        <IconBrands />
        Brands
      </ModeButton>
    </Container>
  )
}

const Container = styled.div<{ mode: StoreMode }>`
  ${tw`w-[232px] min-h-[40px] bg-[#F6F7F9] flex flex-row items-stretch gap-[-4px] rounded-full relative`};

  &:after {
    ${tw`absolute top-0 bottom-0 left-0 rounded-full bg-gray-150 transition-all`};
    ${({ mode }) =>
      mode === "products"
        ? tw`w-[130px] translate-x-[0]`
        : tw`w-[106px] translate-x-[126px]`};
    content: "";
  }
`

const ModeButton = styled.button<{ isActive: boolean }>`
  ${tw`z-10 flex flex-row items-center justify-center gap-2 text-gray-500 transition-all font-medium`};

  ${({ isActive }) =>
    isActive
      ? tw`text-gray-700`
      : tw`text-gray-500 hover:text-gray-700 active:(opacity-75 scale-[0.97])`};

  svg {
    ${tw`transition-all`};
    ${({ isActive }) => (isActive ? tw`text-gray-450` : tw`text-gray-350`)};
  }

  &:hover svg {
    ${({ isActive }) => (isActive ? null : tw`text-gray-450`)};
  }
`
