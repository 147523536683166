import classNames from "classnames"
import { format } from "date-fns"
import React, { useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import tw, { styled } from "twin.macro"

import CheckoutContainer from "./components/CheckoutContainer"
import LoadingBox from "./LoadingBox"
import {
  GiftStatusAccepted,
  GiftStatusCreated,
  GiftStatusDelivered,
  GiftStatusOpened,
  GiftStatusPaid,
  GiftStatusShipped,
} from "../assets/icons"
import CardPreview from "../common/components/CardPreview"
import ConsumerContainer from "../common/components/ConsumerContainer"
import PreviewModal from "../common/components/PreviewModal"
import { QuantityTag } from "../common/components/QuantityTag"
import ShareLinkContainer from "../common/components/ShareLinkContainer"
import { useGift } from "../common/hooks/gifts"
import { successToast } from "../common/toast"
import { MessageContainer, largerTextCss } from "../common/UI"

import { CartProductProductImage } from "@/track/components/CartProductProductImage"
import { BatchSendMethod, GiftViewableStatus } from "@/types/graphql-types"
import {
  CartProductShipmentFragment,
  GiftFragment,
  ShipmentFragment,
} from "@/types/graphql-types"

interface Params {
  id: string
}

// Consumer gifts
const Gift: React.FC = () => {
  const params = useParams<Params>()
  const [previewing, setPreviewing] = useState(false)

  const { gift, refetchGift, giftPaymentCheck } = useGift(params.id)

  if (!gift) {
    return (
      <ConsumerContainer title={"Gift"}>
        <LoadingBox />
      </ConsumerContainer>
    )
  }

  const productList = gift.cartProducts

  return (
    <ConsumerContainer title={"Gift"}>
      <div tw={"pt-16 text-5xl text-center mx-auto text-center"}>
        Your gift for{" "}
        <span className="data-hj-suppress ph-no-capture fs-mask">
          {gift.recipientFirstName}
        </span>
      </div>
      <div tw={"px-4"}>
        <MessageContainer
          tw={"flex flex-row items-center mt-12 mx-auto bg-white"}
          css={"max-width: 625px;"}
        >
          <div tw={"flex flex-col gap-8 flex-1 my-9"}>
            {productList.map((cartProduct) => (
              <GiftProduct
                key={`${cartProduct.id}`}
                cartProduct={cartProduct}
              />
            ))}
          </div>
          {gift.card && (
            <CardPreview
              card={gift.card}
              css={tw`sm:mr-12 mr-4`}
              onClick={() => setPreviewing(true)}
            />
          )}
        </MessageContainer>

        <ShareLinkContainer
          header={"Gift link"}
          show={
            gift.status === "OPENED" ||
            gift.status === "NOTIFIED" ||
            gift.status === "CREATED"
          }
          gift={gift}
          css={"max-width: 625px;"}
          tw={"mx-auto"}
        />

        {gift.thankYouNote ? (
          <ThankYouContainer>
            <div tw={"text-white opacity-60"}>Thank you note</div>
            <div tw={"mt-10 text-white text-2xl text-center mx-16"}>
              {gift.thankYouNote}
            </div>
            <div tw={"mt-5 text-white opacity-60 text-2xl"}>
              {gift.recipientFirstName}
            </div>
          </ThankYouContainer>
        ) : gift.status === GiftViewableStatus.PENDING_PAYMENT &&
          gift.sendMethod !== BatchSendMethod.direct_send ? (
          <StatusContainer>
            <div tw={"text-white text-2xl text-center mx-16"}>
              {gift.recipientFirstName} accepted your gift
            </div>
          </StatusContainer>
        ) : null}
        {gift.status === GiftViewableStatus.PENDING_PAYMENT &&
          gift.sendMethod !== BatchSendMethod.direct_send && (
            <CheckoutContainer
              checkoutKey={gift.checkoutKey!}
              onManualCheckoutComplete={async () => {
                const res = await giftPaymentCheck({
                  variables: { checkoutKey: gift?.checkoutKey! },
                  context: {
                    headers: {
                      "sender-view-access-id": gift?.senderViewAccessId,
                    },
                  },
                })
                // refetch after checkout done
                if (res?.data?.giftPaymentCheck?.complete) {
                  await refetchGift()
                  successToast("Checkout complete!")
                } else {
                  window.alert(
                    "An error occurred when processing your order. Please email contact@ongoody.com with your account phone number.",
                  )
                }
              }}
              onSavedPaymentCheckoutComplete={async () => {
                await refetchGift()
                successToast("Checkout complete!")
              }}
            />
          )}
        <UpdatesBox>
          <div tw={"font-semibold text-gray-400"}>UPDATES</div>
          <UpdatesTimeline gift={gift} />
        </UpdatesBox>
        <div tw={"h-24"} />

        {gift.senderLink && (
          <PreviewModal
            isOpen={previewing}
            close={() => setPreviewing(false)}
            senderLink={gift.senderLink}
          />
        )}
      </div>
    </ConsumerContainer>
  )
}

const GiftProduct = ({
  cartProduct,
}: {
  cartProduct: CartProductShipmentFragment
}) => {
  return (
    <div
      tw={
        "flex flex-col sm:flex-row flex-1 items-start sm:items-center sm:ml-0 ml-5"
      }
    >
      <div tw={"sm:ml-10 relative"}>
        <CartProductProductImage
          cartProduct={cartProduct}
          tw={"rounded-xl w-[84px] h-[84px]"}
        />
        {cartProduct.quantity > 1 && (
          <QuantityTag>{cartProduct.quantity}</QuantityTag>
        )}
      </div>
      <div tw={"flex flex-col flex-1 sm:ml-6 mt-3.5 sm:mt-0"}>
        <div tw={"text-gray-400 text-lg"}>
          {cartProduct.productFragment.brandName}
        </div>
        <div css={largerTextCss}>
          {cartProduct.productTitle || cartProduct.productFragment.name}
        </div>
      </div>
    </div>
  )
}

interface UpdatesTimelineProps {
  gift: GiftFragment
}

const UpdatesTimeline: React.FC<UpdatesTimelineProps> = ({ gift }) => {
  // Filter out statuses that shouldn't be shown for direct send gifts
  const validStatuses = STATUS_INFO.filter((info) => {
    if (
      gift.sendMethod === BatchSendMethod.direct_send &&
      (info.statuses.includes(GiftViewableStatus.OPENED) ||
        info.statuses.includes(GiftViewableStatus.ACCEPTED) ||
        info.statuses.includes(GiftViewableStatus.PENDING_PAYMENT))
    ) {
      return false
    }

    return true
  })

  const currentStatusIndex = useMemo(
    () =>
      validStatuses.findIndex((info) => info.statuses.includes(gift.status)),
    [gift.status],
  )

  return (
    <TimelineContainer>
      {validStatuses.map((info, index) => (
        <TimelineItem
          key={info.statuses[0]}
          currentStatusIndex={currentStatusIndex}
          info={info}
          index={index}
          gift={gift}
          isLast={index === validStatuses.length - 1}
        />
      ))}
    </TimelineContainer>
  )
}

interface TimelineItemProps {
  gift: GiftFragment
  currentStatusIndex: number
  index: number
  info: (typeof STATUS_INFO)[number]
  isLast: boolean
}

const TimelineItem = ({
  gift,
  currentStatusIndex,
  index,
  info,
  isLast,
}: TimelineItemProps) => {
  const IconComponent = info.icon
  const isCurrentStep = currentStatusIndex === index
  const className = classNames("data-hj-suppress", "ph-no-capture fs-mask", {
    "current-step": isCurrentStep,
    "completed-step": currentStatusIndex > index,
  })

  const dateDisplay = useMemo(() => {
    const dateString = info.getDateString?.call(null, gift)
    const date = dateString && new Date(dateString)
    return date?.toLocaleDateString(undefined, {
      month: "short",
      day: "numeric",
    })
  }, [gift])

  const subText = info.getSubText(gift)

  return (
    <>
      <GiftStep className={className}>
        <GiftStepInner>
          <GiftStepText className={className}>
            <IconComponent />
            {info.getText(gift)}
          </GiftStepText>
          {isCurrentStep && dateDisplay && <TimeText>{dateDisplay}</TimeText>}
        </GiftStepInner>
        {isCurrentStep && subText && (
          <SubText className="data-hj-suppress ph-no-capture fs-mask">
            {subText}
          </SubText>
        )}
      </GiftStep>
      {!isLast && <Line />}
    </>
  )
}

const STATUS_INFO = [
  {
    icon: GiftStatusCreated,
    statuses: [GiftViewableStatus.CREATED, GiftViewableStatus.NOTIFIED],
    getText: (gift: GiftFragment) =>
      `You sent a gift to ${gift.recipientFirstName}`,
    getSubText: (gift: GiftFragment) =>
      `Waiting for ${gift.recipientFirstName} to accept`,
    getDateString: (gift: GiftFragment) => gift.createdAt,
  },
  {
    icon: GiftStatusOpened,
    statuses: [GiftViewableStatus.OPENED],
    getText: (gift: GiftFragment) =>
      `${gift.recipientFirstName} opened your card`,
    getSubText: (gift: GiftFragment) =>
      `Waiting for ${gift.recipientFirstName} to accept and enter address`,
    getDateString: (gift: GiftFragment) => gift.openedAt,
  },
  {
    icon: GiftStatusAccepted,
    statuses: [GiftViewableStatus.ACCEPTED, GiftViewableStatus.PENDING_PAYMENT],
    getText: (gift: GiftFragment) =>
      `${gift.recipientFirstName} accepted your gift`,
    getSubText: () => "Gift is ready for checkout",
    getDateString: (gift: GiftFragment) => gift.acceptedAt,
  },
  {
    icon: GiftStatusPaid,
    statuses: [GiftViewableStatus.PAID, GiftViewableStatus.ORDERED],
    getText: () => "You completed checkout",
    getSubText: () => "Order is processing and will be shipped soon",
    getDateString: () => null,
  },
  {
    icon: GiftStatusShipped,
    statuses: [GiftViewableStatus.SHIPPED],
    getText: () => "Gift is on its way",
    getSubText: (gift: GiftFragment) => {
      if (gift.shipments.length === 0) {
        return null
      }

      const getShipText = (shipment: ShipmentFragment) => {
        if (shipment.deliveredAt) {
          return `Delivered on ${format(
            new Date(shipment.deliveredAt),
            "EEEE, MMMM d",
          )}`
        } else if (shipment.shippedAt) {
          if (shipment.deliveryEta) {
            return `Shipped, ETA ${format(
              new Date(shipment.deliveryEta),
              "EEEE, MMMM d",
            )}`
          } else {
            return "Shipped"
          }
        }
        return "Preparing"
      }

      return (
        <div tw={"flex flex-col gap-2"}>
          {gift.shipments.map((shipment) => (
            <div>
              <div tw={"font-medium"}>{shipment.brand.name}</div>
              <div>{getShipText(shipment)}</div>
              {shipment.trackingNumber && shipment.trackingUrl && (
                <div tw="pt-2">
                  <a
                    href={shipment.trackingUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    tw="border-b border-gray-300 hover:border-gray-400 hover:text-gray-600 transition-colors"
                  >
                    {shipment.trackingCarrier} {shipment.trackingNumber}
                  </a>
                </div>
              )}
            </div>
          ))}
        </div>
      )
    },

    getDateString: (gift: GiftFragment) => gift.shippedAt,
  },
  {
    icon: GiftStatusDelivered,
    statuses: [GiftViewableStatus.DELIVERED],
    getText: () => "Gift delivered",
    getSubText: () => null,
    getDateString: (gift: GiftFragment) => gift.deliveredAt,
  },
]

const ThankYouContainer = styled.div`
  ${tw`mx-auto flex flex-col items-center rounded-t-xl sm:rounded-xl justify-center`}
  max-width: 625px;
  margin-top: 22px;
  min-height: 255px;
  background: linear-gradient(74.31deg, #e4a1b8 -7.94%, #b37dd5 99.23%), #ffffff;
  border: 1px solid #eceef1;
  box-shadow:
    0px 1px 4px rgba(0, 0, 0, 0.04),
    0px 6px 20px rgba(0, 0, 0, 0.04);
`

const StatusContainer = styled(ThankYouContainer)`
  height: 118px;
`

const UpdatesBox = styled.div`
  ${tw`sm:border rounded-xl sm:bg-white sm:p-10 mx-auto mt-10 flex flex-col sm:flex-row justify-between flex-1 items-center sm:items-start`}
  max-width: 625px;

  @media (min-width: 640px) {
    border-color: #eceef1;
    box-shadow:
      0px 1px 4px rgba(0, 0, 0, 0.04),
      0px 6px 20px rgba(0, 0, 0, 0.04);
  }
`

const GiftStep = styled.div`
  ${tw`flex flex-col px-4 py-3 opacity-50`}

  &.current-step {
    ${tw`bg-white rounded-xl z-10 opacity-100`}
    box-shadow: 0px 2px 8px rgba(79, 31, 137, 0.08), 0px 12px 40px rgba(79, 31, 137, 0.14);
  }

  &.completed-step {
    ${tw`opacity-100`}
  }
`

const GiftStepInner = styled.div`
  ${tw`flex flex-row items-center justify-between`}
`

const GiftStepText = styled.div`
  ${tw`flex flex-row items-center mr-6`}
  color: #4F4F4F;

  & > svg {
    ${tw`mr-3 stroke-current`}
    color: #B1B1B1;
  }

  &.current-step {
    ${tw`text-primary-500 font-medium`}
    & > svg {
      ${tw`text-primary-500`}
    }
  }
`

const TimeText = styled.div`
  ${tw`text-sm`}
  color: #8e8e8e;
`

const SubText = styled.div`
  ${tw`text-sm ml-8 mr-6 mt-1`};
  color: #8e8e8e;
`

const TimelineContainer = styled.div`
  ${tw`flex flex-col self-stretch sm:self-start mt-9 sm:mt-0`}
  min-width: 350px;
  &:first-child {
    margin-top: -12px;
  }
`

const Line = styled.div`
  ${tw`h-6 w-px opacity-30`}
  background-color: #CBAFEE;
  margin-left: 25px;
  margin-top: -4px;
  margin-bottom: -4px;
`

export default Gift
