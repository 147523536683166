import { ReactNode } from "react"
import tw from "twin.macro"

import Radio from "@/common/Radio"

interface Props {
  checked: boolean
  disabled?: boolean
  onClick: () => void
  children: ReactNode
}

const RadioBox = ({ checked, disabled, onClick, children }: Props) => {
  const borderColor = checked
    ? tw`border-primary-new-300`
    : tw`border-gray-250 hover:border-gray-350 active:border-gray-350`
  const cursor = disabled ? tw`cursor-not-allowed` : tw`cursor-pointer`
  return (
    <div
      css={[
        tw`flex items-start gap-3 p-4 rounded-lg cursor-pointer border active:bg-gray-050 transition ease-out duration-200`,
        borderColor,
        cursor,
      ]}
      onClick={() => {
        if (!disabled) onClick()
      }}
    >
      <Radio checked={checked} />
      <div>{children}</div>
    </div>
  )
}

export default RadioBox
