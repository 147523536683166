import "twin.macro"
import { useState } from "react"

import { ReactComponent as ArrowUpRight } from "@/assets/icons/arrow-up-right.svg"
import { Preview, PreviewStore } from "@/store/GiftOption/FlexGiftSelection"

interface Props {
  amount: number
  productID: string
}

export default function FlexGiftMobilePreview({ amount, productID }: Props) {
  const [previewStoreIsOpen, setPreviewStoreIsOpen] = useState(false)
  const amountInDollars = amount

  return (
    <div tw="lg:hidden pt-8 flex flex-row justify-center">
      <Preview onClick={() => setPreviewStoreIsOpen(true)}>
        Preview ${amountInDollars} gifts <ArrowUpRight tw="inline" />
      </Preview>
      <PreviewStore
        setIsOpen={setPreviewStoreIsOpen}
        isOpen={previewStoreIsOpen}
        previewParams={{ amount: amountInDollars * 100, productID }}
      />
    </div>
  )
}
