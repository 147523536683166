import React from "react"
import tw, { styled } from "twin.macro"

import { ReactComponent as InstagramIcon } from "../../../common/images/instagram-icon.svg"
import { ReactComponent as PathIcon } from "../../../common/images/path.svg"

import { Store_EditorialQuery } from "@/types/graphql-types"

export interface IEditorialItem {
  editorialItem: NonNullable<Store_EditorialQuery["editorialItem"]>
}

const AuthorInfo: React.FC<IEditorialItem> = ({ editorialItem }) => {
  const {
    author: name,
    authorImageSmall,
    instagram1,
    instagram2,
  } = editorialItem

  return (
    <AuthorContainer>
      <PathIcon tw="my-8" />
      <div tw="mb-2">
        {authorImageSmall && (
          <img src={authorImageSmall.url} alt="" tw="inline-block w-8" />
        )}
        <AuthorName>{name}</AuthorName>
      </div>

      {instagram1 && <Instagram handle={instagram1} />}
      {instagram2 && (
        <>
          <span tw="ml-4" />
          <Instagram handle={instagram2} />
        </>
      )}
    </AuthorContainer>
  )
}

const AuthorContainer = styled.span`
  color: #000000;

  span {
    opacity: 0.5;
  }
`

const AuthorName = styled.span`
  ${tw`ml-2`}
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
`

const Instagram = ({ handle }: { handle: string | null }) => {
  return (
    <>
      <InstagramIcon tw="inline-block w-8" />
      <span tw="text-sm ml-2">{handle}</span>
    </>
  )
}

export default AuthorInfo
