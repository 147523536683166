import tw, { styled } from "twin.macro"

import BannerBottomRightIcon from "./assets/icons/banner-bottom-right.svg"
import BannerTopLeftIcon from "./assets/icons/banner-top-left.svg"

import { isBlank } from "@/common/format"
interface Props {
  message?: string
  secondaryMessage?: string | null
}
export const MultipleSelectionBanner = ({
  message,
  secondaryMessage,
}: Props) => {
  if (isBlank(message)) {
    return null
  }

  return (
    <Container>
      <div
        tw={
          "bg-white m-[1.5px] flex-1 relative  rounded-[10.5px] overflow-hidden"
        }
      >
        <div tw={"absolute left-[21.5px] top-[21.5px]"}>
          <img src={BannerTopLeftIcon} alt="Top left banner" />
        </div>
        <div tw={"absolute bottom-0 right-0 flex"}>
          <img src={BannerBottomRightIcon} alt="Bottom right banner" />
        </div>
        <div
          tw={
            "absolute inset-0 flex flex-col items-center text-center justify-center px-4 "
          }
        >
          <MessageOuter>
            <Message>{message}</Message>
            &nbsp;&nbsp;🎉
          </MessageOuter>
          <MessageSecondary>{secondaryMessage ?? ""}</MessageSecondary>
        </div>
      </div>
    </Container>
  )
}

const Container = styled.div`
  ${tw`rounded-xl bg-gradient-to-r from-[#DDCCFF] to-[#CAC2FF] min-h-[162px] flex items-stretch`}
  box-shadow: 0px 1px 4px rgba(82, 31, 154, 0.05), 0px 4px 12px rgba(82, 31, 154, 0.05);

  @media (max-width: 900px) {
    ${tw`mx-4 mb-4`};
  }

  @media (min-width: 900px) {
    ${tw`mx-8 mb-8`};
  }
`

const MessageOuter = styled.div`
  @media (max-width: 900px) {
    ${tw`max-w-[225px] `};
  }
`

const Message = styled.span`
  ${tw`leading-[1.4] text-2xl`};
  background: linear-gradient(90deg, #9f73f7 0%, #7a68f6 100%), #7f53fd;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  @media (max-width: 900px) {
    ${tw`text-base font-medium`};
  }

  @media (min-width: 900px) {
    ${tw`text-2xl font-semibold`};
  }
`

const MessageSecondary = styled.div`
  ${tw`mt-3 text-gray-400 leading-[1.4] font-medium`};

  @media (max-width: 900px) {
    ${tw`text-xs max-w-[225px] `};
  }

  @media (min-width: 900px) {
    ${tw`text-lg max-w-[480px] `};
  }
`
