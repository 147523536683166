import { gql } from "@apollo/client"

import { IMAGE_FRAGMENT } from "@/graphql"

export const PRODUCT_LIST_FRAGMENT = gql`
  fragment ProductList on Product {
    id
    name
    subtitle
    slug
    price
    isFlexGift
    swapStoreSettings {
      priceType
    }
    brand {
      id
      slug
      shippingPrice
      freeShippingMinimum
    }
    productImages {
      id
      imageLarge {
        ...Image
      }
    }
    imagesScalable
    ecommerceIntegrationType
    swagIntegration {
      id
    }
    swagIntegrationActions
    isOfflineProduct
    shippingCountriesFinal
  }
  ${IMAGE_FRAGMENT}
`
