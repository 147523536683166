import { useMutation, useQuery } from "@apollo/client"
import { useEffect, useState } from "react"

import BalanceTransfer from "./components/BalanceTransfer"
import BudgetRule from "./components/BudgetRule"
import MemberDetailNav, { MemberDetailTab } from "./components/MemberDetailNav"
import MemberDetailOverview from "./components/MemberDetailOverview"
import MemberDetailTransactionLog from "./components/MemberDetailTransactionLog"
import {
  BALANCE_TRANSFER_MUTATION,
  BUDGETING_INDEX_QUERY,
  BUDGETING_MEMBERS_QUERY,
  BUDGETING_MEMBER_DETAIL_QUERY,
  BUDGET_RULE_UPDATE,
} from "./graphql"
import { getCentsFromString } from "../../common/currency"
import DrawerCloseButton from "../../common/DrawerCloseButton"
import { formatPrice } from "../../common/format"
import { errorToast, successToast } from "../../common/toast"
import { ROOT_DATA_QUERY } from "../../graphql"

import {
  BalanceTransferDirection,
  BudgetRecurrence,
  OrganizationMembershipAccess,
} from "@/types/graphql-types"
import {
  BudgetingOrganizationMembershipFragment,
  Budgeting_BalanceTransferMutation,
  Budgeting_BalanceTransferMutationVariables,
  Budgeting_BudgetRuleUpdateMutation,
  Budgeting_BudgetRuleUpdateMutationVariables,
  Budgeting_MemberDetailQuery,
  Budgeting_MemberDetailQueryVariables,
} from "@/types/graphql-types"

interface Props {
  membership: BudgetingOrganizationMembershipFragment
  onClose: () => void
}

export default function MemberDetail({ membership, onClose }: Props) {
  const [tab, setTab] = useState<MemberDetailTab>("overview")

  const { data } = useQuery<
    Budgeting_MemberDetailQuery,
    Budgeting_MemberDetailQueryVariables
  >(BUDGETING_MEMBER_DETAIL_QUERY, {
    variables: {
      id: membership.id,
    },
  })

  const resolvedMembership = data?.organization?.membership

  const [transferDirection, setTransferDirection] =
    useState<BalanceTransferDirection>(
      BalanceTransferDirection.FROM_ORG_TO_USER,
    )

  const [transferAmount, setTransferAmount] = useState<string>("")

  const [budgetAmount, setBudgetAmount] = useState<string>("")

  const [selectedRecurrence, setSelectedRecurrence] =
    useState<BudgetRecurrence>(BudgetRecurrence.MONTHLY)

  const currentBudgetRule = resolvedMembership?.budgetRule

  useEffect(() => {
    if (currentBudgetRule) {
      setBudgetAmount(formatPrice(currentBudgetRule.amount))
      setSelectedRecurrence(currentBudgetRule.recurrence)
    }
  }, [currentBudgetRule])

  const [budgetRuleUpdate] = useMutation<
    Budgeting_BudgetRuleUpdateMutation,
    Budgeting_BudgetRuleUpdateMutationVariables
  >(BUDGET_RULE_UPDATE)

  const handleSaveBudget = async () => {
    const cents = getCentsFromString(budgetAmount)
    if (cents === null) {
      return
    }

    const res = await budgetRuleUpdate({
      variables: {
        organizationMembershipID: membership.id,
        amount: cents,
        recurrence: selectedRecurrence,
      },
      refetchQueries: [
        {
          query: BUDGETING_MEMBER_DETAIL_QUERY,
          variables: { id: membership.id },
        },
        { query: BUDGETING_MEMBERS_QUERY },
        { query: BUDGETING_INDEX_QUERY },
        { query: ROOT_DATA_QUERY },
      ],
    })

    const data = res.data?.budgetRuleUpdate

    if (data?.ok) {
      successToast("Saved budget.")
      setTransferAmount("")
    } else {
      errorToast(data?.error || "An error occurred.")
    }
  }

  const [balanceTransfer] = useMutation<
    Budgeting_BalanceTransferMutation,
    Budgeting_BalanceTransferMutationVariables
  >(BALANCE_TRANSFER_MUTATION)

  const handleTransfer = async () => {
    const cents = getCentsFromString(transferAmount)
    if (cents === null) {
      return
    }

    const res = await balanceTransfer({
      variables: {
        organizationMembershipID: membership.id,
        amount: cents,
        direction: transferDirection,
      },
      refetchQueries: [
        {
          query: BUDGETING_MEMBER_DETAIL_QUERY,
          variables: { id: membership.id },
        },
        { query: BUDGETING_MEMBERS_QUERY },
        { query: BUDGETING_INDEX_QUERY },
        { query: ROOT_DATA_QUERY },
      ],
    })

    const data = res.data?.balanceTransfer

    if (data?.ok) {
      successToast("Transfer complete.")
      setTransferAmount("")
    } else {
      errorToast(data?.error || "An error occurred.")
    }
  }

  const isLimited =
    resolvedMembership?.access === OrganizationMembershipAccess.limited

  return (
    <div css={{ width: 896 }}>
      <div
        css={{
          background:
            "linear-gradient(360deg, #f3f4f6 -45%, rgba(243, 244, 246, 0) 115%)",
        }}
      >
        <div tw="p-16 pb-12 flex flex-row items-start justify-between">
          <div>
            <div tw="text-2xl font-semibold">{membership.member.fullName}</div>
            {membership.member.companyTitle && (
              <div tw="text-2xl text-gray-450">
                {membership.member.companyTitle}
              </div>
            )}
            <div tw="h-28" />
          </div>
          <div>
            <DrawerCloseButton onClick={onClose} />
          </div>
        </div>
      </div>

      <BalanceTransfer
        balance={resolvedMembership?.balanceAccount?.balance}
        transferDirection={transferDirection}
        setTransferDirection={setTransferDirection}
        transferAmount={transferAmount}
        setTransferAmount={setTransferAmount}
        handleTransfer={handleTransfer}
        isLimited={isLimited}
        membership={resolvedMembership || null}
      />

      <BudgetRule
        budgetAmount={budgetAmount}
        setBudgetAmount={setBudgetAmount}
        selectedRecurrence={selectedRecurrence}
        setSelectedRecurrence={setSelectedRecurrence}
        handleSaveBudget={handleSaveBudget}
        recentBudgetSettings={data?.me?.recentBudgetSettings}
        currentBudgetRule={currentBudgetRule}
        isLimited={isLimited}
      />

      <div tw="pt-8">
        <MemberDetailNav tab={tab} setTab={setTab} />

        {tab === "overview" && (
          <MemberDetailOverview membershipID={membership.id} />
        )}

        {tab === "transactions" && (
          <MemberDetailTransactionLog
            membershipID={membership.id}
            memberName={membership.member.fullName}
          />
        )}
      </div>
    </div>
  )
}
