import React from "react"
import Modal from "react-modal"

import { modalStyle } from "../modal"

interface Props {
  senderLink: string
  isOpen: boolean
  close: () => void
}

// Modal for sender previewing the gift
const PreviewModal: React.FC<Props> = ({ senderLink, isOpen, close }) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={close} style={modalStyle}>
      {senderLink && (
        <iframe
          src={senderLink}
          title={senderLink}
          tw="bg-white rounded-xl shadow-lg"
          css={{ width: "90vw", height: "90vh" }}
        />
      )}
    </Modal>
  )
}

export default PreviewModal
