import { gql, useMutation, useQuery } from "@apollo/client"
import { isEqual, isNil } from "lodash-es"
import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import { toast } from "react-hot-toast"
import { useHistory, useLocation } from "react-router-dom"
import { useDebounce } from "use-debounce"

import {
  Container,
  CustomStoreStyled,
  fieldsChanged,
  useCustomStoreFormFields,
} from "./common"
import CustomStoreBrowser from "./CustomStoreBrowser"
import CustomStoreForm from "./CustomStoreForm"
import CustomStoreView from "./CustomStoreView"
import { ARCHIVE_CUSTOM_STORE_MUTATION, CUSTOM_STORES_QUERY } from "./graphql"
import { CUSTOM_STORE_BASE_FRAGMENT } from "./graphql/queries"
import { initialCustomStoreState } from "../../common/GlobalState"
import { generateRealmPath } from "../../common/realm"
import { GIFT_OPTION_INTERFACE_DATA_FRAGMENT } from "../GiftOption/graphql/GiftOptionInterfaceDataFragment"
import { Countries } from "../giftOptionFilters/internationalShipping/InternationalShipping"

import { omitSingleKey } from "@/common/utils/omitSingleKey"
import { IMAGE_FRAGMENT } from "@/graphql"
import { useCustomStore } from "@/store/custom/hooks"
import {
  CustomStoreFullFragment,
  InternationalShippingTierEnum,
  MultipleSelectModeEnum,
  Store_CustomStoreArchiveMutation,
  Store_CustomStoreArchiveMutationVariables,
  Store_CustomStoreCreateMutation,
  Store_CustomStoreCreateMutationVariables,
  Store_CustomStoreQuery,
  Store_CustomStoreQueryVariables,
  Store_CustomStoreUpdateMutation,
  Store_CustomStoreUpdateMutationVariables,
} from "@/types/graphql-types"

interface Props {
  customStoreParamID?: string
}

const CustomStorePage = ({ customStoreParamID }: Props) => {
  const [customStore, setCustomStore] =
    useState<CustomStoreFullFragment | null>(null)
  const [customStoreBrowserOpen, setCustomStoreBrowserOpen] = useState(false)
  const { formFields, setFormField, setFormFields } = useCustomStoreFormFields()
  const [debouncedFormFields] = useDebounce(formFields, 1000)
  const [customStoreID, setCustomStoreID] = useState<string | undefined>(
    customStoreParamID,
  )
  const [isFormChanged, setIsFormChanged] = useState(false)

  const [selectedShippingCountry, setSelectedShippingCountry] =
    useState<Countries>(Countries.UNITED_STATES)

  // This is used to see if a createCustomStore has already been initiated.
  // Prevents a race condition where createCustomStore is slower than the
  // actions that trigger it (i.e. first debounce change or the store browser opening).
  // Set to false if createCustomStore failed to allow it to try again
  const [createPromise, setCreatePromise] = useState<Promise<
    string | null
  > | null>(!customStoreParamID ? null : Promise.resolve(customStoreParamID))

  // This is used to prevent a race condition where the header image is uploaded but then removed
  // by the user before the custom store is updated on the backend.
  // Without this, the image URL may persist and the image may not be removed.
  const [updatePromise, setUpdatePromise] = useState<Promise<boolean>>(
    Promise.resolve(false),
  )

  const [createCustomStore] = useMutation<
    Store_CustomStoreCreateMutation,
    Store_CustomStoreCreateMutationVariables
  >(CREATE_CUSTOM_STORE_MUTATION, {
    refetchQueries: [{ query: CUSTOM_STORES_QUERY }],
  })
  const [updateCustomStore] = useMutation<
    Store_CustomStoreUpdateMutation,
    Store_CustomStoreUpdateMutationVariables
  >(UPDATE_CUSTOM_STORE_MUTATION)
  const [archiveCustomStore] = useMutation<
    Store_CustomStoreArchiveMutation,
    Store_CustomStoreArchiveMutationVariables
  >(ARCHIVE_CUSTOM_STORE_MUTATION, {
    refetchQueries: [{ query: CUSTOM_STORES_QUERY }],
  })

  const { data: logoData } = useQuery(LOGO_QUERY)

  const { data, refetch } = useQuery<
    Store_CustomStoreQuery,
    Store_CustomStoreQueryVariables
  >(GET_CUSTOM_STORE, {
    variables: {
      customStoreId: customStoreID ?? "",
    },
  })

  const {
    setUnfilteredOptions,
    setInternationalShippingGiftCardsEnabled,
    setInternationalShippingGlobalRelayEnabled,
  } = useCustomStore()

  // Display a toast that the store has saved when the
  // user navigates away from the form
  useEffect(() => {
    // @ts-ignore
    const unblock = history.block((location, _action) => {
      const fieldsChangedFromDefault =
        debouncedFormFields !== initialCustomStoreState
      const storeChanged =
        isFormChanged || (customStore && customStore.optionList?.length > 0)

      // @ts-ignore
      if (!location.state?.changeToEditUrl) {
        setFormFields(initialCustomStoreState)
      }

      if (
        // Setting allowPush to true lets us unblock certain cases, like
        // replacing the create URL with edit URL, on save, and on archive
        // @ts-ignore
        !location.state?.allowPush &&
        fieldsChangedFromDefault &&
        storeChanged
      ) {
        // Navigating away before save happened
        if (customStore) {
          if (fieldsChanged(customStore, formFields)) {
            const savePromise = saveCustomStore(true)
            setUpdatePromise(savePromise)

            savePromise.then(() => {
              toast.success("Custom store saved")
              refetch()
            })
          } else {
            toast.success("Custom store saved")
          }
        }

        return true
      }
    })

    return () => {
      unblock()
    }
  }, [debouncedFormFields, formFields])

  useEffect(() => {
    if (customStoreParamID && !createPromise) {
      setFormFields(initialCustomStoreState)
    }
  }, [])

  const updateFormFields = async () => {
    const customStoreData = data?.me?.customStore

    if (!!customStoreData) {
      setCustomStore(customStoreData)

      let formFieldsSet
      const formFieldsAreDefault = formFields === initialCustomStoreState

      // Use customStoreData as fields if formFields are default
      if (formFieldsAreDefault) {
        formFieldsSet = {
          name: customStoreData.name,
          subtitle: customStoreData.subtitle ?? "",
          desktopImage: {
            file: formFields.desktopImage.file,
            url:
              customStoreData?.desktopHeaderImage?.url ??
              formFields.desktopImage.url,
          },
          mobileImage: {
            file: formFields.mobileImage.file,
            url:
              customStoreData?.mobileHeaderImage?.url ??
              formFields.mobileImage.url,
          },
          optionList: customStoreData.optionList,
          internationalShippingTier: customStoreData.internationalShippingTier,
          settings: omitSingleKey("__typename", customStoreData.settings),
          brandValuesDisplay: customStoreData.brandValuesDisplay,
          multipleSelectMode: isNil(customStoreData.multipleSelectMode)
            ? MultipleSelectModeEnum.disabled
            : customStoreData.multipleSelectMode,
          multipleSelectCount: customStoreData.multipleSelectCount ?? 1,
          multipleSelectPrice: customStoreData.multipleSelectPrice ?? 10000,
        }
      } else {
        // We prioritize using formFields if they are not default because sometimes customStoreData can
        // lag behind formFields
        formFieldsSet = {
          name: formFields.name ?? customStoreData.name,
          subtitle: formFields.subtitle ?? customStoreData.subtitle,
          desktopImage: {
            file: formFields.desktopImage.file,
            url:
              ((await updatePromise)
                ? formFields.desktopImage.url
                : customStoreData?.desktopHeaderImage?.url) ?? null,
          },
          mobileImage: {
            file: formFields.mobileImage.file,
            url:
              ((await updatePromise)
                ? formFields.mobileImage.url
                : customStoreData?.mobileHeaderImage?.url) ?? null,
          },
          optionList: customStoreData.optionList,
          internationalShippingTier: customStoreData.internationalShippingTier,
          settings: formFields.settings,
          brandValuesDisplay: customStoreData.brandValuesDisplay,
          multipleSelectMode:
            formFields.multipleSelectMode ??
            customStoreData.multipleSelectMode ??
            MultipleSelectModeEnum.disabled,
          multipleSelectCount:
            formFields.multipleSelectCount ??
            customStoreData.multipleSelectCount ??
            1,
          multipleSelectPrice:
            formFields.multipleSelectPrice ??
            customStoreData.multipleSelectPrice ??
            10000,
        }
      }

      // Because we call update every time form fields change, avoid setting here so we don't update twice
      if (!isEqual(formFields, formFieldsSet)) {
        setFormFields(formFieldsSet)
      }
    }
  }

  useEffect(() => {
    updateFormFields()
  }, [data?.me?.customStore])

  // handleCreateCustomStore sets customStoreID on the first debounce change.
  // But if customStoreID is not set before the next debounce change, we don't want
  // to end up with multiple creates
  useEffect(() => {
    setIsFormChanged(true)

    if (
      !customStoreID &&
      formFields !== initialCustomStoreState &&
      !createPromise
    ) {
      setCreatePromise(handleCreateCustomStore())
    } else if (!!customStore) {
      setUpdatePromise(saveCustomStore(true))
    }
  }, [debouncedFormFields])

  useEffect(() => {
    if (!!createPromise && !customStoreID) {
      createPromise.then((createStoreID) => {
        !!createStoreID && setCustomStoreID(createStoreID)
      })
    }
  }, [createPromise, customStoreID])

  useEffect(() => {
    // Don't replace if custom store browser open as it interferes with browser opening
    if (!customStoreBrowserOpen) {
      replaceCreateURLWithEdit()
    }
  }, [customStoreID])

  const location = useLocation()

  useEffect(() => {
    if (customStore?.optionList.length === 0 || customStoreBrowserOpen) {
      return
    }

    replaceCreateURLWithEdit()
  }, [customStore?.optionList.length, customStoreBrowserOpen])

  const replaceCreateURLWithEdit = () => {
    if (
      location.pathname === generateRealmPath("plus", "/store/custom/create") &&
      !!customStoreID
    ) {
      history.replace(
        generateRealmPath("plus", `/store/custom/edit/${customStoreID}`),
        {
          allowPush: true,
          changeToEditUrl: true,
        },
      )
    }
  }

  useEffect(() => {
    setIsFormChanged(true)
  }, [customStore?.optionList.length])

  const handleCreateCustomStore = async (): Promise<string | null> => {
    const res = await createCustomStore({
      variables: formFields,
    })

    if (res.data?.customStoreCreate.ok) {
      const responseCustomStoreID = res.data?.customStoreCreate?.customStore?.id

      if (!!responseCustomStoreID) {
        return responseCustomStoreID
      }

      return null
    } else {
      window.alert(res.data?.customStoreCreate.errors)
      console.log("create not ok", res.data?.customStoreCreate.errors)

      return null
    }
  }

  const history = useHistory()

  const userSaveStore = async () => {
    const result = saveCustomStore(false)

    if (await result) {
      toast.success("Custom store saved")
      history.push(generateRealmPath("plus", "/browse/custom"), {
        allowPush: true,
      })
    } else {
      toast.error("Custom store could not be saved.")
    }
  }

  const saveCustomStore = async (isAutoSave: boolean) => {
    // Don't auto-save if no custom store ID or form not modified
    if (isAutoSave && (!customStoreID || !isFormChanged)) {
      return false
    }

    let responseCustomStoreID

    if (!customStoreID && !createPromise) {
      const promise = handleCreateCustomStore()
      setCreatePromise(promise)
      responseCustomStoreID = await promise
    }

    if (!!createPromise) {
      responseCustomStoreID = await createPromise
    }

    let customStoreUpdateFields = {
      customStoreId: (customStoreID || responseCustomStoreID)!,
      name: formFields.name,
      subtitle: formFields.subtitle,
      desktopImage: formFields.desktopImage,
      mobileImage: formFields.mobileImage,
      activateStore: !isAutoSave,
      internationalShippingTier: formFields.internationalShippingTier,
      brandValuesDisplay: formFields.brandValuesDisplay,
      multipleSelectMode: formFields.multipleSelectMode,
      multipleSelectCount: formFields.multipleSelectCount,
      multipleSelectPrice: formFields.multipleSelectPrice,
      settings: formFields.settings,
    }

    const res = await updateCustomStore({
      variables: customStoreUpdateFields,
    })

    if (res.data?.customStoreUpdate.ok) {
      const customStoreResponse = res.data?.customStoreUpdate.customStore
      !!customStoreResponse && setCustomStore(customStoreResponse)
      return true
    } else {
      if (!isAutoSave) {
        window.alert(res.data?.customStoreUpdate.errors)
      }
      return false
    }
  }

  const handleArchiveStore = async () => {
    if (!customStoreID) {
      return
    }

    const res = await archiveCustomStore({
      variables: { id: customStoreID },
    })

    if (res.data?.customStoreArchive.ok) {
      toast.success("Custom store archived.")
      history.push(generateRealmPath("plus", "/browse/custom"), {
        allowPush: true,
      })
    } else {
      window.alert(res.data?.customStoreArchive.errors)
      console.log("archive not ok", res.data?.customStoreArchive.errors)
    }
  }

  const handleCustomStoreBrowserOpen = async (open: boolean) => {
    // User hasn't changed the details but opens browser
    setCustomStoreBrowserOpen(open)

    if (!customStoreID && !createPromise) {
      setCreatePromise(handleCreateCustomStore())
    }
  }

  const options = customStore?.optionList ?? formFields.optionList

  const availableStoreOptions = options.filter((option) => {
    if (!option.isAvailable) return false

    // Relevant products to check for shipping locations
    const products: { shippingCountriesFinal: string[] }[] = option.giftOption
      ?.products || [option.product]

    // One of the products needs to ship to the country
    if (
      !products.some((product) =>
        product.shippingCountriesFinal.includes(selectedShippingCountry),
      )
    ) {
      return false
    }

    // If we are in the US, the shipping tier doesn't matter
    if (selectedShippingCountry === "US") return true

    if (
      formFields.internationalShippingTier ===
      InternationalShippingTierEnum.disabled
    ) {
      return false
    }

    if (
      formFields.internationalShippingTier ===
      InternationalShippingTierEnum.gift_cards
    ) {
      return !!option.product.giftCardInfo
    }

    if (
      !formFields.settings.giftCardsEnabled &&
      !!option.product.giftCardInfo
    ) {
      return false
    }

    if (
      formFields.internationalShippingTier ===
      InternationalShippingTierEnum.standard
    ) {
      return true
    }

    if (
      formFields.internationalShippingTier ===
        InternationalShippingTierEnum.global_relay ||
      formFields.internationalShippingTier ===
        InternationalShippingTierEnum.full
    ) {
      return true
    }

    throw new Error(
      `internationalShippingTier: ${formFields.internationalShippingTier} not supported.`,
    )
  })

  let containsAlcoholProduct = false
  if (
    options.some(
      (option) => option?.giftOption?.isAlcohol || option.product.isAlcohol,
    )
  ) {
    containsAlcoholProduct = true
  }

  // this is used for displaying errors with multiple gift selection
  useEffect(() => {
    if (customStore?.optionList) {
      setUnfilteredOptions(customStore?.optionList)
    }
  }, [customStore?.optionList])

  // this is used for displaying errors with multiple gift selection
  useEffect(() => {
    setInternationalShippingGiftCardsEnabled(
      formFields.internationalShippingTier ===
        InternationalShippingTierEnum.gift_cards ||
        !!formFields.settings.giftCardsEnabled,
    )
    setInternationalShippingGlobalRelayEnabled(
      formFields.internationalShippingTier ===
        InternationalShippingTierEnum.global_relay ||
        formFields.internationalShippingTier ===
          InternationalShippingTierEnum.full,
    )
  }, [
    formFields.internationalShippingTier,
    formFields.settings.giftCardsEnabled,
  ])

  return (
    <div tw="border-t border-primary-100">
      <Helmet>
        <title>Custom Store – Goody+</title>
      </Helmet>
      <Container>
        <CustomStoreStyled>
          <CustomStoreForm
            customStore={customStore}
            formFields={formFields}
            setFormField={setFormField}
            setCustomStoreBrowserOpen={handleCustomStoreBrowserOpen}
            onSave={userSaveStore}
            onArchive={handleArchiveStore}
            refetchCustomStore={refetch}
            logoUrl={logoData?.me?.logoUrl}
            containsAlcoholProduct={containsAlcoholProduct}
          ></CustomStoreForm>
          <CustomStoreView
            storeName={formFields.name}
            storeSubtitle={formFields.subtitle}
            desktopImage={formFields.desktopImage}
            mobileImage={formFields.mobileImage}
            customStoreOptions={availableStoreOptions}
            logoUrl={logoData?.me?.logoUrl}
            selectedShippingCountry={selectedShippingCountry}
            setSelectedShippingCountry={setSelectedShippingCountry}
            brandValuesDisplay={formFields.brandValuesDisplay}
            multipleSelectMode={formFields.multipleSelectMode}
            multipleSelectCount={formFields.multipleSelectCount}
            multipleSelectPrice={formFields.multipleSelectPrice}
          ></CustomStoreView>
        </CustomStoreStyled>
      </Container>
      {customStoreBrowserOpen && !!customStore && (
        <CustomStoreBrowser
          customStore={customStore}
          open={customStoreBrowserOpen}
          setOpen={setCustomStoreBrowserOpen}
          storeName={formFields.name}
          refetchCustomStore={refetch}
        />
      )}
    </div>
  )
}

export const CUSTOM_STORE_PRODUCT_DATA_FRAGMENT = gql`
  fragment CustomStore_Product_DataFragment on CustomStoreProduct {
    id
    name
    shippingCountriesFinal
    subtitle
    isAlcohol
    price(customStoreId: $customStoreId)
    isAvailable(customStoreId: $customStoreId)
    isDuplicated(customStoreId: $customStoreId)
    shippingPrice
    priceWithShipping
    brand {
      id
      name
      brandValues
    }
    productImages {
      imageThumb {
        ...Image
      }
      imageLarge {
        ...Image
      }
    }
    giftCardInfo {
      currency
    }
    imagesScalable
  }
  ${IMAGE_FRAGMENT}
`

const CUSTOM_STORE_FULL_FRAGMENT = gql`
  fragment CustomStoreFull on CustomStore {
    ...CustomStoreBase
    priceEstimate {
      maximumSubtotalWithShipping
      processingFee
      estimatedTaxLow
      estimatedTaxHigh
      estimatedTotalLow
      estimatedTotalHigh
    }
    optionList {
      ...CustomStoreOptionListItem
    }
  }

  fragment CustomStoreOptionListItem on CustomStoreOption {
    id
    position
    isAvailable
    giftCardAmount
    priceMaxWithShipping
    giftOption {
      ...Store_GiftOptionInterfaceData
      priceMaxWithShipping(customStoreId: $customStoreId)
      products(
        customStoreId: $customStoreId
        shippingCountry: $shippingCountry
      ) {
        ...CustomStore_Product_DataFragment
      }
    }
    product {
      ...CustomStore_Product_DataFragment
    }
  }

  ${GIFT_OPTION_INTERFACE_DATA_FRAGMENT}
  ${CUSTOM_STORE_PRODUCT_DATA_FRAGMENT}
  ${CUSTOM_STORE_BASE_FRAGMENT}
`

// noinspection GraphQLSchemaValidation - $shippingCountry is used in fragment
export const GET_CUSTOM_STORE = gql`
  query Store_CustomStore($customStoreId: ID!, $shippingCountry: String) {
    me {
      customStore(id: $customStoreId) {
        ...CustomStoreFull
      }
    }
  }
  ${CUSTOM_STORE_FULL_FRAGMENT}
`

const CREATE_CUSTOM_STORE_MUTATION = gql`
  mutation Store_CustomStoreCreate(
    $name: String!
    $subtitle: String!
    $desktopImage: CustomStoreImage!
    $mobileImage: CustomStoreImage!
    $internationalShippingTier: InternationalShippingTierEnum!
    $brandValuesDisplay: BrandValuesDisplay!
    $multipleSelectMode: MultipleSelectModeEnum
    $multipleSelectCount: Int
    $multipleSelectPrice: Int
    $settings: GiftsSettingsInput
  ) {
    customStoreCreate(
      name: $name
      subtitle: $subtitle
      desktopImage: $desktopImage
      mobileImage: $mobileImage
      internationalShippingTier: $internationalShippingTier
      brandValuesDisplay: $brandValuesDisplay
      multipleSelectMode: $multipleSelectMode
      multipleSelectCount: $multipleSelectCount
      multipleSelectPrice: $multipleSelectPrice
      settings: $settings
    ) {
      ok
      errors
      customStore {
        ...CustomStoreBase
      }
    }
  }
  ${CUSTOM_STORE_BASE_FRAGMENT}
`

// noinspection GraphQLSchemaValidation - $shippingCountry is used in fragment
export const UPDATE_CUSTOM_STORE_MUTATION = gql`
  mutation Store_CustomStoreUpdate(
    $customStoreId: ID!
    $name: String!
    $subtitle: String!
    $desktopImage: CustomStoreImage!
    $mobileImage: CustomStoreImage!
    $activateStore: Boolean!
    $internationalShippingTier: InternationalShippingTierEnum!
    $brandValuesDisplay: BrandValuesDisplay!
    $shippingCountry: String
    $multipleSelectMode: MultipleSelectModeEnum
    $multipleSelectCount: Int
    $multipleSelectPrice: Int
    $settings: GiftsSettingsInput
  ) {
    customStoreUpdate(
      customStoreId: $customStoreId
      name: $name
      subtitle: $subtitle
      desktopImage: $desktopImage
      mobileImage: $mobileImage
      activateStore: $activateStore
      internationalShippingTier: $internationalShippingTier
      brandValuesDisplay: $brandValuesDisplay
      multipleSelectMode: $multipleSelectMode
      multipleSelectCount: $multipleSelectCount
      multipleSelectPrice: $multipleSelectPrice
      settings: $settings
    ) {
      ok
      errors
      customStore {
        ...CustomStoreFull
      }
    }
  }
  ${CUSTOM_STORE_FULL_FRAGMENT}
`

export const LOGO_QUERY = gql`
  query CustomStore_Logo {
    me {
      logoUrl
    }
  }
`

export default CustomStorePage
