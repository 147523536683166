import { useEffect, useState } from "react"

import { createHookContext } from "./createHookContext"
import { PaymentMethod, PaymentMethodsManager } from "../PaymentMethodsManager"

const usePaymentMethodsLocal = () => {
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([])
  const [isLoading, setIsLoading] = useState(true)

  const getPaymentMethods = async () => {
    const paymentMethodsFromServer =
      await PaymentMethodsManager.getPaymentMethods()
    setPaymentMethods(paymentMethodsFromServer)
    setIsLoading(false)
  }

  const refreshPaymentMethods = async () => {
    await PaymentMethodsManager.fetchPaymentMethods()
    setPaymentMethods(PaymentMethodsManager.getPaymentMethodsWithoutFetch())
  }

  useEffect(() => {
    getPaymentMethods()
  }, [])

  return {
    paymentMethods,
    refreshPaymentMethods,
    paymentMethodsLoading: isLoading,
  }
}

export const { Provider: PaymentMethodsProvider, useHook: usePaymentMethods } =
  createHookContext("PaymentMethods", usePaymentMethodsLocal)
