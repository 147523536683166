import React from "react"
import tw, { styled } from "twin.macro"

import { ReactComponent as BirthdayCakeEmoji } from "../../assets/images/calendar/birthday-cake-emoji.svg"
import { ReactComponent as CalendarEmoji } from "../../assets/images/calendar/calendar-emoji.svg"

import { EventKind } from "@/types/graphql-types"

export const PageBanner = styled.div`
  ${tw`bg-white rounded-lg rounded-t-none grid p-6`}
  box-shadow: 0px 1px 4px rgba(79, 31, 137, 0.06), 0px 4px 8px rgba(79, 31, 137, 0.06);
  grid-row-gap: 1.25rem;

  @media (min-width: 768px) {
    grid-template-columns: max-content auto max-content;
    grid-column-gap: 1.25rem;
    grid-row-gap: 0;
    align-items: center;
  }

  svg {
    &.banner {
      ${tw`w-10 h-10 self-center`}
    }
  }
`

export const RestrictionText = styled.div`
  color: #6b7280;
`

export const DiscardGiftButton = styled.button`
  ${tw`self-center flex justify-center items-center h-10 px-4 py-2.5 rounded-lg hover:bg-gray-150 active:bg-gray-200 transition-colors`}
  background-color: #F3F4F6;
  color: #6b7280;

  @media (min-width: 768px) {
    grid-column: 3;
  }
`

export const EventAttachmentIcon: React.FC<{
  eventKind?: string | null
  className?: string
}> = ({ eventKind, className }) => {
  switch (eventKind) {
    case EventKind.WORK_ANNIVERSARY:
      return <CalendarEmoji tw="z-10 w-5 h-5" className={className} />
    case EventKind.BIRTHDAY:
      return <BirthdayCakeEmoji tw="z-10 w-5 h-5" className={className} />
    default:
      return <div />
  }
}
