import { forwardRef } from "react"

const isWebPSupported = (() => {
  const canvas = document.createElement("canvas")
  if (!!(canvas.getContext && canvas.getContext("2d"))) {
    return canvas.toDataURL("image/webp").indexOf("data:image/webp") === 0
  }
  return false
})()

export const imageAssetUrl = (
  src: string,
  { width }: { width?: number } = {},
) => {
  try {
    const uri = new URL(
      src,
      /^https?:\/\//.test(src) ? undefined : window.location.origin,
    )

    const isImageFormatSupported = /\.(png|jpg|jpeg|gif|webp)$/.test(
      uri.pathname,
    )

    if (isWebPSupported && isImageFormatSupported) {
      uri.searchParams.set("format", "webp")
    }

    if (typeof width != "undefined" && isImageFormatSupported) {
      uri.searchParams.set("width", width.toString())
    }

    return uri.toString()
  } catch (e) {
    return src
  }
}

type ImageProps = {
  src: string
  width?: number
  lazy?: boolean
  alt?: string
} & React.ComponentPropsWithoutRef<"img">

const Image = forwardRef<HTMLImageElement, ImageProps>(
  ({ src, width, lazy = true, alt, ...props }, ref) => (
    <img
      src={imageAssetUrl(src, { width })}
      loading={lazy ? "lazy" : undefined}
      alt={alt}
      ref={ref}
      {...props}
    />
  ),
)

export default Image
