import { gql } from "@apollo/client"

export const SWAG_CREATE_INTERNAL_PRODUCT_MUTATION = gql`
  mutation SwagIntegrationsCreateInternalProduct(
    $providerType: ID!
    $externalProductId: ID!
    $externalTemplateId: ID!
  ) {
    swagIntegrations {
      createProduct(
        providerType: $providerType
        externalTemplateId: $externalTemplateId
        externalProductId: $externalProductId
      ) {
        ok
        swagProductId
      }
    }
  }
`
