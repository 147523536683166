import { MouseEvent } from "react"
import tw from "twin.macro"

import { APIVariant } from "./API"
import { generateRealmPath } from "../../common/realm"

interface Props {
  variant: APIVariant
  onSelectVariant: (variant: APIVariant) => void
}

export default function ChooseAPI({ variant, onSelectVariant }: Props) {
  return (
    <div tw="pt-24">
      <div tw="text-4xl font-reckless-neue font-250 text-center">
        Choose your API:
      </div>
      <div tw="flex flex-col md:flex-row items-stretch justify-center px-4 gap-4 lg:gap-8 pt-8">
        <SelectionButton
          selected={variant === "commerce"}
          href={generateRealmPath("business", "/gift-api")}
          onClick={(e) => {
            e.preventDefault()

            // Trigger a URL change without triggering route change, so we don't
            // move to the top of the page
            window.history.replaceState(
              null,
              "Commerce API",
              generateRealmPath("business", "/gift-api"),
            )

            onSelectVariant("commerce")
          }}
          name="Commerce API"
          subtitle="Sell Goody's entire product catalog in your website or app. Earn revenue from sales."
          pill="For platforms"
        />
        <SelectionButton
          selected={variant === "automation"}
          href={generateRealmPath("business", "/gift-api/automation")}
          onClick={(e) => {
            e.preventDefault()

            window.history.replaceState(
              null,
              "Automation API",
              generateRealmPath("business", "/gift-api/automation"),
            )

            onSelectVariant("automation")
          }}
          name="Automation API"
          subtitle="Automatically send gifts based on triggers you define, using REST API or Zapier."
          pill="For gifters"
        />
      </div>
    </div>
  )
}

interface SelectionButtonProps {
  selected: boolean
  href: string
  onClick: (e: MouseEvent<HTMLAnchorElement>) => void
  name: string
  subtitle: string
  pill: string
}

function SelectionButton({
  selected,
  href,
  onClick,
  name,
  subtitle,
  pill,
}: SelectionButtonProps) {
  return (
    <a
      href={href}
      tw="p-6 lg:p-10 py-5 lg:py-10 border flex-1 w-full md:max-w-[32rem] rounded-2xl lg:rounded-3xl transition-all block"
      css={[
        selected
          ? tw`border-transparent bg-primary-new-600 text-white`
          : tw`border-gray-200 text-gray-500 hover:(border-primary-new-200 bg-primary-new-000 text-gray-700) active:bg-primary-new-050`,
      ]}
      onClick={onClick}
    >
      <div tw="flex flex-row items-center justify-between gap-1">
        <div
          tw="font-reckless-neue text-xl md:text-2xl lg:text-[2rem] flex-1"
          style={{ fontWeight: 280 }}
        >
          {name}
        </div>
        <div
          tw="border px-2 lg:px-4 py-0.5 text-sm lg:text-base uppercase rounded-full"
          css={selected ? tw`border-primary-new-300` : tw`border-gray-300`}
        >
          {pill}
        </div>
      </div>
      <div tw="pt-2 lg:pt-3 lg:text-xl leading-snug">{subtitle}</div>
    </a>
  )
}
