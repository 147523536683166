import React from "react"
import tw, { styled } from "twin.macro"

const CTABannerHeading: React.FC = ({ children }) => {
  return <Header>{children}</Header>
}

const Header = styled.div`
  ${tw`font-light mx-3.5  text-2xl lg:text-4xl leading-p-125 lg:leading-p-125`}
  font-family: "Canela Deck";
`

export default CTABannerHeading
