import { gql, useMutation } from "@apollo/client"
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"

import { generateRealmPath } from "../common/realm"
import { successToast } from "../common/toast"
import { ROOT_DATA_QUERY } from "../graphql"
import Container from "../sites/App/Container"

import {
  VerifyEmailMutation,
  VerifyEmailMutationVariables,
} from "@/types/graphql-types"

export default function VerifyEmail() {
  const [verifyEmail] = useMutation<
    VerifyEmailMutation,
    VerifyEmailMutationVariables
  >(VERIFY_EMAIL_MUTATION, {
    refetchQueries: [
      {
        query: ROOT_DATA_QUERY,
      },
    ],
  })
  const history = useHistory()

  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    ;(async () => {
      if (window.location.hash.startsWith("#token=")) {
        const token = window.location.hash.split("#token=")[1]
        const res = await verifyEmail({ variables: { token } })

        if (res?.data?.emailVerificationVerify?.ok) {
          successToast("Thanks for verifying your email!")
          history.push(generateRealmPath("plus", "/browse"))
        } else {
          setError(
            res?.data?.emailVerificationVerify?.error || "Unknown error.",
          )
        }
      } else {
        setError("No token provided.")
      }
    })()
  }, [])

  return (
    <Container>
      <div tw="px-5 flex flex-row items-center justify-center py-24">
        {error ? <div>{error}</div> : <div>Verifying email&hellip;</div>}
      </div>
    </Container>
  )
}

const VERIFY_EMAIL_MUTATION = gql`
  mutation VerifyEmail($token: String!) {
    emailVerificationVerify(token: $token) {
      ok
      error
    }
  }
`
