import { ChangeEvent, useEffect, useState } from "react"
import tw, { styled } from "twin.macro"

import { SettingInfo } from "@/common/settings/SettingInfo"
import { useCustomStore } from "@/store/custom/hooks"
import { MultipleSelectActions } from "@/store/custom/MultipleGiftSelection/MultipleSelectActions"
import { MultipleSelectModeEnum } from "@/types/graphql-types"

export const MaxPriceForm = () => {
  const MAX_VALUE = 99999
  const {
    multipleSelectPrice,
    setMultipleSelectPrice,
    setMultipleSelectAttributeHasChanged,
  } = useCustomStore()

  const [inputPrice, setInputPrice] = useState<number | null>(
    multipleSelectPrice ? multipleSelectPrice / 100 : null,
  )

  // update mutation price when local price changes
  useEffect(() => {
    if (inputPrice) {
      const priceInCents = inputPrice * 100
      setMultipleSelectPrice(priceInCents)
    } else {
      setMultipleSelectPrice(0)
    }
  }, [inputPrice])

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const amountString = e.target.value

    if (amountString === "") {
      setInputPrice(null)
    }

    const amount = Number(amountString)
    if (/^-?\d*$/.test(amountString) && 0 < amount && amount <= MAX_VALUE) {
      setInputPrice(amount)
      setMultipleSelectAttributeHasChanged(true)
    }
  }

  return (
    <Container>
      <div tw="font-medium mb-[5px] text-gray-600 leading-[140%]">
        Maximum total price
      </div>
      <div tw="mb-3 text-center text-sm text-gray-500 font-medium leading-[140%]">
        Recipients can select multiple gifts adding up to this amount. Prices
        are shown.
      </div>
      <div tw="flex flex-row items-center mb-6">
        <div tw="flex items-center justify-center">
          <span tw="text-gray-450 absolute -ml-14">$</span>
          <div>
            <StyledInput
              type="text"
              value={inputPrice || ""}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      <MultipleSelectActions
        multipleSelectModeToDisplay={MultipleSelectModeEnum.item_count}
      />
      <SettingInfo
        useMultipleGiftMessage={true}
        shouldDisplayAddDonationMessage={true}
      />
    </Container>
  )
}

const Container = tw.div`flex flex-col w-full items-center`
const StyledInput = styled.input`
  ${tw`flex text-center justify-center h-[42px] w-[84px] border border-gray-150 rounded-lg px-4 py-[13px] text-gray-800`};
  /* For Chrome, Safari, Edge, Opera */
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* For Firefox */
  -moz-appearance: textfield;
`
