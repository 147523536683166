import classNames from "classnames"
import { Link } from "react-router-dom"
import tw, { styled } from "twin.macro"

import Container from "./Container"
import Header from "./Header"
import Footer from "../../common/Footer"

import Callout from "@/common/Callout"
import { useGlobalState } from "@/common/GlobalState"
import { generateRealmPath } from "@/common/realm"

interface Props {
  className?: string
}

const AppSite: React.FC<Props> = ({ children, className = "" }) => {
  const [user] = useGlobalState("user")
  const [realm] = useGlobalState("currentRealm")

  return (
    <>
      <Header />
      <Main className={classNames(className, "withWorkspaces")}>
        {realm === "plus" && user?.isDelinquent && (
          <div tw="m-8 flex flex-col items-center">
            <Link
              to={generateRealmPath("plus", "/account/receipts")}
              tw="w-full max-w-[1376px]"
            >
              <Callout
                title="Your account is suspended due to non-payment"
                subtext="You won’t be able to send new gifts. Click here to resolve."
                variant="error"
              />
            </Link>
          </div>
        )}
        {children}
      </Main>
      <Container>
        <Footer />
      </Container>
    </>
  )
}

const Main = styled.div`
  // Users without workspaces don't need as much top margin.
  margin-top: 3rem;
  ${tw`lg:mt-0`};

  &.withWorkspaces {
    // Users with workspaces need additional top margin for the top bar.
    margin-top: 6rem;
    ${tw`lg:mt-0`};
  }
`

export default AppSite
