import BrandValueBadge, { isBrandValueKey } from "../components/BrandValueBadge"
import { ProductImage, ProductImageType } from "../ProductImage"

import { CustomStoreOptionListItemFragment } from "@/types/graphql-types"

export const CustomStoreOptionPreview = ({
  customStoreOption,
  showBrandValues,
}: {
  customStoreOption: CustomStoreOptionListItemFragment
  showBrandValues: boolean
}) => {
  if (!!customStoreOption.giftOption) {
    return (
      <CustomStoreOptionPreviewDumb
        title={customStoreOption.giftOption.name}
        description={customStoreOption.giftOption.subtitle}
        image={customStoreOption.giftOption.primaryImage?.imageLarge}
        showBrandValues={showBrandValues}
        brandValues={customStoreOption.giftOption.brand.brandValues}
      />
    )
  } else if (!!customStoreOption.product) {
    return (
      <CustomStoreOptionPreviewDumb
        image={customStoreOption.product.productImages[0]?.imageLarge}
        title={customStoreOption.product.name}
        subtitle={customStoreOption.product.brand.name}
        description={customStoreOption.product.subtitle}
        showBrandValues={showBrandValues}
        brandValues={customStoreOption.product.brand.brandValues}
        imagesScalable={customStoreOption.product.imagesScalable}
      />
    )
  } else {
    return null
  }
}

// Dumb component
const CustomStoreOptionPreviewDumb = ({
  image,
  title,
  subtitle,
  description,
  showBrandValues,
  brandValues,
  imagesScalable,
}: {
  image?: ProductImageType
  title: string
  subtitle?: string
  description?: string | null
  showBrandValues: boolean
  brandValues: string[]
  imagesScalable?: boolean
}) => (
  <div>
    {image && (
      <div tw="overflow-hidden rounded-lg mb-6 max-h-[15.5rem] flex items-center justify-center">
        <ProductImage
          tw="h-[15.5rem] rounded-lg w-full"
          image={image}
          scalable={imagesScalable}
          alt={title}
        />
      </div>
    )}
    <div tw="pb-3">
      <div tw="font-medium text-lg text-primary-600">{title}</div>
      {subtitle && (
        <div tw="font-medium text-lg text-primary-400">{subtitle}</div>
      )}
    </div>
    <div tw="text-gray-600 font-text" css={{ fontSize: "15px" }}>
      {description}
    </div>
    {showBrandValues && brandValues.length > 0 && (
      <div tw="flex flex-wrap -mx-1 pt-3">
        {brandValues.map(
          (brandValue) =>
            isBrandValueKey(brandValue) && (
              <BrandValueBadge active={false} value={brandValue} />
            ),
        )}
      </div>
    )}
  </div>
)
