import React from "react"
import tw, { styled } from "twin.macro"

import TitleContainer from "../components/TitleContainer"
import card1ImgSrc from "../images/card_1_products.png"
import card2ImgSrc from "../images/card_2_cards.png"
import card3ImgSrc from "../images/card_3_sms.png"

const WhyClientGifting = () => {
  const cards = [
    {
      title: "Make onboarding memorable",
      text: "Roll out the red carpet with custom swag and welcome kits.",
      imgSrc: card1ImgSrc,
    },
    {
      title: "Smooth things over",
      text: "Mistakes happen. Use gifts to turn bad CXs into good vibes. ",
      imgSrc: card2ImgSrc,
    },
    {
      title: "Lock in renewals",
      text: "Schedule personalized, timely gifts that show clients you appreciate them.",
      imgSrc: card3ImgSrc,
    },
  ]

  return (
    <div tw="mx-auto max-w-screen-xl">
      <div tw="mx-auto px-5 lg:w-max">
        <TitleContainer
          heading="Why client gifting matters"
          titleSize="lg"
          title={
            <div tw=" lg:px-8">
              Acquiring a new customer costs
              <span tw="text-[#7F67FF]"> 5 to 25 times </span>
              more than retaining one.
            </div>
          }
          subtitle={
            <div tw="max-w-[560px] mx-auto lg:text-[22px] lg:leading-[32px]">
              Investments in client appreciation gifts deliver outsized returns
              in retention and loyalty.
            </div>
          }
        />
      </div>

      <CardsGridContainer tw="mt-10 lg:mt-14 mx-5 md:mx-14 lg:flex flex-row place-content-between">
        {cards.map((args) => (
          <CardWithImage key={args.title} {...args} />
        ))}
      </CardsGridContainer>
    </div>
  )
}

interface ICardWithImage {
  title: string
  text: string
  imgSrc: string
}

const CardWithImage = ({ title, text, imgSrc }: ICardWithImage) => {
  return (
    <CardWithImageContainer>
      <img src={imgSrc} alt="card" />

      <div>
        <CardTitle>{title}</CardTitle>
        <p tw="text-xl px-1 text-center">{text}</p>
      </div>
    </CardWithImageContainer>
  )
}

const CardWithImageContainer = styled.div`
  ${tw`flex flex-col mx-auto mb-10`}

  @media(min-width: 1080px) {
    max-width: 30%;
  }

  @media (max-width: 1080px) {
    max-width: 360px;
  }
`

export const CardTitle = styled.h1`
  ${tw`text-2xl mb-2 font-semibold mt-4 text-center`}
  color: #9159D6;
`

const CardsGridContainer = tw.div`grid grid-cols-1 lg:grid-cols-2`

export default WhyClientGifting
