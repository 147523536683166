import React from "react"
import { useIntercom } from "react-use-intercom"
import tw, { styled } from "twin.macro"

import {
  Autogift,
  Budgeting,
  HRIntegration,
  MessageCircle,
  NoLimits,
} from "../assets"
import { SubscriptionFeature } from "../components"

import { OrganizationSubscriptionSizeSegment } from "@/types/graphql-types"

interface SegmentProps {
  segment?: OrganizationSubscriptionSizeSegment | null
}

interface Props extends SegmentProps {
  plan: "team" | "pro"
}

const seatsAndPricing = {
  small_business: {
    seats: "2",
    pricePerSeatMonthly: "34",
    pricePerSeatYearly: "28",
  },
  growth: {
    seats: "5",
    pricePerSeatMonthly: "24",
    pricePerSeatYearly: "20",
  },
  enterprise: {
    seats: "10",
    pricePerSeatMonthly: "22",
    pricePerSeatYearly: "18",
  },
}

const PlanDescription = ({ segment }: SegmentProps) => {
  if (segment == null) {
    return (
      <div tw="pt-4">
        <div tw="text-gray-700 font-text">
          Your organization isn't set up yet.
        </div>
        <div tw="text-gray-450 font-text">
          If you have any questions we're happy to help via the chat at the
          bottom of the page.
        </div>
      </div>
    )
  }

  return (
    <div tw="pt-4">
      <div tw="text-gray-700 font-text">
        Includes {seatsAndPricing[segment].seats} full seats, plus free limited
        member seats.
      </div>
      <div tw="text-gray-450 font-text">
        Additional seats are ${seatsAndPricing[segment].pricePerSeatYearly}
        /month on annual plans, or $
        {seatsAndPricing[segment].pricePerSeatMonthly}/month on monthly plans.
      </div>
    </div>
  )
}

const SubscriptionCopy = ({ plan, segment }: Props) => {
  const { show } = useIntercom()

  const isTeam = plan === "team"
  const contentForHeader = isTeam
    ? "Goody for Teams"
    : `Goody for \n Employee Engagement`

  return (
    <div css={[tw`w-full`, segment !== null && tw`lg:w-1/2 lg:pr-16`]}>
      <div tw="uppercase font-semibold text-purple-300">Upgrade</div>
      <HeaderContent tw="text-4xl font-semibold text-purple-500 leading-tight pt-2">
        {contentForHeader}
      </HeaderContent>
      {isTeam && <PlanDescription segment={segment} />}
      <div tw="pt-10">
        <SubscriptionFeature
          image={<Autogift />}
          title="Autogift"
          subtitle="Automate birthday and work anniversary gifts"
        />
        <SubscriptionFeature
          image={<HRIntegration />}
          title="Integrations"
          subtitle="Connect with Salesforce, Calendly, and 30+ HR platforms"
        />
        {isTeam && (
          <SubscriptionFeature
            image={<Budgeting />}
            title="Budgeting"
            subtitle="Allocate one-time or recurring budgets"
          />
        )}
        <SubscriptionFeature
          image={<NoLimits />}
          title={`Unlimited gifts ${isTeam ? "" : "and user seats"}`}
          subtitle="No limits on your gifts"
        />
      </div>
      <div tw="pt-9">
        <div
          css={{
            boxShadow:
              "0 2px 4px rgba(0, 0, 0, 0.04), 0 1px 2px rgba(0, 0, 0, 0.03)",
          }}
          tw="bg-white rounded-full flex items-center justify-between max-w-screen-sm"
        >
          <div tw="pl-5 text-gray-450">Any questions? We’re here.</div>
          <div tw="p-1">
            <ChatButton onClick={() => show()}>
              <MessageCircle />
              Send a message
            </ChatButton>
          </div>
        </div>
      </div>
      <div tw="pb-12 lg:pb-0 pt-6 text-sm text-gray-450 font-text text-left">
        HR integration eligible for companies up to 10,000 total employees.
        <br />
        <button
          onClick={() => show()}
          tw="hover:text-primary-500 transition-colors text-left"
        >
          Larger company? Contact us and we’ll set you up.
        </button>
      </div>
    </div>
  )
}

const HeaderContent = tw.div`whitespace-pre-line`

const ChatButton = styled.button`
  ${tw`rounded-full px-5 py-2 flex flex-row items-center gap-3 relative text-primary-500`}

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      75deg,
      rgba(228, 161, 184, 0.4) 0%,
      rgba(179, 125, 213, 0.4) 110%
    );
    ${tw`bg-gradient-to-r from-gradient-updated-purple-medium to-gradient-updated-blue-medium`}
    opacity: 0.1;
    transition: opacity 0.2s ease-out;
    border-radius: 99px;
  }

  &:hover:after {
    opacity: 0.15;
  }

  &:active:after {
    opacity: 0.2;
  }
`

export default SubscriptionCopy
