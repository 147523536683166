import React from "react"
import { Helmet } from "react-helmet-async"
import tw, { styled } from "twin.macro"

import { ReactComponent as EmailIcon } from "../assets/icons/menu-contact.svg"
import { HubSpot } from "../common/Script"

const loadForm = () => {
  // @ts-ignore
  window.hbspt.forms.create({
    region: "na1",
    portalId: "9308919",
    formId: "10370710-2c2b-4899-a17c-a26ff7dc2db8",
    target: "#contact-form-container",
  })
}

const Contact: React.FC = () => (
  <>
    <HubSpot onLoad={loadForm} />
    <Helmet>
      <title>Contact – Goody for Business</title>
    </Helmet>
    <Top>
      <Title>Contact Us</Title>
    </Top>
    <Container>
      <Card>
        <ContactInfo>
          <EmailIcon tw="text-primary-300" style={{ strokeWidth: 1.5 }} />
          <ContactMethod>Email us</ContactMethod>
          <ContactLink href="mailto:plus-support@ongoody.com">
            support@ongoody.com
          </ContactLink>
        </ContactInfo>
        <div
          id="contact-form-container"
          className="ph-no-capture fs-exclude data-hj-suppress"
          tw="pt-4 md:p-16 md:pt-16"
        />
      </Card>
    </Container>
  </>
)

const Card = tw.div`bg-white flex flex-col items-stretch mb-24 pb-3.5 pt-12 md:pt-20 rounded-2xl shadow-xl px-6 md:px-3.5 mx-5 md:mx-0 md:w-3/4 lg:w-7/12 xl:w-1/2`

const ContactInfo = tw.div`flex leading-4 mb-6 md:px-16 w-full flex-wrap md:flex-nowrap`
const ContactMethod = tw.p`w-1/4 font-medium ml-3 text-primary-600`
const ContactLink = tw.a`w-full pt-2 md:w-3/4 md:pt-0`

const Container = styled.div(() => [
  tw`container flex flex-col items-center mx-auto overflow-hidden relative -mt-36 `,
])

const Title = styled.h2`
  ${tw`bg-clip-text font-medium text-5xl text-transparent mb-36`};
  background-image: linear-gradient(
    77.96deg,
    rgba(215, 112, 147, 1),
    rgba(179, 125, 213, 1)
  );
`

const Top = styled.div`
  ${tw`flex items-center justify-center w-full`};
  background: linear-gradient(
      90deg,
      rgba(228, 161, 184, 0.15) 30.58%,
      rgba(179, 125, 213, 0.15) 100%
    ),
    #ffffff;

  height: 400px;

  @media (max-width: 1024px) {
    height: 40vh;
  }
`

export default Contact
