import React from "react"
import tw, { styled } from "twin.macro"

import ActionsDropdown from "./table/ActionsDropdown"
import TdName from "./table/TdName"
import RoundedTable from "../RoundedTable"

interface ITable {
  gridTemplateColumns?: string
  withMinHeight?: boolean
  children: React.ReactElement
  headers: string[]
}

const Table = ({
  children,
  gridTemplateColumns,
  headers,
  withMinHeight,
}: ITable) => {
  return (
    <TableContainer
      gridTemplateColumns={gridTemplateColumns}
      withMinHeight={withMinHeight}
    >
      <thead>
        <tr>
          {headers.map((header) => (
            <th key={header}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </TableContainer>
  )
}

const TableContainer = styled(RoundedTable)<{
  gridTemplateColumns?: string
  withMinHeight?: boolean
}>`
  ${({ withMinHeight }) => withMinHeight && tw`min-h-[511px]`}

  tr {
    grid-template-columns: ${({
      gridTemplateColumns = "3fr 3fr 2fr 2fr 2fr",
    }) => gridTemplateColumns};

    @media (max-width: 1023px) {
      grid-template-columns: 1fr;
    }

    td,
    th {
      ${tw`flex items-center justify-start px-4 py-2 text-sm first:flex-col first:items-start lg:justify-center lg:py-6 lg:px-4 xl:px-5`};
      &:nth-child(2) {
        ${tw`justify-start`};
      }
    }

    th {
      ${tw`hidden font-medium lg:flex lg:py-1`}
    }
  }

  thead {
    ${tw`hidden lg:block`}
  }

  tbody tr {
    ${tw`py-3 lg:py-0`}
  }
`

export { Table, TdName, ActionsDropdown }
