import { gql, useQuery } from "@apollo/client"
import tw, { styled } from "twin.macro"

import { ReactComponent as UpgradeImage } from "./images/upgrade.svg"

import { LimitedMemberFeatureGateGetOrganizationAdminsQuery } from "@/types/graphql-types"

const LimitedMemberFeatureGateBanner = () => {
  const { data } = useQuery<LimitedMemberFeatureGateGetOrganizationAdminsQuery>(
    GET_ORGANIZATION_ADMINS,
  )
  const adminNames = data?.organization?.adminNames || []

  return (
    <Container>
      <div tw="flex-5">
        <div tw="p-6 sm:p-10 border-b">
          <h2 tw="text-lg sm:text-2xl mb-4">
            Upgrade to a Full Member to access this feature
          </h2>
          <p tw="text-sm sm:text-base text-gray-700">
            You're currently a <span tw="font-medium">Limited Member</span>. You
            can ask an organization admin to upgrade you to a Full Member to
            access this feature.
          </p>
        </div>
        <div tw="text-sm sm:text-base p-6 sm:p-10">
          <h4 tw="font-medium pb-2 text-gray-500">YOUR ORGANIZATION ADMINS</h4>
          {adminNames.map((name, index) => (
            <p key={index}>{name}</p>
          ))}
        </div>
      </div>
      <RightPanel>
        <UpgradeImage tw="w-28 sm:w-40" />
      </RightPanel>
    </Container>
  )
}

const Container = styled.div`
  ${tw`flex rounded-2xl overflow-hidden`}
  box-shadow: 0px 8px 48px rgba(0, 0, 0, 0.08);
`

const RightPanel = styled.div`
  ${tw`p-4 sm:p-10 flex-2 flex items-center justify-center`}
  background: linear-gradient(180deg, #F2F0FF 0%, rgba(242, 240, 255, 0) 156.07%);
`

export const GET_ORGANIZATION_ADMINS = gql`
  query LimitedMemberFeatureGateGetOrganizationAdmins {
    organization {
      id
      adminNames
    }
  }
`

export default LimitedMemberFeatureGateBanner
