import React, { useCallback, useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import tw, { styled } from "twin.macro"

import { screen, track } from "@/common/analytics"
import Script from "@/common/Script"

declare var Calendly: any

interface Props {
  variant: "bookACall" | "chatWithSales" | "teamDemo" | "employeeEngagementDemo"
}

const BookACall: React.FC<Props> = ({ variant }) => {
  const [calendlyLoaded, setCalendlyLoaded] = useState(false)

  const pageTitle = {
    bookACall: "Book a Call",
    chatWithSales: "Chat with Sales",
    employeeEngagementDemo: "Employee Engagement Demo",
    teamDemo: "Goody for Teams Demo",
  }[variant]

  const metaTitle = {
    bookACall: "Book a Call",
    chatWithSales: "Chat with Sales",
    employeeEngagementDemo: "Employee Engagement Demo",
    teamDemo: "Book a Demo - Goody for Teams",
  }[variant]

  const calendlyUrl = {
    bookACall: "https://calendly.com/d/yxr-bdv-hqx?hide_gdpr_banner=1",
    chatWithSales: "https://calendly.com/d/3nn-8vs-jp2?hide_gdpr_banner=1",
    employeeEngagementDemo:
      "https://calendly.com/d/yng-kxn-2pt?hide_gdpr_banner=1",
    teamDemo: "https://calendly.com/d/zzs-79k-n62?hide_gdpr_banner=1",
  }[variant]

  useEffect(() => {
    if (variant === "teamDemo") {
      screen("Business Marketing - Team Demo")
    } else if (variant === "bookACall") {
      screen("Business Marketing - Book a Call")
    }
  }, [])

  const emitEvent = (str: string) => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: str,
    })
  }

  // Calendly uses postMessage to pass messages back to us.
  const handleCalendlyEvent = useCallback((event: MessageEvent<any>) => {
    // Check if it's a Calendly event first.
    if (event.data.event && event.data.event.indexOf("calendly") === 0) {
      if (event.data.event === "calendly.date_and_time_selected") {
        emitEvent("Plus_MeetingScheduler_SelectCallTime")
      } else if (event.data.event === "calendly.event_scheduled") {
        track("Business Marketing - Schedule Call")
        emitEvent("Plus_MeetingScheduler_ScheduleCall")
      }
    }
  }, [])

  // Render widget and attach event listener.
  useEffect(() => {
    if (calendlyLoaded) {
      Calendly.initInlineWidget({
        url: calendlyUrl,
        parentElement: document.getElementById("calendly-embed-container"),
        prefill: {},
        utm: {
          utmSource: "referral",
          utmMedium: "ongoody",
          utmCampaign: "goody-plus-website",
        },
      })

      window.addEventListener("message", handleCalendlyEvent)

      return () => {
        window.removeEventListener("message", handleCalendlyEvent)
      }
    }
  }, [handleCalendlyEvent, calendlyLoaded])

  return (
    <div>
      <Script
        src="https://assets.calendly.com/assets/external/widget.js"
        onLoad={() => setCalendlyLoaded(true)}
      />
      <Helmet>
        <title>{metaTitle} | Goody for Business</title>
      </Helmet>
      <Top>
        <Title>{pageTitle}</Title>
      </Top>
      <Container>
        <Card>
          <CalendlyEmbed id="calendly-embed-container" />
        </Card>
      </Container>
    </div>
  )
}

const Top = styled.div`
  ${tw`flex items-center justify-center w-full`};
  background: linear-gradient(
      90deg,
      rgba(228, 161, 184, 0.15) 30.58%,
      rgba(179, 125, 213, 0.15) 100%
    ),
    #ffffff;

  height: 400px;

  @media (max-width: 1024px) {
    height: 300px;
  }
`

const Title = styled.h2`
  ${tw`bg-clip-text font-medium text-3xl lg:text-5xl text-transparent mb-44 md:mb-36 leading-normal lg:leading-normal`};
  background-image: linear-gradient(
    77.96deg,
    rgba(215, 112, 147, 1),
    rgba(179, 125, 213, 1)
  );
`

const Container = styled.div(() => [
  tw`container flex flex-col items-center mx-auto overflow-hidden relative -mt-48 lg:-mt-56 `,
])

const Card = tw.div`flex flex-col items-stretch w-full`

const CalendlyEmbed = styled.div`
  height: 750px;

  @media (max-width: 1024px) {
    height: 1500px;
  }
`

export default BookACall
