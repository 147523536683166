import tw, { styled } from "twin.macro"

import { Countries, CountriesLongName, Flag } from "./InternationalShipping"

interface Props {
  country: Countries
  giftOptionSelectedShippingCountry: Countries
  onClick: (country: Countries) => void
}

const GiftOptionCountryButton = ({
  country,
  giftOptionSelectedShippingCountry,
  onClick,
  ...props
}: Props) => (
  <CountryButton
    active={giftOptionSelectedShippingCountry === country}
    onClick={() => onClick(country)}
    {...props}
  >
    <FlagContainer>
      <Flag country={country} />
    </FlagContainer>
    <h4 tw="pl-2">{CountriesLongName[country]}</h4>
  </CountryButton>
)

const CountryButton = styled.button<{ active: boolean }>`
  ${tw`flex items-center py-1 w-full transition-colors`}
  ${({ active }) => active && tw`text-primary-500 font-medium`}
  &:hover {
    ${tw`text-primary-600`}
  }
`

const FlagContainer = styled.div`
  ${tw`flex items-center justify-center`}
  width: 32px;
`

export default GiftOptionCountryButton
