import { useIntercom } from "react-use-intercom"

import CustomStoreExample from "./assets/sample-custom-store.png"
import { ReactComponent as ArrowRight } from "../../../assets/icons/arrow-right.svg"
import { ReactComponent as ChatBubble } from "../../../assets/icons/chat-bubble-black.svg"
import Button, { StyledButtonLink } from "../../../common/Button"
import { generateRealmPath } from "../../../common/realm"
import { UpsellContainer } from "../common"

export const CustomStoresUpsell = () => {
  const { showNewMessages, trackEvent } = useIntercom()

  return (
    <UpsellContainer tw="mb-0">
      <div tw="order-last lg:order-1 p-6 py-0 lg:pl-16 lg:pt-16">
        <div tw="pt-6 pb-2 lg:py-0 lg:pb-8">
          <div tw="font-medium lg:text-2xl">
            Create a custom store for your recipients
          </div>
          <div tw="lg:text-2xl">
            featuring your own curation of gifts and swag.
          </div>
        </div>
        <div tw="py-2 lg:py-0 lg:pb-12 text-gray-600 lg:text-[1.125rem]">
          <div>
            <span tw="pr-3">•</span>Customize the branding, or just add your
            logo.
          </div>
          <div>
            <span tw="pr-3">•</span>Select gifts from Goody’s catalog and add
            branded swag.
          </div>
          <div>
            <span tw="pr-3">•</span>Let recipients choose what they like best.
          </div>
        </div>
        <div>
          <div tw="py-2 text-gray-600 lg:text-[1.125rem] pb-4">
            Available with the Team plan.
          </div>
          <div tw="flex flex-col lg:flex-row items-start mb-4 lg:mb-16">
            <StyledButtonLink
              to={generateRealmPath("business", "/team-demo")}
              className="updated"
            >
              Book a demo
              <ArrowRight />
            </StyledButtonLink>
            <div tw="h-4 md:w-4" />
            <Button
              onClick={() => {
                trackEvent(`Store - Custom - Press Chat`)
                showNewMessages()
              }}
            >
              <ChatBubble />
              Chat with us
            </Button>
          </div>
        </div>
      </div>
      <img
        tw="w-full justify-self-center 2xl:justify-self-start self-end 2xl:ml-[-1.25rem] lg:order-2"
        src={CustomStoreExample}
        alt=""
      />
    </UpsellContainer>
  )
}
