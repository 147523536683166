import { isPast, set } from "date-fns"

import { ContactScheduledEventFragment } from "@/types/graphql-types"

// Determine whether a single occurrence scheduled event is upcoming
export const isUpcomingSingleScheduledEvent = (
  scheduledEvent?: ContactScheduledEventFragment | null,
) => {
  if (!scheduledEvent || scheduledEvent?.year === null) {
    return false
  }

  const eventDate = set(new Date(), {
    month: scheduledEvent.month - 1,
    date: scheduledEvent.day,
    year: scheduledEvent.year,
  })

  return !isPast(eventDate)
}
