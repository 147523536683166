import { useMutation } from "@apollo/client"
import { useApolloClient } from "@apollo/client"

import { UPDATE_GIFT_BATCH_SETTINGS_MUTATION } from "./graphql"
import { useGiftBatchSettings } from "./hooks"
import { TRACK_WORKSPACE_GIFT_BATCH_QUERY } from "../graphql"

import { successToast } from "@/common/toast"
import {
  UpdateGiftBatchSettingsMutation,
  UpdateGiftBatchSettingsMutationVariables,
} from "@/types/graphql-types"

export const UpdateButton = () => {
  const { settings: giftBatch } = useGiftBatchSettings()

  const client = useApolloClient()
  const [giftBatchUpdateSettings] = useMutation<
    UpdateGiftBatchSettingsMutation,
    UpdateGiftBatchSettingsMutationVariables
  >(UPDATE_GIFT_BATCH_SETTINGS_MUTATION, {
    onCompleted: (data) => {
      if (data?.giftBatchUpdateSettings?.ok) {
        client.refetchQueries({
          include: [TRACK_WORKSPACE_GIFT_BATCH_QUERY],
        })
      }
    },
  })

  const updateGiftBatchSettings = async () => {
    const updatedGiftBatchSettings = await giftBatchUpdateSettings({
      variables: {
        giftBatchId: giftBatch.giftBatchId,
        batchName: giftBatch.batchName,
        expiresAt: giftBatch.expiresAt,
        swapType: giftBatch.swapType,
        internationalShippingTier: giftBatch.internationalShippingTier,
        giftMeetingSetting: giftBatch.giftMeetingSetting,
        settings: giftBatch.settings,
      },
    })

    const res = updatedGiftBatchSettings.data?.giftBatchUpdateSettings

    if (res?.ok) {
      successToast("Updated gift successfully.")
    } else {
      const error = res?.error ?? "An error occurred."
      alert(error)
    }
  }

  return (
    <button
      tw="rounded-lg border border-primary-new-600 bg-primary-new-550 px-4 py-2 text-white"
      onClick={updateGiftBatchSettings}
    >
      Save
    </button>
  )
}
