import React, { useContext } from "react"

import { ReactComponent as ChooseIcon } from "./icons/mouse-pointer-click.svg"
import { ReactComponent as SwapIcon } from "./icons/swap.svg"
import OptionBase from "./OptionBase"
import { SwapOptions } from "../../../common/components/SwapOptions"
import { useGiftCart, useGiftData } from "../../../common/hooks/giftData"
import { SwapWarning } from "../../components/SwapWarning"
import { OptionsContext } from "../OptionsContext"

import { GiftSwapTypeEnum } from "@/types/graphql-types"

export default function Swap() {
  const { currentGift, priceEstimate, isDirectSend } =
    useContext(OptionsContext)
  const { setSwapType } = useGiftData()
  const { hasForceSwapEnabledItem, hasCustomStore, hasFlexGiftItem } =
    useGiftCart()

  const swapType = currentGift.swapType

  const previewCopy = () => {
    const selectText = hasFlexGiftItem ? "choose" : "swap for"

    return swapType === GiftSwapTypeEnum.swap_multiple
      ? "Prices shown – " + selectText + " multiple items"
      : swapType === GiftSwapTypeEnum.swap_single
      ? "Prices hidden – " + selectText + " one item"
      : swapType === GiftSwapTypeEnum.swap_disabled
      ? "Disabled"
      : null
  }

  if (isDirectSend || hasCustomStore) {
    return null
  }

  return (
    <OptionBase
      name={hasFlexGiftItem ? "Gift of choice" : "Swap"}
      icon={hasFlexGiftItem ? <ChooseIcon /> : <SwapIcon />}
      preview={previewCopy()}
    >
      <div>
        <SwapWarning
          swapType={currentGift.swapType}
          total={priceEstimate?.cartPriceEstimate?.pricePreTax ?? 0}
          onClickButton={() => setSwapType(GiftSwapTypeEnum.swap_multiple)}
          hasFlexGiftItem={hasFlexGiftItem}
        />
        <SwapOptions
          swapType={currentGift.swapType}
          setSwapType={setSwapType}
          forceSwapEnabled={hasForceSwapEnabledItem}
          showTooltip={false}
          sendV3={true}
          hasFlexGiftItem={hasFlexGiftItem}
        />
      </div>

      <div tw="pt-4 text-gray-500 text-sm font-text">
        <span tw="font-semibold">
          Ensures recipients get something they really want.
        </span>{" "}
        {hasFlexGiftItem
          ? "Recipients can choose from "
          : "Recipients can swap your gift for "}
        equal or lower-priced options available in their region (shipping/tax
        included).
      </div>
      <div tw="pt-4 text-gray-500 text-sm">
        <span tw="font-semibold">
          Any price difference from a {hasFlexGiftItem ? "chosen " : "swapped "}
          gift is added to your account balance.
        </span>{" "}
        Balance can be used towards future gifting on Goody.
      </div>
    </OptionBase>
  )
}
