import React from "react"
import { Helmet } from "react-helmet-async"

import Hero from "./Hero"
import IntegrateHR from "./IntegrateHR"
import ProductDescription from "./ProductDescription"
import SwapTestimonials from "./SwapTestimonials"
import CallToAction from "../components/CallToAction"

const HowItWorks = () => {
  return (
    <>
      <Helmet>
        <title>How It Works — Goody for Business</title>
        <meta
          name="description"
          content="Learn how Goody for Business works. Effortlessly send gifts to employees, clients, and sales prospects without any shipping information."
        />
      </Helmet>
      <Hero />
      <ProductDescription />
      <IntegrateHR />
      <SwapTestimonials />
      <CallToAction />
    </>
  )
}
export default HowItWorks
