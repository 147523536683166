import React from "react"

import { ReactComponent as ChevronRight } from "../../../assets/icons/chevron-right.svg"

export default () => (
  <div tw="ml-auto flex flex-row items-center text-gray-400 hover:text-gray-600 active:text-gray-400 transition-colors">
    <div tw="text-[15px] leading-[1] pr-1">More</div>
    <ChevronRight tw="stroke-current stroke-1" width="16" height="16" />
  </div>
)
