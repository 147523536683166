import { isNil } from "lodash-es"
import { useEffect, useState } from "react"
import tw from "twin.macro"

import DirectSendRecipientErrorRow from "./DirectSendRecipientErrorRow"

import { Send_PriceEstimateMutation } from "@/types/graphql-types"

interface Props {
  priceEstimate: Send_PriceEstimateMutation["priceEstimate"]
  isDirectSend: boolean
  runPriceEstimate?: (calculatedTaxEstimate?: boolean) => void
  priceEstimateLoading?: boolean
  priceEstimateIsCalculated?: boolean
}

export default function DirectSendRecipientErrors({
  priceEstimate,
  isDirectSend,
  runPriceEstimate,
  priceEstimateLoading,
  priceEstimateIsCalculated,
}: Props) {
  // This state stores the stored errors for price estimates that were called
  // with recipients. Price estimates can be called with or without recipients.
  // Every time the recipient list changes when using direct send, we call the
  // price estimate **without** recipients. Only when the user clicks the
  // "Estimate" button do we run the price estimate with recipients. This is
  // because it costs money to run the price estimate with recipients, so we
  // only do it when the user clicks the "Estimate" button.
  //
  // An issue comes up if the user clicks the "Estimate" button, and then sees
  // an error with one of the recipients. If the user then updates the recipient
  // and corrects the error, when they scroll down the address errors section
  // will be gone. This is because the price estimate was run without recipients
  // when the user updated the recipient, and so the errors were overwritten.
  //
  // By storing the stored errors here, we can display them even if the user
  // edited the recipient to correct an error.
  const [storedErrors, setStoredErrors] = useState<NonNullable<
    Send_PriceEstimateMutation["priceEstimate"]["recipientErrors"]
  > | null>(null)

  useEffect(() => {
    // Store the errors if the recipientErrors is not nil, indicating that the
    // price estimate was called with recipients. (When called with recipients,
    // the price estimate's recipientErrors field will always be an array of
    // some length. When not called with recipients, the price estimate will be
    // null. We only store errors if the price estimate was called with
    // recipients, since otherwise the price estimates we run without recipients
    // will overwrite the errors we want to display.)
    if (!isNil(priceEstimate.recipientErrors)) {
      setStoredErrors(priceEstimate.recipientErrors)
    }
  }, [priceEstimate])

  const calculationLoading = priceEstimateLoading && priceEstimateIsCalculated

  if (!isDirectSend || storedErrors === null || storedErrors.length === 0) {
    return null
  }

  return (
    <div tw="mt-4 rounded-lg bg-[#FDEEEE]">
      <div tw="p-5">
        <div tw="text-red-600 text-lg font-medium">Address errors</div>
        <div tw="text-gray-700">
          Please fix these address errors to calculate total.
        </div>
      </div>
      {storedErrors?.map((error) => (
        <DirectSendRecipientErrorRow error={error} key={error.index} />
      ))}
      <div tw="border-t border-white p-5 py-3 flex flex-row items-center justify-center">
        <button
          tw="bg-white transition-all hover:opacity-80 active:scale-90 py-2 px-5 rounded-full shadow-min"
          css={[calculationLoading && tw`opacity-50 cursor-wait`]}
          disabled={calculationLoading}
          onClick={() => runPriceEstimate?.(true)}
        >
          {calculationLoading ? "Loading…" : "Re-run checks"}
        </button>
      </div>
    </div>
  )
}
