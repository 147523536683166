import React, { useEffect, useState } from "react"
import tw, { styled } from "twin.macro"

import { useCurrentGift } from "../../common/hooks/currentGift"
import { useGiftData } from "../../common/hooks/giftData"
import { ReactComponent as CalendarIcon } from "../../common/images/calendar.svg"
import { ReactComponent as XIcon } from "../../common/images/x.svg"
import { formatDayWithDateAndTime } from "../../common/utilities"
import ScheduledGiftDateTimePicker from "../components/ScheduledGiftDateTimePicker"

const ScheduleGift = () => {
  const [currentGift] = useCurrentGift()
  const [scheduledSendOnDisplayDate, setScheduledSendOnDisplayDate] =
    useState<string>("")

  const { setScheduledSend } = useGiftData()

  useEffect(() => {
    setScheduledSendOnDisplayDate(
      formatDayWithDateAndTime(
        currentGift.scheduledSendOnDate,
        currentGift.scheduledSendOnTime,
      ),
    )
  }, [currentGift.scheduledSendOnDate, currentGift.scheduledSendOnTime])

  return (
    <div tw="pt-4 pb-3">
      {currentGift.scheduledSendOnOption ? (
        <ScheduleGiftDatePickerContainer>
          <div tw="flex flex-col pl-1 pt-1">
            <div tw="flex gap-3 items-center">
              <CalendarIcon tw="text-[#C78CCA]" />
              <div tw="text-[#C78CCA] text-base leading-5">
                Scheduled for {scheduledSendOnDisplayDate}
              </div>
            </div>
            <div tw="ml-[30px] z-50 pt-[10px]">
              <ScheduledGiftDateTimePicker
                scheduledSendOnDate={currentGift.scheduledSendOnDate}
                setScheduledSend={setScheduledSend}
                scheduledSendOnTime={currentGift.scheduledSendOnTime}
              />
            </div>
          </div>
          <CloseButton onClick={() => setScheduledSend({ option: false })}>
            <XIcon tw="stroke-current stroke-2 w-4 h-auto" />
          </CloseButton>
        </ScheduleGiftDatePickerContainer>
      ) : (
        <ScheduleGiftButton
          onClick={() => {
            setScheduledSend({ option: true })
          }}
        >
          <CalendarIcon tw="text-[#C78CCA]" />
          <div tw="text-[#C78CCA] text-base leading-5">
            Schedule gift for later
          </div>
        </ScheduleGiftButton>
      )}
    </div>
  )
}

const ScheduleGiftButton = styled.button`
  ${tw`box-border bg-white border-[#F0E1F2] rounded-lg flex items-center align-middle gap-3 p-3 transition-all active:scale-95 hover:(bg-[#FDFBFD] border-[#EDD9F0])`}
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05), 0px 4px 32px rgba(0, 0, 0, 0.04);
`

const ScheduleGiftDatePickerContainer = styled.div`
  ${tw`flex box-border bg-white border-[#F0E1F2] rounded-lg gap-[10px] p-2 pb-3 justify-between`}
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05), 0px 4px 32px rgba(0, 0, 0, 0.04);
`

const CloseButton = tw.button`rounded-md p-2 text-[#C78CCA] self-start transition-all hover:bg-[#faf5fa] active:scale-[0.8]`

export default ScheduleGift
