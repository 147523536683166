import { useQuery } from "@apollo/client"
import { useState } from "react"
import tw, { styled } from "twin.macro"

import { BUDGETING_MEMBERS_QUERY } from "./graphql"
import MemberDetailDrawer from "./MemberDetailDrawer"
import { formatPrice } from "../../common/format"

import {
  BudgetRecurrence,
  OrganizationMembershipAccess,
} from "@/types/graphql-types"
import {
  BudgetingOrganizationMembershipFragment,
  Budgeting_MembersQuery,
} from "@/types/graphql-types"

export default function BudgetingMembers() {
  const { data } = useQuery<Budgeting_MembersQuery>(BUDGETING_MEMBERS_QUERY)

  const [selectedMembership, setSelectedMembership] =
    useState<BudgetingOrganizationMembershipFragment | null>(null)

  const [drawerOpen, setDrawerOpen] = useState(false)

  const memberships = data?.organization?.memberships

  return (
    <>
      <MembersTable>
        <thead>
          <tr>
            <th tw="text-left">Member</th>
            <th tw="text-right">Budget</th>
            <th tw="text-right">
              Remaining
              <br />
              Balance
            </th>
          </tr>
        </thead>
        <tbody>
          {!memberships && (
            <tr>
              <td colSpan={3} tw="text-center text-gray-400">
                <div tw="py-8">Loading&hellip;</div>
              </td>
            </tr>
          )}
          {memberships?.map((membership) => (
            <tr
              onClick={() => {
                setSelectedMembership(membership)
                setDrawerOpen(true)
              }}
            >
              <td width="50%">
                <div tw="text-lg flex flex-row items-center">
                  <div>{membership.member.fullName}</div>
                  {membership.access ===
                    OrganizationMembershipAccess.limited && (
                    <div tw="px-3 py-0.5 rounded-full bg-gray-150 text-gray-500 text-sm ml-3 font-text">
                      Limited
                    </div>
                  )}
                </div>
                {membership.member.companyTitle && (
                  <div tw="text-sm text-gray-400">
                    {membership.member.companyTitle}
                  </div>
                )}
              </td>
              <td width="30%">
                <div tw="flex flex-row justify-end">
                  {membership.budgetRule ? (
                    <div tw="flex flex-col items-end">
                      <div tw="text-lg">
                        {formatPrice(membership.budgetRule.amount)}
                      </div>
                      <div tw="text-sm text-gray-400">
                        {membership.budgetRule.recurrence ===
                        BudgetRecurrence.MONTHLY
                          ? "per month"
                          : "per quarter"}
                      </div>
                    </div>
                  ) : (
                    <div tw="text-gray-400 text-sm">+ Budget</div>
                  )}
                </div>
              </td>
              <td width="20%">
                <div tw="my-2 flex flex-row justify-end">
                  <span
                    tw="rounded-full px-3 py-1 bg-gray-100 text-gray-700 text-xl font-medium"
                    className="current-balance-figure"
                  >
                    {formatPrice(membership.balanceAccount?.balance || 0, true)}
                  </span>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </MembersTable>
      <MemberDetailDrawer
        open={drawerOpen}
        onClose={() => {
          setDrawerOpen(false)
        }}
        membership={selectedMembership}
      />
    </>
  )
}

const MembersTable = styled.table`
  ${tw`mt-12 w-1/2 mx-auto`};

  border-spacing: 0;
  border-collapse: separate;

  thead {
    th {
      ${tw`uppercase font-medium text-sm text-gray-450 px-6 py-2`};
      vertical-align: bottom;
    }
  }

  tbody {
    ${tw`rounded-xl border border-gray-150 shadow-min`};

    box-shadow:
      0 2px 6px rgba(0, 0, 0, 0.04),
      0 0 0 1px #eceef1;

    td {
      ${tw`py-3 px-6 border-b border-b-gray-150`}
    }

    tr {
      ${tw`hover:bg-gray-050 hover:cursor-pointer active:bg-gray-100 transition-colors`}

      .current-balance-figure {
        ${tw`transition-colors`};
      }

      &:hover .current-balance-figure {
        ${tw`bg-gray-150`}
      }

      &:active .current-balance-figure {
        ${tw`bg-gray-200`}
      }
    }

    tr:last-child {
      td {
        ${tw`border-b-0`}
      }
    }
  }
`
