import { ReactNode } from "react"

export interface ColumnProps<T> {
  title: string
  render: (data: T) => ReactNode
}

export interface TableProps<T> {
  columns: ColumnProps<T>[]
  data: T[]
}

const Table = <T,>({ columns, data }: TableProps<T>) => (
  <table
    tw="w-full rounded-lg border-separate border border-gray-200 table-auto text-left"
    style={{ borderSpacing: 0 }}
  >
    <thead>
      <tr>
        {columns.map(({ title }, i) => (
          <th
            tw="text-sm font-medium text-gray-500 first:pl-4 pr-4 py-3"
            key={`${title}-${i}`}
          >
            {title}
          </th>
        ))}
      </tr>
    </thead>
    <tbody>
      {data.map((data, i) => (
        <tr key={i}>
          {columns.map(({ render }, j) => (
            <td
              tw="first:pl-4 pr-4 py-4 border-t border-gray-200 align-top"
              key={j}
            >
              {render(data)}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  </table>
)

export default Table
