import React from "react"
import tw, { styled } from "twin.macro"

import scheduleAMetingWithDoordash from "../../business/images/schedule_a_meeting_with_doordash.png"
import {
  BulletPoint,
  Section,
  Subheading,
} from "../../common/SectionComponents"

export const Title = styled.span`
  ${tw`pt-4 text-3xl lg:text-5xl font-bold`}
  line-height: 2.5rem;
  @media (min-width: 1024px) {
    line-height: 3.5rem;
  }
`

const WhySalesGifting = () => {
  return (
    <Section tw="m-auto flex items-center max-w-[1400px]">
      <LeftContainer>
        <Subheading> Why sales gifting? </Subheading>
        <Title>BDR teams see 2.9X ROI from Goody gifts.</Title>

        <div tw="pt-5 text-gray-600 text-xl leading-normal">
          <BulletPoint>
            {" "}
            Automatically send gifts and gift cards when prospects book time on
            your calendar.
          </BulletPoint>
          <BulletPoint>
            {" "}
            Send gifts to leads, contacts, and ABM lists, right from Salesforce.
          </BulletPoint>
        </div>
      </LeftContainer>

      <RightContainer>
        <Image src={scheduleAMetingWithDoordash} />
      </RightContainer>
    </Section>
  )
}

const Image = styled.img`
  ${tw`m-auto -ml-4`}
`

const LeftContainer = styled.div`
  ${tw`flex-1 pr-5 xl:mb-0 xl:pr-0 items-center max-w-[570px]`}

  @media (max-width: 1080px) {
    ${tw`m-auto`}
  }
`

const RightContainer = styled.div`
  ${tw`flex-1 my-12 xl:pr-0 xl:mt-0`}

  @media (max-width: 1080px) {
    ${tw`m-auto mb-10`}
  }
`

export default WhySalesGifting
