export function usdCentsToForeignCents(usdCents: number, exchangeRate: number) {
  Math.round(usdCents * exchangeRate)
}

// USD amount needs to fully cover the foreign amount
export function foreignCentsToUSDCents(
  foreignCents: number,
  exchangeRate: number,
) {
  return Math.ceil(foreignCents / exchangeRate)
}
