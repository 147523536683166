import React, { ReactElement } from "react"

import TitleContainer from "./TitleContainer"
import PurpleGradientLink from "../../../common/PurpleGradientLink"

interface Props {
  heading?: string
  title: string | ReactElement
  description: string | ReactElement
  brandsImage: string
  primaryAction: {
    text?: string
    linkTo: string
  }
}

const OurCollection: React.FC<Props> = ({
  primaryAction,
  heading = "Our collection",
  title,
  description,
  brandsImage,
}) => (
  <div tw="md:rounded-3rem py-16 md:py-24 md:pb-16 mx-auto bg-light-grey max-w-screen-2xl">
    <div tw="px-4 md:px-16 pb-4 md:mx-auto md:w-2/3">
      <TitleContainer heading={heading} title={title} subtitle={description} />
      <div tw="pt-8 mb-16 flex flex-row justify-center">
        <PurpleGradientLink to={primaryAction.linkTo}>
          {primaryAction.text || "Browse Gifts"}
        </PurpleGradientLink>
      </div>
    </div>
    <img
      alt="Brands images that are a part of Goody's collection"
      src={brandsImage}
      tw="block w-auto h-96 md:w-full md:h-auto object-cover"
    />
  </div>
)

export default OurCollection
