import { useMutation } from "@apollo/client"

import { TrashIcon } from "@/assets/icons"
import { Loader } from "@/common/UI"
import { DELETE_SWAG_PRODUCT_MUTATION } from "@/SwagIntegrations/graphql"
import {
  DeleteSwagProductMutation,
  DeleteSwagProductMutationVariables,
} from "@/types/graphql-types"

interface Props {
  productId: string
}

export const SwagIntegrationDeleteButton = ({ productId }: Props) => {
  const [deleteSwagProductMutation, { loading }] = useMutation<
    DeleteSwagProductMutation,
    DeleteSwagProductMutationVariables
  >(DELETE_SWAG_PRODUCT_MUTATION)

  const deleteProduct = async () => {
    const confirmation = window.confirm(
      "Are you sure you want to delete this product? If you’ve gifted this item, and it has not been accepted yet, your recipients will be forced to swap their gift for something else.",
    )
    if (!confirmation) return

    const { data } = await deleteSwagProductMutation({
      variables: { productId },
    })

    if (data?.deleteSwagProduct.ok) {
      // We are doing a full reload here in order to refresh the
      // browser state and reload the store gift options
      // This should be updated for a better approach soon once we
      // refactor the Store to allow for the gift options query to be
      // accessible in a provider
      window.location.href = "/browse/custom/products"
    } else {
      alert(data?.deleteSwagProduct.error)
    }
  }

  return (
    <button
      onClick={deleteProduct}
      disabled={loading}
      tw="flex gap-2 p-2 items-center text-gray-600 hover:text-[#EB5757] active:text-[#EB5757] stroke-[#A372DF] hover:stroke-[#EB5757] active:stroke-[#EB5757]"
    >
      {loading ? <Loader /> : <TrashIcon />}
      Delete
    </button>
  )
}
