import tw, { styled } from "twin.macro"

import { useCustomStore } from "@/store/custom/hooks"
import { MultipleSelectModeEnum } from "@/types/graphql-types"

export const MultipleSelectDisableButton = () => {
  const { multipleSelectMode, disableMultipleSelection } = useCustomStore()

  return (
    <Container
      onClick={disableMultipleSelection}
      disabled={multipleSelectMode === MultipleSelectModeEnum.disabled}
    >
      Disable
    </Container>
  )
}

const Container = styled.button<{ disabled?: boolean }>`
  ${tw`flex flex-row items-center rounded-full text-gray-500 bg-gray-075 px-4 py-2 disabled:opacity-50 disabled:cursor-auto`}

  ${({ disabled }) => !disabled && tw`hover:bg-gray-150 active:bg-gray-200`};
`
