import pluralize from "pluralize"
import { useState } from "react"
import tw, { styled } from "twin.macro"

import { ShowVariantsIcon } from "./ShowVariantsIcon"

import VariantsModal from "@/store/components/VariantsModal"
import { Editorial_GoodyProductCommonFragment } from "@/types/graphql-types"

const Variants = ({
  product,
}: {
  product: Editorial_GoodyProductCommonFragment
}) => {
  const [variantsModalOpen, setVariantsModalOpen] = useState(false)
  return (
    <div tw="mt-6 flex flex-row justify-center">
      <VariantsContainer onClick={() => setVariantsModalOpen(true)}>
        <div>
          See {product.variants.length}{" "}
          {pluralize.plural(product.variantsLabelDowncase || "variant")}
        </div>
        <div tw="ml-3">
          <ShowVariantsIcon />
        </div>
      </VariantsContainer>

      <VariantsModal
        isOpen={variantsModalOpen}
        close={() => {
          setVariantsModalOpen(false)
        }}
        variants={product.variants}
        variantsLabel={product.variantsLabel}
        variantGroups={product.variantGroups}
      />
    </div>
  )
}

const VariantsContainer = styled.button`
  ${tw`flex flex-row items-center text-primary-500 rounded-lg p-2 font-normal text-sm border border-primary-100 hover:border-primary-200 active:bg-primary-000 transition-colors z-20 relative`}
`

export default Variants
