import { gql, useQuery } from "@apollo/client"
import { groupBy, isEmpty, last, lowerCase, union } from "lodash-es"
import moment from "moment"
import React, { useEffect, useState } from "react"
import Modal from "react-modal"
import { useHistory } from "react-router-dom"
import tw, { styled } from "twin.macro"

import { offsetToLocalTimezone } from "./CalendarEvents"
import EventGift from "./EventGift"
import { ReactComponent as BirthdayCakeEmoji } from "../assets/images/calendar/birthday-cake-emoji.svg"
import birthdaySprinkles from "../assets/images/calendar/birthday-sprinkles.png"
import { ReactComponent as CalendarEmoji } from "../assets/images/calendar/calendar-emoji.svg"
import { ReactComponent as GrayDownArrowIcon } from "../assets/images/calendar/gray-down-arrow.svg"
import { ReactComponent as LargeCalendarIcon } from "../assets/images/calendar/large-calendar.svg"
import { ReactComponent as RightArrowBlueIcon } from "../assets/images/calendar/right-arrow-blue.svg"
import { ReactComponent as RightArrowIcon } from "../assets/images/calendar/right-arrow.svg"
import { ReactComponent as ScheduledClockIcon } from "../assets/images/calendar/scheduled-clock-icon.svg"
import { ReactComponent as SentCheckmarkIcon } from "../assets/images/calendar/sent-checkmark.svg"
import { formatScheduledSendDate } from "../common/format"
import { useGlobalState } from "../common/GlobalState"
import { useGiftData } from "../common/hooks/giftData"
import { modalStyle } from "../common/modal"
import { generateRealmPath } from "../common/realm"
import { Loader } from "../common/UI"
import { generateUUID } from "../common/utilities"
import GiftBatchEditModal from "../track/components/GiftBatchEditModal"

import { EventKind } from "@/types/graphql-types"
import {
  Dashboard_UpcomingEventsQuery,
  Dashboard_UpcomingEventsQueryVariables,
} from "@/types/graphql-types"

type UpcomingEvent = NonNullable<
  NonNullable<Dashboard_UpcomingEventsQuery["me"]>["upcomingEvents"]
>[0]

interface Props {
  anchorDate: Date
  dateManuallySelected: boolean
  workspaceId: string
}

export interface EventHash {
  [date: string]: UpcomingEvent[]
}

const DATE_FORMAT = "YYYY-MM-DD"

const Events = ({ anchorDate, dateManuallySelected, workspaceId }: Props) => {
  // Anchor date passed to upcoming events API
  const [fetchDate, setFetchDate] = useState<Date>(anchorDate)

  // Whether we are currently fetching events from the API
  const [isFetching, setIsFetching] = useState(true)

  // Events hash used to load the upcoming events list
  const [events, setEvents] = useState<EventHash>({})

  /**
    Flashing can happen after we click a date on the calendar, but before
    the upcoming events have been fetched.

    oldAnchorDate is used to display the old events list until the API
    call finishes. It gets set at the end of each new API call to bring it
    in sync with fetchDate and anchorDate.
   */
  const [oldAnchorDate, setOldAnchorDate] = useState<Date>(anchorDate)

  /**
    Whether the events are loading from clicking the "Load More" button.
    Controls whether to append to or replace events list.
   */
  const [isLoadingMore, setIsLoadingMore] = useState(false)

  /**
    dayAfterLastEventDate is set to fetchDate to be passed to the API when
    "Load More" is clicked.
   */
  const [dayAfterLastEventDate, setDayAfterLastEventDate] =
    useState<Date>(anchorDate)

  const [giftBatchEditModalOpen, setGiftBatchEditModalOpen] =
    useState<boolean>(false)

  interface GiftBatchEditModalData {
    id: string
    batchName?: string | null
    batchNameFallback: string
    scheduledSendOn: any
    createdAt: any
  }
  // state used when clicking on a gift batch to open edit modal (for scheduled sends)
  const [currentGiftBatch, setCurrentGiftBatch] =
    useState<GiftBatchEditModalData | null>()

  const closeGiftBatchEditModal = () => {
    setGiftBatchEditModalOpen(false)
  }

  const { data } = useQuery<
    Dashboard_UpcomingEventsQuery,
    Dashboard_UpcomingEventsQueryVariables
  >(UPCOMING_EVENTS_QUERY, {
    variables: {
      anchorDate: offsetToLocalTimezone(fetchDate),
      limit: 10,
    },
    context: { workspaceId },
    fetchPolicy: "network-only",
    onCompleted: () => {
      setIsLoadingMore(false)
      setIsFetching(false)
    },
  })

  function refreshEventsList(
    upcomingEvents: UpcomingEvent[] | undefined | null,
  ) {
    const newEvents = filterEvents(bucketUpcomingEventsByDate(upcomingEvents))

    setDateForLoadMore(Object.keys(newEvents))
    setUpcomingEventsList(newEvents)
  }

  useEffect(() => {
    refreshEventsList(data?.me?.upcomingEvents)
  }, [data?.me?.upcomingEvents])

  useEffect(() => {
    setIsFetching(true)
    setFetchDate(anchorDate)
  }, [anchorDate])

  useEffect(() => {
    setIsFetching(true) // Show loader on workspace change
  }, [workspaceId])

  /**
    Converts events from the API response, bucketing them by the event date,
    and setting the events state.
   */
  function bucketUpcomingEventsByDate(
    upcomingEvents: UpcomingEvent[] | undefined | null,
  ) {
    const newEvents: EventHash = {}

    upcomingEvents?.forEach((event: UpcomingEvent) => {
      const dateKey = moment(event.relevantRecurrence).format(DATE_FORMAT)

      if (!newEvents.hasOwnProperty(dateKey)) {
        newEvents[dateKey] = [event]
      } else {
        newEvents[dateKey].push(event)
      }
    })

    return newEvents
  }

  // Filters out events we don't render for
  const filterUnknownEvents = (events: EventHash) => {
    return Object.keys(events).reduce((filteredEvents, date) => {
      filteredEvents[date] = events[date].filter((event) =>
        [
          EventKind.START_DATE,
          EventKind.WORK_ANNIVERSARY,
          EventKind.BIRTHDAY,
        ].includes(event.kind),
      )

      return filteredEvents
    }, {} as EventHash)
  }

  // Filters out work anniversaries if we have a start date event for that contact for that date
  const filterUnnecessaryWorkAnniversaries = (events: EventHash) => {
    return Object.keys(events).reduce((filteredEvents, date) => {
      const toRemove = new Set<string>()

      const groups = groupBy(events[date], (event) => event.contact?.id)
      for (let [contactId, contactEvents] of Object.entries(groups)) {
        // For all events assigned to a contact
        if (contactId) {
          const eventsByKind = groupBy(contactEvents, (event) => event.kind)

          // If we have a start date, remove work anniversaries
          if (eventsByKind[EventKind.START_DATE]) {
            eventsByKind[EventKind.WORK_ANNIVERSARY]?.forEach((event) =>
              toRemove.add(event.id),
            )
          }
        }
      }

      if (toRemove.size > 0) {
        filteredEvents[date] = events[date].filter(
          (event) => !toRemove.has(event.id),
        )
      } else {
        filteredEvents[date] = events[date]
      }

      return filteredEvents
    }, {} as EventHash)
  }

  const filterEvents = (events: EventHash) => {
    return filterUnnecessaryWorkAnniversaries(filterUnknownEvents(events))
  }

  /**
    Sets the dayAfterLastEventDate. This date is used to fetch when the
    user clicks "Load More".
   */
  function setDateForLoadMore(eventDates: string[]) {
    if (!isEmpty(eventDates)) {
      eventDates.sort((dateString1, dateString2) => {
        return moment(dateString1, DATE_FORMAT).diff(
          moment(dateString2, DATE_FORMAT),
          "days",
        )
      })

      setDayAfterLastEventDate(
        moment(last(eventDates), DATE_FORMAT).add(1, "days").toDate(),
      )
    }
  }

  /**
    Updates the events state. If the user clicked "Load More", append to the list,
    and replace it otherwise.
   */
  function setUpcomingEventsList(newEvents: EventHash) {
    if (isLoadingMore) {
      setEvents({ ...events, ...newEvents })
    } else {
      setEvents(newEvents)

      // Setting oldAnchorDate after we finish fetching brings it back in sync with anchorDate.
      // See dateForRendering below.
      setOldAnchorDate(fetchDate)
    }
  }

  /**
    When a new date is selected, the anchorDate prop may have changed, but the events
    list may not have been fetched yet.

    This sometimes results in flashing of a partially updated UI that is trying to use
    the new anchorDate.

    We can't rely on isFetching because there can be some time between clicking a
    new date in the calendar before isFetching is set to true.

    So we use dateForRendering as the oldAnchorDate until it is in sync with anchorDate
    - which happens at the end of the API call.
   */
  const dateForRendering = !moment(anchorDate).isSame(
    moment(oldAnchorDate),
    "day",
  )
    ? oldAnchorDate
    : anchorDate

  const eventDates = union(
    [moment(dateForRendering).format(DATE_FORMAT)],
    Object.keys(events),
  )

  const dateComponents = eventDates.map((dateString) => (
    <UpcomingItem
      key={dateString}
      date={moment(dateString, DATE_FORMAT).toDate()}
      dateSelected={dateManuallySelected ? dateForRendering : null}
      dateEvents={events[dateString]}
      openGiftBatchEditModal={openGiftBatchEditModal}
    />
  ))

  function openGiftBatchEditModal(event: UpcomingEvent) {
    setGiftBatchEditModalOpen(true)
    if (event.giftBatch) {
      setCurrentGiftBatch({
        id: event.giftBatch.id,
        batchName: event.giftBatch.batchName,
        batchNameFallback: event.giftBatch.batchNameFallback,
        scheduledSendOn: event.giftBatch.scheduledSendOn,
        createdAt: event.giftBatch.createdAt,
      })
    }
  }

  const loadMoreButtonComponent = (
    <EventGrid tw="pt-16">
      <div tw="flex" />
      <div tw="hidden xl:flex" />
      <LoadMore onLoadMore={loadMoreEvents} />
    </EventGrid>
  )

  function loadMoreEvents() {
    setFetchDate(dayAfterLastEventDate)
    setIsLoadingMore(true)
  }

  const eventsListEmpty = isEmpty(events) && !isFetching
  const eventsList = eventsListEmpty ? (
    <NoUpcomingEventsContent />
  ) : (
    <EventList>
      {dateComponents}
      {loadMoreButtonComponent}
    </EventList>
  )

  return (
    <EventListContainer>
      {isFetching ? (
        <div tw="flex justify-center items-center h-full">
          <Loader />
        </div>
      ) : (
        <>
          {eventsList}
          <Modal
            isOpen={giftBatchEditModalOpen}
            closeTimeoutMS={500}
            onRequestClose={closeGiftBatchEditModal}
            shouldCloseOnOverlayClick={true}
            style={modalStyle}
          >
            {currentGiftBatch && (
              <GiftBatchEditModal
                giftBatchId={currentGiftBatch.id}
                giftBatchName={currentGiftBatch.batchNameFallback}
                scheduledSendOn={formatScheduledSendDate(
                  currentGiftBatch.scheduledSendOn,
                )}
                closeGiftBatchEditModal={closeGiftBatchEditModal}
              />
            )}
          </Modal>
        </>
      )}
    </EventListContainer>
  )
}

function giftSent(giftBatch: NonNullable<UpcomingEvent["giftBatch"]>) {
  const isSendNow = !giftBatch.isScheduledSend
  const wasScheduledAndSent =
    giftBatch.isScheduledSend && giftBatch.sendStatus === "COMPLETE"

  return isSendNow || wasScheduledAndSent
}

function NoUpcomingEventsContent() {
  return (
    <div tw="w-full h-full flex flex-col justify-center items-center">
      <LargeCalendarIcon />
      <div tw="text-xl leading-6 pt-9 pb-3">No upcoming events</div>
      <AddContactsText>
        Add birthdays and work anniversaries in Contacts.
      </AddContactsText>
    </div>
  )
}

const AddContactsText = styled.div`
  ${tw`leading-5`};
  color: #9ca3af;
`

interface LoadMoreProps {
  onLoadMore: () => void
}

const LoadMoreText = styled.div`
  ${tw`pr-4`};
  color: #6b7280;
`

const LoadMoreButton = styled.button`
  ${tw`rounded-xl flex justify-center items-center px-4 py-2 w-max`};

  background-color: #f3f4f6;
  transition: background-color 0.2s ease-out;

  &:hover,
  &:focus-visible {
    background-color: #f9fafb;
  }

  &:active {
    background-color: #eceef1;
  }
`

const LoadMore: React.FC<LoadMoreProps> = ({ onLoadMore }) => {
  return (
    <LoadMoreButton onClick={() => onLoadMore()}>
      <div tw="pl-2 pr-4">
        <GrayDownArrowIcon />
      </div>
      <LoadMoreText>Load more</LoadMoreText>
    </LoadMoreButton>
  )
}

interface NoEventsItemProps {
  date: Date | null
}

const NoEventsItem: React.FC<NoEventsItemProps> = ({ date }) => {
  if (!date) {
    return null
  }

  const dateToday = moment(date).isSame(moment(), "day")
  const dateCaption = `${moment(date).format("dddd, MMM D")}`

  return (
    <EventGrid>
      <DateContainer
        tw="flex flex-col z-10 justify-center justify-self-start pb-4 xl:pb-0 xl:justify-self-end"
        className={`date-caption ${moment(date).format("MMM-DD-YYYY")}`}
      >
        <div tw="flex justify-end items-center">
          <div tw="flex flex-col items-end">
            <div tw="text-lg text-gray-400">{dateCaption}</div>
          </div>
        </div>
      </DateContainer>
      <div tw="flex xl:hidden" />
      <IconHolder className="empty" />
      <NoEventsLabel>{`No events ${
        dateToday ? "today" : "on this day"
      }`}</NoEventsLabel>
    </EventGrid>
  )
}

interface UpcomingItemProps {
  date: Date
  dateSelected: Date | null
  dateEvents: UpcomingEvent[]
  openGiftBatchEditModal: (event: UpcomingEvent) => void
}

const UpcomingItem = ({
  date,
  dateSelected,
  dateEvents,
  openGiftBatchEditModal,
}: UpcomingItemProps) => {
  if (!dateEvents) {
    return <NoEventsItem date={dateSelected} />
  }

  const sortedDateEvents = [...dateEvents].sort((a, _b) => {
    return a.kind === EventKind.BIRTHDAY ? -1 : 1
  })

  const multipleEvents = sortedDateEvents.length > 1

  const firstEvent: UpcomingEvent | undefined = sortedDateEvents[0]
  const lastEvent: UpcomingEvent | undefined = last(sortedDateEvents)

  /**
    This is used to display different relative text for events where date text is
    "In X days". This is to prevent confusion for the user in thinking an event is
    "In X days" from the day they clicked, as opposed to from today.
    Example: "In 2 days" becomes "2 days from today".
   */
  const selectedDateNotToday = !!(
    dateSelected && !moment(dateSelected).isSame(moment(), "day")
  )

  const giftScheduledOrSent = (event?: UpcomingEvent) => !!event?.giftBatch

  return (
    <EventGrid
      className={`new-date ${multipleEvents ? "multiple-events" : ""}`}
    >
      <div tw="flex xl:hidden" />
      <DateCaption
        date={date}
        selectedDateNotToday={selectedDateNotToday}
        sent={giftScheduledOrSent(firstEvent)}
      />
      {sortedDateEvents.map((event, index) => {
        const name = [event.contact?.firstName, event.contact?.lastName]
          .filter(Boolean)
          .join(" ")

        return (
          <React.Fragment key={event.id}>
            <IconDiv className={giftScheduledOrSent(event) ? "sent" : ""}>
              <IconHolder className={giftScheduledOrSent(event) ? "sent" : ""}>
                <DateCaptionIcon eventKind={event.kind} />
              </IconHolder>
              {multipleEvents ? (
                <LineBackground
                  className={`${event === firstEvent ? "first" : ""}${
                    event === lastEvent ? "last" : ""
                  }${giftScheduledOrSent(event) ? " sent" : ""}`}
                />
              ) : null}
            </IconDiv>
            <div
              className={`${giftScheduledOrSent(event) ? "sent" : ""}${
                event === lastEvent ? " last" : ""
              }`}
            >
              <Event
                className={`${event.kind} ${
                  giftScheduledOrSent(event) ? "sent" : ""
                }`}
                key={index}
              >
                <div tw="flex flex-col md:flex-row justify-between w-full">
                  <UpcomingItemLabel
                    eventDate={date}
                    eventKind={event.kind}
                    name={name}
                  />
                  <div tw="pl-4 md:pl-0 md:mr-2 z-10 pb-4 md:pt-4 pr-4 flex items-center">
                    <EventButton event={event} date={date} />
                  </div>
                </div>
                {event.giftBatch && (
                  <>
                    {multipleEvents && event !== lastEvent ? (
                      <div tw="relative">
                        <LineBackground />
                      </div>
                    ) : null}
                    <EventGift
                      event={event}
                      openGiftBatchEditModal={openGiftBatchEditModal}
                      giftSent={giftSent(event.giftBatch)}
                    />
                  </>
                )}
              </Event>
            </div>
            <div tw="hidden xl:flex"></div>
          </React.Fragment>
        )
      })}
    </EventGrid>
  )
}

interface EventButtonProps {
  event: UpcomingEvent
  date: Date
}

const EventButton = ({ event, date }: EventButtonProps) => {
  if (!!event.giftBatch) {
    if (giftSent(event.giftBatch)) {
      return <SentLabel eventKind={event.kind} />
    } else {
      return <ScheduledLabel eventKind={event.kind} />
    }
  } else {
    return (
      <ActionButton
        eventKind={event.kind}
        eventContact={event.contact}
        eventDate={date}
        id={event.id}
      />
    )
  }
}

interface UpcomingItemLabelProps {
  eventDate: Date
  eventKind: EventKind
  name: string
}

const UpcomingItemLabel: React.FC<UpcomingItemLabelProps> = ({
  eventDate,
  eventKind,
  name,
}) => {
  function getEventTitle(eventKind: EventKind, name: string) {
    switch (eventKind) {
      case EventKind.START_DATE:
        return `${name}’s start date`
      case EventKind.WORK_ANNIVERSARY:
        return `${name}’s work anniversary`
      case EventKind.BIRTHDAY:
        return `${name}’s birthday`
    }
  }

  const eventInPast = moment(eventDate).isBefore(moment(), "day")

  return (
    <div tw="flex items-center pr-4">
      <EventTitle
        className={[
          eventInPast ? "past" : eventKind,
          "data-hj-suppress ph-no-capture fs-mask",
        ].join(" ")}
      >
        {getEventTitle(eventKind, name)}
        {eventInPast ? <PastTag>PAST</PastTag> : null}
      </EventTitle>
    </div>
  )
}

interface DateCaptionProps {
  date: Date
  selectedDateNotToday: boolean
  sent: boolean
}

const DateCaption: React.FC<DateCaptionProps> = ({
  date,
  selectedDateNotToday,
  sent,
}) => {
  function getRelativeDateString() {
    const eventDate = moment(date)

    const daysFromNow = eventDate.diff(moment().startOf("day"), "days")

    if (daysFromNow === 0) {
      return "Today"
    } else if (daysFromNow === 1) {
      return "Tomorrow"
    } else if (daysFromNow >= 2 && daysFromNow <= 3) {
      if (selectedDateNotToday) {
        return `${daysFromNow} days from today`
      } else {
        return `In ${daysFromNow} days`
      }
    } else if (daysFromNow >= 4 && daysFromNow <= 6) {
      return `On ${eventDate.format("dddd")}`
    } else if (daysFromNow === 7) {
      return `In a week`
    }
  }

  const relativeDateString = getRelativeDateString()
  const dateMoment = moment(date)
  const dateCaption = `${dateMoment.format("dddd, MMM D")}`
  const dateCaptionLong = `${dateMoment.format("MMMM D, YYYY")}`
  const dateNotThisYear = !dateMoment.isSame(moment(), "year")

  return (
    <DateContainer className={`date-caption${sent ? " sent" : ""}`}>
      {relativeDateString ? (
        <RelativeDate className={sent ? "sent" : ""}>
          <div tw="text-xl leading-7 font-medium text-right">
            {relativeDateString}
          </div>
          <div tw="text-lg opacity-40 text-right">{dateCaption}</div>
        </RelativeDate>
      ) : (
        <RegularDate className={sent ? "sent" : ""}>
          {dateNotThisYear ? (
            <div tw="text-lg leading-5 text-right">{dateCaptionLong}</div>
          ) : (
            <div tw="text-lg leading-5 text-right">{dateCaption}</div>
          )}
        </RegularDate>
      )}
    </DateContainer>
  )
}

interface DateCaptionIconProps {
  eventKind: EventKind
}

const DateCaptionIcon: React.FC<DateCaptionIconProps> = ({ eventKind }) => {
  switch (eventKind) {
    case EventKind.WORK_ANNIVERSARY:
    case EventKind.START_DATE:
      return <CalendarEmoji tw="z-10" />
    case EventKind.BIRTHDAY:
      return <BirthdayCakeEmoji tw="z-10" />
    default:
      return null
  }
}

const IconDiv = styled.div`
  ${tw`flex justify-center relative`};

  &.sent {
    ${tw`pb-0`};
  }
`

const LineBackground = styled.div`
  ${tw`absolute h-full w-full`};

  background: var(--vertical-line);

  --line-thickness: 0.375rem;
  --line-start: calc(50% - calc(var(--line-thickness) / 2));
  --vertical-line: linear-gradient(
    90deg,
    transparent 0,
    transparent var(--line-start),
    #efe9f6 var(--line-start),
    #efe9f6 calc(var(--line-start) + var(--line-thickness)),
    transparent calc(var(--line-start) + var(--line-thickness))
  );

  &.first {
    ${tw`bottom-0 pt-6`};
    background-clip: content-box;

    &.sent {
      ${tw`h-full`};
    }
  }

  &.last {
    ${tw`h-6 xl:h-1/2`};

    &.sent {
      ${tw`h-6`};
    }
  }
`

const IconHolder = styled.div`
  ${tw`rounded-full drop-shadow-sm bg-white w-12 h-12 mt-4 md:my-0 flex justify-center items-center justify-self-center self-start md:self-center z-10`};
  box-shadow:
    0px 1px 8px rgba(79, 31, 137, 0.04),
    0px 8px 28px rgba(79, 31, 137, 0.08);

  background: linear-gradient(0deg, transparent 0, transparent 70%, white 98%),
    linear-gradient(
      90deg,
      transparent 0,
      transparent 10,
      rgba(183, 170, 231, 0.2) 10,
      rgba(183, 170, 231, 0.2) 12.5,
      transparent 12.5
    );

  &.empty {
    ${tw`w-3.5 h-3.5`};
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  }

  &.sent {
    ${tw`self-start mt-5`};
  }
`

const NoEventsLabel = styled.div`
  ${tw`rounded-xl bg-white border flex justify-center items-center box-border py-1.5 px-5 w-max`};
  border-color: #e5e7eb;
  color: #9ca3af;
`

const PastTag = styled.span`
  ${tw`rounded-full px-2 py-0.5 text-sm mx-2 inline items-center`};
  background-color: #f3f4f6;
`

const EventTitle = styled.div`
  ${tw`text-xl p-4 md:px-2 md:pl-6 py-4 flex items-center lg:py-7 lg:pr-0 lg:w-full lg:flex-1`};

  &.past {
    ${tw`inline`};
    color: #9ca3af;
  }

  &.BIRTHDAY {
    ${tw`text-primary-600`};
  }

  &.WORK_ANNIVERSARY,
  &.START_DATE {
    color: #2f80ed;
  }
`

interface ActionButtonProps {
  eventKind: string
  eventDate: Date
  eventContact?: UpcomingEvent["contact"]
  className?: string
  id: string
}

const ActionButtonBase = ({
  eventKind,
  eventDate,
  eventContact,
  id,
  className,
}: ActionButtonProps) => {
  const history = useHistory()

  // eslint-disable-next-line
  const [recipients, setRecipients] = useGlobalState("recipients")
  const [, setSendPageMode] = useGlobalState("sendPageMode")

  const { initializeWithEvent } = useGiftData()

  async function sendAGift() {
    const recipientsExisting = recipients.some(
      (recip) =>
        recip.firstName || recip.lastName || recip.phone || recip.email,
    )

    const contactFullName = [eventContact?.firstName, eventContact?.lastName]
      .filter(Boolean)
      .join(" ")

    const confirmMessage = `You have existing recipients in your current gift send. Sending to ${contactFullName} will replace those recipients. Do you want to continue?`

    if (
      ((recipientsExisting && window.confirm(confirmMessage)) ||
        !recipientsExisting) &&
      eventContact
    ) {
      const newRecip = []

      switch (eventKind) {
        case EventKind.START_DATE:
        case EventKind.WORK_ANNIVERSARY:
        case EventKind.BIRTHDAY:
          newRecip.push({
            id: generateUUID(),
            firstName: eventContact.firstName || "",
            lastName: eventContact.lastName || "",
            phone: eventContact.phone || "",
            email: eventContact.email || "",
            scheduledEventId: id,
            scheduledEventKind: eventKind,
            eventDate: eventDate,
            errors: {},
          })
          break
        default:
          break
      }

      initializeWithEvent(
        `${contactFullName}’s ${lowerCase(eventKind)}`,
        eventDate,
      )

      setSendPageMode("createGiftBatch")
      setRecipients(newRecip)

      history.push(generateRealmPath("plus", "/send"))
    }
  }

  return (
    <button className={className + " " + eventKind} onClick={() => sendAGift()}>
      <ActionButtonLabel className={eventKind}>Send a gift</ActionButtonLabel>
      <div className={eventKind} tw="flex-grow mx-4">
        {actionButtonArrow(eventKind)}
      </div>
    </button>
  )
}

const actionButtonArrow = (eventKind: string) => {
  switch (eventKind) {
    case EventKind.START_DATE:
    case EventKind.WORK_ANNIVERSARY:
      return <RightArrowBlueIcon tw="h-3 w-auto" />
    case EventKind.BIRTHDAY:
    default:
      return <RightArrowIcon tw="h-3 w-auto" />
  }
}

const ActionButton = styled(ActionButtonBase)`
  ${tw`rounded-full h-10 md:w-36 flex items-center justify-between focus:outline-none focus-visible:outline-none overflow-hidden relative bg-white`};

  transition: opacity 0.1s ease;
  -webkit-transition: opacity 0.1s ease;
  -moz-transition: opacity 0.1s ease;
  -o-transition: opacity 0.1s ease;
  -ms-transition: opacity 0.1s ease;

  // After element contains the gradient background which will change opacity on
  // hover and active.
  &:after {
    ${tw`absolute inset-0`};
    content: "";
    opacity: 0.35;
    transition: opacity 0.1s ease-out;
  }

  &.BIRTHDAY:after {
    background-image: linear-gradient(
      264.22deg,
      rgba(255, 120, 131, 0.4) -53.18%,
      rgba(168, 181, 253, 0.4) 155.61%
    );
  }

  &.WORK_ANNIVERSARY:after,
  &.START_DATE:after {
    background-image: linear-gradient(90deg, #cbdff6 1.46%, #b5d2f2 98.91%);
  }

  // Something weird about & means we can't include this in the &:after block.
  &:hover:after {
    opacity: 0.6;
  }

  &:active:after {
    opacity: 1;
  }
`

interface SentLabelProps {
  eventKind: string
  className?: string
}
const SentLabelBase: React.FC<SentLabelProps> = ({ eventKind, className }) => {
  return (
    <div className={className + " " + eventKind}>
      <ActionButtonLabel className={eventKind}>Sent</ActionButtonLabel>
      <div className={eventKind} tw="flex-grow mr-4">
        <StyledSentCheckmarkIcon className={eventKind} />
      </div>
    </div>
  )
}

const StyledSentCheckmarkIcon = styled(SentCheckmarkIcon)`
  &.BIRTHDAY {
    color: #b77cbe;
  }

  &.WORK_ANNIVERSARY,
  &.START_DATE {
    color: #2f80ed;
  }
`

const SentLabel = styled(SentLabelBase)`
  ${tw`rounded-full h-10 flex items-center justify-between`};

  &.BIRTHDAY {
    border: 1px solid transparent;

    background:
      linear-gradient(0deg, #ffffff, #ffffff) padding-box,
      linear-gradient(
          264.22deg,
          rgba(255, 120, 131, 0.2) -53.18%,
          rgba(168, 181, 253, 0.2) 155.61%
        )
        border-box;

    &:hover {
      background:
        linear-gradient(0deg, #ffffff, #ffffff) padding-box,
        linear-gradient(
            264.22deg,
            rgba(255, 120, 131, 0.4) -53.18%,
            rgba(168, 181, 253, 0.4) 155.61%
          )
          border-box;
    }
  }

  &.WORK_ANNIVERSARY,
  &.START_DATE {
    border: 1px solid transparent;

    background:
      linear-gradient(0deg, #ffffff, #ffffff) padding-box,
      linear-gradient(90deg, #edf4fc 1.46%, #d9e8f8 98.91%) border-box;

    &:hover {
      background:
        linear-gradient(0deg, #ffffff, #ffffff) padding-box,
        linear-gradient(90deg, #cbdff6 1.46%, #b5d2f2 98.91%) border-box;
    }
  }
`

interface ScheduledLabelProps {
  eventKind: string
  className?: string
}
const ScheduledLabelBase: React.FC<ScheduledLabelProps> = ({
  eventKind,
  className,
}) => {
  return (
    <div className={className + " " + eventKind}>
      <ActionButtonLabel className={eventKind}>Scheduled</ActionButtonLabel>
      <div className={eventKind} tw="flex-grow mr-4">
        <StyledScheduledClockIcon className={eventKind} />
      </div>
    </div>
  )
}

const StyledScheduledClockIcon = styled(ScheduledClockIcon)`
  &.BIRTHDAY {
    color: #b77cbe;
  }

  &.WORK_ANNIVERSARY,
  &.START_DATE {
    color: #2f80ed;
  }
`

const ScheduledLabel = styled(ScheduledLabelBase)`
  ${tw`rounded-full h-10 flex items-center justify-between`};

  &.BIRTHDAY {
    border: 1px solid transparent;

    background:
      linear-gradient(0deg, #ffffff, #ffffff) padding-box,
      linear-gradient(
          264.22deg,
          rgba(255, 120, 131, 0.2) -53.18%,
          rgba(168, 181, 253, 0.2) 155.61%
        )
        border-box;

    &:hover {
      background:
        linear-gradient(0deg, #ffffff, #ffffff) padding-box,
        linear-gradient(
            264.22deg,
            rgba(255, 120, 131, 0.4) -53.18%,
            rgba(168, 181, 253, 0.4) 155.61%
          )
          border-box;
    }
  }

  &.WORK_ANNIVERSARY,
  &.START_DATE {
    border: 1px solid transparent;

    background:
      linear-gradient(0deg, #ffffff, #ffffff) padding-box,
      linear-gradient(90deg, #edf4fc 1.46%, #d9e8f8 98.91%) border-box;

    &:hover {
      background:
        linear-gradient(0deg, #ffffff, #ffffff) padding-box,
        linear-gradient(90deg, #cbdff6 1.46%, #b5d2f2 98.91%) border-box;
    }
  }
`

const ActionButtonLabel = styled.div`
  ${tw`text-base leading-6 z-10 ml-4 mr-2 flex-grow whitespace-nowrap`};

  &.BIRTHDAY {
    background: linear-gradient(264.22deg, #ff5764 -53.18%, #8295fc 155.61%),
      linear-gradient(0deg, #ffffff, #ffffff);

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &.WORK_ANNIVERSARY,
  &.START_DATE {
    background: linear-gradient(90deg, #1d69cf 1.83%, #78a3dc 117.07%),
      linear-gradient(0deg, #ffffff, #ffffff);

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`

const Event = styled.div`
  ${tw`bg-white flex flex-col border box-border items-start justify-center rounded-xl relative w-full`};

  &.BIRTHDAY {
    border-color: #f5f1fa;
    box-shadow:
      0px 1px 4px rgba(79, 31, 137, 0.05),
      0px 6px 20px rgba(79, 31, 137, 0.05);

    &:not(.sent) {
      background-image: url(${birthdaySprinkles});
      background-repeat: repeat-y;
      background-position: right top;
      background-size: 288px 79px;

      @media only screen and (max-width: 767px) {
        background-image: none;
      }
    }
  }

  &.WORK_ANNIVERSARY,
  &.START_DATE {
    border-color: #eff4fa;
    box-shadow:
      0px 1px 4px rgba(0, 76, 166, 0.05),
      0px 6px 20px rgba(0, 76, 166, 0.05);
  }
`

const DateContainer = styled.div`
  ${tw`flex flex-col z-10 w-full pb-4 justify-center items-start justify-self-end xl:pb-0 xl:items-end`};

  @media (max-width: 1279px) {
    grid-column: 2;
  }
  grid-column: 1;
  grid-row: 1 / last-line;

  @media (min-width: 1280px) {
    &.sent {
      ${tw`pb-0`};
    }
  }

  &.sent {
    ${tw`justify-start`};
  }
`

const RelativeDate = styled.div`
  ${tw`flex flex-col items-start xl:items-end`}

  &.sent {
    ${tw`pt-5`};
  }
`

const RegularDate = styled.div`
  ${tw`flex flex-col items-end`}

  &.sent {
    ${tw`pt-9`};
  }
`
const EventGrid = styled.div`
  ${tw`grid pb-4`};

  grid-column-gap: 0.5rem;
  grid-template-columns:
    var(--date-column-percentage) var(--icon-column-percentage)
    auto;
  grid-column: 3;

  --date-column-percentage: 24%;
  --icon-column-percentage: 8%;

  &.new-date {
    &:not(:first-of-type) {
      ${tw`pt-6`};
    }
  }

  &.multiple-events {
    ${tw`pb-0`};
    > div {
      &:not(:last-of-type) {
        ${tw`pb-4`};
      }
    }
  }

  background: var(--vertical-line);

  --vertical-line-start: calc(
    var(--date-column-percentage) + calc(var(--icon-column-percentage) / 2) - calc(
        var(--line-thickness) / 2
      ) + 0.5rem
  );
  --line-thickness: 0.25%;
  --vertical-line: linear-gradient(
    90deg,
    transparent 0,
    transparent var(--vertical-line-start),
    rgba(183, 170, 231, 0.2) var(--vertical-line-start),
    rgba(183, 170, 231, 0.2)
      calc(var(--vertical-line-start) + var(--line-thickness)),
    transparent calc(var(--vertical-line-start) + var(--line-thickness))
  );

  &:first-of-type {
    background: linear-gradient(0deg, transparent 0, transparent 70%, white 98%),
      var(--vertical-line);
  }

  &:last-of-type {
    background: linear-gradient(0deg, white 0, transparent 80%),
      var(--vertical-line);
  }

  @media (max-width: 1279px) {
    --icon-column-percentage: 10%;
    --vertical-line-start: calc(
      calc(var(--icon-column-percentage) / 2) - calc(var(--line-thickness) / 2)
    );
    grid-template-columns: var(--icon-column-percentage) auto;
    grid-column: 2;
  }

  @media (max-width: 1024px) {
    --line-thickness: 0.5%;
  }

  @media (max-width: 680px) {
    --icon-column-percentage: 15%;
  }

  @media (max-width: 320px) {
    --icon-column-percentage: 20%;
  }
`

const EventListContainer = styled.div`
  ${tw`relative md:px-10 xl:max-w-full xl:w-full xl:pl-0 xl:pr-12`};
`

const EventList = styled.div`
  animation: opacityIn;
  animation-fill-mode: both;
  animation-timing-function: ease-out;
  animation-duration: 0.2s;
`

const UPCOMING_EVENTS_QUERY = gql`
  query Dashboard_UpcomingEvents($anchorDate: ISO8601Date, $limit: Int) {
    me {
      id
      upcomingEvents(anchorDate: $anchorDate, limit: $limit, segment: PLUS) {
        id
        day
        month
        relevantRecurrence(anchorDate: $anchorDate)
        kind
        contact {
          id
          firstName
          lastName
          phone
          email
        }
        giftBatch(anchorDate: $anchorDate) {
          batchName
          batchNameFallback
          isScheduledSend
          isAutogift
          scheduledSendOn
          fromName
          createdAt
          sendStatus
          customStoreImageThumb {
            url
          }
          id
          message
          gifts {
            id
            status
            senderViewAccessId
          }
          cartProducts {
            productFragment {
              name
              brandName
              primaryImage {
                imageThumb {
                  height
                  width
                  url
                }
              }
            }
          }
          cardFragment {
            image {
              url
            }
          }
        }
      }
    }
  }
`

export default Events
