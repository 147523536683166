import { gql, useQuery } from "@apollo/client"
import React from "react"
import { useIntercom } from "react-use-intercom"
import tw, { styled } from "twin.macro"

import Button, { StyledButtonLink } from "./Button"
import exampleAutogift from "./images/example-autogift.png"
import gradientBg from "./images/gradient-bg.jpg"
import providers from "./images/providers.svg"
import { generateRealmPath } from "./realm"
import { ReactComponent as ArrowRight } from "../assets/icons/arrow-right.svg"
import { ReactComponent as ChatBubble } from "../assets/icons/chat-bubble-black.svg"

import { TeamPlanUpsell_SubscriptionQuery } from "@/types/graphql-types"

interface Props {
  eventPrefix: string
}

export default function TeamPlanUpsell({ eventPrefix }: Props) {
  const { data, loading } = useQuery<TeamPlanUpsell_SubscriptionQuery>(
    TEAM_PLAN_UPSELL_SUBSCRIPTION_QUERY,
  )

  const { showNewMessages, trackEvent, showArticle } = useIntercom()

  if (!data) {
    if (loading) {
      return <UpsellContainer></UpsellContainer>
    } else {
      return null
    }
  }

  return (
    <UpsellContainer>
      <ScreenshotContainer>
        <img src={exampleAutogift} alt="" width={311} />
      </ScreenshotContainer>
      <ContentContainer>
        <div tw="font-medium text-2xl" css={{ color: "#906ff7" }}>
          Set up automated gifts for employee <br tw="hidden lg:inline-block" />
          onboarding, birthdays, and work anniversaries
        </div>
        <div tw="pt-6 font-text block lg:flex flex-row items-center">
          Connect your HR system{" "}
          <button
            onClick={() => showArticle(5998898)}
            tw="opacity-60 hover:opacity-100 transition-opacity block"
          >
            <img src={providers} alt="" tw="pt-2 lg:pt-0 lg:pl-4" />
          </button>
        </div>
        <div tw="pt-4 font-text">
          Enable automatic gifts for onboarding, birthdays, and work
          anniversaries
        </div>
        <div tw="pt-4 font-text">
          See upcoming onboarding events, birthdays, and work anniversaries on a
          calendar
        </div>
        <div tw="pt-12 flex flex-col lg:flex-row items-start">
          {data?.me?.isOrganizationAdmin ? (
            <div tw="flex flex-col items-center">
              <StyledButtonLink
                to={generateRealmPath("business", "/team-demo")}
                className="updated"
              >
                Book a demo
                <ArrowRight />
              </StyledButtonLink>
              <div tw="font-text pt-3 text-gray-500 text-sm">
                Free trial available
              </div>
            </div>
          ) : (
            <button
              tw="border border-gray-200 py-2 px-4 rounded-lg text-gray-600"
              disabled
            >
              Please contact your admin to upgrade
            </button>
          )}
          <div tw="h-4 md:w-4" />
          <Button
            onClick={() => {
              trackEvent(`${eventPrefix}EE Plan Upsell - Press Chat`)
              showNewMessages()
            }}
          >
            <ChatBubble />
            Chat with us
          </Button>
        </div>
      </ContentContainer>
    </UpsellContainer>
  )
}

const UpsellContainer = styled.div`
  ${tw`relative xl:max-w-full xl:w-full mx-6 rounded-xl flex flex-row items-center`};

  box-shadow:
    0px 1px 2px rgba(79, 31, 137, 0.04),
    0px 0px 8px rgba(79, 31, 137, 0.04),
    0px 8px 12px rgba(79, 31, 137, 0.04);
`

const ScreenshotContainer = styled.div`
  ${tw`hidden md:flex flex-row items-center justify-center rounded-l-xl pl-1 pr-5 self-stretch`};
  background-image: url(${gradientBg});
  background-size: cover;
  background-position: right center;
`

const ContentContainer = styled.div`
  ${tw`flex-1 px-10 py-8`};
`

const TEAM_PLAN_UPSELL_SUBSCRIPTION_QUERY = gql`
  query TeamPlanUpsell_Subscription {
    me {
      id
      isOrganizationAdmin
    }
    organization {
      id
      subscription {
        id
      }
    }
  }
`
