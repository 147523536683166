import { useCallback, useMemo } from "react"

import ShippingCountryListFilter from "./ShippingCountryListFilter/ShippingCountryListFilter"

import { useShippingCountries } from "@/store/providers/ShippingCountriesProvider"
import {
  ShippingCountryGroup,
  ShippingCountryGroupEnum,
} from "@/types/graphql-types"

interface Props {
  shippingCountryGroup: ShippingCountryGroup
}

const PopoverShippingCountryList = ({ shippingCountryGroup }: Props) => {
  const {
    popoverShippingCountry,
    setPopoverShippingCountry,
    expandShippingCountries,
  } = useShippingCountries()

  const expandWithSingleGroup = useCallback(
    (
      shippingCountryGroup: ShippingCountryGroup,
      group: "domestic" | "global",
    ) => {
      const countries = shippingCountryGroup[group]
      if (!countries) return []

      const extendedCountries = expandShippingCountries(countries)
      return Object.values(extendedCountries).map((country) => ({
        ...country,
        groups: [ShippingCountryGroupEnum[group]],
      }))
    },
    [expandShippingCountries],
  )

  const shippingCountries = useMemo(
    () => [
      ...expandWithSingleGroup(shippingCountryGroup, "domestic"),
      ...expandWithSingleGroup(shippingCountryGroup, "global"),
    ],
    [shippingCountryGroup, expandWithSingleGroup],
  )

  return (
    <ShippingCountryListFilter
      variant="selector"
      countries={shippingCountries}
      selectedCountry={popoverShippingCountry}
      setSelectedCountry={setPopoverShippingCountry}
      withinScrollable={true}
    />
  )
}

export default PopoverShippingCountryList
