import React, { ReactNode } from "react"
import tw, { styled } from "twin.macro"

import ConnectHRButton from "./ConnectHRButton"
import ConnectHRContainer from "./ConnectHRContainer"
import ConnectRipplingButton from "./ConnectRipplingButton"
import ADPPng from "./images/hr-integration/ADP.png"
import AutogiftPng from "./images/hr-integration/autogift.png"
import BambooHRPng from "./images/hr-integration/bamboohr.png"
import CalendarPng from "./images/hr-integration/calendar.png"
import EasyGiftingPng from "./images/hr-integration/easygifting.png"
import GustoPng from "./images/hr-integration/gusto.png"
import RipplingPng from "./images/hr-integration/rippling.png"
import WorkdayPng from "./images/hr-integration/workday.png"

interface Props {
  onIntegrationConnect: () => void
}

const HRISConnect: React.FC<Props> = ({ onIntegrationConnect }) => {
  return (
    <div tw="h-full overflow-auto">
      <div tw="px-9 pb-24">
        <h2 tw="font-medium text-2xl pt-7 pb-8">HR Integration</h2>
        <ConnectContainer>
          <div tw="text-2xl pt-12 pb-6">
            Connect Goody with your HR platform
          </div>
          <ProvidersContainer>
            <ProviderCard onIntegrationConnect={onIntegrationConnect}>
              <img src={ADPPng} alt="ADP" tw="w-20 object-contain" />
            </ProviderCard>
            <ProviderCard onIntegrationConnect={onIntegrationConnect}>
              <img src={GustoPng} alt="Gusto" tw="w-20 object-contain" />
            </ProviderCard>
            <ProviderCard onIntegrationConnect={onIntegrationConnect}>
              <img src={BambooHRPng} alt="BambooHR" tw="w-36 object-contain" />
            </ProviderCard>
            <ConnectRipplingButton
              className="hide-mobile hris-provider"
              onIntegrationConnect={onIntegrationConnect}
            >
              <img src={RipplingPng} alt="Rippling" tw="w-32 object-contain" />
            </ConnectRipplingButton>
            <ProviderCard onIntegrationConnect={onIntegrationConnect}>
              <img src={WorkdayPng} alt="Workday" tw="w-24 object-contain" />
            </ProviderCard>
            <div tw="col-start-2 xl:col-start-5 text-gray-500 text-center">
              + more
            </div>
          </ProvidersContainer>
          <ConnectHRContainer onIntegrationConnect={onIntegrationConnect} />
          <div tw="text-sm text-gray-500 flex flex-col justify-center pb-9 pt-6">
            <div tw="font-medium text-center">
              Work at a company with over 5,000 employees?
            </div>
            <div tw="text-center">
              <a
                href="mailto:plus-support@ongoody.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Contact us
              </a>
              &nbsp;to connect your HR platform.
            </div>
          </div>
        </ConnectContainer>
        <FeaturesContainer>
          <FeatureComponent featureName="Event calendar" image={CalendarPng} />
          <FeatureComponent featureName="Autogift" image={AutogiftPng} />
          <FeatureComponent featureName="Easy gifting" image={EasyGiftingPng} />
        </FeaturesContainer>
      </div>
    </div>
  )
}

const ConnectContainer = styled.div`
  ${tw`border border-primary-050 box-border rounded-xl px-6 flex flex-col items-center`}

  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%), linear-gradient(264.22deg, rgba(255, 120, 131, 0.1) -4.61%, rgba(168, 181, 253, 0.1) 99.81%), #FFFFFF;
`

const ProvidersContainer = styled.div`
  ${tw`grid-cols-2 gap-x-4 gap-y-4 xl:gap-y-1 grid xl:grid-cols-5 pb-5 xl:col-end-5`}
`

interface ProviderCardProps {
  className?: string
  onIntegrationConnect: () => void
  children: ReactNode
}

const ProviderCard: React.FC<ProviderCardProps> = ({
  className,
  onIntegrationConnect,
  children,
}) => {
  return (
    <ConnectHRButton
      className={`${className ?? ""} hris-provider`}
      onIntegrationConnect={onIntegrationConnect}
    >
      {children}
    </ConnectHRButton>
  )
}

const FeaturesContainer = styled.div`
  ${tw`grid grid-cols-1 xl:grid-cols-3 gap-14 pl-4 pr-6 pt-16`}
`

interface FeatureComponentProps {
  featureName: string
  image: string
}

const FeatureComponent: React.FC<FeatureComponentProps> = ({
  featureName,
  image,
}) => {
  return (
    <div tw="flex flex-col items-center">
      <FeatureName>{featureName}</FeatureName>
      <div tw="text-gray-500 text-center pb-9 px-4 xl:w-full">
        {features[featureName]}
      </div>
      <img src={image} alt={featureName} />
    </div>
  )
}

const FeatureName = styled.div`
  ${tw`text-center pb-3`}

  font-size: 22px;
`

const features: { [name: string]: string } = {
  "Event calendar":
    "See upcoming birthdays and work anniversaries. Schedule gifts in a few clicks.",
  Autogift:
    "Automatically set up gifts for birthdays and work anniversaries. Edit gifts up to a month in advance.",
  "Easy gifting":
    "Send gifts to an entire department, or easily choose people to gift to from Contacts.",
}

export default HRISConnect
