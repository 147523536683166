import React from "react"
import tw from "twin.macro"

import StatsProofCard from "../components/StatsProofCard"
import TitleContainer from "../components/TitleContainer"

const WhyEmployeeGifting = () => {
  const proofCards = [
    {
      percentageAmount: "76",
      text: "Millennials who say they would leave their job if they didn’t feel appreciated.",
      reference: "FORBES, OFFICE TEAM RESEARCH",
      backgroundColor: tw`bg-[rgba(164, 112, 255, 0.1)]`,
      percentageColor: tw`text-[rgba(164, 112, 255, 1)]`,
    },
    {
      percentageAmount: "65",
      text: "Americans said they got no recognition for good work last year.",
      reference: "GALLUP, TOM RATH & DONALD CLIFTON",
      backgroundColor: tw`bg-[rgba(16, 195, 219, 0.1)]`,
      percentageColor: tw`text-[rgba(16, 195, 219, 1)]`,
    },
    {
      percentageAmount: "+25",
      text: "Productivity increase observed when workers received thoughtful gifts.",
      reference: "HARVARD BUSINESS REVIEW",
      backgroundColor: tw`bg-[rgba(255, 90, 161, 0.1)]`,
      percentageColor: tw`text-[rgba(255, 90, 161, 1)]`,
    },
  ]

  return (
    <div tw="mx-auto max-w-screen-xl">
      <div tw="mx-5 md:mx-auto">
        <TitleContainer
          heading="Why employee gifting"
          titleSize="lg"
          title={
            <>
              Your team works hard.
              <br />
              Celebrating them is easy.
            </>
          }
          subtitle={
            <div tw="lg:mt-4 lg:text-[22px] lg:leading-[34px] max-w-[560px] mx-auto">
              Lack of recognition is a primary driver for employee attrition.
              Giving thoughtful, personalized gifts can be more motivating than
              cash bonuses.
            </div>
          }
        />
      </div>

      <CardsGridContainer>
        {proofCards.map((args) => (
          <StatsProofCard key={args.reference} {...args} />
        ))}
      </CardsGridContainer>
    </div>
  )
}

const CardsGridContainer = tw.div`
  grid grid-cols-1 lg:grid-cols-3
  mt-10 mx-5 gap-5 lg:gap-8
  md:max-w-md md:mx-auto
  lg:max-w-max lg:mx-5 lg:mt-14
`

export default WhyEmployeeGifting
