import { useEffect, useState } from "react"
import tw from "twin.macro"

import {
  DEFAULT_FLEX_GIFT_PRICE_IN_DOLLARS,
  MINIMUM_FLEX_GIFT_PRICE_IN_DOLLARS,
} from "./constants"
import FlexGiftAmount from "./FlexGiftAmount"
import FlexGiftInfo from "./FlexGiftInfo"
import FlexGiftMobilePreview from "./FlexGiftMobilePreview"
import FlexGiftPreview from "./FlexGiftPreview"
import FlexGiftSendGiftButton from "./FlexGiftSendGiftButton"
import FlexGiftSwapTypeSelector, { SwapType } from "./FlexGiftSwapTypeSelector"

import { useGlobalState } from "@/common/GlobalState"
import { useGiftData } from "@/common/hooks/giftData"
import ModalCloseButton from "@/store/components/ModalCloseButton"
import useStoreContext from "@/store/hooks/useStoreContext"
import { GiftSwapTypeEnum } from "@/types/graphql-types"
import { Store_PDP_ProductDetailsFragment } from "@/types/graphql-types"

interface Props {
  product: Store_PDP_ProductDetailsFragment
  onClose?: () => void
  onSendGift: () => void
}

export default function FlexGift({ product, onClose, onSendGift }: Props) {
  // Amount in dollars
  const [amount, setAmount] = useState(DEFAULT_FLEX_GIFT_PRICE_IN_DOLLARS)

  const [calculatorMode, setCalculatorMode] = useState(false)

  const [swapType, setSwapType] = useState<SwapType>("single")

  const [currentRealm] = useGlobalState("currentRealm")
  const isConsumer = currentRealm === "consumer"

  const [, setStoreSelections] = useGlobalState("storeSelections")

  const { caller } = useStoreContext()

  useEffect(() => {
    setStoreSelections((val) => ({
      ...val,
      flexGiftPrice: amount * 100,
    }))
  }, [amount, setStoreSelections])

  const { setSwapType: setCurrentGiftSwapType } = useGiftData()
  const [, setCurrentAutogiftRule] = useGlobalState("currentAutogiftRule")

  function saveSwapType(swapType: GiftSwapTypeEnum) {
    if (caller === "autogift") {
      setCurrentAutogiftRule((prev) => ({
        ...prev,
        swapType,
      }))
    } else {
      setCurrentGiftSwapType(swapType)
    }
  }

  function onClickSendGift() {
    if (!isConsumer) {
      if (swapType === "single") {
        saveSwapType(GiftSwapTypeEnum.swap_single)
      } else if (swapType === "multiple") {
        saveSwapType(GiftSwapTypeEnum.swap_multiple)
      }
    }

    onSendGift()
  }

  useEffect(() => {
    // Add body.hide-modal-close-hint
    document.body.classList.add("hide-modal-close-hint")

    return () => {
      // Remove body.hide-modal-close-hint
      document.body.classList.remove("hide-modal-close-hint")
    }
  }, [])

  return (
    <div tw="h-full max-h-[100vh]">
      <div tw="z-10 relative flex flex-col items-stretch h-full">
        {onClose && <ModalCloseButton onClick={onClose} />}
        <div
          tw="flex flex-col lg:flex-row items-start p-6 md:p-10 gap-12"
          css={onClose && tw`lg:pr-20`}
        >
          <FlexGiftInfo product={product} />
          <div tw="flex-[1.15]">
            <FlexGiftAmount
              amount={amount}
              setAmount={setAmount}
              calculatorMode={calculatorMode}
              setCalculatorMode={setCalculatorMode}
              isConsumer={isConsumer}
            />
            <div tw="mt-6">
              <div tw="flex flex-col md:flex-row items-stretch justify-center gap-6">
                {!isConsumer && (
                  <FlexGiftSwapTypeSelector
                    swapType={swapType}
                    setSwapType={setSwapType}
                    amount={amount}
                  />
                )}
                <FlexGiftSendGiftButton
                  product={product}
                  disabled={amount < MINIMUM_FLEX_GIFT_PRICE_IN_DOLLARS}
                  onClick={onClickSendGift}
                />
              </div>
            </div>
            <FlexGiftMobilePreview amount={amount} productID={product.id} />
          </div>
        </div>
        <FlexGiftPreview
          productID={product.id}
          amount={amount}
          calculatorMode={calculatorMode}
          swapType={swapType}
        />
      </div>
    </div>
  )
}
