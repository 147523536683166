import { isNil } from "lodash-es"
import React, { useContext } from "react"

export const createHookContext = <V, P extends any[]>(
  name: string,
  hook: (...params: P) => V,
  ...hookParams: P
) => {
  const Context = React.createContext<V | null>(null)

  const Provider = (
    props: Omit<React.ComponentProps<typeof Context.Provider>, "value">,
  ) => {
    return <Context.Provider value={hook(...hookParams)} {...props} />
  }

  const useHook = () => {
    const context = useContext(Context)

    if (isNil(context)) {
      throw new Error(
        `hook named "${name}" must be used within context provider`,
      )
    }

    return context
  }

  const useHookUnsafe = () => useContext(Context)

  return { Provider, useHook, useHookUnsafe }
}
