import tw, { styled } from "twin.macro"

export const ModalContainer = styled.div`
  ${tw`absolute bg-white hidden lg:block right-4 w-5/6 transition-transform`};

  box-shadow:
    0 1px 4px 0 rgba(58, 8, 119, 0.05),
    0 8px 36px 0 rgba(58, 8, 119, 0.05);
  z-index: 100;

  @media only screen and (min-width: 1080px) {
    height: calc(100vh);

    .custom-store-browser & {
      height: calc(100vh - 6rem);
    }
  }

  // On most screens, take up all the space to the right.
  ${tw`right-0`};

  // On very large screens, limit the width of the container and put it at the
  // right edge of the content container.
  @media only screen and (min-width: 1600px) {
    ${tw`w-[1300px]`};

    // The width of the content container at this size is 1440px or so.
    // To find the total margin between the content container and the edge of
    // the viewport, subtract it from the viewport size. Divide by 2 to get
    // the size of the right margin.
    right: calc((100vw - 1440px) / 2);
  }

  animation: scaleSlideIn 200ms;

  @keyframes scaleSlideIn {
    0% {
      transform: translateX(32px);
      opacity: 0;
    }
    100% {
      transform: translateX(0px);
      opacity: 100;
    }
  }

  &.scale-slide-out {
    animation: scaleSlideOut 400ms;

    @keyframes scaleSlideOut {
      0% {
        transform: translateX(0px);
        opacity: 100;
      }
      100% {
        transform: translateX(32px);
        opacity: 0;
      }
    }
  }
`

export const Underlay = styled.div`
  z-index: 1300;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;

  @media only screen and (min-width: 1024px) {
    animation: backgroundPurpleIn;
    animation-fill-mode: both;
    animation-timing-function: ease-out;
    animation-duration: 0.3s;

    &.closing {
      animation: backgroundPurpleOut;
      animation-fill-mode: both;
      animation-timing-function: ease-out;
      animation-duration: 0.3s;
    }
  }
`
