import { ReactComponent as SearchIcon } from "@/common/images/search.svg"

interface Props {
  value: string
  onChange: (value: string) => void
}

export const SearchInput = ({ value, onChange }: Props) => {
  return (
    <div tw="flex items-center gap-4 py-5 px-7 text-gray-500">
      <SearchIcon tw="stroke-current h-4 w-4" />
      <input
        value={value}
        placeholder="Search"
        tw="appearance-none outline-none border-none placeholder-gray-500 bg-transparent flex-1"
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  )
}
