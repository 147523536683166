import { gql, useMutation } from "@apollo/client"
import { useState } from "react"
import useCollapse from "react-collapsed"
import tw from "twin.macro"

import Box from "../../../common/Box"
import { ReactComponent as Chevron } from "../../../common/images/chevron-down.svg"
import { PurpleGradientButton } from "../../../common/PurpleGradientLink"
import {
  SelectionGroup,
  SelectionIndicator,
  SelectionLabel,
} from "../../../common/SelectionGroup"
import { successToast } from "../../../common/toast"
import { getMonthNameForNumber } from "../../../common/utilities"
import { BUDGETING_INDEX_QUERY } from "../graphql"

import {
  Budgeting_UpdateQuarterStartMonthMutation,
  Budgeting_UpdateQuarterStartMonthMutationVariables,
} from "@/types/graphql-types"

interface Props {
  quarterStartMonth: number
}

export default function BudgetingQuarter({ quarterStartMonth }: Props) {
  const [selectedStartMonth, setSelectedStartMonth] =
    useState(quarterStartMonth)
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse()

  // Note we use the server-returned quarterStartMonth for the label.
  const nextQuarterMonth = getMonthNameForNumber(
    getNextQuarterStartMonth(quarterStartMonth),
  )

  const [updateQuarterStartMonth, { loading: mutationLoading }] = useMutation<
    Budgeting_UpdateQuarterStartMonthMutation,
    Budgeting_UpdateQuarterStartMonthMutationVariables
  >(UPDATE_QUARTER_START_MONTH_MUTATION)

  const handleSave = async () => {
    const res = await updateQuarterStartMonth({
      variables: {
        startMonth: selectedStartMonth,
      },
      refetchQueries: [
        {
          query: BUDGETING_INDEX_QUERY,
        },
      ],
    })

    const data = res?.data?.organizationUpdateQuarterStartMonth

    if (data?.ok) {
      successToast("Updated quarter start month.")
    } else if (data?.error) {
      alert(data.error)
    } else {
      alert("Sorry, an error occurred.")
    }
  }

  return (
    <div tw="lg:w-1/2 mx-auto mt-16">
      <button
        {...getToggleProps()}
        tw="flex flex-row items-center justify-center gap-3 mx-auto text-gray-500 hover:(text-gray-700 bg-gray-100) active:(text-gray-800 bg-gray-150) rounded-full transition-colors py-2 px-4"
        css={[isExpanded && tw`text-gray-700 bg-gray-100`]}
      >
        <div>Next quarter starts in {nextQuarterMonth}</div>
        <Chevron
          tw="stroke-current transition-all"
          css={[isExpanded && tw`rotate-180`]}
        />
      </button>

      <div {...getCollapseProps()} tw="mt-4">
        <Box tw="p-8">
          <div tw="pb-5 font-medium text-lg">Quarter starts on&hellip;</div>
          {[
            { month: 1, name: "January, April, July, October" },
            { month: 2, name: "February, May, August, November" },
            { month: 3, name: "March, June, September, December" },
          ].map((item) => (
            <div>
              <SelectionGroup onClick={() => setSelectedStartMonth(item.month)}>
                <SelectionIndicator
                  selected={selectedStartMonth === item.month}
                />
                <SelectionLabel selected={selectedStartMonth === item.month}>
                  {item.name}
                </SelectionLabel>
              </SelectionGroup>
            </div>
          ))}
          <PurpleGradientButton
            tw="rounded-full h-10 py-2 px-5 mt-6"
            onClick={handleSave}
            disabled={
              quarterStartMonth === selectedStartMonth || mutationLoading
            }
          >
            Save
          </PurpleGradientButton>
        </Box>
      </div>
    </div>
  )
}

// Get the month (1-indexed) based on the quarter start month.
// The quarter start month can be 1 (Jan, etc.), 2 (Feb, etc.) or 3 (Mar, etc.)
// This function focuses on maintainability over efficiency.
function getNextQuarterStartMonth(quarterStartMonth: number) {
  // Inside this function, we work with zero-indexed months
  const quarterStart = quarterStartMonth - 1 // 1 (Feb)
  const currentMonth = new Date().getMonth() // 7 (June)

  let nextQuarterStartMonth = quarterStart

  // Iteratively add 3 months to quarterStart until it exceeds currentMonth
  while (nextQuarterStartMonth <= currentMonth) {
    nextQuarterStartMonth += 3
  }

  // Wrap around to next year
  nextQuarterStartMonth = nextQuarterStartMonth % 12

  // Return 1-indexed
  return nextQuarterStartMonth + 1
}

const UPDATE_QUARTER_START_MONTH_MUTATION = gql`
  mutation Budgeting_UpdateQuarterStartMonth($startMonth: Int!) {
    organizationUpdateQuarterStartMonth(startMonth: $startMonth) {
      ok
      error
    }
  }
`
