import { gql, useApolloClient, useQuery } from "@apollo/client"
import { useEffect, useState } from "react"

import Activity, {
  BALANCE_ACTIVITY_FRAGMENT,
  YearAndMonth,
} from "../../../balance/components/Activity"
import {
  BUDGETING_MEMBERS_QUERY,
  BUDGETING_MEMBER_DETAIL_QUERY,
} from "../graphql"

import {
  Budgeting_MemberDetailOverviewQuery,
  Budgeting_MemberDetailOverviewQueryVariables,
} from "@/types/graphql-types"

interface Props {
  membershipID: string
}

export default function MemberDetailOverview({ membershipID }: Props) {
  const [yearAndMonth, setYearAndMonth] = useState<YearAndMonth>({
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
  })

  const { data, loading, refetch } = useQuery<
    Budgeting_MemberDetailOverviewQuery,
    Budgeting_MemberDetailOverviewQueryVariables
  >(MEMBER_DETAIL_OVERVIEW_QUERY, {
    variables: {
      id: membershipID,
      year: yearAndMonth.year,
      month: yearAndMonth.month,
    },
  })

  const client = useApolloClient()

  useEffect(() => {
    refetchActivity()
    document.querySelector(".MuiPaper-root")?.scrollTo(0, 0)
  }, [yearAndMonth])

  const refetchActivity = () => {
    refetch({
      year: yearAndMonth.year,
      month: yearAndMonth.month,
    })

    client.query({
      query: BUDGETING_MEMBER_DETAIL_QUERY,
      variables: {
        id: membershipID,
      },
    })

    client.query({
      query: BUDGETING_MEMBERS_QUERY,
    })
  }

  return (
    <div tw="px-12 pb-32">
      <Activity
        yearAndMonth={yearAndMonth}
        setYearAndMonth={setYearAndMonth}
        requestRefetch={refetchActivity}
        loading={loading}
        data={data?.organization?.membership?.balanceAccount?.activity}
        isOwnActivity={false}
      />
    </div>
  )
}

const MEMBER_DETAIL_OVERVIEW_QUERY = gql`
  query Budgeting_MemberDetailOverview($id: ID!, $year: Int!, $month: Int!) {
    organization {
      membership(id: $id) {
        balanceAccount {
          activity(year: $year, month: $month) {
            ...BalanceActivity
          }
        }
      }
    }
  }

  ${BALANCE_ACTIVITY_FRAGMENT}
`
