import { capitalize } from "lodash-es"
import pluralize from "pluralize"

import { PaymentMethod } from "@/common/PaymentMethodsManager"
import { Maybe, PaymentMethodDependencies } from "@/types/graphql-types"

export const bulletsLast4 = (paymentMethod: PaymentMethod) =>
  `•••• ${paymentMethod?.last4}`

export const brandLast4 = (paymentMethod: PaymentMethod) =>
  capitalize(`${paymentMethod?.cardBrand} ${paymentMethod?.last4}`)

export const humanizeDepenencies = (
  dependencies?: PaymentMethodDependencies,
) => {
  const humanized: string[] = []
  const addDependencyIfPresent = (
    format: (count: number) => string,
    dependency?: Maybe<string[]>,
  ) => {
    const count = dependency?.length ?? 0
    if (count > 0) humanized.push(format(count))
  }
  addDependencyIfPresent(
    (c) => `${pluralize("gift", c, true)} awaiting payment`,
    dependencies?.pendingBatchBillingItems,
  )
  addDependencyIfPresent(
    (c) => `${pluralize("gift", c, true)} not yet accepted`,
    dependencies?.unexpiredNotYetAcceptedGifts,
  )
  addDependencyIfPresent(
    (c) => pluralize("autogift rule", c, true),
    dependencies?.activeOrPausedAutogiftRules,
  )
  addDependencyIfPresent(
    (c) => `${pluralize("gift batch", c, true)} using open links`,
    dependencies?.unexpiredSmartLinkGiftBatches,
  )
  addDependencyIfPresent(
    (c) => pluralize("scheduled gift batch", c, true),
    dependencies?.pendingScheduledGiftBatches,
  )
  addDependencyIfPresent(
    () => "Goody for Business Pro subscription",
    dependencies?.activeSubscriptions,
  )
  return humanized
}
