import React, { useContext } from "react"
import tw, { styled } from "twin.macro"

import CategoriesColumn from "./CategoriesColumn"
import StoreContext from "../StoreContext"

interface Props {
  children: React.ReactNode
}

export default function CategorySidebarFrame({ children }: Props) {
  const {
    pushNewUrl,
    generateBrowseUrl,
    selectedCategorySet,
    setSelectedCategorySet,
    isEmbeddedCustomStore,
    selectedSupercategory,
    selectedSubcategory,
    useVirtualUrl,
  } = useContext(StoreContext)

  return (
    <StoreContentContainer>
      <ContentContainerWithCategorySidebar>
        <div tw="flex flex-col lg:flex-row items-start w-full flex-1">
          <CategoriesColumnWrapper tw="bg-white lg:bg-transparent lg:static lg:h-full z-10 lg:max-w-[248px]">
            <CategoriesColumn
              selectedSlug={selectedSupercategory?.slug || null}
              setSelectedCategory={(category) => {
                window.scrollTo(0, 0)
                pushNewUrl(
                  generateBrowseUrl({
                    categorySlug: category?.slug || "all",
                    categoryIsFeatured: false,
                  }),
                )
              }}
              setCustomCategorySelected={() => {
                window.scrollTo(0, 0)
                pushNewUrl(
                  generateBrowseUrl({
                    categoryIsCustom: true,
                  }),
                )
              }}
              selectedCategorySet={selectedCategorySet}
              setSelectedCategorySet={setSelectedCategorySet}
              selectedSubcategorySlug={selectedSubcategory?.slug || null}
              onSubcategoryClick={(subcategory) => {
                if (subcategory) {
                  window.scrollTo(0, 0)
                  pushNewUrl(
                    generateBrowseUrl({
                      categorySlug: subcategory.slug,
                      categoryIsFeatured: false,
                    }),
                  )
                }
              }}
              disabled={false}
              onCategoriesClick={() => {}}
              isEmbeddedCustomStore={isEmbeddedCustomStore}
              useVirtualUrl={useVirtualUrl}
            />
          </CategoriesColumnWrapper>
        </div>
        <div tw="flex-1">{children}</div>
      </ContentContainerWithCategorySidebar>
    </StoreContentContainer>
  )
}

const CategoriesColumnWrapper = tw.div`bg-white md:bg-transparent lg:static z-10 lg:z-0 lg:h-full max-w-full w-full`

const ContentContainerWithCategorySidebar = styled.div`
  ${tw`relative flex flex-col lg:grid items-stretch`}
  grid-template-columns: 15.5rem 1fr;
`

const StoreContentContainer = styled.div`
  ${tw`container mx-auto sm:max-w-full md:max-w-full bg-white md:bg-transparent`};

  @media only screen and (max-width: 639px) {
    max-width: 500px;
  }
`
