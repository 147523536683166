import React from "react"
import tw, { styled } from "twin.macro"

export const ModalContainer = styled.div`
  h3 {
    ${tw`text-primary-500`}
    font-size: 24px;
    margin-bottom: 15px;
  }

  p {
    ${tw`pt-2 text-gray-700 `}
    margin-bottom: 5px
  }
  ul {
    padding-left: 20px;
  }
  li {
    ${tw`pt-2 text-gray-500 `}
    list-style-type: disc;
  }
  td {
    padding-bottom: 20px;
  }
  svg {
    display: inline-block;
    margin-right: 15px;
  }
`

export const Exclamation = () => (
  <svg
    width="18"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginLeft: 4 }}
  >
    <path
      d="M22 42C33.0457 42 42 33.0457 42 22C42 10.9543 33.0457 2 22 2C10.9543 2 2 10.9543 2 22C2 33.0457 10.9543 42 22 42Z"
      stroke="#BDBDBD"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22 12L22 24"
      stroke="#BDBDBD"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22 32H22.02"
      stroke="#BDBDBD"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const Check = () => (
  <svg
    tw="ml-1"
    width="16"
    height="12"
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.6673 1L5.50065 10.1667L1.33398 6"
      stroke="#27AE60"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
