import React, { useState } from "react"
import Modal from "react-modal"
import { Link } from "react-router-dom"
import tw, { styled } from "twin.macro"

import Button from "./Button"
import { useGlobalState } from "./GlobalState"
import businessWelcomeTexture from "./images/business-welcome-texture.png"
import { ReactComponent as GiftGradient } from "./images/gift-gradient.svg"
import { modalStyle } from "./modal"
import { generateRealmPath } from "./realm"
import { ReactComponent as ArrowRight } from "../assets/icons/arrow-right.svg"
import { ReactComponent as XIcon } from "../assets/icons/x.svg"
import { BusinessVideoModal } from "../landing/common/HowItWorksVideoCTA"

const BusinessWelcomeModal = (): JSX.Element => {
  const [isVideoOpen, setIsVideoOpen] = useState(false)
  const [isOpen, setIsOpen] = useGlobalState("isBusinessWelcomeModalOpen")

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        closeTimeoutMS={500}
        shouldCloseOnOverlayClick
        style={{
          ...modalStyle,
          content: { ...modalStyle.content, maxWidth: 841, width: "100%" },
        }}
        shouldCloseOnEsc
      >
        <Container className="modal-content-no-width">
          <Left>
            <div tw="p-8 pt-0 md:p-12 md:pt-12">
              <TitleGroup>
                <Title tw="text-primary-new-400">Welcome to</Title>
                <Title tw="text-primary-new-550">Goody for Business</Title>
              </TitleGroup>
              <div tw="mb-4">You’re all set to start gifting right away.</div>
              <div tw="font-medium"></div>
              <div>
                <span tw="font-medium">
                  We’ve dropped a $20 credit in your account,
                </span>{" "}
                so you can send your first gift, on us.
              </div>
              <ButtonGroup>
                <Link
                  onClick={() => setIsOpen(false)}
                  to={generateRealmPath("plus", "/editorial/affordable-gifts")}
                  tw="flex text-base leading-5 bg-gradient-to-r from-gradient-purple-light to-gradient-purple-dark px-6 py-3.5 text-white font-medium rounded-lg hover:opacity-80 active:opacity-90 transition-opacity"
                >
                  Choose a $20 gift
                  <ArrowRight tw="ml-3.5 relative top-0.5 transform scale-90" />
                </Link>
                <Button
                  onClick={() => setIsVideoOpen(true)}
                  tw="text-base leading-5 border border-primary-alternate-500 hover:border-primary-alternate-300 active:border-primary-alternate-400 px-6 py-3.5 text-primary-alternate-500 hover:text-primary-alternate-300 active:text-primary-alternate-400 font-medium rounded-lg transition-colors"
                >
                  How it works
                </Button>
              </ButtonGroup>
            </div>
          </Left>
          <Right>
            <LogoContainer>
              <GiftGradient />
            </LogoContainer>
            <XIconContainer onClick={() => setIsOpen(false)}>
              <StyledXIcon />
            </XIconContainer>
          </Right>
        </Container>
      </Modal>
      <BusinessVideoModal
        autoplay={true}
        isOpen={isVideoOpen}
        setIsOpen={setIsVideoOpen}
      />
    </>
  )
}

const XIconContainer = tw.div`absolute cursor-pointer p-2.5 right-8 top-8`

const StyledXIcon = styled(XIcon)`
  path {
    stroke-width: 2;
  }
`

const ButtonGroup = styled.div`
  ${tw`flex flex-col md:flex-row mt-6 md:mt-[4.5rem] gap-4 items-start`};
`

const Left = styled.div`
  flex: 3;
`

const LogoContainer = styled.div`
  ${tw`py-8 px-7 scale-50 md:scale-100`};
  background: linear-gradient(
    180deg,
    #ffffff 0%,
    rgba(255, 255, 255, 0.51) 100%
  );
  box-shadow:
    0 8px 32px rgba(35, 0, 108, 0.06),
    inset 0px -8px 16px rgba(47, 0, 146, 0.02),
    inset 0px -2px 4px rgba(33, 0, 103, 0.02);
  border-radius: 24px;
`

const Right = styled.div`
  ${tw`flex justify-center items-center bg-cover relative`};
  background-image: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      #ffffff 100%
    ),
    linear-gradient(270deg, rgba(255, 255, 255, 0) 65.67%, #ffffff 146.42%),
    url("${businessWelcomeTexture}");
  flex: 2;
`

const TitleGroup = tw.div`mb-4 md:mb-12`

const Title = styled.div`
  ${tw`font-medium text-xl md:text-[2rem] leading-[1.5rem] md:leading-[39px]`};
`

const Container = styled.div`
  ${tw`rounded-2xl bg-white flex overflow-hidden text-gray-700 text-lg md:text-xl w-full max-w-[calc(100vw - 2rem)] mx-auto md:max-w-none flex-col-reverse md:flex-row`};
  box-shadow: 0 12px 48px rgba(0, 0, 0, 0.1);
`

export default BusinessWelcomeModal
