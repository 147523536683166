import CurrencyFormat from "react-currency-format"
import tw from "twin.macro"

interface Props {
  value: string
  onChange: (value: string) => void
  autoFocus?: boolean
  inputCss?: any
}

export default function MoneyInput({
  value,
  onChange,
  autoFocus,
  inputCss,
}: Props) {
  return (
    <MoneyInputContainer>
      <MoneyInputField
        value={value}
        onChange={(e: any) => {
          onChange(e.target.value)
        }}
        thousandSeparator=","
        decimalScale={2}
        allowNegative={false}
        autoFocus={autoFocus}
        css={inputCss || {}}
      />
    </MoneyInputContainer>
  )
}

const MoneyInputContainer = tw.div`relative before:(z-10 absolute flex flex-col justify-center w-6 text-2xl text-gray-450 pointer-events-none content-['$'] left-5 top-0 bottom-0)`

const MoneyInputField = tw(
  CurrencyFormat,
)`border border-gray-150 rounded-lg shadow-min p-5 pl-9 w-[10rem] focus:outline-none focus:border-primary-new-500 text-2xl text-right transition-colors tabular-nums`
