import React from "react"
import tw, { styled } from "twin.macro"

import { ReactComponent as BirthdayIconActive } from "../../assets/icons/autogift-birthday-active.svg"
import { ReactComponent as BirthdayIconDefault } from "../../assets/icons/autogift-birthday-default.svg"
import { ReactComponent as OnboardingIconActive } from "../../assets/icons/autogift-onboarding-active.svg"
import { ReactComponent as OnboardingIconDefault } from "../../assets/icons/autogift-onboarding-default.svg"
import { ReactComponent as WorkAnniversaryIconActive } from "../../assets/icons/autogift-work-anniversary-active.svg"
import { ReactComponent as WorkAnniversaryIconDefault } from "../../assets/icons/autogift-work-anniversary-default.svg"
import { ReactComponent as ChevronIcon } from "../../assets/icons/chevron-right-gray.svg"

import {
  AutogiftRuleProduct,
  StandardAutogiftRuleFragment,
} from "@/types/graphql-types"

interface AutogiftSelectButtonProps {
  onClick: () => void
  autogiftRule: StandardAutogiftRuleFragment | null
  defaultType: string

  // Custom string to display instead of the regular subtitle
  overrideActiveAdditionalText?: string
}

const AutogiftSelectButton = ({
  onClick,
  autogiftRule,
  defaultType,
  overrideActiveAdditionalText,
}: AutogiftSelectButtonProps) => {
  const getAutogiftType = (
    autogiftRule: StandardAutogiftRuleFragment | null,
    defaultType: string,
  ) => {
    if (autogiftRule?.eventType) {
      return autogiftRule?.eventType
    } else {
      return defaultType
    }
  }

  const title = (autogiftRule: StandardAutogiftRuleFragment | null): string => {
    const autogiftType = getAutogiftType(autogiftRule, defaultType)
    return autogiftType === "birthday"
      ? "Birthdays"
      : autogiftType === "work_anniversary"
      ? "Work Anniversaries"
      : "Onboarding"
  }

  const pausedAutogiftRuleText = (
    productList: AutogiftRuleProduct[] | null | undefined,
  ): string => {
    if (productList && productList.length > 0) {
      return "Inactive"
    } else {
      return "Start autogifting"
    }
  }

  const additionalGiftCountText = (
    productList?: AutogiftRuleProduct[] | null,
  ): string => {
    if (productList && productList.length > 2) {
      return "and " + (productList.length - 1) + " more gifts"
    } else if (productList && productList.length === 2) {
      return "and 1 more gift"
    } else {
      return ""
    }
  }

  return (
    <button
      tw="w-full flex flex-row items-center border border-gray-200 hover:border-primary-300 focus-visible:border-primary-300 focus:outline-none active:bg-primary-000 transition-colors rounded-xl py-4 pl-4 pr-6"
      onClick={onClick}
    >
      {autogiftRule?.status === "ACTIVE" ? (
        <ActiveIconContainer>
          {getAutogiftType(autogiftRule, defaultType) === "birthday" ? (
            <BirthdayIconActive />
          ) : getAutogiftType(autogiftRule, defaultType) ===
            "work_anniversary" ? (
            <WorkAnniversaryIconActive />
          ) : (
            <OnboardingIconActive />
          )}
        </ActiveIconContainer>
      ) : (
        <PausedIconContainer>
          {getAutogiftType(autogiftRule, defaultType) === "birthday" ? (
            <BirthdayIconDefault
              tw="stroke-current"
              css={{ color: "#9CA3AF" }}
            />
          ) : getAutogiftType(autogiftRule, defaultType) ===
            "work_anniversary" ? (
            <WorkAnniversaryIconDefault />
          ) : (
            <OnboardingIconDefault />
          )}
        </PausedIconContainer>
      )}
      <div tw="flex justify-center items-center">
        <div>
          <div tw="flex text-xl mb-1 leading-7 justify-start">
            {title(autogiftRule)}
          </div>
          <div tw="flex flex-row">
            {autogiftRule?.status === "ACTIVE" ? (
              <ActiveButtonContainer>
                <ActiveTextGradient>Active</ActiveTextGradient>
              </ActiveButtonContainer>
            ) : (
              <div>{pausedAutogiftRuleText(autogiftRule?.productList)}</div>
            )}
            {autogiftRule?.status === "ACTIVE" && (
              <div tw="flex items-center text-sm text-gray-400">
                {overrideActiveAdditionalText ? (
                  overrideActiveAdditionalText
                ) : (
                  <>
                    {autogiftRule?.firstGiftSummary}{" "}
                    {additionalGiftCountText(autogiftRule?.productList)}
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div tw="flex justify-center items-center ml-auto">
        <ChevronIcon />
      </div>
    </button>
  )
}

const ActiveIconContainer = styled.div`
  background: linear-gradient(
      68.43deg,
      rgba(255, 177, 195, 0.1) -92.27%,
      rgba(122, 100, 255, 0.1) 244.43%
    ),
    rgba(249, 250, 251, 0.1);
  padding: 19px;
  ${tw`flex justify-center items-center rounded-md mr-5`}
`

const PausedIconContainer = styled.div`
  background: #f9fafb;
  padding: 19px;
  ${tw`flex justify-center items-center rounded-md mr-5`}
`

const ActiveButtonContainer = styled.div`
  background: linear-gradient(
      68.43deg,
      rgba(255, 177, 195, 0.1) -92.27%,
      rgba(122, 100, 255, 0.1) 244.43%
    ),
    rgba(249, 250, 251, 0.1);
  padding: 3px 10px 2px 10px;
  ${tw`flex justify-center items-center rounded-md text-sm mr-3`}
`

const ActiveTextGradient = styled.div`
  background-color: rgba(122, 100, 255, 1);
  background: linear-gradient(
      68.43deg,
      rgba(255, 177, 195, 1) -92.27%,
      rgba(122, 100, 255, 1) 244.43%
    ),
    rgba(249, 250, 251, 1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 135%;
  ${tw`text-sm`};
`

export default AutogiftSelectButton
