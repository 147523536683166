import React from "react"

import { useSegmentAvailable } from "@/common/hooks/segmentAvailable"
import { BusinessSegment } from "@/types/graphql-types"

type Props = {
  segments: BusinessSegment[]
} & React.ComponentProps<"div">

export const SegmentUnavailable = ({ segments, ...restProps }: Props) => {
  const { isAvailable, segmentText } = useSegmentAvailable(segments)

  if (isAvailable) {
    return null
  }

  return (
    <div
      tw={
        "px-5 h-[60px] text-[#B42727] bg-[#FEE6E6] border-[#B42727] border rounded-lg font-semibold flex items-center"
      }
      {...restProps}
    >
      Sorry, this product isn’t available for {segmentText} gifting.
    </div>
  )
}
