import pluralize from "pluralize"
import React, { useEffect, useMemo, useReducer } from "react"

import VariantButton from "./VariantButton"

import { Details_ProductFragment } from "@/types/graphql-types"

// This is a direct port of VariantGroups from the backend (used in acceptance).
// There are no major internal changes.
//
// The most important change is that this component will set setVariant as long
// as at least one variant group has a variant group option selected. This allows
// the caller to deselect the "Let recipient choose" option when a user starts
// choosing variant group options. This does mean that an incomplete variant
// group option will be passed the caller in the beginning, but the caller
// should validate variants before adding them to cart.
//
// This component also uses VariantButton instead of VariantItem for
// the presentation component, which is shared with the other interactive
// variant types.
//
// In addition, the variant group option selections will also be cleared when
// `variants` is set to null, indicating that the user selected the "Let
// recipient choose" option.

interface Props {
  selections?: string[]
  data: NonNullable<Details_ProductFragment["variantGroups"]>
  variants: Details_ProductFragment["variants"]
  setVariant: (variantName: string) => void
  disabled?: boolean
  selectedVariants: string[] | null
  imagesScalable?: boolean
  selectable?: boolean
}

const VariantGroups = ({
  selections,
  data,
  variants,
  setVariant,
  disabled,
  selectedVariants,
  imagesScalable,
  selectable = true,
}: Props) => {
  const getSharedName = (
    variantGroupOptionName1: string,
    variantGroupOptionName2: string,
  ) => [variantGroupOptionName1, variantGroupOptionName2].sort().join("-") // Sort maintains consistency of keys

  // Example Data:
  // variantOptionNames: ['Grey', 'M', 'Slim']
  // variantGroupIndexes: [0, 1]
  // variantStockMap: { 'Color-Grey-Size-M': ['Fit-Broad'] }. This is data from past calculations
  // variantOptionNameWithVariantGroupName: 'Color-Grey-Size-M'

  // The new data would be ['Fit-Slim']. However old data for this stockKey is also included in this generated set.
  // So the final result would be ['Fit-Broad', 'Fit-Slim']
  // Which will be set by parent function on the variantStockMap as {variantOptionNameWithVariantGroupName: [Compatible Variant Options] }.
  // More specifically with our example data {'Color-Gray-Size-M': ['Fit-Broad', 'Fit-Slim']}
  const getCompatibleVariants = (
    variantOptionNames: string[],
    variantGroupIndexes: any, // These are the indexes of all the variant groups which exist within the current combination
    variantStockMap: { [id: string]: string[] },
    variantOptionNameWithVariantGroupName: string, // Key that will be added to the stock map. Represents a combination of variant options
  ) =>
    new Set([
      // Compatible variant options including itself
      ...variantOptionNames.map((name, variantGroupIndex) =>
        getVariantNameWithGroupName(name, data[variantGroupIndex].name),
      ),

      ...variantGroupIndexes.reduce(
        // Go through variant groups that exist within this combination
        (variantOptions: any, variantGroupIndex: any) => [
          ...variantOptions,
          ...data[variantGroupIndex].variantGroupOptions!.map(({ name }) =>
            getVariantNameWithGroupName(name, data[variantGroupIndex].name),
          ), // A variant option is compatible with all other variant options of its variant group
        ],
        [],
      ),
      // Prior compatible variant options for this combination of variant options
      ...(variantStockMap[variantOptionNameWithVariantGroupName] ?? []),
    ])

  // Here we recursively generate all possible valid combinations of variant options from a variant.
  // So a variant such as 'Grey / M / Broad' would result in items added to the variantStockMap as follows:
  // { 'Color-Grey': [{All variant options within the color variant group}, 'Size-M', 'Fit-Broad'] }  depth = 1.
  // { 'Size-M': [{All variant options within the size variant group}, 'Color-Grey', 'Fit-Broad'] } depth = 1
  // { 'Fit-Broad': [{All variant options within fit variant group}, 'Color-Grey', 'Size-M' ] } depth = 1
  // { 'Color-Grey-Size-M': [{All variant options within the color and size variant group}, 'Fit-Broad']  } depth = 2
  // { 'Color-Grey-Fit-Broad': [{All variant options within the color and fit variant group'}, Size-M'] } depth = 2
  // { 'Fit-Broad-Size-M': [{All variant options within the fit and size variant group}, 'Color-Grey']} depth = 2
  const generateInStock = (
    variantOptionNames: any,
    variantStockMap: any,
    initialVariantOptionSet: any,
    priorVariantOptionKey = "",
    priorVariantGroupIndexes: any = [],
  ) => {
    variantOptionNames.forEach(
      (variantOptionName: any, variantGroupIndex: any) => {
        const variantOptionNameWithVariantGroupName =
          getVariantNameWithGroupName(
            variantOptionName,
            data[variantGroupIndex].name,
          )

        if (
          priorVariantOptionKey.includes(variantOptionNameWithVariantGroupName)
        ) {
          return
        }

        // Use the original variant option key for the first run, but shared name for further recursion
        const variantInStockKey = priorVariantOptionKey
          ? getSharedName(
              priorVariantOptionKey,
              variantOptionNameWithVariantGroupName,
            )
          : variantOptionNameWithVariantGroupName

        // Add to initial selectable variant options only on the first call
        // We only want raw variant options here not combinations
        // So initialVariantOptionSet will contain things like 'Color-Grey' but never combinations from recursion such as 'Color-Gray-Size-L'
        if (!priorVariantOptionKey) {
          initialVariantOptionSet.add(variantInStockKey)
        }

        const variantGroupIndexes = [
          ...priorVariantGroupIndexes,
          variantGroupIndex,
        ]

        // Add compatible variants to current variant option
        variantStockMap[variantInStockKey] = getCompatibleVariants(
          variantOptionNames,
          variantGroupIndexes,
          variantStockMap,
          variantInStockKey,
        )

        // Continue if there is more than 1 variant option left to be included as an in stock key
        if (variantOptionNames.length - 1 > variantGroupIndexes.length) {
          generateInStock(
            variantOptionNames,
            variantStockMap,
            initialVariantOptionSet,
            variantInStockKey,
            variantGroupIndexes,
          )
        }
      },
    )
  }

  /*
  The overall in stock strategy is to create an initial configuration of variant options mapped to compatible variant options.
  More details on this calculation and what variant options are considered compatible can be found below where variantInStockMap and initialVariantOptionSet are defined.

  After we have defined variantInStockMap and initialVariantOptionSet we then generate the variantOptionsInStock set by taking the intersection of initialVariantOptionSet and compatible variant options for selected variant options.

  variantOptionsInStock will then represent the intersection of compatible variant options based on selected variants.

 */

  /*
    Create map of variants options to their compatible variant options
    Example:
      variants = ['Grey / M / Slim', 'Blue / S / Broad']

      returns { 'Color-Grey': Set([
                                  'Color-Grey', // The Color-Grey variant option is compatible with itself
                                   'Color-Blue', // The Color-Grey variant option is compatible with all variants in its variant group
                                   'Size-M', // The Color-Grey variant only exists in medium
                                   'Fit-Slim' // The Color-Grey variant only exists in a slim fit
                                   ]),
                'Color-Blue': ...,
                'Color-Grey-Size-M': Set(['Color-Grey', 'Size-M', 'Fit-Slim'])
                }
   */
  const { variantInStockMap, initialVariantOptionSet } = useMemo(() => {
    const initialVariantOptionSet = new Set()
    return {
      variantInStockMap: variants.reduce((variantStockMap: object, variant) => {
        const variantOptionNames = variant.name.split(" / ") // Ex: 'Grey / M / Slim' => ['Grey', 'M', 'Slim']

        generateInStock(
          variantOptionNames,
          variantStockMap,
          initialVariantOptionSet, // `${variantGroupName}-${variantOptionName}` is added for every variant option available in the variants. This lets us know what is always out of stock
        ) // Recursively add keys and compatible variant options to variantStockMap

        return variantStockMap
      }, {}) as any,
      initialVariantOptionSet: initialVariantOptionSet,
    }
  }, [variants])

  const handleClick = (
    variantGroupSelections: any,
    giftOptionSelectionAction: any,
  ) => {
    return {
      ...variantGroupSelections,
      [giftOptionSelectionAction.variantGroupName]:
        giftOptionSelectionAction.variantGroupOptionName,
    }
  }

  const defaultVariantGroupSelection = useMemo(() => {
    if (!selections) {
      return []
    }

    if (selections[0]) {
      return selections[0]
        .split(" / ")
        .reduce(
          (
            variantGroupSelections: any,
            variantOptionName,
            variantGroupIndex,
          ) => {
            variantGroupSelections[data[variantGroupIndex].name] =
              variantOptionName
            return variantGroupSelections
          },
          {},
        )
    } else {
      return data.reduce((variantGroupSelections: any, variantGroup) => {
        variantGroupSelections[variantGroup.name] = ""
        return variantGroupSelections
      }, {})
    }
  }, [variants, selections, data])

  const [variantGroupSelections, dispatch] = useReducer(
    handleClick,
    defaultVariantGroupSelection,
  )

  useEffect(() => {
    const variantGroupValues = data.map(
      (variantGroup) => variantGroupSelections[variantGroup.name],
    )

    // Set variant only if there is at least one non- lank, null, or undefined value
    const filteredVariantGroupValues = variantGroupValues.filter(
      (item) => item !== "" && item !== null && item !== undefined,
    )

    if (filteredVariantGroupValues.length > 0) {
      // If all variant groups have a variant group option selected we can combine to submit the final variant
      setVariant(variantGroupValues.join(" / "))
    }
  }, [variantGroupSelections])

  const addToCompatible = (
    variantOptionsCompatibleWithSelected: any,
    inStockKey: any,
  ) => variantOptionsCompatibleWithSelected.splice(0, 0, inStockKey)

  const addAllCompatible = (
    variantOptionsCompatibleWithSelected: any,
    selectedVariantKeys: any,
    priorStockKey = "",
  ) => {
    // A shared name is a combination of two variant groups and options. Ex: Color-Gray-Size-M
    // If shared names exists within the variantInStockMap get variant options compatible with the shared name
    // Shared names only exist when combinations of variant groups are larger than 2
    selectedVariantKeys.forEach((variantGroupName: any, index: number) => {
      const variantGroupNameWithOptionName = getVariantNameWithGroupName(
        variantGroupSelections[variantGroupName],
        variantGroupName,
      )

      // Do not repeat variant options
      if (priorStockKey.includes(variantGroupNameWithOptionName)) {
        return
      }

      const inStockKey = priorStockKey
        ? getSharedName(priorStockKey, variantGroupNameWithOptionName)
        : variantGroupNameWithOptionName

      // Set of variant options compatible with the current variant option selection
      if (inStockKey in variantInStockMap) {
        addToCompatible(
          variantOptionsCompatibleWithSelected,
          variantInStockMap[inStockKey],
        )
      }

      // Continue if we have more variant option combinations to intersect with
      if (selectedVariantKeys.length >= 2) {
        addAllCompatible(
          variantOptionsCompatibleWithSelected,
          selectedVariantKeys.slice(index),
          inStockKey,
        )
      }
    })
  }

  /*
    Collect compatibility sets for each selected option
    Example:
      variantGroupSelections = { Fit: 'Slim',
                                 Color: 'Blue',
                                 Size: ''
                                }
      variantInStockMap = { 'Fit-Slim': [...compatible variant option names for Fit-Slim]
                            'Color-Blue': [...compatible variant option names Color-Blue]
                            ...
                           }

      returns [
          [...compatible variant option names for Fit-Slim],
          [...compatible variant option names Color-Blue]
        ]
    */
  const variantOptionsInStock = useMemo(() => {
    const selectedVariantKeys = Object.keys(variantGroupSelections).filter(
      (variantGroupName) => variantGroupSelections[variantGroupName],
    )

    const variantOptionsCompatibleWithSelected: string[][] = []

    addAllCompatible(variantOptionsCompatibleWithSelected, selectedVariantKeys)

    // Intersect initialVariantOptionSet with each compatible selected variant options set
    // @ts-ignore
    return variantOptionsCompatibleWithSelected.reduce(
      (
        compatibleVariantOptions: Set<string>,
        compatibleVariantOptionFromSelected: string[],
      ) => {
        return new Set(
          // @ts-ignore
          [...compatibleVariantOptions].filter((variantOptionName) =>
            // @ts-ignore
            compatibleVariantOptionFromSelected.has(variantOptionName),
          ),
        )
      },
      initialVariantOptionSet,
    )
  }, [variantInStockMap, initialVariantOptionSet, variantGroupSelections])

  useEffect(() => {
    if (selectedVariants === null) {
      // clear selected variant options
      Object.keys(variantGroupSelections).forEach((variantGroupName) => {
        dispatch({
          variantGroupName: variantGroupName,
          variantGroupOptionName: null,
        })
      })
    }
  }, [selectedVariants])

  return (
    <div tw="text-center pt-4 grid grid-cols-1 md:grid-cols-[min-content 1fr] gap-3 gap-x-5">
      {data.map((variantGroup) => {
        return (
          <React.Fragment key={variantGroup.name}>
            <div tw="whitespace-nowrap font-medium text-left pt-2.5 text-gray-400">
              {selectable ? variantGroup.name : pluralize(variantGroup.name)}
            </div>

            <div tw="flex flex-row flex-wrap justify-start gap-2 relative">
              {variantGroup.variantGroupOptions?.map((variantGroupOption) => {
                // @ts-ignore
                const softDisabled = !variantOptionsInStock.has(
                  getVariantNameWithGroupName(
                    variantGroupOption.name,
                    variantGroup.name,
                  ),
                )

                const hardDisabled =
                  disabled ||
                  !initialVariantOptionSet.has(
                    getVariantNameWithGroupName(
                      variantGroupOption.name,
                      variantGroup.name,
                    ),
                  )

                return (
                  <VariantButton
                    key={variantGroupOption.name}
                    variant={variantGroupOption}
                    // isSelected={variant.name === selectedVariant}
                    // onClick={() => {
                    //   setSelectedVariant(variant.name);
                    // }}
                    count={
                      variantGroupSelections[variantGroup.name] ===
                      variantGroupOption.name
                        ? 1
                        : 0
                    }
                    onClick={(e) => {
                      // Prevent repeated taps on this button from triggering zoom.
                      // A CSS solution for preventing double-tap to zoom doesn't appear
                      // to be working, so this will have to do for now.
                      e.preventDefault()

                      if (softDisabled || hardDisabled) {
                        // Deselect other variant groups
                        Object.keys(variantGroupSelections).forEach(
                          (variantGroupName) => {
                            dispatch({
                              variantGroupName: variantGroupName,
                              variantGroupOptionName: "",
                            })
                          },
                        )
                        // Select if any possible variant with clicked variant option exists
                        if (
                          initialVariantOptionSet.has(
                            getVariantNameWithGroupName(
                              variantGroupOption.name,
                              variantGroup.name,
                            ),
                          )
                        ) {
                          dispatch({
                            variantGroupName: variantGroup.name,
                            variantGroupOptionName: variantGroupOption.name,
                          })
                        }
                      } else {
                        dispatch({
                          variantGroupName: variantGroup.name,
                          variantGroupOptionName: variantGroupOption.name,
                        })
                      }

                      e.stopPropagation()
                    }}
                    softDisabled={softDisabled}
                    hardDisabled={hardDisabled}
                    selectable={selectable}
                    isMultiple={false}
                    imagesScalable={imagesScalable}
                  />
                )
              })}
            </div>
          </React.Fragment>
        )
      })}
    </div>
  )
}

const getVariantNameWithGroupName = (
  variantOptionName: string,
  variantGroupName: string,
) => `${variantGroupName}-${variantOptionName}`

export default VariantGroups
