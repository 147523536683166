import React from "react"

import { formatPrice } from "../../../common/format"
import { useGlobalState } from "../../../common/GlobalState"
import { ReactComponent as AvatarIcon } from "../../../common/images/avatar.svg"

export default function UserDropdownTrigger() {
  const [user] = useGlobalState("user")

  if (!user) {
    return null
  }

  return (
    <div tw="flex flex-row text-gray-450 items-center gap-2.5 bg-white p-1.5 pl-4 border border-gray-150 rounded-full shadow-slight transition-all hover:(-translate-y-px shadow-min-dark text-gray-600) active:(translate-y-0 bg-gray-050)">
      <div tw="font-medium text-gray-400 text-[15px] pr-0.5">Balance</div>
      <div tw="font-medium text-lg leading-[1.4] h-[24px]">
        {formatPrice(user.balance!, true)}
      </div>
      <AvatarIcon width={24} height={24} tw="stroke-0" />
    </div>
  )
}
