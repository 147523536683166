import { useParams } from "react-router-dom-v5-compat"

import Editorial from "./Editorial"

import CategorySidebarFrame from "@/store/categories/CategorySidebarFrame"

export default function EditorialScreen() {
  const { slug } = useParams<{ slug: string }>()

  if (!slug) {
    return null
  }

  return (
    <CategorySidebarFrame>
      <Editorial slug={slug} />
    </CategorySidebarFrame>
  )
}
