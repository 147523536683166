import tw, { styled } from "twin.macro"

import InternationalShippingCountryList from "./InternationalShippingCountryList"
import { ReactComponent as CheckCircleIcon } from "../../icons/check-circle.svg"
import { ReactComponent as SwapIcon } from "../../icons/swap.svg"

import { GLOBAL_COUNTRY } from "@/store/providers/ShippingCountriesProvider"
import { InternationalShippingTierEnum } from "@/types/graphql-types"

interface Props {
  shipsToCountries: string[]
  swapsToCountries: string[]
  internationalShippingTier: InternationalShippingTierEnum
  onGlobalInfoClick?: () => void
}

const InternationalShippingCountrySummary = ({
  shipsToCountries,
  swapsToCountries,
  internationalShippingTier,
  onGlobalInfoClick,
}: Props) => {
  const swapText = () => {
    switch (internationalShippingTier) {
      case InternationalShippingTierEnum.standard:
        return "Your gift doesn’t ship to these countries domestically, but recipients can swap for a gift that does at no additional cost."
      case InternationalShippingTierEnum.full:
        return "Your gift doesn’t ship to these countries, but recipients can swap for a gift that does."
    }
  }

  if (
    internationalShippingTier === InternationalShippingTierEnum.disabled ||
    shipsToCountries.length === 0
  ) {
    return null
  }

  return (
    <div>
      <Container>
        <div tw="p-5">
          <TextHeader tw="text-[#219653] pb-4">
            <div>
              <CheckCircleIcon tw="w-5 h-5" />
            </div>
            <Text>Your gift ships to</Text>
          </TextHeader>

          <InternationalShippingCountryList
            countries={shipsToCountries}
            onGlobalInfoClick={onGlobalInfoClick}
          />
        </div>

        {swapsToCountries.length > 0 && (
          <>
            <div tw="h-px bg-gray-200" />
            <div tw="p-5">
              <TextHeader tw="text-gray-600">
                <div>
                  <SwapIcon tw="w-5 h-5" />
                </div>
                <Text>
                  Swap available
                  {!swapsToCountries.includes(GLOBAL_COUNTRY.code) && (
                    <span tw="text-gray-450"> — no charge</span>
                  )}
                </Text>
              </TextHeader>
              <div tw="text-gray-700 text-sm font-normal py-3">
                {swapText()}
              </div>

              <InternationalShippingCountryList
                swap
                countries={swapsToCountries}
                onGlobalInfoClick={onGlobalInfoClick}
              />
            </div>
          </>
        )}
      </Container>
    </div>
  )
}

const Container = styled.div`
  ${tw`bg-white rounded-xl border border-gray-200 w-[280px]`}

  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.02), 0px 6px 16px 0px rgba(0, 0, 0, 0.04);
`

const TextHeader = tw.div`flex gap-4 items-center`

const Text = tw.p`text-base font-medium`

export default InternationalShippingCountrySummary
