import React from "react"

import GiftSelectButton from "./GiftSelectButton"
import { generateRealmPath } from "../../common/realm"
import BrowseIcon from "../images/gift-browse.svg"

import { track } from "@/common/analytics"

const BrowseStoreGiftSelectButton = () => (
  <GiftSelectButton
    title="Browse the store"
    subtitle="Explore curated gifts"
    icon={BrowseIcon}
    iconName="browse"
    to={generateRealmPath("plus", "/browse")}
    onClick={() => track("Business - Send - Start Gift - Click Browse")}
  />
)

export default BrowseStoreGiftSelectButton
