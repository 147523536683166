import React from "react"

import { ReactComponent as QuestionMark } from "../../assets/icons/question-mark.svg"
import Tooltip from "../Tooltip"

export const SwapTooltip = () => {
  return (
    <Tooltip
      trigger={
        <span>
          <QuestionMark tw="ml-1 inline-block -mt-0.5" />
        </span>
      }
      title="Gift swaps"
      placement="auto"
    >
      <p>
        <span tw="font-medium">
          Ensures recipients get something they really want.
        </span>{" "}
        Recipients can swap your gift for equal or lower-priced options
        available in their region (with shipping and tax included).
      </p>
      <p>
        If you <span tw="font-medium">hide prices</span>, recipients can swap
        for <span tw="font-medium">one</span> item. If you{" "}
        <span tw="font-medium">show prices</span>, they can swap for{" "}
        <span tw="font-medium">multiple</span> items up to the total price.
      </p>
      <p>Any difference in price is returned to you as account balance.</p>
    </Tooltip>
  )
}
