import React, { Dispatch, SetStateAction } from "react"
import InlineSVG from "react-inlinesvg"
import tw, { styled } from "twin.macro"

import BackToTopButton from "./BackToTopButton"
import CategoryLink from "./CategoryLink"
import SubcategoriesList from "./SubcategoriesList"
import SubcategoriesMobileList from "./SubcategoriesMobileList"

import { useGlobalState } from "@/common/GlobalState"
import { generateRealmPath } from "@/common/realm"
import CategorySetToggle, {
  CategorySet,
} from "@/store/categories/CategorySetToggle"
import { CustomCategoryItem } from "@/store/custom/CustomCategoryItem"
import useCategories from "@/store/hooks/useCategories"
import useStoreContext from "@/store/hooks/useStoreContext"
import { useBrowseUrls } from "@/store/StoreHelpers"
import {
  Store_CategoryCommonFragment,
  Store_CategoryFragment,
} from "@/types/graphql-types"

interface Props {
  className?: string
  selectedSlug: string | null
  setSelectedCategory: (category: Store_CategoryFragment) => void
  setCustomCategorySelected: () => void
  disabled: boolean
  onCategoriesClick: () => void
  selectedCategorySet: CategorySet
  setSelectedCategorySet: Dispatch<SetStateAction<CategorySet>>
  selectedSubcategorySlug: string | null
  onSubcategoryClick: (subcategory: Store_CategoryCommonFragment | null) => void
  isEmbeddedCustomStore: boolean
  useVirtualUrl?: boolean
}

const CategoriesColumn = ({
  className,
  disabled: isDisabled,
  selectedSlug,
  onCategoriesClick,
  setSelectedCategory,
  setCustomCategorySelected,
  selectedCategorySet,
  setSelectedCategorySet,
  selectedSubcategorySlug,
  onSubcategoryClick,
  isEmbeddedCustomStore,
  useVirtualUrl,
}: Props) => {
  const setSelectedName = useGlobalState("selectedCategoryName")[1]
  const [isCustomCategorySelected, setIsCustomCategorySelected] =
    useGlobalState("isCustomCategorySelected")
  const [currentRealm] = useGlobalState("currentRealm")

  const { screen, pushNewUrl } = useStoreContext()
  const isCustomCategorySelectedEffective =
    isCustomCategorySelected || screen === "custom"

  const { generateBrowseUrl } = useBrowseUrls()
  const {
    allCategory,
    allCategoryIcon,
    categoriesWithoutAll,
    selectedCategory,
  } = useCategories({
    selectedCategorySet,
    selectedCategorySlug: selectedSlug,
    isEmbeddedCustomStore,
  })

  return (
    <CategoriesColumnContainer className={className} tw="h-auto">
      <CategorySetToggle
        selectedCategorySet={selectedCategorySet}
        setSelectedCategorySet={setSelectedCategorySet}
      />
      <CategoryLinksContainer
        onClick={onCategoriesClick}
        className={[
          isDisabled ? "disabled" : "",
          isEmbeddedCustomStore ? "is-custom-store-browser" : "",
        ].join(" ")}
      >
        {allCategory && (
          <CategoryLink
            selected={
              selectedCategory?.slug === allCategory.slug &&
              !isCustomCategorySelectedEffective
            }
            selectedSubcategorySlug={selectedSubcategorySlug}
            disabled={isDisabled}
            onClick={(e) => {
              if (pushNewUrl) {
                e.preventDefault()
                pushNewUrl(generateRealmPath(null, "/browse"))
              } else {
                e.preventDefault()
                setIsCustomCategorySelected(false)
                setSelectedName(allCategory.name)
                setSelectedCategory(allCategory)
              }
            }}
            href={generateBrowseUrl({
              giftOptionSlug: null,
              categorySlug: allCategory.slug,
            })}
            key={allCategory.id}
            preventScrollIntoView
          >
            {allCategoryIcon && (
              <InlineSVG src={allCategoryIcon} tw="h-6 w-5" />
            )}
            <div
              tw="flex-1 lg:py-2 text-center lg:text-left"
              css={{ minWidth: 80 }}
            >
              {allCategory.name}
            </div>
          </CategoryLink>
        )}

        {(currentRealm === "plus" || currentRealm === "business") && (
          <CustomCategoryItem
            useVirtualUrl={useVirtualUrl}
            isEmbeddedCustomStore={isEmbeddedCustomStore}
            isCustomCategorySelected={isCustomCategorySelectedEffective}
            isDisabled={isDisabled}
            onClick={(e) => {
              if (pushNewUrl) {
                e.preventDefault()
                pushNewUrl(generateRealmPath(null, "/browse/custom"))
              } else {
                e.preventDefault()
                setCustomCategorySelected()
                setIsCustomCategorySelected(true)
                setSelectedName(null)
              }
            }}
          />
        )}

        {categoriesWithoutAll.map((category) => {
          const isSelected: boolean = selectedCategory?.slug === category.slug

          const icon = category.icon
            ?.replace(/fill="black"/g, `fill="currentColor"`)
            ?.replace(/stroke="black"/g, `stroke="currentColor"`)

          return (
            <React.Fragment key={category.id}>
              <CategoryLink
                onClick={(e) => {
                  e.preventDefault()
                  setSelectedName(category.name)
                  setSelectedCategory(category)
                  setIsCustomCategorySelected(false)
                }}
                selected={
                  isSelected &&
                  (!isCustomCategorySelectedEffective || isEmbeddedCustomStore)
                }
                selectedSubcategorySlug={selectedSubcategorySlug}
                disabled={isDisabled}
                href={generateBrowseUrl({
                  giftOptionSlug: null,
                  categorySlug: category.slug,
                })}
                preventScrollIntoView
              >
                {icon && <InlineSVG src={icon} tw="h-6 w-5" />}
                <div
                  tw="flex-1 lg:py-2 text-center lg:text-left"
                  css={{ minWidth: 80 }}
                >
                  {category.name}
                </div>
              </CategoryLink>
              <SubcategoriesList
                tw="hidden lg:block"
                isSelected={isSelected}
                subcategories={category.subcategories}
                selectedSubcategorySlug={selectedSubcategorySlug}
                onSubcategoryClick={onSubcategoryClick}
                preventScrollIntoView
              />
            </React.Fragment>
          )
        })}
      </CategoryLinksContainer>
      {selectedCategory && (
        <SubcategoriesMobileList
          tw="lg:hidden"
          categorySlug={selectedCategory.slug ?? null}
          onSubcategoryClick={onSubcategoryClick}
          selectedSubcategorySlug={selectedSubcategorySlug}
          subcategories={selectedCategory.subcategories}
        />
      )}
      <BackToTopButton />
      <div tw="h-px" />
    </CategoriesColumnContainer>
  )
}

const CategoryLinksContainer = styled.div`
  ${tw`flex lg:flex-col lg:items-stretch mx-4 lg:mx-0 transition-all lg:overflow-y-auto lg:pl-4 lg:h-full lg:pr-3 lg:pt-6 filter grayscale-0 gap-2 lg:gap-0 lg:pb-20 overflow-x-scroll`}

  &.is-custom-store-browser {
    max-width: calc(100vw - 2rem);
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`

const CategoriesColumnContainer = styled.div`
  ${tw`bg-white md:bg-transparent overflow-x-auto lg:overflow-x-visible overflow-y-hidden lg:overflow-y-visible relative flex flex-col rounded-t-xl lg:rounded-none lg:mr-4`};

  @media only screen and (min-width: 1024px) {
    height: calc(100vh - 97px);
  }
`

export default CategoriesColumn
