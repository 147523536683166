import { useQuery } from "@apollo/client"
import tw from "twin.macro"

import {
  CustomProductsCreateButton,
  CustomStoreCreateButton,
} from "./CustomCreateButton"
import { CustomProductsPreviewList } from "./CustomProductsPreviewList"
import { CustomProductsUpsell } from "./CustomProductsUpsell"
import { CustomStoresList } from "./CustomStoresList"
import { CustomStoresUpsell } from "./CustomStoresUpsell"
import { ORGANIZATION_SUBSCRIPTION_PLAN_QUERY } from "./graphql"
import { useFeatureAccess } from "../../common/hooks/featureAccess"
import LimitedMemberFeatureGateBanner from "../../common/members/LimitedMemberFeatureGateBanner"
import { Loader } from "../../common/UI"
import useStoreContext from "../hooks/useStoreContext"
import { ProductData } from "../StoreHelpers"

interface Props {
  onSelectCustomStoreProductID?: Function
}

export const Custom = ({ onSelectCustomStoreProductID }: Props) => {
  const { pushNewUrl, generatePdpUrl } = useStoreContext()

  function openPDPModal(product: ProductData) {
    pushNewUrl(
      generatePdpUrl({
        productSlug: product.slug,
        brandSlug: product.brand.slug,
      }),
    )
  }

  const { hasFeature } = useFeatureAccess()
  const { data, loading } = useQuery(ORGANIZATION_SUBSCRIPTION_PLAN_QUERY)
  const isLimitedMember =
    data?.organization?.subscription?.isTeamPlan &&
    !data?.me?.isFullOrganizationMember

  if (loading) {
    return (
      <div tw="flex justify-center items-center col-span-2">
        <Loader />
      </div>
    )
  }

  if (isLimitedMember) {
    return (
      <div tw="p-4 lg:ml-16 lg:mt-10 lg:mb-6 col-span-2">
        <LimitedMemberFeatureGateBanner />
      </div>
    )
  }

  return (
    <div
      tw="p-4 lg:ml-16 lg:mt-10 lg:mb-6 col-span-2 overflow-y-auto"
      css={{
        ...(loading ? tw`overflow-y-hidden` : tw`overflow-y-auto`),
      }}
    >
      {/* This is being used as a proxy for an embeded store being rendered inside autogifts */}
      {!onSelectCustomStoreProductID && (
        <>
          <Text tw="mb-7">Custom</Text>
          {hasFeature("custom_stores") && (
            <div tw="mb-16 gap-5 flex flex-col lg:flex-row">
              <CustomStoreCreateButton />
              <CustomProductsCreateButton />
            </div>
          )}

          <CustomProductsPreviewList openPDP={openPDPModal} />
        </>
      )}

      {hasFeature("custom_stores") ? (
        <>
          <Text tw="mb-6">Custom Stores</Text>
          <CustomStoresList
            onSelectCustomStoreProductID={onSelectCustomStoreProductID}
          />
        </>
      ) : (
        <div tw="flex flex-col gap-4 lg:gap-12">
          <CustomStoresUpsell />
          <CustomProductsUpsell />
        </div>
      )}
    </div>
  )
}

const Text = tw.h1`font-medium text-2xl`
