import React from "react"

import GradientButton from "./GradientButton"
import { ReactComponent as ArrowRight } from "../assets/icons/arrow-right.svg"

type StartButtonProps = {
  disabled?: boolean
  label?: string
  arrowCss?: any
} & React.ComponentPropsWithoutRef<"button">

const StartButton: React.FC<StartButtonProps> = ({
  label,
  arrowCss,
  ...restProps
}) => (
  <GradientButton {...restProps}>
    {label || "Start gifting"}
    <ArrowRight tw="ml-8" css={arrowCss} />
  </GradientButton>
)

export default StartButton
