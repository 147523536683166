import { gql } from "@apollo/client"

export const GetSamlEnabled = gql`
  query GetSamlEnabled {
    organization {
      id
      samlEnabled
    }
  }
`
