import styled from "@emotion/styled"
import React, { FC } from "react"
import tw from "twin.macro"

import Elemy from "./images/elemy.png"
import SwapBackground from "./images/swap_background.png"
import SwapTestimonialBackground from "./images/swap_testimonial_background.png"
import Swapping from "./images/swapping.png"

const SwapTestimonials: FC = () => {
  return (
    <div tw="pt-24 pb-24" style={{ backgroundColor: "#F8F8F8" }}>
      <div
        tw="flex flex-col lg:flex-row px-5 lg:px-12 mx-auto"
        style={{ maxWidth: "1440px" }}
      >
        <TestimonialSection>
          <LargeQuote>“</LargeQuote>
          <h2 tw="font-bold text-xl lg:text-3xl sm:w-3/4 leading-8 sm:leading-10 mb-6">
            The ability to swap gifts is universally loved.
          </h2>
          <p tw="text-xl lg:text-2xl leading-8 mb-12">
            We have also gotten a lot of love for the overall experience of
            opening your first Goody down to the Elemy branded card that they
            open.
          </p>
          <p tw="sm:text-lg font-medium">Joe Carbonaro</p>
          <p tw="sm:text-lg mb-12">Director of Business Development, Elemy</p>
          <ElemyImage src={Elemy} />
        </TestimonialSection>
        <SwapSection>
          <SwapSectionHeader>50%</SwapSectionHeader>
          <h4 tw="text-2xl w-4/5 sm:w-2/3 lg:w-4/5 xl:w-2/3 mx-auto mb-16">
            of gift recipients swap their gifts when given the option
          </h4>
          <div tw="flex justify-end">
            <SwappingImage src={Swapping} />
          </div>
        </SwapSection>
      </div>
    </div>
  )
}

const LargeQuote = styled.h2`
  ${tw`font-bold absolute`}

  top: -0.5rem;
  font-size: 11rem;
  opacity: 0.2;

  @media (min-width: 1024px) {
    top: 2.5rem;
  }
`

const TestimonialSection = styled.div`
  ${tw`mx-auto lg:mx-0 flex-1 text-white p-10 lg:p-16 relative pt-36 lg:pt-48 lg:px-24 pb-16 mb-12 lg:mb-0 lg:mr-8`}

  background-color: #3C4779;
  background-image: url(${SwapTestimonialBackground});
  background-size: cover;
  border-radius: 2rem;
  max-width: 580px;

  @media (min-width: 1024px) {
    width: 65%;
    max-width: unset;
  }
`

const SwapSection = styled.div`
  ${tw`mx-auto lg:mx-0 text-center py-12`}

  background-image: url(${SwapBackground});
  background-size: cover;
  max-width: 580px;

  @media (min-width: 1024px) {
    width: 35%;
    max-width: unset;
  }
`

const ElemyImage = styled.img`
  width: 10rem;
`

const SwapSectionHeader = styled.h2`
  ${tw`font-medium sm:mb-4`}

  font-size: 6rem;
  line-height: 6rem;
`

const SwappingImage = styled.img`
  margin-right: 5%;
  width: 75%;
`

export default SwapTestimonials
