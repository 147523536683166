import { useMutation, useQuery } from "@apollo/client"
import React, { useEffect, useState } from "react"
import { toast } from "react-hot-toast"

import Button from "@/common/Button"
import {
  SelectionGroup,
  SelectionIndicator,
  SelectionLabel,
} from "@/common/SelectionGroup/SelectionGroup"
import { SelectionGroupColorEnum } from "@/common/SelectionGroup/types"
import { OrganizationGetRequireSSOSetting } from "@/organization/settings/requireSSO/graphql/OrganizationGetRequireSSOSetting"
import { OrganizationUpdateRequireSsoSetting } from "@/organization/settings/requireSSO/graphql/OrganizationUpdateRequireSSOSetting"
import Section from "@/organization/settings/Section"
import {
  OrganizationGetRequireSSOSettingQuery,
  OrganizationRequireSsoSetting,
  OrganizationUpdateRequireSSOSettingMutation,
  OrganizationUpdateRequireSSOSettingMutationVariables,
} from "@/types/graphql-types"

interface Props {
  samlEnabled: boolean
  organizationId: string
}
const RequireSSO = ({ samlEnabled, organizationId }: Props) => {
  const [requireSSOSetting, setRequireSSOSetting] =
    useState<OrganizationRequireSsoSetting | null>(null)
  const [selectionChanged, setSelectionChanged] = useState(false)

  const { data: getRequireSSOSettingData } =
    useQuery<OrganizationGetRequireSSOSettingQuery>(
      OrganizationGetRequireSSOSetting,
    )

  const [organizationUpdateRequireSsoSetting, { loading }] = useMutation<
    OrganizationUpdateRequireSSOSettingMutation,
    OrganizationUpdateRequireSSOSettingMutationVariables
  >(OrganizationUpdateRequireSsoSetting)

  const requireSSOSettingInitial =
    getRequireSSOSettingData?.organization?.requireSsoSetting

  const updateRequireSsoSetting = async () => {
    if (organizationId && requireSSOSetting) {
      const result = await organizationUpdateRequireSsoSetting({
        variables: {
          organizationId: organizationId,
          requireSsoSetting: requireSSOSetting,
        },
      })

      const data = result?.data?.organizationUpdateRequireSsoSetting

      if (!loading && data?.ok) {
        toast.success("Saved Require SSO setting.")
      } else {
        const error = data?.error ?? "An error occurred."
        toast.error(error)
      }
    }
  }

  const onSetRequireSsoSetting = (
    newSetting: OrganizationRequireSsoSetting,
  ) => {
    if (requireSSOSettingInitial !== newSetting) {
      setSelectionChanged(true)
    } else {
      setSelectionChanged(false)
    }
    setRequireSSOSetting(newSetting)
  }

  const onSave = () => {
    updateRequireSsoSetting()
    setSelectionChanged(false)
  }

  useEffect(() => {
    if (requireSSOSettingInitial) {
      setRequireSSOSetting(requireSSOSettingInitial)
    }
  }, [requireSSOSettingInitial])

  return (
    <Section title="Require SSO">
      <div>
        <div tw="mb-5">Prevent users from signing in without SSO.</div>
        <div tw="flex flex-col gap-y-3">
          <SelectionGroup
            onClick={() =>
              onSetRequireSsoSetting(OrganizationRequireSsoSetting.disabled)
            }
          >
            <SelectionIndicator
              selected={
                requireSSOSetting === null ||
                requireSSOSetting === OrganizationRequireSsoSetting.disabled
              }
              color={SelectionGroupColorEnum.primaryNew}
            />
            <SelectionLabel
              selected={
                requireSSOSetting === null ||
                requireSSOSetting === OrganizationRequireSsoSetting.disabled
              }
              color={SelectionGroupColorEnum.primaryNew}
              unselectedBlack={true}
            >
              Don't require SSO
            </SelectionLabel>
          </SelectionGroup>
          <SelectionGroup
            onClick={() =>
              onSetRequireSsoSetting(OrganizationRequireSsoSetting.google)
            }
          >
            <SelectionIndicator
              selected={
                requireSSOSetting === OrganizationRequireSsoSetting.google
              }
              color={SelectionGroupColorEnum.primaryNew}
            />
            <SelectionLabel
              selected={
                requireSSOSetting === OrganizationRequireSsoSetting.google
              }
              color={SelectionGroupColorEnum.primaryNew}
              unselectedBlack={true}
            >
              Require Google SSO
            </SelectionLabel>
          </SelectionGroup>
          <SelectionGroup
            onClick={() =>
              onSetRequireSsoSetting(OrganizationRequireSsoSetting.microsoft)
            }
          >
            <SelectionIndicator
              selected={
                requireSSOSetting === OrganizationRequireSsoSetting.microsoft
              }
              color={SelectionGroupColorEnum.primaryNew}
            />
            <SelectionLabel
              selected={
                requireSSOSetting === OrganizationRequireSsoSetting.microsoft
              }
              color={SelectionGroupColorEnum.primaryNew}
              unselectedBlack={true}
            >
              Require Microsoft SSO
            </SelectionLabel>
          </SelectionGroup>
          <SelectionGroup
            onClick={() =>
              onSetRequireSsoSetting(OrganizationRequireSsoSetting.saml)
            }
            disabled={!samlEnabled}
            unclickable={!samlEnabled}
          >
            <SelectionIndicator
              selected={
                requireSSOSetting === OrganizationRequireSsoSetting.saml
              }
              color={SelectionGroupColorEnum.primaryNew}
            />
            <SelectionLabel
              selected={
                requireSSOSetting === OrganizationRequireSsoSetting.saml
              }
              color={SelectionGroupColorEnum.primaryNew}
              unselectedBlack={true}
            >
              Require SAML SSO
            </SelectionLabel>
          </SelectionGroup>
          <Button
            variant="primary-new"
            onClick={onSave}
            disabled={!selectionChanged}
            tw="mt-3 max-w-[67px]"
          >
            Save
          </Button>
        </div>
      </div>
    </Section>
  )
}

export default RequireSSO
