import React, { FC } from "react"
import { Helmet } from "react-helmet-async"

import Carousel from "./../business/Carousel"
import PricingTabs from "./PricingTabs"
import Title from "./Title"
import { useScreen } from "../../common/analytics"
import CallToAction from "../components/CallToAction"

const Pricing: FC = () => {
  useScreen("Business Marketing - Pricing")

  return (
    <>
      <Helmet>
        <title>Pricing — Compare Plans, Features & Costs | Goody</title>
        <meta
          name="description"
          content="Discover features and pricing for Goody Business plans. Compare Goody’s free Starter plan with our Pro and Team subscriptions."
        />
      </Helmet>
      <Title />
      <PricingTabs />
      <Carousel />
      <CallToAction />
    </>
  )
}

export default Pricing
