import React, { useEffect } from "react"
import { CSSTransition } from "react-transition-group"
import tw, { styled } from "twin.macro"

import { ReactComponent as XIcon } from "../../common/images/x.svg"
import SwipeableBottomSheet from "../../common/SwipeableBottomSheet"

interface ContactCardProps {
  onClose: () => void
  shown: boolean
}

const ContactCard: React.FC<ContactCardProps> = ({
  onClose,
  children,
  shown,
}) => {
  const card = (
    <CardDisplayContainer onClick={(e) => e.stopPropagation()}>
      <CloseButton onClick={onClose}>
        <XIcon tw="stroke-current stroke-3 w-4 h-auto" />
      </CloseButton>
      {children}
    </CardDisplayContainer>
  )

  useEffect(() => {
    const { body } = document
    const escHandler = (e: KeyboardEvent) => e.key === "Escape" && onClose()
    body.addEventListener("click", onClose)
    body.addEventListener("keydown", escHandler)

    return () => {
      body.removeEventListener("click", onClose)
      body.removeEventListener("keydown", escHandler)
    }
  })

  return (
    <>
      <SwipeableBottomSheet
        swipeableViewsProps={{
          containerStyle: {
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
            margin: "0 auto",
            maxWidth: 500,
            overflow: "hidden",
            transition: "transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s",
          },
        }}
        open={shown}
        fullScreen={true}
        marginTop={135}
        onChange={(isOpen: boolean) => !isOpen && onClose()}
        tw="lg:hidden"
      >
        {card}
      </SwipeableBottomSheet>
      <div tw="hidden lg:block">
        <CSSTransition in={shown} timeout={cardAnimationDuration}>
          {card}
        </CSSTransition>
      </div>
    </>
  )
}

export const cardAnimationDuration = 500

const CloseButton = styled.button`
  ${tw`absolute right-4 top-4 p-2 rounded-full transition-colors z-10 text-primary-300`}

  &:hover {
    ${tw`text-primary-500`}
    background: #F5E6F0;
  }

  &:active {
    ${tw`text-primary-600`}
    background: #F0DBE9;
  }
`

const CardDisplayContainer = styled.div`
  ${tw`flex rounded-t-2xl lg:rounded-2xl flex-col bg-white lg:absolute right-5 top-9 bottom-16 z-20 overflow-hidden lg:hidden transition-all transform h-full`};
  box-shadow:
    0 12px 48px rgba(129, 101, 163, 0.12),
    0 1px 4px rgba(129, 101, 163, 0.1);
  transition-duration: ${cardAnimationDuration.toString()}ms;

  @media only screen and (min-width: 1024px) {
    max-height: calc(100% + 140px);
    min-height: 400px;
    margin-top: -50px;
    margin-bottom: -80px;
    width: 424px;
  }

  &.enter,
  &.exit {
    ${tw`flex opacity-0 translate-x-4`}
  }

  &.enter-active,
  &.enter-done {
    ${tw`flex opacity-100 translate-x-0`}
  }
`

export default ContactCard
