import React, { useRef } from "react"
import { styled } from "twin.macro"

import { AddPaymentMethodFormFields } from "./useAddPaymentMethod"
import FormField from "../FormField"
import { useGlobalState } from "../GlobalState"
import { useCurrentGift } from "../hooks/currentGift"
import { usePlacesAutocomplete } from "../hooks/usePlacesAutocomplete"

import { BatchSendMethod } from "@/types/graphql-types"

interface Props {
  formFields: AddPaymentMethodFormFields
  setFormField: (name: string, value: string) => void
  setFormFields: (val: AddPaymentMethodFormFields) => void
  showBackButton?: boolean
  onClickBack?: () => void
}

// AddPaymentMethodForm is a UI component that just displays form fields.
// This is meant to be used in conjunction with useAddPaymentMethod.
const AddPaymentMethodForm: React.FC<Props> = ({
  formFields,
  setFormField,
  setFormFields,
  showBackButton,
  onClickBack,
}) => {
  const { autocompleteFieldRef } = usePlacesAutocomplete({
    onAutocompletePlace: (place) => {
      const { country, ...result } = place
      setFormFields({
        ...formFields,
        ...result,
      })
    },
  })

  const initialName = useRef(formFields.name).current

  const [currentGift] = useCurrentGift()
  const [consumerRecipient] = useGlobalState("consumerRecipient")

  const isDirectSendWithConsumerAddress =
    currentGift.sendMethod === BatchSendMethod.direct_send &&
    consumerRecipient?.mailingAddress

  function setFormFieldsFromConsumerAddress() {
    if (!consumerRecipient.mailingAddress) {
      return
    }

    setFormFields({
      ...formFields,
      ...consumerRecipient.mailingAddress,
      address2: consumerRecipient.mailingAddress.address2 || "",
    })
  }

  function clearFormFields() {
    setFormFields({
      name: initialName,
      address1: "",
      address2: "",
      city: "",
      state: "",
      postalCode: "",
    })
  }

  return (
    <>
      {showBackButton && (
        <div tw="pb-4">
          <button
            type="button"
            onClick={onClickBack}
            tw="text-sm text-purple-500 hover:text-purple-700 transition-colors"
          >
            &larr; Back to payment methods
          </button>
        </div>
      )}
      <div
        tw="rounded-xl border border-gray-150 lg:pl-2"
        css={{
          boxShadow: "0 1px 4px rgba(0, 0, 0, 0.06)",
        }}
        className="data-hj-suppress ph-no-capture fs-exclude"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            boxSizing: "border-box",
            overflow: "hidden",
            width: "100%",
            height: "48px",
            transition: "all 0.2s ease",
          }}
        >
          <SecureField id="card-number" />
          <SecureField id="card-expiration" />
          <SecureField id="card-cvc" />
        </div>
        <div className="CreditCardForm__ErrorMessage" />
      </div>
      <div tw="h-6" />
      {isDirectSendWithConsumerAddress && (
        <div tw="pb-6">
          <label tw="bg-white border border-gray-150 rounded-lg text-gray-700 px-4 py-3 flex w-full flex-row items-center gap-3 transition-all hover:(shadow-min border-gray-200 cursor-pointer) active:(bg-gray-050)">
            <input
              type="checkbox"
              onChange={(e) => {
                if (e.target.checked) {
                  setFormFieldsFromConsumerAddress()
                } else {
                  clearFormFields()
                }
              }}
            />
            <span>Billing is the same as shipping address</span>
          </label>
        </div>
      )}
      <div
        tw="border border-gray-150 rounded-lg"
        className="data-hj-suppress ph-no-capture fs-exclude"
      >
        <div tw="border-b border-gray-150 p-3 px-4">
          <FormField
            name="name"
            label="Name on card"
            placeholder="Name"
            value={formFields.name}
            onChange={setFormField}
          />
        </div>
        <div tw="border-b border-gray-150 p-3 px-4">
          <FormField
            name="address1"
            label="Address 1"
            placeholder="185 Berry Street"
            value={formFields.address1}
            onChange={setFormField}
            ref={autocompleteFieldRef}
          />
        </div>
        <div tw="border-b border-gray-150 p-3 px-4">
          <FormField
            name="address2"
            label="Address 2"
            placeholder="Optional"
            value={formFields.address2}
            onChange={setFormField}
          />
        </div>
        <div tw="flex flex-row">
          <div tw="p-3 px-4 border-r border-gray-150 flex-2">
            <FormField
              name="city"
              label="City"
              placeholder="San Francisco"
              value={formFields.city}
              onChange={setFormField}
            />
          </div>
          <div tw="flex-1 p-3 px-4 border-r border-gray-150">
            <FormField
              name="state"
              label="State"
              placeholder="CA"
              value={formFields.state}
              onChange={setFormField}
            />
          </div>
          <div tw="flex-1 p-3 px-4">
            <FormField
              name="postalCode"
              label="ZIP"
              placeholder="12345"
              value={formFields.postalCode}
              onChange={setFormField}
            />
          </div>
        </div>
      </div>
    </>
  )
}

const SecureField = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;

  iframe {
    height: 100%;
  }

  &#card-number {
    width: 60%;
  }

  &#card-expiration,
  &#card-cvc {
    width: 20%;
  }
`

export default AddPaymentMethodForm
