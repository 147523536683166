import tw, { css, styled } from "twin.macro"

// Contains the left, center (bar), and right animations
export const FullContainer = styled.div`
  ${tw`flex flex-col md:flex-row items-center justify-center`};
`

// Wraps around the left and right animations
export const AnimationContainer = styled.div<{ withShadow?: boolean }>`
  ${tw`flex-1 w-full max-w-[560px] relative`};

  // Container should have an aspect ratio of 560:369, the same as the SVG
  // This holds space for the animations to be displayed, including when they
  // are still being loaded
  aspect-ratio: 560 / 369;

  ${(props) =>
    props.withShadow &&
    css`
      filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.03))
        drop-shadow(0px 2px 12px rgba(0, 0, 0, 0.02));
    `}
`

// The bar becomes vertical on mobile screens (rotated 90 degrees)
export const BarContainer = tw.div`w-[48px] h-[48px] md:(w-[48px] h-[48px]) xl:(w-[92px] h-[92px]) flex flex-col items-center justify-center rotate-90 transform origin-center md:(rotate-0)`
