import "twin.macro"
import { Helmet } from "react-helmet-async"

import CategorySidebarFrame from "@/store/categories/CategorySidebarFrame"
import { CustomFeaturesPages } from "@/store/custom/CustomFeaturesPages"
import { onSelectProductIDType } from "@/store/types"

interface Props {
  onSelectProductID?: onSelectProductIDType
  isEmbeddedCustomStore: boolean
  useVirtualUrl?: boolean
}

export default function CustomScreen({
  onSelectProductID,
  isEmbeddedCustomStore,
  useVirtualUrl,
}: Props) {
  return (
    <>
      {!useVirtualUrl && (
        <Helmet>
          <title>Custom | Goody</title>
          <meta name="title" property="og:title" content="Custom | Goody" />
          <meta
            name="description"
            property="og:description"
            content="Send branded swag and gifts, and create custom stores for your recipients."
          />
        </Helmet>
      )}
      <CategorySidebarFrame>
        <CustomFeaturesPages
          onSelectProductID={onSelectProductID}
          isEmbeddedCustomStore={isEmbeddedCustomStore}
        />
      </CategorySidebarFrame>
    </>
  )
}
