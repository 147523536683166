import styled from "@emotion/styled"
import { Dispatch, FC, SetStateAction } from "react"
import { Link } from "react-router-dom"
import tw from "twin.macro"

import FrequentlyAskedQuestions from "./FrequentlyAskedQuestions"
import Gift from "./images/gift.svg"
import Heart from "./images/heart.svg"
import HRCompanies from "./images/hr_companies.png"
import ProPlan from "./images/pro-plan.svg"
import MonthlyAnnualToggle from "./MonthlyAnnualToggle"
import { gtmEvent } from "../../common/gtm"
import PurpleGradientLink, {
  InactivePurpleGradientContainer,
} from "../../common/PurpleGradientLink"
import { generateRealmPath } from "../../common/realm"
import WhiteBackgroundLink from "../../common/WhiteBackgroundLink"

import { SubscriptionPlanSku } from "@/types/graphql-types"

interface Props {
  isAnnualBilling: boolean
  setIsAnnualBilling: Dispatch<SetStateAction<boolean>>
  isSmallBusinessSection: boolean
  switchTab: (index: number) => void
  isEnrolledInBusiness: boolean
  organizationPlanSku: SubscriptionPlanSku | null
  userPlanSku: SubscriptionPlanSku | null
  upsellText?: boolean
}

type CurrentPlan = "NOT_ENROLLED" | "FREE" | "PRO" | "EE"

const TabPanelContent: FC<Props> = ({
  isAnnualBilling,
  setIsAnnualBilling,
  isEnrolledInBusiness,
  organizationPlanSku,
  userPlanSku,
  upsellText,
}) => {
  const billingInterval = isAnnualBilling ? "annually" : "monthly"

  let currentPlan: CurrentPlan = "NOT_ENROLLED"

  if (isEnrolledInBusiness) {
    currentPlan = "FREE"
  }

  if (isEnrolledInBusiness && userPlanSku) {
    currentPlan = "PRO"
  }

  if (isEnrolledInBusiness && organizationPlanSku) {
    currentPlan = "EE"
  }

  return (
    <div tw="px-4 pb-24 bg-gray-050 pt-4 md:pt-12">
      {upsellText && (
        <div>
          <div tw="text-xl text-purple-500 pb-2 font-medium text-center">
            Plans
          </div>
          <h1 tw="text-5xl pb-6 font-medium text-center">
            Elevate your gifting
          </h1>
        </div>
      )}
      <MonthlyAnnualToggle
        isAnnualBilling={isAnnualBilling}
        setIsAnnualBilling={setIsAnnualBilling}
      />
      <div tw="flex flex-col lg:flex-row lg:justify-center">
        <PricingSection>
          <div tw="px-4 pb-8 border-b border-gray-200 border-solid">
            <div tw="flex flex-row items-center justify-start gap-4 pb-4">
              <img src={Gift} alt="" />
              <p tw="text-xl font-medium">Starter</p>
            </div>
            <div tw="pt-3 pb-1 text-lg text-gray-600">
              For individuals gifting occasionally
            </div>
            <p tw="text-6xl font-medium mb-6">
              $0
              <span tw="text-base font-normal text-gray-700 ml-2">Forever</span>
            </p>
            {currentPlan === "FREE" ? (
              <InactivePurpleGradientContainer tw="rounded-xl">
                Current plan
              </InactivePurpleGradientContainer>
            ) : (
              currentPlan === "NOT_ENROLLED" && (
                <>
                  <PurpleGradientLink
                    tw="rounded-xl w-full text-center py-4"
                    to={generateRealmPath("business", "/signup")}
                    onClick={() => gtmEvent("BusinessLn_Pricing_Free_SignUp")}
                  >
                    Sign up
                  </PurpleGradientLink>
                </>
              )
            )}
          </div>
          <div tw="pt-4" />
          <DescriptionHeader tw="max-w-[320px]">
            Send unlimited gifts in the U.S. and Canada.
          </DescriptionHeader>
          <Subheading> Features </Subheading>
          <StyledUL>
            <StyledLI>Send to 1,000+ recipients at once</StyledLI>
            <StyledLI>Create a workspace and invite workspace members</StyledLI>
            <StyledLI>Send via email, text message, or custom link</StyledLI>
            <StyledLI>View thank you notes in one place</StyledLI>
            <StyledLI>On-demand branded swag with no minimums</StyledLI>
            <StyledLI>Schedule gifts</StyledLI>
            <StyledLI>Gift tracking & reporting</StyledLI>
            <StyledLI>Create landing page links for gift redemption</StyledLI>
            <StyledLI>Sign in with Google or Microsoft SSO</StyledLI>
          </StyledUL>
          <div tw="text-sm text-gray-500 leading-[19.6px] -mt-3">
            Custom Stores and SAML Single Sign-On available as add-ons. Contact
            us to learn more.
          </div>
        </PricingSection>
        <PricingSection>
          <div tw="px-4 pb-8 border-b border-gray-200 border-solid">
            <div tw="flex flex-row items-center justify-start gap-4 pb-4">
              <img src={ProPlan} alt="" />
              <p tw="text-xl font-medium">Pro</p>
            </div>
            <div tw="pt-3 pb-1 text-lg text-gray-600">
              For individuals gifting at scale
            </div>
            <p tw="text-6xl font-medium mb-6">
              ${isAnnualBilling ? 20 : 25}
              <span tw="text-base font-normal text-gray-700 ml-2 inline-block">
                /month/user
                <br />
                When billed {billingInterval}
              </span>
            </p>
            {/* @ts-ignore WTF? */}
            {currentPlan === "PRO" && (
              <>
                <InactivePurpleGradientContainer tw="rounded-xl">
                  Current plan
                </InactivePurpleGradientContainer>
                {!window.location.pathname.includes("account") && (
                  <div tw="text-center mt-3">
                    <Link
                      to={generateRealmPath("plus", "/account/subscription")}
                      tw="font-medium text-primary-new-600 hover:text-primary-new-700 active:text-primary-new-800 text-center"
                    >
                      Manage my Pro plan
                    </Link>
                  </div>
                )}
              </>
            )}
            {(currentPlan === "NOT_ENROLLED" || currentPlan === "FREE") && (
              <PurpleGradientLink
                tw="rounded-xl w-full text-center py-4"
                to={
                  currentPlan === "NOT_ENROLLED"
                    ? generateRealmPath("business", "/signup?pro")
                    : generateRealmPath("plus", "/subscribe-pro")
                }
                onClick={() =>
                  gtmEvent(
                    currentPlan === "NOT_ENROLLED"
                      ? "BusinessLn_Pricing_Pro_ClickSignup"
                      : "BusinessLn_Pricing_Pro_ClickUpgrade",
                  )
                }
              >
                {currentPlan === "NOT_ENROLLED" ? "Sign up" : "Upgrade"}
              </PurpleGradientLink>
            )}
          </div>
          <div tw="pb-8 pt-4">
            <DescriptionHeader tw="max-w-[320px]">
              Gift globally, add logo branding, and send gift cards.
            </DescriptionHeader>
            <Subheading> Features </Subheading>
            <div tw="pt-2">Everything in Starter, plus:</div>
            <StyledUL>
              <StyledLI>
                <span>Add logo branding</span> to gift notifications
              </StyledLI>
              <StyledLI>
                <span>Send gift cards</span> from hundreds of brands
              </StyledLI>
              <StyledLI>
                <span>International gifts and swag</span> to 140+ countries
              </StyledLI>
              <StyledLI>
                <span>Integrate Calendly</span> to book meetings and increase
                show rates
              </StyledLI>
              <StyledLI>Create custom cards</StyledLI>
              <StyledLI>Send from your own Gmail account</StyledLI>
              <StyledLI>Advanced reporting &amp; analytics</StyledLI>
            </StyledUL>
            <div tw="text-sm text-gray-500 leading-[19.6px] -mt-3">
              Custom Stores and SAML Single Sign-On available as add-ons.
              Contact us to learn more.
            </div>
          </div>
        </PricingSection>
        <PricingSection>
          <div tw="px-4 pb-8 border-b border-gray-200 border-solid">
            <div tw="flex flex-row items-center justify-start gap-4 pb-4">
              <img src={Heart} alt="" />
              <p tw="text-xl font-medium">Team</p>
            </div>
            <div tw="pt-3 pb-2 text-lg text-gray-600">
              For HR, Sales & Marketing teams
            </div>
            <p tw="text-2xl font-medium mb-8 mt-4">Chat with us</p>
            <PurpleGradientLink
              tw="rounded-xl w-full text-center py-4"
              to={generateRealmPath("business", "/team-demo")}
              onClick={() => gtmEvent("BusinessLn_Pricing_EETrial_Demo")}
            >
              Book a demo
            </PurpleGradientLink>
          </div>
          <div tw="pb-8 pt-4">
            <DescriptionHeader>
              Book meetings, accelerate deals, and automate employee gifts.
            </DescriptionHeader>
            <Subheading> Features </Subheading>

            <div tw="pt-2">Everything in Pro, plus:</div>
            <StyledUL>
              <StyledLI>
                <span>Set and manage budgets</span> for your team
              </StyledLI>
              <StyledLI>
                <span>
                  <a
                    target="blank"
                    href="https://www.ongoody.com/blog/how-to-build-branded-gift-experiences-with-custom-stores"
                  >
                    Build custom digital stores
                  </a>
                </span>{" "}
                of gifts & swag
              </StyledLI>
              <StyledLI>
                <span>Integrate Salesforce</span> and gift directly from your
                CRM
              </StyledLI>
              <StyledLI>
                <span>
                  <a
                    target="blank"
                    href="https://intercom.help/goody/en/articles/5998898-which-hr-platforms-does-goody-integrate-with"
                  >
                    HR integrations
                  </a>
                </span>{" "}
                with 30+ leading platforms
              </StyledLI>
            </StyledUL>
            <div tw="-mt-4 flex w-3/4 items-center">
              <img src={HRCompanies} alt="" />
              <p tw="ml-4">
                + 21
                <br />
                more
              </p>
            </div>
            <StyledUL>
              <StyledLI>
                <span>
                  <a
                    target="blank"
                    href="https://www.ongoody.com/business/use-cases/autogifting"
                  >
                    Automate employee gifts
                  </a>
                </span>{" "}
                for birthdays, work anniversaries & onboarding.
              </StyledLI>
              <StyledLI>
                <span>Create a single gift link</span> that captures recipients'
                lead info
              </StyledLI>
              <StyledLI>
                <span>Single sign-on (SSO)</span> using SAML
              </StyledLI>
              <StyledLI>Priority support</StyledLI>
            </StyledUL>
          </div>
        </PricingSection>
      </div>
      <FrequentlyAskedQuestions />
    </div>
  )
}

const StyledWhiteBackgroundLink = styled(WhiteBackgroundLink)`
  ${tw`text-black block text-center hover:bg-primary-000 active:scale-95 transition-transform`}
`

const StyledUL = styled.ul`
  ${tw`pb-4 pt-4 pl-4 font-text`}
`

const StyledLI = styled.li`
  ${tw`mb-4`}

  &::before {
    content: "•";
    color: blue;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }

  span {
    font-weight: 700;
  }
`

const PricingSection = styled.div`
  ${tw`relative mx-auto lg:mx-4 py-12 px-6 sm:px-8 mb-12 overflow-hidden bg-white border-gray-200`}

  border-top: none;
  border-radius: 0.75rem;
  border-width: 1px;
  width: 100%;
  max-width: 420px;

  &:before {
    ${tw`absolute top-0 left-0 right-0`}
    background: linear-gradient(90deg, #AE72FF -15.69%, #6B62FF 142.97%);
    height: 5px;
    width: 100%;
    content: "";
  }
`

const DescriptionHeader = styled.h1`
  ${tw`text-xl font-semibold mb-8`}
`

const Subheading = styled.h1`
  ${tw`text-base md:text-xl font-semibold`}
  color: #A981FF;
`

export default TabPanelContent
