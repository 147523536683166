import tw, { styled } from "twin.macro"

const RoundedTable = styled.table`
  ${tw`block rounded-lg w-full lg:border border-gray-200`};

  thead,
  tbody {
    ${tw`block`}
  }

  thead {
    ${tw`px-px`};

    tr {
      ${tw`text-gray-500 text-left text-sm font-medium py-2`}
    }
  }

  tbody {
    td {
      ${tw`py-6 h-full`};
    }

    // Make sure the bottom border of the last td does not stack over the bottom border of the table
    margin-bottom: -1px;
  }

  tr {
    ${tw`grid items-start border-b border-gray-200`};
  }

  td,
  th {
    ${tw`px-3`}
  }
`

export default RoundedTable
