import { ReactNode } from "react"

import Button from "@/common/Button"

interface Props {
  cta: string
  onCta: () => void
  children: ReactNode
}

const Alert = ({ cta, onCta, children }: Props) => (
  <>
    <div tw="text-gray-800">{children}</div>
    <Button
      variant="default"
      tw="w-full justify-center text-gray-600"
      onClick={onCta}
    >
      {cta}
    </Button>
  </>
)

export default Alert
