import React, { useState } from "react"
import Confetti from "react-confetti"
import { Link, useParams } from "react-router-dom"
import tw, { styled } from "twin.macro"

import { ArrowRightPink } from "../assets/icons"
import { ReactComponent as AppDownload } from "../assets/images/consumer-send/app-download.svg"
import SpeechBubble from "../assets/images/consumer-send/speech-bubble.svg"
import CardPreview from "../common/components/CardPreview"
import ConsumerContainer from "../common/components/ConsumerContainer"
import PreviewModal from "../common/components/PreviewModal"
import ShareLinkContainer from "../common/components/ShareLinkContainer"
import { useGift } from "../common/hooks/gifts"
import { generateRealmPath } from "../common/realm"
import { MessageContainer, largerTextCss } from "../common/UI"

import { BatchSendMethod } from "@/types/graphql-types"

interface Params {
  id: string
}

// Post consumer send page
const SendComplete: React.FC = () => {
  const params = useParams<Params>()
  const [previewing, setPreviewing] = useState<boolean>(false)

  const { gift } = useGift(params.id)

  const content = gift ? (
    <>
      <div css={"z-index: -50;"}>
        <Confetti recycle={false} numberOfPieces={300} gravity={0.05} />
      </div>
      <div tw={"px-4 flex flex-col items-center"}>
        <div tw={"mt-16 text-5xl text-center font-bold"}>Gift sent!</div>
        <div
          tw={"text-gray-500 text-xl mt-4"}
          className="data-hj-suppress ph-no-capture fs-exclude"
        >
          Thanks for making {gift.recipientFirstName}’s day!
        </div>
        <StatusButton
          to={generateRealmPath(
            "consumer",
            `/my-gifts/${gift.senderViewAccessId}`,
          )}
          tw={
            "border flex flex-row items-center rounded-full justify-between pl-7 pr-4"
          }
        >
          Gift status
          <ArrowRightPink />
        </StatusButton>
        {gift.sendMethod !== BatchSendMethod.direct_send ||
        (gift.sendMethod === BatchSendMethod.direct_send &&
          !gift.giftNotificationDisabled) ? (
          <ShareLinkContainer
            gift={gift}
            show={!!gift.isSelfSend}
            header={`Share your gift link with 
        ${[gift.recipientFirstName, gift.recipientLastName]
          .filter(Boolean)
          .join(" ")}`}
            css={"max-width: 625px;"}
            tw={"sm:mx-auto self-stretch"}
          />
        ) : null}
        <div
          css={"max-width: 470px; margin-top: 4.5rem;"}
          tw={"w-full flex flex-col items-stretch"}
        >
          <NotificationsContainer>
            <div
              tw={
                "bg-white m-0.5 flex-1 flex flex-row items-center justify-between"
              }
              css={"border-radius: .675rem;"}
            >
              <div tw={"flex flex-col ml-9 items-start"}>
                <GetNotifiedContainer>
                  Get notified when
                  <br />
                  they see your gift
                </GetNotifiedContainer>
                <EnableNotifications>Enable notifications</EnableNotifications>
              </div>
              <GiftMessage>
                <div tw={"opacity-60"} css={"line-height: 130%"}>
                  Your gift was
                  <br />
                  opened!
                </div>
              </GiftMessage>
            </div>
          </NotificationsContainer>
          {gift.card?.imageLarge?.url && (
            <SecondaryContainer>
              <div
                tw={"text-gray-600 ml-5 sm:ml-9 mr-10 sm:mr-14"}
                css={largerTextCss}
              >
                See what your gift looks like
              </div>
              <CardPreview
                card={gift.card}
                css={tw`mr-4 sm:mr-12`}
                onClick={() => setPreviewing(true)}
              />
            </SecondaryContainer>
          )}
          <div tw={"h-24"} />
          <MobileAppComponent />
          {gift.senderLink && (
            <PreviewModal
              isOpen={previewing}
              close={() => setPreviewing(false)}
              senderLink={gift.senderLink}
            />
          )}
        </div>
      </div>
    </>
  ) : null

  return (
    <ConsumerContainer title={"Gift Sent"} tw={"flex flex-col items-center"}>
      {content}
    </ConsumerContainer>
  )
}

export const MobileAppComponent = () => {
  return (
    <SecondaryContainer>
      <a
        href={"https://get.ongoody.com"}
        target={"_blank"}
        rel="noopener noreferrer"
        tw={"flex flex-row items-center"}
      >
        <div
          tw={"text-gray-600 ml-5 sm:ml-9 mr-10 sm:mr-14"}
          css={largerTextCss}
        >
          Get the mobile app to get gift updates and track birthdays
        </div>
        <div tw={"mr-4 sm:mr-12"}>
          <AppDownload />
        </div>
      </a>
    </SecondaryContainer>
  )
}

export const StatusButton = styled(Link)`
  ${tw`rounded-full mt-9 w-full flex flex-row items-center text-xl font-medium`}
  border-color: #c98dc8;
  color: #c98dc8;
  max-width: 200px;
  height: 50px;

  &:active,
  &:hover {
    ${tw`opacity-50`}
  }
`

const NotificationsContainer = styled(MessageContainer)`
  ${tw`border-none hidden`}
  max-width: 470px;
  background: linear-gradient(90deg, #e4a1b8 0%, #b37dd5 100%);
`

const SecondaryContainer = styled(MessageContainer)`
  @media (min-width: 640px) {
    max-width: 470px;
  }
  ${tw`mt-7 flex flex-row items-center bg-white`};
`

const GetNotifiedContainer = styled.div`
  ${tw`font-medium`}
  ${largerTextCss}
  background: linear-gradient(90deg, #e4a1b8 -23.62%, #b37dd5 113.78%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const EnableNotifications = styled.button`
  ${tw`text-lg mt-4`}
  background: linear-gradient(90deg, #e4a1b8 -23.62%, #b37dd5 113.78%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const GiftMessage = styled.div`
  background: url(${SpeechBubble});
  background-repeat: no-repeat;
  background-size: contain;
  padding: 12px 36px 9px 24px;
  ${tw`mr-6`}
`

export default SendComplete
