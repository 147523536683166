import React from "react"
import { Route } from "react-router-dom"

type RouteTag = "send"

type Props = Route["props"] & {
  tags?: RouteTag[]
}

const TaggedRouteContext = React.createContext<RouteTag[]>([])

export const TaggedRoute = ({ tags, ...restProps }: Props) => {
  return (
    <TaggedRouteContext.Provider value={tags ?? []}>
      <Route {...restProps} />
    </TaggedRouteContext.Provider>
  )
}

export const useRouteHasTag = (tag: RouteTag) => {
  const tags = React.useContext(TaggedRouteContext) ?? []
  return tags.includes(tag)
}
