import { useLocation } from "react-router-dom"

export const useIsBusinessSignup = () => {
  const location = useLocation()

  const isBusinessSignupPath = location.pathname === "/business/signup"

  return {
    isBusinessSignupPath,
  }
}
