import "twin.macro"

import InteractiveProductVariants from "../components/InteractiveProductVariants"
import ProductVariants from "../components/ProductVariants"

import { Store_PDP_ProductDetailsFragment } from "@/types/graphql-types"

interface Props {
  product: Store_PDP_ProductDetailsFragment
  useVirtualUrl?: boolean
  selectedVariants: string[] | null
  setSelectedVariants: (selectedVariants: string[] | null) => void
}

export default function ProductVariantsDisplay({
  product,
  useVirtualUrl,
  selectedVariants,
  setSelectedVariants,
}: Props) {
  const isSwagTemplate = !!product.swagIntegration?.id

  return (
    <div tw="mt-4">
      {product.variants.length > 0 && (
        <>
          {useVirtualUrl ? (
            <ProductVariants
              variants={product.variants}
              variantsLabel={product.variantsLabel}
              variantGroups={product.variantGroups}
            />
          ) : (
            <InteractiveProductVariants
              variants={product.variants}
              variantsLabel={product.variantsLabel}
              variantsNumSelectable={product.variantsNumSelectable}
              variantGroups={product.variantGroups}
              selectedVariants={selectedVariants}
              setSelectedVariants={setSelectedVariants}
              hideLetRecipientDecide={
                isSwagTemplate || product.isOfflineProduct
              }
              imagesScalable={product.imagesScalable}
            />
          )}
        </>
      )}
    </div>
  )
}
