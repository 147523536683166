import { gql, useLazyQuery } from "@apollo/client"
import moment from "moment"
import { useEffect, useState } from "react"
import Modal from "react-modal"
import nl2br from "react-nl2br"
import tw, { styled } from "twin.macro"

import { ReactComponent as EditIcon } from "../../assets/icons/edit-purple.svg"
import Button from "../../common/Button"
import {
  formatScheduledSendDate,
  formatScheduledSendDateShort,
  isBlank,
} from "../../common/format"
import { useGlobalState } from "../../common/GlobalState"
import { modalStyle } from "../../common/modal"
import GiftBatchEditModal from "../../track/components/GiftBatchEditModal"
import { GiftBatchEditModalData } from "../../track/Index"

import { EventKind } from "@/types/graphql-types"
import {
  AutogiftUpcoming_GiftBatchesQuery,
  AutogiftUpcoming_GiftBatchesQueryVariables,
} from "@/types/graphql-types"

const AutogiftUpcoming = () => {
  const [currentAutogiftRule] = useGlobalState("currentAutogiftRule")

  const [getUpcoming, { data, loading }] = useLazyQuery<
    AutogiftUpcoming_GiftBatchesQuery,
    AutogiftUpcoming_GiftBatchesQueryVariables
  >(GIFT_BATCHES_QUERY)

  useEffect(() => {
    if (currentAutogiftRule.contactListId && currentAutogiftRule.eventType) {
      let eventType: EventKind | null = null

      // Translate event type to EventKind enum.
      // TODO use real enums for currentAutogiftRule.eventType
      if (currentAutogiftRule.eventType === "birthday") {
        eventType = EventKind.BIRTHDAY
      } else if (currentAutogiftRule.eventType === "work_anniversary") {
        eventType = EventKind.WORK_ANNIVERSARY
      } else if (currentAutogiftRule.eventType === "onboarding") {
        eventType = EventKind.ONBOARDING
      }

      if (eventType) {
        getUpcoming({
          variables: {
            contactListID: currentAutogiftRule.contactListId,
            eventType,
          },
        })
      }
    }
  }, [currentAutogiftRule, currentAutogiftRule?.contactListId])

  const giftBatches = data?.workspace?.contactList?.autogiftGiftBatches

  return (
    <div tw="flex flex-col items-center">
      {giftBatches ? (
        <div tw="mt-16">
          <GiftBatches loading={!data && loading} giftBatches={giftBatches} />
        </div>
      ) : (
        <div tw="mt-16" />
      )}
      <div
        tw="flex flex-col justify-center items-center text-gray-500 mb-16"
        css={{ lineHeight: "140%", width: 451 }}
      >
        <div tw="text-center">
          Autogift gifts are created 30 days before the event date.
        </div>
      </div>
    </div>
  )
}

type AutogiftGiftBatches = NonNullable<
  AutogiftUpcoming_GiftBatchesQuery["workspace"]
>["contactList"]["autogiftGiftBatches"]
type AutogiftGiftBatch = NonNullable<AutogiftGiftBatches>[0]

interface GiftBatchesProps {
  giftBatches: AutogiftGiftBatch[]
  loading: boolean
}

const GiftBatches = ({ giftBatches }: GiftBatchesProps) => {
  const [giftBatchEditModalOpen, setGiftBatchEditModalOpen] =
    useState<boolean>(false)

  // State used when clicking on a gift batch to open edit modal (for scheduled sends)
  const [currentEditingGiftBatch, setCurrentEditingGiftBatch] =
    useState<GiftBatchEditModalData | null>()

  const handleRecipientName = (giftBatch: AutogiftGiftBatch) => {
    if (giftBatch.firstRecipientFullName) {
      return giftBatch.firstRecipientFullName
    } else {
      return "Name not provided"
    }
  }

  const formatProductName = (brandName: string, productName: string) => {
    const totalLength = brandName.length + productName.length
    if (totalLength > 34) {
      const difference = 34 - brandName.length
      return productName.substring(0, difference) + "..."
    } else {
      return productName
    }
  }

  const closeGiftBatchEditModal = () => {
    setGiftBatchEditModalOpen(false)
  }

  const giftBatchName = (
    batchName: string | null,
    batchNameFallback: string,
  ) => {
    if (isBlank(batchName) || batchName === null) {
      return batchNameFallback
    } else {
      return batchName
    }
  }

  return (
    <div>
      {giftBatches.map((giftBatch) => (
        <div key={giftBatch.id}>
          <GiftBatchContainer>
            {giftBatch.cardFragment && (
              <div
                tw="absolute flex flex-col justify-center top-2 bottom-2 pointer-events-none"
                css={{ marginLeft: 410, zIndex: 1100 }}
              >
                <img
                  src={giftBatch.cardFragment.image.url}
                  css={[
                    {
                      width: 98,
                      imageRendering: "-webkit-optimize-contrast",
                    },
                    tw`rounded-lg shadow-lg pointer-events-auto`,
                  ]}
                />
              </div>
            )}
            <div tw="absolute flex flex-col justify-end items-center top-2 right-2 bottom-2">
              <div
                tw="absolute bg-white"
                css={{
                  width: 252,
                  zIndex: 1001,
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingTop: 5,
                  paddingBottom: 4,
                  marginBottom: 2,
                  borderBottomRightRadius: 10,
                  borderBottomLeftRadius: 10,
                  borderTopLeftRadius: 2,
                  borderTopRightRadius: 2,
                }}
              >
                <ProductLabelText>
                  <div tw="text-gray-400">
                    {giftBatch.productFragment.brandName}
                  </div>
                  <div tw="text-gray-400 mx-1">—</div>
                  <div>
                    {formatProductName(
                      giftBatch.productFragment.brandName,
                      giftBatch.productFragment.name,
                    )}
                  </div>
                </ProductLabelText>
              </div>
              <div tw="w-64 h-full">
                <img
                  src={
                    giftBatch.productFragment.primaryImage?.imageLarge.url ??
                    giftBatch.productFragment.customStoreImageThumb?.url
                  }
                  tw="object-cover overflow-hidden h-full w-full rounded-xl"
                />
              </div>
            </div>
            <div
              tw="flex flex-row items-center mt-3 text-gray-500 text-sm pl-5"
              css={{ lineHeight: "17px" }}
            >
              <div tw="font-medium">
                {formatScheduledSendDateShort(giftBatch.scheduledSendOn)}
              </div>
              <div tw="mx-2">·</div>
              <div tw="">
                {moment(new Date(giftBatch.scheduledSendOn)).fromNow()}
              </div>
            </div>
            <div
              tw="mb-2 ml-5 text-2xl"
              css={{ marginTop: 10, lineHeight: "29px" }}
            >
              {handleRecipientName(giftBatch)}
            </div>
            {giftBatch.tenure !== null && (
              <div tw="mt-1 mb-3 text-gray-500 text-sm pl-5 text-primary-500">
                {giftBatch.tenure} year work anniversary
              </div>
            )}
            <div
              tw="mt-auto p-5"
              css={{
                backgroundColor: "#F9FAFB",
                borderBottomLeftRadius: 16,
                borderBottomRightRadius: 16,
              }}
            >
              <div tw="mb-2" css={{ width: "55%" }}>
                {nl2br(giftBatch.message)}
              </div>
              <div
                tw="text-gray-400 text-sm"
                css={{ lineHeight: "16.8px", marginBottom: 21 }}
              >
                {giftBatch.fromName}
              </div>
              <div tw="flex flex-col items-start">
                <Button
                  tw="text-primary-500"
                  onClick={() => {
                    setGiftBatchEditModalOpen(true)
                    setCurrentEditingGiftBatch({
                      id: giftBatch.id,
                      batchName: giftBatch.batchName,
                      customEmailSubject: giftBatch.customEmailSubject,
                      batchNameFallback: giftBatch.batchNameFallback,
                      scheduledSendOn: giftBatch.scheduledSendOn,
                      createdAt: giftBatch.createdAt,
                    })
                  }}
                >
                  <EditIcon />
                  <div>Edit gift</div>
                </Button>
              </div>
            </div>
          </GiftBatchContainer>
        </div>
      ))}
      <Modal
        isOpen={giftBatchEditModalOpen}
        closeTimeoutMS={500}
        onRequestClose={closeGiftBatchEditModal}
        shouldCloseOnOverlayClick={true}
        style={modalStyle}
      >
        {currentEditingGiftBatch && (
          <GiftBatchEditModal
            giftBatchId={currentEditingGiftBatch.id}
            giftBatchName={giftBatchName(
              currentEditingGiftBatch.batchName ?? null,
              currentEditingGiftBatch.batchNameFallback,
            )}
            scheduledSendOn={formatScheduledSendDate(
              currentEditingGiftBatch.scheduledSendOn,
            )}
            closeGiftBatchEditModal={closeGiftBatchEditModal}
          />
        )}
      </Modal>
    </div>
  )
}

const GiftBatchContainer = styled.div`
  background: white;
  box-shadow:
    0px 1px 4px rgba(0, 0, 0, 0.04),
    0px 8px 36px rgba(0, 0, 0, 0.06);
  width: 721px;
  min-height: 271px;
  ${tw`flex flex-col rounded-2xl mb-16 pt-2 relative`};
`

const ProductLabelText = styled.div`
  line-height: 140%;
  letter-spacing: -0.01em;
  ${tw`flex flex-row text-xs whitespace-nowrap overflow-hidden`}
`

export const GIFT_BATCHES_QUERY = gql`
  query AutogiftUpcoming_GiftBatches(
    $contactListID: ID!
    $eventType: EventKind!
  ) {
    workspace {
      contactList(id: $contactListID) {
        id
        autogiftGiftBatches(eventType: $eventType) {
          id
          firstRecipientFullName
          batchName
          batchNameFallback
          customEmailSubject
          createdAt
          scheduledSendOn
          message
          userFullName
          fromName
          productFragment {
            name
            brandName
            isFlexGift
            primaryImage {
              imageLarge {
                url
              }
            }
            customStoreImageThumb {
              url
            }
          }
          cardFragment {
            image {
              url
            }
          }
          flexGiftPrice
          tenure
        }
      }
    }
  }
`

export default AutogiftUpcoming
