import React, { FC, useEffect } from "react"
import { Link } from "react-router-dom"
import tw, { styled } from "twin.macro"

interface Props {
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void
  disabled: boolean
  href: string
  selected: boolean
  selectedSubcategorySlug: string | null
  preventScrollIntoView?: boolean
}

const CategoryLink: FC<Props> = ({
  children,
  disabled,
  href,
  onClick,
  selected,
  selectedSubcategorySlug,
  preventScrollIntoView,
}) => {
  const ref = React.useRef<HTMLAnchorElement>(null)

  useEffect(() => {
    if (
      !selected ||
      Boolean(selectedSubcategorySlug) ||
      preventScrollIntoView
    ) {
      return
    }

    ref.current?.scrollIntoView({
      behavior: window.innerWidth >= 1024 ? "smooth" : "auto",
      block: "nearest",
      inline: "nearest",
    })
  }, [selected, selectedSubcategorySlug])

  return (
    <LinkContainer
      disabled={disabled}
      to={href}
      onClick={onClick}
      ref={ref}
      selected={selected}
    >
      {children}
    </LinkContainer>
  )
}

const LinkContainer = styled(Link)<{ disabled: boolean; selected: boolean }>`
  ${tw`
    flex flex-col mb-1 lg:flex-row lg:gap-3 items-center p-4 px-1 lg:py-0 lg:px-5 lg:rounded-lg lg:text-left
    outline-none transition-all text-lg hover:text-primary-700 active:text-primary-700 shadow-none border-transparent
  `}

  ${({ disabled }) => disabled && tw`opacity-40 grayscale pointer-events-none`}
  ${({ selected }) =>
    selected &&
    tw`font-medium text-primary-500 lg:(border-t-0 text-primary-700)`}
`

export default CategoryLink
