import { gql, useMutation } from "@apollo/client"

import { CURRENT_REALM_KEY } from "./realmConstants"
import { useGlobalState } from "../GlobalState"

import { Realm } from "."

import { RealmTtype } from "@/types/graphql-types"

export const useSetRealm = () => {
  const [currentRealm, setCurrentRealm] = useGlobalState("currentRealm")
  const [setLastRealm] = useMutation(SET_LAST_REALM)

  return (realm: Realm) => {
    if (realm !== currentRealm) {
      setCurrentRealm(realm)
      if (realm === "consumer" || realm === "plus" || realm === "business") {
        setSavedRealm(realm)

        setLastRealm({
          variables: {
            lastRealm: realm === "plus" ? RealmTtype.PLUS : RealmTtype.CONSUMER,
          },
        })
      }
    }
  }
}

// Forces session/localStorage realm
const setSavedRealm = (realm: Realm) => {
  window.sessionStorage.setItem(CURRENT_REALM_KEY, realm)
  window.localStorage.setItem(CURRENT_REALM_KEY, realm)
}

const SET_LAST_REALM = gql`
  mutation Realm_SetLast($lastRealm: RealmTtype!) {
    setLastRealm(lastRealm: $lastRealm) {
      ok
    }
  }
`
