import { useLazyQuery } from "@apollo/client"
import { add, isPast, isToday, set } from "date-fns"
import { useCallback, useEffect, useState } from "react"
import toast from "react-hot-toast"

import { CONTACT_LISTS_QUERY } from "./queries"
import { EventType, SaveResult } from "./types/Contact"
import { useGlobalState } from "../common/GlobalState"
import { useCurrentWorkspace } from "../common/hooks"
import { generateEmptyRecipients, isEmptyRecipient } from "../common/recipient"

import {
  Contacts_ContactListsQuery,
  Contacts_ContactListsQueryVariables,
  StandardContactFragment,
} from "@/types/graphql-types"

export const getNextDate = ({
  day,
  month,
  year,
  type,
}: {
  day: number
  month: number
  year?: number | null
  type: EventType
}) => {
  // For onboarding events (start date and added to hris date), use the exact saved date
  // If past, return null
  if (year !== null && (type === "startDate" || type === "addedToHrisDate")) {
    const onboardingDate = set(new Date(), {
      month: month - 1,
      date: day,
      year: year,
    })

    if (isPast(onboardingDate)) {
      return null
    }
    return onboardingDate
  }

  // For other events (birthday, work anniversary), return this year's date
  const thisYearsDate = set(new Date(), { month: month - 1, date: day })

  if (isPast(thisYearsDate) && !isToday(thisYearsDate)) {
    return add(thisYearsDate, { years: 1 })
  }

  return thisYearsDate
}

export const eventTypeLabels: {
  [eventType in EventType]: string
} = Object.freeze({
  birthday: "Birthday",
  workAnniversary: "Work anniversary",
  startDate: "Start date",
  addedToHrisDate: "Added to HR",
})

const eventTypes: readonly EventType[] = [
  "birthday",
  "workAnniversary",
  "startDate",
  "addedToHrisDate",
]

interface ContactEvent {
  date: Date
  type: EventType
}

// Determine the contact's next event
export const nextEvent = (contact: StandardContactFragment) => {
  let nextEvents: ContactEvent[] = []

  // Loop through all of contact's events and calculate the next occurrence of that event
  // Build an upcoming events array which saves the next occurrence date and event type of each event
  // Past onboarding events (start date and added to hris date) will be removed
  eventTypes
    .filter((type) => contact[type] != null)
    .forEach((type) => {
      const { day, month, year } = contact[type]!
      const nextDate = getNextDate({ day, month, year, type })

      if (nextDate !== null) {
        nextEvents.push({ date: nextDate, type })
      }
    })

  if (nextEvents.length === 0) {
    return null
  }

  // Sort the events first dy date ascending. For equal dates, prioritize startDate type, then addedToHrisDate type
  const sortedEvents = nextEvents.sort((a, b) => {
    // First sort dates in ascending order with the closest date from today first
    // If date a is greater than date b, then sort b first
    if (a.date > b.date) {
      return 1
    }

    // If the dates are equal, then first prioritize the start date
    if (a.date.getTime() === b.date.getTime()) {
      if (a.type === "startDate") {
        return -1
      } else if (b.type === "startDate") {
        return 1
        // Otherwise, prioritize the addedToHrisDate
      } else if (a.type === "addedToHrisDate") {
        return -1
      } else if (b.type === "addedToHrisDate") {
        return 1
      }
    }
    // If we reach here, then date a is less than date b, so sort a first
    return -1
  })

  // Return the first event
  return sortedEvents[0]
}

export const useRecipients = () => {
  const [recipients, setRecipients] = useGlobalState("recipients")

  const addRecipient = useCallback(
    (contact: StandardContactFragment) => {
      const { id, firstName, phone, email } = contact
      const lastName = contact.lastName || ""
      setRecipients((previousRecipients) => {
        const preservedRecipients = previousRecipients.filter(
          (recipient) => !isEmptyRecipient(recipient),
        )
        // Add at least one empty recipient at the end but no more than 2,
        // depending on the number of preserved recipients.
        const numEmptyRecipients = Math.max(2 - preservedRecipients.length, 1)

        return [
          // First keep the existing previous recipients,
          ...preservedRecipients,
          // then append the contact as recipient,
          {
            id,
            firstName: firstName || "",
            lastName: lastName || "",
            phone: phone || "",
            email: email || "",
            scheduledEventId: null,
            scheduledEventKind: null,
            eventDate: null,
            errors: {},
          },
          // finally insert some empty rows.
          ...generateEmptyRecipients(numEmptyRecipients),
        ]
      })
    },
    [setRecipients],
  )

  const removeRecipient = useCallback(
    (contact: StandardContactFragment) => {
      setRecipients((previousRecipients) =>
        previousRecipients.filter((recipient) => recipient.id !== contact.id),
      )
    },
    [setRecipients],
  )

  const hasRecipient = useCallback(
    (contact: StandardContactFragment) =>
      recipients.some((recipient) => recipient.id === contact.id),
    [recipients],
  )

  return { addRecipient, hasRecipient, removeRecipient }
}

export const useContactLists = () => {
  const [currentListID, setCurrentListID] = useState<string | null>(null)
  const { currentWorkspace } = useCurrentWorkspace()

  const [refetchList, { data: listData }] = useLazyQuery<
    Contacts_ContactListsQuery,
    Contacts_ContactListsQueryVariables
  >(CONTACT_LISTS_QUERY)

  // Whenever the workspace changes, reset lists.
  useEffect(() => {
    setCurrentListID(null)
    refetchList()
  }, [currentWorkspace?.id, refetchList])

  const contactLists: ContactList[] = [
    {
      name: "All contacts",
      id: null,
      contactsCount: listData?.workspace?.contactsCount || 0,
      hrisSynced: false,
    },
    ...(listData?.workspace?.contactLists || []).map((list) => ({
      name: list.name,
      id: list.id,
      contactsCount: list.contactsCount,
      hrisSynced: list.hrisSynced ?? false,
    })),
  ]

  const currentList =
    contactLists.find((list) => list.id === currentListID) || null

  return { contactLists, currentList, setCurrentListID }
}

export interface ContactList {
  id: string | null
  name: string
  contactsCount: number
  hrisSynced: boolean | null
}

export type CardMode = "view" | "edit"

export const handleGraphQLResponse = (
  result: SaveResult | null | undefined,
  successMessage: string,
) => {
  if (result == null) {
    return
  }

  const { ok, error, errors } = result
  const displayError = error || errors?.join(", ")

  if (ok) {
    toast.success(successMessage)
  } else if (displayError) {
    toast.error(displayError)
  }
}
