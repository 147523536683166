import React from "react"

import { getCartProductImage } from "@/common/cartProducts"
import { ProductImage } from "@/store/ProductImage"
import {
  CartProductShipmentFragment,
  ImageFragment,
} from "@/types/graphql-types"

type Props = {
  cartProduct: CartProductShipmentFragment
  customStoreImageThumb?: ImageFragment | null
} & Omit<
  React.ComponentProps<typeof ProductImage>,
  "image" | "alt" | "scalable" | "contain"
>
export const CartProductProductImage = ({
  cartProduct,
  customStoreImageThumb,
  ...restProps
}: Props) => {
  const productImage = getCartProductImage(cartProduct, customStoreImageThumb)

  return (
    productImage && (
      <ProductImage
        alt={cartProduct.productTitle}
        image={productImage}
        scalable={cartProduct.productFragment.imagesScalable}
        addSpacing={cartProduct.productDetails.isBook}
        {...restProps}
      />
    )
  )
}
