import React from "react"
import tw, { styled } from "twin.macro"

const CTABanner: React.FC = ({ children }) => {
  return <Container>{children}</Container>
}

const Container = styled.div`
  ${tw` rounded-xl w-full flex flex-col text-primary-new-850 justify-center px-6 text-center py-12 lg:py-[4.5rem] my-8 lg:my-16 `}
  background: linear-gradient(
    124.4deg,
    rgba(170, 194, 255, 0.15) 1.9%,
    rgb(250, 187, 255, 0.15) 100%
  );
`

export default CTABanner
