import React, { useContext } from "react"

import { ReactComponent as Icon } from "./icons/gift-name.svg"
import OptionBase from "./OptionBase"
import { useGiftData } from "../../../common/hooks/giftData"
import { OptionsContext } from "../OptionsContext"

export default function GiftName() {
  const { currentGift } = useContext(OptionsContext)
  const { setBatchName } = useGiftData()

  return (
    <OptionBase
      name="Gift name"
      icon={<Icon />}
      preview={
        <div>
          <input
            tw="w-full p-0 focus:outline-none"
            className="data-hj-suppress ph-no-capture fs-mask"
            placeholder="Optional (not displayed to recipients)"
            value={currentGift.batchName || ""}
            onChange={(e) => setBatchName(e.target.value)}
          />
        </div>
      }
      isTextInput={true}
    />
  )
}
