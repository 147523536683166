import { useState } from "react"
import { useInView } from "react-intersection-observer"

import { Image } from "@/types/graphql-types"

interface Props {
  image?: Image
  scrollElemRef: React.RefObject<HTMLDivElement>
}

export const ProductImage = ({ image, scrollElemRef }: Props) => {
  const [loaded, setLoaded] = useState(false)

  const { ref, inView } = useInView({
    rootMargin: "1500px",
    root: scrollElemRef.current,
    triggerOnce: true,
  })

  if (!image || !image.height || !image.width) {
    return null
  }

  return (
    <div
      ref={ref}
      tw="overflow-hidden lg:(rounded-xl transition-all)"
      className={loaded ? "lazy-image-loaded" : "lazy-image-loading"}
    >
      {inView && (
        <img
          src={image.url}
          tw="w-full h-full object-cover"
          alt=""
          onLoad={() => setLoaded(true)}
        />
      )}
    </div>
  )
}
