import { gql, useMutation, useQuery } from "@apollo/client"
import moment from "moment"
import React from "react"
import { useHistory } from "react-router-dom"
import tw from "twin.macro"

import { ReactComponent as EditIcon } from "../../assets/icons/edit-gray.svg"
import { ReactComponent as XIcon } from "../../assets/icons/x-gray-large.svg"
import { getProduct } from "../../common/gifts"
import {
  BatchRecipient,
  CurrentGift,
  useGlobalState,
} from "../../common/GlobalState"
import { useCurrentGift } from "../../common/hooks/currentGift"
import { useGiftCart } from "../../common/hooks/giftData"
import { BASE_GIFT_BATCH_FRAGMENT } from "../../common/queries"
import { generateRealmPath } from "../../common/realm"
import { successToast } from "../../common/toast"
import { generateUUID } from "../../common/utilities"
import { ROOT_DATA_QUERY } from "../../graphql"
import { GIFT_BATCHES_QUERY, perPage } from "../Index"

import {
  GiftBatchEditModal_GiftBatchCancelMutation,
  GiftBatchEditModal_GiftBatchCancelMutationVariables,
  GiftBatchEditModal_GiftBatchQuery,
  GiftBatchEditModal_GiftBatchQueryVariables,
  MailingAddress,
} from "@/types/graphql-types"

interface Props {
  giftBatchId: string
  giftBatchName: string
  scheduledSendOn: string
  closeGiftBatchEditModal: () => void
  cancelOptionOnly?: boolean
  refetchGifts?: () => void
}

const GiftBatchEditModal: React.FC<Props> = ({
  giftBatchId,
  giftBatchName,
  scheduledSendOn,
  closeGiftBatchEditModal,
  cancelOptionOnly,
  refetchGifts,
}) => {
  const [currentGift] = useCurrentGift()
  const [, setSendPageMode] = useGlobalState("sendPageMode")
  const [recipients, setRecipients] = useGlobalState("recipients")

  const { data } = useQuery<
    GiftBatchEditModal_GiftBatchQuery,
    GiftBatchEditModal_GiftBatchQueryVariables
  >(GIFT_BATCH_QUERY, {
    variables: { id: giftBatchId },
    fetchPolicy: "network-only",
  })
  const currentGiftBatch = data?.workspace?.giftBatch || null

  const [giftBatchCancel] = useMutation<
    GiftBatchEditModal_GiftBatchCancelMutation,
    GiftBatchEditModal_GiftBatchCancelMutationVariables
  >(CANCEL_GIFT_BATCH_MUTATION)

  const history = useHistory()

  const { editWithGiftBatch } = useGiftCart()

  // Update null address fields to blank strings for non-nullable fields
  function processAddressFieldsNullToBlank(
    address: MailingAddress | null | undefined,
  ) {
    if (!address) {
      return null
    }

    return {
      address1: address.address1 || "",
      address2: address.address2 ?? null,
      city: address.city || "",
      state: address.state || "",
      postalCode: address.postalCode || "",
    }
  }

  // load the gift batch data in to global vars to load when editing on Send page
  const loadGiftBatchData = () => {
    if (currentGiftBatch) {
      editWithGiftBatch(currentGiftBatch)

      // set recipient list
      if (currentGiftBatch.scheduledSendRecipientList) {
        const recipientList: BatchRecipient[] = []
        currentGiftBatch.scheduledSendRecipientList.map((recip) => {
          recipientList.push({
            id: generateUUID(),
            firstName: recip.firstName,
            lastName: recip.lastName || "",
            phone: recip.phone || "",
            email: recip.email || "",
            mailingAddress:
              processAddressFieldsNullToBlank(recip.mailingAddress) || null,
            errors: {},
            scheduledEventId: currentGiftBatch.scheduledEvent?.id || null,
            scheduledEventKind: currentGiftBatch.scheduledEvent?.kind || null,
            eventDate: currentGiftBatch.event?.date
              ? moment(currentGiftBatch.event?.date).toDate()
              : null,
          })
        })
        setRecipients(recipientList)
      }
    }
  }

  // To edit gift batch we need to load the DB gift batch data into
  // global state, set the send page to update mode, then redirect to the send page
  const routeToViewAndEditGift = () => {
    loadGiftBatchData()
    setSendPageMode("updateGiftBatch")
    history.push(generateRealmPath("plus", "/send"))
    closeGiftBatchEditModal()
  }

  const cancelGiftBatch = async (id: string) => {
    const confirmed = window.confirm(
      "Are you sure you want to cancel this gift batch?",
    )

    if (!confirmed) {
      return
    }

    const res = await giftBatchCancel({
      variables: {
        id: id,
      },
      refetchQueries: [
        {
          query: GIFT_BATCHES_QUERY,
          variables: {
            page: 1,
            perPage: perPage,
            onlyMyGifts: true,
          },
        },
        {
          query: GIFT_BATCHES_QUERY,
          variables: {
            page: 1,
            perPage: perPage,
            onlyMyGifts: false,
          },
        },
        {
          query: ROOT_DATA_QUERY,
        },
      ],
    })

    if (res?.data?.giftBatchCancel?.ok) {
      closeGiftBatchEditModal()
      successToast("Gift batch canceled.")
    } else {
      alert("An error occurred.")
    }
  }

  const cancelGift = async (id: string) => {
    const confirmed = window.confirm(
      "Are you sure you want to cancel this gift?",
    )

    if (!confirmed) {
      return
    }

    const res = await giftBatchCancel({ variables: { id } })

    if (res?.data?.giftBatchCancel?.ok) {
      !!refetchGifts && refetchGifts()
      closeGiftBatchEditModal()
      successToast("Gift canceled.")
    } else {
      alert("An error occurred.")
    }
  }

  const handleCancelClick = () => {
    if (cancelOptionOnly) {
      cancelGift(giftBatchId)
    } else {
      currentGiftBatch && cancelGiftBatch(currentGiftBatch.id)
    }
  }

  const nonBlankRecipientsCount = (recipients: BatchRecipient[]) => {
    let count = 0
    recipients.map((recipient: BatchRecipient) => {
      if (recipient.firstName !== "") {
        count++
      }
    })
    return count
  }

  // conditional logic to display warning that current gift send will be overwritten
  const displayWarning = (
    currentGift: CurrentGift,
    recipients: BatchRecipient[],
  ) => {
    return (
      currentGift.message !== "" ||
      currentGift.fromName !== "" ||
      getProduct(currentGift)?.id ||
      currentGift.card?.id ||
      nonBlankRecipientsCount(recipients) > 0
    )
  }

  return (
    <div className="modal-content modal-content-wide">
      <div tw="flex flex-col items-center p-8">
        <div
          tw="text-2xl font-medium text-center"
          className="data-hj-suppress ph-no-capture fs-mask"
        >
          {giftBatchName}
        </div>
        <div tw="text-base mt-4 font-medium" css={{ color: "#E1A900" }}>
          Scheduled for
        </div>
        <div>{scheduledSendOn}</div>
        {/* Disable and fade out until data is available. */}
        <div tw="w-full" css={[!data && tw`opacity-50 pointer-events-none`]}>
          {!cancelOptionOnly && (
            <button
              onClick={routeToViewAndEditGift}
              tw="mt-8 border border-gray-300 hover:border-primary-400 focus:outline-none focus-visible:border-primary-400 active:bg-primary-000 rounded-xl block w-full transition-colors"
            >
              <div tw="px-8 py-4">
                <div tw="flex flex-row">
                  <div tw="flex justify-center items-center pr-8">
                    <EditIcon />
                  </div>
                  <div tw="flex flex-col justify-center items-start">
                    <div>View and edit gift</div>
                    <div tw="text-gray-500 text-sm mt-1 text-left">
                      View and edit product, recipients, name, message, or
                      scheduled time.
                    </div>
                    {displayWarning(currentGift, recipients) && (
                      <div tw="text-sm mt-1" css={{ color: "#F2994A" }}>
                        This will replace your current gift send.
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </button>
          )}
          <button
            onClick={() => handleCancelClick()}
            tw="mt-8 border border-gray-300 hover:border-primary-200 focus:outline-none focus-visible:border-primary-200 active:bg-primary-000 rounded-xl block w-full transition-colors"
          >
            <div tw="px-8 py-4">
              <div tw="flex flex-row">
                <div tw="flex justify-center items-center pr-8">
                  <XIcon />
                </div>
                <div tw="flex flex-col justify-center items-start">
                  <div>Cancel gift</div>
                  <div tw="text-gray-500 text-sm mt-1">
                    Cancel this scheduled gift.
                  </div>
                </div>
              </div>
            </div>
          </button>
        </div>
      </div>
    </div>
  )
}

export const GIFT_BATCH_QUERY = gql`
  query GiftBatchEditModal_GiftBatch($id: ID!) {
    workspace {
      giftBatch(id: $id) {
        ...Base_GiftBatch
        batchName
        expiresAt
        autopayPaymentMethodName
        autopayPaymentMethodId
        swapEnabled
        swapType
        landingPageEnabled
        landingPageSendNotifs
        landingPageSlug
        isScheduledSend
        scheduledSendOn
        emailDeliveryMethod
        productUnavailable
        isAutogift
        customEmailSubject
        meetingSettings {
          giftSetting
        }
        scheduledEvent {
          id
          relevantRecurrence
          kind
        }
        event {
          date
        }
        scheduledSendRecipientList {
          firstName
          lastName
          email
          phone
          mailingAddress {
            address1
            address2
            city
            state
            postalCode
          }
        }
        userPublicId
        priceEstimate {
          priceProduct
        }
        sendMethod
        sendAudience
      }
    }
  }
  ${BASE_GIFT_BATCH_FRAGMENT}
`

const CANCEL_GIFT_BATCH_MUTATION = gql`
  mutation GiftBatchEditModal_GiftBatchCancel($id: ID!) {
    giftBatchCancel(id: $id) {
      ok
    }
  }
`

export default GiftBatchEditModal
