import React, { useEffect, useState } from "react"

import {
  Countries,
  CountriesLongName,
} from "./giftOptionFilters/internationalShipping/InternationalShipping"
import { useShippingCountries } from "./providers/ShippingCountriesProvider"

import { useFeatureFlags } from "@/common/hooks/featureFlags"
import { ShippingCountry } from "@/types/graphql-types"

type CountriesDispatch = React.Dispatch<React.SetStateAction<Countries>>
interface ContextProps {
  selectedShippingCountry: Countries
  setSelectedShippingCountry: CountriesDispatch
  giftOptionSelectedShippingCountry: Countries
  setGiftOptionSelectedShippingCountry: CountriesDispatch
}

const DEFAULT_COUNTRY = Countries.UNITED_STATES
const UNDEFINED_SET = () => undefined

const Context = React.createContext<ContextProps>({
  selectedShippingCountry: DEFAULT_COUNTRY,
  setSelectedShippingCountry: UNDEFINED_SET,
  giftOptionSelectedShippingCountry: DEFAULT_COUNTRY,
  setGiftOptionSelectedShippingCountry: UNDEFINED_SET,
})

interface Props {
  children: React.ReactNode
}
export const GiftOptionCountryProvider = ({ children }: Props) => {
  const { hasFeatureFlag } = useFeatureFlags()
  const {
    selectedShippingCountry: selectedShippingCountryV2,
    setSelectedShippingCountry: setSelectedShippingCountryV2,
    popoverShippingCountry,
    setPopoverShippingCountry,
    shippingCountries,
  } = useShippingCountries()

  const [selectedShippingCountry, setSelectedShippingCountry] =
    useState<Countries>(DEFAULT_COUNTRY)

  const [
    giftOptionSelectedShippingCountry,
    setGiftOptionSelectedShippingCountry,
  ] = useState(selectedShippingCountry)

  useEffect(() => {
    setGiftOptionSelectedShippingCountry(selectedShippingCountry)
  }, [selectedShippingCountry])

  // This update should make the changes backwards compatibile with the
  // existing code

  const syncLegacySelectedCountries = (
    countryV1: Countries,
    countryV2: ShippingCountry,
    setCountryV1: (countryV1: Countries) => void,
    setCountryV2: (countryV2: ShippingCountry) => void,
  ) => {
    if (countryV2.code === countryV1) return

    if (hasFeatureFlag("shipping_config_v2")) {
      setCountryV1(countryV2.code as Countries)
    } else {
      setCountryV2(
        shippingCountries.find((country) => country.code === countryV1) || {
          code: countryV1.toString(),
          name: CountriesLongName[countryV1].toString(),
          groups: [],
        },
      )
    }
  }

  useEffect(() => {
    syncLegacySelectedCountries(
      selectedShippingCountry,
      selectedShippingCountryV2,
      setSelectedShippingCountry,
      setSelectedShippingCountryV2,
    )
  }, [selectedShippingCountry, selectedShippingCountryV2])

  useEffect(() => {
    syncLegacySelectedCountries(
      giftOptionSelectedShippingCountry,
      popoverShippingCountry,
      setGiftOptionSelectedShippingCountry,
      setPopoverShippingCountry,
    )
  }, [giftOptionSelectedShippingCountry, popoverShippingCountry])

  return (
    <Context.Provider
      value={{
        selectedShippingCountry,
        setSelectedShippingCountry,
        giftOptionSelectedShippingCountry,
        setGiftOptionSelectedShippingCountry,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default GiftOptionCountryProvider
export const useCountry = () => React.useContext(Context)
