import { Link } from "react-router-dom"
import tw, { css, styled } from "twin.macro"

export const SectionContainer = tw.div`mx-5 pt-16 md:pt-24`

const Section = tw.div`w-full max-w-[900px] mx-auto p-8 pt-12 md:(p-16 pt-16) rounded-2xl md:rounded-[2rem] text-center`

export const SectionHeader = tw.h2`font-reckless-neue font-350 text-[2rem] md:text-[3rem] leading-[1.12] tracking-[-1px]`

export const SectionHeaderEm = tw.em`italic font-extralight`

export const SectionContent = tw.div`pt-6 md:pt-9 text-lg md:text-xl text-gray-800 max-w-[32rem] mx-auto flex flex-col items-center gap-6 leading-[1.5]!`

export const SectionPill = tw.div`px-4 py-0.5 font-medium tracking-[0.5px] mx-auto inline-block uppercase mb-4 rounded-full text-sm md:text-base`

export const SectionButtonCommon = css`
  ${tw`px-5 py-3 flex flex-row items-center gap-4 text-white rounded-xl text-lg font-medium active:scale-95 transition-all`}
`

export const SectionLink = styled(Link)`
  ${SectionButtonCommon}
`

export default Section
