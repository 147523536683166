import { isObject } from "lodash-es"
import { ReactNode } from "react"
import { CSSObject } from "styled-components/macro"
import tw from "twin.macro"

import { ReactComponent as CircleX } from "@/assets/icons/circle-x.svg"
import { ReactComponent as Info } from "@/assets/icons/info.svg"
import { ReactComponent as TriangleAlert } from "@/assets/icons/triangle-alert.svg"
import Image from "@/common/Image"

const VARIANTS: { [key: string]: CalloutVariant } = {
  info: {
    wrapper: tw`bg-blue-50 border-blue-500 hover:bg-blue-75 active:bg-blue-100`,
    title: tw`text-blue-500`,
    icon: <Info />,
  },
  warning: {
    wrapper: tw`bg-warning-callout-bg border-warning-callout hover:bg-warning-callout-bg-hover active:bg-warning-callout-bg-active`,
    title: tw`text-warning-callout`,
    icon: <TriangleAlert />,
  },
  error: {
    wrapper: tw`bg-red-50 border-red-600 hover:bg-red-75 active:bg-red-100`,
    title: tw`text-red-600`,
    icon: <CircleX />,
  },
}

interface CalloutProps {
  variant?: CalloutVariant | string
  title: string
  subtext?: string
}

interface CalloutVariant {
  wrapper: CSSObject | CSSObject[]
  title: CSSObject | CSSObject[]
  icon: ReactNode | string
}

const resolveVariant = (variant?: CalloutVariant | keyof typeof VARIANTS) =>
  isObject(variant) ? variant : VARIANTS[variant ?? "info"]

const Callout = ({ variant, title, subtext }: CalloutProps) => {
  const { icon, ...resolvedVariant } = resolveVariant(variant)

  return (
    <div
      css={[
        tw`border rounded-xl flex flex-col w-full gap-3 p-6 cursor-pointer`,
        resolvedVariant.wrapper,
      ]}
    >
      <div css={[tw`flex gap-4 items-center`, resolvedVariant.title]}>
        <div tw="w-6 h-6">
          {typeof icon == "string" ? <Image src={icon} /> : icon}
        </div>
        <div tw="text-lg leading-[22px] font-semibold">{title}</div>
      </div>
      {subtext && <div tw="pl-10">{subtext}</div>}
    </div>
  )
}

export default Callout
