import { useMutation } from "@apollo/client"
import * as Sentry from "@sentry/react"
import { useEffect, useState } from "react"

import { BalanceMessage } from "./BalanceMessage"
import { ORGANIZATION_BALANCE_DEPOSIT_MUTATION } from "./queries"
import SubmitButton from "./SubmitButton"
import { RECEIPTS_QUERY } from "../../../account/Receipts"
import AddPaymentMethodForm from "../../../common/billing/AddPaymentMethodForm"
import OmniPaymentMethodSelector from "../../../common/billing/OmniPaymentMethodSelector"
import useAddPaymentMethod from "../../../common/billing/useAddPaymentMethod"
import useOmniPaymentMethods from "../../../common/billing/useOmniPaymentMethods"
import Box from "../../../common/Box"
import { getCentsFromString } from "../../../common/currency"
import { successToast } from "../../../common/toast"
import { ROOT_DATA_QUERY } from "../../../graphql"
import { BUDGETING_INDEX_QUERY } from "../graphql"

import {
  BalanceDepositAccount,
  BalanceDepositFundingMethod,
} from "@/types/graphql-types"
import {
  Budgeting_OrganizationBalanceDepositMutation,
  Budgeting_OrganizationBalanceDepositMutationVariables,
} from "@/types/graphql-types"

// String: payment method ID
// "NEW" string special-case: new payment method
// null: not yet loaded
type SelectedPaymentMethodIDType = string | "NEW" | null

interface Props {
  amount: string
  accountType: BalanceDepositAccount
  onSuccess: (props: {
    amountInCents: number
    type: "credit_card" | "invoice"
  }) => void
}

export default function CreditCardForm({
  amount,
  accountType,
  onSuccess,
}: Props) {
  const [loading, setLoading] = useState(false)

  const amountInCents = getCentsFromString(amount)

  const [balanceDepositMutation] = useMutation<
    Budgeting_OrganizationBalanceDepositMutation,
    Budgeting_OrganizationBalanceDepositMutationVariables
  >(ORGANIZATION_BALANCE_DEPOSIT_MUTATION)

  const [selectedPaymentMethodID, setSelectedPaymentMethodID] =
    useState<SelectedPaymentMethodIDType>(null)

  const { paymentMethods, paymentMethodCount, defaultPaymentMethodID } =
    useOmniPaymentMethods("USER")

  useEffect(() => {
    // Only run this prep function when selectedPaymentMethodID is null, and if
    // we have fully loaded payment methods data.
    if (selectedPaymentMethodID === null && paymentMethods.me) {
      if (defaultPaymentMethodID) {
        setSelectedPaymentMethodID(defaultPaymentMethodID)
      } else {
        // No available payment methods. Set to new form.
        setSelectedPaymentMethodID("NEW")
      }
    }
  }, [paymentMethods, defaultPaymentMethodID, selectedPaymentMethodID])

  const { formFields, setFormField, setFormFields, preSubmit } =
    useAddPaymentMethod()

  // See SubscribePro prepareCard
  const prepareCard = async (): Promise<string | null | false> => {
    if (selectedPaymentMethodID === "NEW") {
      const interimCardToken = await preSubmit()

      if (!interimCardToken) {
        // Invalid result for creating a new interim card; something must be
        // wrong, so return false instead of null which could be confused with
        // not creating a new payment method
        Sentry.captureException(
          new Error(`BudgetingDeposit: Failed to get interim card token`),
        )
        return false
      }

      return interimCardToken
    } else {
      return null
    }
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault()

    if (!amountInCents) {
      alert("Amount not set.")
      return
    }

    setLoading(true)

    const interimCardToken = await prepareCard()

    if (interimCardToken === false) {
      // Error occurred. Return early. The error is probably already alerted by
      // the function handling prepareCard/preSubmit.
      setLoading(false)
      return
    }

    // At this point, we either have a valid interimCardToken, or a null
    // interimCardToken signifying that we are not creating a new card.

    const variables: Budgeting_OrganizationBalanceDepositMutationVariables = {
      amount: amountInCents,
      fundingMethod: BalanceDepositFundingMethod.CREDIT_CARD,
      accountType,
    }

    // Set paymentMethodID or cardInput based on the payment mode.
    if (
      !interimCardToken &&
      selectedPaymentMethodID &&
      selectedPaymentMethodID !== "NEW"
    ) {
      variables.paymentMethodID = selectedPaymentMethodID
    } else if (interimCardToken && selectedPaymentMethodID === "NEW") {
      variables.cardInput = {
        interimCardToken,
        address1: formFields.address1,
        address2: formFields.address2,
        addressCity: formFields.city,
        addressState: formFields.state,
        addressPostalCode: formFields.postalCode,
        cardholderName: formFields.name,
      }
    }

    const graphqlResponse = await balanceDepositMutation({
      variables,
      refetchQueries: [
        {
          query:
            accountType === BalanceDepositAccount.user
              ? ROOT_DATA_QUERY
              : BUDGETING_INDEX_QUERY,
        },
        { query: RECEIPTS_QUERY },
      ],
    })

    const data = graphqlResponse?.data?.organizationBalanceDeposit

    if (data?.ok) {
      successToast(
        `Funds were added to your ${
          accountType === BalanceDepositAccount.user ? "user" : "organization"
        } balance.`,
      )
      onSuccess({
        amountInCents,
        type: "credit_card",
      })
    } else {
      if (data?.error) {
        alert(data.error)
      } else {
        alert("Sorry, an unexpected error occurred.")
      }
    }

    setLoading(false)
  }

  return (
    <>
      <Box tw="mt-8 p-6 md:p-10 mb-3">
        <div tw="font-semibold text-xl pb-6">Credit card</div>
        <div
          css={{
            display:
              selectedPaymentMethodID && selectedPaymentMethodID !== "NEW"
                ? "block"
                : "none",
          }}
        >
          <OmniPaymentMethodSelector
            selectedPaymentMethodID={selectedPaymentMethodID}
            paymentMethods={paymentMethods}
            setSelectedPaymentMethodID={setSelectedPaymentMethodID}
          />
        </div>
        <div
          css={{
            display:
              selectedPaymentMethodID && selectedPaymentMethodID === "NEW"
                ? "block"
                : "none",
          }}
        >
          <AddPaymentMethodForm
            setFormFields={setFormFields}
            setFormField={setFormField}
            formFields={formFields}
            showBackButton={
              !!(paymentMethodCount > 0 && defaultPaymentMethodID)
            }
            onClickBack={() =>
              setSelectedPaymentMethodID(defaultPaymentMethodID)
            }
          />
        </div>
      </Box>
      <BalanceMessage />
      <SubmitButton
        amountInCents={amountInCents}
        fundingMethod={BalanceDepositFundingMethod.CREDIT_CARD}
        loading={loading}
        onSubmit={handleSubmit}
      />
    </>
  )
}
