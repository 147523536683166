import React from "react"

import { GiftBatchGiftStatusSet } from "./GiftBatchGiftStatusSet"

import {
  GiftSeriesFilter,
  Track_GiftBatchFragment,
} from "@/types/graphql-types"
interface Props {
  giftBatchID: string
  giftBatch?: Track_GiftBatchFragment | null
}

export const GiftBatchRecipientCards = ({ giftBatchID, giftBatch }: Props) => {
  return (
    <div tw="pt-0 md:pt-0">
      <GiftBatchGiftStatusSet
        filter={GiftSeriesFilter.recently_accepted}
        giftBatchID={giftBatchID}
      />
      <GiftBatchGiftStatusSet
        filter={GiftSeriesFilter.opened}
        expiresAt={
          (giftBatch?.expiresAt && new Date(giftBatch.expiresAt)) || null
        }
        giftBatchID={giftBatchID}
      />
      <GiftBatchGiftStatusSet
        filter={GiftSeriesFilter.notified}
        expiresAt={
          (giftBatch?.expiresAt && new Date(giftBatch.expiresAt)) || null
        }
        giftBatchID={giftBatchID}
      />
    </div>
  )
}
