import { gql } from "@apollo/client"

export const ORGANIZATION_MEMBER_FRAGMENT = gql`
  fragment OrganizationMember on Member {
    publicId
    fullName
    companyTitle
    email
  }
`
