import { gql, useMutation } from "@apollo/client"
import React, { MouseEvent, ReactNode, useCallback, useState } from "react"
import tw, { styled } from "twin.macro"

import { ReactComponent as Ellipsis } from "./images/ellipsis.svg"
import { ReactComponent as GiftIcon } from "../../assets/icons/gift-status-accepted-alt.svg"
import AutogiftIndexButton from "../../autogift/components/AutogiftIndexButton"
import AutogiftIndexDrawer from "../../autogift/components/AutogiftIndexDrawer"
import Button from "../../common/Button"
import DropdownMenu, {
  DropdownMenuItem,
  DropdownMenuPanel,
} from "../../common/DropdownMenu"
import { useCurrentWorkspace } from "../../common/hooks"
import { ReactComponent as PlusIcon } from "../../common/images/plus.svg"
import { ReactComponent as UploadIcon } from "../../common/images/upload.svg"
import { ReactComponent as WorkspaceIcon } from "../../common/images/workspace.svg"
import { ContactList, handleGraphQLResponse } from "../lib"
import { CONTACT_LISTS_QUERY } from "../queries"

import {
  Contacts_ContactListDeleteMutation,
  Contacts_ContactListDeleteMutationVariables,
  Contacts_ContactListRenameMutation,
  Contacts_ContactListRenameMutationVariables,
} from "@/types/graphql-types"

interface ContactMainProps {
  onClickNew: () => void
  onClickImport: () => void
  onClickSendGiftToAll: () => void
  list: ContactList | null
  search: JSX.Element
  sendAllLoading?: boolean
  contactsCount?: number
  children: ReactNode
}

const ContactsMain = ({
  onClickNew,
  onClickImport,
  onClickSendGiftToAll,
  list,
  search,
  children,
  sendAllLoading,
  contactsCount,
}: ContactMainProps) => {
  const { currentWorkspace } = useCurrentWorkspace()
  const [renameContactList] = useMutation<
    Contacts_ContactListRenameMutation,
    Contacts_ContactListRenameMutationVariables
  >(CONTACT_LIST_RENAME_MUTATION)
  const [deleteContactList] = useMutation<
    Contacts_ContactListDeleteMutation,
    Contacts_ContactListDeleteMutationVariables
  >(CONTACT_LIST_DELETE_MUTATION)

  const [autogiftDrawerOpen, setAutogiftDrawerOpen] = useState(false)

  // toggle the drawer open and close
  const toggleAutogiftDrawer = () => {
    const toggledOpenState = !autogiftDrawerOpen
    setAutogiftDrawerOpen(toggledOpenState)
  }

  const handleNewClick = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation()
      onClickNew()
    },
    [onClickNew],
  )

  const handleRenameClick = useCallback(async () => {
    if (list?.id == null) {
      return
    }

    const name = window.prompt("New name for the list:")

    if (name == null) {
      return
    }

    const res = await renameContactList({
      variables: { name, id: list.id },
      refetchQueries: [{ query: CONTACT_LISTS_QUERY }],
    })

    handleGraphQLResponse(res.data?.contactListUpdate, "Renamed contact list")
  }, [renameContactList, list])

  const handleDeleteClick = useCallback(async () => {
    if (list?.id == null) {
      return
    }

    const proceed = window.confirm(
      "Are you sure you want to delete this contact list? No contacts will be deleted.",
    )

    if (!proceed) {
      return
    }

    const res = await deleteContactList({
      variables: { id: list.id },
      refetchQueries: [{ query: CONTACT_LISTS_QUERY }],
    })

    handleGraphQLResponse(res.data?.contactListDestroy, "Deleted contact list")
  }, [deleteContactList, list])

  // Only show the "Gift to all" button if we have a currently selected list with contacts
  const showSendAllButton = list?.id && !!contactsCount

  return (
    <div tw="flex flex-col h-full overflow-auto pb-12 lg:pb-16 pr-5">
      <div tw="px-5 lg:pl-12 pr-6 pt-5 pb-2 lg:py-8">
        <Workspace>
          {currentWorkspace?.name}
          {currentWorkspace?.ttype === "STANDARD" && (
            <div className="shared">
              <WorkspaceIcon /> Shared
            </div>
          )}
        </Workspace>
        <div tw="flex justify-between items-end">
          <h2 tw="pt-1 text-2xl font-medium hidden lg:block">
            {list?.name || "All contacts"}
          </h2>
          {search}
        </div>
        <div tw="flex flex-col lg:flex-row gap-4 items-start justify-between lg:mt-4">
          {list?.id && <AutogiftIndexButton onClick={toggleAutogiftDrawer} />}
          <ButtonGroup>
            {list?.id != null && (
              <ListActionsMenu
                trigger={
                  <Button as="div">
                    <Ellipsis tw="stroke-0 fill-current text-gray-500" />
                  </Button>
                }
              >
                <DropdownMenuPanel>
                  <DropdownMenuItem>
                    <button onClick={handleRenameClick}>Rename list</button>
                  </DropdownMenuItem>
                  <DropdownMenuItem>
                    <button onClick={handleDeleteClick}>Delete list</button>
                  </DropdownMenuItem>
                </DropdownMenuPanel>
              </ListActionsMenu>
            )}
            {showSendAllButton ? (
              <Button
                onClick={onClickSendGiftToAll}
                disabled={sendAllLoading}
                tw="hidden md:flex"
              >
                {sendAllLoading ? (
                  "Please wait..."
                ) : (
                  <>
                    <GiftIcon tw={"w-4 h-4 stroke-current"} />
                    <div
                      style={{ lineHeight: "133%", letterSpacing: "-.01em" }}
                      tw={"text-gray-500"}
                    >
                      Gift to all
                    </div>
                  </>
                )}
              </Button>
            ) : null}
            <Button onClick={onClickImport}>
              <UploadIcon tw="w-4 text-gray-400 stroke-current stroke-1.5" />
              <div>
                Import
                {!showSendAllButton && (
                  <span tw="hidden lg:inline"> contacts</span>
                )}
              </div>
            </Button>
            {!list?.hrisSynced && (
              <Button variant="primary" onClick={handleNewClick}>
                <PlusIcon />
                <div>
                  New<span tw="hidden lg:inline"> contact</span>
                </div>
              </Button>
            )}
          </ButtonGroup>
        </div>
      </div>
      {list?.id && (
        <AutogiftIndexDrawer
          open={autogiftDrawerOpen}
          contactListId={list.id}
          contactListName={list?.name}
          contactListHRISSynced={!!list?.hrisSynced}
          setOpen={setAutogiftDrawerOpen}
        />
      )}
      {children}
    </div>
  )
}

const ListActionsMenu = styled(DropdownMenu)`
  > button.trigger {
    ${tw`h-full flex items-stretch`}

    > div {
      ${tw`px-3`}
    }
  }
`

const ButtonGroup = styled.div`
  ${tw`flex gap-4 items-stretch`}

  > button.default {
    ${tw`text-gray-500`}
  }
`

const Workspace = styled.div`
  ${tw`hidden lg:flex font-medium text-sm text-primary-500 gap-5`}

  > .shared {
    ${tw`flex gap-2 items-center text-primary-300 font-normal`}

    > svg {
      ${tw`w-4 h-auto stroke-current stroke-2`}
    }
  }
`

const CONTACT_LIST_RENAME_MUTATION = gql`
  mutation Contacts_ContactListRename($id: ID!, $name: String!) {
    contactListUpdate(id: $id, name: $name) {
      ok
      errors
    }
  }
`

const CONTACT_LIST_DELETE_MUTATION = gql`
  mutation Contacts_ContactListDelete($id: ID!) {
    contactListDestroy(id: $id) {
      ok
      errors
    }
  }
`

export default ContactsMain
