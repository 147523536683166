import { useMutation, useQuery } from "@apollo/client"
import { useState } from "react"
import { toast } from "react-hot-toast"

import Button from "@/common/CommonButton"
import TextInputField from "@/common/TextInputField"
import organizationGetBillingEmail from "@/organization/settings/receipts/graphql/organizationGetBillingEmail"
import organizationUpdateBillingEmails from "@/organization/settings/receipts/graphql/organizationUpdateBillingEmails"
import {
  OrganizationGetBillingEmailQuery,
  OrganizationUpdateBillingEmailsMutation,
  OrganizationUpdateBillingEmailsMutationVariables,
} from "@/types/graphql-types"

interface BillingEmailFormProps {
  organizationId?: string
}

const BillingEmailForm = ({ organizationId }: BillingEmailFormProps) => {
  const [billingEmails, setBillingEmails] = useState<string>()
  const [error, setError] = useState<string>()
  const [updateBillingEmails, { loading: updating }] = useMutation<
    OrganizationUpdateBillingEmailsMutation,
    OrganizationUpdateBillingEmailsMutationVariables
  >(organizationUpdateBillingEmails)
  const { data: initial, loading } = useQuery<OrganizationGetBillingEmailQuery>(
    organizationGetBillingEmail,
  )

  const save = async () => {
    if (organizationId) {
      const response = await updateBillingEmails({
        variables: {
          organizationId,
          billingEmails,
        },
      })
      setError(
        response?.data?.organizationUpdateBillingEmails?.error ?? undefined,
      )
      if (response?.data?.organizationUpdateBillingEmails?.ok) {
        toast.success("Billing email updated.")
      }
    }
  }

  if (loading) return null

  return (
    <div tw="flex flex-col gap-6">
      <div tw="flex flex-col leading-5 gap-2">
        <div tw="font-medium text-gray-900">
          Billing contact for all receipts in your organization
        </div>
        <div tw="text-gray-600">
          All receipts for users in your organization will be CC’d to the
          billing contact. Separate multiple emails with commas.
        </div>
      </div>
      <TextInputField
        tw="max-w-[420px]"
        placeholder="Email(s)"
        onChange={(e) => setBillingEmails(e.target.value)}
        error={error}
        defaultValue={initial?.organization?.billingEmails?.join(",") ?? ""}
      />
      <div tw="flex">
        <Button disabled={updating} variant="primaryNew" onClick={save}>
          Save
        </Button>
      </div>
    </div>
  )
}

export default BillingEmailForm
