import React, { ReactNode } from "react"
import tw, { styled } from "twin.macro"

import { Container, ProText, StyledProTagIcon } from "@/common/ProTag"

interface Props {
  name: string
  children?: ReactNode
  hint?: string
  disabled?: boolean
  alert?: ReactNode | null
  displayProTag?: boolean
}

export const OptionBase = ({
  name,
  children,
  hint,
  disabled,
  alert,
  displayProTag,
}: Props) => {
  return (
    <div tw="flex flex-col gap-y-5 mb-16">
      <div tw="flex flex-row items-center">
        <div tw="text-lg font-medium mr-4">{name}</div>
        {displayProTag && (
          <Container>
            <StyledProTagIcon />
            <ProText>Pro</ProText>
          </Container>
        )}
      </div>
      {alert && alert}
      <DisabledContainer disabled={disabled} unclickable={disabled}>
        {children}
      </DisabledContainer>
      {hint && (
        <DisabledContainer
          disabled={disabled}
          unclickable={disabled}
          tw="text-gray-500 leading-[21px] text-[15px]"
        >
          {hint}
        </DisabledContainer>
      )}
    </div>
  )
}

const DisabledContainer = styled.div<{
  disabled?: boolean
  unclickable?: boolean
}>`
  ${({ disabled }) => (disabled ? tw`opacity-40` : null)};
  ${({ unclickable }) => (unclickable ? tw`pointer-events-none` : null)};
`
