import React from "react"

import Button from "../../common/Button"

export default function DirectSendProductIneligible({
  switchToEmailOrLink,
  openCart,
}: {
  switchToEmailOrLink: () => void
  openCart: () => void
}) {
  return (
    <>
      <div tw="mx-auto w-[100px] border-t border-gray-300 pb-8" />
      <div tw="text-gray-500 text-lg text-center max-w-[500px] mx-auto pb-8">
        You can switch to sending this gift via email or gift link, or edit your
        bag to switch to another gift.
      </div>
      <div tw="flex flex-row items-center justify-center gap-3">
        <Button variant="updated" onClick={switchToEmailOrLink}>
          Send gift via email or gift link
        </Button>
        <Button onClick={openCart}>Edit bag</Button>
      </div>
    </>
  )
}
