import { lowerCase } from "lodash-es"
import moment from "moment/moment"
import React, { useContext, useEffect, useState } from "react"

import { ReactComponent as Icon } from "./icons/schedule.svg"
import OptionBase from "./OptionBase"
import {
  eventEligibleForSchedule,
  useGiftData,
} from "../../../common/hooks/giftData"
import {
  SelectionGroup,
  SelectionIndicator,
  SelectionLabel,
} from "../../../common/SelectionGroup"
import { formatDayWithDateAndTime } from "../../../common/utilities"
import ScheduledGiftDateTimePicker from "../../components/ScheduledGiftDateTimePicker"
import { OptionsContext } from "../OptionsContext"

import { BatchSendMethod } from "@/types/graphql-types"

export default function Schedule() {
  const { currentGift, recipients } = useContext(OptionsContext)

  const { setScheduledSend } = useGiftData()

  const scheduledEventAttached = recipients.some(
    (recipient) => recipient.scheduledEventId && recipient.scheduledEventKind,
  )

  const eventRecipient = recipients[0]

  const eventDate =
    scheduledEventAttached && eventRecipient.eventDate
      ? moment(eventRecipient.eventDate).toDate()
      : null

  const [scheduledSendOnEventDate, setScheduledSendOnEventDate] =
    useState<boolean>(false)

  useEffect(() => {
    const scheduledForEventDate = moment(eventDate).isSame(
      moment(currentGift.scheduledSendOnDate),
      "day",
    )
    const selectScheduledSendOnEventDateOption =
      !!eventDate &&
      eventEligibleForSchedule(eventDate) &&
      scheduledForEventDate
    setScheduledSendOnEventDate(selectScheduledSendOnEventDateOption)
  }, [])

  const scheduledSendOnEventDateOption =
    scheduledSendOnEventDate && currentGift.scheduledSendOnOption

  const scheduledSendOnDifferentDateOption =
    !scheduledSendOnEventDate && currentGift.scheduledSendOnOption

  const [scheduledSendOnDisplayDate, setScheduledSendOnDisplayDate] =
    useState<string>("")

  // When the scheduled send on date or time are changed, update the formatted display string
  useEffect(() => {
    setScheduledSendOnDisplayDate(
      formatDayWithDateAndTime(
        currentGift.scheduledSendOnDate,
        currentGift.scheduledSendOnTime,
      ),
    )
  }, [currentGift.scheduledSendOnDate, currentGift.scheduledSendOnTime])

  if (currentGift.isGiftDispenser || currentGift.isSmartLink) {
    return null
  }

  return (
    <OptionBase
      name="Schedule"
      icon={<Icon />}
      preview={
        currentGift.scheduledSendOnOption
          ? scheduledSendOnDisplayDate
          : `Send now`
      }
    >
      <div>
        <SelectionGroup
          onClick={() => {
            setScheduledSend({ option: false })
            setScheduledSendOnEventDate(false)
          }}
        >
          <SelectionIndicator selected={!currentGift.scheduledSendOnOption} />
          <SelectionLabel selected={!currentGift.scheduledSendOnOption}>
            Send now
          </SelectionLabel>
        </SelectionGroup>
      </div>
      {eventDate && eventEligibleForSchedule(eventDate) ? (
        <div>
          <SelectionGroup
            onClick={() => {
              setScheduledSend({
                option: true,
                date: eventDate,
              })
              setScheduledSendOnEventDate(true)
            }}
          >
            <SelectionIndicator selected={scheduledSendOnEventDateOption} />
            <SelectionLabel
              selected={scheduledSendOnEventDateOption}
              className="data-hj-suppress ph-no-capture fs-mask"
            >
              {`Schedule for ${eventRecipient.firstName} ${
                eventRecipient.lastName
              }'s ${lowerCase(eventRecipient.scheduledEventKind || "")}`}
            </SelectionLabel>
          </SelectionGroup>
        </div>
      ) : null}
      {scheduledSendOnEventDateOption ? (
        <div tw="mb-4">
          <ScheduledGiftDateTimePicker
            scheduledSendOnDate={currentGift.scheduledSendOnDate}
            setScheduledSend={setScheduledSend}
            scheduledSendOnTime={currentGift.scheduledSendOnTime}
          />
        </div>
      ) : null}
      <div>
        <SelectionGroup
          onClick={() => {
            setScheduledSend({
              option: true,
            })
            setScheduledSendOnEventDate(false)
          }}
        >
          <SelectionIndicator selected={scheduledSendOnDifferentDateOption} />
          <SelectionLabel selected={scheduledSendOnDifferentDateOption}>
            Schedule for later
          </SelectionLabel>
        </SelectionGroup>
      </div>
      {scheduledSendOnDifferentDateOption && (
        <div tw="mt-4">
          <ScheduledGiftDateTimePicker
            scheduledSendOnDate={currentGift.scheduledSendOnDate}
            setScheduledSend={setScheduledSend}
            scheduledSendOnTime={currentGift.scheduledSendOnTime}
          />
        </div>
      )}
      <div tw="pt-4 text-gray-500 text-sm font-text">
        You can schedule this gift send for a later date. If you schedule it,
        you can cancel the send or update the gift before it goes out.
      </div>
      {currentGift.sendMethod === BatchSendMethod.direct_send &&
        scheduledSendOnDifferentDateOption && (
          <div tw="pt-4 text-gray-500 text-sm font-text">
            <strong tw="font-medium text-orange-alt-500">
              Note: This does <u>not</u> schedule the delivery of the gift
            </strong>
            , only when the order is created (and when notifications are sent to
            recipients, if enabled). Delivery times vary depending on the
            product and shipping method used.
          </div>
        )}
    </OptionBase>
  )
}
