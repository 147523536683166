import { gql, useMutation } from "@apollo/client"
import { toast } from "react-hot-toast"

import { ActionsDropdown, TdName } from "../../common/members/Table"
import useChangeOrganizationAccess from "../../common/members/useChangeOrganizationAccess"
import RoleBadge from "../../organization/RoleBadge"
import { GET_WORKSPACE_QUERY } from "../graphql"

import {
  BaseMeFragment,
  WorkspaceFragment,
  WorkspaceMembership,
  WorkspaceMembershipDestroyMutation,
  WorkspaceMembershipDestroyMutationVariables,
  WorkspaceMembershipRole,
  WorkspaceMembershipUpdateMutation,
  WorkspaceMembershipUpdateMutationVariables,
} from "@/types/graphql-types"

interface WorkspaceMemberRowProps extends WorkspaceMembership {
  currentUser: BaseMeFragment
  isTeamPlan: boolean
  workspace: WorkspaceFragment
  isManager: boolean
}

const WorkspaceMemberRow = ({
  organizationAccess,
  organizationMembershipId,
  companyTitle,
  email,
  fullName,
  role,
  userPublicId,
  id,
  giftCount,
  invitedByUserEmail,
  invitedByUserFullName,
  currentUser,
  isTeamPlan,
  workspace,
  isManager,
}: WorkspaceMemberRowProps) => {
  const [destroyWorkspaceMembership] = useMutation<
    WorkspaceMembershipDestroyMutation,
    WorkspaceMembershipDestroyMutationVariables
  >(WORKSPACE_MEMBERSHIP_DESTROY)

  const [updateWorkspaceMembership] = useMutation<
    WorkspaceMembershipUpdateMutation,
    WorkspaceMembershipUpdateMutationVariables
  >(WORKSPACE_MEMBERSHIP_UPDATE)

  const changeAccess = useChangeOrganizationAccess()
  const deactivate = async (id: string, fullName?: string | null) => {
    const res = await destroyWorkspaceMembership({
      variables: {
        id,
      },
      refetchQueries: [
        { query: GET_WORKSPACE_QUERY, variables: { id: workspace.id } },
      ],
    })

    if (res.data?.workspaceMembershipDestroy.ok) {
      toast.success(
        `${fullName || "Member"} has been removed from the workspace.`,
        {
          iconTheme: {
            primary: "#27ae60",
            secondary: "#fff",
          },
        },
      )
    }
  }

  const onClickDemoteRole = (id: string) => {
    if (
      window.confirm(
        "Are you sure you want to demote this manager to a member?",
      )
    ) {
      setMembershipRole(id, WorkspaceMembershipRole.MEMBER)
    }
  }

  const onClickDeactivate = () => {
    if (window.confirm("Are you sure you want to remove this member?")) {
      deactivate(id, fullName)
    }
  }

  const setMembershipRole = async (
    id: string,
    role: WorkspaceMembershipRole,
  ) => {
    const res = await updateWorkspaceMembership({
      variables: {
        id,
        fields: { role },
      },
    })

    if (res.data?.workspaceMembershipUpdate.ok) {
      toast.success(
        `Updated role to ${
          res.data.workspaceMembershipUpdate.workspaceMembership?.role.toLowerCase() ||
          role.toString().toLowerCase()
        }.`,
        {
          iconTheme: {
            primary: "#27ae60",
            secondary: "#fff",
          },
        },
      )
    } else if (res.data?.workspaceMembershipUpdate.error) {
      alert(res.data.workspaceMembershipUpdate.error)
    } else {
      alert("An unexpected error occurred.")
    }
  }

  return (
    <tr key={id}>
      <TdName name={fullName} role={companyTitle}>
        <ActionsDropdown
          id={id}
          access={organizationAccess}
          onlyDisplayOn="mobile"
          changeAccess={(newAccess) =>
            changeAccess({
              id: organizationMembershipId,
              workspaceId: workspace.id,
              newAccess: newAccess,
              refetchQueries: [
                { query: GET_WORKSPACE_QUERY, variables: { id: workspace.id } },
              ],
            })
          }
          onClickDemoteRole={onClickDemoteRole}
          promoteRole={() =>
            setMembershipRole(id, WorkspaceMembershipRole.MANAGER)
          }
          onClickDeactivate={onClickDeactivate}
          isMember={role === WorkspaceMembershipRole.MEMBER}
          belongsToCurrentUser={userPublicId === currentUser.publicId}
          higherRoleLabel="Manager"
          groupLabel="workspace"
          isTeamPlan={isTeamPlan}
        />
      </TdName>
      <td
        tw="text-gray-500"
        className="data-hj-suppress ph-no-capture fs-exclude"
      >
        {email}
      </td>
      {isManager && (
        <>
          <td>
            {giftCount}
            <span tw="lg:hidden">&nbsp;gifts</span>
          </td>
          <td>
            <RoleBadge
              access={organizationAccess}
              role={role}
              isTeamPlan={isTeamPlan}
            />
          </td>
          <td>
            <ActionsDropdown
              id={id}
              access={organizationAccess}
              onlyDisplayOn="desktop"
              changeAccess={(newAccess) =>
                changeAccess({
                  id: organizationMembershipId,
                  workspaceId: workspace.id,
                  newAccess: newAccess,
                  refetchQueries: [
                    {
                      query: GET_WORKSPACE_QUERY,
                      variables: { id: workspace.id },
                    },
                  ],
                })
              }
              onClickDemoteRole={onClickDemoteRole}
              promoteRole={() =>
                setMembershipRole(id, WorkspaceMembershipRole.MANAGER)
              }
              onClickDeactivate={onClickDeactivate}
              isMember={role === WorkspaceMembershipRole.MEMBER}
              belongsToCurrentUser={userPublicId === currentUser.publicId}
              higherRoleLabel="Manager"
              invitedByUserEmail={invitedByUserEmail}
              invitedByUserFullName={invitedByUserFullName}
              groupLabel="workspace"
              isTeamPlan={isTeamPlan}
            />
          </td>
        </>
      )}
    </tr>
  )
}

const WORKSPACE_MEMBERSHIP_UPDATE = gql`
  mutation WorkspaceMembershipUpdate(
    $id: ID!
    $fields: WorkspaceMembershipUpdateInput!
  ) {
    workspaceMembershipUpdate(id: $id, fields: $fields) {
      ok
      error
      workspaceMembership {
        id
        role
      }
    }
  }
`

const WORKSPACE_MEMBERSHIP_DESTROY = gql`
  mutation WorkspaceMembershipDestroy($id: ID!) {
    workspaceMembershipDestroy(id: $id) {
      ok
    }
  }
`

export default WorkspaceMemberRow
