import { WatchQueryFetchPolicy, useQuery } from "@apollo/client"

import { RESTRICTED_PRODUCTS_QUERY } from "../graphql"

import {
  RestrictedProductsQuery,
  RestrictedProductsQueryVariables,
} from "@/types/graphql-types"

interface Params {
  fetchPolicy?: WatchQueryFetchPolicy
  pageSize: number
}

export const useRestrictedProducts = ({
  pageSize,
  fetchPolicy = "cache-and-network",
}: Params) => {
  const { data, loading, error, fetchMore } = useQuery<
    RestrictedProductsQuery,
    RestrictedProductsQueryVariables
  >(RESTRICTED_PRODUCTS_QUERY, {
    fetchPolicy: fetchPolicy,
    variables: {
      pageSize: pageSize,
    },
  })
  const connection = data?.restrictedProductsConnection

  const loadMore = () => {
    fetchMore({
      variables: {
        afterCursor: connection?.pageInfo.endCursor,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const newConnection = fetchMoreResult.restrictedProductsConnection
        const prevConnection = previousResult.restrictedProductsConnection
        const pageInfo = newConnection?.pageInfo

        if (
          !newConnection?.nodes.length ||
          pageInfo.endCursor === prevConnection?.pageInfo.endCursor
        ) {
          return previousResult
        }

        const nodes = [...prevConnection.nodes, ...newConnection?.nodes]
        const mappedNodes = new Map(
          nodes.map((item) => [item.id, item]),
        ).values()
        const uniqueNodes = Array.from(mappedNodes)

        return {
          restrictedProductsConnection: {
            __typename: prevConnection.__typename,
            nodes: uniqueNodes,
            pageInfo,
          },
        }
      },
    })
  }

  return {
    loading,
    error,
    loadMore,
    hasNextPage: connection?.pageInfo.hasNextPage,
    products: connection?.nodes,
  }
}
