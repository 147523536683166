import { gql } from "@apollo/client"

export const BALANCE_TRANSFER_MUTATION = gql`
  mutation Budgeting_BalanceTransfer(
    $organizationMembershipID: ID!
    $amount: Int!
    $direction: BalanceTransferDirection!
  ) {
    balanceTransfer(
      organizationMembershipId: $organizationMembershipID
      amount: $amount
      direction: $direction
    ) {
      ok
      error
    }
  }
`
