import styled from "@emotion/styled"
import React, { FC } from "react"
import tw from "twin.macro"

import { ReactComponent as Box } from "./images/box.svg"
import { ReactComponent as Compass } from "./images/compass.svg"
import { ReactComponent as Dropbox } from "./images/dropbox.svg"
import { ReactComponent as Facebook } from "./images/facebook.svg"
import { ReactComponent as Google } from "./images/google.svg"
import { ReactComponent as KPMG } from "./images/kpmg.svg"
import { ReactComponent as NEA } from "./images/nea.svg"
import { ReactComponent as RampImage } from "./images/ramp.svg"
import { ReactComponent as RO } from "./images/ro.svg"
import { ReactComponent as Stripe } from "./images/stripe.svg"

const Brands: FC = (props) => {
  return (
    <div
      tw="mt-40 text-center mx-auto"
      style={{ maxWidth: "1200px" }}
      {...props}
    >
      <p tw="font-bold text-3xl mb-12 mx-4">
        Trusted by thousands of leading companies.
      </p>
      <GridContainer>
        <GridItem>
          <Facebook />
        </GridItem>
        <GridItem>
          <Google />
        </GridItem>
        <GridItem>
          <KPMG />
        </GridItem>
        <GridItem>
          <Stripe />
        </GridItem>
        <GridItem>
          <Box />
        </GridItem>
        <GridItem>
          <Compass />
        </GridItem>
        <GridItem>
          <NEA />
        </GridItem>
        <GridItem>
          <Dropbox />
        </GridItem>
        <GridItem>
          <RampImage />
        </GridItem>
        <GridItem>
          <RO />
        </GridItem>
      </GridContainer>
    </div>
  )
}

const GridContainer = styled.div`
  max-width: 640px;
  ${tw`grid grid-cols-2 lg:grid-cols-5 mx-auto`}
  @media (min-width: 1024px) {
    max-width: 1440px;
  }
`

const GridItem = styled.div`
  ${tw`flex items-center justify-center m-4 lg:m-8`}
`

export default Brands
