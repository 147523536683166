import { useState } from "react"
import useCollapse from "react-collapsed"
import tw from "twin.macro"

import ActivityGift from "./ActivityGift"
import ActivityGiftBatchActions from "./ActivityGiftBatchActions"
import ActivityGiftBatchFinalized from "./ActivityGiftBatchFinalized"
import ActivityGiftBatchPending from "./ActivityGiftBatchPending"
import ActivityGiftBatchSubtitle from "./ActivityGiftBatchSubtitle"
import { Group, Row } from "./common"
import { ReactComponent as Chevron } from "../../common/images/chevron-down.svg"

import { BalanceGiftBatchStatus } from "@/types/graphql-types"
import { BalanceGiftBatch } from "@/types/graphql-types"

interface Props {
  balanceGiftBatch: BalanceGiftBatch
  cancelGift: (id: string) => void
  giftBatchCancelPendingGifts: (id: string) => void
  giftBatchCancelScheduledBatch: (id: string) => void
  isOwnActivity: boolean
}

export default function ActivityGiftBatch({
  balanceGiftBatch,
  cancelGift,
  giftBatchCancelPendingGifts,
  giftBatchCancelScheduledBatch,
  isOwnActivity,
}: Props) {
  const [isExpanded, setIsExpanded] = useState(false)
  const { getCollapseProps } = useCollapse({ isExpanded })

  const isFinalized =
    balanceGiftBatch.status === BalanceGiftBatchStatus.FINALIZED

  // Always expandable if it's a user's own activity that they're viewing.
  // If not, only make it expandable if it's finalized.
  const expandable = isOwnActivity || isFinalized

  return (
    <Group
      onClick={() => {
        setIsExpanded(!isExpanded)
      }}
      css={[
        isExpanded && tw`shadow-min-with-border`,
        !expandable && tw`pointer-events-none`,
      ]}
    >
      <Row tw="py-5">
        <div tw="flex flex-row items-center justify-between gap-4">
          <div>
            <div tw="font-medium text-xl">{balanceGiftBatch.name}</div>
            <div tw="font-text">
              <ActivityGiftBatchSubtitle balanceGiftBatch={balanceGiftBatch} />
            </div>
          </div>
          {expandable && (
            <Chevron
              tw="stroke-current text-gray-500 transition-transform"
              css={[isExpanded && tw`rotate-180`]}
            />
          )}
        </div>
        <ActivityGiftBatchPending balanceGiftBatch={balanceGiftBatch} />
        <ActivityGiftBatchFinalized balanceGiftBatch={balanceGiftBatch} />
      </Row>
      <div {...getCollapseProps()}>
        <ActivityGiftBatchActions
          isOwnActivity={isOwnActivity}
          balanceGiftBatch={balanceGiftBatch}
          giftBatchCancelScheduledBatch={giftBatchCancelScheduledBatch}
          giftBatchCancelPendingGifts={giftBatchCancelPendingGifts}
        />
        {balanceGiftBatch.balanceGifts.map((gift) => (
          <ActivityGift
            gift={gift}
            key={gift.id}
            cancelGift={cancelGift}
            isOwnActivity={isOwnActivity}
          />
        ))}
      </div>
    </Group>
  )
}
