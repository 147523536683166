import React from "react"
import tw, { styled } from "twin.macro"

import { ReactComponent as GiftSvg } from "./images/gradient-gift.svg"
import {
  ServiceIcon,
  SpinningIconStyled,
  TitleComponent,
  TitleStatusContainer,
} from "../../common/hris"

interface HRISPreparingDataProps {
  serviceName: string | undefined
  serviceSquareImage: string | undefined
}

const HRISPreparingData: React.FC<HRISPreparingDataProps> = ({
  serviceName,
  serviceSquareImage,
}) => {
  return (
    <div tw="overflow-auto">
      <div tw="overflow-auto relative h-screen">
        <TitleStatusContainer className="loading">
          <div tw="flex flex-col gap-8 box-border px-9 py-8">
            <TitleComponent title={serviceName} image={serviceSquareImage} />
          </div>
        </TitleStatusContainer>
        <div tw="flex justify-center">
          <InitialSyncContainer>
            <div tw="px-8 w-full md:w-auto md:px-28 py-12 flex flex-col justify-center items-center">
              <div tw="flex flex-row pb-6 items-center gap-5">
                <ServiceIcon tw="w-9 h-9">
                  <img
                    src={serviceSquareImage}
                    tw="object-cover w-9 h-9"
                    alt=""
                  />
                </ServiceIcon>
                <SpinningIconStyled tw="text-primary-500" />
                <ServiceIcon tw="w-9 h-9 flex items-center justify-center">
                  <GiftSvg tw="object-cover w-5 h-5" />
                </ServiceIcon>
              </div>
              <div tw="pb-3 text-xl font-medium text-primary-500 text-center">
                Performing initial sync
              </div>
              <div tw="text-gray-600 max-w-xs text-center">
                You don’t need to keep this window open. We’ll email you when
                it’s complete.
              </div>
            </div>
          </InitialSyncContainer>
        </div>
      </div>
    </div>
  )
}

const InitialSyncContainer = styled.div`
  ${tw`mt-20 border border-primary-100 box-border rounded-lg`}

  box-shadow: 0px 2px 4px rgba(228, 216, 244, 0.3);
`

export default HRISPreparingData
