import { BaseMeFragment, BaseOrganizationFragment } from "@/types/graphql-types"

export function getPlanLevel(
  me: BaseMeFragment,
  organization?: BaseOrganizationFragment | null,
) {
  if (organization?.subscription?.isTeamPlan) {
    return "team"
  }

  if (organization?.subscription?.isEe) {
    return "ee"
  }

  if (me.featureAccess.includes("pro_plan")) {
    return "pro"
  }

  return "free"
}
