import DropdownContent from "./components/DropDownContent"
import { ReactComponent as BrandedSwagIcon } from "./images/branded-swag.svg"
import { ReactComponent as BulkGiftingIcon } from "./images/bulk-gifting.svg"
import { ReactComponent as CustomCollectionsIcon } from "./images/custom-collections.svg"
import { Gift } from "../../assets/icons"
import { generateRealmPath } from "../../common/realm"

const HowItWorksDropdownContent = () => {
  const howItWorksPath = (page: string) =>
    generateRealmPath("business", `/how-it-works/${page}`)
  const items = [
    {
      icon: (
        <Gift tw="h-5 w-5 fill-[#8167FF] group-hover:stroke-current group-hover:fill-current" />
      ),
      name: "Platform Overview",
      link: howItWorksPath(""),
    },
    {
      icon: <BulkGiftingIcon />,
      name: "Bulk Gifting",
      link: howItWorksPath("bulk-gifting"),
    },
    {
      icon: <BrandedSwagIcon />,
      name: "Branded Swag",
      link: howItWorksPath("branded-swag"),
    },
    {
      icon: <CustomCollectionsIcon />,
      name: "Custom Collections",
      link: howItWorksPath("custom-collections"),
    },
  ]

  return <DropdownContent items={items} />
}

export default HowItWorksDropdownContent
