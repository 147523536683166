import React from "react"

import { formatDayWithDate } from "../../common/utilities"

interface Props {
  // trialEnd is typed as `any`, not a string, in GraphQL codegen.
  trialEnd: any
}

export default function SubscriptionSummary({ trialEnd }: Props) {
  if (!trialEnd) {
    return null
  }

  return (
    <div tw="pt-12 font-text">
      <div tw="font-semibold text-gray-400 pb-2">Summary</div>
      <div tw="pb-1">
        Your plan will begin when your trial expires on{" "}
        {formatDayWithDate(new Date(trialEnd))},{" "}
        {new Date(trialEnd).getFullYear()}.
      </div>
      <div>
        <br />
        If you cancel before the end of your trial, you won’t be charged.
      </div>
    </div>
  )
}
