import React from "react"

import { getProductImageThumb, getProductName } from "@/common/gifts"
import { Product } from "@/common/GlobalState"
import { ProductImage } from "@/store/ProductImage"

type Props = {
  product: Product
} & Omit<
  React.ComponentProps<typeof ProductImage>,
  "image" | "alt" | "scalable" | "contain"
>
export const CartProductImage = ({ product, ...restProps }: Props) => {
  const imageThumb = getProductImageThumb(product)

  return (
    imageThumb && (
      <ProductImage
        alt={getProductName(product)}
        image={imageThumb}
        scalable={product.imagesScalable}
        addSpacing={product.isBook}
        {...restProps}
      />
    )
  )
}
