import React from "react"
import { useLocation } from "react-router-dom"

import OmniAuthButton from "@/common/OmniAuthButton"
import SSOSignInForm from "@/signIn/SSOSignInForm"
import { OrganizationRequireSsoSetting } from "@/types/graphql-types"

interface Props {
  requireSSOSetting: string
  requireSSOError: string
  email: string | null
}

const RequireSSO = ({ requireSSOSetting, requireSSOError, email }: Props) => {
  const { hash } = useLocation()

  const isOmniauth =
    requireSSOSetting === OrganizationRequireSsoSetting.google ||
    requireSSOSetting === OrganizationRequireSsoSetting.microsoft

  const isSaml = requireSSOSetting === OrganizationRequireSsoSetting.saml

  return (
    <div tw="w-[530px] px-8 py-12 rounded-2xl">
      <div tw="mb-8 text-center">{requireSSOError}</div>
      {isOmniauth ? (
        <OmniAuthButton
          key={requireSSOSetting}
          provider={requireSSOSetting}
          labelText="Sign in with"
          origin={`/business/signin${hash}`}
        />
      ) : isSaml && email ? (
        <SSOSignInForm email={email} fromRequireSSO={true} />
      ) : null}
    </div>
  )
}

export default RequireSSO
