import { gql } from "@apollo/client"

export const ORGANIZATION_BALANCE_DEPOSIT_MUTATION = gql`
  mutation Budgeting_OrganizationBalanceDeposit(
    $amount: Int!
    $cardInput: PaymentMethodCreditCardInput
    $paymentMethodID: ID
    $invoiceRequestInput: InvoiceRequestInput
    $fundingMethod: BalanceDepositFundingMethod!
    $accountType: BalanceDepositAccount!
  ) {
    organizationBalanceDeposit(
      amount: $amount
      fundingMethod: $fundingMethod
      cardInput: $cardInput
      paymentMethodId: $paymentMethodID
      invoiceRequestInput: $invoiceRequestInput
      accountType: $accountType
    ) {
      ok
      error
    }
  }
`
