import { Link } from "react-router-dom"
import tw, { css, styled } from "twin.macro"

interface GiftSelectButtonProps {
  title: string
  subtitle: string
  icon: string
  iconName: string
  onClick?: () => void
  to?: string
  css?: any
}

const GiftSelectButton = ({
  title,
  subtitle,
  icon,
  iconName,
  onClick,
  to,
  ...props
}: GiftSelectButtonProps) => {
  return (
    <ButtonContainer
      onClick={onClick}
      to={to ?? "#"}
      iconName={iconName}
      {...props}
    >
      <div tw="flex flex-row items-center py-[31px] px-6" className="group">
        <div tw="flex flex-row items-center justify-center">
          <IconContainer iconName={iconName}>
            <img src={icon} />
          </IconContainer>
          <div>
            <div tw="font-medium">{title}</div>
            <div tw="text-gray-450">{subtitle}</div>
          </div>
        </div>
      </div>
    </ButtonContainer>
  )
}

const IconContainer = styled.div<{ iconName?: string }>`
  ${tw`flex items-center justify-center mr-5 group-hover:-translate-y-0.5 transition-transform`};

  ${({ iconName }) => css`
    ${iconName === "browse"
      ? "filter: drop-shadow(0px 2px 6px rgba(98, 29, 241, 0.12))"
      : "filter: drop-shadow(0px 2px 6px rgba(47, 128, 237, 0.12))"}
  `}
`

const ButtonContainer = ({
  iconName,
  css,
  ...restProps
}: React.ComponentProps<typeof Link> & { iconName?: string }) => (
  <Link
    {...restProps}
    css={[
      tw`flex flex-row w-full rounded-xl items-center active:scale-[96%] flex-1 transition-all`,
      iconName === "browse"
        ? tw`bg-[#FBFAFF] hover:bg-[#F6F3FF]`
        : tw`bg-[#F6FAFF] hover:bg-[#EFF5FF]`,
      css,
    ]}
  />
)

export default GiftSelectButton
