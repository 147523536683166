import GoodyProductCommon from "./GoodyProductCommon"

interface Props {
  id: string
  price?: string
}

export function GoodyProduct({ id, price }: Props) {
  return <GoodyProductCommon id={id} withGiftOption={false} price={price} />
}
