import Tippy from "@tippyjs/react"
import React from "react"
import tw, { styled } from "twin.macro"
import "tippy.js/animations/scale.css"

type Placement =
  | "auto"
  | "auto-end"
  | "auto-start"
  | "left"
  | "right"
  | "top"
  | "bottom"
//
// interface ContainerProps {
//   disabled: boolean;
// }
// const Container = styled.div<ContainerProps>`
//   ${tw`h-4 inline-block relative w-4`};
//   pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
//
//   .tooltip-container {
//     ${tw`opacity-0 duration-200 transform scale-75 transition mx-3`};
//   }
//
//   &:hover {
//     .tooltip-container {
//       ${tw`opacity-100 scale-100`};
//     }
//   }
// `;
//

const TooltipContentContainer = styled.div`
  ${tw`bg-white border border-gray-200 leading-6 p-6 z-20`};
  border-radius: 12px;
  box-shadow:
    0px 1px 4px 0px rgba(0, 0, 0, 0.02),
    0px 8px 20px 0px rgba(0, 0, 0, 0.05);

  max-width: 444px;

  p + p {
    ${tw`mt-4`};
  }

  @media only screen and (max-width: 1023px) {
    width: 320px;
  }

  @media only screen and (min-width: 1024px) and (max-width: 1279px) {
    width: 400px;
  }
`
const Title = tw.h2`mb-4 font-medium text-primary-600`

interface TooltipProps {
  children: React.ReactNode
  disabled?: boolean
  placement: Placement
  title?: string
  trigger?: React.ReactElement
  triggerRef?: React.RefObject<HTMLElement> | null
}

const Tooltip: React.FC<TooltipProps> = ({
  children,
  placement,
  title,
  trigger,
  triggerRef,
}) => {
  return (
    <Tippy
      animation="scale"
      content={<TooltipContent title={title}>{children}</TooltipContent>}
      placement={placement}
      hideOnClick={false}
      popperOptions={{
        modifiers: [
          {
            name: "preventOverflow",
            options: {
              altAxis: true,
              padding: 8,
            },
          },
        ],
      }}
      reference={triggerRef ? triggerRef : undefined}
    >
      {trigger}
    </Tippy>
  )
}

interface TooltipContentProps {
  children: React.ReactNode
  title?: string
}

const TooltipContent: React.FC<TooltipContentProps> = ({ children, title }) => {
  return (
    <TooltipContentContainer>
      {title && <Title>{title}</Title>}
      {children}
    </TooltipContentContainer>
  )
}

export default Tooltip
