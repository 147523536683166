import { FC } from "react"
import tw, { styled } from "twin.macro"

import GlobalFlag from "../../../assets/icons/flags/global.svg"
import staticAssetUrl from "../../../common/staticAssetUrl"

export enum Countries {
  GLOBAL = "GLOBAL",
  UNITED_STATES = "US",
  CANADA = "CA",
  UNITED_KINGDOM = "GB",
  AUSTRALIA = "AU",
  NEW_ZEALAND = "NZ",
  AFGHANISTAN = "AF",
  ALBANIA = "AL",
  ALGERIA = "DZ",
  ANGOLA = "AO",
  ARGENTINA = "AR",
  ARMENIA = "AM",
  AUSTRIA = "AT",
  AZERBAIJAN = "AZ",
  BANGLADESH = "BD",
  // BELARUS = "BY", // Unavailable due to U.S. sanctions
  BELGIUM = "BE",
  BENIN = "BJ",
  BOLIVIA = "BO",
  BOSNIA_AND_HERZEGOVINA = "BA",
  BOTSWANA = "BW",
  BRAZIL = "BR",
  BULGARIA = "BG",
  BURKINA_FASO = "BF",
  BURUNDI = "BI",
  CAMBODIA = "KH",
  CAMEROON = "CM",
  CENTRAL_AFRICAN_REPUBLIC = "CF",
  CHAD = "TD",
  CHILE = "CL",
  CHINA = "CN",
  COLOMBIA = "CO",
  CONGO = "CG",
  COSTA_RICA = "CR",
  COTE_D_IVOIRE = "CI",
  CROATIA = "HR",
  CZECH_REPUBLIC_CZECHIA = "CZ",
  DENMARK = "DK",
  DOMINICAN_REPUBLIC = "DO",
  DR_CONGO = "CD",
  ECUADOR = "EC",
  EGYPT = "EG",
  EL_SALVADOR = "SV",
  ERITREA = "ER",
  ESTONIA = "EE",
  ETHIOPIA = "ET",
  FINLAND = "FI",
  FRANCE = "FR",
  GABON = "GA",
  GAMBIA = "GM",
  GEORGIA = "GE",
  GERMANY = "DE",
  GHANA = "GH",
  GREECE = "GR",
  GUATEMALA = "GT",
  GUINEA = "GN",
  HAITI = "HT",
  HONDURAS = "HN",
  HUNGARY = "HU",
  INDIA = "IN",
  INDONESIA = "ID",
  IRAQ = "IQ",
  IRELAND = "IE",
  ISRAEL = "IL",
  ITALY = "IT",
  JAMAICA = "JM",
  JAPAN = "JP",
  JORDAN = "JO",
  KAZAKHSTAN = "KZ",
  KENYA = "KE",
  KUWAIT = "KW",
  KYRGYZSTAN = "KG",
  LAOS = "LA",
  LATVIA = "LV",
  LEBANON = "LB",
  LESOTHO = "LS",
  LIBERIA = "LR",
  LIBYA = "LY",
  LITHUANIA = "LT",
  LUXEMBOURG = "LU",
  MADAGASCAR = "MG",
  MALAWI = "MW",
  MALAYSIA = "MY",
  MALI = "ML",
  MALTA = "MT",
  MAURITANIA = "MR",
  MEXICO = "MX",
  MOLDOVA = "MD",
  MONGOLIA = "MN",
  MOROCCO = "MA",
  MOZAMBIQUE = "MZ",
  MYANMAR = "MM",
  NAMIBIA = "NA",
  NEPAL = "NP",
  NETHERLANDS = "NL",
  NICARAGUA = "NI",
  NIGER = "NE",
  NIGERIA = "NG",
  NORTH_MACEDONIA = "MK",
  NORWAY = "NO",
  OMAN = "OM",
  PAKISTAN = "PK",
  PANAMA = "PA",
  PAPUA_NEW_GUINEA = "PG",
  PARAGUAY = "PY",
  PERU = "PE",
  PHILIPPINES = "PH",
  POLAND = "PL",
  PORTUGAL = "PT",
  PUERTO_RICO = "PR",
  QATAR = "QA",
  ROMANIA = "RO",
  RWANDA = "RW",
  SAUDI_ARABIA = "SA",
  SENEGAL = "SN",
  SERBIA = "RS",
  SIERRA_LEONE = "SL",
  SINGAPORE = "SG",
  SLOVAKIA = "SK",
  SLOVENIA = "SI",
  SOMALIA = "SO",
  SOUTH_AFRICA = "ZA",
  SOUTH_KOREA = "KR",
  SOUTH_SUDAN = "SD",
  SPAIN = "ES",
  SRI_LANKA = "LK",
  SWEDEN = "SE",
  SWITZERLAND = "CH",
  TAIWAN = "TW",
  TAJIKISTAN = "TJ",
  TANZANIA = "TZ",
  THAILAND = "TH",
  TOGO = "TG",
  TUNISIA = "TN",
  TURKEY = "TR",
  TURKMENISTAN = "TM",
  UGANDA = "UG",
  UNITED_ARAB_EMIRATES = "AE",
  URUGUAY = "UY",
  UZBEKISTAN = "UZ",
  // VENEZUELA = "VE", // Unavailable due to U.S. sanctions
  VIETNAM = "VN",
  YEMEN = "YE",
  ZAMBIA = "ZM",
  ZIMBABWE = "ZW",
}

export const CountriesLongName: Record<Countries, String> = {
  GLOBAL: "Global",
  AF: "Afghanistan",
  AL: "Albania",
  DZ: "Algeria",
  AO: "Angola",
  AR: "Argentina",
  AM: "Armenia",
  AU: "Australia",
  AT: "Austria",
  AZ: "Azerbaijan",
  BD: "Bangladesh",
  // BY: "Belarus", // Unavailable due to U.S. sanctions
  BE: "Belgium",
  BJ: "Benin",
  BO: "Bolivia",
  BA: "Bosnia and Herzegovina",
  BW: "Botswana",
  BR: "Brazil",
  BG: "Bulgaria",
  BF: "Burkina Faso",
  BI: "Burundi",
  KH: "Cambodia",
  CM: "Cameroon",
  CA: "Canada",
  CF: "Central African Republic",
  TD: "Chad",
  CL: "Chile",
  CN: "China",
  CO: "Colombia",
  CG: "Congo",
  CR: "Costa Rica",
  CI: "Côte d'Ivoire",
  HR: "Croatia",
  CZ: "Czechia",
  DK: "Denmark",
  DO: "Dominican Republic",
  CD: "DR Congo",
  EC: "Ecuador",
  EG: "Egypt",
  SV: "El Salvador",
  ER: "Eritrea",
  EE: "Estonia",
  ET: "Ethiopia",
  FI: "Finland",
  FR: "France",
  GA: "Gabon",
  GM: "Gambia",
  GE: "Georgia",
  DE: "Germany",
  GH: "Ghana",
  GR: "Greece",
  GT: "Guatemala",
  GN: "Guinea",
  HT: "Haiti",
  HN: "Honduras",
  HU: "Hungary",
  IN: "India",
  ID: "Indonesia",
  IQ: "Iraq",
  IE: "Ireland",
  IL: "Israel",
  IT: "Italy",
  JM: "Jamaica",
  JP: "Japan",
  JO: "Jordan",
  KZ: "Kazakhstan",
  KE: "Kenya",
  KW: "Kuwait",
  KG: "Kyrgyzstan",
  LA: "Laos",
  LV: "Latvia",
  LB: "Lebanon",
  LS: "Lesotho",
  LR: "Liberia",
  LY: "Libya",
  LT: "Lithuania",
  LU: "Luxembourg",
  MG: "Madagascar",
  MW: "Malawi",
  MY: "Malaysia",
  ML: "Mali",
  MT: "Malta",
  MR: "Mauritania",
  MX: "Mexico",
  MD: "Moldova",
  MN: "Mongolia",
  MA: "Morocco",
  MZ: "Mozambique",
  MM: "Myanmar",
  NA: "Namibia",
  NP: "Nepal",
  NL: "Netherlands",
  NZ: "New Zealand",
  NI: "Nicaragua",
  NE: "Niger",
  NG: "Nigeria",
  MK: "North Macedonia",
  NO: "Norway",
  OM: "Oman",
  PK: "Pakistan",
  PA: "Panama",
  PG: "Papua New Guinea",
  PY: "Paraguay",
  PE: "Peru",
  PH: "Philippines",
  PL: "Poland",
  PT: "Portugal",
  PR: "Puerto Rico",
  QA: "Qatar",
  RO: "Romania",
  RW: "Rwanda",
  SA: "Saudi Arabia",
  SN: "Senegal",
  RS: "Serbia",
  SL: "Sierra Leone",
  SG: "Singapore",
  SK: "Slovakia",
  SI: "Slovenia",
  SO: "Somalia",
  ZA: "South Africa",
  KR: "South Korea",
  SD: "South Sudan",
  ES: "Spain",
  LK: "Sri Lanka",
  SE: "Sweden",
  CH: "Switzerland",
  TW: "Taiwan",
  TJ: "Tajikistan",
  TZ: "Tanzania",
  TH: "Thailand",
  TG: "Togo",
  TN: "Tunisia",
  TR: "Turkey",
  TM: "Turkmenistan",
  UG: "Uganda",
  AE: "United Arab Emirates",
  GB: "United Kingdom",
  US: "United States",
  UY: "Uruguay",
  UZ: "Uzbekistan",
  // VE: "Venezuela", // Unavailable due to U.S. sanctions
  VN: "Vietnam",
  YE: "Yemen",
  ZM: "Zambia",
  ZW: "Zimbabwe",
}

export const internationalCountries: Countries[] = Object.keys(Countries)
  .map((key) => Countries[key as keyof typeof Countries])
  .filter(
    (country) => ![Countries.UNITED_STATES, Countries.GLOBAL].includes(country),
  )

export const allCountries: Countries[] = Object.keys(Countries)
  .map((key) => Countries[key as keyof typeof Countries])
  .filter((country) => ![Countries.GLOBAL].includes(country))

const flagSrc = (country: Countries, size: "s" | "m" | "l" = "m") => {
  if (country === Countries.GLOBAL) {
    return GlobalFlag
  }

  return staticAssetUrl(`static/flags/${size}/${country}.svg`)
}

interface Props {
  country: Countries
  size?: "s" | "m" | "l"
}

export const Flag: FC<Props> = ({ country, size = "m", ...props }) => (
  <StyledFlag src={flagSrc(country, size)} alt={country} {...props} />
)

const StyledFlag = styled.img`
  ${tw`rounded-sm`}
  filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.1));
`
