import { gql, useQuery } from "@apollo/client"
import { Link } from "react-router-dom"

import { track } from "../common/analytics"

import {
  GetWebflowPostRelatedPostsQuery,
  GetWebflowPostRelatedPostsQueryVariables,
} from "@/types/graphql-types"

interface Props {
  postSlug: string
}

export default function SidebarRelatedPosts({ postSlug }: Props) {
  const { data } = useQuery<
    GetWebflowPostRelatedPostsQuery,
    GetWebflowPostRelatedPostsQueryVariables
  >(WEBFLOW_POST_RELATED_POSTS_QUERY, {
    variables: { slug: postSlug },
  })

  const posts = data?.webflowPost?.relatedPosts

  return (
    <div tw="sticky top-8 pt-24 lg:pt-0 xl:pl-24">
      {posts?.map((post) => (
        <Link
          to={`/blog/${post.slug}`}
          key={post.slug}
          tw="mb-8 block hover:text-primary-new-600 transition-all active:(scale-95)"
          onClick={() =>
            track("Blog - Post - Click Related Post", {
              postSlug,
              relatedPostSlug: post.slug,
            })
          }
        >
          {post.thumbnailUrl && (
            <img src={post.thumbnailUrl} alt="" tw="min-h-[150px] rounded-lg" />
          )}
          <div tw="pt-3 font-arizona-mix text-xl leading-normal">
            {post.title}
          </div>
        </Link>
      ))}
    </div>
  )
}

const WEBFLOW_POST_RELATED_POSTS_QUERY = gql`
  query GetWebflowPostRelatedPosts($slug: String!) {
    webflowPost(slug: $slug) {
      relatedPosts {
        title
        thumbnailUrl
        slug
        overview
        excerpt
      }
    }
  }
`
