import { gql, useMutation } from "@apollo/client"
import { isNil } from "lodash-es"
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react"
import { toast } from "react-hot-toast"
import tw, { styled } from "twin.macro"

import { ReactComponent as CheckmarkSvg } from "./images/checkmark.svg"
import { ReactComponent as CustomStoreIcon } from "./images/custom-store.svg"
import { ReactComponent as DownArrowSvg } from "./images/down-arrow.svg"
import { ReactComponent as PlusIcon } from "./images/plus.svg"
import SelectedGiftsDrawer from "./SelectedGiftsDrawer"
import Button from "../../common/Button"
import Store from "../../store/Store"

import {
  CustomStoreFullFragment,
  CustomStoreOptionListItemFragment,
  Store_CustomStores_AddCustomStoreOptionMutation,
  Store_CustomStores_AddCustomStoreOptionMutationVariables,
} from "@/types/graphql-types"

interface CustomStoreBrowserProps {
  customStore: CustomStoreFullFragment
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  storeName: string
  refetchCustomStore: Function
}

const CustomStoreBrowser = ({
  customStore,
  open,
  setOpen,
  storeName,
  refetchCustomStore,
}: CustomStoreBrowserProps) => {
  const [openDrawer, setOpenDrawer] = useState(false)
  const [selectedGiftsDrawerOpen, setSelectedGiftsDrawerOpen] = useState(false)

  const openCustomStoreDrawer = () => {
    setOpenDrawer(true)
  }

  // close drawer and hide child components
  const closeCustomStoreDrawer = () => {
    setOpenDrawer(false)

    // Timeout to allow for CustomStoreBrowser to slide down, fade out overlay, and then remove the div
    setTimeout(function () {
      // To reset the open prop to false when closing the drawer
      // This must be called here also since it doesn't get called on the onClose callback when using this method
      // to close the drawer
      setOpen(false)
    }, 500)
  }

  // when the open prop changes, open / close the drawer based on the prop
  // this will allow for opening/closing the drawer from outside the component
  useEffect(() => {
    if (open) {
      openCustomStoreDrawer()
    } else {
      closeCustomStoreDrawer()
    }
  }, [open])

  const [addCustomStoreOption] = useMutation<
    Store_CustomStores_AddCustomStoreOptionMutation,
    Store_CustomStores_AddCustomStoreOptionMutationVariables
  >(ADD_CUSTOM_STORE_OPTION)

  const handleAddCustomStoreOption = async (
    id: string | null | undefined,
    giftOptionId: string | null,
    productId: string | null,
    giftCardAmount?: number,
  ) => {
    if (isNil(id)) {
      toastError()
      return
    }

    const { data } = await addCustomStoreOption({
      variables: {
        customStoreId: id,
        giftOptionId: giftOptionId,
        productId: productId,
        giftCardAmount: giftCardAmount,
      },
    })

    if (data?.customStoreAddOption?.ok) {
      toast.success("Added")
      !!refetchCustomStore && refetchCustomStore()
    } else {
      const additionalErrorMessage = errorMessage(
        data?.customStoreAddOption?.errors,
      )

      if (additionalErrorMessage) {
        toast.error(
          <div>
            <div tw="font-medium">Unable to add product to custom store</div>
            {additionalErrorMessage && (
              <div tw="mt-2">{additionalErrorMessage}</div>
            )}
          </div>,
          { duration: 4000 },
        )
      } else {
        toastError()
      }
    }
  }

  const errorMessage = (errors?: string[] | null) => {
    if (!isNil(errors) && errors.length > 0) {
      return " " + errors[errors.length - 1]
    }
  }

  const toastError = () => {
    toast.error("An error occurred. Unable to add product to custom store.")
  }

  const customStoreBrowserProps = () => {
    const customStoreProductButton = (productID: string) => {
      return (
        <AddProductToCustomStoreButton
          customStoreID={customStore.id}
          productID={productID}
          customStoreOptions={customStore.optionList}
          handleAddCustomStoreOption={handleAddCustomStoreOption}
        />
      )
    }
    const customStoreGiftOptionButton = (
      giftOptionID: string,
      isSingleProduct: boolean,
    ) => {
      return (
        <AddGiftOptionToCustomStoreButton
          customStoreID={customStore.id}
          giftOptionID={giftOptionID}
          customStoreOptions={customStore.optionList}
          handleAddCustomStoreOption={handleAddCustomStoreOption}
          isSingleProduct={isSingleProduct}
        />
      )
    }

    const customStoreGiftCardButton = (
      productID: string,
      giftCardAmount: number | null,
    ) => {
      return (
        <AddGiftCardToCustomStoreButton
          customStoreID={customStore.id}
          productID={productID}
          giftCardAmount={giftCardAmount}
          customStoreOptions={customStore.optionList}
          handleAddCustomStoreOption={handleAddCustomStoreOption}
        />
      )
    }

    const isGiftCardInCustomStore = (productID: string) => {
      return isProductSelected(productID, customStore.optionList)
    }

    return {
      customStoreProductButton,
      customStoreGiftOptionButton,
      customStoreGiftCardButton,
      isGiftCardInCustomStore,
    }
  }

  return (
    <>
      <div
        className="fadeIn animated"
        tw="fixed w-screen h-screen top-0 left-0 duration-200 ease-in-out transition-all transform"
        css={{
          zIndex: 70,
          backgroundColor: "rgba(122, 122, 122, 0.5)",
          ...(openDrawer ? tw`opacity-100` : tw`delay-200 opacity-0`),
        }}
      ></div>
      <div
        className="fadeInUp animated"
        tw="fixed bg-white duration-500 ease-in-out transition-all transform w-full flex flex-col items-stretch inset-0"
        css={{
          zIndex: 80,
        }}
      >
        <div tw="bg-white overflow-y-scroll pb-48">
          <div className="custom-store-browser">
            <Store
              visible={true}
              isEmbedded={true}
              useVirtualUrl={true}
              customStoreBrowserProps={customStoreBrowserProps()}
              caller="custom_store"
            />
          </div>
        </div>
        <FooterContainer>
          <CustomStoreBrowserFooter>
            {/* pl-[x] is padding for the intercom button */}
            <div tw="self-center hidden md:block md:pl-16 2xl:pl-0">
              <div tw="text-gray-450">Custom Store</div>
              <div tw="font-semibold">{storeName}</div>
            </div>
            <GiftsSelectedButton
              onClick={() => {
                setSelectedGiftsDrawerOpen(!selectedGiftsDrawerOpen)
              }}
            >
              <div tw="border-r border-gray-150 px-4 py-2">{`${
                customStore.optionList.length
              } gift${
                customStore.optionList.length === 1 ? "" : "s"
              } selected`}</div>
              <div tw="flex items-center gap-1">
                <CustomStoreIcon tw="w-6 text-primary-500" />
                <DownArrowSvg
                  tw="w-3 h-2 text-gray-400 transition-all ease-in-out duration-200"
                  css={{
                    ...(selectedGiftsDrawerOpen ? tw`rotate-180` : tw``),
                  }}
                />
              </div>
            </GiftsSelectedButton>
            <div tw="flex gap-2 justify-self-center md:justify-self-end self-center">
              <Button
                tw="bg-primary-500 hover:bg-primary-400 active:bg-primary-600 shadow-none text-white"
                onClick={closeCustomStoreDrawer}
              >
                <CheckmarkSvg tw="w-4 text-white" />
                Done
              </Button>
            </div>
          </CustomStoreBrowserFooter>
        </FooterContainer>
        {selectedGiftsDrawerOpen && (
          <SelectedGiftsDrawer
            customStore={customStore}
            open={selectedGiftsDrawerOpen}
            setOpen={setSelectedGiftsDrawerOpen}
            refetchCustomStore={refetchCustomStore}
          />
        )}
      </div>
    </>
  )
}

const FooterContainer = styled.div`
  ${tw`z-[2000] bg-white fixed bottom-0 w-full flex justify-center pl-12 pr-2 sm:pr-4 md:px-12 flex-1`}

  box-shadow: 0px -6px 24px rgba(0, 0, 0, 0.05),
    0px -2px 4px rgba(0, 0, 0, 0.03);
`

const CustomStoreBrowserFooter = styled.div`
  ${tw`bg-white w-full h-24 grid grid-cols-2 md:grid-cols-3 content-center self-end xl:max-w-[1440px] pl-16 md:pl-0`};
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
`

const GiftsSelectedButton = styled.button`
  ${tw`border box-border border-gray-150 hover:border-gray-250 active:bg-gray-050 rounded-full flex gap-3 pr-4 items-center justify-self-center self-center`};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
`

const isProductSelected = (
  productID: string,
  customStoreOptions: CustomStoreOptionListItemFragment[],
) => {
  const isSelectedAsProduct = !!customStoreOptions.find(
    (option) => option.product?.id === productID,
  )
  const isSelectedWithinGiftOption = !!customStoreOptions.find(
    (option) =>
      option.giftOption?.products.find((product) => product.id === productID),
  )

  return isSelectedAsProduct || isSelectedWithinGiftOption
}

interface AddProductToCustomStoreButtonProps {
  customStoreID: string | null | undefined
  productID: string
  customStoreOptions: CustomStoreOptionListItemFragment[]
  handleAddCustomStoreOption: Function
}

const AddProductToCustomStoreButton: React.FC<
  AddProductToCustomStoreButtonProps
> = ({
  customStoreID,
  productID,
  customStoreOptions,
  handleAddCustomStoreOption,
}) => {
  if (isProductSelected(productID, customStoreOptions)) {
    return (
      <CustomStoreProductButton className="selected">
        <div tw="flex items-center justify-center gap-1">
          <CustomStoreIcon css={{ color: "#27AE60" }} />
          <CheckmarkSvg css={{ color: "#27AE60" }} />
        </div>
        <div css={{ color: "#27AE60" }} tw="font-semibold">
          In custom store
        </div>
      </CustomStoreProductButton>
    )
  } else {
    return (
      <CustomStoreProductButton
        className="not-selected"
        onClick={() =>
          handleAddCustomStoreOption(customStoreID, null, productID)
        }
      >
        <div tw="flex">
          <CustomStoreIcon tw="text-white" />
          <PlusIcon tw="text-white" />
        </div>
        <div tw="text-white font-semibold">Add to custom store</div>
      </CustomStoreProductButton>
    )
  }
}

const CustomStoreProductButton = styled.button`
  ${tw`bg-gradient-to-r text-xl text-white font-medium inline-flex px-6 rounded-lg flex gap-3 items-center mb-5 lg:mb-0 lg:mr-6 transition-all focus:outline-none relative overflow-hidden z-10 py-3`}

  &.not-selected {
    ${tw`active:scale-95 hover:opacity-80 active:opacity-90 transition-opacity`}
    background: linear-gradient(90deg, #9F73F7 0%, #7A68F6 100%), linear-gradient(74.31deg, #E4A1B8 -7.94%, #B37DD5 99.23%), #FFFFFF;
  }

  &.selected {
    ${tw`cursor-default`}
    background: rgba(39, 174, 96, 0.05);
    border: 1px solid rgba(39, 174, 96, 0.3);
  }

  &.gift-card {
    ${tw`relative justify-center w-full`}

    z-index: 15;
    transition: opacity 0.2s ease-out;
  }

  &:disabled {
    ${tw`opacity-50 cursor-auto`}
  }
`

const useIsGiftOptionSelected = (
  giftOptionID: string,
  customStoreOptions: CustomStoreOptionListItemFragment[],
) => {
  const isSelected = useMemo(
    () =>
      !!customStoreOptions.find(
        (option) => option.giftOption?.id === giftOptionID,
      ),
    [customStoreOptions, giftOptionID],
  )

  return isSelected
}

interface AddGiftOptionToCustomStoreButtonProps {
  customStoreID: string | null | undefined
  giftOptionID: string
  customStoreOptions: CustomStoreOptionListItemFragment[]
  isSingleProduct: boolean
  handleAddCustomStoreOption: Function
}

const AddGiftOptionToCustomStoreButton: React.FC<
  AddGiftOptionToCustomStoreButtonProps
> = ({
  customStoreID,
  giftOptionID,
  customStoreOptions,
  isSingleProduct,
  handleAddCustomStoreOption,
}) => {
  if (useIsGiftOptionSelected(giftOptionID, customStoreOptions)) {
    return (
      <div tw="flex items-center justify-center flex-col">
        <CustomStoreGiftOptionButton className="selected">
          <div tw="flex items-center justify-center gap-1">
            <CustomStoreIcon css={{ color: "#27AE60" }} />
            <CheckmarkSvg css={{ color: "#27AE60" }} />
          </div>
          <div tw="flex flex-col items-start">
            <div css={{ color: "#27AE60" }} tw="font-semibold text-left">
              Brand is in custom store
            </div>
          </div>
        </CustomStoreGiftOptionButton>
        {!isSingleProduct && (
          <div tw="text-gray-450 pt-2 text-left">
            You can customize products below.
          </div>
        )}
      </div>
    )
  } else {
    return (
      <div tw="flex items-center justify-center flex-col">
        <CustomStoreGiftOptionButton
          className="not-selected"
          onClick={() => {
            handleAddCustomStoreOption(customStoreID, giftOptionID, null)
          }}
        >
          <div tw="flex">
            <CustomStoreIcon />
            <PlusIcon />
          </div>
          <div tw="flex flex-col items-start">
            <div tw="font-semibold text-left">Add brand to custom store</div>
          </div>
        </CustomStoreGiftOptionButton>
        {!isSingleProduct && (
          <div tw="text-gray-450 pt-2 text-left">
            You can delete specific products after.
          </div>
        )}
      </div>
    )
  }
}

const CustomStoreGiftOptionButton = styled.button`
  ${tw`flex items-center border box-border rounded-xl border-primary-new-600 bg-primary-new-600 hover:(border-primary-new-700 bg-primary-new-700) active:scale-95 gap-4 px-5 py-2 mt-6 transition-all`}

  &.not-selected {
    ${tw`text-white`}
  }

  &.selected {
    ${tw`cursor-default`}
    background: rgba(39, 174, 96, 0.05);
    border: 1px solid rgba(39, 174, 96, 0.3);
  }

  &:disabled {
    ${tw`opacity-50 cursor-auto`}
  }
`

interface AddGiftCardToCustomStoreButtonProps {
  customStoreID: string | null | undefined
  productID: string
  giftCardAmount: number | null
  customStoreOptions: CustomStoreOptionListItemFragment[]
  handleAddCustomStoreOption: Function
}

const AddGiftCardToCustomStoreButton: React.FC<
  AddGiftCardToCustomStoreButtonProps
> = ({
  customStoreID,
  productID,
  giftCardAmount,
  customStoreOptions,
  handleAddCustomStoreOption,
}) => {
  const isProductInStore = isProductSelected(productID, customStoreOptions)
  const addButton = () => {
    if (isProductInStore) {
      return (
        <CustomStoreProductButton className="selected gift-card">
          <div tw="flex items-center justify-center gap-1">
            <CustomStoreIcon css={{ color: "#27AE60" }} />
            <CheckmarkSvg css={{ color: "#27AE60" }} />
          </div>
          <div css={{ color: "#27AE60" }} tw="font-semibold">
            In custom store
          </div>
        </CustomStoreProductButton>
      )
    } else if (!!giftCardAmount) {
      return (
        <CustomStoreProductButton
          tw="mr-0"
          className="not-selected gift-card"
          onClick={() =>
            handleAddCustomStoreOption(
              customStoreID,
              null,
              productID,
              giftCardAmount,
            )
          }
        >
          <div tw="flex">
            <CustomStoreIcon tw="text-white" />
            <PlusIcon tw="text-white" />
          </div>
          <div tw="text-white font-semibold">Add to custom store</div>
        </CustomStoreProductButton>
      )
    } else {
      return <></>
    }
  }

  return (
    <GiftCardButtonContainer
      css={[
        (isProductInStore || !!giftCardAmount) && {
          transform: "translate3d(0, 0, 0)",
        },
      ]}
    >
      {addButton()}
    </GiftCardButtonContainer>
  )
}

const GiftCardButtonContainer = styled.div`
  // Scrim that covers the bottom below the button.
  &::before {
    position: absolute;
    content: "";
    right: 0;
    bottom: 0;
    left: 0;
    height: 7.5rem;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.8) 40%,
      rgba(255, 255, 255, 1) 100%
    );
    transition: opacity 0.5s ease-out;
    opacity: 1;
  }
`

const ADD_CUSTOM_STORE_OPTION = gql`
  mutation Store_CustomStores_AddCustomStoreOption(
    $customStoreId: ID!
    $productId: ID
    $giftOptionId: ID
    $giftCardAmount: Int
  ) {
    customStoreAddOption(
      customStoreId: $customStoreId
      productId: $productId
      giftOptionId: $giftOptionId
      giftCardAmount: $giftCardAmount
    ) {
      ok
      errors
    }
  }
`

export default CustomStoreBrowser
