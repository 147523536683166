import { useEffect } from "react"

import { ExperimentName } from "@/common/experiments"
import { useExperiments } from "@/common/hooks/experiments"

export const useExperimentConfig = <T extends ExperimentName>(
  experimentName: T,
) => {
  const { triggerExperiment, getExperimentConfig } = useExperiments()

  useEffect(() => triggerExperiment(experimentName))

  return getExperimentConfig(experimentName)
}
