import { gql } from "@apollo/client"

export const OrganizationUpdateSamlIdentityProvider = gql`
  mutation OrganizationUpdateSamlIdentityProvider(
    $organizationId: ID!
    $ssoUrl: String!
    $entityId: String!
    $certificate: String!
  ) {
    samlUpdateIdentityProvider(
      organizationId: $organizationId
      ssoUrl: $ssoUrl
      entityId: $entityId
      certificate: $certificate
    ) {
      ok
      error
      samlIdentityProvider {
        organizationId
        enabled
        samlId
        certificate
        ssoUrl
        entityId
        acsUrl
        spEntityId
      }
    }
  }
`
