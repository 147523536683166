import { useMemo } from "react"

import { useFeatureAccess } from "./featureAccess"
import { useGlobalState } from "../GlobalState"

import { OrganizationMembershipAccess } from "@/types/graphql-types"

export type PlanPrivilege = "none" | "pro" | "team_limited" | "team_full"

export default function usePlanPrivilege() {
  const { hasFeature } = useFeatureAccess()
  const [organization] = useGlobalState("organization")

  const hasPro = hasFeature("pro_plan")

  const privilege = useMemo<PlanPrivilege>(() => {
    if (organization) {
      // EE plans are considered Team plans, and there are only full members
      if (organization.subscription?.isEe) {
        return "team_full"
      }

      if (organization.subscription?.isTeamPlan) {
        if (
          organization.currentMembership?.access ===
          OrganizationMembershipAccess.full
        ) {
          return "team_full"
        }

        return "team_limited"
      }
    }

    if (hasPro) {
      return "pro"
    }

    return "none"
  }, [organization, hasPro])

  return { privilege }
}
