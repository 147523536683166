import { Helmet } from "react-helmet-async"

import FrequentlyAskedQuestions from "./FrequentlyAskedQuestions"
import Hero from "./Hero"
import HowItWorks from "./HowItWorks"
import MemorableGifts from "./MemorableGifts"
import OrderStatuses from "./OrderStatuses"
import ProductDescription from "./ProductDescription"
import Brands from "../../business/Brands"
import GiftingInternationally from "../components/GiftingInternationally"

const BulkGifting = () => (
  <>
    <Helmet>
      <title>
        Bulk Gifting Platform - Send 1,000+ Gifts In Minutes | Goody
      </title>
      <meta
        name="description"
        content="Goody's bulk gifting platform lets you send thousands of corporate gifts. Send custom swag, curated brands, or gift cards. Gift internationally to 140+ countries."
      />
    </Helmet>
    <Hero />
    <Brands tw="my-24 lg:mt-24" />
    <HowItWorks />
    <ProductDescription />
    <GiftingInternationally />
    <OrderStatuses />
    <MemorableGifts />
    <FrequentlyAskedQuestions />
  </>
)

export default BulkGifting
