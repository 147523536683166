import "twin.macro"

import ModalCloseButton from "../components/ModalCloseButton"
import PopoverShippingCountryList from "../components/shipping/PopoverShippingCountryList"
import useStoreContext from "../hooks/useStoreContext"

import { ReactComponent as Chevron } from "@/assets/icons/chevron-left.svg"
import { useFeatureFlags } from "@/common/hooks/featureFlags"
import { ShippingCountryGroup } from "@/types/graphql-types"

interface Props {
  onClose?: () => void
  backButtonLabel?: string
  shippingCountryGroup: ShippingCountryGroup
}

export default function PDPHeader({ onClose, shippingCountryGroup }: Props) {
  const { hasFeatureFlag } = useFeatureFlags()
  const { locationStackDownstackItem } = useStoreContext()

  // The back button label is the downstack item's name (either the previous
  // gift option or the background page) or "Back" if there is no downstack item
  const backButtonLabel = locationStackDownstackItem?.state?.name || "Back"

  return (
    <>
      {onClose ? (
        <div tw="hidden lg:flex flex-row justify-between px-10 pt-6 pb-6 items-center gap-6 h-[90px]">
          <button
            tw="flex flex-row items-center gap-2 text-lg text-gray-500 -ml-2 -mr-3 -my-2 pl-2 pr-3 py-2 transition-all hover:bg-gray-100 active:scale-95 rounded-lg"
            onClick={onClose}
          >
            <Chevron tw="stroke-current" />
            <div>{backButtonLabel}</div>
          </button>

          {hasFeatureFlag("shipping_config_v2") && (
            <div tw="pr-10">
              <PopoverShippingCountryList
                shippingCountryGroup={shippingCountryGroup}
              />
            </div>
          )}

          <ModalCloseButton onClick={onClose} />
        </div>
      ) : (
        <div tw="h-12" />
      )}
    </>
  )
}
