import React from "react"
import tw from "twin.macro"

import { QuantityMinus, QuantityPlus } from "../../assets/icons"
import { Product } from "../../common/GlobalState"
import { VariantsArray, useGiftCart } from "../../common/hooks/giftData"

type Props = {
  product: Product
  variants: VariantsArray
}

const QuantitySelector: React.FC<Props> = ({ product, variants }) => {
  const { increaseQuantity, decreaseQuantity, setQuantity } = useGiftCart()

  function handleClickQuantity() {
    const newQuantity = window.prompt(
      "Enter the new quantity:",
      product.quantity.toString(),
    )
    if (newQuantity) {
      const parsedQuantity = parseInt(newQuantity)
      if (!isNaN(parsedQuantity) && parsedQuantity >= 0) {
        setQuantity(product, variants, parsedQuantity)
      }
    }
  }

  return (
    <div tw={"flex flex-row items-stretch"}>
      <button
        tw={
          "h-8 w-8 bg-primary-000 rounded-full flex items-center justify-center"
        }
        onClick={() => decreaseQuantity(product, variants)}
      >
        <QuantityMinus />
      </button>
      <div
        tw="text-primary-800 text-xl mx-1 text-center rounded-md transition-all hover:(bg-primary-new-000 cursor-pointer) active:scale-90 flex flex-row items-center justify-center"
        css={[
          product.quantity < 10 && tw`w-9`,
          product.quantity >= 10 && product.quantity < 100 && tw`w-10`,
          product.quantity >= 100 && tw`w-12`,
        ]}
        onClick={handleClickQuantity}
      >
        {product.quantity}
      </div>
      <button
        tw={
          "h-8 w-8 bg-primary-000 rounded-full flex items-center justify-center"
        }
        onClick={() => {
          increaseQuantity(product, variants)
        }}
      >
        <QuantityPlus />
      </button>
    </div>
  )
}

export default QuantitySelector
