import React from "react"

import RothysImage from "./images/Rothys.svg"
import Softbank from "./images/Softbank.svg"
import CarouselComponent, {
  ContentProps as Testimonials,
} from "../components/Carousel"
import GongImage from "../pricing/images/Gong.svg"

const DEFAULT_TESTIMONIALS = [
  {
    text: "“Goody made it possible to send 800+ high quality gifts to our employees during the holidays. Their gift options were plentiful, turnaround time was quick, customization options were unique, but most importantly, it was so easy to administer and track.\n\n Their team was always a text or a call away and made the experience easy. Since that big send, the team has used Goody a bunch and raves about the platform.”",
    name: "Grace Lancaster",
    title: "Employee Engagement and Experience, Gong",
    image: GongImage,
  },
  {
    text: "“Our team has loved the option to swap the gift for anything they want. This is such a helpful feature when ordering for several team members. The process to redeem gifts is super easy.”",
    name: "Shaumiya Suehiro-Woell",
    title: "People Operations, Rothy’s",
    image: RothysImage,
  },
  {
    text: "“Let me emphasize that it is a lot of fun and the reaction was overwhelmingly positive. The interface is super easy to use. And our team really appreciated it!!”",
    name: "Kelly Matika",
    title: "Partner, Human Capital at SoftBank",
    image: Softbank,
  },
]

interface ICarousel {
  testimonials?: Testimonials[]
}

const Carousel = ({ testimonials }: ICarousel) => {
  return <CarouselComponent content={testimonials || DEFAULT_TESTIMONIALS} />
}

export default Carousel
