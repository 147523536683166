import { useEffect, useRef, useState } from "react"

import { CountriesLongName, Flag } from "./InternationalShipping"
import InternationalShippingFilterPopover from "./InternationalShippingFilterPopover"

import { useGlobalState } from "@/common/GlobalState"
import FilterButton from "@/store/filters/FilterButton"
import { useCountry } from "@/store/GiftOptionCountryProvider"

const InternationalShippingFilter = () => {
  const { selectedShippingCountry, setSelectedShippingCountry } = useCountry()

  const buttonRef = useRef<HTMLButtonElement>(null)
  const [popoverOpen, setPopoverOpen] = useState(false)
  const [currentRealm] = useGlobalState("currentRealm")

  useEffect(() => {
    if (window.location.hash === "#global") {
      setTimeout(() => {
        setPopoverOpen(true)

        // This component can be rendered multiple times, especially when using
        // StrictMode. This causes the hash to be removed and not available on
        // the next render. So, only clear it after a delay.
        setTimeout(() => {
          if (window.location.hash === "#global") {
            window.history.replaceState({}, "", window.location.pathname)
          }
        }, 500)
      }, 500)
    }
  }, [])

  let triggerButtonY = buttonRef.current?.getBoundingClientRect().y

  if (triggerButtonY) {
    // In V2, we are not in a scroll container, and getBoundingClientRect().y
    // is relative to the viewport, so we need to adjust by the viewport's
    // vertical scroll
    triggerButtonY += window.scrollY

    // The popover adds a margin by default which is a bit too much
    triggerButtonY -= 16
  }

  // Get the distance between the right of the button and the right of the
  // viewport
  const triggerButtonRight = buttonRef.current?.getBoundingClientRect().right
  const triggerButtonDistanceFromRight =
    document.body.scrollWidth - (triggerButtonRight || 0)

  let selectedShippingCountryName = CountriesLongName[selectedShippingCountry]
  if (selectedShippingCountryName === "United States") {
    selectedShippingCountryName = "USA"
  }

  if (currentRealm === "consumer") {
    return null
  }

  return (
    <div tw="flex relative flex-shrink-0">
      <FilterButton
        onClick={() => setPopoverOpen(!popoverOpen)}
        filterIsActive={selectedShippingCountry !== "US"}
        filterDropdownIsOpen={popoverOpen}
        buttonRef={buttonRef}
      >
        <Flag country={selectedShippingCountry} size="m" />
        <span tw="hidden lg:block">{selectedShippingCountryName}</span>
      </FilterButton>
      <InternationalShippingFilterPopover
        popoverOpen={popoverOpen}
        setPopoverOpen={setPopoverOpen}
        selectedShippingCountry={selectedShippingCountry}
        setSelectedShippingCountry={setSelectedShippingCountry}
        triggerButtonY={triggerButtonY}
        triggerButtonDistanceFromRight={triggerButtonDistanceFromRight}
      />
    </div>
  )
}

export default InternationalShippingFilter
