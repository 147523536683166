import PurpleGradientLink from "../../../common/PurpleGradientLink"
import RainbowHeading from "../../../common/RainbowHeading"
import { generateRealmPath } from "../../../common/realm"
import staticAssetUrl from "../../../common/staticAssetUrl"

const CustomCollectionsExample = staticAssetUrl(
  "landing/how_it_works/branded_swag/customCollectionsExample.png",
)

const CustomCollections = () => (
  <div tw="bg-white px-5 py-16">
    <div tw="max-w-[500px] lg:max-w-[1200px] mx-auto flex flex-col lg:flex-row">
      <img
        tw="mx-auto mb-20 max-w-[44rem] w-full lg:mr-16"
        src={CustomCollectionsExample}
      />
      <div tw="lg:pt-12">
        <RainbowHeading tw="font-bold mb-4 text-2xl">
          Custom Collections
        </RainbowHeading>
        <h2 tw="text-left font-bold mb-6 text-3xl lg:text-[3rem] lg:leading-[3.5rem]">
          Build a digital swag closet.
        </h2>
        <div tw="text-gray-600 text-xl mb-6">
          Let your recipients choose from a custom assortment of on-demand or
          pre-ordered swag &mdash; or regular gifts from our collection. Brand
          your store with your logo and messaging.
        </div>
        <div tw="text-sm text-gray-600 text-sm mb-10">
          Available on Teams plan.
        </div>
        <PurpleGradientLink
          tw="lg:text-lg px-10 w-full sm:w-[14rem] lg:w-auto"
          to={generateRealmPath("business", "/business/team-demo ")}
        >
          Book a Demo
        </PurpleGradientLink>
      </div>
    </div>
  </div>
)

export default CustomCollections
