import { ReactNode } from "react"

import Checkbox from "@/common/Checkbox"
import {
  SelectionGroup,
  SelectionIndicator,
  SelectionLabel,
} from "@/common/SelectionGroup/index"
import { SelectionGroupColorEnum } from "@/common/SelectionGroup/types"

interface Props {
  selected: boolean
  text: ReactNode
  description?: ReactNode
  onClick: () => void
  disabled?: boolean
  unclickable?: boolean
  color?: SelectionGroupColorEnum
  variant?: "radio" | "checkbox"
}

export const SelectionGroupWithText = ({
  selected,
  text,
  disabled,
  onClick,
  unclickable,
  color,
  description,
  variant = "radio",
}: Props) => (
  <SelectionGroup
    tw="flex items-start"
    disabled={disabled}
    onClick={onClick}
    unclickable={unclickable}
  >
    {variant === "radio" && (
      <SelectionIndicator tw="my-0.5" selected={selected} color={color} />
    )}
    {variant === "checkbox" && (
      <Checkbox tw="my-0.5 mr-3" selected={selected} color={color} />
    )}

    <div tw="flex flex-col gap-1">
      <SelectionLabel
        selected={variant !== "checkbox" && selected}
        color={color}
      >
        {text}
      </SelectionLabel>
      {description && (
        <div tw="text-sm font-normal text-gray-700">{description}</div>
      )}
    </div>
  </SelectionGroup>
)
