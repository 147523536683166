import { Link } from "react-router-dom"

import { formatPrice } from "../../../common/format"
import { ReactComponent as PlusIcon } from "../../../common/images/plus.svg"
import { generateRealmPath } from "../../../common/realm"

import { BudgetSummary } from "@/types/graphql-types"

interface Props {
  organizationBalance: number | undefined
  budgetSummary?: BudgetSummary
  loading: boolean
}

export default function BudgetingHUD({
  organizationBalance,
  budgetSummary,
}: Props) {
  return (
    <div>
      <div tw="border-b border-gray-200 pt-9"></div>
      <div tw="-mt-16 h-32 flex flex-row items-center justify-center gap-4">
        <div tw="relative">
          <div tw="bg-primary-new-000 border border-primary-new-100 rounded-xl shadow-min h-32 w-60">
            <div tw="h-11 flex flex-col justify-end text-center">
              <div tw="text-primary-new-500 font-semibold">
                Organization balance
              </div>
            </div>
            <div tw="text-center">
              <div tw="mt-4 px-3 py-[2px] bg-white rounded-full shadow-min text-2xl font-medium text-center inline-block">
                {organizationBalance === undefined
                  ? "···"
                  : formatPrice(organizationBalance)}
              </div>
            </div>
          </div>
          <AddFundsButton />
        </div>
        <div tw="bg-white border border-primary-new-100 rounded-xl shadow-min h-32 w-60">
          <div tw="h-11 flex flex-col justify-end text-center">
            <div tw="text-primary-new-500 font-semibold">Recurring budgets</div>
          </div>
          <div tw="pt-2 text-lg font-medium text-center">
            {!budgetSummary
              ? "···"
              : formatPrice(budgetSummary.budgetTotalMonthly)}
            <span tw="text-gray-400"> / month</span>
          </div>
          <div tw="text-lg font-medium text-center">
            {!budgetSummary
              ? "···"
              : formatPrice(budgetSummary.budgetTotalQuarterly)}
            <span tw="text-gray-400"> / quarter</span>
          </div>
        </div>
      </div>
    </div>
  )
}

function AddFundsButton() {
  return (
    <div tw="absolute -bottom-4 w-full h-8 flex items-center justify-center">
      <Link
        to={generateRealmPath("plus", "/organization/budgeting/deposit")}
        tw="transition-all bg-white font-semibold text-primary-new-500 rounded-full border border-primary-new-250 flex items-center justify-center px-4 py-1 gap-1 hover:(bg-primary-new-500 text-white border-primary-new-500) active:(bg-primary-new-600 border-primary-new-600 scale-95)"
      >
        <PlusIcon tw="stroke-current" />
        <span>Add funds</span>
      </Link>
    </div>
  )
}
