import React, { Dispatch, SetStateAction, useState } from "react"
import tw, { styled } from "twin.macro"

import Chevron from "../../assets/icons/chevron.svg"
import { ReactComponent as GlobeIcon } from "../../assets/icons/globe.svg"
import staticAssetUrl from "../../common/staticAssetUrl"
import CountryList from "../giftOptionFilters/internationalShipping/CountryList"
import {
  Countries,
  CountriesLongName,
} from "../giftOptionFilters/internationalShipping/InternationalShipping"

interface ICountryDropdown {
  setSelectedShippingCountry: Dispatch<SetStateAction<Countries>>
  selectedShippingCountry: Countries
}
const CountryDropdown = ({
  setSelectedShippingCountry,
  selectedShippingCountry,
}: ICountryDropdown) => {
  const [isOpen, setIsOpen] = useState(false)

  const countryOnClick = (country: Countries) => {
    setSelectedShippingCountry(country)
    setIsOpen(false)
  }

  return (
    <Container>
      <Label tw="p-3 mr-2 text-sm items-center flex">
        <GlobeIcon
          style={{ margin: "0 10px -5px 5px" }}
          tw="stroke-current w-4 h-4 mr-2"
        />
        Preview options for
      </Label>

      <DropdownContainer>
        <DropdownButton onClick={() => setIsOpen(!isOpen)}>
          <img
            src={staticAssetUrl(
              `static/flags/l/${selectedShippingCountry}.svg`,
            )}
            tw="mr-2 rounded-sm max-w-[16px] max-h-[11px]"
            alt="flag"
          />
          {CountriesLongName[selectedShippingCountry]}

          <img
            src={Chevron}
            tw="text-gray-400 ml-3 text-sm h-2 w-3"
            alt="chevron-down"
          />
        </DropdownButton>

        {isOpen && (
          <OptionsContainer>
            <CountryList
              insideDropdown={true}
              onlyInternationalCountries={false}
              selectedShippingCountry={selectedShippingCountry}
              onClick={countryOnClick}
            />
          </OptionsContainer>
        )}
      </DropdownContainer>
    </Container>
  )
}

const Container = styled.div`
  ${tw`absolute -top-12 flex p-1 cursor-pointer right-0 h-12`}

  background: linear-gradient(90deg, #F3F2FA 0%, #E5E0FF 100%);
  border-radius: 100px;
`

const Label = styled.span`
  color: #723cfb;
`

const DropdownContainer = styled.div`
  ${tw`relative`}
  max-height: calc(100vh - 100rem);
`

const DropdownButton = styled.button`
  ${tw`transition-colors flex p-2 cursor-pointer text-sm bg-white items-center py-[10px] pr-3 pl-4`}
  border-radius: 100px;
`

const OptionsContainer = styled.div`
  ${tw`absolute p-4 bg-white rounded-lg overflow-y-auto max-h-[60vh]`}
  box-shadow: 0px 12px 32px rgba(0, 0, 0, 0.25), 0px 1px 4px rgba(0, 0, 0, 0.25);
  min-width: 244px;
  right: -4px;
  top: 60px;
  z-index: 1;
`

export default CountryDropdown
