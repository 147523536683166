import tw, { styled } from "twin.macro"

const DropShadow = styled.img`
  filter: drop-shadow(0px 42px 54px #060d250f);
`
export const MobileImage = tw(DropShadow)`lg:hidden`
export const DesktopImage = tw(DropShadow)`
  w-full h-full object-cover object-left
  hidden lg:block
`

export default DropShadow
