import React from "react"
import tw, { styled } from "twin.macro"

import Expire from "./options/Expire"
import GiftName from "./options/GiftName"
import InternationalShipping from "./options/InternationalShipping/InternationalShipping"
import MeetingCalendar from "./options/MeetingCalendar"
import NotificationOptions from "./options/NotificationOptions"
import Schedule from "./options/Schedule"
import Swap from "./options/Swap"
import { OptionsContext } from "./OptionsContext"
import { BatchRecipient } from "../../common/GlobalState"
import { useCurrentGift } from "../../common/hooks/currentGift"

import { BatchSendMethod } from "@/types/graphql-types"
import {
  Send_GetUserQuery,
  Send_PriceEstimateMutation,
} from "@/types/graphql-types"

interface Props {
  recipients: BatchRecipient[]
  priceEstimate: Send_PriceEstimateMutation["priceEstimate"] | null
  calendarIntegration?:
    | NonNullable<Send_GetUserQuery["me"]>["calendarIntegration"]
    | null
}

export default function Options({
  recipients,
  priceEstimate,
  calendarIntegration,
}: Props) {
  const [currentGift] = useCurrentGift()
  const isDirectSend = currentGift.sendMethod === BatchSendMethod.direct_send

  return (
    <OptionsContext.Provider
      value={{
        currentGift,
        recipients,
        priceEstimate,
        isDirectSend,
        calendarIntegration,
      }}
    >
      <OptionsContainer tw="rounded-lg border border-gray-200 mt-16">
        <Schedule />
        <Expire />
        <Swap />
        <InternationalShipping />
        <NotificationOptions />
        <MeetingCalendar />
        <GiftName />
      </OptionsContainer>
    </OptionsContext.Provider>
  )
}

const OptionsContainer = styled.div`
  > div:last-of-type {
    ${tw`border-none`};
  }
`
