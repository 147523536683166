import tw from "twin.macro"

import { PaymentMethod } from "@/common/PaymentMethodsManager"
import { brandLast4 } from "@/common/payments/utils"
import RadioBox from "@/common/RadioBox"
import { PaymentMethodDependencies } from "@/types/graphql-types"

interface Props {
  checked: boolean
  dependencies?: PaymentMethodDependencies
  humanizedDepenencies: string[]
  paymentMethod: PaymentMethod
  onClick: () => void
}

const CancelRadioBox = ({
  checked,
  dependencies,
  humanizedDepenencies,
  paymentMethod,
  onClick,
}: Props) => {
  const labelTextColor = checked ? tw`text-primary-new-550` : tw`text-gray-500`
  const batchBillingDependencyCount =
    dependencies?.pendingBatchBillingItems?.length ?? 0
  const batchBillingDependencies = batchBillingDependencyCount > 0
  const onlyBatchBillingDependencies =
    batchBillingDependencies && humanizedDepenencies.length === 1
  const paymentMethodBrandLast4 = brandLast4(paymentMethod)

  let label
  let subtext
  if (onlyBatchBillingDependencies) {
    label =
      "Process payment for gifts awaiting payment, and delete payment method"
    subtext = `You will be billed one last time for the ${batchBillingDependencyCount} gifts awaiting payment during the regular billing cycle. In addition, delete ${paymentMethodBrandLast4}`
  } else if (batchBillingDependencies) {
    label =
      "Process payment for gifts awaiting payment, and cancel everything else"
    subtext = `You will be billed one last time for the ${batchBillingDependencyCount} gifts awaiting payment during the regular billing cycle. In addition, cancel ${humanizedDepenencies.join(
      ", ",
    )}, and delete ${paymentMethodBrandLast4}`
  } else {
    label = "Cancel these items"
    subtext = `Cancel ${humanizedDepenencies.join(
      ", ",
    )}, and delete ${paymentMethodBrandLast4}`
  }

  return (
    <RadioBox checked={checked} onClick={onClick}>
      <div tw="flex flex-col gap-2">
        <div css={[tw`font-semibold leading-5`, labelTextColor]}>{label}</div>
        <div tw="text-gray-600 text-sm leading-5">{subtext}</div>
      </div>
    </RadioBox>
  )
}

export default CancelRadioBox
