import classNames from "classnames"
import tw, { styled } from "twin.macro"

import searchClearIcon from "../common/images/search-clear.svg"
import { ReactComponent as SearchIcon } from "../common/images/search.svg"

const SearchField: React.FC<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >
> = ({ className, ...inputProps }) => {
  const fieldClasses = classNames(className, {
    active: isSearchActive(inputProps.value?.toString() || ""),
  })

  return (
    <StyledField className={fieldClasses}>
      <SearchIcon />
      <input
        placeholder="Search"
        {...inputProps}
        type="search"
        tw="appearance-none"
        style={{ backgroundColor: "transparent" }}
      />
    </StyledField>
  )
}

const StyledField = styled.div`
  ${tw`bg-primary-050 text-primary-300 focus-within:text-primary-500 py-2 px-4 rounded-lg transition-all flex gap-4 items-center border border-transparent w-auto lg:w-24 xl:w-auto`}
  ${tw`focus-within:bg-white focus-within:border-primary-200`};

  > input {
    ${tw`outline-none placeholder-primary-300 bg-transparent flex-1 min-w-0`}

    &::-webkit-search-cancel-button {
      ${tw`hidden`}
    }
  }

  &.active > input::-webkit-search-cancel-button {
    ${tw`relative block right-0 cursor-pointer h-8 w-8 appearance-none`}
    background: url(${searchClearIcon}) right no-repeat;
  }

  > svg {
    ${tw`stroke-current`}
  }
`

export const isSearchActive = (searchTerm: string) => searchTerm.length > 0

export default SearchField
