import React, { useState } from "react"
import tw, { styled } from "twin.macro"

import { CopySmall } from "../../assets/icons"
import { largerTextCss } from "../UI"

import { GiftFragment } from "@/types/graphql-types"

type Props = {
  gift: GiftFragment
  show: boolean
  header: string
} & React.ComponentPropsWithoutRef<"div">

// Container containing link to share for self-send gifts
// Visible on post-send and my gifts
const ShareLinkContainer: React.FC<Props> = ({
  gift,
  show,
  header,
  ...restProps
}) => {
  const [copied, setCopied] = useState(false)

  const copy = async () => {
    if (gift?.sharedLink) {
      await navigator.clipboard.writeText(gift?.sharedLink)
      setCopied(true)
    }
  }

  return gift.sharedLink && show ? (
    <LinkContainer {...restProps}>
      <div tw={"text-gray-600 m-6"} css={largerTextCss}>
        {header}
      </div>
      <div tw={"h-px self-stretch"} css={"background-color:#ECEEF1;"} />
      <div tw={"flex flex-row items-center self-stretch sm:self-auto w-full"}>
        <LinkInput
          readOnly
          value={gift.sharedLink}
          onClick={(e) => e.currentTarget.select()}
          className="data-hj-suppress ph-no-capture fs-exclude"
        />
        {navigator.clipboard ? (
          <button tw={"flex flex-row items-center mr-4 sm:mr-8"} onClick={copy}>
            <span tw={"text-primary-400"}>{copied ? "Copied!" : "Copy"}</span>
            <CopySmall tw={"ml-2.5"} />
          </button>
        ) : null}
      </div>
    </LinkContainer>
  ) : null
}

const LinkContainer = styled.div`
  ${tw`flex flex-col items-center mt-12 border rounded-xl bg-white w-full`};
  border-color: #eceef1;

  box-shadow:
    0px 1px 4px rgba(0, 0, 0, 0.04),
    0px 6px 20px rgba(0, 0, 0, 0.04);
`

const LinkInput = styled.input`
  ${tw`max-w-none focus:outline-none w-full text-gray-600 my-5 ml-4 mr-8 sm:mx-8 flex-1 sm:flex-initial truncate`};
`

export default ShareLinkContainer
