import React from "react"
import tw from "twin.macro"

import DesktopImg from "./images/HowGoodyIsDifferentDesktop.png"
import MobileImg from "./images/HowGoodyIsDifferentMobile.png"
import { useWindowSize } from "../../../common/hooks/useWindowSize"
import PurpleGradientLink from "../../../common/PurpleGradientLink"
import Accordion from "../../components/Accordion"
import { DesktopImage, MobileImage } from "../components/DropShaddowImage"
import {
  FlexCenterItems,
  GridCenterContainer,
} from "../components/PositionHelper"
import TitleContainer from "../components/TitleContainer"

interface Props {
  linkTo: string
}

const HowGoodyIsDifferent: React.FC<Props> = ({ linkTo }) => {
  const accordionOptions = [
    {
      title: "Gift one customer, or hundreds.",
      content: "Gift your clients at scale without repetitive manual work.",
    },
    {
      title: "No shipping info needed.",
      content:
        "Customers provide their mailing info when they accept your gift.",
    },
    {
      title: "Clients can swap gifts.",
      content:
        "Let your clients swap for something they want – a win-win for everyone.",
    },
  ]
  const windowSize = useWindowSize()

  return (
    <div tw="px-5">
      <FlexCenterItems tw="pb-3 items-start sm:items-center">
        <TitleContainer
          heading="Modern client appreciation"
          title="How Goody is different"
          align={windowSize.lessThen("sm") ? "left" : "center"}
        />
      </FlexCenterItems>
      <GridCenterContainer>
        <AccordionContainer>
          <div tw="max-w-xl lg:pr-20">
            <MobileImage tw="pt-5 pb-6 mx-auto" src={MobileImg} />
            <div tw="pb-7 md:py-6">
              <Accordion items={accordionOptions} />
            </div>
            <PurpleGradientLink to={linkTo}>How Goody Works</PurpleGradientLink>
          </div>
        </AccordionContainer>
        <DesktopImage
          tw="pt-3 pb-20 xl:pr-20 xl:object-contain h-[900px]"
          src={DesktopImg}
        />
      </GridCenterContainer>
    </div>
  )
}

const AccordionContainer = tw.div`
  md:mb-20 xl:w-[640px] xl:px-5
  xl:justify-self-end
`

export default HowGoodyIsDifferent
