import { Send_PriceEstimateMutation } from "@/types/graphql-types"

interface Props {
  error: NonNullable<
    Send_PriceEstimateMutation["priceEstimate"]["recipientErrors"]
  >[0]
}

export default function DirectSendRecipientErrorRow({ error }: Props) {
  const scrollToRow = () => {
    const errorElement = document.querySelector(`#recipient-row-${error.index}`)

    if (errorElement) {
      errorElement.scrollIntoView({ block: "center", behavior: "smooth" })
    }
  }

  return (
    <div tw="p-5 py-3 border-t border-white flex flex-row items-center justify-between gap-3">
      <div tw="flex-1">
        <div tw="font-medium">{error.recipientName}</div>
        <div tw="text-gray-450">{error.error}</div>
      </div>
      <button
        tw="bg-white transition-all hover:opacity-80 active:scale-90 py-2 px-5 rounded-lg"
        onClick={scrollToRow}
      >
        Fix
      </button>
    </div>
  )
}
