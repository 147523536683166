import { gql } from "@apollo/client"

export const PAYMENT_METHOD_DEPENDENCIES_QUERY = gql`
  query PaymentMethodDependencies($paymentMethodId: ID!) {
    paymentMethodDependencies(paymentMethodId: $paymentMethodId) {
      canDeactivate
      failedBatchBillingGroups
      pendingBatchBillingItems
      unexpiredNotYetAcceptedGifts
      activeOrPausedAutogiftRules
      unexpiredSmartLinkGiftBatches
      pendingScheduledGiftBatches
      activeSubscriptions
    }
  }
`
