import React, { Suspense } from "react"

import { Loader } from "../common/UI"

const SwagIntegrations = React.lazy(() => import("./SwagIntegrations"))

export default function SwagIntegrationsLoader() {
  return (
    <div>
      <Suspense fallback={<Loader />}>
        <SwagIntegrations />
      </Suspense>
    </div>
  )
}
