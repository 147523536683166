import tw, { styled } from "twin.macro"

import { ReactComponent as GiftsIcon } from "../../../assets/icons/gifts.svg"
import OutOfStockButton from "../../../common/OutOfStockButton"

import { Editorial_GoodyProductCommonFragment } from "@/types/graphql-types"

export interface Props {
  product: Editorial_GoodyProductCommonFragment
  beforeHandleClick?: () => void
}

const GiftButton = ({ product, beforeHandleClick }: Props) => {
  return product.status === "ACTIVE" ? (
    <Active product={product} beforeHandleClick={beforeHandleClick} />
  ) : (
    <OutOfStock />
  )
}

const Active = ({ product, beforeHandleClick }: Props) => {
  const handleClick = () => {
    if (beforeHandleClick) {
      beforeHandleClick()
    }
  }

  return (
    <Container>
      <GradientButtonWithAfterElement
        tw="flex justify-center w-full"
        onClick={(e) => {
          e.preventDefault()
          handleClick()
        }}
      >
        <div>
          <GiftsIcon tw="mr-3" />
          {activeButtonText(product)}
        </div>
      </GradientButtonWithAfterElement>
    </Container>
  )
}

const activeButtonText = (product: Editorial_GoodyProductCommonFragment) => {
  return product.isOfflineProduct ? "Get a quote" : "Gift"
}

const OutOfStock = () => {
  return (
    <Container>
      <OutOfStockButton
        disabled
        tw="flex justify-center pr-8 w-full border-gray-500"
        onClick={() => {}}
      >
        Out of stock
      </OutOfStockButton>
    </Container>
  )
}

const Container = styled.div`
  ${tw`p-3 flex m-0 flex-col lg:flex-row items-stretch lg:items-center w-full`}
`

// Gradient button that covers the entire card with a clickable area.
// See: https://inclusive-components.design/cards/
const GradientButtonWithAfterElement = styled.button`
  ${tw`text-xl text-white font-medium transition-all focus:outline-none`}

  // This inner div renders the button itself.
  // It's the same sort of format as GradientButton.
  // 
  // We need to have the inner div because we can't have multiple ::before/::after on a
  // single element.
  //
  // The ::before/::after inside this div controls the gradients for hover and
  // active, respectively.
  div {
    ${tw`bg-gradient-to-r from-gradient-updated-purple-medium to-gradient-updated-blue-medium text-xl text-white rounded-xl px-5 py-3 font-medium flex flex-row justify-center items-center active:scale-95 transition-all focus:outline-none relative w-full`}
    z-index: -2;

    &::before,
    &::after {
      ${tw`block absolute top-0 left-0 w-full rounded-xl h-full bg-gradient-to-r opacity-0 transition-opacity`}
      content: "";
      z-index: -1;
    }

    &::before {
      ${tw`from-gradient-updated-purple-dark to-gradient-updated-blue-dark`}
    }

    &::after {
      ${tw`from-gradient-updated-purple-darker to-gradient-updated-blue-darker`}
    }
  }

  // On hover, direct the inner div to show the hover gradient.
  &:hover {
    div {
      ${tw`shadow-md`}

      &::before {
        ${tw`opacity-100`}
      }
    }
  }

  &:focus.enabled,
  &:active.enabled {
    div {
      &::after {
        ${tw`opacity-100`}
      }
    }
  }

  &:active {
    // Likely because adding a transform creates a new render context and new
    // z-index stack, this scale-95 can't be put on the outer button, or else
    // clicks outside of the button in the psuedo-element's area do not
    // trigger the onClick.
    div {
      ${tw`scale-95`}
    }
  }

  // This creates a pseudo-element that allows this button to cover the entire
  // card container, so clicks on the card container go to this button.
  // Other buttons (variant, more from) break through using a higher z-index.
  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
`

export default GiftButton
