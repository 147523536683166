import React from "react"
import tw, { styled } from "twin.macro"

export interface AcceptanceStats {
  accepted: number
  opened: number
  waiting: number
}

interface Props {
  stats: AcceptanceStats
}

// How this chart works:
//
// Background: We need to handle arbitrary numbers of accepted/opened/waiting
// which means that we need to handle cases where the progress bar segments
// (which always show the exact ratio) are not aligned with the text underneath
// (because the text underneath could be larger than the width of the segments,
// especially when you have a small segment of like 2% of the width).
//
// How we work around this is to have three states:
// 1. Accepted is left-aligned to the Accepted segment
// 2. Opened is left-aligned to the Opened segment
// 3. Waiting is right-aligned to the Waiting segment
//
// Now, for the progress bar, we just render it with the exact width ratios.
//
// But for the annotation text, our objective is to have the alignments to the
// progress bar segments to be as close as possible, but allow them to expand
// out if possible.
//
// The foundation of this is that we can split two segments by making one have
// a FIXED with and one with a FLEX width. A FIXED width is implemented using
// a min-width and the FLEX width just has flex: 1 filling the remaining space.
// Here's an example:
//
// |90%                                    |10% |
// |---------------------------------------|----|
// |Small Text                             |Overflow Text|
//
// Here, we have the 90% segment corresponding to Small Text, and a 10% segment
// corresponding to Overflow Text. Overflow Text is larger than the 10% that is
// allotted to it by the width of the segment. We want to shrink down the text
// containers so that Overflow Text shows fully without overflowing, by taking
// space away from the Small Text container.
//
// We can set the Large section to a flex width, and Overflow to min-width 10%.
// If Overflow is larger than the min-width, then it will expand, and take up
// space that Large used to have.
//
// |90%                                    |10% |
// |---------------------------------------|----|
// |Small Text                    |Overflow Text|
// |==============================|=============|
// | FLEX                         | FIXED       |
//
// Here, we set the Over
//
// Now let's go back to the real thing with an example:
//
// |----------------------|----------|---------|
//  Accepted                Opened      Waiting
//
// This one is fine. We have enough space for everything.
//
// |--------------------------------|------|---|
//  Accepted                     Opened Waiting
//
// In this case, what we did was have a set min-width for the second half, and
// then we allowed the first half (Accepted) to flex the remaining width:
//
// |--------------------------------|------|---|
//  Accepted                     Opened Waiting
// |============================|==============|
//  Flex width                   Fixed min-width
//
// The ===== segments show the halves.
//
// Similarly:
//
// |---|-----------------------------------|---|
//  Accepted  Opened                    Waiting
// |========|==================================|
//  Fixed min-width      Flex width
//
// Now the first half is smaller, so we set it to a fixed min-width.
// The min-width will expand if the content inside is larger (when whitespace
// is set to nowrap).
// Then the second half can have a flex width. Of course, now we have Opened
// a bit offset from where its segment starts, but that's unavoidable.
//
// 1. Split the bar into two halves:
//    - Accepted half
//    - Opened + Waiting half
//
// 2. Render the accepted half
//    - If the width of the accepted half is the SMALLER of the two halves,
//      then set it to a fixed min-width.
//    - If the width of the accepted half is the LARGER of the two halves,
//      then allow it to shrink by setting flex: 1 instead of a min-width.
//
// 3. Render the Opened + Waiting half
//    - Repeat the above approach for the opened and waiting halves.
const AcceptanceChart: React.FC<Props> = ({ stats }) => {
  const { accepted, opened, waiting } = stats

  // Uncomment for testing (and comment the above)
  // const accepted = 5;
  // const opened = 50;
  // const waiting = 0;

  const total = accepted + opened + waiting
  const firstHalfIsLargest = accepted > opened + waiting

  return (
    <>
      <div tw="h-2 bg-gray-200 rounded-lg relative">
        <div
          css={[
            tw`rounded-lg h-2 overflow-hidden absolute top-0 left-0`,
            {
              width: `${((accepted + opened) * 100) / total}%`,
            },
          ]}
        >
          <BarAnimationInner
            tw="bg-yellow-300"
            css={{ animationDelay: "0.3s" }}
          />
        </div>
        <div
          css={[
            tw`rounded-lg h-2 overflow-hidden z-10 absolute top-0 left-0`,
            {
              width: `${(accepted * 100) / total}%`,
            },
          ]}
        >
          <BarAnimationInner
            tw="bg-green-400"
            css={{ animationDelay: "0.6s" }}
          />
        </div>
      </div>
      <div tw="pt-3 flex flex-row items-start">
        <div
          className="animate__animated animate__fadeInTopAbsolute"
          css={[
            tw`whitespace-nowrap pr-3 text-green-500`,
            {
              animationDelay: "0.5s",
            },
            // Set min-width if this is the smaller segment.
            !firstHalfIsLargest && {
              minWidth: `${(accepted * 100) / total}%`,
            },
            // Set flex if this is the larger segment.
            firstHalfIsLargest && tw`flex-1`,
          ]}
        >
          <SingleStat title="Accepted" value={accepted} total={total} />
        </div>
        <div
          className="animate__animated animate__fadeInTopAbsolute"
          css={[
            tw`flex flex-row items-center`,
            firstHalfIsLargest && {
              minWidth: `${((opened + waiting) * 100) / total}%`,
            },
            !firstHalfIsLargest && tw`flex-1`,
          ]}
        >
          <div
            className="animate__animated animate__fadeInTopAbsolute"
            css={[
              tw`whitespace-nowrap pr-3 text-yellow-500`,
              {
                animationDelay: "0.65s",
              },
              opened < waiting && {
                minWidth: `${(opened * 100) / (opened + waiting)}%`,
              },
              opened >= waiting && tw`flex-1`,
            ]}
          >
            <SingleStat title="Opened" value={opened} total={total} />
          </div>
          <div
            className="animate__animated animate__fadeInTopAbsolute"
            css={[
              tw`whitespace-nowrap text-gray-400 text-right`,
              {
                animationDelay: "0.8s",
              },
              opened >= waiting && {
                minWidth: `${(waiting * 100) / total}%`,
              },
              opened < waiting && tw`flex-1`,
            ]}
          >
            <SingleStat title="Waiting" value={waiting} total={total} />
          </div>
        </div>
      </div>
    </>
  )
}

interface SingleStatProps {
  title: string
  value: number
  total: number
}

const SingleStat: React.FC<SingleStatProps> = ({ title, value, total }) => {
  return (
    <div>
      <div tw="font-medium text-sm md:text-lg">{title}</div>
      <div tw="font-medium text-lg md:text-3xl pt-1 md:pt-2">{value}</div>
      <div tw="md:text-2xl opacity-50">
        {total !== 0 ? ((value * 100) / total).toFixed(0) : 0}%
      </div>
    </div>
  )
}

// Animates one segment of the bar. This is contained within an absolutely
// positioned segment that also has overflow-hidden for the rounded corners.
const BarAnimationInner = styled.div.attrs({
  className: "animate__animated animate__scaleWidth",
})`
  ${tw`absolute top-0 left-0 right-0 bottom-0`};
  transform-origin: 0 0;
`

export default AcceptanceChart
