import { ReactComponent as Avatar } from "../../common/images/avatar.svg"

import { BalanceTransactionFragment } from "@/types/graphql-types"

interface Props {
  item: BalanceTransactionFragment
  memberName?: string | null
}

export default function TransactionCreatedBy({ item, memberName }: Props) {
  if (!item.createdByUser || !memberName) {
    return null
  }

  // Show if the user who created this transaction isn't the one whose transaction log we
  // are viewing (i.e. a different user created this transaction against this person's
  // transaction log)
  if (item.createdByUser !== memberName) {
    return (
      <div tw="text-gray-450 flex flex-row items-center gap-1.5">
        <Avatar />
        <div>{item.createdByUser}</div>
      </div>
    )
  }

  return null
}
