import { gql, useMutation } from "@apollo/client"
import * as Sentry from "@sentry/react"
import { useEffect, useState } from "react"
import Modal from "react-modal"

import SampleGiftImage from "./images/SampleGift.png"
import { modalStyle } from "../../common/modal"
import { HubSpot } from "../../common/Script"

interface Props {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

const SeeSampleGiftModal = ({ isOpen, setIsOpen }: Props) => {
  const [createSampleGift] = useMutation(CREATE_SAMPLE_GIFT)
  const [hubSpotLoaded, setHubSpotLoaded] = useState(false)

  useEffect(() => {
    if (hubSpotLoaded && isOpen) {
      // @ts-ignore
      window.hbspt.forms.create({
        region: "na1",
        portalId: "9308919",
        formId: "4948f8e8-9624-4134-a674-37412c67cc1a",
        target: "#sample-gift-form-container",
        onFormSubmitted: async () => {
          setIsOpen(false)
          const res = await createSampleGift()
          const error = res.data?.sampleGiftCreate?.error
          const recipientAccessLink =
            res.data?.sampleGiftCreate?.sampleGift?.recipientAccessLink

          if (recipientAccessLink) {
            window.location.href = recipientAccessLink
          } else {
            if (error) {
              Sentry.captureException(new Error(error))
              alert(error)
            } else {
              Sentry.captureException(new Error("Sample gift: Unknown error"))
              alert(
                "An unknown error occurred. Please contact us at support@ongoody.com",
              )
            }
          }
        },
      })
    }
  }, [isOpen, hubSpotLoaded])

  return (
    <>
      <HubSpot onLoad={() => setHubSpotLoaded(true)} />
      <Modal
        isOpen={isOpen}
        closeTimeoutMS={500}
        onAfterClose={() => {}}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => {
          setIsOpen(false)
        }}
        style={modalStyle}
      >
        <div tw="flex bg-white rounded-lg">
          <div tw="p-8 md:p-12 flex flex-3 flex-col">
            <h3 tw="text-primary-500 text-2xl md:text-3xl mb-8">
              Experience it for yourself
            </h3>
            <p tw="mb-6 text-lg">See what it's like to receive a Goody gift.</p>
            <p tw="mb-2">Enter your work email to unwrap a gift:</p>

            <div
              id="sample-gift-form-container"
              className="ph-no-capture fs-exclude data-hj-suppress"
            />
          </div>
          <img
            tw="hidden md:flex flex-1 max-w-xs h-full rounded-r-lg"
            src={SampleGiftImage}
          />
        </div>
      </Modal>
    </>
  )
}

const CREATE_SAMPLE_GIFT = gql`
  mutation SampleGiftCreate {
    sampleGiftCreate {
      ok
      error
      sampleGift {
        id
        recipientAccessLink
      }
    }
  }
`

export default SeeSampleGiftModal
