import { gql, useQuery } from "@apollo/client"
import axios from "axios"
import React from "react"
import {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
  useGoogleLogin,
} from "react-google-login"
import Modal from "react-modal"
import tw, { styled } from "twin.macro"

import {
  Check,
  Exclamation,
  ModalContainer,
} from "./AuthorizeGmailModal.styled"
import { getCsrfToken } from "./csrf"
import { modalStyle } from "./modal"
import { ReactComponent as GmailMdIcon } from "../assets/icons/gmail-md.svg"
import { ReactComponent as GmailSmIcon } from "../assets/icons/gmail-sm.svg"
import { getEnvVars } from "../common/environment"
import { useGlobalState } from "../common/GlobalState"
import { successToast } from "../common/toast"

const { apiUrl, googleClientId } = getEnvVars()

interface IAuthorizeGmailModal {
  onRequestClose?: () => void
  onSuccess?: () => void
}

const AuthorizeGmailModal: React.FC<IAuthorizeGmailModal> = ({
  onRequestClose,
  onSuccess,
}) => {
  const [currentUser] = useGlobalState("user")

  const { googleIdentityRefetch, setIsOpen, isOpen } = useAuthorizeGmailModal()

  const googleCallbackOnFailure = ({ error }: { error: any }) => {
    console.log(error)
    // Deal with popup_closed_by_user and access_denied
  }

  const googleCallbackOnSuccess = ({
    code,
  }: GoogleLoginResponse | GoogleLoginResponseOffline) => {
    const params = {
      code,
      state: GmailAPIAuthParams().state,
      postmessage: true,
    }

    axios
      .get(`${apiUrl}/users/auth/google_oauth2/callback`, {
        params: params,
        withCredentials: true,
      })
      .then((e) => {
        googleIdentityRefetch().then((res) => {
          const wasAuthorized = res?.data?.me?.identity?.canSendEmailOnBehalf

          if (wasAuthorized) {
            setIsOpen(false)
            successToast("Send via Gmail connected!")
            onSuccess && onSuccess()
          } else {
            alert(
              'To allow Goody to send gifts via Gmail, you need to allow the Gmail send permission. Click the "Enable Send via Gmail" button to retry.',
            )
          }
        })
      })
  }

  const { signIn } = useGoogleLogin({
    clientId: googleClientId,
    onSuccess: googleCallbackOnSuccess,
    onFailure: googleCallbackOnFailure,
    accessType: "offline",
    responseType: "code",
    cookiePolicy: "single_host_origin",
    loginHint: currentUser?.email,
    prompt: "consent",
    scope: "https://www.googleapis.com/auth/gmail.send",
  })

  const populateState = () => {
    const csrfToken = getCsrfToken()

    axios
      .post(
        `${apiUrl}/graphql_web/oauth_state`,
        { authenticity_token: csrfToken },
        { withCredentials: true },
      )
      .then((res) => {
        GmailAPIAuthParams().state = res.data.state
      })
  }

  return (
    <Modal
      isOpen={isOpen}
      closeTimeoutMS={500}
      onRequestClose={() => {
        setIsOpen(false)
        onRequestClose && onRequestClose()
      }}
      onAfterClose={() => {}}
      shouldCloseOnOverlayClick={true}
      style={modalStyle}
    >
      <ModalContainer className="modal-content modal-content-wider">
        <div tw="grid grid-cols-7">
          <div tw="hidden md:block col-span-3 p-11">
            <h3 tw="font-medium">
              <GmailMdIcon style={{ marginTop: "-4px" }} /> Send via Gmail
            </h3>
            <p>
              Goody can send gifts from your own Google work or personal email.
              This can help with:
            </p>

            <ul>
              {/*<li>*/}
              {/*  <span tw="font-medium">Better deliverability</span>, e.g. to*/}
              {/*  networks with a corporate firewall*/}
              {/*</li>*/}
              <li>
                <span tw="font-medium">Higher acceptance</span> due to a more
                recognizable sender name
              </li>
              <li>
                <span tw="font-medium">Email replies</span> go directly to you
              </li>
            </ul>
          </div>
          <div tw="col-span-7 md:col-span-4 border-l p-6 md:p-11">
            <h3> Authorize Gmail</h3>
            <table style={{ marginTop: 25 }}>
              <tbody>
                <tr style={{ verticalAlign: "baseline" }}>
                  <td>
                    {" "}
                    <Check />{" "}
                  </td>
                  <td>
                    Goody will be able to send gifts from your email using a
                    secure connection to Google.
                  </td>
                </tr>

                <tr style={{ verticalAlign: "baseline" }}>
                  <td>
                    {" "}
                    <Check />{" "}
                  </td>
                  <td>You can disconnect your account at any time.</td>
                </tr>

                <tr style={{ verticalAlign: "baseline" }}>
                  <td>
                    {" "}
                    <Exclamation />{" "}
                  </td>
                  <td>
                    Goody can only send email, and will not be able to read your
                    email. The permission you grant on the next screen is
                    limited to sending email only.
                  </td>
                </tr>
              </tbody>
            </table>

            <StyledButton
              onClick={() => {
                populateState()
                signIn()
              }}
              style={{
                padding: "9px 16px",
                width: "100%",
                border: "1px solid #EFE9F6",
                boxSizing: "border-box",
                boxShadow: "0px 2px 4px rgba(228, 216, 244, 0.3)",
                borderRadius: 8,
                marginTop: 16,
                fontSize: 18,
              }}
            >
              <GmailSmIcon /> Enable Send via Gmail
            </StyledButton>
          </div>
        </div>
      </ModalContainer>
    </Modal>
  )
}

// Queries
const GET_IDENTITY = gql`
  query Identity($provider: String!) {
    me {
      identity(provider: $provider) {
        canSendEmailOnBehalf
      }
    }
  }
`

// Sometimes the object's key for the authParameters changes inside the Gmail
// object.
//
// This function ensures we'll always get the correct key to reach authParameters
// inside of the Gmail's auth instance.
const GmailAPIAuthParams = (): any => {
  const authInstance = (window as any).gapi.auth2.getAuthInstance()

  for (let key1 in authInstance) {
    for (let key2 in authInstance[key1]) {
      for (let key3 in authInstance[key1][key2]) {
        if (key3 === "authParameters") {
          return authInstance[key1][key2][key3]
        }
      }
    }
  }
}

export default AuthorizeGmailModal

// Custom Hook
export const useAuthorizeGmailModal = () => {
  const [isOpen, setIsOpen] = useGlobalState("isAuthorizeGmailModalOpen")

  const { data: googleIdentityData, refetch: googleIdentityRefetch } = useQuery(
    GET_IDENTITY,
    { variables: { provider: "google" } },
  )

  const canSendEmailOnBehalf =
    googleIdentityData?.me?.identity?.canSendEmailOnBehalf

  return {
    canSendEmailOnBehalf,
    googleIdentityRefetch,
    isOpen,
    setIsOpen,
  }
}

const StyledButton = styled.button`
  ${tw`items-center px-4 py-2 text-gray-800 bg-white border rounded-lg gap-3 border-primary-100 hover:border-primary-200 focus-visible:border-primary-300 focus:outline-none active:bg-primary-000 transition-colors active:transition-border disabled:opacity-50 disabled:cursor-default disabled:hover:border-primary-100 transition-opacity`}
  box-shadow: 0 2px 4px rgba(228, 216, 244, 0.3);

  &.primary {
    ${tw`text-white border-primary-500 bg-primary-500 hover:bg-primary-550 hover:border-primary-550 focus-visible:border-primary-550 active:bg-primary-600 disabled:hover:border-primary-500`}
  }

  &:disabled {
    ${tw`shadow-none`}
  }
`
