import React from "react"
import { Link } from "react-router-dom"

import BalanceMoreText from "./BalanceMoreText"
import { formatPrice } from "../../../common/format"
import { useGlobalState } from "../../../common/GlobalState"
import { generateRealmPath } from "../../../common/realm"

export default function AccountDropdownBudget() {
  const [user] = useGlobalState("user")

  if (!user) {
    return null
  }

  return (
    <Link
      to={generateRealmPath("plus", "/balance")}
      tw="p-5 py-4 pr-3 flex flex-row items-center justify-between hover:bg-gray-050 active:bg-gray-100 transition-colors border-b border-gray-150"
    >
      <div>
        <div tw="text-gray-400 font-medium text-sm uppercase">Balance</div>
        <div tw="text-gray-700 font-medium text-lg">
          {formatPrice(user.balance!, true)}
        </div>
      </div>
      <BalanceMoreText />
    </Link>
  )
}
