import React from "react"
import tw, { styled } from "twin.macro"

import { ReactComponent as Plus } from "../assets/icons/plus.svg"

interface PersonalizationTagsProps {
  insertAtCursorOrEnd: (text: string) => void
  messageTextAreaHasFocus: boolean
}

const PersonalizationTags: React.FC<PersonalizationTagsProps> = ({
  insertAtCursorOrEnd,
  messageTextAreaHasFocus,
}) => {
  return (
    <PersonalizationSpace>
      <div tw="flex flex-initial gap-2">
        <PersonalizationTag
          onMouseDown={(e) => e.preventDefault()} // Prevent losing focus on message text area when inserting a tag
          onClick={() => insertAtCursorOrEnd("{{firstname}}")}
          disabled={!messageTextAreaHasFocus}
        >
          <Plus />
          <TagText>First name</TagText>
        </PersonalizationTag>
        <PersonalizationTag
          onMouseDown={(e) => e.preventDefault()} // Prevent losing focus on message text area when inserting a tag
          onClick={() => insertAtCursorOrEnd("{{fullname}}")}
          disabled={!messageTextAreaHasFocus}
        >
          <Plus />
          <TagText>Full name</TagText>
        </PersonalizationTag>
      </div>
    </PersonalizationSpace>
  )
}

export const searchForTags = (message: string) =>
  message.search(/\{\{ *(fullname|firstname) *\}\}/gi)

export const renderTags = (
  message: string,
  firstName?: string | null,
  lastName?: string | null,
) =>
  message
    .replace(
      /\{\{ *(firstname) *\}\}/gi,
      firstName?.trim() || "Recipient First Name",
    )
    .replace(
      /\{\{ *(fullname) *\}\}/gi,
      firstName || lastName
        ? `${firstName?.trim()} ${lastName?.trim()}`.trim()
        : "Recipient Full Name",
    )

const PersonalizationSpace = styled.div`
  ${tw`pt-3 flex justify-between flex-wrap gap-3 flex-1`}
`

const TagText = styled.span`
  ${tw`flex items-center text-sm whitespace-nowrap`}
  line-height: 1.0625rem;
  letter-spacing: -0.01rem;
  height: 1.0625rem;
`

const PersonalizationTag = styled.button`
  ${tw`text-primary-400 transition-all disabled:opacity-0 disabled:pointer-events-none duration-200 active:bg-primary-000 svg:inline flex-nowrap rounded-md border border-primary-100 hover:border-primary-200 px-2 py-1 flex justify-items-center items-center align-middle`}
  box-shadow: 0px .125rem .25rem rgba(228, 216, 244, 0.3);
  height: 1.5625rem;

  svg {
    margin-right: 0.375rem;
    // For visual center
    margin-top: 1px;
  }
`

export default PersonalizationTags
