import { Link } from "react-router-dom"
import tw, { styled } from "twin.macro"

import PurpleGradientLink, {
  PurpleGradientExternalLink,
} from "../../../common/PurpleGradientLink"
import { generateRealmPath } from "../../../common/realm"
import staticAssetUrl from "../../../common/staticAssetUrl"
import WhiteBackgroundLink from "../../../common/WhiteBackgroundLink"

const assetRoot = "landing/how_it_works/branded_swag/our_services"
const BackgroundMobile = staticAssetUrl(`${assetRoot}/mobile/background.png`)
const BackgroundDesktop = staticAssetUrl(`${assetRoot}/desktop/background.png`)
const OnDemandSwagMobile = staticAssetUrl(
  `${assetRoot}/mobile/onDemandSwag.png`,
)
const OnDemandSwagDesktop = staticAssetUrl(
  `${assetRoot}/desktop/onDemandSwag.png`,
)
const BulkOrdersMobile = staticAssetUrl(`${assetRoot}/mobile/bulkOrders.png`)
const BulkOrdersDesktop = staticAssetUrl(`${assetRoot}/desktop/bulkOrders.png`)
const CustomBoxesMobile = staticAssetUrl(`${assetRoot}/mobile/customBoxes.png`)
const CustomBoxesDesktop = staticAssetUrl(
  `${assetRoot}/desktop/customBoxes.png`,
)
const DigitalSwagClosetsMobile = staticAssetUrl(
  `${assetRoot}/mobile/digitalSwagClosets.png`,
)
const DigitalSwagClosetsDesktop = staticAssetUrl(
  `${assetRoot}/desktop/digitalSwagClosets.png`,
)

const OurServices = () => (
  <Container>
    <div tw="mx-auto">
      <div tw="text-center lg:font-medium text-white text-xl mb-4">
        Our Services
      </div>
      <div tw="text-center font-bold text-white text-3xl lg:text-5xl mb-12">
        Change the way you swag
      </div>
      <div tw="flex items-center justify-center flex-wrap max-w-[98rem] mx-auto">
        <ServicesCategory
          mobileImage={OnDemandSwagMobile}
          desktopImage={OnDemandSwagDesktop}
          header="Global Swag On Demand"
          subheader="Design and order high-quality swag online, without pre-purchasing."
          firstButtonText="Get Started"
          firstButtonUrl={generateRealmPath(
            "business",
            "/business/browse/category/branded-swag",
          )}
          external={false}
        />
        <ServicesCategory
          mobileImage={BulkOrdersMobile}
          desktopImage={BulkOrdersDesktop}
          header="Bulk Orders & Warehousing"
          subheader="Access our full catalog of 100+ customizable products."
          firstButtonText="Gift Catalog"
          firstButtonUrl="https://go.ongoody.com/custom-gifts/"
          external={true}
        />
        <ServicesCategory
          mobileImage={CustomBoxesMobile}
          desktopImage={CustomBoxesDesktop}
          header="Custom Boxes"
          subheader="Build delightful, bespoke boxes that will bring your brand to life."
          firstButtonText="Gift Catalog"
          firstButtonUrl="https://go.ongoody.com/custom-gifts/"
          external={true}
        />
        <ServicesCategory
          mobileImage={DigitalSwagClosetsMobile}
          desktopImage={DigitalSwagClosetsDesktop}
          header="Digital Swag Closets"
          subheader={
            <span>
              Create a digital swag closet with your branding.{" "}
              <Link
                tw="text-primary-500 hover:text-primary-600 transition-colors"
                to={generateRealmPath("business", "/pricing")}
              >
                Available on Teams plan.
              </Link>
            </span>
          }
          firstButtonText="Learn More"
          firstButtonUrl="https://www.ongoody.com/blog/how-to-build-branded-gift-experiences-with-custom-stores"
          external={true}
        />
      </div>
    </div>
  </Container>
)

interface ServicesCategoryProps {
  mobileImage: string
  desktopImage: string
  header: string
  subheader: string | React.ReactNode
  firstButtonText: string
  firstButtonUrl: string
  external: boolean
}

const ServicesCategory = ({
  mobileImage,
  desktopImage,
  header,
  subheader,
  firstButtonText,
  firstButtonUrl,
  external,
}: ServicesCategoryProps) => (
  <div tw="rounded-3xl overflow-hidden w-[30rem] my-6 sm:mx-6 bg-[#EDEDED]">
    <img src={mobileImage} tw="w-full h-auto md:hidden" />
    <img src={desktopImage} tw="w-full h-auto hidden md:block" />
    <div tw="bg-white p-6">
      <div tw="font-bold text-2xl mb-4">{header}</div>
      <div tw="text-gray-600 text-lg">{subheader}</div>
      <div tw="flex flex-col sm:flex-row items-center justify-center mt-6 gap-4 w-full">
        {external ? (
          <PurpleGradientExternalLink
            tw="lg:text-lg px-10 w-full"
            href={firstButtonUrl}
            target="_blank"
          >
            {firstButtonText}
          </PurpleGradientExternalLink>
        ) : (
          <PurpleGradientLink tw="lg:text-lg px-10 w-full" to={firstButtonUrl}>
            {firstButtonText}
          </PurpleGradientLink>
        )}
        <WhiteBackgroundLink
          tw="lg:text-lg px-10 w-full text-center bg-white transition-all"
          to={generateRealmPath("business", "/business/book-a-call")}
        >
          Book a Call
        </WhiteBackgroundLink>
      </div>
    </div>
  </div>
)

const Container = styled.div`
  ${tw`relative overflow-x-hidden py-16 px-6 bg-cover`}
  background-image: url(${BackgroundMobile});

  @media only screen and (min-width: 1024px) {
    background-image: url(${BackgroundDesktop});
  }
`

export default OurServices
