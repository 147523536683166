import { gql, useMutation } from "@apollo/client"
import { Redirect } from "react-router-dom"

import BackButton from "../../common/BackButton"
import Button from "../../common/Button"
import { useGlobalState } from "../../common/GlobalState"
import usePlanPrivilege from "../../common/hooks/usePlanPrivilege"
import { generateRealmPath } from "../../common/realm"
import { successToast } from "../../common/toast"
import Container from "../../sites/App/Container"

import {
  DeletionRequestCreateMutation,
  DeletionRequestCreateMutationVariables,
} from "@/types/graphql-types"

const RequestDataDeletion = () => {
  const [user] = useGlobalState("user")

  const [createDataDeletionRequest] = useMutation<
    DeletionRequestCreateMutation,
    DeletionRequestCreateMutationVariables
  >(REQUEST_DATA_DELETION_MUTATION)

  const { privilege: planPrivilege } = usePlanPrivilege()

  if (!user) {
    return <Redirect to={generateRealmPath("business", "/signin")} />
  }

  const requestDataDeletion = async () => {
    const response = await createDataDeletionRequest()
    if (response.data?.deletionRequestCreate?.error) {
      alert(response.data?.deletionRequestCreate?.error)
    } else {
      // Auth tokens are removed when the user is deactivated so handleLogout out does not work
      successToast("Data deletion request made successfully")
      setTimeout(() => (window.location.href = "/"), 2000)
    }
  }

  return (
    <Container>
      <div tw="px-6 pt-12">
        <div tw="font-medium  text-3xl">Delete account</div>
        <div>
          <div tw="text-base leading-p-140 my-9">
            When you delete your account, the following changes will take place.
            <ul tw="list-disc ml-6 py-5">
              <li>Any gifts that have not been accepted will be canceled.</li>
            </ul>
            <div>If you have a business account:</div>
            <ul tw="list-disc ml-6 py-5">
              <li>
                Any scheduled gifts that have not been sent will be canceled.
              </li>
              <li>
                You will be removed from your Goody for Business organization,
                if any.
              </li>
              <li>
                Your Goody for Business Pro subscription will be canceled, if
                you have one.
              </li>
              <li> Any Autogift Rules created by you will be deleted.</li>
              <li>
                If you have any pending payments, they will be processed
                immediately and will need to process successfully before
                deletion can take place.
              </li>
            </ul>
            Deleting your account cannot be undone.
            {planPrivilege != "none" && (
              <div tw="font-semibold">
                Since you are a paid member we will reach out to you to
                coordinate any data transfers.
              </div>
            )}
          </div>
          <div tw="flex flex-row gap-3">
            <Button onClick={requestDataDeletion}>Delete account</Button>
            <BackButton />
          </div>
        </div>
      </div>
    </Container>
  )
}

const REQUEST_DATA_DELETION_MUTATION = gql`
  mutation DeletionRequestCreate {
    deletionRequestCreate {
      ok
      error
      id
    }
  }
`

export default RequestDataDeletion
