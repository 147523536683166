import { gql, useApolloClient } from "@apollo/client"
import { uniq } from "lodash-es"

import { useGiftCart } from "./giftData"
import { CurrentGift } from "../GlobalState"

import { ProductStatus } from "@/types/graphql-types"
import {
  Cart_ProductsStatusQuery,
  Cart_ProductsStatusQueryVariables,
} from "@/types/graphql-types"

// Checks to see if anything in the cart in is no longer available and removes it
// In the future, we would probably rather mark these as unavailable rather than just removing
// but we'll need some UI around it
export const useCartRefresh = () => {
  const client = useApolloClient()
  const { replaceCart } = useGiftCart()

  return async (currentGift?: CurrentGift) => {
    const cart = currentGift?.cart

    if (!cart || cart.length === 0) {
      return
    }

    const productIds = uniq(cart.map((product) => product.id))

    const productsStatusData = await client.query<
      Cart_ProductsStatusQuery,
      Cart_ProductsStatusQueryVariables
    >({
      query: GET_PRODUCTS_STATUS,
      variables: { productIdList: productIds },
    })

    if (productsStatusData?.data?.products) {
      const goodProductsIds = new Set(
        productsStatusData.data.products
          .filter((product) => product.status === ProductStatus.ACTIVE)
          .map((product) => product.id),
      )

      const newCart = cart.filter((product) => goodProductsIds.has(product.id))
      if (newCart.length < cart.length) {
        replaceCart(newCart)
      }
    }
  }
}

const GET_PRODUCTS_STATUS = gql`
  query Cart_ProductsStatus($productIdList: [ID!]!) {
    products(productIdList: $productIdList) {
      id
      status
    }
  }
`
