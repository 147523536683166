import { gql, useMutation, useQuery } from "@apollo/client"
import { isEqual } from "lodash-es"
import { useEffect, useState } from "react"

import { BalanceMessage } from "./BalanceMessage"
import { ORGANIZATION_BALANCE_DEPOSIT_MUTATION } from "./queries"
import SubmitButton from "./SubmitButton"
import Box from "../../../common/Box"
import { getCentsFromString } from "../../../common/currency"
import FormField from "../../../common/FormField"
import { successToast } from "../../../common/toast"
import { ROOT_DATA_QUERY } from "../../../graphql"
import { BUDGETING_INDEX_QUERY } from "../graphql"

import {
  BalanceDepositAccount,
  BalanceDepositFundingMethod,
  InvoiceRequestInput,
} from "@/types/graphql-types"
import {
  Budgeting_DepositInvoiceDataQuery,
  Budgeting_OrganizationBalanceDepositMutation,
  Budgeting_OrganizationBalanceDepositMutationVariables,
} from "@/types/graphql-types"

interface Props {
  amount: string
  accountType: BalanceDepositAccount
  onSuccess: (props: {
    amountInCents: number
    type: "credit_card" | "invoice"
  }) => void
}

export default function InvoiceForm({ amount, accountType, onSuccess }: Props) {
  const [invoiceData, setInvoiceData] =
    useState<InvoiceRequestInput>(emptyInvoiceData)

  const [loading, setLoading] = useState(false)

  const amountInCents = getCentsFromString(amount)

  const setField = (field: string, value: string) => {
    setInvoiceData({
      ...invoiceData,
      [field]: value,
    })
  }

  const [balanceDepositMutation] = useMutation<
    Budgeting_OrganizationBalanceDepositMutation,
    Budgeting_OrganizationBalanceDepositMutationVariables
  >(ORGANIZATION_BALANCE_DEPOSIT_MUTATION)

  const handleSubmit = async (e: any) => {
    e.preventDefault()

    if (!amountInCents) {
      alert("Amount not set.")
      return
    }

    setLoading(true)

    const variables: Budgeting_OrganizationBalanceDepositMutationVariables = {
      amount: amountInCents,
      invoiceRequestInput: invoiceData,
      fundingMethod: BalanceDepositFundingMethod.INVOICE,
      accountType,
    }

    const graphqlResponse = await balanceDepositMutation({
      variables,
      refetchQueries: [
        {
          query:
            accountType === BalanceDepositAccount.user
              ? ROOT_DATA_QUERY
              : BUDGETING_INDEX_QUERY,
        },
      ],
    })

    const data = graphqlResponse?.data?.organizationBalanceDeposit

    if (data?.ok) {
      successToast("An invoice was requested.")
      onSuccess({ amountInCents, type: "invoice" })
    } else {
      if (data?.error) {
        alert(data.error)
      } else {
        alert("Sorry, an unexpected error occurred.")
      }
    }

    setLoading(false)
  }

  const { data: invoicePreloadData } =
    useQuery<Budgeting_DepositInvoiceDataQuery>(
      BUDGETING_DEPOSIT_INVOICE_DATA_QUERY,
    )

  useEffect(() => {
    if (isEqual(invoiceData, emptyInvoiceData) && invoicePreloadData) {
      const preloadData = invoicePreloadData
      const address = preloadData.me?.businessBillingAddress

      setInvoiceData({
        name: [preloadData.me?.firstName, preloadData.me?.lastName]
          .filter(Boolean)
          .join(" "),
        company: preloadData.organization?.name || "",
        email: preloadData.me?.email || "",
        address1: address?.address1 || "",
        address2: address?.address2 || "",
        addressCity: address?.city || "",
        addressState: address?.state || "",
        addressPostalCode: address?.postalCode || "",
      })
    }
  }, [invoicePreloadData, isEqual, setInvoiceData])

  return (
    <>
      <Box tw="p-6 md:p-10 mt-8 mb-3">
        <div tw="font-semibold text-xl">Invoice details</div>
        <div tw="pt-3 pb-6 text-gray-700">
          We’ll send an invoice to the email address you provide within one
          business day. The amount will be added to your account balance once
          the invoice is paid.
        </div>
        <div
          tw="border border-gray-150 rounded-lg"
          className="data-hj-suppress ph-no-capture fs-exclude"
        >
          <div tw="border-b border-gray-150">
            <div tw="flex flex-row">
              <div tw="flex-1 p-3 px-4 border-r border-gray-150">
                <FormField
                  name="name"
                  label="Contact name"
                  placeholder="Name"
                  value={invoiceData.name}
                  onChange={setField}
                />
              </div>
              <div tw="flex-1 p-3 px-4">
                <FormField
                  name="company"
                  label="Company"
                  placeholder="Company name"
                  value={invoiceData.company}
                  onChange={setField}
                />
              </div>
            </div>
          </div>
          <div tw="p-3 px-4">
            <FormField
              name="email"
              label="Email for invoice"
              placeholder="email@ongoody.com"
              value={invoiceData.email}
              onChange={setField}
            />
          </div>
        </div>

        <div
          tw="border border-gray-150 rounded-lg mt-6"
          className="data-hj-suppress ph-no-capture fs-exclude"
        >
          <div tw="border-b border-gray-150 p-3 px-4">
            <FormField
              name="address1"
              label="Billing address 1"
              placeholder="185 Berry Street"
              value={invoiceData.address1}
              onChange={setField}
            />
          </div>
          <div tw="border-b border-gray-150 p-3 px-4">
            <FormField
              name="address2"
              label="Billing address 2"
              placeholder="Optional"
              value={invoiceData.address2}
              onChange={setField}
            />
          </div>
          <div tw="flex flex-row">
            <div tw="p-3 px-4 border-r border-gray-150 flex-2">
              <FormField
                name="addressCity"
                label="City"
                placeholder="San Francisco"
                value={invoiceData.addressCity}
                onChange={setField}
              />
            </div>
            <div tw="flex-1 p-3 px-4 border-r border-gray-150">
              <FormField
                name="addressState"
                label="State"
                placeholder="CA"
                value={invoiceData.addressState}
                onChange={setField}
              />
            </div>
            <div tw="flex-1 p-3 px-4">
              <FormField
                name="addressPostalCode"
                label="ZIP"
                placeholder="12345"
                value={invoiceData.addressPostalCode}
                onChange={setField}
              />
            </div>
          </div>
        </div>
      </Box>
      <BalanceMessage />
      <SubmitButton
        amountInCents={amountInCents}
        fundingMethod={BalanceDepositFundingMethod.CREDIT_CARD}
        loading={loading}
        onSubmit={handleSubmit}
      />
    </>
  )
}

export const emptyInvoiceData: InvoiceRequestInput = {
  name: "",
  company: "",
  address1: "",
  address2: "",
  addressCity: "",
  addressState: "",
  addressPostalCode: "",
  email: "",
}

const BUDGETING_DEPOSIT_INVOICE_DATA_QUERY = gql`
  query Budgeting_DepositInvoiceData {
    me {
      firstName
      lastName
      email
      businessBillingAddress {
        address1
        address2
        city
        state
        postalCode
      }
    }
    organization {
      id
      name
    }
  }
`
