import { gql } from "@apollo/client"

import { ORGANIZATION_MEMBER_FRAGMENT } from "./OrganizationMemberFragment"

export const BUDGETING_MEMBERS_QUERY = gql`
  query Budgeting_Members {
    organization {
      memberships {
        ...BudgetingOrganizationMembership
      }
    }
  }

  fragment BudgetingOrganizationMembership on OrganizationMembership {
    id
    role
    access
    member {
      ...OrganizationMember
    }
    budgetRule {
      id
      amount
      recurrence
    }
    balanceAccount {
      id
      balance
    }
  }
  ${ORGANIZATION_MEMBER_FRAGMENT}
`
