import { PurpleGradientExternalLink } from "../../../common/PurpleGradientLink"
import RainbowHeading from "../../../common/RainbowHeading"
import { generateRealmPath } from "../../../common/realm"
import staticAssetUrl from "../../../common/staticAssetUrl"
import WhiteBackgroundLink from "../../../common/WhiteBackgroundLink"

const ProductMasonryImage = staticAssetUrl(
  "landing/how_it_works/branded_swag/productMasonryImage.png",
)

const BulkOrders = () => (
  <div tw="bg-[#F7F6F7] overflow-hidden">
    <div tw="lg:max-w-[1300px] mx-auto flex flex-col lg:flex-row-reverse">
      <img
        tw="lg:mb-0 max-h-[35rem] lg:h-full w-full object-cover md:mr-[-25%]"
        src={ProductMasonryImage}
      />
      <div tw="mx-auto max-w-[800px] md:py-14 py-20 lg:pl-[50px] px-5">
        <RainbowHeading tw="font-bold mb-4 text-2xl">
          Bulk Orders &amp; Custom Boxes
        </RainbowHeading>
        <h2 tw="text-left font-bold mb-6 text-3xl lg:text-[3rem] lg:leading-[3.5rem]">
          Let's work together to build the swag of your dreams.
        </h2>
        <div tw="text-gray-600 text-xl mb-6">
          We'll source and design unforgettable custom merch or bespoke boxes
          from our full catalog of hundreds of products.
        </div>
        <div tw="text-sm text-gray-600 text-sm mb-10">
          Warehousing available. Order minimums apply.
        </div>
        <div tw="gap-4 flex lg:max-w-[30rem]">
          <PurpleGradientExternalLink
            tw="lg:text-lg w-full sm:w-[14rem] lg:w-auto flex-1"
            href="https://go.ongoody.com/custom-gifts/"
          >
            Explore the Catalog
          </PurpleGradientExternalLink>
          <WhiteBackgroundLink
            tw="lg:text-lg w-full sm:w-[14rem] lg:w-auto text-center transition-all flex-1"
            to={generateRealmPath("business", "/business/book-a-call")}
          >
            Book a Call
          </WhiteBackgroundLink>
        </div>
      </div>
    </div>
  </div>
)

export default BulkOrders
