import React from "react"

import { RecipientFieldError, RecipientTextInput } from "./recipientInputs"
import { BatchRecipient } from "../../common/GlobalState"

export function RecipientPhoneInput(props: {
  recipient: BatchRecipient
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void
  disabled: boolean
}) {
  return (
    <>
      <RecipientTextInput
        value={props.recipient.phone}
        placeholder="Mobile phone # (optional)"
        autoComplete="none"
        onChange={props.onChange}
        className={
          props.recipient.errors.phone
            ? "field-error data-hj-suppress ph-no-capture fs-exclude"
            : "data-hj-suppress ph-no-capture fs-exclude"
        }
        onKeyDown={props.onKeyDown}
        disabled={props.disabled}
      />
      {props.recipient.errors.phone && (
        <RecipientFieldError>
          {props.recipient.errors.phone}
        </RecipientFieldError>
      )}
    </>
  )
}
