import React, { ReactElement } from "react"
import tw from "twin.macro"

import { ProPlanFeature } from "../common/proPlan"
import { FloatingProTag } from "../common/ProTag"
import {
  SelectionGroup,
  SelectionIndicator,
  SelectionLabel,
} from "../common/SelectionGroup"

// Component for creating an Option inside the Options section.
interface IOption {
  title: String
  children: ReactElement
  proFeature?: ProPlanFeature
  removePadding?: boolean
}
const Option = ({ title, children, proFeature, removePadding }: IOption) => {
  return (
    <div
      tw="px-5 py-3 border-t border-primary-100"
      css={[removePadding && tw`px-0 py-0 border-none`]}
    >
      <Title title={title} proFeature={proFeature} />
      {children}
    </div>
  )
}

interface IRadio {
  value: string
  currentValue: string
  onSelect: (...args: any[]) => void
  explainer?: String | ReactElement
  children: String | ReactElement
}
const Radio = ({
  value,
  currentValue,
  onSelect,
  explainer,
  children,
}: IRadio): ReactElement => {
  const selected = currentValue === value

  const Children = () => {
    if (typeof children === "string") {
      return (
        <SelectionLabel selected={selected}>
          {children}
          {explainer && <span tw="text-gray-500">{explainer}</span>}
        </SelectionLabel>
      )
    } else {
      return <>{children}</>
    }
  }

  return (
    <SelectionGroup
      tw="w-full"
      onClick={() => {
        onSelect(value)
      }}
    >
      <SelectionIndicator selected={selected} />
      <Children />
    </SelectionGroup>
  )
}

interface ITitle {
  title: String
  proFeature?: ProPlanFeature
}
const Title = ({ title, proFeature }: ITitle) => {
  return (
    <div tw="flex flex-row items-center justify-start pb-2 gap-4">
      <div tw="font-medium text-gray-600">{title}</div>
      {proFeature && <FloatingProTag feature={proFeature} />}
    </div>
  )
}

Option.Radio = Radio

export default Option
