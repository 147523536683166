import tw, { styled } from "twin.macro"

import { SelectionGroupColorEnum } from "./types/index"

interface SelectionIndicatorProps {
  selected: boolean
  grayUnselected?: boolean
  color?: SelectionGroupColorEnum
}

export const SelectionIndicator = styled.div<SelectionIndicatorProps>`
  ${tw`h-5 w-5 border border-gray-400 inline-block relative rounded-full mr-3 transition-colors flex-grow-0 flex-shrink-0`};

  flex-basis: 1.25rem;

  ${({ selected }) =>
    selected &&
    tw`bg-gradient-to-r from-primary-600 to-primary-500 border-primary-500`}
  ${({ selected, color }) =>
    color === SelectionGroupColorEnum.primaryNew &&
    selected &&
    tw`from-primary-new-600 to-primary-new-600 border-primary-new-600`}

  ${({ selected, color }) =>
    color === SelectionGroupColorEnum.primaryNew &&
    !selected &&
    tw`border-[#CCCCCC]`}

  ${({ selected, grayUnselected }) =>
    !selected && grayUnselected && tw`border-gray-250`}

  &:after {
    ${tw`bg-white bottom-[5px] rounded inline-block left-[5px] h-2 w-2 absolute transition-opacity duration-300 ease-out`};
    content: "";
    transition: transform 0.2s ease-out;
    opacity: ${({ selected }) => (selected ? 1 : 0)};
    transform: scale(${({ selected }) => (selected ? 1 : 0)});
  }
`

interface SelectionLabelProps {
  selected: boolean
  color?: SelectionGroupColorEnum
  unselectedBlack?: boolean
}

export const SelectionLabel = styled.span<SelectionLabelProps>`
  bottom: 4.5px;

  ${({ unselectedBlack }) =>
    unselectedBlack ? tw`text-black` : tw`text-gray-600`};

  ${({ selected }) => selected && tw`text-primary-600`};
  ${({ selected, color }) =>
    color === SelectionGroupColorEnum.primaryNew &&
    selected &&
    tw`text-primary-new-600`};

  span {
    ${tw`font-normal block md:inline-block md:ml-2`}
    ${({ selected }) => (selected ? tw`text-primary-600` : tw`text-gray-500`)};

    &:before {
      ${tw`font-medium hidden md:inline mr-2`}

      content: '·';
    }
  }
`

export const SelectionGroup = styled.div<{
  disabled?: boolean
  unclickable?: boolean
}>`
  ${tw`flex cursor-pointer flex-row items-center`};

  ${({ disabled }) => (disabled ? tw`opacity-40` : null)};
  ${({ unclickable }) => (unclickable ? tw`pointer-events-none` : null)};

  &:hover {
    ${SelectionIndicator} {
      ${tw`border-primary-500 bg-primary-050`};
    }
  }

  &:active {
    ${SelectionIndicator} {
      ${tw`border-primary-500 bg-primary-200`};
    }
  }
`
