import { gql } from "@apollo/client"

export const UPDATE_GIFT_BATCH_SETTINGS_MUTATION = gql`
  mutation UpdateGiftBatchSettings(
    $giftBatchId: ID!
    $batchName: String
    $expiresAt: ISO8601Date
    $swapType: String
    $internationalShippingTier: String
    $giftMeetingSetting: String
    $settings: GiftsSettingsInput
  ) {
    giftBatchUpdateSettings(
      giftBatchId: $giftBatchId
      batchName: $batchName
      expiresAt: $expiresAt
      swapType: $swapType
      internationalShippingTier: $internationalShippingTier
      giftMeetingSetting: $giftMeetingSetting
      settings: $settings
    ) {
      ok
      error
    }
  }
`
