import styled from "@emotion/styled"
import React, { FC } from "react"
import tw from "twin.macro"

import WomanUsingLaptopDesktop from "./images/woman_using_laptop_desktop.png"
import WomanUsingLaptopMobile from "./images/woman_using_laptop_mobile.png"
import {
  CommonBtns,
  Header,
  SignUpText,
  Subheading,
} from "../common/HeroComponents"

const Hero: FC = () => {
  return (
    <>
      <Container>
        <InnerContainer>
          <div tw="flex-1">
            <Header>
              Learn how <br />
              Goody works.
            </Header>
            <Subheading tw="max-w-[510px]">
              Send amazing gifts that boost employee morale, reduce customer
              churn, and book more sales meetings. All in one place.
            </Subheading>
            <SignUpText />

            <CommonBtns />
          </div>
          <div tw="flex-1">
            <WomanUsingLaptopDesktopImage src={WomanUsingLaptopDesktop} />
          </div>
        </InnerContainer>
        <img
          src={WomanUsingLaptopMobile}
          tw="md:hidden w-full mt-8"
          alt="woman_using_laptop_mobile"
        />
      </Container>
    </>
  )
}

const Container = styled.div`
  position: relative;
  background-color: #f8f8f8;
  ${tw`px-4 sm:px-8 pt-20 pb-24 lg:pb-12 xl:pb-32`}
`

const InnerContainer = styled.div`
  max-width: 1200px;
  ${tw`flex mx-auto flex-col lg:flex-row`}
`

const WomanUsingLaptopDesktopImage = styled.img`
  ${tw`hidden md:block mx-auto lg:absolute mt-10`}
  max-width: 640px;

  @media (min-width: 1080px) {
    left: 50%;
    bottom: -5%;
    width: 50%;
    max-width: 46rem;
  }
`

export default Hero
