import { groupBy } from "lodash-es"
import { useMemo } from "react"
import { styled } from "twin.macro"

import { CountrySelectorItem } from "./CountrySelectorItem"
import GlobalCountryDropdown from "./GlobalCountryDropdown"

import PlaneIconSvg from "@/assets/icons/flags/plane.svg"
import TruckIconSvg from "@/assets/icons/flags/truck.svg"
import { ProTag } from "@/common/ProTag"
import {
  ShippingCountry,
  ShippingCountryGroupEnum,
} from "@/types/graphql-types"

const PRO_UPSELL_TEXT =
  "Pro subscription required for global shipping. An additional fee applies to global (non-domestic) shipments for shipping, tax, and duties. Goody caps this at a maximum of $50 per international recipient."

const INTL_SHIPPING_TEXT =
  "An additional fee applies to global (non-domestic) shipments for shipping, tax, and duties. Goody caps this at a maximum of $50 per international recipient."

interface Props {
  countries: ShippingCountry[]
  selectedCountry: ShippingCountry
  onSelectCountry: (country: ShippingCountry) => void
  variant: "domestic" | "global"
  withProUpsell?: boolean
}

export const CountryGroup = ({
  countries,
  selectedCountry,
  onSelectCountry,
  variant,
  withProUpsell,
}: Props) => {
  const intlCountries = useMemo(() => {
    if (variant === "global") {
      return groupBy(countries, (country) =>
        country.groups.includes(ShippingCountryGroupEnum.global)
          ? "global"
          : "nonGlobal",
      )
    } else {
      return {}
    }
  }, [countries, variant])

  return (
    <>
      <div tw="flex items-center pb-1">
        <GroupIcon
          tw="rounded-full"
          src={variant === "domestic" ? TruckIconSvg : PlaneIconSvg}
        />
        <h4 tw="pl-2 text-primary-800 uppercase text-sm font-medium font-text">
          {variant === "domestic" && "Domestic shipping"}
          {variant === "global" && "Global shipping"}
        </h4>
        {withProUpsell && <ProTag feature="international_shipping" tw="ml-3" />}
      </div>
      {variant === "domestic" &&
        countries.map((country) => (
          <CountrySelectorItem
            key={country.code}
            country={country}
            selected={selectedCountry.code === country.code}
            onClick={onSelectCountry}
          />
        ))}
      {variant === "global" && (
        <>
          {intlCountries.global?.length > 0 && (
            <GlobalCountryDropdown
              countries={intlCountries.global}
              selectedCountry={selectedCountry}
              onClick={onSelectCountry}
            />
          )}
          {intlCountries.nonGlobal?.map((country) => (
            <CountrySelectorItem
              key={country.code}
              country={country}
              selected={selectedCountry.code === country.code}
              onClick={onSelectCountry}
            />
          ))}
          <p tw="text-sm text-gray-500 px-1 pt-4">
            {withProUpsell ? PRO_UPSELL_TEXT : INTL_SHIPPING_TEXT}
          </p>
        </>
      )}
    </>
  )
}

const GroupIcon = styled.img`
  box-shadow:
    0px 4px 4px rgba(0, 0, 0, 0.02),
    0px 1px 2px rgba(0, 0, 0, 0.04);
`
