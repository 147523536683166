import React, { useRef, useState } from "react"
import tw, { css, styled } from "twin.macro"

import ContactBottomButtons from "./ContactBottomButtons"
import { FieldType, ImportStep, Issue, getFieldError } from "./shared"
import { ImportContactsFixed, ImportContactsIssue } from "../../../assets/icons"
import { isBlank } from "../../../common/format"

interface Props {
  importStep: ImportStep
  goNext: () => void
  goPrevious: () => void
  issues: Issue[]
  matchedFields: (FieldType | null)[] | null
  setIssueValue: (issue: Issue, value: string) => void
  isLoading: boolean
}

const ContactFormattingCheck: React.FC<Props> = ({
  importStep,
  goNext,
  goPrevious,
  issues,
  matchedFields,
  setIssueValue,
  isLoading,
}) => {
  return importStep === "formatting-check" ? (
    <div tw={"mt-12"}>
      <div
        tw={"flex flex-row rounded-xl border py-4 px-5"}
        css={"border-color: #DCDFE4;"}
      >
        <div tw={"h-6 w-6"} css={"margin-top: 2px;"}>
          <ImportContactsIssue />
        </div>
        <div tw={"ml-5"}>
          <div tw={"text-xl font-medium"}>
            We found some formatting issues in your data
          </div>
          <div tw={"mt-2 text-gray-500"}>
            You can correct these issues, or ignore them and we'll leave the
            contact fields blank.
          </div>
        </div>
      </div>
      <div tw={"mt-8"}>
        {issues.map((issue, index) => (
          <IssueRow
            key={index}
            issue={issue}
            setIssueValue={setIssueValue}
            field={matchedFields?.[issue.column]!}
          />
        ))}
      </div>
      <ContactBottomButtons
        nextText={"Continue"}
        isLoading={isLoading}
        onClickNext={goNext}
        onClickBack={goPrevious}
      />
    </div>
  ) : null
}

interface IssueRowProps {
  issue: Issue
  field: FieldType
  setIssueValue: (issue: Issue, value: string) => void
}

// Basically just sticks these items on top of each other
// because they are specifically positioned in the same row/column
const stackCss = css`
  grid-row: 1;
  grid-column: 1;
`

const IssueRow: React.FC<IssueRowProps> = ({ issue, field, setIssueValue }) => {
  const [value, setValue] = useState(issue.value)
  const error = getFieldError(field, value)

  // Used as a placeholder so the size of the container doesn't change
  const initialError = useRef(error).current

  const name = [issue.user.firstName, issue.user.lastName]
    .filter((name) => !isBlank(name))
    .join(" ")

  return (
    <IssueContainer>
      <div tw={"flex flex-col"}>
        {!isBlank(name) ? <div>{name}</div> : null}
        {!isBlank(issue.user.email) ? (
          <div tw={"text-gray-400 whitespace-nowrap overflow-hidden truncate"}>
            {issue.user.email}
          </div>
        ) : null}
      </div>
      <IssueInputContainer>
        <div tw="text-gray-400 text-sm whitespace-nowrap overflow-hidden truncate">
          {field}
        </div>
        <input
          tw="w-full p-0 focus:outline-none mt-1"
          defaultValue={issue.value}
          onChange={(e) => {
            const fieldValue = e.target.value
            setValue(fieldValue)
            setIssueValue(issue, fieldValue)
          }}
        />
      </IssueInputContainer>
      <div tw={"sm:mx-8 mt-6 sm:mt-0 grid"}>
        <div
          tw={"text-gray-600 transition-opacity"}
          css={[stackCss, error ? tw`` : tw`opacity-0`]}
        >
          {error}
        </div>
        <ImportContactsFixed
          tw={"transition-opacity"}
          css={[stackCss, error ? tw`opacity-0` : tw``]}
        />
        {/*just a placeholder*/}
        <div tw={"opacity-0"} css={stackCss}>
          {initialError}
        </div>
      </div>
    </IssueContainer>
  )
}

const IssueContainer = styled.div`
  ${tw`sm:grid px-6 py-6 sm:py-0 mb-2 last:mb-0 items-center rounded-lg`};
  @media (min-width: 640px) {
    height: 100px;
  }
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 2.5fr);
  background-color: #f6f7f9;
`

const IssueInputContainer = styled.div`
  ${tw`w-full px-3 bg-white border rounded-md flex flex-col justify-center mt-3 sm:mt-0`}
  border-color: #D1D5DB;
  height: 67px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
`

export default ContactFormattingCheck
