import { useQuery } from "@apollo/client"
import React from "react"
import tw, { styled } from "twin.macro"

import { ReactComponent as LinkLargeIcon } from "./images/hr-integration/link-large.svg"
import { TitleComponent, TitleStatusContainer } from "../../common/hris"
import { GET_ORGANIZATION_ADMINS } from "../../common/members/LimitedMemberFeatureGateBanner"

import { LimitedMemberFeatureGateGetOrganizationAdminsQuery } from "@/types/graphql-types"

interface HRISNoPermissionsProps {
  serviceName?: string | undefined
  serviceSquareImage?: string | undefined
  serviceConfiguredByName?: string | null | undefined
  isOnConnect?: boolean
}

const HRISNoPermissions: React.FC<HRISNoPermissionsProps> = ({
  serviceName,
  serviceSquareImage,
  serviceConfiguredByName,
  isOnConnect,
}) => {
  const { data } = useQuery<LimitedMemberFeatureGateGetOrganizationAdminsQuery>(
    GET_ORGANIZATION_ADMINS,
  )
  const adminNames = data?.organization?.adminNames || []

  if (isOnConnect) {
    return (
      <div tw="overflow-auto">
        <div tw="overflow-auto relative">
          <TitleStatusContainer>
            <NoPermissionsContainer>
              <div tw="flex flex-col items-center justify-center">
                <LinkLargeIcon />
                <NoPermissionsText>
                  <div tw="text-2xl pb-9 max-w-xs px-2">
                    {`You must be an admin to connect an HR integration.`}
                  </div>
                  <div tw="max-w-xs">
                    <div tw="pb-6">
                      {`Please contact an admin to ask them to upgrade your account:`}
                    </div>
                    <div>
                      {adminNames.map((name) => (
                        <p>{name}</p>
                      ))}
                    </div>
                  </div>
                </NoPermissionsText>
              </div>
            </NoPermissionsContainer>
          </TitleStatusContainer>
        </div>
      </div>
    )
  } else {
    return (
      <div tw="overflow-auto">
        <div tw="overflow-auto relative">
          <TitleStatusContainer>
            <TitleComponent title={serviceName} image={serviceSquareImage} />
            <NoPermissionsContainer>
              <div tw="flex flex-col items-center justify-center">
                <LinkLargeIcon />
                <NoPermissionsText>
                  <div tw="text-2xl pb-9 max-w-xs px-2">
                    {`This integration is managed by ${serviceConfiguredByName}`}
                  </div>
                  <div tw="max-w-xs">
                    {`If you’d like to make changes to the HR integration, please contact
                  ${serviceConfiguredByName}.`}
                  </div>
                </NoPermissionsText>
              </div>
            </NoPermissionsContainer>
          </TitleStatusContainer>
        </div>
      </div>
    )
  }
}

const NoPermissionsContainer = styled.div`
  ${tw`mt-16 py-20 px-4 box-border border rounded-lg flex items-center justify-center max-h-full`}

  border-color: #dcdfe4;
`

const NoPermissionsText = styled.div`
  ${tw`pt-5 text-center justify-center`}

  color: #4b5563;
`

export default HRISNoPermissions
