import { useMutation, useQuery } from "@apollo/client"
import pluralize from "pluralize"
import React from "react"

import editSmartLink from "@/assets/icons/edit-smart-link.svg"
import Button from "@/common/Button"
import {
  GIFT_BATCH_SMART_LINK_COUNTS,
  GIFT_BATCH_SMART_LINK_DEACTIVATE,
  GIFT_BATCH_SMART_LINK_UPDATE_QUANTITY,
} from "@/track/queries"
import {
  GiftBatch_SmartLinkCountsQuery,
  GiftBatch_SmartLinkDeactivateMutation,
  GiftBatch_SmartLinkDeactivateMutationVariables,
  GiftBatch_SmartLinkUpdateQuantityMutation,
  GiftBatch_SmartLinkUpdateQuantityMutationVariables,
  Track_GiftBatchGiftLinkFragment,
} from "@/types/graphql-types"

interface Props {
  giftBatch: Track_GiftBatchGiftLinkFragment | null | undefined
}

export default function GiftLinkSmartLinkSub({ giftBatch }: Props) {
  // This query is separate so I can refetchQueries on just the counts
  const { data } = useQuery<GiftBatch_SmartLinkCountsQuery>(
    GIFT_BATCH_SMART_LINK_COUNTS,
    {
      variables: { id: giftBatch?.id },
    },
  )

  const [giftBatchSmartLinkDeactivate, { loading: deactivateLoading }] =
    useMutation<
      GiftBatch_SmartLinkDeactivateMutation,
      GiftBatch_SmartLinkDeactivateMutationVariables
    >(GIFT_BATCH_SMART_LINK_DEACTIVATE)

  const [giftBatchSmartLinkUpdateQuantity, { loading: updateQuantityLoading }] =
    useMutation<
      GiftBatch_SmartLinkUpdateQuantityMutation,
      GiftBatch_SmartLinkUpdateQuantityMutationVariables
    >(GIFT_BATCH_SMART_LINK_UPDATE_QUANTITY)

  if (!giftBatch?.isSmartLink) {
    return null
  }

  const deactivateSmartLink = async () => {
    const res = await giftBatchSmartLinkDeactivate({
      variables: { id: giftBatch.id },
    })
    if (res.data?.giftBatchSmartLinkDeactivate?.ok) {
      // TODO: be smarter about this
      window.location.reload()
    } else {
      alert("Sorry, an error occurred.")
    }
  }

  // Use updated counts from the server if available
  const claimedCount =
    data?.workspace?.giftBatch?.smartLinkClaimedCount ??
    giftBatch.smartLinkClaimedCount ??
    0
  const awaitingApprovalCount =
    data?.workspace?.giftBatch?.smartLinkAwaitingApprovalCount ??
    giftBatch.smartLinkAwaitingApprovalCount ??
    0
  const remainingCount =
    (giftBatch.smartLinkQuantity ?? 0) - claimedCount - awaitingApprovalCount

  const claimedAndAwaitingApprovalCount = claimedCount + awaitingApprovalCount

  const handleEditQuantity = async () => {
    const newQuantity = window.prompt(
      `Enter a new quantity of total gifts for this gift link.\n\nThis gift link currently has ${claimedAndAwaitingApprovalCount} ${pluralize(
        "gift",
        claimedAndAwaitingApprovalCount,
      )} already claimed or awaiting approval, so the new quantity can’t be below that number.`,
      giftBatch.smartLinkQuantity?.toString() || "",
    )

    if (newQuantity) {
      const parsedQuantity = parseInt(newQuantity, 10)
      if (!isNaN(parsedQuantity)) {
        const res = await giftBatchSmartLinkUpdateQuantity({
          variables: { id: giftBatch.id, quantity: parsedQuantity },
        })
        if (res.data?.giftBatchSmartLinkUpdateQuantity?.ok) {
          // TODO: be smarter about this
          window.location.reload()
        } else {
          alert(
            res.data?.giftBatchSmartLinkUpdateQuantity?.error ||
              "Sorry, an error occurred.",
          )
        }
      } else {
        alert("Invalid quantity.")
      }
    }
  }

  return (
    <>
      <div tw="pt-6 text-gray-600 text-center px-6">
        Anyone can use this link to claim a gift.
      </div>
      <div tw="pt-2 text-gray-600 text-center font-semibold">
        {awaitingApprovalCount > 0 && (
          <>
            <span tw={"text-primary-600"}>
              {awaitingApprovalCount} {pluralize("gift", awaitingApprovalCount)}{" "}
              awaiting approval
            </span>
            <span>&nbsp;&nbsp;·&nbsp;&nbsp;</span>
          </>
        )}
        <span>
          {claimedCount} {pluralize("gift", claimedCount)} claimed
        </span>
        <span>&nbsp;&nbsp;·&nbsp;&nbsp;</span>
        <span>
          {remainingCount} {pluralize("gift", remainingCount)} remaining
        </span>
        <span>&nbsp;&nbsp;·&nbsp;&nbsp;</span>
        <button
          onClick={handleEditQuantity}
          tw="hover:text-gray-500 transition-colors"
        >
          Edit quantity
        </button>
      </div>
      <div tw={"mx-4 mt-5 flex flex-row justify-center"}>
        <Button
          disabled={deactivateLoading}
          tw="text-[#EB5757] flex flex-row justify-center gap-2"
          onClick={() =>
            window.confirm(
              "Do you want to turn off this gift link? This will prevent gifts from being redeemed.",
            ) && deactivateSmartLink()
          }
        >
          <img src={editSmartLink} alt={"Upload CSV"} />
          Turn off gift link
        </Button>
      </div>
    </>
  )
}
