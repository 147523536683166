import { useState } from "react"

export interface SubscriptionPlan {
  sku: string
  name: string
  price: number
  monthlyPrice: number
  interval: "monthly" | "yearly"
  businessType: "standard" | "small_business" | "user"
  additionalSeatPrice: number | null
}

export const PLANS: { [id: string]: SubscriptionPlan } = {
  business_employee_standard_monthly: {
    sku: "business_employee_standard_monthly",
    name: "Goody for Employee Engagement",
    price: 50000,
    monthlyPrice: 50000,
    interval: "monthly",
    businessType: "standard",
    additionalSeatPrice: null,
  },
  business_employee_standard_yearly: {
    sku: "business_employee_standard_yearly",
    name: "Goody for Employee Engagement",
    price: 480000,
    monthlyPrice: 40000,
    interval: "yearly",
    businessType: "standard",
    additionalSeatPrice: null,
  },
  business_employee_smallbusiness_monthly: {
    sku: "business_employee_smallbusiness_monthly",
    name: "Employee Engagement, Small Business",
    price: 12000,
    monthlyPrice: 12000,
    interval: "monthly",
    businessType: "small_business",
    additionalSeatPrice: null,
  },
  business_employee_smallbusiness_yearly: {
    sku: "business_employee_smallbusiness_yearly",
    name: "Employee Engagement, Small Business",
    price: 120000,
    monthlyPrice: 10000,
    interval: "yearly",
    businessType: "small_business",
    additionalSeatPrice: null,
  },
  business_employee_smallbusiness_2022b_monthly: {
    sku: "business_employee_smallbusiness_2022b_monthly",
    name: "Employee Engagement, Small Business",
    price: 12000,
    monthlyPrice: 12000,
    interval: "monthly",
    businessType: "small_business",
    additionalSeatPrice: null,
  },
  business_employee_smallbusiness_2022b_yearly: {
    sku: "business_employee_smallbusiness_2022b_yearly",
    name: "Employee Engagement, Small Business",
    price: 120000,
    monthlyPrice: 10000,
    interval: "yearly",
    businessType: "small_business",
    additionalSeatPrice: null,
  },
  business_pro_monthly: {
    sku: "business_pro_monthly",
    name: "Goody for Business Pro",
    price: 2500,
    monthlyPrice: 2500,
    interval: "monthly",
    businessType: "user",
    additionalSeatPrice: null,
  },
  business_pro_yearly: {
    sku: "business_pro_yearly",
    name: "Goody for Business Pro",
    price: 24000,
    monthlyPrice: 2000,
    interval: "yearly",
    businessType: "user",
    additionalSeatPrice: null,
  },
  business_team_base_monthly_2022a: {
    sku: "business_team_base_monthly_2022a",
    name: "Teams, Base",
    price: 24900,
    monthlyPrice: 24900,
    interval: "monthly",
    businessType: "standard",
    additionalSeatPrice: 3400,
  },
  business_team_base_yearly_2022a: {
    sku: "business_team_base_yearly_2022a",
    name: "Teams, Base",
    price: 249000,
    monthlyPrice: 20750,
    interval: "yearly",
    businessType: "standard",
    additionalSeatPrice: 2800,
  },
  business_team_growth_monthly_2022a: {
    sku: "business_team_growth_monthly_2022a",
    name: "Teams, Growth",
    price: 33900,
    monthlyPrice: 33900,
    interval: "monthly",
    businessType: "standard",
    additionalSeatPrice: 2400,
  },
  business_team_growth_yearly_2022a: {
    sku: "business_team_growth_yearly_2022a",
    name: "Teams, Growth",
    price: 339000,
    monthlyPrice: 28250,
    interval: "yearly",
    businessType: "standard",
    additionalSeatPrice: 2000,
  },
  business_team_enterprise_monthly_2022a: {
    sku: "business_team_enterprise_monthly_2022a",
    name: "Teams, Enterprise",
    price: 44900,
    monthlyPrice: 44900,
    interval: "monthly",
    businessType: "standard",
    additionalSeatPrice: 2200,
  },
  business_team_enterprise_yearly_2022a: {
    sku: "business_team_enterprise_yearly_2022a",
    name: "Teams, Enterprise",
    price: 449000,
    monthlyPrice: 37417,
    interval: "yearly",
    businessType: "standard",
    additionalSeatPrice: 1800,
  },
}

// When null default plan or invalid default plan, select this fallback
const FALLBACK_PLAN = PLANS["business_employee_standard_yearly"]

export default function useSubscriptionPlanData(defaultPlan: string | null) {
  const matchedDefaultPlan = defaultPlan ? PLANS[defaultPlan] : null
  const [selectedPlan, setSelectedPlan] = useState<SubscriptionPlan>(
    matchedDefaultPlan || FALLBACK_PLAN,
  )

  const getAlternateInterval = (sku: string) => {
    switch (sku) {
      case "business_employee_standard_monthly":
        return PLANS["business_employee_standard_yearly"]
      case "business_employee_standard_yearly":
        return PLANS["business_employee_standard_monthly"]
      case "business_employee_smallbusiness_monthly":
        return PLANS["business_employee_smallbusiness_yearly"]
      case "business_employee_smallbusiness_yearly":
        return PLANS["business_employee_smallbusiness_monthly"]
      case "business_employee_smallbusiness_2022b_monthly":
        return PLANS["business_employee_smallbusiness_2022b_yearly"]
      case "business_employee_smallbusiness_2022b_yearly":
        return PLANS["business_employee_smallbusiness_2022b_monthly"]
      case "business_pro_monthly":
        return PLANS["business_pro_yearly"]
      case "business_pro_yearly":
        return PLANS["business_pro_monthly"]
    }
  }

  const getAlternateBusinessType = (sku: string) => {
    switch (sku) {
      case "business_employee_standard_monthly":
        return PLANS["business_employee_smallbusiness_2022b_monthly"]
      case "business_employee_standard_yearly":
        return PLANS["business_employee_smallbusiness_2022b_yearly"]
      case "business_employee_smallbusiness_monthly":
        return PLANS["business_employee_standard_monthly"]
      case "business_employee_smallbusiness_yearly":
        return PLANS["business_employee_standard_yearly"]
      case "business_employee_smallbusiness_2022b_monthly":
        return PLANS["business_employee_standard_monthly"]
      case "business_employee_smallbusiness_2022b_yearly":
        return PLANS["business_employee_standard_yearly"]
    }
  }

  const toggleInterval = () => {
    const newInterval = getAlternateInterval(selectedPlan.sku)
    if (newInterval) {
      setSelectedPlan(newInterval)
    }
  }

  const alternateIntervalPlan = getAlternateInterval(selectedPlan.sku)

  const toggleBusinessType = () => {
    const newBusinessType = getAlternateBusinessType(selectedPlan.sku)
    if (newBusinessType) {
      setSelectedPlan(newBusinessType)
    }
  }

  return {
    selectedPlan,
    setSelectedPlan,
    toggleInterval,
    alternateIntervalPlan,
    toggleBusinessType,
  }
}
