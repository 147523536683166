import "twin.macro"
import ModeSwitcherBrandsDefault from "./ModeSwitcherBrandsDefault"
import { Props as ModeSwitcherProps } from "./ModeSwitcherBrandsDefault"
import ModeSwitcherProductsDefault from "./ModeSwitcherProductsDefault"
import { StoreMode } from "../types"

interface Props extends ModeSwitcherProps {
  defaultMode: StoreMode
}

// Switches which ModeSwitcher is rendered based on the defaultMode
export default function ModeSwitcherDefaultSwitcher({
  defaultMode,
  ...restProps
}: Props) {
  if (defaultMode === "products") {
    return <ModeSwitcherProductsDefault {...restProps} />
  } else {
    return <ModeSwitcherBrandsDefault {...restProps} />
  }
}
