import tw, { css, styled } from "twin.macro"

const selectionButton = css`
  ${tw`flex-1 text-center align-middle rounded-lg first:ml-0 ml-2 focus:outline-none transition-colors min-w-0`}
  height: 54px;
  line-height: 54px;
`

const checkedButton = css`
  ${tw`bg-primary-600 text-white`}
`
const uncheckedButton = css`
  background-color: #fbf6fb;
`

interface SelectionButtonProps {
  checked: boolean
}

export const PrimarySelectionButton = styled.button<SelectionButtonProps>`
  ${selectionButton}
  ${({ checked }) => (checked ? checkedButton : uncheckedButton)}
`

export const SecondarySelectionButton = styled.input<SelectionButtonProps>`
  ${selectionButton}
  ${({ checked }) => (checked ? checkedButton : null)}
  ${tw`border`}
`
