import Drawer from "@mui/material/Drawer"
import React from "react"
import { Link, useHistory } from "react-router-dom"
import tw, { styled } from "twin.macro"

import BrandSection from "./BrandSection"
import CartDeveloperInfo from "./CartDeveloperInfo"
import { BagLarge, BagXL, CartStar, Close, GiftsIcon } from "../../assets/icons"
import { useBrowseUrls } from "../../store/StoreHelpers"
import { useCartDrawer } from "../contexts/cartDrawer"
import { formatPrice } from "../format"
import { Product, useGlobalState } from "../GlobalState"
import { GradientLink } from "../GradientButton"
import { useCurrentGift } from "../hooks/currentGift"
import { useGiftCart } from "../hooks/giftData"
import { PathRealm, generateRealmPath, getRealmFromPath } from "../realm"

const CartDrawer: React.FC = () => {
  const { cartOpen, closeCart } = useCartDrawer()
  const [currentGift] = useCurrentGift()

  const hasCartItems = currentGift.cart.length > 0

  // Note: when we support autogift we will have to support virtual urls
  return (
    <Drawer
      classes={{ paper: "cart-drawer" }}
      sx={{ zIndex: 9999 }} // Need to be above PDP, should probably sort out z-indexes in app
      anchor={"right"}
      open={cartOpen}
      onClose={closeCart}
    >
      <div tw={"flex flex-col overflow-x-hidden w-full"}>
        <Header hasCartItems={hasCartItems}>
          <BagLarge />
          <CartText>Bag</CartText>
          <CartClose onClick={closeCart}>
            <Close />
          </CartClose>
        </Header>
        {hasCartItems ? <CartFull /> : <CartEmpty />}
      </div>
    </Drawer>
  )
}

const CartClose = styled.button`
  ${tw`absolute right-2 top-2 rounded-full flex items-center justify-center text-gray-400`}
  width: 28px;
  height: 28px;
  color: #baadff;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  &:active {
    background-color: rgba(0, 0, 0, 0.1);
  }
`

const Header = styled.div<{ hasCartItems: boolean }>`
  ${tw`items-center flex flex-col justify-center relative`}
  height: 138px;
  ${({ hasCartItems }) => (hasCartItems ? tw`pb-[28px]` : "")}
  background: linear-gradient(90deg, #f8f5ff 0%, #edebff 100%), #d9d9d9;
`

const CartText = styled.div`
  ${tw`mt-0.5`}
  background: linear-gradient(90deg, #9F73F7 0%, #7A68F6 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const CartEmpty = () => {
  const { closeCart } = useCartDrawer()
  const history = useHistory()
  const { isBrowseUrl, getPath } = useBrowseUrls()
  const currentRealm = getRealmFromPath(history.location.pathname)

  return (
    <div tw={"flex flex-col items-center text-center"}>
      <CartCircle tw={"mt-[105px]"}>
        <BagXL />
      </CartCircle>
      <div tw={"mt-11 text-2xl mx-5 text-primary-500 font-medium"}>
        Your bag is empty
      </div>
      <div tw={"mt-4 text-gray-450 text-lg mx-5"}>
        It’s a gift waiting to happen.
      </div>
      <SendButtonContainer
        tw={"mt-8 self-stretch"}
        to={
          isBrowseUrl() ? getPath() : generateRealmPath(currentRealm, "/browse")
        }
        onClick={closeCart}
      >
        <GiftsIcon tw="mr-2.5" />
        Start browsing
      </SendButtonContainer>
    </div>
  )
}

const CartCircle = styled.div`
  ${tw`h-32 w-32 rounded-full bg-white flex items-center justify-center`}
  background: #ffffff;
  box-shadow:
    0px 2px 4px rgba(216, 208, 255, 0.2),
    0px 0px 250px rgba(203, 193, 255, 0.2);
`

const CartFull = () => {
  const { closeCart } = useCartDrawer()
  const [currentRealm] = useGlobalState("currentRealm")
  const { isBrowseUrl, getPath } = useBrowseUrls()
  const [currentGift] = useCurrentGift()
  const { brandProducts, cartSubtotal } = useGiftCart()

  return (
    <>
      <SendButton
        tw={"mt-[-28px]"}
        currentRealm={currentRealm || "business"}
        closeCart={closeCart}
        cart={currentGift.cart}
      />
      <div
        tw={"flex flex-col items-stretch flex-1 overflow-y-auto"}
        css={"margin-top: -28px; padding-top: 28px;"}
      >
        <div tw={"flex flex-col"}>
          <div
            tw={"flex flex-row justify-center items-center mt-5"}
            css={"color: #8C6EF7;"}
          >
            <CartStar tw={"mr-2"} /> No address required
          </div>
          <div tw={"flex flex-row justify-between mx-8 mt-12"}>
            <div tw={"font-semibold"}>
              Subtotal{currentRealm !== "consumer" ? " per recipient" : ""}
            </div>
            <div>{formatPrice(cartSubtotal, true)}</div>
          </div>
          <div tw={"h-1"} />
          {brandProducts.map((products) => (
            <BrandSection
              key={products[0].brandID}
              products={products}
              brandName={products[0].brandName}
              cartEligible={products[0].cartEligible}
              giftOptionSlug={products[0].giftOptionSlug}
            />
          ))}
          <SendButton
            tw={"mt-12"}
            currentRealm={currentRealm || "business"}
            closeCart={closeCart}
            cart={currentGift.cart}
          />
          <ContinueLink
            to={
              isBrowseUrl()
                ? getPath()
                : generateRealmPath(currentRealm, "/browse")
            }
            onClick={closeCart}
          >
            Continue browsing
          </ContinueLink>
          <CartDeveloperInfo />
          <div
            css={"max-width: 279px; margin-top: 38px;"}
            tw={"text-center self-center text-sm text-gray-500 mb-8"}
          >
            Gifts ship directly from brands, so items from different brands ship
            separately.
          </div>
        </div>
      </div>
    </>
  )
}

const ContinueLink = styled(Link)`
  ${tw`flex justify-center items-center bg-gray-050 rounded-xl mx-5 mt-3 text-gray-450 hover:bg-gray-100 transition-all active:scale-95`}
  height: 50px;
`

const SendButton = ({
  closeCart,
  currentRealm,
  cart,
  ...restProps
}: React.ComponentPropsWithoutRef<typeof SendButtonContainer> & {
  closeCart: () => void
  currentRealm: PathRealm
  cart: Product[]
}) => {
  const realmToUse = currentRealm === "business" ? "plus" : currentRealm
  const [, setRealmChoiceModalOpen] = useGlobalState("realmChoiceModalOpen")

  return (
    <SendButtonContainer
      to={generateRealmPath(realmToUse, "/send")}
      onClick={(e) => {
        if (currentRealm === "business" && cartHasNuuly(cart)) {
          e.preventDefault()
          setRealmChoiceModalOpen(true)
        }

        closeCart()
      }}
      {...restProps}
    >
      <GiftsIcon tw="mr-2.5" />
      Checkout
    </SendButtonContainer>
  )
}

const SendButtonContainer = styled(GradientLink)`
  ${tw`mx-5 flex flex-row justify-center z-50`}
`

// Nuuly products trigger a realm choice modal when checking out from the business realm
function cartHasNuuly(cart: Product[]) {
  return cart.some(
    (product) => product.brandSlug === "nuuly" || product.brandName === "Nuuly",
  )
}

export default CartDrawer
