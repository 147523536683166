const CACHE_KEY = "goody.swag_products"

const fetchSwagProducts = () => {
  const storageValue = localStorage.getItem(CACHE_KEY)
  if (!storageValue) return {}

  return JSON.parse(storageValue)
}

export const clearSwagProducts = () => {
  localStorage.removeItem(CACHE_KEY)
}

export const fetchSwagProductId = (templateId: string) => {
  const products = fetchSwagProducts()
  return products[templateId]
}

export const saveSwagProductId = (templateId: string, mockupId: string) => {
  const products = fetchSwagProducts()
  products[templateId] = mockupId
  localStorage.setItem(CACHE_KEY, JSON.stringify(products))
}

export const removeSwagProductId = (templateId: string) => {
  const products = fetchSwagProducts()
  delete products[templateId]
  localStorage.setItem(CACHE_KEY, JSON.stringify(products))
}
