import { gql, useMutation } from "@apollo/client"
import React, { useState } from "react"
import { Helmet } from "react-helmet-async"
import { Link } from "react-router-dom"
import tw, { styled } from "twin.macro"

import Button from "../common/Button"
import FormError from "../common/FormError"
import { generateRealmPath } from "../common/realm"
import TextInputField from "../common/TextInputField"

const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState("")
  const [error, setError] = useState("")

  const [formHidden, setFormHidden] = useState(false)
  const [forgotPassword, { loading }] = useMutation(FORGOT_PASSWORD)

  // Events

  const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError("")
    setEmail(event.target.value)
  }

  const onSubmit = async () => {
    const response = await forgotPassword({
      variables: { email: email },
    })

    if (response?.data?.forgotPassword?.ok) {
      setFormHidden(true)
    } else {
      if (response?.data?.forgotPassword?.error) {
        setError(response.data.forgotPassword.error)
      }
    }
  }

  // Template

  return (
    <>
      <Helmet>
        <title>Forgot Password – Goody for Business</title>
      </Helmet>

      <BackgroundGradientContainer>
        <div tw="container mx-auto">
          <div tw="xl:w-2/3 mx-auto">
            <MainContainer>
              <FormContainer
                onSubmit={(e) => {
                  e.preventDefault()
                  onSubmit()
                }}
              >
                <FormError message={error} />
                <FormHeading>Forgot password</FormHeading>

                <FormContent isHidden={formHidden}>
                  <p>
                    Enter your email and a link to reset your password will be
                    sent to your email.
                  </p>

                  <InputContainer>
                    <TextInputField
                      type="email"
                      placeholder="Email"
                      value={email}
                      onChange={onEmailChange}
                      autoFocus={true}
                      className="ph-no-capture fs-exclude data-hj-suppress"
                    />
                  </InputContainer>

                  <div tw="flex justify-center mt-4">
                    <Button onClick={onSubmit} disabled={loading}>
                      Submit
                    </Button>
                  </div>
                </FormContent>

                <Confirmation>
                  Instructions to reset your password have been sent to your
                  email.
                </Confirmation>

                <div tw="flex justify-center text-sm text-gray-400">
                  <Link to={generateRealmPath("business", "/signin")}>
                    Back to sign in
                  </Link>
                </div>
              </FormContainer>
            </MainContainer>
          </div>
        </div>
      </BackgroundGradientContainer>
    </>
  )
}

// Styles

export const BackgroundGradientContainer = styled.div`
  background: repeat-x
    linear-gradient(
      90deg,
      rgba(228, 161, 184, 0.15) 30.58%,
      rgba(179, 125, 213, 0.15) 100%
    );
  ${tw`px-5 py-32`}
  background-size: 100% 400px;

  @media (max-width: 768px) {
    background-size: 100% 800px;
  }
`

export const MainContainer = styled.div`
  max-width: 530px;
  margin: 0 auto;
  ${tw`rounded-2xl bg-white divide-y divide-gray-100`}
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.04), 0 8px 50px rgba(0, 0, 0, 0.06);
  overflow: hidden;
`

export const FormContainer = styled.form`
  position: relative;
  padding: 2rem;
`

const FormHeading = styled.div`
  ${tw`text-2xl font-medium`}
  text-align: center;
`

interface FormContentProps {
  isHidden?: boolean
}

const FormContent = styled.div<FormContentProps>`
  ${tw`bg-white transition-opacity`}
  z-index: 3;
  position: relative;
  margin: 1em 0 3em;
  ${({ isHidden }) =>
    isHidden ? `z-index: 1; opacity: 0; pointer-events: none;` : ``}

  p {
    width: 75%;
    margin: 0 auto;
    text-align: center;
  }
`

const Confirmation = styled.div`
  top: 50%;
  left: 50%;
  z-index: 2;
  position: absolute;
  text-align: center;
  transform: translate(-50%, -50%);
`

export const InputContainer = styled.div`
  ${tw`flex flex-row`}
  height: calc(1.5em + 2rem + 2px);
  margin-top: 2em;
  overflow: hidden;
`

const FORGOT_PASSWORD = gql`
  mutation Forgot_Password($email: String!) {
    forgotPassword(email: $email) {
      ok
      error
    }
  }
`

export default ForgotPassword
