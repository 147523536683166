import { gql } from "@apollo/client"

export const OrganizationCreateSamlIdentityProvider = gql`
  mutation OrganizationCreateSamlIdentityProvider($organizationId: ID!) {
    samlCreateIdentityProvider(organizationId: $organizationId) {
      ok
      samlIdentityProvider {
        organizationId
        enabled
        samlId
        certificate
        ssoUrl
        entityId
        acsUrl
        spEntityId
      }
    }
  }
`
