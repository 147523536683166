import { registerComponent } from "@plasmicapp/react-web/lib/host"

import HolidayGiftGuideButton from "./store/components/HolidayGiftGuideScreen/HolidayGiftGuideButton"
import LinkContainer from "./store/HolidayGiftGuideScreen/LinkContainer"

import HeroProductAnimation from "@/landing/business/HeroProductAnimation"
import HowItWorksSection from "@/landing/business/HowItWorksSection/HowItWorksSection"
import BigButton from "@/landing/common/BigButton"

registerComponent(BigButton, {
  name: "BigButton",
  props: {
    label: "string",
    to: "string",
  },
  importPath: "./src/landing/common/BigButton",
  isDefaultExport: true,
})

registerComponent(HeroProductAnimation, {
  name: "HeroProductAnimation",
  props: {
    cardImageUrl: "imageUrl",
    product1ImageUrl: "imageUrl",
    product2ImageUrl: "imageUrl",
    product1TimingOffset: "number",
    product2TimingOffset: "number",
    cardTimingOffset: "number",
    cardNudge: "number",
    isOnRightSide: "boolean",
  },
  importPath: "./src/landing/business/HeroProductAnimation",
  isDefaultExport: true,
})

registerComponent(HowItWorksSection, {
  name: "HowItWorksSection",
  props: {
    leftContent: "slot",
    rightContent: "slot",
  },
  importPath: "./src/landing/business/HowItWorksSection/HowItWorksSection",
  isDefaultExport: true,
})

registerComponent(HolidayGiftGuideButton, {
  name: "HolidayGiftGuideButton",
  props: {
    variant: "string",
    text: "string",
    to: "string",
  },
  importPath: "./src/store/HolidayGiftGuideScreen/HolidayGiftGuideButton",
  isDefaultExport: true,
})

registerComponent(LinkContainer, {
  name: "LinkContainer",
  props: {
    to: "string",
    className: "string",
    children: "slot",
  },
  importPath: "./src/store/HolidayGiftGuideScreen/LinkContainer",
  isDefaultExport: true,
})
