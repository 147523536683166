import { useQuery } from "@apollo/client"
import React from "react"

import { Alert, AlertColor, OptionBase } from "./index"

import { CALENDLY_QUERY } from "@/account/Integrations/Calendly"
import { GiftMeetingOptions } from "@/common/components/GiftMeetingOptions"
import { useGlobalState } from "@/common/GlobalState"
import { useFeatureAccess } from "@/common/hooks/featureAccess"
import { useGiftBatchSettings } from "@/track/components/giftBatch/GiftBatchSettings/hooks"
import {
  CalendarIntegrationQuery,
  gift_meeting_setting,
} from "@/types/graphql-types"

export const MeetingCalendar = () => {
  const { settings, setSettings } = useGiftBatchSettings()
  const [currentUser] = useGlobalState("user")

  const { data } = useQuery<CalendarIntegrationQuery>(CALENDLY_QUERY)
  const calendlyApiUrl = data?.me?.calendarIntegration?.calendlyApiUrl

  const { hasFeature } = useFeatureAccess()
  const hasPro = hasFeature("pro_plan")

  const calendlyIsNotSetUp = calendlyApiUrl == null
  const currentUserIsNotGiftCreator =
    settings.userPublicId !== currentUser?.publicId

  const handleSetGiftMeeting = (
    newGiftMeetingSetting: gift_meeting_setting,
  ) => {
    setSettings((currentSettings) => ({
      ...currentSettings,
      giftMeetingSetting: newGiftMeetingSetting,
    }))
  }

  const creatorAlert = (
    <Alert
      primaryMessage={
        "Only the creator of this gift, " +
        settings.userFullName +
        ", can edit this field."
      }
      color={AlertColor.yellow}
    />
  )

  const calendlySetupAlert = (
    <Alert
      primaryMessage="You don't have a meeting calendar set up."
      color={AlertColor.blue}
      linkURL="/account/integrations/calendly"
      linkText="Set up →"
    />
  )

  const selectedAlert = () => {
    if (hasPro && currentUserIsNotGiftCreator) {
      return creatorAlert
    }

    if (hasPro && calendlyIsNotSetUp) {
      return calendlySetupAlert
    }

    return null
  }

  if (settings.isDirectSend) {
    return null
  }

  return (
    <OptionBase
      name="Meeting calendar"
      displayProTag={!hasPro}
      disabled={!hasPro || currentUserIsNotGiftCreator || calendlyIsNotSetUp}
      alert={selectedAlert()}
    >
      <GiftMeetingOptions
        giftMeetingType={settings.giftMeetingSetting}
        setGiftMeetingType={handleSetGiftMeeting}
      />
    </OptionBase>
  )
}
