import React from "react"
import tw, { TwStyle } from "twin.macro"

interface Props {
  percentageAmount: string
  text: string
  reference: string
  backgroundColor: TwStyle
  percentageColor: TwStyle
  twoColumns?: boolean
}
const StatsProofCard: React.FC<Props> = ({
  percentageAmount,
  text,
  reference,
  backgroundColor,
  percentageColor,
  twoColumns,
}) => (
  <CardContainer css={[backgroundColor]}>
    <TextContainer css={[twoColumns && TwoColumns]}>
      <PercentageText css={[percentageColor]}>
        {percentageAmount}
        <span tw="font-sans">%</span>
      </PercentageText>
      <div>
        <MainText tw="pb-8">{text}</MainText>
        <ReferenceText>{reference}</ReferenceText>
      </div>
    </TextContainer>
  </CardContainer>
)

const CardContainer = tw.div`
  flex items-start rounded-[30px]  
`

const TextContainer = tw.div`
  flex flex-col items-start gap-4
  ml-10 mr-7 md:mr-10 my-16 py-2
`
const TwoColumns = tw`md:mx-8 my-12 lg:flex-row lg:gap-6`

const PercentageText = tw.p`text-[62px] leading-none font-semibold`
const MainText = tw.p`text-xl font-semibold text-dark-blue`
const ReferenceText = tw.p`text-base font-normal text-[#626366]`

export default StatsProofCard
