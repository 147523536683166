import React from "react"
import { Helmet } from "react-helmet-async"
import { Link } from "react-router-dom"
import tw from "twin.macro"

import { FlexCenterItems } from "./businessUseCases/components/PositionHelper"
import SOCImage from "../common/images/soc.png"
import { generateRealmPath } from "../common/realm"

const Security = () => (
  <>
    <Helmet>
      <title>Security — Goody for Business</title>
      <meta
        name="description"
        content="At Goody, we take security and the protection of your data seriously."
      />
    </Helmet>

    <FlexCenterItems tw="py-20 mx-5">
      <Title>Security</Title>
      <Subtitle tw="pt-5 mx-5">
        At Goody, we take security and the protection of your data seriously.
      </Subtitle>
      <Soc />
      <SecurityItems />
    </FlexCenterItems>
  </>
)

const Title = tw.div`text-black text-5xl font-semibold`
const Subtitle = tw.div`text-gray-600 text-xl font-normal text-center`
const Header = tw.div`text-black text-xl font-semibold`
const Content = tw.div`text-gray-600 text-base font-text font-normal`

const Soc = () => (
  <SocContainer tw="max-w-lg my-20">
    <div>
      <Header>SOC 2</Header>
      <Content tw="py-3">
        Goody is SOC 2 Type 2 compliant. SOC 2 is an industry-standard
        compliance framework that sets rigorous standards for a company’s
        internal controls for safeguarding customer data and security.
      </Content>
      <Content>
        To access the report,{" "}
        <InternalLink to={generateRealmPath(null, "/contact")}>
          contact us
        </InternalLink>
        .
      </Content>
    </div>
    <a
      href="https://www.aicpa.org/soc4so"
      rel="nofollow noopener noreferrer"
      target="_blank"
      tw="w-[120px] md:w-auto"
    >
      <img src={SOCImage} />
    </a>
  </SocContainer>
)
const SocContainer = tw.div`
  p-6 border border-gray-250 rounded-xl
  flex flex-col md:flex-row items-center gap-10
`
const InternalLink = tw(Link)`text-primary-600`

const securityItems: SecurityItemProps[] = [
  {
    header: "Hosting",
    content:
      "Goody’s application is hosted on Amazon Web Services, an industry-leading SOC 2 Type 2 and ISO 27001-compliant infrastructure provider. Amazon Web Services provides physical, perimeter, and network security.",
  },
  {
    header: "SSO",
    content:
      "For business accounts, Google Workspace or Microsoft accounts can be used to SSO into your Goody account.",
  },
  {
    header: "Penetration test",
    content:
      "We perform third-party penetration testing of our application and infrastructure annually.",
  },
  {
    header: "Data security",
    content:
      "HTTPS (TLS) is enforced for all communication between clients and our servers. Data is encrypted at rest in our database. Backups are taken regularly and continuously.",
  },
  {
    header: "Policies",
    content:
      "Goody maintains internal security policies covering a range of topics, including data management, incident response, secure development, and business continuity and disaster response. Employees undergo regular security training.",
  },
  {
    header: "Access control",
    content:
      "Access to data is restricted only to employees who require it, and segmented using role-based access control. We follow the principle of least privilege to restrict access to data, and all requests for access privileges are logged and go through an approval process.",
  },
  {
    header: "Payment security",
    content:
      "Goody uses Stripe for payment processing, a PCI Level 1 service provider.",
  },
]

const SecurityItems = () => (
  <FlexCenterItems tw="max-w-md gap-20">
    {securityItems.map((item) => (
      <SecurityItem {...item} />
    ))}
  </FlexCenterItems>
)

interface SecurityItemProps {
  header: string
  content: string
}

const SecurityItem = ({ header, content }: SecurityItemProps) => (
  <div>
    <Header tw="pb-3">{header}</Header>
    <Content>{content}</Content>
  </div>
)

export default Security
