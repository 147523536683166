import { gql, useMutation } from "@apollo/client"

import ActivityGiftBatch from "./ActivityGiftBatch"
import ActivityNav from "./ActivityNav"
import { Row } from "./common"
import { successToast } from "../../common/toast"
import { ROOT_DATA_QUERY } from "../../graphql"

import {
  BalanceActivityFragment,
  Balance_CancelGiftMutation,
  Balance_CancelGiftMutationVariables,
  Balance_GiftBatchCancelPendingGiftsMutation,
  Balance_GiftBatchCancelPendingGiftsMutationVariables,
  Balance_GiftBatchCancelScheduledBatchMutation,
  Balance_GiftBatchCancelScheduledBatchMutationVariables,
} from "@/types/graphql-types"

export interface YearAndMonth {
  year: number
  month: number
}

interface Props {
  yearAndMonth: YearAndMonth
  setYearAndMonth: (yearAndMonth: YearAndMonth) => void
  requestRefetch: () => void
  loading: boolean
  data: BalanceActivityFragment | undefined
  // Whether this is on the user's /plus/balance page.
  // False if on the Organization > Budgeting > Member Detail page.
  // Note that even if a user is viewing their own activity on the Member Detail
  // page, this is still meant to be false.
  isOwnActivity: boolean
}

// Displays balance activity by gift batch.
// Caller holds the YearAndMonth state and controls this component.
// Caller also fetches data, incorporating the fragment at the bottom.
export default function Activity({
  yearAndMonth,
  setYearAndMonth,
  requestRefetch,
  loading,
  data,
  isOwnActivity,
}: Props) {
  const { year, month } = yearAndMonth

  const currentYear = new Date().getFullYear()
  const currentMonth = new Date().getMonth() + 1

  const canGoPrevious = !(year <= 2022 && month === 1)
  const canGoNext = !(year >= currentYear && month >= currentMonth)

  // Min month: 1/2022
  const previousMonth = () => {
    if (!canGoPrevious) {
      return
    }

    if (month === 1) {
      setYearAndMonth({
        year: year - 1,
        month: 12,
      })
    } else {
      setYearAndMonth({
        year,
        month: month - 1,
      })
    }
  }

  // Max month: 12/2050
  const nextMonth = () => {
    if (!canGoNext) {
      return
    }

    if (month === 12) {
      setYearAndMonth({
        year: year + 1,
        month: 1,
      })
    } else {
      setYearAndMonth({
        year,
        month: month + 1,
      })
    }
  }

  const [cancelGiftMutation] = useMutation<
    Balance_CancelGiftMutation,
    Balance_CancelGiftMutationVariables
  >(GIFT_CANCEL_MUTATION)

  const [giftBatchCancelPendingGiftsMutation] = useMutation<
    Balance_GiftBatchCancelPendingGiftsMutation,
    Balance_GiftBatchCancelPendingGiftsMutationVariables
  >(GIFT_BATCH_CANCEL_PENDING_GIFTS_MUTATION)

  const [giftBatchCancelScheduledBatchMutation] = useMutation<
    Balance_GiftBatchCancelScheduledBatchMutation,
    Balance_GiftBatchCancelScheduledBatchMutationVariables
  >(GIFT_BATCH_CANCEL_SCHEDULED_BATCH_MUTATION)

  const cancelGift = async (id: string) => {
    const res = await cancelGiftMutation({
      variables: { id },
      refetchQueries: [
        {
          query: ROOT_DATA_QUERY,
        },
      ],
    })

    if (res?.data?.giftCancel?.ok) {
      successToast("Gift canceled.")
      requestRefetch()
    } else if (res?.data?.giftCancel?.error) {
      alert(res.data.giftCancel.error)
    } else {
      alert("Sorry, an error occurred.")
    }
  }

  const giftBatchCancelPendingGifts = async (id: string) => {
    const res = await giftBatchCancelPendingGiftsMutation({
      variables: { id },
      refetchQueries: [
        {
          query: ROOT_DATA_QUERY,
        },
      ],
    })

    if (res?.data?.giftBatchCancelPendingGifts?.ok) {
      successToast("Pending gifts canceled.")
      requestRefetch()
    } else if (res?.data?.giftBatchCancelPendingGifts?.error) {
      alert(res.data.giftBatchCancelPendingGifts.error)
    } else {
      alert("Sorry, an error occurred.")
    }
  }

  const giftBatchCancelScheduledBatch = async (id: string) => {
    const res = await giftBatchCancelScheduledBatchMutation({
      variables: { id },
      refetchQueries: [
        {
          query: ROOT_DATA_QUERY,
        },
      ],
    })

    if (res?.data?.giftBatchCancel?.ok) {
      successToast("Scheduled batch canceled.")
      requestRefetch()
    } else if (res?.data?.giftBatchCancel?.error) {
      alert(res.data.giftBatchCancel.error)
    } else {
      alert("Sorry, an error occurred.")
    }
  }

  return (
    <div>
      <ActivityNav
        year={year}
        month={month}
        previousMonth={previousMonth}
        nextMonth={nextMonth}
        canGoPrevious={canGoPrevious}
        canGoNext={canGoNext}
      />

      <div>
        <div tw="relative after:(absolute bottom-0 left-3 right-3 border-b border-gray-200 content[''])">
          <Row tw="font-medium text-sm uppercase text-gray-700">
            <div>Gift batch</div>
            <div tw="text-right text-yellow-600">Pending</div>
            <div tw="text-right">Accepted</div>
          </Row>
        </div>
        {data?.balanceGiftBatches?.map((batch) => (
          <ActivityGiftBatch
            balanceGiftBatch={batch}
            key={batch.id}
            cancelGift={cancelGift}
            giftBatchCancelPendingGifts={giftBatchCancelPendingGifts}
            giftBatchCancelScheduledBatch={giftBatchCancelScheduledBatch}
            isOwnActivity={isOwnActivity}
          />
        ))}
        {data?.balanceGiftBatches?.length === 0 && !loading && (
          <div tw="py-12 text-center text-gray-400">
            No gifts sent using balance
          </div>
        )}
        {!data && loading && (
          <div tw="py-12 text-center text-gray-400">Loading&hellip;</div>
        )}
      </div>
    </div>
  )
}

export const BALANCE_ACTIVITY_FRAGMENT = gql`
  fragment BalanceActivity on BalanceActivity {
    balanceGiftBatches {
      id
      name
      expiresAt
      status
      amountReserved
      createdAt
      scheduledSendOn
      scheduledRecipients
      pendingGiftsCount
      pendingAmount
      acceptedGiftsCount
      acceptedAmount
      giftBatchPublicId
      balanceGifts {
        id
        recipientName
        status
        storedAmount
        postAcceptAdjustedAmount
        isRefunded
        giftPublicId
      }
    }
  }
`

const GIFT_CANCEL_MUTATION = gql`
  mutation Balance_CancelGift($id: ID!) {
    giftCancel(id: $id) {
      ok
      error
    }
  }
`

const GIFT_BATCH_CANCEL_PENDING_GIFTS_MUTATION = gql`
  mutation Balance_GiftBatchCancelPendingGifts($id: ID!) {
    giftBatchCancelPendingGifts(id: $id) {
      ok
      error
    }
  }
`

const GIFT_BATCH_CANCEL_SCHEDULED_BATCH_MUTATION = gql`
  mutation Balance_GiftBatchCancelScheduledBatch($id: ID!) {
    giftBatchCancel(id: $id) {
      ok
      error
    }
  }
`
