import { gql } from "@apollo/client"

export const OrganizationGetRequireSSOSetting = gql`
  query OrganizationGetRequireSSOSetting {
    organization {
      id
      requireSsoSetting
    }
  }
`
