import React from "react"
import tw, { styled } from "twin.macro"

import { ReactComponent as ErrorIcon } from "../assets/icons/x-circle.svg"

interface ErrorProps {
  message?: string
}

const FormError: React.FC<ErrorProps> = (props) => {
  const message: string = props.message || ""
  const hasError: boolean = message.length > 0

  return (
    <ErrorMessage hasError={hasError}>
      <ErrorIcon css="height: 24px;" />
      {message}
    </ErrorMessage>
  )
}

type ErrorMessageProps = {
  hasError: boolean
}

const ErrorMessage = styled.div<ErrorMessageProps>`
  ${tw`rounded-lg bg-red-50 px-4 py-2`}
  margin-bottom: 1em;
  align-items: center;
  gap: 0.5em;
  ${({ hasError }) => (hasError ? `display: flex;` : `display: none;`)}
`

export default FormError
