import DropdownContent from "./components/DropDownContent"
import { ReactComponent as AutogiftingIcon } from "./images/autogifting.svg"
import { ReactComponent as ClientAppreciationIcon } from "./images/client-appreciation.svg"
import { ReactComponent as EmployeeGiftsIcon } from "./images/employee-gifts.svg"
import { ReactComponent as SalesProspectingIcon } from "./images/sales-prospecting.svg"
import { Gift } from "../../assets/icons"
import { generateRealmPath } from "../../common/realm"

const BusinessUseCasesDropdownContent = () => {
  const useCasePath = (page: string) =>
    generateRealmPath("business", `/use-cases/${page}`)
  const businessUseCases = [
    {
      icon: <EmployeeGiftsIcon />,
      name: "Employee Gifts",
      link: useCasePath("employee-recognition"),
    },
    {
      icon: <ClientAppreciationIcon />,
      name: "Client Appreciation",
      link: useCasePath("client-appreciation"),
    },
    {
      icon: <SalesProspectingIcon />,
      name: "Sales Prospecting",
      link: useCasePath("sales-prospecting"),
    },
    {
      icon: <AutogiftingIcon />,
      name: "Automated Gifting",
      link: useCasePath("autogifting"),
    },
    {
      icon: (
        <div tw="flex items-center justify-center">
          <Gift tw="h-5 w-5 fill-[#8167FF] group-hover:stroke-current group-hover:fill-current" />
        </div>
      ),
      name: "Custom Swag",
      link: generateRealmPath("business", "/browse/category/branded-swag"),
    },
  ]

  return <DropdownContent items={businessUseCases} />
}

export default BusinessUseCasesDropdownContent
