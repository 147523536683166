import React from "react"

import { useGiftBatchSettings } from "./hooks"

import { OptionBase } from "./index"

import { SwapOptions } from "@/common/components/SwapOptions"
import { SwapWarning } from "@/send/components/SwapWarning"
import { GiftSwapTypeEnum } from "@/types/graphql-types"

export const Swap = () => {
  const { settings, setSettings } = useGiftBatchSettings()
  const swapType = settings.swapType

  const handleSetSwapType = (newSwapType: GiftSwapTypeEnum) => {
    setSettings((currentSettings) => ({
      ...currentSettings,
      swapType: newSwapType,
    }))
  }

  if (swapType == null || settings.isDirectSend || settings.hasCustomStore) {
    return null
  }

  return (
    <OptionBase name={settings.hasFlexGiftItem ? "Gift of choice" : "Swap"}>
      <div>
        <SwapWarning
          swapType={swapType}
          total={settings.pricePreTax ?? 0}
          onClickButton={() =>
            handleSetSwapType(GiftSwapTypeEnum.swap_multiple)
          }
          hasFlexGiftItem={settings.hasFlexGiftItem}
        />
        <SwapOptions
          swapType={swapType}
          setSwapType={handleSetSwapType}
          forceSwapEnabled={settings.hasForceSwapEnabledItem}
          showTooltip={false}
          sendV3={true}
          hasFlexGiftItem={settings.hasFlexGiftItem}
        />
      </div>
    </OptionBase>
  )
}
