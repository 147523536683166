import arrowDownToLine from "@/assets/icons/arrow-down-to-line.svg"
import squareArrowOutUpRight from "@/assets/icons/square-arrow-out-up-right.svg"
import Button from "@/common/CommonButton"
import { getEnvVars } from "@/common/environment"
import { formatPrice } from "@/common/format"
import Table, { TableProps } from "@/common/Table"
import { BalanceDeposit } from "@/types/graphql-types"

type BalanceReceiptsTableProps = Omit<TableProps<BalanceDeposit>, "columns">

const BalanceReceiptsTable = (props: BalanceReceiptsTableProps) => (
  <Table<BalanceDeposit>
    columns={[
      {
        title: "Date",
        render: ({ finalizedAt, createdAt }) =>
          new Date(finalizedAt ?? createdAt).toLocaleDateString(),
      },
      {
        title: "Receipt #",
        render: ({ id }) => id,
      },
      {
        title: "Amount charged",
        render: ({ amount, bundledReceiptItem }) => (
          <div>
            <div>{formatPrice(amount)}</div>
            <div tw="text-gray-450">
              {bundledReceiptItem?.bundledReceipt?.paymentMethodName ??
                "Invoiced"}
            </div>
          </div>
        ),
      },
      {
        title: "View",
        render: ({ bundledReceiptItem }) => {
          const url = `${
            getEnvVars().giftsUrl
          }/business_receipts/item/${bundledReceiptItem?.accessToken}`
          return (
            <div tw="flex gap-3">
              <a href={url} target="_blank" rel="noreferrer" tw="flex">
                <Button leftIcon={squareArrowOutUpRight}>Open</Button>
              </a>
              <a href={`${url}.pdf`} rel="noreferrer" tw="flex">
                <Button leftIcon={arrowDownToLine}>PDF</Button>
              </a>
            </div>
          )
        },
      },
    ]}
    {...props}
  />
)

export default BalanceReceiptsTable
