import classNames from "classnames"
import { isEmpty } from "lodash-es"
import tw, { styled } from "twin.macro"

import Tooltip from "./Tooltip"
import { ReactComponent as AlertIcon } from "../contacts/components/images/hr-integration/alert-triangle.svg"
import { ReactComponent as SpinningIcon } from "../contacts/components/images/hr-integration/arrow-cycle.svg"
import { ReactComponent as ConnectedIcon } from "../contacts/components/images/hr-integration/connected.svg"
import { ReactComponent as ReadyToConnectIcon } from "../contacts/components/images/hr-integration/ready-to-connect.svg"
import { ReactComponent as TooltipIcon } from "../contacts/components/images/hr-integration/tooltip.svg"

import {
  CustomerIntegrationHrisTeamInput,
  CustomerIntegrationWorkspaceConfigurationInput,
} from "@/types/graphql-types"
import { CustomerIntegrationWorkspaceConfiguration } from "@/types/graphql-types"

// Used to distinguish special logic for the "All employees" sync option
// and to sort the HRIS synced teams in the contacts sidebar -
// with "All employees" lists at the top and "Unassigned" at the bottom
export const ALL_EMPLOYEES = "All employees"
export const ALL_SYNCED_EMPLOYEES = "All synced employees"
export const UNASSIGNED = "Unassigned"

export const HRIS = "HRIS"
export const MERGE = "Merge"
export const RIPPLING = "Rippling"

// The workspace configuration lists are pulled from the API for display, and then sent back
// with the updated isSynced values on each HRIS team with __typename removed for input
export const convertWorkspaceConfigurationsToInput = (
  workspaces: CustomerIntegrationWorkspaceConfiguration[] | null | undefined,
) => {
  return workspaces?.map((workspaceConfig) => {
    const hrisTeamsInput = workspaceConfig?.hrisTeams?.map((hrisTeam) => {
      const { __typename, ...hrisTeamInput } = hrisTeam
      return hrisTeamInput
    })

    return {
      id: workspaceConfig.id,
      name: workspaceConfig.name,
      hrisTeams: hrisTeamsInput ?? [],
    }
  })
}

// Return HRIS teams configuration for Goody workspace with workspaceID
export const getHRISTeamsConfigurationList = (
  workspaceID: string | null,
  workspaces:
    | CustomerIntegrationWorkspaceConfigurationInput[]
    | null
    | undefined,
) => {
  if (!workspaceID) {
    return null
  }
  return workspaces?.find((workspace) => workspace.id === workspaceID)
    ?.hrisTeams
}

export const isAllEmployeesSelected = (
  teams: CustomerIntegrationHrisTeamInput[],
) => {
  return !!teams?.every(
    (t) =>
      t.isSynced ||
      (!isEmpty(t.nameHierarchy) && t.nameHierarchy[0] === ALL_EMPLOYEES),
  )
}

// Return a hash with Goody workspaces as keys and the number of synced
// (or toggled to sync) HRIS teams as values. Used in the Workspaces Sidebar
// on the HRIS Configuration page.
export const numSyncedTeamsHash = (
  workspaces:
    | CustomerIntegrationWorkspaceConfigurationInput[]
    | null
    | undefined,
) => {
  const syncedTeamsHash: { [id: string]: Number } = {}

  workspaces?.forEach((workspace) => {
    syncedTeamsHash[workspace.id] = workspace?.hrisTeams.filter(
      (hrisTeam) =>
        // We want to count teams with no name but we don't want to count the ALL_EMPLOYEES team as a synced team
        (isEmpty(hrisTeam.nameHierarchy) ||
          hrisTeam.nameHierarchy[0] !== ALL_EMPLOYEES) &&
        hrisTeam.isSynced,
    ).length
  })

  return syncedTeamsHash
}

export const Container = styled.div`
  ${tw`bg-white container mx-auto relative`}

  height: calc(100vh - 12rem);
`

interface TitleComponentProps {
  title: string | undefined
  image: string | undefined
}

export const TitleComponent: React.FC<TitleComponentProps> = ({
  title,
  image,
}) => {
  return (
    <TitleContainer>
      <HRIntegrationTitle>HR Integration</HRIntegrationTitle>
      <div tw="flex flex-row">
        <ServiceIcon>
          <img src={image} tw="object-cover w-9 h-9" alt="" />
        </ServiceIcon>
        <ServiceName>{title}</ServiceName>
      </div>
    </TitleContainer>
  )
}

export const TitleStatusContainer = styled.div`
  &:not(.loading) {
    ${tw`flex flex-col gap-8 box-border px-9 py-8`}
  }

  background: linear-gradient(
    264.22deg,
    rgba(255, 120, 131, 0.05) -4.61%,
    rgba(168, 181, 253, 0.05) 99.81%
  );
`

const TitleContainer = styled.div`
  ${tw`grid`}

  row-gap: 0.5em;
`

const HRIntegrationTitle = styled.h2`
  ${tw`text-lg font-medium`}

  color: #9CA3AF;
`

export const ServiceIcon = styled.div`
  ${tw`border rounded-md box-border overflow-hidden`}

  border-color: #E5E7EB;
`

const ServiceName = styled.div`
  ${tw`pl-3 font-medium`}

  font-size: 1.5rem;
`

interface StatusComponentProps {
  serviceName: string | undefined
  isInitialSync?: boolean | null | undefined
  isSyncing?: boolean | undefined
  isActionRequired?: boolean | undefined
  syncStage?: string | null
}

export const StatusComponent: React.FC<StatusComponentProps> = ({
  serviceName,
  isInitialSync,
  isSyncing,
  syncStage,
  isActionRequired,
}) => {
  const getStatusIcon = () => {
    if (isSyncing) {
      return <SpinningIconStyled tw="text-white w-4 h-4" />
    } else if (isInitialSync) {
      return <ReadyToConnectIcon />
    } else if (isActionRequired) {
      return <AlertIcon />
    } else {
      return <ConnectedIcon />
    }
  }

  const statusContainerClassNames = classNames({
    syncing: isSyncing,
    ready: isInitialSync,
    "relink-needed": isActionRequired,
  })

  const getStatusText = () => {
    if (isSyncing) {
      return `Syncing with ${syncStage === HRIS ? serviceName : "contacts"}`
    } else if (isInitialSync) {
      return "Ready to connect teams"
    } else if (isActionRequired) {
      return "Relink needed"
    } else {
      return `Connected`
    }
  }

  return (
    <div tw="flex flex-row gap-2 items-end">
      <StatusComponentContainer className={statusContainerClassNames}>
        <StatusIconContainer>{getStatusIcon()}</StatusIconContainer>
        <div tw="text-white">{getStatusText()}</div>
      </StatusComponentContainer>
      {isActionRequired && (
        <Tooltip
          trigger={
            <span>
              <TooltipIcon tw="mb-[0.6rem]" />
            </span>
          }
          title="Relink needed"
          placement="auto"
        >
          <p>
            Please relink your {serviceName} integration to keep your HR
            contacts up to date.
          </p>
        </Tooltip>
      )}
    </div>
  )
}

const StatusComponentContainer = styled.div`
  ${tw`flex flex-row md:self-end items-center justify-center mb-2 py-0.5 px-3 gap-2 rounded-full w-max`}

  background: linear-gradient(245.33deg, #30D576 -36.43%, #27AE60 102.76%);

  &.ready {
    background: linear-gradient(195.52deg, #f2994a 4.83%, #e28e43 89.13%);
  }

  &.syncing {
    background: linear-gradient(195.52deg, #69aeff 4.83%, #51a1ff 89.13%);
  }

  &.relink-needed {
    background: linear-gradient(195.52deg, #f2994a 4.83%, #e28e43 89.13%),
      #c4c4c4;
  }
`

const StatusIconContainer = styled.div`
  ${tw`object-contain`}
`

export const SpinningIconStyled = styled(SpinningIcon)`
  ${tw`animate-spin z-10 text-white`}
`
