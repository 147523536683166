import { useCustomStore } from "@/store/custom/hooks"
import { MultipleSelectButton } from "@/store/custom/MultipleGiftSelection/MultipleSelectButton/MultipleSelectButton"
import { MultipleSelectDisableButton } from "@/store/custom/MultipleGiftSelection/MultipleSelectDisableButton"
import { MultipleSelectTooltip } from "@/store/custom/MultipleGiftSelection/MultipleSelectTooltip"
import { MultipleSelectModeEnum } from "@/types/graphql-types"

interface Props {
  multipleSelectModeToDisplay: MultipleSelectModeEnum
}
export const MultipleSelectActions = ({
  multipleSelectModeToDisplay,
}: Props) => {
  const { setMultipleSelectMode } = useCustomStore()

  const displayMaxPriceForm = () => {
    setMultipleSelectMode(MultipleSelectModeEnum.max_price)
  }

  const displayItemCountForm = () => {
    setMultipleSelectMode(MultipleSelectModeEnum.item_count)
  }

  return (
    <div tw="flex flex-row">
      <MultipleSelectButton
        onClick={
          multipleSelectModeToDisplay === MultipleSelectModeEnum.max_price
            ? displayMaxPriceForm
            : displayItemCountForm
        }
        label={
          multipleSelectModeToDisplay === MultipleSelectModeEnum.max_price
            ? "Or set a maximum price"
            : "Or set number of gifts"
        }
      />
      <MultipleSelectTooltip multipleSelectMode={multipleSelectModeToDisplay} />
      <div tw="ml-3" />
      <MultipleSelectDisableButton />
    </div>
  )
}
