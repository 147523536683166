import { useState } from "react"
import tw from "twin.macro"

import Image from "@/common/Image"
import chevronDown from "@/common/images/chevron-down-gray-350.svg"
import { PaymentMethod } from "@/common/PaymentMethodsManager"
import { cardImage } from "@/common/payments/PaymentMethod"

interface Props {
  paymentMethods: PaymentMethod[]
  selectedPaymentMethod: PaymentMethod
  onSelect: (paymentMethod: PaymentMethod) => void
}

const PaymentMethodLabel = ({
  paymentMethod,
}: {
  paymentMethod: PaymentMethod
}) => (
  <button tw="flex gap-1 tabular-nums">
    <Image tw="h-5" src={cardImage(paymentMethod.cardBrand)} />{" "}
    {paymentMethod.last4}
  </button>
)

const PaymentMethodDropdown = ({
  paymentMethods,
  selectedPaymentMethod,
  onSelect,
}: Props) => {
  const [open, setOpen] = useState<boolean>(false)
  const openable = paymentMethods.length > 1
  const optionVisibility = open && openable ? undefined : tw`hidden`
  const wrapperBorderBottom =
    open && openable ? tw`rounded-b-none border-b-0` : undefined
  const chevronTransform = open ? tw`rotate-180` : undefined
  const selectablePaymentMethods = paymentMethods.filter(
    (paymentMethod) => paymentMethod.id !== selectedPaymentMethod.id,
  )

  return (
    <button tw="relative max-h-[30px]">
      <div
        css={[
          tw`bg-white rounded border border-gray-200 px-2 py-[5px] flex gap-2 hover:bg-gray-50`,
          wrapperBorderBottom,
        ]}
        onClick={() => setOpen(!open)}
      >
        <PaymentMethodLabel paymentMethod={selectedPaymentMethod} />
        {openable && (
          <Image
            css={[tw`transition-transform duration-100`, chevronTransform]}
            src={chevronDown}
          />
        )}
      </div>
      <div
        css={[
          tw`flex flex-col bg-white rounded rounded-t-none border border-gray-200`,
          optionVisibility,
        ]}
      >
        {selectablePaymentMethods.map((paymentMethod) => (
          <div
            tw="border-b border-gray-200 px-2 py-[5px] hover:bg-gray-50 last:border-b-0"
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              onSelect(paymentMethod)
            }}
          >
            <PaymentMethodLabel paymentMethod={paymentMethod} />
          </div>
        ))}
      </div>
    </button>
  )
}

export default PaymentMethodDropdown
