import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react"
import tw, { styled } from "twin.macro"

import CountryButton from "./GiftOptionCountryButton"
import GiftOptionGlobalDropdown from "./GiftOptionGlobalDropdown"
import { InternationalProPlanMessageSmall } from "./InternationalProPlanMessage"
import { Countries, CountriesLongName, Flag } from "./InternationalShipping"

import PlaneIconSvg from "@/assets/icons/flags/plane.svg"
import TruckIconSvg from "@/assets/icons/flags/truck.svg"
import FilterButton from "@/store/filters/FilterButton"
import { useCountry } from "@/store/GiftOptionCountryProvider"

interface Props {
  shippingCountries: String[]
  isGiftCard: boolean
}

const GiftOptionInternationalShipping: FC<Props> = ({
  shippingCountries,
  isGiftCard,
  ...props
}) => {
  const {
    giftOptionSelectedShippingCountry,
    setGiftOptionSelectedShippingCountry,
  } = useCountry()
  const [popoverOpen, setPopoverOpen] = useState(false)

  let selectedShippingCountryName =
    CountriesLongName[giftOptionSelectedShippingCountry]
  if (selectedShippingCountryName === "United States") {
    selectedShippingCountryName = "USA"
  }

  return (
    <div tw="flex items-start" {...props}>
      <FilterButton
        onClick={() => setPopoverOpen(!popoverOpen)}
        filterIsActive={false}
        filterDropdownIsOpen={popoverOpen}
        hideChevron={isGiftCard}
        disableClick={isGiftCard}
      >
        <Flag country={giftOptionSelectedShippingCountry} size="m" />
        <span tw="hidden lg:block">{selectedShippingCountryName}</span>
      </FilterButton>
      <ClosePopover
        onClick={() => setPopoverOpen(false)}
        isOpen={popoverOpen}
      />
      <GiftOptionInternationalShippingPopover
        giftOptionSelectedShippingCountry={giftOptionSelectedShippingCountry}
        setGiftOptionSelectedShippingCountry={
          setGiftOptionSelectedShippingCountry
        }
        popoverOpen={popoverOpen}
        setPopoverOpen={setPopoverOpen}
        shippingCountries={shippingCountries}
      />
    </div>
  )
}

interface GiftOptionInternationalShippingPopoverProps {
  giftOptionSelectedShippingCountry: Countries
  setGiftOptionSelectedShippingCountry: Dispatch<SetStateAction<Countries>>
  popoverOpen: boolean
  setPopoverOpen: Dispatch<SetStateAction<boolean>>
  shippingCountries: String[]
}

const GiftOptionInternationalShippingPopover = ({
  giftOptionSelectedShippingCountry,
  setGiftOptionSelectedShippingCountry,
  popoverOpen,
  setPopoverOpen,
  shippingCountries,
}: GiftOptionInternationalShippingPopoverProps) => {
  const [display, setDisplay] = useState(false)
  const contentRef = useRef() as React.MutableRefObject<HTMLDivElement>

  useEffect(() => {
    if (!popoverOpen) {
      contentRef.current.classList.add("scale-fade-out")
      setTimeout(() => setDisplay(popoverOpen), 200)
    } else {
      setDisplay(popoverOpen)
    }
  }, [popoverOpen])

  const onClick = (country: Countries) => {
    setPopoverOpen(false)
    setGiftOptionSelectedShippingCountry(country)
  }

  return (
    <GiftOptionInternationalShippingPopoverContainer
      $display={display}
      ref={contentRef}
    >
      <InternationalProPlanMessageSmall visible={display} />
      <div tw="p-4">
        {shippingCountries.includes(Countries.UNITED_STATES) && (
          <div tw="pb-2">
            <div tw="flex items-center py-1">
              <ShippingIcon src={TruckIconSvg} />
              <h4 tw="pl-2 text-primary-800 uppercase text-sm font-medium font-text">
                US shipping
              </h4>
            </div>
            <CountryButton
              country={Countries.UNITED_STATES}
              giftOptionSelectedShippingCountry={
                giftOptionSelectedShippingCountry
              }
              onClick={() => onClick(Countries.UNITED_STATES)}
            />
          </div>
        )}

        {shippingCountries.length > 0 &&
          shippingCountries.filter((el) => el !== Countries.UNITED_STATES)
            .length > 0 && (
            <>
              <div tw="flex items-center py-1 pt-3">
                <ShippingIcon src={PlaneIconSvg} />
                <h4 tw="pl-2 text-primary-800 uppercase text-sm font-medium font-text">
                  International
                </h4>
              </div>
              <GiftOptionGlobalDropdown
                giftOptionSelectedShippingCountry={
                  giftOptionSelectedShippingCountry
                }
                shippingCountries={shippingCountries}
                onClick={onClick}
              />
              <p tw="text-sm text-gray-500 px-1 pt-5">
                An additional fee applies to international shipments for
                shipping, tax, and tariffs. Goody caps this at a maximum of $50
                per international recipient.
              </p>
            </>
          )}
      </div>
    </GiftOptionInternationalShippingPopoverContainer>
  )
}

const GiftOptionInternationalShippingPopoverContainer = styled.div<{
  $display: boolean
}>`
  ${tw`absolute hidden bg-white rounded-xl border border-gray-200 origin-top-right transition-colors z-[150]`}
  top: 4.5rem;
  ${tw`right-0 lg:right-[5.75rem] max-w-[500px] top-[5rem]`}

  animation: scaleFadeIn 200ms;
  box-shadow:
    0px 1px 4px rgba(0, 0, 0, 0.05),
    0px 4px 32px rgba(0, 0, 0, 0.06);
  @keyframes scaleFadeIn {
    0% {
      transform: scale(0.5);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 100;
    }
  }
  &.scale-fade-out {
    animation: scaleFadeOut 400ms;
    @keyframes scaleFadeOut {
      0% {
        transform: scale(1);
        opacity: 100;
      }
      50% {
        transform: scale(0.5);
        opacity: 0;
      }
      100% {
        transform: scale(0.5);
        opacity: 0;
      }
    }
  }
  &:before {
    ${tw`absolute top-0 bg-white border-gray-200`}
    content: "";
    top: -1px;
    right: 4%;
    transform: translate(-50%, -50%) rotate(45deg);
    border-width: 1px 0 0 1px;
    height: 16px;
    width: 16px;
  }
  ${({ $display }) => $display && tw`block`}
`

const ShippingIcon = styled.img`
  border-radius: 50%;
  box-shadow:
    0px 4px 4px rgba(0, 0, 0, 0.02),
    0px 1px 2px rgba(0, 0, 0, 0.04);
`

const ClosePopover = styled.div<{ isOpen: boolean }>`
  ${tw`fixed top-0 bottom-0 right-0 left-0 z-0`}
  height: 100vh;
  width: 100vw;
  display: ${({ isOpen }) => (isOpen ? `block` : `none`)};
`

export default GiftOptionInternationalShipping
