import { AddBalanceModalProvider } from "@/common/hooks/addBalanceModal"
import { BusinessSendProvider } from "@/common/hooks/send"
import { GiftPreviewProvider } from "@/send/hooks/giftPreview"
import { TopUpExperimentProvider } from "@/send/hooks/topUpExperiment"
import { TopUpUIProvider } from "@/send/hooks/topUpUI"
import ShippingCountriesProvider from "@/store/providers/ShippingCountriesProvider"

interface Props {
  children: React.ReactNode
}

export const SendBusinessProviders = ({ children }: Props) => (
  <BusinessSendProvider>
    <TopUpUIProvider>
      <TopUpExperimentProvider>
        <GiftPreviewProvider>
          <ShippingCountriesProvider>
            <AddBalanceModalProvider>{children}</AddBalanceModalProvider>
          </ShippingCountriesProvider>
        </GiftPreviewProvider>
      </TopUpExperimentProvider>
    </TopUpUIProvider>
  </BusinessSendProvider>
)
