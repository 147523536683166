import { gtmEvent } from "../../../common/gtm"
import { getRealmFromPath } from "../../../common/realm"

interface IgtmEventForEditorialItem {
  editorialItem: { id: string; slug: string }
  extra?: Object
}

export const gtmEventForEditorialItem = (
  event: string,
  { editorialItem, extra }: IgtmEventForEditorialItem,
) => {
  const payload = {
    editorial_item_id: editorialItem.id,
    editorial_item_slug: editorialItem.slug,
    current_realm: getRealmFromPath(window.location.pathname),
    ...(extra || {}),
  }

  gtmEvent(event, payload)
}
