import { gql, useMutation } from "@apollo/client"
import { formatRelative } from "date-fns"
import { enUS } from "date-fns/esm/locale"
import { isEmpty, isEqual } from "lodash-es"
import React, { RefObject, useEffect, useRef, useState } from "react"
import { toast } from "react-hot-toast"
import tw, { styled } from "twin.macro"

import ConnectHRButton from "./ConnectHRButton"
import ConnectRipplingButton from "./ConnectRipplingButton"
import HRISWorkspacesSidebar from "./HRISWorkspacesSidebar"
import { ReactComponent as CaretIcon } from "./images/hr-integration/caret.svg"
import ToggleOffPng from "./images/hr-integration/toggle-off.png"
import ToggleOnPng from "./images/hr-integration/toggle-on.png"
import { CUSTOMER_INTEGRATION_SYNC_MUTATION } from "./SyncAndSaveComponent"
import SyncAndSaveComponent from "./SyncAndSaveComponent"
import {
  ALL_EMPLOYEES,
  Container,
  HRIS,
  RIPPLING,
  StatusComponent,
  TitleComponent,
  TitleStatusContainer,
  convertWorkspaceConfigurationsToInput,
  getHRISTeamsConfigurationList,
  isAllEmployeesSelected,
} from "../../common/hris"

import {
  Contacts_CustomerIntegrationDisconnectMutation,
  Contacts_CustomerIntegrationDisconnectMutationVariables,
  Contacts_CustomerIntegrationSyncMutation,
  Contacts_CustomerIntegrationSyncMutationVariables,
  CustomerIntegrationHrisTeamInput,
  CustomerIntegrationQuery,
  CustomerIntegrationStatus,
  CustomerIntegrationSyncInfo,
  CustomerIntegrationWorkspaceConfigurationInput,
} from "@/types/graphql-types"

interface HRISConfigurationProps {
  integration: NonNullable<
    CustomerIntegrationQuery["me"]
  >["customerIntegration"]
  refetchIntegration: () => void
  isSyncing: boolean
  setIsSyncing: (syncing: boolean) => void
  isConnected: boolean
  setIsConnected: (connected: boolean) => void
}

const HRISConfiguration = ({
  integration,
  refetchIntegration,
  isSyncing,
  setIsSyncing,
  isConnected,
  setIsConnected,
}: HRISConfigurationProps) => {
  const [isActionRequired, setIsActionRequired] = useState(false)

  // Sync stage gives the user visibility on if we are syncing with the HRIS
  // or linking with Goody contacts and contact lists
  const [currentSyncStage, setCurrentSyncStage] = useState<string | null>(null)

  // Workspace selected in the sidebar for configuration
  const [selectedWorkspace, setSelectedWorkspace] =
    useState<SelectedWorkspaceProps>({
      id: null,
      name: null,
    })

  // Used to display "Save and sync" button
  const [configChanged, setConfigChanged] = useState(false)

  // integration?.workspaceConfigurations is an array of Goody workspace
  // HRIS configurations
  //
  // workspaceConfigs is the object we use to toggle different sync settings
  // for each workspace.
  // workspaceConfigs is passed in the sync mutation.
  const [workspaceConfigs, setWorkspaceConfigs] = useState<
    CustomerIntegrationWorkspaceConfigurationInput[] | null | undefined
  >(convertWorkspaceConfigurationsToInput(integration?.workspaceConfigurations))

  const { status, syncInfo } = integration ?? {}
  const { isInitialSync } = syncInfo ?? {}
  const { name: serviceName, squareImage: serviceSquareImage } =
    integration?.serviceInfo ?? {}

  const hrisTeamsListRef = useRef(null)

  useEffect(() => {
    switch (status) {
      case CustomerIntegrationStatus.ACTIVE:
        setIsSyncing(false)
        setIsActionRequired(false)
        return
      case CustomerIntegrationStatus.SYNCING:
        setIsSyncing(true)
        setIsActionRequired(false)
        return
      case CustomerIntegrationStatus.ACTION_REQUIRED:
        setIsActionRequired(true)
        return
    }
  }, [status])

  // Sync stage gives the user visibility on if we are syncing with the HRIS
  // or linking with Goody contacts and contact lists
  useEffect(() => {
    const stage = integration?.syncStage
    if (isSyncing && stage) {
      setCurrentSyncStage(stage)
    }
  }, [isSyncing, integration?.syncStage])

  useEffect(() => {
    if (!isConnected) {
      // When disconnecting, toggle all the configs off, so if reconnecting
      // in the same session, the cached values are up to date
      const allWorkspacesToggledOffConfig = workspaceConfigs?.map(
        (workspace) => {
          const hrisTeams = workspace.hrisTeams.map((hrisTeam) => {
            return { ...hrisTeam, isSynced: false }
          })

          return { ...workspace, hrisTeams: hrisTeams }
        },
      )

      setWorkspaceConfigs(allWorkspacesToggledOffConfig)
    }
  }, [isConnected])

  // Set workspaceConfigs when integration.workspaceConfigurations changes
  useEffect(() => {
    const fetchedWorkspaceConfigs = convertWorkspaceConfigurationsToInput(
      integration?.workspaceConfigurations,
    )

    if (!fetchedWorkspaceConfigs) {
      return
    }

    // If the teams change (ex: adding a new team), or any HRIS employee/team details
    // change, we want to display these changes while preserving any changes to the
    // current config.
    const mergedWorkspaceConfigs = fetchedWorkspaceConfigs.map(
      (fetchedWorkspaceConfig) => {
        const currentWorkspaceConfig = workspaceConfigs?.find(
          (t) => t.id === fetchedWorkspaceConfig.id,
        )
        if (
          currentWorkspaceConfig &&
          !!currentWorkspaceConfig.hrisTeams &&
          !isEmpty(currentWorkspaceConfig.hrisTeams)
        ) {
          const mergedHrisTeams = fetchedWorkspaceConfig.hrisTeams.map(
            (fetchedHrisTeam) => {
              const currentHrisTeam = currentWorkspaceConfig.hrisTeams.find(
                (t) => {
                  if (!!t.hrisId) {
                    return t.hrisId === fetchedHrisTeam.hrisId
                  } else {
                    return isEqual(
                      t.nameHierarchy,
                      fetchedHrisTeam.nameHierarchy,
                    )
                  }
                },
              )

              if (currentHrisTeam) {
                return {
                  ...currentHrisTeam,
                  isSynced: currentHrisTeam.isSynced,
                  numEmployees: fetchedHrisTeam.numEmployees,
                  nameHierarchy: fetchedHrisTeam.nameHierarchy,
                }
              } else {
                return fetchedHrisTeam
              }
            },
          )

          return {
            ...fetchedWorkspaceConfig,
            hrisTeams: mergedHrisTeams,
          }
        } else {
          return fetchedWorkspaceConfig
        }
      },
    )

    setWorkspaceConfigs(mergedWorkspaceConfigs)
  }, [integration?.workspaceConfigurations])

  // Sets the HRIS team configuration for the selected team in workspaceConfigs
  // And sets configChanged to true
  const setHrisTeamConfigurationForSelectedWorkspace = (
    hrisTeamInput: CustomerIntegrationHrisTeamInput[],
  ) => {
    if (!workspaceConfigs) {
      return
    }

    const newWorkspaceConfig = workspaceConfigs.map((workspace) => {
      return workspace.id === selectedWorkspace.id
        ? { ...workspace, hrisTeams: hrisTeamInput }
        : workspace
    })

    setWorkspaceConfigs(newWorkspaceConfig)
    setConfigChanged(true)
  }

  const hrisTeamConfigForSelectedWorkspace = getHRISTeamsConfigurationList(
    selectedWorkspace.id,
    workspaceConfigs,
  )

  const onWorkspaceSelect = (workspaceProps: SelectedWorkspaceProps) => {
    setSelectedWorkspace(workspaceProps)
    scrollToTop(hrisTeamsListRef)
  }

  return (
    <>
      <Container>
        <div tw="h-full overflow-auto">
          <div tw="overflow-auto relative h-full">
            <TitleStatusContainer>
              <div tw="flex flex-col gap-y-6 xl:gap-y-0 xl:flex-row justify-between">
                <div tw="flex flex-col md:flex-row gap-7">
                  <TitleComponent
                    title={serviceName}
                    image={serviceSquareImage}
                  />
                  <StatusComponent
                    serviceName={serviceName}
                    isInitialSync={isInitialSync}
                    isSyncing={isSyncing}
                    syncStage={currentSyncStage}
                    isActionRequired={isActionRequired}
                  />
                </div>
                <div tw="flex flex-col xl:flex-row justify-between xl:items-center">
                  {!!syncInfo && (
                    <IntegrationSyncDetails
                      syncInfo={syncInfo}
                      syncing={isSyncing}
                      refetchIntegration={refetchIntegration}
                      isActionRequired={isActionRequired}
                      workspaceConfigs={workspaceConfigs}
                      setSyncing={setIsSyncing}
                      setIsConnected={setIsConnected}
                      setSyncStage={setCurrentSyncStage}
                      serviceName={serviceName}
                    />
                  )}
                </div>
              </div>
            </TitleStatusContainer>
            <IntegrationSyncContainer>
              {!!workspaceConfigs && !!serviceName && (
                <HRISWorkspacesSidebar
                  serviceName={serviceName}
                  workspaceConfigs={workspaceConfigs}
                  selectedWorkspace={selectedWorkspace}
                  onWorkspaceSelect={onWorkspaceSelect}
                />
              )}
              {!!hrisTeamConfigForSelectedWorkspace && (
                <HRISTeamsList ref={hrisTeamsListRef}>
                  <SyncConfigurationComponent
                    selectedWorkspace={selectedWorkspace}
                    teams={hrisTeamConfigForSelectedWorkspace}
                    setTeams={setHrisTeamConfigurationForSelectedWorkspace}
                    serviceName={serviceName}
                    isSyncing={isSyncing}
                  />
                </HRISTeamsList>
              )}
            </IntegrationSyncContainer>
          </div>
        </div>
        <HRISFooter />
      </Container>
      <SyncAndSaveComponent
        workspaceConfigs={workspaceConfigs}
        configChanged={configChanged}
        setConfigChanged={setConfigChanged}
        isUnavailable={isSyncing || isActionRequired}
        setSyncing={setIsSyncing}
        setSyncStage={setCurrentSyncStage}
      />
    </>
  )
}

const scrollToTop = (hrisTeamsListRef: RefObject<HTMLDivElement>) => {
  const hrisTeamsListEl = hrisTeamsListRef.current as HTMLDivElement | null

  if (hrisTeamsListEl) {
    hrisTeamsListEl.scrollTop = 0
  }
}

const IntegrationSyncContainer = styled.div`
  ${tw`flex flex-row box-border w-full`}
  gap: 3rem;

  @media (min-width: 1024px) {
    height: calc(100% - 10rem);
    gap: 7.75rem;
  }
`

const HRISTeamsList = styled.div`
  ${tw`overflow-y-scroll w-full pt-12 w-4/5 pr-16`}
`

const teamName = (team: CustomerIntegrationHrisTeamInput) => {
  return !isEmpty(team.nameHierarchy) ? team.nameHierarchy[0] : "Unnamed"
}

export interface SelectedWorkspaceProps {
  id: string | null
  name: string | null
}

interface SyncComponentProps {
  selectedWorkspace: SelectedWorkspaceProps
  teams: CustomerIntegrationHrisTeamInput[]
  setTeams: (teams: CustomerIntegrationHrisTeamInput[]) => void
  serviceName?: string
  isSyncing: boolean
}

const SyncConfigurationComponent: React.FC<SyncComponentProps> = ({
  selectedWorkspace,
  teams,
  setTeams,
  serviceName,
  isSyncing,
}) => {
  // Used to toggle the All employees switch
  const [syncAllTeams, setSyncAllTeams] = useState(
    isAllEmployeesSelected(teams),
  )

  useEffect(() => {
    setSyncAllTeams(isAllEmployeesSelected(teams))
  }, [teams])

  const toggleAllTeams = () => {
    const newTeams = teams.map((team) => {
      return { ...team, isSynced: !syncAllTeams }
    })

    setTeams(newTeams)
  }

  const numAllEmployees = teams?.find((t) => teamName(t) === ALL_EMPLOYEES)
    ?.numEmployees

  return (
    <>
      <div tw="flex flex-col gap-y-6">
        <h2 tw="text-2xl font-medium">{selectedWorkspace.name}</h2>
        {!isEmpty(teams) ? (
          <TeamsList>
            <button
              disabled={isSyncing}
              onClick={() => toggleAllTeams()}
              tw="flex box-border p-4 justify-between hover:bg-gray-050 active:bg-gray-100"
            >
              <TeamSyncSwitch>
                <ToggleImg
                  src={syncAllTeams ? ToggleOnPng : ToggleOffPng}
                  alt="Toggle"
                />
                All employees
              </TeamSyncSwitch>
              <div className="numEmployees">{`${numAllEmployees} employees`}</div>
            </button>
          </TeamsList>
        ) : (
          <>No teams found. Please add teams in {serviceName}.</>
        )}
      </div>
      <TeamsComponent teams={teams} setTeams={setTeams} isSyncing={isSyncing} />
    </>
  )
}

interface TeamsComponentProps {
  teams: CustomerIntegrationHrisTeamInput[]
  setTeams: (teams: CustomerIntegrationHrisTeamInput[]) => void
  isSyncing: boolean
}

const TeamsComponent: React.FC<TeamsComponentProps> = ({
  teams,
  setTeams,
  isSyncing,
}) => {
  const setTeamConfiguration = (
    modifiedTeam: CustomerIntegrationHrisTeamInput,
  ) => {
    const isSynced = !modifiedTeam.isSynced

    const newTeams = teams.map((team) => {
      return team === modifiedTeam ? { ...team, isSynced: isSynced } : team
    })

    // Set All employees as synced in the team config
    // if toggling modifiedTeam makes all teams selected
    const isSyncAll = isAllEmployeesSelected(newTeams)

    setTeams(
      newTeams.map((team) => {
        return teamName(team) === ALL_EMPLOYEES
          ? { ...team, isSynced: isSyncAll }
          : team
      }),
    )
  }

  if (teams.length === 0) {
    return null
  }

  return (
    <div tw="pt-5 pb-48">
      <TeamsList tw="">
        {teams
          .filter((t) => teamName(t) !== ALL_EMPLOYEES)
          .map((team) => {
            return (
              <button
                disabled={isSyncing}
                key={team.hrisId ?? teamName(team)}
                onClick={() => setTeamConfiguration(team)}
                tw="flex box-border p-4 justify-between border-b hover:bg-gray-050 active:bg-gray-100"
              >
                <TeamSyncSwitch>
                  <ToggleImg
                    src={team.isSynced ? ToggleOnPng : ToggleOffPng}
                    alt="Toggle off"
                  />
                  {teamNameDisplay(team)}
                </TeamSyncSwitch>
                <div className="numEmployees">{`${team.numEmployees} employees`}</div>
              </button>
            )
          })}
      </TeamsList>
    </div>
  )
}

const teamNameDisplay = (team: CustomerIntegrationHrisTeamInput) => {
  return (
    <TeamNameDisplay>
      {!isEmpty(team.nameHierarchy) ? (
        team.nameHierarchy.map((name, idx) => {
          return (
            <React.Fragment key={idx}>
              <div className={name === "Unassigned" ? "unassigned" : ""}>
                {name}
              </div>
              <CaretIcon />
            </React.Fragment>
          )
        })
      ) : (
        <React.Fragment>
          <div className="unassigned">Unnamed</div>
          <CaretIcon />
        </React.Fragment>
      )}
    </TeamNameDisplay>
  )
}

const ToggleImg = styled.img`
  ${tw`object-contain w-7`}

  padding-top: 2px;
`

const TeamNameDisplay = styled.div`
  ${tw`flex gap-2 items-center text-gray-500`}

  div:last-of-type {
    ${tw`text-black`}

    &.unassigned {
      ${tw`text-gray-500`}
    }
  }

  svg:last-of-type {
    ${tw`hidden`}
  }
`

const TeamsList = styled.div`
  ${tw`border box-border rounded-lg flex flex-col`}

  border-color: #E5E7EB;

  div.numEmployees {
    ${tw`text-sm`}
    color: #9ca3af;
  }
`

const TeamSyncSwitch = styled.div`
  ${tw`flex gap-4`}
`
interface IntegrationSyncDetailsProps {
  syncInfo: CustomerIntegrationSyncInfo
  syncing: boolean
  refetchIntegration: () => void
  isActionRequired: boolean
  workspaceConfigs:
    | CustomerIntegrationWorkspaceConfigurationInput[]
    | null
    | undefined
  setSyncing: (syncing: boolean) => void
  setIsConnected: (connected: boolean) => void
  setSyncStage: (syncStage: string) => void
  serviceName: string | undefined
}

const IntegrationSyncDetails: React.FC<IntegrationSyncDetailsProps> = ({
  syncInfo,
  syncing,
  refetchIntegration,
  isActionRequired,
  workspaceConfigs,
  setSyncing,
  setIsConnected,
  setSyncStage,
  serviceName,
}) => {
  const { isInitialSync, lastSyncedAt, nextSyncAt } = syncInfo

  const [disconnectIntegration] = useMutation<
    Contacts_CustomerIntegrationDisconnectMutation,
    Contacts_CustomerIntegrationDisconnectMutationVariables
  >(CUSTOMER_INTEGRATION_DISCONNECT_MUTATION)

  const [resyncIntegration] = useMutation<
    Contacts_CustomerIntegrationSyncMutation,
    Contacts_CustomerIntegrationSyncMutationVariables
  >(CUSTOMER_INTEGRATION_SYNC_MUTATION)

  const resync = async () => {
    setSyncing(true)
    setSyncStage(HRIS)

    const res = await resyncIntegration({
      variables: {
        workspaceConfigs: workspaceConfigs,
        resyncOnly: true,
      },
    })

    if (res.data?.customerIntegrationSync.ok) {
      toast.success("Resync started. The Contacts page shows synced contacts.")
    } else {
      toast.error("Could not sync")
    }
  }

  const disconnect = async () => {
    if (
      window.confirm(
        "Disconnecting the HR integration will remove all contacts and teams that were synced with this HR integration. Are you sure you want to continue?",
      )
    ) {
      setIsConnected(false)

      const res = await disconnectIntegration()

      if (res.data?.customerIntegrationDisconnect.ok) {
        toast.success("HRIS disconnect initiated") //TODO: Change these messages
      } else {
        toast.error("Could not disconnect HRIS")
      }
    }
  }

  function relativeDate(syncDate: string) {
    const formatRelativeLocale: { [token: string]: string } = {
      lastWeek: "'Last' eeee',' p",
      yesterday: "'Yesterday,' p",
      today: "'Today,' p",
      tomorrow: "'Tomorrow,' p",
      nextWeek: "eeee',' p",
      other: "eeee',' p",
    }

    const locale = {
      ...enUS,
      formatRelative: (token: string) => formatRelativeLocale[token],
    }

    return formatRelative(Date.parse(syncDate), new Date(), { locale })
  }

  return (
    <div tw="pr-9 gap-x-12 flex flex-row">
      <SyncStatus>
        {!syncing && (
          <>
            <SyncStatusTitle>Last synced</SyncStatusTitle>
            {isInitialSync ? "Never" : relativeDate(lastSyncedAt)}
          </>
        )}
      </SyncStatus>
      {!isInitialSync && (
        <SyncStatus>
          <SyncStatusTitle>Next sync</SyncStatusTitle>
          {syncing ? "Syncing now" : relativeDate(nextSyncAt)}
        </SyncStatus>
      )}
      <div tw="flex flex-row gap-x-6">
        {!isInitialSync && !isActionRequired && (
          <SyncControlButton disabled={syncing} onClick={() => resync()}>
            {syncing ? "Syncing…" : "Resync now"}
          </SyncControlButton>
        )}
        <SyncControlButton disabled={syncing} onClick={() => disconnect()}>
          Disconnect
        </SyncControlButton>
        {serviceName === RIPPLING ? (
          <ConnectRipplingButton
            relink={true}
            className="relink"
            onIntegrationConnect={refetchIntegration}
          />
        ) : (
          <ConnectHRButton
            relink={true}
            className="relink"
            onIntegrationConnect={refetchIntegration}
          />
        )}
      </div>
    </div>
  )
}

const SyncStatus = styled.div`
  ${tw`flex flex-col text-sm pr-8`}
`

const SyncStatusTitle = styled.div`
  ${tw`font-medium`}

  color: #9CA3AF;
`

const SyncControlButton = styled.button`
  ${tw`bg-white border border-primary-100 box-border rounded-lg text-gray-500 mb-2 px-4 hover:border-primary-200 active:border-primary-300 w-max`}

  height: min-content;
  padding-top: 0.5625rem;
  padding-bottom: 0.5625rem;
  box-shadow: 0px 2px 4px rgba(228, 216, 244, 0.3);

  &:disabled {
    ${tw`opacity-50 cursor-auto shadow-none`}
  }
`

const HRISFooter = styled.footer`
  ${tw`absolute bottom-0 w-full flex justify-center lg:pb-6`}
  background: linear-gradient(0deg, rgba(255, 255, 255, 100%) 0%, rgba(255, 255, 255, 0%) 100%);
`

const CUSTOMER_INTEGRATION_DISCONNECT_MUTATION = gql`
  mutation Contacts_CustomerIntegrationDisconnect {
    customerIntegrationDisconnect {
      ok
    }
  }
`

export default HRISConfiguration
