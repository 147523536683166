import React from "react"
import { Helmet } from "react-helmet-async"

import { useScreen } from "../../common/analytics"

import LP2 from "@/landing/business/LP2"

const Business = () => {
  useScreen("Business Marketing - Home")

  const WEBSITE_STRUCTURED_DATA = `
    {
      "@context": "https://schema.org",
      "@type": "WebSite",
      "name": "Goody",
      "url": "https://www.ongoody.com/"
    }
  `

  return (
    <div tw="overflow-x-hidden">
      <Helmet>
        <title>Goody | Send the Perfect Business Gift. No Address Needed</title>
        <meta
          name="title"
          property="og:title"
          content="Goody | Send the Perfect Business Gift. No Address Needed"
        />
        <meta
          name="description"
          property="og:description"
          content="Goody is a corporate gifting platform for unique employee, client and sales prospecting gifts. Free to sign up and start gifting."
        />
        <link rel="canonical" href={`https://www.ongoody.com/`} />
        <script type="application/ld+json">{WEBSITE_STRUCTURED_DATA}</script>
      </Helmet>
      <LP2 />
    </div>
  )
}

export default Business
