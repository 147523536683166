import { useEffect } from "react"
import { Link } from "react-router-dom"
import tw, { styled } from "twin.macro"

import HeaderDesktopImage from "./images/DesktopBackground.png"
import HeaderImage from "./images/Header.png"
import HeaderMobileImage from "./images/MobileBackground.png"
import { HOLIDAY_GUIDES, usePreloadHoliday } from "../holiday"

import { generateRealmPath } from "@/common/realm"

const HolidayHeader = () => {
  const { preloadAllEditorials } = usePreloadHoliday()

  useEffect(() => {
    preloadAllEditorials()
  }, [])

  return (
    <Container>
      <Link to={generateRealmPath(null, "/browse/holiday-gifts")}>
        <img src={HeaderImage} tw="max-w-[28rem] mx-auto w-4/5 pt-8" />
      </Link>
      <div tw="flex-row flex-wrap flex justify-center pb-8 px-2">
        {Object.entries(HOLIDAY_GUIDES).map(([slug, title]) => (
          <StyledLink
            selected={window.location.pathname === `/editorial/${slug}`}
            to={`/editorial/${slug}`}
          >
            {title}
          </StyledLink>
        ))}
      </div>
    </Container>
  )
}

const Container = styled.div`
  ${tw`relative bg-cover`}
  background-image: url(${HeaderDesktopImage});

  @media screen and (max-width: 768px) {
    background-image: url(${HeaderMobileImage});
  }
`

const StyledLink = styled(Link)<{ selected: boolean }>`
  ${tw`w-[10rem] sm:w-auto text-center whitespace-nowrap rounded-[3rem] px-7 py-2 m-1 text-sm sm:text-base  xl:py-3 xl:px-8 xl:text-xl hover:bg-white active:text-[#447FB5]`}

  ${({ selected }) => selected && tw`bg-white text-[#447FB5]`}
`

export default HolidayHeader
