import { useEffect } from "react"
import tw, { styled } from "twin.macro"

import { RoundedHyphenIcon, RoundedPlusSignIcon } from "./assets/icons"

import { SettingInfo } from "@/common/settings/SettingInfo"
import { useCustomStore } from "@/store/custom/hooks"
import { MultipleSelectActions } from "@/store/custom/MultipleGiftSelection/MultipleSelectActions"
import { MultipleSelectModeEnum } from "@/types/graphql-types"

export const ItemCountForm = () => {
  const {
    multipleSelectMode,
    setMultipleSelectMode,
    multipleSelectCount,
    setMultipleSelectCount,
  } = useCustomStore()

  const MIN_ITEM_COUNT = 1
  const MAX_ITEM_COUNT = 10

  const decrementButtonDisabled = multipleSelectCount === MIN_ITEM_COUNT
  const incrementButtonDisabled = multipleSelectCount === MAX_ITEM_COUNT

  const decrementItemCount = () => {
    if (multipleSelectCount && multipleSelectCount > 1) {
      setMultipleSelectCount(multipleSelectCount - 1)
    }
  }

  const incrementItemCount = () => {
    if (multipleSelectCount && multipleSelectCount < MAX_ITEM_COUNT) {
      setMultipleSelectCount(multipleSelectCount + 1)
    }
  }

  // this updates the multiple select mode when the item counter changes, either setting it to disabled or item_count
  useEffect(() => {
    if (
      multipleSelectCount &&
      multipleSelectCount > 1 &&
      multipleSelectMode === MultipleSelectModeEnum.disabled
    ) {
      setMultipleSelectMode(MultipleSelectModeEnum.item_count)
    } else if (
      multipleSelectCount === 1 &&
      multipleSelectMode === MultipleSelectModeEnum.item_count
    ) {
      setMultipleSelectMode(MultipleSelectModeEnum.disabled)
    }
  }, [multipleSelectMode, multipleSelectCount])

  return (
    <Container>
      <div tw="font-medium mb-3 text-gray-600">
        Number of gifts recipient can select
      </div>
      <div tw="flex flex-row items-center mb-6">
        <CounterButton
          disabled={decrementButtonDisabled}
          onClick={decrementItemCount}
          tw="mr-4"
        >
          <RoundedHyphenIcon />
        </CounterButton>
        <div tw="flex items-center justify-center h-[51px] w-[81px] border border-gray-150 rounded-lg px-[37px] py-[13px] text-xl">
          {multipleSelectCount}
        </div>
        <CounterButton
          disabled={incrementButtonDisabled}
          onClick={incrementItemCount}
          tw="ml-4"
        >
          <RoundedPlusSignIcon />
        </CounterButton>
      </div>
      <MultipleSelectActions
        multipleSelectModeToDisplay={MultipleSelectModeEnum.max_price}
      />
      <SettingInfo useMultipleGiftMessage={true} />
    </Container>
  )
}

const Container = tw.div`flex flex-col w-full items-center`
const CounterButton = styled.button<{
  disabled?: boolean
}>`
  ${tw`flex items-center justify-center h-9 w-9 rounded-full bg-[#F3F4F6]`}
  ${({ disabled }) =>
    disabled ? tw`text-gray-350 cursor-default` : tw`text-primary-new-600`};
`
