import { useQuery } from "@apollo/client"
import { useEffect, useRef } from "react"

import { CATEGORIES_QUERY } from "./useCategories"
import { CategorySet } from "../categories/CategorySetToggle"
import { getFeaturedCategoryKey } from "../StoreHelpers"

import { CategorySegment } from "@/types/graphql-types"
import {
  Store_CategoriesQuery,
  Store_CategoriesQueryVariables,
  Store_CategoryCommonFragment,
  Store_CategoryFragment,
} from "@/types/graphql-types"

interface UseCategoriesDataProps {
  selectedCategorySlug: string | null
}

export default function useCategoriesData({
  selectedCategorySlug,
}: UseCategoriesDataProps) {
  const categoryLookup = useRef<{
    [key: string]: {
      supercategory: Store_CategoryFragment
      subcategory: Store_CategoryCommonFragment | null
      set: CategorySet
    }
  }>({}).current

  // We use skip: true instead of useLazyQuery because useLazyQuery
  // does not return a promise in this version of apollo client
  const { refetch: refetchCategories } = useQuery<
    Store_CategoriesQuery,
    Store_CategoriesQueryVariables
  >(CATEGORIES_QUERY, {
    skip: true,
  })

  const loadCategories = async () => {
    const { data } = await refetchCategories({
      segment: CategorySegment.PLUS,
    })

    if (data.occasionSet) {
      addCategoriesToLookup(data.occasionSet, CategorySet.OCCASIONS)
    }
    if (data.categorySet) {
      addCategoriesToLookup(data.categorySet, CategorySet.CATEGORIES)
    }
  }

  useEffect(() => {
    loadCategories()
  }, [])

  const addCategoriesToLookup = (
    categories: Store_CategoryFragment[],
    set: CategorySet,
  ) => {
    categories.forEach((supercategory) => {
      // We assume no collisions between id and slug
      categoryLookup[supercategory.id] = {
        supercategory,
        set,
        subcategory: null,
      }
      if (supercategory.slug) {
        categoryLookup[supercategory.slug] = {
          supercategory,
          set,
          subcategory: null,
        }
      }
      // Also look up the featured category
      if (supercategory.isFeatured) {
        categoryLookup[getFeaturedCategoryKey("plus")] = {
          supercategory,
          set,
          subcategory: null,
        }
      }

      addSubcategoriesToLookup(supercategory.subcategories, supercategory, set)
    })
  }

  const addSubcategoriesToLookup = (
    subcategories: Store_CategoryCommonFragment[],
    supercategory: Store_CategoryFragment,
    set: CategorySet,
  ) => {
    subcategories.forEach((subcategory) => {
      // We assume no collisions between id and slug
      categoryLookup[subcategory.id] = { subcategory, supercategory, set }
      if (subcategory.slug) {
        categoryLookup[subcategory.slug] = { subcategory, supercategory, set }
      }
    })
  }

  const selectedSupercategory =
    selectedCategorySlug && categoryLookup[selectedCategorySlug]
      ? categoryLookup[selectedCategorySlug].supercategory
      : null

  const selectedSubcategory =
    selectedCategorySlug && categoryLookup[selectedCategorySlug]
      ? categoryLookup[selectedCategorySlug].subcategory
      : null

  // Selected subcategory if exists, if not selected category
  const selectedCategory = selectedSubcategory ?? selectedSupercategory

  return {
    selectedCategory,
    selectedSupercategory,
    selectedSubcategory,
  }
}
