import React from "react"
import { useHistory } from "react-router-dom"
import tw, { styled } from "twin.macro"

import RainbowHeading from "../../../common/RainbowHeading"
import { generateRealmPath } from "../../../common/realm"
import StartButton from "../../../common/StartButton"

const Container = styled.div`
  background: linear-gradient(339.45deg, #cda6ff -30.87%, #8bbde1 101.76%),
    linear-gradient(0deg, #ffffff, #ffffff);

  ${tw`w-full px-5 pt-12 pb-20 lg:p-16`}
`

const DemoButton = styled.a`
  ${tw`bg-white text-sm lg:text-xl rounded-full px-7 py-4 font-medium inline-flex items-center transition-all focus:outline-none relative overflow-hidden z-10`}

  &::before, &::after {
    ${tw`block absolute top-0 left-0 w-full h-full bg-gradient-to-r opacity-0 transition-opacity`}
    content: "";
    z-index: -1;
  }

  &::before {
    ${tw`bg-gray-50`}
  }

  &::after {
    ${tw`bg-gray-50`}
  }

  &:hover {
    ${tw`shadow-md`}

    &::before {
      ${tw`opacity-100`}
    }
  }

  &:focus,
  &:active {
    &::after {
      ${tw`opacity-100`}
    }
  }
`

export const Footer: React.FC = () => {
  const history = useHistory()

  return (
    <Container>
      <div tw="w-full grid grid-cols-12 gap-y-4">
        <div tw="col-span-full lg:col-span-5 lg:pt-12 xl:pt-16 lg:pl-10 lg:pr-8 flex flex-col items-center text-center lg:items-start lg:text-left">
          <h3 tw="text-white text-3xl lg:text-4xl pb-6 font-medium leading-snug">
            Have questions?
          </h3>
          <h4 tw="text-white text-2xl pb-4 lg:pb-8">
            Holler. Our concierge team will show you the ropes.
          </h4>
          <DemoButton
            tw="mb-6"
            css={{ color: "#9CB7E9" }}
            href={generateRealmPath("business", "/book-a-call")}
            target="_blank"
            rel="nofollow noopener"
          >
            Schedule demo
          </DemoButton>
        </div>
        <div tw="col-span-full lg:col-span-7 bg-white rounded-3xl p-8 lg:p-12 xl:p-16 text-center lg:text-left flex flex-col justify-center">
          <RainbowHeading tw="text-3xl lg:text-4xl pb-3 font-light leading-snug">
            Make someone’s&nbsp;day.
          </RainbowHeading>
          <h4 tw="text-2xl pb-8 lg:pb-16 leading-normal text-opacity-80">
            We bet it will be a&nbsp;huge&nbsp;win.
          </h4>
          <div tw="flex flex-col lg:flex-row items-center">
            <StartButton
              tw="max-w-xs"
              onClick={() => {
                history.push(generateRealmPath("business", "/signup?via=ea"))
              }}
            />
          </div>
        </div>
      </div>
    </Container>
  )
}
