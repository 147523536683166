import cardAmex from "../assets/images/payment-cards/amex.png"
import cardCorporateAccount from "../assets/images/payment-cards/corporate-account.png"
import cardDiscover from "../assets/images/payment-cards/discover.png"
import cardMastercard from "../assets/images/payment-cards/mastercard.png"
import cardVisa from "../assets/images/payment-cards/visa.png"

export const imageForCardBrand = (brand?: string | null) => {
  const cardStyle = { width: 32 }

  switch (brand) {
    case "visa":
      return (
        <img
          src={cardVisa}
          alt="Visa"
          style={cardStyle}
          className="data-hj-suppress ph-no-capture fs-exclude"
        />
      )
    case "mastercard":
      return (
        <img
          src={cardMastercard}
          alt="Mastercard"
          style={cardStyle}
          className="data-hj-suppress ph-no-capture fs-exclude"
        />
      )
    case "amex":
      return (
        <img
          src={cardAmex}
          alt="Amex"
          style={cardStyle}
          className="data-hj-suppress ph-no-capture fs-exclude"
        />
      )
    case "discover":
      return (
        <img
          src={cardDiscover}
          alt="Discover"
          style={cardStyle}
          className="data-hj-suppress ph-no-capture fs-exclude"
        />
      )
    case "CORPORATE_ACCOUNT":
    case "BALANCE":
      return (
        <img
          src={cardCorporateAccount}
          alt="Corporate Account"
          style={cardStyle}
        />
      )
    default:
      return brand
  }
}

export const nameForCardBrand = (brand?: string | null) => {
  switch (brand) {
    case "visa":
      return "Visa"
    case "mastercard":
      return "Mastercard"
    case "amex":
      return "Amex"
    case "discover":
      return "Discover"
    default:
      return brand
  }
}
