import { Link } from "react-router-dom"
import tw, { styled } from "twin.macro"

interface Props {
  to: string
  selected: boolean
  label: string
  pushNewUrl?: (path: string) => void
}

export function MenuItem({ to, selected, label, pushNewUrl }: Props) {
  return (
    <MenuItemContainer
      to={to}
      selected={selected}
      onClick={(e) => {
        if (pushNewUrl) {
          // Push a new URL to the Store (for virtual routing) instead of the
          // actual browser location
          e.preventDefault()
          pushNewUrl(to)
        }
      }}
    >
      <div>{label}</div>
      <div tw="h-0 overflow-hidden font-medium">{label}</div>
    </MenuItemContainer>
  )
}

const MenuItemContainer = styled(Link)<{ selected: boolean }>`
  ${tw`whitespace-nowrap text-gray-600 px-4 py-2 rounded-full text-[19px] hover:bg-gray-100 active:(bg-gray-200 scale-95) transition-all flex flex-col items-center first:ml-auto last:mr-auto`}

  ${({ selected }) =>
    selected &&
    tw`bg-primary-new-050 font-medium text-primary-new-600 hover:bg-primary-new-050 active:bg-primary-new-050`}
`
