import React from "react"
import tw from "twin.macro"

import { SparkleIcon } from "./assets/icons/"
import { SettingBase } from "../index"

import { SelectionGroupColorEnum } from "@/common/SelectionGroup/types"
import { BrandValuesDisplayOptions } from "@/store/custom/BrandValuesDisplayOptions"
import { BrandValuesDisplay } from "@/types/graphql-types"

interface Props {
  brandValuesDisplay: BrandValuesDisplay
  setBrandValuesDisplay: (brandValuesDisplay: BrandValuesDisplay) => void
  isExpandable?: boolean
  color?: SelectionGroupColorEnum
  alignPreviewName?: string
}

export const BrandValuesSetting = ({
  brandValuesDisplay,
  setBrandValuesDisplay,
  isExpandable,
  color,
  alignPreviewName,
}: Props) => {
  return (
    <SettingBase
      name="Showcase brand attributes"
      icon={<SparkleIcon />}
      preview={
        <div
          tw="flex"
          css={[alignPreviewName === "right" && tw`md:justify-end`]}
        >
          {brandValuesDisplay === BrandValuesDisplay.options
            ? "In gift view"
            : "Store and gift view"}
        </div>
      }
      color={color}
      isExpandable={isExpandable}
    >
      <BrandValuesDisplayOptions
        brandValuesDisplay={brandValuesDisplay}
        setBrandValuesDisplay={setBrandValuesDisplay}
        color={color}
      />
    </SettingBase>
  )
}
