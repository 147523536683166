import React from "react"
import Select from "react-select"

interface TimeSelectFieldProps {
  onChange: (
    option: React.SetStateAction<{
      label: string
      value: string
    } | null>,
  ) => void
  selectedValue: string
}

const hourIncrementOptionsString = Object.freeze({
  "12 AM": "12 AM",
  "1 AM": "1 AM",
  "2 AM": "2 AM",
  "3 AM": "3 AM",
  "4 AM": "4 AM",
  "5 AM": "5 AM",
  "6 AM": "6 AM",
  "7 AM": "7 AM",
  "8 AM": "8 AM",
  "9 AM": "9 AM",
  "10 AM": "10 AM",
  "11 AM": "11 AM",
  "12 PM": "12 PM",
  "1 PM": "1 PM",
  "2 PM": "2 PM",
  "3 PM": "3 PM",
  "4 PM": "4 PM",
  "5 PM": "5 PM",
  "6 PM": "6 PM",
  "7 PM": "7 PM",
  "8 PM": "8 PM",
  "9 PM": "9 PM",
  "10 PM": "10 PM",
  "11 PM": "11 PM",
})

const hourIncrementOptions = Object.entries(hourIncrementOptionsString).map(
  ([label, value]) => ({
    label,
    value,
  }),
)

const customStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
    color: state.isSelected ? "white" : "black",
    paddingLeft: "25%",
    backgroundColor: state.isSelected ? "rgba(163, 114, 223,1)" : "white",
    "&:hover": {
      backgroundColor: "#efe9f6",
    },
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    width: 98,
    border: state.isFocused ? "1px solid #d0b8ed" : "1px solid #DCDCDC",
    boxShadow: state.isFocused ? 0 : 0,
    paddingLeft: 2,
    paddingRight: 2,
    boxSizing: "border-box",
    borderRadius: 6,
    "&:hover": {
      border: "1px solid #d0b8ed",
    },
  }),
  menu: (base: any) => ({
    ...base,
    borderRadius: 6,
    boxShadow: "rgba(163, 114, 223, 0.15) 0 12px 40px",
  }),
  menuList: (provided: any) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    borderRadius: 6,
  }),
}

// TODO Add in default value code
const TimeSelectField: React.FC<TimeSelectFieldProps> = ({
  onChange,
  selectedValue,
}) => {
  return (
    <Select
      options={hourIncrementOptions}
      isSearchable={true}
      onChange={onChange}
      styles={customStyles}
      defaultValue={{
        label: selectedValue,
        value: selectedValue,
      }}
      components={{
        // DropdownIndicator,
        IndicatorSeparator: () => null,
      }}
    />
  )
}

export default TimeSelectField
