import { Helmet } from "react-helmet-async"

import FrequentlyAskedQuestions from "./FrequentlyAskedQuestions"
import GetStarted from "./GetStarted"
import Hero from "./Hero"
import HowAreCompaniesUsing from "./HowAreCompaniesUsing"
import Brands from "../../business/Brands"
import GiftingInternationally from "../components/GiftingInternationally"
import HowItWorks from "../components/HowItWorks"

const CustomCollections = () => (
  <>
    <Helmet>
      <title>
        Custom Collections - Curate Your Own Gift Collection | Goody
      </title>
      <meta
        name="description"
        content="Build your own customized digital gift collection, and fill it with branded swag and trendy gifts. Choose from 300+ curated corporate gift brands."
      />
    </Helmet>
    <Hero />
    <HowItWorks
      tw="py-12 lg:pt-[14rem]"
      stepOne={{
        header: "Build your own collection",
        body: "Add your logo and a custom message. Fill it with on-demand swag, custom merch, or gifts from our curated collection.",
      }}
      stepTwo={{
        header: "Send with an email",
        body: "Recipients decide which gift they want to claim. They provide their size and shipping info.",
      }}
      stepThree={{
        header: "We do the rest",
        body: "We'll deliver your gift to their doorstep. You pay only when someone accepts.",
      }}
    />
    <HowAreCompaniesUsing />
    <GiftingInternationally />
    <Brands />
    <GetStarted />
    <FrequentlyAskedQuestions />
  </>
)

export default CustomCollections
