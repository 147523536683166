import "./App.css"
import { useApolloClient, useLazyQuery } from "@apollo/client"
import { PlasmicCanvasHost } from "@plasmicapp/react-web/lib/host"
import pluralize from "pluralize"
import { useEffect, useRef, useState } from "react"
import { Helmet, HelmetProvider } from "react-helmet-async"
import { Toaster } from "react-hot-toast"
import Modal from "react-modal"
import { Redirect, Route, BrowserRouter as Router } from "react-router-dom"
import { CompatRouter } from "react-router-dom-v5-compat"
import { useIntercom } from "react-use-intercom"
import { Statsig, StatsigProvider } from "statsig-react"
import tw, { GlobalStyles } from "twin.macro"
import "@/common/web-vitals"

import Account from "./account/Account"
import AccountConsumer from "./account/AccountConsumer"
import VerifyEmail from "./account/VerifyEmail"
import Balance from "./balance/Balance"
import { BalanceDeposit } from "./balance/BalanceDeposit"
import Blog from "./blog/Blog"
import Post from "./blog/Post"
import Browse from "./browse/Browse"
import { STATSIG_OPTIONS, identify, track } from "./common/analytics"
import { getPlanLevel } from "./common/analyticsHelpers"
import AnalyticsListener from "./common/AnalyticsListener"
import BusinessWelcomeModal from "./common/BusinessWelcomeModal"
import CartDrawer from "./common/cart/CartDrawer"
import { CartDrawerProvider } from "./common/contexts/cartDrawer"
import { fetchCsrfTokenFromServer } from "./common/csrf"
import { getEnvVars } from "./common/environment"
import { useGlobalState } from "./common/GlobalState"
import { persistUtmParams, useQueryParams } from "./common/gtm"
import { useGlobalUserState } from "./common/hooks"
import { FeatureFlagsProvider } from "./common/hooks/featureFlags"
import { useGlobalStateSync } from "./common/hooks/globalStateSync"
import { useIframeCompletedCheck } from "./common/hooks/iframeAuth"
import { PaymentMethodsProvider } from "./common/hooks/paymentMethods"
import IframeRedirect from "./common/IframeRedirect"
import LearnAboutFullVsLimitedModal from "./common/members/LearnAboutFullVsLimitedModal"
import LimitedMemberFeatureGateModal from "./common/members/LimitedMemberFeatureGateModal"
import { OCCASIONS_QUERY } from "./common/occasions"
import PrerenderRedirect from "./common/PrerenderRedirect"
import ProPlanModal from "./common/ProPlanModal"
import { Realm, generateRealmPath } from "./common/realm"
import RealmRoute from "./common/RealmRoute"
import ScrollToTop from "./common/ScrollToTop"
import SendAutosave from "./common/SendAutosave"
import SetToRealm from "./common/SetToRealm"
import { Loader } from "./common/UI"
import WSConnectionLoader from "./common/WSConnectionLoader"
import Contact from "./contact/Contact"
import HRISIntegration from "./contacts/components/HRISIntegration"
import { ContactsWrapper } from "./contacts/ContactsWrapper"
import Dashboard from "./dashboard/Dashboard"
import ForgotPassword from "./forgotPassword/ForgotPassword"
import { ROOT_DATA_QUERY } from "./graphql"
import API from "./landing/api/API"
import BookACall from "./landing/BookACall"
import Business from "./landing/business"
import AutomatedGifting from "./landing/businessUseCases/automatedGifting"
import ClientAppreciation from "./landing/businessUseCases/clientAppreciation"
import EmployeeRecognition from "./landing/businessUseCases/employeeRecognition"
import SalesProspecting from "./landing/businessUseCases/salesProspecting"
import Careers from "./landing/Careers"
import CoinsTerms from "./landing/CoinsTerms"
import EA from "./landing/EA"
import HowItWorks from "./landing/howItWorks"
import BrandedSwag from "./landing/howItWorks/brandedSwag"
import BulkGifting from "./landing/howItWorks/bulkGifting"
import CustomCollections from "./landing/howItWorks/customCollections"
import Landing from "./landing/Landing"
import Partner from "./landing/Partner"
import Pricing from "./landing/pricing"
import PrivacyPolicy from "./landing/PrivacyPolicy"
import ReferralTerms from "./landing/ReferralTerms"
import Security from "./landing/Security"
import Terms from "./landing/Terms"
import WorkspaceReferralTerms from "./landing/WorkspaceReferralTerms"
import MyGiftsSwitch from "./myGifts/MyGiftsSwitch"
import Organization from "./organization/Organization"
import ReportsLoader from "./reports/ReportsLoader"
import ResetPassword from "./resetPassword/ResetPassword"
import { SendBusiness } from "./send/SendBusiness"
import SendConsumer from "./send/SendConsumer"
import SendV3 from "./send/SendV3"
import { getFromSalesforce } from "./send/useSalesforceSend"
import SignIn from "./signIn/SignIn"
import CompanyName from "./signUp/CompanyName"
import SignUpToggleContainer from "./signUp/components/SignUpToggleContainer"
import { useIsBusinessSignup } from "./signUp/hooks/businessSignUp"
import InviteMembers from "./signUp/InviteMembers"
import SelectWorkspace from "./signUp/SelectWorkspace"
import SignUp from "./signUp/SignUp"
import AppSite from "./sites/App"
import PublicSite from "./sites/Public"
import RealmToggle from "./sites/shared/RealmToggle"
import CustomFeaturesRoutes from "./store/custom/CustomFeaturesRoutes"
import { SubscribePro, SubscribeTeam } from "./subscriptions"
import { SwagIntegrationsLoader } from "./SwagIntegrations"
import Track from "./track/Track"
import {
  App_OccasionsQuery,
  App_OccasionsQueryVariables,
  App_RootDataQuery,
} from "./types/graphql-types"
import WorkspaceInvitation from "./workspaceInvitation/WorkspaceInvitation"
import Workspaces from "./workspaces/Workspaces"

import { ExperimentsProvider } from "@/common/hooks/experiments"
import LP2 from "@/landing/business/LP2"
import PlasmicPlayground from "@/landing/PlasmicPlayground"
import { SendBusinessProviders } from "@/send/components/SendBusinessProviders"
import { OccasionSegment, PlusEnrollmentStatus } from "@/types/graphql-types"

Modal.setAppElement("#root")

// Since we call the Statsig singleton outside of the React context, we also
// must initialize it here.
Statsig.initialize(getEnvVars().statsigSdkKey, undefined, STATSIG_OPTIONS)

// Add custom pluralization rules
pluralize.addIrregularRule("fit", "fit")

function App() {
  const queryParams = useQueryParams()
  const [csrfReady, setCsrfReady] = useState(false)
  const [userReady, setUserReady] = useState(false)
  const [appReady, setAppReady] = useState(false)
  const [loadRootData, { data }] =
    useLazyQuery<App_RootDataQuery>(ROOT_DATA_QUERY)
  const { setGlobalUserState, setGlobalOrganizationState } =
    useGlobalUserState()
  const [intercomBooted, setIntercomBooted] = useState(false)
  const { update, boot, show, trackEvent } = useIntercom()
  const [signedIn] = useGlobalState("signedIn")
  const [enrollmentStatus] = useGlobalState("enrollmentStatus")
  const [user] = useGlobalState("user")
  const [showIframeRedirect, setShowIframeRedirect] = useState(false)
  useIframeCompletedCheck()
  const client = useApolloClient()
  const lastIdentifiedUserID = useRef<string | null>(null)

  useGlobalStateSync()

  const prefetchOccasions = () => {
    client.query<App_OccasionsQuery, App_OccasionsQueryVariables>({
      query: OCCASIONS_QUERY,
      variables: { segment: OccasionSegment.PLUS },
    })
    client.query<App_OccasionsQuery, App_OccasionsQueryVariables>({
      query: OCCASIONS_QUERY,
      variables: { segment: OccasionSegment.STANDARD },
    })
  }

  // If we're in salesforce and in safari and cookies aren't working properly, we need
  // to redirect.
  useEffect(() => {
    ;(async () => {
      if (
        window.self !== window.top &&
        window.location.pathname === "/plus/salesforce-send" && // @ts-ignore
        document.hasStorageAccess
      ) {
        // @ts-ignore
        if ((await document.hasStorageAccess()) === false) {
          setShowIframeRedirect(true)
        }
      }
    })()
  }, [])

  // If we have utm query params, we extract them and save to local state
  useEffect(() => {
    persistUtmParams(queryParams)
  }, [queryParams])

  // Clear referring_gift_id if we're logged in
  useEffect(() => {
    if (signedIn) {
      window.localStorage?.removeItem("referring_gift_id")
      window.localStorage?.removeItem("referring_code")
    }
  }, [signedIn])

  // Initialize app
  useEffect(() => {
    async function fetchCsrf() {
      await fetchCsrfTokenFromServer()
      setCsrfReady(true)
      loadRootData()
    }

    fetchCsrf()

    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual"
    }
  }, [])

  useEffect(() => {
    if (data) {
      setGlobalUserState(data.me)
      setGlobalOrganizationState(data.organization)
      setUserReady(true)

      if (data.me && lastIdentifiedUserID.current !== data.me.id) {
        const planLevel = getPlanLevel(data.me, data.organization)

        identify(data.me.id, {
          first_name: data.me.firstName,
          last_name: data.me.lastName,
          email: data.me.email,
          crm_id: data.me.crmId,
          organization_id: data.organization?.id,
          organization_name: data.organization?.name,
          plan_level: planLevel,
          plus_enrolled_at: data.me.plusEnrolledAt,
        })

        lastIdentifiedUserID.current = data.me.id
      }

      window.goodyUserID = data.me?.id || null
    }
  }, [data])

  useEffect(() => {
    if (userReady && csrfReady) {
      setAppReady(true)
      track("App - Ready")

      prefetchOccasions()
    }
  }, [userReady, csrfReady])

  function trackIntercomProForFreeEvent() {
    if (data?.me?.featureAccess?.includes("pro_for_free_group_test")) {
      trackEvent("Display Pro for Free Test Group")
    }
  }

  // When user is ready, we boot intercom
  useEffect(() => {
    if (userReady) {
      bootIntercom()

      // Check if we should open the chat window (only once).
      if (!intercomBooted && window.location.hash === "#chat") {
        show()
      }

      trackIntercomProForFreeEvent()

      setIntercomBooted(true)
    }
  }, [userReady])

  // If me data changes, we update intercom
  useEffect(() => updateIntercom(), [data?.me])

  const getIntercomProps = () => {
    const me = data?.me
    return {
      email: me?.email,
      userId: me?.crmId,
      createdAt: me?.createdAtUnix,
      name: me ? `${me.firstName} ${me.lastName}` : undefined,
      userHash: me?.userHash,
      customAttributes: {
        "Pro for Free Test Group":
          me?.featureAccess?.includes("pro_for_free_group_test") || undefined,
      },
    }
  }

  const bootIntercom = () => boot(getIntercomProps())
  const updateIntercom = () => {
    // Only update if we've booted already
    if (intercomBooted) {
      update(getIntercomProps())
      trackIntercomProForFreeEvent()
    }
  }

  const statsigUser = user ? { userID: user.id } : {}

  const enrolled = signedIn && enrollmentStatus !== PlusEnrollmentStatus.NONE
  const [currentRealm] = useGlobalState("currentRealm")

  useEffect(() => {
    if (currentRealm === "business") {
      document.body.classList.add("is-business")
    } else {
      document.body.classList.remove("is-business")
    }
    return () => document.body.classList.remove("is-business")
  }, [currentRealm === "business"])

  useEffect(() => {
    if (signedIn) {
      document.body.classList.add("signed-in")
    } else {
      document.body.classList.remove("signed-in")
    }
    return () => document.body.classList.remove("signed-in")
  }, [signedIn])

  // Logged out header for consumer web
  let Site = enrolled && currentRealm === "plus" ? AppSite : PublicSite

  // If the current route is /plasmic-host, render an empty Site containing only children (must be FC)
  if (window.location.pathname === "/plasmic-host") {
    Site = ({ children }) => <>{children}</>
  }

  // NOTE: Do not have multiple RealmRoutes that match a single route: do the switching inside
  // Realm redirects only work for shared routes
  const routes = (
    <>
      <RealmRoute path="/" exact realm={["plus", "business"]}>
        {currentRealm === "plus" ? (
          enrolled ? (
            <Redirect to={generateRealmPath("plus", "/send")} />
          ) : (
            <>
              <PrerenderRedirect location="https://www.ongoody.com/business" />
              <Redirect to={generateRealmPath("business", "/")} />
            </>
          )
        ) : (
          <Business />
        )}
      </RealmRoute>
      <RealmRoute path="/lp2" exact realm="business">
        <LP2 />
      </RealmRoute>
      <RealmRoute path="/plasmic-playground" exact realm="business">
        <PlasmicPlayground />
      </RealmRoute>
      <RealmRoute path="/personal" exact realm="consumer">
        <Landing />
      </RealmRoute>
      <RealmRoute path="/dashboard" realm="plus">
        {({ match }: { match: any }) => <Dashboard visible={!!match} />}
      </RealmRoute>
      <RealmRoute path="/forgot-password" realm="business">
        <ForgotPassword />
      </RealmRoute>
      <RealmRoute path="/reset-password" realm="business">
        <ResetPassword />
      </RealmRoute>
      <RealmRoute path="/send" realm="plus" tags={["send"]}>
        {getFromSalesforce() ? (
          <Redirect to={generateRealmPath("plus", "/salesforce-send")} />
        ) : (
          <SendBusiness />
        )}
      </RealmRoute>
      <RealmRoute path="/salesforce-send" realm="plus" tags={["send"]}>
        <SendBusinessProviders>
          <SendV3 fromSalesforce={true} />
        </SendBusinessProviders>
      </RealmRoute>
      <RealmRoute path="/send" realm="consumer">
        <SendConsumer />
      </RealmRoute>
      <RealmRoute path="/my-gifts" realm="consumer">
        <MyGiftsSwitch />
      </RealmRoute>
      <RealmRoute path="/security">
        <Security />
      </RealmRoute>
      <RealmRoute path="/account">
        <Redirect to={generateRealmPath("consumer", "/account")} />
      </RealmRoute>
      <RealmRoute path="/account" realm="consumer">
        <AccountConsumer />
      </RealmRoute>
      <RealmRoute path={["/browse", "/editorial/:editorialSlug"]}>
        {currentRealm === "business" && enrolled && (
          <SetToRealm realm={"plus"} />
        )}
        <Browse visible={true} />
      </RealmRoute>
      <RealmRoute path="/store/custom" realm="plus">
        <CustomFeaturesRoutes />
      </RealmRoute>
      <RealmRoute path="/editor/:brandSlug/:productSlug" realm="plus">
        <SwagIntegrationsLoader />
      </RealmRoute>
      <RealmRoute path="/post-signup/workspace" realm="plus">
        <SelectWorkspace />
      </RealmRoute>
      <RealmRoute path="/post-signup/company" realm="plus">
        <CompanyName />
      </RealmRoute>
      <RealmRoute path="/post-signup/invite" realm="plus">
        <InviteMembers />
      </RealmRoute>
      <RealmRoute path="/track" realm="plus">
        <Track />
      </RealmRoute>
      <RealmRoute path="/contacts" realm="plus">
        <ContactsWrapper />
      </RealmRoute>
      <RealmRoute path="/gift-api" exact realm="business">
        <API defaultVariant="commerce" />
      </RealmRoute>
      <RealmRoute path="/gift-api/automation" exact realm="business">
        <API defaultVariant="automation" />
      </RealmRoute>
      <RealmRoute path="/organization/hr" realm="plus">
        <HRISIntegration />
      </RealmRoute>
      <RealmRoute path="/account" realm="plus">
        <Account />
      </RealmRoute>
      <RealmRoute
        path={["/balance", "/balance/transaction-log"]}
        exact
        realm="plus"
      >
        <Balance />
      </RealmRoute>
      <RealmRoute path="/balance/deposit" exact realm="plus">
        <BalanceDeposit />
      </RealmRoute>
      <RealmRoute path="/workspaces" realm="plus">
        <Workspaces />
      </RealmRoute>
      <RealmRoute path="/workspace-invite" realm="plus">
        <WorkspaceInvitation />
      </RealmRoute>
      <RealmRoute path="/verify-email" realm="plus">
        <VerifyEmail />
      </RealmRoute>
      <RealmRoute path="/contact">
        <Contact />
      </RealmRoute>
      <RealmRoute path="/ea" exact component={EA} realm="business" />
      <RealmRoute path="/referral-terms">
        <ReferralTerms />
      </RealmRoute>
      <RealmRoute path="/workspace-referral-terms">
        <WorkspaceReferralTerms />
      </RealmRoute>
      <RealmRoute path="/coins-terms">
        <CoinsTerms />
      </RealmRoute>
      <RealmRoute path="/terms">
        <Terms />
      </RealmRoute>
      <RealmRoute path="/privacy">
        <PrivacyPolicy />
      </RealmRoute>
      <RealmRoute path="/partner">
        <Partner />
      </RealmRoute>
      <RealmRoute path="/careers">
        <Careers />
      </RealmRoute>
      <RealmRoute path="/blog/:slug" exact component={Post} />
      <RealmRoute path="/blog" exact>
        <Blog />
      </RealmRoute>
      <RealmRoute
        path="/how-it-works"
        component={HowItWorks}
        realm="business"
        exact
      />
      <RealmRoute
        path="/how-it-works/branded-swag"
        component={BrandedSwag}
        realm="business"
      />
      <RealmRoute
        path="/how-it-works/bulk-gifting"
        component={BulkGifting}
        realm="business"
      />
      <RealmRoute
        path="/how-it-works/custom-collections"
        component={CustomCollections}
        realm="business"
      />
      <RealmRoute
        path="/use-cases/employee-recognition"
        component={EmployeeRecognition}
        realm="business"
      />
      <RealmRoute
        path="/use-cases/autogifting"
        component={AutomatedGifting}
        realm="business"
      />
      <RealmRoute
        path="/use-cases/client-appreciation"
        component={ClientAppreciation}
        realm="business"
      />
      <RealmRoute
        path="/use-cases/sales-prospecting"
        component={SalesProspecting}
        realm="business"
      />
      <RealmRoute path="/pricing" component={Pricing} realm="business" />
      <RealmRoute path="/signup" realm="business">
        <SignUp />
      </RealmRoute>
      <RealmRoute path="/signin" realm="business">
        {({ match }) => <SignIn visible={!!match} />}
      </RealmRoute>
      <RealmRoute path="/book-a-call" realm="business">
        <BookACall variant="bookACall" />
      </RealmRoute>
      <RealmRoute path="/book-a-call-sales" realm="business">
        <BookACall variant="chatWithSales" />
      </RealmRoute>
      <RealmRoute path="/employee-engagement-demo" realm="business">
        <BookACall variant="employeeEngagementDemo" />
      </RealmRoute>
      <RealmRoute path="/team-demo" realm="business">
        <BookACall variant="teamDemo" />
      </RealmRoute>
      <RealmRoute path="/subscribe" exact realm="plus">
        <Redirect to={generateRealmPath("plus", "/subscribe-team")} />
      </RealmRoute>
      <RealmRoute path="/subscribe-team" exact realm="plus">
        <SubscribeTeam />
      </RealmRoute>
      <RealmRoute path="/subscribe-pro" exact realm="plus">
        <SubscribePro />
      </RealmRoute>
      <RealmRoute path={"/organization"} realm="plus">
        <Organization />
      </RealmRoute>
      <RealmRoute path="/invited" exact>
        <Redirect to={generateRealmPath("business", "?invited")} />
      </RealmRoute>
      <RealmRoute path="/reports" exact realm="plus">
        <ReportsLoader />
      </RealmRoute>
      <Route path={["/send", "/send/"]} exact>
        <>
          <PrerenderRedirect location="https://www.ongoody.com/personal/send" />
          <Redirect to={generateRealmPath("consumer", "/send")} />
        </>
      </Route>
      <Route path={["/business", "/business/"]} exact>
        <>
          <PrerenderRedirect location="https://www.ongoody.com/" />
          <Redirect to={generateRealmPath("business", "/")} />
        </>
      </Route>
      <Route
        path={"/my-gifts/:id"}
        render={({ match }) => {
          return (
            <Redirect
              to={generateRealmPath("consumer", `/my-gifts/${match.params.id}`)}
            />
          )
        }}
      />
      <Route path="/plasmic-host" exact>
        <PlasmicCanvasHost />
      </Route>
      <Route
        path={[
          "/plus/browse/:rest*",
          "/plus/browse/",
          "/plus/browse",
          "/business/browse/:rest*",
          "/business/browse/",
          "/business/browse",
          "/personal/browse/:rest*",
          "/personal/browse/",
          "/personal/browse",
        ]}
        render={({ match }) => {
          const realmToSet = getRealmToSetFromMatchPath(match.path)

          return (
            <>
              {realmToSet && <SetToRealm realm={realmToSet} />}
              <PrerenderRedirect
                location={`https://www.ongoody.com/browse${
                  match.params.rest ? "/" + match.params.rest : ""
                }`}
              />
              <Redirect
                to={`/browse${
                  match.params.rest ? "/" + match.params.rest : ""
                }`}
              />
            </>
          )
        }}
      />

      <Route
        path={[
          "/plus/editorial/:rest*",
          "/business/editorial/:rest*",
          "/personal/editorial/:rest*",
        ]}
        render={({ match }) => {
          const realmToSet = getRealmToSetFromMatchPath(match.path)

          return (
            <>
              {realmToSet && <SetToRealm realm={realmToSet} />}
              <PrerenderRedirect
                location={`https://www.ongoody.com/editorial${
                  match.params.rest ? "/" + match.params.rest : ""
                }`}
              />
              <Redirect
                to={`/editorial${
                  match.params.rest ? "/" + match.params.rest : ""
                }`}
              />
            </>
          )
        }}
      />
    </>
  )

  if (showIframeRedirect) {
    return <IframeRedirect />
  }

  return (
    <HelmetProvider>
      <GlobalStyles />
      {appReady ? (
        <Router>
          <CompatRouter>
            <FeatureFlagsProvider>
              <StatsigProvider
                sdkKey={getEnvVars().statsigSdkKey}
                user={statsigUser}
                options={STATSIG_OPTIONS}
              >
                <ExperimentsProvider>
                  <PaymentMethodsProvider>
                    <CartDrawerProvider>
                      <Helmet>
                        {/* Default */}
                        <meta
                          property="og:image"
                          content="https://www.ongoody.com/static/images/og.png"
                        />
                      </Helmet>
                      <SendAutosave />
                      <ScrollToTop />
                      <AnalyticsListener />
                      <Toaster toastOptions={{ duration: 4000 }} />
                      <ToggleContainer />
                      <Site>{routes}</Site>
                      <BusinessWelcomeModal />
                      <ProPlanModal />
                      <LearnAboutFullVsLimitedModal />
                      <LimitedMemberFeatureGateModal />
                      <CartDrawer />
                      <WSConnectionLoader />
                    </CartDrawerProvider>
                  </PaymentMethodsProvider>
                </ExperimentsProvider>
              </StatsigProvider>
            </FeatureFlagsProvider>
          </CompatRouter>
        </Router>
      ) : (
        <div tw="absolute top-0 left-0 right-0 bottom-0 flex flex-row items-center justify-center h-full w-full">
          <Loader />
        </div>
      )}
    </HelmetProvider>
  )
}

// From the matched path that contains a realm (e.g. /plus/browse), determine the
// realm to set.
function getRealmToSetFromMatchPath(path: string) {
  // foundRealm is the first part of the path, e.g. "plus" or "business"
  let foundRealm = path.split("/")[1]
  if (foundRealm === "personal") {
    foundRealm = "consumer"
  }

  // realmToSet is consumer, business, or plus if foundRealm is one of them, otherwise null
  // this allows programmatically setting the realm from the URL
  return ["consumer", "business", "plus"].includes(foundRealm as Realm)
    ? (foundRealm as Realm)
    : null
}

const ToggleContainer = () => {
  const [currentRealm] = useGlobalState("currentRealm")
  const [signedIn] = useGlobalState("signedIn")
  const { isBusinessSignupPath } = useIsBusinessSignup()
  const [user] = useGlobalState("user")

  if (!signedIn && isBusinessSignupPath) {
    return <SignUpToggleContainer currentRealm={currentRealm} />
  }

  return (
    <div
      tw="absolute w-full pointer-events-none z-[60] max-w-[100vw]"
      css={[
        currentRealm !== "plus" && tw`fixed`,
        // Leave space for the staff access banner
        user?.staffAccessActive && tw`top-12`,
      ]}
      id="realmToggleContainer"
    >
      <div
        tw={
          "w-screen 2xl:max-w-[1440px] flex flex-row items-center justify-start h-12 mx-auto hidden lg:flex px-5"
        }
      >
        <RealmToggle />
      </div>
    </div>
  )
}

export default App
