import React from "react"
import tw, { css, styled } from "twin.macro"

interface IAlert {
  text: string
}

const Alert: React.FC<IAlert> = ({ text }) => {
  return (
    <Container>
      <Text>
        {" "}
        <Exclamation /> {text}{" "}
      </Text>
    </Container>
  )
}

const Container = styled.div(() => [
  tw`inline-block p-5 text-gray-500 rounded-lg bg-gray-50 leading-4`,
  css`
    width: 100%;
    background-color: rgba(250, 239, 205, 0.3);
  `,
])

const textColorHex = "#A78725"

const Text = styled.span`
  color: ${textColorHex};
  svg {
    ${tw`inline-block mb-1 mr-4`}
  }
`

const Exclamation = () => (
  <svg
    width="18"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginLeft: 4 }}
  >
    <path
      d="M22 42C33.0457 42 42 33.0457 42 22C42 10.9543 33.0457 2 22 2C10.9543 2 2 10.9543 2 22C2 33.0457 10.9543 42 22 42Z"
      stroke={textColorHex}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22 12L22 24"
      stroke={textColorHex}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22 32H22.02"
      stroke={textColorHex}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Alert
