import { Link, NavLink } from "react-router-dom"
import tw, { css, styled } from "twin.macro"

export const buttonStyle = css`
  ${tw`text-xl text-white rounded-xl px-5 py-3 font-medium inline-flex items-center active:scale-95 transition-all outline-none relative overflow-hidden z-10`}

  &:hover:not(:disabled) {
    ${tw`shadow-md-light`}
  }

  &:disabled {
    ${tw`opacity-50 cursor-auto`}
  }
`

export const gradientStyle = css`
  ${buttonStyle}
  ${tw`bg-gradient-to-r from-gradient-updated-purple-medium to-gradient-updated-blue-medium`}

  &::before, &::after {
    ${tw`block absolute top-0 left-0 w-full h-full bg-gradient-to-r opacity-0 transition-opacity`}
    content: "";
    z-index: -1;
  }

  &::before {
    ${tw`from-gradient-updated-purple-dark to-gradient-updated-blue-dark`}
  }

  &::after {
    ${tw`from-gradient-updated-purple-darker to-gradient-updated-blue-darker`}
  }

  &:hover:not(:disabled) {
    ${tw`shadow-md`}

    &::before {
      ${tw`opacity-100`}
    }
  }

  &:focus:enabled,
  &:active:enabled {
    &::after {
      ${tw`opacity-100`}
    }
  }
`

const GradientButton = styled.button`
  ${gradientStyle}
`

export const GradientLink = styled(Link)`
  ${gradientStyle}
`

export const GradientNavLink = styled(NavLink)`
  ${gradientStyle}
`

export const GradientAnchor = styled.a`
  ${tw`no-underline`}
  ${gradientStyle}
`

export const GradientDiv = styled.div`
  ${gradientStyle}
`

export default GradientButton
