import { gql, useQuery } from "@apollo/client"
import { useState } from "react"
import tw, { styled } from "twin.macro"

import CreateWorkspace from "./CreateWorkspace"
import EditWorkspaceDrawer from "./EditWorkspaceDrawer"
import PencilIcon from "../../assets/icons/pencil-edit.svg"
import Container from "../../sites/App/Container"

import { GetOrganizationWorkspacesQuery } from "@/types/graphql-types"

const Workspaces = () => {
  const { data } = useQuery<GetOrganizationWorkspacesQuery>(GET_WORKSPACES)
  const workspaces = data?.organization?.workspaces || []

  return (
    <Container>
      <CreateWorkspace tw="m-7 mt-10" />
      <div tw="pb-9 px-3 flex flex-wrap">
        {workspaces.map((workspace) => (
          <Workspace key={workspace.id} workspace={workspace} />
        ))}
      </div>
    </Container>
  )
}

interface WorkspaceProps {
  workspace: NonNullable<
    GetOrganizationWorkspacesQuery["organization"]
  >["workspaces"][0]
}

const Workspace = ({ workspace }: WorkspaceProps) => {
  const [editWorkspaceDrawerOpen, setEditWorkspaceDrawerOpen] = useState(false)

  const openEditDrawer = () => {
    setEditWorkspaceDrawerOpen(true)
  }

  return (
    <div tw="border rounded-lg w-96 m-4 p-3">
      <div tw="p-4 pb-0">
        <h3 tw="text-primary-700 font-medium mb-6 text-2xl">
          {workspace.name}
        </h3>
        <div tw="flex mb-4 justify-between">
          <div tw="flex-1">
            <Subheader>Gifts all time</Subheader>
            <p tw="text-2xl">{workspace.giftsCount}</p>
          </div>
          <div tw="flex-1 mx-4">
            <Subheader>Last 30 days</Subheader>
            <p tw="text-2xl">{workspace.giftsCountInLast30Days}</p>
          </div>
          <div tw="flex-1">
            <Subheader>Members</Subheader>
            <p tw="text-2xl">{workspace.members.length}</p>
          </div>
        </div>
        <div tw="mb-6">
          <Subheader>Managers</Subheader>
          <p>{workspace.managerNames.join(" • ")}</p>
        </div>
      </div>
      <button
        onClick={openEditDrawer}
        tw="bg-gray-100 flex w-full justify-center py-2 rounded items-center"
      >
        <img src={PencilIcon} tw="mr-2" />
        <span tw="text-gray-500">Edit</span>
      </button>
      <EditWorkspaceDrawer
        workspace={workspace}
        open={editWorkspaceDrawerOpen}
        setOpen={setEditWorkspaceDrawerOpen}
      />
    </div>
  )
}

const Subheader = styled.h4`
  ${tw`text-gray-700 whitespace-nowrap`}
`

export const GET_WORKSPACES = gql`
  query GetOrganizationWorkspaces {
    organization {
      workspaces {
        id
        name
        giftsCount
        giftsCountInLast30Days
        members {
          id
        }
        managerNames
      }
    }
  }
`

export default Workspaces
