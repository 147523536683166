import { isNil } from "lodash-es"
import React from "react"

import { formatPrice } from "@/common/format"
import { useAddBalanceModal } from "@/common/hooks/addBalanceModal"
import Button from "@/common/payments/Button"
import PaymentMethod, {
  Props as PaymentMethodProps,
} from "@/common/payments/PaymentMethod"
import { TopUpExperiment } from "@/send/components/topUpSend/TopUpExperiment"
import { useTopUpExperimentUnsafe } from "@/send/hooks/topUpExperiment"
import plus from "@/send/images/add-funds-plus.svg"
import { BudgetRecurrence, BudgetRule } from "@/types/graphql-types"

interface Props extends Omit<PaymentMethodProps, "label"> {
  balanceAmount: number | null
  budgetRule?: BudgetRule | null
  addBalance?: boolean
}

const BalancePaymentMethod = ({
  balanceAmount,
  budgetRule,
  addBalance = true,
  ...props
}: Props) => {
  const { openAddBalanceModal } = useAddBalanceModal()
  // We don't want to use the experiment if we're not on the send page
  const canExposeToTopUpExperiment =
    useTopUpExperimentUnsafe()?.canExposeToTopUpExperiment ?? false

  const subtext =
    typeof balanceAmount === "number" && balanceAmount > 0
      ? undefined
      : "Simplify receipts by pre-funding via credit card or invoice."
  const budgetRuleText =
    !isNil(budgetRule) && budgetRule.amount !== 0
      ? ` of ${formatPrice(budgetRule.amount)} this ${
          budgetRule.recurrence === BudgetRecurrence.MONTHLY
            ? "month"
            : "quarter"
        }`
      : ""

  return (
    <PaymentMethod
      label="Balance"
      sublabel={formatPrice(balanceAmount ?? 0, true) + budgetRuleText}
      subtext={subtext}
      additionalContentWhenSelected={
        canExposeToTopUpExperiment ? <TopUpExperiment /> : null
      }
      {...props}
    >
      <Button
        disabled={!addBalance}
        icon={plus}
        onClick={(e) => {
          e.stopPropagation()
          openAddBalanceModal()
        }}
      >
        Add funds
      </Button>
    </PaymentMethod>
  )
}

export default BalancePaymentMethod
