import { useState } from "react"

import { OrganizationSubscriptionSizeSegment } from "@/types/graphql-types"

export interface SubscriptionPlan {
  sku: string
  name: string
  price: number
  monthlyPrice: number
  interval: string
  includedSeatCount: number
  additionalSeatPrice: number
}

const PLANS = {
  small_business: {
    yearly: {
      sku: "business_team_base_yearly_2022a",
      name: "Goody for Teams - Base (Yearly)",
      price: 249000,
      monthlyPrice: 20750,
      interval: "yearly",
      includedSeatCount: 2,
      additionalSeatPrice: 2800,
    },
    monthly: {
      sku: "business_team_base_monthly_2022a",
      name: "Goody for Teams - Base (Monthly)",
      price: 24900,
      monthlyPrice: 24900,
      interval: "monthly",
      includedSeatCount: 2,
      additionalSeatPrice: 3400,
    },
  },
  growth: {
    yearly: {
      sku: "business_team_growth_yearly_2022a",
      name: "Goody for Teams - Growth (Yearly)",
      price: 339000,
      monthlyPrice: 28250,
      interval: "yearly",
      includedSeatCount: 5,
      additionalSeatPrice: 2000,
    },
    monthly: {
      sku: "business_team_growth_monthly_2022a",
      name: "Goody for Teams - Growth (Monthly)",
      price: 33900,
      monthlyPrice: 33900,
      interval: "monthly",
      includedSeatCount: 5,
      additionalSeatPrice: 2400,
    },
  },
  enterprise: {
    yearly: {
      sku: "business_team_enterprise_yearly_2022a",
      name: "Goody for Teams - Enterprise (Yearly)",
      price: 449000,
      monthlyPrice: 37517,
      interval: "yearly",
      includedSeatCount: 10,
      additionalSeatPrice: 1800,
    },
    monthly: {
      sku: "business_team_enterprise_monthly_2022a",
      name: "Goody for Teams - Enterprise (Monthly)",
      price: 44900,
      monthlyPrice: 44900,
      interval: "monthly",
      includedSeatCount: 10,
      additionalSeatPrice: 2200,
    },
  },
}

export default function useTeamsSubscriptionPlanData(
  segment: OrganizationSubscriptionSizeSegment,
) {
  const planDataFromSegment = PLANS[segment]
  const [selectedPlan, setSelectedPlan] = useState<SubscriptionPlan>(
    planDataFromSegment.yearly,
  )

  const alternateIntervalPlan =
    selectedPlan === PLANS[segment].yearly
      ? PLANS[segment].monthly
      : PLANS[segment].yearly

  const toggleInterval = () => {
    setSelectedPlan(alternateIntervalPlan)
  }

  return { selectedPlan, alternateIntervalPlan, toggleInterval }
}
