import React from "react"
import { Helmet } from "react-helmet-async"

import Hero from "./Hero"
import BrandsCollection from "./images/BrandsCollection.png"
import HowGoodyIsDifferentDesktop from "./images/HowGoodyIsDifferentDesktop.png"
import HowGoodyIsDifferentMobile from "./images/HowGoodyIsDifferentMobile.png"
import MissionControlForSalesGifts from "./MissionControlForSalesGifts"
import WhySalesGifting from "./WhySalesGifting"
import { useGlobalState } from "../../../common/GlobalState"
import { generateRealmPath } from "../../../common/realm"
import Brands from "../components/Brands"
import CallToAction from "../components/CallToAction"
import Divider from "../components/Divider"
import HowGoodyIsDifferent from "../components/HowGoodyIsDifferent"
import OurCollection from "../components/OurCollection"

const SalesProspecting: React.FC = () => {
  const [signedIn] = useGlobalState("signedIn")
  const goTo = (path: string) => generateRealmPath("business", path)

  return (
    <>
      <Helmet>
        <title>Sales Prospecting — Goody for Business</title>
        <meta
          name="description"
          content="Customer gifts that drive relationships and retention. Send curated gifts that will make your clients feel valued and unique. No shipping address needed."
        />
      </Helmet>

      <Hero />

      <div tw="mt-16 lg:mt-20">
        <Brands
          title="Thousands of companies use Goody to send delightful business gifts"
          variant="secondary"
        />
      </div>

      <Divider tw="mt-16 md:mt-24 md:pt-0.5 mb-12 md:mb-20" />

      <div tw="flex mx-8 lg:mx-36 lg:mb-20 items-center">
        <WhySalesGifting />
      </div>

      <MissionControlForSalesGifts />

      <div tw="my-14 lg:mx-12 lg:mt-20 lg:mb-36">
        <OurCollection
          heading={""}
          title="Unique, memorable gifts that prospects can’t ignore."
          description={
            <div tw="max-w-[640px] mx-auto leading-8">
              The most effective sellers stand out from the pack.
              <br />
              Our one-of-a-kind collection includes 350+ unique brands that make
              a statement, plus dozens of popular gift cards.
            </div>
          }
          brandsImage={BrandsCollection}
          primaryAction={{ linkTo: goTo("/browse") }}
        />
      </div>

      <div tw="pb-28">
        <HowGoodyIsDifferent
          linkTo={goTo("/how-it-works")}
          options={[
            {
              title: "No shipping address needed.",
              content:
                "Just a phone number or email is all you need. Your recipient enters their address.",
            },
            {
              title: "Recipients can swap gifts.",
              content:
                "Your gift recipients can swap their gift for one of equal or lesser value, with no pricing shown.",
            },
            {
              title: "Track all your gifts in one place.",
              content:
                "All of your team’s gifts, thank you notes, and delivery statuses, centralized.",
            },
          ]}
          image={{
            mobile: HowGoodyIsDifferentMobile,
            desktop: HowGoodyIsDifferentDesktop,
          }}
        />
      </div>

      <CallToAction signedIn={signedIn} />
      <Divider tw="mt-20" />
    </>
  )
}

export default SalesProspecting
