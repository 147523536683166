import React, { useContext } from "react"

import { ReactComponent as Icon } from "./icons/meeting-calendar.svg"
import OptionBase from "./OptionBase"
import { useGlobalState } from "../../../common/GlobalState"
import CalendarMeetingOptions from "../../components/CalendarMeetingOptions"
import { OptionsContext } from "../OptionsContext"

import { gift_meeting_setting } from "@/types/graphql-types"

export default function MeetingCalendar() {
  const { currentGift, calendarIntegration, isDirectSend } =
    useContext(OptionsContext)
  const [sendPageMode, setSendPageMode] = useGlobalState("sendPageMode")

  if (
    isDirectSend ||
    !calendarIntegration?.calendlyApiUrl ||
    currentGift.isAutogift
  ) {
    return null
  }

  return (
    <OptionBase
      name="Meeting calendar"
      icon={<Icon />}
      preview={
        currentGift.giftCalendarSetting === gift_meeting_setting.GIFT_FIRST
          ? "Enabled, show after gift accepted"
          : currentGift.giftCalendarSetting ===
            gift_meeting_setting.MEETING_REQUIRED
          ? "Enabled, require meeting to claim gift"
          : "Disabled"
      }
    >
      <CalendarMeetingOptions
        calendarIntegration={calendarIntegration}
        defaultGiftSetting={
          sendPageMode === "updateGiftBatch"
            ? currentGift.giftCalendarSetting
            : undefined
        }
        sendV3={true}
      />
    </OptionBase>
  )
}
