import { keyBy } from "lodash-es"
import { useState } from "react"
import { useMemo } from "react"
import tw, { css, styled } from "twin.macro"

import GiftOptionProductsList from "./GiftOptionProductsList"

import { getColor } from "@/common/tailwind"
import { SegmentUnavailable } from "@/store/components/SegmentUnavailable"
import { giftOptionIsGiftCard } from "@/store/utils"
import { Store_GiftOptionDataFragment } from "@/types/graphql-types"

interface Props {
  giftOption: Store_GiftOptionDataFragment
  priceFilterMin: number
  priceFilterMax: number | null
}

const GiftOptionProducts = ({
  giftOption,
  priceFilterMin,
  priceFilterMax,
}: Props) => {
  const { products, sections } = giftOption
  const [selectedSectionId, setSelectedSectionId] = useState("all")

  const sectionsById = useMemo(() => keyBy(sections, "id"), [sections])

  const availableSections: Store_GiftOptionDataFragment["sections"] = useMemo(
    () =>
      sections.length > 0
        ? [{ id: "all", name: "All", productList: [] }, ...sections]
        : [],
    [sections],
  )

  // Filter products using gift option sections.
  const filteredProducts = useMemo(() => {
    if (selectedSectionId === "all") {
      return products
    } else {
      const selectedSection = sectionsById[selectedSectionId]

      if (selectedSection) {
        const productIdSet = new Set(selectedSection.productList)

        return products.filter(({ id }) => productIdSet.has(id))
      } else {
        return []
      }
    }
  }, [products, sectionsById, selectedSectionId])

  const shippingPrice = giftOption.brand.shippingPrice || 0

  // Apply price filters.
  const qualifiedProducts = filteredProducts.filter(({ price }) => {
    let productShippingPrice = shippingPrice
    if (
      giftOption.brand.freeShippingMinimum &&
      price >= giftOption.brand.freeShippingMinimum
    ) {
      productShippingPrice = 0
    }

    const priceWithShipping = price + productShippingPrice

    return (
      priceWithShipping >= priceFilterMin &&
      (!priceFilterMax || priceWithShipping <= priceFilterMax)
    )
  })

  const otherProducts = filteredProducts.filter(
    (product) => !qualifiedProducts.includes(product),
  )

  return (
    <>
      {availableSections.length > 0 && (
        <Sections
          css={[
            giftOption.colors?.background
              ? {
                  backgroundColor: giftOption.colors.background,
                }
              : tw`bg-gray-050`,
          ]}
        >
          {availableSections.map(({ id, name }) => (
            <SectionPill
              key={id}
              onClick={() => setSelectedSectionId(id)}
              selected={id === selectedSectionId}
              title={name}
              color={giftOption.colors?.primary || getColor("primary-500")}
            >
              {name}
            </SectionPill>
          ))}
        </Sections>
      )}
      <div tw="px-4 pt-10">
        <SegmentUnavailable
          segments={giftOption.brand.segments}
          tw={"mb-6 mx-10"}
        />
        <div tw={"h-4"} />
        <GiftOptionProductsList
          giftOption={giftOption}
          products={qualifiedProducts}
        />
        {otherProducts.length > 0 ? (
          <>
            <div
              tw="text-center text-gray-500 font-text mb-6"
              css={[qualifiedProducts.length > 0 ? tw`mt-4` : tw`mt-2`]}
            >
              Other products
            </div>
            <GiftOptionProductsList
              giftOption={giftOption}
              products={otherProducts}
            />
          </>
        ) : null}

        {giftOptionIsGiftCard(giftOption) && (
          <div tw="text-center text-gray-400 text-sm mb-4 mt-10">
            The merchants represented are not sponsors of the rewards or
            otherwise affiliated with Goody. The logos and other identifying
            marks attached are trademarks of and owned by each represented
            company and/or its affiliates. Please visit each company's website
            for additional terms and conditions.
          </div>
        )}
      </div>
    </>
  )
}

const Sections = styled.div`
  ${tw`flex justify-center`};
`

const SectionPill = styled.button<{ selected: boolean; color: string }>`
  ${tw`border-b-[3px] border-transparent hover:border-gray-300 leading-5 px-4 text-xl mx-2 py-2 text-gray-500 hover:text-gray-600 transition`};

  ${({ selected, color }) =>
    selected &&
    css`
      ${tw`font-medium text-primary-500`};

      color: ${color};
      border-color: ${color};

      &:hover {
        border-color: ${color};
      }
    `}

  /**
    Reserves container space for bolded version of text. We need to
    pass in the link text to the "title" attribute.
   */
  &::before {
    ${tw`block font-medium h-0 invisible`};
    content: attr(title);
  }
`

export default GiftOptionProducts
