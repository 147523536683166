import { gql } from "@apollo/client"

import { SHIPMENT_FRAGMENT } from "./shipment_fragment"
import { CART_PRODUCT_SHIPMENT_FRAGMENT } from "../hooks/gifts"

export const TRACK_PAGE_GIFT_FRAGMENT = gql`
  fragment TrackPageGift on Gift {
    id
    status
    recipientFirstName
    recipientLastName
    thankYouNote
    isSwapped
    productFragment {
      id
      brandName
      brandId
      name
    }
    deliveryEta
    expiresAt
    openedAt
    acceptedAt
    shippedAt
    deliveredAt
    reminder1SentAt
    senderViewAccessId
    meetingStatus {
      meetingScheduled
    }
    cartProducts {
      ...CartProductShipment
    }
    shipments {
      ...Shipment
    }
  }

  ${SHIPMENT_FRAGMENT}
  ${CART_PRODUCT_SHIPMENT_FRAGMENT}
`
