import { Link, useRouteMatch } from "react-router-dom"

import {
  CalendarClockIcon,
  ChevronRightSmall,
  SmallGlobeIcon,
  TimerIcon,
} from "./assets/icons"
import { allRecipientsAccepted } from "./utils"
import { getExpiresAtCopy } from "./utils"

import { generateRealmPath } from "@/common/realm"
import {
  InternationalShippingTierEnum,
  Track_GiftBatchFragment,
  gift_meeting_setting,
} from "@/types/graphql-types"

interface Props {
  giftBatch?: Track_GiftBatchFragment | null
  directSend: boolean
}
export default function SettingsSummary({ directSend, giftBatch }: Props) {
  const routeMatch = useRouteMatch()
  if (giftBatch == null) {
    return (
      <div tw="text-gray-500 text-lg flex items-center justify-center h-full">
        Loading...
      </div>
    )
  }

  const shouldDisplayExpirationSection = (
    giftBatch: Track_GiftBatchFragment,
  ) => {
    return !(allRecipientsAccepted(giftBatch) && giftBatch.isExpired)
  }

  return (
    <div tw="h-full flex flex-col items-start px-5 lg:pl-6 pt-5 lg:pt-6 lg:pb-5 text-gray-500">
      {directSend ? (
        <div tw="h-full w-full flex items-center justify-center">
          Shipped directly to address
        </div>
      ) : (
        <div tw="flex flex-col gap-y-4">
          {shouldDisplayExpirationSection(giftBatch) && (
            <Link to={generateRealmPath("plus", `${routeMatch.url}/settings`)}>
              <div tw="flex flex-row items-center gap-2 hover:text-gray-800 active:text-gray-400">
                <TimerIcon />
                <div>{getExpiresAtCopy(giftBatch)}</div>
              </div>
            </Link>
          )}
          <Link to={generateRealmPath("plus", `${routeMatch.url}/settings`)}>
            <div tw="flex flex-row items-center gap-2 hover:text-gray-800 active:text-gray-400">
              <SmallGlobeIcon />
              <div>
                International shipping{" "}
                {giftBatch.internationalShippingTier ===
                InternationalShippingTierEnum.disabled
                  ? "disabled"
                  : "enabled"}
              </div>
            </div>
          </Link>
          {giftBatch?.meetingSettings?.giftSetting &&
            giftBatch.meetingSettings.giftSetting !==
              gift_meeting_setting.NO_MEETING && (
              <Link
                to={generateRealmPath("plus", `${routeMatch.url}/settings`)}
              >
                <div tw="flex flex-row items-center gap-2 hover:text-gray-800 active:text-gray-400">
                  <CalendarClockIcon />
                  <div>
                    {giftBatch.meetingSettings.giftSetting ===
                    gift_meeting_setting.MEETING_REQUIRED
                      ? "Meeting required"
                      : giftBatch.meetingSettings.giftSetting ===
                        gift_meeting_setting.GIFT_FIRST
                      ? "Meeting calendar enabled"
                      : null}{" "}
                  </div>
                </div>
              </Link>
            )}
        </div>
      )}
      <div tw="mt-8 lg:mt-auto w-full flex justify-center mb-5 lg:mb-0">
        <Link
          to={generateRealmPath("plus", `${routeMatch.url}/settings`)}
          tw="w-[132px] flex flex-row justify-center items-center gap-3 rounded-full hover:bg-gray-075 lg:p-2 active:bg-gray-150 transition-transform active:scale-90"
        >
          <div tw="text-gray-450">All settings</div>
          <ChevronRightSmall />
        </Link>
      </div>
    </div>
  )
}
