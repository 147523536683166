import {
  Store_GiftOptionDataFragment,
  Store_GiftOptionPreviewFragment,
} from "@/types/graphql-types"

export function isFullData(
  giftOption?:
    | Store_GiftOptionPreviewFragment
    | Store_GiftOptionDataFragment
    | null,
): giftOption is Store_GiftOptionDataFragment {
  return (
    (giftOption &&
      "products" in giftOption &&
      giftOption.products[0] &&
      "variants" in giftOption.products[0]) ||
    false
  )
}
