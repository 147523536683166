import { gql, useQuery } from "@apollo/client"
import React from "react"
import { Link, NavLink } from "react-router-dom"
import { useIntercom } from "react-use-intercom"
import tw from "twin.macro"

import TrialUpgradePromo from "./components/TrialUpgradePromo"
import { PLANS } from "./useSubscriptionPlanData"
import { ReactComponent as RightArrowIcon } from "../assets/icons/arrow-right.svg"
import { formatPrice } from "../common/format"
import { generateRealmPath } from "../common/realm"
import { formatDayWithDate, relativeTime } from "../common/utilities"
import PricingTabs from "../landing/pricing/PricingTabs"
import Container from "../sites/App/Container"

import {
  SubscriptionManagement,
  SubscriptionTrialCardStatus,
} from "@/types/graphql-types"
import {
  OrganizationMembershipAccess,
  OrganizationMembershipRole,
} from "@/types/graphql-types"
import { Subscriptions_OrganizationSubscriptionQuery } from "@/types/graphql-types"

const OrganizationSubscription = () => {
  const { data } = useQuery<Subscriptions_OrganizationSubscriptionQuery>(
    ORGANIZATION_SUBSCRIPTION_QUERY,
  )

  const { show } = useIntercom()

  const subscription = data?.organization?.subscription
  const memberships = data?.organization?.memberships
  const memberCount = memberships?.length || 0
  const adminCount = getAdminCount(memberships)
  const fullMemberCount = getFullMemberCount(memberships)
  const billedSeatCount = subscription?.billedSeatCount

  if (!data) {
    return null
  }

  const planType = subscription?.isTeamPlan ? "Teams" : "Employee Engagement"

  return (
    <>
      {subscription ? (
        <Container tw="px-5 pt-8" css={{ minHeight: "80vh" }}>
          {subscription.isTrialActive &&
            subscription.trialCardStatus ===
              SubscriptionTrialCardStatus.CARDLESS &&
            !subscription.isCancelRegistered && <TrialUpgradePromo />}
          <div tw="flex flex-col lg:flex-row items-stretch gap-6">
            <Box tw="flex-2">
              <BoxHeader>Plan</BoxHeader>
              <div tw="pt-6">
                <div tw="text-lg">Goody for {planType}</div>
                <div tw="text-lg text-gray-400">
                  {subscription.managementType ===
                  SubscriptionManagement.MANAGED
                    ? "Invoiced plan"
                    : getPlanTerms(subscription)}
                </div>
                <div tw="pt-6 mt-auto text-primary-600 font-text">
                  {getNextPlanEvent(subscription)}
                </div>
              </div>
            </Box>
            {subscription.isTeamPlan && (
              <Box tw="flex-1">
                <div tw="flex justify-between mb-2">
                  <p tw="font-bold">{billedSeatCount} paid seats</p>
                  <p tw="text-gray-500">
                    {subscription?.includedSeatCount} seats included in plan
                  </p>
                </div>
                <div tw="tabular-nums mb-2">
                  <div tw="flex justify-between border-b border-gray-200 py-1">
                    <p>
                      Admins <span tw="text-gray-400">&mdash; paid</span>
                    </p>
                    <p>{adminCount}</p>
                  </div>
                  <div tw="flex justify-between border-b border-gray-200 py-1">
                    <p>
                      Full Members <span tw="text-gray-400">&mdash; paid</span>
                    </p>
                    <p>{fullMemberCount - adminCount}</p>
                  </div>
                  <div tw="flex justify-between py-1">
                    <p tw="text-gray-400">Limited Members &mdash; free</p>
                    <p tw="text-gray-400">{memberCount - fullMemberCount}</p>
                  </div>
                </div>
                <NavLink
                  tw="text-primary-600 font-medium flex items-center"
                  to={generateRealmPath("plus", "/organization/members")}
                >
                  Manage members{" "}
                  <RightArrowIcon tw="ml-1 stroke-current scale-[0.8]" />
                </NavLink>
              </Box>
            )}
            <Box tw="flex-1">
              <BoxHeader>Actions</BoxHeader>
              <button
                tw="font-text text-gray-500 hover:text-gray-800 block mt-1 transition-colors"
                onClick={show}
              >
                Message support
              </button>
              <a
                href="mailto:plus-support@ongoody.com"
                rel="noopener nofollow noreferrer"
                tw="font-text text-gray-500 hover:text-gray-800 block mt-1 transition-colors"
              >
                Email support
              </a>
              {!subscription?.isCancelRegistered && (
                <Link
                  to={generateRealmPath(
                    "plus",
                    "/organization/subscription-cancel",
                  )}
                  tw="font-text text-gray-500 hover:text-gray-800 block mt-1 transition-colors"
                >
                  Cancel subscription
                </Link>
              )}
            </Box>
          </div>
        </Container>
      ) : (
        <div>
          <PricingTabs />
        </div>
      )}
    </>
  )
}
type OrganizationMemberships = NonNullable<
  Subscriptions_OrganizationSubscriptionQuery["organization"]
>["memberships"]
type OrganizationSubscriptionType = NonNullable<
  NonNullable<
    Subscriptions_OrganizationSubscriptionQuery["organization"]
  >["subscription"]
>

const getAdminCount = (memberships: OrganizationMemberships) =>
  memberships?.filter(({ role }) => role === OrganizationMembershipRole.admin)
    ?.length || 0

const getFullMemberCount = (memberships: OrganizationMemberships) =>
  memberships?.filter(
    ({ access }) => access === OrganizationMembershipAccess.full,
  )?.length || 0

function getPlanTerms(subscription: OrganizationSubscriptionType) {
  const planString = subscription.planSku.toString()
  const planData = PLANS[planString]

  if (planData) {
    let text = ""
    text += planData.interval === "monthly" ? "Monthly" : "Yearly"
    text += `, ${formatPrice(planData.price)}/${
      planData.interval === "monthly" ? "month" : "year"
    }`

    if (planData.additionalSeatPrice) {
      let additionalSeatPriceText =
        planData.interval === "monthly"
          ? planData.additionalSeatPrice
          : planData.additionalSeatPrice * 12
      text += ` + ${formatPrice(additionalSeatPriceText)}/${
        planData.interval === "monthly" ? "month" : "year"
      } per additional seat`
    }

    if (
      subscription.isTrialActive &&
      subscription.trialCardStatus === SubscriptionTrialCardStatus.CARD_ENTERED
    ) {
      text += " (trial)"
    }

    return text
  }

  return null
}

function getNextPlanEvent(subscription: OrganizationSubscriptionType) {
  // Trial
  if (subscription.isTrialActive && subscription.trialEnd) {
    const trialEndDate = new Date(subscription.trialEnd)

    const trialEndDateText = `${formatDayWithDate(
      trialEndDate,
    )}, ${trialEndDate.getFullYear()} (in about ${relativeTime(trialEndDate)})`

    if (subscription.isCancelRegistered) {
      return "\u2715 Canceled, downgrading soon"
    } else {
      if (
        subscription.trialCardStatus ===
        SubscriptionTrialCardStatus.CARD_ENTERED
      ) {
        return `Next bill when trial ends on ${trialEndDateText}`
      } else {
        return `Trial ends on ${trialEndDateText}`
      }
    }
  }

  // Non-trial
  if (!subscription.isTrialActive && subscription.currentPeriodEnd) {
    const currentPeriodEndDate = new Date(subscription.currentPeriodEnd)

    const currentPeriodEndDateText = `${formatDayWithDate(
      currentPeriodEndDate,
    )}, ${currentPeriodEndDate.getFullYear()} (in about ${relativeTime(
      currentPeriodEndDate,
    )})`

    if (subscription.isCancelRegistered) {
      return `\u2715 Canceled, downgrading on ${currentPeriodEndDateText}`
    } else {
      return `Next billing on ${currentPeriodEndDateText}`
    }
  }
}

const Box = tw.div`border border-gray-150 p-6 shadow-min rounded-xl`
const BoxHeader = tw.div`font-medium text-gray-600`

export const ORGANIZATION_SUBSCRIPTION_QUERY = gql`
  query Subscriptions_OrganizationSubscription {
    organization {
      id
      subscription {
        id
        planSku
        isTrialActive
        trialEnd
        currentPeriodEnd
        isCancelRegistered
        trialCardStatus
        managementType
        isTeamPlan
        includedSeatCount
        billedSeatCount
      }
      memberships {
        id
        role
        access
      }
    }
  }
`

export default OrganizationSubscription
