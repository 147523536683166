import { Helmet } from "react-helmet-async"

import BulkOrders from "./BulkOrders"
import CustomCollections from "./CustomCollections"
import FrequentlyAskedQuestions from "./FrequentlyAskedQuestions"
import Hero from "./Hero"
import OnDemandSwag from "./OnDemandSwag"
import OurServices from "./OurServices"
import WeDontCompromiseOnQuality from "./WeDontCompromiseOnQuality"
import Brands from "../../business/Brands"
import HowItWorks from "../components/HowItWorks"

const BrandedSwag = () => {
  return (
    <>
      <Helmet>
        <title>Branded Swag on Goody | Send Customized Corporate Gifts</title>
        <meta
          name="description"
          content="With Goody, you can send on-demand branded swag with no order minimums. Build bespoke swag boxes or create a digital swag closet."
        />
      </Helmet>
      <Hero />
      <OurServices />
      <div tw="pb-40">
        <Brands />
      </div>
      <OnDemandSwag />
      <HowItWorks
        stepOne={{
          header: "Design your swag",
          body: "Upload a high-quality logo and choose from our catalog of on-demand swag.",
        }}
        stepTwo={{
          header: "Send with an email",
          body: "Recipients get a beautiful digital card and unwrapping experience. They provide their size and shipping info.",
        }}
        stepThree={{
          header: "We do the rest",
          body: "We'll deliver your swag to their doorstep in 1-2 weeks. You pay only when someone accepts.",
        }}
      />
      <CustomCollections />
      <BulkOrders />
      <WeDontCompromiseOnQuality />
      <FrequentlyAskedQuestions />
    </>
  )
}

export default BrandedSwag
