import { MutableRefObject } from "react"
import { Gallery, Item } from "react-photoswipe-gallery"
import SwiperCore, { Navigation, Pagination, Thumbs } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "photoswipe/dist/photoswipe.css"
import "photoswipe/dist/default-skin/default-skin.css"
import tw, { styled } from "twin.macro"

import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import { env } from "./environment"
import GalleryNavArrow from "../assets/icons/gallery-nav-arrow.png"
import { ProductImage } from "../store/ProductImage"

import { ImageFragment } from "@/types/graphql-types"

interface SwipeableGalleryProps {
  thumbsSwiper?: SwiperCore | null
  images: GalleryImageProps[]
  disableExpansion?: boolean
  scalable?: boolean
}

const SwipeableGallery = ({
  thumbsSwiper,
  images,
  disableExpansion,
  scalable,
}: SwipeableGalleryProps) => {
  const galleryOptions = {
    clickToCloseNonZoomable: false,
    captionEl: false,
    fullscreenEl: false,
    shareEl: false,
    zoomEl: false,
  }

  // This avoids the need to use `useState`. This is set to the Swiper instance in the
  // `onSwiper` callback set on the `Swiper` component.
  let swiper: SwiperCore

  // @ts-ignore
  // Removing the `getThumbBoundsFn` prevents Photoswipe from zooming out the image
  // back into Swiper.
  const onGalleryOpen = (photoswipe) => {
    photoswipe.listen("close", () => {
      if (swiper?.activeIndex !== photoswipe.getCurrentIndex()) {
        photoswipe.options.getThumbBoundsFn = undefined
      }
    })
  }

  if (images.length === 0) {
    return null
  } else if (images.length === 1) {
    const { image, name, large } = images[0]
    return (
      <Gallery options={galleryOptions}>
        <GalleryImage
          name={name}
          image={image}
          large={large}
          disableExpansion={disableExpansion}
          scalable={scalable}
        />
      </Gallery>
    )
  }

  return (
    <>
      <StyledGallery options={galleryOptions} onOpen={onGalleryOpen}>
        <StyledSwiper
          autoHeight
          navigation
          modules={[Navigation, Pagination, Thumbs]}
          pagination={{ el: ".swiper-pagination" }}
          preventClicks={false}
          onSwiper={(swiperInstance: SwiperCore) => (swiper = swiperInstance)}
          watchSlidesProgress
          // HACK: In dev, this breaks if thumbsSwiper is null, so we set it to undefined.
          // In prod, thumbsSwiper being null is fine, but it breaks if it is undefined.
          // Maybe it's a Rollup thing, but we don't have time to figure out why.
          thumbs={
            thumbsSwiper
              ? { swiper: thumbsSwiper }
              : env.PROD
              ? { swiper: null }
              : undefined
          }
          tw="relative w-full"
        >
          <SwiperPaginationContainer>
            <div className="swiper-pagination" />
          </SwiperPaginationContainer>
          {images.map(({ image, large, name }) => (
            <SwiperSlide key={image.url}>
              <GalleryImage
                name={name}
                image={image}
                large={large}
                disableExpansion={disableExpansion}
                scalable={scalable}
              />
            </SwiperSlide>
          ))}
        </StyledSwiper>
      </StyledGallery>
    </>
  )
}

export interface GalleryImageProps {
  name: string
  image: ImageFragment
  large: ImageFragment
  disableExpansion?: boolean
  scalable?: boolean
}

export const GalleryImage = ({
  name,
  image,
  large,
  disableExpansion,
  scalable,
}: GalleryImageProps) => {
  const { height, url, width } = image

  return (
    <Item
      height={height ?? undefined}
      original={url}
      thumbnail={large.url}
      width={width ?? undefined}
    >
      {({ ref, open }) => (
        <ProductImage
          ref={ref as MutableRefObject<HTMLImageElement>}
          onClick={!disableExpansion ? open : undefined}
          image={large}
          alt={name}
          scalable={scalable}
          tw="cursor-pointer w-full"
          css={[disableExpansion && tw`cursor-default `]}
        />
      )}
    </Item>
  )
}

const StyledGallery = styled(Gallery)`
  ${tw`opacity-0 transition-all duration-300 z-20 hidden`};

  &.pswp--open {
    ${tw`lg:block opacity-100 top-0 left-0 w-screen h-screen`};
  }

  > .pswp__bg {
    background: rgba(220, 220, 220, 0.75);
  }

  @keyframes scaleDown {
    0% {
      ${tw`scale-100`}
    }
    50% {
      ${tw`scale-100`}
    }
    75% {
      ${tw`scale-95`}
    }
  }

  .pswp__img {
    ${tw`rounded-2xl transform scale-95`};
    animation: scaleDown 0.3s;
    box-shadow:
      rgba(0, 0, 0, 0.075) 0 4px 8px 0,
      rgba(0, 0, 0, 0.075) 0 16px 16px 0;

    + .pswp__img {
      animation: none;
      ${tw`transition-transform duration-300 transform scale-100`};
    }
  }

  &.pswp--animated-in .pswp__img {
    &:first-child {
      box-shadow: none;
    }

    &:last-child {
      ${tw`transition-transform transform scale-95`};
    }
  }
`

const StyledSwiper = styled(Swiper)`
  .swiper-button-prev,
  .swiper-button-next {
    ${tw`h-full mt-0 opacity-0 top-0 transition px-2.5 w-14`};

    &.swiper-button-disabled {
      ${tw`pointer-events-auto opacity-0`};
    }

    &::before {
      ${tw`h-full opacity-0 transition w-full z-10`};
      content: "";
      position: absolute;
    }

    &::after {
      ${tw`h-9 w-9 z-20`};
      background-image: url(${GalleryNavArrow});
      content: "";
    }
  }

  .swiper-button-next {
    right: 0;

    &::before {
      background: linear-gradient(
        270deg,
        rgba(135, 135, 135, 0.44),
        rgba(135, 135, 135, 0)
      );
      right: 0;
    }
  }

  .swiper-button-prev {
    left: 0;

    &::before {
      ${tw`h-full w-full`};
      background: linear-gradient(
        90deg,
        rgba(135, 135, 135, 0.44),
        rgba(135, 135, 135, 0)
      );
      left: 0;
    }

    &::after {
      transform: scaleX(-1);
    }
  }

  .swiper-slide {
    width: 100% !important;
  }

  &:hover {
    .swiper-button-prev,
    .swiper-button-next {
      ${tw`opacity-75`};

      &:hover {
        ${tw`opacity-100`};

        &::before {
          opacity: 0.65;
        }
      }
    }

    .swiper-button-disabled {
      ${tw`opacity-0`};

      &:hover {
        ${tw`opacity-0`};
      }
    }
  }
`

const SwiperPaginationContainer = styled.div`
  ${tw`absolute bottom-2 flex justify-center left-0 w-full`};

  .swiper-pagination {
    ${tw`relative rounded-lg`};
    background: rgba(255, 255, 255, 0.5);
    line-height: 0;
    padding: 3px 4px;

    .swiper-pagination-bullet {
      ${tw`h-1.5 mr-1.5 w-1.5`};
      background: rgba(0, 0, 0, 0.5);

      &-active {
        ${tw`bg-black`};
      }
    }
  }

  .swiper-pagination-horizontal {
    width: fit-content;
  }

  @media (min-width: 1024px) {
    display: none;
  }
`

export default SwipeableGallery
