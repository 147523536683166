import { useEffect, useRef } from "react"
import {
  ReactGoogleAutocompleteProps,
  usePlacesWidget,
} from "react-google-autocomplete"

export type PlaceResult = Parameters<
  NonNullable<ReactGoogleAutocompleteProps["onPlaceSelected"]>
>[0]

export interface AutocompletePlace {
  address1?: string
  city?: string
  state?: string
  postalCode?: string
  country?: string
}

interface Props {
  onAutocompletePlace: (place: AutocompletePlace) => void
}

// Implements Google Places Autocomplete. Pass a callback `onAutocompletePlace`
// to run when a place is selected. Returns `autocompleteFieldRef` which should
// be attached to the <input> that should trigger autocomplete.
export function usePlacesAutocomplete({ onAutocompletePlace }: Props) {
  const onPlaceSelected = useRef<(place: PlaceResult) => void>(() => undefined)

  // This works around the fact that onPlaceSelected is not reactive
  // https://github.com/ErrorPro/react-google-autocomplete/issues/168
  // When the onAutocompletePlace function changes, update the ref
  useEffect(() => {
    onPlaceSelected.current = (place: PlaceResult) => {
      onAutocompletePlace(transformAutocompleteResult(place))
    }
  }, [onAutocompletePlace])

  const { ref: autocompleteFieldRef } = usePlacesWidget<HTMLInputElement>({
    apiKey: import.meta.env.VITE_GOOGLE_PLACES_PUBLIC_API_KEY,
    onPlaceSelected: (place) => {
      onPlaceSelected.current(place)
    },
    options: {
      types: ["geocode"],
      fields: ["address_component"],
      componentRestrictions: { country: "US" },
    },
  })

  return {
    autocompleteFieldRef,
  }
}

// Determines, for each address component, which type of format to use.
const formatForAddressComponent: { [id: string]: "long_name" | "short_name" } =
  {
    street_number: "short_name",
    route: "long_name",
    locality: "long_name",
    postal_town: "long_name",
    administrative_area_level_1: "short_name",
    sublocality_level_1: "long_name",
    country: "short_name",
    postal_code: "short_name",
  }

export function transformAutocompleteResult(
  place: PlaceResult,
): AutocompletePlace {
  const result: AutocompletePlace = {}

  if (!place.address_components) {
    return result
  }

  let fullStreet = []

  // Get each component of the address from the place details,
  // and then fill-in the corresponding field on the form.
  for (const component of place.address_components) {
    // An address component can refer to multiple types, but we use the first
    const addressType = component.types[0]

    const format = formatForAddressComponent[addressType]

    if (format) {
      const val = component[format]

      switch (addressType) {
        case "street_number":
          // The street number and address are given separately
          fullStreet.unshift(val)
          break
        case "route":
          fullStreet.push(val)
          break
        case "locality":
          result.city = val
          break
        case "postal_town":
          result.city = val
          break
        case "sublocality_level_1":
          result.city = val
          break
        case "administrative_area_level_1":
          result.state = val
          break
        case "postal_code":
          result.postalCode = val
          break
      }
    }
  }

  if (fullStreet.length > 0) {
    result.address1 = fullStreet.join(" ")
  }

  return result
}
