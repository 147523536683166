import { useState } from "react"

import { createHookContext } from "@/common/hooks/createHookContext"
import { useFeatureFlags } from "@/common/hooks/featureFlags"
import { useTopUpUI } from "@/send/hooks/topUpUI"

export const useTopUpExperimentLocal = () => {
  const { hasFeatureFlag } = useFeatureFlags()
  const hasFeature = hasFeatureFlag("top_up_send")

  const { canDisplayTopUpUI, topUpAmount } = useTopUpUI()

  const canExposeToTopUpExperiment = hasFeature && canDisplayTopUpUI

  const [experimentData, setExperimentData] = useState({
    displayTopUpExperiment: false,
    isLoading: false,
  })

  return {
    canExposeToTopUpExperiment,
    experimentData,
    setExperimentData,
    canDisplayTopUpUI,
    topUpAmount,
  }
}

export const {
  Provider: TopUpExperimentProvider,
  useHook: useTopUpExperiment,
  useHookUnsafe: useTopUpExperimentUnsafe,
} = createHookContext("TopUpExperiment", useTopUpExperimentLocal)
