import { useEffect } from "react"

interface ScriptProps {
  src: string
  onLoad?: Function
  async?: boolean
}

const DATA_LOADED = "data-loaded"

const findOrCreateScript = ({ src, onLoad, async }: ScriptProps) => {
  const script: HTMLScriptElement =
    document.querySelector(`script[src="${src}"]`) ||
    document.createElement("script")

  if (script.src !== src) {
    script.src = src
    script.onload = () => script.setAttribute(DATA_LOADED, "true")
    script.async = !!async
    document.head.appendChild(script)
  }
  return script
}

const isScriptLoaded = (script: HTMLScriptElement) =>
  script.getAttribute(DATA_LOADED) === "true"

const observeScriptLoad = (script: HTMLScriptElement, onLoad?: Function) => {
  const observer = new MutationObserver((mutations) => {
    for (const mutation of mutations) {
      if (
        mutation.type === "attributes" &&
        mutation.attributeName === DATA_LOADED &&
        isScriptLoaded(mutation.target as HTMLScriptElement)
      ) {
        onLoad?.()
        observer.disconnect()
        return
      }
    }
  })
  observer.observe(script, { attributes: true })
  return observer
}

const Script = ({ onLoad, ...props }: ScriptProps) => {
  useEffect(() => {
    const script: HTMLScriptElement = findOrCreateScript({ onLoad, ...props })

    if (isScriptLoaded(script)) {
      onLoad?.()
    } else if (onLoad) {
      const observer = observeScriptLoad(script, onLoad)
      return () => observer.disconnect()
    }
  }, [])

  return null
}

export default Script

export const HubSpot = (props: Omit<ScriptProps, "src">) => (
  <Script src="https://js.hsforms.net/forms/v2.js" {...props} />
)
