import React from "react"
import { Link, useHistory } from "react-router-dom"
import tw, { styled } from "twin.macro"

import { getProductName, getProductSubtitle } from "../../../common/gifts"
import { Product } from "../../../common/GlobalState"
import { generateRealmPath } from "../../../common/realm"
import SmallButton from "../../../common/SmallButton"
import { summarizeVariants } from "../../../common/utilities"
import { useBrowseUrls } from "../../../store/StoreHelpers"

import { CartProductImage } from "@/common/cart/CartProductImage"

interface Props {
  cart: Product[]
  showChangeGiftsButton?: boolean
  smallSize?: boolean
}

export default function CartContents({
  cart,
  showChangeGiftsButton,
  smallSize,
}: Props) {
  const { generateProductUrl } = useBrowseUrls()

  const history = useHistory()

  return (
    <div tw="flex flex-col gap-5">
      {cart.map((product, index) => (
        <div
          key={index}
          tw={
            "flex flex-row sm:items-center items-start flex-wrap lg:flex-nowrap"
          }
        >
          <Link
            tw={"flex flex-row flex-1 items-center"}
            to={generateProductUrl(product)}
          >
            <div tw={"relative w-[84px]"}>
              <CartProductImage
                tw="rounded-xl h-[84px] w-[84px]"
                product={product}
              />
              {product.quantity && product.quantity > 1 && (
                <QuantityTag>{product.quantity}</QuantityTag>
              )}
            </div>
            <div
              tw={
                "flex-1 flex sm:flex-row flex-col sm:items-center items-start"
              }
            >
              <div tw={"flex flex-col flex-1 ml-6"}>
                <div css={smallSize ? tw`text-lg` : { fontSize: "1.375rem" }}>
                  {getProductName(product)}
                </div>
                <div tw={"text-lg text-gray-400 mt-0.5"}>
                  {getProductSubtitle(product)}
                </div>
                {product.variants !== null && (
                  <div tw={"text-sm mt-1.5 text-gray-400"}>
                    {summarizeVariants(product.variants)}
                  </div>
                )}
              </div>
            </div>
          </Link>
          {index === 0 && showChangeGiftsButton && (
            <SmallButton
              tw={"mt-4 sm:mt-0 w-full sm:w-auto justify-between"}
              onClick={() => {
                history.push(generateRealmPath(null, "/browse"))
              }}
              rounded
              label={"Change gifts"}
            />
          )}
        </div>
      ))}
    </div>
  )
}

const QuantityTag = styled.div`
  ${tw`absolute right-2 top-2 bg-white rounded-full flex items-center justify-center text-gray-700 text-sm`}
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
  width: 24px;
  height: 24px;
`
