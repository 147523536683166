import { Send_GiftBatchCreateMutation } from "@/types/graphql-types"
import "twin.macro"

interface Props {
  errors: NonNullable<
    Send_GiftBatchCreateMutation["giftBatchCreate"]["recipientErrors"]
  >
}

export default function DirectSendRecipientErrors({ errors }: Props) {
  return (
    <div tw="px-6 sm:px-9 pt-6">
      {errors.map((error) => (
        <div
          tw="bg-red-100 text-red-600 rounded-lg mt-1 rounded-lg px-3 py-2"
          key={`${error.fieldName} ${error.error}`}
        >
          {error.error}
        </div>
      ))}
    </div>
  )
}
