import React from "react"

import GiftSelectButton from "./GiftSelectButton"
import GiftOfChoiceIcon from "../images/gift-of-choice.svg"

import { track } from "@/common/analytics"
import { generateRealmPath } from "@/common/realm"

const GiftOfChoiceGiftSelectButton = () => (
  <GiftSelectButton
    title="Let recipient choose"
    subtitle="Send a gift of choice"
    icon={GiftOfChoiceIcon}
    iconName="giftOfChoice"
    to={generateRealmPath("plus", "/browse/category/gift-of-choice")}
    onClick={() => track("Business - Send - Start Gift - Click Gift of Choice")}
  />
)

export default GiftOfChoiceGiftSelectButton
