import { useEffect, useState } from "react"
import tw from "twin.macro"

import { useGiftData } from "@/common/hooks/giftData"
import { usePaymentMethods } from "@/common/hooks/paymentMethods"
import { PaymentMethod } from "@/common/PaymentMethodsManager"
import ChevronDown from "@/common/payments/miniPaymentMethodSelector/assets/icons/chevron-down-primary.svg"
import { MiniPaymentMethodLabel } from "@/common/payments/miniPaymentMethodSelector/MiniPaymentMethodLabel"
import { MiniPaymentMethodSelector } from "@/common/payments/miniPaymentMethodSelector/MiniPaymentMethodSelector"

export const MiniSelectedPaymentMethod = () => {
  const { setTopUpPaymentMethod } = useGiftData()
  const { paymentMethods } = usePaymentMethods()
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<PaymentMethod>(paymentMethods[0])
  const [formOpen, setFormOpen] = useState(false)

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    setFormOpen(!formOpen)
  }

  useEffect(() => {
    setTopUpPaymentMethod(selectedPaymentMethod)
  }, [selectedPaymentMethod])

  const onPaymentMethodSelect = (paymentMethod: PaymentMethod) => {
    setSelectedPaymentMethod(paymentMethod)
    setFormOpen(false)
  }

  return (
    <div>
      <button
        onClick={handleClick}
        className="payment-method-button"
        tw="flex flex-row items-center p-1 rounded hover:bg-white active:bg-primary-new-000 transition active:scale-90"
      >
        <MiniPaymentMethodLabel
          paymentMethod={selectedPaymentMethod}
          displayLabel={true}
        />
        <div tw="mr-1 min-w-[10px]">
          <img
            src={ChevronDown}
            tw="transition-transform"
            css={[formOpen && tw`transform rotate-180`]}
          />
        </div>
      </button>
      <MiniPaymentMethodSelector
        isExpanded={formOpen}
        onSelect={onPaymentMethodSelect}
      />
    </div>
  )
}
