import { useMutation } from "@apollo/client"
import React, { useState } from "react"

import { useFeatureAccess } from "@/common/hooks/featureAccess"
import useTeamPlan from "@/common/hooks/teamPlan"
import { OrganizationCreateSamlIdentityProvider } from "@/organization/settings/saml/graphql/OrganizationCreateSamlIdentityProvider"
import { useSamlSettings } from "@/organization/settings/saml/hooks/samlSettings"
import SamlContent from "@/organization/settings/saml/SamlContent"
import SamlUpsell from "@/organization/settings/saml/SamlUpsell"
import Section from "@/organization/settings/Section"
import {
  OrganizationCreateSamlIdentityProviderMutation,
  OrganizationCreateSamlIdentityProviderMutationVariables,
} from "@/types/graphql-types"

interface Props {
  samlEnabled: boolean
  samlEnabledLoading: boolean
  organizationId?: string
}

const Saml = ({ samlEnabled, samlEnabledLoading, organizationId }: Props) => {
  const [isEditing, setIsEditing] = useState(false)
  const { identityProvider, setIdentityProvider } = useSamlSettings()
  const { onTeamPlan, loading: teamPlanLoading } = useTeamPlan()
  const { hasFeature } = useFeatureAccess()

  const [
    samlIdentityProviderCreate,
    { loading: samlIdentityProviderCreateLoading },
  ] = useMutation<
    OrganizationCreateSamlIdentityProviderMutation,
    OrganizationCreateSamlIdentityProviderMutationVariables
  >(OrganizationCreateSamlIdentityProvider)

  const handleSetup = () => {
    createIdentityProvider()
    setIsEditing(true)
  }

  const createIdentityProvider = async () => {
    if (organizationId) {
      const result = await samlIdentityProviderCreate({
        variables: {
          organizationId: organizationId,
        },
      })

      const data = result?.data?.samlCreateIdentityProvider
      if (data?.ok && data.samlIdentityProvider) {
        setIdentityProvider(data.samlIdentityProvider)
      }
    }
  }

  return (
    <Section
      title="SAML SSO"
      subtext={
        <a
          href="https://intercom.help/goody/en/articles/9359288-set-up-saml-single-sign-on"
          tw="pb-3 text-gray-500"
        >
          View SAML documentation
        </a>
      }
    >
      {onTeamPlan || hasFeature("saml") ? (
        <SamlContent
          loading={
            samlEnabledLoading ||
            samlIdentityProviderCreateLoading ||
            teamPlanLoading
          }
          enabled={samlEnabled || identityProvider?.enabled || false}
          isEditing={isEditing}
          handleSetup={handleSetup}
          onSave={() => {
            setIsEditing(false)
            window.scrollTo(0, 0)
          }}
        />
      ) : (
        <SamlUpsell />
      )}
    </Section>
  )
}

export default Saml
