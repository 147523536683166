import { ReactComponent as InfoIcon } from "../../assets/icons/info.svg"
import { useGlobalState } from "../GlobalState"

const LearnAboutFullVsLimitedModalButton = ({ ...props }) => {
  const [_, setIsOpen] = useGlobalState("isLearnAboutFullVsLimitedModalOpen")

  const onClick = () => {
    setIsOpen(true)
  }

  return (
    <div {...props}>
      <button
        type="button"
        tw="flex items-center text-gray-500"
        onClick={onClick}
      >
        <InfoIcon tw="mr-2 text-gray-450" />
        Learn about full vs. limited members
      </button>
    </div>
  )
}

export default LearnAboutFullVsLimitedModalButton
