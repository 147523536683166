import tw from "twin.macro"

import GiftTemplatesMenu, { GiftTemplateType } from "./GiftTemplatesMenu"

interface GiftTemplateDropdownProps {
  open: boolean
  setOpen: (open: boolean) => void
  setGiftTemplate: (giftTemplate: GiftTemplateType) => {}
}

const GiftTemplateDropdown = ({
  open,
  setOpen,
  setGiftTemplate,
}: GiftTemplateDropdownProps) => {
  return (
    <div>
      <div tw="relative">
        <div
          tw="absolute right-0 w-3/4 bg-[#f6faff] rounded-xl z-[1020] scale-90 opacity-0 pointer-events-none transition-all origin-top lg:(origin-[80% top])"
          css={[open && tw`scale-100 opacity-100 pointer-events-auto`]}
        >
          <GiftTemplatesMenu
            onSelect={(giftTemplate: GiftTemplateType) => {
              setGiftTemplate(giftTemplate)
              setOpen(false)
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default GiftTemplateDropdown
