import { capitalize } from "lodash-es"
import React, { useMemo, useState } from "react"
import tw, { styled } from "twin.macro"

import { BatchRecipient } from "../../common/GlobalState"
import Pagination from "../../common/pagination/Pagination"

const RECIPIENTS_PER_PAGE = 15

interface Props {
  count: number
  recipients: BatchRecipient[]
  onClearRecipients: () => void
}

export default function CollapsedRecipients({
  count,
  recipients,
  onClearRecipients,
}: Props) {
  const [page, setPage] = useState(0)
  const offset = page * RECIPIENTS_PER_PAGE
  const previewSet = recipients.slice(offset, offset + RECIPIENTS_PER_PAGE)

  const errorRecipients = useMemo<BatchRecipient[]>(() => {
    return recipients.filter((recipient) => {
      return Object.keys(recipient.errors).length > 0
    })
  }, [recipients])

  const errorCount = errorRecipients.length

  return (
    <div tw="border border-gray-200 rounded-lg w-full p-4 lg:p-12">
      <div tw="text-2xl text-center text-gray-700 pb-3 font-medium">
        {count} recipients
      </div>
      <div tw="text-lg text-center text-gray-500 font-text">
        You can preview your recipients below.
      </div>
      <div tw="text-lg text-center pb-8 text-gray-500 font-text">
        <button tw="hover:text-gray-600" onClick={onClearRecipients}>
          Clear list
        </button>
      </div>
      {errorCount > 0 && (
        <div
          tw="-mx-4 lg:-mx-12 px-4 px-12 py-8 mb-12 bg-red-100"
          className="field-error"
        >
          <div tw="text-2xl text-center text-gray-700 pb-3 font-medium text-red-500">
            {errorCount} error{errorCount === 1 ? "" : "s"}
          </div>
          <PreviewTable>
            <thead>
              <tr>
                <th>First name</th>
                <th>Last name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Error</th>
              </tr>
            </thead>
            <tbody>
              {errorRecipients.map((recip) => (
                <tr>
                  <td>{recip.firstName}</td>
                  <td>{recip.lastName}</td>
                  <td>{recip.email}</td>
                  <td>{recip.phone}</td>
                  <td>
                    {Object.keys(recip.errors).map((key, idx) => (
                      <div key={key}>
                        {/* @ts-ignore sigh */}
                        {capitalize(key)}: {recip.errors[key]}
                      </div>
                    ))}
                  </td>
                </tr>
              ))}
            </tbody>
          </PreviewTable>
        </div>
      )}
      <PreviewTable>
        <thead>
          <tr>
            <th>First name</th>
            <th>Last name</th>
            <th>Email</th>
            <th>Phone</th>
          </tr>
        </thead>
        <tbody>
          {previewSet.map((recip) => (
            <tr>
              <td>{recip.firstName}</td>
              <td>{recip.lastName}</td>
              <td>{recip.email}</td>
              <td>{recip.phone}</td>
            </tr>
          ))}
        </tbody>
      </PreviewTable>

      <Pagination
        pageIndex={page}
        perPage={RECIPIENTS_PER_PAGE}
        setPageIndex={setPage}
        totalCount={count}
        preventScrollToTop={true}
      />
    </div>
  )
}

const PreviewTable = styled.table`
  ${tw`mb-8 w-full font-text text-lg`}

  thead {
    ${tw`border-b border-gray-300 font-normal`}

    th {
      ${tw`text-left py-2 font-normal text-gray-500`}
    }
  }

  tbody {
    tr {
      ${tw`transition-colors`}

      &:hover {
        ${tw`bg-gray-050`}
      }
    }

    td {
      ${tw`py-2`}
    }
  }
`
