import tw from "twin.macro"

import { ReactComponent as ChevronRight } from "@/assets/icons/chevron-right.svg"
import { CountryFlag } from "@/common/CountryFlag"
import {
  GLOBAL_COUNTRY,
  useShippingCountries,
} from "@/store/providers/ShippingCountriesProvider"

interface Props {
  countries: string[]
  swap?: boolean
  onGlobalInfoClick?: () => void
}

const InternationalShippingCountryList = ({
  countries,
  swap,
  onGlobalInfoClick,
}: Props) => {
  const { expandShippingCountries } = useShippingCountries()

  const shippingCountries = expandShippingCountries(countries)

  const includeGlobalCountry = countries.includes(GLOBAL_COUNTRY.code)

  return (
    <div tw="flex flex-col gap-2">
      {shippingCountries.map((country) => (
        <div tw="flex justify-between items-center" key={country.code}>
          <div tw="flex gap-4 items-center">
            <CountryFlag code={country.code} size="m" />
            <div tw="flex gap-2">
              <span tw="text-black text-base font-normal">{country.name}</span>
              {country.code === GLOBAL_COUNTRY.code && (
                <GlobalInfoTag onClick={onGlobalInfoClick}>
                  <span tw="text-gray-450 text-sm font-normal">info</span>
                  <ChevronRight tw="h-4 w-3 text-gray-400 stroke-current" />
                </GlobalInfoTag>
              )}
            </div>
          </div>
          {swap && includeGlobalCountry && (
            <span tw="text-gray-450 text-sm font-normal">
              {country.code === GLOBAL_COUNTRY.code ? "Up to $50" : "No fee"}
            </span>
          )}
        </div>
      ))}
    </div>
  )
}

const GlobalInfoTag = tw.div`
  cursor-pointer flex items-center gap-1 px-2
  border border-gray-250 rounded-full
  hover:bg-gray-075 active:bg-gray-150
`

export default InternationalShippingCountryList
