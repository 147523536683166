import "twin.macro"
import { Link } from "react-router-dom"
import tw from "twin.macro"

import BrandLogo from "../components/BrandLogo"
import BrandValueBadge, { isBrandValueKey } from "../components/BrandValueBadge"

import { isBlank } from "@/common/format"
import { generateRealmPath } from "@/common/realm"
import {
  Store_PDP_ProductDetailsFragment,
  Store_PDP_RelatedProductsQuery,
} from "@/types/graphql-types"

interface Props {
  brand: Store_PDP_ProductDetailsFragment["brand"]
  primaryGiftOption?: NonNullable<
    Store_PDP_RelatedProductsQuery["productDetails"]
  >["brand"]["primaryGiftOption"]
}

export default function ProductBrandDetails({
  brand,
  primaryGiftOption,
}: Props) {
  const brandValues = brand.brandValues.map(
    (brandValue) =>
      isBrandValueKey(brandValue) && (
        <BrandValueBadge
          key={brandValue}
          active={false}
          value={brandValue}
          small={true}
          gray={true}
          whiteBackground={true}
        />
      ),
  )

  if (brandValues.length === 0 && isBlank(brand.subtitle)) {
    return null
  }

  return (
    <div tw="px-5 lg:px-12 mt-5">
      <BrandDetails>
        <div>
          <Link
            to={
              primaryGiftOption?.slug
                ? generateRealmPath(
                    "plus",
                    "/browse/gift-option/" + primaryGiftOption.slug,
                  )
                : "/browse/"
            }
            tw="hover:text-primary-500 transition-colors"
          >
            {brand.logoImage ? (
              <BrandLogo
                alt={brand.name}
                logoImage={brand.logoImage}
                maxWidthFactor={1.4}
              />
            ) : (
              <div tw="text-center text-xl font-medium">{brand.name}</div>
            )}
          </Link>
        </div>
        {brand.subtitle && (
          <div tw="text-gray-600 flex-1">{brand.subtitle}</div>
        )}
        {brandValues.length > 0 && (
          <div tw="flex-1 flex flex-wrap justify-start -mb-2">
            {brandValues}
          </div>
        )}
      </BrandDetails>
    </div>
  )
}
const BrandDetails = tw.div`
  flex flex-col lg:flex-row justify-center items-center p-9 bg-gray-050 w-full gap-12 rounded-xl
`
