import { gql, useMutation } from "@apollo/client"
import { useState } from "react"
import { toast } from "react-hot-toast"
import { useHistory } from "react-router-dom"

import Button from "../common/Button"
import { useCurrentWorkspace } from "../common/hooks"
import { generateRealmPath } from "../common/realm"
import { ROOT_DATA_QUERY } from "../graphql"
import { WORKSPACE_UPDATE } from "../organization/Workspaces/EditWorkspaceDrawer"

import {
  WorkspaceFragment,
  Workspace_LeaveMutation,
  Workspace_LeaveMutationVariables,
  Workspace_UpdateMutation,
  Workspace_UpdateMutationVariables,
} from "@/types/graphql-types"

interface WorkspaceSettingsProps {
  workspace: WorkspaceFragment
  isManager: boolean
}

const WorkspaceSettings = ({
  workspace,
  isManager,
}: WorkspaceSettingsProps) => {
  const [updateWorkspace, { loading: updateLoading }] = useMutation<
    Workspace_UpdateMutation,
    Workspace_UpdateMutationVariables
  >(WORKSPACE_UPDATE)

  const [leaveWorkspace] = useMutation<
    Workspace_LeaveMutation,
    Workspace_LeaveMutationVariables
  >(WORKSPACE_LEAVE_MUTATION)

  const history = useHistory()
  const { currentWorkspace, resetCurrentWorkspace } = useCurrentWorkspace()

  const [workspaceName, setWorkspaceName] = useState(workspace.name)

  const handleLeaveWorkspace = async () => {
    const res = await leaveWorkspace({
      variables: { id: workspace.id },
      refetchQueries: [
        {
          query: ROOT_DATA_QUERY,
        },
      ],
    })

    if (res.data?.workspaceLeave.ok) {
      toast.success(`Left workspace.`, {
        iconTheme: {
          primary: "#27ae60",
          secondary: "#fff",
        },
      })

      if (currentWorkspace && currentWorkspace.id === workspace.id) {
        resetCurrentWorkspace()
      }

      history.push(generateRealmPath("plus", "/workspaces"))
    } else {
      alert("An unexpected error occurred.")
    }
  }

  const handleUpdate = async () => {
    const res = await updateWorkspace({
      variables: { id: workspace.id, fields: { name: workspaceName } },
      // Even though updateWorkspace will return a WorkspaceType and update the cache, we
      // store the name of the workspace in global state (which we should refactor),
      // so we need to refetch the root query
      refetchQueries: [
        {
          query: ROOT_DATA_QUERY,
        },
      ],
    })

    if (res.data?.workspaceUpdate.ok) {
      toast.success(`Updated name.`, {
        iconTheme: {
          primary: "#27ae60",
          secondary: "#fff",
        },
      })
    } else {
      if (res.data?.workspaceUpdate.error) {
        alert(res.data.workspaceUpdate.error)
      } else {
        alert("An unexpected error occurred.")
      }
    }
  }

  return (
    <div tw="py-9 px-6 lg:px-12">
      {isManager && (
        <div tw="pb-12">
          <h2 tw="text-lg font-medium pb-2">Workspace name</h2>
          <div tw="pb-2">
            <input
              type="text"
              value={workspaceName}
              onChange={(e) => setWorkspaceName(e.target.value)}
              tw="border border-gray-300 focus:border-primary-300 active:border-primary-300 focus:outline-none text-lg px-4 py-3 rounded-lg w-full lg:w-96 transition-colors"
            />
          </div>
          <Button disabled={updateLoading} onClick={() => handleUpdate()}>
            Update name
          </Button>
        </div>
      )}

      <div tw="pb-12">
        <h2 tw="text-lg font-medium pb-1">Leave workspace</h2>
        <div tw="text-gray-500 pb-2">
          {workspace.canLeaveWorkspace
            ? "Once you leave the workspace, you'll need to be re-invited to rejoin the workspace."
            : "You can't leave the workspace since you're the only manager in this workspace. You'll need to set another member as a manager to leave the workspace."}
        </div>
        {workspace.canLeaveWorkspace && (
          <Button
            onClick={() => {
              if (
                window.confirm("Are you sure you want to leave the workspace?")
              ) {
                handleLeaveWorkspace()
              }
            }}
          >
            Leave workspace
          </Button>
        )}
      </div>
    </div>
  )
}

const WORKSPACE_LEAVE_MUTATION = gql`
  mutation Workspace_Leave($id: ID!) {
    workspaceLeave(id: $id) {
      ok
    }
  }
`

export default WorkspaceSettings
