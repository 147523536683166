import React from "react"
import tw, { styled } from "twin.macro"

const ThreeColumnLayout: React.FC = ({ children }) => {
  return <Container>{children} </Container>
}

const Container = styled.div`
  ${tw`flex flex-col lg:flex-row lg:space-x-8`}
  > div {
    ${tw`flex-1 mb-8`}
  }
`
export default ThreeColumnLayout
