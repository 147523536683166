import { useCountry } from "../GiftOptionCountryProvider"
import { Countries } from "../giftOptionFilters/internationalShipping/InternationalShipping"

import { useFeatureAccess } from "@/common/hooks/featureAccess"

export const useRequestInternationalProduct = (shippingCountries: string[]) => {
  const { hasFeature } = useFeatureAccess()
  const {
    giftOptionSelectedShippingCountry,
    setGiftOptionSelectedShippingCountry,
  } = useCountry()

  return () => {
    if (hasFeature("pro_plan")) {
      return true
    }

    if (shippingCountries.includes(Countries.UNITED_STATES)) {
      if (giftOptionSelectedShippingCountry === Countries.UNITED_STATES) {
        return true
      }

      if (
        window.confirm(
          "You need to have Goody for Business Pro to send this gift internationally. Do you want to send this as a US gift instead?",
        )
      ) {
        setGiftOptionSelectedShippingCountry(Countries.UNITED_STATES)
        return true
      }
    } else {
      window.alert(
        "You need to have Goody for Business Pro to send this gift internationally.",
      )
    }

    return false
  }
}
