import staticAssetUrl from "../../../common/staticAssetUrl"
import Section, {
  SectionContainer,
  SectionContent,
  SectionHeader,
  SectionHeaderEm,
  SectionPill,
} from "../Section"

export default function Revshare() {
  return (
    <SectionContainer>
      <Section tw="bg-[#f4f9ff]">
        <SectionPill tw="bg-[#e6eff9] text-[#3b6496]">
          Revenue Share
        </SectionPill>
        <SectionHeader tw="text-[#3b6496] max-w-[580px] mx-auto">
          We fulfill. <SectionHeaderEm>You earn.</SectionHeaderEm>
        </SectionHeader>
        <SectionContent>
          <p>
            You earn a share of revenue on all sales on your platform. Goody
            handles order fulfillment, delivery, and even support.
          </p>
        </SectionContent>
        <div tw="pt-10 -mx-4">
          <img
            src={staticAssetUrl(
              "static/web/landing/api/revshare.png?quality=100,90",
            )}
            alt=""
            tw="mx-auto w-[553px]"
          />
        </div>
      </Section>
    </SectionContainer>
  )
}
