import { Fragment } from "react"
import { RectShape, TextBlock } from "react-placeholder/lib/placeholders"

interface Props {
  cols?: number
  rows?: number
}

export const GalleryPlaceholder = ({ cols = 2, rows = 2 }: Props) => (
  <>
    {[...Array(cols)].map((_, index) => (
      <div key={`col-${index}`} tw="flex flex-col">
        {[...Array(rows)].map((_, index) => (
          <Fragment key={`row-${index}`}>
            <Placeholder />
            {index < rows && <br />}
          </Fragment>
        ))}
      </div>
    ))}
  </>
)

const Placeholder = () => (
  <div tw="pb-28" className={"show-loading-animation"}>
    <div
      className={"rect-shape"}
      tw="bg-[#F6F7F9] w-full aspect-w-3 aspect-h-2 lg:rounded-xl outline-none"
    />
    <br />
    <RectShape
      color="#F6F7F9"
      style={{
        width: "40%",
        height: 30,
        marginLeft: "5%",
        marginRight: "5%",
      }}
    />
    <TextBlock
      color="#F6F7F9"
      rows={1}
      style={{
        width: "90%",
        height: 20,
        marginLeft: "5%",
        marginRight: "5%",
        marginTop: "3%",
      }}
    />
    <TextBlock
      color="#F6F7F9"
      rows={1}
      style={{
        width: "90%",
        height: 20,
        marginLeft: "5%",
        marginRight: "5%",
        marginTop: "-0%",
      }}
    />
  </div>
)
