import React, { useEffect } from "react"
import { useHistory } from "react-router-dom"

import CustomStore from "./CustomStore"
import CustomStores from "./CustomStores"
import EditCustomStore from "./EditCustomStore"
import { useGlobalState } from "../../common/GlobalState"
import { useCurrentWorkspace } from "../../common/hooks"
import { useFeatureAccess } from "../../common/hooks/featureAccess"
import RealmRoute from "../../common/RealmRoute"

import { CustomStoreProvider } from "@/store/custom/hooks"
import { PlusEnrollmentStatus } from "@/types/graphql-types"

const CustomFeaturesRoutes: React.FC = () => {
  const [enrollmentStatus] = useGlobalState("enrollmentStatus")
  const history = useHistory()

  const { currentWorkspace } = useCurrentWorkspace()
  const { hasFeature } = useFeatureAccess()

  useEffect(() => {
    if (enrollmentStatus === PlusEnrollmentStatus.NONE) {
      history.replace("/signup")
    }
  }, [enrollmentStatus, history])

  if (enrollmentStatus === PlusEnrollmentStatus.NONE) {
    return null
  }

  // HACK: These pages have some race condition where the Apollo query is made
  // before the currentWorkspace is available
  if (!currentWorkspace || !hasFeature("custom_stores")) {
    return null
  }

  return (
    <>
      <RealmRoute path="/browse/custom" realm={"plus"}>
        <CustomStores />
      </RealmRoute>
      <RealmRoute path="/store/custom/edit/:id" realm={"plus"}>
        <EditCustomStore />
      </RealmRoute>
      <RealmRoute path="/store/custom/create" realm={"plus"}>
        <CustomStoreProvider>
          <CustomStore />
        </CustomStoreProvider>
      </RealmRoute>
    </>
  )
}

export default CustomFeaturesRoutes
