import { Link } from "react-router-dom"

import { generateRealmPath } from "../../../common/realm"
import FAQAccordion, { PurpleLink } from "../../components/FAQAccordion"

const FrequentlyAskedQuestions = () => {
  const sections = [
    {
      question: "Do I need a paid software plan to gift in bulk on Goody?",
      answer: (
        <>
          <p>
            Nope! You can send unlimited gifts within the U.S. on our free
            Starter plan. You can even upload CSVs to save time. To get started,{" "}
            <Link
              tw="text-purple-500 font-medium"
              to={generateRealmPath("business", "/signup")}
            >
              sign up for Goody for Business
            </Link>
            .
          </p>
          <p>
            If you’re looking to send bulk gifts internationally, add custom
            cards and digital logo branding, and more, you’ll need to upgrade to
            one of our{" "}
            <Link
              tw="text-purple-500 font-medium"
              to={generateRealmPath("business", "/signup?pro")}
            >
              paid software subscriptions
            </Link>
            . Our most affordable plan, Goody Pro, costs $25 per month, with no
            annual contract required.
          </p>
        </>
      ),
    },
    {
      question: "Can Goody support bulk gifting to international recipients?",
      answer: (
        <>
          <p>
            Yes we can! You can send gifts to Canada on our free Starter plan at
            no additional charge. For gifts to
            <PurpleLink href="https://intercom.help/goody/en/articles/6909547-international-gifting-options-available-for-businesses">
              {" "}
              140+ supported countries{" "}
            </PurpleLink>
            and international gift cards,
            <Link
              tw="text-purple-500 font-medium"
              to={generateRealmPath("business", "/business/signup?pro")}
            >
              {" "}
              upgrade to Goody Pro
            </Link>
            .
          </p>
        </>
      ),
    },
    {
      question: "How am I billed for bulk gifts?",
      answer: (
        <>
          <p>
            With Goody, you’re only billed for gifts that people accept. After
            you send your bulk gifts, your recipients will receive a digital
            notification via email, text message, or a link you send in your own
            comms. If you enable gift swapping, they can also swap your gift for
            one with an equal or lower price (with no pricing shown).
          </p>
          <p>
            You only pay after your recipients are notified and choose to accept
            your gift. If they swap for a lower-priced option, any difference is
            returned to your account as balance.
          </p>
          <p>
            That means none of your gifting budget is wasted on gifts that
            people don’t want! Most companies using Goody save 20-30% on bulk
            gifts, compared with conventional gifting.
          </p>
        </>
      ),
    },
    {
      question: "Can Goody ship bulk gifts to a single location?",
      answer: (
        <>
          <p>
            Yes we can. Please note that this feature is not available on our
            website. To discuss shipping bulk gifts to a single location,{" "}
            <PurpleLink
              href={generateRealmPath("business", "/business/book-a-call")}
            >
              book a call with us
            </PurpleLink>
            .
          </p>
        </>
      ),
    },
  ]

  return (
    <div tw="px-4 pb-24 bg-gray-050 pt-4 md:pt-12">
      <FAQAccordion sections={sections} />
    </div>
  )
}

export default FrequentlyAskedQuestions
