import HowItWorksCommon from "../HowItWorksCommon"

interface Props {}

export default function HowItWorksCommerce({}: Props) {
  return (
    <div tw="pt-12">
      <HowItWorksCommon
        content={[
          {
            heading: "Users shop and check out in your app.",
            description:
              "Gifting upsells, peer-to-peer gifting, traditional ecommerce – we can support it right in your app.",
          },
          {
            heading: "We handle the hard stuff.",
            description:
              "Don’t worry about inventory, order fulfillment, or even payment collection. Send an API call and we take care of it all, while sending you webhooks with updates.",
          },
          {
            heading: "Earn revenue from sales.",
            description:
              "Open up a new revenue channel for your business. Earn revenue share plus the ability to mark up prices.",
          },
        ]}
      />
    </div>
  )
}
