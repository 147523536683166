import { useRef, useState } from "react"
import tw, { styled } from "twin.macro"

import CountryButton from "./GiftOptionCountryButton"
import { Countries, internationalCountries } from "./InternationalShipping"
import { ReactComponent as ChevronDown } from "../../../common/images/chevron-down.svg"

interface Props {
  giftOptionSelectedShippingCountry: Countries
  onClick: (country: Countries) => void
  shippingCountries: String[]
}

const GiftOptionGlobalDropdown = ({
  giftOptionSelectedShippingCountry,
  onClick,
  shippingCountries,
}: Props) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const dropdownRef = useRef<HTMLDivElement>(null)

  if (!shippingCountries.includes(Countries.GLOBAL)) {
    return null
  }

  const onButtonClick = (country: Countries) => {
    setIsExpanded(false)
    onClick(country)
  }

  return (
    <>
      <div tw="flex items-center">
        <CountryButton
          tw="w-auto"
          country={Countries.GLOBAL}
          giftOptionSelectedShippingCountry={giftOptionSelectedShippingCountry}
          onClick={onButtonClick}
        />
        <DropdownTrigger onClick={() => setIsExpanded(!isExpanded)}>
          140+ countries
          <ChevronDown
            height={6}
            tw="ml-1 stroke-current text-gray-400 stroke-1 transition-transform"
            css={[isExpanded && tw`rotate-180`]}
          />
        </DropdownTrigger>
      </div>
      <DropdownContainer
        isExpanded={isExpanded}
        maxHeight={`${dropdownRef.current?.scrollHeight ?? 0}px`}
        ref={dropdownRef}
      >
        {internationalCountries.map((country) => (
          <CountryButton
            country={country}
            giftOptionSelectedShippingCountry={
              giftOptionSelectedShippingCountry
            }
            onClick={onButtonClick}
          />
        ))}
      </DropdownContainer>
    </>
  )
}

const DropdownTrigger = styled.div`
  ${tw`text-sm text-gray-500 border px-3 rounded-3xl ml-3 flex items-center cursor-pointer`}

  padding-top: 2px;
  padding-bottom: 2px;
`

const DropdownContainer = styled.div<{
  isExpanded: boolean
  maxHeight: string
}>`
  ${tw`pl-9 overflow-hidden`}

  max-height: ${({ isExpanded, maxHeight }) => (isExpanded ? maxHeight : `0`)};
  transform-origin: top;
  transition: max-height 0.3s ease;
`

export default GiftOptionGlobalDropdown
