// See https://stackoverflow.com/a/28226736

const downloadURI = (uri: string, filename: string) => {
  const a = document.createElement("a")

  a.download = filename
  a.href = uri

  a.dispatchEvent(
    new MouseEvent("click", {
      view: window,
      bubbles: false,
      cancelable: true,
    }),
  )
}

export const downloadSvg = (svg: SVGSVGElement | null, filename: string) => {
  if (!svg) {
    return
  }
  const svgString = new XMLSerializer().serializeToString(svg)
  const svgBlob = new Blob([svgString], {
    type: "image/svg+xml;charset=utf-8",
  })

  const DOMURL = window.URL || window.webkitURL || window
  const url = DOMURL.createObjectURL(svgBlob)

  const image = new Image()
  image.width = svg.width.baseVal.value
  image.height = svg.height.baseVal.value

  image.src = url
  image.onload = function () {
    const canvas = document.createElement("canvas")
    canvas.width = image.width
    canvas.height = image.height

    const context = canvas.getContext("2d")
    if (context) {
      context.drawImage(image, 0, 0)
      DOMURL.revokeObjectURL(url)

      const imgURI = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream")
      downloadURI(imgURI, filename)
    }
  }
}
