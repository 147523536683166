import { gql } from "@apollo/client"

export const ARCHIVE_CUSTOM_STORE_MUTATION = gql`
  mutation Store_CustomStoreArchive($id: ID!) {
    customStoreArchive(id: $id) {
      ok
      errors
    }
  }
`
