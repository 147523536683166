import { ReactElement } from "react"

import {
  CurrentAutogiftRule,
  CurrentCustomStore,
  CurrentGift,
} from "../common/GlobalState"
import { useFeatureAccess } from "../common/hooks/featureAccess"
import { ProPlanProtected } from "../common/ProPlanModal"
import { FloatingProTag } from "../common/ProTag"
import {
  SelectionGroup,
  SelectionIndicator,
  SelectionLabel,
} from "../common/SelectionGroup"

import { useFeatureFlags } from "@/common/hooks/featureFlags"
import { GiftBatchSettingsType } from "@/track/components/giftBatch/GiftBatchSettings/hooks"
import { InternationalShippingTierEnum } from "@/types/graphql-types"

// This is the old version of the component and will be removed in the future
// New version is in src/common/InternationalShippingOptions.tsx
// This component is being used in the Send Page, Gift Batch Settings, and Autogifts

type ObjectType =
  | CurrentGift
  | CurrentAutogiftRule
  | CurrentCustomStore
  | GiftBatchSettingsType

interface InternationalShippingTierOptionsProps {
  object: ObjectType
  header?: ReactElement
  setInternationalShippingTier: (
    internationalShippingTier: InternationalShippingTierEnum,
  ) => void
  disallowInternational?: boolean
  showSwapMultipleWarning?: boolean
}

const InternationalShippingTierOptions = ({
  object,
  setInternationalShippingTier,
  header,
  disallowInternational,
  showSwapMultipleWarning,
  ...props
}: InternationalShippingTierOptionsProps) => {
  const { hasFeature } = useFeatureAccess()
  const { hasFeatureFlag } = useFeatureFlags()

  return (
    <div {...props}>
      {header || (
        <div tw="flex flex-row items-center justify-start pb-2 gap-4">
          <div tw="font-medium text-gray-600">International</div>
          <FloatingProTag feature="international_shipping" />
        </div>
      )}
      {disallowInternational && (
        <div tw={"bg-gray-100 rounded-lg inline-block px-4 py-2 mt-0.5 mb-2"}>
          International shipping isn’t available for gifts with multiple items.
        </div>
      )}

      <InternationalShippingTierSelectionGroup
        object={object}
        tier={InternationalShippingTierEnum.disabled}
        onClick={() =>
          setInternationalShippingTier(InternationalShippingTierEnum.disabled)
        }
        disabled={false}
        unclickable={false}
        text="Disabled"
      />
      <ProPlanProtected feature="international_shipping">
        <InternationalShippingTierSelectionGroup
          object={object}
          tier={InternationalShippingTierEnum.gift_cards}
          disabled={disallowInternational || !hasFeature("pro_plan")}
          unclickable={hasFeature("pro_plan") && !!disallowInternational}
          onClick={() =>
            setInternationalShippingTier(
              InternationalShippingTierEnum.gift_cards,
            )
          }
          text={
            <>
              Gift cards only <span tw="text-gray-500">No additional fee</span>
            </>
          }
        />
      </ProPlanProtected>
      {hasFeatureFlag("global_partners") && (
        <ProPlanProtected feature="international_shipping">
          <InternationalShippingTierSelectionGroup
            object={object}
            tier={InternationalShippingTierEnum.global_partners}
            disabled={disallowInternational || !hasFeature("pro_plan")}
            unclickable={hasFeature("pro_plan") && !!disallowInternational}
            onClick={() =>
              setInternationalShippingTier(
                InternationalShippingTierEnum.global_partners,
              )
            }
            text={
              <>
                Local partners{" "}
                <span tw="text-gray-500">Economical international options</span>
              </>
            }
          />
        </ProPlanProtected>
      )}
      <ProPlanProtected feature="international_shipping">
        <InternationalShippingTierSelectionGroup
          object={object}
          tier={InternationalShippingTierEnum.global_relay}
          disabled={disallowInternational || !hasFeature("pro_plan")}
          unclickable={hasFeature("pro_plan") && !!disallowInternational}
          onClick={() =>
            setInternationalShippingTier(
              InternationalShippingTierEnum.global_relay,
            )
          }
          text={
            <>
              Full global catalog{" "}
              <span tw="text-gray-500">Up to $50 per recipient</span>
            </>
          }
        />
      </ProPlanProtected>
      <div tw="pt-2 text-gray-500 text-sm font-text">
        <span tw="font-semibold">
          Our full international shipping catalog has an additional fee of up to
          $50 per international recipient
        </span>{" "}
        for cross-border shipping, tariffs, and taxes in the destination
        country.
        {showSwapMultipleWarning && (
          <> Recipients outside the USA can only swap for one item</>
        )}
      </div>
    </div>
  )
}

interface InternationalShippingTierSelectionGroupProps {
  object: ObjectType
  tier: InternationalShippingTierEnum
  text: string | ReactElement
  disabled: boolean
  unclickable: boolean
  onClick: () => void
}

const InternationalShippingTierSelectionGroup = ({
  object,
  tier,
  text,
  disabled,
  onClick,
  unclickable,
}: InternationalShippingTierSelectionGroupProps) => (
  <SelectionGroup
    tw="flex"
    disabled={disabled}
    onClick={onClick}
    unclickable={unclickable}
  >
    <SelectionIndicator selected={object.internationalShippingTier === tier} />
    <SelectionLabel selected={object.internationalShippingTier === tier}>
      {text}
    </SelectionLabel>
  </SelectionGroup>
)

export default InternationalShippingTierOptions
