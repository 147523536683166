import React from "react"
import tw, { css, styled } from "twin.macro"

import { useGlobalState } from "./GlobalState"
import GradientButton, { GradientLink } from "./GradientButton"
import { useFeatureAccess } from "./hooks/featureAccess"
import { ProPlanFeature, trackProFeatureClick } from "./proPlan"
import { generateRealmPath } from "./realm"
import { ProTagIcon } from "../assets/icons"

type ProTagProps = React.ComponentPropsWithoutRef<"button"> & {
  feature: ProPlanFeature
  stopPropagation?: boolean
}

export const ProTag: React.FC<ProTagProps> = (props) => {
  const { hasFeature } = useFeatureAccess()
  const [, setIsProPlanModalOpen] = useGlobalState("isProPlanModalOpen")

  if (hasFeature("pro_plan")) {
    return null
  }

  const { feature, ...restProps } = props

  return (
    <Container
      {...restProps}
      onClick={(e) => {
        trackProFeatureClick(feature)
        setIsProPlanModalOpen(true)

        if (props.stopPropagation) {
          e.stopPropagation()
        }
      }}
    >
      <StyledProTagIcon />
      <ProText>Pro</ProText>
    </Container>
  )
}

export const Container = styled.button`
  background-color: #f7f4fe;
  border-color: #e0d4fc;
  height: 1.75rem;
  width: 4.125rem;
  ${tw`flex items-stretch rounded-lg outline-none border flex-row justify-center items-center gap-1 transition-all active:scale-95`}
  color: #8d6ef7;

  &:hover {
    background-color: #fcfaff;
    border-color: #d5c5fc;
  }
`

export const ProText = tw.div`font-semibold`

export const StyledProTagIcon = tw(ProTagIcon)`fill-current`

export const FloatingProTag: React.FC<{ feature: ProPlanFeature }> = ({
  feature,
}) => {
  const { hasFeature } = useFeatureAccess()

  if (hasFeature("pro_plan")) {
    return null
  }

  return (
    <FloatContainer>
      <ProTag feature={feature} />
    </FloatContainer>
  )
}

// To make it centered vertically but not take up space
const FloatContainer = styled.div`
  position: relative;
  top: -0.875rem;
  height: 0;
`

export const UpgradeToProTag: React.FC<{ feature: ProPlanFeature }> = ({
  feature,
}) => {
  const { hasFeature } = useFeatureAccess()
  const [, setIsProPlanModalOpen] = useGlobalState("isProPlanModalOpen")

  if (hasFeature("pro_plan")) {
    return null
  }

  return (
    <UpgradeContainer
      onClick={() => {
        trackProFeatureClick(feature)
        setIsProPlanModalOpen(true)
      }}
    >
      <ProTagIcon tw={"fill-current text-white"} />
      <div tw={"font-semibold text-white text-base"}>Upgrade to Pro</div>
    </UpgradeContainer>
  )
}

const upgradeContainerStyle = css`
  height: 2rem;
  ${tw`flex flex-row justify-center items-center gap-2 rounded-lg outline-none p-0 px-3`}
`

export const UpgradeContainer = styled(GradientButton)`
  ${upgradeContainerStyle}
`
const UpgradeContainerLink = styled(GradientLink)`
  ${upgradeContainerStyle}
`

type UpgradeToProMessageTagProps = {
  message: string
  feature: ProPlanFeature
} & React.ComponentPropsWithoutRef<"div">

export const UpgradeToProMessageTag: React.FC<UpgradeToProMessageTagProps> = ({
  message,
  feature,
  ...restProps
}) => {
  return (
    <UpgradeMessageContainer {...restProps}>
      <UpgradeToProTag feature={feature} />
      <div tw={"text-gray-500 whitespace-normal leading-5"}>{message}</div>
    </UpgradeMessageContainer>
  )
}

const UpgradeMessageContainer = styled.div`
  ${tw`flex flex-row items-center gap-3`}
`

export const UpgradeToProHeaderTag: React.FC = () => {
  const { hasFeature } = useFeatureAccess()

  if (hasFeature("pro_plan")) {
    return null
  }

  return (
    <UpgradeContainerLink
      onClick={() => {
        trackProFeatureClick("app_header")
      }}
      to={generateRealmPath("plus", "/account/subscription")}
    >
      <ProTagIcon tw={"fill-current stroke-current! text-white! stroke-0!"} />
      <div tw={"font-semibold text-white text-base"}>Upgrade</div>
    </UpgradeContainerLink>
  )
}
