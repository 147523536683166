import { isBlank } from "@/common/format"
import { Track_GiftBatchFragment } from "@/types/graphql-types"

export const getGiftBatchTitle = (
  giftBatch: Track_GiftBatchFragment | null | undefined,
) => {
  if (giftBatch == null) {
    return ""
  }

  if (giftBatch.batchName && !isBlank(giftBatch.batchName)) {
    return giftBatch.batchName
  }

  return giftBatch.batchNameFallback ?? ""
}
