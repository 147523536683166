import { useMutation } from "@apollo/client"
import { useState } from "react"

import Button from "@/common/Button"
import UPDATE_PAYMENT_METHOD_MUTATION from "@/common/payments/graphql/updatePaymentMethodMutation"
import {
  PaymentMethodModalProps,
  PaymentMethodModalWrapper,
} from "@/common/payments/PaymentMethodModal"
import { bulletsLast4 } from "@/common/payments/utils"
import TextInputField from "@/common/TextInputField"
import {
  UpdatePaymentMethodMutation,
  UpdatePaymentMethodMutationVariables,
} from "@/types/graphql-types"

const EditLabelModal = ({
  open,
  close,
  paymentMethod,
  refreshPaymentMethods,
}: PaymentMethodModalProps) => {
  const [label, setLabel] = useState(paymentMethod.label || null)
  const [updatePaymentMethod] = useMutation<
    UpdatePaymentMethodMutation,
    UpdatePaymentMethodMutationVariables
  >(UPDATE_PAYMENT_METHOD_MUTATION)
  const submit = async () => {
    const response = await updatePaymentMethod({
      variables: {
        paymentMethodId: paymentMethod.id,
        label,
      },
    })
    refreshPaymentMethods()
    close()
  }

  return (
    <PaymentMethodModalWrapper open={open} close={close}>
      <div tw="flex justify-between">
        <div tw="capitalize font-semibold text-xl leading-6">
          {paymentMethod.cardBrand} {bulletsLast4(paymentMethod)}
        </div>
      </div>
      <div tw="flex flex-col gap-3">
        <div tw="flex items-center">
          <div tw="mr-12">Label</div>
          <TextInputField
            tw="py-3"
            type="text"
            placeholder="Optional"
            defaultValue={paymentMethod.label ?? ""}
            onChange={(e) => setLabel(e.target.value)}
          />
        </div>
        <div tw="text-sm text-gray-500 pl-[88px]">
          Displayed alongside the brand and last 4 digits.
        </div>
      </div>
      <Button variant="primary" tw=" justify-center" onClick={submit}>
        Save
      </Button>
    </PaymentMethodModalWrapper>
  )
}

export default EditLabelModal
