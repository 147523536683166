import "twin.macro"
import { useMemo } from "react"

import { ImageFragment } from "@/types/graphql-types"

interface Props {
  logoImage: ImageFragment
  alt: string
  // Set this to a value between 1 and 2 to limit the max width of the logo
  // to between 140px to 280px
  maxWidthFactor?: number
}

export default function BrandLogo({ logoImage, alt, maxWidthFactor }: Props) {
  const { url } = logoImage
  const { maxWidth, maxHeight } = useMemo(() => {
    const { width, height } = logoImage

    if (!width || !height) {
      return { maxWidth: 200, maxHeight: 150 }
    }

    const aspectRatio = width / height

    let baseWidth = 140
    let baseHeight = 140

    // If the image is wider than it is tall, then we can let it be wider by
    // a maximum factor of 2 (up to 280px) proportional to how much wider it
    // is than it is tall (the aspect ratio).
    // For example, a 200x100 image would be 2x wider than it is tall, so
    // we'd let it be 2x wider than the base width of 140px, or 280px.
    let widthFactor = Math.min(2, aspectRatio)
    if (maxWidthFactor) {
      widthFactor = Math.min(maxWidthFactor, widthFactor)
    }

    // When we let wider images go up to 280px, we also want to make sure
    // those images don't get overly tall, which would make the logo too
    // big. So we limit the height to the reciprocal of the aspect ratio.
    // For example, a 200x100 image would be 2x wider than it is tall, so
    // we'd limit the height to 1/2 of the base height of 140px, or 70px.
    let heightFactor = Math.max(Math.min(1, 1 / aspectRatio))

    return {
      maxWidth: baseWidth * widthFactor,
      // Make sure the height is at least 40px, so that the logo doesn't
      // appear way too small.
      maxHeight: Math.max(baseHeight * heightFactor, 40),
    }
  }, [logoImage])

  return (
    <img
      src={url}
      alt={alt}
      css={{
        width: "100%",
        maxWidth: `${maxWidth}px`,
        maxHeight: `${maxHeight}px`,
        objectFit: "contain",
        margin: "auto",
      }}
    />
  )
}
