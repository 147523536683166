import "twin.macro"

export const ReferralDescription = () => {
  return (
    <div>
      <div tw="text-xl mb-4 font-semibold">
        <p>Earn $50 for each friend you refer to Goody for Business!</p>
        <p tw="font-normal">
          You can use your gift credits on business or personal gifts (or to
          treat yourself!)
        </p>
      </div>
      <div tw="text-xl mb-4 font-semibold">How it works:</div>
      <div tw="text-lg mb-4 mx-8">
        <ul tw="list-disc">
          <li tw="mb-2">
            Enter their work email below and click “Invite”, or copy your
            Referral Link below and share it with your network
          </li>
          <li tw="mb-2">
            They’ll get $20 gift credit when they sign up using your link
          </li>
          <li tw="mb-2">
            You’ll earn $50 gift credit for every new user once their first gift
            is accepted.
          </li>
        </ul>
      </div>
      <div tw="text-lg">
        View full terms and conditions
        <a href="/terms#section-11" tw="ml-1 text-primary-400 font-semibold">
          here
        </a>
        .
      </div>
    </div>
  )
}
