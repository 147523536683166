import React from "react"

import { ReactComponent as XIcon } from "../assets/icons/x-light-gray-large.svg"

const DrawerCloseButton = ({ onClick }: { onClick: () => void }) => (
  <button
    onClick={onClick}
    tw="rounded-full p-2 hover:bg-gray-150 focus:outline-none focus-visible:bg-gray-100 active:bg-gray-200 transition-colors"
  >
    <XIcon tw="stroke-current text-gray-400" />
  </button>
)

export default DrawerCloseButton
