import classNames from "classnames"
import { useEffect, useState } from "react"
import tw, { styled } from "twin.macro"

interface Props {
  trigger: JSX.Element
  className?: string
  disabled?: boolean
}
const DropdownMenu: React.FC<Props> = ({
  children,
  className,
  trigger,
  disabled: isDisabled,
}) => {
  const [isExpanded, expand] = useState<boolean>(false)

  useEffect(() => {
    const { body } = document
    const collapse = () => expand(false)

    body.addEventListener("click", collapse)

    return () => body.removeEventListener("click", collapse)
  })

  const dropdownClasses = classNames(className, {
    expanded: isExpanded,
    disabled: isDisabled,
  })

  return (
    <DropdownNav className={dropdownClasses}>
      <button
        onClick={(e) => {
          e.stopPropagation()
          expand((isExpanded) => !isExpanded)
        }}
        className="trigger group"
      >
        {trigger}
      </button>
      {children}
    </DropdownNav>
  )
}

const DropdownNav = styled.nav`
  ${tw`relative inline-block`}

  button:focus {
    ${tw`outline-none`}
  }

  &.disabled {
    pointer-events: none;
  }
`

export const DropdownMenuPanel = styled.ul`
  ${tw`absolute bg-white shadow-lg rounded-lg top-12 overflow-hidden z-10 border border-gray-300 hidden whitespace-nowrap`}

  .expanded > & {
    ${tw`block`}
  }
`

export const DropdownMenuItem = styled.li`
  a[href],
  button {
    ${tw`w-full py-3 px-4 flex justify-between transition-colors text-gray-600`}

    &:hover {
      ${tw`bg-primary-000 text-primary-600`}
    }

    &:active {
      ${tw`outline-none`}
    }
  }

  svg {
    ${tw`w-10 stroke-current opacity-80`}
  }
`

export default DropdownMenu
