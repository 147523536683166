import {
  Track_GiftBatchQuery,
  Track_GiftBatchesQuery,
} from "@/types/graphql-types"

export const getRecipientCount = (
  giftBatch:
    | NonNullable<NonNullable<Track_GiftBatchQuery["workspace"]>["giftBatch"]>
    | NonNullable<Track_GiftBatchesQuery["workspace"]>["giftBatches"][0],
) => {
  return giftBatch.isSmartLink && giftBatch.smartLinkQuantity
    ? giftBatch.smartLinkQuantity
    : giftBatch.recipientCount
}
