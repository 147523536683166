import { addDays } from "date-fns"
import React, { useState } from "react"
import { DayModifiers } from "react-day-picker"
import DayPicker from "react-day-picker/DayPicker"
import tw, { styled } from "twin.macro"

import { formatDateUS } from "./utilities"
import { ReactComponent as CalendarActive } from "../assets/icons/calendar-active.svg"
import { ReactComponent as Calendar } from "../assets/icons/calendar.svg"
import { ReactComponent as Lock } from "../assets/icons/lock.svg"
import "react-day-picker/lib/style.css"

interface DatePickerFieldProps {
  date: Date | null
  onDateChange: (date: Date) => void
  disabled?: boolean
  openBelow?: boolean
  disableBefore?: Date

  // The number of days to disable ahead of the current day.
  numDisabledDaysAhead?: number

  // The maximum number of days that can be selected ahead.
  numMaximumDaysAhead?: number
}

// Invisible div used to handle a click event when clicking outside of the day picker when it is displayed.
const ClickableCloserUnderlay = styled.div`
  ${tw`cursor-default fixed opacity-0 z-20`}
  height: 100vh;
  left: 0;
  top: 0;
  width: 100vw;
`

const Container = styled.div`
  ${tw`cursor-pointer relative`};

  &.disabled {
    background: #f3f4f6;
    ${tw`rounded-lg`};
  }
`

const DateField = styled.div<{ showPicker?: boolean }>`
  ${tw`flex relative transition`};
  border: 1px solid ${({ showPicker }) => (showPicker ? "#d0b8ed" : "#dcdcdc")};
  border-radius: 6px;
  height: 38px;
  width: 162px;

  &:not(.disabled):hover {
    border: 1px solid #d0b8ed;
  }

  &.disabled {
    width: 9rem;
    padding-right: 0.75rem;
    justify-content: space-between;
    display: flex;
  }
`

const DateText = styled.div`
  ${tw`ml-3`};
  line-height: 36px;

  &.disabled {
    ${tw`text-black opacity-50`};
  }
`

const StyledCalendar = styled(Calendar)`
  ${tw`absolute`}
  right: 4px;
  top: 4px;
`

const StyledCalendarActive = styled(CalendarActive)`
  ${tw`absolute`}
  right: 4px;
  top: 4px;
`

const StyledLock = styled(Lock)`
  ${tw`self-center`}
`

// Styles a date picker calendar that appears to the right of a component.
const StyledDayPicker = styled(DayPicker)<{
  showPicker: boolean
  openBelow: boolean
}>`
  ${tw`absolute bg-white inline-block rounded z-30`}
  ${({ showPicker }) => (showPicker ? tw`visible` : tw`invisible`)};
  ${({ openBelow }) =>
    openBelow ? tw`left-0 top-12` : tw`left-[170px] top-[-117.5px]`};

  box-shadow:
    0 2px 4px rgba(0, 0, 0, 0.02),
    0 12px 40px rgba(163, 114, 223, 0.15);

  .DayPicker-Day {
    ${tw`h-8 rounded w-8`};
    line-height: 18px;

    &:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(
        .DayPicker-Day--outside
      ):hover {
      background-color: #efe9f6 !important;
    }
  }

  .DayPicker-Day--today {
    ${tw`text-primary-500`};
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(
      .DayPicker-Day--outside
    ) {
    ${tw`bg-primary-500`};
  }

  @media only screen and (max-width: 1023px) {
    left: 0;
    top: 3rem;
  }
`

const DatePickerField: React.FC<DatePickerFieldProps> = ({
  date,
  onDateChange,
  disabled,
  openBelow = false,
  numDisabledDaysAhead,
  numMaximumDaysAhead,
  disableBefore = new Date(),
}) => {
  const [showPicker, setShowPicker] = useState(false)

  // Show DayPicker and also centers it vertically next to the Date field
  const handleDateFieldClick = () => {
    setShowPicker(!showPicker)
  }

  const handleDayClick = (day: Date, modifiers: DayModifiers) => {
    if (modifiers.disabled) {
      return false
    }

    onDateChange(day)
    setShowPicker(false)
  }

  // Set the date for which all previous dates will be disabled.
  // We find the date numDisabledDaysAhead days from now, and disable all dates
  // prior to that date. This defaults to disabling all past dates.
  disableBefore = addDays(disableBefore, numDisabledDaysAhead || 0)

  // Set the date for which all following dates will be disabled.
  // We find the date numMaximumDaysAhead days from now, and disable all dates
  // after that date. This defaults to no maximum days.
  let disableAfter = undefined
  if (numMaximumDaysAhead !== undefined) {
    disableAfter = new Date()
    disableAfter = addDays(disableAfter, numMaximumDaysAhead)
  }

  if (disabled) {
    return (
      <Container className="disabled">
        <DateField className="disabled">
          <DateText className="disabled">{date && formatDateUS(date)}</DateText>
          <StyledLock />
        </DateField>
      </Container>
    )
  } else {
    return (
      <Container>
        <DateField onClick={handleDateFieldClick} showPicker={showPicker}>
          <DateText>{date && formatDateUS(date)}</DateText>
          {showPicker ? <StyledCalendarActive /> : <StyledCalendar />}
        </DateField>
        <StyledDayPicker
          showPicker={showPicker}
          onDayClick={handleDayClick}
          selectedDays={date || new Date()}
          disabledDays={{ before: disableBefore, after: disableAfter }}
          openBelow={openBelow}
        />
        {showPicker && (
          <ClickableCloserUnderlay onClick={() => setShowPicker(false)} />
        )}
      </Container>
    )
  }
}

export default DatePickerField
