import React, { useState } from "react"
import Modal from "react-modal"

import { AddCreditCard } from "./components/AddCreditCard"
import PaymentMethodOption from "./PaymentMethodOption"
import { ArrowRightLarge } from "../assets/icons"
import {
  getPaymentMethodName,
  useLocalStatePayment,
} from "../common/hooks/payment"
import { maxHeightModalStyle } from "../common/modal"
import { PaymentMethod } from "../common/PaymentMethodsManager"
import { SubmitButton } from "../common/UI"

interface Props {
  isOpen: boolean
  close: () => void
  autopayPaymentMethod: PaymentMethod | null
  setPaymentMethod: (
    paymentMethod: PaymentMethod,
    paymentMethodName: string,
  ) => void
  title?: string
  showAdd?: boolean
}

// Modal on consumer web for choosing a payment method
const PaymentMethodsModal: React.FC<Props> = ({
  isOpen,
  close,
  autopayPaymentMethod,
  setPaymentMethod,
  title,
  showAdd,
}) => {
  const {
    selectablePaymentMethods: paymentMethods,
    refreshPaymentMethods,
    setSelectedPaymentMethod,
    selectedPaymentMethod,
  } = useLocalStatePayment(autopayPaymentMethod)

  const [addPaymentMethodModalOpen, setAddPaymentMethodModalOpen] =
    useState(false)

  return (
    <Modal
      isOpen={isOpen}
      closeTimeoutMS={500}
      onRequestClose={close}
      onAfterClose={() => {}}
      shouldCloseOnOverlayClick={true}
      style={maxHeightModalStyle}
    >
      <div className="modal-content modal-content-wide modal-content-max-height">
        <div tw="p-6">
          <div tw="text-2xl font-medium text-primary-500 pb-6">
            {title || "Select autopay card"}
          </div>
          <div tw="flex flex-col items-stretch gap-4">
            {paymentMethods?.map((paymentMethod) => {
              return (
                <PaymentMethodOption
                  key={paymentMethod.id}
                  paymentMethod={paymentMethod}
                  selectedPaymentMethodID={selectedPaymentMethod?.id || null}
                  onSelect={(updatedPaymentMethod) =>
                    setSelectedPaymentMethod(updatedPaymentMethod)
                  }
                />
              )
            })}
            {showAdd && (
              <AddCreditCard
                open={addPaymentMethodModalOpen}
                setOpen={setAddPaymentMethodModalOpen}
                onComplete={() => refreshPaymentMethods()}
              />
            )}
          </div>
          <div tw="h-6" />
          <SubmitButton
            className="primary"
            onClick={() => {
              if (selectedPaymentMethod) {
                setPaymentMethod(
                  selectedPaymentMethod,
                  getPaymentMethodName(selectedPaymentMethod),
                )
              }
              close()
            }}
          >
            <div>Select payment card</div>
            <ArrowRightLarge className="feather feather-arrow-right" />
          </SubmitButton>
        </div>
      </div>
    </Modal>
  )
}

export default PaymentMethodsModal
