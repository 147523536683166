import { getEnvVars } from "./environment"

let storedCsrfToken: string

export async function fetchCsrfTokenFromServer() {
  const res = await window.fetch(
    getEnvVars().apiUrl + "/graphql_web/csrf_token",
    {
      method: "POST",
      credentials: "include",
    },
  )

  if (res.ok) {
    const returnedToken = res.headers.get("csrf-token")
    if (typeof returnedToken === "string") {
      storedCsrfToken = returnedToken
    }
  }
}

export function getCsrfToken() {
  return storedCsrfToken
}
