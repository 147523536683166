import Modal from "react-modal"
import tw from "twin.macro"

import { level2ModalStyle } from "../../common/modal"

interface DeactivateMemberModalProps {
  isOpen: boolean
  setIsOpen: (arg: boolean) => void
  fullName: string
  pendingGiftCount: number
  scheduledGiftBatchCount: number
  deactivate: () => void
}

const DeactivateMemberModal = ({
  isOpen,
  setIsOpen,
  fullName,
  pendingGiftCount,
  scheduledGiftBatchCount,
  deactivate,
}: DeactivateMemberModalProps) => {
  const pendingGiftBatchPluralizedLabel =
    pendingGiftCount > 1 ? "gifts" : "gift"
  const scheduledGiftBatchPluralizedLabel =
    scheduledGiftBatchCount > 1
      ? "scheduled gift batches"
      : "scheduled gift batch"

  return (
    <Modal
      isOpen={isOpen}
      style={level2ModalStyle}
      closeTimeoutMS={500}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      onRequestClose={() => setIsOpen(false)}
    >
      <Container>
        <MemberInformation>
          <MemberInformationHeader>
            <div tw="text-left text-2xl mb-3 font-semibold leading-[30px]">
              Remove {fullName}
            </div>
            <div tw="text-left text-gray-600 font-normal text-base leading-p-140">
              When you remove {fullName} from your organization, they will no
              longer be able to access the organization or any workspaces. In
              addition, the following changes will take place.
            </div>
          </MemberInformationHeader>
          {pendingGiftCount > 0 && (
            <MemberInformationRow>
              <RowHeader>
                {pendingGiftCount} pending {pendingGiftBatchPluralizedLabel}{" "}
                will be canceled
              </RowHeader>
              <RowBody>
                {pendingGiftCount} pending (non-accepted){" "}
                {pendingGiftBatchPluralizedLabel} will be canceled and not able
                to be accepted.
              </RowBody>
            </MemberInformationRow>
          )}
          {scheduledGiftBatchCount > 0 && (
            <MemberInformationRow>
              <RowHeader>
                {scheduledGiftBatchCount} {scheduledGiftBatchPluralizedLabel}{" "}
                will be canceled
              </RowHeader>
              <RowBody>
                These gift batches created by {fullName} will not be sent.
              </RowBody>
            </MemberInformationRow>
          )}
          <MemberInformationRow>
            <RowHeader>Accepted gifts will still be visible</RowHeader>
            <RowBody>
              Accepted gifts sent by this member will be visible on the Track
              page to other workspace members and available in reports.
            </RowBody>
          </MemberInformationRow>
          <MemberInformationRow>
            <RowHeader>
              Balance will be transferred to the organization
            </RowHeader>
            <RowBody>
              If the member has a balance after pending gifts are canceled, the
              balance will be transferred to the organization balance.
            </RowBody>
          </MemberInformationRow>
          <div>
            <div tw="flex flex-col px-48 pt-10 pb-11 flex items-center border-none">
              <DeactivationButton onClick={deactivate}>
                Deactivate member
              </DeactivationButton>
              <CancelButton onClick={() => setIsOpen(false)}>
                Cancel
              </CancelButton>
            </div>
          </div>
        </MemberInformation>
      </Container>
    </Modal>
  )
}

const DeactivationButton = tw.button`
  bg-primary-new-550 rounded-xl font-semibold text-base leading-p-140 text-white py-3.5 px-10 whitespace-nowrap hover:opacity-90 active:opacity-95 transition-all
`

const CancelButton = tw.button`
  font-normal text-base leading-p-140 pt-4 text-gray-400
`

const MemberInformation = tw.div`
   rounded-xl shadow-[0px 2px 6px rgba(0, 0, 0, 0.04)]
`

const MemberInformationRow = tw.div`
    border-b border-gray-150
`

const RowHeader = tw.div`font-semibold text-base leading-p-140 px-10 pt-4 pb-1`

const RowBody = tw.div`font-normal text-base leading-p-140 text-gray-600 pb-4 px-10`

const MemberInformationHeader = tw.div`
  p-9 pb-6 border-b
`

const Container = tw.div`
  overflow-y-auto rounded-xl bg-white flex flex-col w-4/5 lg:w-1/2 mx-auto relative max-h-[90vh]
`

export default DeactivateMemberModal
