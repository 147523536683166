import React, { useEffect, useState } from "react"
import Modal from "react-modal"
import { useHistory } from "react-router-dom"
import tw, { styled } from "twin.macro"

import { useGlobalState } from "./GlobalState"
import { topModalStyle } from "./modal"
import { generateRealmPath } from "./realm"

interface AgeVerificationModalProps {
  verifyAge: boolean
}

const localStorageKey = "__goody_alcohol_age_verification_attested"

export const ageAttested = () => {
  const ageAttested = localStorage.getItem(localStorageKey)

  return !!ageAttested && ageAttested === "TRUE"
}

const AgeVerificationModal = ({ verifyAge }: AgeVerificationModalProps) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [currentRealm] = useGlobalState("currentRealm")
  const history = useHistory()

  useEffect(() => {
    setModalIsOpen(verifyAge)
  }, [verifyAge])

  if (ageAttested()) {
    return null
  }

  const attestOver21 = () => {
    localStorage.setItem(localStorageKey, "TRUE")
    setModalIsOpen(false)
  }

  const attestUnder21 = () => {
    setModalIsOpen(false)
    history.push(generateRealmPath(currentRealm, "/browse"))
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      closeTimeoutMS={500}
      onAfterClose={() => {}}
      shouldCloseOnOverlayClick={true}
      style={topModalStyle}
    >
      <ModalWidthOverride className="modal-content">
        <div tw="border-b border-gray-200 py-9 text-center">
          <div tw="font-semibold text-gray-400 uppercase">
            Please verify your age
          </div>
          <div tw="mt-3 text-2xl">Are you 21 years of age or older?</div>
        </div>
        <ModalButtons>
          <ModalButton onClick={attestOver21}>Yes</ModalButton>
          <ModalButton onClick={attestUnder21}>No</ModalButton>
        </ModalButtons>
      </ModalWidthOverride>
    </Modal>
  )
}

const ModalButtons = tw.div`flex text-2xl`
const ModalButton = tw.button`hover:bg-gray-100 active:bg-gray-200 flex-1 first:border-r first:border-gray-200 py-6 transition`

const ModalWidthOverride = styled.div`
  ${tw`overflow-hidden`};
  max-width: 474px !important;
`

export default AgeVerificationModal
