import { ReactNode } from "react"
import tw from "twin.macro"

import Image from "@/common/Image"

interface Props {
  children: ReactNode
  onClick: (e: React.MouseEvent<HTMLElement>) => void
  icon?: string
  disabled?: boolean
}

const Button = ({ icon, onClick, children, disabled }: Props) => (
  <div
    css={[
      tw`flex gap-2 px-4 py-2 rounded-lg border border-gray-200 bg-white text-gray-600 whitespace-nowrap justify-center transition`,
      disabled
        ? tw`opacity-50 cursor-not-allowed scale-100`
        : tw`hover:(shadow-0-2-4-gray-350-10 border-gray-300) active:scale-95`,
    ]}
    onClick={!disabled ? onClick : undefined}
    className="payment-method-button"
  >
    {icon && <Image src={icon} tw="h-5" />}
    <div tw="leading-5">{children}</div>
  </div>
)

export default Button
