import InteractiveProductVariants, {
  generateVariantsDisplayLabel,
} from "../../store/components/InteractiveProductVariants"

import { Details_ProductFragment } from "@/types/graphql-types"

interface Props {
  product: Details_ProductFragment
  selectedVariants: string[] | null
  setSelectedVariants: (variants: string[] | null) => void
}

export default function DirectSendVariantsRequiredProduct({
  product,
  selectedVariants,
  setSelectedVariants,
}: Props) {
  return (
    <div tw="pb-8">
      <div tw="flex flex-row items-center gap-4">
        <img
          src={product.productImages[0]?.imageLarge?.url}
          tw="w-16 h-16 object-cover rounded-lg"
        />
        <div>
          <div tw="text-gray-500">
            {product.brand.name} – {product.name}
          </div>
          <div tw="text-lg font-medium">
            {generateVariantsDisplayLabel(
              product.variantsLabel,
              product.variantsNumSelectable,
              false,
            )}
          </div>
        </div>
      </div>
      <InteractiveProductVariants
        variants={product.variants}
        variantsLabel={product.variantsLabel}
        variantsNumSelectable={product.variantsNumSelectable}
        variantGroups={product.variantGroups}
        selectedVariants={selectedVariants}
        setSelectedVariants={setSelectedVariants}
        hideLetRecipientDecide={true}
        hideLabel={true}
        horizontalPopupPosition="right"
        imagesScalable={product.imagesScalable}
      />
    </div>
  )
}
