export function categoryRequiresGiftOptions(categorySlug: string | null) {
  if (!categorySlug) {
    return false
  }

  const regex =
    /(charity|giftcards|alcohol|wine|tequila|mezcal|cocktail|champagne|beer|whiskey|bourbon|scotch|vodka|gin|rum)/
  return regex.test(categorySlug)
}

export function categoryRequiresProducts(
  categorySlug: string | null,
  supercategorySlug: string | null,
) {
  const slugsToTest = [categorySlug, supercategorySlug].filter(
    Boolean,
  ) as string[]

  const regex = /branded-swag/
  return slugsToTest.some((slug) => regex.test(slug))
}
