import React from "react"

import Box from "../../../common/Box"
import MoneyInput from "../../../common/MoneyInput"

interface Props {
  amount: string
  setAmount: (value: string) => void
}

export default function AmountToFund({ amount, setAmount }: Props) {
  return (
    <Box tw="p-6 md:p-10">
      <div tw="font-semibold text-xl">Amount to fund</div>
      <div tw="pt-6 flex flex-col md:flex-row items-stretch justify-start">
        <MoneyInput value={amount} onChange={setAmount} autoFocus={true} />
        <div tw="pt-9 md:(pt-0 pl-9)">
          <div tw="flex flex-row gap-2">
            {["1,000", "2,500", "5,000"].map((dollars) => (
              <PresetButton
                key={dollars}
                dollars={dollars}
                onClick={() => setAmount(dollars)}
              />
            ))}
          </div>
          <div tw="pt-2 flex flex-row gap-2">
            {["7,500", "10,000", "20,000"].map((dollars) => (
              <PresetButton
                key={dollars}
                dollars={dollars}
                onClick={() => setAmount(dollars)}
              />
            ))}
          </div>
        </div>
      </div>
    </Box>
  )
}

interface PresetButtonProps {
  dollars: string
  onClick: () => void
}

function PresetButton({ dollars, onClick }: PresetButtonProps) {
  return (
    <button
      tw="w-24 h-8 flex flex-row items-center justify-center transition-colors bg-gray-100 hover:bg-gray-150 active:bg-gray-200 rounded-full text-lg text-gray-800"
      onClick={onClick}
    >
      ${dollars}
    </button>
  )
}
