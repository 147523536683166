import { useEffect } from "react"

import { Realm, useSetRealm } from "./realm"

interface Props {
  realm: Realm
}

// Programmatically set the realm to the given realm when rendered.
// Use this in redirects to set the realm before redirecting.
export default function SetToRealm({ realm }: Props) {
  const setRealm = useSetRealm()

  useEffect(() => {
    setRealm(realm)
  }, [])

  return null
}
