import React from "react"

import DatePickerField from "../../common/DatePickerField"
import { useNumScheduledGiftMaxDaysAhead } from "../../common/gifts"
import TimeSelectField from "../../common/TimeSelectField"
import { timeZoneAbbr } from "../../common/utilities"

interface ScheduledGiftDateTimePickerProps {
  scheduledSendOnDate: Date
  setScheduledSend: ({
    option,
    date,
    time,
  }: {
    option?: boolean
    date?: Date
    time?: any
  }) => void
  scheduledSendOnTime: string
}

export default function ScheduledGiftDateTimePicker({
  scheduledSendOnDate,
  setScheduledSend,
  scheduledSendOnTime,
}: ScheduledGiftDateTimePickerProps) {
  const numMaximumDaysAhead = useNumScheduledGiftMaxDaysAhead()

  return (
    <>
      <div tw="inline-block mr-2">
        <DatePickerField
          date={scheduledSendOnDate}
          onDateChange={(date: Date) => {
            setScheduledSend({ date })
          }}
          numMaximumDaysAhead={numMaximumDaysAhead}
        />
      </div>
      <div tw="inline-block mr-3">
        <TimeSelectField
          onChange={(option: any) =>
            setScheduledSend({
              time: option?.value!,
            })
          }
          selectedValue={scheduledSendOnTime}
        />
      </div>
      <div tw="inline-block opacity-50 text-sm">
        {timeZoneAbbr(scheduledSendOnDate)} (local)
      </div>
    </>
  )
}
