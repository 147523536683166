import React from "react"
import tw, { styled } from "twin.macro"

import { AutogiftRuleTenureClassification } from "../../common/GlobalState"

export const TenureSlider = ({
  selectedClassification,
  onSelectClassification,
}: {
  selectedClassification: AutogiftRuleTenureClassification
  onSelectClassification: (
    classification: AutogiftRuleTenureClassification,
  ) => void
}) => {
  return (
    <div
      css={[
        tw`w-full relative rounded-full h-10`,
        { backgroundColor: "#f9fafb" },
      ]}
    >
      <TenureOption
        name="All tenures"
        selected={
          selectedClassification === AutogiftRuleTenureClassification.ALL
        }
        onClick={() =>
          onSelectClassification(AutogiftRuleTenureClassification.ALL)
        }
      />
      <TenureOption
        name="Single year"
        selected={
          selectedClassification ===
          AutogiftRuleTenureClassification.YEAR_SINGLE
        }
        onClick={() =>
          onSelectClassification(AutogiftRuleTenureClassification.YEAR_SINGLE)
        }
      />
      <TenureOption
        name="Range of years"
        selected={
          selectedClassification === AutogiftRuleTenureClassification.YEAR_RANGE
        }
        onClick={() =>
          onSelectClassification(AutogiftRuleTenureClassification.YEAR_RANGE)
        }
      />
      <CurrentSelectionSlider
        css={[
          {
            transform: `translateX(${getTranslateXForAutogiftRuleTenureClassification(
              selectedClassification,
            )})`,
          },
          tw`transition-transform`,
        ]}
      />
    </div>
  )
}
const getTranslateXForAutogiftRuleTenureClassification = (
  classification: AutogiftRuleTenureClassification,
) => {
  switch (classification) {
    case AutogiftRuleTenureClassification.ALL:
      return 0
    case AutogiftRuleTenureClassification.YEAR_SINGLE:
      return "100%"
    case AutogiftRuleTenureClassification.YEAR_RANGE:
      return "200%"
  }
}

const TenureOption = ({
  name,
  selected,
  onClick,
}: {
  name: string
  selected: boolean
  onClick: () => void
}) => {
  return (
    <button
      css={[
        tw`w-1/3 text-gray-500 hover:text-gray-800 transition-colors z-20 relative h-10 rounded-full`,
        selected && tw`text-primary-500 hover:text-primary-500`,
        !selected && tw`active:bg-gray-150`,
      ]}
      onClick={onClick}
    >
      {name}
    </button>
  )
}

const CurrentSelectionSlider = styled.div`
  ${tw`bg-white rounded-full z-10 absolute w-1/3 h-full border border-primary-100 top-0`};
  box-shadow: 0 2px 4px rgba(228, 216, 244, 0.3);
`
