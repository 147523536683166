import { gql, useMutation } from "@apollo/client"
import { useCallback } from "react"
import tw, { styled } from "twin.macro"

import ContactForm, { FormData, createScheduledEventInput } from "./ContactForm"
import { ReactComponent as ChevronIcon } from "../../common/images/chevron-down.svg"
import {
  CONTACTS_QUERY,
  CONTACT_LISTS_QUERY,
  UPDATE_CONTACT_MUTATION,
} from "../queries"
import { SaveResult } from "../types/Contact"

import {
  Contacts_ContactDeleteMutation,
  Contacts_ContactDeleteMutationVariables,
  Contacts_UpdateContactMutation,
  Contacts_UpdateContactMutationVariables,
  StandardContactFragment,
} from "@/types/graphql-types"

interface EditContactFormProps {
  contact: StandardContactFragment
  onBack: () => void
  onDelete: () => void
  onSuccess: () => void
  currentListID: string | null
}

const EditContactForm = ({
  contact,
  onBack,
  onDelete,
  onSuccess,
  currentListID,
}: EditContactFormProps) => {
  const [updateContact] = useMutation<
    Contacts_UpdateContactMutation,
    Contacts_UpdateContactMutationVariables
  >(UPDATE_CONTACT_MUTATION)

  const onSave = async (formData: FormData): Promise<SaveResult> => {
    const {
      firstName,
      lastName,
      title,
      company,
      email,
      phone,
      birthday,
      workAnniversary,
      listIds,
    } = formData

    const birthdayInput = createScheduledEventInput(
      birthday.month,
      birthday.day,
      birthday.year,
    )
    const workAnniversaryInput = createScheduledEventInput(
      workAnniversary.month,
      workAnniversary.day,
      workAnniversary.year,
    )

    const res = await updateContact({
      variables: {
        contact: {
          id: contact.id,
          firstName,
          lastName,
          title,
          company,
          email,
          phone,
          birthday: birthdayInput,
          workAnniversary: workAnniversaryInput,
        },
        listIds,
      },
      refetchQueries: [
        {
          query: CONTACTS_QUERY,
          variables: {
            listID: currentListID,
          },
        },
        // Update contact list counts
        {
          query: CONTACT_LISTS_QUERY,
        },
      ],
    })

    if (res.errors || !res.data) {
      return {
        ok: false,
        error: res.errors ? res.errors[0].message : "Unknown error",
      }
    }

    if (res.data.contactUpdate.ok) {
      onSuccess()
    }

    return res.data.contactUpdate
  }

  const [deleteContact] = useMutation<
    Contacts_ContactDeleteMutation,
    Contacts_ContactDeleteMutationVariables
  >(CONTACT_DELETE_MUTATION)

  const handleDelete = useCallback(async (): Promise<SaveResult> => {
    const proceed = window.confirm(
      "Are you sure you want to delete this contact?",
    )

    if (!proceed) {
      return { ok: false }
    }

    const res = await deleteContact({
      variables: { id: contact.id },
      refetchQueries: [{ query: CONTACT_LISTS_QUERY }],
    })

    if (res.data == null) {
      return {
        ok: false,
        error: "Unknown error",
      }
    }

    if (res.data.contactRemove.ok) {
      onDelete()
    }

    return res.data.contactRemove
  }, [contact, deleteContact, onDelete])

  return (
    <ContactForm
      onSave={onSave}
      onDelete={handleDelete}
      contact={contact}
      title={
        <div tw="flex justify-between mr-5">
          <TitleButton onClick={onBack} type="button">
            <ChevronIcon />
            Edit contact
          </TitleButton>
        </div>
      }
    />
  )
}

const TitleButton = styled.button`
  ${tw`flex items-center gap-3 transition-colors`}

  svg {
    ${tw`stroke-current stroke-2 text-gray-400 transform rotate-90 transition-colors`}
  }

  &:hover {
    ${tw`text-gray-700`}

    svg {
      ${tw`text-gray-500`}
    }
  }
`

const CONTACT_DELETE_MUTATION = gql`
  mutation Contacts_ContactDelete($id: String!) {
    contactRemove(contact: { id: $id }) {
      ok
    }
  }
`

export default EditContactForm
