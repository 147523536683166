import { gql } from "@apollo/client"

export const REFERRAL_INVITE_SEND = gql`
  mutation ReferralInviteSend($emails: [String!]!) {
    referralInviteSend(emails: $emails) {
      ok
      error
      processedCount
    }
  }
`
