import React from "react"
import { Helmet } from "react-helmet-async"
import tw, { styled } from "twin.macro"

type Props = { title: string } & React.ComponentPropsWithoutRef<"div">

// Container with the gradient background on consumer pages
const ConsumerContainer: React.FC<Props> = ({
  title,
  children,
  css,
  ...restProps
}) => {
  return (
    <GradientBackground
      css={[tw`lg:border-t border-primary-100`, css]}
      {...restProps}
    >
      <Helmet>
        <title>{title} – Goody</title>
      </Helmet>
      {children}
    </GradientBackground>
  )
}

export default ConsumerContainer

const GradientBackground = styled.div`
  background: linear-gradient(
      178.92deg,
      rgba(255, 255, 255, 0) -45.08%,
      #ffffff 100%
    ),
    linear-gradient(
      90deg,
      rgba(228, 161, 184, 0.15) 30.58%,
      rgba(179, 125, 213, 0.15) 100%
    );
  background-size: auto 510px;
  background-repeat: repeat-x;
`
