import { gql } from "@apollo/client"

export const WORKSPACE_INVITE_ACCEPT = gql`
  mutation WorkspaceInviteAccept($workspaceInviteToken: String!) {
    workspaceInviteAccept(workspaceInviteToken: $workspaceInviteToken) {
      ok
      error
      joinedWorkspace {
        id
        name
        ttype
      }
    }
  }
`

export const GET_WORKSPACE_INVITE = gql`
  query GetWorkspaceInvite($workspaceInviteToken: String!) {
    workspaceInvite(token: $workspaceInviteToken) {
      id
      organizationName
      workspaceName
      senderName
      isExpired
      email
      samlEnabled
      requireSsoSetting
    }
  }
`
