import { kebabCase } from "lodash-es"

export function categoryNameToSlug(categoryName: string) {
  if (categoryName === "What's Goody" || categoryName === "What’s Goody") {
    return "what-s-goody"
  }

  return kebabCase(categoryName)
}

const SLUG_TO_CATEGORY_NAME = {
  "gift-guides": "Gift Guides",
  "founder-q-a": "Founder Q&A",
  "our-community": "Our Community",
  "what-s-goody": "What's Goody",
  marketing: "Marketing",
}

export function slugToCategoryName(slug: string) {
  // @ts-ignore
  return SLUG_TO_CATEGORY_NAME[slug] || slug
}
