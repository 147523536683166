import React from "react"
import tw from "twin.macro"

import DirectSendNotificationToggle from "./DirectSendNotificationToggle"
import { ReactComponent as QuestionMark } from "../../assets/icons/question-mark.svg"
import { useGiftData } from "../../common/hooks/giftData"
import Tooltip from "../../common/Tooltip"

interface Props {
  isConsumer?: boolean
}

export default function DirectSendNotification({ isConsumer }: Props) {
  const { currentGift, setLandingPage } = useGiftData()

  const recipientsLabel = isConsumer ? "your recipient" : "recipients"
  const notificationsLabel = isConsumer ? "Gift notification" : "Notifications"

  return (
    <div
      tw="p-5 flex flex-row items-center gap-4"
      css={[isConsumer && tw`px-6 sm:px-9 pb-0`]}
    >
      <div>
        <DirectSendNotificationToggle
          enabled={currentGift.landingPageSendNotifs}
          setEnabled={(enabled: boolean) => {
            setLandingPage({
              ...currentGift,
              sendNotifs: enabled,
            })
          }}
        />
      </div>
      <div>
        <div tw="font-medium">
          {notificationsLabel}{" "}
          {currentGift.landingPageSendNotifs ? "enabled" : "disabled"}
        </div>
        <div tw="text-gray-600 text-sm pt-[2px]">
          {currentGift.landingPageSendNotifs
            ? `Let ${recipientsLabel} know a gift is on the way, and send them the tracking number.`
            : `The order will be shipped to ${recipientsLabel} without any notifications. Unless you’re ordering this for yourself, we recommend sending notifications so ${recipientsLabel} know${
                isConsumer ? "s" : ""
              } what to expect.`}
          <Tooltip
            trigger={
              <span>
                <QuestionMark tw="ml-1 inline-block -mt-0.5" />
              </span>
            }
            title="Notifications"
            placement="auto"
          >
            <p>
              Recommended. If enabled, we'll send email and/or SMS notifications
              to your recipients letting them know your gift is on the way.
            </p>
            <p>
              They'll also receive a tracking number when the item ships.
              Recipients will receive your digital card and see the gift you
              chose, and can optionally send a thank you note.
            </p>
            <p>
              If disabling notifications, be sure to tell your recipients that a
              gift is on the way!
            </p>
          </Tooltip>
        </div>
      </div>
    </div>
  )
}
