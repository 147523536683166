import React from "react"
import { NavLink, useRouteMatch } from "react-router-dom"
import tw, { styled } from "twin.macro"

import { generateRealmPath } from "@/common/realm"

interface Props {}
export default function GiftBatchSubNav({}: Props) {
  const routeMatch = useRouteMatch()

  return (
    <SubNavWrapper>
      <SubNav>
        <SubNavItem
          title="Overview"
          to={generateRealmPath("plus", `${routeMatch.url}`)}
          exact
        >
          Overview
        </SubNavItem>
        <SubNavItem
          title="Recipients"
          to={generateRealmPath("plus", `${routeMatch.url}/recipients`)}
          exact
        >
          Recipients
        </SubNavItem>
        <SubNavItem
          title="Settings"
          to={generateRealmPath("plus", `${routeMatch.url}/settings`)}
          exact
        >
          Settings
        </SubNavItem>
      </SubNav>
    </SubNavWrapper>
  )
}

const SubNavWrapper = tw.div`whitespace-nowrap w-full overflow-y-auto lg:h-16 lg:h-auto lg:overflow-y-visible z-10 flex`
const SubNav = tw.nav`container flex flex-row whitespace-nowrap -ml-4`
const SubNavItem = styled(NavLink)`
  ${tw`relative inline-block p-4 pb-2 text-center text-gray-450 transition text-lg hover:text-primary-new-600`};

  /**
    Reserves container space for bolded version of text. We need to
    pass in the link text to the "title" attribute.
   */
  &::before {
    ${tw`invisible block h-0 font-medium`};
    content: attr(title);
  }

  &::after {
    ${tw`absolute inline-block bg-gray-200 rounded-full opacity-0 left-4 right-4 transition`};
    bottom: -2px;
    content: "";
    height: 3px;
  }

  &:hover {
    &::after {
      ${tw`opacity-100 bg-primary-200`};
    }
  }

  &.active {
    ${tw`font-medium text-primary-new-600 font-semibold`};

    &::after {
      ${tw`opacity-100 bg-primary-new-600 font-semibold`};
    }
  }
`
