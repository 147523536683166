import React from "react"

import { ReactComponent as AutogiftIcon } from "../../assets/icons/autogift.svg"
import { ReactComponent as SettingsIcon } from "../../assets/icons/settings.svg"
import Button from "../../common/Button"

interface AutogiftIndexButtonProps {
  onClick: () => void
}

const AutogiftIndexButton: React.FC<AutogiftIndexButtonProps> = ({
  onClick,
}) => {
  return (
    <Button onClick={onClick}>
      <div tw="flex flex-row border-r border-primary-100 pr-3">
        <div tw="flex justify-center items-center pr-1">
          <AutogiftIcon />
        </div>
        <div tw="text-primary-500">Autogift</div>
      </div>
      <div tw="flex justify-center items-center">
        <SettingsIcon />
      </div>
    </Button>
  )
}

export default AutogiftIndexButton
