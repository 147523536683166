import tw from "twin.macro"

import { DomesticShippingTag } from "../DomesticShippingTag"
import { GlobalShippingTag } from "../GlobalShippingTag"

import ToggleSwitch from "@/common/components/ToggleSwitch"
import { CountryFlag } from "@/common/CountryFlag"
import {
  ShippingCountry,
  ShippingCountryGroupEnum,
} from "@/types/graphql-types"

interface Props {
  selectedCountry: ShippingCountry
  domesticOnly: boolean
  toogleDomesticOnly: () => void
}

export const ShippingOptionsBanner = ({
  selectedCountry,
  domesticOnly,
  toogleDomesticOnly,
}: Props) => {
  const { groups: shippingGroups, name: countryName } = selectedCountry

  const domesticExplanation = `Denotes gifts that ship domestically within ${countryName}. Cheaper, faster, and more environmentally friendly.`
  const globalExplanation = `Ships cross-border from the United States to ${countryName}. Import duties, tax, and freight apply, up to $50 (Goody pays for any remainder over $50).`
  const giftCardsExplanation = `Goody supports gift cards for ${countryName}.`

  return (
    <div tw="grid grid-cols-1 gap-12 md:gap-6 md:grid-cols-3 items-start justify-start rounded-xl border border-solid border-gray-150 p-7">
      <div tw="flex flex-col gap-3">
        <span tw="text-base text-gray-500 font-medium">
          {shippingGroups.includes(ShippingCountryGroupEnum.domestic)
            ? "Shipping options for"
            : "Shipping to"}
        </span>
        <div tw="flex items-center gap-3">
          <CountryFlag code={selectedCountry.code} size="l" />
          <span tw="text-2xl text-gray-900 font-normal">{countryName}</span>
        </div>
      </div>

      {shippingGroups.includes(ShippingCountryGroupEnum.domestic) ? (
        <>
          <div tw="flex flex-col">
            <DomesticShippingTag country={selectedCountry} />
            <Text tw="mt-4 max-w-[290px]">{domesticExplanation}</Text>

            <div tw="flex flex-row items-center gap-3 mt-3">
              <ToggleSwitch
                width={32}
                height={20}
                isOn={domesticOnly}
                onClick={toogleDomesticOnly}
              />
              <div tw="text-sm text-gray-450 font-normal">
                Show only domestic shipping gifts
              </div>
            </div>
          </div>
          <div tw="flex flex-col">
            <GlobalShippingTag />
            <Text tw="mt-4 max-w-[310px]">{globalExplanation}</Text>
          </div>
        </>
      ) : (
        <div tw="col-span-2 self-center">
          <Text>
            {shippingGroups.includes(ShippingCountryGroupEnum.global)
              ? globalExplanation
              : giftCardsExplanation}
          </Text>
        </div>
      )}
    </div>
  )
}

const Text = tw.span`text-base text-gray-600 font-normal`
