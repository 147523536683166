import { useEffect } from "react"

import { useCurrentGift } from "./currentGift"
import { useGiftCart, useGiftData } from "./giftData"

import {
  GiftSwapTypeEnum,
  InternationalShippingTierEnum,
} from "@/types/graphql-types"

export const useGiftEffects = () => {
  const [currentGift] = useCurrentGift()

  const { setSwapType, setInternationalShippingTier } = useGiftData()
  const { cartQuantity, hasForceSwapEnabledItem, hasCustomStore } =
    useGiftCart()

  useEffect(() => {
    // Custom stores must be swap_single
    if (
      currentGift.swapType !== GiftSwapTypeEnum.swap_single &&
      hasCustomStore
    ) {
      setSwapType(GiftSwapTypeEnum.swap_single)
    }

    // Force swap enabled can't be swap_disabled
    if (
      currentGift.swapType === GiftSwapTypeEnum.swap_disabled &&
      hasForceSwapEnabledItem
    ) {
      setSwapType(GiftSwapTypeEnum.swap_single)
    }
  }, [
    hasForceSwapEnabledItem,
    hasCustomStore,
    currentGift.swapType,
    setSwapType,
  ])

  // hasFeatureFlag("global_partners") can't be used here, since this
  // hook is on the main App, which is outside the feature flag provider
  useEffect(() => {
    if (cartQuantity >= 2) {
      // New international shipping tiers
      if (
        [InternationalShippingTierEnum.full].includes(
          currentGift.internationalShippingTier,
        )
      ) {
        setInternationalShippingTier(InternationalShippingTierEnum.standard)
      }

      // Old international shipping tiers
      if (
        [
          InternationalShippingTierEnum.gift_cards,
          InternationalShippingTierEnum.global_partners,
          InternationalShippingTierEnum.global_relay,
        ].includes(currentGift.internationalShippingTier)
      ) {
        setInternationalShippingTier(InternationalShippingTierEnum.disabled)
      }
    }
  }, [
    cartQuantity,
    currentGift.internationalShippingTier,
    setInternationalShippingTier,
  ])
}
