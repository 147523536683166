import { useState } from "react"
import tw, { styled } from "twin.macro"

import { CopySmall } from "../../../assets/icons"
import Button from "../../../common/Button"
import { formatPrice } from "../../../common/format"
import AccountSection from "../AccountSection"

interface Props {
  referralUrl: string
  creditAwarded: number | undefined
}
export const ReferralUrlAndReward = ({ referralUrl, creditAwarded }: Props) => {
  const [copied, setCopied] = useState(false)
  const copy = async (referral_code: string | null | undefined) => {
    if (!referral_code) return

    await navigator.clipboard.writeText(referral_code)
    setCopied(true)
  }

  return (
    <>
      <AccountSection headline="My Referral Link">
        <BoxContainer>
          <div tw="flex flex-row items-center">
            <div tw="text-lg flex-1">{referralUrl}</div>
            <div>
              {navigator.clipboard ? (
                <Button
                  tw={"flex flex-row items-center"}
                  onClick={() => {
                    copy(referralUrl)
                  }}
                >
                  <span tw={"text-primary-400"}>
                    {copied ? "Copied!" : "Copy"}
                  </span>
                  <CopySmall tw={"ml-1"} />
                </Button>
              ) : null}
            </div>
          </div>
        </BoxContainer>
      </AccountSection>
      <AccountSection headline="My Rewards">
        <BoxContainer>
          {creditAwarded !== null && (
            <div tw="flex flex-row items-center">
              <div tw="bg-green-100 text-green-600 font-medium px-4 py-1 rounded-full text-lg">
                {
                  // @ts-ignore
                  formatPrice(creditAwarded, true)
                }
              </div>
              <div tw="text-lg text-gray-500 pl-2">credit awarded</div>
            </div>
          )}
        </BoxContainer>
      </AccountSection>
    </>
  )
}

const BoxContainer = styled.div`
  ${tw`flex flex-1 flex-col bg-white border border-gray-150 rounded-xl max-w-[50rem] p-4`}
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
`
