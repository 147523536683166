import { useEffect, useMemo, useState } from "react"
import tw from "twin.macro"

import { MINIMUM_FLEX_GIFT_PRICE_IN_DOLLARS } from "./constants"

import { formatPrice } from "@/common/format"

interface Props {
  amount: number
  setAmount: (amount: number) => void
}

// Includes 10% tax estimate and 5% processing fee
// 1.1 * 1.05 = 1.155
// See: https://www.notion.so/ongoody/Flex-Gift-UI-V2-b18529f5e23e4e47aef261c775f23eaf?pvs=4#4893f953264e4e7ab28ef3df60ec33fd
const INCLUDE_TAX_FEES_FACTOR = 1.155

export default function FlexGiftCalculator({ amount, setAmount }: Props) {
  const [recipients, setRecipients] = useState("20")
  const [budget, setBudget] = useState("2000")
  const [includeTaxesFees, setIncludeTaxesFees] = useState(false)

  const recipientsInt = useMemo(() => {
    const parsed = parseInt(recipients)
    return isNaN(parsed) ? null : parsed
  }, [recipients])

  const budgetInt = useMemo(() => {
    const parsed = parseInt(budget)
    return isNaN(parsed) ? null : parsed
  }, [budget])

  const paramsAreValid =
    recipientsInt !== null &&
    budgetInt !== null &&
    recipientsInt > 0 &&
    budgetInt > 0

  useEffect(() => {
    if (paramsAreValid) {
      const taxesFeesFactor = includeTaxesFees ? INCLUDE_TAX_FEES_FACTOR : 1
      setAmount(Math.floor(budgetInt / taxesFeesFactor / recipientsInt))
    }
  }, [recipientsInt, budgetInt, includeTaxesFees])

  const formattedBudget = budgetInt !== null ? formatPrice(budgetInt * 100) : ""

  const perRecipientWithTaxesFeesInCents = Math.round(
    amount * INCLUDE_TAX_FEES_FACTOR * 100,
  )

  return (
    <div tw="mt-4 bg-white border border-gray-200 rounded-xl shadow-min">
      <div tw="p-5">
        <div tw="flex flex-row items-start gap-12">
          <div>
            <div tw="font-medium pb-2">Total recipients</div>
            <div>
              <Input
                type="text"
                value={recipients}
                tw="w-[100px]"
                onChange={(e) => {
                  const val = e.target.value
                  const parsed = parseInt(val)

                  if (!isNaN(parsed)) {
                    setRecipients(parsed.toString())
                  } else if (val === "") {
                    setRecipients("")
                  }
                }}
              />
            </div>
          </div>
          <div>
            <div tw="font-medium pb-2">Total budget for all recipients</div>
            <div>
              <Input
                type="text"
                value={formattedBudget}
                tw="w-[120px]"
                onChange={(e) => {
                  const val = e.target.value
                    .replace("$", "")
                    .replaceAll(",", "")
                    .trim()
                  const parsed = parseInt(val)

                  if (!isNaN(parsed)) {
                    setBudget(parsed.toString())
                  } else if (val === "") {
                    setBudget("")
                  }
                }}
              />
            </div>
          </div>
        </div>
        <label tw="text-gray-600">
          <input
            type="checkbox"
            tw="mt-4"
            checked={includeTaxesFees}
            onChange={(e) => {
              setIncludeTaxesFees(e.target.checked)
            }}
          />
          &nbsp;&nbsp;My budget needs to include taxes and fees
        </label>
      </div>

      {paramsAreValid && (
        <div tw="p-5 border-t border-gray-200">
          <div tw="font-medium text-lg">
            Each recipient can select a gift up to{" "}
            <span tw="p-2 bg-primary-new-050 text-primary-new-600 ml-1 rounded-lg">
              ${amount}
            </span>
          </div>
          {amount < MINIMUM_FLEX_GIFT_PRICE_IN_DOLLARS ? (
            <div tw="pt-1.5 text-orange-alt-500">
              Amount must be at least $15.
            </div>
          ) : includeTaxesFees ? (
            <>
              <div tw="pt-1.5 text-gray-500">
                After shipping, taxes, and fees, each accepted gift would cost a
                maximum of approx.{" "}
                <span tw="font-medium text-gray-800">
                  {formatPrice(perRecipientWithTaxesFeesInCents)}
                </span>{" "}
                for a total spend of{" "}
                <span tw="font-medium text-gray-800">
                  {formatPrice(
                    perRecipientWithTaxesFeesInCents * (recipientsInt || 0),
                  )}
                </span>
                . You only pay for accepted gifts.
              </div>
            </>
          ) : (
            <div tw="pt-1.5 text-gray-500">
              You only pay for accepted gifts. Shipping is included.
            </div>
          )}
        </div>
      )}
    </div>
  )
}

const Input = tw.input`border border-gray-300 rounded-lg px-3 py-2 text-gray-700 outline-none focus:(outline-none border-primary-new-400) transition-all`
