import { CurrentWorkspace } from "./GlobalState"

let workspaceContext: CurrentWorkspace | null = null

export function setWorkspaceContext(newWorkspaceContext: CurrentWorkspace) {
  workspaceContext = newWorkspaceContext
}

export function getWorkspaceContext() {
  return workspaceContext
}
