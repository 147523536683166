import tw, { styled } from "twin.macro"

import staticAssetUrl from "../../../common/staticAssetUrl"
import { ReactComponent as CheckCircle } from "../images/check-circle.svg"

interface Props {}

export default function PostableCaseStudy({}: Props) {
  return (
    <>
      <CaseStudyContainer tw="mx-auto w-full max-w-[1500px] bg-[#fcfaf3] mt-24 flex flex-col lg:flex-row items-stretch relative">
        <div tw="flex-1 p-8 md:p-18">
          <div tw="flex flex-col md:flex-row items-start md:items-center justify-between gap-4">
            <h2 tw="text-3xl md:text-5xl font-250 font-reckless-neue text-[#857228]">
              Case study
            </h2>
            <img
              src={staticAssetUrl("static/web/landing/api/postable-logo.svg")}
              alt="Postable"
              tw="w-[150px] md:w-auto"
            />
          </div>
          <div tw="pt-18 xl:pr-20 2xl:pr-24 flex flex-col items-stretch gap-12">
            <div>
              <PointHeader>The challenge</PointHeader>
              <PointContent>
                Physical greeting card platform Postable wanted to let its users
                add gifts to their cards — without worrying about inventory,
                order fulfillment, or logistics.
              </PointContent>
            </div>
            <div>
              <PointHeader>The solution</PointHeader>
              <PointContent>
                Using the Commerce API, Postable added gifting upsells into its
                website. Postable users can choose from dozens of gift brands.
              </PointContent>
              <PointContent>
                When orders are received, Goody handles fulfillment and provides
                shipping updates to gift recipients.
              </PointContent>
            </div>
            <div>
              <PointHeader>The results</PointHeader>
              <PointContent tw="pt-2">
                <div tw="flex flex-row items-start gap-4">
                  <CheckCircle tw="mt-1" />
                  <div tw="flex-1">
                    Hundreds of gifts sent within days of launch
                  </div>
                </div>
              </PointContent>
              <PointContent tw="pt-2">
                <div tw="flex flex-row items-start gap-4">
                  <CheckCircle tw="mt-1" />
                  <div tw="flex-1">Increased AOV and margins</div>
                </div>
              </PointContent>
            </div>
          </div>
        </div>
        <div tw="absolute top-0 bottom-0 hidden lg:flex flex-col items-center justify-center lg:right-[22%] xl:right-[24%] 2xl:right-[28%]">
          <img
            src={staticAssetUrl("static/web/landing/api/postable-ui.png")}
            tw="max-w-[352px]"
          />
        </div>
        <div
          tw="w-full h-[360px] sm:h-[600px] lg:h-auto lg:w-[550px] 2xl:w-[600px] bg-cover bg-center overflow-hidden"
          css={{
            backgroundImage: `url(${staticAssetUrl(
              "static/web/landing/api/postable-scene.jpg",
            )})`,
          }}
        >
          <img
            src={staticAssetUrl("static/web/landing/api/postable-ui.png")}
            tw="max-w-[200px] sm:max-w-[352px] mt-6 lg:hidden"
          />
        </div>
      </CaseStudyContainer>
      <img
        src={staticAssetUrl("static/web/landing/api/postable-dots.svg")}
        tw="mx-auto invisible md:visible"
      />
      <div tw="mx-5">
        <div tw="bg-[#fcf8ec] max-w-[900px] mx-auto p-6 md:p-11 rounded-[1.25rem] flex flex-row items-center relative">
          <img
            src={staticAssetUrl("static/web/landing/api/postable-jesse.jpg")}
            tw="max-w-[150px] md:max-w-[213px] absolute -top-12 md:-top-8 md:-left-8 rounded-[1.25rem]"
          />
          <div tw="pt-40 md:(pt-0 pl-44) flex flex-col items-stretch gap-4 text-gray-700">
            <h3 tw="text-2xl font-medium">
              The Goody Commerce API was such a huge win for us.
            </h3>
            <div tw="text-lg md:text-xl leading-[1.5]!">
              It lets us seamlessly offer Goody gifts as an add-on during the
              checkout process, without having to invest in inventory or build
              out backend order fulfillment.
            </div>
            <div tw="text-lg md:text-xl leading-[1.5]!">
              It opened up a new sales channel for us, and allowed us to launch
              in weeks rather than quarters.
            </div>
            <div tw="text-gray-500">
              <strong tw="font-medium">Jesse Blockton</strong> — Co-Founder,
              Postable
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const PointHeader = tw.h3`text-[1.75rem] font-extralight font-reckless-neue text-[#857228] italic`
const PointContent = tw.div`pt-4 text-gray-700 text-lg md:text-xl leading-[1.5]!`
const CaseStudyContainer = styled.div`
  @media (min-width: 1500px) {
    ${tw`rounded-2xl overflow-hidden`};
  }
`
