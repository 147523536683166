import React from "react"

import AllIntegrations from "./Integrations/AllIntegrations"
import Salesforce from "./Integrations/Salesforce"
import RealmRoute from "../common/RealmRoute"
import Container from "../sites/App/Container"

const Integrations = () => {
  return (
    <Container tw="px-5 pt-16" css={{ minHeight: "60vh" }}>
      <RealmRoute path="/organization/integrations" exact realm="plus">
        <AllIntegrations />
      </RealmRoute>
      <RealmRoute
        path="/organization/integrations/salesforce"
        exact
        realm="plus"
      >
        <Salesforce />
      </RealmRoute>
    </Container>
  )
}

export default Integrations
