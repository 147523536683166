import tw, { styled } from "twin.macro"

import { ReactComponent as CloseIcon } from "../../assets/icons/x.svg"

const StyledCloseIcon = styled(CloseIcon)`
  g {
    ${tw`opacity-60`};
  }
`

export default StyledCloseIcon
