import React, { useEffect, useState } from "react"
import Modal from "react-modal"
import { styled } from "twin.macro"

import { ReactComponent as PlayButton } from "./images/play.svg"
import { useQueryParams } from "../../common/gtm"
import { modalStyle } from "../../common/modal"

interface HowItWorksVideoCTAProps {
  customHeader?: React.ReactNode
}

const HowItWorksVideoCTA = ({ customHeader }: HowItWorksVideoCTAProps) => {
  const expandVideo = useQueryParams().get("expand_vid") === "1"

  const [videoModalOpen, setVideoModalOpen] = useState(expandVideo)
  const [autoplay, setAutoplay] = useState(!expandVideo)

  useEffect(() => {
    if (videoModalOpen) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: "BusinessLanding_VideoView",
      })
    } else {
      // If video isn't from initial expand, we want autoplay to be true
      setAutoplay(true)
    }
  }, [videoModalOpen])

  return (
    <>
      <button
        tw="flex flex-row  items-center transition-opacity hover:opacity-75 active:opacity-90"
        onClick={() => setVideoModalOpen(true)}
      >
        <PlayButton />
        {customHeader ? (
          customHeader
        ) : (
          <div tw="ml-2 font-medium text-gray-800">How it works</div>
        )}
      </button>
      <BusinessVideoModal
        autoplay={autoplay}
        isOpen={videoModalOpen}
        setIsOpen={setVideoModalOpen}
      />
    </>
  )
}

interface BusinessVideoModalProps {
  autoplay: boolean
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const BusinessVideoModal = ({
  autoplay,
  isOpen,
  setIsOpen,
}: BusinessVideoModalProps): JSX.Element => {
  return (
    <Modal
      isOpen={isOpen}
      closeTimeoutMS={500}
      onRequestClose={() => setIsOpen(false)}
      shouldCloseOnOverlayClick={true}
      style={modalStyle}
    >
      <VideoContainer>
        <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
          <iframe
            src={`https://player.vimeo.com/video/683933685?h=056d229761&amp;badge=0&amp;autopause=0&amp;autoplay=${
              autoplay ? "1" : "0"
            }&amp;player_id=0&amp;app_id=58479`}
            frameBorder="0"
            sandbox="allow-scripts allow-same-origin"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
            title="Goody for Business | How It Works"
          />
        </div>
      </VideoContainer>
    </Modal>
  )
}

const VideoContainer = styled.div`
  width: 100vw;

  @media (min-width: 1024px) {
    width: 80vw;
  }
`

export default HowItWorksVideoCTA
