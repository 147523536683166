import { useState } from "react"

import { track } from "@/common/analytics"
import { createHookContext } from "@/common/hooks/createHookContext"
import { useCurrentGift } from "@/common/hooks/currentGift"
import { useBusinessSend } from "@/common/hooks/send"
import { BatchSendMethod } from "@/types/graphql-types"

export type PreviewButtonLocation = "Message" | "Sidebar" | "Bottom"
type PreviewButtonType = "Email" | "Gift"

export const trackClickPreview = ({
  location,
  type,
}: {
  location: PreviewButtonLocation
  type: PreviewButtonType
}) => {
  track("Business - Send - Click Preview", { location, type })
}

const useGiftPreviewLocal = () => {
  const [modalOpen, setModalOpen] = useState(false)
  const [noCartModalOpen, setNoCartModalOpen] = useState(false)

  const { saveGiftDraft, giftBatchesDraftCreateLoading } = useBusinessSend()
  const [currentGift] = useCurrentGift()

  const previewVisible = currentGift.sendMethod !== BatchSendMethod.direct_send

  const setModalClosed = () => setModalOpen(false)
  const setNoCartModalClosed = () => setNoCartModalOpen(false)

  const openPreview = async (force = false) => {
    if (!force && currentGift.cart.length === 0) {
      setNoCartModalOpen(true)
    } else {
      setModalOpen(true)
      const result = await saveGiftDraft()
      if (!result) {
        setModalOpen(false)
      }
    }
  }

  return {
    modalOpen,
    openPreview,
    setModalClosed,
    loading: giftBatchesDraftCreateLoading,
    noCartModalOpen,
    setNoCartModalClosed,
    previewVisible,
  }
}

export const { Provider: GiftPreviewProvider, useHook: useGiftPreview } =
  createHookContext("PaymentMethods", useGiftPreviewLocal)
