import { NavLink } from "react-router-dom"
import tw, { css, styled } from "twin.macro"

export const SubNavWrapper = tw.div`whitespace-nowrap w-full overflow-y-auto h-16 lg:h-auto lg:overflow-y-visible z-10 border-b flex flex-col justify-end`

export const SubNav = tw.nav`container mx-auto flex flex-row items-end whitespace-nowrap`

export const SubNavStartSpacer = tw.div`pl-2 lg:pl-1`

export const SubNavEndSpacer = tw.div`pr-2 lg:pr-8 flex-1`

/**
 Before element reserves container space for bolded version of text. We need to
 pass in the link text to the "title" attribute.
 */
const SubNavBase = css`
  ${tw`
  relative inline-block p-4 pb-2 text-center text-gray-500 transition text-lg 
  hover:text-primary-500 mb-0.5 
  before:(block font-medium h-0 invisible content-[attr(title)]) 
  after:(absolute bg-gray-200 inline-block left-4 right-4 rounded-full opacity-0 transition bottom-[-2px] h-[3px] content-[""])
  hover:(after:(bg-primary-200 opacity-100))
  active:(after:(bg-primary-300 opacity-100))
  `};

  &.active {
    ${tw`font-medium text-primary-500 after:(bg-primary-500 opacity-100)`};
  }
`
export const SubNavItem = styled(NavLink)`
  ${SubNavBase}
`

export const SubNavButton = styled.button`
  ${SubNavBase}
`
