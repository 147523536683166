interface RequestContext {
  storeType?: string
}

export type WithContext<V> = {
  requestContext: RequestContext
} & V

export const withContext = <Variables>(
  variables: Variables,
  context: RequestContext,
): WithContext<Variables> => ({
  ...variables,
  requestContext: context,
})
