import React from "react"
import tw, { styled } from "twin.macro"

import { useGlobalState } from "../../common/GlobalState"
import { getToday9AMEST } from "../../common/utilities"

import { AutogiftRuleStatus } from "@/types/graphql-types"

const AutogiftSameDayAvailabilityMessage: React.FC = () => {
  const [currentAutogiftRule] = useGlobalState("currentAutogiftRule")

  const sendTimeHasPassed = () => {
    const date = new Date()
    return date >= getToday9AMEST()
  }

  const removeUnderscore = (text: string) => {
    if (text) {
      return text.replace(/_/g, " ")
    } else {
      return text
    }
  }

  const sendTimeHasPassedMessage = (eventType: string) => {
    return (
      "Since 9am ET has already passed, no autogift will be scheduled for contacts with a " +
      removeUnderscore(eventType.toLocaleLowerCase()) +
      " today. You can still send them a regular gift."
    )
  }

  const sendTimeHasNotPassedMessage = (contactsWithEventToday: string[]) => {
    const contactsString =
      contactsWithEventToday.length > 1
        ? "each of these contacts"
        : "this contact"

    return (
      "Since 9am ET has not yet passed, an autogift will be created for " +
      contactsString +
      " and sent at 9am ET."
    )
  }

  return (
    <div>
      {currentAutogiftRule.status === AutogiftRuleStatus.PAUSED &&
        currentAutogiftRule.contactsWithEventToday.length > 0 && (
          <SameDayAvailabilityContainer>
            <div
              tw="rounded-xl"
              css={{
                backgroundColor: sendTimeHasPassed() ? "#EB5757" : "#F2994A",
                width: "8px",
              }}
            />
            <div css={{ marginLeft: "18px" }}>
              <div
                tw="font-medium mb-2"
                css={{
                  marginTop: "14px",
                  color: sendTimeHasPassed() ? "#EB5757" : "#F2994A",
                }}
              >
                {currentAutogiftRule.contactsWithEventToday.length} contact
                {currentAutogiftRule.contactsWithEventToday.length > 1
                  ? "s"
                  : ""}{" "}
                ha
                {currentAutogiftRule.contactsWithEventToday.length > 1
                  ? "ve"
                  : "s"}{" "}
                a{" "}
                {removeUnderscore(
                  currentAutogiftRule.eventType.toLocaleLowerCase(),
                )}{" "}
                today
              </div>
              <div
                tw="mb-4"
                css={{
                  color: "#4B5563",
                  lineHeight: "140%",
                }}
              >
                {sendTimeHasPassed()
                  ? sendTimeHasPassedMessage(currentAutogiftRule.eventType)
                  : sendTimeHasNotPassedMessage(
                      currentAutogiftRule.contactsWithEventToday,
                    )}
              </div>
              <div
                css={{
                  marginBottom: "14px",
                  lineHeight: "140%",
                }}
              >
                {currentAutogiftRule.contactsWithEventToday.map((contact) => (
                  <div tw="flex flex-row items-center" key={contact}>
                    <ul tw="list-disc pl-4">
                      <li>{contact}</li>
                    </ul>
                  </div>
                ))}
              </div>
            </div>
          </SameDayAvailabilityContainer>
        )}
    </div>
  )
}

const SameDayAvailabilityContainer = styled.div`
  border: 1px solid rgba(235, 87, 87, 0.2);
  box-sizing: border-box;
  box-shadow:
    0px 0px 4px rgba(0, 0, 0, 0.05),
    0px 0px 32px rgba(0, 0, 0, 0.04);
  padding: 6px;
  ${tw`bg-white rounded-lg flex flex-row`};
`

export default AutogiftSameDayAvailabilityMessage
