import React, { Dispatch, SetStateAction, useEffect, useState } from "react"
import tw, { styled } from "twin.macro"

import GiftList from "./GiftList"
import { ReactComponent as CustomStoreIcon } from "./images/custom-store.svg"
import { ReactComponent as CloseX } from "../../assets/icons/x-close.svg"

import { CustomStoreFullFragment } from "@/types/graphql-types"

interface SelectedGiftsDrawerProps {
  customStore: CustomStoreFullFragment
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  refetchCustomStore: Function
}

const SelectedGiftsDrawer: React.FC<SelectedGiftsDrawerProps> = ({
  customStore,
  open,
  setOpen,
  refetchCustomStore,
}) => {
  const [openDrawer, setOpenDrawer] = useState(false)

  const openSelectedGiftsDrawer = () => {
    setOpenDrawer(true)
  }

  // close drawer and hide child components
  const closeSelectedGiftsDrawer = () => {
    setOpenDrawer(false)

    // Timeout to allow for CustomStoreBrowser to slide down, fade out overlay, and then remove the div
    setTimeout(function () {
      // To reset the open prop to false when closing the drawer
      // This must be called here also since it doesn't get called on the onClose callback when using this method
      // to close the drawer
      setOpen(false)
    }, 500)
  }

  // when the open prop changes, open / close the drawer based on the prop
  // this will allow for opening/closing the drawer from outside the component
  useEffect(() => {
    if (open) {
      openSelectedGiftsDrawer()
    } else {
      closeSelectedGiftsDrawer()
    }
  }, [open])

  return (
    <>
      <button
        className="fadeIn animated"
        tw="fixed w-screen h-screen bottom-24 left-0 duration-200 ease-in-out transition-all transform cursor-default"
        css={{
          zIndex: 2001,
          backgroundColor: "rgba(122, 122, 122, 0.5)",
          ...(openDrawer ? tw`opacity-100` : tw`delay-200 opacity-0`),
        }}
        onClick={closeSelectedGiftsDrawer}
      ></button>
      <SelectedGiftsContainer
        tw="bottom-[5.25rem] fixed z-20 left-1/2 -translate-x-1/2 transition-all duration-200 ease-in-out overflow-y-scroll max-w-[100vw]"
        css={{
          zIndex: 2002,
          ...(openDrawer ? tw`scale-100 opacity-100` : tw`scale-60 opacity-0`),
        }}
      >
        <div tw="flex justify-between items-center">
          <CustomStoreIcon tw="w-5 text-gray-350" />
          <div tw="font-semibold text-black">Selected gifts</div>
          <button
            tw="rounded-full bg-gray-150 flex items-center justify-center w-6 h-6 hover:bg-gray-200 active:bg-gray-250"
            onClick={closeSelectedGiftsDrawer}
          >
            <CloseX tw="w-4 h-4 text-gray-400" />
          </button>
        </div>
        {!!customStore && (
          <GiftList
            customStore={customStore}
            refetchCustomStore={refetchCustomStore}
            isInsideGiftsDrawer={true}
          />
        )}
      </SelectedGiftsContainer>
    </>
  )
}

const SelectedGiftsContainer = styled.div`
  ${tw`rounded-xl p-4`}
  width: 30.0625rem;
  height: 37rem;
  background-color: #f6f7f9;
  box-shadow:
    0px 8px 32px rgba(0, 0, 0, 0.12),
    0px 1px 4px rgba(0, 0, 0, 0.03);
`

export default SelectedGiftsDrawer
