import { ReactNode, useEffect, useState } from "react"
import useCollapse from "react-collapsed"
import tw, { css } from "twin.macro"

import cardAmex from "@/assets/images/payment-cards/amex.png"
import cardCorporateAccount from "@/assets/images/payment-cards/corporate-account.png"
import cardDiscover from "@/assets/images/payment-cards/discover.png"
import cardMastercard from "@/assets/images/payment-cards/mastercard.png"
import cardNew from "@/assets/images/payment-cards/new-card.svg"
import cardVisa from "@/assets/images/payment-cards/visa.png"
import Image from "@/common/Image"

export const cardImage = (brand: string | null = null) => {
  switch (brand) {
    case "amex":
      return cardAmex
    case "discover":
      return cardDiscover
    case "mastercard":
      return cardMastercard
    case "visa":
      return cardVisa
    case "goody":
      return cardCorporateAccount
    default:
      return cardNew
  }
}

export interface Props {
  image: string
  label: string
  sublabel?: string
  subtext?: string
  selected: boolean
  ghostButtons?: boolean
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void
  children?: ReactNode
  additionalContentWhenSelected?: ReactNode | null
  selectable?: boolean
}

const PaymentMethod = ({
  image,
  label,
  sublabel,
  subtext,
  selected,
  ghostButtons = false,
  onClick,
  children,
  additionalContentWhenSelected,
  selectable = true,
}: Props) => {
  const [additionalContentExpanded, setAdditionalContentExpanded] =
    useState(false)
  const { getCollapseProps } = useCollapse({
    isExpanded: additionalContentExpanded,
  })

  const bgColor = selected ? tw`bg-white` : tw`bg-gray-075 hover:bg-gray-100`
  const textColor = selected ? tw`text-primary-new-700` : tw`text-gray-800`
  const sublabelTextColor = selected
    ? tw`text-primary-new-700`
    : tw`text-gray-500`
  const border = selected
    ? tw`border-solid border-2 border-primary-new-300`
    : tw`border-solid border-2 border-transparent`
  const buttonDisplay = ghostButtons && !selected ? tw`hidden` : undefined
  const cursor = selectable ? tw`cursor-pointer` : tw`cursor-default`
  const scale = selectable ? tw`active:scale-[.98]` : undefined

  useEffect(() => {
    setAdditionalContentExpanded(selected && !!additionalContentWhenSelected)
  }, [selected, additionalContentWhenSelected])

  return (
    <button
      css={[
        tw`rounded-[10px] px-3 transition-all text-base`,
        css`
          &:has(.payment-method-button:active) {
            ${tw`scale-100`}
          }
          &:hover,
          &:focus {
            .payment-method-buttons {
              display: flex;
            }
          }
        `,
        bgColor,
        border,
        cursor,
        scale,
      ]}
      onClick={onClick}
    >
      <div tw="flex">
        <div tw="mt-5 mr-4 pl-2 min-w-[32px]">
          <Image src={image} tw="w-8 h-5" />
        </div>
        <div tw="flex items-start sm:items-center gap-4 w-full flex-col sm:flex-row justify-between my-5 sm:my-0">
          <div tw="flex flex-col sm:my-5">
            <div tw="flex gap-3 leading-5 whitespace-nowrap flex-wrap">
              <div css={[tw`font-medium`, textColor]}>{label}</div>
              <div css={[sublabelTextColor]}>{sublabel}</div>
            </div>
            {subtext && (
              <div tw="mt-1 text-sm text-gray-450 text-left">{subtext}</div>
            )}
          </div>
          {children && (
            <div
              className="payment-method-buttons"
              css={[tw`flex gap-2 flex-shrink-0`, buttonDisplay]}
            >
              {children}
            </div>
          )}
        </div>
      </div>
      <div {...getCollapseProps()}>
        <div tw="flex mb-3">{additionalContentWhenSelected}</div>
      </div>
    </button>
  )
}

export default PaymentMethod
