import React, { ReactNode } from "react"
import { Helmet } from "react-helmet-async"

import Container from "../../sites/App/Container"

interface Props {
  title: string
  children?: ReactNode
}

const BasicContainer: React.FC<Props> = ({ title, children }) => {
  return (
    <div>
      <Helmet>
        <title>{title} – Goody</title>
      </Helmet>

      <Container tw={"px-6"}>
        <div tw={"font-semibold text-6xl text-center mt-16"}>{title}</div>
        <div tw={"py-24"}>{children}</div>
      </Container>
    </div>
  )
}

export default BasicContainer
