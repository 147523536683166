import React, { ReactNode } from "react"
import tw, { styled } from "twin.macro"

import { ReactComponent as TeamUpgrade } from "@/assets/images/team-plan-upgrade.svg"

interface UpsellProps {
  children: ReactNode
}

const Upsell = ({ children }: UpsellProps) => {
  return (
    <Container>
      <IconColumn>
        <TeamUpgrade />
      </IconColumn>
      <div tw="flex flex-col">{children}</div>
    </Container>
  )
}

const Container = styled.div`
  ${tw`flex flex-row bg-white rounded-lg border-[#eceef1] border-solid border max-w-[763px]`}

  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
`

const IconColumn = styled.div`
  ${tw`p-[0.6625rem]`};

  background: linear-gradient(
    180deg,
    #f2f0ff 0%,
    rgba(242, 240, 255, 0) 156.07%
  );
  border-radius: 7px 0px 0px 7px;
`

export default Upsell
