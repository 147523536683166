import React from "react"
import tw, { styled } from "twin.macro"

interface Props {
  className?: string
  onClick?: () => void
  label: React.ReactNode
  hideArrow?: boolean
  useCheck?: boolean
  disabled?: boolean
  light?: boolean
  type?: "button" | "submit" | "reset"
  rounded?: boolean
}

const SmallButton: React.FC<Props> = ({
  className,
  onClick,
  label,
  hideArrow,
  useCheck,
  disabled,
  light,
  type,
  rounded,
}) => {
  return (
    <ButtonContainer
      className={className}
      light={light}
      onClick={onClick}
      disabled={disabled || false}
      type={type}
      rounded={rounded}
    >
      <div>{label}</div>
      {(useCheck || !hideArrow) && (
        <div tw="pl-6">
          {useCheck ? (
            <svg
              tw="ml-1" // To make it even in size with the arrow
              width="16"
              height="12"
              viewBox="0 0 16 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.6673 1L5.50065 10.1667L1.33398 6"
                stroke="#9159D6"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#a372df"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-arrow-right"
            >
              <line x1="5" y1="12" x2="19" y2="12" />
              <polyline points="12 5 19 12 12 19" />
            </svg>
          )}
        </div>
      )}
    </ButtonContainer>
  )
}

interface ButtonContainerProps {
  light?: boolean
  rounded?: boolean
}

const ButtonContainer = styled.button<ButtonContainerProps>`
  ${tw`inline-flex flex-row items-center text-primary-600 bg-primary-100 rounded py-2 px-3 focus:outline-none transition-colors`}
  ${({ rounded }) => (rounded ? tw`rounded-full px-5` : null)}
  ${({ light }) =>
    light
      ? tw`hover:bg-primary-050 active:bg-primary-100 focus-visible:bg-primary-050 bg-white`
      : tw`hover:bg-primary-150 active:bg-primary-200 focus-visible:bg-primary-200 bg-primary-100`}
  
  &:disabled {
    ${tw`cursor-auto opacity-50`}
    ${({ light }) =>
      light
        ? tw`hover:bg-white focus-visible:bg-white bg-white`
        : tw`hover:bg-primary-100 focus-visible:bg-primary-100 bg-primary-100`}
  }
`

export const GraySmallButton = tw(
  SmallButton,
)`bg-gray-200 hover:bg-gray-300 text-gray-500 focus-visible:bg-gray-300`

export const OutlinedSmallButton = tw(
  SmallButton,
)`border border-primary-100 hover:border-primary-200`

export default SmallButton
