import { useMemo } from "react"

import { BatchRecipient, useGlobalState } from "../../common/GlobalState"
import { useCurrentGift } from "../../common/hooks/currentGift"

import { BatchSendMethod } from "@/types/graphql-types"

// No matter if the user is using a send method that is sending to multiple
// recipients ("email_and_link" or "link_multiple_custom_list"), or a send
// method that is sending to a single recipient ("link_single"), we always store
// the recipients in the same global state variable `recipients`.
//
// However, when we need to run things like price estimates or send up the list
// of recipients to the server in giftBatchCreate, that list of recipients needs
// to be responsive to the current send method.
//
// useRecipientsData() is a hook that returns the *effective recipients*, which
// is the list of recipients that should be used for the current send method.
export default function useRecipientsData() {
  const [currentGift] = useCurrentGift()
  const [globalRecipients] = useGlobalState("recipients")

  const sendMethod = currentGift.sendMethod

  // Get the global recipient array for the current send method.
  const effectiveRecipients = useMemo<BatchRecipient[]>(() => {
    switch (sendMethod) {
      case BatchSendMethod.email_and_link:
        return globalRecipients
      case BatchSendMethod.link_multiple_custom_list:
        return extractRecipientsWithoutPhone(globalRecipients)
      case BatchSendMethod.link_single:
        return extractLinkSingleRecipient(globalRecipients)
      case BatchSendMethod.link_multiple_anyone:
      case BatchSendMethod.link_unspecified:
        return []
      default:
        return globalRecipients
    }
  }, [sendMethod, globalRecipients])

  return {
    effectiveRecipients,
  }
}

// Extract first recipient, setting email and phone to blank
function extractLinkSingleRecipient(
  recipients: BatchRecipient[],
): BatchRecipient[] {
  const firstRecipient = recipients[0]

  if (firstRecipient) {
    return [
      {
        ...firstRecipient,
        email: "",
        phone: "",
      },
    ]
  }

  return []
}

// Extract recipients without phone
function extractRecipientsWithoutPhone(
  recipients: BatchRecipient[],
): BatchRecipient[] {
  // return recipients with phone set to empty string
  return recipients.map((recipient) => ({
    ...recipient,
    phone: "",
  }))
}
