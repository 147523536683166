import { Draft } from "immer"
import { cloneDeep, isBoolean, mapValues, merge } from "lodash-es"
import { useEffect } from "react"
import { useExperiment } from "statsig-react"
import { useImmer } from "use-immer"

import { EXPERIMENTS, ExperimentName } from "@/common/experiments"
import { useGlobalState } from "@/common/GlobalState"
import { createHookContext } from "@/common/hooks/createHookContext"
import { useFeatureFlags } from "@/common/hooks/featureFlags"
import useRecordExperimentAssignment from "@/common/hooks/useRecordExperimentAssignment"

const DEFAULT_EXPERIMENT = {
  loading: true,
  values: {},
  active: false,
}

const useExperimentsLocal = () => {
  const [experiments, setExperiments] = useImmer<
    Partial<{
      readonly [key in ExperimentName]: {
        loading: boolean
        values: (typeof EXPERIMENTS)[key]["defaults"]
        active: boolean
      }
    }>
  >({})

  const [user] = useGlobalState("user")
  const { hasFeatureFlag } = useFeatureFlags()

  const experimentIsActive = (experimentName: ExperimentName) => {
    const experiment = EXPERIMENTS[experimentName]

    return (
      !("active" in experiment) ||
      (isBoolean(experiment.active)
        ? experiment.active
        : experiment.active({ user, hasFeatureFlag }) ?? false)
    )
  }

  const getExperimentDefaultValues = <T extends ExperimentName>(
    experimentName: T,
  ) =>
    EXPERIMENTS[experimentName].defaults as (typeof EXPERIMENTS)[T]["defaults"]

  const getDefaultExperiment = <T extends ExperimentName>(experimentName: T) =>
    merge(cloneDeep(DEFAULT_EXPERIMENT), {
      values: getExperimentDefaultValues(experimentName),
    }) as NonNullable<(typeof experiments)[T]>

  const getExperiment = <T extends ExperimentName>(experimentName: T) =>
    experiments[experimentName] ?? getDefaultExperiment(experimentName)

  const getDraftExperiment = <T extends ExperimentName>(
    draft: Draft<typeof experiments>,
    experimentName: T,
  ) => (draft[experimentName] ||= getDefaultExperiment(experimentName))

  const getExperimentValues = <T extends ExperimentName>(experimentName: T) =>
    getExperiment(experimentName).active
      ? getExperiment(experimentName).values
      : getExperimentDefaultValues(experimentName)

  const getExperimentLoading = <T extends ExperimentName>(experimentName: T) =>
    getExperiment(experimentName).loading

  const setExperimentData = <T extends ExperimentName>(
    name: T,
    experimentValues: (typeof EXPERIMENTS)[T]["defaults"],
    loading: boolean,
  ) => {
    setExperiments((draft) => {
      const draftExperiment = getDraftExperiment(draft, name)
      draftExperiment.values = merge(draftExperiment.values, experimentValues)
      draftExperiment.loading = loading
    })
  }

  const triggerExperiment = (name: ExperimentName) =>
    setExperiments((draft) => {
      const draftExperiment = getDraftExperiment(draft, name)
      const active = experimentIsActive(name)
      draftExperiment.active = active
      // If we're not active, ensure that we're not loading
      if (!active) {
        draftExperiment.loading = false
        // If we just changed to active, make sure that we're loading
      } else if (active && !draftExperiment.active) {
        draftExperiment.loading = true
      }
    })

  const getExperimentConfig = <T extends ExperimentName>(name: T) => {
    return merge(
      { loading: getExperimentLoading(name) },
      getExperimentValues(name),
    )
  }

  return {
    getExperimentConfig,
    getExperimentDefaultValues,
    setExperimentData,
    triggerExperiment,
    experiments,
  }
}

const { Provider, useHook: useExperiments } = createHookContext(
  "useExperimentValuesLocal",
  useExperimentsLocal,
)

type ExperimentValuesProviderProps = React.ComponentProps<typeof Provider>

const ExperimentsProvider = ({
  children,
  ...restProps
}: ExperimentValuesProviderProps) => {
  return (
    <Provider {...restProps}>
      {children}
      <Experiments />
    </Provider>
  )
}
const Experiments = () => {
  const { experiments } = useExperiments()

  return (
    <>
      {(Object.keys(experiments) as ExperimentName[])
        .filter((experimentName) => experiments[experimentName]?.active)
        .map((experimentName) => (
          <Experiment key={experimentName} name={experimentName} />
        ))}
    </>
  )
}

const Experiment = ({ name }: { name: ExperimentName }) => {
  const { setExperimentData, getExperimentDefaultValues } = useExperiments()

  const experimentDefaultValues = getExperimentDefaultValues(name)

  const { config, isLoading } = useExperiment(name)
  const experimentValues = mapValues(
    experimentDefaultValues,
    (defaultValue, key) => config.get(key, defaultValue),
  ) as unknown as (typeof EXPERIMENTS)[ExperimentName]["defaults"]

  const group = experimentValues.group_name

  useRecordExperimentAssignment({
    experiment: name,
    isLoading,
    group,
  })

  useEffect(() => setExperimentData(name, experimentValues, isLoading))

  return null
}

export { ExperimentsProvider, useExperiments }
