import React from "react"

import BasicContainer from "./components/BasicContainer"
import {
  BasicAnswer,
  BasicHeader,
  BasicList,
  BasicListItem,
  BasicParagraphStart,
  BasicQuestion,
  BasicSection,
  BasicSectionStart,
  PrimarySpan,
} from "./Ui"

const ReferralTerms: React.FC = () => {
  return (
    <BasicContainer title={"Referral Terms"}>
      <BasicSection>
        <BasicHeader>FAQ</BasicHeader>
        <BasicQuestion>How do referrals on Goody work?</BasicQuestion>
        <BasicAnswer>
          You can refer friends to Goody by sending them your invite code or
          invite link. When they use your code or link to install Goody, they'll
          receive $15 off their first qualifying purchase of $50 or more. Once
          they complete their purchase, you'll also receive a referral credit of
          $15, for up to 10 referrals.
        </BasicAnswer>
        <BasicQuestion>
          Who can accept a referral and get the $15 credit?
        </BasicQuestion>
        <BasicAnswer>
          Only new users who make a qualifying purchase of $50 or more are
          eligible.
        </BasicAnswer>
        <BasicQuestion>
          What's the maximum amount of referral credit I can get?
        </BasicQuestion>
        <BasicAnswer>
          You can refer up to 10 people that you get referral credit for. After
          10, anyone who joins with your code will still get their $15 credit,
          but you won't receive credit when they join.
        </BasicAnswer>
        <BasicQuestion>
          My friend was offered a no-cost gift when they installed the app. Does
          that replace my referral?
        </BasicQuestion>
        <BasicAnswer>
          The no-cost gift is free, so the $15 credit doesn't apply to that gift
          and you won't receive referral credit for that gift. If they send a
          regular gift in the amount of $50 or more, they can still apply your
          referral code as long as they haven't used another referral or promo
          code.
        </BasicAnswer>
        <BasicQuestion>
          Can the referral code be combined with other offers or credits?
        </BasicQuestion>
        <BasicAnswer>
          No, the referral code cannot be combined with any other offers or
          credits.
        </BasicAnswer>
      </BasicSection>
      <BasicSection>
        <BasicHeader>Terms & Conditions</BasicHeader>
        <BasicSectionStart>
          <PrimarySpan>Effective Date</PrimarySpan>: 2/11/2022
        </BasicSectionStart>
        <BasicParagraphStart>
          Participants in the Goody Referral Program (“Program”) are subject to
          Goody’s{" "}
          <a
            href="https://www.ongoody.com/terms"
            target="_blank"
            rel="noopener noreferrer"
            tw="underline"
          >
            Terms of Use
          </a>{" "}
          and{" "}
          <a
            href="https://www.ongoody.com/privacy"
            target="_blank"
            rel="noopener noreferrer"
            tw="underline"
          >
            Privacy Policy
          </a>{" "}
          (both available on our website), as well as the following additional
          Terms & Conditions for the Program. By participating in the Program,
          each participant represents and warrants that he/she has read and
          agrees to be bound by the{" "}
          <a
            href="https://www.ongoody.com/terms"
            target="_blank"
            rel="noopener noreferrer"
            tw="underline"
          >
            Terms of Use
          </a>
          ,{" "}
          <a
            href="https://www.ongoody.com/privacy"
            target="_blank"
            rel="noopener noreferrer"
            tw="underline"
          >
            Privacy Policy
          </a>{" "}
          and these Terms & Conditions for the Program.
        </BasicParagraphStart>
        <BasicList>
          <BasicListItem>
            <PrimarySpan>Eligibility.</PrimarySpan> Referral codes may only be
            used by new Goody users.
          </BasicListItem>
          <BasicListItem>
            <PrimarySpan>Referral.</PrimarySpan> To refer your eligible people
            to Goody, send your referral code or referral link. You can tap the
            message button or share button to send your referral code and link.
          </BasicListItem>
          <BasicListItem>
            <PrimarySpan>Referral Award.</PrimarySpan> For every person that you
            refer via your code that both (a) creates a Goody account as a new
            user and (b) uses the unique referral code to purchase and send a
            Goody gift (excluding free welcome gifts), you will receive a $15
            account credit from Goody, up to a maximum of 10 referrals per
            account. Each referred person who creates a Goody account will also
            receive a $15 account credit from Goody. All $15 account credits may
            be used at any time on any qualifying purchase of at least $50. $15
            account credits for referral recipients cannot be combined with
            other offers or credits. After you have referred 10 people on your
            account, recipients will continue to receive a $15 account credit,
            but you will not receive additional credit for the referral once you
            have reached this maximum. If two or more individuals refer the same
            person, referral credit will be given based on which code was used
            to send a gift. Credit award decisions are in Goody's sole
            discretion. Credits may not be transferred; no cash or other
            substitutions. The referral code cannot be combined with other
            offers, promotions, or codes.
          </BasicListItem>
          <BasicListItem>
            <PrimarySpan>Referral Messages.</PrimarySpan> Referral messages
            should be sent only through the Program as described in these Terms
            & Conditions. Please send your referrals only to eligible people,
            and limit your referrals to a reasonable number. Use only truthful,
            non-misleading statements that accurately reflect Goody's services
            and its Referral Program when sending a referral message. Referring
            persons must comply with all applicable laws, regulations and
            guidelines, including up-to-date “SPAM” laws. For example, emails
            must be created and distributed in a personal manner. Bulk email
            distribution and any use of automated devices or third party
            services is prohibited. Any distribution of a Program referral link
            that could constitute unsolicited commercial email or "spam" under
            any applicable law or regulation, or that otherwise violates any
            restriction imposed by Goody or is otherwise inappropriate as
            determined by Goody in its sole discretion, is expressly prohibited
            and will be grounds for immediate exclusion from the Program.
          </BasicListItem>
          <BasicListItem>
            <PrimarySpan>Right to Cancel Program or Change Terms.</PrimarySpan>{" "}
            Goody reserves the right to cancel the Referral Program or to change
            these Terms & Conditions at any time in its sole discretion. Any
            unclaimed referral rewards will be forfeited at that time. Goody
            reserves the right to disqualify anyone from this Program if any
            participant abuses this Program, as determined by Goody in its sole
            discretion.
          </BasicListItem>
          <BasicListItem>
            <PrimarySpan>Severability.</PrimarySpan> If any provision in these
            terms are held to be invalid, void, or unenforceable, such provision
            (or the part of it that is making it invalid, void or unenforceable)
            will be struck and not affect the validity of and enforceability of
            the remaining provisions.
          </BasicListItem>
        </BasicList>
      </BasicSection>
    </BasicContainer>
  )
}

export default ReferralTerms
