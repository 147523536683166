import tw, { styled } from "twin.macro"

const RainbowHeadingStyle = tw`
  text-4xl lg:text-5xl
  leading-tight lg:leading-tight tracking-tight
  text-transparent font-medium
  bg-gradient-to-r from-gradient-purple-light to-gradient-purple-dark bg-clip-text
`

const RainbowHeading = styled.h1`
  ${RainbowHeadingStyle}
`

export default RainbowHeading
