import useCollapse from "react-collapsed"
import tw from "twin.macro"

import HTMLRenderer from "@/common/HTMLRenderer"
import { ReactComponent as ChevronDown } from "@/common/images/chevron-down.svg"
import { Details_ProductFragment } from "@/types/graphql-types"

type ProductAttributeType = NonNullable<
  Details_ProductFragment["productAttributes"]
>[0]

interface ProductAttributeProps {
  productAttribute: ProductAttributeType
}

const ProductAttribute = ({ productAttribute }: ProductAttributeProps) => {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse()
  const isExpandable = productAttribute.displayType === "expandable"
  return (
    <div>
      <button
        tw={"uppercase font-semibold text-sm leading-[17px] text-gray-500"}
        css={
          isExpandable
            ? tw`hover:text-primary-600 active:text-primary-600`
            : tw`pointer-events-none`
        }
        {...(isExpandable ? getToggleProps() : {})} // Add getToggleProps if the collapsible button should be active
      >
        {productAttribute.label}
        {isExpandable && (
          <ChevronDown
            tw="stroke-current transition-transform inline mb-1 ml-2 stroke-[1.5px]"
            css={[isExpanded && tw`rotate-180`]}
          />
        )}
      </button>
      <div
        {...(isExpandable ? getCollapseProps() : {})}
        tw={"text-gray-700 text-base leading-5 mt-2"}
      >
        <HTMLRenderer html={productAttribute.content} />
      </div>
    </div>
  )
}

interface ProductAttributesProps {
  productAttributes: ProductAttributeType[]
}

const ProductAttributes = ({ productAttributes }: ProductAttributesProps) => {
  return (
    <div tw={"flex flex-col gap-5 mt-10 pl-0 font-text"}>
      {productAttributes.map((productAttribute) => (
        <ProductAttribute
          key={productAttribute.label}
          productAttribute={productAttribute}
        />
      ))}
    </div>
  )
}

export default ProductAttributes
