import React from "react"
import Select from "react-select"
import tw from "twin.macro"

import { SelectDropdownIndicator } from "../../../assets/icons"
import { getColor } from "../../../common/tailwind"

// Custom styled select
const customStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    ...(state.isFocused ? tw`hover:border-primary-500` : null),
    border: "1px solid #D1D5DB",
    boxShadow: "0 1px 2px rgba(0, 0, 0, 0.05)",
    height: 42,
    ...tw`transition-border`,
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,
    ...tw`mr-3 ml-1`,
  }),
  singleValue: (provided: any) => ({
    ...provided,
    ...tw`mx-1`,
  }),
}

const customTheme = (theme: any) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: getColor("primary-500"),
    primary50: getColor("primary-200"),
    primary25: getColor("primary-100"),
    primary75: getColor("primary-400"),
  },
})

const CustomSelect: React.FC<React.ComponentProps<typeof Select>> = ({
  styles,
  components,
  ...restProps
}) => {
  return (
    <Select
      styles={{ ...customStyles, ...styles }}
      components={{
        DropdownIndicator: SelectDropdownIndicator as any,
        IndicatorSeparator: () => null,
        ...components,
      }}
      theme={customTheme}
      {...restProps}
    />
  )
}

export default CustomSelect
