import { gql, useMutation } from "@apollo/client"
import { useEffect, useRef } from "react"
import { Statsig } from "statsig-react"

import {
  ExperimentAssignmentRecordCreateMutation,
  ExperimentAssignmentRecordCreateMutationVariables,
} from "@/types/graphql-types"

interface Params {
  experiment: string
  isLoading: boolean
  group: string | null
}

// Record an experiment assignment result to the backend. Only records once per
// hook mount. Only records when isLoading is false and group is not null.
export default function useRecordExperimentAssignment({
  experiment,
  isLoading,
  group,
}: Params) {
  const recorded = useRef(false)
  const [experimentAssignmentRecordCreate] = useMutation<
    ExperimentAssignmentRecordCreateMutation,
    ExperimentAssignmentRecordCreateMutationVariables
  >(EXPERIMENT_ASSIGNMENT_RECORD_CREATE_MUTATION)

  useEffect(() => {
    if (recorded.current || isLoading || group === null) {
      return
    }

    const stableID = Statsig.getStableID()

    experimentAssignmentRecordCreate({
      variables: {
        experiment,
        group,
        stableId: stableID,
      },
    })
    recorded.current = true
  }, [experiment, isLoading, group])
}

const EXPERIMENT_ASSIGNMENT_RECORD_CREATE_MUTATION = gql`
  mutation ExperimentAssignmentRecordCreate(
    $experiment: String!
    $group: String!
    $stableId: String!
  ) {
    experimentAssignmentRecordCreate(
      experiment: $experiment
      group: $group
      stableId: $stableId
    ) {
      ok
    }
  }
`
