export const modalStyle = {
  overlay: {
    backgroundColor: "transparent",
    // zIndex was raised to make the modal visible over the MUI drawer which has a
    // default zIndex of 1200
    zIndex: 1301,
  },
  content: {
    position: "fixed" as "fixed",
    // zIndex was raised here also to make the modal visible over the MUI drawer
    zIndex: 1300,
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "none",
    borderRadius: 0,
    padding: 0,
    border: 0,
  },
}

export const fullScreenModalStyle = {
  overlay: {
    ...modalStyle.overlay,
    zIndex: 1501,
  },
  content: {
    ...modalStyle.content,
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    margin: 0,
    transform: "none",
    backgroundColor: "transparent",
  },
}

export const level2ModalStyle = {
  overlay: {
    ...modalStyle.overlay,
    zIndex: 1501,
  },
  content: {
    ...modalStyle.content,
    zIndex: 1500,
  },
}

export const topModalStyle = {
  overlay: {
    ...modalStyle.overlay,
    zIndex: 1601,
  },
  content: {
    ...modalStyle.content,
    zIndex: 1600,
  },
}

// JANKY BUT NO OTHER WAY
// USE WITH .modal-content-max-height on .modal-content
export const maxHeightModalStyle = {
  overlay: modalStyle.overlay,
  content: {
    ...modalStyle.content,
    pointerEvents: "none" as "none",
    height: "100%",
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
  },
}
