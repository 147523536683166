import React from "react"
import { Helmet } from "react-helmet-async"

import HowAutogiftingWorks from "./HowAutogiftingWorks"
import HeroDesktop from "./images/HeroDesktop.png"
import HeroMobile from "./images/HeroMobile.png"
import WhyAutogifting from "./WhyAutogifting"
import { useGlobalState } from "../../../common/GlobalState"
import { generateRealmPath } from "../../../common/realm"
import Carousel from "../../business/Carousel"
import Brands from "../components/Brands"
import CallToAction from "../components/CallToAction"
import Divider from "../components/Divider"
import Hero from "../components/Hero"
import IntegrateHR from "../components/IntegrateHR"

const AutomatedGifting = () => {
  const [signedIn] = useGlobalState("signedIn")
  const goTo = (path: string) => generateRealmPath("business", path)

  return (
    <>
      <Helmet>
        <title>Automated Gifting — Goody for Business</title>
        <meta
          name="description"
          content="Automate gifting for onboarding, birthdays, and work anniversaries. Integrate your HR stack for automated gifts that boost employee engagement and morale."
        />
      </Helmet>

      <Hero
        heading="Autogifting"
        title="Automated gifts that boost engagement and morale"
        subtitle="Integrate your HR stack and send automated gifts for work anniversaries, birthdays, and onboarding."
        checkItems={["Personalized employee gifts", "30+ HR integrations"]}
        variant="list-on-action"
        primaryAction={{
          text: "Book a Demo",
          linkTo: goTo("/employee-engagement-demo"),
        }}
        image={{
          desktop: HeroDesktop,
          mobile: HeroMobile,
        }}
        hideSecondaryAction={true}
      />

      <div tw="mt-24">
        <Brands
          title="Thousands of companies use Goody to drive employee engagement"
          variant="secondary"
        />
      </div>

      <Divider tw="mt-16 md:mt-24 md:pt-0.5 mb-12 md:mb-20 z-0" />

      <div tw="mb-16 pb-2 lg:mb-28 lg:pb-0 z-0">
        <WhyAutogifting />
      </div>

      <div tw="pb-12">
        <HowAutogiftingWorks />
      </div>

      <IntegrateHR
        title="Powered by seamless integrations with 30+ HR platforms"
        description="Securely connect your HRIS in minutes. Effortlessly scale your gifting programs as your team grows."
        primaryAction={{
          text: "View Plans",
          linkTo: goTo("/pricing"),
        }}
        background="light"
      />

      <Carousel />

      <CallToAction
        signedIn={signedIn}
        showSecondaryAction={false}
        title="Take Autogifting for a spin."
        description="Learn how your company can send effortless employee engagement gifts, powered by 30+ seamless HR integrations."
        primaryAction={{
          text: "Book a Demo",
          linkTo: goTo("/employee-engagement-demo"),
        }}
      />
      <Divider tw="mt-20" />
    </>
  )
}

export default AutomatedGifting
