import React from "react"
import tw, { styled } from "twin.macro"

import { Row } from "./common"
import DropdownMenu, {
  DropdownMenuItem,
  DropdownMenuPanel,
} from "../../common/DropdownMenu"
import { formatPrice } from "../../common/format"
import { ReactComponent as MoreIcon } from "../../common/images/more-horizontal.svg"

import { BalanceGiftStatus } from "@/types/graphql-types"
import { BalanceGift } from "@/types/graphql-types"

interface Props {
  gift: BalanceGift
  cancelGift: (id: string) => void
  isOwnActivity: boolean
}

export default function ActivityGift({
  gift,
  cancelGift,
  isOwnActivity,
}: Props) {
  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    // Don't let this close the gift batch group.
    e.stopPropagation()

    const ok = window.confirm(
      `Are you sure you want to cancel the gift for ${
        gift.recipientName
      }?\n\n${formatPrice(
        gift.storedAmount || 0,
      )} will be returned to your balance.`,
    )

    if (ok) {
      cancelGift(gift.giftPublicId)
    }
  }

  return (
    <Row tw="border-t border-gray-150">
      <div tw="flex flex-row items-center justify-between">
        <div tw="flex flex-row items-center">
          <div>{gift.recipientName}</div>
          <ActionOptions>
            {isOwnActivity && gift.status === BalanceGiftStatus.PENDING && (
              <DropdownMenu
                trigger={
                  <div tw="group-hover:bg-gray-100 group-active:bg-gray-200 ml-4 p-1 rounded-full">
                    <MoreIcon fill="black" tw="h-4 w-4" />
                  </div>
                }
              >
                <GiftDropdownMenuPanel>
                  <DropdownMenuItem>
                    <button onClick={handleCancel}>
                      Cancel gift for {gift.recipientName}
                    </button>
                  </DropdownMenuItem>
                </GiftDropdownMenuPanel>
              </DropdownMenu>
            )}
          </ActionOptions>
        </div>
        <StatusIndicator status={gift.status} />
      </div>
      <div tw="text-right tabular-nums text-yellow-600">
        {gift.status === BalanceGiftStatus.PENDING &&
          gift.storedAmount &&
          formatPrice(gift.storedAmount)}
      </div>
      <div tw="text-right tabular-nums">
        {gift.status === BalanceGiftStatus.FINALIZED &&
          gift.postAcceptAdjustedAmount &&
          formatPrice(gift.postAcceptAdjustedAmount)}
      </div>
    </Row>
  )
}

function StatusIndicator({ status }: { status: BalanceGiftStatus }) {
  switch (status) {
    case BalanceGiftStatus.FINALIZED:
      return <StatusFinalized>Accepted</StatusFinalized>
    case BalanceGiftStatus.PENDING:
      return <StatusPending>Pending</StatusPending>
    case BalanceGiftStatus.EXPIRED:
    case BalanceGiftStatus.CANCELED:
      return <StatusCanceled>Canceled</StatusCanceled>
  }
}

const StatusBase = tw.div`text-sm py-[0.125rem] px-3 rounded-full font-text`
const StatusPending = tw(StatusBase)`text-yellow-600 bg-yellow-100`
const StatusCanceled = tw(StatusBase)`text-gray-450 bg-gray-150`
const StatusFinalized = tw(StatusBase)`text-green-500 bg-green-100`

const ActionOptions = styled.div`
  nav {
    display: block;
    line-height: 1;
    margin: -4px;
  }
`

const GiftDropdownMenuPanel = tw(DropdownMenuPanel)`top-8 z-[99999]`
