import React, { useState } from "react"
import useCollapse from "react-collapsed"
import tw from "twin.macro"

import { ChevronDown } from "./assets/icons"

import DeliveryChart, { DeliveryStats } from "@/track/components/DeliveryChart"

interface Props {
  deliveryStats: DeliveryStats | null
  directSend: boolean
}
export default function GiftBatchDeliveryStatus({
  deliveryStats,
  directSend,
}: Props) {
  const [isExpanded, setIsExpanded] = useState(false)
  const { getCollapseProps } = useCollapse({ isExpanded })

  return (
    <div tw="block w-full flex flex-col items-center border-b lg:border-0">
      <div tw="w-full flex justify-center">
        <button
          tw="w-[208px] rounded-full p-2 mb-3 lg:mb-6 hover:bg-gray-075 active:bg-gray-150 transition-transform active:scale-90"
          onClick={() => {
            setIsExpanded((prev) => !prev)
          }}
        >
          <div tw="flex flex-row items-center justify-center lg:mt-auto">
            <div tw="text-gray-450 mr-4">Show shipping status</div>
            <div
              tw="transition-transform"
              css={[isExpanded && tw`transform rotate-180`]}
            >
              <ChevronDown />
            </div>
          </div>
        </button>
      </div>
      <div tw="w-full" {...getCollapseProps()}>
        <div>
          {deliveryStats &&
            (deliveryStats.delivered !== 0 ||
              deliveryStats.preparing !== 0 ||
              deliveryStats.shipped !== 0) && (
              <div tw="p-5 border-gray-200" css={[!directSend && tw`border-t`]}>
                <div tw="text-lg text-gray-500 font-medium pb-4">Delivery</div>
                {deliveryStats && <DeliveryChart stats={deliveryStats} />}
              </div>
            )}
        </div>
      </div>
    </div>
  )
}
