import React, { useEffect } from "react"
import ReactPaginate from "react-paginate"
import tw, { styled } from "twin.macro"

import { ReactComponent as ChevronIcon } from "../images/chevron-down.svg"

type Props = {
  pageIndex: number
  perPage: number
  setPageIndex: (page: number) => void
  totalCount: number
  preventScrollToTop?: boolean
} & React.ComponentPropsWithoutRef<typeof Container>

const Pagination: React.FC<Props> = ({
  pageIndex,
  perPage,
  setPageIndex,
  totalCount,
  preventScrollToTop,
  ...props
}) => {
  const handlePageChange = ({ selected }: { selected: number }) => {
    setPageIndex(selected)
  }

  useEffect(() => {
    if (!preventScrollToTop) {
      window.scrollTo(0, 0)
    }
  }, [pageIndex])

  return (
    <Container {...props}>
      {totalCount > perPage && (
        <StyledReactPaginate
          pageCount={Math.ceil(totalCount / perPage)}
          pageRangeDisplayed={3}
          marginPagesDisplayed={window.innerWidth > 1080 ? 1 : 0}
          forcePage={pageIndex}
          onPageChange={handlePageChange}
          previousLabel={<ChevronIcon tw="rotate-90" />}
          nextLabel={<ChevronIcon tw="-rotate-90" />}
          breakLabel="⋯"
        />
      )}
    </Container>
  )
}

const Container = styled.span`
  ${tw`bottom-0 w-full flex justify-center lg:pb-6`}
  background: linear-gradient(0deg, rgba(255, 255, 255, 100%) 0%, rgba(255, 255, 255, 0%) 100%);
`

const StyledReactPaginate = styled(ReactPaginate)`
  ${tw`flex items-center justify-center bg-white p-1 rounded-full lg:w-9/12 select-none`}
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05), 0 6px 32px rgba(0, 0, 0, 0.05);
  max-width: 30rem;

  > li {
    > a {
      ${tw`flex w-10 h-10 items-center justify-center rounded-lg text-gray-400 bg-white transition-colors hover:text-primary-500 active:text-primary-700`}
    }

    // Hide break symbol and additional 4th number on small displays.
    &.break,
    &:nth-child(5):not(.next) {
      ${tw`hidden lg:block`}
    }

    &.selected > a {
      ${tw`text-primary-600 bg-primary-000`}
    }

    &.previous,
    &.next {
      ${tw`flex flex-grow`}

      > a {
        ${tw`w-12 h-12 flex-initial rounded-full hover:bg-primary-000 active:bg-primary-050 duration-300`}
      }
    }

    &.previous {
      ${tw`justify-start`}
    }

    &.next {
      ${tw`justify-end`}
    }

    &.disabled {
      ${tw`text-gray-300 pointer-events-none`}

      svg {
        ${tw`text-gray-200`}
      }
    }
  }

  svg {
    ${tw`stroke-current text-primary-400 stroke-2 scale-90`}
  }
`

export default Pagination
