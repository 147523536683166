import React from "react"
import { Helmet } from "react-helmet-async"

interface Props {
  location: string
}

export default function PrerenderRedirect({ location }: Props) {
  return (
    <Helmet>
      <meta name="prerender-status-code" content="301" />
      <meta name="prerender-header" content={`Location: ${location}`} />
    </Helmet>
  )
}
