import { isNil } from "lodash-es"
import React, { useCallback, useContext, useState } from "react"

const useAddBalanceModalLocal = () => {
  const [addBalanceModalOpen, setAddBalanceModalOpen] = useState(false)
  const [defaultBalance, setDefaultBalance] = useState<number | null>(null)

  const openAddBalanceModal = useCallback(
    (defaultBalance: number | null = null) => {
      setDefaultBalance(defaultBalance)
      setAddBalanceModalOpen(true)
    },
    [setDefaultBalance, setAddBalanceModalOpen],
  )

  const closeAddBalanceModal = useCallback(
    () => setAddBalanceModalOpen(false),
    [setAddBalanceModalOpen],
  )

  return {
    addBalanceModalOpen,
    openAddBalanceModal,
    closeAddBalanceModal,
    defaultBalance,
  }
}

const AddBalanceModalContext = React.createContext<null | ReturnType<
  typeof useAddBalanceModalLocal
>>(null)

export const AddBalanceModalProvider = (
  props: Omit<
    React.ComponentProps<typeof AddBalanceModalContext.Provider>,
    "value"
  >,
) => {
  return (
    <AddBalanceModalContext.Provider
      value={useAddBalanceModalLocal()}
      {...props}
    />
  )
}

export const useAddBalanceModal = () => {
  const context = useContext(AddBalanceModalContext)

  if (isNil(context)) {
    throw new Error(
      "useAddBalanceModal must be used within AddBalanceModalContext",
    )
  }

  return context
}
