import { useQuery } from "@apollo/client"
import React, { ReactNode, useState } from "react"
import { Helmet } from "react-helmet-async"
import { Link } from "react-router-dom"

import BundledReceiptsTable from "@/account/components/receipts/BundledReceiptsTable"
import { ReactComponent as Calendar } from "@/assets/icons/calendar-current-color.svg"
import { ReactComponent as ChevronRight } from "@/assets/icons/chevron-right.svg"
import { ReactComponent as CircleUser } from "@/assets/icons/circle-user-current-color.svg"
import Callout from "@/common/Callout"
import Button from "@/common/CommonButton"
import useTeamPlan from "@/common/hooks/teamPlan"
import useSet from "@/common/hooks/useSet"
import Pagination from "@/common/pagination/Pagination"
import { generateRealmPath } from "@/common/realm"
import organizationBundledReceiptMembers from "@/organization/graphql/organizationBundledReceiptMembers"
import organizationBundledReceipts from "@/organization/graphql/organizationBundledReceipts"
import DateFilterDrawer from "@/organization/receipts/DateFilterDrawer"
import FilterButton from "@/organization/receipts/FilterButton"
import OrganizationReceiptsUpsell from "@/organization/receipts/OrganizationReceiptsUpsell"
import UserFilterDrawer from "@/organization/receipts/UserFilterDrawer"
import Container from "@/sites/App/Container"
import {
  Organization_BundledReceiptMembersQuery,
  Organization_BundledReceiptsQuery,
  Organization_BundledReceiptsQueryVariables,
} from "@/types/graphql-types"

const PER_PAGE = 20

const Loading = () => <div tw="mt-9 text-gray-400">Loading...</div>

const AccountReceiptButton = ({ children }: { children: ReactNode }) => (
  <Link to={generateRealmPath("plus", "/account/receipts")}>
    <Button rightIcon={<ChevronRight tw="text-gray-400 h-5 w-5" />}>
      {children}
    </Button>
  </Link>
)

const Receipts = () => {
  const [page, setPage] = useState(0)
  const [userFilterOpen, setUserFilterOpen] = useState(false)
  const [dateFilterOpen, setDateFilterOpen] = useState(false)
  const [fromDate, setFromDate] = useState<Date | null>(null)
  const [toDate, setToDate] = useState<Date | null>(null)
  const [userIds, { toggle: toggleUserId, clear: clearUserIds }] =
    useSet<string>()

  const { onTeamPlan, loading: onTeamPlanLoading } = useTeamPlan()

  const { data, loading, refetch } = useQuery<
    Organization_BundledReceiptsQuery,
    Organization_BundledReceiptsQueryVariables
  >(organizationBundledReceipts, {
    variables: {
      page: page + 1,
      perPage: PER_PAGE,
      fromDate,
      toDate,
      userIds: [...userIds],
    },
  })

  const {
    data: bundledReceiptMembersData,
    loading: bundledReceiptMembersDataLoading,
  } = useQuery<Organization_BundledReceiptMembersQuery>(
    organizationBundledReceiptMembers,
  )

  const table =
    (data?.organization?.bundledReceipts?.totalCount ?? 0) > 0 ? (
      <>
        <BundledReceiptsTable
          userColumn={true}
          data={data?.organization?.bundledReceipts?.items ?? []}
        />
        {(data?.organization?.bundledReceipts?.totalCount ?? 0) > PER_PAGE && (
          <Pagination
            tw="mt-[50px]"
            pageIndex={page}
            perPage={PER_PAGE}
            setPageIndex={(index) => {
              setPage(index)
              refetch()
            }}
            totalCount={data?.organization?.bundledReceipts?.totalCount ?? 0}
          />
        )}
      </>
    ) : (
      <div tw="mt-9 text-gray-400">No receipts found</div>
    )

  const tableHeader = (
    <div tw="flex justify-between w-full mb-7 gap-5">
      <div tw="flex gap-5">
        <FilterButton
          active={false}
          icon={<CircleUser />}
          onClick={() => setUserFilterOpen(true)}
        >
          Users
        </FilterButton>
        <FilterButton
          active={false}
          icon={<Calendar />}
          onClick={() => setDateFilterOpen(true)}
        >
          Date
        </FilterButton>
      </div>
      <div tw="flex gap-5">
        <AccountReceiptButton>My receipts</AccountReceiptButton>
        <AccountReceiptButton>Subscription receipts</AccountReceiptButton>
      </div>
    </div>
  )

  return (
    <>
      <Helmet>
        <title>Receipts – Goody for Business</title>
      </Helmet>
      <Container tw="flex flex-col items-center justify-center px-8 py-10">
        {onTeamPlanLoading ? (
          <Loading />
        ) : (
          <>
            {onTeamPlan ? (
              <>
                {tableHeader}
                {loading ? <Loading /> : table}
              </>
            ) : (
              <div tw="flex flex-col gap-8 pt-8">
                <OrganizationReceiptsUpsell />
                <Link
                  to={generateRealmPath("plus", "/account/receipts")}
                  tw="w-[763px]"
                >
                  <Callout
                    variant="info"
                    title="Looking for your own receipts and subscription receipts?"
                    subtext="Click here to go to your Account Receipts page."
                  />
                </Link>
              </div>
            )}
          </>
        )}
      </Container>
      <UserFilterDrawer
        open={userFilterOpen}
        onClose={() => setUserFilterOpen(false)}
        members={
          bundledReceiptMembersData?.organization?.bundledReceiptMembers || []
        }
        userIds={userIds}
        toggleUserId={toggleUserId}
        clearUserIds={clearUserIds}
        loading={bundledReceiptMembersDataLoading}
      />
      <DateFilterDrawer
        open={dateFilterOpen}
        onClose={() => setDateFilterOpen(false)}
        fromDate={fromDate}
        setFromDate={setFromDate}
        toDate={toDate}
        setToDate={setToDate}
      />
    </>
  )
}

export default Receipts
