import { useState } from "react"
import tw, { TwStyle } from "twin.macro"

import { ShipsGloballySwagModal } from "./ShipsGloballySwagModal"

import { useFeatureAccess } from "@/common/hooks/featureAccess"
import { SmallGlobeIcon } from "@/track/components/giftBatch/assets/icons"
import { Product } from "@/types/graphql-types"

interface Props {
  product: {
    swagIntegration?: { id: string } | null
    swagIntegrationActions: Product["swagIntegrationActions"]
    shippingCountriesFinal: Product["shippingCountriesFinal"]
    isOfflineProduct: Product["isOfflineProduct"]
  }
  skipSubscriptionCheck?: boolean
  variant?: "button" | "tag"
  twStyle?: TwStyle
}

export const ShipsGloballyTag = ({
  product,
  skipSubscriptionCheck,
  variant = "button",
  twStyle,
}: Props) => {
  const [openSwagModal, setOpenSwagModal] = useState(false)

  const hasProPlan = useFeatureAccess().hasFeature("pro_plan")

  const isSwagTemplate = !!product.swagIntegration?.id
  const isSwagProduct = product.swagIntegrationActions.length !== 0
  const isOfflineProduct = product.isOfflineProduct

  const hasGlobalShipping = product.shippingCountriesFinal.includes("GLOBAL")

  const isSwag = isSwagTemplate || isSwagProduct || isOfflineProduct

  if (!hasGlobalShipping || !isSwag) return null

  const Content = () => {
    switch (variant) {
      case "button":
        return (
          <>
            <ButtonTag
              onClick={(e) => {
                e.preventDefault()
                setOpenSwagModal(true)
                e.stopPropagation()
              }}
            >
              <span tw="text-gray-400">
                <SmallGlobeIcon />
              </span>
              <span tw="text-gray-600 text-sm font-medium">
                Ships globally
                {!skipSubscriptionCheck && !hasProPlan && " with Goody Pro"}
              </span>
            </ButtonTag>
            <ShipsGloballySwagModal
              isOpen={openSwagModal}
              onClose={() => setOpenSwagModal(false)}
              isSwagTemplate={isSwagTemplate}
            />
          </>
        )
      case "tag":
        return (
          <Tag>
            <span tw="text-gray-350">
              <SmallGlobeIcon height={16} width={16} />
            </span>
            <span tw="text-gray-450 text-sm font-normal">Ships globally</span>
          </Tag>
        )
      default:
        return null
    }
  }

  return (
    <div css={twStyle} tw="flex">
      <Content />
    </div>
  )
}

const Tag = tw.div`
  flex items-center justify-center gap-2 px-2.5 py-2
`

const ButtonTag = tw(Tag)`
  cursor-pointer border border-solid rounded-lg
  bg-gray-050 border-gray-200
  hover:(bg-gray-075 border-gray-250)
  active:(bg-gray-100 border-gray-250 scale-95)
`
