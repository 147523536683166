import tw, { styled } from "twin.macro"

interface Props {
  onClick: () => void
  disabled?: boolean
  loading?: boolean
  label: string
}

export default function FancyButton({
  onClick,
  disabled,
  loading,
  label,
}: Props) {
  return (
    <Button $loading={loading} disabled={disabled || loading} onClick={onClick}>
      <span>{label}</span>
    </Button>
  )
}

const Button = styled.button<{ $loading?: boolean }>`
  ${tw`text-white text-center px-12 py-4 text-lg font-medium rounded-full disabled:(opacity-50 pointer-events-none)`};
  background: linear-gradient(
      184deg,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    #723cfb;
  box-shadow:
    0px 4px 12px 0px rgba(68, 4, 179, 0.25),
    0px 2px 4px 0px rgba(255, 255, 255, 0.15) inset;
  position: relative;

  ${(props) => props.$loading && tw`cursor-wait`};

  span {
    z-index: 10;
    position: relative;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
        184deg,
        rgba(255, 255, 255, 0.3) 0%,
        rgba(255, 255, 255, 0) 100%
      ),
      #723cfb;
    box-shadow:
      0px 4px 12px 0px rgba(68, 4, 179, 0.1),
      0px 2px 4px 0px rgba(255, 255, 255, 0.15) inset;
    border-radius: 9999px;
    z-index: 5;
    opacity: 0;
    transition: opacity 0.1s ease-out;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
        184deg,
        rgba(0, 0, 0, 0.4) 0%,
        rgba(0, 0, 0, 0) 100%
      ),
      #723cfb;
    box-shadow:
      0px 1px 2px 0px rgba(255, 255, 255, 0.15) inset,
      0px 4px 12px 0px rgba(68, 4, 179, 0.25);
    border-radius: 9999px;
    z-index: 5;
    opacity: 0;
    transition: opacity 0.1s ease-out;
  }

  &:hover:before {
    opacity: 1;
  }

  &:active:before {
    opacity: 0;
  }

  &:active:after {
    opacity: 1;
  }
`
