import React from "react"
import { useHistory } from "react-router-dom"

import { ProTagIcon } from "../../assets/icons"
import Button from "../Button"
import { generateRealmPath } from "../realm"

export default function GiftCardNonProSendBlock() {
  const history = useHistory()

  return (
    <>
      <h2 tw="text-lg font-semibold pb-3">
        A Pro subscription is required to send gift cards
      </h2>
      <p tw="font-text">
        Subscribe to Pro to send gift cards, plus add logo branding to gifts,
        send international gifts, schedule gifts, and more.
      </p>
      <Button
        variant="updated"
        onClick={() => {
          history.push(generateRealmPath("plus", "/subscribe-pro"))
        }}
        tw="mt-6 w-full text-center justify-center"
      >
        <ProTagIcon tw="fill-current stroke-0" />
        Upgrade to Pro
      </Button>
    </>
  )
}
