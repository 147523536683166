import { ReactNode, useEffect, useRef, useState } from "react"
import { CSSObject } from "styled-components/macro"
import tw from "twin.macro"

interface Props {
  hidden: boolean
  twBase: CSSObject | CSSObject[]
  twHidden: CSSObject | CSSObject[]
  twVisible: CSSObject | CSSObject[]
  children: ReactNode
}

// A component to enable style transitions when showing or hiding.
// This uses events and delays to ensure that 'display: none' is not added until transitions end,
// and transition styles are not added in until 'display: none' is removed.
const TransitionHideable = ({
  hidden,
  twBase,
  twHidden,
  twVisible,
  children,
}: Props) => {
  const [hide, setHide] = useState(hidden)
  const [transitionable, setTransitionable] = useState(!hidden)
  const [timeoutId, setTimeoutId] = useState<number>()
  const display = hide ? tw`hidden` : undefined
  const twCurrent = !hidden && transitionable ? twVisible : twHidden
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!hidden) {
      setHide(false)
      clearTimeout(timeoutId)
      setTimeoutId(setTimeout(() => setTransitionable(true), 10))
    }

    const div = ref.current
    if (div) {
      const listener = () => {
        if (hidden) {
          setHide(true)
          setTransitionable(false)
        }
      }
      div.addEventListener("transitionend", listener)
      return () => div.removeEventListener("transitionend", listener)
    }
  }, [hidden])

  return (
    <div ref={ref} css={[twBase, display, twCurrent]}>
      {children}
    </div>
  )
}

export default TransitionHideable
