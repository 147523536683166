import { gql, useQuery } from "@apollo/client"
import React from "react"
import { useParams } from "react-router-dom"
import tw, { styled } from "twin.macro"

import AppPortalContainerLoader from "./components/AppPortalContainerLoader"
import APITokens, { API_TOKEN_FRAGMENT } from "../common/APITokens"
import BackArrow from "../common/BackArrow"
import { generateRealmPath } from "../common/realm"
import Container from "../sites/App/Container"

import { APITokenTtype } from "@/types/graphql-types"
import {
  Organization_API_CommerceAppQuery,
  Organization_API_CommerceAppQueryVariables,
} from "@/types/graphql-types"

interface Params {
  id: string
}

export default function CommerceApp() {
  const params = useParams<Params>()
  const id = params.id

  const { data, loading, refetch } = useQuery<
    Organization_API_CommerceAppQuery,
    Organization_API_CommerceAppQueryVariables
  >(COMMERCE_APP_QUERY, {
    variables: {
      id,
    },
  })

  const refetchData = () => {
    refetch({ id })
  }

  const app = data?.organization?.commerceApp

  return (
    <div>
      <Container>
        <div tw="p-6 pt-12">
          <div tw="flex flex-col md:flex-row items-start gap-4">
            <div tw="md:w-[25%]">
              <BackArrow
                label="Commerce API"
                path={generateRealmPath("plus", "/organization/api/commerce")}
              />
              <div tw="font-medium mt-1 text-2xl">
                {!app && loading ? (
                  <div tw="text-gray-500">Loading&hellip;</div>
                ) : (
                  app && <div>{app.name}</div>
                )}
              </div>
            </div>
            <div tw="w-full pt-8">
              {app && (
                <>
                  <BoxContainer>
                    <div tw="pt-6" />
                    <APITokens
                      apiTokens={app.apiTokens}
                      queryToRefetch={COMMERCE_APP_QUERY}
                      onRefetch={() => refetchData}
                      ttype={APITokenTtype.commerce}
                      commerceAppID={app.id}
                    />
                  </BoxContainer>
                  <div tw="font-medium text-xl pb-4 pt-12">Webhooks</div>
                  <div tw="pb-3">
                    You can use webhooks to receive notifications about events
                    on orders in your commerce application at a specified URL.
                    Webhooks are delivered by the Svix platform.
                  </div>
                  <div tw="relative w-full pb-6">
                    <AppPortalContainerLoader url={app.svixDashboardUrl} />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

const BoxContainer = styled.div`
  ${tw`flex flex-1 flex-col bg-white border border-gray-150 rounded-xl w-[36rem] max-w-full`}
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
`

const COMMERCE_APP_QUERY = gql`
  query Organization_API_CommerceApp($id: ID!) {
    organization {
      commerceApp(id: $id) {
        id
        name
        publicAppId
        svixDashboardUrl
        apiTokens {
          ...APIToken
        }
      }
    }
  }
  ${API_TOKEN_FRAGMENT}
`
