import { gql, useQuery } from "@apollo/client"
import React, { useEffect, useState } from "react"
import { Redirect } from "react-router-dom"

import PDPContent from "./PDPContent"
import PDPPlaceholder from "./PDPPlaceholder"

import { DETAILS_PRODUCT_FRAGMENT } from "@/common/queries"
import StoreSEO from "@/common/StoreSEO"
import { IMAGE_FRAGMENT } from "@/graphql"
import {
  ProductData,
  generateMetaDescription,
  useBrowseUrls,
} from "@/store/StoreHelpers"
import { onSelectProductIDType } from "@/store/types"
import {
  Store_PDP_ProductDetailsFragment,
  Store_PDP_ProductDetailsQuery,
  Store_PDP_ProductDetailsQueryVariables,
} from "@/types/graphql-types"

interface ProductDetailPageProps {
  productData: ProductData
  scrollToTop?: () => void
  // Override the default (setCurrentGift) for when a product is selected.
  onSelectProductID?: onSelectProductIDType
  // Method to call after selecting a product.
  onAfterSelectProduct?: () => void
  setPDPProduct: (productPreviewData: ProductData) => void
  customStoreProductButton?: (productID: string) => React.ReactElement
  customStoreGiftCardButton?: (
    productID: string,
    giftCardAmount: number | null,
  ) => React.ReactElement
  isGiftCardInCustomStore?: (
    productID: string,
    giftCardAmount: number | null,
  ) => boolean
  useVirtualUrl?: boolean
  storeVisible: boolean
  onClose?: () => void
  isEmbeddedCustomStore?: boolean
}

// HACK: This is checking whether an item is a productDetails using a field
// that we are reasonably sure only exists on productDetails, but if that
// field is added elsewhere, this will no longer apply
const isProductFragment = (
  productData: ProductData | null,
): productData is Store_PDP_ProductDetailsFragment =>
  !!productData?.hasOwnProperty("giftCollectionTiers")

// Loads a standard PDP, which pulls product state from the URL.
const ProductDetailPage = ({
  scrollToTop,
  onSelectProductID,
  onAfterSelectProduct,
  productData,
  setPDPProduct,
  customStoreProductButton,
  customStoreGiftCardButton,
  isGiftCardInCustomStore,
  useVirtualUrl,
  storeVisible,
  onClose,
  isEmbeddedCustomStore,
}: ProductDetailPageProps) => {
  const [data, setData] = useState<Store_PDP_ProductDetailsQuery | null>(null)
  const { generatePdpUrl, generateBrowseUrl } = useBrowseUrls()

  const { loading, refetch } = useQuery<
    Store_PDP_ProductDetailsQuery,
    Store_PDP_ProductDetailsQueryVariables
  >(GET_PRODUCT_DETAILS, {
    fetchPolicy: "cache-and-network",
    skip: true,
  })

  // Prefer live data when available, but fall back to passed-in product, e.g.
  // when loading live data.
  const productDetails = data?.productDetails ?? productData

  useEffect(() => {
    refetch({
      brandSlug: productData.brand.slug,
      productSlug: productData.slug,
    }).then((newData) => {
      setData(newData.data)
    })
  }, [productData.slug, productData.brand.slug, setData, refetch])

  if (!isProductFragment(productDetails)) {
    if (data?.inactiveProductDetails) {
      return (
        <Redirect
          to={generateBrowseUrl({
            giftOptionSlug:
              data.inactiveProductDetails.brand.primaryGiftOption?.slug,
          })}
        />
      )
    }
    return null
  }

  if (loading) {
    return <PDPPlaceholder />
  }

  const canonicalPath = generatePdpUrl({
    productSlug: productDetails.slug,
    brandSlug: productDetails.brand.slug,
    pathRealm: "consumer",
  })
  const metaDescription = generateMetaDescription(
    productDetails.brand.name,
    productDetails.description,
  )
  const ogImage =
    (productDetails.productImages[0] &&
      productDetails.productImages[0].imageLarge?.url) ||
    null
  const titlePrefix = `${productDetails.brand.name} – ${productDetails.name}`

  return (
    <>
      {storeVisible && (
        <StoreSEO
          canonicalPath={canonicalPath}
          metaDescription={metaDescription}
          ogImage={ogImage}
          titlePrefix={titlePrefix}
        />
      )}
      <PDPContent
        product={productDetails}
        scrollToTop={scrollToTop}
        onSelectProductID={onSelectProductID}
        onAfterSelectProduct={onAfterSelectProduct}
        loading={loading}
        setPDPProduct={setPDPProduct}
        customStoreProductButton={customStoreProductButton}
        customStoreGiftCardButton={customStoreGiftCardButton}
        isGiftCardInCustomStore={isGiftCardInCustomStore}
        useVirtualUrl={useVirtualUrl}
        onClose={onClose}
        isEmbeddedCustomStore={isEmbeddedCustomStore}
      />
    </>
  )
}

const STORE_PDP_PRODUCT_DETAILS = gql`
  fragment Store_PDP_ProductDetails on Product {
    ...Details_Product
    brand {
      primaryGiftOption {
        id
        slug
        name
      }
    }
    giftCollectionTiers {
      id
      name
      colorToken
      priceMax
      products {
        id
        slug
        name
        price
        imagesScalable
        brand {
          id
          slug
        }
        productImages {
          id
          imageThumb {
            ...Image
          }
        }
      }
    }
    giftCardInfo {
      currency
      currencyRate
      denominations
      description
      expiryAndValidity
      howToUse
      isSlidingScale
      termsAndConditions
      valueRestrictions {
        min
        max
      }
    }
  }
  ${DETAILS_PRODUCT_FRAGMENT}
  ${IMAGE_FRAGMENT}
`

export const GET_PRODUCT_DETAILS = gql`
  query Store_PDP_ProductDetails($brandSlug: String!, $productSlug: String!) {
    productDetails(brandSlug: $brandSlug, productSlug: $productSlug) {
      ...Store_PDP_ProductDetails
    }
    inactiveProductDetails: productDetails(
      brandSlug: $brandSlug
      productSlug: $productSlug
      filters: { status: [INACTIVE] }
    ) {
      id
      brand {
        id
        primaryGiftOption {
          id
          slug
          name
        }
      }
    }
  }
  ${STORE_PDP_PRODUCT_DETAILS}
`

export default ProductDetailPage
