import { useEffect, useRef } from "react"
import { Link, useHistory } from "react-router-dom"

import {
  DEFAULT_COUNTRY,
  useShippingCountries,
} from "../providers/ShippingCountriesProvider"

import DialogModal from "@/common/DialogModal"
import { useGlobalState } from "@/common/GlobalState"
import { generateRealmPath } from "@/common/realm"
import CountriesUndelineText from "@/store/components/modals/GlobalShippingModal/components/CountriesUndelineText"
import GlobalProductsHeader from "@/store/components/modals/GlobalShippingModal/components/GlobalProductsHeader"

interface Props {
  shipsToUS: boolean
  open: boolean
  onClose: () => void
  onAddToCart: () => void
}

const GlobalRelayProUpsellModal = ({
  shipsToUS,
  open,
  onClose,
  onAddToCart,
}: Props) => {
  const { popoverShippingCountry, setPopoverShippingCountry } =
    useShippingCountries()
  const addCartClickedRef = useRef(false)
  const history = useHistory()
  const [currentRealm] = useGlobalState("currentRealm")
  const isConsumerRealm = currentRealm === "consumer"

  const handleAddToCart = () => {
    addCartClickedRef.current = true
    setPopoverShippingCountry(DEFAULT_COUNTRY)
    onClose()
  }

  const handleSearchForUS = () => {
    history.push(generateRealmPath(currentRealm, "/browse"))
  }

  useEffect(() => {
    if (
      popoverShippingCountry === DEFAULT_COUNTRY &&
      addCartClickedRef.current
    ) {
      onAddToCart()
      addCartClickedRef.current = false
    }
  }, [popoverShippingCountry])

  const upsellLink = isConsumerRealm
    ? generateRealmPath("business", "/signup")
    : generateRealmPath("plus", "/subscribe-pro")

  return (
    <DialogModal open={open} onClose={onClose}>
      <GlobalProductsHeader />

      <DialogModal.Title>Global shipping to 140+ countries</DialogModal.Title>

      <DialogModal.Content>
        {isConsumerRealm && (
          <>
            {!shipsToUS &&
              "This product does not ship within the United States. "}
            International shipping options are only available on Goody for
            business.{" "}
          </>
        )}
        Subscribe{!isConsumerRealm && " to Pro"} for global shipping to{" "}
        <CountriesUndelineText />
      </DialogModal.Content>

      <DialogModal.Actions tw="flex flex-col gap-3">
        <Link to={upsellLink} tw="w-full">
          <DialogModal.Button variant="primary-new">
            Subscribe to {isConsumerRealm ? "Business" : "Pro"}
          </DialogModal.Button>
        </Link>

        {shipsToUS ? (
          <DialogModal.Button onClick={handleAddToCart}>
            Add to bag for US shipping
          </DialogModal.Button>
        ) : (
          <DialogModal.Button onClick={handleSearchForUS}>
            Search for US products
          </DialogModal.Button>
        )}

        <DialogModal.Button
          onClick={onClose}
          tw="text-gray-450 border-transparent shadow-none"
        >
          Close
        </DialogModal.Button>
      </DialogModal.Actions>
    </DialogModal>
  )
}

export default GlobalRelayProUpsellModal
