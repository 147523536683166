import React from "react"
import tw, { styled } from "twin.macro"

import HeroDesktop from "./images/HeroDesktop.png"
import HeroMobile from "./images/HeroMobile.png"
import PurpleGradientLink from "../../../common/PurpleGradientLink"
import { generateRealmPath } from "../../../common/realm"
import { FlexCenterItems } from "../components/PositionHelper"
import TitleContainer from "../components/TitleContainer"

const Hero = () => (
  <OuterContainer>
    <InnerContainer tw="pt-20">
      <div tw="w-screen mx-auto">
        <ImageContainer>
          <Image tw="md:hidden" src={HeroMobile} />
          <Image tw="hidden md:block" src={HeroDesktop} />
        </ImageContainer>
        <FlexCenterItems tw="px-5 max-w-[680px] mx-auto">
          <TitleContainer
            heading="Sales Prospecting"
            title="Getting warmer..."
            subtitle={
              <div tw="text-[22px] leading-[34px]">
                Sales teams using Goody set <b>3.7X more meetings</b> and <br />
                get <b>80% more email replies.</b>
              </div>
            }
            titleSize="xl"
          />
          <PurpleGradientLink
            tw="mt-6 z-10"
            to={generateRealmPath("business", "/team-demo")}
          >
            Book a Demo
          </PurpleGradientLink>
        </FlexCenterItems>
      </div>
    </InnerContainer>
  </OuterContainer>
)

const breakpointSize = "430px"
const Image = styled.img`
  ${tw`object-cover w-[200vw] sm:w-full max-w-[1400px] mx-auto`}

  @media (min-width: ${breakpointSize}) {
    ${tw`w-full`}
  }
`
const InnerContainer = tw.div`
  bg-[#F8F8F8] rounded-b-[100%] overflow-hidden
  absolute h-full top-0 right[-457px] left-[-457px]
`

const OuterContainer = styled.div`
  ${tw`relative h-[800px] overflow-x-hidden`}

  @media (min-width: ${breakpointSize}) {
    ${tw`h-[1100px]`}
  }

  ${tw`md:h-[820px] lg:h-[850px]`}
`

const ImageContainer = tw.div`
  absolute inset-x-0 -bottom-5 pt-16 z-0
`

export default Hero
