import React, { useState } from "react"

import Button from "../Button"
import { SendBlockType } from "../hooks/useSendBlock"
import useSendEmailVerification from "../hooks/useSendEmailVerification"

interface Props {
  potentialRecipientsCount?: number
  plusLimitedGiftCount?: number
  sendBlockType: SendBlockType
}

export default function SendEmailVerificationSendBlock({
  potentialRecipientsCount,
  plusLimitedGiftCount,
  sendBlockType,
}: Props) {
  const { sendEmailVerification, sendEmailVerificationLoading } =
    useSendEmailVerification()
  const [sent, setSent] = useState(false)

  const handleSendEmailVerification = async () => {
    const ok = await sendEmailVerification()
    if (ok) {
      setSent(true)
    }
  }

  return (
    <>
      <h2 tw="text-lg font-semibold pb-3">
        Please verify your email to send this gift
      </h2>
      {sendBlockType === "GIFT_CARD_NOT_PLUS_FULL" && (
        <p tw="font-text">
          Gift cards can only be sent by accounts that have a verified email
          address.
        </p>
      )}
      {sendBlockType === "EXCEEDS_PLUS_LIMITED_GIFT_LIMIT" && (
        <>
          <p tw="font-text">
            To prevent spam, un-verified accounts are limited to 25 sent or
            scheduled gifts per account.{" "}
            {potentialRecipientsCount !== undefined &&
            plusLimitedGiftCount !== undefined
              ? `You’ve sent ${plusLimitedGiftCount} gift${
                  plusLimitedGiftCount === 1 ? "" : "s"
                } and this gift has ${potentialRecipientsCount} recipient${
                  potentialRecipientsCount === 1 ? "" : "s"
                }. `
              : ""}
          </p>
          <p tw="font-semibold mt-3">
            To send this gift, please verify your email address.
          </p>
        </>
      )}
      <Button
        variant="updated"
        onClick={handleSendEmailVerification}
        tw="mt-6 w-full text-center justify-center"
        disabled={sent || sendEmailVerificationLoading}
      >
        {sent ? "Verification email sent" : "Re-send verification email"}
      </Button>
    </>
  )
}
