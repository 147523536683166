import tw, { TwStyle } from "twin.macro"

import { CountryFlag } from "@/common/CountryFlag"
import { ShippingCountry } from "@/types/graphql-types"

interface Props {
  twStyle?: TwStyle
  onClick?: () => void
  country: ShippingCountry
  customLabel?: string
}

export const DomesticShippingTag = ({
  twStyle,
  onClick,
  country,
  customLabel,
}: Props) => {
  return (
    <div tw="flex flex-row">
      <div
        onClick={onClick}
        css={[twStyle, onClick && ClickableStyle]}
        tw="flex h-8"
      >
        <DomesticTag>
          <CountryFlag code={country.code} size="m" />
          <span tw="text-sm font-medium truncate">
            {customLabel || `Domestic shipping · ${country.name}`}
          </span>
        </DomesticTag>
      </div>
    </div>
  )
}

const Tag = tw.div`
  flex items-center justify-center gap-2 p-2
  border border-solid rounded-lg
`

const DomesticTag = tw(Tag)`
  text-[#219653] bg-[#F4FAF6] border-[#27AE604D]
`

const ClickableStyle = tw`
  cursor-pointer transition-all
  hover:(bg-[#EDF7F1] border-[#AEE0C3] rounded-lg)
  active:(bg-[#E3F2E8] border-[#AEE0C3] scale-95)
`
