import React, { useMemo } from "react"
import tw, { styled } from "twin.macro"

import CustomSelect from "./CustomSelect"
import { FIELD_LIST, ImportStep, getImportListName } from "./shared"
import CSVDropZone from "../../../common/components/CSVDropZone"
import { ContactList } from "../../lib"

interface Props {
  importStep: ImportStep
  goNext: () => void
  setRows: (rows: string[][]) => void
  lists: ContactList[]
  importListId: string | null
  setImportListId: (id: string | null) => void
  newImportListName: string
  setNewImportListName: (name: string) => void
}

const ContactUpload: React.FC<Props> = ({
  importStep,
  goNext,
  setRows,
  lists,
  importListId,
  setImportListId,
  newImportListName,
  setNewImportListName,
}) => {
  // No list ID means we're adding a new list
  const listOptions = useMemo(
    () =>
      lists.map((list) => ({
        label: list.id ? list.name : "Add list…",
        value: list.id,
      })),
    [lists],
  )

  const value = useMemo(() => {
    return {
      value: importListId,
      label: importListId
        ? getImportListName(lists, importListId, newImportListName)
        : "Add list…",
    }
  }, [importListId, lists, newImportListName])

  return importStep === "upload" ? (
    <div tw={"flex flex-col sm:flex-row mt-9 items-stretch"}>
      <div tw={"flex-1 flex flex-col"}>
        <ShadowContainer tw={"flex flex-col p-6"}>
          <ImportListText tw={"text-sm font-medium"}>
            Import into contact list
          </ImportListText>
          <div tw={"h-1"} />
          <div tw={"flex flex-col sm:flex-row items-stretch sm:items-center"}>
            <div tw={"flex-1"}>
              <CustomSelect
                defaultValue={value}
                value={value}
                options={listOptions}
                onChange={(option: any) =>
                  setImportListId(option?.value ?? null)
                }
              />
            </div>
            <div tw={"w-4"} />
            <div tw={"flex-1 mt-3 sm:mt-0"}>
              {!importListId ? (
                <input
                  tw="w-full focus:outline-none"
                  placeholder="New list name…"
                  value={newImportListName}
                  onChange={(e) => setNewImportListName(e.target.value)}
                  className="data-hj-suppress ph-no-capture fs-exclude"
                />
              ) : null}
            </div>
          </div>
        </ShadowContainer>
        <ShadowContainer
          tw={"p-6 flex-1 mt-5 sm:mt-4 flex sm:min-h-0"}
          css={{ minHeight: 304 }}
        >
          <CSVDropZone
            setRows={(rows) => {
              setRows(rows)
              goNext()
            }}
          />
        </ShadowContainer>
      </div>
      <InstructionsContainer>
        <div tw="font-medium text-gray-600">How to import</div>
        <div tw="mt-4">
          You can upload a CSV or XLSX file. A header row is required. Supported
          columns:
        </div>
        <HeaderList tw="mt-4 list-disc list-inside">
          {FIELD_LIST.map((header) => (
            <li key={header.name}>
              {header.name}
              {header.extraLabel ? (
                <span tw={"text-gray-400"}> ({header.extraLabel})</span>
              ) : null}
            </li>
          ))}
        </HeaderList>
        <div tw={"mt-4"}>
          Birthday and work anniversary can be with or without year. You will be
          able to review the column matching before confirming.
        </div>
      </InstructionsContainer>
    </div>
  ) : null
}

const ShadowContainer = styled.div`
  ${tw`border border-gray-100 rounded-xl`}
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05), 0 4px 20px rgba(0, 0, 0, 0.04);
`

const ImportListText = styled.div`
  color: #374151;
`

const HeaderList = styled.ul`
  ${tw`mt-4 list-disc list-inside`}

  // Moves the dots to the left
  li:before {
    content: "";
    margin-right: -0.5rem;
  }
`

// The container wraps below on mobile, so margin should shift to the top instead of left
// Width is also not constrained on mobile
const InstructionsContainer = styled.div`
  ${tw`bg-gray-100 rounded-lg p-6 pr-3 sm:ml-4 mt-12 sm:mt-0`};
  @media (min-width: 640px) {
    width: 381px;
  }
`

export default ContactUpload
