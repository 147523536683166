import tw, { styled } from "twin.macro"

import staticAssetUrl from "../../../common/staticAssetUrl"
import EffectCarousel from "../../components/EffectCarousel"

const assetRoot = "landing/how_it_works/custom_collections"
const WelcomeAboardImage = staticAssetUrl(`${assetRoot}/WelcomeAboard.png`)
const NEAImage = staticAssetUrl(`${assetRoot}/NEAImage.png`)
const HappyHolidaysImage = staticAssetUrl(`${assetRoot}/HappyHolidays.png`)
const BlackHistoryMonthImage = staticAssetUrl(
  `${assetRoot}/BlackHistoryMonth.png`,
)

const HowAreCompaniesUsing = () => {
  const CreateDigitalSwagSection = (
    <Section
      image={WelcomeAboardImage}
      header="Create a digital swag closet"
      text="Let recipients choose from an assortment of on-demand branded swag (or non-swag items!)"
    />
  )

  const CelebrateMilestonesSection = (
    <Section
      image={NEAImage}
      header="Celebrate milestones or events"
      text="Create a fully branded gift store to celebrate events like off-sites or sales kickoffs."
    />
  )

  const NextLevelSalesGiftsSection = (
    <Section
      image={HappyHolidaysImage}
      header="Send next-level sales gifts"
      text="Give your clients a fully branded gift experience, letting them choose from gift options you set."
    />
  )

  const BuildThemedGiftCollectionsSection = (
    <Section
      image={BlackHistoryMonthImage}
      header="Build themed gift collections"
      text="Curate and custom-brand a collection for holidays or DEI moments like Black History Month."
    />
  )

  const AllSlides = [
    CreateDigitalSwagSection,
    CelebrateMilestonesSection,
    NextLevelSalesGiftsSection,
    BuildThemedGiftCollectionsSection,
  ]

  return (
    <div tw="px-5 pb-12 pt-12 lg:pt-48 max-w-[1280px] mx-auto">
      <div tw="text-center font-bold mb-8 lg:mb-16 mx-auto text-4xl lg:text-[3rem] lg:leading-[3.5rem] max-w-[520px] lg:max-w-[640px]">
        How are companies using custom collections?
      </div>
      <EffectCarousel tw="hidden lg:block" slides={AllSlides} />
      <div tw="flex flex-col justify-center items-center mx-auto max-w-[640px] lg:hidden">
        {CreateDigitalSwagSection}
        {CelebrateMilestonesSection}
        {NextLevelSalesGiftsSection}
        {BuildThemedGiftCollectionsSection}
      </div>
    </div>
  )
}

interface SectionProps {
  image: string
  header: string
  text: string
}

const Section = ({ image, header, text }: SectionProps) => (
  <div tw="pb-4">
    <img tw="my-8 mx-auto" src={image} />
    <SectionText tw="mx-4 text-center">
      <p tw="font-bold text-3xl mb-4">{header}</p>
      <div tw="text-xl lg:text-2xl leading-normal lg:leading-9 pb-4 mb-4 pr-0">
        {text}
      </div>
    </SectionText>
  </div>
)

const SectionText = styled.div`
  ${tw`lg:opacity-0 lg:h-[10rem] transition-opacity`}

  .swiper-slide-active & {
    ${tw`opacity-100`}
  }
`

export default HowAreCompaniesUsing
