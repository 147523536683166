import React, { useState } from "react"
import tw, { styled } from "twin.macro"

import { CardContainer } from "./CardContainer"
import CustomCardForm from "./CustomCardForm"
import { ReactComponent as ArrowRight } from "../assets/icons/arrow-right.svg"
import { ReactComponent as Dimensions } from "../assets/icons/dimensions.svg"
import ClickableCloserUnderlay from "../common/ClickableCloserUnderlay"
import GradientButton from "../common/GradientButton"

const AddCard: React.FC = () => {
  const [displayForm, setDisplayForm] = useState(false)
  const closeForm = () => setDisplayForm(false)

  return (
    <CardContainer>
      <Button onClick={() => setDisplayForm(true)}>
        Add custom card
        <ArrowRight />
      </Button>
      <DimensionsIcon />
      <Text>800px by 1130px</Text>
      <Text>PNG or JPEG file</Text>
      <Text>Card will be rounded</Text>
      <CustomCardForm closeForm={closeForm} displayForm={displayForm} />
      {displayForm && <ClickableCloserUnderlay onClick={closeForm} />}
    </CardContainer>
  )
}

const Button = styled(GradientButton)`
  ${tw`flex justify-between`};
  width: 285px;
`

const DimensionsIcon = styled(Dimensions)`
  ${tw`mb-5`};
  margin-top: 75px;
`

const Text = tw.p`text-gray-600 text-lg`

export default AddCard
