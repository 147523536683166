import { NavLink } from "react-router-dom"
import tw, { css, styled } from "twin.macro"

const gradientStyle = css`
  ${tw`bg-gray-200 text-dark-grey text-xl rounded-xl px-5 py-3 font-medium inline-flex items-center active:scale-95 transition-all focus:outline-none relative overflow-hidden z-10`}

  &:disabled {
    ${tw`opacity-50 cursor-auto`}
  }
`

const OutOfStockButton = styled.button`
  ${gradientStyle}
`

export const GradientNavLink = styled(NavLink)`
  ${gradientStyle}
`

export default OutOfStockButton
