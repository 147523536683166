import React, { useEffect } from "react"
import tw, { styled } from "twin.macro"

import CVCEntry from "./CVCEntry"
import GiftCardNonProSendBlock from "./GiftCardNonProSendBlock"
import SendEmailVerificationSendBlock from "./SendEmailVerificationSendBlock"
import { track } from "../analytics"
import { SendBlockType } from "../hooks/useSendBlock"
import { PaymentMethod } from "../PaymentMethodsManager"

interface Props {
  sendBlockType: SendBlockType
  paymentMethod: PaymentMethod | null
  onSetCVC: () => void
  potentialRecipientsCount?: number
  plusLimitedGiftCount?: number
}

// Renders a component for two types of send blockers:
// - Trying to send alcohol using a corporate account
// - Needing to update a payment method to support sending alcohol with that payment method
export default function SendBlock({
  sendBlockType,
  paymentMethod,
  onSetCVC,
  potentialRecipientsCount,
  plusLimitedGiftCount,
}: Props) {
  // Log send blocks to analytics under the generic "Info" event.
  useEffect(() => {
    if (sendBlockType !== "NONE") {
      track("Info", {
        name: "Send - Send Block",
        sendBlockType,
      })
    }
  }, [sendBlockType])

  if (sendBlockType === "NONE") {
    return null
  }

  if (sendBlockType === "UPDATE_ALCOHOL_WITH_CVC") {
    return (
      <Container>
        <h2 tw="text-purple-600 text-lg font-medium pb-3">
          Please re-enter your CVC
        </h2>
        <p tw="font-text text-gray-700">
          Alcohol orders are processed by an alcohol beverage licensee. Please
          re-enter your card’s CVC for verification (you’ll only need to do this
          once for this card).
        </p>
        <CVCEntry paymentMethod={paymentMethod} onSuccess={onSetCVC} />
      </Container>
    )
  }

  if (
    sendBlockType === "CORPORATE_ACCOUNT_NOT_ALLOWED" ||
    sendBlockType === "BALANCE_NOT_ALLOWED"
  ) {
    return (
      <Container>
        <h2 tw="text-lg font-semibold pb-3">
          {sendBlockType === "CORPORATE_ACCOUNT_NOT_ALLOWED"
            ? "Corporate account can’t be used for alcohol"
            : "Account balance can’t be used for alcohol"}
        </h2>
        <p tw="font-text">
          Due to regulations, alcohol purchases are processed by an alcohol
          beverage licensee and{" "}
          <span tw="font-semibold">only support credit card payments</span>.
        </p>
      </Container>
    )
  }

  if (sendBlockType === "BALANCE_NOT_ALLOWED_FOR_GIFT_DISPENSER") {
    return (
      <Container>
        <h2 tw="text-lg font-semibold pb-3">
          Account balance can’t be used for gift links open to anyone
        </h2>
        <p tw="font-text">
          Due to the variable nature of gift links open to anyone, we can’t
          process them using account balance.{" "}
          <span tw="font-semibold">Please use a credit card instead.</span>
        </p>
      </Container>
    )
  }

  if (
    sendBlockType === "EXCEEDS_PLUS_LIMITED_GIFT_LIMIT" ||
    sendBlockType === "GIFT_CARD_NOT_PLUS_FULL"
  ) {
    return (
      <Container>
        <SendEmailVerificationSendBlock
          potentialRecipientsCount={potentialRecipientsCount}
          plusLimitedGiftCount={plusLimitedGiftCount}
          sendBlockType={sendBlockType}
        />
      </Container>
    )
  }

  if (sendBlockType === "GIFT_CARD_NO_PRO_SUBSCRIPTION") {
    return (
      <Container>
        <GiftCardNonProSendBlock />
      </Container>
    )
  }

  if (sendBlockType === "DELINQUENT") {
    return (
      <Container>
        <div tw="mb-2">
          Your account has been suspended because you have overdue payments. To
          send new gifts, you must resolve your overdue payments by going to
          your Account page, and then the Receipts tab.
        </div>
        <div>
          For assistance, contact support chat or email{" "}
          <a href="mailto:support@ongoody.com">support@ongoody.com</a>.
        </div>
      </Container>
    )
  }

  return null
}

const Container = styled.div`
  box-sizing: border-box;
  box-shadow:
    0px 0px 4px rgba(0, 0, 0, 0.05),
    0px 0px 32px rgba(0, 0, 0, 0.04);
  ${tw`bg-white rounded-lg border border-purple-200 p-5`};
`
