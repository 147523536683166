import { gql } from "@apollo/client"

export const BOOKS_SEARCH_QUERY = gql`
  mutation Store_BookSearch($query: String!) {
    bookSearch(query: $query) {
      ok
      productId
      results {
        binding
        contributors
        ean
        id
        imageAvailable
        imageLarge
        imageMedium
        inStock
        isbn
        notYetAvailable
        availableQuantity
        onOrder
        pages
        price
        productFormat
        title
      }
    }
  }
`
