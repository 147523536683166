import { useEffect } from "react"

import { screen } from "./analytics"

interface Props {
  name: string
}

// Track the given screen on mount.
export default function AnalyticsScreen({ name }: Props) {
  useEffect(() => {
    screen(name)
  }, [name])

  return null
}
