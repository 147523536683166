import { useQuery } from "@apollo/client"

import { DefaultDesktopHeaderURL } from "../../../common"
import { LOGO_QUERY } from "../../../CustomStore"

import { CustomStoreBaseFragment } from "@/types/graphql-types"

interface Props {
  customStore: CustomStoreBaseFragment
}

export const CustomStoreItemHeader = ({ customStore }: Props) => {
  const { data: logoData } = useQuery(LOGO_QUERY)

  return (
    <div tw="relative">
      <img
        tw="object-cover rounded-t-lg relative"
        src={customStore.desktopHeaderImage?.url ?? DefaultDesktopHeaderURL}
        alt="Custom store desktop header"
      />
      {!customStore.desktopHeaderImage?.url && !!logoData?.me?.logoUrl && (
        <img
          alt="company logo"
          src={logoData.me.logoUrl}
          tw="absolute max-w-[200px] max-h-[72px] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 scale-50"
        />
      )}
    </div>
  )
}
