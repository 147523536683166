import React from "react"
import tw, { styled } from "twin.macro"

import HTMLRenderer from "@/common/HTMLRenderer"
import { Store_PDP_ProductDetailsFragment } from "@/types/graphql-types"

interface Props {
  product: Store_PDP_ProductDetailsFragment
}

export default function GiftCardInfo({ product }: Props) {
  if (!product.giftCardInfo) {
    return null
  }

  const { termsAndConditions, expiryAndValidity, howToUse, description } =
    product.giftCardInfo

  return (
    <div tw="pt-8">
      <InfoBlock>
        <HTMLRenderer html={description || ""} />
      </InfoBlock>
      <InfoBlock>
        <div tw="font-medium">Terms and conditions</div>
        <HTMLRenderer html={termsAndConditions || ""} />
      </InfoBlock>
      {expiryAndValidity && (
        <InfoBlock>
          <div tw="font-medium">Expiry and validity</div>
          <HTMLRenderer html={expiryAndValidity || ""} />
        </InfoBlock>
      )}
      {howToUse && (
        <InfoBlock>
          <div tw="font-medium">How to use</div>
          <HTMLRenderer html={howToUse || ""} />
        </InfoBlock>
      )}

      <InfoBlock>
        The merchants represented are not sponsors of the rewards or otherwise
        affiliated with Goody. The logos and other identifying marks attached
        are trademarks of and owned by each represented company and/or its
        affiliates. Please visit each company's website for additional terms and
        conditions.
      </InfoBlock>
    </div>
  )
}

const InfoBlock = styled.div`
  ${tw`text-sm text-gray-400 text-left mt-4 break-words`}

  p {
    ${tw`mb-4`}
  }
`
