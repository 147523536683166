import { keyBy } from "lodash-es"
import React, { useMemo, useRef, useState } from "react"
import tw, { css, styled } from "twin.macro"

import ClickableCloserUnderlay from "./ClickableCloserUnderlay"
import { ReactComponent as ChevronIcon } from "../assets/icons/chevron.svg"

interface DropdownSelectProps {
  value: string
  optionMappings: { value: string; label: string }[]
  setValue: (value: string) => void
}

const DropdownSelect = ({
  value,
  optionMappings,
  setValue,
}: DropdownSelectProps) => {
  const [display, setDisplay] = useState(false)
  const buttonRef = useRef<HTMLButtonElement>(null)
  const optionsByValue = useMemo(
    () => keyBy(optionMappings, "value"),
    [optionMappings],
  )
  const currentLabel = useMemo(
    () => optionsByValue[value]?.label || null,
    [value, optionsByValue],
  )

  const menuItems = optionMappings.map(({ label, value }) => (
    <MenuItem
      key={value}
      onClick={() => {
        setDisplay(false)
        setValue(value)
      }}
    >
      {label}
    </MenuItem>
  ))

  return (
    <div tw="relative">
      <Button onClick={() => setDisplay((display) => !display)} ref={buttonRef}>
        {currentLabel}
        <ChevronIcon />
      </Button>
      <Menu
        ref={(menuRef) => {
          const { current } = buttonRef
          if (current && menuRef) {
            current.style.width = `${menuRef.offsetWidth}px`
          }
        }}
        display={display}
      >
        {menuItems}
      </Menu>
      {display && <ClickableCloserUnderlay onClick={() => setDisplay(false)} />}
    </div>
  )
}

const boxShadow = css`
  box-shadow: 0 2px 4px rgba(229, 231, 235, 0.3);
`
const Button = styled.button`
  ${tw`border border-gray-200 flex justify-between items-center leading-5 rounded-lg px-4 py-2 text-gray-600`};
  ${boxShadow};
`
const Menu = styled.div<{ display: boolean }>(({ display }) => [
  tw`absolute bg-white border border-gray-200 flex flex-col leading-5 mt-2 right-0 rounded-lg text-gray-600 transition w-max z-50`,
  boxShadow,
  !display && tw`opacity-0 pointer-events-none`,
])
const MenuItem = tw.button`border-b border-gray-200 last:border-none mr-11 px-4 py-3 text-left w-full`

export default DropdownSelect
