import "twin.macro"
import HeroProductAnimation from "./business/HeroProductAnimation"
import HowItWorksSection from "./business/HowItWorksSection/HowItWorksSection"
import BasicContainer from "./components/BasicContainer"

import BigButton from "@/landing/common/BigButton"

export default function PlasmicPlayground() {
  return (
    <BasicContainer title="Plasmic Playground">
      <div>
        <BigButton to="/business/signup" label="Start gifting" />
        <HeroProductAnimation
          cardImageUrl="https://site-assets.plasmic.app/9be258cf18a3b4bf027c862c5cafc4e1.jpg"
          product1ImageUrl="https://site-assets.plasmic.app/2edc54cdbc79dca2118170c9c812a80e.jpg"
          product2ImageUrl="https://site-assets.plasmic.app/f406a67ede2270805b7aecc128a1529c.jpg"
          cardTimingOffset={0.2}
          product1TimingOffset={2.2}
          product2TimingOffset={7.2}
        />
        <div tw="py-[500px]">
          <HowItWorksSection
            leftContent={<strong>Left</strong>}
            rightContent={<em>Right</em>}
          />
        </div>
      </div>
    </BasicContainer>
  )
}
