import tw from "twin.macro"

import { PaymentMethod } from "@/common/PaymentMethodsManager"
import PaymentMethodDropdown from "@/common/payments/PaymentMethodDropdown"
import { brandLast4 } from "@/common/payments/utils"
import RadioBox from "@/common/RadioBox"

interface Props {
  checked: boolean
  replaceable: boolean
  selectablePaymentMethods: PaymentMethod[]
  paymentMethod: PaymentMethod
  replacementPaymentMethod: PaymentMethod
  setReplacementPaymentMethod: (paymentMethod: PaymentMethod) => void
  onClick: () => void
}

const ReplaceRadioBox = ({
  checked,
  replaceable,
  onClick,
  selectablePaymentMethods,
  paymentMethod,
  replacementPaymentMethod,
  setReplacementPaymentMethod,
}: Props) => {
  const labelTextColor = checked ? tw`text-primary-new-550` : tw`text-gray-500`
  return (
    <RadioBox disabled={!replaceable} checked={checked} onClick={onClick}>
      <div tw="flex flex-col gap-2">
        <div css={[tw`font-semibold leading-5 flex gap-2`, labelTextColor]}>
          {replaceable ? (
            <>
              Replace with{" "}
              <div tw="mt-[-5px]">
                <PaymentMethodDropdown
                  paymentMethods={selectablePaymentMethods}
                  selectedPaymentMethod={replacementPaymentMethod}
                  onSelect={setReplacementPaymentMethod}
                />
              </div>{" "}
            </>
          ) : (
            "Replace with another payment method"
          )}
        </div>
        <div tw="text-gray-600 text-sm leading-5">
          {replaceable
            ? `Replace the payment method for these items with ${brandLast4(
                replacementPaymentMethod,
              )} and delete ${brandLast4(paymentMethod)}`
            : "Add another credit card to use this option"}
        </div>
      </div>
    </RadioBox>
  )
}

export default ReplaceRadioBox
