import { kebabCase, lowerCase } from "lodash-es"
import React, { useEffect, useState } from "react"
import Modal from "react-modal"
import tw, { styled } from "twin.macro"

import { level2ModalStyle } from "../../common/modal"
import { HubSpot } from "../../common/Script"

import { Store_PDP_ProductDetailsFragment } from "@/types/graphql-types"

interface Props {
  modalOpen: boolean
  onClose: () => void
  product: Store_PDP_ProductDetailsFragment
  selectedVariants: string[] | null
}

export default function OfflineProductFormModal({
  modalOpen,
  onClose,
  product,
  selectedVariants,
}: Props) {
  const [hubSpotLoaded, setHubSpotLoaded] = useState(false)

  useEffect(() => {
    if (hubSpotLoaded && modalOpen) {
      // @ts-ignore - window.hbspt is not being detected even though it's accessible
      window.hbspt.forms.create({
        region: "na1",
        portalId: "9308919",
        formId: "df801bf3-246f-4364-ac88-6813f0c11764",
        target: "#offline-product-form-container",
        onFormSubmit: ($form: any) => {
          const elements: HTMLFormElement[] = Array.from($form.elements)
          elements.forEach((elem: HTMLFormElement) => {
            if (elem.name === "product_name") {
              elem.value = product.brand.name + " " + product.name
            }
            if (elem.name === "product_url") {
              elem.value =
                "https://www.ongoody.com/browse/brands/" +
                lowerCase(product.brand.name) +
                "/" +
                kebabCase(product.name)
            }
            if (elem.name === "selected_variants") {
              elem.value = selectedVariants
            }
          })
        },
      })
    }
  }, [modalOpen, hubSpotLoaded])

  return (
    <>
      <HubSpot onLoad={() => setHubSpotLoaded(true)} />
      <Modal
        isOpen={modalOpen}
        style={level2ModalStyle}
        closeTimeoutMS={500}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        onRequestClose={onClose}
      >
        <Container className="modal-content-animated">
          <div tw="w-[550px] max-w-[100vw] p-8 min-h-[300px] max-h-[90vh] overflow-auto">
            <div id="offline-product-form-container" />
          </div>
        </Container>
      </Modal>
    </>
  )
}

const Container = styled.div`
  ${tw`rounded-2xl bg-white flex flex-col overflow-hidden`}
  box-shadow: 0px 8px 48px rgba(0, 0, 0, 0.08);
`
