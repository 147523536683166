import { RectShape } from "react-placeholder/lib/placeholders"

interface Props {
  items?: number
}

export const ProductPreviewPlaceholder = ({ items = 4 }: Props) => (
  <>
    {[...Array(items)].map((_, index) => (
      <div key={`col-${index}`} tw="flex flex-col h-72">
        <Placeholder />
        {index < items && <br />}
      </div>
    ))}
  </>
)

const Placeholder = () => (
  <div className="show-loading-animation">
    <div
      className="rect-shape"
      tw="bg-[#F6F7F9] w-full aspect-w-3 aspect-h-2 lg:rounded-xl outline-none"
    />
    <br />
    <RectShape
      color="#F6F7F9"
      style={{
        width: "40%",
        height: 30,
        marginLeft: "5%",
        marginRight: "5%",
      }}
    />
  </div>
)
