import React, { useCallback, useLayoutEffect, useRef, useState } from "react"
import { UseFormRegisterReturn } from "react-hook-form"

import PersonalizationTags from "./PersonalizationTags"

interface PersonalizationMessageProps {
  value: string
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  setMessage: (message: string) => void
  textareaRows?: number
  inputProps?: UseFormRegisterReturn
  hidePersonalizationTags?: boolean
}

const MessageTextAreaWithPersonalizationTags = ({
  value,
  onChange,
  setMessage,
  textareaRows,
  inputProps,
  hidePersonalizationTags,
}: PersonalizationMessageProps) => {
  const [messageTextAreaHasFocus, setMessageTextAreaHasFocus] = useState(false)
  const messageTextArea = useRef<HTMLTextAreaElement | null>(null)
  const [cursorIndex, setCursorIndex] = useState(0)

  useLayoutEffect(() => {
    // Set cursor index after tag insertion
    if (messageTextArea.current && cursorIndex !== null) {
      messageTextArea.current.setSelectionRange(cursorIndex, cursorIndex)
    }
  }, [cursorIndex])

  // Used for personalization tags in message box
  const insertAtCursorOrEnd = useCallback(
    (tag: string) => {
      const selectionStart = messageTextArea?.current?.selectionStart
      const selectionEnd = messageTextArea?.current?.selectionEnd

      if (selectionStart != null) {
        let newMessage = value.slice(0, selectionStart)
        let charactersInserted = tag.length
        // Only add space before if we're not at the beginning and there's no space before
        if (selectionStart !== 0 && value[selectionStart - 1] !== " ") {
          newMessage = newMessage + " "
          charactersInserted = charactersInserted + 1
        }
        setCursorIndex(selectionStart + charactersInserted)

        newMessage =
          newMessage +
          tag +
          value.slice(selectionEnd || selectionStart, value.length)

        // Set message is a function passed by the parent component to handle updating the message state
        setMessage(newMessage)
      } else {
        setMessage(value + " " + tag)
      }
    },
    [messageTextArea, value],
  )

  return (
    <div>
      <textarea
        tw="w-full p-0 focus:outline-none"
        placeholder="Enter a message here..."
        className="data-hj-suppress ph-no-capture fs-mask"
        rows={textareaRows ?? 5}
        value={value}
        onChange={onChange}
        {...inputProps}
        ref={(el) => {
          messageTextArea.current = el
          if (inputProps?.ref) {
            ;(inputProps as UseFormRegisterReturn).ref(el)
          }
        }}
        onFocus={() => setMessageTextAreaHasFocus(true)}
        onBlur={() => setMessageTextAreaHasFocus(false)}
      />
      <PersonalizationTags
        insertAtCursorOrEnd={insertAtCursorOrEnd}
        messageTextAreaHasFocus={
          messageTextAreaHasFocus && !hidePersonalizationTags
        }
      />
    </div>
  )
}

export default MessageTextAreaWithPersonalizationTags
