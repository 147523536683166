import { formatDistanceToNowStrict, isToday } from "date-fns"
import React, { useEffect } from "react"
import { Link, useHistory } from "react-router-dom"
import { styled } from "twin.macro"

import Gift from "./components/Gift"
import ScheduledGift from "./components/ScheduledGift"
import LoadingBox from "./LoadingBox"
import { giftContainerCss } from "./shared"
import { getCartProductSubtitle } from "../common/cartProducts"
import ConsumerContainer from "../common/components/ConsumerContainer"
import { useGlobalState } from "../common/GlobalState"
import { getGiftStatusCopy, useGifts } from "../common/hooks/gifts"
import { generateRealmPath } from "../common/realm"
import SmallButton from "../common/SmallButton"
import { RoundedBox } from "../common/UI"

const MyGifts: React.FC = () => {
  const { gifts, scheduledGiftBatches, loading, refetch } = useGifts()
  const [signedIn] = useGlobalState("signedIn")
  const history = useHistory()

  const noGiftsSent =
    (!gifts || gifts.length === 0) &&
    (!scheduledGiftBatches || scheduledGiftBatches.length === 0)

  useEffect(() => {
    refetch()
  }, [signedIn])

  return (
    <ConsumerContainer title={"My Gifts"}>
      <div tw={"mx-auto"} css={"max-width: 623px;"}>
        <div
          tw={"font-medium text-5xl text-center"}
          css={"padding-top: 3.75rem"}
        >
          My Gifts
        </div>
        {loading ? (
          <LoadingBox />
        ) : noGiftsSent ? (
          <RoundedBox tw="p-6 py-24 text-center mt-16 mb-24">
            <div tw="pb-8 text-xl text-gray-500">No gifts sent yet.</div>
            <SmallButton
              onClick={() => {
                history.push(generateRealmPath("consumer", "/send"))
              }}
              label={"Send a gift"}
            />
          </RoundedBox>
        ) : (
          <div
            tw={"flex flex-col gap-8 items-stretch mt-16 px-4"}
            css={"min-height: 500px;"}
          >
            {scheduledGiftBatches?.map((giftBatch) => {
              const cartProduct = giftBatch.cartProducts[0]
              const productSubtitle = getCartProductSubtitle(cartProduct)

              return (
                <ScheduledGift
                  key={giftBatch.id}
                  giftBatchId={giftBatch.id}
                  cartQuantities={giftBatch.cartQuantities}
                  recipientFullName={giftBatch.firstRecipientFullName}
                  brandName={productSubtitle ?? ""}
                  productTitle={cartProduct.productTitle}
                  scheduledSendOn={giftBatch.scheduledSendOn}
                  refetchGifts={refetch}
                  cartProduct={cartProduct}
                />
              )
            })}
            {gifts?.map((gift) => {
              const date = new Date(gift.createdAt)
              const distance = isToday(date)
                ? "Today"
                : formatDistanceToNowStrict(date) + " ago"
              const recipientFullName = [
                gift.recipientFirstName,
                gift.recipientLastName,
              ]
                .filter(Boolean)
                .join(" ")

              const cartProduct = gift.cartProducts[0]
              const productSubtitle = getCartProductSubtitle(cartProduct)

              return (
                <GiftContainer
                  key={gift.senderViewAccessId}
                  to={generateRealmPath(
                    "consumer",
                    `/my-gifts/${gift.senderViewAccessId}`,
                  )}
                >
                  <Gift
                    cartProduct={cartProduct}
                    cartQuantities={gift.cartQuantities}
                    recipientFullName={recipientFullName}
                    brandName={productSubtitle ?? ""}
                    productTitle={cartProduct.productTitle}
                    sendDateText={`Sent ${distance}`}
                    isScheduledSend={false}
                    giftStatus={getGiftStatusCopy(gift)}
                  />
                </GiftContainer>
              )
            })}
          </div>
        )}
        <div tw={"h-24"} />
      </div>
    </ConsumerContainer>
  )
}

const GiftContainer = styled(Link)`
  ${giftContainerCss}
`

export default MyGifts
