import React from "react"
import nl2br from "react-nl2br"
import tw from "twin.macro"

import GiftOptionImageGallery from "./GiftOptionImageGallery"
import GiftOptionShipping from "./GiftOptionShipping"
import { isFullData } from "./utils/isFullData"
import PopoverShippingCountryList from "../components/shipping/PopoverShippingCountryList"
import GiftOptionInternationalShipping from "../giftOptionFilters/internationalShipping/GiftOptionInternationalShipping"

import { joinWithCommaAnd } from "@/common/format"
import { useFeatureFlags } from "@/common/hooks/featureFlags"
import BrandLogo from "@/store/components/BrandLogo"
import BrandValueBadge, {
  BrandValueKey,
  isBrandValueKey,
  sortBrandValuesBySelected,
} from "@/store/components/BrandValueBadge"
import { GiftOptionType } from "@/store/types"
import { giftOptionIsGiftCard } from "@/store/utils"

interface Props {
  giftOption: GiftOptionType
  selectedBrandValues: BrandValueKey[]
  customStoreGiftOptionButton?: (
    giftOptionID: string,
    isSingleProduct: boolean,
  ) => React.ReactElement
}

export default function GiftOptionHeader({
  giftOption,
  selectedBrandValues,
  customStoreGiftOptionButton,
}: Props) {
  const { hasFeatureFlag } = useFeatureFlags()

  // Use the description if available, otherwise subtitle.
  let subtitle = giftOption.subtitle
  if (
    isFullData(giftOption) &&
    giftOption.description &&
    giftOption.description !== ""
  ) {
    subtitle = giftOption.description
  }

  const shippingCountryFilter = hasFeatureFlag("shipping_config_v2") ? (
    <PopoverShippingCountryList
      shippingCountryGroup={giftOption.shippingCountryGroup}
    />
  ) : (
    <GiftOptionInternationalShipping
      shippingCountries={giftOption.brand.shippingCountriesFinal}
      isGiftCard={giftOption.name.startsWith("Gift Cards")}
    />
  )

  return (
    <div
      css={[
        giftOption.colors?.background
          ? { backgroundColor: giftOption.colors.background }
          : tw`bg-gray-050`,
      ]}
    >
      <div tw="hidden lg:flex flex-row justify-end px-10 pt-6 pb-6 items-center gap-6 pr-20">
        {shippingCountryFilter}
      </div>
      <div tw="flex flex-col-reverse lg:flex-row items-center">
        <div tw="lg:w-[45%] p-8 px-5 lg:p-10 lg:pt-0 lg:px-16 w-full">
          <div tw="flex flex-col items-center justify-center">
            <div tw="relative w-full flex flex-row items-start">
              <div tw="w-20 lg:hidden" />
              {giftOption.logoImage ? (
                <div tw="flex-1 text-center">
                  <BrandLogo
                    logoImage={giftOption.logoImage}
                    alt={giftOption.brand.name}
                    tw="mx-auto"
                  />
                </div>
              ) : (
                <div tw="font-medium text-2xl px-2 text-center flex-1">
                  {giftOption.name}
                </div>
              )}
              <div tw="w-20 lg:hidden">{shippingCountryFilter}</div>
            </div>
            <div tw="pt-6 text-[17px] text-center text-gray-800">
              {nl2br(subtitle)}
            </div>

            <GiftOptionShipping giftOption={giftOption} />

            {giftOption.brand.restrictedStates.length > 0 && (
              <div tw="text-center pt-4 pb-2 text-gray-600 text-sm">
                Cannot ship to{" "}
                {joinWithCommaAnd(giftOption.brand.restrictedStates)}
              </div>
            )}
            {giftOption.brand.brandValues.length > 0 ? (
              <div tw="flex flex-wrap justify-center mx-4 mt-8">
                {sortBrandValuesBySelected(
                  giftOption.brand.brandValues,
                  selectedBrandValues,
                ).map(
                  (brandValue) =>
                    isBrandValueKey(brandValue) && (
                      <BrandValueBadge
                        active={selectedBrandValues.includes(brandValue)}
                        value={brandValue}
                        small={true}
                        inverted={true}
                        gray={true}
                        key={brandValue}
                      />
                    ),
                )}
              </div>
            ) : null}
            {!giftOptionIsGiftCard(giftOption) &&
              customStoreGiftOptionButton &&
              customStoreGiftOptionButton(giftOption.id, false)}
          </div>
        </div>
        <div tw="lg:w-[55%] lg:p-10 lg:pt-0">
          <div tw="lg:rounded-2xl overflow-hidden">
            <GiftOptionImageGallery
              giftOption={giftOption}
              disableExpansion={true}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
