import { Link } from "react-router-dom"
import tw, { styled } from "twin.macro"

// Note: When exporting from Figma and running through Vecta SVG optimizer,
// rename the gradient defs to #start-gift-[browse, gift-of-choice, swag] to
// avoid conflicts between the gradient definitions as they are inlined.
// Also add viewBox="0 0 [w] [h]" to the <svg> attributes.
import { ReactComponent as BrowseIcon } from "./images/browse.svg"
import { ReactComponent as GiftOfChoiceIcon } from "./images/gift-of-choice.svg"
import { ReactComponent as SwagIcon } from "./images/swag.svg"

import { track } from "@/common/analytics"

type StartGiftButtonVariant = "browse" | "giftOfChoice" | "swag"

interface Props {
  variant: StartGiftButtonVariant
  title: string
  subtitle: string
  to: string
  clickEvent: string
  showMostPopularBadge?: boolean
}

const VARIANT_STYLES = {
  browse: {
    gradientStart: "#D0FAE3",
    gradientEnd: "#E9FFF3",
    title: "#14B25B",
    icon: <BrowseIcon />,
    hoverShadowColor: "rgba(0, 127, 58, 0.06)",
    hoverBorderColor: "#AAF4CC",
  },
  giftOfChoice: {
    gradientStart: "#D0F0FA",
    gradientEnd: "#EAFAFF",
    title: "#00ADE3",
    icon: <GiftOfChoiceIcon />,
    hoverShadowColor: "rgba(0, 108, 187, 0.06)",
    hoverBorderColor: "#C9E8FF",
  },
  swag: {
    gradientStart: "#E5E0FF",
    gradientEnd: "#F9F7FF",
    title: "#621DF1",
    icon: <SwagIcon />,
    hoverShadowColor: "rgba(114, 60, 251, 0.06)",
    hoverBorderColor: "#E4DAFF",
  },
}

export default function StartGiftButton({
  variant,
  title,
  subtitle,
  to,
  clickEvent,
  showMostPopularBadge,
}: Props) {
  return (
    <StyledLink to={to} variant={variant} onClick={() => track(clickEvent)}>
      <IconContainer>{VARIANT_STYLES[variant].icon}</IconContainer>
      {showMostPopularBadge && (
        <div tw="h-0 z-[1] hidden md:block">
          <div tw="text-[#61BEF7] border border-[#ACDEFD] bg-white px-2.5 h-[24px] mt-[-14px] text-sm font-medium rounded-[8px] flex flex-row items-center">
            Most popular
          </div>
        </div>
      )}
      <div tw="px-4 py-3 md:py-5">
        <div
          tw="font-medium md:text-xl md:text-center"
          css={{
            color: "var(--title-color)",
          }}
        >
          {title}
        </div>
        <div tw="pt-1 md:pt-2 text-gray-500 text-sm leading-[145%] md:text-[15px] lg:px-4 md:text-center">
          {subtitle}
        </div>
      </div>
    </StyledLink>
  )
}

const IconContainer = styled.div`
  ${tw`w-[100px] h-auto px-4 md:(w-full h-[110px] px-0) rounded-[8px] flex flex-row items-center justify-center`};

  background: radial-gradient(
      58.1% 146.15% at 50% 100%,
      rgba(255, 255, 255, 0.8) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    linear-gradient(
      245deg,
      var(--gradient-start) 25.77%,
      var(--gradient-end) 82.44%
    );

  // Inner shadow of 2px solid white full spread with 10px rounded corners
  clip-path: inset(2px round 10px);
  transition: all 0.2s ease-in-out;
  background-size: 110%;

  svg {
    transition: all 0.2s ease-in-out;
    ${tw`max-w-[36px] md:max-w-none`};
  }
`

const StyledLink = styled(Link)<{ variant: StartGiftButtonVariant }>`
  --gradient-start: ${(props) => VARIANT_STYLES[props.variant].gradientStart};
  --gradient-end: ${(props) => VARIANT_STYLES[props.variant].gradientEnd};
  --title-color: ${(props) => VARIANT_STYLES[props.variant].title};
  --hover-shadow-color: ${(props) =>
    VARIANT_STYLES[props.variant].hoverShadowColor};
  --hover-border-color: ${(props) =>
    VARIANT_STYLES[props.variant].hoverBorderColor};

  ${tw`rounded-xl flex flex-row md:flex-col items-stretch md:items-center flex-1 p-1 bg-white border border-gray-100 transition-all duration-100 active:scale-[0.98] hover:border-gray-150`};

  box-shadow:
    0 1px 8px 0 rgba(0, 0, 0, 0.03),
    0 1px 2px 0 rgba(0, 0, 0, 0.03);

  &:hover {
    box-shadow:
      0 4px 12px 0 var(--hover-shadow-color),
      0 1px 2px 0 var(--hover-shadow-color);

    border-color: var(--hover-border-color);

    ${IconContainer} {
      clip-path: inset(0 round 10px);
      background-size: 150%;
      background-position: 100% 100%;
    }

    ${IconContainer} svg {
      transform: translateY(-2px);
    }
  }

  &:active {
    filter: brightness(98%);
  }
`
