import { gql } from "@apollo/client"

const organizationBundledReceiptMembers = gql`
  query Organization_BundledReceiptMembers {
    organization {
      bundledReceiptMembers {
        status
        member {
          id
          email
          fullName
          publicId
        }
      }
    }
  }
`

export default organizationBundledReceiptMembers
