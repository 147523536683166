import { gql } from "@apollo/client"

export const SHIPMENT_FRAGMENT = gql`
  fragment Shipment on Shipment {
    status
    deliveredAt
    deliveryEta
    shippedAt
    trackingCarrier
    brand {
      id
      name
    }
    trackingNumber
    trackingUrl
  }
`
