import { ReactComponent as InfoIcon } from "../../../assets/icons/info.svg"
import { useGlobalState } from "../../../common/GlobalState"
import { PurpleGradientButton } from "../../../common/PurpleGradientLink"

interface Props {
  onPromoteMember: () => void
  name: string | null
}

export default function LimitedUpgradeCTA({ onPromoteMember, name }: Props) {
  const [_, setInfoModalOpen] = useGlobalState(
    "isLearnAboutFullVsLimitedModalOpen",
  )

  return (
    <div tw="text-center">
      <div tw="py-12">
        <div tw="font-medium text-2xl">{name} is a limited member</div>
        <div tw="text-lg text-gray-600 pt-1">
          Limited members don’t have access to budgeting
        </div>
        <div tw="w-2/3 mx-auto pt-6 text-lg">
          You can upgrade them to a full member to set a recurring&nbsp;budget
          or transfer account balance to them.
        </div>
        <div tw="flex flex-row justify-center">
          <PurpleGradientButton
            tw="rounded-full h-10 py-2 px-5 mt-6"
            onClick={onPromoteMember}
          >
            Upgrade to full member
          </PurpleGradientButton>
        </div>
      </div>
      <button
        tw="border-t border-gray-150 py-4 px-4 text-gray-500 text-center transition-colors hover:bg-gray-050 active:bg-gray-100 flex flex-row items-center justify-center w-full"
        onClick={() => setInfoModalOpen(true)}
      >
        <InfoIcon tw="mr-2 w-5 h-5 text-gray-450" />
        Learn more about full vs. limited members
      </button>
    </div>
  )
}
