import React from "react"
import tw, { styled } from "twin.macro"

import { DesktopImage, MobileImage } from "./DropShaddowImage"
import { FlexCenterItems } from "./PositionHelper"
import TitleContainer from "./TitleContainer"
import PurpleGradientLink from "../../../common/PurpleGradientLink"
import BrandsRightDesktop from "../../common/images/HRBrandsLeftDesktop.png"
import BrandsMobile from "../../common/images/HRBrandsMobile.png"
import BrandsLeftDesktop from "../../common/images/HRBrandsRightDesktop.png"

interface Props {
  heading?: string
  title: string
  description: string
  primaryAction: LinkAction
  background?: "light" | "dark"
}

const IntegrateHR: React.FC<Props> = ({
  primaryAction,
  heading,
  title,
  description,
  background = "dark",
}) => {
  return (
    <Container
      css={[tw`z-0`, background === "light" ? LightBackground : DarkBackground]}
    >
      <CustomDesktopImage
        tw="object-right h-[400px] justify-self-end"
        src={BrandsRightDesktop}
      />
      <FlexCenterItems tw="max-w-xl px-5">
        <TitleContainer
          heading={heading}
          title={title}
          subtitle={description}
          theme={background}
        />
        <PrimaryAction
          tw="mt-8 font-bold"
          isDark={background === "dark"}
          to={primaryAction.linkTo}
        >
          {primaryAction.text}
        </PrimaryAction>

        <MobileImage tw="pt-10" src={BrandsMobile} />
      </FlexCenterItems>

      <CustomDesktopImage
        tw="object-left h-[440px] justify-self-start"
        src={BrandsLeftDesktop}
      />
    </Container>
  )
}

// 2xl:max-h-[400px] 2xl:object-none
const CustomDesktopImage = tw(DesktopImage)`
  2xl:object-contain w-[440px]
`

const RoundedTop = tw.div`
  before:(
    absolute inset-0 rounded-b-[100%] bg-white

    content-['']
    h-[14%] w-[120%] top-[-7%] inset-x-[-10%]
    sm:h-[20%] sm:top-[-8%]
    lg:h-[30%] sm:top-[-14%]
    xl:w-[110%] xl:inset-x-[-5%]
  )
`

const Container = styled(RoundedTop)`
  ${tw`
    pt-24 sm:pt-36 lg:pt-48
    pb-14 lg:pb-28
    relative grid gap-5 grid-cols-1
    lg:grid-cols-[minmax(0,1fr) 600px minmax(0,1fr)]
    items-center justify-items-center
    overflow-x-hidden
  `}

  &:before {
    @media (min-width: 1800px) {
      content: none;
    }
  }

  @media (min-width: 1800px) {
    ${tw`pt-28`}
  }
`

const DarkBackground = tw`bg-gradient-to-r from-[#AE72FF] to-[#6B62FF]`
const LightBackground = tw`bg-light-grey`

const PrimaryAction = styled(PurpleGradientLink)<{ isDark: boolean }>`
  ${({ isDark }) => isDark && DarkBackgroundActionLink}
`
const DarkBackgroundActionLink = tw`border border-solid	border-white bg-none`

export default IntegrateHR
