import React from "react"
import { Link } from "react-router-dom"
import tw from "twin.macro"

import { useGlobalState } from "../../../common/GlobalState"
import { generateRealmPath } from "../../../common/realm"
import {
  REALM_TOGGLE_TOTAL_WIDTH_SIGNED_IN,
  REALM_TOGGLE_TOTAL_WIDTH_SIGNED_OUT,
} from "../../shared/RealmToggle"
import { useLogin } from "../LoginProvider"

const HeaderContainer = tw.div`
  fixed lg:static top-0 z-50
  w-screen 2xl:max-w-[1440px]
  mx-auto bg-white flex items-stretch
`

export function TopBar() {
  const [signedIn] = useGlobalState("signedIn")
  const { login, logout } = useLogin()
  const [currentRealm] = useGlobalState("currentRealm")

  return (
    <div>
      <HeaderContainer tw="justify-between self-stretch items-center h-12 bg-transparent px-5">
        <div
          style={{
            width: `${
              signedIn
                ? REALM_TOGGLE_TOTAL_WIDTH_SIGNED_IN
                : REALM_TOGGLE_TOTAL_WIDTH_SIGNED_OUT
            }px`,
          }}
        />
        <div tw="flex flex-row gap-8">
          {signedIn ? (
            <>
              {currentRealm === "business" ? (
                <Link
                  tw="text-gray-500"
                  to={generateRealmPath("plus", "/send")}
                >
                  Go to app
                </Link>
              ) : (
                <Link
                  tw="text-gray-500"
                  to={generateRealmPath("consumer", "/account")}
                >
                  My account
                </Link>
              )}
              <LoggedInHeaderNavButton onClick={logout}>
                Sign out
              </LoggedInHeaderNavButton>
            </>
          ) : (
            <>
              <Link tw="text-gray-500" to="/contact">
                Contact Sales
              </Link>
              {currentRealm === "business" ? (
                <Link
                  tw="text-gray-500"
                  to={generateRealmPath("business", "/signin")}
                >
                  Sign in
                </Link>
              ) : (
                <button tw="text-gray-500" onClick={login}>
                  Sign in
                </button>
              )}
            </>
          )}
        </div>
      </HeaderContainer>
    </div>
  )
}

const LoggedInHeaderNavButton = tw.button`text-gray-500`
