import { useMutation, useQuery } from "@apollo/client"
import { isEmpty } from "lodash-es"
import { useEffect, useState } from "react"
import toast from "react-hot-toast"
import ReactPlaceholder from "react-placeholder"
import tw, { styled } from "twin.macro"

import { CustomStoreEmpty } from "./CustomStoreEmpty"
import { CustomStoreItem } from "./CustomStoreItem"
import { useCurrentWorkspace } from "../../../common/hooks"
import { DelayedComponent } from "../../components/DelayedComponent"
import { StorePlaceholderList } from "../common"
import { ARCHIVE_CUSTOM_STORE_MUTATION, CUSTOM_STORES_QUERY } from "../graphql"

import {
  CustomStoreBaseFragment,
  Store_CustomStoreArchiveMutation,
  Store_CustomStoreArchiveMutationVariables,
  Store_CustomStoresQuery,
} from "@/types/graphql-types"

interface Props {
  onSelectCustomStoreProductID?: Function
}

export const CustomStoresList = ({ onSelectCustomStoreProductID }: Props) => {
  const { currentWorkspace } = useCurrentWorkspace()
  const { data, loading } = useQuery<Store_CustomStoresQuery>(
    CUSTOM_STORES_QUERY,
    {
      onCompleted: (data) => {
        setStoresListStateVariables(data?.me?.customStores)
      },
      context: {
        workspaceId: currentWorkspace?.id,
      },
    },
  )

  const [storesList, setStoresList] = useState<CustomStoreBaseFragment[]>([])

  const [archiveCustomStore] = useMutation<
    Store_CustomStoreArchiveMutation,
    Store_CustomStoreArchiveMutationVariables
  >(ARCHIVE_CUSTOM_STORE_MUTATION)

  const handleArchiveCustomStore = async (id: string) => {
    const res = await archiveCustomStore({
      variables: { id: id },
    })

    setStoresList(storesList.filter((store) => store.id !== id))

    if (res.data?.customStoreArchive.ok) {
      console.log("archive ok")
      toast.success("Custom store archived")
    } else {
      console.log("archive not ok")
    }
  }

  useEffect(() => {
    setStoresListStateVariables(data?.me?.customStores)
  }, [data?.me?.customStores])

  const setStoresListStateVariables = (
    storesList: CustomStoreBaseFragment[] | null | undefined,
  ) => {
    if (!storesList) {
      return
    }

    setStoresList(storesList)
  }

  return (
    <DelayedComponent waitBeforeShow={100}>
      <ReactPlaceholder
        showLoadingAnimation={true}
        ready={!loading}
        customPlaceholder={<StorePlaceholderList />}
      >
        {isEmpty(storesList) ? (
          <CustomStoreEmpty />
        ) : (
          <Container>
            {storesList.map((customStore) => (
              <CustomStoreItem
                key={customStore.id}
                customStore={customStore}
                onSelectCustomStoreProductID={onSelectCustomStoreProductID}
                onArchive={handleArchiveCustomStore}
              />
            ))}
          </Container>
        )}
      </ReactPlaceholder>
    </DelayedComponent>
  )
}

const Container = styled.div`
  ${tw`pb-12 grid grid-cols-1 gap-8`}

  @media only screen and (min-width: 1280px) {
    grid-template-columns: fit-content(23rem) fit-content(23rem);
  }
`
