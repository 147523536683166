import "react-placeholder/lib/reactPlaceholder.css"
import "@/store/opacity.css"

import { gql } from "@apollo/client"
import React from "react"
import { Link } from "react-router-dom"

import { gtmEventForEditorialItem } from "./utils"

import { useGlobalState } from "@/common/GlobalState"
import Image from "@/common/Image"
import { generateRealmPath, getRealmFromPath } from "@/common/realm"
import { aspectRatioCSS } from "@/common/utilities"
import { Editorial_CTAsFragment } from "@/types/graphql-types"

interface IEditorialCTAs {
  editorialItems: Editorial_CTAsFragment[]
  show: boolean
}

const EditorialCTAs: React.FC<IEditorialCTAs> = ({ editorialItems, show }) => {
  if (!show) {
    return null
  }

  return (
    <>
      {editorialItems?.map((editorialItem) => (
        <Banner key={editorialItem.slug} editorialItem={editorialItem} />
      ))}
    </>
  )
}

interface IBanner {
  editorialItem: Editorial_CTAsFragment
}

const Banner = ({ editorialItem }: IBanner) => {
  const [currentRealm] = useGlobalState("currentRealm")

  const handleClick = () => {
    gtmEventForEditorialItem("Editorial_CTA_Click", { editorialItem })
  }

  const editorialPath =
    editorialItem.ctaUrlOverride || `/editorial/${editorialItem.slug}`

  // Only rewrite realm if it's not explicitly passed in. Consumer has no prefix, so we can check
  // if the realm of the path is consumer.
  const editorialLink =
    getRealmFromPath(editorialPath) === "consumer"
      ? generateRealmPath(currentRealm, editorialPath)
      : editorialPath

  if (
    !editorialItem.ctaImageDesktopWideXlarge ||
    !editorialItem.ctaImageMobileLarge
  ) {
    return null
  }

  return (
    <Link
      tw="block mb-5 px-1 pb-1 pt-8 lg:px-0 lg:pb-6 w-full lg:mb-0 mt-5 lg:mt-0 lg:rounded-lg xl:col-span-2"
      to={editorialLink}
      onClick={handleClick}
    >
      {editorialItem.ctaImageDesktopWideXlarge && (
        <Image
          src={editorialItem.ctaImageDesktopWideXlarge.url}
          alt={editorialItem.title}
          tw="w-full hidden md:block rounded-xl transition-all hover:scale-[100.5%] active:scale-[99.5%]"
          css={[aspectRatioCSS(editorialItem.ctaImageDesktopWideXlarge)]}
          lazy={false}
        />
      )}
      {editorialItem.ctaImageMobileLarge && (
        <Image
          src={editorialItem.ctaImageMobileLarge.url}
          alt={editorialItem.title}
          tw="w-full md:hidden xl:hidden rounded-xl transition-all"
          css={[aspectRatioCSS(editorialItem.ctaImageMobileLarge)]}
          lazy={false}
        />
      )}
    </Link>
  )
}

export const EDITORIAL_CTAS_FRAGMENT = gql`
  fragment Editorial_CTAs on EditorialItem {
    id
    slug
    title
    ctaUrlOverride
    ctaImageDesktopLarge {
      url
      width
      height
    }
    ctaImageDesktopWideXlarge {
      url
      width
      height
    }
    ctaImageMobileLarge {
      url
      width
      height
    }
  }
`

export default EditorialCTAs
