import tw from "twin.macro"

// A Group is a clickable component that can expand.
// This is used to hold a gift batch.
export const Group = tw.div`
  block w-full text-left rounded-xl
  transition-all
  relative
  hover:cursor-pointer
  hover:(shadow-min-with-border)
  active:(bg-gray-050)
  active:focus-within:(bg-white)
  after:(absolute bottom-0 left-3 right-3 border-b border-gray-200 content[""])
  -mt-px
`

// Rows can be on different levels in the HTML, but align to the general grid
// on the page. In the absence of CSS subgrid support, these might not exactly
// align exactly to the grid if there is overflow.
export const Row = tw.div`grid grid-template-columns[4fr 1fr 1fr] p-4 px-5 gap-6`
