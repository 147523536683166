import React from "react"
import tw from "twin.macro"

import CircleUserIcon from "@/assets/icons/circle-user.svg"
import Image from "@/common/Image"

interface Props {
  onClick: () => void
  text: string
  displayLogo?: boolean
  type?: "button" | "submit"
}

const SSOButton = ({ onClick, text, displayLogo, type = "button" }: Props) => {
  return (
    <button
      type={type}
      onClick={onClick}
      tw="flex flex-row items-center w-full p-2 pl-5 py-4 text-gray-800 bg-white border rounded-lg border-primary-100 hover:border-primary-200 focus-visible:border-primary-300 focus:outline-none active:bg-primary-000 transition-colors active:transition-border transition-opacity disabled:cursor-default disabled:active:bg-white disabled:hover:border-primary-100 gap-4 transition-colors"
      css={[{ boxShadow: "0 2px 4px rgba(228, 216, 244, 0.3)" }]}
    >
      {displayLogo && <Image src={CircleUserIcon} tw="mr-[120px]" />}

      <div css={[!displayLogo && tw`text-center w-full`]}>{text}</div>
    </button>
  )
}

export default SSOButton
