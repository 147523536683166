import React from "react"

import { Loader } from "../common/UI"

const LoadingBox: React.FC = () => (
  <div css={"min-height: 500px;"} tw={"flex items-center justify-center"}>
    <Loader />
  </div>
)
export default LoadingBox
