import { useState } from "react"

import { createHookContext } from "@/common/hooks/createHookContext"
import { OrganizationCreateSamlIdentityProviderMutation } from "@/types/graphql-types"

const useSamlSettingsLocal = () => {
  const [identityProvider, setIdentityProvider] = useState<
    | OrganizationCreateSamlIdentityProviderMutation["samlCreateIdentityProvider"]["samlIdentityProvider"]
    | null
  >(null)

  return {
    identityProvider,
    setIdentityProvider,
  }
}

export const { Provider: SamlSettingsProvider, useHook: useSamlSettings } =
  createHookContext("SamlSettings", useSamlSettingsLocal)
