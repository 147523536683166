import "react-placeholder/lib/reactPlaceholder.css"
import "@/store/opacity.css"

import { gql, useQuery } from "@apollo/client"

import { isBlank } from "@/common/format"
import { Store_InfoBannerQuery } from "@/types/graphql-types"

const InfoBanner = () => {
  const { data: infoBannerData } =
    useQuery<Store_InfoBannerQuery>(INFO_BANNER_QUERY)

  const infoBanner = infoBannerData?.infoBanner

  if (!infoBanner) {
    return null
  }

  return (
    <div tw="mb-1 -mt-3 rounded-lg p-4 border border-primary-200 text-center xl:col-span-2">
      <div tw="text-primary-500 pb-1 font-medium">{infoBanner.name}</div>
      {!isBlank(infoBanner.content) && (
        <div tw="text-primary-400">{infoBanner.content}</div>
      )}
    </div>
  )
}

const INFO_BANNER_QUERY = gql`
  query Store_InfoBanner {
    infoBanner(isPlus: true) {
      name
      content
    }
  }
`

export default InfoBanner
