import React, { ReactElement } from "react"
import tw from "twin.macro"

import RainbowHeading from "../../../common/RainbowHeading"

type Alignment = "left" | "center" | "right"
type Size = "xl" | "lg" | "md" | "sm"
interface Props {
  heading?: string
  title: string | ReactElement
  subtitle?: string | ReactElement
  titleSize?: Size
  darkerColors?: boolean
  align?: Alignment
  theme?: "light" | "dark"
}

const TitleContainer: React.FC<Props> = ({
  heading,
  title,
  subtitle,
  titleSize = "md",
  align = "center",
  theme = "light",
  darkerColors = false,
}) => {
  const darkThemeText = theme === "dark" && tw`text-white bg-none`
  return (
    <div css={[getAlignment(align), tw`lg:max-w-screen-md lg:mx-auto`]}>
      {heading && <Heading css={[tw`pb-4`, darkThemeText]}>{heading}</Heading>}
      <Title
        css={[
          getTitleSize(titleSize),
          darkThemeText,
          darkerColors && tw`text-[#060D25]`,
        ]}
      >
        {title}
      </Title>
      {subtitle && (
        <Text
          css={[tw`pt-6`, darkThemeText, darkerColors && tw`text-[#4B5563]`]}
        >
          {subtitle}
        </Text>
      )}
    </div>
  )
}

const getAlignment = (align: Alignment) => {
  switch (align) {
    case "center":
      return tw`text-center`
    case "right":
      return tw`text-right`
    case "left":
      return tw`text-left`
  }
}

const getTitleSize = (titleSize: Size) => {
  switch (titleSize) {
    case "xl":
      return tw`text-4xl lg:text-6xl lg:leading-[4rem]`
    case "lg":
      return tw`text-3xl lg:text-[52px] lg:leading-[3.75rem]`
    case "md":
      return tw`text-3xl lg:text-5xl lg:leading-[3.5rem]`
    case "sm":
      return tw`text-3xl lg:text-4xl`
  }
}

export const Heading = tw(RainbowHeading)`
  font-medium text-xl
`

export const Title = tw.div`
  font-bold text-dark-blue
  text-3xl lg:text-5xl
 `

export const Text = tw.div`
  font-normal text-[#626366]
  text-xl leading-[30px]
  lg:text-2xl lg:leading-[32px]
`

export default TitleContainer
