import "react-placeholder/lib/reactPlaceholder.css"
import "./opacity.css"

import { useExperiment } from "statsig-react"

import { CartAddProvider } from "./CartAddContext"
import { CustomStoreBrowserProps } from "./custom/common"
import GiftOptionCountryProvider from "./GiftOptionCountryProvider"
import ShippingCountriesProvider from "./providers/ShippingCountriesProvider"
import StoreContent from "./StoreContent"
import { StoreCaller } from "./StoreContext"
import { onSelectProductIDType } from "./types"

import { useGlobalState } from "@/common/GlobalState"
import { useFeatureAccess } from "@/common/hooks/featureAccess"
import useRecordExperimentAssignment from "@/common/hooks/useRecordExperimentAssignment"

export interface StoreProps {
  visible: boolean
  // Is the Store being embedded into another component, not on its own page?
  // This means the Store should have slightly different measurements to account
  // for the lack of footer.
  isEmbedded?: boolean
  // Override the default (setCurrentGift) for when a product is selected.
  onSelectProductID?: onSelectProductIDType
  // Whether to use a "virtual" URL instead of the real one. For embedding of the
  // store where we don't want the actual URL to change.
  useVirtualUrl?: boolean
  customStoreBrowserProps?: CustomStoreBrowserProps
  caller: StoreCaller
}

function StoreSwitcher(props: StoreProps) {
  const { hasFeature } = useFeatureAccess()
  const [user] = useGlobalState("user")

  if (props.useVirtualUrl) {
    // Custom store or autogift; exclude from experiment since these do not have
    // traditional 'add to cart' or 'send gift' target actions
    return <StoreContent {...props} defaultMode="brands" />
  }

  if (hasFeature("store_product_mode_default_exp_exclude")) {
    // Excluded
    return <StoreContent {...props} defaultMode="brands" />
  } else if (
    hasFeature("store_product_mode_default_exp_new_user_population") ||
    !user
  ) {
    // New users or signed out users
    return (
      <StoreProductModeExperiment
        name={"store_-_product_mode_default_-_new_users"}
        {...props}
      />
    )
  } else {
    // Existing users
    return (
      <StoreProductModeExperiment
        name={"store_-_product_mode_default_-_existing_users"}
        {...props}
      />
    )
  }
}

interface StoreProductModeExperimentProps extends StoreProps {
  name:
    | "store_-_product_mode_default_-_new_users"
    | "store_-_product_mode_default_-_existing_users"
}

// For users who have the Store V2 new user experiment feature flag, put the user
// in one of the experiment groups (exposure).
function StoreProductModeExperiment({
  name,
  ...restProps
}: StoreProductModeExperimentProps) {
  const { config, isLoading } = useExperiment(name)
  const productModeIsDefault = config.get<boolean>(
    "product_mode_is_default",
    false,
  )
  const groupName = config.get<string | null>("group_name", null)

  useRecordExperimentAssignment({
    experiment: name,
    isLoading,
    group: groupName,
  })

  if (isLoading) {
    return <div tw="py-48 text-center text-gray-500">Loading...</div>
  }

  if (productModeIsDefault) {
    return <StoreContent {...restProps} defaultMode="products" />
  } else {
    return <StoreContent {...restProps} defaultMode="brands" />
  }
}

const StoreContainer = (props: StoreProps) => (
  <ShippingCountriesProvider>
    <GiftOptionCountryProvider>
      <CartAddProvider>
        <StoreSwitcher {...props} />
      </CartAddProvider>
    </GiftOptionCountryProvider>
  </ShippingCountriesProvider>
)

export default StoreContainer
