import { NavLink } from "react-router-dom"

import Button from "../../../../common/Button"
import { generateRealmPath } from "../../../../common/realm"
import { ReactComponent as CustomStoreIcon } from "../../images/custom-store.svg"

export const CustomStoreEmpty = () => (
  <div tw="rounded-xl border border-gray-150 py-14 flex items-center justify-center text-center flex-col mr-16 mb-16">
    <CustomStoreIcon tw="w-24 self-center text-gray-350 mb-4" />
    <div tw="font-medium">No custom stores</div>
    <div tw="text-gray-450 pb-6">Get started by creating one.</div>
    <Button tw="rounded-lg bg-primary-500 text-white border-none hover:bg-primary-400 active:bg-primary-600">
      <NavLink
        tw="flex gap-x-4"
        to={generateRealmPath("plus", `/store/custom/create`)}
      >
        Create custom store
      </NavLink>
    </Button>
  </div>
)
