import React, { useEffect } from "react"

import BookGiftOption from "./BookGiftOption"
import GiftOptionHeader from "./GiftOptionHeader"
import GiftOptionProductDisplay from "./GiftOptionProductDisplay"

import { useGlobalState } from "@/common/GlobalState"
import { BrandValueKey } from "@/store/components/BrandValueBadge"
import { GiftOptionType } from "@/store/types"

export interface ExternalGiftOptionDisplayProps {
  customStoreGiftOptionButton?: (
    giftOptionID: string,
    isSingleProduct: boolean,
  ) => React.ReactElement
  giftOptionSlug: string
  priceFilterMax: number | null
  priceFilterMin: number
  selectedBrandValues: BrandValueKey[]
  selectedCategoryID: string | null
}

interface GiftOptionDisplayProps extends ExternalGiftOptionDisplayProps {
  assignScrollContainerRef?: boolean
  giftOption: GiftOptionType
  scrollContainerRef: React.RefObject<HTMLDivElement>
  loading?: boolean
}

export const GiftOptionDisplay = ({
  assignScrollContainerRef,
  customStoreGiftOptionButton,
  giftOption,
  giftOptionSlug,
  priceFilterMax,
  priceFilterMin,
  scrollContainerRef,
  selectedBrandValues,
  selectedCategoryID,
  loading,
}: GiftOptionDisplayProps) => {
  const [, setReferringCategoryID] = useGlobalState("referringCategoryID")

  // Update global referringCategoryID whenever a different gift option is selected
  useEffect(() => {
    setReferringCategoryID(selectedCategoryID)
  }, [giftOptionSlug])

  if (giftOption.name === "Gift any book") {
    return <BookGiftOption giftOption={giftOption} />
  }

  return (
    <div tw="overflow-hidden flex-1 flex flex-col relative">
      <div
        tw="overflow-y-auto h-full"
        ref={assignScrollContainerRef ? scrollContainerRef : null}
      >
        <GiftOptionHeader
          giftOption={giftOption}
          selectedBrandValues={selectedBrandValues}
          customStoreGiftOptionButton={customStoreGiftOptionButton}
        />
        <GiftOptionProductDisplay
          giftOption={giftOption}
          priceFilterMax={priceFilterMax}
          priceFilterMin={priceFilterMin}
          loading={loading}
        />
      </div>
    </div>
  )
}
