import React from "react"
import tw, { styled } from "twin.macro"

import { ConditionalNavLink } from "../../../common/components/ConditionalNavLink"
import { ReactComponent as ChevronIcon } from "../../../common/images/chevron-down.svg"

interface Props {
  path?: string
  exact?: boolean
  icon?: JSX.Element | React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  name: string
  onClick?: () => void
  active?: boolean
  trigger?: boolean
  className?: string
  blockReclicks?: boolean
}

export const TopMenuItem: React.FC<Props> = ({
  active,
  path,
  exact,
  icon,
  name,
  onClick,
  trigger: isTrigger,
  blockReclicks,
}) => {
  const activeClassName = "active"
  const linkProps = {
    exact,
    to: path,
    activeClassName,
  }

  const isButton = !!onClick

  return (
    // @ts-ignore doesn't like blockReclicks
    <TopMenuItemHeaderNavLink
      onClick={onClick}
      as={isButton ? "button" : undefined}
      {...(isButton ? {} : linkProps)}
      className={active ? activeClassName : undefined}
      blockReclicks={blockReclicks}
    >
      <div tw="flex flex-col items-center">
        <Name>{name}</Name>
        <NameActivePlaceholder>{name}</NameActivePlaceholder>
      </div>
      {isTrigger ? <ChevronIcon className="chevron" /> : null}
    </TopMenuItemHeaderNavLink>
  )
}

const Name = styled.div``
const NameActivePlaceholder = tw.div`font-medium h-0 overflow-hidden`

export const TopMenuItemHeaderNavLink = styled(ConditionalNavLink)`
  ${tw`flex flex-row items-center gap-3 lg:gap-px p-6 py-2 rounded-full text-gray-600 transition-colors text-xl duration-200 transition-all`}
  > svg:not(.chevron) {
    ${tw`text-primary-300 stroke-current transition-colors relative`};
    stroke-width: 1.5;
    top: 1px;

    .inactiveFill {
      ${tw`fill-current stroke-0`}
    }
  }

  &:hover {
    ${tw`lg:bg-gray-100 text-gray-800`}
  }

  &:active {
    ${tw`lg:scale-95`};
  }

  &.active {
    ${tw`lg:bg-primary-new-050 text-primary-new-550`}

    > svg:not(.chevron) {
      ${tw`text-primary-500`}
      .inactiveFill {
        ${tw`text-primary-050`}
      }

      .activeFill {
        ${tw`fill-current stroke-current`}
      }
    }

    ${Name} {
      ${tw`font-medium`}
    }
  }

  > .chevron {
    // Chevron
    ${tw`transform scale-125 mt-1 stroke-current text-gray-400`}
  }
`
