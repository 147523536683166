import React from "react"

import { isFullData } from "./utils/isFullData"
import SwipeableGallery, {
  GalleryImage,
  GalleryImageProps,
} from "../../common/SwipeableGallery"

import {
  Store_GiftOptionDataFragment,
  Store_GiftOptionPreviewFragment,
} from "@/types/graphql-types"

interface GiftOptionImageGalleryProps {
  giftOption: Store_GiftOptionPreviewFragment | Store_GiftOptionDataFragment
  disableExpansion?: boolean
}

const GiftOptionImageGallery: React.FC<GiftOptionImageGalleryProps> = ({
  giftOption,
  disableExpansion,
}) => {
  const { name, primaryImage } = giftOption

  if (!isFullData(giftOption)) {
    if (primaryImage) {
      const { imageLarge } = primaryImage

      return (
        <GalleryImage
          name={name}
          image={imageLarge}
          large={imageLarge}
          disableExpansion={disableExpansion}
        />
      )
    } else {
      return null
    }
  }

  const { giftOptionImages } = giftOption

  const images: GalleryImageProps[] = giftOptionImages.map(
    ({ imageXlarge, imageLarge }) => {
      return { image: imageXlarge, name, large: imageLarge }
    },
  )

  return (
    <SwipeableGallery images={images} disableExpansion={disableExpansion} />
  )
}

export default GiftOptionImageGallery
