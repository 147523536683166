import React, { useEffect, useState } from "react"
import { useDropzone } from "react-dropzone"
import { NavLink } from "react-router-dom"
import { useHistory } from "react-router-dom"
import tw, { styled } from "twin.macro"

import { CustomStoreFormFieldValue, HeaderImageProps } from "./common"
import { CustomStoreOptions } from "./CustomStoreOptions"
import GiftList from "./GiftList"
import { ReactComponent as ArrowRight } from "./images/arrow-right.svg"
import { ReactComponent as BackArrowSvg } from "./images/back-arrow.svg"
import { ReactComponent as PlusIcon } from "../../assets/icons/plus-purple.svg"
import Button from "../../common/Button"

import { formatPrice } from "@/common/format"
import {
  CurrentCustomStore,
  initialCustomStoreState,
} from "@/common/GlobalState"
import { PurpleGradientButton } from "@/common/PurpleGradientLink"
import { generateRealmPath } from "@/common/realm"
import { CustomStoreWarning } from "@/store/custom/CustomStoreWarning"
import { CustomStoreFullFragment } from "@/types/graphql-types"

interface CustomStoreFormProps {
  customStore: CustomStoreFullFragment | null
  formFields: CurrentCustomStore
  setFormField: (name: string, value: CustomStoreFormFieldValue) => void
  setCustomStoreBrowserOpen: (open: boolean) => void
  onSave: () => void
  onArchive: () => void
  refetchCustomStore: Function
  logoUrl: string | null | undefined
  containsAlcoholProduct: boolean
}

const CustomStoreForm = ({
  customStore,
  formFields,
  setFormField,
  setCustomStoreBrowserOpen,
  onSave,
  onArchive,
  refetchCustomStore,
  logoUrl,
  containsAlcoholProduct,
}: CustomStoreFormProps) => {
  const getHeightAndWidth = (
    file: File,
  ): Promise<{ height: number; width: number; src: string }> => {
    const img = new Image()
    return new Promise((resolve) => {
      img.onload = () => {
        resolve({
          height: img.height,
          width: img.width,
          src: img.src,
        })
      }

      img.src = URL.createObjectURL(file)
    })
  }

  const onDrop = async (
    file: File,
    widthConstraint: number,
    heightConstraint: number,
    setImage: (image: HeaderImageProps) => void,
  ) => {
    const { height, width, src } = await getHeightAndWidth(file)
    const ratioConstraint = heightConstraint / widthConstraint
    const floorValue = Math.floor(ratioConstraint * 100) / 100
    const ceilValue = Math.ceil(ratioConstraint * 100) / 100

    const ratio = height / width
    const validRatio = floorValue <= ratio && ratio <= ceilValue
    if (heightConstraint <= height && widthConstraint <= width && validRatio) {
      setImage({
        file: file,
        url: src,
      })
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    // Revoke the data uris to avoid memory leaks
    if (formFields.desktopImage?.url && formFields.desktopImage?.file) {
      URL.revokeObjectURL(formFields.desktopImage.url)
    }
  }, [formFields.desktopImage])

  useEffect(() => {
    // Revoke the data uris to avoid memory leaks
    if (formFields.mobileImage?.url && formFields.mobileImage?.file) {
      URL.revokeObjectURL(formFields.mobileImage.url)
    }
  }, [formFields.mobileImage])

  const history = useHistory()

  const handleUploadLogo = () => {
    const ok = window.confirm(
      "Your custom store will be saved automatically. You can return to it from the Browse tab after you’ve uploaded a logo. Continue to the logo upload page?",
    )

    if (ok) {
      history.push(generateRealmPath("plus", "/account"))
    }
  }

  const handleClick = (e: any, defaultField: string) => {
    if (e.currentTarget.value === defaultField) {
      e.currentTarget.select()
    }
  }

  return (
    <div tw="flex flex-col pt-9 min-w-[481px]">
      <div tw="pl-3 gap-1 flex flex-col pb-7">
        <NavLink to={generateRealmPath("plus", "/browse/custom")}>
          <Button tw="gap-1 border-none shadow-none p-0">
            <BackArrowSvg />
            <div tw="text-primary-400">Browse</div>
          </Button>
        </NavLink>
        <h1 tw="font-medium text-2xl">Custom store</h1>
      </div>
      <div tw="border rounded-xl border-gray-150 box-border shadow-sm">
        <div tw="px-5 py-3 border-b">
          <div tw="text-sm font-medium pb-1 text-gray-450">Name</div>
          <div>
            <input
              tw="w-full p-0 focus:outline-none text-xl"
              placeholder="Enter a message"
              value={formFields.name}
              className="data-hj-suppress ph-no-capture fs-mask"
              onChange={({ target: { value } }) => setFormField("name", value)}
              onClick={(e) => handleClick(e, initialCustomStoreState.name)}
            />
          </div>
        </div>
        <div tw="px-5 py-3 border-b lg:border-none">
          <div tw="text-sm font-medium pb-1 text-gray-450">Subtitle</div>
          <div>
            <textarea
              tw="w-full p-0 focus:outline-none"
              placeholder="Enter a message"
              value={formFields.subtitle}
              className="data-hj-suppress ph-no-capture fs-mask"
              onClick={(e) => handleClick(e, initialCustomStoreState.subtitle)}
              onChange={({ target: { value } }) =>
                setFormField("subtitle", value)
              }
            />
          </div>
        </div>
      </div>
      <GiftConfigSection tw="p-4 mt-6">
        <div tw="flex justify-between items-center pb-1">
          <div tw="text-xl text-gray-450 pl-2">Gifts</div>
          <AddGiftButton
            tw="text-primary-600 hover:bg-[#FEFDFF] active:bg-primary-000 px-5 py-3"
            onClick={() => {
              setCustomStoreBrowserOpen(true)
            }}
          >
            <PlusIcon />
            <div tw="leading-5">Add gift</div>
          </AddGiftButton>
        </div>
        <GiftList
          customStore={customStore}
          refetchCustomStore={refetchCustomStore}
        />
      </GiftConfigSection>
      <div tw="border rounded-xl border-gray-150 box-border shadow-sm mt-6">
        {!logoUrl && (
          <div tw="p-2 text-sm text-primary-800">
            <button
              tw="bg-primary-050 rounded-[6px] w-full flex items-center justify-between px-4 py-2 hover:bg-primary-100 active:bg-primary-200"
              onClick={() => handleUploadLogo()}
            >
              <div>The default header uses your company logo.</div>

              <div tw="flex items-center gap-1">
                <div tw="font-semibold">Add a logo</div>
                <ArrowRight tw="stroke-current" />
              </div>
            </button>
          </div>
        )}
        <div tw="p-4 border-b">
          <div tw="text-sm font-medium pb-1 text-gray-450">Desktop header</div>
          <div tw="text-sm pb-3 text-gray-450">1720×550 PNG or JPG</div>
          <StyledFileUpload
            onDrop={onDrop}
            widthConstraintPx={1720}
            heightConstraintPx={550}
            imageSrc={formFields.desktopImage.url}
            setImage={(image: HeaderImageProps) =>
              setFormField("desktopImage", image)
            }
          />
        </div>
        <div tw="p-4 border-b">
          <div tw="text-sm font-medium pb-1 text-gray-450">Mobile header</div>
          <div tw="text-sm pb-3 text-gray-450">
            If not specified, a smaller version of the desktop image is used.
            750×550 PNG or JPG
          </div>
          <StyledFileUpload
            onDrop={onDrop}
            widthConstraintPx={750}
            heightConstraintPx={550}
            imageSrc={formFields.mobileImage.url}
            setImage={(image: HeaderImageProps) =>
              setFormField("mobileImage", image)
            }
          />
        </div>
        {formFields && (
          <CustomStoreOptions
            formFields={formFields}
            setFormField={setFormField}
          />
        )}
      </div>
      <CustomStoreWarning />
      <div
        tw="px-4 py-8 lg:p-14"
        css={{
          lineHeight: "19px",
        }}
      >
        {!!customStore?.priceEstimate && (
          <div>
            <div tw="text-xl text-gray-700 mb-5">Review</div>
            <div>
              <div tw="flex flex-row mb-5">
                <div>Maximum subtotal with shipping</div>
                <div tw="ml-auto">
                  {formatPrice(
                    customStore.priceEstimate.maximumSubtotalWithShipping,
                    false,
                  )}
                </div>
              </div>
              <div tw="flex flex-row mb-5">
                <div>Processing fee (5%)</div>
                <div tw="ml-auto">
                  {formatPrice(customStore.priceEstimate.processingFee, false)}
                </div>
              </div>
              <div tw="flex flex-row mb-5">
                <div>Estimated tax (0-10%)</div>
                <div tw="ml-auto">
                  {formatPrice(
                    customStore.priceEstimate.estimatedTaxLow,
                    false,
                  )}{" "}
                  –{" "}
                  {formatPrice(
                    customStore.priceEstimate.estimatedTaxHigh,
                    false,
                  )}
                </div>
              </div>
              <div tw="flex flex-row mb-8 font-medium">
                <div>Estimated total per recipient</div>
                <div tw="ml-auto">
                  {formatPrice(
                    customStore.priceEstimate.estimatedTotalLow,
                    false,
                  )}{" "}
                  –{" "}
                  {formatPrice(
                    customStore.priceEstimate.estimatedTotalHigh,
                    false,
                  )}
                </div>
              </div>
              <div tw="mb-5 text-gray-500" css={{ lineHeight: "140%" }}>
                You will only be charged for each gift when the recipient
                accepts.
              </div>
              <div tw="mb-6 text-gray-500" css={{ lineHeight: "140%" }}>
                If a product’s price changes and exceeds your maximum subtotal,
                it will no longer be displayed in the list of gifts.
              </div>
            </div>
          </div>
        )}
        {containsAlcoholProduct && (
          <AlcoholWarningContainer>
            <h2 tw="text-lg font-semibold pb-3 leading-6">
              Alcohol orders must be paid with a credit card.
            </h2>
            <p tw="font-text leading-5">
              If you use balance to pay for a custom store containing alcohol,
              any alcohol selections will be paid with the credit card on file.
            </p>
          </AlcoholWarningContainer>
        )}
        <PurpleGradientButton tw="mt-6 w-full" onClick={() => onSave()}>
          Save custom store
        </PurpleGradientButton>
        {customStore?.id && (
          <div tw="w-full flex items-center justify-center py-4">
            <ArchiveStoreButton onClick={() => onArchive()}>
              Archive custom store
            </ArchiveStoreButton>
          </div>
        )}
      </div>
    </div>
  )
}

const ArchiveStoreButton = styled(Button)`
  ${tw`text-gray-400 hover:text-gray-600 transition-colors outline-none border-none shadow-none`}
`

interface FileUploadProps {
  onDrop: (
    file: File,
    widthConstraint: number,
    heightConstraint: number,
    setImage: (image: HeaderImageProps) => void,
  ) => Promise<boolean>
  widthConstraintPx: number
  heightConstraintPx: number
  imageSrc: string | null
  setImage: (image: HeaderImageProps) => void
}

const FileUpload = ({
  onDrop,
  widthConstraintPx,
  heightConstraintPx,
  imageSrc,
  setImage,
}: FileUploadProps) => {
  const [validationError, setValidationError] = useState<string | null>(null)

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: async ([acceptedFile]) => {
      setValidationError(null)
      const result = await onDrop(
        acceptedFile,
        widthConstraintPx,
        heightConstraintPx,
        setImage,
      )
      if (!result) {
        setValidationError(
          `Image must be ${widthConstraintPx}px by ${heightConstraintPx}px`,
        )
      }
    },
    accept: "image/png, image/jpeg",
  })

  if (!imageSrc) {
    return (
      <>
        <div
          {...getRootProps()}
          tw="border border-dashed border-primary-200 hover:bg-primary-000 active:bg-primary-050 box-border rounded-lg flex items-center py-4 justify-center transition-all duration-75 cursor-pointer"
          css={{
            ...(isDragActive
              ? tw`bg-primary-000 border-primary-300`
              : tw`bg-white`),
          }}
        >
          <input {...getInputProps()} />
          <div tw="text-sm text-gray-450">Upload header image</div>
        </div>
        {validationError && (
          <div tw="text-red-600 text-xs py-2 text-center">
            {validationError}
          </div>
        )}
      </>
    )
  } else {
    return (
      <>
        <div tw="border border-none box-border rounded-lg flex items-center py-4 justify-center">
          <input {...getInputProps()} />
          {!!imageSrc && (
            <div tw="flex gap-6 w-full items-center flex-col lg:flex-row">
              <img
                tw="max-w-2xl max-h-14 object-cover mr-3"
                src={imageSrc}
                alt="Header"
              />
              <div tw="flex gap-6 items-center">
                <button {...getRootProps()} tw="text-sm text-gray-450">
                  Replace
                  <input {...getInputProps()} />
                </button>
                <button
                  tw="text-sm text-gray-450"
                  onClick={() => setImage({ file: null, url: null })}
                >
                  Remove
                </button>
              </div>
            </div>
          )}
        </div>
        {validationError && (
          <div tw="text-red-600 text-xs py-2 text-center">
            {validationError}
          </div>
        )}
      </>
    )
  }
}

const StyledFileUpload = tw(FileUpload)`cursor-pointer`

const GiftConfigSection = styled.div`
  ${tw`rounded-xl`}
  background-color: #F6F7F9;
`

const AddGiftButton = styled(Button)`
  ${tw`rounded-full border-none`}
  &:hover {
    background-color: #fefdff;
  }
`

const AlcoholWarningContainer = styled.div`
  box-sizing: border-box;
  box-shadow:
    0px 0px 4px rgba(0, 0, 0, 0.05),
    0px 0px 32px rgba(0, 0, 0, 0.04);
  ${tw`bg-white rounded-lg border border-purple-200 p-5`};
`

export default CustomStoreForm
