import { gql, useMutation } from "@apollo/client"
import React, { useState } from "react"
import { toast } from "react-hot-toast"
import tw, { styled } from "twin.macro"

import { CUSTOM_CARDS_FETCH } from "./graphql"
import { ReactComponent as AddCardIcon } from "../assets/icons/add-card.svg"
import { ReactComponent as ArrowRight } from "../assets/icons/arrow-right.svg"
import FileUpload from "../common/FileUpload"
import GradientButton from "../common/GradientButton"

interface CustomCardFormProps {
  card?: {
    name?: string | null
    id: string
    imageURL: string
  }
  closeForm: () => void
  displayForm: boolean
}

const CustomCardForm: React.FC<CustomCardFormProps> = ({
  card,
  closeForm,
  displayForm,
}) => {
  const [imageFile, setImageFile] = useState<File | null>(null)
  const [name, setName] = useState(card?.name || "")
  const [archiveCard, { loading: archiveLoading }] = useMutation(CARD_ARCHIVE, {
    refetchQueries: [{ query: CUSTOM_CARDS_FETCH }],
  })
  const [updateCard, { loading: updateLoading }] = useMutation(CARD_UPDATE)
  const [createCard, { loading: createLoading }] = useMutation(CARD_CREATE, {
    refetchQueries: [{ query: CUSTOM_CARDS_FETCH }],
  })

  const validNameLength = name.length <= 100 && name.length > 0

  const handleArchiveClick = () => {
    const result = window.confirm(
      "Archiving a card will not affect existing gifts sent with that card, but will remove it from the list of custom cards.",
    )
    if (card && result) {
      void archiveCard({ variables: { id: card.id, name: card.name } })
    }
  }

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.currentTarget.value)
  }

  const handleSubmit = async () => {
    // If a card object is provided, we will update that card.  If not, we will create a new card.
    if (card) {
      if (validNameLength) {
        const variables: { id: string; name: string; imageFile?: File } = {
          id: card.id,
          name,
        }

        if (imageFile) {
          variables.imageFile = imageFile
        }

        const response = await updateCard({ variables })
        const { cardUpdate } = response.data
        if (cardUpdate.ok) {
          toast.success("Card updated.", {
            iconTheme: {
              primary: "#27ae60",
              secondary: "#fff",
            },
          })
          setImageFile(null)
          closeForm()
        } else if (cardUpdate.error) {
          window.alert(cardUpdate.error)
        }
      }
    } else {
      if (validNameLength && imageFile) {
        const variables = {
          imageFile,
          name,
        }

        const response = await createCard({ variables })
        const { cardCreate } = response.data
        if (cardCreate.ok) {
          toast.success("Card created.", {
            iconTheme: {
              primary: "#27ae60",
              secondary: "#fff",
            },
          })
          setImageFile(null)
          setName("")
          closeForm()
        } else {
          window.alert(cardCreate.error)
        }
      }
    }
  }

  const getHeightAndWidth = (
    file: File,
  ): Promise<{ height: number; width: number }> => {
    const img = new Image()
    return new Promise((resolve) => {
      img.onload = () => {
        resolve({
          height: img.height,
          width: img.width,
        })
        URL.revokeObjectURL(img.src)
      }

      img.src = URL.createObjectURL(file)
    })
  }

  const onDrop = async (file: File) => {
    const { height, width } = await getHeightAndWidth(file)
    const ratio = height / width
    const validRatio = 1.41 <= ratio && ratio <= 1.42
    if (1130 <= height && 800 <= width && validRatio) {
      setImageFile(file)
      return true
    } else {
      return false
    }
  }

  const uploadDisabled =
    archiveLoading ||
    createLoading ||
    updateLoading ||
    !validNameLength ||
    (!card && !imageFile)

  return (
    <Container displayForm={displayForm}>
      {card === undefined && (
        <Instructions>
          <AddCardIcon tw="mb-2" />
          <InstructionsTitle>Add a custom card</InstructionsTitle>
          <Text>800px by 1130px</Text>
          <Text>PNG or JPEG file</Text>
          <Text>Card will be rounded</Text>
        </Instructions>
      )}
      <Input placeholder="Card name" onChange={handleNameChange} value={name} />
      <NameDisclaimer>Card name is not shown to recipients</NameDisclaimer>
      <StyledFileUpload
        file={imageFile}
        fileExists={card !== undefined}
        onDrop={onDrop}
      />
      {card !== undefined && (
        <UpdateDisclaimer>
          Updating the card image will update all gifts sent with this card.
        </UpdateDisclaimer>
      )}
      <Button disabled={uploadDisabled} onClick={handleSubmit}>
        {card === undefined ? "Upload" : "Update"}
        <ArrowRight />
      </Button>
      {card !== undefined && (
        <Archive onClick={handleArchiveClick}>Archive card</Archive>
      )}
    </Container>
  )
}

const Archive = styled.button`
  ${tw`text-primary-600`};

  margin-bottom: 4.25rem;
`

const Button = styled(GradientButton)`
  ${tw`flex justify-between mb-6`};
  width: 178px;
`

const Container = styled.div<{ displayForm: boolean }>`
  ${tw`absolute bg-white flex flex-col items-center opacity-0 p-3 pointer-events-none rounded-xl transform scale-75 transition z-30`};
  ${({ displayForm }) =>
    displayForm && tw`opacity-100 pointer-events-auto scale-100`};

  box-shadow:
    0 2px 4px rgba(0, 0, 0, 0.08),
    0 12px 31px rgba(0, 0, 0, 0.1);
  height: 688px;
  width: 463px;
`

const Instructions = tw.div`bg-primary-000 flex flex-col items-center justify-center p-6 rounded-lg w-full`

const InstructionsTitle = tw.p`font-medium text-primary-600 text-xl mb-4`

const Input = styled.input`
  ${tw`border border-gray-300 focus-visible:outline-none mt-8 placeholder-black rounded-lg text-center text-lg py-3 w-3/4`};

  ::-webkit-input-placeholder {
    ${tw`opacity-50`};
  }
`

const StyledFileUpload = tw(FileUpload)`cursor-pointer my-6 w-3/4`

const NameDisclaimer = tw.p`mt-2 text-gray-500 text-sm`
const UpdateDisclaimer = tw.p`mb-6 mt-12 text-gray-500 text-center w-72`
const Text = tw.p`text-gray-600 text-lg`

const CARD_ARCHIVE = gql`
  mutation CardArchive($id: ID!, $name: String!) {
    cardUpdate(cardFields: { id: $id, name: $name, status: "INACTIVE" }) {
      ok
      error
    }
  }
`

const CARD_CREATE = gql`
  mutation CardCreate($imageFile: Upload!, $name: String!) {
    cardCreate(cardFields: { imageFile: $imageFile, name: $name }) {
      ok
      error
    }
  }
`

const CARD_UPDATE = gql`
  mutation CardUpdate($id: ID!, $imageFile: Upload, $name: String!) {
    cardUpdate(cardFields: { id: $id, imageFile: $imageFile, name: $name }) {
      ok
      error
      card {
        id
        name
        imageLarge {
          url
        }
      }
    }
  }
`

export default CustomCardForm
