// This allows saving and restoring a workspace invite token after a user clicks away
// from the Sign Up page to either the 1) Sign In page, or 2) clicks an identity
// provider like Google to sign in.
//
// To prevent sending the workspace invite token to the third party identity
// provider, store the restore token in session storage, which expires when the
// tab is closed.
//
// Consumers of these methods (SignUp, WorkspaceInvitation) attempt to restore a
// restore token from session storage if it exists, and if one does exist, it
// restores the hash in window.location. This clears the token from session
// storage on retrieval.

export const RESTORE_TOKEN_STORAGE_NAME = "__goody__invite_restore_token"

export const setRestoreToken = (token: string) => {
  window.sessionStorage.setItem(RESTORE_TOKEN_STORAGE_NAME, token)
}

export const getRestoreToken = () => {
  return window.sessionStorage.getItem(RESTORE_TOKEN_STORAGE_NAME)
}
