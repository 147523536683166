import React from "react"
import tw from "twin.macro"

import HeaderInternalLink from "./HeaderInternalLink"

interface Props {
  name: string
  path?: string
  blockReclicks?: boolean
  mobileOnly?: boolean
}

const MainMenuItem: React.FC<Props> = ({
  name,
  path,
  blockReclicks,
  mobileOnly,
}) => {
  if (path?.startsWith("https://")) {
    return (
      <HeaderExternalLink
        tw="px-6 lg:px-0 py-4 lg:py-0"
        css={mobileOnly ? tw`lg:hidden` : ""}
        href={path}
        target="_blank"
        rel="noopener"
      >
        <PageName>{name}</PageName>
      </HeaderExternalLink>
    )
  }

  return (
    <HeaderInternalLink
      css={mobileOnly ? tw`lg:hidden` : ""}
      activeClassName="active"
      blockReclicks={blockReclicks}
      to={path || "#"}
    >
      <PageName>{name}</PageName>
    </HeaderInternalLink>
  )
}

export const HeaderExternalLink = tw.a`
  text-black lg:text-gray-700 hover:lg:text-primary-new-550 lg:font-medium whitespace-nowrap 
`
const PageName = tw.div`text-xl transition-colors lg:text-[16px] xl:text-[17px]`

export default MainMenuItem
