import React from "react"

import CartButtonBase from "./CartButtonBase"
import { useCartDrawer } from "../../common/contexts/cartDrawer"
import { useGiftCart } from "../../common/hooks/giftData"

// Cart button for use in the menu
const CartButton = () => {
  const { cartQuantity } = useGiftCart()
  const { openCart } = useCartDrawer()

  return <CartButtonBase openCart={openCart} cartItemCount={cartQuantity} />
}

export default CartButton
