import InternationalShippingCountriesModalList from "./InternationalShippingCountriesModalList"

import DialogModal from "@/common/DialogModal"
import { ReactComponent as CloseIcon } from "@/common/images/x.svg"
import { useShippingCountries } from "@/store/providers/ShippingCountriesProvider"

interface Props {
  open: boolean
  onClose: () => void
  shipsToGlobalCountries: string[]
  swapsToGlobalCountries: string[]
}

const InternationalShippingCountriesModal = ({
  open,
  onClose,
  shipsToGlobalCountries,
  swapsToGlobalCountries,
}: Props) => {
  const { expandShippingCountries } = useShippingCountries()

  const shipsToCountries = expandShippingCountries(shipsToGlobalCountries)
  const swapsToCountries = expandShippingCountries(swapsToGlobalCountries)

  const hasShipsToCountries = shipsToCountries.length > 0
  const hasSwapsToCountries = swapsToCountries.length > 0

  return (
    <DialogModal open={open} onClose={onClose}>
      <div tw="px-7 pt-7">
        <div tw="flex items-center justify-between w-full">
          <p tw="text-sm font-medium text-primary-new-600">Global Shipping</p>
          <div>
            <CloseIcon
              tw="w-5 h-5 stroke-current stroke-1.5 text-gray-450"
              onClick={onClose}
            />
          </div>
        </div>
      </div>

      <div tw="flex flex-col gap-7">
        {hasShipsToCountries && (
          <InternationalShippingCountriesModalList
            countries={shipsToCountries}
            header="This gift ships to"
            slitView={hasSwapsToCountries}
          />
        )}

        {hasSwapsToCountries && (
          <InternationalShippingCountriesModalList
            countries={swapsToCountries}
            header={
              hasShipsToCountries
                ? "This gift does not ship to these countries, but recipients can swap for gifts that do"
                : "This gift doesn’t ship globally, but recipients in these countries can swap for a gift that does"
            }
            slitView={hasShipsToCountries}
          />
        )}
      </div>
    </DialogModal>
  )
}

export default InternationalShippingCountriesModal
