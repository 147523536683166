import React from "react"

import TriangleAlertIcon from "./assets/icons/triangle-alert.svg"

import SSOSignInLink from "@/account/SSOSignInLink"
import Image from "@/common/Image"

interface Props {
  email: string
}

const SamlBlock = ({ email }: Props) => {
  return (
    <div tw="flex flex-col w-full border border-[#F2994A] bg-[#FFF8F2] p-6 rounded-xl mb-8">
      <div tw="flex flex-row items-center mb-2 leading-[19.68px]">
        <Image src={TriangleAlertIcon} tw="mr-[17px]" />
        <div tw="text-[#D0701B] font-semibold text-lg">
          Single sign-on has been disabled for your account
        </div>
      </div>
      <SSOSignInLink
        email={email}
        text="Click here to reconnect your account with your company’s single sign-on
        provider."
      />
    </div>
  )
}

export default SamlBlock
