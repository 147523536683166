import { useEffect, useState } from "react"

export const useAfterMountDelay = (timeoutInMs: number) => {
  const [done, setDone] = useState(false)

  useEffect(() => {
    // Animation is 500ms, giving it 600 for some log
    const timeout = setTimeout(() => setDone(true), timeoutInMs)
    return () => clearTimeout(timeout)
  }, [timeoutInMs])

  return done
}
