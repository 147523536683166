import React from "react"
import tw, { styled } from "twin.macro"

import AccountSection from "./AccountSection"

const AccountSupport = ({
  supportEmail = "plus-support@ongoody.com",
}: {
  supportEmail?: string
}) => (
  <AccountSection headline="Support">
    <a
      href={`mailto:${supportEmail}`}
      target="_blank"
      rel="noreferrer"
      tw="mb-40"
    >
      <MailContainer>
        <div tw="md:text-lg">{supportEmail}</div>
        <div tw="ml-10">
          <MailIcon />
        </div>
      </MailContainer>
    </a>
  </AccountSection>
)

const MailContainer = styled.div`
  ${tw`flex flex-row border rounded-md px-4 py-3 items-center border-[#dddddd]`}
`

const MailIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <g opacity="0.5">
      <path
        d="M4 4H20C21.1 4 22 4.9 22 6V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18V6C2 4.9 2.9 4 4 4Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 6L12 13L2 6"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
)

export default AccountSupport
