import tw, { styled } from "twin.macro"

import { gradientBg } from "../../assets/cdnAssets"

// Displays the gradient background behind the Send page.
export default function GradientBackground({ visible }: { visible: boolean }) {
  return (
    <>
      <BackgroundContainer visible={visible} />
      <ScrimContainer>
        <ScrimGradient />
        <ScrimSolid />
      </ScrimContainer>
    </>
  )
}

const BackgroundContainer = styled.div<{ visible: boolean }>`
  ${tw`h-[300px] bg-no-repeat bg-cover bg-top absolute top-0 left-0 right-0 transition-opacity`};
  background-image: url(${gradientBg});

  ${(props) => (props.visible ? tw`opacity-100` : tw`opacity-0`)};
`

const ScrimContainer = tw.div`absolute top-0 left-0 right-0 z-[5] h-[300px] transition-transform duration-[400ms] ease-out transform translate-y-[-100px]`

const ScrimGradient = styled.div`
  ${tw`h-[250px]`};
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
`

const ScrimSolid = tw.div`h-[250px] bg-white`
