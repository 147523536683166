import React, { useState } from "react"
import Modal from "react-modal"
import tw, { styled } from "twin.macro"

import ConnectHRButton from "./ConnectHRButton"
import ConnectRipplingButton from "./ConnectRipplingButton"
import HRPlatformsPng from "./images/hr-integration/hr-platforms.png"
import RipplingIconPng from "./images/hr-integration/rippling-icon.png"
import { ReactComponent as LinkIcon } from "./images/link.svg"
import { ReactComponent as CloseX } from "../../assets/icons/x.svg"
import Button from "../../common/Button"
import { modalStyle } from "../../common/modal"

interface ConnectHRContainerProps {
  className?: string
  onIntegrationConnect?: () => void
}

const ConnectHRContainer: React.FC<ConnectHRContainerProps> = ({
  className,
  children,
  onIntegrationConnect,
}) => {
  const [isModalOpen, setModalOpen] = useState(false)

  return (
    <ConnectHRModal
      isModalOpen={isModalOpen}
      setModalOpen={setModalOpen}
      onIntegrationConnect={onIntegrationConnect}
    />
  )
}

const ConnectHRModal = ({
  isModalOpen,
  setModalOpen,
  onIntegrationConnect,
}: {
  isModalOpen: boolean
  setModalOpen: (isModalOpen: boolean) => void
  onIntegrationConnect?: () => void
}) => {
  return (
    <>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => {
          setModalOpen(false)
        }}
        shouldCloseOnOverlayClick={true}
        style={modalStyle}
      >
        <div
          className="modal-content"
          style={{ maxWidth: "656px", maxHeight: "calc(100vh - 5rem)" }}
          tw="overflow-y-auto h-80v md:h-auto pb-12"
        >
          <ConnectHRModalBody
            onIntegrationConnect={onIntegrationConnect}
            close={() => setModalOpen(false)}
          />
        </div>
      </Modal>
      <ConnectHRModalButton onClick={() => setModalOpen(true)}>
        <div tw="flex items-center gap-3">
          <LinkIcon />
          <div tw="leading-snug">Connect HR</div>
        </div>
      </ConnectHRModalButton>
    </>
  )
}

const ConnectHRModalBody = ({
  close,
  onIntegrationConnect,
}: {
  close: () => void
  onIntegrationConnect?: () => void
}) => {
  return (
    <div tw="justify-center items-center text-center">
      <div tw="flex flex-col h-full">
        <div tw="h-10">
          <CloseX tw="float-right h-4 w-4 mt-6 mr-6" onClick={() => close()} />
        </div>
      </div>
      <div tw="flex flex-col h-full justify-center items-center">
        <div tw="w-full px-2 md:px-0 md:w-[37rem] text-center">
          <div tw="text-primary-500 font-medium text-2xl pb-12">
            Which HR platform are you using?
          </div>
          <ConnectHRButton
            onIntegrationConnect={onIntegrationConnect}
            tw="mb-10 w-full flex justify-center items-center"
          >
            <div tw="flex flex-col">
              <div tw="text-black font-medium text-xl pt-4 pb-2">
                All HR platforms except Rippling
              </div>
              <div tw="pb-5 text-gray-600">
                ADP, Gusto, BambooHR, UKG, and more
              </div>
              <img
                src={HRPlatformsPng}
                alt="HR Platforms"
                tw="object-contain w-[26rem]"
              />
            </div>
          </ConnectHRButton>
          <ConnectRipplingButton
            onIntegrationConnect={onIntegrationConnect}
            tw="w-full flex justify-center items-center"
          >
            <div tw="flex flex-col justify-center items-center">
              <div tw="text-black font-medium text-xl pt-4 pb-2">Rippling</div>
              <img
                src={RipplingIconPng}
                alt="Rippling Icon"
                tw="object-contain w-[3.5625rem] pb-4"
              />
            </div>
          </ConnectRipplingButton>
        </div>
      </div>
    </div>
  )
}

const ConnectHRModalButton = styled(Button)`
  ${tw`text-white`}
  background: linear-gradient(260.85deg, #FF7883 -94.31%, #A8B5FD 154.8%);

  &:hover,
  &:focus-visible {
    background: linear-gradient(260.85deg, #ff949d -94.31%, #c3ccfe 154.8%);
  }

  &:active {
    background: linear-gradient(260.85deg, #ff616e -94.31%, #91a2fc 154.8%);
  }
`

export default ConnectHRContainer
