import { gql, useMutation } from "@apollo/client"
import { useEffect } from "react"
import tw from "twin.macro"

import DirectSendProductIneligible from "./DirectSendProductIneligible"
import DirectSendVariantsRequired from "./DirectSendVariantsRequired"
import { useCartDrawer } from "../../common/contexts/cartDrawer"
import { useGiftData } from "../../common/hooks/giftData"
import { DETAILS_PRODUCT_FRAGMENT } from "../../common/queries"
import { ReactComponent as CannotShip } from "../images/cannot-ship.svg"
import { ReactComponent as SelectOptions } from "../images/select-options.svg"

import {
  BatchSendMethod,
  CartValidateDirectSendErrorType,
} from "@/types/graphql-types"
import {
  Send_ValidateDirectSendMutation,
  Send_ValidateDirectSendMutationVariables,
} from "@/types/graphql-types"

// Display an error when using direct send and a product is not eligible for
// direct send, or if variants need to be selected.
export default function DirectSendValidation({ sendV3 }: { sendV3?: boolean }) {
  const { currentGift, getCartInput, setSendMethod } = useGiftData()
  const { openCart } = useCartDrawer()

  const cart = currentGift.cart
  const isDirectSend = currentGift.sendMethod === BatchSendMethod.direct_send

  const [validateDirectSend, { data, reset }] = useMutation<
    Send_ValidateDirectSendMutation,
    Send_ValidateDirectSendMutationVariables
  >(VALIDATE_DIRECT_SEND_MUTATION)

  useEffect(() => {
    if (isDirectSend && cart.length > 0) {
      ;(async () => {
        await validateDirectSend({
          variables: {
            cart: getCartInput(),
          },
        })
      })()
    } else {
      reset()
    }
  }, [cart, isDirectSend])

  if (!isDirectSend) {
    return null
  }

  if (!data || data?.cartValidateDirectSend?.ok) {
    return null
  }

  const result = data.cartValidateDirectSend

  const errorType = result.errorType
  const errorMessage = result.errorMessage
  const errorProductName = result.errorProductName
  const variantRequiredProducts = result.variantRequiredProducts

  const switchToEmailOrLink = () => {
    setSendMethod(null)
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  return (
    <div tw="pt-16 pb-10">
      <div tw="bg-gray-050 border border-gray-150 p-6 rounded-lg">
        <div tw="py-2">
          <div
            tw="pb-4 text-center flex flex-row items-center justify-center gap-3 text-xl font-medium"
            css={[
              errorType ===
                CartValidateDirectSendErrorType.product_ineligible &&
                tw`text-[#EB5757]`,
            ]}
          >
            {errorType ===
            CartValidateDirectSendErrorType.product_ineligible ? (
              <>
                <CannotShip tw="hidden md:block" />
                <div>
                  Sorry, {errorProductName || "this product"} cannot be shipped
                  to an address
                </div>
              </>
            ) : errorType ===
              CartValidateDirectSendErrorType.variant_required ? (
              <>
                <SelectOptions tw="hidden md:block" />
                <div>
                  To send directly to address, you’ll need to select some
                  options
                </div>
              </>
            ) : null}
          </div>
          <div tw="text-gray-500 text-lg text-center max-w-[500px] mx-auto pb-8">
            {errorType === CartValidateDirectSendErrorType.product_ineligible
              ? errorMessage
              : errorType === CartValidateDirectSendErrorType.variant_required
              ? "You selected ‘Let recipient choose’ for a product, but when sending directly to an address, we need to know which options to ship to your recipient."
              : null}
          </div>
          {errorType === CartValidateDirectSendErrorType.product_ineligible && (
            <DirectSendProductIneligible
              switchToEmailOrLink={switchToEmailOrLink}
              openCart={openCart}
            />
          )}
          {errorType === CartValidateDirectSendErrorType.variant_required && (
            <DirectSendVariantsRequired
              switchToEmailOrLink={switchToEmailOrLink}
              variantRequiredProducts={variantRequiredProducts}
              sendV3={sendV3}
            />
          )}
        </div>
      </div>
    </div>
  )
}

const VALIDATE_DIRECT_SEND_MUTATION = gql`
  mutation Send_ValidateDirectSend($cart: [CartProductInput!]!) {
    cartValidateDirectSend(cart: $cart) {
      ok
      errorType
      errorMessage
      errorProductName
      variantRequiredProducts {
        ...Details_Product
      }
    }
  }
  ${DETAILS_PRODUCT_FRAGMENT}
`
