import React from "react"
import { Link } from "react-router-dom"
import tw, { styled } from "twin.macro"

interface DropDownContentProps {
  items: {
    icon: React.ReactNode
    name: string
    link: string
  }[]
}

const DropdownContent = ({ items }: DropDownContentProps) => (
  <div tw="p-4">
    {items.map((item) => (
      <Item
        key={item.name}
        to={{ pathname: item.link }}
        className={"group"}
        target={item.link.startsWith("https://") ? "_blank" : undefined}
      >
        {item.icon}
        {item.name}
      </Item>
    ))}
  </div>
)

const Container = tw`
  flex items-center gap-3 py-3 px-4 rounded-md
  bg-transparent hover:bg-[rgba(60, 71, 121, 0.05)]
  active:bg-[rgba(129, 103, 255, 0.05)]
`
const LinkTextStyle = tw`
  text-[#1f2024] text-base font-semibold
  active:text-[#8167ff]
`

const Item = styled(Link)`
  ${Container}
  ${LinkTextStyle}

  svg {
    stroke: #8167ff;
  }

  &:hover {
    svg {
      stroke: #1f2024;
    }
  }
  &:active {
    svg {
      stroke: #8167ff;
    }
  }
`

export default DropdownContent
