import React from "react"
import { UseFormReturn } from "react-hook-form"

import { CurrentGiftSendForm } from "./sendTypes"

interface CardFromProps {
  currentGiftForm: UseFormReturn<CurrentGiftSendForm>
  onChange: () => void
}

const CardFrom = ({
  currentGiftForm: { register },
  onChange,
}: CardFromProps) => {
  const { onChange: currentGiftFormOnChange, ...inputProps } =
    register("fromName")
  return (
    <div tw="px-5 pb-3 pt-6">
      <div tw="text-sm font-semibold pb-1 text-gray-500">From</div>
      <div>
        <input
          tw="w-full p-0 focus:outline-none"
          placeholder="A name or company name"
          onChange={(e) => {
            onChange()
            return currentGiftFormOnChange(e)
          }}
          {...inputProps}
        />
      </div>
    </div>
  )
}
export default CardFrom
