import { ApolloProvider } from "@apollo/client"
import { PlasmicRootProvider } from "@plasmicapp/react-web"
import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"
import { excludeGraphQLFetch } from "apollo-link-sentry"
import React from "react"
import { createRoot } from "react-dom/client"
import { Link } from "react-router-dom"
import { IntercomProvider } from "react-use-intercom"

import App from "./App"
import { client } from "./common/apollo"
import { DeveloperModeProvider } from "./common/contexts/developerMode"
import { DismissedScreensProvider } from "./common/contexts/dismissedScreens"
import { getEnvVars } from "./common/environment"
import GlobalStyles from "./styles/GlobalStyles"

import PlasmicGlobalContextsProvider from "@/plasmic/plasmic/main/PlasmicGlobalContextsProvider"

import "./index.css"

// @ts-ignore - TypeScript doesn't like us importing this file without some flag
import "./plasmic-init.ts"

const dsn = getEnvVars().sentryDsn
let tracingOrigin = new URL(getEnvVars().apiUrl).origin

if (dsn) {
  Sentry.init({
    dsn,
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: [tracingOrigin],
      }),
      Sentry.replayIntegration({
        mask: [".ph-no-capture", ".pac-container"],
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    replaysSessionSampleRate: 0.0,
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: 0.01,
    beforeBreadcrumb: excludeGraphQLFetch,
  })
}

const AppWithSentry = Sentry.withProfiler(App)

const container = document.getElementById("root")!
const root = createRoot(container)

root.render(
  // NOTE: React.StrictMode will double invoke rendering which can causes issues
  // with tokens saved to session since session is cleared on each rerender
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<div>"An error has occurred"</div>}>
      <PlasmicRootProvider Link={Link}>
        <PlasmicGlobalContextsProvider>
          <ApolloProvider client={client}>
            <IntercomProvider appId={getEnvVars().intercomAppId}>
              <DismissedScreensProvider>
                <DeveloperModeProvider>
                  <GlobalStyles />
                  <AppWithSentry />
                </DeveloperModeProvider>
              </DismissedScreensProvider>
            </IntercomProvider>
          </ApolloProvider>
        </PlasmicGlobalContextsProvider>
      </PlasmicRootProvider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
)
