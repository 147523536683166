import { LocationDescriptor } from "history"
import React from "react"
import { NavLink } from "react-router-dom"
import tw from "twin.macro"

interface WorkspacesListItemProps {
  selected: boolean
  name: string
  to: LocationDescriptor
}

const WorkspacesListItem: React.FC<WorkspacesListItemProps> = ({
  selected,
  name,
  to,
}) => {
  return (
    <NavLink to={to} tw="block">
      <div
        className="group"
        css={[
          // Relative positioning allows us to use z-index.
          tw`bg-white relative block w-full text-left focus:outline-none transition-colors`,
          // When selected, overlap 1px above and 1px below.
          // -1px Y margin shifts this element 1px above and below.
          // +1px Y padding accounts for the 2px in lost space by making this item larger.
          selected && tw`-my-px py-px z-10`,
        ]}
      >
        <div
          css={[
            // When selected, set a background color.
            selected && tw`bg-primary-050 rounded-lg`,
          ]}
        >
          <div css={[tw`px-6 py-3`]}>
            <div
              css={[
                // tw`text-gray-500 group-hover:text-gray-700 group-focus-visible:text-gray-700 transition-colors`,
                tw`text-gray-500 group-hover:text-gray-700 transition-colors`,
                // This doesn't actually work, the group-* styles get applied even if not selected
                // The group-* stuff just gets applied to the non-selected class, not sure why
                // selected &&
                //   tw`font-medium text-primary-500 group-hover:text-primary-500 group-focus-visible:text-primary-600`,
                selected &&
                  tw`font-medium text-primary-500 group-hover:text-primary-500`,
              ]}
            >
              {name}
            </div>
          </div>
        </div>
      </div>
    </NavLink>
  )
}

export default WorkspacesListItem
