import { gql, useQuery } from "@apollo/client"
import { useEffect, useState } from "react"

import BalanceTransactions, {
  BALANCE_TRANSACTIONS_FRAGMENT,
} from "../../../balance/components/BalanceTransactions"

import {
  Budgeting_MemberDetailTransactionsQuery,
  Budgeting_MemberDetailTransactionsQueryVariables,
} from "@/types/graphql-types"

interface Props {
  membershipID: string
  memberName?: string | null
}

export default function MemberDetailTransactionLog({
  membershipID,
  memberName,
}: Props) {
  const [page, setPage] = useState(0)

  const { data, refetch } = useQuery<
    Budgeting_MemberDetailTransactionsQuery,
    Budgeting_MemberDetailTransactionsQueryVariables
  >(MEMBER_DETAIL_TRANSACTIONS_QUERY, {
    variables: {
      id: membershipID,
      page: page + 1,
    },
  })

  useEffect(() => {
    if (refetch) {
      refetch({
        page: page + 1,
      })
    }
  }, [page])

  return (
    <div tw="pb-32">
      <BalanceTransactions
        page={page}
        totalCount={
          data?.organization?.membership?.balanceAccount?.transactions
            ?.totalCount
        }
        onChangePage={(page) => {
          setPage(page)
          document.querySelector(".MuiPaper-root")?.scrollTo(0, 0)
        }}
        transactions={
          data?.organization?.membership?.balanceAccount?.transactions?.items
        }
        compact={true}
        preventScrollToTopOnPageChange={true}
        memberName={memberName}
      />
    </div>
  )
}

const MEMBER_DETAIL_TRANSACTIONS_QUERY = gql`
  query Budgeting_MemberDetailTransactions($id: ID!, $page: Int!) {
    organization {
      membership(id: $id) {
        balanceAccount {
          id
          transactions(page: $page, perPage: 10) {
            totalCount
            items {
              ...BalanceTransaction
            }
          }
        }
      }
    }
  }

  ${BALANCE_TRANSACTIONS_FRAGMENT}
`
