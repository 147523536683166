import React from "react"

interface Props {
  name: string
  label: string
  placeholder: string
  value: string
  onChange: (name: string, value: string) => void
}

const FormField = React.forwardRef<HTMLInputElement, Props>(
  ({ name, label, placeholder, value, onChange }, ref) => (
    <>
      <div tw="text-sm font-medium pb-1 text-gray-600 font-text">{label}</div>
      <div>
        <input
          tw="w-full p-0 focus:outline-none font-text"
          placeholder={placeholder}
          value={value}
          name={name}
          onChange={(e) => {
            onChange(name, e.target.value)
          }}
          ref={ref}
        />
      </div>
    </>
  ),
)

export default FormField
