import React, { useEffect } from "react"
import { Helmet } from "react-helmet-async"
import tw, { styled } from "twin.macro"

import customerLogos from "./images/customer-logos-ea.png"
import { Banner } from "./sections/ea/Banner"
import { BetterAndEasy } from "./sections/ea/BetterAndEasy"
import { Brands } from "./sections/ea/Brands"
import { EALovesGoody } from "./sections/ea/EALovesGoody"
import { Features } from "./sections/ea/Features"
import { Footer } from "./sections/ea/Footer"

const Section = styled.section`
  ${tw`mb-20 col-span-12`}
`

const EA: React.FC = () => {
  useEffect(() => {
    document.body.classList.add("bg-white")
    return () => {
      document.body.classList.remove("bg-white")
    }
  }, [])

  return (
    <div tw="container pt-4 mx-auto grid grid-cols-12">
      <Helmet>
        <title>For Executive Assistants & Admins — Goody for Business</title>
      </Helmet>
      <Section>
        <Banner />
      </Section>
      <Section>
        <EALovesGoody />
      </Section>
      {/* <Section>
        <UserReviews />
      </Section> */}
      <Section>
        <img
          src={customerLogos}
          alt="Customer logos"
          tw="px-5 md:py-20 md:px-12 lg:mx-auto lg:p-0 lg:pb-12 lg:w-3/5"
        />
      </Section>
      <Section tw="mb-0">
        <Features />
      </Section>
      <Section>
        <Brands />
      </Section>
      <Section tw="mb-0">
        <BetterAndEasy />
      </Section>
      <Section tw="mb-0">
        <Footer />
      </Section>
    </div>
  )
}

export default EA
