import tw, { styled } from "twin.macro"

import search from "@/assets/icons/search.svg"

const SearchInput = styled.input`
  ${tw`pl-10 pr-3 py-[7px] leading-5 rounded-lg border border-gray-150 hover:border-gray-200 outline-none bg-no-repeat`}
  background-image: url(${search});
  background-position: 12px 10px;
`

export default SearchInput
