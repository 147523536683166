import tw, { styled } from "twin.macro"

import { CheckCircleBlue } from "../../../assets/icons"
import PurpleGradientLink from "../../../common/PurpleGradientLink"
import { generateRealmPath } from "../../../common/realm"
import staticAssetUrl from "../../../common/staticAssetUrl"
import WhiteBackgroundLink from "../../../common/WhiteBackgroundLink"
import { Header, Subheading } from "../../common/HeroComponents"

const BulkGiftingExample = staticAssetUrl(
  "landing/how_it_works/bulk_gifting/BulkGiftingExample.png",
)

const Hero = () => (
  <Container>
    <InnerContainer>
      <div tw="flex-1 pt-20">
        <Header>
          Bulk gifts.
          <br />
          Personal Touch.
        </Header>
        <Subheading tw="max-w-[28rem] lg:max-w-full lg:mb-8 pr-0">
          Whether you're sending 10 gifts or 10,000, Goody's bulk gifting
          platform makes it easier than ever before.
        </Subheading>
        <div tw="flex flex-col-reverse lg:flex-col">
          <div tw="flex flex-col lg:flex-row">
            <PurpleGradientLink
              tw="flex-none w-full lg:w-auto"
              to={generateRealmPath("business", "/business/signup")}
            >
              Start Gifting
            </PurpleGradientLink>
            <WhiteBackgroundLink
              tw="w-full lg:w-40 text-center font-semibold lg:ml-4 lg:inline-block mt-2 lg:mt-0"
              to={generateRealmPath("business", "/book-a-call")}
            >
              Book a Call
            </WhiteBackgroundLink>
          </div>
          <div tw="flex flex-col lg:flex-row items-center py-8 gap-6">
            <div tw="flex w-[12.5rem] lg:w-max items-center text-gray-600 text-lg">
              <CheckCircleBlue tw="flex-shrink-0 mr-2" />
              <span>Unlimited Recipients</span>
            </div>
            <div tw="flex w-[12.5rem] lg:w-max items-center text-gray-600 text-lg">
              <CheckCircleBlue tw="flex-shrink-0 mr-2" />
              <span>300+ gift brands</span>
            </div>
            <div tw="flex w-[12.5rem] lg:w-max items-center text-gray-600 text-lg">
              <CheckCircleBlue tw="flex-shrink-0 mr-2" />
              <span>No address needed</span>
            </div>
          </div>
        </div>
      </div>
      <div tw="flex items-center justify-center pt-8">
        <img tw="xl:w-[95%]" src={BulkGiftingExample} />
      </div>
    </InnerContainer>
  </Container>
)

const Container = styled.div`
  position: relative;
  background-color: rgba(249, 236, 255, 0.3);
  ${tw`px-8 pb-24 lg:pb-32`}

  @media (min-width: 2000px) {
    padding-bottom: 6rem;
    margin-bottom: 12rem;
  }

  &:after {
    ${tw`hidden lg:block`}
    content: "";
    background-color: #ffffff;
    position: absolute;
    height: 5rem;
    width: 120%;
    border-radius: 50%;
    bottom: -10%;
    left: -10%;
    right: -10%;

    @media (min-width: 1024px) {
      height: 30%;
      bottom: -15%;
    }

    @media (min-width: 2000px) {
      content: none;
    }
  }
`

const InnerContainer = styled.div`
  max-width: 1200px;
  ${tw`flex mx-auto flex-col lg:flex-row`}
`

export default Hero
