import React, { Suspense } from "react"

const WSConnection = React.lazy(() => import("./WSConnection"))

// Lazy-loads WSConnection. Since this is a UI-less component, no loader
// is shown. Lazy-loading means that we don't need to have the ~60KB Ably package
// in our main bundle, since it can be loaded in separately.
export default function WSConnectionLoader() {
  return (
    <Suspense fallback={null}>
      <WSConnection />
    </Suspense>
  )
}
