import "twin.macro"
import { useQuery } from "@apollo/client"
import { Helmet } from "react-helmet-async"

import { ReferralDescription } from "./components/referrals/ReferralDescription"
import { ReferralInviteForm } from "./components/referrals/ReferralInviteForm"
import { ReferralUrlAndReward } from "./components/referrals/ReferralUrlAndReward"
import { REFERRALS_QUERY } from "./queries/referrals/Referrals"
import { Loader } from "../common/UI"

import { Account_ReferralsQuery } from "@/types/graphql-types"

export default function Referrals() {
  const { data, loading } = useQuery<Account_ReferralsQuery>(REFERRALS_QUERY, {
    fetchPolicy: "cache-first",
  })
  const me = data?.me

  const referralUrl =
    me?.businessReferralInfo?.referralUrl ||
    "Unable to generate referral url.  Please contact support."

  const creditAwarded = me?.businessReferralInfo?.creditAwarded

  return (
    <>
      <Helmet>
        <title>Account – Referrals</title>
      </Helmet>
      {data == null || loading ? (
        <div tw="container mx-auto bg-white flex items-center justify-center h-80">
          <Loader />
        </div>
      ) : (
        <div tw="container mx-auto bg-white pl-12">
          <div tw="py-6 pt-12">
            <ReferralDescription />
          </div>
          <div tw="mt-4 ml-12">
            <ReferralInviteForm />
          </div>
          <ReferralUrlAndReward
            referralUrl={referralUrl}
            creditAwarded={creditAwarded}
          />
        </div>
      )}
    </>
  )
}
