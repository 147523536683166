import tw, { styled } from "twin.macro"

const TrackActionButton = styled.button<{ hasProTag: boolean }>`
  ${tw`inline-flex flex-row rounded-lg object-center items-center text-gray-500 bg-white hover:text-primary-500 focus:outline-none active:bg-primary-000`}
  ${({ hasProTag }) => (hasProTag ? tw`p-0.5 pl-3` : tw`py-1 px-3 `)};

  box-shadow:
    0 4px 4px rgba(0, 0, 0, 0.04),
    0 8px 37px rgba(0, 0, 0, 0.06);

  &:disabled {
    ${tw`cursor-auto opacity-50`}
  }
`

export default TrackActionButton
