import React from "react"
import { Helmet } from "react-helmet-async"
import { NavLink, Redirect } from "react-router-dom"
import tw, { styled } from "twin.macro"

import AccountAPIKeys from "./AccountAPIKeys"
import AccountGeneral from "./AccountGeneral"
import RequestDataAccess from "./dataPrivacyComponents/RequestDataAccess"
import RequestDataDeletion from "./dataPrivacyComponents/RequestDataDeletion"
import Integrations from "./Integrations"
import PaymentMethods from "./PaymentMethods"
import Receipts from "./Receipts"
import Referrals from "./Referrals"
import { useGlobalState } from "../common/GlobalState"
import { ReactComponent as UserIcon } from "../common/images/user.svg"
import { generateRealmPath } from "../common/realm"
import RealmRoute from "../common/RealmRoute"
import CustomCards from "../customCards/CustomCards"
import Container from "../sites/App/Container"
import UserSubscription from "../subscriptions/UserSubscription"
import UserSubscriptionCancel from "../subscriptions/UserSubscriptionCancel"

const Account: React.FC = () => {
  const [user] = useGlobalState("user")

  if (!user) {
    return <Redirect to={generateRealmPath("business", "/signin")} />
  }

  return (
    <div tw="border-t border-primary-100">
      <Container tw="px-5">
        <div tw="pt-10">
          <div tw="flex flex-row items-center">
            <UserIcon
              tw="stroke-current text-gray-400 stroke-1.5"
              style={{ width: 28, height: 28 }}
            />
            <div tw="pl-3 text-2xl font-medium">Account</div>
          </div>
        </div>
      </Container>
      <div tw="pt-4">
        <WorkspaceNavWrapper>
          <WorkspaceNav>
            <WorkspaceNavStartSpacer />
            <WorkspaceNavItem
              title="My Account"
              to={generateRealmPath("plus", `/account`)}
              exact
            >
              My Account
            </WorkspaceNavItem>
            <WorkspaceNavItem
              title="Subscription"
              to={generateRealmPath("plus", `/account/subscription`)}
              exact
            >
              Subscription
            </WorkspaceNavItem>
            <WorkspaceNavItem
              title="Receipts"
              to={generateRealmPath("plus", "/account/receipts")}
              exact
            >
              Receipts
            </WorkspaceNavItem>
            <WorkspaceNavItem
              title="Payment Methods"
              to={generateRealmPath("plus", "/account/payment-methods")}
              exact
            >
              Payment Methods
            </WorkspaceNavItem>
            <WorkspaceNavItem
              title="Integrations"
              to={generateRealmPath("plus", `/account/integrations`)}
            >
              Integrations
            </WorkspaceNavItem>
            <WorkspaceNavItem
              title="API Keys"
              to={generateRealmPath("plus", `/account/api-keys`)}
            >
              API Keys
            </WorkspaceNavItem>
            <WorkspaceNavItem
              title="Custom Cards"
              to={generateRealmPath("plus", `/account/custom-cards`)}
              exact
            >
              Custom Cards
            </WorkspaceNavItem>
            <WorkspaceNavItem
              title="Referrals"
              to={generateRealmPath("plus", "/account/referrals")}
            >
              Referrals
            </WorkspaceNavItem>
            <WorkspaceNavEndSpacer />
          </WorkspaceNav>
        </WorkspaceNavWrapper>
      </div>
      <RealmRoute exact path="/account/request-data-deletion" realm={"plus"}>
        <RequestDataDeletion />
      </RealmRoute>
      <RealmRoute exact path="/account/request-data-access" realm={"plus"}>
        <RequestDataAccess />
      </RealmRoute>
      <RealmRoute exact path={"/account/subscription"} realm={"plus"}>
        <UserSubscription />
        <Helmet>
          <title>Subscription – Goody for Business</title>
        </Helmet>
      </RealmRoute>
      <RealmRoute exact path={"/account/receipts"} realm={"plus"}>
        <Receipts />
      </RealmRoute>
      <RealmRoute exact path={"/account/payment-methods"} realm={"plus"}>
        <PaymentMethods />
      </RealmRoute>
      <RealmRoute exact path={"/account"} realm={"plus"}>
        <AccountGeneral />
      </RealmRoute>
      <RealmRoute exact path={"/account/custom-cards"} realm={"plus"}>
        <CustomCards />
      </RealmRoute>
      <RealmRoute exact path={"/account/subscription-cancel"} realm={"plus"}>
        <UserSubscriptionCancel />
      </RealmRoute>
      <Integrations />
      <RealmRoute exact path={"/account/api-keys"} realm={"plus"}>
        <AccountAPIKeys />
      </RealmRoute>
      <RealmRoute exact path={"/account/referrals"} realm={"plus"}>
        <Referrals />
      </RealmRoute>
    </div>
  )
}

const WorkspaceNavWrapper = tw.div`whitespace-nowrap w-full overflow-y-auto h-16 lg:h-auto lg:overflow-y-visible z-10 border-b flex flex-col justify-end`
const WorkspaceNav = tw.nav`container mx-auto flex flex-row items-end whitespace-nowrap`
const WorkspaceNavStartSpacer = tw.div`pl-2 lg:pl-1`
const WorkspaceNavEndSpacer = tw.div`pr-2 lg:pr-8 flex-1`
const WorkspaceNavItem = styled(NavLink)`
  ${tw`relative inline-block p-4 pb-2 text-center text-gray-500 transition text-lg hover:text-primary-500 mb-0.5`};

  /**
    Reserves container space for bolded version of text. We need to
    pass in the link text to the "title" attribute.
   */
  &::before {
    ${tw`block font-medium h-0 invisible`};
    content: attr(title);
  }

  &::after {
    ${tw`absolute bg-gray-200 inline-block left-4 right-4 rounded-full opacity-0 transition`};
    bottom: -2px;
    content: "";
    height: 3px;
  }

  &:hover {
    &::after {
      ${tw`bg-primary-200 opacity-100`};
    }
  }

  &.active {
    ${tw`font-medium text-primary-500`};

    &::after {
      ${tw`bg-primary-500 opacity-100`};
    }
  }
`

export default Account
