import React, { useState } from "react"
import useCollapse from "react-collapsed"
import tw from "twin.macro"

import { ReactComponent as ChevronDown } from "../../common/images/chevron-down.svg"

interface Props {
  items: SlideData[]
  onExpand?: (index: number) => void
  initialExpandedIndex?: number
}

const Accordion: React.FC<Props> = ({
  items,
  onExpand,
  initialExpandedIndex = 0,
}) => {
  const [currentSlide, setCurrentSlide] = useState<number>(initialExpandedIndex)

  const expandItem = (index: number) => {
    onExpand && onExpand(index)
    setCurrentSlide(index)
  }
  return (
    <>
      {items.map((item, index) => (
        <CollapsibleContent
          key={item.title}
          data={item}
          isExpanded={index === currentSlide}
          onExpand={() => expandItem(index)}
        />
      ))}
    </>
  )
}

const CollapsibleContent: React.FC<{
  data: SlideData
  isExpanded: boolean
  onExpand: () => void
}> = ({ data, isExpanded, onExpand }) => {
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded })

  return (
    <button
      tw="border border-transparent bg-gray-050 hover:bg-gray-100 active:bg-gray-150 focus:outline-none focus-visible:bg-gray-100 block w-full text-left py-8 px-6 mb-3 rounded-xl transition-colors"
      css={[
        isExpanded &&
          tw`bg-white hover:bg-white active:bg-white focus-visible:bg-white border-gray-150 transition-shadow`,
        isExpanded && { boxShadow: "0 12px 20px 0 rgba(0, 0, 0, 0.05)" },
      ]}
      {...getToggleProps({
        onClick: () => {
          console.log("expand")
          onExpand()
        },
      })}
    >
      <div tw="flex flex-row items-stretch gap-4 pl-2">
        {/* Not using this progress bar */}
        {/*<div tw="rounded bg-gray-300" css={{ width: "4px" }}></div>*/}
        <div tw="flex-1">
          <div tw="flex flex-row items-center justify-between gap-3">
            <div
              tw="font-medium text-2xl leading-tight transition-colors"
              css={[isExpanded && tw`text-primary-600`]}
            >
              {data.title}
            </div>
            <ChevronDown
              tw="stroke-current text-gray-400 stroke-2 transition-transform"
              css={[isExpanded && tw`rotate-180`]}
            />
          </div>
          <div
            tw="text-lg lg:text-xl text-gray-600"
            css={{ lineHeight: 1.5 }}
            {...getCollapseProps()}
          >
            <div tw="pt-4 pr-4 lg:pr-8">{data.content}</div>
          </div>
        </div>
      </div>
    </button>
  )
}

export default Accordion
