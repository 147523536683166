import React, { useContext } from "react"

import { ReactComponent as Icon } from "./icons/notification-options.svg"
import OptionBase from "./OptionBase"
import { ReactComponent as QuestionMark } from "../../../assets/icons/question-mark.svg"
import { useGiftData } from "../../../common/hooks/giftData"
import {
  SelectionGroup,
  SelectionIndicator,
  SelectionLabel,
} from "../../../common/SelectionGroup"
import Tooltip from "../../../common/Tooltip"
import EmailDeliverySection from "../../EmailDeliverySection"
import EmailSubjectOption from "../../EmailSubjectOption"
import { OptionsContext } from "../OptionsContext"

import { EmailDeliveryMethods } from "@/types/graphql-types"

export default function NotificationOptions() {
  const { currentGift, isDirectSend } = useContext(OptionsContext)
  const { setLandingPage } = useGiftData()

  let preview = currentGift.landingPageSendNotifs
    ? "Notifications enabled"
    : "Notifications disabled"

  if (currentGift.customEmailSubject) {
    preview += ", custom subject"
  }

  if (currentGift.emailDeliveryMethod === EmailDeliveryMethods.gmail) {
    preview += ", Gmail"
  }

  // Direct send has a "notifications enabled/disabled" toggle at the top, so
  // if that's disabled, hide this
  if (isDirectSend && !currentGift.landingPageSendNotifs) {
    return null
  }

  if (currentGift.isSmartLink) {
    return null
  }

  return (
    <OptionBase name="Notification options" icon={<Icon />} preview={preview}>
      {!isDirectSend && (
        <div>
          <div tw="text-gray-500 text-sm font-text">
            You can disable email/text gift notifications to recipients. If you
            disable gift notifications, your recipients will only be able to
            redeem gifts if you send them a gift link.
            <Tooltip
              trigger={
                <span>
                  <QuestionMark tw="ml-1 inline-block -mt-0.5" />
                </span>
              }
              title="Gift notifications"
              placement="auto"
            >
              <p>
                If you enable this, we'll send the usual email and text
                notifications to your recipients. If you disable this, we won't
                send any notifications or reminders, and you'll just need to
                make sure you send the gift link to all of your recipients on
                your own.
              </p>
              <p>
                We'll automatically create a gift link for you where recipients
                can enter their email address to open their gift. You can also
                send individual gift links to people which open directly to
                their assigned gift.
              </p>
            </Tooltip>
          </div>
          <div tw="pt-3" />
          <div>
            <SelectionGroup
              onClick={() => setLandingPage({ sendNotifs: true })}
            >
              <SelectionIndicator
                selected={currentGift.landingPageSendNotifs}
              />
              <SelectionLabel selected={currentGift.landingPageSendNotifs}>
                Yes, send gift notifications to recipients
              </SelectionLabel>
            </SelectionGroup>
          </div>
          <div>
            <SelectionGroup
              onClick={() => setLandingPage({ sendNotifs: false })}
            >
              <SelectionIndicator
                selected={!currentGift.landingPageSendNotifs}
              />
              <SelectionLabel selected={!currentGift.landingPageSendNotifs}>
                No, just get a gift link to send
              </SelectionLabel>
            </SelectionGroup>
          </div>
        </div>
      )}

      {currentGift.landingPageSendNotifs ? (
        <>
          <div tw="pt-8">
            <EmailSubjectOption giftBatch={currentGift} removePadding={true} />
          </div>
          <div tw="pt-8">
            <EmailDeliverySection removePadding={true} />
          </div>
        </>
      ) : (
        <div tw="pl-8 text-gray-500 text-sm font-text">
          Link displayed after gift is sent
        </div>
      )}
    </OptionBase>
  )
}
