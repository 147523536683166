import React, { ReactElement } from "react"

import { useGlobalState } from "./GlobalState"
import useIsLimitedMember from "./hooks/useIsLimitedMember"

interface Props {
  children: ReactElement
}

const LimitedAccessProtected = ({ children }: Props) => {
  const isLimitedMember = useIsLimitedMember()

  if (isLimitedMember) {
    return <LimitedAccessModal children={children} />
  }

  return children
}

const LimitedAccessModal = ({ children }: Props) => {
  const [_, setIsLimitedMemberFeatureGateModalOpen] = useGlobalState(
    "isLimitedMemberFeatureGateModalOpen",
  )

  const { children: grandchildren } = children.props
  return React.cloneElement(
    children,
    {
      onClick: () => setIsLimitedMemberFeatureGateModalOpen(true),
    },
    grandchildren,
  )
}

export default LimitedAccessProtected
