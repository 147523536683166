import React, { ReactElement } from "react"
import tw from "twin.macro"

import TitleContainer from "./TitleContainer"
import PurpleGradientLink from "../../../common/PurpleGradientLink"
import { generateRealmPath } from "../../../common/realm"
import WhiteBackgroundLink from "../../../common/WhiteBackgroundLink"

interface Props {
  heading?: string
  signedIn: boolean
  showSecondaryAction?: boolean
  title?: string
  description?: string | ReactElement
  primaryAction?: LinkAction
  primarySignedInAction?: LinkAction
  secondaryAction?: LinkAction
}

const CallToAction = ({
  signedIn,
  showSecondaryAction = true,
  heading = "See it in action.",
  title = "Start gifting today.",
  description,
  primaryAction,
  secondaryAction,
  primarySignedInAction,
}: Props) => {
  const _primaryAction = primaryAction || {
    text: "Sign up — it’s free",
    linkTo: generateRealmPath("business", "/signup"),
  }

  const _primarySignedInAction = primarySignedInAction || {
    text: "Go to Goody for Business",
    linkTo: generateRealmPath("plus", "/send"),
  }
  const _secondaryAction = secondaryAction || {
    text: "Book a Demo",
    linkTo: generateRealmPath("business", "/team-demo"),
  }

  return (
    <div tw="mt-14 md:mt-24 max-w-screen-md mx-auto">
      <div tw="px-4">
        <TitleContainer
          heading={heading}
          title={title}
          subtitle={description || <DefaultDescription />}
          darkerColors
        />
      </div>
      <Actions tw="mt-12">
        {!signedIn ? (
          <PrimaryAction {..._primaryAction} />
        ) : (
          <PrimaryAction {..._primarySignedInAction} />
        )}
        {showSecondaryAction && <SecondaryAction {..._secondaryAction} />}
      </Actions>
    </div>
  )
}

const Actions = tw.div`
  flex flex-col sm:flex-row
  items-center justify-center
 `

const PrimaryAction = ({ linkTo, text }: LinkAction) => (
  <PurpleGradientLink tw="w-max mb-2 sm:mb-0 sm:mr-6 text-center" to={linkTo}>
    {text}
  </PurpleGradientLink>
)

const SecondaryAction = ({ linkTo, text }: LinkAction) => (
  <WhiteBackgroundLink tw="w-48 !text-black text-center" to={linkTo}>
    {text}
  </WhiteBackgroundLink>
)

const DefaultDescription = () => (
  <>
    When you sign up for Goody for Business, you’ll get a
    <span tw="font-semibold"> $20 gift credit. </span>
    Have questions or need some gift inspiration? Book a demo with our team.
  </>
)

export default CallToAction
