import { gql } from "@apollo/client"

export const ORGANIZATION_SUBSCRIPTION_PLAN_QUERY = gql`
  query OrganizationSubscriptionPlan {
    me {
      isFullOrganizationMember
    }
    organization {
      subscription {
        isTeamPlan
      }
    }
  }
`
