import { isNil, parseInt } from "lodash-es"
import { useContext, useMemo } from "react"
import tw from "twin.macro"

import EditorialContext from "./EditorialContext"
import GiftButton from "./GoodyProductWithGiftOption/GiftButton"
import MoreFrom from "./GoodyProductWithGiftOption/MoreFrom"
import {
  Body,
  BrandName,
  Container,
  Description,
  FlexColumn,
  Image,
  Name,
  Price,
  ShippingCost,
} from "./GoodyProductWithGiftOption/styledComponents"
import Variants from "./GoodyProductWithGiftOption/Variants"
import { gtmEventForEditorialItem } from "./utils"
import { ReactComponent as Truck } from "../../assets/icons/truck.svg"
import useStoreContext from "../hooks/useStoreContext"

interface Props {
  id: string
  withGiftOption: boolean
  price?: string
}

const GoodyProductCommon = ({ id, withGiftOption, price }: Props) => {
  const editorialItem = useContext(EditorialContext)

  const { pushNewUrl, generatePdpUrl } = useStoreContext()

  const product = useMemo(() => {
    const products = withGiftOption
      ? editorialItem?.productsWithGiftOption
      : editorialItem?.products
    if (products) {
      return products.find((p) => {
        return p.id === id
      })
    }
  }, [id, editorialItem])

  if (!product || !editorialItem) {
    return null
  }

  const featuredImage =
    product?.productEditorialImages?.find(
      (productEditorialImage) =>
        productEditorialImage.editorialItemId === editorialItem.id,
    ) || product?.productImages[0]

  if (!product || !editorialItem) {
    return null
  }

  const displayPrice =
    product.isGiftCollection && !isNil(price) && parseInt(price) > 0
      ? parseInt(price)
      : product.price

  return (
    <Container>
      {featuredImage?.imageLarge && (
        <Image
          src={featuredImage.imageLarge.url}
          css={{
            aspectRatio: `${featuredImage.imageLarge.width} / ${featuredImage.imageLarge.height}`,
          }}
        />
      )}
      <Body>
        <div tw="flex">
          <div>
            <Name>{product.name}</Name>
            <BrandName>{product.brand.name}</BrandName>
          </div>
          <FlexColumn tw="text-right min-w-[80px] pl-3">
            <Price>
              {formatPrice(displayPrice, product.hasVariablePricing)}
            </Price>
            <ShippingCost>
              <Truck
                width="15"
                height="15"
                color="#808080"
                tw="inline-block mr-2"
              />
              {formatPrice(product.brand.shippingPrice ?? null) || "Free"}
            </ShippingCost>
          </FlexColumn>
        </div>
        <Description>{product.subtitle}</Description>

        {product.variants.length > 0 && <Variants product={product} />}
      </Body>

      <div tw="flex-1" />
      <StickToBottom>
        <GiftButton
          product={product}
          beforeHandleClick={() => {
            gtmEventForEditorialItem("Editorial_GiftProduct", {
              editorialItem,
              extra: { product_id: product.id },
            })

            pushNewUrl(
              generatePdpUrl({
                productSlug: product.slug,
                brandSlug: product.brand.slug,
                pathRealm: "plus",
              }),
            )
          }}
        />
        {withGiftOption && (
          <MoreFrom
            product={product}
            handleBeforeClick={() => {
              gtmEventForEditorialItem("Editorial_ClickMoreFrom", {
                editorialItem,
                extra: { product_id: product.id },
              })
            }}
          />
        )}
      </StickToBottom>
    </Container>
  )
}

export default GoodyProductCommon

const StickToBottom = tw.div`mt-auto w-full`

const formatPrice = (
  cents: number | null,
  variablePricing?: boolean | null,
) => {
  if (!cents) return null

  const plusSign = variablePricing ? "+" : ""

  return `$${cents / 100}${plusSign}`
}
