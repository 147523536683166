import { useEffect } from "react"
import { useExperiment } from "statsig-react"

import { TopUpPaymentSelectorForm } from "@/send/components/topUpSend/TopUpPaymentSelectorForm"
import { useTopUpExperiment } from "@/send/hooks/topUpExperiment"

export const TopUpExperiment = () => {
  const { config, isLoading } = useExperiment("send_-_top_up_balance")
  const displayTopUpExperiment = config.get<boolean>("display_top_up", false)

  const { setExperimentData, canDisplayTopUpUI } = useTopUpExperiment()

  useEffect(() => {
    setExperimentData({
      displayTopUpExperiment,
      isLoading,
    })
  }, [displayTopUpExperiment, isLoading])

  if (!isLoading && canDisplayTopUpUI && displayTopUpExperiment) {
    return <TopUpPaymentSelectorForm />
  }

  return null
}
