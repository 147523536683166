import { isNil } from "lodash-es"
import { useMemo, useState } from "react"

import { useGlobalState } from "@/common/GlobalState"
import { createHookContext } from "@/common/hooks/createHookContext"
import {
  CustomStoreFullFragment,
  MultipleSelectModeEnum,
} from "@/types/graphql-types"

export interface CustomStoreType {
  containsAlcohol: boolean
  containsGiftCards: boolean
  internationalShippingGlobalRelayEnabled: boolean
}

const useCustomStoreLocal = () => {
  const [formFields, setFormFields] = useGlobalState("currentCustomStore")

  const [unfilteredOptions, setUnfilteredOptions] = useState<
    CustomStoreFullFragment["optionList"] | null
  >(null)

  const [
    internationalShippingGiftCardsEnabled,
    setInternationalShippingGiftCardsEnabled,
  ] = useState(false)

  const [
    internationalShippingGlobalRelayEnabled,
    setInternationalShippingGlobalRelayEnabled,
  ] = useState(false)

  const multipleSelectMode = formFields.multipleSelectMode
  const multipleSelectPrice = formFields.multipleSelectPrice
  const multipleSelectCount = formFields.multipleSelectCount

  const setMultipleSelectMode = (
    multipleSelectMode: MultipleSelectModeEnum,
  ) => {
    setFormFields((oldFormFields) => ({
      ...oldFormFields,
      multipleSelectMode,
    }))

    if (
      multipleSelectMode === MultipleSelectModeEnum.max_price ||
      multipleSelectMode === MultipleSelectModeEnum.item_count
    ) {
      setMultipleSelectAttributeHasChanged(true)
    }
  }

  const setMultipleSelectCount = (multipleSelectCount: number) => {
    setFormFields((oldFormFields) => ({
      ...oldFormFields,
      multipleSelectCount,
    }))

    if (multipleSelectMode !== MultipleSelectModeEnum.disabled) {
      setMultipleSelectAttributeHasChanged(true)
    }
  }

  const setMultipleSelectPrice = (multipleSelectPrice: number) => {
    setFormFields((oldFormFields) => ({
      ...oldFormFields,
      multipleSelectPrice,
    }))
  }

  const [
    multipleSelectAttributeHasChanged,
    setMultipleSelectAttributeHasChanged,
  ] = useState(false)

  const [displayMultipleSelectTooltip, setDisplayMultipleSelectTooltip] =
    useState(false)

  const containsAlcohol: boolean = !!unfilteredOptions?.some(
    (option) => option?.giftOption?.isAlcohol || option.product.isAlcohol,
  )

  const containsGiftCard: boolean = !!unfilteredOptions?.some(
    (option) => !isNil(option.giftCardAmount),
  )

  const hasRestrictedProductOrSetting =
    containsGiftCard ||
    containsAlcohol ||
    internationalShippingGiftCardsEnabled ||
    internationalShippingGlobalRelayEnabled

  const productOverMaximumPrice = useMemo(() => {
    if (isNil(multipleSelectPrice) || !unfilteredOptions) {
      return null
    }

    // save all products over the multiple select price
    const productsOverMultipleSelectPrice = unfilteredOptions.flatMap(
      (option) => {
        if (option.giftOption) {
          return option.giftOption.products.filter(
            (product) => product.priceWithShipping > multipleSelectPrice,
          )
        } else if (option.priceMaxWithShipping > multipleSelectPrice) {
          return option.product
        } else {
          return undefined
        }
      },
    )

    // return the most expensive product
    return productsOverMultipleSelectPrice.sort(
      (product1, product2) =>
        (product2?.priceWithShipping ?? 0) - (product1?.priceWithShipping ?? 0),
    )[0]
  }, [unfilteredOptions, multipleSelectPrice])

  const disableMultipleSelection = () => {
    setMultipleSelectMode(MultipleSelectModeEnum.disabled)
    setMultipleSelectCount(1)
  }

  return {
    unfilteredOptions,
    setUnfilteredOptions,
    containsAlcohol,
    containsGiftCard,
    internationalShippingGiftCardsEnabled,
    setInternationalShippingGiftCardsEnabled,
    internationalShippingGlobalRelayEnabled,
    setInternationalShippingGlobalRelayEnabled,
    hasRestrictedProductOrSetting,
    multipleSelectMode,
    setMultipleSelectMode,
    multipleSelectCount,
    setMultipleSelectCount,
    multipleSelectPrice,
    setMultipleSelectPrice,
    productOverMaximumPrice,
    multipleSelectAttributeHasChanged,
    setMultipleSelectAttributeHasChanged,
    displayMultipleSelectTooltip,
    setDisplayMultipleSelectTooltip,
    disableMultipleSelection,
  }
}

export const { Provider: CustomStoreProvider, useHook: useCustomStore } =
  createHookContext("useCustomStore", useCustomStoreLocal)
