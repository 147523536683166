import React from "react"
import { Link, useHistory, useLocation } from "react-router-dom"
import tw, { styled } from "twin.macro"

import SignInIconImg from "../../../assets/icons/signin.svg"
import { generateRealmPath } from "../../../common/realm"
import WhiteBackgroundLink from "../../../common/WhiteBackgroundLink"
import CartButton from "../../shared/CartButton"

interface Props {
  isBusiness: boolean
}

interface HeaderCtaProps {
  signedIn: boolean
  onLogoutClick: () => void
  onLoginClick: () => void
}

const MenuCta = ({
  isBusiness,
  signedIn,
  onLogoutClick,
  onLoginClick,
}: Props & HeaderCtaProps) => {
  const history = useHistory()
  const goToBusinessSignIn = () =>
    history.push(generateRealmPath("business", "/signin"))
  const location = useLocation()
  const isSignup = location.pathname === "/business/signup"

  return (
    <CtaContainer>
      <div tw="w-full flex flex-col lg:flex-row gap-5 lg:gap-2 lg:items-center lg:text-[14.5px] xl:text-[17px]">
        {isBusiness ? (
          <BusinessHeaderCta
            signedIn={signedIn}
            onLoginClick={goToBusinessSignIn}
            onLogoutClick={onLogoutClick}
          />
        ) : (
          <ConsumerHeaderCta
            signedIn={signedIn}
            onLoginClick={onLoginClick}
            onLogoutClick={onLogoutClick}
          />
        )}
        {!isSignup && <CartButton />}
      </div>
    </CtaContainer>
  )
}

const CtaContainer = tw.div`
  flex flex-col lg:flex-row items-center whitespace-nowrap
  px-6 lg:px-0 pt-0 pb-8 lg:p-0 lg:gap-5 xl:gap-6 2xl:gap-7
`

const ConsumerHeaderCta: React.FC<HeaderCtaProps> = ({
  signedIn,
  onLogoutClick,
  onLoginClick,
}) => (
  <>
    <div tw="flex gap-4">
      <SignInOrOutButton
        signedIn={signedIn}
        onLoginClick={onLoginClick}
        onLogoutClick={onLogoutClick}
      />
      <PrimaryLink to={generateRealmPath("consumer", "/send")}>
        Send a gift
      </PrimaryLink>
    </div>
  </>
)

const BusinessHeaderCta: React.FC<HeaderCtaProps> = ({
  signedIn,
  onLogoutClick,
  onLoginClick,
}) => {
  const location = useLocation()
  const isSignup = location.pathname === "/business/signup"

  return (
    <>
      <div tw="flex gap-5 lg:gap-2">
        {!isSignup && (
          <>
            <SignInOrOutButton
              signedIn={signedIn}
              onLoginClick={onLoginClick}
              onLogoutClick={onLogoutClick}
            />
            <PrimaryLink to={generateRealmPath("plus", "/send")}>
              Send a gift
            </PrimaryLink>
          </>
        )}
      </div>
      {!isSignup && (
        <HeaderWhiteBackgroundLink
          to={generateRealmPath("business", "/book-a-call")}
        >
          Book a call
        </HeaderWhiteBackgroundLink>
      )}
    </>
  )
}

const LinearGradientLink = styled(Link)`
  background: linear-gradient(to right, #ae72ff -15%, #6b62ff 143%);
`
const PrimaryLink = tw(LinearGradientLink)`
  text-white font-medium text-center
  hover:opacity-80 active:opacity-90 transition-opacity
  flex-1 inline-block rounded-lg
  py-3 lg:px-5
`

const OptionalText = styled.span`
  ${tw`hidden xl:inline`};

  @media (min-width: 400px) {
    ${tw`inline`}
  }

  @media (min-width: 1024px) {
    ${tw`hidden`}
  }
`

const HeaderWhiteBackgroundLink = tw(WhiteBackgroundLink)`
  text-center py-3 px-5
`

const SignInOrOutButton: React.FC<HeaderCtaProps> = ({
  signedIn,
  onLogoutClick,
  onLoginClick,
}) => (
  <SignInOrOutButtonStyle onClick={signedIn ? onLogoutClick : onLoginClick}>
    <img alt="Sign In icon" tw="mr-2 lg:hidden xl:block" src={SignInIconImg} />
    {signedIn ? "Sign Out" : "Sign In"}
  </SignInOrOutButtonStyle>
)

const SignInOrOutButtonStyle = tw.button`
  text-[#7d68f6] font-medium focus:outline-none rounded-lg
  flex items-center justify-center flex-1
  py-1 lg:px-3 xl:px-4
  transition-colors bg-primary-050 lg:bg-white hover:lg:bg-primary-050
  min-h-[3rem]
  lg:hidden
`

export default MenuCta
