import { useGlobalState } from "../../../common/GlobalState"

export default function useConsumerSignUp() {
  const [fields, setFields] = useGlobalState("consumerSignUpFields")

  const { firstName, lastName, email, phone } = fields

  const setField = (field: keyof typeof fields, val: string) => {
    setFields({
      ...fields,
      [field]: val,
    })
  }

  const setFirstName = (val: string) => setField("firstName", val)
  const setLastName = (val: string) => setField("lastName", val)
  const setEmail = (val: string) => setField("email", val)
  const setPhone = (val: string) => setField("phone", val)

  return {
    firstName,
    lastName,
    email,
    phone,
    setFirstName,
    setLastName,
    setEmail,
    setPhone,
  }
}
