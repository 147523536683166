import React from "react"
import tw, { styled } from "twin.macro"

export type Variant = "blue" | "primary" | "gray" | "lightBlue"

interface BadgeProps {
  className?: string
  variant?: Variant
}

const Badge: React.FC<BadgeProps> = ({
  children,
  className,
  variant = "gray",
  ...props
}) => {
  return (
    <Container className={className} variant={variant} {...props}>
      {children}
    </Container>
  )
}

const Container = styled.div<{ variant: Variant }>(({ variant }) => [
  tw`bg-gray-50 inline-block px-2 py-1 rounded-full text-gray-500 text-sm leading-4`,
  variant === "blue" && tw`bg-blue-500 text-white`,
  variant === "gray" && tw`bg-gray-100 text-gray-400`,
  variant === "primary" && tw`bg-primary-500 text-white`,
  variant === "lightBlue" && tw`bg-blue-100 text-blue-500`,
])

export default Badge
