import tw, { styled } from "twin.macro"

import { isBlank } from "../../common/format"

interface Props {
  value?: string | null
  onChange: (e: string) => void
  unstyled?: boolean
}

export default function RecipientStateInput({
  value,
  onChange,
  unstyled,
}: Props) {
  return (
    <SelectContainer
      $valueIsBlank={isBlank(value)}
      $unstyled={unstyled || false}
      value={value || ""}
      onChange={(e) => onChange(e.target.value)}
      className="data-hj-suppress ph-no-capture fs-exclude"
    >
      <option value="">State</option>
      <option value="AL">Alabama</option>
      <option value="AK">Alaska</option>
      <option value="AZ">Arizona</option>
      <option value="AR">Arkansas</option>
      <option value="CA">California</option>
      <option value="CO">Colorado</option>
      <option value="CT">Connecticut</option>
      <option value="DC">District of Columbia</option>
      <option value="DE">Delaware</option>
      <option value="FL">Florida</option>
      <option value="GA">Georgia</option>
      <option value="HI">Hawaii</option>
      <option value="ID">Idaho</option>
      <option value="IL">Illinois</option>
      <option value="IN">Indiana</option>
      <option value="IA">Iowa</option>
      <option value="KS">Kansas</option>
      <option value="KY">Kentucky</option>
      <option value="LA">Louisiana</option>
      <option value="ME">Maine</option>
      <option value="MD">Maryland</option>
      <option value="MA">Massachusetts</option>
      <option value="MI">Michigan</option>
      <option value="MN">Minnesota</option>
      <option value="MS">Mississippi</option>
      <option value="MO">Missouri</option>
      <option value="MT">Montana</option>
      <option value="NE">Nebraska</option>
      <option value="NV">Nevada</option>
      <option value="NH">New Hampshire</option>
      <option value="NJ">New Jersey</option>
      <option value="NM">New Mexico</option>
      <option value="NY">New York</option>
      <option value="NC">North Carolina</option>
      <option value="ND">North Dakota</option>
      <option value="OH">Ohio</option>
      <option value="OK">Oklahoma</option>
      <option value="OR">Oregon</option>
      <option value="PA">Pennsylvania</option>
      <option value="RI">Rhode Island</option>
      <option value="SC">South Carolina</option>
      <option value="SD">South Dakota</option>
      <option value="TN">Tennessee</option>
      <option value="TX">Texas</option>
      <option value="UT">Utah</option>
      <option value="VT">Vermont</option>
      <option value="VA">Virginia</option>
      <option value="WA">Washington</option>
      <option value="WV">West Virginia</option>
      <option value="WI">Wisconsin</option>
      <option value="WY">Wyoming</option>
    </SelectContainer>
  )
}

const SelectContainer = styled.select<{
  $valueIsBlank: boolean
  $unstyled: boolean
}>`
  ${tw`w-full flex-1 p-4 py-3 bg-gray-100 border border-transparent rounded-lg focus:outline-none focus:ring ring-blue-500 ring-opacity-30`};

  ${(props) =>
    props.$valueIsBlank &&
    // Ensure placeholder value has placeholder color
    `
      color: #9DA3AE;
      
      option {
        color: #000000;
      }
    `};

  ${(props) => props.$unstyled && tw`bg-transparent border-none p-0`}
`
