import { loadVGSCollect } from "@vgs/collect-js"
import React, { useEffect, useRef, useState } from "react"
import tw, { styled } from "twin.macro"

import { getEnvVars } from "../environment"
import { imageForCardBrand } from "../payment"
import { PaymentMethod } from "../PaymentMethodsManager"
import { PurpleGradientButton } from "../PurpleGradientLink"

interface Props {
  paymentMethod: PaymentMethod | null
  onSuccess: () => void
}

// For alcohol orders, a payment method must be on file with the external licensee.
// If not, we need to ask for the CVC again, send it through VGS, and then set
// up the payment method (identified by the token). If successful, call
// onSuccess() to enable the payment method to be used with the gift.
export default function CVCEntry({ paymentMethod, onSuccess }: Props) {
  const [vgsReady, setVGSReady] = useState(false)
  const vgsFormRef = useRef<any | null>(null)
  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    ;(async function () {
      const collect = (await loadVGSCollect({
        vaultId: getEnvVars().vgsVaultId,
        environment: getEnvVars().name === "production" ? "live" : "sandbox",
        version: "2.12.0",
      })) as any

      const vgsForm = collect.init()
      vgsForm.useCname(getEnvVars().vgsCname)

      // Use VGS Satellite in development.
      if (getEnvVars().name === "development") {
        vgsForm.connectSatellite(9098)
      }

      vgsFormRef.current = vgsForm

      setVGSReady(true)
    })()

    return () => {}
  }, [])

  useEffect(() => {
    if (!vgsReady) {
      return
    }

    const vgsForm = vgsFormRef.current

    const fieldElements = []

    const cardCvc = vgsForm.field("#card-cvc-reentry", {
      type: "card-security-code",
      name: "card_cvc",
      placeholder: "CVC",
      validations: ["required", "validCardSecurityCode"],
      autoComplete: "cc-csc",
      classes: classes,
      css: css,
    })

    fieldElements.push({
      name: "card_cvc",
      field: cardCvc,
      displayName: "CVC",
      container: "#card-cvc-reentry",
    })

    vgsForm.on("enterPress", () => {
      submit()
    })
  }, [vgsReady])

  const submit = () => {
    const vgsForm = vgsFormRef.current

    if (!vgsForm) {
      return
    }

    if (!paymentMethod?.token) {
      alert("Payment method is not valid. Please contact support.")
      return
    }

    setSubmitting(true)

    const endpoint = "/vgs/inbound/update_alcohol_with_cvc"

    vgsForm.submit(
      endpoint,
      {
        data: {
          token: paymentMethod.token,
        },
      },
      async function (status: any, data: any) {
        // An interim card token should be returned
        const ok = data.ok
        const error = data.error

        if (ok) {
          onSuccess()
        } else {
          alert(error || "An error occurred. Please contact support.")
        }

        setSubmitting(false)
      },
      (error: any) => {
        const error_output = []

        // Collect errors in the weird format that they're returned
        for (const el in error) {
          error_output.push(error[el].errorMessages[0])
        }

        // Join them with newlines
        alert(error_output.join("\n"))

        setSubmitting(false)
      },
    )
  }

  if (!paymentMethod) {
    return null
  }

  return (
    <div>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          submit()
        }}
      >
        <div
          tw="flex flex-row items-center pt-4"
          className="data-hj-suppress ph-no-capture fs-exclude"
        >
          <SecureField id="card-cvc-reentry" />
          <div tw="flex flex-row items-center">
            <div tw="pl-4 pr-2 text-gray-400">for</div>
            {imageForCardBrand(paymentMethod.cardBrand)}
            <div
              tw="pl-2 tabular-nums"
              className="data-hj-suppress ph-no-capture fs-exclude"
            >
              {paymentMethod.last4}
            </div>
          </div>
        </div>
        <div tw="h-4" />
        <PurpleGradientButton tw="w-full py-3" disabled={submitting}>
          Submit
        </PurpleGradientButton>
      </form>
    </div>
  )
}

const css = {
  fontFamily: '"Helvetica Neue", Helvetica',
  boxSizing: "border-box",
  lineHeight: "1.5em",
  fontSize: "16px",
  border: "none",
  color: "#31325F",
  width: "100%",
  height: "48px",
  "&::placeholder": {
    color: "#94a3b8",
  },
  "&.invalid.touched": {
    color: "rgb(201, 31, 36)",
  },
}

const classes = {
  focused: "SecureField--focused",
  valid: "SecureField--valid",
}

const SecureField = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  height: 52px;
  width: 20%;

  iframe {
    height: 100%;
  }

  ${tw`border border-gray-300 px-4 rounded-lg`}
`
