import { Dispatch, SetStateAction, useEffect } from "react"
import tw, { styled } from "twin.macro"

import {
  DefaultDesktopHeaderURL,
  DefaultMobileHeaderURL,
  HeaderImageProps,
} from "./common"
import CountryDropdown from "./CountryDropdown"
import { CustomStoreOptionPreview } from "./CustomStoreOptionPreview"
import {
  Countries,
  CountriesLongName,
} from "../giftOptionFilters/internationalShipping/InternationalShipping"

import { formatPrice } from "@/common/format"
import { MultipleSelectionBanner } from "@/store/custom/MultipleGiftSelection/MultipleSelectionBanner"
import {
  BrandValuesDisplay,
  CustomStoreFullFragment,
  MultipleSelectModeEnum,
} from "@/types/graphql-types"

interface Props {
  storeName: string | null
  storeSubtitle: string | null
  desktopImage: HeaderImageProps | null
  mobileImage: HeaderImageProps | null
  customStoreOptions?: CustomStoreFullFragment["optionList"]
  logoUrl: string | null | undefined
  setSelectedShippingCountry: Dispatch<SetStateAction<Countries>>
  selectedShippingCountry: Countries
  brandValuesDisplay: BrandValuesDisplay
  multipleSelectMode?: MultipleSelectModeEnum
  multipleSelectCount?: number
  multipleSelectPrice?: number
}

export const CustomStoreView = ({
  storeName,
  storeSubtitle,
  desktopImage,
  mobileImage,
  customStoreOptions,
  logoUrl,
  selectedShippingCountry,
  setSelectedShippingCountry,
  brandValuesDisplay,
  multipleSelectMode,
  multipleSelectCount,
  multipleSelectPrice,
}: Props) => {
  // Setting to overflow visible allows for StorePreview to be sticky
  useEffect(() => {
    document.documentElement.classList.add("overflow-visible")

    return () => document.documentElement.classList.remove("overflow-visible")
  }, [])

  const shouldDisplayMultipleSelectBanner =
    (multipleSelectMode === MultipleSelectModeEnum.item_count &&
      multipleSelectCount) ||
    (multipleSelectMode === MultipleSelectModeEnum.max_price &&
      multipleSelectPrice)

  const multipleSelectMessage = () => {
    if (multipleSelectMode === MultipleSelectModeEnum.item_count) {
      return "You can select " + multipleSelectCount + " gifts"
    }

    if (
      multipleSelectMode === MultipleSelectModeEnum.max_price &&
      multipleSelectPrice
    ) {
      return `You have ${formatPrice(
        multipleSelectPrice,
      )} available to select your gifts`
    }
  }

  return (
    <>
      <Container>
        <CountryDropdown
          selectedShippingCountry={selectedShippingCountry}
          setSelectedShippingCountry={setSelectedShippingCountry}
        />

        <StorePreview>
          <div tw="relative">
            <img
              tw="object-cover rounded-t-lg w-auto hidden lg:flex relative"
              src={desktopImage?.url ?? DefaultDesktopHeaderURL}
              alt="Desktop store header"
            />
            {!desktopImage?.url && !!logoUrl && (
              <img
                alt="company logo"
                src={logoUrl}
                tw="absolute max-w-[200px] max-h-[72px] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 hidden lg:flex"
              />
            )}
          </div>
          <div tw="relative">
            <img
              tw="object-cover rounded-t-lg w-auto flex lg:hidden relative"
              src={mobileImage?.url ?? DefaultMobileHeaderURL}
              alt="Mobile store header"
            />
            {!mobileImage?.url && !!logoUrl && (
              <img
                alt="company logo"
                src={logoUrl}
                tw="absolute max-w-[200px] max-h-[72px] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex lg:hidden"
              />
            )}
          </div>
          <div tw="w-full flex flex-col justify-center items-center py-9 px-4">
            <CustomStoreName tw="pb-3 text-2xl font-medium text-center">
              {storeName}
            </CustomStoreName>

            {shouldDisplayMultipleSelectBanner ? (
              <div tw="w-full mt-10">
                <MultipleSelectionBanner
                  message={multipleSelectMessage()}
                  secondaryMessage={storeSubtitle}
                />
              </div>
            ) : (
              <div tw="text-gray-500 text-center mt-3">{storeSubtitle}</div>
            )}
          </div>
          {customStoreOptions?.length === 0 && (
            <div tw="grid p-20 justify-items-center text-gray-450">
              Sorry, no gifts are available for{" "}
              {CountriesLongName[selectedShippingCountry]}.
            </div>
          )}
          <div tw="grid grid-cols-1 p-6 lg:px-24 xl:px-12 xl:grid-cols-2 justify-items-stretch gap-12">
            {customStoreOptions?.map((customStoreOption) => (
              <CustomStoreOptionPreview
                key={customStoreOption.id}
                customStoreOption={customStoreOption}
                showBrandValues={
                  brandValuesDisplay === BrandValuesDisplay.options_and_list
                }
              />
            ))}
          </div>
        </StorePreview>
      </Container>
    </>
  )
}

const Container = styled.div`
  ${tw`xl:justify-self-end sticky top-8 mt-24 pb-12 w-full justify-self-start`}
`

const StorePreview = styled.div`
  ${tw`xl:justify-self-end overflow-y-auto sticky top-8 mt-4 pb-12 border border-gray-150 box-border rounded-xl w-full justify-self-start`}

  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);

  height: calc(100vh - 6rem);
`

const CustomStoreName = styled.div`
  width: inherit;
  overflow-wrap: break-word;
`

export default CustomStoreView
