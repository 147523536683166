import React from "react"
import tw, { styled } from "twin.macro"

import { CheckCircleBlue } from "../../assets/icons"
import PurpleGradientLink from "../../common/PurpleGradientLink"

const Section = styled.div`
  ${tw`flex flex-col-reverse lg:flex-row mb-12 md:mb-24`}
`

const BulletPoint = ({ children }: { children: React.ReactNode }) => {
  return (
    <div tw="flex mb-1">
      <CheckCircleBlue style={{ marginTop: "0.325rem" }} />
      <p tw="flex-1 ml-3 mb-3">{children}</p>
    </div>
  )
}

const CtaBtn = styled(PurpleGradientLink)`
  ${tw`font-bold mt-10 lg:inline-block w-full md:w-auto p-3 md:py-4 px-8`}
`

const Paragraph = styled.div`
  ${tw`pt-5 text-gray-600 text-xl leading-9`}
`

const Subheading = styled.h1`
  ${tw`text-base md:text-xl mb-2 md:mb-4 font-semibold`}
  color: #A981FF;
`

const Header = styled.span`
  ${tw`pt-4 text-5xl font-bold`}
  font-size: 2.75rem;
  line-height: 3.5rem;

  @media (min-width: 1024px) {
    line-height: 3.5rem;
  }
`

const BulletPoints = ({ bulletPoints }: { bulletPoints: string[] }) => {
  return (
    <div tw="pt-5 text-gray-600 text-xl leading-normal">
      {bulletPoints.map((bulletPoint, i) => (
        <BulletPoint key={i}>{bulletPoint}</BulletPoint>
      ))}
    </div>
  )
}

export {
  Paragraph,
  Section,
  BulletPoint,
  CtaBtn,
  Subheading,
  BulletPoints,
  Header,
}
