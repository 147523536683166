import React, { useEffect } from "react"
import Modal from "react-modal"
import tw, { styled } from "twin.macro"

import IconButton from "@/common/components/IconButton"
import StyledCloseIcon from "@/common/components/StyledCloseIcon"
import { getEnvVars } from "@/common/environment"
import { fullScreenModalStyle } from "@/common/modal"
import { Loader } from "@/common/UI"
import { useGiftPreview } from "@/send/hooks/giftPreview"
import GiftPreview from "@/send/images/gift-preview.svg"

export const GiftPreviewModal = () => {
  const { modalOpen, setModalClosed, loading } = useGiftPreview()

  const url = `${getEnvVars().apiUrl}/gift-preview`

  useEffect(() => {
    const eventListener = (event: MessageEvent) => {
      if (
        event.origin === getEnvVars().apiUrl &&
        event.data.previewModalClose
      ) {
        setModalClosed()
      }
    }

    if (modalOpen) {
      window.addEventListener("message", eventListener)
    }

    return () => window.removeEventListener("message", eventListener)
  }, [modalOpen, setModalClosed])

  return (
    <Modal
      isOpen={modalOpen}
      closeTimeoutMS={500}
      onRequestClose={() => setModalClosed()}
      onAfterClose={() => {}}
      shouldCloseOnOverlayClick={true}
      style={fullScreenModalStyle}
      shouldReturnFocusAfterClose={false}
    >
      <div
        className={"modal-content-translated"}
        tw={"w-full h-full flex flex-col items-stretch"}
        onClick={() => setModalClosed()}
      >
        <div tw={"h-18 flex flex-col"}>
          <div tw={"flex-1 flex flex-row items-center justify-center"}>
            <GiftPreviewTitle onClick={(e) => e.stopPropagation()}>
              <img src={GiftPreview} alt={"Gift preview"} />
              Gift Preview
            </GiftPreviewTitle>
            <div tw="fixed right-3 cursor-pointer">
              <IconButton>
                <StyledCloseIcon />
              </IconButton>
            </div>
          </div>
        </div>
        <Divider />
        <div tw={"overflow-auto flex-1 flex items-stretch flex-col bg-white"}>
          {loading ? (
            <div
              tw={
                "flex-1 flex flex-row items-center justify-center w-full h-full"
              }
            >
              <Loader />
            </div>
          ) : (
            <iframe title="Preview" src={url} tw="w-full h-full flex-1" />
          )}
        </div>
      </div>
    </Modal>
  )
}

const Divider = styled.div`
  ${tw`bg-white h-[3px]`}

  filter: drop-shadow(0px 0px 48px rgba(0, 0, 0, .05)) drop-shadow(0px -1px 4px rgba(0, 0, 0, .05));
`

const GiftPreviewTitle = styled.div`
  ${tw`py-2 px-3 gap-3 flex flex-row items-center justify-center bg-white text-primary-new-600 rounded-xl font-medium text-xl`}

  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, .03)) drop-shadow(0px 1px 4px rgba(0, 0, 0, .05));
`
