import { gql, useMutation } from "@apollo/client"
import React, { MouseEvent } from "react"
import tw, { styled } from "twin.macro"

import { ReactComponent as AlertTriangle } from "../../assets/icons/alert-triangle.svg"
import { ReactComponent as ClockIconRed } from "../../assets/icons/clock-red.svg"
import AuthorizeGmailModal, {
  useAuthorizeGmailModal,
} from "../../common/AuthorizeGmailModal"

import { Track_GiftBatchesQuery } from "@/types/graphql-types"

interface Props {
  giftBatch: any
  variant: "1" | "2"
  onAuthorizationGranted?: () => void
}

const GiftBatchErrorMessage = ({
  giftBatch,
  variant,
  onAuthorizationGranted,
}: Props) => {
  const errorMessage =
    giftBatch.failureMessages[giftBatch.failureMessages.length - 1]

  const message = resolveMessage(errorMessage.message)

  if (variant === "1") {
    return (
      <Variant1
        errorMessage={errorMessage}
        message={message}
        giftBatch={giftBatch}
        onAuthorizationGranted={onAuthorizationGranted}
      />
    )
  }

  if (variant === "2") {
    return (
      <Variant2
        errorMessage={errorMessage}
        message={message}
        giftBatch={giftBatch}
        onAuthorizationGranted={onAuthorizationGranted}
      />
    )
  }

  return null
}

interface IVariant {
  errorMessage: any
  message: string
  giftBatch: any
  onAuthorizationGranted?: () => void
}

const stopPropagation = (e: MouseEvent) => {
  e.stopPropagation()
  e.preventDefault()
}

const Variant1 = ({
  errorMessage,
  message,
  giftBatch,
  onAuthorizationGranted,
}: IVariant) => {
  return (
    <div tw="flex-1 lg:flex-5 p-5 cursor-default" onClick={stopPropagation}>
      <div tw="rounded-lg overflow-hidden cursor-default">
        <div tw="p-5" css={{ backgroundColor: "rgba(235, 87, 87, 0.15)" }}>
          <div tw="flex flex-col items-center">
            <div tw="flex justify-center items-center mb-1 mr-3">
              <ClockIconRed />
            </div>
            <div tw="font-medium mb-4 mr-3">
              <div tw="" css={{ color: "#EB5757" }}>
                {errorMessage.title}
              </div>
            </div>
            <div tw="text-center">
              <div tw="mt-3 px-3">
                <div style={{ whiteSpace: "break-spaces" }}>
                  {message}
                  {isGmailUnauthorized(errorMessage.message) && (
                    <AuthorizeGmailAndTryAgainButton
                      onAuthorizationGranted={onAuthorizationGranted}
                      giftBatch={giftBatch}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const Variant2 = ({
  errorMessage,
  message,
  giftBatch,
  onAuthorizationGranted,
}: IVariant) => {
  return (
    <div tw="flex rounded-lg overflow-hidden" onClick={stopPropagation}>
      <div tw="w-16 grid" style={{ background: "#F87171" }}>
        <AlertTriangle tw="m-auto justify-self-center" />
      </div>
      <div tw="flex-grow" style={{ background: "#FEF2F2" }}>
        <div tw="p-5 cursor-default">
          <div tw="flex">
            <div tw="flex-grow">
              <div tw="font-medium" css={{ color: "#EB5757" }}>
                {errorMessage.title}
              </div>
              {message}
            </div>
            <div tw="flex-none w-60 h-full">
              {isGmailUnauthorized(errorMessage.message) && (
                <AuthorizeGmailAndTryAgainButton
                  onAuthorizationGranted={onAuthorizationGranted}
                  giftBatch={giftBatch}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const GIFT_BATCH_RETRY_FAILED_EMAIL_DELIVERY_QUERY = gql`
  mutation GiftBatch_RetryFailedEmailDeliver($giftBatchId: ID!) {
    giftBatchRetryFailedEmailDelivery(giftBatchId: $giftBatchId) {
      ok
    }
  }
`

// Buttons
interface IAuthorizeGmailAndTryAgainButton {
  giftBatch: NonNullable<Track_GiftBatchesQuery["workspace"]>["giftBatches"][0]
  onAuthorizationGranted?: () => void
}

const AuthorizeGmailAndTryAgainButton = ({
  giftBatch,
  onAuthorizationGranted,
}: IAuthorizeGmailAndTryAgainButton) => {
  const { setIsOpen } = useAuthorizeGmailModal()

  const [retryFailedEmailsDelivery] = useMutation(
    GIFT_BATCH_RETRY_FAILED_EMAIL_DELIVERY_QUERY,
  )

  const handleClick = () => {
    setIsOpen(true)
  }

  const onSuccess = async () => {
    await retryFailedEmailsDelivery({
      variables: { giftBatchId: giftBatch.id },
    })

    onAuthorizationGranted && onAuthorizationGranted()
  }

  return (
    <>
      <ActionButton
        productUnavailable
        onClick={handleClick}
        tw="cursor-pointer m-auto mt-2"
      >
        Authorize Gmail & try again
      </ActionButton>
      <AuthorizeGmailModal onSuccess={onSuccess} />
    </>
  )
}

const ActionButton = styled.div<{
  productUnavailable: boolean
}>(({ productUnavailable }) => [
  tw`flex flex-row items-center justify-center flex-grow px-2 py-1 mt-2 mb-4 rounded-lg cursor-default`,
  !productUnavailable && "border: 1px solid rgba(189, 142, 0, 0.25);",
  productUnavailable && "border: 1px solid rgba(235, 87, 87, 0.25);",
  `color: ${productUnavailable ? "#EB5757" : "#BD8E00"};`,
])

// Helpers
const isGmailUnauthorized = (message: string) =>
  message === "gmail_invalid_grant"

const resolveMessage = (message: string) => {
  if (isGmailUnauthorized(message)) {
    return "Sending from Gmail failed due to “not authorized”."
  } else {
    return "An unknown error has occurred"
  }
}

export default GiftBatchErrorMessage
