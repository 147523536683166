import "twin.macro"
import React, { Suspense } from "react"

import { AnimationContainer, BarContainer, FullContainer } from "./common"

const HowItWorksAnimation = React.lazy(() => import("./HowItWorksAnimation"))

// Code split the animation component to reduce the initial bundle size
// This split includes the SVGator player SDK and the SVG animations, which are large
// The loader component will show a placeholder of the same size of the animations
// when loading
export default function HowItWorksAnimationLoader() {
  const Placeholder = () => (
    <FullContainer>
      <AnimationContainer tw="bg-gray-050 rounded-lg"></AnimationContainer>
      <BarContainer tw="h-0"></BarContainer>
      <AnimationContainer tw="bg-gray-050 rounded-lg"></AnimationContainer>
    </FullContainer>
  )

  return (
    <div>
      <Suspense fallback={<Placeholder />}>
        <HowItWorksAnimation />
      </Suspense>
    </div>
  )
}
