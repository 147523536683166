import React from "react"
import Modal from "react-modal"
import tw, { styled } from "twin.macro"

import { BagGradient } from "../../assets/icons"
import { useCartAdd } from "../../store/CartAddContext"
import GradientButton, { buttonStyle } from "../GradientButton"
import { topModalStyle } from "../modal"

const CartReplaceModal = () => {
  const {
    cartReplaceModalOpen,
    cartReplaceModalExplanation,
    onAddItemCancelled,
    onAddItemConfirmed,
    cartItemName,
  } = useCartAdd()

  return (
    <Modal
      isOpen={cartReplaceModalOpen}
      closeTimeoutMS={500}
      onRequestClose={onAddItemCancelled}
      onAfterClose={() => {}}
      shouldCloseOnOverlayClick={true}
      style={topModalStyle}
    >
      <div
        className="modal-content modal-content-wide"
        tw={
          "flex flex-col bg-white text-center w-full items-center p-6 overflow-hidden"
        }
      >
        <GradientBackground />
        <LogoContainer>
          <BagGradient />
        </LogoContainer>
        <div tw={"font-medium text-2xl mt-6 max-w-[442px]"}>
          This product can’t be combined with the other products in your bag.
        </div>
        <div tw={"mt-6 text-lg max-w-[441px]"}>
          {cartReplaceModalExplanation}
        </div>
        <div tw={"text-lg mt-6"}>
          Do you want to replace your bag with
          <br />
          <span tw={"font-medium"}>{cartItemName}</span>
        </div>
        <div tw={"flex flex-row gap-6 mt-8 ml-3 mb-3 mr-3 self-stretch"}>
          <button
            onClick={onAddItemCancelled}
            css={[buttonStyle, "height: 52px;"]}
            tw={
              "flex-1 text-base text-gray-500 text-center justify-center font-normal bg-white border border-gray-200"
            }
          >
            No, go back
          </button>
          <GradientButton
            onClick={onAddItemConfirmed}
            tw={"flex-1 text-center text-base justify-center"}
            css={"height: 52px;"}
          >
            Yes, continue
          </GradientButton>
        </div>
      </div>
    </Modal>
  )
}

const LogoContainer = styled.div`
  ${tw`w-12 h-12 rounded-full flex items-center justify-center`}

  background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.51) 100%);
  box-shadow:
    0px 3.39823px 13.5929px rgba(35, 0, 108, 0.06),
    inset 0px -3.39823px 6.79646px rgba(47, 0, 146, 0.02),
    inset 0px -0.849558px 1.69912px rgba(33, 0, 103, 0.02);
`

const GradientBackground = styled.div`
  ${tw`absolute left-0 top-0 right-0 h-[96px]`}

  background: linear-gradient(180deg, #f2f0ff 0%, rgba(242, 240, 255, 0) 100%);
  transform: matrix(-1, 0, 0, 1, 0, 0);
`

export default CartReplaceModal
