import { Dispatch, SetStateAction, useContext } from "react"
import tw from "twin.macro"

import BrandValueFilter from "./BrandValueFilter"
import FilterButton from "./FilterButton"
import ModeSwitcherDefaultSwitcher from "./ModeSwitcherDefaultSwitcher"
import PriceFilter, { PriceFilterValue } from "./PriceFilter"
import ShippingCountryListFilter from "../components/shipping/ShippingCountryListFilter/ShippingCountryListFilter"
import InternationalShippingFilter from "../giftOptionFilters/internationalShipping/InternationalShippingFilter"
import { useShippingCountries } from "../providers/ShippingCountriesProvider"

import { useFeatureAccess } from "@/common/hooks/featureAccess"
import { useFeatureFlags } from "@/common/hooks/featureFlags"
import { generateRealmPath } from "@/common/realm"
import { BrandValueKey } from "@/store/components/BrandValueBadge"
import { ReactComponent as SearchIcon } from "@/store/images/search.svg"
import StoreContext from "@/store/StoreContext"
import { StoreMode } from "@/store/types"

interface Props {
  brandValues: BrandValueKey[]
  priceFilterValue: PriceFilterValue
  searchTerm: string
  setBrandValues: Dispatch<SetStateAction<BrandValueKey[]>>
  setPriceFilter: Dispatch<SetStateAction<PriceFilterValue>>
  setSearchTerm: Dispatch<SetStateAction<string>>
  isSearch?: boolean
  mode?: StoreMode
  setMode?: Dispatch<SetStateAction<StoreMode>>
  hideModeSwitcher?: boolean
}

export default function Filters({
  brandValues,
  priceFilterValue,
  setBrandValues,
  setPriceFilter,
  isSearch,
  mode,
  setMode,
  hideModeSwitcher,
}: Props) {
  const { pushNewUrl, defaultMode } = useContext(StoreContext)

  const { hasFeature } = useFeatureAccess()
  const { hasFeatureFlag } = useFeatureFlags()
  const {
    selectedShippingCountry,
    setSelectedShippingCountry,
    shippingCountries,
  } = useShippingCountries()

  const toggleBrandValue = (value: BrandValueKey) => {
    if (brandValues.includes(value)) {
      setBrandValues(brandValues.filter((brandValue) => brandValue !== value))
    } else {
      setBrandValues(brandValues.concat(value))
    }
  }

  return (
    <div tw="flex flex-row gap-4 items-stretch pb-2 transition-all duration-300 relative flex-wrap lg:flex-nowrap">
      {!isSearch && mode && setMode && !hideModeSwitcher && (
        <>
          <ModeSwitcherDefaultSwitcher
            mode={mode}
            setMode={setMode}
            defaultMode={defaultMode}
          />
          <div tw="w-full lg:hidden" />
        </>
      )}
      <PriceFilter
        priceFilterValue={priceFilterValue}
        setPriceFilter={setPriceFilter}
      />
      {!isSearch && (
        <>
          <BrandValueFilter
            selectedBrandValues={brandValues}
            toggleBrandValue={toggleBrandValue}
          />
          <div tw="flex-1" />
          <FilterButton
            onClick={() => {
              pushNewUrl(generateRealmPath(null, "/browse/search"))
            }}
            filterIsActive={false}
            filterDropdownIsOpen={false}
            hideChevron={true}
          >
            <SearchIcon tw="stroke-current h-4 w-4" />
            Search
          </FilterButton>

          {hasFeatureFlag("shipping_config_v2") ? (
            <ShippingCountryListFilter
              variant="selector"
              selectedCountry={selectedShippingCountry}
              setSelectedCountry={setSelectedShippingCountry}
              countries={shippingCountries}
              withProUpsell={!hasFeature("pro_plan")}
            />
          ) : (
            <InternationalShippingFilter />
          )}
        </>
      )}
    </div>
  )
}

export const filterStyle = tw`bg-primary-050 lg:bg-primary-050 rounded-full lg:rounded-lg min-w-0 h-full border border-transparent text-primary-500`
