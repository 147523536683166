import { format, parseISO } from "date-fns"
import { isNil } from "lodash-es"
import React from "react"
import { Redirect } from "react-router-dom"

import BalanceBudgetRule from "./BalanceBudgetRule"
import Overview from "./Overview"
import TransactionLog from "./TransactionLog"
import { BlankPlus } from "../assets/icons"
import { LinkButton } from "../common/Button"
import {
  SubNav,
  SubNavItem,
  SubNavStartSpacer,
  SubNavWrapper,
} from "../common/components/SubNav"
import { formatPrice } from "../common/format"
import { useGlobalState } from "../common/GlobalState"
import { generateRealmPath } from "../common/realm"
import RealmRoute from "../common/RealmRoute"

export default function Balance() {
  const [user] = useGlobalState("user")

  if (!user) {
    return <Redirect to={generateRealmPath("business", "/signin")} />
  }

  if (isNil(user.balanceAccount)) {
    return (
      <div tw="container">Sorry, you don't have access to account balance.</div>
    )
  }

  return (
    <div tw="border-t border-primary-100">
      <div tw="pt-12 text-center">
        <div tw="font-medium text-xl text-gray-500">Balance</div>
        <div tw="pt-2 font-semibold text-3xl">
          {formatPrice(user.balanceAccount.balance, true)}
        </div>
        {user.balanceAccount.balanceExpiresAt && (
          <div tw="text-gray-500 text-sm mt-2">
            Expiration:{" "}
            {format(parseISO(user.balanceAccount.balanceExpiresAt), "M/d/yyyy")}
          </div>
        )}
        <div tw={"flex flex-col mt-5"}>
          <LinkButton
            variant={"updated"}
            tw={"justify-center gap-2 self-center"}
            to={generateRealmPath("plus", "/balance/deposit")}
          >
            <BlankPlus />
            Add funds
          </LinkButton>
        </div>
        <BalanceBudgetRule />
      </div>
      <div tw="pt-4">
        <SubNavWrapper>
          <SubNav tw="md:justify-center">
            <SubNavStartSpacer />
            <SubNavItem
              title="Overview"
              to={generateRealmPath("plus", "/balance")}
              exact
            >
              Overview
            </SubNavItem>
            <SubNavItem
              title="Transaction Log"
              to={generateRealmPath("plus", "/balance/transaction-log")}
              exact
            >
              Transaction Log
            </SubNavItem>
          </SubNav>
        </SubNavWrapper>
      </div>
      <RealmRoute exact path={"/balance"} realm={"plus"}>
        <Overview />
      </RealmRoute>
      <RealmRoute exact path={"/balance/transaction-log"} realm={"plus"}>
        <TransactionLog />
      </RealmRoute>
    </div>
  )
}
