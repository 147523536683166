import { QueryOptions, gql, useMutation } from "@apollo/client"
import * as Sentry from "@sentry/react"
import { toast } from "react-hot-toast"

import { OrganizationMembershipAccess } from "@/types/graphql-types"
import {
  OrganizationMembershipChangeAccessMutation,
  OrganizationMembershipChangeAccessMutationVariables,
} from "@/types/graphql-types"

interface Props {
  id: string
  workspaceId?: string
  newAccess: OrganizationMembershipAccess
  refetchQueries: Array<QueryOptions>
}

const useChangeOrganizationAccess = () => {
  const [changeAccessOrganizationMembership] = useMutation<
    OrganizationMembershipChangeAccessMutation,
    OrganizationMembershipChangeAccessMutationVariables
  >(ORGANIZATION_MEMBERSHIP_CHANGE_ACCESS)

  return async ({ id, workspaceId, newAccess, refetchQueries }: Props) => {
    const res = await changeAccessOrganizationMembership({
      variables: {
        id,
        workspaceId,
        newAccess,
      },
      refetchQueries,
    })

    if (res.data?.organizationMembershipChangeAccess?.ok) {
      const message =
        newAccess == OrganizationMembershipAccess.full
          ? "Promoted user to a full member."
          : "Demoted user to a limited member."

      toast.success(message, {
        iconTheme: {
          primary: "#27ae60",
          secondary: "#fff",
        },
      })
    } else {
      alert("An error occurred. Please contact our team.")
      Sentry.withScope((scope) => {
        scope.setExtra("response", res)
        Sentry.captureException(
          new Error("changeAccessOrganizationMembership failed"),
        )
      })
    }
  }
}

const ORGANIZATION_MEMBERSHIP_CHANGE_ACCESS = gql`
  mutation OrganizationMembershipChangeAccess(
    $id: ID!
    $workspaceId: ID
    $newAccess: OrganizationMembershipAccess!
  ) {
    organizationMembershipChangeAccess(
      id: $id
      workspaceId: $workspaceId
      newAccess: $newAccess
    ) {
      ok
      error
      organizationMembership {
        access
      }
    }
  }
`

export default useChangeOrganizationAccess
