import tw from "twin.macro"

import { imageForCardBrand } from "@/common/payment"
import { PaymentMethod as PaymentMethodType } from "@/common/PaymentMethodsManager"
import { isBlank } from "@/common/utilities"

interface Props {
  paymentMethod: PaymentMethodType
  onSelect: (paymentMethod: PaymentMethodType) => void
}
export const MiniPaymentMethod = ({ onSelect, paymentMethod }: Props) => {
  const handleClick = (paymentMethod: PaymentMethodType) => {
    onSelect(paymentMethod)
  }

  return (
    <ButtonContainer
      onClick={(e) => {
        e.stopPropagation()
        handleClick(paymentMethod)
      }}
      key={paymentMethod.id}
      className="payment-method-button"
    >
      <div tw="mr-2">{imageForCardBrand(paymentMethod.cardBrand)}</div>
      {!isBlank(paymentMethod.label) && (
        <div tw="mr-1 text-left">
          {paymentMethod.label?.substring(0, 20)}
          {paymentMethod.label?.length && paymentMethod.label.length > 20
            ? "..."
            : ""}
        </div>
      )}
      <div tw="text-gray-800 font-normal">{paymentMethod.last4}</div>
    </ButtonContainer>
  )
}

const ButtonContainer = tw.button`
  flex flex-row items-center px-4 py-[6px] hover:bg-gray-075 active:bg-gray-150 border-b border-gray-200 last:border-none first:rounded-t-lg last:rounded-b-lg
`
