import React from "react"

import {
  SelectionGroup,
  SelectionIndicator,
  SelectionLabel,
} from "../SelectionGroup"

import { gift_meeting_setting } from "@/types/graphql-types"

interface Props {
  giftMeetingType: gift_meeting_setting
  setGiftMeetingType: (giftMeetingType: gift_meeting_setting) => void
}

export const GiftMeetingOptions = ({
  giftMeetingType,
  setGiftMeetingType,
}: Props) => {
  return (
    <>
      <div>
        <div>
          <SelectionGroup
            onClick={() => setGiftMeetingType(gift_meeting_setting.NO_MEETING)}
          >
            <SelectionIndicator
              selected={giftMeetingType === gift_meeting_setting.NO_MEETING}
            />
            <SelectionLabel
              selected={giftMeetingType === gift_meeting_setting.NO_MEETING}
            >
              No meeting calendar
            </SelectionLabel>
          </SelectionGroup>
        </div>
        <div>
          <SelectionGroup
            onClick={() => setGiftMeetingType(gift_meeting_setting.GIFT_FIRST)}
          >
            <SelectionIndicator
              selected={giftMeetingType === gift_meeting_setting.GIFT_FIRST}
            />
            <SelectionLabel
              selected={giftMeetingType === gift_meeting_setting.GIFT_FIRST}
            >
              Gift first - show meeting calendar after gift is accepted
            </SelectionLabel>
          </SelectionGroup>
        </div>
        <div>
          <SelectionGroup
            onClick={() =>
              setGiftMeetingType(gift_meeting_setting.MEETING_REQUIRED)
            }
          >
            <SelectionIndicator
              selected={
                giftMeetingType === gift_meeting_setting.MEETING_REQUIRED
              }
            />
            <SelectionLabel
              selected={
                giftMeetingType === gift_meeting_setting.MEETING_REQUIRED
              }
            >
              Require meeting - meeting must be scheduled to claim gift
            </SelectionLabel>
          </SelectionGroup>
        </div>
      </div>
    </>
  )
}
