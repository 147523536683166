import { isNil } from "lodash-es"
import { useMemo } from "react"

import { useCurrentGift } from "./currentGift"
import { useFeatureAccess } from "./featureAccess"
import {
  BALANCE_PAYMENT_METHOD_ID,
  CORPORATE_ACCOUNT_PAYMENT_METHOD_ID,
} from "./payment"
import { getProduct } from "../gifts"
import { useGlobalState } from "../GlobalState"
import { isEmptyRecipient } from "../recipient"

import { PlusEnrollmentStatus } from "@/types/graphql-types"

export type SendBlockType =
  | "UPDATE_ALCOHOL_WITH_CVC"
  | "CORPORATE_ACCOUNT_NOT_ALLOWED"
  | "BALANCE_NOT_ALLOWED"
  | "BALANCE_NOT_ALLOWED_FOR_GIFT_DISPENSER"
  | "EXCEEDS_PLUS_LIMITED_GIFT_LIMIT"
  | "GIFT_CARD_NO_PRO_SUBSCRIPTION"
  | "GIFT_CARD_NOT_PLUS_FULL"
  | "DELINQUENT"
  | "NONE"

const PLUS_LIMITED_GIFT_MAX = 25

// Send blocks prevent a send from happening. They are similar to send checks
// but do not have a button that can be clicked to recover from the error, and
// they display their own UI to the user explaining the issue.
export default function useSendBlock() {
  const [currentGift] = useCurrentGift()
  const [recipients] = useGlobalState("recipients")
  const [user] = useGlobalState("user")
  const { hasFeature } = useFeatureAccess()

  let blockType: SendBlockType = "NONE"

  if (getProduct(currentGift)?.isAlcohol) {
    if (currentGift.paymentMethod?.id === CORPORATE_ACCOUNT_PAYMENT_METHOD_ID) {
      blockType = "CORPORATE_ACCOUNT_NOT_ALLOWED"
    } else if (currentGift.paymentMethod?.id === BALANCE_PAYMENT_METHOD_ID) {
      blockType = "BALANCE_NOT_ALLOWED"
    } else if (
      currentGift.paymentMethod &&
      !currentGift.paymentMethod.externalLocationsAlcohol &&
      currentGift.autopayEnabled == true
    ) {
      blockType = "UPDATE_ALCOHOL_WITH_CVC"
    }
  }

  if (
    (currentGift.isGiftDispenser || currentGift.isSmartLink) &&
    currentGift.paymentMethod?.id === BALANCE_PAYMENT_METHOD_ID
  ) {
    blockType = "BALANCE_NOT_ALLOWED_FOR_GIFT_DISPENSER"
  }

  const nonEmptyRecipients = useMemo(() => {
    let nonEmptyRows = 0

    for (const recipient of recipients) {
      if (!isEmptyRecipient(recipient)) {
        nonEmptyRows += 1
      }
    }

    return nonEmptyRows
  }, [recipients])

  if (
    currentGift.cart.length > 0 &&
    // Any cart item has the brand "Gift Cards"
    currentGift.cart.some((item) => item.brandName === "Gift Cards")
  ) {
    if (!hasFeature("pro_plan")) {
      blockType = "GIFT_CARD_NO_PRO_SUBSCRIPTION"
    } else if (!(user?.plusEnrollmentStatus === PlusEnrollmentStatus.FULL)) {
      blockType = "GIFT_CARD_NOT_PLUS_FULL"
    }
  }

  if (
    user &&
    user.plusEnrollmentStatus === PlusEnrollmentStatus.LIMITED &&
    !isNil(user.plusLimitedGiftCount)
  ) {
    const resultingGiftCount = user.plusLimitedGiftCount + nonEmptyRecipients

    if (resultingGiftCount > PLUS_LIMITED_GIFT_MAX) {
      blockType = "EXCEEDS_PLUS_LIMITED_GIFT_LIMIT"
    }
  }

  if (user?.isDelinquent) {
    blockType = "DELINQUENT"
  }

  return {
    sendBlockType: blockType,
  }
}
