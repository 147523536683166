import { isNil } from "lodash-es"

import { PriceFilterValue } from "../filters"
import { GiftOptionType } from "../types"

import { Store_GiftOptionStoreTileFragment } from "@/types/graphql-types"

export const GIFT_CARDS_BRAND_NAME = "Gift Cards"

export const giftOptionIsGiftCard = (
  giftOption: GiftOptionType | Store_GiftOptionStoreTileFragment,
) => giftOption.brand.name === GIFT_CARDS_BRAND_NAME

export const priceFilterOptions = Object.freeze({
  All: null,
  "Under $25": 0,
  "$25 – $50": 25,
  "$50 – $75": 50,
  "$75 – $100": 75,
  "$100 – $200": 100,
  "$200 – $300": 200,
  "$300 – $400": 300,
  "$400 – $500": 400,
  "$500+": 500,
})

export function priceFilterValueToMinMax(priceFilterValue: PriceFilterValue) {
  let priceFilterMin = null
  let priceFilterMax = null

  if (priceFilterValue !== null) {
    priceFilterMin = isNil(priceFilterValue.min)
      ? null
      : priceFilterValue.min * 100
    priceFilterMax = isNil(priceFilterValue.max)
      ? null
      : priceFilterValue.max * 100
  }

  return {
    priceFilterMin,
    priceFilterMax,
  }
}
