import { gql, useQuery } from "@apollo/client"
import React from "react"
import tw, { styled } from "twin.macro"

import { ReactComponent as SalesforceLogo } from "../../assets/images/integrations/salesforce-logo.svg"
import APITokens, { API_TOKEN_FRAGMENT } from "../../common/APITokens"
import BackArrow from "../../common/BackArrow"
import TeamUpsell from "../../common/components/TeamUpsell"
import useTeamPlan from "../../common/hooks/teamPlan"
import { Loader } from "../../common/UI"

import { APITokenTtype } from "@/types/graphql-types"
import { Organization_Salesforce_APITokensQuery } from "@/types/graphql-types"

export const Salesforce = () => {
  const { data: tokenData } =
    useQuery<Organization_Salesforce_APITokensQuery>(API_TOKENS_QUERY)
  const { hasSubscription, loading: planLoading } = useTeamPlan()

  return (
    <div tw={"flex flex-col mt-[-1rem]"}>
      {!tokenData || planLoading ? (
        <div tw={"flex justify-center items-center flex-1"}>
          <Loader />
        </div>
      ) : (
        <SalesforceContent
          isAdmin={!!tokenData?.me?.isOrganizationAdmin}
          hasSubscription={!!hasSubscription}
          tokenData={tokenData}
        />
      )}
    </div>
  )
}

const SalesforceContent = ({
  isAdmin,
  hasSubscription,
  tokenData,
}: {
  isAdmin: boolean
  hasSubscription: boolean
  tokenData: Organization_Salesforce_APITokensQuery | undefined
}) => {
  const hasSalesforceIntegrationAccess = hasSubscription && isAdmin

  const UpgradeBanner = () => {
    if (!hasSubscription) {
      return (
        <div tw="my-5">
          <TeamUpsell feature="Salesforce integration" />
        </div>
      )
    }

    return null
  }

  const apiTokens = tokenData?.organization?.apiTokens

  return (
    <div tw={"flex flex-col"}>
      <BackArrow label="Integrations" />
      <div tw={"font-semibold mt-1"} css={"font-size: 2rem;"}>
        Salesforce
      </div>
      {<UpgradeBanner />}
      <div
        css={[
          tw`grid grid-cols-2 gap-8 mt-14`,
          hasSalesforceIntegrationAccess
            ? ""
            : tw`opacity-50 pointer-events-none`,
        ]}
        tw={"grid grid-cols-2 gap-8 mt-14"}
      >
        <SalesforceStep stepNumber={1} title={"Install the app on AppExchange"}>
          <div tw={"flex items-center justify-center my-6 mx-6"}>
            <div tw={"flex flex-row items-center justify-center"}>
              <InstallButtonContainer
                href={
                  "https://appexchange.salesforce.com/listingDetail?listingId=a0N4V00000HE8BvUAL&tab=e"
                }
                tw={
                  "flex flex-row items-center font-semibold gap-2.5 border border-gray-250 rounded-xl px-3 py-2"
                }
                target={"_blank"}
              >
                <SalesforceLogo width="36" height="27" />
                Install on AppExchange
              </InstallButtonContainer>
            </div>
          </div>
        </SalesforceStep>
        <SalesforceStep stepNumber={2} title={"Enter your API key"}>
          <APITokens
            apiTokens={apiTokens}
            queryToRefetch={API_TOKENS_QUERY}
            ttype={APITokenTtype.salesforce}
            actionsDisabled={!hasSalesforceIntegrationAccess}
          />
        </SalesforceStep>
      </div>
    </div>
  )
}

const InstallButtonContainer = styled.a`
  ${tw`flex flex-row items-center font-semibold gap-2.5 border border-gray-250 rounded-xl px-3 py-2 transition-all`}

  &:hover {
    --tw-scale-x: 1.02;
    --tw-scale-y: 1.02;
    transform: var(--tw-transform);
  }

  &:active {
    --tw-scale-x: 0.98;
    --tw-scale-y: 0.98;
    transform: var(--tw-transform);
  }
`

type SalesforceStepProps = {
  stepNumber: number
  title: string
}

const SalesforceStep: React.FC<SalesforceStepProps> = ({
  stepNumber,
  title,
  children,
  ...restProps
}) => {
  return (
    <StepContainer {...restProps}>
      <div tw={"flex flex-row p-6 items-center"}>
        <div
          tw={
            "text-primary-600 bg-primary-100 rounded-full h-9 w-9 items-center justify-center flex"
          }
        >
          {stepNumber}
        </div>
        <div tw={"text-lg font-semibold ml-4"}>{title}</div>
      </div>
      {children}
    </StepContainer>
  )
}

const StepContainer = styled.div`
  ${tw`flex flex-1 flex-col bg-white border border-gray-150 rounded-xl`}
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
`

const API_TOKENS_QUERY = gql`
  query Organization_Salesforce_APITokens {
    me {
      isOrganizationAdmin
    }
    organization {
      apiTokens {
        ...APIToken
      }
    }
  }
  ${API_TOKEN_FRAGMENT}
`

export default Salesforce
