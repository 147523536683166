import React, { FC } from "react"
import InlineSVG from "react-inlinesvg"
import tw, { styled } from "twin.macro"

import useStoreContext from "../hooks/useStoreContext"

import { useGlobalState } from "@/common/GlobalState"
import { CategorySet } from "@/store/categories/CategorySetToggle"
import useCategories from "@/store/hooks/useCategories"
import { useBrowseUrls } from "@/store/StoreHelpers"

interface Props {
  selectedCategorySet: CategorySet
  selectedCategorySlug: string | null
  selectedSubcategorySlug: string | null
}

const GiftOptionsListHeader: FC<Props> = ({
  selectedCategorySet,
  selectedCategorySlug,
  selectedSubcategorySlug,
}) => {
  const setSelectedName = useGlobalState("selectedCategoryName")[1]

  const { useVirtualUrl, virtualLocation } = useStoreContext()
  const { generateBrowseUrl, getPath, pushPath, isPDPUrl, isGiftOptionUrl } =
    useBrowseUrls(useVirtualUrl, virtualLocation)
  const pushNewUrl = (path: string) => {
    if (getPath() !== path) {
      pushPath(path)
    }
  }

  const { selectedCategory: category, selectedSubcategory: subcategory } =
    useCategories({
      selectedCategorySet,
      selectedCategorySlug,
      selectedSubcategorySlug,
      isEmbeddedCustomStore: false, // Doesn't matter for the selected categories
    })

  const selectedCategory = subcategory || category

  const { name, pageHeading, pageDescription } = selectedCategory || {}
  const pageHeadingString = pageHeading || name
  const isSubcategory = Boolean(subcategory)
  const linkString = isSubcategory ? category?.name : pageHeadingString

  const categorySlug = isSubcategory ? category?.slug : null
  const browseUrl = generateBrowseUrl({ giftOptionSlug: null, categorySlug })
  const href = isSubcategory ? browseUrl : ""

  const onClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    if (isSubcategory) {
      setSelectedName(null)
      pushNewUrl(browseUrl)
    }
  }

  if (!selectedCategory) {
    return null
  }

  const icon = category?.icon
    ?.replace(/fill="black"/g, `fill="currentColor"`)
    ?.replace(/stroke="black"/g, `stroke="currentColor"`)

  // When there is a GiftOption or a Product opened, we want to use their H1, for seo performance.
  const HeaderTag = isPDPUrl() || isGiftOptionUrl() ? "span" : "h1"
  const LinkTag = isSubcategory ? "nav" : HeaderTag

  const subcategories = category?.subcategories || []
  const hasSubcategories = !isSubcategory && subcategories.length > 0

  return (
    <div tw="hidden lg:flex justify-center w-full pt-4">
      <div tw="flex items-center flex-col w-full px-4 xl:w-2/3">
        <LinkTag>
          <CategoryLink
            tw="flex"
            $hasSubcategory={isSubcategory}
            onClick={onClick}
            href={href}
            css={[
              !isSubcategory &&
                tw`font-reckless-neue text-[40px] font-normal tracking-[-0.01em] gap-2`,
            ]}
          >
            {icon && (
              <Icon
                src={icon}
                $hasSubcategory={isSubcategory}
                css={[!isSubcategory && tw`h-8 w-8`]}
              />
            )}
            {linkString}
          </CategoryLink>
        </LinkTag>
        {isSubcategory && (
          <span tw="mb-4 font-reckless-neue text-[40px] font-normal tracking-[-0.01em]">
            {pageHeadingString}
          </span>
        )}
        <h2 tw="text-xl text-gray-500 text-center">{pageDescription}</h2>
        {hasSubcategories && (
          <div tw="pt-3 flex flex-row items-center justify-center gap-3 text-lg flex-wrap">
            {subcategories.map((subcategory) => (
              <a
                href={generateBrowseUrl({
                  categorySlug: subcategory.slug,
                  giftOptionSlug: null,
                })}
                onClick={(e) => {
                  e.preventDefault()
                  pushNewUrl(
                    generateBrowseUrl({
                      categorySlug: subcategory.slug,
                      giftOptionSlug: null,
                    }),
                  )
                }}
                tw="px-4 py-1.5 rounded-full text-gray-800 bg-gray-100 hover:bg-gray-200 active:scale-95 transition-all whitespace-nowrap"
              >
                {subcategory.name}
              </a>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

const Icon = styled(InlineSVG)<{ $hasSubcategory: boolean }>`
  ${tw`h-7 w-6 mr-2`}

  ${({ $hasSubcategory }) => $hasSubcategory && tw`h-6 w-5`}
`

const CategoryLink = styled.a<{ $hasSubcategory: boolean }>`
  ${tw`
    flex flex-col justify-center mb-1 flex-row items-center rounded-lg outline-none transition-all
    shadow-none border-transparent text-center cursor-text text-3xl font-medium pb-1
  `}

  ${({ $hasSubcategory }) =>
    $hasSubcategory &&
    tw`text-xl cursor-pointer font-normal text-gray-400 hover:text-primary-600`}
`

export default GiftOptionsListHeader
