import tw, { styled } from "twin.macro"

import { ConditionalNavLink } from "../../../common/components/ConditionalNavLink"

// TODO: Fix warning
// Warning: React does not recognize the `blockReclicks` prop
// on a DOM element. If you intentionally want it to appear in
// the DOM as a custom attribute, spell it as lowercase `blockreclicks`
// instead. If you accidentally passed it from a parent component,
// remove it from the DOM element.
const HeaderNavLink = styled(ConditionalNavLink)`
  ${tw`flex gap-2 text-black lg:text-gray-700 lg:font-medium py-4 lg:py-0 w-full lg:w-max`};

  svg {
    ${tw`fill-current opacity-50 w-auto transition-opacity place-self-center mt-[0.15rem]`}
    grid-row: 1;
    grid-column: 1;
  }
`

const BusinessInternalLinkStyle = tw`
  w-auto h-[70px] px-0 mx-6
  font-semibold text-[#1f2024]
  border-b border-solid border-[rgba(31, 32, 36, 0.1)]
`
const HeaderInternalLinkStyle = styled(HeaderNavLink)`
  .is-business & {
    @media (max-width: 1023px) {
      ${BusinessInternalLinkStyle};
    }
  }

  &.active {
    ${tw`lg:text-primary-new-550`}
  }
`
const HeaderInternalLink = tw(HeaderInternalLinkStyle)`
  flex flex-row items-center justify-between whitespace-nowrap
  px-6 lg:px-0
  hover:lg:text-primary-new-550
`

export default HeaderInternalLink
