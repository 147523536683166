import { gql } from "@apollo/client"

export const SWAG_EDIT_PRODUCT_MUTATION = gql`
  mutation SwagIntegrationsEditProduct(
    $providerType: ID!
    $externalProductId: ID!
  ) {
    swagIntegrations {
      editProduct(
        providerType: $providerType
        externalProductId: $externalProductId
      ) {
        ok
      }
    }
  }
`
