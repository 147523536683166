import { isNil } from "lodash-es"
import React, { ReactNode } from "react"
import { Link } from "react-router-dom"

import { useHolidayEmailGateUnsafe } from "../components/HolidayGiftGuideScreen/hooks/useHolidayEmailGate"

type Props = {
  to: string
  className: string
  children: ReactNode
}

const LinkContainer = ({ to, className, children }: Props) => {
  // NOTE: Context is not available inside the plasmic editor so we need to call it this way to avoid crashes.
  const { tryAccessGiftGuide } = useHolidayEmailGateUnsafe() ?? {}

  const onClick = (e: React.MouseEvent) => {
    if (isNil(tryAccessGiftGuide)) {
      return
    }

    if (!tryAccessGiftGuide()) {
      e.preventDefault()
    }
  }

  return (
    <Link to={to} className={className} onClick={onClick}>
      {children}
    </Link>
  )
}

export default LinkContainer
