import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import React from "react"
import tw, { styled } from "twin.macro"

import { useRemoveCustomStoreOption } from "./hooks/useRemoveCustomStoreOption"
import { ReactComponent as ChevronDown } from "./images/down-arrow.svg"
import { ReactComponent as QuestionMark } from "../../assets/icons/question-mark.svg"
import { ReactComponent as XIcon } from "../../assets/icons/x-gray.svg"
import Button from "../../common/Button"
import { formatPrice, formatPriceInternational } from "../../common/format"
import Tooltip from "../../common/Tooltip"
import { ProductImage } from "../ProductImage"

import { CustomStoreOptionListItemFragment } from "@/types/graphql-types"

interface StoreOptionProps {
  customStoreID: string | null | undefined
  customStoreOption: CustomStoreOptionListItemFragment
  refetchCustomStore: Function
}

const StoreOption: React.FC<StoreOptionProps> = ({
  customStoreID,
  customStoreOption,
  refetchCustomStore,
}) => {
  const { handleRemoveCustomStoreOption } =
    useRemoveCustomStoreOption(refetchCustomStore)

  if (customStoreOption.isAvailable) {
    return (
      <StoreOptionStyled key={customStoreOption.id}>
        {optionListing(
          customStoreID,
          customStoreOption,
          handleRemoveCustomStoreOption,
        )}
      </StoreOptionStyled>
    )
  } else {
    return (
      <Tooltip
        title={"Product is unavailable"}
        trigger={
          <StoreOptionStyled className="inactive" key={customStoreOption.id}>
            {optionListing(
              customStoreID,
              customStoreOption,
              handleRemoveCustomStoreOption,
            )}
          </StoreOptionStyled>
        }
        placement="auto"
      >
        <p>
          This item is either inactive or its price exceeds maximum subtotal.
        </p>
      </Tooltip>
    )
  }
}

const StoreOptionStyled = styled.div`
  ${tw`rounded-lg`}

  &.inactive {
    ${tw`opacity-80 bg-gray-250`}
  }

  &:not(.inactive) {
    &:hover {
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.06);
    }
  }
`

interface StoreOptionProductProps {
  customStoreID?: string | null
  customStoreOption: CustomStoreOptionListItemFragment
  customStoreOptionID?: string | null
  product: CustomStoreOptionListItemFragment["product"]
  removeCustomStoreOption: Function
  isAvailable: boolean
  isDuplicated: boolean
}

const StoreOptionProduct: React.FC<StoreOptionProductProps> = ({
  customStoreID,
  customStoreOption,
  product,
  removeCustomStoreOption,
  isAvailable,
}) => {
  return (
    <GiftContainer
      className={isAvailable ? "" : "inactive"}
      tw="flex flex-row items-center rounded-lg pr-4 bg-white"
    >
      <div
        css={{
          borderTopLeftRadius: 8,
          borderBottomLeftRadius: 8,
        }}
      >
        {product.productImages[0]?.imageThumb?.url && (
          <ProductImage
            image={product.productImages[0]?.imageThumb}
            scalable={product.imagesScalable}
            alt={product.name}
            tw="w-14 max-w-[3.5rem] h-14 p-0.5 rounded-lg"
          />
        )}
      </div>
      <div tw="py-2 px-3 gap-y-1 flex flex-col max-w-[50%] md:max-w-[fit-content]">
        <div
          tw="text-sm text-gray-400"
          css={{
            lineHeight: "130%",
          }}
        >
          {product.brand.name}
        </div>
        <div
          tw="text-gray-700"
          css={{
            lineHeight: "130%",
          }}
        >
          {product.name}
        </div>
      </div>
      <div tw="ml-auto flex gap-2">
        {product.isDuplicated && (
          <Tooltip
            title={"Product is duplicated"}
            trigger={<QuestionMark tw="ml-1 inline-block" />}
            placement="right"
          >
            <p>This gift appears more than once in the custom store.</p>
          </Tooltip>
        )}
        <div
          tw="ml-auto flex-shrink text-sm text-black"
          css={{ lineHeight: "130%" }}
        >
          {product.giftCardInfo?.currency &&
            product.giftCardInfo.currency !== "USD" &&
            customStoreOption.giftCardAmount && (
              <>
                <span tw="text-gray-500">
                  {formatPriceInternational({
                    nativeCurrency: product.giftCardInfo.currency,
                    nativeAmountInCents: customStoreOption.giftCardAmount,
                  })}
                </span>
                <span tw="text-gray-500 px-2">&middot;</span>
              </>
            )}
          {formatPrice(product.price + (product.shippingPrice ?? 0), false)}
        </div>
      </div>
      <div tw="flex items-center">
        <RemoveGiftButton
          onClick={() => {
            removeCustomStoreOption(
              customStoreID,
              null,
              product.id,
              customStoreOption.id,
            )
          }}
        />
      </div>
    </GiftContainer>
  )
}

interface StoreOptionGiftOptionProps {
  customStoreID?: string | null
  customStoreOptionID?: string | null
  giftOption: NonNullable<CustomStoreOptionListItemFragment["giftOption"]>
  removeCustomStoreOption: Function
  isAvailable: boolean
}

const StoreOptionGiftOption: React.FC<StoreOptionGiftOptionProps> = ({
  customStoreID,
  customStoreOptionID,
  giftOption,
  removeCustomStoreOption,
}) => {
  const expandElement = () => (
    <div
      tw="overflow-hidden h-14 flex items-center justify-center"
      css={{
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
        pointerEvents: "auto",
      }}
    >
      <ExpandGiftOptionButton>
        <ChevronDown tw="text-gray-350 ease-in-out transform" />
        <div tw="text-gray-350">{giftOption?.products?.length}</div>
      </ExpandGiftOptionButton>
    </div>
  )

  return (
    <Accordion
      defaultExpanded={true}
      disableGutters={true}
      elevation={0}
      square={true}
      sx={{ borderRadius: "0.5rem", "&:before": { display: "none" } }}
    >
      <AccordionSummary
        expandIcon={expandElement()}
        sx={{
          ".MuiAccordionSummary-content": {
            margin: 0,
          },
          flexDirection: "row-reverse",
          ".MuiAccordionSummary-expandIconWrapper": {
            "&.Mui-expanded": {
              transform: "none",
            },
            "&:not(.Mui-expanded)": {
              "div button svg": { transform: "rotate(-90deg)" },
            },
          },
        }}
      >
        <GiftContainer tw="pl-4 flex flex-row items-center bg-white z-10 relative w-full">
          <div tw="py-2 gap-y-1 flex flex-col max-w-[50%] md:max-w-[fit-content]">
            <div
              tw="text-gray-700 font-semibold break-words"
              css={{
                lineHeight: "130%",
              }}
            >
              {giftOption.name}
            </div>
          </div>
          <div
            tw="ml-auto flex-shrink text-sm text-black"
            css={{ lineHeight: "130%" }}
          >
            {giftOption.priceMaxWithShipping && (
              <div tw="flex flex-row gap-2">
                <div tw="text-gray-400">max</div>
                {formatPrice(giftOption.priceMaxWithShipping, false)}
              </div>
            )}
          </div>
          <div tw="flex items-center">
            <RemoveGiftButton
              onClick={() => {
                removeCustomStoreOption(
                  customStoreID,
                  giftOption.id,
                  null,
                  customStoreOptionID,
                )
              }}
            />
          </div>
        </GiftContainer>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 0 }}>
        {giftOption.products.map((product) => {
          if (product.isAvailable) {
            return (
              <GiftContainer
                tw="flex flex-row items-center border-t border-gray-150 last:rounded-b-lg pr-4 bg-white transition-all duration-200 ease-in-out z-0 transform relative"
                key={product.id}
              >
                <div
                  css={{
                    borderTopLeftRadius: 8,
                    borderBottomLeftRadius: 8,
                  }}
                >
                  {product.productImages[0]?.imageThumb?.url && (
                    <ProductImage
                      image={product.productImages[0]?.imageThumb}
                      alt={product.name}
                      scalable={product.imagesScalable}
                      tw="w-14 h-14 max-w-[3.5rem] p-1 rounded-lg"
                    />
                  )}
                </div>
                <div tw="py-2 px-3 gap-y-1 flex flex-col max-w-[50%] md:max-w-[fit-content]">
                  <div tw="text-gray-700" css={{ lineHeight: "130%" }}>
                    {product.name}
                  </div>
                </div>
                <div tw="ml-auto flex gap-2">
                  {product.isDuplicated && (
                    <Tooltip
                      title={"Product is duplicated"}
                      trigger={<QuestionMark tw="ml-1 inline-block" />}
                      placement="right"
                    >
                      <p>
                        This gift appears more than once in the custom store.
                      </p>
                    </Tooltip>
                  )}
                  <div
                    tw="ml-auto flex-shrink text-sm text-black"
                    css={{ lineHeight: "130%" }}
                  >
                    {formatPrice(
                      product.price + (product.shippingPrice ?? 0),
                      false,
                    )}
                  </div>
                </div>
                <div tw="flex items-center">
                  <RemoveGiftButton
                    onClick={() => {
                      removeCustomStoreOption(
                        customStoreID,
                        giftOption.id,
                        product.id,
                      )
                    }}
                  />
                </div>
              </GiftContainer>
            )
          } else {
            return (
              <Tooltip
                title={"Product is unavailable"}
                trigger={
                  <GiftContainer
                    className="inactive"
                    tw="flex flex-row items-center border-t border-gray-150 last:rounded-b-lg pr-4 bg-white transition-all duration-200 ease-in-out z-0 transform relative"
                    key={product.id}
                  >
                    <div
                      css={{
                        borderTopLeftRadius: 8,
                        borderBottomLeftRadius: 8,
                      }}
                    >
                      {product.productImages[0]?.imageThumb?.url && (
                        <img
                          src={product.productImages[0]?.imageThumb.url}
                          tw="object-cover w-14 max-w-[3.5rem] h-14 p-1 rounded-lg"
                          alt={product.name}
                        />
                      )}
                    </div>
                    <div tw="py-2 px-3 gap-y-1 flex flex-col max-w-[50%] md:max-w-[fit-content]">
                      <div tw="text-gray-700" css={{ lineHeight: "130%" }}>
                        {product.name}
                      </div>
                    </div>
                    <div
                      tw="ml-auto flex-shrink text-sm text-black"
                      css={{ lineHeight: "130%" }}
                    >
                      {formatPrice(
                        product.price + (product.shippingPrice ?? 0),
                        false,
                      )}
                    </div>
                    <div tw="flex items-center">
                      <RemoveGiftButton
                        onClick={() => {
                          removeCustomStoreOption(
                            customStoreID,
                            giftOption.id,
                            product.id,
                          )
                        }}
                      />
                    </div>
                  </GiftContainer>
                }
                placement="auto"
              >
                <p>
                  This item is either inactive or its price exceeds maximum
                  subtotal.
                </p>
              </Tooltip>
            )
          }
        })}
      </AccordionDetails>
    </Accordion>
  )
}

interface RemoveGiftBaseButtonProps {
  onClick: React.MouseEventHandler
  className?: string
}
const RemoveGiftBaseButton: React.FC<RemoveGiftBaseButtonProps> = ({
  onClick,
  className,
}) => (
  <button className={className} type="button" onClick={onClick}>
    <XIcon width={12} height={12} />
  </button>
)

const RemoveGiftButton = styled(RemoveGiftBaseButton)`
  ${tw`rounded hover:bg-gray-200 active:bg-gray-300 z-10 p-1`}
`

const ExpandGiftOptionButton = styled(Button)`
  ${tw`gap-1 border box-border border-gray-150 shadow-none px-2 py-0`}

  &:hover {
    ${tw`bg-gray-050 border-gray-150`}
  }

  &:active {
    ${tw`bg-gray-100 border-gray-150`}
  }
`

const GiftContainer = styled.div`
  &.inactive {
    ${tw`opacity-80 bg-gray-250`}
  }

  &:hover:not(.collapsed) {
    ${RemoveGiftButton} {
      ${tw`opacity-100 scale-100 w-5 ml-2`}
    }
  }

  ${RemoveGiftButton} {
    ${tw`opacity-0 transition-all duration-75 transform scale-0 w-0`}
  }
`

const optionListing = (
  customStoreID: string | null | undefined,
  customStoreOption: CustomStoreOptionListItemFragment,
  removeCustomStoreOption: Function,
) => {
  if (!!customStoreOption.giftOption) {
    return (
      <StoreOptionGiftOption
        customStoreID={customStoreID}
        customStoreOptionID={customStoreOption.id}
        giftOption={customStoreOption.giftOption}
        isAvailable={customStoreOption.isAvailable}
        removeCustomStoreOption={removeCustomStoreOption}
      />
    )
  } else if (!!customStoreOption.product) {
    return (
      <StoreOptionProduct
        customStoreID={customStoreID}
        customStoreOption={customStoreOption}
        customStoreOptionID={customStoreOption.id}
        product={customStoreOption.product}
        isAvailable={customStoreOption.isAvailable}
        isDuplicated={customStoreOption.product.isDuplicated}
        removeCustomStoreOption={removeCustomStoreOption}
      />
    )
  } else {
    return null
  }
}

export default StoreOption
