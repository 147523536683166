import staticAssetUrl from "../../../common/staticAssetUrl"
import Section, {
  SectionContainer,
  SectionContent,
  SectionHeader,
  SectionHeaderEm,
  SectionPill,
} from "../Section"

export default function International() {
  return (
    <SectionContainer>
      <Section tw="bg-[#f4f9ff]">
        <SectionPill tw="bg-[#e6eff9] text-[#3b6496]">
          International Shipping
        </SectionPill>
        <SectionHeader tw="text-[#3b6496] max-w-[580px] mx-auto">
          Gift globally, <SectionHeaderEm>seamlessly.</SectionHeaderEm>
        </SectionHeader>
        <SectionContent>
          <p>
            Goody supports international shipping and local gift cards in 140
            countries.
          </p>
        </SectionContent>
        <div tw="pt-10 -mx-4">
          <img
            src={staticAssetUrl("static/web/landing/api/global.png")}
            alt=""
            tw="mx-auto w-[554px]"
          />
        </div>
      </Section>
    </SectionContainer>
  )
}
