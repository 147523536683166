import { gql, useMutation } from "@apollo/client"
import { useRef } from "react"

import {
  User_CreateDismissedScreenMutation,
  User_CreateDismissedScreenMutationVariables,
} from "@/types/graphql-types"

export enum DismissedScreenID {
  send__howitworks__email__generic = "send__howitworks__email__generic",
  send__howitworks__link_single__generic = "send__howitworks__link_single__generic",
  send__howitworks__link_multiple__generic = "send__howitworks__link_multiple__generic",
}

// useDismissedScreens hook stores a list of dismissed screens (an array of
// ints) where the int corresponds to a known dismissed screen ID. Dismissed
// screens are things like Send guidance modals.
//
// Use setDismissedScreens when the user data is loaded from the server.
// When a screen is dismissed, use addDismissedScreen.
//
// Should be used through the context hook useDismissedScreensContext so that
// data is not cleared between different components.
export const useDismissedScreens = () => {
  const dismissedScreensRef = useRef<string[]>([])

  const [createDismissedScreen] = useMutation<
    User_CreateDismissedScreenMutation,
    User_CreateDismissedScreenMutationVariables
  >(CREATE_DISMISSED_SCREEN_MUTATION)

  // Setting dismissed screens from the server combines with the existing
  // screens in memory. This doesn't use a DismissedScreenID type because the
  // server only returns ints; we just assume that the server is returning
  // valid screen IDs.
  const setDismissedScreens = (screenIDs: string[]) => {
    dismissedScreensRef.current = Array.from(
      new Set([...dismissedScreensRef.current, ...screenIDs]),
    )
  }

  const addDismissedScreen = (screenID: DismissedScreenID) => {
    dismissedScreensRef.current = [...dismissedScreensRef.current, screenID]

    createDismissedScreen({
      variables: {
        screenID,
      },
    })
  }

  const isScreenDismissed = (screenID: DismissedScreenID) => {
    return dismissedScreensRef.current.includes(screenID)
  }

  return {
    dismissedScreens: dismissedScreensRef.current,
    setDismissedScreens,
    addDismissedScreen,
    isScreenDismissed,
  }
}

const CREATE_DISMISSED_SCREEN_MUTATION = gql`
  mutation User_CreateDismissedScreen($screenID: String!) {
    userDismissedScreenCreate(screenId: $screenID) {
      ok
    }
  }
`
