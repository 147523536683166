import { ReactNode } from "react"

interface Props {
  title: string
  children: ReactNode
}

const PaymentMethodsContainer = ({ title, children }: Props) => (
  <div tw="w-full flex flex-col gap-3 p-3 rounded-lg border border-solid border-gray-250">
    {title && (
      <div tw="pl-2 pt-1 font-text font-semibold text-lg text-gray-500">
        {title}
      </div>
    )}
    {children}
  </div>
)

export default PaymentMethodsContainer
