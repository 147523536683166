import HowItWorksCommon from "../HowItWorksCommon"

interface Props {}

export default function HowItWorksAutomation({}: Props) {
  return (
    <HowItWorksCommon
      content={[
        {
          heading: "Trigger via direct API integration or Zapier.",
          description:
            "You can automate gifts using data from virtually any business system or app.",
        },
        {
          heading: "No shipping info needed.",
          description:
            "Your recipient provides their address when they accept your gift.",
        },
        {
          heading: "You only pay for gifts that people want.",
          description:
            "Recipients can swap gifts for alternatives if they prefer. You pay only when they accept.",
        },
      ]}
    />
  )
}
