import { useApolloClient, useLazyQuery } from "@apollo/client"
import React, { useEffect, useMemo } from "react"
import tw from "twin.macro"

import {
  AUTOGIFTS_QUERY,
  CONTACTS_WITH_EVENT_TODAY_QUERY,
} from "./AutogiftIndex"
import { useAutogiftData } from "./hooks"
import { ReactComponent as TenureIcon } from "../../assets/icons/tenure-small.svg"
import { useGlobalState } from "../../common/GlobalState"

import {
  AutogiftRuleStatus,
  Contacts_AutogiftsQuery,
  Contacts_AutogiftsQueryVariables,
  EventKind,
} from "@/types/graphql-types"

// Allows a user to switch between other Autogift tenures in the same event type
// Assumes the existence of a current autogift rule, which could be one without
// any content filled in, but it requires a contactListId
const AutogiftTenureSidebar = () => {
  const [currentAutogiftRule] = useGlobalState("currentAutogiftRule")

  const { loadAutogiftRuleData, loadNewAutogiftRuleData } = useAutogiftData({})

  const loadContactsWithEventToday = useApolloClient()

  // Typically, this should be cached by AutogiftIndex.
  const [loadAutogifts, { data }] = useLazyQuery<
    Contacts_AutogiftsQuery,
    Contacts_AutogiftsQueryVariables
  >(AUTOGIFTS_QUERY)

  // Load new autogift rule data when selecting a new tenure level
  const handleNewTenureSelect = async (
    contactListName: string,
    contactListId: string,
  ) => {
    // For a new autogift rule, run query for contacts that have an event today
    const { data } = await loadContactsWithEventToday.query({
      query: CONTACTS_WITH_EVENT_TODAY_QUERY,
      variables: {
        contactListId: contactListId,
        eventType: EventKind.WORK_ANNIVERSARY,
      },
    })

    const contactsWithEventToday =
      data?.workspace?.contactList?.contactsWithEventToday

    loadNewAutogiftRuleData(
      "work_anniversary",
      contactListName,
      contactListId,
      contactsWithEventToday,
    )
  }

  // currentAutogiftRule is not guaranteed to be present, so lazy-load data when
  // it does appear (and re-load it when the contact list ID changes, which
  // shouldn't be possible while this component is mounted, but kept for
  // correctness.)
  useEffect(() => {
    if (currentAutogiftRule.contactListId) {
      loadAutogifts({
        variables: {
          contactListId: currentAutogiftRule.contactListId,
        },
      })
    }
  }, [currentAutogiftRule?.contactListId])

  const rules = useMemo(() => {
    const serverRules = data?.workspace?.contactList?.autogiftRules

    if (serverRules) {
      const workAnniversaryRules = serverRules.filter(
        (rule) => rule.eventType === "work_anniversary",
      )

      // Sort by tenure
      return [...workAnniversaryRules].sort((a, b) => {
        if (!a.tenureMin || !b.tenureMin) {
          return 0
        }

        return a.tenureMin - b.tenureMin
      })
    } else {
      return []
    }
  }, [data?.workspace?.contactList?.autogiftRules])

  return (
    <div tw="mt-20">
      <div tw="px-6 pb-2 flex flex-row items-center">
        <TenureIcon />
        <div tw="pl-2 text-gray-400">Tenure levels</div>
      </div>
      <div tw="pr-3">
        {rules.map((rule) => (
          <button
            key={rule.id}
            css={[
              tw`pl-6 pr-4 py-2 flex flex-row items-center w-full text-left hover:bg-gray-100 active:bg-gray-200 rounded-r-lg transition-colors`,
              currentAutogiftRule.id === rule.id &&
                tw`bg-primary-050 hover:bg-primary-050 active:bg-primary-050 text-primary-500`,
            ]}
            onClick={() => loadAutogiftRuleData(rule)}
          >
            <div tw="text-xl flex-1">
              {generateTitle(rule.tenureMin, rule.tenureMax)}
            </div>
            <div
              css={[
                tw`h-2 w-2 rounded-full`,
                rule.status === AutogiftRuleStatus.ACTIVE
                  ? tw`bg-green-500`
                  : tw`bg-red-500`,
              ]}
            />
          </button>
        ))}
      </div>
      <div tw="pt-4">
        <button
          css={[
            tw`text-gray-400 hover:text-gray-500 pl-6 transition-colors w-full text-left`,
            currentAutogiftRule.id === null &&
              tw`text-primary-500 hover:text-primary-500`,
          ]}
          onClick={() => {
            if (
              currentAutogiftRule.contactListName &&
              currentAutogiftRule.contactListId
            ) {
              handleNewTenureSelect(
                currentAutogiftRule.contactListName,
                currentAutogiftRule.contactListId,
              )
            }
          }}
        >
          New tenure level
        </button>
      </div>
    </div>
  )
}

const generateTitle = (min?: number | null, max?: number | null) => {
  if (min === null && max === null) {
    return "All"
  }

  if (min !== null && max !== null) {
    if (min === max) {
      return `${min} year${min !== 1 ? "s" : ""}`
    } else {
      return `${min} to ${max} years`
    }
  }

  // Either min or max set, but not both, should not be possible
  return ""
}

export default AutogiftTenureSidebar
