import { gql } from "@apollo/client"

import { StandardOccasionFragment } from "@/types/graphql-types"

// NOTE: CACHE IS NOT CURRENTLY POPULATED OR USED

let occasions: StandardOccasionFragment[] = []
let occasionsIndex: { [id: string]: StandardOccasionFragment } = {}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function cacheCardImages(occasions: StandardOccasionFragment[]) {
  const images = new Set<string>()

  if (occasions.length > 0) {
    for (const occasion of occasions) {
      if (occasion.cards && occasion.cards.length > 0) {
        for (const card of occasion.cards) {
          const url = card.imageLarge.url
          images.add(url)
        }
      }
    }
  }

  // Cache
  images.forEach((url) => {
    new Image().src = url
  })
}

export function cacheOccasions(
  occasionsFromServer: StandardOccasionFragment[],
) {
  occasions = occasionsFromServer
  occasionsIndex = {}

  for (const occasion of occasionsFromServer) {
    occasionsIndex[occasion.id] = occasion
  }

  // We disable this for now because there are too many cards.
  // cacheCardImages(occasions);
}

export function getOccasion(id: string) {
  return occasionsIndex[id]
}

export function getOccasions() {
  return occasions
}

export const STANDARD_CARD_FRAGMENT = gql`
  fragment StandardCard on Card {
    id
    imageLarge {
      url
      width
      height
    }
    rounded
  }
`

export const STANDARD_OCCASION_FRAGMENT = gql`
  fragment StandardOccasion on Occasion {
    id
    name
    longName
    color
    icon
    cards {
      ...StandardCard
    }
  }
  ${STANDARD_CARD_FRAGMENT}
`

export const OCCASIONS_QUERY = gql`
  query App_Occasions($segment: OccasionSegment!) {
    occasions(segment: $segment) {
      ...StandardOccasion
    }
  }
  ${STANDARD_OCCASION_FRAGMENT}
`
