import React from "react"

import useDeveloperMode from "../hooks/useDeveloperMode"

export const DeveloperModeContext = React.createContext<null | ReturnType<
  typeof useDeveloperMode
>>(null)

export const DeveloperModeProvider = (
  props: Omit<
    React.ComponentProps<typeof DeveloperModeContext.Provider>,
    "value"
  >,
) => {
  return <DeveloperModeContext.Provider value={useDeveloperMode()} {...props} />
}
