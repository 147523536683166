import { gql, useQuery } from "@apollo/client"
import React, { useEffect, useState } from "react"
import { ReactNode } from "react"
import { Helmet } from "react-helmet-async"
import { useHistory } from "react-router-dom"

import HRISConfiguration from "./HRISConfiguration"
import HRISConnect from "./HRISConnect"
import HRISNoPermissions from "./HRISNoPermissions"
import HRISPreparingData from "./HRISPreparingData"
import { useGlobalState } from "../../common/GlobalState"
import { useCurrentWorkspace } from "../../common/hooks"
import { Container } from "../../common/hris"
import { generateRealmPath } from "../../common/realm"
import { Loader } from "../../common/UI"

import {
  CustomerIntegrationStatus,
  PlusEnrollmentStatus,
} from "@/types/graphql-types"
import {
  CustomerIntegrationQuery,
  CustomerIntegrationQueryVariables,
} from "@/types/graphql-types"

const HRISIntegration: React.FC<{}> = () => {
  const [enrollmentStatus] = useGlobalState("enrollmentStatus")

  const history = useHistory()

  // If HRIS has been connected to the organization
  const [isConnected, setIsConnected] = useState(false)

  const [isLoading, setIsLoading] = useState(true)
  const [isPerformingInitialSync, setIsPerformingInitialSync] = useState(false)
  const [isSyncing, setIsSyncing] = useState(false)
  const [isAwaitingRippling, setIsAwaitingRippling] = useState(false)

  const { currentWorkspace } = useCurrentWorkspace()
  const workspaceId = currentWorkspace?.id

  const { data, refetch, startPolling, stopPolling } = useQuery<
    CustomerIntegrationQuery,
    CustomerIntegrationQueryVariables
  >(CUSTOMER_INTEGRATION_QUERY, {
    context: { workspaceId },
    onCompleted: (data) => {
      const integration = data?.me?.customerIntegration
      if (!!integration) {
        if (integration.serviceInfo) {
          setIsLoading(false)
          setIsConnected(true)
        }
      } else {
        setIsConnected(false)
        setIsLoading(false)
      }
    },
  })

  const customerIntegration = data?.me?.customerIntegration
  const {
    name: serviceName,
    squareImage: serviceSquareImage,
    configuredBy: serviceConfiguredByName,
  } = customerIntegration?.serviceInfo ?? {}

  useEffect(() => {
    if (enrollmentStatus === PlusEnrollmentStatus.NONE) {
      history.replace(generateRealmPath("business", "/signup"))
    }
  }, [enrollmentStatus, history])

  const [user] = useGlobalState("user")

  // Reload workspaces side bar when new workspace is added
  useEffect(() => {
    refetch()
  }, [user?.workspaces])

  useEffect(() => {
    switch (customerIntegration?.status) {
      case CustomerIntegrationStatus.ACTIVE:
        setIsPerformingInitialSync(false)
        setIsAwaitingRippling(false)
        return
      case CustomerIntegrationStatus.SYNCING:
        const firstSyncWithHRIS =
          !!customerIntegration?.syncInfo?.isInitialSync &&
          customerIntegration?.syncStage === "HRIS"
        setIsPerformingInitialSync(firstSyncWithHRIS)
        return
    }
  }, [
    customerIntegration?.status,
    customerIntegration?.syncInfo?.isInitialSync,
    customerIntegration?.syncStage,
  ])

  // Poll to fetch status while syncing
  useEffect(() => {
    if (isSyncing || isPerformingInitialSync) {
      startPolling(500)
    } else {
      stopPolling()
    }
  }, [isSyncing || isPerformingInitialSync])

  // Poll to fetch status while syncing
  useEffect(() => {
    if (isAwaitingRippling) {
      startPolling(500)
    } else {
      stopPolling()
    }
  }, [isAwaitingRippling])

  if (enrollmentStatus === PlusEnrollmentStatus.NONE) {
    return null
  }

  // If the user has no permissions, we only return the HRIS' serviceName,
  // square image, and the name of the person who configured the
  // integration on the configuration page
  const userHasNoPermissions =
    !customerIntegration?.id && !!customerIntegration?.serviceInfo

  if (isLoading) {
    return (
      <HRISContainer>
        <div tw="flex items-center justify-center h-screen">
          <Loader />
        </div>
      </HRISContainer>
    )
  } else if (userHasNoPermissions) {
    return (
      <div tw="border-t border-primary-100">
        <Helmet>
          <title>HR Integration – Goody for Business</title>
        </Helmet>
        <div tw="bg-white container mx-auto relative h-screen">
          <HRISNoPermissions
            serviceName={serviceName}
            serviceSquareImage={serviceSquareImage}
            serviceConfiguredByName={serviceConfiguredByName}
          />
        </div>
      </div>
    )
  } else if (isPerformingInitialSync) {
    return (
      <HRISContainer>
        <HRISPreparingData
          serviceName={serviceName}
          serviceSquareImage={serviceSquareImage}
        />
      </HRISContainer>
    )
  } else if (isConnected) {
    // HRIS Configuration view doesn't use HRISContainer because the
    // "Save and start sync" button is full width and outside the container
    return (
      <div tw="border-t border-primary-100">
        <Helmet>
          <title>HR Integration – Goody for Business</title>
        </Helmet>
        <HRISConfiguration
          integration={customerIntegration}
          refetchIntegration={refetch}
          isSyncing={isSyncing}
          setIsSyncing={setIsSyncing}
          isConnected={isConnected}
          setIsConnected={setIsConnected}
        />
      </div>
    )
  } else {
    // Handle integration confirmation
    const onIntegrationConnect = (isRippling?: boolean) => {
      if (isRippling) {
        setIsAwaitingRippling(true)
      } else {
        setIsLoading(true)
        setIsPerformingInitialSync(true)
      }

      refetch()
    }

    // The user must be an admin to connect an HR integration
    const userIsAdmin = !!user?.isOrganizationAdmin

    if (userIsAdmin) {
      return (
        <HRISContainer>
          <HRISConnect onIntegrationConnect={onIntegrationConnect} />
        </HRISContainer>
      )
    } else {
      return (
        <div tw="border-t border-primary-100">
          <Helmet>
            <title>HR Integration – Goody for Business</title>
          </Helmet>
          <div tw="bg-white container mx-auto relative h-screen">
            <HRISNoPermissions isOnConnect={true} />
          </div>
        </div>
      )
    }
  }
}

interface HRISContainerProps {
  children: ReactNode
}

const HRISContainer: React.FC<HRISContainerProps> = ({ children }) => {
  return (
    <div tw="border-t border-primary-100">
      <Helmet>
        <title>HR Integration – Goody for Business</title>
      </Helmet>
      <Container>{children}</Container>
    </div>
  )
}

export const CUSTOMER_INTEGRATION_QUERY = gql`
  query CustomerIntegration {
    me {
      customerIntegration(ttype: HRIS) {
        id
        status
        syncStage
        serviceInfo {
          name
          squareImage
          configuredBy
        }
        syncInfo {
          isInitialSync
          lastSyncedAt
          nextSyncAt
        }
        workspaceConfigurations {
          id
          name
          hrisTeams {
            hrisId
            nameHierarchy
            numEmployees
            isSynced
          }
        }
      }
    }
  }
`

export default HRISIntegration
