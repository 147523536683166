import { useEffect, useState } from "react"

import { useBrowseUrls } from "../../StoreHelpers"
import { useRestrictedProducts } from "../hooks/useRestrictedProducts"
import { ReactComponent as CustomCategorySvg } from "../images/custom-category.svg"

import { useFeatureAccess } from "@/common/hooks/featureAccess"
import CategoryLink from "@/store/categories/CategoryLink"
import SubcategoriesList from "@/store/categories/SubcategoriesList"
import { Store_CategoryCommonFragment } from "@/types/graphql-types"

type SubcategoryItem = {
  slug: "products" | "stores"
  name: string
}

const CUSTOM_CATEGORY = {
  name: "Custom",
  icon: <CustomCategorySvg tw="fill-current h-6 w-5" />,
}

interface Props {
  useVirtualUrl?: boolean
  isEmbeddedCustomStore: boolean
  isCustomCategorySelected: boolean
  isDisabled: boolean
  onClick: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
}

export const CustomCategoryItem = ({
  useVirtualUrl,
  isEmbeddedCustomStore,
  isCustomCategorySelected,
  isDisabled,
  onClick,
}: Props) => {
  const { generateBrowseUrl, pushPath, customPath } =
    useBrowseUrls(useVirtualUrl)
  const selectedSubcategorySlug = customPath() || null

  const { hasFeature } = useFeatureAccess()
  const hasCustomStoreFeature = hasFeature("custom_stores")

  // For deciding if we have custom products or not we would only need one
  // product to be returned in here, but we are requesting 4 products to
  // match the same query being performed on `CustomProductsPreviewList`.
  // This is an optimization so we don't request 2 queries to the server
  // for the same data.
  const { products: restrictedProducts } = useRestrictedProducts({
    fetchPolicy: "cache-first",
    pageSize: 4,
  })
  const hasRestrictedProducts = !!restrictedProducts?.length

  const [subcategories, setSubcategories] = useState<
    Store_CategoryCommonFragment[]
  >([])

  const addSubcategory = (item: SubcategoryItem) => {
    setSubcategories((prev) => {
      if (!prev.find((prevItem) => prevItem.slug === item.slug)) {
        return [...prev, castToSubcategoryType(item)].sort().reverse()
      } else {
        return prev
      }
    })
  }

  useEffect(() => {
    if (hasRestrictedProducts) {
      addSubcategory({ slug: "products", name: "My products" })
    }
    if (hasCustomStoreFeature) {
      addSubcategory({ slug: "stores", name: "My custom stores" })
    }
  }, [hasCustomStoreFeature, hasRestrictedProducts])

  return (
    <div tw="pb-6">
      <CategoryLink
        selected={isCustomCategorySelected}
        selectedSubcategorySlug={selectedSubcategorySlug}
        disabled={isDisabled}
        onClick={onClick}
        key={CUSTOM_CATEGORY.name}
        href={generateBrowseUrl({
          categoryIsCustom: true,
        })}
      >
        {CUSTOM_CATEGORY.icon}
        <div tw="flex-1 lg:py-2">{CUSTOM_CATEGORY.name}</div>
      </CategoryLink>
      {!isEmbeddedCustomStore && (
        <SubcategoriesList
          tw="hidden lg:block"
          isSelected={isCustomCategorySelected}
          isCustomCategory
          subcategories={subcategories}
          selectedSubcategorySlug={selectedSubcategorySlug}
          onSubcategoryClick={(subcategory) => {
            pushPath(
              generateBrowseUrl({
                customSlug: subcategory.slug,
                categoryIsCustom: true,
              }),
            )
          }}
        />
      )}
    </div>
  )
}

const castToSubcategoryType = (
  item: SubcategoryItem,
): Store_CategoryCommonFragment => {
  return {
    ...item,
    __typename: "Category",
    id: `custom-${item.slug}`,
    isAlcohol: false,
    pageHeading: null,
    pageDescription: null,
    seoTitle: null,
  }
}
