import { useState } from "react"

const LOCALSTORAGE_KEY = "__goody_developer_mode"
const LOCALSTORAGE_TRUE_VALUE = "true"

export default function useDeveloperMode() {
  const [enabled, setEnabled] = useState(
    window.localStorage.getItem(LOCALSTORAGE_KEY) === LOCALSTORAGE_TRUE_VALUE,
  )

  const enable = () => {
    setEnabled(true)
    window.localStorage.setItem(LOCALSTORAGE_KEY, LOCALSTORAGE_TRUE_VALUE)
  }

  const disable = () => {
    setEnabled(false)
    window.localStorage.removeItem(LOCALSTORAGE_KEY)
  }

  return { enabled, enable, disable }
}
