import { isArray } from "lodash-es"
import React, { useMemo } from "react"
import { Redirect, matchPath, useHistory } from "react-router-dom"

import { Realm, generateRealmPath, getRealmFromPath, useRealm } from "./realm"
import { TaggedRoute } from "./TaggedRoute"

type Props = React.ComponentProps<typeof TaggedRoute> & {
  realm?: Realm | Realm[]
  noEmbed?: boolean
}

// Route inside react router that generates paths for different realms
// Generates paths with the passed in path combined with the realm.
// For example: If "plus" is the realm and "/send" is the path, will
// generate a route "/plus/send".
const RealmRoute = ({
  realm,
  path,
  children,
  noEmbed,
  ...restProps
}: Props) => {
  const history = useHistory()
  const paths = isArray(path) ? path : path ? [path] : []
  const realms = isArray(realm) ? realm : [realm]

  const routePaths = useMemo(() => {
    const newPaths: string[] = []
    realms.forEach((realm) => {
      paths.forEach((path) => {
        newPaths.push(generateRealmPath(realm || null, path))
      })
    })
    return newPaths
  }, [paths.join(","), realms.join(",")])

  useRealm(realm || null, { ...restProps, path: routePaths })

  return (
    <TaggedRoute {...restProps} path={routePaths}>
      {noEmbed &&
      window.self !== window.top &&
      matchPath(history.location.pathname, {
        path: routePaths,
        ...restProps,
      }) ? (
        <Redirect
          to={generateRealmPath(
            getRealmFromPath(history.location.pathname),
            "/",
          )}
        />
      ) : (
        children
      )}
    </TaggedRoute>
  )
}

export default RealmRoute
