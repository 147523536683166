import { createLocation } from "history"
import { omit } from "lodash-es"
import React from "react"
import { Link, NavLink, matchPath, useLocation } from "react-router-dom"

// copied logic from here: https://github.com/remix-run/react-router/blob/v5-website/packages/react-router-dom/modules/NavLink.js

const resolveToLocation = (to: any, currentLocation: any) =>
  typeof to === "function" ? to(currentLocation) : to

const normalizeToLocation = (to: any, currentLocation: any) => {
  return typeof to === "string"
    ? createLocation(to, null, undefined, currentLocation)
    : to
}

interface ExtraProps {
  blockReclicks?: boolean
}

export const ConditionalNavLink: React.FC<
  React.ComponentProps<typeof NavLink> & ExtraProps
> = (props) => {
  const {
    exact,
    isActive: isActiveProp,
    location: locationProp,
    strict,
    to,
    blockReclicks,
  } = props

  const location = useLocation()

  if (!blockReclicks) {
    return <NavLink {...omit(props, "blockReclicks")} />
  }

  const currentLocation = locationProp || location
  const toLocation = normalizeToLocation(
    resolveToLocation(to, currentLocation),
    currentLocation,
  )
  const { pathname: path } = toLocation
  // Regex taken from: https://github.com/pillarjs/path-to-regexp/blob/master/index.js#L202
  const escapedPath = path && path.replace(/([.+*?=^!:${}()[\]|/\\])/g, "\\$1")

  const match = escapedPath
    ? matchPath(currentLocation.pathname, {
        path: escapedPath,
        exact,
        strict,
      })
    : null
  const isActive = !!(isActiveProp
    ? isActiveProp(match, currentLocation)
    : match)

  let { onClick, ...restProps } = props
  restProps = omit(restProps, "blockReclicks")

  return (
    <NavLink
      {...restProps}
      onClick={(e) => {
        if (isActive) {
          e.preventDefault()
        } else if (onClick) {
          onClick(e)
        }
      }}
    />
  )
}

export const ConditionalLink: React.FC<
  React.ComponentProps<typeof Link> & ExtraProps
> = (props) => {
  const { to, blockReclicks } = props

  const currentLocation = useLocation()

  if (!blockReclicks) {
    return <NavLink {...props} />
  }

  const toLocation = normalizeToLocation(
    resolveToLocation(to, currentLocation),
    currentLocation,
  )
  const { pathname: path } = toLocation
  // Regex taken from: https://github.com/pillarjs/path-to-regexp/blob/master/index.js#L202
  const escapedPath = path && path.replace(/([.+*?=^!:${}()[\]|/\\])/g, "\\$1")

  const match = escapedPath
    ? matchPath(currentLocation.pathname, {
        path: escapedPath,
      })
    : null
  const isActive = !!match

  const { onClick, ...restProps } = props

  return (
    <NavLink
      {...restProps}
      onClick={(e) => {
        if (isActive) {
          e.preventDefault()
        } else if (onClick) {
          onClick(e)
        }
      }}
    />
  )
}
