import tw, { styled } from "twin.macro"

import Header from "./Header"
import { LoginProvider } from "./LoginProvider"
import Footer from "../../common/Footer"
import { useGlobalState } from "../../common/GlobalState"
import { useIsBusinessSignup } from "../../signUp/hooks/businessSignUp"

interface Props {
  className?: string
}
const PublicSite: React.FC<Props> = ({ children, className }) => {
  const [signedIn] = useGlobalState("signedIn")
  const { isBusinessSignupPath } = useIsBusinessSignup()

  return (
    <>
      <LoginProvider>
        <Header />
      </LoginProvider>
      <Main className={className}>{children}</Main>
      <div tw="container mx-auto">
        <Footer />
      </div>
    </>
  )
}

const Main = styled.div`
  ${tw`mt-14 lg:mt-0`};

  .is-business & {
    margin-top: 4.875rem;
    ${tw`lg:mt-0`};
  }
`

export default PublicSite
