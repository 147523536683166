import { GiftTemplateType } from "../../send/components/giftTemplates/GiftTemplatesMenu"
import { HeaderImageProps } from "../../store/custom/common"
import { ProductImageType } from "../../store/ProductImage"
import { PaymentMethod } from "../PaymentMethodsManager"
import { Realm } from "../realm"

import { WebflowBlogPost } from "@/blog/utils"
import {
  AutogiftRuleAnchorDateSendOptionEnum,
  AutogiftRuleAnchorDateTypeEnum,
  AutogiftRuleStatus,
  BatchSendMethod,
  BrandValuesDisplay,
  EmailDeliveryMethods,
  GiftSwapTypeEnum,
  GiftsSettingsInput,
  InternationalShippingTierEnum,
  MultipleSelectModeEnum,
  PlusEnrollmentStatus,
  SalesforceGiftBatchDataInput,
  SalesforceRecipientDataInput,
  SendAudience,
  ShippingCountryGroupFragment,
  WorkspaceTtype,
  flex_gift_product_price_type,
  gift_meeting_setting,
} from "@/types/graphql-types"
import {
  AutogiftRuleProduct,
  BaseMeFragment,
  BaseOrganizationFragment,
  CustomStoreFullFragment,
  Details_ProductFragment,
} from "@/types/graphql-types"

export interface MailingAddress {
  address1: string
  address2: string | null
  city: string
  state: string
  postalCode: string
}

export interface MailingAddressWithName extends MailingAddress {
  firstName: string
  lastName: string
}

export interface BatchRecipient {
  id: string
  firstName: string
  lastName: string
  email: string
  phone: string
  mailingAddress?: MailingAddress | null
  scheduledEventId?: string | null
  scheduledEventKind?: string | null
  eventDate?: Date | null
  salesforceRecipientData?: SalesforceRecipientDataInput | null
  errors: {
    firstName?: string | null
    lastName?: string | null
    email?: string | null
    phone?: string | null
    mailingAddress?: string | null
  }
}

export type GiftGuideMode = "emailForm" | "showGuide"
export type SendPageMode = "createGiftBatch" | "updateGiftBatch"
export type ConsumerNewUserPaymentSelection =
  | "addCreditCardNow"
  | "addCreditCardLater"
export type BusinessSendStep = "start" | "recipients" | "review"

// We just have a single field on consumer send
export interface ConsumerRecipient {
  name: string
  recipientInfo: string
  mailingAddress: MailingAddressWithName | null
}

export interface CurrentWorkspace {
  id: string
  name: string
  ttype: WorkspaceTtype
}

export interface CurrentCustomStore {
  name: string
  subtitle: string
  desktopImage: HeaderImageProps
  mobileImage: HeaderImageProps
  optionList: CustomStoreFullFragment["optionList"]
  internationalShippingTier: InternationalShippingTierEnum
  settings: GiftsSettingsInput
  brandValuesDisplay: BrandValuesDisplay
  multipleSelectMode?: MultipleSelectModeEnum
  multipleSelectCount?: number
  multipleSelectPrice?: number
}

type BaseExpireAtOption = "none" | "twoWeeks" | "fourWeeks" | "sixWeeks"
export type ExpireAtOption = BaseExpireAtOption | "custom"
export type AutogiftExpireAtOption = BaseExpireAtOption
export type RecipientType = "link" | "manual"

export interface Product {
  id: string
  name: string
  brandName: string
  brandID: string
  brandSlug?: string
  brandFreeShippingMinimum: number | null
  giftOptionSlug?: string
  slug?: string
  priceProduct: number
  priceShipping: number
  imageThumb: ProductImageType | null
  imageLarge: ProductImageType | null
  isAlcohol: boolean
  isFlexGift: boolean
  isBook: boolean
  forceSwapEnabled: boolean
  flexGiftPrice?: number
  bookData?: {
    isbn?: string | null
    ean?: string | null
    title?: string | null
    contributors?: string | null
    image?: string | null
    imageAvailable?: boolean | null
  }
  brandHideMadeBySubtitle: boolean
  giftCardAmount?: number
  giftCardCurrency?: string
  priceType?: flex_gift_product_price_type | null
  customStore?: Details_ProductFragment["customStore"]
  quantity: number
  referringCategoryID: string | null
  referringSearchRecordID: string | null
  cartEligible: boolean
  shippingCountryGroup: ShippingCountryGroupFragment
  // List of variants selected for this product.
  // Can be null if no variants are selected (i.e. "let recipient choose").
  variants: string[] | null
  cartTags?: string[] | null
  imagesScalable?: boolean
}

export interface CurrentGift {
  cart: Product[]
  card: {
    id: string
    image: string
  } | null
  fromName: string
  message: string
  batchName: string | null
  expiresAt: Date | null
  expireAtOption: ExpireAtOption
  customExpirationDate: Date
  autopayPaymentMethodName: string | null
  autopayPaymentMethodID: string | null
  autopayEnabled: boolean
  paymentMethod: PaymentMethod | null
  swapType: GiftSwapTypeEnum
  landingPageEnabled: boolean
  landingPageSendNotifs: boolean
  scheduledSendOnOption: boolean
  scheduledSendOnDate: Date
  scheduledSendOnTime: string
  giftBatchId: string | null
  emailDeliveryMethod: EmailDeliveryMethods | null | undefined
  userPublicId: string | null
  recipientType: RecipientType
  isGiftDispenser: boolean
  giftDispenserQuantity: number | null
  isSalesforceSend?: boolean | null
  salesforceGiftBatchData?: SalesforceGiftBatchDataInput | null
  internationalShippingTier: InternationalShippingTierEnum
  settings: GiftsSettingsInput
  referringCategoryID: string | null
  giftCalendarSetting: gift_meeting_setting | null
  isAutogift: boolean | null
  consumerNewUserPaymentSelection: ConsumerNewUserPaymentSelection
  businessSendStep: BusinessSendStep
  audience: SendAudience | null
  sendMethod: BatchSendMethod | null
  lastSelectedLinkSendMethod: BatchSendMethod
  omitCredit: boolean | null
  customEmailSubject: string | null | undefined
  giftTemplate: GiftTemplateType | null
  isSmartLink: boolean
  smartLinkQuantity: number | null
  smartLinkApprovalRequired: boolean
  hasRecentlyAddedBalance: boolean
  hasRecentlyAddedCreditCard: boolean
  topUpAmount: number | null
  topUpPaymentMethod: PaymentMethod | null
}

export enum AutogiftRuleTenureClassification {
  ALL = "ALL",
  YEAR_SINGLE = "YEAR_SINGLE",
  YEAR_RANGE = "YEAR_RANGE",
}

export interface CurrentAutogiftRule {
  id: string | null
  contactListId: string | null
  contactListName: string | null
  eventType: string
  status: AutogiftRuleStatus
  productList: AutogiftRuleProduct[]
  card: {
    id: string
    image: string
  } | null
  fromName: string
  message: string
  expireAtOption: AutogiftExpireAtOption
  autopayPaymentMethodName?: string | null
  autopayPaymentMethodID?: string | null
  swapType: GiftSwapTypeEnum
  userPublicId: string | null
  maximumProductPrice: number | null
  processingFee: number | null
  estimatedTaxLow: number | null
  estimatedTaxHigh: number | null
  estimatedTotalLow: number | null
  estimatedTotalHigh: number | null
  flexGiftPrice: number | null
  tenureClassification: AutogiftRuleTenureClassification
  tenureMin?: number | null
  tenureMax?: number | null
  contactsWithEventToday: string[]
  anchorDateSendOption?: AutogiftRuleAnchorDateSendOptionEnum | null
  anchorDateType?: AutogiftRuleAnchorDateTypeEnum | null
  anchorDateNumberOfDaysDelta?: number | null
  internationalShippingTier: InternationalShippingTierEnum
  settings: GiftsSettingsInput
}

interface storeSelections {
  flexGiftPrice?: number
}

export interface ConsumerSignUpFields {
  firstName: string
  lastName: string
  email: string
  phone: string
}

export interface GlobalStateType {
  selectedCategoryName: string | null
  isCustomCategorySelected: boolean
  signedIn: boolean
  enrollmentStatus: PlusEnrollmentStatus | null
  user: BaseMeFragment | null
  organization: BaseOrganizationFragment | null
  openLogin: boolean
  consumerCurrentGift: CurrentGift
  businessCurrentGift: CurrentGift
  currentAutogiftRule: CurrentAutogiftRule
  currentCustomStore: CurrentCustomStore
  recipients: BatchRecipient[]
  consumerRecipient: ConsumerRecipient
  currentWorkspace: CurrentWorkspace | null
  giftGuideMode: GiftGuideMode
  giftGuideEnteredEmail: string | null
  promoCode: string | null
  sendPageMode: SendPageMode
  sendPageRecipientIndex: number
  storeSelections: storeSelections
  isAuthorizeGmailModalOpen: boolean
  currentRealm: Realm | null
  isProPlanModalOpen: boolean
  isLearnAboutFullVsLimitedModalOpen: boolean
  isLimitedMemberFeatureGateModalOpen: boolean
  isBusinessWelcomeModalOpen: boolean
  eagerLoadedPost: WebflowBlogPost | null
  referringCategoryID: string | null
  consumerSignUpFields: ConsumerSignUpFields
  realmChoiceModalOpen: boolean
  referringSearchRecordID: string | null
  hideMenuItems: boolean
}
