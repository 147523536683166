import tw, { styled } from "twin.macro"

import PurpleGradientLink from "../../../common/PurpleGradientLink"
import RainbowHeading from "../../../common/RainbowHeading"
import { generateRealmPath } from "../../../common/realm"
import staticAssetUrl from "../../../common/staticAssetUrl"

const CustomCollectionsDesktop = staticAssetUrl(
  "landing/how_it_works/custom_collections/CustomCollectionsDesktop.png",
)
const CustomCollectionsMobile = staticAssetUrl(
  "landing/how_it_works/custom_collections/CustomCollectionsMobile.png",
)

const Hero = () => (
  <Container>
    <div tw="flex mx-auto flex-col lg:flex-row max-w-[1200px]">
      <div tw="pt-20 max-w-[580px] mx-auto z-10">
        <RainbowHeading tw="font-bold mb-4 text-2xl">
          Custom Collections
        </RainbowHeading>
        <div tw="text-left font-bold mb-4 text-4xl lg:text-[3rem] lg:leading-[3.5rem]">
          Build a Customized
          <br />
          Gift Collection
        </div>
        <div tw="text-xl lg:text-2xl text-gray-600 leading-normal lg:leading-9 text-left pb-4 lg:ml-0 lg:max-w-full pr-0">
          Build your own gift collection for recipients, and fill it with
          branded swag and trendy gifts.
        </div>
        <div tw="text-lg lg:text-xl text-gray-600 leading-normal lg:leading-9 text-left pb-4 lg:ml-0 lg:max-w-full mb-4 lg:mb-8 pr-0">
          Available on Goody for Teams plans
        </div>
        <PurpleGradientLink
          tw="w-full lg:w-auto"
          to={generateRealmPath("business", "/business/team-demo")}
        >
          Book a Demo
        </PurpleGradientLink>
      </div>
      <div tw="flex items-center justify-center pt-8 z-10">
        <img tw="lg:hidden w-[28rem]" src={CustomCollectionsMobile} />
        <img tw="hidden lg:block w-full" src={CustomCollectionsDesktop} />
      </div>
    </div>
  </Container>
)

const Container = styled.div`
  position: relative;
  background-color: #ffffff;
  ${tw`px-8 pb-4 lg:pb-0 lg:pr-0 h-full lg:h-[580px]`}

  &:after {
    ${tw`hidden lg:block`}
    content: "";
    background-color: white;
    position: absolute;
    height: 8rem;
    width: 120%;
    border-radius: 50%;
    bottom: -10%;
    left: -10%;
    right: -10%;
  }
`

export default Hero
