import tw, { styled } from "twin.macro"

const MenuGroup = styled.div`
  ${tw`p-2`}
  &:not(:last-child) {
    ${tw`border-b border-gray-100`}
  }
`

export default MenuGroup
