import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useReducer,
} from "react"

// TODO: simplify

type ActionType = { type: "OPEN_CART" } | { type: "CLOSE_CART" }

const initialState = {
  cartOpen: false,
}

type StateType = typeof initialState

type CartDrawerType = StateType & {
  openCart: () => void
  closeCart: () => void
}

// @ts-ignore
export const CartDrawerContext = createContext<CartDrawerType>()

const CartDrawerReducer = (state: StateType, action: ActionType): StateType => {
  const { type } = action
  switch (type) {
    case "OPEN_CART":
      return {
        ...state,
        cartOpen: true,
      }
    case "CLOSE_CART":
      return {
        ...state,
        cartOpen: false,
      }
    default:
      throw new Error(`No case for type ${type} found in UIReducer.`)
  }
}

export const CartDrawerProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(CartDrawerReducer, initialState)

  const openCart = useCallback(
    () => dispatch({ type: "OPEN_CART" }),
    [dispatch],
  )
  const closeCart = useCallback(
    () => dispatch({ type: "CLOSE_CART" }),
    [dispatch],
  )

  const value = {
    cartOpen: state.cartOpen,
    openCart,
    closeCart,
  }

  return (
    <CartDrawerContext.Provider value={value}>
      {children}
    </CartDrawerContext.Provider>
  )
}

export const useCartDrawer = () => {
  const context = useContext(CartDrawerContext)

  if (context === undefined) {
    throw new Error("useCartDrawer must be used within CartDrawerContext")
  }

  return context
}
