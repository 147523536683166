import React from "react"

import { WebflowBlogPost, mountUrl, setImageUrlVariation } from "./utils"

interface IHero {
  posts: WebflowBlogPost[]
  goToPost: (
    e: React.MouseEvent<HTMLAnchorElement>,
    post: WebflowBlogPost,
  ) => void
}

interface IPostParams {
  post: WebflowBlogPost
  goToPost: (
    e: React.MouseEvent<HTMLAnchorElement>,
    post: WebflowBlogPost,
  ) => void
}

const Hero = ({ posts, goToPost }: IHero) => {
  const featured = posts.find((post) => post.isFeatured)
  const recentHits = posts.filter((post) => post.isRecentHit)

  return (
    <div className="hero-blog-landing" tw="pt-16!">
      <div className="container">
        <div className="title-centre">
          <div className="blog-page-heading">
            <h2 className="blog-heading__1">
              Goody <br />
              Knows
            </h2>
            <div className="blog-heading-decoration">
              <div className="blog-heading__line" />
              <div className="blog-heading__line line2" />
              <img
                src="https://assets.website-files.com/61be62f73beca575b799e3f5/61be62f73beca5594c99e501_goody-gift-icon.svg"
                loading="lazy"
                width={16}
                height={16}
                alt=""
                className="blog-heading__icon"
              />
            </div>
            <h2 className="blog-heading__2">
              What's
              <br />
              good!
            </h2>
          </div>
          <div className="blog-hero-details" tw="font-text">
            A behind the scenes look at #ongoody brands, gift guides, culture
            and inspo &amp; learnings from our community.
            <br />
          </div>
        </div>
        <div className="blog-upper">
          <div className="blog-featured-container">
            <h2 className="blog-column-heading">Featured</h2>
            <div className="w-dyn-list">
              <div role="list" className="w-dyn-items">
                {featured && <Featured goToPost={goToPost} post={featured} />}
              </div>
            </div>
          </div>
          <div className="blog-recent-container">
            <h2 className="blog-column-heading recent-hits-heading">
              Recent Hits
            </h2>
            <div className="w-dyn-list">
              <div role="list" className="w-dyn-items">
                {recentHits.slice(0, 2).map((post) => (
                  <RecentHit goToPost={goToPost} key={post.title} post={post} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const Featured = ({ post, goToPost }: IPostParams) => {
  return (
    <>
      <div role="listitem" className="blog-featured-item w-dyn-item">
        <a
          tw="cursor-pointer"
          href={mountUrl(post)}
          onClick={(e) => {
            goToPost(e, post)
          }}
          className="w-inline-block"
        >
          {post.thumbnailUrl && (
            <img
              src={post.thumbnailUrl}
              alt=""
              className="blog-featured-image"
            />
          )}
          <h4 className="blog-featured-heading">{post.title}</h4>
          <p tw="font-text" className="blog-featured-excerpt">
            {post.excerpt}
          </p>
          <div className="readmore">
            <h4 className="blog-featured-readmore">Read More </h4>
            <img
              src="https://assets.website-files.com/61be62f73beca575b799e3f5/61be62f73beca569d899e505_arrow-right.svg"
              alt=""
            />
          </div>
        </a>
      </div>
    </>
  )
}

const RecentHit = ({ post, goToPost }: IPostParams) => {
  return (
    <div role="listitem" className="recent-item w-dyn-item">
      <a
        href={mountUrl(post)}
        onClick={(e) => goToPost(e, post)}
        tw="cursor-pointer"
        className="w-inline-block"
      >
        <p className="read-label">{post.readLabel}</p>
        {post.thumbnailUrl && (
          <img
            src={setImageUrlVariation(post.thumbnailUrl, 500)}
            alt=""
            className="blog-recent-image"
          />
        )}
        <h4 className="blog-recent-heading">{post.title}</h4>
        <p tw="font-text" className="blog-recent-excerpt">
          {post.excerpt}
        </p>
      </a>
    </div>
  )
}

export default Hero
