import { useRef } from "react"
import Modal from "react-modal"
import QRCode from "react-qr-code"
import tw from "twin.macro"

import Close from "./icons/close.svg"
import Download from "./icons/download.svg"

import { buttonStyle } from "@/common/GradientButton"
import { modalStyle } from "@/common/modal"
import { downloadSvg } from "@/common/utils/svgs"

interface Props {
  isOpen: boolean
  setOpen: (open: boolean) => void
  href: string | null
  filename: string
}

export const GiftLinkQRCodeModal = ({
  isOpen,
  setOpen,
  href,
  filename,
}: Props) => {
  const qRCodeRef = useRef<SVGSVGElement | null>(null)

  return (
    <Modal
      isOpen={isOpen}
      closeTimeoutMS={500}
      shouldCloseOnOverlayClick={true}
      onRequestClose={() => setOpen(false)}
      style={modalStyle}
    >
      {href && (
        <div
          className="modal-content-no-width"
          tw="p-4 flex flex-col items-stretch"
        >
          {/* @ts-ignore - can't get the ref typescript to work for some reason */}
          <QRCode ref={qRCodeRef} size={256} value={href} tw={"p-8"} />
          <button
            css={buttonStyle}
            tw={
              "bg-primary-new-550 justify-center gap-2 text-base h-auto py-2.5"
            }
            onClick={() => downloadSvg(qRCodeRef.current, filename)}
          >
            <img src={Download} alt={"Download"} />
            Download
          </button>
          <div tw={"h-3"} />
          <button
            css={buttonStyle}
            tw={
              "bg-white border border-gray-200 text-[#4B5563] justify-center gap-2 text-base h-auto py-2.5"
            }
            onClick={() => setOpen(false)}
          >
            <img src={Close} alt={"Close"} />
            Close
          </button>
        </div>
      )}
    </Modal>
  )
}

const ModalButton = tw.button`

`
