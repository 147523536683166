import tw, { styled } from "twin.macro"

import { ProductImage } from "../ProductImage"

import { formatPrice } from "@/common/format"
import {
  Store_PDP_ProductDetailsFragment,
  Store_PDP_RelatedProductsQuery,
} from "@/types/graphql-types"

interface Props {
  product: Store_PDP_ProductDetailsFragment
  primaryGiftOption?: NonNullable<
    Store_PDP_RelatedProductsQuery["productDetails"]
  >["brand"]["primaryGiftOption"]
  generatePdpUrl: (params: { productSlug: string; brandSlug: string }) => string
  scrollToTop?: () => void
  setPDPProduct: (params: {
    slug: string
    brand: { slug: string; name: string }
  }) => void
}

export default function ProductMoreProductsFromBrand({
  product,
  primaryGiftOption,
  generatePdpUrl,
  scrollToTop,
  setPDPProduct,
}: Props) {
  const { brand } = product
  const products = (primaryGiftOption?.products ?? []).filter(
    (p) => p.id !== product.id,
  )

  if (products.length === 0) {
    return null
  }

  return (
    <div tw="flex flex-col items-center mt-10 px-5 lg:px-0 w-full">
      <div tw="font-medium mb-6 text-gray-500">More from {brand.name}</div>
      <MoreProducts>
        {primaryGiftOption?.products.map((relatedProduct) =>
          relatedProduct.slug === product.slug ? null : (
            <MoreProductsItem
              key={relatedProduct.slug}
              onClick={(e) => {
                e.preventDefault()
                setPDPProduct({
                  slug: relatedProduct.slug,
                  brand: {
                    slug: brand.slug,
                    name: brand.name,
                  },
                })
                scrollToTop && scrollToTop()
              }}
              tw="cursor-pointer"
              href={generatePdpUrl({
                productSlug: relatedProduct.slug,
                brandSlug: brand.slug,
              })}
            >
              <ProductImage
                alt={relatedProduct.name}
                image={relatedProduct.productImages[0].imageXmedium}
                scalable={product.imagesScalable}
                tw="rounded-xl"
                css={{ aspectRatio: "5 / 4", width: 460 }}
              />
              <div tw="pt-4 font-medium">{relatedProduct.name}</div>
              <div tw="flex items-center justify-start pt-1">
                <div>{formatPrice(relatedProduct.price)}</div>
              </div>
            </MoreProductsItem>
          ),
        )}
      </MoreProducts>
    </div>
  )
}

const MoreProducts = styled.div`
  ${tw`lg:px-12 grid lg:grid-cols-3 xl:grid-cols-4 gap-8`};
`

const MoreProductsItem = styled.a`
  ${tw`flex flex-col justify-between overflow-hidden transition transform hover:-translate-y-1 active:scale-95`};
`
