import { capitalize, lowerCase } from "lodash-es"
import moment from "moment"
import { Link } from "react-router-dom"
import tw, { styled } from "twin.macro"

import { ReactComponent as AutogiftIcon } from "../assets/icons/autogift-gray.svg"
import { ReactComponent as EditIcon } from "../assets/images/calendar/edit-icon.svg"
import { ReactComponent as ViewArrowIcon } from "../assets/images/calendar/view-arrow.svg"
import { generateRealmPath } from "../common/realm"

import { Dashboard_UpcomingEventsQuery } from "@/types/graphql-types"

type UpcomingEvent = NonNullable<
  NonNullable<Dashboard_UpcomingEventsQuery["me"]>["upcomingEvents"]
>[0]

interface EventGiftProps {
  event: UpcomingEvent
  openGiftBatchEditModal: (event: UpcomingEvent) => void
  giftSent: boolean
}

// Currently is only used for sent gifts
// TODO - Clarify if the "not sent" statuses are needed here since this function is only called for sent gifts
function giftStatusDisplay(
  giftBatch: UpcomingEvent["giftBatch"],
  giftSent: boolean,
) {
  if (!giftBatch) {
    return ""
  }

  // If gift is sent, use GiftViewableStatus
  if (giftSent) {
    const status = giftBatch.gifts[0]?.status

    switch (status) {
      case "NOTIFIED":
        return "Gift sent"
      case "OPENED":
        return "Gift opened"
      default:
        return capitalize(lowerCase(status))
    }
  }

  // If gift is not yet set, use GiftBatchSendStatus
  const status = giftBatch.sendStatus
  switch (status) {
    case "PENDING":
      return "Scheduled"
    default:
      return capitalize(lowerCase(status))
  }
}

// Format gift message by limiting message to 2 lines and displaying ellipsis when needed
// If message contains a new line, convert new line into separate div
function giftMessageDisplay(message: string) {
  const splitMessage = message.split("\n")
  const maxCharactersInLine = 70

  return (
    <div>
      {splitMessage.length === 1 ? (
        <div>
          {message.substring(0, maxCharactersInLine * 2)}
          {message.length > maxCharactersInLine * 2 && <div>...</div>}
        </div>
      ) : (
        <div>
          <div>{splitMessage[0]}</div>
          <div>{splitMessage[1].substring(0, maxCharactersInLine)}</div>
          {splitMessage[1].length > maxCharactersInLine && <div>...</div>}
        </div>
      )}
    </div>
  )
}

const EventGift = ({
  event,
  openGiftBatchEditModal,
  giftSent,
}: EventGiftProps) => {
  if (!event?.giftBatch) {
    return null
  }

  // This is using only the first product in the cart to keep
  // the same behavior we had before implementing Goody Bag.
  const productFragment = event.giftBatch.cartProducts[0].productFragment

  return (
    <EventGiftContainer className={event.kind}>
      <div tw="flex items-center flex-col xl:flex-row justify-between w-full">
        <div
          tw="flex items-center self-start"
          css={[giftSent && tw`px-2 xl:px-0`]}
        >
          {!!event.giftBatch?.customStoreImageThumb ? (
            <ProductImageContainer>
              <img
                alt={productFragment.name}
                src={event.giftBatch.customStoreImageThumb.url}
                tw="object-cover w-9 h-9"
                css={[!giftSent && tw`rounded-lg`]}
              />
            </ProductImageContainer>
          ) : (
            productFragment.primaryImage?.imageThumb && (
              <ProductImageContainer>
                <img
                  src={productFragment.primaryImage.imageThumb.url}
                  tw="object-cover w-9 h-9"
                  css={[!giftSent && tw`rounded-lg`]}
                  alt={!giftSent ? "product-thumb" : ""}
                />
              </ProductImageContainer>
            )
          )}
          <div tw="pb-4 xl:pb-0 pl-3 md:pl-4 pr-2">
            {event.giftBatch.isAutogift && (
              <div tw="flex flex-row items-center">
                <DarkGrayDetails tw="font-medium">Autogift</DarkGrayDetails>
                <div tw="ml-2">
                  <AutogiftIcon />
                </div>
              </div>
            )}
            <DarkGrayDetails>
              {`${productFragment.brandName} — ${productFragment.name}`}
            </DarkGrayDetails>
            {!event.giftBatch.isAutogift && (
              <LightGrayDetails
                className={
                  giftSent ? "data-hj-suppress ph-no-capture fs-mask" : ""
                }
              >
                {giftSent ? "Sent " : "Scheduled "}
                {`${moment(event.giftBatch.createdAt).fromNow()} by ${event
                  .giftBatch?.fromName}`}
              </LightGrayDetails>
            )}
            {giftSent && (
              <DarkGrayDetails tw="flex xl:hidden">
                {giftStatusDisplay(event.giftBatch, giftSent)}
              </DarkGrayDetails>
            )}
            {!giftSent &&
            !moment(event.giftBatch.scheduledSendOn).isSame(
              moment(event.relevantRecurrence),
              "day",
            ) ? (
              <LightGrayDetails>
                {`Sending on ${moment(event.giftBatch.scheduledSendOn).format(
                  "dddd, MMMM DD, YYYY",
                )}`}
              </LightGrayDetails>
            ) : null}
          </div>
        </div>
        <div tw="flex items-center justify-center self-stretch xl:self-center">
          {giftSent ? (
            <>
              <DarkGrayDetails tw="hidden xl:flex pr-3">
                {giftStatusDisplay(event.giftBatch, giftSent)}
              </DarkGrayDetails>
              <Link
                to={generateRealmPath("plus", `/track/${event?.giftBatch?.id}`)}
                tw="w-full"
              >
                <ViewButton className={event.kind}>
                  <div tw="p-2 px-3">View</div>
                  <div tw="pr-3">
                    <StyledViewArrowIcon className={event.kind} />
                  </div>
                </ViewButton>
              </Link>
            </>
          ) : (
            <ViewButton
              className={event.kind}
              onClick={() => {
                openGiftBatchEditModal(event)
              }}
            >
              <div tw="p-2 pr-3">Edit</div>
              <div tw="pr-2">
                <StyledEditIcon />
              </div>
            </ViewButton>
          )}
        </div>
      </div>
      {event.giftBatch.isAutogift && (
        <CardAndMessageContainer>
          <CardImageContainer>
            <img
              src={event.giftBatch.cardFragment?.image.url}
              tw="object-cover rounded-lg"
              css={{ height: 50, width: 36 }}
              alt="card-thumb"
            />
          </CardImageContainer>
          <GiftMessage>
            {giftMessageDisplay(event.giftBatch.message)}
          </GiftMessage>
        </CardAndMessageContainer>
      )}
    </EventGiftContainer>
  )
}

const EventGiftContainer = styled.div`
  ${tw`flex flex-col items-start justify-center p-2 pt-4 lg:px-6 lg:py-4 lg:pb-4 rounded-b-xl w-full`};

  &.BIRTHDAY {
    background-color: rgba(163, 114, 223, 0.02);
  }

  &.WORK_ANNIVERSARY {
    background-color: rgba(47, 128, 237, 0.02);
  }

  img {
    border-radius: 6px;
  }
`

const ProductImageContainer = styled.div`
  ${tw`flex-shrink-0 self-start`};
  padding-top: 2px;
`

const DarkGrayDetails = styled.div`
  ${tw`text-sm`};
  color: #6b7280;
`

const LightGrayDetails = styled.div`
  ${tw`text-sm`};
  color: #9ca3af;
`

const ViewButton = styled.button`
  ${tw`text-sm rounded-lg flex items-center justify-center w-full`};

  &.BIRTHDAY {
    ${tw`bg-primary-050`};

    color: rgba(145, 89, 214, 0.6);

    &:hover {
      ${tw`bg-primary-100`};
    }

    &:active {
      ${tw`bg-primary-200`};
    }
  }

  &.WORK_ANNIVERSARY {
    background-color: #eff6ff;
    color: rgba(47, 128, 237, 0.6);

    &:hover {
      background: rgba(219, 234, 254, 1);
    }

    &:active {
      background: rgba(191, 219, 254, 1);
    }
  }
`

const StyledViewArrowIcon = styled(ViewArrowIcon)`
  &.BIRTHDAY {
    color: #9159d6;
  }

  &.WORK_ANNIVERSARY {
    color: #2f80ed;
  }
`

const StyledEditIcon = styled(EditIcon)`
  &.BIRTHDAY {
    color: #9159d6;
  }

  &.WORK_ANNIVERSARY {
    color: #2f80ed;
  }
`

const CardAndMessageContainer = styled.div`
  ${tw`flex flex-row items-start rounded-lg w-full mt-3 p-3`};
  border: 1px solid #e5e7eb;
  box-sizing: border-box;
`

const CardImageContainer = styled.div`
  ${tw`flex-shrink-0 self-start rounded-md`};
  padding-top: 2px;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.02))
    drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.06));
`

const GiftMessage = styled.div`
  ${tw`ml-3 text-sm`};
  color: #6b7280;
`

export default EventGift
