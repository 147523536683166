import { CURRENT_REALM_KEY } from "./realmConstants"
import { Realm } from "./realmTypes"

// Gets the current realm
export const getSavedRealm = (): Realm | null => {
  // First tries to get realm from the storage
  const sessionRealm = window.sessionStorage.getItem(CURRENT_REALM_KEY)
  if (sessionRealm) {
    return sessionRealm as Realm
  }

  // Then tries to get realm from localStorage. If we find it, set the realm
  // for the session.
  const localStorageRealm = window.localStorage.getItem(CURRENT_REALM_KEY)
  if (localStorageRealm) {
    window.sessionStorage.setItem(CURRENT_REALM_KEY, localStorageRealm)
    return localStorageRealm as Realm
  }

  return "business"
}
