import tw, { styled } from "twin.macro"

import { usePaymentMethods } from "@/common/hooks/paymentMethods"
import { PaymentMethod as PaymentMethodType } from "@/common/PaymentMethodsManager"
import { MiniPaymentMethod } from "@/common/payments/miniPaymentMethodSelector/MiniPaymentMethod"

interface Props {
  isExpanded: boolean
  onSelect: (paymentMethod: PaymentMethodType) => void
}
export const MiniPaymentMethodSelector = ({ isExpanded, onSelect }: Props) => {
  const { paymentMethods } = usePaymentMethods()

  return (
    <Container isExpanded={isExpanded}>
      <div tw="flex flex-col">
        {paymentMethods.map((paymentMethod) => {
          return (
            <MiniPaymentMethod
              paymentMethod={paymentMethod}
              onSelect={onSelect}
            />
          )
        })}
      </div>
    </Container>
  )
}

const Container = styled.div<{ isExpanded: boolean }>`
  ${tw`absolute mt-[4px] ml-[-13px] w-[268px] bg-white rounded-lg border border-gray-200 transition duration-300 ease-in-out`}
  box-shadow: 0px 2px 4px 0px rgba(183, 188, 197, 0.10);
  transform-origin: top left;
  ${({ isExpanded }) =>
    isExpanded ? tw`scale-100 opacity-100` : tw`scale-90 opacity-0`}
`
