import { gql } from "@apollo/client"

export const SHIPPING_COUNTRY_GROUP_FRAGMENT = gql`
  fragment ShippingCountryGroup on ShippingCountryGroup {
    domestic
    global
    passthrough
    globalRelay
    giftCard
    digital
  }
`
