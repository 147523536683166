import { isNil } from "lodash-es"
import React from "react"
import tw from "twin.macro"

import { RecipientFieldError, RecipientTextInput } from "./recipientInputs"
import RecipientStateInput from "./RecipientStateInput"
import { BatchRecipient } from "../../common/GlobalState"
import { usePlacesAutocomplete } from "../../common/hooks/usePlacesAutocomplete"

interface Props {
  recipient: BatchRecipient
  setField: (field: string, value: string) => void
  setFields: (fields: BatchRecipient["mailingAddress"]) => void
  sendV3?: boolean
}

export default function RecipientAddressInput({
  recipient,
  setField,
  setFields,
  sendV3,
}: Props) {
  const { autocompleteFieldRef } = usePlacesAutocomplete({
    onAutocompletePlace: (place) => {
      const { country, ...result } = place
      setFields({
        address1: result.address1 || "",
        address2: null,
        city: result.city || "",
        state: result.state || "",
        postalCode: result.postalCode || "",
      })
    },
  })

  const address2Collapsed = isNil(recipient.mailingAddress?.address2)

  return (
    <div css={[!sendV3 && tw`lg:pr-6`]}>
      <div
        tw="flex flex-row items-center gap-2"
        css={[!address2Collapsed && tw`flex-col lg:flex-row`]}
      >
        <RecipientTextInput
          value={recipient.mailingAddress?.address1}
          placeholder="Address"
          tw="flex-1"
          autoComplete="none"
          onChange={(e) => {
            setField("address1", e.target.value)
          }}
          className="data-hj-suppress ph-no-capture fs-exclude"
          ref={autocompleteFieldRef}
        />
        {address2Collapsed ? (
          <button
            tw="text-gray-400 hover:text-gray-500 active:scale-90 transition-all px-2"
            onClick={() => {
              setField("address2", "")
            }}
          >
            <span tw="hidden lg:inline">+ Address 2</span>
            <span tw="lg:hidden">+ Addr 2</span>
          </button>
        ) : (
          <RecipientTextInput
            value={recipient.mailingAddress?.address2!}
            placeholder="Address 2 (optional)"
            tw="flex-1"
            autoComplete="none"
            onChange={(e) => {
              setField("address2", e.target.value)
            }}
            className="data-hj-suppress ph-no-capture fs-exclude"
          />
        )}
      </div>
      <div tw="pt-2 flex flex-row items-stretch gap-2 flex-wrap lg:flex-nowrap">
        <RecipientTextInput
          value={recipient.mailingAddress?.city}
          placeholder="City"
          tw="w-full lg:w-auto lg:flex-1"
          autoComplete="none"
          onChange={(e) => {
            setField("city", e.target.value)
          }}
          className="data-hj-suppress ph-no-capture fs-exclude"
        />
        <RecipientStateInput
          value={recipient.mailingAddress?.state}
          onChange={(e) => {
            setField("state", e)
          }}
        />
        <RecipientTextInput
          value={recipient.mailingAddress?.postalCode}
          placeholder="ZIP"
          tw="flex-[0.5]"
          autoComplete="none"
          onChange={(e) => {
            setField("postalCode", e.target.value)
          }}
          className="data-hj-suppress ph-no-capture fs-exclude"
        />
      </div>

      {recipient.errors.mailingAddress && (
        <RecipientFieldError>
          {recipient.errors.mailingAddress}
        </RecipientFieldError>
      )}
    </div>
  )
}
