// See also src/contacts/components/ContactImport/shared.ts

// Order key/value data structure of all valid fields
// key: name of field
// name: name of field
// acceptedFormats: auto-match formats for the field after normalization.
//   We look at the header row, normalize, and see if it matches any of these fields
// toInputValue: changes the value of the field to the format expected by BatchRecipients
import { formatPhoneIfPossible } from "../../common/format"

export const FIELDS = new Map([
  [
    "First name",
    {
      name: "First name",
      extraLabel: "required",
      acceptedFormats: ["first", "firstname", "name"],
      inputName: "firstName",
    },
  ],
  [
    "Last name",
    {
      name: "Last name",
      acceptedFormats: ["last", "lastname"],
      inputName: "lastName",
    },
  ],
  [
    "Phone",
    {
      name: "Phone",
      acceptedFormats: ["phone", "phonenumber", "mobilephone", "cellphone"],
      inputName: "phone",
      toInputValue: (value: string) =>
        value.length > 0 ? formatPhoneIfPossible(value) : "",
    },
  ],
  [
    "Email",
    {
      name: "Email",
      acceptedFormats: ["email", "emailaddress"],
      inputName: "email",
    },
  ],
  [
    "Address 1",
    {
      name: "Address 1",
      acceptedFormats: ["address1", "address_1", "address 1", "address"],
      inputName: "address1",
      isMailingAddress: true,
    },
  ],
  [
    "Address 2",
    {
      name: "Address 2",
      acceptedFormats: ["address2", "address_2", "address 2"],
      inputName: "address2",
      isMailingAddress: true,
    },
  ],
  [
    "City",
    {
      name: "City",
      acceptedFormats: ["city"],
      inputName: "city",
      isMailingAddress: true,
    },
  ],
  [
    "State",
    {
      name: "State",
      acceptedFormats: ["state", "province"],
      inputName: "state",
      isMailingAddress: true,
    },
  ],
  [
    "Postal Code",
    {
      name: "Postal Code",
      acceptedFormats: [
        "zip",
        "zipcode",
        "zip_code",
        "postal",
        "postal_code",
        "postalcode",
      ],
      inputName: "postalCode",
      isMailingAddress: true,
    },
  ],
])

// Array of the field information
export const FIELD_LIST = Array.from(FIELDS.values())

// Mapping of all accepted formats to the name of the field
const FORMAT_LOOKUP: { [key: string]: string } = {}
FIELD_LIST.forEach((header) => {
  header.acceptedFormats.forEach((format) => {
    FORMAT_LOOKUP[format] = header.name
  })
})

// Type for all valid fields - "First name", "Last name", etc...
export type FieldType = (typeof FORMAT_LOOKUP)["name"]

// Find a field for a header row name
export const getSuggestedField = (userHeader: string) => {
  // Replace all non alpha characters and make lower case
  const normalized = userHeader.toLowerCase().replace(/[^a-zA-Z0-9]/g, "")
  return FORMAT_LOOKUP[normalized] ?? null
}
