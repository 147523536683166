import { useState } from "react"
import tw from "twin.macro"

import { CountrySelectorItem } from "./CountrySelectorItem"

import { ReactComponent as ChevronDown } from "@/common/images/chevron-down.svg"
import {
  ShippingCountry,
  ShippingCountryGroupEnum,
} from "@/types/graphql-types"

interface Props {
  selectedCountry: ShippingCountry
  onClick: (country: ShippingCountry) => void
  countries: ShippingCountry[]
}

const GlobalCountryDropdown = ({
  selectedCountry,
  onClick,
  countries,
}: Props) => {
  const [isExpanded, setIsExpanded] = useState(
    !!countries.find((country) => country.code === selectedCountry.code),
  )

  const onButtonClick = (country: ShippingCountry) => {
    setIsExpanded(false)
    onClick(country)
  }

  return (
    <>
      <CountrySelectorItem
        tw="w-min"
        country={{
          code: "GLOBAL",
          name: "Global",
          groups: [ShippingCountryGroupEnum.global],
        }}
        selected={selectedCountry.code === "GLOBAL"}
        onClick={onButtonClick}
      >
        <DropdownTrigger
          onClick={(event) => {
            event.preventDefault()
            event.stopPropagation()
            setIsExpanded(!isExpanded)
          }}
        >
          Expand
          <ChevronDown
            height={6}
            tw="ml-1 stroke-current text-gray-400 stroke-1 transition-transform"
            css={[isExpanded && tw`rotate-180`]}
          />
        </DropdownTrigger>
      </CountrySelectorItem>
      <DropdownContainer css={[isExpanded ? tw`max-h-[1000px]` : tw`max-h-0`]}>
        {countries
          .filter((c) => !(c.code === "GLOBAL"))
          .map((country) => (
            <CountrySelectorItem
              key={country.code}
              country={country}
              selected={selectedCountry.code === country.code}
              onClick={onClick}
            />
          ))}
      </DropdownContainer>
    </>
  )
}

const DropdownTrigger = tw.div`
  flex items-center border rounded-3xl
  text-sm text-gray-500 font-normal
  py-0.5 px-3 -ml-1 cursor-pointer
`

const DropdownContainer = tw.div`
  pl-9 overflow-hidden origin-top
  transition-[max-height] ease-in-out duration-300
`

export default GlobalCountryDropdown
