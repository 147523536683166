import { useMemo, useState } from "react"
import tw from "twin.macro"

import { CountryFlag } from "@/common/CountryFlag"
import { ShippingCountry } from "@/types/graphql-types"

interface Props {
  countries: ShippingCountry[]
  header: string
  slitView?: boolean
}

const InternationalShippingCountriesModalList = ({
  countries,
  header,
  slitView,
}: Props) => {
  const [searchText, setSearchText] = useState("")

  const filteredCountries = useMemo(() => {
    if (!searchText) {
      return countries
    } else {
      return countries.filter((country) =>
        country.name.toLowerCase().includes(searchText.toLowerCase().trim()),
      )
    }
  }, [countries, searchText])

  return (
    <div>
      <h1 tw="text-xl font-normal text-left px-7 pt-1.5 pb-5">{header}</h1>

      <div tw="flex items-center gap-4 px-3 mb-5">
        <input
          value={searchText}
          placeholder="Search"
          tw="appearance-none outline-none border-none rounded-xl placeholder-gray-500 bg-gray-075 py-2.5 px-5 flex-1"
          onChange={(e) => setSearchText(e.target.value)}
        />
      </div>

      <div
        tw="pb-5 pl-4 overflow-auto grid grid-flow-row-dense grid-cols-2 auto-rows-min items-start gap-y-4 text-left"
        css={[slitView ? tw`h-[200px]` : tw`h-[400px]`]}
      >
        {filteredCountries.map((country) => (
          <div key={country.code} tw="flex items-center gap-3 px-4">
            <CountryFlag code={country.code} size="m" />
            <span tw="text-black text-base font-normal">{country.name}</span>
          </div>
        ))}
      </div>
    </div>
  )
}

export default InternationalShippingCountriesModalList
