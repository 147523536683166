import React, { useEffect } from "react"
import Modal from "react-modal"

import { modalStyle } from "./modal"
import SmallButton from "./SmallButton"

interface Props {
  isOpen: boolean
  onConfirm: () => void
  onCancel: () => void
}

const ConfirmationModal: React.FC<Props> = ({
  isOpen,
  onConfirm,
  onCancel,
}) => {
  const [modalIsOpen, setModalIsOpen] = React.useState(false)

  const closeModal = () => {
    setModalIsOpen(false)
    onCancel()
  }

  const onSubmit = (onConfirm: () => void) => {
    onConfirm()
  }

  useEffect(() => {
    setModalIsOpen(isOpen)
  }, [isOpen])

  return (
    <Modal
      isOpen={modalIsOpen}
      closeTimeoutMS={500}
      onAfterClose={() => {}}
      shouldCloseOnOverlayClick={true}
      style={modalStyle}
    >
      <div className="modal-content">
        <div tw="p-6">
          <div tw="pb-3 text-center text-xl font-medium text-primary-500">
            Are you sure you want to discard your changes?
          </div>
          <div tw="pb-6 text-gray-500 text-center">
            Your gift batch won't be updated.
          </div>
          <div tw="flex justify-center">
            <div tw="mr-2">
              <SmallButton
                label={"No, go back"}
                onClick={closeModal}
                hideArrow={true}
                light={true}
              />
            </div>
            <SmallButton
              label={"Yes, discard"}
              onClick={() => onSubmit(onConfirm)}
              hideArrow={true}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ConfirmationModal
