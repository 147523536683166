import React from "react"
import tw, { styled } from "twin.macro"

type TextInputFieldProps = {
  message?: React.ReactNode
  error?: string
} & React.InputHTMLAttributes<HTMLInputElement>

const TextInputField: React.FC<TextInputFieldProps> = ({
  error,
  message,
  children,
  ...restProps
}) => (
  <TextInputContainer>
    <div className="input">
      <TextInput error={!!error} {...restProps} />
      {children}
    </div>
    {error ? (
      <TextInputError>{error}</TextInputError>
    ) : message ? (
      <TextInputMessage>{message}</TextInputMessage>
    ) : null}
  </TextInputContainer>
)

const TextInputContainer = styled.div`
  ${tw`flex flex-1 flex-col`}

  .input {
    ${tw`relative`}

    > :nth-child(2) {
      ${tw`absolute right-0 top-0 h-full p-5`}
    }
  }
`

interface TextInputProps {
  error?: boolean
}

export const TextInput = styled.input<TextInputProps>`
  ${tw`w-full p-4 border border-solid rounded-lg border-gray-200 focus:outline-none transition-colors`}
  ${({ error }) =>
    error ? tw`border-red-500` : tw`focus:border-primary-600 border-gray-200`}
`

const TextInputMessage = styled.div`
  ${tw`text-sm mt-2 opacity-50`}
`

export const TextInputError = styled.div.attrs({
  className: "text-input-error",
})`
  ${tw`text-sm mt-2 text-red-500`}
`

export default TextInputField
