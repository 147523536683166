import tw, { styled } from "twin.macro"

interface Props {
  headline: string
}
const AccountSection: React.FC<Props> = ({ headline, children }) => {
  return (
    <AccountSectionContainer>
      <h2>{headline}</h2>
      <div>{children}</div>
    </AccountSectionContainer>
  )
}

const AccountSectionContainer = styled.section`
  ${tw`flex flex-wrap lg:flex-nowrap pb-24`}

  > h2 {
    ${tw`pb-3 font-medium text-3xl w-1/4 min-w-full lg:min-w-min`}
  }

  > div {
    ${tw`flex flex-1`}
  }
`

export default AccountSection
