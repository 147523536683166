import React, { useEffect } from "react"
import { useLocation } from "react-router-dom"

const ScrollToTop: React.FC = () => {
  const { pathname } = useLocation()
  const lastPathname = React.useRef<string | null>(null)

  useEffect(() => {
    // Scroll to top when navigating to an editorial from the Holiday Gift Guide
    if (
      lastPathname.current?.startsWith("/browse/holiday-gifts") &&
      pathname.includes("/editorial")
    ) {
      window.scrollTo(0, 0)
      lastPathname.current = pathname
      return
    }

    // Within browse:
    // Pathnames starting with /browse and containing /gift-option or /browse
    // and should not have the scroll to top behavior
    if (
      lastPathname.current?.startsWith("/browse") &&
      ((pathname.startsWith("/browse") &&
        (pathname.includes("/gift-option") || pathname.includes("/browse"))) ||
        pathname.includes("/editorial"))
    ) {
      lastPathname.current = pathname
      return
    }

    if (
      lastPathname.current?.startsWith("/editorial") &&
      ((pathname.startsWith("/browse") &&
        (pathname.includes("/gift-option") || pathname.includes("/browse"))) ||
        pathname.includes("/editorial"))
    ) {
      lastPathname.current = pathname
      return
    }

    window.scrollTo(0, 0)
    lastPathname.current = pathname
  }, [pathname])

  return null
}

export default ScrollToTop
