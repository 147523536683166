import { generateRealmPath } from "../../../common/realm"
import FAQAccordion, { PurpleLink } from "../../components/FAQAccordion"

const FrequentlyAskedQuestions = () => {
  const sections = [
    {
      question: "Do I need a paid plan to send swag?",
      answer: (
        <>
          <p>
            Nope! You can send unlimited on-demand swag free on Goody’s Starter
            plan. To get started, simply{" "}
            <PurpleLink
              href={generateRealmPath("business", "/business/signup")}
            >
              sign up for Goody for Business
            </PurpleLink>{" "}
            and start designing your swag.
          </p>
        </>
      ),
    },
    {
      question: "Can Goody design custom boxes or branded kits? ",
      answer: (
        <>
          <p>
            Absolutely. We’ll work with you to design a beautiful, bespoke box
            that embodies your brand.
          </p>
          <p>
            You can explore our custom box options in our{" "}
            <PurpleLink href="https://go.ongoody.com/custom-gifts/">
              gift catalog
            </PurpleLink>
            , or simply{" "}
            <PurpleLink href={generateRealmPath("business", "/book-a-call")}>
              book a call with us
            </PurpleLink>{" "}
            to discuss your project. We can also support warehousing and
            storage.
          </p>
        </>
      ),
    },
    {
      question: "Can Goody store my swag for future orders?",
      answer: (
        <>
          <p>
            Yes we can. Please note that order minimums and warehousing fees
            apply.
          </p>
          <p>
            To learn more about our warehousing options,{" "}
            <PurpleLink href={generateRealmPath("business", "/book-a-call")}>
              book a call with us
            </PurpleLink>
            .
          </p>
        </>
      ),
    },
  ]

  return (
    <div tw="px-4 pb-24 bg-gray-050 pt-4 md:pt-12">
      <FAQAccordion sections={sections} />
    </div>
  )
}

export default FrequentlyAskedQuestions
