import { useMemo, useState } from "react"
import tw, { styled } from "twin.macro"

import {
  BrandValueKey,
  brandValueIconMappings,
  brandValueKeys,
} from "@/store/components/BrandValueBadge"
import Dropdown from "@/store/components/Dropdown"

interface BrandValueFilterProps {
  selectedBrandValues: BrandValueKey[]
  toggleBrandValue: (value: BrandValueKey) => void
}

const BrandValueFilter = ({
  selectedBrandValues,
  toggleBrandValue,
}: BrandValueFilterProps) => {
  const selectedBrandsLookup = useMemo(() => {
    const lookup: { [key: string]: boolean } = {}
    selectedBrandValues.forEach((brandValue) => {
      lookup[brandValue] = true
    })
    return lookup
  }, [selectedBrandValues])
  const [dropdownOpened, setDropdownOpened] = useState(false)

  return (
    <Dropdown
      opened={dropdownOpened}
      setOpened={(val: boolean) => setDropdownOpened(val)}
      active={selectedBrandValues.length > 0}
      label={`Values${
        selectedBrandValues.length > 0 ? ` (${selectedBrandValues.length})` : ""
      }`}
    >
      <BrandValuesContainer>
        <BrandValueList>
          {brandValueKeys.map((brandValue) => (
            <BrandValue
              key={brandValue}
              onClick={() => {
                toggleBrandValue(brandValue)
                setDropdownOpened(false)
              }}
              selected={selectedBrandsLookup[brandValue]}
            >
              <div tw="flex-1 relative top-6">
                {brandValueIconMappings[brandValue]}
              </div>
              <div tw="flex flex-1 items-center relative bottom-2">
                {brandValue}
              </div>
            </BrandValue>
          ))}
        </BrandValueList>
      </BrandValuesContainer>
    </Dropdown>
  )
}

const BrandValuesContainer = styled.div`
  ${tw`bg-white lg:border border-gray-150 p-5 rounded-xl lg:w-max`};

  @media only screen and (min-width: 1024px) {
    box-shadow:
      0 8px 24px rgba(0, 0, 0, 0.08),
      0 1px 4px rgba(0, 0, 0, 0.01);
  }
`

const BrandValueList = tw.div`gap-3 grid grid-cols-6`

const BrandValue = styled.button<{ selected: boolean }>`
  ${({ selected }) => [
    tw`bg-gray-100 hover:bg-gray-150 active:bg-gray-200 border-2 border-transparent flex flex-col items-center justify-center h-28 mx-auto rounded-lg px-2 text-gray-600 text-sm transition w-28`,
    selected &&
      tw`border-2 border-primary-400 bg-primary-000 hover:bg-primary-050 text-primary-600`,
  ]}

  // https://css-irl.info/controlling-leftover-grid-items/ 
  ${tw`col-span-2 last:[&:nth-child(3n - 2)]:col-end-5 last:[&:nth-child(3n - 1)]:col-end-[-2] [&:nth-last-child(2):nth-child(3n + 1)]:col-end-4`}
`

export default BrandValueFilter
