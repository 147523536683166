import { gql } from "@apollo/client"

export const BUDGETING_INDEX_QUERY = gql`
  query Budgeting_Index {
    organization {
      balanceAccount {
        id
        balance
      }
      budgetSummary {
        budgetTotalMonthly
        budgetTotalQuarterly
      }
      quarterStartMonth
    }
  }
`
