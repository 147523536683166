import { gql } from "@apollo/client"

import { EDITORIAL_PRODUCT_COMMON_FRAGMENT } from "./EditorialProductCommonFragment"

export const EDITORIAL_QUERY = gql`
  query Store_Editorial($slug: String) {
    editorialItem(slug: $slug) {
      id
      title
      subtitle
      slug
      contentHtml
      author
      authorImageSmall {
        url
      }
      instagram1
      instagram2
      headerImageLarge {
        url
        width
        height
      }
      products {
        ...Editorial_GoodyProductCommon
      }
      productsWithGiftOption {
        ...Editorial_GoodyProductCommon
      }
    }
  }
  ${EDITORIAL_PRODUCT_COMMON_FRAGMENT}
`
