import Modal from "react-modal"
import { Link } from "react-router-dom"
import tw, { styled } from "twin.macro"

import globalBackground from "./assets/global-background.png"
import { SwagProductsExamples } from "./SwagProductsExamples"

import Button from "@/common/Button"
import { useFeatureAccess } from "@/common/hooks/featureAccess"
import Image from "@/common/Image"
import { level2ModalStyle } from "@/common/modal"
import { generateRealmPath } from "@/common/realm"

interface Props {
  isOpen: boolean
  onClose: () => void
  isSwagTemplate: boolean
}

export const ShipsGloballySwagModal = ({
  isOpen,
  onClose,
  isSwagTemplate,
}: Props) => {
  const hasProPlan = useFeatureAccess().hasFeature("pro_plan")

  return (
    <Modal
      isOpen={isOpen}
      style={level2ModalStyle}
      closeTimeoutMS={500}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      onRequestClose={onClose}
    >
      <Container
        className="modal-content-animated"
        tw="max-w-xs lg:max-w-lg max-h-screen overflow-scroll"
      >
        <GradientBackgroundImage
          src={globalBackground}
          alt="Global background"
          tw="object-center object-cover h-48"
        />

        <SwagProductsExamples />

        <div tw="p-6 lg:(p-8 pt-6) flex flex-col justify-center text-center">
          <h1 tw="text-black text-xl font-semibold pb-5">Gift swag globally</h1>
          <p tw="text-black text-base font-normal pb-3">
            Ship custom swag to{" "}
            <UnderlineText
              href="https://intercom.help/goody/en/articles/5838916-does-goody-ship-internationally"
              target="_blank"
              rel="noopener noreferrer"
            >
              140+ countries.
            </UnderlineText>
          </p>

          {hasProPlan ? (
            <>
              <p tw="text-black text-base font-normal">
                <span tw="font-medium">You’re already subscribed to Pro.</span>
                {isSwagTemplate
                  ? " Create your custom items, add them to your bag, and select “Full global catalog” under International when you send your gift."
                  : " Add your product to your bag and select “Full global catalog” under International when you send your gift."}
              </p>

              <Link to="https://intercom.help/goody/en/articles/5838916-does-goody-ship-internationally">
                <Button variant="primary-new" tw="mt-6 mb-4 w-full">
                  <div tw="w-full text-center">
                    Learn more in the help center
                  </div>
                </Button>
              </Link>
              <Button onClick={onClose}>
                <div tw="w-full text-center">Close</div>
              </Button>
            </>
          ) : (
            <>
              <div tw="flex flex-col gap-3 pb-5 lg:pb-8">
                <p tw="text-black text-base font-normal">
                  Available with a Pro subscription, starting at $20/month.
                </p>
                <p tw="text-gray-500 text-sm font-normal">
                  Shipping, taxes, and duties vary by country.
                </p>
              </div>

              <Link to={generateRealmPath("plus", "/subscribe-pro")}>
                <Button variant="primary-new" tw="w-full">
                  <div tw="w-full text-center">Subscribe to Pro</div>
                </Button>
              </Link>
            </>
          )}
        </div>
      </Container>
    </Modal>
  )
}

const Container = styled.div`
  ${tw`rounded-2xl bg-white flex flex-col overflow-hidden`}
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.05), 0px 2px 8px 0px rgba(0, 0, 0, 0.05);
`

// Adding CSS because we are not in the latest version of tailwind.
// When we upgrade we can change this to:
// tw`underline-offset-4 decoration-primary-new-200`
const UnderlineText = styled.a`
  ${tw`text-primary-new-800 underline`}
  text-decoration-color: #CBC1FF; // primary-new-200
  text-underline-offset: 4px;
`

const GradientBackgroundImage = styled(Image)`
  background: linear-gradient(
      180deg,
      rgba(192, 213, 238, 0.18) 81.32%,
      #c0d5ee 211.21%
    ),
    #fff;
`
