import { MouseEventHandler, ReactNode } from "react"
import tw, { styled } from "twin.macro"

import Checkbox from "@/common/Checkbox"

interface WrapperProps {
  selected: boolean
}

const Wrapper = styled.button<WrapperProps>`
  ${tw`flex flex-row items-center w-full px-3 py-[14px] relative rounded transition-all -mt-px text-gray-600
  hover:bg-gray-075
  active:bg-gray-100
  before:(absolute top-0 left-3 right-3 border-b border-gray-200 content[""] z-[-10])
  after:(absolute bottom-0 left-3 right-3 border-b border-gray-200 content[""] z-[-10])`}

  ${(props) => props.selected && tw`text-primary-new-700`}
`

interface FilterListOptionProps {
  selected: boolean
  active?: boolean
  onClick: MouseEventHandler<HTMLButtonElement>
  children: ReactNode
}

const FilterListOption = ({
  selected,
  active = true,
  onClick,
  children,
}: FilterListOptionProps) => (
  <Wrapper selected={selected} onClick={onClick}>
    <Checkbox selected={selected} tw="mr-3" />
    <div tw="capitalize text-gray-700">{children}</div>
    {!active && (
      <div tw="px-2 rounded-full border border-gray-200 text-gray-400 text-sm">
        Inactive
      </div>
    )}
  </Wrapper>
)

export default FilterListOption
