import React from "react"
import { Helmet } from "react-helmet-async"
import tw, { styled } from "twin.macro"

import { HubSpot } from "../common/Script"

const loadForm = () => {
  // @ts-ignore
  window.hbspt.forms.create({
    region: "na1",
    portalId: "9308919",
    formId: "ce34b5e9-8925-4299-9d75-cf0d552f6d82",
    target: "#partner-form-container",
  })
}

const Partner: React.FC = () => (
  <>
    <HubSpot onLoad={loadForm} />
    <Helmet>
      <title>Brand Partners – Goody</title>
    </Helmet>
    <Top>
      <Title>Bring your brand to Goody</Title>
      <Subtitle>Make your products easy to gift</Subtitle>
    </Top>
    <Container>
      <Card>
        <TextSection>
          Have a brand that people love? Partner with Goody and list your brand
          on our storefront, and we'll help people discover and gift your
          products.
        </TextSection>
        <TextSection>
          We'll work with you to find the best products to list on Goody,
          determine the best creative to use, and set it up on our platform.
        </TextSection>
        <div
          id="partner-form-container"
          className="ph-no-capture fs-exclude data-hj-suppress"
          tw="mt-8"
        />
      </Card>
    </Container>
  </>
)

const Card = styled.div`
  ${tw`bg-white flex flex-col items-stretch rounded-2xl shadow-xl p-8 lg:p-12`};
  max-width: 600px;
`

const TextSection = styled.div`
  ${tw`text-lg lg:text-xl text-gray-800 mb-4`}
`

const Container = styled.div(() => [
  tw`container flex flex-col items-center mx-auto relative -mt-36 `,
])

const Title = styled.h2`
  ${tw`bg-clip-text font-medium text-3xl lg:text-5xl text-transparent text-center leading-snug lg:leading-snug px-6`};
  background-image: linear-gradient(
    77.96deg,
    rgba(215, 112, 147, 1),
    rgba(179, 125, 213, 1)
  );
`

const Subtitle = styled.h3`
  ${tw`mb-36 mt-4 text-2xl px-4`}
`

const Top = styled.div`
  ${tw`flex items-center justify-center w-full text-center flex-col`};
  background: linear-gradient(
      90deg,
      rgba(228, 161, 184, 0.15) 30.58%,
      rgba(179, 125, 213, 0.15) 100%
    ),
    #ffffff;

  height: 400px;

  @media (max-width: 1024px) {
    height: 40vh;
  }
`

export default Partner
