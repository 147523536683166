import pluralize from "pluralize"
import { useState } from "react"
import tw, { styled } from "twin.macro"

import VariantsModal from "./VariantsModal"

import { ReactComponent as PlusCircleIcon } from "@/assets/icons/plus-circle.svg"
import { joinWithCommaAnd } from "@/common/utilities"
import {
  Details_ProductFragment,
  VariantGroupFragment,
} from "@/types/graphql-types"

interface Props {
  variants: Details_ProductFragment["variants"]
  variantGroups?: VariantGroupFragment[] | null
  variantsLabel?: string | null
}

const ProductVariants = ({ variants, variantGroups, variantsLabel }: Props) => {
  const [showVariantsModal, setShowVariantsModal] = useState(false)

  const displayVariantGroups = variantGroups && variantGroups.length > 0
  const variantsDisplayLabel = () => {
    if (variantGroups && variantGroups.length > 0) {
      return joinWithCommaAnd(
        variantGroups.map((variantGroup, variantGroupIndex) =>
          pluralize.plural(
            variantGroupIndex > 0
              ? variantGroup.name.toLowerCase()
              : variantGroup.name,
          ),
        ),
      )
    } else {
      return `${variants.length} ${pluralize.plural(
        variantsLabel || "variant",
      )}`
    }
  }

  return (
    <>
      <Variants>
        <div tw="absolute flex font-text">
          <MoreVariants
            onClick={() => {
              setShowVariantsModal(true)
            }}
          >
            <div tw="mr-3">{variantsDisplayLabel()}</div>
            <PlusCircleIcon tw="text-primary-300" />
          </MoreVariants>
          {!displayVariantGroups &&
            variants.slice(0, 3).map(({ name, imageThumb }) => (
              <div
                key={name}
                tw="flex flex-shrink-0 items-center mr-5 text-gray-400"
              >
                {imageThumb && <VariantImage alt={name} src={imageThumb.url} />}
                {name}
              </div>
            ))}
        </div>
        <VariantFade />
      </Variants>
      <VariantsModal
        isOpen={showVariantsModal}
        close={() => setShowVariantsModal(false)}
        variants={variants}
        variantsLabel={variantsLabel}
        variantGroups={variantGroups}
      />
    </>
  )
}

const Variants = tw.div`
  flex relative mt-6 overflow-hidden w-full h-[42px]
`

const MoreVariants = tw.button`
  flex justify-between items-center flex-shrink-0
  leading-5 text-primary-600
  mr-5 pl-4 pr-2 py-2
  transition-all rounded-full
  border border-primary-100 hover:border-primary-300
  active:(scale-90 bg-primary-000)
`

const VariantFade = styled.div`
  ${tw`absolute right-0 h-[41px] w-[43px]`};

  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0) 100%
  );
`

const VariantImage = tw.img`
  object-cover rounded-md
  mr-[11px] max-h-[33px] w-[33px]
`

export default ProductVariants
