import { gql, useMutation } from "@apollo/client"
import { sumBy } from "lodash-es"
import { useCallback, useEffect } from "react"
import { Helmet } from "react-helmet-async"
import {
  SubmitHandler,
  UseFormRegister,
  useFieldArray,
  useForm,
} from "react-hook-form"
import { useHistory } from "react-router-dom"

import WorkspaceInvitePromo from "./components/WorkspaceInvitePromo"
import WorkspaceSetup from "./components/WorkspaceSetup"
import { track, useScreen } from "../common/analytics"
import { useGlobalState } from "../common/GlobalState"
import GradientButton from "../common/GradientButton"
import { gtmEvent } from "../common/gtm"
import { useCurrentWorkspace } from "../common/hooks"
import { generateRealmPath } from "../common/realm"
import { TextInput } from "../common/TextInputField"

import {
  PostSignup_WorkspaceInviteCreateMutation,
  PostSignup_WorkspaceInviteCreateMutationVariables,
  WorkspaceInviteCreateInput,
} from "@/types/graphql-types"
import { GET_WORKSPACE_QUERY } from "@/workspaces/graphql"

interface WorkspaceInviteFormValues {
  workspaceInvites: WorkspaceInviteCreateInput[]
}

const InviteMembers = () => {
  const [currentUser] = useGlobalState("user")
  const { currentWorkspace } = useCurrentWorkspace()
  useScreen("Business - Post-Signup - Invite")

  const [, setIsBusinessWelcomeModalOpen] = useGlobalState(
    "isBusinessWelcomeModalOpen",
  )
  const history = useHistory()
  const [workspaceInvitesCreate, { loading }] = useMutation<
    PostSignup_WorkspaceInviteCreateMutation,
    PostSignup_WorkspaceInviteCreateMutationVariables
  >(WORKSPACE_INVITE_CREATE)
  const {
    control,
    handleSubmit,
    register,
    setError,
    clearErrors,
    reset,
    setFocus,
    formState: { errors },
  } = useForm<WorkspaceInviteFormValues>({
    defaultValues: {
      workspaceInvites: Array(3).fill({ email: "" }),
    },
  })
  const { fields, append } = useFieldArray({
    control,
    name: "workspaceInvites",
  })

  useEffect(() => {
    // Do not focus on mobile
    if (window.innerWidth > 1080) {
      setFocus("workspaceInvites.0.email")
    }
  }, [setFocus])

  const onSuccess = () => {
    const redirectTo = sessionStorage.getItem("signupRedirectTo")
    if (redirectTo) {
      sessionStorage.removeItem("signupRedirectTo")
      history.push(redirectTo)
      if (!redirectTo.includes("openCreateProduct")) {
        setIsBusinessWelcomeModalOpen(true)
      }
    } else {
      history.push(generateRealmPath("plus", "/send"))
      setIsBusinessWelcomeModalOpen(true)
    }
  }

  const onSubmit: SubmitHandler<WorkspaceInviteFormValues> = async ({
    workspaceInvites,
  }) => {
    if (!currentWorkspace) {
      // This should never happen as the button is disabled if the workspace isn't available yet.
      alert("An unknown error occurred.")
      return
    }

    const res = await workspaceInvitesCreate({
      variables: { workspaceId: currentWorkspace.id, workspaceInvites },
      refetchQueries: [
        {
          query: GET_WORKSPACE_QUERY,
          variables: { id: currentWorkspace.id },
        },
      ],
    })

    const resData = res.data?.workspaceInviteCreate
    if (resData?.ok) {
      if (resData.errors && resData.errors.length > 0) {
        // Replace the array inputs with the errored inputs.
        reset({
          workspaceInvites: resData.errors.map((error) => {
            return {
              email: error.email || "",
            }
          }),
        })

        // Clear the existing errors, then set new errors by index.
        // We use the email as the 'single error field' for the row.
        resData.errors.forEach((err, i) => {
          if (err.error) {
            setError(
              `workspaceInvites.${i}.email` as "workspaceInvites.0.email",
              {
                type: "manual",
                message: err.error,
              },
            )
          }
        })
      } else {
        const inviteCount = getNonEmptyCount(workspaceInvites)

        if (inviteCount > 0) {
          gtmEvent("Business_Onboarding_Invite_Submit")
          track("Business - Post-Signup - Invite - Submit", {
            inviteCount,
          })
        }

        // No errors.
        // Reset the form to the original state.
        clearErrors()
        onSuccess()
      }
    } else {
      alert("An unknown error occurred.")
    }
  }

  const addInvitation = useCallback(() => {
    append([{ email: "" }])
  }, [append])

  return (
    <>
      <Helmet>
        <title>Invite your colleagues – Goody for Business</title>
      </Helmet>
      <WorkspaceSetup>
        <div tw="flex flex-row items-center justify-center gap-3 mb-8">
          <div tw="w-20 h-1 bg-primary-new-500 rounded-lg"></div>
          <div tw="w-20 h-1 bg-primary-new-500 rounded-lg"></div>
          <div tw="w-20 h-1 bg-primary-new-500 rounded-lg"></div>
        </div>
        <h2 tw="text-2xl font-semibold mb-5">
          Invite people to your workspace
        </h2>
        <p tw="text-lg text-gray-500 mb-3">
          Workspace members can see each other’s gift activity, track gift
          sends, and more.
        </p>

        {!currentUser?.hasPaidPlan && (
          <div tw="mt-6 pt-0.5 pb-0.5">
            <WorkspaceInvitePromo />
          </div>
        )}

        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" tw="mt-6">
          <div tw="flex flex-col gap-3">
            {fields.map((_, index) => (
              <Field
                index={index}
                error={errors?.workspaceInvites?.[index]?.email?.message}
                register={register}
                key={index}
              />
            ))}
          </div>
          <button
            onClick={() => addInvitation()}
            tw="mt-3 text-gray-450 hover:text-gray-600 transition-colors"
            type="button"
          >
            Add more
          </button>
          <div tw="flex items-center gap-8 mt-10">
            <GradientButton
              tw="text-base justify-center w-32"
              disabled={!currentWorkspace || loading}
              onClick={handleSubmit(onSubmit)}
            >
              Invite
            </GradientButton>
            <button
              type="button"
              tw="text-gray-500 hover:text-gray-600 transition-colors"
              onClick={() => {
                onSuccess()
                gtmEvent("Business_Onboarding_Invite_Skip")
              }}
            >
              Skip
            </button>
          </div>
        </form>
      </WorkspaceSetup>
    </>
  )
}

function getNonEmptyCount(workspaceInvites: WorkspaceInviteCreateInput[]) {
  return sumBy(workspaceInvites, (invite) => {
    return invite.email !== "" ? 1 : 0
  })
}

interface FieldProps {
  index: number
  error?: string
  register: UseFormRegister<WorkspaceInviteFormValues>
}
const Field = ({ index, error, register }: FieldProps) => (
  <>
    <div tw="flex gap-3">
      <TextInput
        {...register(
          `workspaceInvites.${index}.email` as "workspaceInvites.0.email",
        )}
        placeholder="Email"
        type="email"
        autoComplete="off"
        className="data-hj-suppress ph-no-capture fs-exclude"
      />
    </div>
    {error && <div tw="bg-gray-200 py-1 px-3 rounded-lg mb-1">{error}</div>}
  </>
)

const WORKSPACE_INVITE_CREATE = gql`
  mutation PostSignup_WorkspaceInviteCreate(
    $workspaceId: ID!
    $workspaceInvites: [WorkspaceInviteCreateInput!]!
  ) {
    workspaceInviteCreate(
      workspaceId: $workspaceId
      workspaceInvites: $workspaceInvites
    ) {
      ok
      errors {
        email
        error
      }
    }
  }
`

export default InviteMembers
