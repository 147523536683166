import { gql } from "@apollo/client"

export const CREATE_SWAG_PRODUCT_MUTATION = gql`
  mutation CreateSwagProduct(
    $productName: String!
    $swagProductId: ID!
    $swagPreviousProductId: ID
  ) {
    createSwagProduct(
      productName: $productName
      swagProductId: $swagProductId
      swagPreviousProductId: $swagPreviousProductId
    ) {
      ok
      error
      product {
        id
        slug
        brand {
          slug
        }
      }
    }
  }
`
