import { useQuery } from "@apollo/client"
import React, { useEffect } from "react"
import { Helmet } from "react-helmet-async"
import tw, { styled } from "twin.macro"

import CTABanner from "./CTABanner/CTABanner"
import CTABannerButton from "./CTABanner/CTABannerButton"
import CTABannerHeading from "./CTABanner/CTABannerHeading"
import CTABannerSubHeading from "./CTABanner/CTABannerSubHeading"
import EditorialContext from "./EditorialContext"
import { GoodyProduct } from "./GoodyProduct"
import { GoodyProductWithGiftOption } from "./GoodyProductWithGiftOption"
import { EDITORIAL_QUERY } from "./graphql"
import Header from "./Header"
import { HOLIDAY_SLUGS } from "./holiday"
import HolidayHeader from "./HolidayHeader"
import ThreeColumnLayout from "./ThreeColumnLayout"
import { gtmEventForEditorialItem } from "./utils"

import { useGlobalState } from "@/common/GlobalState"
import HTMLRenderer from "@/common/HTMLRenderer"
import {
  Store_EditorialQuery,
  Store_EditorialQueryVariables,
} from "@/types/graphql-types"

interface Props {
  slug: string
}

const Editorial = ({ slug }: Props) => {
  const { data } = useQuery<
    Store_EditorialQuery,
    Store_EditorialQueryVariables
  >(EDITORIAL_QUERY, {
    variables: { slug },
  })

  const editorialItem = data?.editorialItem
  const [_, setHideMenuItems] = useGlobalState("hideMenuItems")
  const shouldDisplayHolidayHeader = HOLIDAY_SLUGS.includes(
    editorialItem?.slug ?? "",
  )

  useEffect(() => {
    if (editorialItem) {
      gtmEventForEditorialItem("Editorial_View", { editorialItem })
    }
  }, [editorialItem?.id])

  useEffect(() => {
    if (shouldDisplayHolidayHeader) {
      setHideMenuItems(true)
    }

    return () => {
      setHideMenuItems(false)
    }
  }, [shouldDisplayHolidayHeader, setHideMenuItems])

  const header = shouldDisplayHolidayHeader ? (
    <HolidayHeader />
  ) : (
    <div tw="lg:px-6">
      <Header editorialItem={editorialItem ?? undefined} />
    </div>
  )

  return (
    <div tw="flex flex-col">
      {header}
      {editorialItem && (
        <>
          <Helmet>
            <title>{editorialItem.title} | Goody</title>
            <link
              rel="canonical"
              href={`https://www.ongoody.com/editorial/${editorialItem.slug}`}
            />
          </Helmet>
          <EditorialContext.Provider value={editorialItem}>
            <div tw="px-3 lg:px-5 pb-12">
              <HTMLRenderer
                html={editorialItem.contentHtml || ""}
                components={{
                  goodyproductwithgiftoption: GoodyProductWithGiftOption,
                  ctabanner: CTABanner,
                  ctabannerheading: CTABannerHeading,
                  ctabannersubheading: CTABannerSubHeading,
                  ctabannerbutton: CTABannerButton,
                  goodyproduct: GoodyProduct,
                  threecolumnlayout: ThreeColumnLayout,
                  heading: ({ children }) => <Heading>{children}</Heading>,
                  subheading: ({ children }) => (
                    <div tw="text-center pt-2 mb-8 text-xl">{children}</div>
                  ),
                }}
              />
            </div>
          </EditorialContext.Provider>
        </>
      )}
    </div>
  )
}

const Heading = styled.h2`
  ${tw`text-3xl mt-12 mb-2 font-normal text-center`}
  font-family: 'Canela Deck';
  font-weight: 400;
  font-size: 37px;
  line-height: 125%;

  background: linear-gradient(132.78deg, #4a7dff -9.05%, #b28eff 77.67%),
    linear-gradient(90deg, #9f73f7 0%, #7a68f6 100%), #a372df;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`

export default Editorial
