import { NavLink } from "react-router-dom"
import tw, { styled } from "twin.macro"

import { RoundedBox } from "../../common/UI"

const Tabs = styled(RoundedBox)`
  ${tw`mx-auto w-min flex h-14 -my-7 rounded-2xl p-1`}
`

export const TabLink = styled(NavLink)`
  ${tw`text-gray-500 px-4 flex items-center rounded-xl text-lg md:text-xl relative overflow-hidden z-10 whitespace-nowrap`}

  &:focus {
    ${tw`outline-none`}
  }

  &::before,
  &::after {
    ${tw`block absolute top-0 left-0 w-full h-full bg-gradient-to-r opacity-0 transition-opacity`}
    content: "";
    z-index: -1;
  }

  &::before {
    ${tw`from-gradient-alternate-pink-lightest to-gradient-alternate-purple-lightest`}
  }

  &::after {
    ${tw`from-gradient-alternate-pink-light to-gradient-alternate-purple-light`}
  }

  &:hover::before {
    ${tw`opacity-100`}
  }

  &.active {
    ${tw`bg-gradient-to-r  text-primary-600 font-medium pointer-events-none`}

    &::after {
      ${tw`opacity-100`}
    }
  }

  &:not(:last-child) {
    ${tw`mr-1`}
  }

  svg {
    ${tw`stroke-current stroke-2 opacity-50 mr-3`}
  }
`

interface TabButtonProps {
  active?: boolean
  onClick: () => void
}
export const TabButton: React.FC<TabButtonProps> = ({
  active,
  children,
  onClick,
}) => (
  <TabLink
    as="button"
    className={active ? "active" : undefined}
    onClick={onClick}
  >
    {children}
  </TabLink>
)

export default Tabs
