import { useState } from "react"
import SwiperCore from "swiper"
import tw from "twin.macro"

import SwipeableGallery, {
  GalleryImageProps,
} from "../../common/SwipeableGallery"
import SwiperThumbs from "../../common/SwiperThumbs"

import { Details_ProductFragment } from "@/types/graphql-types"

export type ProductImage = Details_ProductFragment["productImages"][0]
interface ProductImageGalleryProps {
  productName: string
  productImages: ProductImage[]
  isGiftCard?: boolean
  isFlexGift?: boolean
  scalable?: boolean
}

type ImageGallery = GalleryImageProps & {
  thumb: { url: string }
}

const ProductImageGallery = ({
  productName,
  productImages,
  isGiftCard,
  isFlexGift,
  scalable,
}: ProductImageGalleryProps) => {
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperCore | null>(null)

  const images: ImageGallery[] = productImages.map(
    ({ imageXlarge, imageLarge, imageThumb }) => {
      return {
        image: imageXlarge,
        large: imageLarge,
        thumb: imageThumb,
        name: productName,
      }
    },
  )

  return (
    <div>
      <GalleryContainer
        css={[
          (isGiftCard || isFlexGift) &&
            tw`rounded-2xl lg:rounded-2xl lg:w-[360px] xl:w-[460px] 2xl:w-[560px]`,
        ]}
      >
        <SwipeableGallery
          thumbsSwiper={thumbsSwiper}
          images={images}
          scalable={scalable}
        />
      </GalleryContainer>
      <SwiperThumbsContainer>
        <SwiperThumbs
          setThumbsSwiper={setThumbsSwiper}
          images={images}
          scalable={scalable}
        />
      </SwiperThumbsContainer>
    </div>
  )
}

const GalleryContainer = tw.div`
  flex-1 flex-shrink-0 lg:rounded-none overflow-hidden w-full
  max-w-[500px] h-[min-content]
  lg:w-[450px] xl:w-[500px] 2xl:w-[600px] lg:max-w-[600px]
`

const SwiperThumbsContainer = tw.div`
  hidden lg:block
  lg:w-[450px] xl:w-[500px] 2xl:w-[600px] lg:max-w-[600px]
`

export default ProductImageGallery
