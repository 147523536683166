import { CustomCreateButton } from "./CustomCreateButton"
import { generateRealmPath } from "../../../common/realm"
import { BrandedSwagIcon, CustomStoreIcon } from "../images"

export { CustomCreateButton }

export const CustomStoreCreateButton = () => (
  <CustomCreateButton
    url={generateRealmPath("plus", `/store/custom/create`)}
    title="Create a custom store"
    subtitle="Add multiple gifts to a custom store for your recipients to select from."
    icon={<CustomStoreIcon tw="w-6 text-primary-600" />}
  />
)

export const CustomProductsCreateButton = () => (
  <CustomCreateButton
    url={generateRealmPath("plus", `/browse/category/branded-swag`)}
    title="Create a branded product"
    subtitle="Add your logo to apparel, drinkware, and more, with no minimum quantity."
    icon={<BrandedSwagIcon tw="w-6 text-primary-600" />}
  />
)
