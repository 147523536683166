import tw, { styled } from "twin.macro"

import { buttonStyle } from "../../common/GradientButton"

const AddButton = styled.div<{ disabled?: boolean }>`
  ${buttonStyle}
  ${tw`bg-white border border-gray-200 rounded-lg text-base text-gray-600 font-normal cursor-pointer h-[38px] w-[8.5rem] justify-center px-4 py-2 -m-2 ml-0`}
  box-shadow: 0px 2px 4px rgba(229, 231, 235, 0.3);
  ${({ disabled }) => disabled && tw`pointer-events-none opacity-30`}
`

export default AddButton
