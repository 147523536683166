import React, { useEffect } from "react"
import { useLocation } from "react-router-dom"

import { track } from "./analytics"

declare global {
  interface Window {
    dataLayer: Record<string, any>[]
  }
}

// Send full URLs of pages to GTM when the router page changes.
const AnalyticsListener: React.FC = () => {
  const { pathname } = useLocation()
  useEffect(() => {
    let full = window.location.origin + pathname

    // Don't add the querystring part of the URL if 'token' is present.
    if (window.location.search.indexOf("token") < 0) {
      full += window.location.search
    }

    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "virtualPageview",
      pageUrl: full,
    })

    track("Page View", {
      url: full,
      path: pathname,
    })
  }, [pathname])

  return null
}

export default AnalyticsListener
