import React from "react"

import PaymentOption from "./PaymentOption"
import { useGiftData } from "../../../common/hooks/giftData"

import { BatchSendMethod } from "@/types/graphql-types"

export default function PaymentSelector() {
  const { currentGift, setConsumerNewUserPaymentSelection } = useGiftData()

  if (currentGift.sendMethod === BatchSendMethod.direct_send) {
    // Direct send always requires a payment method
    return null
  }

  return (
    <div tw="flex flex-col md:flex-row items-stretch md:items-start pt-14">
      <PaymentOption
        option="addCreditCardNow"
        currentSelection={currentGift.consumerNewUserPaymentSelection}
        setSelection={setConsumerNewUserPaymentSelection}
      />
      <div tw="p-4 text-xl text-gray-400 self-center">or</div>
      <PaymentOption
        option="addCreditCardLater"
        currentSelection={currentGift.consumerNewUserPaymentSelection}
        setSelection={setConsumerNewUserPaymentSelection}
      />
    </div>
  )
}
