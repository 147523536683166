import React, { useEffect } from "react"

import checkCircleGreen from "@/assets/icons/check-circle-green.svg"
import { useGlobalState } from "@/common/GlobalState"
import { useCurrentGift } from "@/common/hooks/currentGift"
import { useBusinessSend } from "@/common/hooks/send"
import { CurrentGiftPaymentMethods } from "@/common/payments/PaymentMethods"
import PaymentMethodsContainer from "@/common/payments/PaymentMethodsContainer"
import SendErrorAlertBubble from "@/send/SendErrorAlertBubble"
import { Send_PriceEstimateMutation } from "@/types/graphql-types"

interface Props {
  priceEstimate: Send_PriceEstimateMutation["priceEstimate"] | null
  isAlcohol?: boolean
}

export const PaymentSection = ({ priceEstimate, isAlcohol }: Props) => {
  const [currentGift] = useCurrentGift()
  const [user] = useGlobalState("user")
  const { clearError } = useBusinessSend()
  const editorIsNotCreator =
    currentGift.userPublicId && currentGift.userPublicId !== user?.publicId
  const coveredByCredit =
    (priceEstimate?.totalPriceEstimate?.creditApplied || 0) > 0 &&
    priceEstimate?.totalPriceEstimate?.estGroupTotalHigh === 0

  useEffect(() => {
    if (currentGift.autopayPaymentMethodID !== null) {
      clearError("paymentMethod")
    }
  }, [currentGift])

  if (editorIsNotCreator) {
    return (
      <PaymentMethodsContainer title="Payment">
        <div tw="p-5 text-gray-600">
          Only the gift creator can edit the payment method.
        </div>
      </PaymentMethodsContainer>
    )
  }

  if (coveredByCredit) {
    return (
      <PaymentMethodsContainer title="Payment">
        <div tw="p-5 text-green-500 flex flex-row items-center">
          <img src={checkCircleGreen} alt="" />
          <div tw="pl-3">Paid for with credit</div>
        </div>
      </PaymentMethodsContainer>
    )
  }

  return (
    <SendErrorAlertBubble
      text="Please select a payment method"
      subtext="A payment method is required."
      error="paymentMethod"
    >
      <CurrentGiftPaymentMethods addBalance={!isAlcohol} />
    </SendErrorAlertBubble>
  )
}
