import { gql, useQuery } from "@apollo/client"
import React, { useMemo } from "react"
import { useHistory } from "react-router-dom"
import tw from "twin.macro"

import AcceptanceChart, { AcceptanceStats } from "./components/AcceptanceChart"
import DeliveryChart, { DeliveryStats } from "./components/DeliveryChart"
import GiftBatchErrorMessage from "./components/GiftBatchErrorMessage"
import GiftLink from "./components/giftLink/GiftLink"
import GiftStatusSet from "./components/GiftStatusSet"
import sendingIcon from "../assets/icons/gift-status-created.svg"
import { generateRealmPath } from "../common/realm"
import {
  Loader,
  RoundedBox,
  TrackStandardBox,
  TrackStandardBoxNoPad,
} from "../common/UI"

import { BatchSendMethod, GiftSeriesFilter } from "@/types/graphql-types"
import {
  Track_GiftBatchQuery,
  Track_Overview_StatsQuery,
  Track_Overview_StatsQueryVariables,
} from "@/types/graphql-types"

interface Props {
  giftBatchID: string
  data: Track_GiftBatchQuery | undefined
  loading: boolean
  loadingVisible: boolean
  refetch: () => void
}
const Overview: React.FC<Props> = ({
  giftBatchID,
  data,
  loading,
  loadingVisible,
  refetch,
}) => {
  const batch = data?.workspace?.giftBatch
  const history = useHistory()

  const { data: statsData } = useQuery<
    Track_Overview_StatsQuery,
    Track_Overview_StatsQueryVariables
  >(OVERVIEW_STATS_QUERY, {
    variables: {
      id: giftBatchID,
    },
  })

  const acceptanceStats = useMemo<AcceptanceStats | null>(() => {
    const stats = statsData?.workspace?.giftBatch

    if (stats) {
      return {
        accepted: stats.accepted.totalCount,
        opened: stats.opened.totalCount,
        waiting: stats.notified.totalCount,
      }
    }

    return null
  }, [statsData])

  const deliveryStats = useMemo<DeliveryStats | null>(() => {
    const stats = statsData?.workspace?.giftBatch

    if (stats && stats.accepted.totalCount > 0) {
      return {
        preparing:
          stats.accepted.totalCount -
          stats.shipped.totalCount -
          stats.delivered.totalCount,
        shipped: stats.shipped.totalCount,
        delivered: stats.delivered.totalCount,
      }
    }

    return null
  }, [statsData])

  const directSend =
    data?.workspace?.giftBatch?.sendMethod === BatchSendMethod.direct_send

  return (
    <div tw="mt-12">
      {batch && batch.failureMessages.length > 0 && (
        <div tw="px-5 pb-14 pt-0 pt-2">
          <GiftBatchErrorMessage
            variant="2"
            onAuthorizationGranted={() =>
              history.push(
                generateRealmPath("plus", `/track/${batch.id}/recipients`),
              )
            }
            giftBatch={batch}
          />
        </div>
      )}

      {loadingVisible && (
        <TrackStandardBox>
          <RoundedBox tw="py-24 flex flex-row items-center justify-center">
            <Loader />
          </RoundedBox>
        </TrackStandardBox>
      )}
      {data ? (
        <>
          {!directSend && (
            <TrackStandardBoxNoPad>
              <GiftLink giftBatch={data?.workspace?.giftBatch} />
            </TrackStandardBoxNoPad>
          )}
          <TrackStandardBoxNoPad>
            <RoundedBox>
              {!directSend && (
                <div tw="p-5">
                  <div tw="md:text-lg text-gray-500 font-medium pb-6">
                    Gift acceptance
                  </div>
                  {acceptanceStats && (
                    <AcceptanceChart stats={acceptanceStats} />
                  )}
                </div>
              )}
              {deliveryStats &&
                (deliveryStats.delivered !== 0 ||
                  deliveryStats.preparing !== 0 ||
                  deliveryStats.shipped !== 0) && (
                  <div
                    tw="p-5 border-gray-200"
                    css={[!directSend && tw`border-t`]}
                  >
                    <div tw="text-lg text-gray-500 font-medium pb-4">
                      Delivery
                    </div>
                    {deliveryStats && <DeliveryChart stats={deliveryStats} />}
                  </div>
                )}
            </RoundedBox>
            {batch?.pendingSends && batch?.pendingSends > 0 ? (
              <div tw="mt-10 bg-gray-100 text-gray-800 p-6 py-4 rounded-lg flex flex-row items-center">
                <img src={sendingIcon} alt="" />
                <div tw="pl-2">
                  We’re sending out your gifts now ({batch.pendingSends}{" "}
                  recipient{batch.pendingSends !== 1 ? "s" : ""} remaining).{" "}
                  <button
                    tw="focus:outline-none border-b border-gray-300 hover:border-gray-400 text-gray-500 hover:text-gray-700 transition-colors"
                    onClick={() => refetch()}
                  >
                    {loading ? "Refreshing..." : "Refresh"}
                  </button>
                </div>
              </div>
            ) : null}
          </TrackStandardBoxNoPad>
          <div tw="p-5 md:p-24 pt-0 md:pt-0">
            <GiftStatusSet
              filter={GiftSeriesFilter.recently_accepted}
              giftBatchID={giftBatchID}
            />
            <GiftStatusSet
              filter={GiftSeriesFilter.opened}
              expiresAt={
                (batch?.expiresAt && new Date(batch.expiresAt)) || null
              }
              giftBatchID={giftBatchID}
            />
            <GiftStatusSet
              filter={GiftSeriesFilter.notified}
              expiresAt={
                (batch?.expiresAt && new Date(batch.expiresAt)) || null
              }
              giftBatchID={giftBatchID}
            />
          </div>
        </>
      ) : (
        <div tw="py-20 px-20 text-center text-gray-500 text-lg">Loading...</div>
      )}
    </div>
  )
}

const OVERVIEW_STATS_QUERY = gql`
  query Track_Overview_Stats($id: ID!) {
    workspace {
      giftBatch(id: $id) {
        notified: giftSeries(page: 1, perPage: 1, filter: notified) {
          totalCount
        }
        opened: giftSeries(page: 1, perPage: 1, filter: opened) {
          totalCount
        }
        accepted: giftSeries(page: 1, perPage: 1, filter: recently_accepted) {
          totalCount
        }
        shipped: giftSeries(page: 1, perPage: 1, filter: shipped) {
          totalCount
        }
        delivered: giftSeries(page: 1, perPage: 1, filter: delivered) {
          totalCount
        }
      }
    }
  }
`

export default Overview
