import React, { FC } from "react"

const Title: FC = () => {
  return (
    <div tw="pt-20 pb-8 px-5">
      <h1 tw="text-center font-bold mb-6 text-5xl lg:text-4rem">Pricing</h1>
      <h2 tw="text-xl lg:text-2xl xl:pr-12 text-gray-500 leading-normal text-center pb-4">
        Compare features and find a plan that works for your business.
      </h2>
    </div>
  )
}

export default Title
