import { gql } from "@apollo/client"

export const SHIPPING_COUNTRIES_QUERY = gql`
  query ShippingCountries {
    shippingCountries {
      name
      code
      groups
    }
  }
`
