import { Link } from "react-router-dom"

import { useGlobalState } from "../../../common/GlobalState"
import { ReactComponent as ArrowInCircle } from "../../../common/images/arrowInCircle.svg"
import { generateRealmPath } from "../../../common/realm"

import { Editorial_GoodyProductCommonFragment } from "@/types/graphql-types"

export interface Props {
  product: Editorial_GoodyProductCommonFragment
  handleBeforeClick: () => void
}

const MoreFrom = ({ product, handleBeforeClick }: Props) => {
  const [currentRealm] = useGlobalState("currentRealm")

  return (
    <Link
      onClick={() => handleBeforeClick()}
      to={generateRealmPath(
        currentRealm,
        `/browse/gift-option/${product.brand.primaryGiftOption?.slug}`,
      )}
      // For some reason, relative is required here to have this break through
      // the Gift button pseudo-element, though it's not required for the
      // variant button. Not sure why.
      tw="flex border-gray-200 border-t py-4 px-5 w-full hover:bg-primary-000 active:bg-primary-050 transition-colors rounded-br-[11px] rounded-bl-[11px] z-30 relative"
    >
      <div>
        <span tw="text-gray-500">More from </span>
        <span tw="font-medium text-primary-500">{product.brand.name}</span>
      </div>
      <ArrowInCircle tw="absolute right-5" />
    </Link>
  )
}

export default MoreFrom
