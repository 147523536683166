import { gql } from "@apollo/client"

const organizationUpdateBillingEmails = gql`
  mutation OrganizationUpdateBillingEmails(
    $organizationId: ID!
    $billingEmails: String
  ) {
    organizationUpdateBillingEmails(
      organizationId: $organizationId
      billingEmails: $billingEmails
    ) {
      ok
      error
    }
  }
`

export default organizationUpdateBillingEmails
