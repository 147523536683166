import React from "react"
import tw from "twin.macro"

import BusinessUseCasesDropdownContent from "./BusinessUseCasesDropdownContent"
import BrowseGiftsDropdown from "./components/BrowseGiftsDropdown"
import HeaderMenuBar from "./components/HeaderMenuBar"
import { TopBar } from "./components/TopBar"
import HowItWorksDropdownContent from "./HowItWorksDropdownContent"
import { useGlobalState } from "../../common/GlobalState"
import { generateRealmPath } from "../../common/realm"
import StaffAccessBanner from "../shared/StaffAccessBanner"

import { useIsBusinessSignup } from "@/signUp/hooks/businessSignUp"

const businessMenuItems: MenuItem[] = [
  {
    name: "How It Works",
    path: generateRealmPath("business", "/how-it-works"),
    dropdownContent: <HowItWorksDropdownContent />,
  },
  {
    name: "Browse Gifts",
    path: generateRealmPath("business", "/browse"),
    dropdownContent: <BrowseGiftsDropdown />,
    blockReclicks: true,
  },
  {
    name: "Pricing",
    path: generateRealmPath("business", "/pricing"),
  },
  {
    name: "Use Cases",
    dropdownContent: <BusinessUseCasesDropdownContent />,
  },
  {
    name: "API",
    path: generateRealmPath("business", "/gift-api"),
  },
]

const consumerMenuItems: MenuItem[] = [
  {
    name: "Browse Gifts",
    path: generateRealmPath("business", "/browse"),
  },
  {
    name: "My Gifts",
    path: generateRealmPath("consumer", "/my-gifts"),
  },
  {
    name: "My Account",
    path: generateRealmPath("consumer", "/account"),
    mobileOnly: true,
  },
]

const Header: React.FC = () => {
  const [signedIn] = useGlobalState("signedIn")
  const [currentRealm] = useGlobalState("currentRealm")
  const isBusinessRealm = currentRealm === "business"
  const { isBusinessSignupPath } = useIsBusinessSignup()

  if (!signedIn && isBusinessSignupPath) {
    return (
      <HeaderShadowContainer id="mainHeader" tw="lg:py-3">
        <StaffAccessBanner />
        <TopBar />
        <div tw="lg:hidden">
          <HeaderMenuBar
            isSignedIn={signedIn}
            isBusiness={isBusinessRealm}
            businessMenuItems={businessMenuItems}
            consumerMenuItems={consumerMenuItems}
          />
        </div>
      </HeaderShadowContainer>
    )
  }

  return <HeaderContent />
}

const HeaderContent = () => {
  const [signedIn] = useGlobalState("signedIn")
  const [currentRealm] = useGlobalState("currentRealm")
  const isBusinessRealm = currentRealm === "business"

  return (
    <HeaderShadowContainer id="mainHeader">
      <StaffAccessBanner />
      <TopBar />
      <HeaderMenuBar
        isSignedIn={signedIn}
        isBusiness={isBusinessRealm}
        businessMenuItems={businessMenuItems}
        consumerMenuItems={consumerMenuItems}
      />
    </HeaderShadowContainer>
  )
}

const HeaderShadowContainer = tw.div`
  z-30 bg-white shadow-[0 0 10px rgba(6, 13, 37, 0.03)] sticky w-full top-0
`

export default Header
