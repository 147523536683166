import React, { FC } from "react"
import tw, { styled } from "twin.macro"

import AirplaneIcon from "./icons/airplane.svg"
import EnvelopeIcon from "./icons/envelope.svg"
import GiftIcon from "./icons/gift.svg"
import MobileIcon from "./icons/mobile.svg"
import PencilIcon from "./icons/pencil.svg"
import SwapIcon from "./icons/swap.svg"
import cardHomesickCandlesSrc from "./images/card_homesick_candles.png"
import FaceBubbles from "./images/face_bubbles.png"
import GiftAcceptanceBackground from "./images/gift_acceptance_background.png"
import GiftAcceptanceFloat from "./images/gift_acceptance_float.png"
import GiftForYou from "./images/gift_for_you.png"
import GiftForYouBackground from "./images/gift_for_you_background.png"
import grazaImgSrc from "./images/graza.png"
import scheduleAMetingWithAirbnbSrc from "./images/schedule_a_metting_airbnb_calendly_salesforce.png"
import SenderForm from "./images/sender_form.png"
import SenderFormBackground from "./images/sender_form_background.png"
import ShippingForm from "./images/shipping_form.png"
import ShippingFormBackground from "./images/shipping_form_background.png"
import SquigglyCirclesLeftCorner from "./images/squiggly_circles_left_corner.svg"
import SquigglyCirclesRightCorner from "./images/squiggly_circles_right_corner.svg"
import SwapOfChoice from "./images/swap_of_choice.png"
import UnwrappingExperience from "./images/unwrapping_experience.jpg"
import PurpleGradientLink from "../../common/PurpleGradientLink"
import { generateRealmPath } from "../../common/realm"
import { Header, Paragraph, Subheading } from "../common/SectionComponents"
import Accordion from "../components/Accordion"

const ProductDescription: FC = () => {
  return (
    <div
      tw="px-5 pb-12 pt-0 sm:pt-24 lg:pt-48 lg:px-12 mx-auto flex flex-col justify-center items-center"
      style={{ maxWidth: "1200px" }}
    >
      <Section tw="flex-col lg:flex-row">
        <LeftTextContainer
          icon={GiftIcon}
          title="Send one gift or many."
          content="Whether you're gifting a special client or entire company, Goody makes it easy. Upload CSVs to save time. All you need is name and email."
        />
        <div tw="flex-1 mb-24 lg:pr-8 relative">
          <img
            tw="w-full"
            src={SenderFormBackground}
            alt="SenderFormBackground"
          />
          <SenderFormImgContainer>
            <img tw="h-full" src={SenderForm} alt="SenderForm" />
          </SenderFormImgContainer>
          <FaceBubblesImg src={FaceBubbles} />
          <SquigglyCirclesRightCornerImg src={SquigglyCirclesRightCorner} />
        </div>
      </Section>
      <Section tw="flex-col lg:flex-row-reverse">
        <RightTextContainer
          icon={AirplaneIcon}
          title="Choose how you want to send."
          content="Notify your recipients by text or email — or create a Landing Page link that you can share personally."
        />
        <div tw="flex-1 mb-24 lg:pr-8 relative">
          <img
            tw="w-full"
            src={GiftForYouBackground}
            alt="GiftForYouBackground"
          />
          <GiftForYouImgContainer>
            <img tw="h-full" src={GiftForYou} alt="GiftForYou" />
          </GiftForYouImgContainer>
        </div>
      </Section>
      <Section tw="flex-col lg:flex-row">
        <LeftTextContainer
          icon={MobileIcon}
          title="Your recipient gets a custom unwrapping experience."
          content="Choose from stylish cards for any occasion and add a message or even custom branding."
        />
        <div tw="flex-1 mb-12 lg:mb-24 lg:pr-8">
          <img
            tw="w-full"
            src={UnwrappingExperience}
            alt="UnwrappingExperience"
          />
        </div>
      </Section>
      <Section tw="flex-col lg:flex-row-reverse">
        <RightTextContainer
          icon={SwapIcon}
          title="Recipients can swap for a gift of their choice."
          content="Don't stress about dietary restrictions or picky recipients. If they don't like your gift, they can swap it out from a curated list of alternatives."
        />
        <div tw="flex-1 mt-12 lg:mt-0 mb-24 lg:pr-8 relative">
          <img tw="w-full" src={SwapOfChoice} alt="SwapOfChoice" />
          <GrazaImg src={grazaImgSrc} />
          <CardHomesickCandlesImg src={cardHomesickCandlesSrc} />
          <SquigglyCirclesLeftCornerImg src={SquigglyCirclesLeftCorner} />
        </div>
      </Section>
      <Section tw="flex-col lg:flex-row pt-4 lg:pt-0">
        <LeftTextContainer
          icon={PencilIcon}
          title="Never worry about shipping details again."
          content="With Goody, gift recipients provide their shipping info when they accept a gift."
        />
        <div tw="flex-1 mb-24 lg:pr-8 relative">
          <img
            tw="w-full"
            src={ShippingFormBackground}
            alt="ShippingFormBackground"
          />
          <ShippingFormImgContainer>
            <img tw="h-full" src={ShippingForm} alt="ShippingForm" />
          </ShippingFormImgContainer>
        </div>
      </Section>
      <Section tw="flex-col lg:flex-row-reverse">
        <RightTextContainer
          icon={EnvelopeIcon}
          title="Status updates. Thank you notes. All in one place."
          content="Track all your gifts — and your team's activity — in your Goody dashboard. Recipients' thank you notes go right into Goody."
        />
        <div tw="flex-1 mb-12 lg:mb-24 lg:pr-8 relative">
          <img
            tw="w-full"
            src={GiftAcceptanceBackground}
            alt="GiftAcceptanceBackground"
          />
          <GiftAcceptanceFloatImg src={GiftAcceptanceFloat} />
        </div>
      </Section>

      <ForSalesTeamsSection />
    </div>
  )
}

const ForSalesTeamsSection = () => {
  return (
    <Section tw="flex-col lg:flex-row lg:pt-0 w-full">
      <div tw="flex-1 mb-4 lg:mb-0 xl:pr-0 xl:pb-0 ">
        <div tw="lg:pr-20 xl:pr-28">
          <Subheading> For sales teams </Subheading>
          <Header>Boost sales outcomes. Instantly.</Header>
          <BoostSalesAccordions />
        </div>
      </div>

      <ForSalesTeamsImgContainer>
        <img
          src={scheduleAMetingWithAirbnbSrc}
          alt="scheduleAMetingWithAirbnbSrc"
        />
      </ForSalesTeamsImgContainer>
    </Section>
  )
}

const BoostSalesAccordions = () => {
  const accordionOptions = [
    {
      title: "Book more meetings",
      content:
        "Integrate with Calendly and send gifts before or after someone books time on your calendar.",
    },
    {
      title: "Accelerate deals and renewals",
      content:
        "Send timely, personalized gifts that help you close more business, right from Salesforce.",
    },
    {
      title: "Manage budgets and measure results",
      content:
        "Set gift budgets for everyone on your team, and track gift sends and performance in Salesforce.",
    },
  ]

  return (
    <>
      <AccordionContainer>
        <Accordion items={accordionOptions} initialExpandedIndex={2} />
      </AccordionContainer>
      <PurpleGradientLink
        tw="mt-4 py-5 px-10 w-full lg:w-auto"
        to={generateRealmPath("business", "/team-demo")}
      >
        Get a Demo
      </PurpleGradientLink>
    </>
  )
}

const AccordionContainer = styled.div`
  ${tw`pt-8`}

  @media (min-width: 640px) {
    min-width: 400px;
  }

  @media (min-width: 1200px) {
    min-width: 485px;
  }
`

interface TextContainerProps {
  icon?: string
  title: string
  subheading?: string
  content?: string
  children?: React.ReactNode
}

const LeftTextContainer: FC<TextContainerProps> = ({
  icon,
  title,
  subheading,
  content,
  children,
}) => (
  <div tw="flex-1 mb-12 lg:mb-24 xl:pr-0 xl:pb-24 lg:pt-24">
    <div tw="lg:pr-20 xl:pr-28">
      {icon && <img src={icon} alt="icon" tw="mb-4 lg:mb-8" />}
      {subheading && <Subheading> {subheading} </Subheading>}
      <Header>{title}</Header>
      <Paragraph>{content}</Paragraph>
      {children}
    </div>
  </div>
)

const RightTextContainer: FC<TextContainerProps> = ({
  icon,
  title,
  content,
}) => (
  <div tw="flex-1 mb-12 lg:mb-24 xl:pr-0 xl:pb-24 lg:pt-24">
    <div tw="lg:pl-20 xl:pl-28">
      <img src={icon} alt="icon" tw="mb-4 lg:mb-8" />
      <Header>{title}</Header>
      <Paragraph>{content}</Paragraph>
    </div>
  </div>
)

const Section = styled.div`
  ${tw`flex lg:mb-12`}

  max-width: 580px;

  @media (min-width: 1024px) {
    max-width: none;
  }
`

const ImgContainer = styled.div`
  ${tw`absolute`}
  width: 200%;
  height: 90%;
`

const SenderFormImgContainer = styled(ImgContainer)`
  left: 5%;
  top: 16%;
`

const GiftForYouImgContainer = styled(ImgContainer)`
  left: 5%;
  top: 16%;
`

const ShippingFormImgContainer = styled(ImgContainer)`
  left: 9%;
  top: 12%;
  height: 93%;
`

const ForSalesTeamsImgContainer = styled.div`
  ${tw`flex-1`}

  img {
    margin-left: -48px;
    margin: auto;

    @media (min-width: 1080px) {
      margin-left: -48px;
      width: 550px;
      max-width: 550px;
    }

    @media (min-width: 1200px) {
      margin-left: -92px;
      width: 650px;
      max-width: 650px;
    }
  }
`

const FaceBubblesImg = styled.img`
  ${tw`absolute`}
  top: -5%;
  left: 7%;
  width: 25%;
`

const GrazaImg = styled.img`
  ${tw`absolute`}
  top: -13%;
  right: 4%;
  width: 40%;
  filter: drop-shadow(0px 42px 54px rgba(6, 13, 37, 0.06));

  @media (min-width: 1024px) {
    top: -14%;
    right: -8%;
    width: 50%;
  }
`

const CardHomesickCandlesImg = styled.img`
  ${tw`absolute`}
  bottom: -8%;
  left: 8%;
  width: 40%;
  filter: drop-shadow(0px 42px 54px rgba(6, 13, 37, 0.06));

  @media (min-width: 1024px) {
    bottom: -10%;
    left: -16%;
    width: 50%;
  }
`

const SquigglyCirclesLeftCornerImg = styled.img`
  ${tw`absolute hidden lg:block`}

  top: -9.5%;
  left: -12%;
`

const SquigglyCirclesRightCornerImg = styled.img`
  ${tw`absolute hidden lg:block`}

  top: -10.5%;
  right: -8%;
`

const GiftAcceptanceFloatImg = styled.img`
  ${tw`absolute`}

  top: -5%;
  right: 2.5%;
  width: 50%;
  filter: drop-shadow(0px 42px 54px rgba(6, 13, 37, 0.06));

  @media (min-width: 1024px) {
    top: -5%;
    right: 8%;
    width: 47%;
  }

  @media (min-width: 1440px) {
    top: 16%;
    bottom: unset;
    left: -29%;
    width: 50%;
  }
`

export default ProductDescription
