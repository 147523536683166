import { CheckCircleBlue } from "../../../assets/icons"
import staticAssetUrl from "../../../common/staticAssetUrl"
import HowItWorksVideoCTA from "../../common/HowItWorksVideoCTA"
import { Header } from "../../common/SectionComponents"

const GiftsIcon = staticAssetUrl(
  "landing/how_it_works/bulk_gifting/GiftsIcon.png",
)
const HowItWorksExample = staticAssetUrl(
  "landing/how_it_works/bulk_gifting/HowItWorksExample.png",
)

const HowItWorks = () => {
  return (
    <div tw="bg-[#F7F7F7] px-5 py-16">
      <div tw="max-w-[1200px] mx-auto">
        <div tw="pb-12">
          <h2 tw="text-center font-bold mb-6 text-3xl md:text-[2.75rem] lg:leading-[3.5rem]">
            How it works
          </h2>
          <div tw="flex justify-center">
            <HowItWorksVideoCTA
              customHeader={
                <div tw="ml-2 text-xl text-gray-800">Watch an overview</div>
              }
            />
          </div>
        </div>
        <div tw="flex flex-col lg:flex-row items-center">
          <div tw="flex-3 mb-16">
            <img src={GiftsIcon} tw="mb-6" />
            <Header>
              Send thousands of gifts at once.
              <br />
              No address needed.
            </Header>
            <div tw="flex items-center mt-6 mb-4 text-gray-600 text-xl">
              <CheckCircleBlue tw="flex-shrink-0 mr-4" />
              <span>Upload a CSV to save time.</span>
            </div>
            <div tw="flex items-center mb-4 text-gray-600 text-xl">
              <CheckCircleBlue tw="flex-shrink-0 mr-4" />
              <span>Recipients provide their own shipping info.</span>
            </div>
          </div>
          <div tw="flex-2">
            <img src={HowItWorksExample} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default HowItWorks
