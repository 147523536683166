import { ReactNode } from "react"

import Button from "@/common/Button"

export interface Props {
  cta: string
  ctaDisabled: boolean
  onCtaClick: () => void
  onDenyClick: () => void
  children: ReactNode
}

const Confirm = ({
  cta,
  ctaDisabled,
  onCtaClick,
  onDenyClick,
  children,
}: Props) => (
  <>
    <div tw="text-gray-800">{children}</div>
    <div tw="flex gap-5">
      <Button
        variant="default"
        tw="w-full justify-center text-gray-600"
        onClick={onDenyClick}
      >
        Go back
      </Button>
      <Button
        variant="primary"
        tw="w-full justify-center"
        disabled={ctaDisabled}
        onClick={onCtaClick}
      >
        {cta}
      </Button>
    </div>
  </>
)

export default Confirm
