import tw, { css } from "twin.macro"

export const giftContainerCss = css`
  ${tw`border sm:h-48 bg-white rounded-xl overflow-hidden flex flex-col sm:flex-row mx-auto w-full transition-shadow border-[#F5F1FA] max-w-[343px]`}

  box-shadow: 0px 1px 4px rgba(125, 64, 200, 0.04),
    0px 6px 20px rgba(125, 64, 200, 0.04);

  &:hover {
    box-shadow:
      0px 1px 8px rgba(125, 64, 200, 0.04),
      0px 8px 32px rgba(125, 64, 200, 0.08);
  }

  &:active {
    ${tw`bg-[#fdfcfe]`}

    box-shadow: 0px 1px 8px rgba(125, 64, 200, 0.04),
      0px 8px 32px rgba(125, 64, 200, 0.08);
  }

  @media (min-width: 640px) {
    ${tw`max-w-none`}
  }
`
