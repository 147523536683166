import React from "react"

import Gift from "./Gift"
import MyGifts from "./MyGifts"
import RealmRoute from "../common/RealmRoute"

const MyGiftsSwitch: React.FC = () => {
  return (
    <>
      <RealmRoute exact path="/my-gifts/:id" realm={"consumer"}>
        <Gift />
      </RealmRoute>
      <RealmRoute exact path="/my-gifts" realm={"consumer"}>
        <MyGifts />
      </RealmRoute>
    </>
  )
}

export default MyGiftsSwitch
