import Drawer from "@mui/material/Drawer"

import MemberDetail from "./MemberDetail"

import { BudgetingOrganizationMembershipFragment } from "@/types/graphql-types"

interface Props {
  open: boolean
  onClose: () => void
  membership: BudgetingOrganizationMembershipFragment | null
}

export default function MemberDetailDrawer({
  open,
  onClose,
  membership,
}: Props) {
  return (
    <Drawer anchor={"right"} open={open} onClose={onClose}>
      {membership && (
        <MemberDetail
          membership={membership}
          key={membership.id}
          onClose={onClose}
        />
      )}
    </Drawer>
  )
}
