import { Drawer } from "@mui/material"
import { useEffect, useState } from "react"

import close from "@/assets/icons/close.svg"
import Button from "@/common/CommonButton"
import DatePickerField from "@/common/DatePickerField"
import Image from "@/common/Image"
import RemoveFilterButton from "@/organization/receipts/RemoveFilterButton"

interface DateFilterDrawerProps {
  open: boolean
  onClose: () => void
  fromDate: Date | null
  setFromDate: (d: Date | null) => void
  toDate: Date | null
  setToDate: (d: Date | null) => void
}

const DateFilterDrawer = ({
  open,
  onClose,
  fromDate,
  setFromDate,
  toDate,
  setToDate,
}: DateFilterDrawerProps) => {
  const [localFromDate, setLocalFromDate] = useState<Date | null>(fromDate)
  const [localToDate, setLocalToDate] = useState<Date | null>(toDate)

  useEffect(() => {
    setLocalFromDate(fromDate)
    setLocalToDate(toDate)
  }, [open])

  const apply = () => {
    setFromDate(localFromDate)
    setToDate(localToDate)
    onClose()
  }

  const clear = () => {
    setLocalFromDate(null)
    setLocalToDate(null)
  }

  return (
    <Drawer anchor="left" open={open} onClose={onClose}>
      <div tw="flex flex-col m-9 min-w-[350px]">
        <div tw="flex justify-between items-center gap-4 mb-10">
          <div tw="font-medium text-2xl">Date</div>
          <Image src={close} tw="w-6 h-6 cursor-pointer" onClick={onClose} />
        </div>
        <div tw="flex justify-center gap-4 mb-8">
          <RemoveFilterButton onClick={clear} />
        </div>
        <div tw="flex flex-col items-center text-gray-450">
          <div tw="flex flex-col items-center gap-2 mb-8">
            <div>From</div>
            <DatePickerField
              date={localFromDate}
              onDateChange={(date) => setLocalFromDate(date)}
              openBelow={true}
              numMaximumDaysAhead={0}
              disableBefore={new Date(0)}
            />
          </div>
          <div tw="flex flex-col items-center gap-2 mb-8">
            <div>To</div>
            <DatePickerField
              date={localToDate}
              onDateChange={(date) => setLocalToDate(date)}
              openBelow={true}
              numMaximumDaysAhead={0}
              disableBefore={new Date(0)}
            />
          </div>
          <Button onClick={apply}>Apply</Button>
        </div>
      </div>
    </Drawer>
  )
}

export default DateFilterDrawer
