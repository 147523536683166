import React, { FC } from "react"
import nl2br from "react-nl2br"
import { Navigation, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import tw, { styled } from "twin.macro"

import SliderNavArrow from "../../assets/icons/slider_nav_arrow.png"
import CarouselBackground from "../images/CarouselBackground.png"

import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"

export interface ContentProps {
  textHtml?: React.ReactNode
  text?: string
  name: string
  title: string
  image: string
}

interface Props {
  content: ContentProps[]
}

const Carousel: FC<Props> = ({ content }) => {
  const onSlideTransition = (arrowDirection: string, method: string) => {
    const classList = document.getElementsByClassName(
      `swiper-button-${arrowDirection}`,
    )[0].classList
    if (method === "add") {
      classList.add("swiper-button-transition")
    } else {
      classList.remove("swiper-button-transition")
    }
  }

  return (
    <StyledSwiper
      modules={[Navigation, Pagination]}
      navigation
      pagination={{ clickable: true }}
      preventClicks={false}
      tw="relative w-full"
      onSlidePrevTransitionStart={() => onSlideTransition("prev", "add")}
      onSlidePrevTransitionEnd={() => onSlideTransition("prev", "remove")}
      onSlideNextTransitionStart={() => onSlideTransition("next", "add")}
      onSlideNextTransitionEnd={() => onSlideTransition("next", "remove")}
    >
      <div className="swiper-pagination" />

      {content.map(({ textHtml, text, name, title, image }, index) => (
        <SwiperSlide key={index}>
          <SwiperSlideContent>
            <SliderSlideText tw="text-lg sm:text-lg lg:text-2xl font-medium mb-4 text-white mb-8">
              {textHtml || nl2br(text)}
            </SliderSlideText>
            <SliderSlideText tw="text-lg text-white mb-8">
              <span tw="font-bold">{name}</span> — {title}
            </SliderSlideText>
            <img src={image} tw="mx-auto" />
          </SwiperSlideContent>
        </SwiperSlide>
      ))}
    </StyledSwiper>
  )
}

const StyledSwiper = styled(Swiper)`
  .swiper-slide {
    ${tw`self-center`}
  }

  @keyframes next-arrow-animate {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(0.8);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes prev-arrow-animate {
    0% {
      transform: scale(-1);
    }
    50% {
      transform: scale(-0.8);
    }
    100% {
      transform: scale(-1);
    }
  }

  background-image: url(${CarouselBackground});
  background-size: cover;

  .swiper-button-prev,
  .swiper-button-next {
    ${tw`transition`};

    width: 5rem;
    position: absolute;
    top: unset;
    bottom: 6.5rem;

    &::after {
      height: 3.75rem;
      width: 3.75rem;
      background-image: url(${SliderNavArrow});
      background-size: cover;
      content: "";
    }

    @media (min-width: 1024px) {
      top: 50%;
    }
  }

  .swiper-button-prev {
    left: calc(50% - 4.75rem);

    &::after {
      transform: scaleX(-1);
    }

    &.swiper-button-transition {
      &::after {
        animation: prev-arrow-animate 3s ease;
      }
    }

    @media (min-width: 1024px) {
      left: 4rem;
    }
  }

  .swiper-button-next {
    right: calc(50% - 4.75rem);

    @media (min-width: 1024px) {
      right: 4rem;
    }

    &.swiper-button-transition {
      &::after {
        animation: next-arrow-animate 3s ease;
      }
    }
  }

  .swiper-pagination {
    ${tw`absolute`};

    bottom: 11rem;

    @media (min-width: 1024px) {
      bottom: 4rem;
    }

    .swiper-pagination-bullet {
      ${tw`bg-white`};
    }
  }

  @keyframes fade-in {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  .swiper-slide-active {
    animation-name: fade-in;
    animation-duration: 2s;
    animation-fill-mode: both;
  }

  &:hover {
    .swiper-button-prev,
    .swiper-button-next {
      ${tw`opacity-75`};

      &:hover {
        ${tw`opacity-100`};

        &::before {
          opacity: 0.65;
        }
      }
    }

    .swiper-button-disabled {
      ${tw`opacity-20`};

      &:hover {
        ${tw`opacity-20`};
      }
    }
  }
`

const SwiperSlideContent = styled(SwiperSlide)`
  ${tw`pt-24 lg:pt-36 pb-24 lg:pb-40 px-8 text-center`}
`

const SliderSlideText = styled.h3`
  ${tw`mx-auto w-full lg:w-4/5`}
  max-width: 1024px;
`

export default Carousel
