import { useEffect, useState } from "react"
import tw from "twin.macro"

import AmountToFund from "./deposit/AmountToFund"
import CreditCardForm from "./deposit/CreditCardForm"
import FundingMethod from "./deposit/FundingMethod"
import InvoiceForm from "./deposit/InvoiceForm"
import { formatPrice } from "../../common/format"

import { useTopUpUIUnsafe } from "@/send/hooks/topUpUI"
import {
  BalanceDepositAccount,
  BalanceDepositFundingMethod,
} from "@/types/graphql-types"

const BALANCE_MINIMUM = 100

interface Props {
  fullWidth: boolean
  accountType: BalanceDepositAccount
  onSuccess: (props: {
    amountInCents: number
    type: "credit_card" | "invoice"
  }) => void
  defaultBalance?: number | null
}
export const BudgetingDepositContent = ({
  fullWidth,
  accountType,
  onSuccess,
  defaultBalance,
}: Props) => {
  const [amount, setAmount] = useState("")
  // We don't care about topUpAmount if we're not on send page
  const topUpAmount = useTopUpUIUnsafe()?.topUpAmount ?? 0

  const [fundingMethod, setFundingMethod] =
    useState<BalanceDepositFundingMethod>(
      BalanceDepositFundingMethod.CREDIT_CARD,
    )

  useEffect(() => {
    setAmount(
      topUpAmount && topUpAmount > 0
        ? formatPrice(topUpAmount)
        : defaultBalance
        ? formatPrice(Math.max(BALANCE_MINIMUM, defaultBalance))
        : "",
    )
  }, [topUpAmount, defaultBalance])

  return (
    <div css={fullWidth ? "" : tw`w-1/2 mx-auto`}>
      <AmountToFund amount={amount} setAmount={setAmount} />
      <FundingMethod method={fundingMethod} setMethod={setFundingMethod} />
      <div
        css={[
          fundingMethod !== BalanceDepositFundingMethod.CREDIT_CARD &&
            tw`hidden`,
        ]}
      >
        <CreditCardForm
          amount={amount}
          accountType={accountType}
          onSuccess={onSuccess}
        />
      </div>
      <div
        css={[
          fundingMethod !== BalanceDepositFundingMethod.INVOICE && tw`hidden`,
        ]}
      >
        <InvoiceForm
          amount={amount}
          accountType={accountType}
          onSuccess={onSuccess}
        />
      </div>
    </div>
  )
}
