// This file is used in both the frontend and backend (acceptance)
// Update this file in both locations.

import Cookies from "js-cookie"
import { v4 as uuidv4 } from "uuid"

const SESSION_ID_COOKIE_NAME = "__goody_clickstream_session_id"
const VISITOR_ID_COOKIE_NAME = "__goody_clickstream_visitor_id"

const SESSION_ID_EXPIRY = 60 / 24 // 60 minutes
const VISITOR_ID_EXPIRY = 3650 // 10 years

// Flip this to true to send clickstream events in development
const SEND_IN_DEVELOPMENT = true

// For deduplication
let lastEvent: string | null = null

const isProduction =
  window.location.hostname === "www.ongoody.com" ||
  window.location.hostname === "gifts.ongoody.com"

const cachedVisitorID = getVisitorID()
const cachedSessionID = getSessionID()

type CookieBasedIDName =
  | typeof SESSION_ID_COOKIE_NAME
  | typeof VISITOR_ID_COOKIE_NAME

interface FullParams {
  event_name: string
  event_value: string
  user_id: string | null
  visitor_id: string
  session_id: string
  gift_token?: string
  created_at_client: number
}

export function clickstreamEvent(eventName: string, eventValue: string) {
  const host = getClickstreamHost()

  if (!host) {
    return
  }

  if (isDuplicateEvent(eventName, eventValue)) {
    return
  }

  const fullParams: FullParams = {
    event_name: eventName,
    event_value: eventValue,
    // Visitor ID is assigned in a cookie.
    visitor_id: cachedVisitorID,
    // Session ID is assigned in a cookie.
    session_id: cachedSessionID,
    created_at_client: new Date().getTime(),
    // User ID is a window global, if set.
    user_id: (window as any).goodyUserID || undefined,
    // Gift token is a window global, if set.
    gift_token: (window as any).giftToken || undefined,
  }

  navigator.sendBeacon(`${host}/event`, JSON.stringify(fullParams))

  setTimeout(() => {
    updateSessionIDExpiry()
    updateVisitorIDExpiry()
  }, 500)
}

function getSessionID() {
  return getCookieBasedID(SESSION_ID_COOKIE_NAME)
}

function getVisitorID() {
  return getCookieBasedID(VISITOR_ID_COOKIE_NAME)
}

function updateSessionIDExpiry() {
  Cookies.set(SESSION_ID_COOKIE_NAME, cachedSessionID, {
    expires: SESSION_ID_EXPIRY,
  })
}

function updateVisitorIDExpiry() {
  Cookies.set(VISITOR_ID_COOKIE_NAME, cachedVisitorID, {
    expires: VISITOR_ID_EXPIRY,
    domain: isProduction ? ".ongoody.com" : undefined,
  })
}

function getCookieBasedID(name: CookieBasedIDName): string {
  // Attempt to retrieve the session ID from the cookie
  let id = Cookies.get(name)

  // If ID does not exist in the cookies, create a new one
  if (!id) {
    const prefix = name === SESSION_ID_COOKIE_NAME ? "session_" : "visitor_"
    id = prefix + uuidv4().replaceAll("-", "")

    Cookies.set(name, id, {
      // If session ID, expires in 60 minutes. If visitor ID, expires in 10 years
      expires:
        name === SESSION_ID_COOKIE_NAME ? SESSION_ID_EXPIRY : VISITOR_ID_EXPIRY,
      // If visitor ID in production, use cross domain
      domain:
        name === VISITOR_ID_COOKIE_NAME && isProduction
          ? ".ongoody.com"
          : undefined,
    })
  }

  return id
}

function getClickstreamHost() {
  if (window.location.hostname === "localhost" && SEND_IN_DEVELOPMENT) {
    return "http://localhost:3502"
  }

  if (isProduction && !isBot()) {
    return "https://cls.ongoody.com"
  }

  // Do not send in non-dev, non-prod environments
  return null
}

function isDuplicateEvent(eventName: string, eventValue: string) {
  const serializedEvent = `${eventName},${eventValue}`

  if (lastEvent === serializedEvent) {
    return true
  }

  lastEvent = serializedEvent
  return false
}

const isBot = () =>
  window.navigator.userAgent.match(/(Prerender|HeadlessChrome|PhantomJS)/i)
