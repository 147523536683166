import React from "react"
import { Link } from "react-router-dom"
import tw, { styled } from "twin.macro"

import {
  ArrowRight,
  CircleThumbsUp,
  ConfettiPopper,
  Diamond,
  Fire,
  Gift,
  PersonPlus,
  Smile,
  Star,
} from "../../../assets/icons"
import { generateRealmPath } from "../../../common/realm"

const popularSection = [
  {
    icon: <Fire tw="w-[21px] stroke-[#8167FF] group-hover:stroke-current" />,
    name: "Best Sellers",
    link: generateRealmPath("business", "/browse/category/best-sellers"),
  },
  {
    icon: (
      <CircleThumbsUp tw="w-[21px] stroke-[#8167FF] group-hover:stroke-current" />
    ),
    name: "Affordable",
    link: generateRealmPath("business", "/editorial/affordable-gifts"),
  },
  {
    icon: <Diamond tw="w-[21px] stroke-[#8167FF] group-hover:stroke-current" />,
    name: "Gift of Choice",
    link: generateRealmPath("business", "/browse/category/gift-of-choice"),
  },
  {
    icon: <Star tw="w-[21px] fill-[#8167FF] group-hover:fill-current" />,
    name: "Branded Swag",
    link: generateRealmPath("business", "/browse/category/branded-swag"),
  },
]

const occasions = [
  {
    icon: (
      <Smile tw="stroke-[#8167FF] fill-[#8167FF] group-hover:stroke-current group-hover:fill-current" />
    ),
    name: "Employee Appreciation",
    link: generateRealmPath(
      "business",
      "/browse/category/employee-appreciation",
    ),
  },
  {
    icon: (
      <Gift tw="stroke-[#8167FF] fill-[#8167FF] group-hover:stroke-current group-hover:fill-current" />
    ),
    name: "Client Gifts",
    link: generateRealmPath("business", "/browse/category/client-gifts"),
  },
  {
    icon: <ConfettiPopper tw="fill-[#8167FF] group-hover:fill-current" />,
    name: "Work Anniversary",
    link: generateRealmPath("business", "/browse/category/work-anniversary"),
  },
  {
    icon: (
      <PersonPlus tw="fill-[#8167FF] stroke-[#8167FF] group-hover:stroke-current group-hover:fill-current" />
    ),
    name: "Sales Prospecting",
    link: generateRealmPath("business", "/browse/category/sales-prospecting"),
  },
]

const BrowseGiftsDropdown = () => {
  return (
    <div tw="bg-transparent lg:bg-white pt-7 pb-4 lg:pb-9 px-4 flex-col lg:px-6 flex lg:flex-row rounded-xl">
      <div tw="lg:border-r lg:border-[rgba(6, 13, 37, 0.05)]">
        <DropdownColumnHeader>Popular</DropdownColumnHeader>
        <DropdownColumn tw="pr-7">
          {popularSection.map((item) => (
            <DropdownItem className={"group"} to={item.link} key={item.name}>
              {item.icon} {item.name}
            </DropdownItem>
          ))}
          <div tw="hidden lg:block">
            <ViewAllButton />
          </div>
        </DropdownColumn>
      </div>
      <div>
        <DropdownColumnHeader tw="mt-5 lg:mt-0 lg:ml-11">
          Occasions
        </DropdownColumnHeader>
        <DropdownColumn tw="lg:pl-7">
          {occasions.map((item) => (
            <DropdownItem className={"group"} to={item.link} key={item.name}>
              {item.icon} {item.name}
            </DropdownItem>
          ))}
          <div>
            <ViewAllButton />
          </div>
        </DropdownColumn>
      </div>
    </div>
  )
}

const ViewAllButton = () => (
  <Link
    to={generateRealmPath("business", "/browse")}
    tw="svg:inline flex gap-1.5 items-center ml-4 font-semibold text-base lg:text-xs mt-4 lg:block"
  >
    View All <ArrowRight tw="stroke-[#8167FF] scale-125 lg:scale-100" />
  </Link>
)

const DropdownColumnHeader = styled.h6`
  ${tw`text-[#888888] ml-4 font-bold text-xs uppercase mb-5`}
`

const DropdownColumn = styled.div`
  ${tw`flex flex-col gap-0 lg:gap-1`}
`

const DropdownItem = styled(Link)`
  ${tw`px-4 py-3 text-left flex gap-3 items-center font-semibold svg:inline text-[#1F2024] text-base whitespace-nowrap hover:rounded-md hover:bg-[rgba(60, 71, 121, 0.05)]`}
`

export default BrowseGiftsDropdown
