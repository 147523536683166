import "react-placeholder/lib/reactPlaceholder.css"

import { RectShape } from "react-placeholder/lib/placeholders"

const PDPPlaceholder = () => {
  return (
    <div
      className="show-loading-animation"
      tw="flex items-center justify-center pb-12 pt-24 lg:pt-12 lg:px-12"
    >
      <div tw="flex flex-col lg:flex-row pb-7 lg:pb-0 gap-16">
        <RectShape
          color="#F6F7F9"
          style={{
            borderRadius: 12,
            height: 403,
            width: 410,
          }}
        />
        <div>
          <RectShape
            color="#F6F7F9"
            style={{
              borderRadius: 6,
              height: 24,
              width: 155,
            }}
            tw="mb-3"
          />
          <RectShape
            color="#F6F7F9"
            style={{
              borderRadius: 6,
              height: 36,
              width: 219,
            }}
            tw="mb-8"
          />
          <RectShape
            color="#F6F7F9"
            style={{
              borderRadius: 6,
              height: 20,
              width: 519,
            }}
            tw="mb-1"
          />
          <RectShape
            color="#F6F7F9"
            style={{
              borderRadius: 6,
              height: 20,
              width: 519,
            }}
            tw="mb-1"
          />
          <RectShape
            color="#F6F7F9"
            style={{
              borderRadius: 6,
              height: 20,
              width: 219,
            }}
            tw="mb-24"
          />
          <RectShape
            color="#F6F7F9"
            style={{
              borderRadius: 6,
              height: 45,
              width: 144,
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default PDPPlaceholder
