import { useGlobalState } from "../GlobalState"
import { getBusinessSegmentFromRealm } from "../realm"

import { BusinessSegment } from "@/types/graphql-types"

export const useSegmentAvailable = (segments: BusinessSegment[]) => {
  const [currentRealm] = useGlobalState("currentRealm")

  const businessSegment = getBusinessSegmentFromRealm(currentRealm)

  return {
    isAvailable: segments.includes(businessSegment),
    segmentText:
      businessSegment === BusinessSegment.consumer ? "personal" : "business",
  }
}
