import "twin.macro"
import { useEffect, useState } from "react"
import tw from "twin.macro"

const LOCALSTORAGE_KEY = "__goody_store_hide_modal_close_hint"

// Display this every 4 times that this component is mounted
// Since React.StrictMode mounts components twice, this will be displayed every
// 8 times in development which really is every 4 times in actuality accounting
// for double render
const DISPLAY_EVERY_N_TIMES = import.meta.env.MODE === "development" ? 8 : 4

const WINDOW_EVENT_NAME = "hide_modal_close_hint"

let hideModalCloseHintCached = localStorage.getItem(LOCALSTORAGE_KEY) === "true"
let displayedTimes = 0

// initial: no content rendered
// visible: visible on the screen
// hidden: animated out
type Status = "initial" | "visible" | "hidden"

// External components can call this to hide the hint on all mounted versions
export function hideModalCloseHint() {
  hideModalCloseHintCached = true
  localStorage.setItem(LOCALSTORAGE_KEY, "true")
  window.dispatchEvent(new Event(WINDOW_EVENT_NAME))
}

// ModalCloseHint is a component that overlays on top of gift option modals and
// PDP modals to remind people that they can click outside the modal to close it.
// It hides whenever they click outside a modal to close it, or when they click
// the "got it" button. Until then, it will be displayed every 4 times that a
// modal is opened.
export default function ModalCloseHint() {
  const [status, setStatus] = useState<Status>("initial")

  useEffect(() => {
    if (hideModalCloseHintCached || window.innerWidth < 1080) {
      return
    }

    if (displayedTimes % DISPLAY_EVERY_N_TIMES === 0) {
      setStatus("visible")
    }

    displayedTimes++
  }, [])

  // When this component is made hidden in another location, hide it here too
  function handleHiddenInOtherLocation() {
    setStatus("hidden")
  }

  function hide() {
    hideModalCloseHint()
    setStatus("hidden")
  }

  useEffect(() => {
    // Listen for the event "hide_modal_close_hint" to hide the hint when closed
    // on a PDP when a Gift Option is displayed below
    window.addEventListener(WINDOW_EVENT_NAME, handleHiddenInOtherLocation)

    return () => {
      window.removeEventListener(WINDOW_EVENT_NAME, handleHiddenInOtherLocation)
    }
  }, [])

  return (
    <div
      tw="absolute top-5 left-5 h-[80px] z-10 transition-all duration-300 pointer-events-none opacity-0 translate-x-[20px]"
      css={[
        status === "visible" &&
          tw`opacity-100 pointer-events-auto translate-x-0`,
      ]}
      className="modal-close-hint"
    >
      {status !== "initial" && (
        <>
          <img
            src="https://assets.ongoody.com/static/web/store/close-modal-hint.svg"
            tw="w-[135px] h-[60px] absolute left-[-108px] top-[10px]"
            alt=""
          />
          <div tw="z-10 bg-white h-[80px] px-5 relative flex flex-row items-center gap-6 rounded-lg border-gray-200 shadow-[0px 4px 12px 0px rgba(0, 0, 0, 0.05)]">
            <div>
              Click outside this
              <br />
              window to close
            </div>
            <button
              tw="bg-gray-100 hover:bg-gray-200 active:bg-gray-300 transition-colors rounded-lg py-1.5 font-medium w-[64px]"
              onClick={hide}
            >
              {status === "hidden" ? "🎉" : "Got it"}
            </button>
          </div>
        </>
      )}
    </div>
  )
}
