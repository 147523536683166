import React, { Dispatch, FC, SetStateAction } from "react"
import Toggle from "react-toggle"
import tw, { styled } from "twin.macro"
import "react-toggle/style.css"

interface Props {
  isAnnualBilling: boolean
  setIsAnnualBilling: Dispatch<SetStateAction<boolean>>
}

const MonthlyAnnualToggle: FC<Props> = ({
  isAnnualBilling,
  setIsAnnualBilling,
}) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsAnnualBilling(e.target.checked)
  }

  return (
    <Container>
      <Label
        onClick={() => setIsAnnualBilling(false)}
        tw="mr-4 flex-1 text-right"
        active={!isAnnualBilling}
      >
        Monthly billing
      </Label>
      <Toggle checked={isAnnualBilling} icons={false} onChange={onChange} />
      <Label
        onClick={() => setIsAnnualBilling(true)}
        tw="ml-4 flex-1 text-left"
        active={isAnnualBilling}
      >
        Annual billing
      </Label>
    </Container>
  )
}

interface LabelProps {
  active: boolean
  onClick: () => void
}

const Label = styled.h3<LabelProps>`
  ${tw`font-medium text-gray-500 cursor-pointer text-center`}
  ${({ active }) => active && tw`text-black`}
`

const Container = styled.div`
  ${tw`flex items-center justify-center pb-8 md:pb-12 pt-8`}

  .react-toggle-track {
    background-color: #7d66ff !important;
    height: 26px;
  }

  .react-toggle-thumb {
    top: 2px;
    left: 2px;
    border: 0;
  }

  .react-toggle--checked .react-toggle-thumb {
    left: 26px;
  }

  .react-toggle--focus .react-toggle-thumb,
  .react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
    box-shadow: none;
  }
`

export default MonthlyAnnualToggle
