import tw, { styled } from "twin.macro"

export const BasicHeader = styled.div`
  ${tw`text-5xl`}
`

export const BasicSection = styled.div`
  ${tw`mt-16`}
`

export const BasicSectionHeader = styled.div`
  ${tw`text-6xl`}
`

export const BasicSectionSubheader = styled.div`
  ${tw`text-4xl mt-16 font-semibold`}
`

export const BasicQuestion = styled.div`
  ${tw`text-primary-500 mt-6 text-lg font-semibold`}
`

export const BasicAnswer = styled.div`
  ${tw`mt-2.5`}
`

export const BasicSectionStart = styled.div`
  ${tw`mt-6`}
`

export const BasicParagraphStart = styled.div`
  ${tw`mt-2.5`}
`

export const BasicList = styled.ul`
  ${tw`mt-2.5 list-disc ml-6`}
`

export const BasicListItem = styled.li`
  ${tw`mt-2.5`}
`

export const SemiboldSpan = styled.span`
  ${tw`font-semibold`}
`

export const PrimarySpan = styled(SemiboldSpan)`
  ${tw`font-semibold text-primary-500`}
`

export const CopiedHtmlContainer = styled.div`
  p {
    ${tw`pt-2.5`}
  }

  ol {
    ${tw`mt-4 list-decimal ml-6 text-primary-500 font-semibold`}

    li {
      ${tw`mt-1.5`}
    }
  }

  ul {
    ${tw`mt-4 list-disc ml-6`}

    li {
      ${tw`mt-1.5`}
    }
  }

  & > h2 {
    ${tw`text-6xl mt-8`}
  }

  & > div {
    & > h2 {
      ${tw`text-4xl mt-16`}
    }
  }

  td {
    ${tw`align-top`}
  }

  th {
    ${tw`text-left`}
  }

  table,
  th,
  td {
    ${tw`border border-collapse`}
  }

  table {
    ${tw`mt-2.5`}
  }
`
