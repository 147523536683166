import { ReactNode } from "react"
import Modal from "react-modal"

import closeIcon from "@/assets/icons/close.svg"
import Image from "@/common/Image"
import { modalStyle } from "@/common/modal"
import { PaymentMethod as PaymentMethodType } from "@/common/PaymentMethodsManager"

const ModalExitButton = ({ close }: { close: () => void }) => (
  <button
    tw="p-3 right-5 top-5 absolute rounded-full hover:bg-gray-075 active:bg-gray-100 active:scale-90 transition-all ease-out duration-200"
    onClick={close}
  >
    <Image src={closeIcon} tw="h-5 w-5" />
  </button>
)

export interface PaymentMethodModalProps {
  open: boolean
  close: () => void
  paymentMethod: PaymentMethodType
  refreshPaymentMethods: () => Promise<void>
}

export const PaymentMethodModalWrapper = ({
  open,
  close,
  children,
}: Omit<PaymentMethodModalProps, "paymentMethod" | "refreshPaymentMethods"> & {
  children: ReactNode
}) => (
  <Modal
    isOpen={open}
    closeTimeoutMS={500}
    onRequestClose={close}
    shouldCloseOnEsc={false}
    shouldCloseOnOverlayClick={true}
    style={modalStyle}
  >
    <div
      className="modal-content modal-content-medium"
      tw="p-8 flex flex-col gap-8"
    >
      <ModalExitButton close={close} />
      {children}
    </div>
  </Modal>
)
