import { useQuery } from "@apollo/client"
import React from "react"
import tw from "twin.macro"

import { CustomStoreCreateButton } from "./CustomCreateButton"
import { CustomProductsUpsell } from "./CustomProductsUpsell"
import { CustomStoresList } from "./CustomStoresList"
import { CustomStoresUpsell } from "./CustomStoresUpsell"
import { ORGANIZATION_SUBSCRIPTION_PLAN_QUERY } from "./graphql"
import { useFeatureAccess } from "../../common/hooks/featureAccess"
import LimitedMemberFeatureGateBanner from "../../common/members/LimitedMemberFeatureGateBanner"
import { Loader } from "../../common/UI"

interface CustomStoresProps {
  onSelectCustomStoreProductID?: Function
}

const CustomStores: React.FC<CustomStoresProps> = ({
  onSelectCustomStoreProductID,
}) => {
  const { hasFeature } = useFeatureAccess()
  const { data, loading } = useQuery(ORGANIZATION_SUBSCRIPTION_PLAN_QUERY)

  if (loading) {
    return (
      <div tw="flex justify-center items-center col-span-2">
        <Loader />
      </div>
    )
  }

  if (
    data?.organization?.subscription?.isTeamPlan &&
    !data?.me?.isFullOrganizationMember
  ) {
    return (
      <div tw="p-4 lg:ml-16 lg:mt-10 lg:mb-6 col-span-2">
        <LimitedMemberFeatureGateBanner />
      </div>
    )
  }

  if (!hasFeature("custom_stores")) {
    return (
      <div tw="lg:p-10 lg:pr-4 w-full col-span-2 flex flex-col gap-4 lg:gap-12">
        <h1 tw="hidden lg:flex font-medium text-2xl">Custom</h1>
        <CustomStoresUpsell />
        <CustomProductsUpsell />
      </div>
    )
  }

  return (
    <div
      tw="col-span-2 overflow-y-auto p-4 lg:(ml-16 mt-10 mb-6)"
      css={{
        ...(loading ? tw`overflow-y-hidden` : tw`overflow-y-auto`),
      }}
    >
      {!onSelectCustomStoreProductID && (
        <>
          <Text tw="mb-7">Custom</Text>
          <div tw="mt-7 mb-16">
            <CustomStoreCreateButton />
          </div>
        </>
      )}

      <Text tw="mb-6">Custom Stores</Text>
      <CustomStoresList
        onSelectCustomStoreProductID={onSelectCustomStoreProductID}
      />
    </div>
  )
}

export default CustomStores

const Text = tw.h1`font-medium text-2xl`
