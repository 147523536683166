import React, { useEffect, useRef, useState } from "react"
import tw, { styled } from "twin.macro"

import { foreignCentsToUSDCents } from "@/common/forex"
import { formatPrice } from "@/common/format"

const denominationStyle = tw`border rounded-lg border-gray-150 block w-full text-gray-500 text-lg flex flex-col items-center justify-center h-14 outline-none text-center transition-colors`
const denominationLargeStyle = tw`h-16`
const denominationHoverStyle = tw`border-primary-300`
const denominationActiveStyle = tw`border-2 border-primary-400 text-primary-500`

export const DenominationButton = styled.button.attrs({ type: "button" })<{
  selected: boolean
  large?: boolean
}>`
  ${denominationStyle}
  ${({ selected }) => selected && denominationActiveStyle}
  ${({ large }) => large && denominationLargeStyle}
  &:hover {
    ${denominationHoverStyle}
  }
  &:active {
    ${denominationActiveStyle}
  }
`

export const DenominationInput = styled.input.attrs({ type: "number" })`
  ${tw`focus:outline-none text-center`}
  &:placeholder-shown {
    ${tw`w-36`}
  }
  // Disable spinner in input type="number".
  &[type="number"] {
    -moz-appearance: textfield;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`

export const StyledDenominationField = styled.div<{
  large?: boolean
}>`
  ${denominationStyle}
  ${({ large }) => large && denominationLargeStyle}
  ${tw`flex flex-col cursor-text`}

  .currency {
    padding-right: 0.5rem;
  }

  #denomination-wrapper > ${DenominationInput}:placeholder-shown + .currency {
    ${tw`hidden`}
  }

  &:hover {
    ${denominationHoverStyle}
  }

  &:focus-within {
    ${denominationActiveStyle}
  }
`

interface DenominationFieldProps {
  min?: number
  max?: number
  setDenomination: (denomination: number) => void
  setDenominationValid: (valid: boolean) => void
  selectedDenomination: number | null
  currency: string
  currencyRate: number
}

export const DenominationField: React.FC<DenominationFieldProps> = ({
  min,
  max,
  setDenomination,
  setDenominationValid,
  selectedDenomination,
  currency,
  currencyRate,
}) => {
  const ref = useRef<HTMLInputElement>(null)
  const element = ref.current
  const [width, setWidth] = useState<number | undefined>(undefined)
  const [
    shouldDisplayCurrentDenomination,
    setShouldDisplayCurrentDenomination,
  ] = useState<boolean>(false)

  useEffect(() => {
    // set width to text width, which is the scrollWidth
    setWidth(element ? element.scrollWidth : undefined)
  }, [selectedDenomination, element])

  return (
    <StyledDenominationField
      large={currency !== "USD"}
      onClick={() => element?.focus()}
    >
      <div id="denomination-wrapper">
        <span className="currency">
          {currency && currency !== "USD" ? currency : "$"}
        </span>
        <DenominationInput
          placeholder="Custom amount"
          min={min}
          max={max}
          step={1}
          ref={ref}
          css={{ width }}
          required
          onChange={({ target }) => {
            setDenomination(parseInt(target.value))

            if (!shouldDisplayCurrentDenomination) {
              setShouldDisplayCurrentDenomination(true)
            }

            if (target.value.length === 0) {
              setWidth(undefined)
            } else if (
              !selectedDenomination ||
              target.value.length < selectedDenomination.toString().length
            ) {
              setWidth(0) // Reset scrollWidth for shorter values
            }

            setTimeout(() => {
              setDenominationValid(true)
              target.reportValidity()
            }, 200)
          }}
          onInvalid={(event) => {
            event.preventDefault()
            setDenominationValid(false)
          }}
          value={
            shouldDisplayCurrentDenomination && selectedDenomination
              ? selectedDenomination
              : ""
          }
        />
      </div>
      {currency !== "USD" && (
        <div tw="text-sm text-gray-500">
          {selectedDenomination
            ? formatPrice(
                foreignCentsToUSDCents(
                  selectedDenomination * 100,
                  currencyRate,
                ),
              )
            : "Enter an amount"}
        </div>
      )}
    </StyledDenominationField>
  )
}

export const suggestedDenominations = (min: number, max: number) =>
  [10, 20, 50, 100, 150, 200]
    .filter((denomination) => denomination >= min && denomination <= max)
    .slice(0, 4)
