import { gql, useMutation } from "@apollo/client"
import React, { useState } from "react"
import { Helmet } from "react-helmet-async"
import { toast } from "react-hot-toast"
import { useHistory, useLocation } from "react-router-dom"
import { useIntercom } from "react-use-intercom"
import tw, { styled } from "twin.macro"

import Button from "../common/Button"
import PasswordInputField from "../common/PasswordInputField"
import { generateRealmPath } from "../common/realm"

import {
  Reset_PasswordMutation,
  Reset_PasswordMutationVariables,
} from "@/types/graphql-types"

const ResetPassword: React.FC = () => {
  const [password, setPassword] = useState("")
  const [error, setError] = useState("")

  const [resetPassword, { loading }] = useMutation<
    Reset_PasswordMutation,
    Reset_PasswordMutationVariables
  >(RESET_PASSWORD)

  const history = useHistory()
  const { hash } = useLocation()
  const token = hash.split("#token=")[1]
  const { show } = useIntercom()

  const [showPhoneCheck, setShowPhoneCheck] = useState(false)
  const [userPhone, setUserPhone] = useState<string | null>(null)

  // Events

  const onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError("")
    setPassword(event.target.value)
  }

  const onSubmit = async () => {
    if (!token) {
      alert("No password reset token.")
      return
    }

    const response = await resetPassword({
      variables: { password: password, token: token },
    })

    if (response?.data?.resetPassword?.ok) {
      if (response.data.resetPassword.userPhoneNumber) {
        setUserPhone(response.data.resetPassword.userPhoneNumber)
        setShowPhoneCheck(true)
      } else {
        showSuccessToast()
        history.push(generateRealmPath("business", "/signin"))
      }
    } else {
      if (response?.data?.resetPassword?.error) {
        setError(response.data.resetPassword.error)
      }
    }
  }

  const showSuccessToast = () => {
    toast.success("Password successfully reset", {
      iconTheme: {
        primary: "#27ae60",
        secondary: "#fff",
      },
    })
  }

  // Template

  return (
    <>
      <Helmet>
        <title>Reset Password – Goody for Business</title>
      </Helmet>

      <BackgroundGradientContainer>
        <div tw="container mx-auto">
          <div tw="xl:w-2/3 mx-auto">
            <MainContainer>
              {showPhoneCheck ? (
                <div tw="pt-12">
                  <FormHeading>Do you recognize this phone number?</FormHeading>

                  <FormContent>
                    <p>
                      <strong tw="font-semibold text-red-600">
                        The phone number {userPhone} is currently associated
                        with your account.
                      </strong>{" "}
                      If you don't recognize this phone number, please contact
                      support and do not sign in.
                    </p>
                    <div tw="flex flex-col items-center gap-2 pt-8">
                      <Button
                        onClick={() => {
                          showSuccessToast()
                          history.push("/business/signin")
                        }}
                      >
                        Yes, I recognize {userPhone}, continue to sign in
                      </Button>
                      <Button
                        onClick={() => {
                          show()
                          alert(
                            "Please email us at support@ongoody.com or use help chat, and do not sign in to your account.",
                          )
                        }}
                      >
                        No, I don't recognize that number
                      </Button>
                    </div>
                  </FormContent>
                </div>
              ) : (
                <FormContainer
                  onSubmit={(e) => {
                    e.preventDefault()
                    onSubmit()
                  }}
                >
                  <FormHeading>Reset password</FormHeading>

                  <FormContent>
                    <p>Enter a new password below.</p>

                    <InputContainer>
                      <PasswordInputField
                        placeholder="New password"
                        onChange={onPasswordChange}
                        value={password}
                        autoFocus={true}
                        error={error}
                        className="ph-no-capture fs-exclude data-hj-suppress"
                      />
                    </InputContainer>

                    <div tw="flex justify-center mt-4">
                      <Button onClick={onSubmit} disabled={loading}>
                        Submit
                      </Button>
                    </div>
                  </FormContent>
                </FormContainer>
              )}
            </MainContainer>
          </div>
        </div>
      </BackgroundGradientContainer>
    </>
  )
}

// Styles

export const BackgroundGradientContainer = styled.div`
  background: repeat-x
    linear-gradient(
      90deg,
      rgba(228, 161, 184, 0.15) 30.58%,
      rgba(179, 125, 213, 0.15) 100%
    );
  ${tw`px-5 py-32`}
  background-size: 100% 400px;

  @media (max-width: 768px) {
    background-size: 100% 800px;
  }
`

export const MainContainer = styled.div`
  max-width: 530px;
  margin: 0 auto;
  ${tw`rounded-2xl bg-white divide-y divide-gray-100`}
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.04), 0 8px 50px rgba(0, 0, 0, 0.06);
  overflow: hidden;
`

export const FormContainer = styled.form`
  padding: 2rem;
`

const FormHeading = styled.div`
  ${tw`text-2xl font-medium`}
  text-align: center;
`

const FormContent = styled.div`
  ${tw`bg-white transition-opacity`}
  margin: 1em 0 3em;

  p {
    width: 75%;
    margin: 0 auto;
    text-align: center;
  }
`

export const InputContainer = styled.div`
  ${tw`flex flex-row`}
  margin-top: 2em;
  overflow: hidden;
`

const RESET_PASSWORD = gql`
  mutation Reset_Password($password: String!, $token: String!) {
    resetPassword(password: $password, token: $token) {
      ok
      error
      userPhoneNumber
    }
  }
`

export default ResetPassword
