import "twin.macro"
import React, { useCallback } from "react"

import FormField from "../../common/FormField"
import {
  MailingAddressWithName,
  useGlobalState,
} from "../../common/GlobalState"
import { usePlacesAutocomplete } from "../../common/hooks/usePlacesAutocomplete"
import RecipientStateInput from "../components/RecipientStateInput"

export default function MailingAddressInput() {
  const [recipient, setRecipient] = useGlobalState("consumerRecipient")
  const formFields = recipient.mailingAddress

  const setFormFields = useCallback(
    (fields: MailingAddressWithName) => {
      setRecipient((recip) => {
        return {
          ...recip,
          // Set the name so that places that use this name are up to date
          name: [fields.firstName, fields.lastName].filter(Boolean).join(" "),
          mailingAddress: {
            ...fields,
          },
        }
      })
    },
    [setRecipient],
  )

  const setFormField = (name: string, value: string) => {
    setFormFields({
      firstName: formFields?.firstName || "",
      lastName: formFields?.lastName || "",
      address1: formFields?.address1 || "",
      address2: formFields?.address2 || "",
      city: formFields?.city || "",
      state: formFields?.state || "",
      postalCode: formFields?.postalCode || "",
      [name]: value,
    })
  }

  const { autocompleteFieldRef } = usePlacesAutocomplete({
    onAutocompletePlace: (place) => {
      const { country, ...result } = place
      setFormFields({
        firstName: formFields?.firstName || "",
        lastName: formFields?.lastName || "",
        address1: formFields?.address1 || "",
        address2: formFields?.address2 || "",
        city: formFields?.city || "",
        state: formFields?.state || "",
        postalCode: formFields?.postalCode || "",
        ...result,
      })
    },
  })

  return (
    <div tw="mx-6 sm:mx-9 my-9">
      <div
        tw="border border-gray-150 rounded-lg"
        className="data-hj-suppress ph-no-capture fs-exclude"
      >
        <div tw="flex flex-row border-b border-gray-150">
          <div tw="p-3 px-4 border-r border-gray-150 flex-1">
            <FormField
              name="firstName"
              label="First name"
              placeholder="First name"
              value={formFields?.firstName || ""}
              onChange={setFormField}
            />
          </div>
          <div tw="p-3 px-4 flex-1">
            <FormField
              name="lastName"
              label="Last name"
              placeholder="Last name"
              value={formFields?.lastName || ""}
              onChange={setFormField}
            />
          </div>
        </div>
        <div tw="border-b border-gray-150 p-3 px-4">
          <FormField
            name="address1"
            label="Address 1"
            placeholder="185 Berry Street"
            value={formFields?.address1 || ""}
            onChange={setFormField}
            ref={autocompleteFieldRef}
          />
        </div>
        <div tw="border-b border-gray-150 p-3 px-4">
          <FormField
            name="address2"
            label="Address 2"
            placeholder="Optional"
            value={formFields?.address2 || ""}
            onChange={setFormField}
          />
        </div>
        <div tw="flex flex-row flex-wrap md:flex-nowrap">
          <div tw="p-3 px-4 md:border-r border-gray-150 flex-2">
            <FormField
              name="city"
              label="City"
              placeholder="San Francisco"
              value={formFields?.city || ""}
              onChange={setFormField}
            />
          </div>
          <div tw="w-full border-b border-gray-150 md:hidden" />
          <div tw="flex-1 p-3 px-4 border-r border-gray-150">
            <div tw="text-sm font-medium pb-1 text-gray-600 font-text">
              State
            </div>
            <div>
              <RecipientStateInput
                value={formFields?.state}
                onChange={(val) => {
                  setFormField("state", val)
                }}
                unstyled={true}
              />
            </div>
          </div>
          <div tw="flex-1 p-3 px-4">
            <FormField
              name="postalCode"
              label="ZIP"
              placeholder="12345"
              value={formFields?.postalCode || ""}
              onChange={setFormField}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
