// https://github.com/manufont/react-swipeable-bottom-sheet
import React from "react"
import SwipeableViews from "react-swipeable-views"

import HeightUpdater from "./HeightUpdater"

interface State {
  open?: boolean
  height: number
  swipeableViewsDisabled: boolean
}

interface Props {
  bodyStyle?: any
  className?: string
  defaultOpen?: boolean
  fullScreen?: boolean
  marginTop?: number
  onChange?: any
  onTransitionEnd?: any
  open?: boolean
  overflowHeight?: number
  overlay?: boolean
  overlayStyle?: any
  scrollTopAtClose?: boolean
  shadowTip?: boolean
  style?: any
  swipeableViewsProps?: any
  topShadow?: boolean
  bodyRef?: React.Ref<HTMLDivElement>
}

class SwipeableBottomSheet extends React.Component<Props, State> {
  // bodyElt: React.Ref<HTMLDivElement>;
  bodyElt: any

  static defaultProps = {
    defaultOpen: false,
    fullScreen: false,
    marginTop: 0,
    overflowHeight: 0,
    overlay: true,
    scrollTopAtClose: true,
    shadowTip: true,
    swipeableViewsProps: {},
    topShadow: true,
  }

  constructor(props: Props) {
    super(props)

    this.onHeightChange = this.onHeightChange.bind(this)
    this.onChangeIndex = this.onChangeIndex.bind(this)
    this.onTransitionEnd = this.onTransitionEnd.bind(this)
    this.handleScroll = this.handleScroll.bind(this)
    this.bodyElt = props.bodyRef || React.createRef<HTMLDivElement>()

    this.state = {
      open: props.defaultOpen,
      height: window.innerHeight,
      swipeableViewsDisabled: false,
    }
  }

  componentDidMount() {
    if (this.bodyElt.current) {
      this.bodyElt.current.addEventListener("scroll", this.handleScroll)
    }
  }

  handleScroll(e: any) {
    const scrollTop = this.bodyElt.current.scrollTop
    if (scrollTop <= 0 && this.state.swipeableViewsDisabled) {
      this.setState({
        swipeableViewsDisabled: false,
      })
    } else if (scrollTop > 0 && !this.state.swipeableViewsDisabled) {
      this.setState({
        swipeableViewsDisabled: true,
      })
    }
  }

  onHeightChange(height: number) {
    this.setState({ height })
  }

  onChangeIndex(index: number) {
    const open = index === 1
    if (this.props.open === undefined) {
      this.setState({ open })
    }
    if (this.props.onChange !== undefined) {
      this.props.onChange(open)
    }
  }

  onTransitionEnd() {
    const { overflowHeight, swipeableViewsProps } = this.props
    if (overflowHeight === 0) {
      this.bodyElt.current.scrollTop = 0
    }
    if (swipeableViewsProps.onTransitionEnd) {
      swipeableViewsProps.onTransitionEnd()
    }
  }

  render() {
    const {
      className,
      overflowHeight,
      fullScreen,
      marginTop,
      open,
      topShadow,
      shadowTip,
      overlay,
      swipeableViewsProps,
    } = this.props

    const hiddenWhenClosed = overflowHeight === 0
    const isControlled = open !== undefined
    const isOpen = isControlled ? open : this.state.open
    const hideShadows = hiddenWhenClosed && !isOpen
    const index = isOpen ? 1 : 0
    const maxHeight = this.state.height - (marginTop ?? 0)

    const styles = {
      root: {
        height: overflowHeight,
        position: "fixed",
        bottom: 0,
        right: 0,
        left: 0,
        zIndex: 60,
        ...this.props.style,
      },
      swiper: {
        root: {
          overflowY: "initial",
          boxSizing: "border-box",
          ...swipeableViewsProps.style,
        },
        container: {
          boxSizing: "border-box",
          ...(topShadow &&
            !hideShadows && {
              boxShadow: "rgba(0, 0, 0, 0.156863) 0px -6px 5px",
              borderTopRightRadius: 16,
              borderTopLeftRadius: 16,
            }),
          ...swipeableViewsProps.containerStyle,
        },
        slide: {
          boxSizing: "border-box",
          overflow: "visible",
          marginBottom: -(overflowHeight ?? 0),
          ...swipeableViewsProps.slideStyle,
        },
        bottomSlide: {
          marginBottom: overflowHeight,
        },
        body: {
          overflow: isOpen ? "auto" : "hidden",
          backgroundColor: fullScreen ? "white" : "initial",
          height: fullScreen ? maxHeight : "initial",
          maxHeight: maxHeight,
          ...this.props.bodyStyle,
        },
      },
      overlay: {
        position: "fixed",
        top: 0,
        right: 0,
        left: 0,
        height: this.state.height,
        transition: "opacity 450ms",
        pointerEvents: "none",
        backgroundColor: "black",
        opacity: 0,
        ...(isOpen && {
          opacity: 0.54,
          pointerEvents: "auto",
        }),
        ...this.props.overlayStyle,
      },
      shadowTip: {
        position: "fixed" as "fixed",
        height: 60,
        width: "200%",
        bottom: -60,
        left: "-50%",
        boxShadow: "rgba(0, 0, 0, 0.7) 0px 0px 30px",
        transition: "transform 450ms",
        transform: isOpen ? "translateY(50px)" : "translateY(0)",
      },
    }

    return (
      <div style={styles.root} className={className}>
        <HeightUpdater
          height={this.state.height}
          onHeightChange={this.onHeightChange}
        />
        {overlay && (
          <div style={styles.overlay} onClick={() => this.onChangeIndex(0)} />
        )}
        <SwipeableViews
          index={index}
          axis="y"
          enableMouseEvents
          onChangeIndex={this.onChangeIndex}
          disabled={true}
          {...this.props.swipeableViewsProps}
          onTransitionEnd={this.onTransitionEnd}
          style={styles.swiper.root}
          containerStyle={styles.swiper.container}
          slideStyle={styles.swiper.slide}
        >
          <div
            ref={this.bodyElt}
            style={styles.swiper.body}
            className={`ReactSwipeableBottomSheet--${
              isOpen ? "open" : "closed"
            }`}
          >
            {this.props.children}
          </div>
          <div style={styles.swiper.bottomSlide} />
        </SwipeableViews>
        {shadowTip && !hideShadows && <div style={styles.shadowTip} />}
      </div>
    )
  }
}

export default SwipeableBottomSheet
