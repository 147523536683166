import { gql } from "@apollo/client"

const organizationGetBillingEmail = gql`
  query OrganizationGetBillingEmail {
    organization {
      id
      billingEmails
    }
  }
`

export default organizationGetBillingEmail
