import { ReactNode, useRef, useState } from "react"
import tw from "twin.macro"

import Image from "@/common/Image"
import alertRipple from "@/common/images/alert-ripple.svg"
import closeRippleActive from "@/common/images/close-ripple-active.svg"
import closeRipple from "@/common/images/close-ripple.svg"
import TractorBeam from "@/common/TractorBeam"
import TransitionHideable from "@/common/TransitionHideable"

export interface AlertBubbleProps {
  text: string
  subtext?: string
  position?: "above" | "below"
  open: boolean
  onClose: () => void
  className?: string
  children: ReactNode
}

const AlertBubble = ({
  text,
  subtext,
  position = "above",
  open,
  onClose,
  className,
  children,
}: AlertBubbleProps) => {
  const ref = useRef(null)
  const [hover, setHover] = useState(false)
  const [active, setActive] = useState(false)
  const inactiveIcon = hover ? closeRipple : alertRipple
  const icon = active ? closeRippleActive : inactiveIcon
  const above = position === "above"
  const bubblePosition = above
    ? tw`bottom-[calc(100% + 18px)]`
    : tw`top-[calc(100% + 18px)]`
  const caratPosition = above
    ? tw`before:top-[calc(100% - 8px)]`
    : tw`before:top-[-8px]`
  const carat = tw`before:(absolute left-[20px] h-[17px] w-[17px] bg-primary-new-700 rounded-tl-sm rotate-45)`
  const hiddenTranslate = above
    ? tw`translate-y-[20px]`
    : tw`translate-y-[-20px]`

  return (
    <div tw="relative" className={className} ref={ref}>
      <TractorBeam anchor={ref}>
        <TransitionHideable
          hidden={!open}
          twBase={[
            tw`transition-all duration-200 ease-out absolute w-max z-50`,
            bubblePosition,
          ]}
          twHidden={[tw`opacity-0`, hiddenTranslate]}
          twVisible={tw`opacity-100 translate-y-0`}
        >
          <div
            css={[
              tw`transition-all ease-out duration-200 pl-5 py-[14px] bg-primary-new-700 rounded-[10px] justify-between items-center gap-3 flex`,
              caratPosition,
              carat,
              "filter: drop-shadow(0px 4px 12px rgba(61, 9, 170, 0.25));",
            ]}
          >
            <div tw="flex flex-col gap-[6px] whitespace-nowrap">
              <div tw="text-white text-[17px] leading-5 font-semibold">
                {text}
              </div>
              <div tw="text-primary-new-150 leading-5">{subtext}</div>
            </div>
            <Image
              src={icon}
              tw="cursor-pointer active:scale-90 transition ease-out duration-200"
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
              onMouseDown={() => setActive(true)}
              onMouseUp={() => setActive(false)}
              onClick={onClose}
            />
          </div>
        </TransitionHideable>
      </TractorBeam>
      {children}
    </div>
  )
}

export default AlertBubble
