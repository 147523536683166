import { useEffect, useRef, useState } from "react"
import tw from "twin.macro"

import { ProPlanProtected } from "./ProPlanModal"
import { ProTag } from "./ProTag"
import { SelectionGroupWithText } from "./SelectionGroup/SelectionGroupWithText"
import { SelectionGroupColorEnum } from "./SelectionGroup/types"

import {
  GiftSwapTypeEnum,
  InternationalShippingTierEnum,
} from "@/types/graphql-types"

interface Props {
  internationalShippingTier: InternationalShippingTierEnum
  setInternationalShippingTier: (tier: InternationalShippingTierEnum) => void
  giftCardsEnabled: boolean
  setGiftCardsEnabled: (enabled: boolean) => void
  color?: SelectionGroupColorEnum
  showDisabledTier?: boolean
  disableGlobalTier?: boolean
  cartIsGloballyEligible?: boolean
  swapType?: GiftSwapTypeEnum | null
  onGlobalCountriesClick?: () => void
}

const InternationalShippingOptions = ({
  internationalShippingTier,
  setInternationalShippingTier,
  giftCardsEnabled,
  setGiftCardsEnabled,
  color,
  onGlobalCountriesClick,
  cartIsGloballyEligible = false,
  disableGlobalTier = false,
  showDisabledTier = false,
  swapType = GiftSwapTypeEnum.swap_single,
}: Props) => {
  const disabledTier =
    internationalShippingTier === InternationalShippingTierEnum.disabled
  const toggleGiftCardsEnabled = () => setGiftCardsEnabled(!giftCardsEnabled)
  const overrideShowDisabledTier = useRef(disabledTier)

  const [disableGiftCardsSelection, setDisableGiftCardsSelection] =
    useState(disabledTier)

  useEffect(() => {
    const isDisabled =
      disabledTier || swapType === GiftSwapTypeEnum.swap_disabled
    setDisableGiftCardsSelection(isDisabled)
    if (isDisabled && giftCardsEnabled) {
      setGiftCardsEnabled(false)
    }
  }, [disabledTier, swapType])

  useEffect(() => {
    if (
      swapType === GiftSwapTypeEnum.swap_disabled &&
      !cartIsGloballyEligible
    ) {
      setInternationalShippingTier(InternationalShippingTierEnum.standard)
    }
  }, [swapType])

  const handleTierChange = (tier: InternationalShippingTierEnum) => {
    if (tier === InternationalShippingTierEnum.full) {
      setGiftCardsEnabled(true)
    }
    setInternationalShippingTier(tier)
  }

  const shippingTiers = [
    {
      id: InternationalShippingTierEnum.disabled,
      label: "US only",
      hidden: !overrideShowDisabledTier.current && !showDisabledTier,
      availableOnProPlan: false,
    },
    {
      id: InternationalShippingTierEnum.standard,
      label: "Standard",
      fees: "no additional fees",
      description: "Domestic shipping to US and Canada. No additional fees.",
      availableOnProPlan: false,
    },
    {
      id: InternationalShippingTierEnum.full,
      label: "Global",
      fees: "up to $50/order",
      description: cartIsGloballyEligible
        ? "Ship this gift cross-border to 140+ countries. Up to $50/order for taxes, tariffs, and freight."
        : "Enable a larger catalog of gifts that ship cross-border to 140+ countries. Up to $50/order for taxes, tariffs, and freight.",
      disabledMessage: disableGlobalTier
        ? "Not supported for gifts with more than 1 item in the bag."
        : swapType === GiftSwapTypeEnum.swap_disabled && !cartIsGloballyEligible
        ? "Enable swap to select this option"
        : undefined,
      multiSwapWarning:
        !disableGlobalTier &&
        swapType === GiftSwapTypeEnum.swap_multiple &&
        "Non-US recipients selecting gifts that ship cross-border can only select one gift.",
      availableOnProPlan: true,
      onGlobalCountriesClick: onGlobalCountriesClick,
    },
  ]

  return (
    <div tw="flex flex-col gap-5">
      {shippingTiers.map(
        (tier) =>
          !tier.hidden && (
            <div key={tier.id}>
              <ProPlanProtected
                feature="international_shipping"
                disableProtection={!tier.availableOnProPlan}
              >
                <SelectionGroupWithText
                  selected={internationalShippingTier === tier.id}
                  text={
                    <p tw="font-medium">
                      {tier.label}
                      {tier.fees && <span>{tier.fees}</span>}
                    </p>
                  }
                  description={tier.description}
                  disabled={!!tier.disabledMessage}
                  unclickable={!!tier.disabledMessage}
                  onClick={() => handleTierChange(tier.id)}
                  color={color}
                />
              </ProPlanProtected>
              <div tw="ml-8 flex flex-col gap-1.5 mt-1.5 text-sm font-medium">
                {tier.disabledMessage && (
                  <p tw="text-[#EB5757]">{tier.disabledMessage}</p>
                )}
                {tier.multiSwapWarning && (
                  <p tw="text-gray-700">{tier.multiSwapWarning}</p>
                )}
                {tier.onGlobalCountriesClick && (
                  <p
                    onClick={tier.onGlobalCountriesClick}
                    css={[
                      tw`cursor-pointer`,
                      color === SelectionGroupColorEnum.primaryNew
                        ? tw`text-primary-new-600`
                        : tw`text-primary-600`,
                    ]}
                  >
                    View countries
                  </p>
                )}
                {tier.availableOnProPlan && (
                  <ProTag
                    feature="international_shipping"
                    stopPropagation={true}
                  />
                )}
              </div>
            </div>
          ),
      )}

      <div tw="h-px bg-gray-150" />

      <div>
        <ProPlanProtected feature="international_shipping">
          <SelectionGroupWithText
            variant="checkbox"
            text={<p tw="font-medium">Enable International gift cards</p>}
            description={"No additional charge"}
            disabled={disableGiftCardsSelection}
            unclickable={disableGiftCardsSelection}
            selected={giftCardsEnabled}
            onClick={toggleGiftCardsEnabled}
            color={color}
          />
        </ProPlanProtected>
        <p tw="ml-8 mt-1.5">
          <ProTag feature="international_shipping" stopPropagation={true} />
        </p>
      </div>
    </div>
  )
}

export default InternationalShippingOptions
