import { gql, useMutation, useQuery } from "@apollo/client"
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import tw, { styled } from "twin.macro"
import { useDebounce } from "use-debounce"

import { ReactComponent as ArrowRight } from "../../assets/icons/arrow-right.svg"
import {
  AddPaymentMethodForm,
  OmniPaymentMethodSelector,
  useAddPaymentMethod,
  useOmniPaymentMethods,
} from "../../common/billing"
import GradientButton from "../../common/GradientButton"
import { generateRealmPath } from "../../common/realm"
import { errorToast, successToast } from "../../common/toast"
import { Loader } from "../../common/UI"
import { ROOT_DATA_QUERY } from "../../graphql"
import {
  SubscriptionSummary,
  TeamSubscriptionPriceEstimate,
  TeamsPlanSelector,
} from "../components"
import useSubscriptionPlanData from "../useTeamsSubscriptionPlanData"

import {
  OrganizationSubscriptionSizeSegment,
  SubscriptionForm_OrganizationSubscriptionQuery,
  SubscriptionForm_SubscriptionCreateMutation,
  SubscriptionForm_SubscriptionCreateMutationVariables,
  SubscriptionForm_SubscriptionPriceEstimateMutation,
  SubscriptionForm_SubscriptionPriceEstimateMutationVariables,
} from "@/types/graphql-types"

interface Props {
  fullMemberCount: number
  segment: OrganizationSubscriptionSizeSegment
}

const SubscriptionForm = ({ fullMemberCount, segment }: Props) => {
  // String: payment method ID
  // "NEW" string special-case: new payment method
  // null: not yet loaded
  const [selectedPaymentMethodID, setSelectedPaymentMethodID] = useState<
    string | "NEW" | null
  >(null)
  const [subscriptionCreateLoading, setSubscriptionCreateLoading] =
    useState(false)

  const { formFields, setFormField, setFormFields, preSubmit } =
    useAddPaymentMethod()
  const { paymentMethods, paymentMethodCount, defaultPaymentMethodID } =
    useOmniPaymentMethods()
  const { selectedPlan, alternateIntervalPlan, toggleInterval } =
    useSubscriptionPlanData(segment)

  const [debouncedFormFields] = useDebounce(formFields, 1000)
  const history = useHistory()

  let additionalSeatCount = 0
  if (fullMemberCount - selectedPlan.includedSeatCount > 0) {
    additionalSeatCount = fullMemberCount - selectedPlan.includedSeatCount
  }
  const additionalSeatPrice =
    additionalSeatCount * selectedPlan.additionalSeatPrice

  const [
    subscriptionPriceEstimate,
    { data: estimateData, loading: estimateLoading },
  ] = useMutation<
    SubscriptionForm_SubscriptionPriceEstimateMutation,
    SubscriptionForm_SubscriptionPriceEstimateMutationVariables
  >(SUBSCRIPTION_PRICE_ESTIMATE_MUTATION)
  const [subscriptionCreate] = useMutation<
    SubscriptionForm_SubscriptionCreateMutation,
    SubscriptionForm_SubscriptionCreateMutationVariables
  >(SUBSCRIPTION_CREATE_MUTATION)

  const { data: subscriptionData } =
    useQuery<SubscriptionForm_OrganizationSubscriptionQuery>(
      SUBSCRIBE_SUBSCRIPTION_QUERY,
    )

  // Run price estimate on mount and debounced form fields change.
  useEffect(() => {
    runPriceEstimate()
  }, [debouncedFormFields, selectedPlan, selectedPaymentMethodID])

  useEffect(() => {
    // Only run this prep function when selectedPaymentMethodID is null, and if
    // we have fully loaded payment methods data.
    if (
      selectedPaymentMethodID === null &&
      paymentMethods.organization &&
      paymentMethods.me
    ) {
      if (defaultPaymentMethodID) {
        setSelectedPaymentMethodID(defaultPaymentMethodID)
      } else {
        // No available payment methods. Set to new form.
        setSelectedPaymentMethodID("NEW")
      }
    }
  }, [paymentMethods, defaultPaymentMethodID])

  const runPriceEstimate = async () => {
    // Only pass up the payment method ID if it's set and it's not NEW.
    const paymentMethodID =
      selectedPaymentMethodID && selectedPaymentMethodID !== "NEW"
        ? selectedPaymentMethodID
        : null

    await subscriptionPriceEstimate({
      variables: {
        price: selectedPlan.price + additionalSeatPrice,
        paymentMethodID,
        addressLine1: formFields.address1,
        addressLine2: formFields.address2,
        addressCity: formFields.city,
        addressState: formFields.state,
        addressPostalCode: formFields.postalCode,
      },
    })
  }

  // Prepare the card, whether it is an existing payment method or a new card.
  //
  // - If a new card, first create an InterimCard by calling VGS through
  //   presubmit(), and pass through the interimCardToken it returns. If it
  //   returns a falsy value for interimCardToken, return false.
  // - If existing payment method, just pass through and return null.
  //
  // This function returns a string for an interimCardValue, null when not
  // creating a new payment method, and false when there's an error creating an
  // interim card token.
  const prepareCard = async (): Promise<string | null | false> => {
    if (selectedPaymentMethodID === "NEW") {
      const interimCardToken = await preSubmit()

      if (!interimCardToken) {
        // Invalid result for creating a new interim card; something must be
        // wrong, so return false instead of null which could be confused with
        // not creating a new payment method
        return false
      }
      return interimCardToken
    } else {
      return null
    }
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault()

    setSubscriptionCreateLoading(true)

    const interimCardToken = await prepareCard()

    if (interimCardToken === false) {
      // Error occurred. Return early. The error is probably already alerted by
      // the function handling prepareCard/preSubmit.
      setSubscriptionCreateLoading(false)
      return
    }

    // At this point, we either have a valid interimCardToken, or a null
    // interimCardToken signifying that we are not creating a new card.

    const variables: SubscriptionForm_SubscriptionCreateMutationVariables = {
      sku: selectedPlan.sku,
    }

    // Set paymentMethodID or cardInput based on the payment mode.
    if (
      !interimCardToken &&
      selectedPaymentMethodID &&
      selectedPaymentMethodID !== "NEW"
    ) {
      variables.paymentMethodID = selectedPaymentMethodID
    } else if (interimCardToken && selectedPaymentMethodID === "NEW") {
      variables.cardInput = {
        interimCardToken,
        address1: formFields.address1,
        address2: formFields.address2,
        addressCity: formFields.city,
        addressState: formFields.state,
        addressPostalCode: formFields.postalCode,
        cardholderName: formFields.name,
      }
    }

    const graphqlResponse = await subscriptionCreate({
      variables,
      refetchQueries: [{ query: ROOT_DATA_QUERY }],
    })

    const data = graphqlResponse?.data?.subscriptionCreate

    if (data?.ok) {
      successToast("Welcome to Goody for Teams.")
      history.push(generateRealmPath("plus", "/organization/subscription"))
    } else {
      if (data?.error) {
        errorToast(data.error)
      } else {
        errorToast("Sorry, an unexpected error occurred.")
      }
    }

    setSubscriptionCreateLoading(false)
  }

  return (
    <div tw="w-full lg:w-1/2">
      <FormContainer onSubmit={handleSubmit}>
        <div
          css={{
            display:
              selectedPaymentMethodID && selectedPaymentMethodID !== "NEW"
                ? "block"
                : "none",
          }}
        >
          <OmniPaymentMethodSelector
            selectedPaymentMethodID={selectedPaymentMethodID}
            paymentMethods={paymentMethods}
            setSelectedPaymentMethodID={setSelectedPaymentMethodID}
          />
        </div>
        <div
          css={{
            display:
              selectedPaymentMethodID && selectedPaymentMethodID === "NEW"
                ? "block"
                : "none",
          }}
        >
          <AddPaymentMethodForm
            setFormField={setFormField}
            setFormFields={setFormFields}
            formFields={formFields}
            showBackButton={
              !!(paymentMethodCount > 0 && defaultPaymentMethodID)
            }
            onClickBack={() =>
              setSelectedPaymentMethodID(defaultPaymentMethodID)
            }
          />
        </div>
        <TeamsPlanSelector
          selectedPlan={selectedPlan}
          alternateIntervalPlan={alternateIntervalPlan}
          toggleInterval={toggleInterval}
        />
        <TeamSubscriptionPriceEstimate
          selectedPlan={selectedPlan}
          additionalSeatCount={additionalSeatCount}
          additionalSeatPrice={additionalSeatPrice}
          estimateData={estimateLoading ? null : estimateData}
          isTrial={subscriptionData?.organization?.subscription?.isTrialActive}
        />
        <SubscriptionSummary
          trialEnd={subscriptionData?.organization?.subscription?.trialEnd}
        />
        <div tw="pt-14 pb-6 flex flex-row justify-center">
          <GradientButton
            onClick={handleSubmit}
            disabled={subscriptionCreateLoading}
          >
            {subscriptionCreateLoading ? (
              <Loader />
            ) : (
              <>
                Upgrade <ArrowRight tw="ml-8" />
              </>
            )}
          </GradientButton>
        </div>
      </FormContainer>
    </div>
  )
}

const FormContainer = styled.form`
  ${tw`bg-white rounded-xl p-10 w-full`}
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.04), 0 1px 2px rgba(0, 0, 0, 0.03);
`

// ERROR!
const SUBSCRIPTION_PRICE_ESTIMATE_MUTATION = gql`
  mutation SubscriptionForm_SubscriptionPriceEstimate(
    $price: Int!
    $paymentMethodID: ID
    $addressLine1: String
    $addressLine2: String
    $addressCity: String
    $addressState: String
    $addressPostalCode: String
  ) {
    subscriptionPriceEstimate(
      price: $price
      paymentMethodId: $paymentMethodID
      addressLine1: $addressLine1
      addressLine2: $addressLine2
      addressCity: $addressCity
      addressState: $addressState
      addressPostalCode: $addressPostalCode
    ) {
      estimatedTax
      estimatedTotal
    }
  }
`

const SUBSCRIPTION_CREATE_MUTATION = gql`
  mutation SubscriptionForm_SubscriptionCreate(
    $cardInput: PaymentMethodCreditCardInput
    $paymentMethodID: ID
    $sku: String!
  ) {
    subscriptionCreate(
      cardInput: $cardInput
      paymentMethodId: $paymentMethodID
      sku: $sku
    ) {
      ok
      error
    }
  }
`

const SUBSCRIBE_SUBSCRIPTION_QUERY = gql`
  query SubscriptionForm_OrganizationSubscription {
    organization {
      id
      subscription {
        id
        planSku
        isTrialActive
        trialEnd
        currentPeriodEnd
        isCancelRegistered
        trialCardStatus
      }
    }
  }
`

export default SubscriptionForm
