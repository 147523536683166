import React from "react"
import tw from "twin.macro"

import { ReactComponent as CardOpen } from "../../assets/icons/card-open.svg"

interface AddCustomCardProps {}

const AddCustomCard: React.FC<AddCustomCardProps> = () => {
  return (
    <Container>
      <CardOpen tw="flex-shrink-0 mb-9 opacity-50" />
      <Title>No custom cards added</Title>
      <Message>
        You can upload your own custom cards to send with your gifts.
      </Message>
    </Container>
  )
}

const Container = tw.div`flex flex-col items-center justify-center pt-4 md:pt-12 px-12 md:px-20 lg:px-28 w-5/6`
const Title = tw.p`font-medium mb-4 text-2xl text-center text-primary-600`
const Message = tw.p`text-center text-gray-500 text-lg`

export default AddCustomCard
