import { gql, useMutation } from "@apollo/client"
import React, { useEffect, useState } from "react"
import { toast } from "react-hot-toast"
import tw, { styled } from "twin.macro"

import {
  Contacts_CustomerIntegrationSyncMutation,
  Contacts_CustomerIntegrationSyncMutationVariables,
  CustomerIntegrationWorkspaceConfigurationInput,
} from "@/types/graphql-types"

interface SyncAndSaveProps {
  workspaceConfigs:
    | CustomerIntegrationWorkspaceConfigurationInput[]
    | null
    | undefined
  configChanged: boolean
  setConfigChanged: (configChanged: boolean) => void
  isUnavailable: boolean
  setSyncing: (syncing: boolean) => void
  setSyncStage: (syncStage: string) => void
}

const SyncAndSaveComponent: React.FC<SyncAndSaveProps> = ({
  workspaceConfigs,
  configChanged,
  setConfigChanged,
  isUnavailable,
  setSyncing,
  setSyncStage,
}) => {
  const [isSyncFromSaveAndSyncContainer, setIsSyncFromSaveAndSyncContainer] =
    useState(false)
  const [syncIntegration] = useMutation<
    Contacts_CustomerIntegrationSyncMutation,
    Contacts_CustomerIntegrationSyncMutationVariables
  >(CUSTOMER_INTEGRATION_SYNC_MUTATION)

  useEffect(() => {
    setIsSyncFromSaveAndSyncContainer(false)
  }, [isUnavailable])

  const saveAndSync = async () => {
    setIsSyncFromSaveAndSyncContainer(true)
    setSyncing(true)
    setSyncStage("MERGE")
    setConfigChanged(false)

    const res = await syncIntegration({
      variables: {
        workspaceConfigs: workspaceConfigs,
      },
    })

    if (res.data?.customerIntegrationSync.ok) {
      toast.success("Sync started. The Contacts page shows synced contacts.")
    } else {
      toast.error("Could not sync")
    }
  }

  return (
    <StartSyncContainer
      className={
        isUnavailable && isSyncFromSaveAndSyncContainer
          ? "hide"
          : configChanged && !isUnavailable
          ? "show"
          : ""
      }
    >
      <StartSyncButton disabled={isUnavailable} onClick={saveAndSync}>
        Save and start sync
      </StartSyncButton>
    </StartSyncContainer>
  )
}

const StartSyncContainer = styled.div`
  ${tw`py-7 bg-white mx-auto flex items-center justify-center fixed z-10 w-full max-w-full`}
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05), 0px 0px 32px rgba(0, 0, 0, 0.04);

  &:not(.hide):not(.show) {
    ${tw`-bottom-52`}
  }

  &.show {
    ${tw`transition duration-200 -translate-y-14`}
  }

  &.hide {
    ${tw`transition duration-500 translate-y-14`}
  }
`

const StartSyncButton = styled.button`
  ${tw`border box-border rounded-lg border-primary-500 text-white px-4 text-xl`}

  box-shadow: 0px 2px 4px rgba(228, 216, 244, 0.3);
  background: linear-gradient(180deg, #a576e0 0%, #a372df 100%);
  padding-top: 0.5625rem;
  padding-bottom: 0.5625rem;
`

export const CUSTOMER_INTEGRATION_SYNC_MUTATION = gql`
  mutation Contacts_CustomerIntegrationSync(
    $workspaceConfigs: [CustomerIntegrationWorkspaceConfigurationInput!]
    $resyncOnly: Boolean
  ) {
    customerIntegrationSync(
      workspaceConfigs: $workspaceConfigs
      resyncOnly: $resyncOnly
    ) {
      ok
    }
  }
`

export default SyncAndSaveComponent
