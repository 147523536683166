import React, { useEffect, useRef, useState } from "react"
import tw, { styled } from "twin.macro"

import SwipeableBottomSheet from "@/common/SwipeableBottomSheet"
import FilterButton from "@/store/filters/FilterButton"

interface Props {
  active?: boolean
  label: string
  opened: boolean
  setOpened: (opened: boolean) => void
  trigger?: React.ReactNode
}

const Dropdown: React.FC<Props> = ({
  active = false,
  children,
  label,
  opened,
  setOpened,
  trigger,
}) => {
  // We need to have a separate state for showing the content separate than
  // the `opened` state that is passed in, in order to add a fade out
  // animation.
  // The `setDisplay` function should only be used inside the `useEffect` call
  // that syncs these two states.
  const [display, setDisplay] = useState(false)
  const contentRef = useRef() as React.MutableRefObject<HTMLDivElement>

  useEffect(() => {
    if (!opened) {
      contentRef.current.classList.add("scale-fade-out")
      setTimeout(() => setDisplay(opened), 200)
    } else {
      setDisplay(opened)
    }
  }, [opened])

  return (
    <div tw="relative inline-block">
      {trigger ? (
        trigger
      ) : (
        <FilterButton
          onClick={() => setOpened(!opened)}
          filterIsActive={active}
          filterDropdownIsOpen={opened}
        >
          {label}
        </FilterButton>
      )}
      <ContentWrap>
        <DropdownContent $display={display} ref={contentRef}>
          {children}
        </DropdownContent>
      </ContentWrap>
      <SwipeableBottomSheet
        swipeableViewsProps={{
          containerStyle: {
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
            margin: "0 auto",
            maxWidth: 500,
            overflow: "hidden",
            transition: "transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s",
          },
        }}
        open={opened}
        fullScreen={true}
        marginTop={200}
        onChange={(isOpen: boolean) => {
          setOpened(isOpen)
        }}
        tw="lg:hidden"
      >
        {children}
      </SwipeableBottomSheet>
      {opened && <ClickableCloserUnderlay onClick={() => setOpened(false)} />}
    </div>
  )
}

const ClickableCloserUnderlay = styled.div`
  ${tw`cursor-default fixed opacity-0 z-20`}
  height: 100vh;
  left: 0;
  top: 0;
  width: 100vw;
`

const ContentWrap = styled.div`
  ${tw`absolute top-[100%] z-40`};
`

const DropdownContent = styled.div<{ $display: boolean }>`
  ${({ $display }) => ($display ? tw`hidden lg:block` : tw`hidden`)};
  ${tw`lg:mt-1 origin-top-left`};

  animation: scaleFadeInDropdown 200ms;

  @keyframes scaleFadeInDropdown {
    0% {
      transform: scale(0.5);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 100;
    }
  }

  &.scale-fade-out {
    animation: scaleFadeOutDropdown 400ms;

    @keyframes scaleFadeOutDropdown {
      0% {
        transform: scale(1);
        opacity: 100;
      }
      50% {
        transform: scale(0.5);
        opacity: 0;
      }
      100% {
        transform: scale(0.5);
        opacity: 0;
      }
    }
  }
`

export default Dropdown
