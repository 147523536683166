import React from "react"
import tw, { styled } from "twin.macro"

import { generateRealmPath } from "../../../common/realm"
import WhiteBackgroundLink from "../../../common/WhiteBackgroundLink"
import TitleContainer from "../components/TitleContainer"
import card1ImgSrc from "../images/card_1_salesforce_calendly.png"
import card2ImgSrc from "../images/card_2_lulemon.png"
import card3ImgSrc from "../images/card_3_budget_available.png"

const MissionControlForSalesGifts = () => {
  const cards = [
    {
      title: "Integrate your sales stack.",
      imgSrc: card1ImgSrc,
    },
    {
      title: "Use gifts to drive meetings and reduce no-shows.",
      imgSrc: card2ImgSrc,
    },
    {
      title: "Set budgets, manage spend, and track results.",
      imgSrc: card3ImgSrc,
    },
  ]

  return (
    <Container>
      <div tw="mx-auto px-5 lg:w-max pt-10 z-10">
        <TitleContainer
          heading="For teams"
          titleSize="lg"
          theme="dark"
          title={<div tw="">Mission control for sales gifts.</div>}
          subtitle={
            <>
              <div tw="mx-auto lg:text-[22px] lg:leading-[32px] -mt-4">
                Control budgets, integrate sales tools, and track results, all
                in one place.
              </div>

              <WhiteBackgroundLink
                tw="text-base w-full md:w-40 text-center py-3 inline-block mt-9 mb-14 text-white border-white z-10 "
                to={generateRealmPath("business", "/team-demo")}
              >
                Book a Demo
              </WhiteBackgroundLink>
            </>
          }
        />
      </div>

      <CardsGridContainer>
        {cards.map((args) => (
          <CardWithImage key={args.title} {...args} />
        ))}
      </CardsGridContainer>

      <BackgroundBall tw="hidden md:block bottom-[-150px] left-[-64px] z-0" />
      <BackgroundBall tw="hidden md:block top-[68px] right-[-358px] z-0" />

      <BackgroundBall tw="hidden top-[68px] left-[350px] z-0" />
      <BackgroundBall tw="md:hidden bottom-[-150px] left-[-64px] z-0" />
    </Container>
  )
}

const BackgroundBall = styled.div`
  position: absolute;
  height: 700px;
  width: 700px;
  background: white;
  opacity: 0.1;
  border-radius: 350px;
  margin-top: -350px;
  margin-left: -350px;
`

const Container = styled.div`
  ${tw`pt-12 pb-12 mt-24 lg:mt-0 relative overflow-hidden`}
  background: linear-gradient(90deg, #AD71FF 0%, #6B61FF 100%);
  color: white;
`

interface ICardWithImage {
  title: string
  imgSrc: string
}

const CardWithImage = ({ title, imgSrc }: ICardWithImage) => {
  return (
    <CardWithImageContainer>
      <img tw="z-10" src={imgSrc} alt="card" />

      <CardTitle>{title}</CardTitle>
    </CardWithImageContainer>
  )
}

const CardWithImageContainer = styled.div`
  ${tw`flex flex-col mx-auto mb-10 z-10`}

  @media(max-width: 1080px) {
  }
  max-width: 320px;
`

export const CardTitle = styled.h1`
  ${tw`px-6 text-2xl mb-2 font-semibold mt-9 text-center`}
  color: white;
`

const CardsGridContainer = styled.div`
  ${tw`m-auto lg:flex flex-row `}

  max-width: 1048px
`

export default MissionControlForSalesGifts
