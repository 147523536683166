import { useState } from "react"
import tw from "twin.macro"

import GlobalShippingCostModal from "@/store/components/modals/GlobalShippingCostModal"

interface Props {
  formattedPrice?: string | false
  withDomesticSupport?: boolean
}

const GlobalShippingPriceExplanation = ({
  formattedPrice,
  withDomesticSupport,
}: Props) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      {formattedPrice && formattedPrice}

      <span
        tw="border-b border-gray-350 border-dotted cursor-pointer"
        onClick={() => setOpen(true)}
        css={[formattedPrice && tw`ml-2`]}
      >
        {formattedPrice ? "+ t" : "T"}
        ax, duties, and freight
      </span>

      <GlobalShippingCostModal
        withDomesticSupport={withDomesticSupport}
        open={open}
        onClose={() => setOpen(false)}
      />
    </>
  )
}

export default GlobalShippingPriceExplanation
