import React from "react"
import tw, { styled } from "twin.macro"

import { ConditionalNavLink } from "../../../common/components/ConditionalNavLink"
import { ReactComponent as ChevronIcon } from "../../../common/images/chevron-down.svg"

interface Props {
  path?: string
  exact?: boolean
  icon?: JSX.Element | React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  name: string
  onClick?: () => void
  active?: boolean
  trigger?: boolean
  className?: string
  blockReclicks?: boolean
}

export const MenuItem: React.FC<Props> = ({
  active,
  path,
  exact,
  icon,
  name,
  onClick,
  trigger: isTrigger,
  blockReclicks,
}) => {
  const activeClassName = "active"
  const linkProps = {
    exact,
    to: path,
    activeClassName,
  }

  const isButton = !!onClick

  return (
    // @ts-ignore doesn't like blockReclicks
    <MenuItemHeaderNavLink
      onClick={onClick}
      as={isButton ? "button" : undefined}
      {...(isButton ? {} : linkProps)}
      className={active ? activeClassName : undefined}
      blockReclicks={blockReclicks}
    >
      {icon}
      {name}
      {isTrigger ? <ChevronIcon className="chevron" /> : null}
    </MenuItemHeaderNavLink>
  )
}

export const MenuItemHeaderNavLink = styled(ConditionalNavLink)`
  ${tw`flex flex-row items-center gap-3 px-3 py-2 rounded-lg text-gray-400 bg-white transition-colors text-xl duration-75`}
  > svg:not(.chevron) {
    ${tw`text-primary-300 stroke-current transition-colors relative`};
    stroke-width: 1.5;
    top: 1px;

    .inactiveFill {
      ${tw`fill-current stroke-0`}
    }
  }

  &:hover {
    ${tw`lg:bg-primary-000 text-primary-500`}
  }

  &:active {
    background-color: #f8f5fd;
  }

  &.active {
    ${tw`lg:bg-primary-050`}
    color: #633899; // Custom color that's been desaturated a bit

    > svg:not(.chevron) {
      ${tw`text-primary-500`}
      .inactiveFill {
        ${tw`text-primary-050`}
      }

      .activeFill {
        ${tw`fill-current stroke-current`}
      }
    }
  }

  > .chevron {
    // Chevron
    ${tw`transform scale-125 mt-1 stroke-current text-gray-400`}
  }
`
