import { gql, useMutation } from "@apollo/client"
import { FormEventHandler, useState } from "react"
import { toast } from "react-hot-toast"
import { useHistory } from "react-router-dom"

import { ReactComponent as ArrowRight } from "../assets/icons/arrow-right.svg"
import GradientButton from "../common/GradientButton"
import useIsLimitedMember from "../common/hooks/useIsLimitedMember"
import LimitedMemberFeatureGateBanner from "../common/members/LimitedMemberFeatureGateBanner"
import { generateRealmPath } from "../common/realm"
import { ROOT_DATA_QUERY } from "../graphql"

import {
  Workspace_CreateMutation,
  Workspace_CreateMutationVariables,
} from "@/types/graphql-types"

const NewWorkspace: React.FC = () => {
  const [name, setName] = useState("")
  const [createWorkspace] = useMutation<
    Workspace_CreateMutation,
    Workspace_CreateMutationVariables
  >(WORKSPACE_CREATE)
  const history = useHistory()

  const isLimitedMember = useIsLimitedMember()

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault()

    const { data } = await createWorkspace({
      variables: { name },
      refetchQueries: [{ query: ROOT_DATA_QUERY }],
      awaitRefetchQueries: true,
    })

    if (data?.workspaceCreate.id) {
      history.push(
        generateRealmPath("plus", `/workspaces/${data.workspaceCreate.id}`),
      )
      toast.success(`Workspace created.`, {
        iconTheme: {
          primary: "#27ae60",
          secondary: "#fff",
        },
      })
    } else {
      alert("An error occurred.")
    }
  }

  if (isLimitedMember) {
    return (
      <div tw="px-6 lg:px-12 pb-9">
        <LimitedMemberFeatureGateBanner />
      </div>
    )
  }

  return (
    <div tw="px-6 lg:px-12 pb-9">
      <h2 tw="text-2xl font-medium pb-5">Create a workspace</h2>
      <p tw="pb-5 text-gray-500">
        Create a workspace to share gift sends with colleagues and collaborate
        in a single workspace.
        <br />
        You'll be able to invite workspace members after you create the
        workspace.
      </p>
      <form tw="py-6" onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Workspace name"
          value={name}
          onChange={({ target: { value } }) => setName(value)}
          maxLength={50}
          required
          autoFocus
          tw="px-4 py-3 border border-gray-300 text-gray-600 rounded-lg focus:outline-none focus:border-gray-400 transition-colors w-full lg:w-96"
        />
        <br />
        <GradientButton
          type="submit"
          tw="mt-5 flex gap-10"
          disabled={name.length === 0}
        >
          Create <ArrowRight />
        </GradientButton>
      </form>
    </div>
  )
}

export const WORKSPACE_CREATE = gql`
  mutation Workspace_Create($name: String!) {
    workspaceCreate(name: $name) {
      id
      errors
    }
  }
`

export default NewWorkspace
