import React, { useState } from "react"
import Modal from "react-modal"

import CardModal from "./CardModal"
import CardSection from "./CardSection"
import { CurrentGift } from "../common/GlobalState"
import { useGiftData } from "../common/hooks/giftData"
import { modalStyle } from "../common/modal"

interface CardSelectProps {
  currentGift: CurrentGift
  onChange: () => void
}

const CardSelect = ({ currentGift: { card }, onChange }: CardSelectProps) => {
  const [cardsModalOpen, setCardsModalOpen] = useState(false)
  const { setCard } = useGiftData()

  return (
    <>
      <div tw="lg:w-[11.75rem] flex flex-col items-center justify-center p-5">
        <CardSection setCardsModalOpen={setCardsModalOpen} card={card} />
      </div>
      <Modal
        isOpen={cardsModalOpen}
        closeTimeoutMS={500}
        onRequestClose={() => {
          setCardsModalOpen(false)
        }}
        onAfterClose={() => {}}
        shouldCloseOnOverlayClick={true}
        style={modalStyle}
      >
        <CardModal
          onSelectCard={(id, image) => {
            setCard({ id, image })
            onChange()

            setCardsModalOpen(false)
          }}
          closeModal={() => {
            setCardsModalOpen(false)
          }}
        />
      </Modal>
    </>
  )
}

export default CardSelect
