import { ApolloClient, ApolloLink, InMemoryCache } from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { onError } from "@apollo/client/link/error"
import { createPersistedQueryLink } from "@apollo/client/link/persisted-queries"
import * as Sentry from "@sentry/react"
import { SentryLink } from "apollo-link-sentry"
import { createUploadLink } from "apollo-upload-client"
import { sha256 } from "crypto-hash"

import { getCsrfToken } from "./csrf"
import { getEnvVars } from "./environment"
import { getWorkspaceContext } from "./workspaceContext"

const httpLink = createUploadLink({
  uri: getEnvVars().apiUrl + "/graphql_web",
  credentials: "include",
})

const csrfLink = setContext(async (req, { headers }) => {
  const csrfHeaders = {
    "X-CSRF-Token": getCsrfToken(),
  }

  const workspaceHeaders = {
    // Set the workspace context. If it is undefined/null/falsy, set it to empty string.
    "x-plus-workspace-context": getWorkspaceContext()?.id || "",
  }

  return {
    headers: {
      ...headers,
      ...csrfHeaders,
      ...workspaceHeaders,
    },
  }
})

const persistedQueriesLink = createPersistedQueryLink({ sha256 })

export const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          const msg = `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          console.log(msg)
          Sentry.captureException(new Error(msg))
        })
      }
      if (networkError) {
        const msg = `[Network error]: ${networkError}`
        console.log(msg)
        Sentry.captureException(new Error(msg))
      }
    }),
    new SentryLink({
      attachBreadcrumbs: {
        includeError: true,
      },
    }),
    // @ts-ignore
    persistedQueriesLink.concat(csrfLink.concat(httpLink)),
  ]),
  // Define a custom merge policy for the query { me } field, which is a
  // "singleton" that doesn't have an ID field.
  cache: new InMemoryCache({
    typePolicies: {
      Me: {
        merge: true,
      },
      CurrentWorkspace: {
        keyFields: [],
      },
      Gift: {
        keyFields: ["senderViewAccessId"],
      },
      Category: {
        keyFields: ["slug"],
      },
      GiftOption: {
        keyFields: ["slug"],
      },
      EditorialItem: {
        keyFields: ["slug"],
      },
      ReportChart: {
        merge: true,
      },
      ReportChartsThankYouNotes: {
        fields: {
          // Support offset pagination of this field. Note that
          // offsetLimitPagination() from Apollo does NOT work here, and it's
          // not really clear why.
          data: {
            keyArgs: false,
            merge(existing, incoming, { args }) {
              const merged = existing ? existing.slice(0) : []

              if (incoming) {
                if (args) {
                  const { offset = 0 } = args
                  for (let i = 0; i < incoming.length; ++i) {
                    merged[offset + i] = incoming[i]
                  }
                } else {
                  merged.push.apply(merged, incoming)
                }
              }

              return merged
            },
          },
        },
      },
    },
    possibleTypes: {
      GiftOptionInterface: ["CustomStoreGiftOption", "GiftOption"],
      ProductInterface: ["CustomStoreProduct", "Product"],
    },
  }),
  defaultOptions: {
    // For most query hooks, display cache but update from network.
    watchQuery: {
      fetchPolicy: "cache-and-network",
      errorPolicy: "all",
    },
    query: {
      fetchPolicy: "network-only",
      errorPolicy: "all",
    },
    mutate: {
      errorPolicy: "all",
    },
  },
})
