import { gql, useQuery } from "@apollo/client"
import { useMemo } from "react"

import {
  Billing_OmniPaymentMethodFragment,
  Billing_OmniPaymentMethodsQuery,
} from "@/types/graphql-types"

export interface OmniPaymentMethodsList {
  me?: Billing_OmniPaymentMethodFragment[]
  organization?: Billing_OmniPaymentMethodFragment[]
}

// Use both organization and user payment methods.
// Specify owner: USER to only get user payment methods.
export default function useOmniPaymentMethods(owner: "ALL" | "USER" = "ALL") {
  const onlyUser = owner === "USER"

  const { data } = useQuery<Billing_OmniPaymentMethodsQuery>(
    OMNI_PAYMENT_METHODS_QUERY,
  )

  const paymentMethods = useMemo<OmniPaymentMethodsList>(() => {
    return {
      me: data?.me?.paymentMethods,
      organization: onlyUser ? [] : data?.organization?.paymentMethods,
    }
  }, [data])

  const paymentMethodCount = useMemo(() => {
    return (
      (data?.me?.paymentMethods?.length || 0) +
      (onlyUser ? 0 : data?.organization?.paymentMethods?.length || 0)
    )
  }, [data])

  // Set a default payment method, which is the first organization payment
  // method, falling back to the first me payment method, or null if not loaded
  // or none available.
  // Set a default payment method.
  // - onlyUser true: Return the first user payment method.
  // - onlyUser false: Return the first org payment method, falling back to first user payment method.
  const defaultPaymentMethodID = useMemo<string | null>(() => {
    if (paymentMethods.organization && paymentMethods.me) {
      if (onlyUser) {
        // Only return user payment method.
        if (paymentMethods.me.length > 0) {
          return paymentMethods.me[0].id
        }
      } else {
        // Get first organization or user payment method.
        if (paymentMethods.organization.length > 0) {
          // Set first org payment method as default.
          return paymentMethods.organization[0].id
        } else if (paymentMethods.me.length > 0) {
          // Set first me payment method as default.
          return paymentMethods.me[0].id
        }
      }
    }

    return null
  }, [paymentMethods])

  return {
    paymentMethods,
    paymentMethodCount,
    defaultPaymentMethodID,
  }
}

const OMNI_PAYMENT_METHODS_FRAGMENT = gql`
  fragment Billing_OmniPaymentMethod on PaymentMethod {
    id
    cardBrand
    last4
    cardholderName
  }
`

const OMNI_PAYMENT_METHODS_QUERY = gql`
  query Billing_OmniPaymentMethods {
    me {
      id
      paymentMethods(onlyPersisted: true) {
        ...Billing_OmniPaymentMethod
      }
    }
    organization {
      id
      paymentMethods(onlyPersisted: true) {
        ...Billing_OmniPaymentMethod
      }
    }
  }

  ${OMNI_PAYMENT_METHODS_FRAGMENT}
`
