import { useMutation } from "@apollo/client"
import axios from "axios"
import React, { ReactNode } from "react"
import tw, { styled } from "twin.macro"

import { CUSTOMER_INTEGRATION_CREATE_MUTATION } from "./ConnectHRButton"
import Button from "../../common/Button"
import { getCsrfToken } from "../../common/csrf"
import { getEnvVars } from "../../common/environment"
import { HRIS, RIPPLING } from "../../common/hris"

import {
  Contacts_CustomerIntegrationCreateMutation,
  Contacts_CustomerIntegrationCreateMutationVariables,
} from "@/types/graphql-types"

const { apiUrl, ripplingAppUrl } = getEnvVars()

interface ConnectRipplingButtonProps {
  className?: string
  children?: ReactNode
  relink?: boolean
  onIntegrationConnect?: (isRippling?: boolean) => void
}

const ConnectRipplingButton: React.FC<ConnectRipplingButtonProps> = ({
  className,
  children,
  relink,
  onIntegrationConnect,
}) => {
  const [createIntegration, { loading: createIntegrationLoading }] =
    useMutation<
      Contacts_CustomerIntegrationCreateMutation,
      Contacts_CustomerIntegrationCreateMutationVariables
    >(CUSTOMER_INTEGRATION_CREATE_MUTATION)

  const populateState = () => {
    const csrfToken = getCsrfToken()

    axios.post(
      `${apiUrl}/graphql_web/oauth_state`,
      { authenticity_token: csrfToken },
      { withCredentials: true },
    )
  }

  const handleConnect = async () => {
    window.open(ripplingAppUrl, "_blank")

    await populateState()

    const res = await createIntegration({
      variables: {
        integrationType: HRIS,
        integrationServiceName: RIPPLING,
        relink: relink,
      },
    })

    if (res.data?.customerIntegrationCreate.ok) {
      !!onIntegrationConnect && onIntegrationConnect(true)
    }
  }

  return (
    <ConnectHRButton
      className={className}
      onClick={handleConnect}
      disabled={createIntegrationLoading}
    >
      {children}
      {relink && <div>Reconnect Rippling</div>}
    </ConnectHRButton>
  )
}

const ConnectHRToggleButton = styled(Button)`
  &.hide-mobile {
    ${tw`hidden xl:flex`}
  }
`

const ConnectHRButton = styled(ConnectHRToggleButton)`
  &.hris-provider {
    ${tw`bg-white box-border p-4 lg:p-0 lg:w-48 items-center flex justify-center items-center`}

    height: 5.5rem;

    &.hide-mobile {
      ${tw`hidden xl:flex`}
    }
  }

  &.relink {
    ${tw`bg-white border border-primary-100 box-border rounded-lg text-gray-500 mb-2 px-4 hover:border-primary-200 active:border-primary-300 w-max`}

    height: min-content;
    padding-top: 0.5625rem;
    padding-bottom: 0.5625rem;
    box-shadow: 0px 2px 4px rgba(228, 216, 244, 0.3);
  }
`

export default ConnectRipplingButton
