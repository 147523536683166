import { ComponentVariant } from "./giftLinkTypes"
import { ReactComponent as CheckIcon } from "../../../common/images/check.svg"

interface Props {
  variant: ComponentVariant
  landingPageSendNotifs?: boolean | null
  recipientEmailsAvailable?: boolean | null
}

export default function GiftLinkPill({
  variant,
  landingPageSendNotifs,
  recipientEmailsAvailable,
}: Props) {
  return (
    <div tw="flex flex-row items-center justify-center">
      {variant === "standard" ? (
        <div tw="h-[28px] px-5 flex flex-row items-center justify-center text-white font-medium uppercase bg-primary-new-550 rounded-full">
          Your gift link
        </div>
      ) : (
        <div tw="h-12 px-5 flex flex-row items-center justify-center text-white font-medium bg-green-500 rounded-full gap-3">
          <CheckIcon tw="stroke-current stroke-2" />
          <div tw="font-medium text-lg">
            {landingPageSendNotifs && recipientEmailsAvailable
              ? "Gift sent"
              : "Gift link ready"}
          </div>
        </div>
      )}
    </div>
  )
}
