import { ReactNode, RefObject, useEffect, useState } from "react"
import { createPortal } from "react-dom"
import tw from "twin.macro"

interface Props {
  anchor: RefObject<HTMLElement>
  children: ReactNode
  withinScrollable?: boolean
}

const ANCHOR_EVENTS = [
  "animationstart",
  "animationend",
  "animationcancel",
  "animationiteration",
  "transitioncancel",
  "transitionend",
  "transitionrun",
  "transitionstart",
]

const offset = (element: HTMLElement) => {
  let top = 0
  let left = 0
  let current: HTMLElement | null = element

  do {
    top += current.offsetTop
    left += current.offsetLeft
    current = current.offsetParent as HTMLElement
  } while (current)

  return { top, left }
}

const TractorBeam = ({ anchor, withinScrollable = false, children }: Props) => {
  const [style, setStyle] = useState({})

  const reposition = () => {
    const element = anchor.current
    if (element) {
      const { height, width, top, left } = element.getBoundingClientRect()
      setStyle({
        height,
        width,
        ...(withinScrollable ? { top, left } : offset(element)),
      })
    }
  }

  useEffect(() => {
    reposition()
  }, [anchor])

  useEffect(() => {
    window.addEventListener("resize", reposition)
    window.addEventListener("scroll", reposition, true)
    return () => {
      window.removeEventListener("resize", reposition)
      window.removeEventListener("scroll", reposition)
    }
  }, [])

  useEffect(() => {
    const element = anchor.current
    if (element)
      ANCHOR_EVENTS.forEach((event) =>
        element.addEventListener(event, reposition),
      )
    return () => {
      if (element)
        ANCHOR_EVENTS.forEach((event) =>
          element.removeEventListener(event, reposition),
        )
    }
  }, [anchor])

  return createPortal(
    <div
      tw="pointer-events-none"
      style={style}
      css={[withinScrollable ? tw`fixed z-[1500]` : tw`absolute`]}
    >
      <div tw="pointer-events-auto">{children}</div>
    </div>,
    document.body,
  )
}

export default TractorBeam
