import React from "react"
import tw from "twin.macro"

import { track } from "./analytics"
import { getCsrfToken } from "./csrf"
import { getEnvVars } from "./environment"
import { ReactComponent as GoogleIcon } from "../assets/icons/logo-google.svg"
import { ReactComponent as MicrosoftIcon } from "../assets/icons/logo-microsoft.svg"
import {
  isInIframe,
  resetIframeAuthIfPresent,
  useIFrameAuthInitiationCallback,
} from "../common/hooks/iframeAuth"
import { setRestoreToken } from "../signUp/restoreToken"

const { apiUrl } = getEnvVars()

const OMNIAUTH_PROVIDER_MAP: Record<string, string> = {
  microsoft: "microsoft_graph",
  google: "google_oauth2",
}

interface IExtraParams {
  scope?: string | null
  prompt?: string | null
  access_type?: string | null
  login_hint?: string | null
  include_granted_scopes?: boolean | null
  origin?: string | null
  referring_gift_id?: string | null
  referring_code?: string | null
}

interface OmniAuthButtonProps {
  provider: string
  label?: string
  labelText?: string
  origin?: string
  disabled?: boolean
  consentInANewTab?: boolean | null
  extraParams?: IExtraParams | null
  // Workspace invite token to restore.
  token?: string | null
  onStartAuth?: () => void
  // Whether to center the auth provider's icon with the text.
  centeredIcon?: boolean
  trackClicks?: boolean
  eventPrefix?: string
}

const OmniAuthButton: React.FC<OmniAuthButtonProps> = ({
  consentInANewTab,
  provider,
  origin,
  onStartAuth,
  centeredIcon,
  trackClicks,
  eventPrefix,
  ...props
}) => {
  const startIframeAuthentication = useIFrameAuthInitiationCallback()
  const inIframe = isInIframe()

  let extraParams: IExtraParams = props.extraParams || {}
  const csrfToken = getCsrfToken()

  if (!OMNIAUTH_PROVIDER_MAP[provider]) {
    throw new Error("Provider is required for OmniAuthButton")
  }

  const label = props.label || (
    <DefaultLabel
      provider={provider}
      labelText={props.labelText}
      centeredIcon={centeredIcon}
    />
  )

  if (typeof origin === "string") {
    extraParams.origin = `${window.location.origin}${origin}`
  }
  const referringGiftId = window.localStorage?.getItem("referring_gift_id")
  if (referringGiftId) {
    extraParams.referring_gift_id = referringGiftId
  }

  const referringCode = window.localStorage.getItem("referring_code")
  if (referringCode) {
    extraParams.referring_code = referringCode
  }

  const queryParams = new URLSearchParams(extraParams as any).toString()

  const postUrl = `${apiUrl}/users/auth/${OMNIAUTH_PROVIDER_MAP[provider]}?${queryParams}`

  // Button is disabled either when passed via props, or the CSRF token hasn't been loaded yet.
  const disabled = typeof props.disabled === "boolean" ? props.disabled : false

  return (
    <form
      action={postUrl}
      method="post"
      target={consentInANewTab || inIframe ? "print_popup" : "_self"}
      onSubmit={(e) => {
        if (inIframe) {
          startIframeAuthentication()
        } else {
          resetIframeAuthIfPresent()
        }

        onStartAuth?.call(null)

        // Before opening the identity provider login, if there is a token
        // present, save the token in session storage so it can be restored.
        if (props.token) {
          setRestoreToken(props.token)
        }

        if (trackClicks) {
          track(`${eventPrefix || ""}Authenticate with Provider`, {
            provider,
          })
        }

        return true
      }}
    >
      <input type="hidden" name="authenticity_token" value={csrfToken} />
      <button
        type="submit"
        disabled={disabled}
        css={[
          tw`flex flex-row items-center w-full p-2 pl-5 text-gray-800 bg-white border rounded-lg border-primary-100 hover:border-primary-200 focus-visible:border-primary-300 focus:outline-none active:bg-primary-000 transition-colors active:transition-border transition-opacity disabled:cursor-default disabled:active:bg-white disabled:hover:border-primary-100 gap-4 transition-colors`,
          { boxShadow: "0 2px 4px rgba(228, 216, 244, 0.3)" },
          tw`text-left`,
          centeredIcon && tw`px-2 justify-center`,
        ]}
      >
        {label}
      </button>
    </form>
  )
}

export default OmniAuthButton

function iconForProvider(provider: string) {
  switch (provider) {
    case "google":
      return (
        <GoogleIcon
          style={{ width: "24px", height: "24px", display: "block" }}
        />
      )
    case "microsoft":
      return (
        <MicrosoftIcon
          style={{
            width: "24px",
            height: "24px",
            display: "block",
          }}
        />
      )
  }
}

const DefaultLabel: React.FC<{
  provider: string
  labelText?: string
  centeredIcon?: boolean
}> = (props) => {
  const provider = props.provider
  const labelText =
    typeof props.labelText === "string" ? props.labelText : "Sign in with"
  const icon = iconForProvider(provider)
  const centeredIcon = props.centeredIcon

  return (
    <>
      <div css={!centeredIcon ? tw`absolute` : {}}>{icon}</div>
      <div tw="text-center py-2" css={!centeredIcon ? tw`flex-1 w-full` : {}}>
        {labelText}{" "}
        <span style={{ textTransform: "capitalize" }}>{provider}</span>
      </div>
    </>
  )
}
