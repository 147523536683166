import { gql } from "@apollo/client"

export const SWAG_PRODUCT_QUERY = gql`
  query SwagProduct($brandSlug: String!, $productSlug: String!) {
    productDetails(brandSlug: $brandSlug, productSlug: $productSlug) {
      id
      name
      swagIntegration {
        providerType
        templateId
      }
      swagProduct {
        id
        providerType
        templateId
        externalProductId
        newExternalProductId
      }
    }
  }
`
