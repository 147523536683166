import "react-placeholder/lib/reactPlaceholder.css"

import "@/store/opacity.css"

import { gql, useQuery } from "@apollo/client"

import { Store_ImageBannerQuery } from "@/types/graphql-types"

const WebImageBanner = () => {
  const { data: imageBannerData } =
    useQuery<Store_ImageBannerQuery>(IMAGE_BANNER_QUERY)
  const webImageBanner = imageBannerData?.webImageBanner

  if (!webImageBanner?.image?.url) {
    return null
  }

  return (
    <img
      tw="mb-5 lg:mb-0 mt-1 lg:-mt-8 rounded-lg xl:col-span-2"
      alt={webImageBanner.image.url}
      src={webImageBanner.image.url}
    />
  )
}

const IMAGE_BANNER_QUERY = gql`
  query Store_ImageBanner {
    webImageBanner {
      image {
        url
        width
        height
      }
    }
  }
`

export default WebImageBanner
