import React from "react"
import tw, { styled } from "twin.macro"

import RainbowHeading from "../../../common/RainbowHeading"

const Paragraph = styled.p`
  ${tw`text-gray-800 text-opacity-80 text-lg lg:text-1.5xl lg:leading-8 font-normal pt-4`}
`

export const EALovesGoody: React.FC = () => {
  return (
    <div tw="text-center mx-auto max-w-6xl w-10/12 lg:w-3/4">
      <RainbowHeading tw="pb-2 text-3xl lg:text-4xl leading-tight">
        EAs love using Goody
      </RainbowHeading>
      <Paragraph tw="">
        <b>Before Goody</b> — Hours of gift research, massive gift card runs,
        chasing down address information, and uncertainty with no gifting
        feedback loop.
      </Paragraph>
      <Paragraph>
        <b>After Goody</b> — Personalized gifts for everyone on the team,
        gifting overview in one place, seamless shipping, and thank you notes to
        capture employee sentiment.
      </Paragraph>
    </div>
  )
}
