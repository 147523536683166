import { gql, useQuery } from "@apollo/client"
import React, { useEffect, useState } from "react"

import AutogiftActions from "./AutogiftActions"
import AutogiftOnboarding from "./AutogiftOnboarding"
import AutogiftOptions from "./AutogiftOptions"
import AutogiftReview from "./AutogiftReview"
import AutogiftSelectCardAndMessage from "./AutogiftSelectCardAndMessage"
import AutogiftSelectGifts from "./AutogiftSelectGifts"
import AutogiftStatus from "./AutogiftStatus"
import AutogiftTenure from "./AutogiftTenure"
import AutogiftTenureSidebar from "./AutogiftTenureSidebar"
import { useGlobalState } from "../../common/GlobalState"

import {
  AutogiftRule_ProductsQuery,
  AutogiftRule_ProductsQueryVariables,
} from "@/types/graphql-types"

interface AutogiftSettingsProps {
  closeAutogiftRuleConfigScreen: () => void
  // Determine whether to display tenure sidebar and options
  showTenure: boolean
  // Determine whether to display onboarding options
  showOnboarding: boolean
}

const AutogiftSettings = ({
  closeAutogiftRuleConfigScreen,
  showTenure,
  showOnboarding,
}: AutogiftSettingsProps) => {
  const [selectGiftsDrawerOpen, setSelectGiftsDrawerOpen] = useState(false)
  const [currentAutogiftRule] = useGlobalState("currentAutogiftRule")
  const { data, refetch } = useQuery<
    AutogiftRule_ProductsQuery,
    AutogiftRule_ProductsQueryVariables
  >(PRODUCTS_QUERY, {
    variables: {
      productIdList:
        currentAutogiftRule.productList === null
          ? []
          : currentAutogiftRule.productList.map((product) => product.productId),
    },
  })

  // When the product list changes,
  useEffect(() => {
    // Refetch the query to update products
    refetch({
      productIdList: currentAutogiftRule.productList.map(
        (product) => product.productId,
      ),
    })

    // Also, close the drawer since that means that a product was added
    setSelectGiftsDrawerOpen(false)
  }, [currentAutogiftRule.productList])

  const products = data?.products ?? []

  return (
    <div tw="flex flex-row items-stretch">
      {showTenure && (
        <div tw="w-52 border-r border-gray-100">
          <AutogiftTenureSidebar />
        </div>
      )}
      <div tw="flex justify-center mt-20 flex-1">
        <div>
          <AutogiftStatus
            closeAutogiftRuleConfigScreen={closeAutogiftRuleConfigScreen}
          />
          {showTenure && (
            <div tw="mb-8">
              <AutogiftTenure />
            </div>
          )}
          {showOnboarding && (
            <div tw="mb-8">
              <AutogiftOnboarding />
            </div>
          )}
          <div tw="mb-8">
            <AutogiftSelectGifts
              selectGiftsDrawerOpen={selectGiftsDrawerOpen}
              setSelectGiftsDrawerOpen={setSelectGiftsDrawerOpen}
              products={products}
            />
          </div>
          <div css={{ marginBottom: 60 }}>
            <AutogiftSelectCardAndMessage />
          </div>
          <div css={{ marginBottom: 60 }}>
            <AutogiftOptions products={products} />
          </div>
          <div css={{ marginBottom: 34 }}>
            <AutogiftReview />
          </div>
          <div css={{ marginBottom: 78 }}>
            <AutogiftActions
              closeAutogiftRuleConfigScreen={closeAutogiftRuleConfigScreen}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export const PRODUCTS_QUERY = gql`
  query AutogiftRule_Products($productIdList: [ID!]!) {
    products(productIdList: $productIdList) {
      id
      brandName
      name
      price
      shippingPrice
      brandHideMadeBySubtitle
      status
      primaryImage {
        imageThumb {
          url
          width
          height
        }
      }
      autogiftTotalPrice
      isFlexGift
      customStoreImageThumb {
        url
      }
      isGiftCard
      imagesScalable
      isBook
      customStore {
        id
      }
    }
  }
`

export default AutogiftSettings
