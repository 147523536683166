import React from "react"
import tw, { styled } from "twin.macro"

import giftSwapsImage from "../../images/gift-swaps.png"
import receiptsImage from "../../images/receipts.png"
import unboxingImage from "../../images/unboxing.png"

const Container = styled.div`
  background: linear-gradient(63.31deg, #fbf8ff 22.96%, #fbebeb 122.84%);
  ${tw`w-full p-5 lg:p-16`}
`

interface PointProps {
  title: string
  description: string
  hasShadow?: boolean
  image: string
  className?: string
}

const Point: React.FC<PointProps> = ({
  description,
  image,
  title,
  className,
}) => (
  <div className={className}>
    <figure>
      <img src={image} alt="Point" />
      <figcaption>
        <h4>{title}</h4>
        <p>{description}</p>
      </figcaption>
    </figure>
  </div>
)

const StyledPoint = styled(Point)`
  ${tw`flex flex-col items-center text-center`}

  figure {
    ${tw`mb-8`}

    img {
      ${tw`w-full h-auto rounded-lg`}
      ${(props) =>
        props.hasShadow
          ? "box-shadow: 0px 5px 26.2px 3.75px rgba(0, 0, 0, 0.06);"
          : ""}
    }

    h4 {
      ${tw`text-2xl font-medium pt-6 lg:pt-8 pb-3 bg-clip-text text-transparent`}
      letter-spacing: -0.01em;
      background-image: linear-gradient(90deg, #a45cff -118%, #8bbde1 120%);
    }
    p {
      ${tw`w-11/12 lg:w-9/12 mx-auto text-black text-opacity-80 text-lg font-normal`}
    }
  }
`

export const BetterAndEasy: React.FC = () => {
  return (
    <Container>
      <h2 tw="text-gray-900 text-center text-opacity-80 text-4xl font-light pb-12 hidden lg:block">
        How we make it better (and easy!)
      </h2>
      <h2 tw="text-gray-900 text-center text-opacity-80 text-3xl font-light pt-8 pb-12 block lg:hidden">
        The Goody difference
      </h2>
      <div tw="w-full grid grid-cols-1 lg:grid-cols-3 gap-8">
        <StyledPoint
          description="An environmentally conscious and personalized way to send gifts"
          image={unboxingImage}
          title="Beautiful digital unboxing"
        />
        <StyledPoint
          description="Recipients can swap for another gift from our catalog if they’d prefer something else"
          hasShadow
          image={giftSwapsImage}
          title="Flexible gift swaps"
        />
        <StyledPoint
          description="Weekly billings make expense reports easy"
          hasShadow
          image={receiptsImage}
          title="Consolidated receipts"
        />
      </div>
    </Container>
  )
}
