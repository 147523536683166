import React, { ReactElement } from "react"
import tw, { styled } from "twin.macro"

import { DesktopImage, MobileImage } from "./DropShaddowImage"
import TitleContainer, { Text } from "./TitleContainer"
import { CheckCircleBlue } from "../../../assets/icons"
import { useWindowSize } from "../../../common/hooks/useWindowSize"
import PurpleGradientLink from "../../../common/PurpleGradientLink"
import { generateRealmPath } from "../../../common/realm"
import WhiteBackgroundLink from "../../../common/WhiteBackgroundLink"
import HowItWorksVideoCTA from "../../common/HowItWorksVideoCTA"

interface Props {
  heading?: string
  title: string
  subtitle?: string | ReactElement
  checkItems?: string[]
  variant?: "how-it-works" | "list-on-action" | "none"
  primaryAction: {
    text: string
    linkTo: string
  }
  secondaryAction?: {
    text?: string
    linkTo: string
  }
  image: {
    desktop: string
    mobile: string
  }
  hideSecondaryAction?: boolean
}

const Hero: React.FC<Props> = ({
  heading,
  title,
  subtitle,
  checkItems = [],
  primaryAction,
  secondaryAction,
  variant = "none",
  image,
  hideSecondaryAction = false,
}) => {
  const windowSize = useWindowSize()

  const isSmall = windowSize.lessThen("md")
  const displayListBeforeAction =
    checkItems?.length > 0 && (variant !== "list-on-action" || isSmall)

  return (
    <Container>
      <Content>
        <div tw="max-w-screen-sm mx-auto">
          <TitleContainer
            heading={heading}
            title={title}
            subtitle={subtitle}
            titleSize="xl"
            align="left"
          />
        </div>

        {displayListBeforeAction && (
          <ListItemsOnTop tw="pt-5 max-w-screen-sm mx-auto !text-lg">
            <ListItems items={checkItems} />
          </ListItemsOnTop>
        )}

        <Actions>
          <PurpleGradientLink
            tw="flex-none w-full lg:w-auto"
            to={primaryAction.linkTo}
          >
            {primaryAction.text}
          </PurpleGradientLink>

          {hideSecondaryAction ? null : (
            <WhiteBackgroundLink
              tw="w-full lg:w-40 text-center font-semibold lg:ml-4 lg:inline-block mt-4 lg:mt-0"
              to={
                secondaryAction?.linkTo ||
                generateRealmPath("business", "/book-a-call")
              }
            >
              {secondaryAction?.text || "Get a Demo"}
            </WhiteBackgroundLink>
          )}
        </Actions>
        <Actions>
          <div>
            {variant === "how-it-works" && (
              <div tw="flex flex-col lg:flex-row items-center justify-start">
                <HowItWorksVideoCTA />
              </div>
            )}

            {!displayListBeforeAction && (
              <ListItemsOnAction>
                <ListItems isOnAction items={checkItems} />
              </ListItemsOnAction>
            )}
          </div>
        </Actions>
      </Content>
      <div tw="max-w-[550px] lg:hidden">
        <MobileImage tw="pt-10 px-3 -mb-16 " src={image.mobile} />
      </div>
      <DesktopImage
        tw="justify-self-start xl:object-contain h-[700px] w-[700px] my-10 ml-10 xl:mr-20"
        src={image.desktop}
      />
    </Container>
  )
}

const Container = tw.div`
  bg-light-grey
  grid justify-items-center items-center
  grid-cols-1 lg:grid-cols-[max-content minmax(0,1fr)] xl:grid-cols-2
`

const Content = tw.div`
  w-full lg:max-w-screen-sm pt-12 lg:pt-20 lg:pb-12 px-5
  justify-self-start md:justify-self-center lg:justify-self-end
`

const Actions = styled.div`
  ${tw`flex flex-col lg:flex-row items-center justify-start mt-6`}
  flex flex-row items-center justify-start mt-6
`

const ListItemsOnTop = tw.div`flex flex-col gap-3`
const ListItemsOnAction = tw.div`flex flex-col justify-between`
interface ListItemsProps {
  items: string[]
  isOnAction?: boolean
}
const ListItems = ({ items, isOnAction = false }: ListItemsProps) => (
  <>
    {items.map((item) => (
      <ListItem key={item}>
        <Text css={[tw`text-base lg:text-xl`, isOnAction && tw`lg:text-lg`]}>
          {item}
        </Text>
      </ListItem>
    ))}
  </>
)
const ListItem: React.FC = ({ children }) => (
  <div tw="flex gap-3 items-start lg:items-center">
    <div tw="pt-0.5">
      <CheckCircleBlue />
    </div>
    {children}
  </div>
)

export default Hero
