import { gql } from "@apollo/client"

import { DETAILS_PRODUCT_FRAGMENT } from "@/common/queries"

export const EDITORIAL_PRODUCT_COMMON_FRAGMENT = gql`
  fragment Editorial_GoodyProductCommon on Product {
    ...Details_Product
    brand {
      primaryGiftOption {
        id
        slug
      }
    }
    productEditorialImages {
      editorialItemId
      imageLarge {
        url
        width
        height
      }
    }
  }
  ${DETAILS_PRODUCT_FRAGMENT}
`
