import React from "react"
import tw, { styled } from "twin.macro"

interface Interface {
  colorName: "yellow" | "red"
  title: string
  message: string
}

const HEX_COLORS = { yellow: "#F2994A", red: "#EB5757" }

const Notice = ({ colorName, title, message }: Interface) => {
  const hexColor = HEX_COLORS[colorName]

  return (
    <Container>
      <Bar hexColor={hexColor} />
      <div tw="ml-[18px]">
        <Title hexColor={hexColor}> {title} </Title>
        <Message> {message} </Message>
      </div>
    </Container>
  )
}

const Container = styled.div`
  border: 1px solid rgba(235, 87, 87, 0.2);
  box-shadow:
    0px 0px 4px rgba(0, 0, 0, 0.05),
    0px 0px 32px rgba(0, 0, 0, 0.04);
  padding: 6px;
  ${tw`bg-white box-border rounded-lg flex flex-row`};
`

const Bar = ({ hexColor }: { hexColor: string }) => {
  return <div tw="rounded-xl w-[4px]" css={{ backgroundColor: hexColor }} />
}

const Title = ({
  hexColor,
  children,
}: {
  hexColor: string
  children: React.ReactNode
}) => {
  return (
    <div tw="font-medium mb-2 mt-[14px]" css={{ color: hexColor }}>
      {children}
    </div>
  )
}

const Message = ({ children }: { children: React.ReactNode }) => {
  return <div tw="mb-4 pr-4 text-[#4B5563] leading-[140%]">{children}</div>
}

export default Notice
