import { gql, useQuery } from "@apollo/client"
import { Helmet } from "react-helmet-async"
import tw, { styled } from "twin.macro"

import AccountSection from "./components/AccountSection"
import APITokens, { API_TOKEN_FRAGMENT } from "../common/APITokens"
import Button, { StyledButtonLink } from "../common/Button"
import { useDeveloperModeContext } from "../common/hooks/useDeveloperModeContext"
import { generateRealmPath } from "../common/realm"

import { APITokenTtype, Account_APITokensQuery } from "@/types/graphql-types"

export default function AccountAPIKeys() {
  const { data } = useQuery<Account_APITokensQuery>(USER_API_TOKENS_QUERY)
  const {
    enabled: devEnabled,
    enable: devEnable,
    disable: devDisable,
  } = useDeveloperModeContext()

  return (
    <>
      <Helmet>
        <title>Account – Goody for Business</title>
      </Helmet>
      <div tw="container mx-auto bg-white">
        <div tw="p-6 pt-12">
          <AccountSection headline="API Keys">
            <BoxContainer>
              <div tw="pt-6" />
              <APITokens
                apiTokens={data?.me?.apiTokens}
                queryToRefetch={USER_API_TOKENS_QUERY}
                ttype={APITokenTtype.user}
              />
            </BoxContainer>
          </AccountSection>
          <AccountSection headline="Developer Mode">
            <div>
              <div tw="flex flex-row items-center gap-4 pb-4">
                <div
                  tw="w-[100px] text-center py-2 rounded-lg"
                  css={[
                    devEnabled
                      ? tw`text-white bg-green-500`
                      : tw`bg-gray-100 text-gray-600`,
                  ]}
                >
                  {devEnabled ? "Enabled" : "Disabled"}
                </div>

                <Button
                  onClick={() => (devEnabled ? devDisable() : devEnable())}
                >
                  {devEnabled ? "Disable" : "Enable"} developer mode
                </Button>
              </div>
              <div>When you enable developer mode:</div>
              <ul tw="pl-4 pt-2">
                <li tw="list-disc">
                  Your cart displays a JSON cart payload you can pass to the API
                </li>
                <li tw="list-disc">
                  Product IDs are displayed on product pages
                </li>
                <li tw="list-disc">
                  Card IDs are displayed in the digital greeting card UI
                </li>
              </ul>
            </div>
          </AccountSection>
          <AccountSection headline="Webhooks">
            <div>
              <div>
                As an organization admin, you can configure webhooks on your
                organization&rsquo;s Webhooks page.
              </div>
              <div tw="pt-4">
                <StyledButtonLink
                  to={generateRealmPath("plus", "/organization/api/automation")}
                  className="updated"
                  tw="inline-block"
                >
                  Go to Webhooks
                </StyledButtonLink>
              </div>
            </div>
          </AccountSection>
        </div>
      </div>
    </>
  )
}

const BoxContainer = styled.div`
  ${tw`flex flex-1 flex-col bg-white border border-gray-150 rounded-xl max-w-[36rem]`}
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
`

const USER_API_TOKENS_QUERY = gql`
  query Account_APITokens {
    me {
      isOrganizationAdmin
      apiTokens {
        ...APIToken
      }
    }
  }
  ${API_TOKEN_FRAGMENT}
`
