import { Link } from "react-router-dom"
import tw, { styled } from "twin.macro"

interface Props {
  to: string
  label: string
  className?: string
}

export default function BigButton({ to, label, className }: Props) {
  return (
    <ButtonBase to={to} className={className}>
      <div tw="z-[3] relative">{label}</div>
    </ButtonBase>
  )
}

// This button has three states:
//
// Normal = normal background and shadow
// Hover = before element replaces gradient overlay and inner shadow (z-index 1)
// Active = after element replaces gradient overlay and inner shadow (z-index 2)
//
// Contents of the button are z-index 3 so they always appear above
const ButtonBase = styled(Link)`
  ${tw`px-12 md:px-[3.75rem] py-4 md:py-5 text-xl md:text-2xl text-white font-semibold relative inline-block rounded-full transition-all duration-150`}

  // The button has a base state background and a shadow
  background: linear-gradient(
            226deg,
            rgba(255, 255, 255, 0.4) -39.51%,
            rgba(255, 255, 255, 0) 92.96%
    ),
    #6360f5;
  box-shadow:
    0 2px 4px 0 rgba(255, 255, 255, 0.15) inset,
    0 4px 12px 0 rgba(68, 4, 179, 0.25);

  // Before pseudo-element = hover
  // Gradient overlay and inset shadow are replaced
  // z-index 1
  &:before {
    ${tw`absolute inset-0 rounded-full transition-opacity duration-150 content z-[1] opacity-0`}
    background: linear-gradient(
                231deg,
                rgba(255, 255, 255, 0.4) -33.59%,
                rgba(255, 255, 255, 0) 130.68%
        ),
        #6360f5;
    box-shadow: 0 2px 4px 0 rgba(255, 255, 255, 0.15) inset;
  }

  // After pseudo-element = active
  // Gradient overlay and inset shadow are replaced
  // z-index 2
  &:after {
    ${tw`absolute inset-0 rounded-full transition-opacity duration-150 content z-[2] opacity-0`}
    background: linear-gradient(231deg, rgba(0, 0, 0, 0.60) -33.59%, rgba(255, 255, 255, 0.00) 130.68%), #6360F5;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08) inset;
  }

  // Hover causes before element to be visible
  &:hover {
    &:before {
      opacity: 1;
    }

    // Hover causes drop shadow change on base button
    box-shadow:
      0 2px 4px 0 rgba(255, 255, 255, 0.15) inset,
      0 4px 16px 0 rgba(68, 4, 179, 0.3);
  }

  // Active causes after element to be visible
  &:active {
    &:after {
      opacity: 1;
    }
  }
`
