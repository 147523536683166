import styled from "@emotion/styled"
import { FC, useEffect } from "react"
import tw from "twin.macro"

import { ReactComponent as ArrowRight } from "../../../assets/icons/arrow-right.svg"
import { ReactComponent as ProInternationalSmall } from "../../../assets/images/pro-international-small.svg"
import { ReactComponent as ProInternational } from "../../../assets/images/pro-international.svg"
import { useGlobalState } from "../../../common/GlobalState"
import { GradientNavLink } from "../../../common/GradientButton"
import { useFeatureAccess } from "../../../common/hooks/featureAccess"
import {
  trackProFeatureClick,
  trackProSubscribeClick,
} from "../../../common/proPlan"
import { generateRealmPath } from "../../../common/realm"

const useProPlanFeatureClick = (hasProPlan: boolean, visible: boolean) => {
  useEffect(() => {
    if (visible && !hasProPlan) {
      trackProFeatureClick("international_shipping")
    }
  }, [visible, hasProPlan])
}

type Props = {
  visible: boolean
  onNavigate?: () => void
}

export const InternationalProPlanMessage: FC<Props> = ({
  onNavigate,
  visible,
}) => {
  const hasProPlan = useFeatureAccess().hasFeature("pro_plan")
  const [user] = useGlobalState("user")

  useProPlanFeatureClick(hasProPlan, visible)

  const signUpPath = user
    ? generateRealmPath("plus", "/subscribe-pro")
    : generateRealmPath("business", "/signup?pro")

  if (hasProPlan) {
    return null
  }

  return (
    <Container tw={"mx-4 mt-4"}>
      <ProInternational tw={"h-[211px] hidden lg:block"} />
      <div
        tw={
          "flex flex-col justify-center items-start flex-1 m-5 lg:(my-0 ml-5 mr-3)"
        }
      >
        <div tw={"text-[22px] leading-[34px] text-primary-500 font-semibold"}>
          Send international gifts on the Pro plan
        </div>
        <div tw={"mt-3.5 leading-p-140"}>
          Send gifts and gift cards worldwide with Pro. Feel free to browse our
          catalog before upgrading.
        </div>
        <UpgradeContainerLink
          tw={"h-[39px] mt-4 font-normal text-base"}
          onClick={() => {
            trackProSubscribeClick()
            if (onNavigate) {
              onNavigate()
            }
          }}
          to={signUpPath}
        >
          Upgrade to Pro
          <ArrowRight tw="ml-3" />
        </UpgradeContainerLink>
        <div tw={"mt-3 text-gray-450 text-sm leading-p-140"}>
          International shipments may have additional shipping and tariff
          charges.
        </div>
      </div>
    </Container>
  )
}

export const InternationalProPlanMessageSmall: FC<Props> = ({ visible }) => {
  const hasProPlan = useFeatureAccess().hasFeature("pro_plan")

  useProPlanFeatureClick(hasProPlan, visible)

  const [user] = useGlobalState("user")

  const signUpPath = user
    ? generateRealmPath("plus", "/subscribe-pro")
    : generateRealmPath("business", "/signup?pro")

  if (hasProPlan) {
    return null
  }

  return (
    <div tw={"items-center flex flex-col"}>
      <Container tw={"mx-1.5 mt-1.5 rounded-lg xl:(mx-3 mt-3)"}>
        <ProInternationalSmall tw={"h-[161px] ml-[-8px] mr-[-5px] xl:mx-0"} />
        <div
          tw={
            "flex flex-col justify-center items-start flex-1 my-0 ml-4 mr-1 xl:mx-5"
          }
        >
          <div tw={"text-primary-500 font-semibold"}>
            Send international gifts with Pro
          </div>
          <div tw={"mt-2 leading-p-140 text-sm"}>
            Send gifts and gift cards worldwide with the Goody for Business Pro
            plan.
          </div>
          <UpgradeContainerLink
            tw={"h-[39px] mt-4 font-normal text-base"}
            to={signUpPath}
            onClick={() => {
              trackProSubscribeClick()
            }}
          >
            Upgrade to Pro
            <ArrowRight tw="ml-3" />
          </UpgradeContainerLink>
        </div>
      </Container>
      <div
        tw={
          "leading-p-140 text-[13px] text-gray-450 mt-3 text-center max-w-[230px]"
        }
      >
        International shipments may have additional shipping and tariff charges.
      </div>
    </div>
  )
}

const Container = styled.div`
  ${tw`bg-white flex flex-row border border-gray-200 rounded-xl overflow-hidden`}
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
`

const UpgradeContainerLink = styled(GradientNavLink)``
