import React from "react"
import { useIntercom } from "react-use-intercom"

import { track } from "@/common/analytics"
import CalendarIcon from "@/organization/settings/assets/icons/calendar.svg"
import MessageIcon from "@/organization/settings/saml/assets/icons/message-circle.svg"
import Upsell from "@/organization/Upsell"
import UpsellSection from "@/organization/UpsellSection"
const SamlUpsell = () => {
  const { showNewMessages, trackEvent } = useIntercom()
  const handleBookACallClick = () => {
    track("Info", {
      name: "Organization - SAML Upsell - Click Book a Call",
    })

    window.open(
      "https://www.ongoody.com/business/team-demo?campaign=goody-plus-website-sso",
    )
  }

  const handleChatWithUsClick = () => {
    track("Info", {
      name: "Organization - SAML Upsell - Click Chat with Us",
    })

    trackEvent(`SAML Upsell - Click Chat with Us`)
    showNewMessages("I'd like to add the SAML SSO add-on")
  }

  return (
    <Upsell>
      <UpsellSection
        title="SAML SSO is available on the Team plan"
        subtext="Upgrade to the Team plan to add SAML SSO plus more features like Salesforce integration, budgeting, custom stores, and more."
        icon={CalendarIcon}
        cta="Book a call"
        onClick={handleBookACallClick}
      />
      <div tw="bg-[#ECEEF1] h-px" />
      <UpsellSection
        title="SAML SSO is also available as an add-on"
        subtext="Chat with us to add SAML SSO for $50/month or $500/year for your organization."
        icon={MessageIcon}
        cta="Chat with us"
        onClick={handleChatWithUsClick}
      />
    </Upsell>
  )
}

export default SamlUpsell
