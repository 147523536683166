import * as Sentry from "@sentry/react"
import AnimatedNumber from "animated-number-react"
import { useContext } from "react"
import tw from "twin.macro"

import BudgetingOrganizationContext from "./BudgetingOrganizationContext"
import LimitedUpgradeCTA from "./LimitedUpgradeCTA"
import { ReactComponent as ArrowRight } from "../../../assets/icons/arrow-right-no-color.svg"
import { formatPrice } from "../../../common/format"
import useChangeOrganizationAccess from "../../../common/members/useChangeOrganizationAccess"
import MoneyInput from "../../../common/MoneyInput"
import { PurpleGradientButton } from "../../../common/PurpleGradientLink"
import { getColor } from "../../../common/tailwind"
import {
  BUDGETING_MEMBERS_QUERY,
  BUDGETING_MEMBER_DETAIL_QUERY,
} from "../graphql"
import { ReactComponent as IconOrgActive } from "../images/org-active.svg"
import { ReactComponent as IconOrgInactive } from "../images/org-inactive.svg"
import { ReactComponent as IconUserActive } from "../images/user-active.svg"
import { ReactComponent as IconUserInactive } from "../images/user-inactive.svg"

import {
  BalanceTransferDirection,
  OrganizationMembershipAccess,
} from "@/types/graphql-types"
import { BudgetingOrganizationMembershipFragment } from "@/types/graphql-types"

interface Props {
  balance?: number | null
  transferDirection: BalanceTransferDirection
  setTransferDirection: (td: BalanceTransferDirection) => void
  transferAmount: string
  setTransferAmount: (amount: string) => void
  handleTransfer: () => void
  membership: BudgetingOrganizationMembershipFragment | null
  isLimited: boolean
}

export default function BalanceTransfer({
  balance,
  transferDirection,
  setTransferDirection,
  transferAmount,
  setTransferAmount,
  handleTransfer,
  membership,
  isLimited,
}: Props) {
  const budgetingOrganization = useContext(BudgetingOrganizationContext)
  const organizationBalance = budgetingOrganization.balance

  const changeOrganizationAccess = useChangeOrganizationAccess()

  const onPromoteMember = async () => {
    if (!membership) {
      return
    }

    await changeOrganizationAccess({
      id: membership.id,
      newAccess: OrganizationMembershipAccess.full,
      refetchQueries: [
        {
          query: BUDGETING_MEMBER_DETAIL_QUERY,
          variables: { id: membership.id },
        },
        { query: BUDGETING_MEMBERS_QUERY },
      ],
    })

    // Capture message in Sentry for rudimentary analytics
    Sentry.captureMessage("Member upgraded to Full from budgeting screen")
  }

  return (
    <div tw="px-16">
      {isLimited && (
        <div tw="mt-[-152px] pt-12 relative">
          <div tw="rounded-xl bg-white shadow-min border border-gray-150 flex flex-row items-center">
            <LimitedUpgradeCTA
              onPromoteMember={onPromoteMember}
              name={membership?.member.fullName || null}
            />
          </div>
        </div>
      )}
      <div
        tw="mt-[-152px] h-[240px] pt-12 relative"
        css={[isLimited && tw`mt-6 opacity-50 pointer-events-none`]}
      >
        <div tw="absolute -mt-7 mx-auto flex flex-row justify-center w-full">
          <div tw="bg-primary-new-600 px-6 h-14 rounded-full flex flex-row items-center justify-center gap-5">
            <div tw="font-medium text-primary-new-200 uppercase">Balance</div>
            <div tw="font-medium text-3xl text-white tabular-nums">
              {balance !== null && balance !== undefined && (
                <>
                  <AnimatedNumber
                    value={balance}
                    duration={300}
                    formatValue={(value: any) => {
                      return value ? formatPrice(value, true) : "$0.00"
                    }}
                  />
                </>
              )}
            </div>
          </div>
        </div>
        <div tw="rounded-xl bg-white px-8 shadow-min border border-gray-150 h-[192px] flex flex-row items-center">
          <div tw="flex-1">
            <div tw="inline-flex flex-col items-stretch">
              <TransferDirectionButton
                direction={BalanceTransferDirection.FROM_ORG_TO_USER}
                currentDirection={transferDirection}
                setTransferDirection={setTransferDirection}
              />
              <div tw="h-1.5" />
              <TransferDirectionButton
                direction={BalanceTransferDirection.FROM_USER_TO_ORG}
                currentDirection={transferDirection}
                setTransferDirection={setTransferDirection}
              />
            </div>
          </div>
          <div tw="w-[272px]">
            <div tw="flex flex-row justify-start items-center gap-4">
              <MoneyInput
                value={transferAmount}
                onChange={(val) => setTransferAmount(val)}
                inputCss={tw`w-[144px] py-3`}
              />
              <PurpleGradientButton
                tw="rounded-full h-10 py-2 px-4 w-full"
                onClick={handleTransfer}
              >
                Transfer
              </PurpleGradientButton>
            </div>
            <div tw="text-center pt-3 text-gray-500">
              {transferDirection ===
              BalanceTransferDirection.FROM_ORG_TO_USER ? (
                <>
                  {organizationBalance !== null &&
                    organizationBalance !== undefined && (
                      <>
                        <span tw="font-semibold">
                          {formatPrice(organizationBalance, true)}
                        </span>{" "}
                        available in organization
                      </>
                    )}
                </>
              ) : (
                <>
                  {balance !== null && balance !== undefined && (
                    <>
                      <span tw="font-semibold">
                        {formatPrice(balance, true)}
                      </span>{" "}
                      available in balance
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

interface TransferDirectionButtonProps {
  direction: BalanceTransferDirection
  currentDirection: BalanceTransferDirection
  setTransferDirection: (direction: BalanceTransferDirection) => void
}

function TransferDirectionButton({
  direction,
  currentDirection,
  setTransferDirection,
}: TransferDirectionButtonProps) {
  const isActive = direction === currentDirection
  const text = `Transfer from ${
    direction === BalanceTransferDirection.FROM_ORG_TO_USER
      ? "org to user"
      : "user to org"
  }`

  return (
    <button
      tw="px-5 py-3 block flex flex-row items-center gap-4 rounded-full transition-colors"
      css={[
        isActive && tw`font-semibold text-primary-new-600`,
        {
          boxShadow: `inset 0 0 0 1px ${getColor("primary-new-150")}`,
        },
        !isActive && tw`shadow-none hover:bg-gray-050 active:bg-gray-100`,
      ]}
      onClick={() => setTransferDirection(direction)}
    >
      <div
        tw="flex items-center flex-row gap-1.5"
        css={[
          direction === BalanceTransferDirection.FROM_USER_TO_ORG &&
            tw`flex-row-reverse`,
          isActive ? tw`text-primary-new-300` : tw`text-gray-350`,
        ]}
      >
        {isActive ? <IconOrgActive /> : <IconOrgInactive />}
        <ArrowRight tw="stroke-current w-3 h-3" />
        {isActive ? <IconUserActive /> : <IconUserInactive />}
      </div>
      <div tw="flex flex-col items-start">
        <div>{text}</div>
        {/* Reserve bold space */}
        <div tw="h-0 invisible font-semibold">{text}</div>
      </div>
    </button>
  )
}
