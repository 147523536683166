import { Link } from "react-router-dom"
import tw, { css, styled } from "twin.macro"

import loaderInvertedIcon from "../assets/icons/loader-inverted.png"
import loaderIcon from "../assets/icons/loader.png"

export const SubmitButton = styled.button`
  ${tw`focus:outline-none`};
  height: 3.5rem;
  z-index: 15;
  display: flex;
  padding: 1.5rem 1.5rem;
  width: 100%;
  border: 1px solid transparent;
  position: relative;
  background: linear-gradient(120deg, #98c2fe 0%, #ca99fd 100%);
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  font-weight: 500;
  letter-spacing: -0.4px;
  pointer-events: auto;
  transition: opacity 0.2s ease-out;

  &:hover {
    opacity: 0.85;
  }

  &:active {
    opacity: 0.5;
  }

  div {
    // Put this above the white background.
    z-index: 1;
  }

  div {
    z-index: 14;
  }

  // Simulate a white background since CSS transitions are not available
  // for transitions to gradient backgrounds.
  &:before {
    background: #fff;
    opacity: 0;
    transition: opacity 0.3s ease-out;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    border-radius: 8px;
  }

  &:disabled {
    ${tw`opacity-50 cursor-default`};
  }
`

export const GradientHeader = styled.div`
  ${tw`pt-20 pb-32 bg-gradient-to-r from-gradient-alternate-pink-light to-gradient-alternate-purple-light`};
`

export const GradientHeaderTextMain = styled.div`
  ${tw`text-3xl font-medium text-center md:text-5xl text-primary-600`};
`

export const GradientHeaderTextSub = styled.div`
  ${tw`pt-4 text-lg text-center md:text-2xl text-primary-400`};

  &::after {
    content: "\feff";
  }
`

export const RoundedBox = styled.div`
  ${tw`bg-white rounded-lg`};
  box-shadow:
    0 1px 4px rgba(0, 0, 0, 0.04),
    0 8px 30px rgba(0, 0, 0, 0.06);
  border: 1px solid rgba(233, 233, 233, 0.5);
`

// A nested component under RoundedBoxLink that has its color modified when
// RoundedBoxLink is hovered.
export const RoundedBoxLinkColorModifier = styled.div`
  ${tw`transition-colors`}
`

export const RoundedBoxLink = styled(Link)<{ disabled: boolean }>`
  ${tw`block bg-white rounded-lg transition-all active:bg-gray-050`};
  box-shadow:
    0 1px 4px rgba(0, 0, 0, 0.04),
    0 8px 30px rgba(0, 0, 0, 0.06);
  border: 1px solid rgba(233, 233, 233, 0.5);

  &:hover {
    box-shadow:
      0 4px 16px rgba(0, 0, 0, 0.06),
      0 16px 54px rgba(0, 0, 0, 0.08);
    transform: translateY(-4px);
    border-color: #fff;

    ${RoundedBoxLinkColorModifier} {
      ${tw`text-primary-600`}
    }
  }

  ${(props) => props.disabled && tw`pointer-events-none`}
`

export const TrackStandardBox = styled.div`
  ${tw`px-5 pb-24 mx-auto md:px-0 md:w-2/3`};
`

export const TrackStandardBoxNoPad = styled(TrackStandardBox)`
  ${tw`pb-0`};
`

const LoaderBase = css`
  ${tw`animate-spin`};

  height: 24px;
  width: 24px;
`

export const Loader = styled.div`
  ${LoaderBase};
  background-image: url(${loaderIcon});
`

export const LoaderInverted = styled.div`
  ${LoaderBase};
  background-image: url(${loaderInvertedIcon});
`

export const MessageContainer = styled.div`
  ${tw`rounded-xl flex items-stretch border self-stretch`}
  min-height: 210px;
  border-color: #eceef1;
  box-shadow:
    0px 1px 4px rgba(125, 64, 200, 0.04),
    0px 6px 20px rgba(125, 64, 200, 0.06);
`

export const largerTextCss = css`
  font-size: 1.375rem;
  line-height: 1.7875rem;
`

export const StackedItemContainer = styled.div`
  ${tw`grid`}
  & > * {
    grid-column: 1 / 1;
    grid-row: 1 / 1;
  }
`
