import { capitalize, isNil } from "lodash-es"
import React from "react"
import { Link } from "react-router-dom"
import tw from "twin.macro"

import SendSummaryBalanceChart from "./SendSummaryBalanceChart"
import { BlankPlus } from "../../assets/icons"
import Button from "../../common/Button"
import { formatPrice } from "../../common/format"
import { useGlobalState } from "../../common/GlobalState"
import { useAddBalanceModal } from "../../common/hooks/addBalanceModal"
import { useCurrentGift } from "../../common/hooks/currentGift"
import { generateRealmPath } from "../../common/realm"
import { ReactComponent as RefundIcon } from "../images/summary-refund.svg"
import { ReactComponent as WalletIcon } from "../images/summary-wallet.svg"

import { useTopUpExperiment } from "@/send/hooks/topUpExperiment"
import { Send_PriceEstimateMutation } from "@/types/graphql-types"

interface Props {
  priceEstimate: Send_PriceEstimateMutation["priceEstimate"]
  isAlcohol?: boolean
  sendV3?: boolean
}

export default function SendSummaryBalance({
  priceEstimate,
  isAlcohol,
  sendV3,
}: Props) {
  const recipientCount = priceEstimate.plusPriceEstimate?.recipients
  const totalPrice = priceEstimate.plusPriceEstimate?.estGroupTotalHigh
  const balance = priceEstimate.effectiveBalanceData?.effectiveBalance
  const scheduledReservedBalance =
    priceEstimate.effectiveBalanceData?.scheduledGiftBatchReservedBalance
  const [currentGift] = useCurrentGift()
  const { openAddBalanceModal } = useAddBalanceModal()

  const [user] = useGlobalState("user")

  const senderIsDifferentUser =
    currentGift.userPublicId && currentGift.userPublicId !== user?.publicId

  const { canExposeToTopUpExperiment, topUpAmount } = useTopUpExperiment()

  if (
    recipientCount === undefined ||
    totalPrice === undefined ||
    isNil(balance) ||
    isAlcohol
  ) {
    return null
  }

  if (recipientCount === 0) {
    return (
      <div tw="flex-1 md:p-9" css={[sendV3 && tw`w-full md:p-0 mt-8`]}>
        <div tw="font-semibold text-primary-new-600">
          Add some recipients first
        </div>
        <div tw="text-gray-600 text-[15px] pt-2">
          You’ll need to add some recipients to see a price summary.
        </div>
      </div>
    )
  }

  const balancePercent = (100.0 * totalPrice) / balance
  const totalIsOverBalance = totalPrice > balance
  const totalAmountOverBalance = totalPrice - balance

  let titleText = `${formatPrice(
    totalPrice,
  )} will be paid using your account balance`
  let subtitleText = `This is ${Math.round(
    balancePercent,
  )}% of your available ${formatPrice(balance)} balance.`

  if (totalIsOverBalance) {
    titleText = `Your total of ${formatPrice(totalPrice)} exceeds your balance`
    titleText = canExposeToTopUpExperiment
      ? `${titleText} by ${formatPrice(topUpAmount)}`
      : titleText

    subtitleText = `This is ${formatPrice(
      totalAmountOverBalance,
    )} over your ${formatPrice(balance)} account balance.`
  }

  if (scheduledReservedBalance) {
    subtitleText += ` (Includes ${formatPrice(
      scheduledReservedBalance,
    )} originally reserved for this scheduled gift.)`
  }

  if (senderIsDifferentUser) {
    titleText = titleText.replace("your", "the sender’s")
    subtitleText = subtitleText.replace("your", "the sender’s")
  }

  if (canExposeToTopUpExperiment && currentGift.topUpPaymentMethod) {
    const topUpPaymentMethod = currentGift.topUpPaymentMethod
    const cardText = `${capitalize(topUpPaymentMethod?.cardBrand ?? "")} ••••${
      topUpPaymentMethod.last4
    }`

    subtitleText = `We’ll deposit ${formatPrice(
      topUpAmount,
    )} into your balance from ${cardText}.`
  }

  return (
    <div tw="flex-1 md:p-9" css={[sendV3 && tw`w-full md:p-0 mt-8`]}>
      <div>
        <div tw="flex flex-row items-start font-text">
          <div
            tw="pr-4 text-primary-new-500"
            css={[totalIsOverBalance && tw`text-red-500`]}
          >
            <WalletIcon tw="stroke-current" />
          </div>
          <div tw="flex-1">
            <div
              tw="font-semibold text-primary-new-600"
              css={[totalIsOverBalance && tw`text-red-600`]}
            >
              {titleText}
            </div>
            <div tw="pt-2 text-[15px] text-gray-600">{subtitleText}</div>
            {totalIsOverBalance ? (
              <>
                {!canExposeToTopUpExperiment && (
                  <Button
                    variant={"updated"}
                    tw={"w-full justify-center mt-3 mb-6 gap-2"}
                    onClick={() => openAddBalanceModal(totalAmountOverBalance)}
                  >
                    <BlankPlus />
                    Add {formatPrice(totalAmountOverBalance)} to your balance
                  </Button>
                )}
                <div tw="pt-2 text-[15px] text-gray-600">
                  {canExposeToTopUpExperiment ? "Or, you" : "You"} can change
                  your gift, the number of recipients, or cancel past gifts to
                  free up balance.
                </div>
                <Link
                  to={generateRealmPath("plus", "/balance")}
                  tw="mt-4 rounded-lg block w-full border border-gray-200 text-center py-1 px-2 text-[15px] text-gray-500 transition-colors hover:border-gray-300 active:bg-gray-050"
                >
                  View balance and past gifts
                </Link>
              </>
            ) : (
              <div tw="pt-4">
                <SendSummaryBalanceChart
                  totalPrice={totalPrice}
                  balance={balance}
                  balancePercent={balancePercent}
                />
              </div>
            )}
          </div>
        </div>
        {!totalIsOverBalance && (
          <div tw="flex flex-row items-start font-text pt-9">
            <div tw="pr-4 text-primary-new-500">
              <RefundIcon tw="stroke-current" />
            </div>
            <div tw="flex-1">
              <div tw="font-semibold text-primary-new-600">
                Non-accepted gifts refund to your balance
              </div>
              <div tw="pt-2 text-[15px] text-gray-600">
                If you cancel a gift, or a gift expires, it’s refunded to your
                balance. Differences in final tax amounts are also returned to
                your balance.
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
