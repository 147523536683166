import React from "react"
import { Link } from "react-router-dom"
import tw, { styled } from "twin.macro"

import ProductRow from "./ProductRow"
import { PlusMedium } from "../../assets/icons"
import { useBrowseUrls } from "../../store/StoreHelpers"
import { useCartDrawer } from "../contexts/cartDrawer"
import { Product } from "../GlobalState"
import { serializeProductForKey } from "../utilities"

interface Props {
  products: Product[]
  brandName: string
  giftOptionSlug?: string
  cartEligible: boolean
}

const BrandSection = ({
  products,
  brandName,
  giftOptionSlug,
  cartEligible,
}: Props) => {
  const { generateBrowseUrl } = useBrowseUrls()
  const { closeCart } = useCartDrawer()

  return (
    <BrandSectionContainer>
      {products.map((product, index) => (
        <ProductRow
          key={serializeProductForKey(product)}
          product={product}
          combinedShipping={index !== 0}
        />
      ))}
      {cartEligible && !products.some((product) => product.isBook) && (
        <Link
          tw={
            "flex flex-row items-center justify-center text-sm text-gray-500 px-4 hover:bg-gray-050 active:bg-gray-100 h-[50px]"
          }
          to={generateBrowseUrl({
            giftOptionSlug,
          })}
          onClick={closeCart}
        >
          <PlusMedium tw={"stroke-current"} />
          <div tw={"ml-1.5"}>
            <span tw={"font-semibold text-gray-600"}>
              Gift more {brandName}
            </span>{" "}
            and combine shipping
          </div>
        </Link>
      )}
    </BrandSectionContainer>
  )
}

const BrandSectionContainer = styled.div`
  ${tw`border border-gray-150 rounded-xl mt-5 mx-5 overflow-hidden`}
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
`

export default BrandSection
