import Section, { SectionContainer, SectionHeader } from "./Section"
import Container from "../../sites/App/Container"

interface Props {
  content: {
    heading: string
    description: string
  }[]
}

export default function HowItWorksCommon({ content }: Props) {
  return (
    <Container>
      <SectionContainer>
        <Section tw="bg-[#fcfaf3] p-3 md:p-8 pt-8 md:pt-16 max-w-full">
          <SectionHeader tw="font-250 text-[#857228]">
            How it works
          </SectionHeader>
          <div tw="flex flex-col lg:flex-row items-stretch gap-4 md:gap-6 pt-6 md:pt-12">
            {content.map((item, i) => (
              <div tw="flex-1 border-2 border-[#f0ebd7] p-5 md:p-8 flex flex-col items-center bg-white rounded-2xl md:rounded-3xl">
                <div tw="h-8 w-8 md:(h-12 w-12) rounded-full bg-[#fcfaf3] flex flex-row items-center justify-center text-[#a3914c] text-xl md:text-[2rem] font-reckless-neue">
                  {i + 1}
                </div>
                <div tw="pt-4 md:pt-6 text-xl md:text-2xl font-medium text-[#857228]">
                  {item.heading}
                </div>
                <div tw="pt-5 text-gray-800 md:text-lg">{item.description}</div>
              </div>
            ))}
          </div>
        </Section>
      </SectionContainer>
    </Container>
  )
}
