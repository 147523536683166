import React from "react"
import tw from "twin.macro"

import { useGlobalState } from "../../../../common/GlobalState"
import RainbowHeading from "../../../../common/RainbowHeading"
import bannerImage from "../../../../landing/images/banner-image.png"

const CreateOrganizationBanner = () => {
  const [organization] = useGlobalState("organization")
  const [user] = useGlobalState("user")

  if (!user || organization) {
    return null
  }

  return (
    <BannerContainer
      css={{
        background:
          "linear-gradient(80deg, #fffafc 14%, #f6f4ff 94%),rgba(125, 64, 200, 0.07)",
      }}
    >
      <img
        tw="col-span-12 lg:col-span-5 lg:col-start-8 hidden lg:block h-[40vh]"
        src={bannerImage}
        alt="Banner"
      />
      <div tw="h-full flex justify-center flex-col lg:h-auto col-span-12 lg:col-span-7 text-center lg:text-left lg:px-8">
        <RainbowHeading tw="text-xl md:text-2xl lg:text-3xl xl:text-4xl pb-4 leading-tight!">
          You're not part of an organization
        </RainbowHeading>
        <div tw="text-gray-900 opacity-80 text-xl lg:text-1.5xl xl:text-2xl lg:flex flex-wrap pb-4 lg:pb-8 leading-normal! -mx-1">
          <div tw="mx-1">
            <a href="https://intercom.help/goody/en/">
              Please contact support to continue using Goody!
            </a>
          </div>
        </div>
      </div>
    </BannerContainer>
  )
}

const BannerContainer = tw.div`
  mt-[5.5rem] mb-[-5.5rem] lg:m-0 col-span-12 grid grid-cols-12 grid-flow-row-dense p-10 lg:py-8 lg:px-12 place-items-center
`

export default CreateOrganizationBanner
