import { gql, useLazyQuery } from "@apollo/client"
import { isNil } from "lodash-es"
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { Helmet } from "react-helmet-async"

import SearchHeader from "./SearchHeader"

import { track } from "@/common/analytics"
import { useGlobalState } from "@/common/GlobalState"
import { getBusinessSegmentFromRealm } from "@/common/realm"
import Container from "@/sites/App/Container"
import Filters, {
  PriceFilterValue,
  priceFilterInitialValue,
} from "@/store/filters"
import { DEFAULT_COUNTRY } from "@/store/providers/ShippingCountriesProvider"
import StoreContext from "@/store/StoreContext"
import { GIFT_OPTION_STORE_TILE_FRAGMENT } from "@/store/storeTile/GiftOptionStoreTile"
import { PRODUCT_STORE_TILE_FRAGMENT } from "@/store/storeTile/ProductStoreTile"
import { StoreTilesList } from "@/store/storeTile/StoreTilesList"
import { priceFilterValueToMinMax } from "@/store/utils"
import {
  Store_GiftOptionStoreTileFragment,
  Store_ProductStoreTileFragment,
  Store_SearchQuery,
  Store_SearchQueryVariables,
} from "@/types/graphql-types"

const RESULT_PREFETCH_THRESHOLD = 6

interface FilteredResult {
  searchRecordID: string
  item: Store_GiftOptionStoreTileFragment | Store_ProductStoreTileFragment
}

type FilteredResultArray = FilteredResult[]

interface Props {
  useVirtualUrl?: boolean
}

// The submitted search term is searchTerm.
// To submit the search term, call setSearchTerm.
// The displayed text in the input field is the localSearchTerm.
export default function SearchScreen({ useVirtualUrl }: Props) {
  const [searchTerm, setSearchTerm] = useState("")
  const [submittedSearchTerm, setSubmittedSearchTerm] = useState("")
  const [lastIndexShown, setLastIndexShown] = useState(0)
  const cursor = useRef<string | null>(null)
  const [hasNextPage, setHasNextPage] = useState(false)
  const [nextPageIsLoading, setNextPageIsLoading] = useState(false)
  const [, setReferringSearchRecordID] = useGlobalState(
    "referringSearchRecordID",
  )
  const [currentRealm] = useGlobalState("currentRealm")

  const [priceFilterValue, setPriceFilter] = useState<PriceFilterValue>(
    priceFilterInitialValue,
  )
  const { priceFilterMin, priceFilterMax } = useMemo(() => {
    return priceFilterValueToMinMax(priceFilterValue)
  }, [priceFilterValue])

  const {
    pushNewUrl,
    setSelectedGiftOptionPreview,
    setSelectedProductPreview,
  } = useContext(StoreContext)

  const [runQuery, { data, loading, fetchMore }] = useLazyQuery<
    Store_SearchQuery,
    Store_SearchQueryVariables
  >(SEARCH_QUERY)

  function submit() {
    if (searchTerm.trim() === "") return
    setSubmittedSearchTerm(searchTerm)
  }

  useEffect(() => {
    if (submittedSearchTerm.trim() === "") return

    ;(async () => {
      const res = await runQuery({
        variables: {
          query: submittedSearchTerm,
          priceMin: priceFilterMin,
          priceMax: priceFilterMax,
          segment: getBusinessSegmentFromRealm(currentRealm),
        },
      })

      cursor.current = res?.data?.storeSearch?.cursor || null
      setLastIndexShown(0)
      setHasNextPage(!!cursor.current)
    })()
  }, [submittedSearchTerm, priceFilterValue])

  // Filter out results with the same ID
  const [resultList, resultListItems] = useMemo(() => {
    const res = extractResultList(data?.storeSearch?.results)
    const items = res.map((result) => result.item)

    return [res, items]
  }, [data?.storeSearch?.results])

  // Check to fetch next page
  useEffect(() => {
    if (
      !loading &&
      lastIndexShown >= resultList.length - RESULT_PREFETCH_THRESHOLD &&
      hasNextPage
    ) {
      ;(async () => {
        setNextPageIsLoading(true)
        const res = await fetchMore({
          variables: {
            query: submittedSearchTerm,
            priceMin: priceFilterMin,
            priceMax: priceFilterMax,
            afterCursor: cursor.current,
            searchSessionID: data?.storeSearch?.searchSessionId,
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev

            return {
              storeSearch: {
                ...fetchMoreResult.storeSearch,
                results: [
                  ...(prev.storeSearch.results || []),
                  ...(fetchMoreResult.storeSearch.results || []),
                ],
              },
            }
          },
        })

        cursor.current = res?.data?.storeSearch?.cursor || null
        setHasNextPage(!!cursor.current)
        setNextPageIsLoading(false)
      })()
    }
  }, [loading, lastIndexShown])

  // Sets index of last item displayed so we know whether to prefetch
  const decideIfNextPageShouldLoad = useCallback(
    (index: number) => {
      if (index > lastIndexShown) {
        setLastIndexShown(index)
      }
    },
    [lastIndexShown],
  )

  // Clear search record ID when leaving search page
  useEffect(() => {
    return () => {
      setReferringSearchRecordID(null)
    }
  }, [])

  function setReferringSearchRecordIDByItemID(itemID: string) {
    // Find the result in the results list
    const result = resultList.find((result) => result.item.id === itemID)

    if (result) {
      setReferringSearchRecordID(result.searchRecordID)
    }
  }

  return (
    <>
      {!useVirtualUrl && (
        <Helmet>
          <title>Search | Goody</title>
          <meta name="title" property="og:title" content="Search | Goody" />
          <meta
            name="description"
            property="og:description"
            content="Search Goody's collection of trendy gifts for business and personal gifting."
          />
        </Helmet>
      )}
      <SearchHeader
        onSubmit={submit}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
      />
      {submittedSearchTerm.trim() !== "" && (
        <Container tw="px-5">
          <div tw="max-w-[70rem] mx-auto lg:p-8 lg:pb-12">
            <Filters
              priceFilterValue={priceFilterValue}
              setPriceFilter={setPriceFilter}
              // Not supported filters at this time
              searchTerm={""}
              brandValues={[]}
              setSearchTerm={() => {}}
              setBrandValues={() => {}}
              isSearch={true}
            />
            <div tw="pt-4 md:grid md:grid-cols-2 gap-x-8 gap-y-12 lg:grid-cols-3 gap-x-10 gap-y-14">
              <StoreTilesList
                brandValues={[]}
                list={resultListItems}
                onSelectGiftOption={(giftOption) => {
                  setSelectedGiftOptionPreview(giftOption)

                  const { id, slug } = giftOption
                  track("Store - Search - Click Gift Option Result", {
                    id,
                    slug,
                    searchQuery: submittedSearchTerm,
                  })

                  setReferringSearchRecordIDByItemID(id)
                }}
                onSelectProduct={(product) => {
                  setSelectedProductPreview(product)

                  const { id, slug, brand } = product
                  track("Store - Search - Click Product Result", {
                    id,
                    slug,
                    brandSlug: brand.slug,
                    searchQuery: submittedSearchTerm,
                  })

                  setReferringSearchRecordIDByItemID(id)
                }}
                pushNewUrl={pushNewUrl}
                loading={loading}
                hasStartedLoading={true}
                minDisplayPrice={priceFilterMin || 0}
                nextPageIsLoading={nextPageIsLoading}
                decideIfNextPageShouldLoad={decideIfNextPageShouldLoad}
                selectedCategory={null}
                selectedShippingCountry={DEFAULT_COUNTRY}
                showProductShippingPrice={true}
                showProductBrandName={true}
              />
            </div>
          </div>
        </Container>
      )}
    </>
  )
}

function extractResultList(
  results?: Store_SearchQuery["storeSearch"]["results"] | null,
) {
  if (!results) {
    return []
  }

  return (
    (
      (results
        .map((result) => {
          // Extract actual result
          if (result.itemType === "gift_option") {
            return {
              searchRecordID: result.searchRecordId,
              item: result.giftOption,
            }
          } else {
            return {
              searchRecordID: result.searchRecordId,
              item: result.product,
            }
          }
        })
        // Remove nulls
        .filter((result) => !isNil(result)) || []) as FilteredResultArray
    ).reduce((acc, cur) => {
      // Remove duplicates
      const existing = acc.find(
        (el: FilteredResult) => el.item.id === cur.item.id,
      )
      if (!existing) {
        acc.push(cur)
      }
      return acc
    }, [] as FilteredResultArray)
  )
}

const SEARCH_QUERY = gql`
  query Store_Search(
    $query: String!
    $priceMin: Int
    $priceMax: Int
    $afterCursor: String
    $searchSessionID: String
    $segment: BusinessSegment
  ) {
    storeSearch(
      query: $query
      priceMin: $priceMin
      priceMax: $priceMax
      afterCursor: $afterCursor
      searchSessionId: $searchSessionID
      segment: $segment
    ) {
      results {
        id
        itemType
        product {
          id
          name
        }
        giftOption {
          ...Store_GiftOptionStoreTile
        }
        product {
          ...Store_ProductStoreTile
        }
        searchRecordId
      }
      cursor
      searchSessionId
    }
  }

  ${GIFT_OPTION_STORE_TILE_FRAGMENT}
  ${PRODUCT_STORE_TILE_FRAGMENT}
`
