import React from "react"
import tw from "twin.macro"

interface AlcoholInfoProps {
  isBusiness: boolean
}

const AlcoholInfo = ({ isBusiness }: AlcoholInfoProps) => {
  return (
    <Container>
      <div tw="font-medium mb-3 text-black font-text">Alcohol info</div>
      <p tw="mb-3 font-text">
        Due to regulations, alcohol purchases are processed by an alcohol
        beverage licensee
        {isBusiness ? (
          <>
            {" "}
            individually per order and are{" "}
            <span tw="font-medium text-black">not part of weekly billing.</span>
          </>
        ) : (
          "."
        )}
      </p>
      <p tw="font-text">
        Credits and promo codes do not apply to alcohol.{" "}
        {isBusiness
          ? "You can access receipts for alcohol orders on your Account page. "
          : ""}
        Recipients must be 21 years of age or older to accept alcohol gifts.
        They can also swap to a non-alcohol gift.
      </p>
    </Container>
  )
}

const Container = tw.div`bg-gray-100 mb-8 mt-5 p-5 rounded-xl text-gray-700`

export default AlcoholInfo
