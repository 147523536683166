import { MouseEventHandler } from "react"

import Image from "@/common/Image"
import radioChecked from "@/common/images/radio-checked.svg"
import radio from "@/common/images/radio.svg"

interface Props {
  checked: boolean
  onClick?: MouseEventHandler
}

const Radio = ({ checked, onClick }: Props) => (
  <div tw="flex items-center justify-center min-w-[20px] min-h-[20px]">
    <Image
      src={checked ? radioChecked : radio}
      onClick={onClick}
      tw="w-[18px]"
    />
  </div>
)

export default Radio
