import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client"

import { STANDARD_CARD_FRAGMENT } from "../occasions"
import { SHIPMENT_FRAGMENT } from "../queries/shipment_fragment"

import { IMAGE_FRAGMENT } from "@/graphql"
import { GiftViewableStatus } from "@/types/graphql-types"
import {
  GiftFragment,
  Gift_GiftPaymentCheckMutation,
  Gift_GiftPaymentCheckMutationVariables,
  Gifts_GetGiftQuery,
  Gifts_GetGiftQueryVariables,
  Gifts_GetGiftsQuery,
} from "@/types/graphql-types"

export const useGifts = () => {
  const { data, loading, refetch } = useQuery<Gifts_GetGiftsQuery>(GET_GIFTS, {
    fetchPolicy: "network-only",
  })

  const gifts = data?.me?.gifts
  const scheduledGiftBatches = data?.me?.scheduledGiftBatches

  return { gifts, scheduledGiftBatches, loading, refetch }
}

// Hook to auto load gift by sender view access id
export const useGift = (senderViewAccessId: string) => {
  const {
    data,
    refetch: refetchGift,
    loading: giftLoading,
  } = useQuery<Gifts_GetGiftQuery, Gifts_GetGiftQueryVariables>(GET_GIFT, {
    variables: {
      senderViewAccessId,
    },
    context: {
      headers: {
        "sender-view-access-id": senderViewAccessId,
      },
    },
  })

  const [giftPaymentCheck] = useMutation<
    Gift_GiftPaymentCheckMutation,
    Gift_GiftPaymentCheckMutationVariables
  >(GIFT_PAYMENT_CHECK)

  const gift = data?.giftBySenderId

  return { gift, refetchGift, giftPaymentCheck, giftLoading }
}

// hook to fetch gift to load it into the cache
export const useFetchGift = () => {
  const [fetchGift] = useLazyQuery<
    Gifts_GetGiftQuery,
    Gifts_GetGiftQueryVariables
  >(GET_GIFT)

  return { fetchGift }
}
export const PRODUCT_FRAGMENT_FRAGMENT = gql`
  fragment ProductFragment on ProductFragment {
    id
    brandName
    brandId
    brandSlug
    name
    isFlexGift
    isBook
    primaryImage {
      imageLarge {
        ...Image
      }
      imageThumb {
        ...Image
      }
    }
    imagesScalable
  }
  ${IMAGE_FRAGMENT}
`

export const CART_PRODUCT_SHIPMENT_FRAGMENT = gql`
  fragment CartProductShipment on CartProduct {
    id
    quantity
    productTitle
    productFragment {
      ...ProductFragment
    }
    productDetails {
      isBook
      bookImage
      bookContributors
    }
  }
  ${PRODUCT_FRAGMENT_FRAGMENT}
`

const GIFT_FRAGMENT = gql`
  fragment Gift on Gift {
    id
    recipientFirstName
    recipientLastName
    productTitle
    senderViewAccessId
    sharedLink
    senderLink
    thankYouNote
    checkoutKey
    status
    createdAt
    isSelfSend
    createdAt
    acceptedAt
    openedAt
    shippedAt
    deliveredAt
    deliveryEta
    sendMethod
    giftNotificationDisabled
    productFragment {
      ...ProductFragment
    }
    cartQuantities
    card {
      ...StandardCard
    }
    cartProducts {
      ...CartProductShipment
    }
    shipments {
      ...Shipment
    }
  }
  ${STANDARD_CARD_FRAGMENT}
  ${PRODUCT_FRAGMENT_FRAGMENT}
  ${SHIPMENT_FRAGMENT}
  ${CART_PRODUCT_SHIPMENT_FRAGMENT}
`

const GIFT_BATCH_FRAGMENT = gql`
  fragment GiftBatch on GiftBatch {
    id
    scheduledSendOn
    cartQuantities
    firstRecipientFullName
    cartProducts {
      ...CartProductShipment
    }
  }
`

const GET_GIFTS = gql`
  query Gifts_GetGifts {
    me {
      gifts {
        ...Gift
      }
      scheduledGiftBatches {
        ...GiftBatch
      }
    }
  }
  ${GIFT_FRAGMENT}
  ${GIFT_BATCH_FRAGMENT}
`

const GET_GIFT = gql`
  query Gifts_GetGift($senderViewAccessId: String!) {
    giftBySenderId(senderViewAccessId: $senderViewAccessId) {
      ...Gift
    }
  }
  ${GIFT_FRAGMENT}
`

const GIFT_PAYMENT_CHECK = gql`
  mutation Gift_GiftPaymentCheck($checkoutKey: String!) {
    giftPaymentCheck(checkoutKey: $checkoutKey) {
      complete
    }
  }
`

export const getGiftStatusCopy = (gift: GiftFragment) =>
  giftCopySet[gift.status].tileStatusText

type GiftCopySetType = {
  [id in GiftViewableStatus]: { tileStatusText: string }
}

const giftCopySet: GiftCopySetType = {
  CREATED: {
    tileStatusText: "Card sent",
  },
  NOTIFIED: {
    tileStatusText: "Card send",
  },
  OPENED: {
    tileStatusText: "Card opened",
  },
  ACCEPTED: {
    tileStatusText: "Accepted",
  },
  PENDING_PAYMENT: {
    tileStatusText: "Accepted",
  },
  PAID: {
    tileStatusText: "Processing",
  },
  ORDERED: {
    tileStatusText: "Preparing",
  },
  SHIPPED: {
    tileStatusText: "Shipped",
  },
  DELIVERED: {
    tileStatusText: "Delivered",
  },
  FAILED: {
    tileStatusText: "Failed",
  },
  UNKNOWN: {
    tileStatusText: "Unknown",
  },
  PENDING_USER_APPROVAL: {
    tileStatusText: "Pending Approval",
  },
}
