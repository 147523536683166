import { useQuery } from "@apollo/client"
import { Helmet } from "react-helmet-async"
import { NavLink, useParams } from "react-router-dom"
import tw, { styled } from "twin.macro"

import { GET_WORKSPACE_QUERY } from "./graphql"
import WorkspaceMembers from "./WorkspaceMembers"
import WorkspacePending from "./WorkspacePending"
import WorkspacePermissions from "./WorkspacePermissions"
import WorkspaceSettings from "./WorkspaceSettings"
import { DropdownMenuItem, DropdownMenuPanel } from "../common/DropdownMenu"
import { useGlobalState } from "../common/GlobalState"
import { generateRealmPath } from "../common/realm"
import RealmRoute from "../common/RealmRoute"

import {
  InviteMembersPermission,
  WorkspaceMembershipRole,
} from "@/types/graphql-types"
import {
  GetWorkspaceQuery,
  GetWorkspaceQueryVariables,
} from "@/types/graphql-types"

interface Params {
  id: string
}

const Workspace = () => {
  const { id } = useParams<Params>()
  const { data } = useQuery<GetWorkspaceQuery, GetWorkspaceQueryVariables>(
    GET_WORKSPACE_QUERY,
    {
      variables: { id },
    },
  )
  const [user] = useGlobalState("user")

  if (!user) {
    return null
  }

  // This workspace variable holds partial workspace data that we will use while the full data is loaded.
  const workspace = user.workspaces.find((workspace) => workspace.id === id)

  if (!data?.me?.workspace || !workspace) {
    return null
  }

  const isManager = data.me?.workspace?.role === WorkspaceMembershipRole.MANAGER
  const canInviteMembers =
    isManager ||
    workspace.permissions.inviteMembersPermission ===
      InviteMembersPermission.MEMBERS_AND_MANAGERS

  return (
    <>
      <Helmet>
        <title>{workspace.name} – Workspaces – Goody for Business</title>
      </Helmet>
      <WorkspaceInfo>
        <SectionName>Workspace</SectionName>
        <WorkspaceName>{workspace.name}</WorkspaceName>
      </WorkspaceInfo>
      <WorkspaceNavWrapper>
        <WorkspaceNav>
          <WorkspaceNavStartSpacer />
          {canInviteMembers && (
            <WorkspaceNavItem
              title="Members"
              to={generateRealmPath("plus", `/workspaces/${workspace.id}`)}
              exact
            >
              Members
            </WorkspaceNavItem>
          )}
          {isManager && (
            <>
              <WorkspaceNavItem
                title="Pending Invites"
                to={generateRealmPath(
                  "plus",
                  `/workspaces/${workspace.id}/pending`,
                )}
                exact
              >
                Pending Invites
              </WorkspaceNavItem>

              <WorkspaceNavItem
                title="Permissions"
                to={generateRealmPath(
                  "plus",
                  `/workspaces/${workspace.id}/permissions`,
                )}
                exact
              >
                Permissions
              </WorkspaceNavItem>
            </>
          )}
          <WorkspaceNavItem
            title="Workspace Settings"
            to={generateRealmPath(
              "plus",
              canInviteMembers
                ? `/workspaces/${workspace.id}/settings`
                : `/workspaces/${workspace.id}`,
            )}
            exact
          >
            Workspace Settings
          </WorkspaceNavItem>
          <WorkspaceNavEndSpacer />
        </WorkspaceNav>
      </WorkspaceNavWrapper>
      {isManager ? (
        <div>
          <RealmRoute exact path="/workspaces/:id/pending" realm={"plus"}>
            <WorkspacePending workspace={data.me.workspace} />
          </RealmRoute>
          <RealmRoute exact path="/workspaces/:id/permissions" realm={"plus"}>
            <WorkspacePermissions workspace={data.me.workspace} />
          </RealmRoute>
          <RealmRoute exact path="/workspaces/:id/settings" realm={"plus"}>
            <WorkspaceSettings
              workspace={data.me.workspace}
              isManager={isManager}
            />
          </RealmRoute>
          <RealmRoute exact path="/workspaces/:id" realm={"plus"}>
            <WorkspaceMembers
              currentUser={user}
              isManager={isManager}
              isFullOrganizationMember={data.me.isFullOrganizationMember}
              workspace={data.me.workspace}
              isTeamPlan={data?.organization?.subscription?.isTeamPlan}
            />
          </RealmRoute>
        </div>
      ) : (
        <div>
          {canInviteMembers ? (
            <>
              <RealmRoute exact path="/workspaces/:id/settings" realm={"plus"}>
                <WorkspaceSettings
                  workspace={data.me.workspace}
                  isManager={isManager}
                />
              </RealmRoute>
              <RealmRoute exact path="/workspaces/:id" realm={"plus"}>
                <WorkspaceMembers
                  currentUser={user}
                  isManager={isManager}
                  isFullOrganizationMember={data.me.isFullOrganizationMember}
                  workspace={data.me.workspace}
                  isTeamPlan={data?.organization?.subscription?.isTeamPlan}
                />
              </RealmRoute>
            </>
          ) : (
            <>
              <RealmRoute exact path="/workspaces/:id" realm={"plus"}>
                <WorkspaceSettings
                  workspace={data.me.workspace}
                  isManager={isManager}
                />
              </RealmRoute>
            </>
          )}
        </div>
      )}
    </>
  )
}

const SectionName = tw.h2`uppercase font-medium text-gray-500 text-sm`

const WorkspaceInfo = tw.div`mb-8 px-6 lg:px-12 lg:px-12`

export const ActionsDropdownTrigger = tw.button`border border-gray-200 lg:bg-white flex focus:outline-none items-center leading-4 p-3 rounded-lg text-gray-500 focus-visible:bg-gray-100 hover:bg-gray-100 transition-colors`
export const ActionsDropdownMenuPanel = tw(DropdownMenuPanel)`right-0 py-2`
export const ActionsDropdownMenuItem = styled(DropdownMenuItem)`
  ${tw`flex items-center px-4 py-2 text-gray-600 cursor-pointer hover:bg-gray-100`};

  svg {
    ${tw`mr-5 w-3.5 stroke-current`};

    g {
      ${tw`opacity-100`};
    }

    path {
      ${tw`stroke-current`};
    }
  }
`

const WorkspaceName = styled.div(() => [tw`font-medium`, "font-size: 2rem;"])
const WorkspaceNavWrapper = tw.div`whitespace-nowrap w-full overflow-y-auto h-16 lg:h-auto lg:overflow-y-visible z-10`
const WorkspaceNav = tw.nav`flex flex-row items-end whitespace-nowrap`
const WorkspaceNavStartSpacer = tw.div`pl-2 lg:pl-8 border-b`
const WorkspaceNavEndSpacer = tw.div`pr-2 lg:pr-8 border-b flex-1`
const WorkspaceNavItem = styled(NavLink)`
  ${tw`relative inline-block p-4 pb-2 text-lg text-center text-gray-500 border-b transition hover:text-primary-500`};

  /**
    Reserves container space for bolded version of text. We need to
    pass in the link text to the "title" attribute.
   */
  &::before {
    ${tw`invisible block h-0 font-medium`};
    content: attr(title);
  }

  &::after {
    ${tw`absolute bg-gray-200 inline-block left-4 right-4 rounded-full opacity-0 transition mb-0.5`};
    bottom: -2px;
    content: "";
    height: 3px;
  }

  &:hover {
    &::after {
      ${tw`opacity-100 bg-primary-200`};
    }
  }

  &.active {
    ${tw`font-medium text-primary-500`};

    &::after {
      ${tw`opacity-100 bg-primary-500`};
    }
  }
`

export default Workspace
