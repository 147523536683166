import { useEffect, useMemo, useState } from "react"
import tw, { styled } from "twin.macro"

import Dropdown from "../components/Dropdown"

import { ReactComponent as XIcon } from "@/assets/icons/x.svg"

export interface PriceFilterValue {
  min: number | null
  max: number | null
}

export const priceFilterInitialValue: PriceFilterValue = {
  min: null,
  max: null,
}

interface Props {
  priceFilterValue: PriceFilterValue
  setPriceFilter: (value: PriceFilterValue) => void
}

const PRESETS = [
  {
    min: null,
    max: null,
    label: "All",
  },
  {
    min: null,
    max: 25,
    label: "Under $25",
  },
  {
    min: 25,
    max: 50,
    label: "$25 – $50",
  },
  {
    min: 50,
    max: 75,
    label: "$50 – $75",
  },
  {
    min: 75,
    max: 100,
    label: "$75 – $100",
  },
  {
    min: 100,
    max: 200,
    label: "$100 – $200",
  },
  {
    min: 200,
    max: 300,
    label: "$200 – $300",
  },
  {
    min: 300,
    max: null,
    label: "$300+",
  },
]

const PriceFilterV2 = ({ priceFilterValue, setPriceFilter }: Props) => {
  const [dropdownOpened, setDropdownOpened] = useState(false)

  const priceFilterHasValue =
    priceFilterValue.min !== null || priceFilterValue.max !== null

  const [minInput, setMinInput] = useState<string>("")
  const [maxInput, setMaxInput] = useState<string>("")

  const minInputInt = useMemo(() => {
    const val = minInput.trim().replace("$", "")
    if (val === "") {
      return null
    }

    const int = parseInt(val)

    if (isNaN(int)) {
      return null
    }

    return int
  }, [minInput])

  const maxInputInt = useMemo(() => {
    const val = maxInput.trim().replace("$", "")
    if (val === "") {
      return null
    }

    const int = parseInt(val)

    if (isNaN(int)) {
      return null
    }

    return int
  }, [maxInput])

  const isCustomRangeActive = useMemo(() => {
    return priceFilterIsNotPreset(priceFilterValue)
  }, [priceFilterValue])

  function commitCustomRange() {
    setPriceFilter({
      min: minInputInt,
      max: maxInputInt,
    })
  }

  useEffect(() => {
    // If this component loads with a value that is not a preset, set the
    // min/max inputs to the values in the price filter
    if (isCustomRangeActive) {
      setMinInput(priceFilterValue.min ? `$${priceFilterValue.min}` : "")
      setMaxInput(priceFilterValue.max ? `$${priceFilterValue.max}` : "")
    }
  }, [])

  return (
    <Dropdown
      opened={dropdownOpened}
      setOpened={(val: boolean) => setDropdownOpened(val)}
      active={priceFilterHasValue}
      label={generateLabel(priceFilterValue)}
    >
      <PricesContainer>
        <div tw="font-medium mb-4 text-center text-gray-500 pt-5">
          Price (including shipping)
        </div>
        <div tw="px-2 grid grid-cols-2 grid-rows-4 grid-flow-col gap-px">
          {PRESETS.map((preset) => (
            <PresetButton
              key={preset.label}
              onClick={() => {
                setPriceFilter({
                  min: preset.min,
                  max: preset.max,
                })
                setMinInput("")
                setMaxInput("")
                setDropdownOpened(false)
              }}
              $selected={
                preset.min === priceFilterValue.min &&
                preset.max === priceFilterValue.max
              }
            >
              {preset.label}
            </PresetButton>
          ))}
        </div>
        <div tw="border-t border-gray-150 mt-3" />
        <div tw="p-5">
          <div
            tw="font-medium mb-2 text-center text-gray-500"
            css={[isCustomRangeActive && tw`text-primary-new-700`]}
          >
            Custom range
          </div>
          <div tw="flex flex-row items-center justify-center gap-2 w-full relative">
            <CustomInput
              type="text"
              placeholder="Min"
              value={minInput}
              onChange={(e) => {
                const int = parseInt(e.target.value.trim().replace("$", ""))

                if (isNaN(int)) {
                  setMinInput("")
                  return
                }

                setMinInput("$" + int.toString())
              }}
              onBlur={commitCustomRange}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  commitCustomRange()
                }
              }}
            />
            <div tw="text-gray-400">—</div>
            <CustomInput
              type="text"
              placeholder="Max"
              value={maxInput}
              onChange={(e) => {
                const int = parseInt(e.target.value.trim().replace("$", ""))

                if (isNaN(int)) {
                  setMaxInput("")
                  return
                }

                setMaxInput("$" + int.toString())
              }}
              onBlur={commitCustomRange}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  commitCustomRange()
                }
              }}
            />
            {(minInput !== "" || maxInput !== "") && (
              <div tw="absolute top-0 bottom-0 right-0 w-8 flex flex-row items-center justify-center">
                <button
                  tw="w-8 h-8 flex flex-row items-center justify-center rounded-full bg-gray-100 hover:bg-gray-150 active:(bg-gray-200 scale-90) transition-all"
                  onClick={() => {
                    setMinInput("")
                    setMaxInput("")
                    setPriceFilter({
                      min: null,
                      max: null,
                    })
                  }}
                >
                  <XIcon />
                </button>
              </div>
            )}
          </div>
        </div>
      </PricesContainer>
    </Dropdown>
  )
}

// When min and max are nil: "Price"
// When min is nil and max is not nil: "Under $max"
// When min is not nil and max is nil: "$min+"
// When min and max are not nil: "$min – $max"
function generateLabel(priceFilterValue: PriceFilterValue) {
  const { min, max } = priceFilterValue

  if (min === null && max === null) {
    return "Price"
  }

  if (min === null && max !== null) {
    return `Under $${max}`
  }

  if (min !== null && max === null) {
    return `$${min}+`
  }

  return `$${min} – $${max}`
}

// Determine whether a preset is active, or the custom range is active
function priceFilterIsNotPreset(priceFilterValue: PriceFilterValue) {
  return PRESETS.every((preset) => {
    return (
      priceFilterValue.min !== preset.min || priceFilterValue.max !== preset.max
    )
  })
}

const PricesContainer = styled.div`
  ${tw`bg-white lg:border border-gray-150 rounded-xl max-w-full lg:w-[340px]`};

  @media only screen and (min-width: 1024px) {
    box-shadow:
      0 8px 24px rgba(0, 0, 0, 0.08),
      0 1px 4px rgba(0, 0, 0, 0.01);
  }
`

const PresetButton = styled.button<{ $selected: boolean }>`
  ${tw`px-5 py-3 rounded-lg hover:bg-gray-100 active:bg-gray-150 transition-colors text-left`}
  ${({ $selected }) =>
    $selected &&
    tw`bg-primary-new-050 text-primary-new-700 font-medium hover:bg-primary-new-050 active:bg-primary-new-050`}
`

const CustomInput = tw.input`w-16 text-center border border-gray-200 px-2 py-2 rounded-lg focus:(outline-none border-primary-new-400) transition-colors`

export default PriceFilterV2
