import { useGlobalState } from "../GlobalState"
import { Realm, getRealmFromPath } from "../realm"

export const useCurrentGift = (realmOverride?: Realm) => {
  const consumerCurrentGiftState = useGlobalState("consumerCurrentGift")
  const businessCurrentGiftState = useGlobalState("businessCurrentGift")

  const realm = realmOverride ?? getRealmFromPath(window.location.pathname) // Use window.location because may not be in router

  return realm === "consumer"
    ? consumerCurrentGiftState
    : businessCurrentGiftState
}
