import classNames from "classnames"
import { FC, ReactNode } from "react"
import tw, { styled } from "twin.macro"

interface Props {
  state: any
  leftState: string
  leftText: ReactNode
  rightText: ReactNode
  onLeftClick: () => void
  onRightClick: () => void
  width: string
  bgColor?: string
}

const Toggle: FC<Props> = ({
  state,
  leftState,
  leftText,
  rightText,
  onLeftClick,
  onRightClick,
  width,
  bgColor,
  ...props
}) => {
  const backgroundClassName = classNames("toggle-background", {
    "right-selected": state !== leftState,
  })
  const leftButtonClassName = classNames("toggle-button", {
    "left-selected": state === leftState,
  })
  const rightButtonClassName = classNames("toggle-button", {
    "left-selected": state !== leftState,
  })

  return (
    <Container width={width} bgColor={bgColor || "transparent"} {...props}>
      <ToggleBackground className={backgroundClassName} width={width} />
      <div className="toggle-container">
        <button className={leftButtonClassName} onClick={onLeftClick}>
          {leftText}
        </button>
        <button className={rightButtonClassName} onClick={onRightClick}>
          {rightText}
        </button>
      </div>
    </Container>
  )
}

const Container = styled.div<{ width: string; bgColor: string }>`
  ${tw`pointer-events-auto border flex flex-row items-stretch rounded-full hover:bg-gray-100 grid pointer-events-auto`};
  border-color: #eceef1;
  height: 34px;
  background-color: ${(props) => props.bgColor};

  & > div {
    ${tw`col-start-1 row-start-1`}
  }

  & > div.toggle-container {
    ${tw`flex flex-row z-10`}

    & > button {
      ${tw`flex flex-row items-center text-gray-400 gap-2`};
      width: ${({ width }) => width};
      justify-content: center;

      svg {
        ${tw`text-gray-400`}
      }

      &.left-selected {
        ${tw`text-primary-500 font-semibold`}

        svg {
          ${tw`text-primary-500`}
        }
      }
    }
  }
`

const ToggleBackground = styled.div<{ width: string }>`
  ${tw`h-full bg-white rounded-full border border-primary-200 transition-transform ease-in-out p-4`}
  box-shadow: 0px 2px 4px rgba(125, 64, 200, 0.08),
    0px 1px 2px rgba(125, 64, 200, 0.06);
  width: 50%;
  margin: -1px 0px;

  &.right-selected {
    transform: translate(${({ width }) => width});
  }
`

export default Toggle
