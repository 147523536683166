import { gql, useMutation } from "@apollo/client"
import { useEffect } from "react"
import { useDebouncedCallback } from "use-debounce"

import { getProduct } from "../gifts"
import { CurrentGift, useGlobalState } from "../GlobalState"

import {
  GiftBatchCheckAbandonedCartMutation,
  GiftBatchCheckAbandonedCartMutationVariables,
} from "@/types/graphql-types"

const FIVE_MINUTES = 5 * 60 * 1000

// Hook responsible for notifying the backend about GiftBatches that is
// potentially applicable for abandoned carts notification.
export const useAbandonedCarts = (giftBatch: CurrentGift) => {
  const [user] = useGlobalState("user")
  const { card, fromName, message } = giftBatch

  const product = getProduct(giftBatch)

  const shouldNotify =
    user &&
    !user.hasCreatedAGiftBatch &&
    product?.id &&
    (card?.id || fromName || message)

  const [checkAbandonedCart] = useMutation<
    GiftBatchCheckAbandonedCartMutation,
    GiftBatchCheckAbandonedCartMutationVariables
  >(CHECK_ABANDONED_CART_MUTATION)

  const notifyAbandonedCartPossibility = useDebouncedCallback(
    checkAbandonedCart,
    FIVE_MINUTES,
    {
      leading: true, // Do not trigger automatically on load
      maxWait: FIVE_MINUTES,
    },
  )

  useEffect(() => {
    if (shouldNotify) {
      notifyAbandonedCartPossibility()
    }
  }, [
    card?.id,
    fromName,
    message,
    product?.id,
    shouldNotify,
    notifyAbandonedCartPossibility,
  ])
}

const CHECK_ABANDONED_CART_MUTATION = gql`
  mutation GiftBatchCheckAbandonedCart {
    giftBatchCheckAbandonedCart {
      ok
    }
  }
`
