import { useRef, useState } from "react"

import { useGlobalState } from "@/common/GlobalState"
import { useFeatureAccess } from "@/common/hooks/featureAccess"
import { useShippingCountries } from "@/store/providers/ShippingCountriesProvider"
import { ShippingCountryGroupFragment } from "@/types/graphql-types"

export const useGlobalShippingUpsell = () => {
  const { hasFeature } = useFeatureAccess()
  const { popoverShippingCountry } = useShippingCountries()
  const [openModal, setOpenModal] = useState(false)
  const [currentRealm] = useGlobalState("currentRealm")
  const isConsumerRealm = currentRealm === "consumer"
  const shipsToUS = useRef(false)

  const closeModal = () => setOpenModal(false)

  const checkForGlobalShipping = (
    shippingCountryGroup: ShippingCountryGroupFragment,
  ) => {
    shipsToUS.current = shippingCountryGroup.domestic?.includes("US") ?? false

    const consumerCheck =
      isConsumerRealm &&
      (popoverShippingCountry.code !== "US" || !shipsToUS.current)
    const businessCheck =
      !hasFeature("pro_plan") &&
      shippingCountryGroup.globalRelay?.includes(popoverShippingCountry.code)
    const shouldOpenModal = consumerCheck || businessCheck

    if (shouldOpenModal) {
      setOpenModal(true)
    }

    return shouldOpenModal
  }

  return {
    openGlobalShippingUpsellModal: openModal,
    closeGlobalShippingUpsellModal: closeModal,
    checkForGlobalShipping,
    shipsToUS: shipsToUS.current,
  }
}
