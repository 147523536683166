import { useState } from "react"
import Modal from "react-modal"
import tw, { styled } from "twin.macro"

import { ReactComponent as CheckIcon } from "../../common/images/check.svg"
import { modalStyle } from "../../common/modal"
import { ReactComponent as ArrowUpRightIcon } from "../images/arrow-up-right.svg"
import { ReactComponent as CopyIcon } from "../images/copy.svg"

export const LandingPageContainer = styled.div`
  ${tw`rounded-lg p-4 lg:text-center lg:items-center flex flex-col lg:flex-row lg:p-6 bg-white`}
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04), 0px 8px 37px rgba(0, 0, 0, 0.06);
`

export const OutlinedButton = styled.button`
  ${tw`rounded-lg h-10 border border-gray-200 inline-flex items-center gap-3 px-4 text-sm text-gray-500 transition-colors justify-center`}

  &:hover:not(.active), &:focus-visible {
    ${tw`border-purple-300 text-purple-500`}
  }

  &:active {
    ${tw`border-purple-500`}
  }

  &:active,
  &:focus {
    ${tw`outline-none`}
  }

  svg {
    ${tw`stroke-current opacity-80`}
  }
`

interface GiftLinkProps {
  href: string
  previewHref: string
  previewClass?: string
  color: "blue" | "gray"
}

export const LinkContainer: React.FC<GiftLinkProps> = ({
  href,
  previewHref,
  previewClass,
  color,
}) => {
  const [previewing, setPreviewing] = useState<boolean>(false)
  const [copied, setCopied] = useState<boolean>(false)

  const copy = async () => {
    await navigator.clipboard.writeText(href)
    setCopied(true)
    setTimeout(() => setCopied(false), 3000)
  }

  return (
    <GiftLinkContainer color={color}>
      <div className="copyLink">
        {navigator.clipboard ? (
          <button onClick={copy} className={copied ? "copied" : ""}>
            <CopyIcon className="copy" />
            <CheckIcon className="check" tw="stroke-2" />
          </button>
        ) : null}
        <input
          readOnly
          value={href}
          onClick={(e) => e.currentTarget.select()}
          className="data-hj-suppress ph-no-capture fs-exclude"
        />
      </div>

      <OutlinedButton
        className={previewClass}
        onClick={() => setPreviewing(true)}
      >
        Preview
        <ArrowUpRightIcon />
      </OutlinedButton>
      <Modal
        isOpen={previewing}
        onRequestClose={() => setPreviewing(false)}
        style={modalStyle}
      >
        <iframe
          src={previewHref}
          title={href}
          tw="bg-white rounded-xl shadow-lg"
          css={{ width: "90vw", height: "90vh" }}
        ></iframe>
      </Modal>
    </GiftLinkContainer>
  )
}

const GiftLinkContainer = styled.div`
  ${tw`inline lg:flex text-gray-500 w-full items-stretch gap-2`}
  line-height: 2.75rem;

  button {
    &:active,
    &:focus {
      ${tw`outline-none`}
    }
  }

  .copyLink {
    ${tw`inline-flex w-full lg:w-auto h-10 overflow-hidden rounded-lg flex-auto`}

    button {
      ${tw`flex-none border-r-2 border-white transition-colors`}

      &:hover,
      &:focus-visible {
        ${tw`bg-gray-400 text-white`}
        ${(props) => (props.color === "blue" ? tw`bg-blue-500` : null)}
      }

      &:active {
        ${tw`bg-gray-500`}
        ${(props) => (props.color === "blue" ? tw`bg-blue-600` : null)}
      }

      svg {
        ${tw`w-6 stroke-current`}
      }

      &:not(.copied) .check,
      &.copied .copy {
        ${tw`hidden`}
      }
    }

    input {
      ${tw`block truncate items-center flex-auto`}

      &:focus {
        ${tw`outline-none`}
      }
    }

    button,
    input {
      ${tw`bg-gray-100 h-full px-3`}
      ${(props) =>
        props.color === "blue" ? tw`text-blue-500 bg-blue-100` : null}
    }
  }

  ${OutlinedButton} {
    ${tw`lg:w-auto lg:mr-0`}
    width: calc(50% - .25rem);
    margin-right: 0.5rem;

    &.full-width {
      @media (max-width: 1023px) {
        width: 100%;
      }
    }
  }
`

export const LandingPageLink: React.FC<GiftLinkProps> = ({
  href,
  previewHref,
  previewClass,
  color,
}) => {
  const [copied, setCopied] = useState<boolean>(false)

  const copy = async () => {
    await navigator.clipboard.writeText(href)
    setCopied(true)
    setTimeout(() => setCopied(false), 3000)
  }

  return (
    <GiftLinkContainer color={color}>
      <div className="copyLink">
        {navigator.clipboard ? (
          <button onClick={copy} className={copied ? "copied" : ""}>
            <CopyIcon className="copy" />
            <CheckIcon className="check" />
          </button>
        ) : null}
        <input
          readOnly
          value={href}
          onClick={(e) => e.currentTarget.select()}
          className="data-hj-suppress ph-no-capture fs-exclude"
        />
      </div>

      <OutlinedButton
        className={previewClass}
        onClick={() => window.open(href, "_blank", "noopener")}
      >
        Preview
        <ArrowUpRightIcon />
      </OutlinedButton>
    </GiftLinkContainer>
  )
}
