import "twin.macro"
import React from "react"

import { useGlobalState } from "../../common/GlobalState"
import Container from "../App/Container"

export default function StaffAccessBanner() {
  const [user] = useGlobalState("user")

  if (!user?.staffAccessActive) {
    return null
  }

  return (
    <div tw="bg-[#FF8C4B] text-white h-12 flex flex-row items-center">
      <Container tw="flex items-center gap-4 px-5">
        <div tw="rounded-full bg-[#D9692A] px-4 py-1 font-medium">
          Staff Access
        </div>
        <div>
          You are currently signed in to a customer account. Sign out when you
          are finished.
        </div>
      </Container>
    </div>
  )
}
