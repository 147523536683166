import { useMutation } from "@apollo/client"
import { useCallback, useState } from "react"
import tw, { styled } from "twin.macro"

import { ReactComponent as InfoIcon } from "../assets/icons/info.svg"
import { ReactComponent as LockIcon } from "../assets/icons/lock.svg"
import DropdownSelect from "../common/DropdownSelect"
import { successToast } from "../common/toast"
import { WORKSPACE_UPDATE } from "../organization/Workspaces/EditWorkspaceDrawer"

import { InviteMembersPermission } from "@/types/graphql-types"
import {
  WorkspaceFragment,
  Workspace_UpdateMutation,
  Workspace_UpdateMutationVariables,
} from "@/types/graphql-types"

interface WorkspacePermissionsProps {
  workspace: WorkspaceFragment
}

const WorkspacePermissions = ({ workspace }: WorkspacePermissionsProps) => {
  const [inviteOtherUsersToWorkspace, setInviteOtherUsersToWorkspace] =
    useState<InviteMembersPermission>(
      workspace.permissions.inviteMembersPermission,
    )
  const [updateWorkspace] = useMutation<
    Workspace_UpdateMutation,
    Workspace_UpdateMutationVariables
  >(WORKSPACE_UPDATE)

  const inviteOtherUsersMapping: { value: string; label: string }[] = [
    {
      value: "MEMBERS_AND_MANAGERS",
      label: "Members and managers",
    },
    {
      value: "MANAGERS",
      label: "Managers",
    },
  ]

  const updateWorkspacePermissions = useCallback(
    async (inviteMembersPermission: InviteMembersPermission) => {
      const result = await updateWorkspace({
        variables: {
          id: workspace.id,
          fields: { permissions: { inviteMembersPermission } },
        },
      })

      if (result.data?.workspaceUpdate.ok) {
        successToast("Permissions updated.")
      }
    },
    [workspace, updateWorkspace],
  )

  return (
    <Container>
      <Banner>
        <div>
          <InfoIcon tw="text-gray-450" />
        </div>
        <div>
          <div tw="mb-3">
            Adjust permissions for your workspace for managers and members.
          </div>
          <div tw="mb-3">
            <span tw="font-medium">Members</span> are standard users who can
            send gifts. This is the default role for new users joining the
            workspace.
          </div>
          <div>
            <span tw="font-medium">Managers</span> are workspace administrators.
            Managers can adjust permissions settings, promote other users to
            managers, remove members, and see gifts sent in their workspace.
          </div>
        </div>
      </Banner>
      <div>
        <PermissionRow>
          <PermissionDescription>
            Edit workspace settings like workspace name and permissions
          </PermissionDescription>
          <PermissionValue>
            Managers <DarkLockIcon />
          </PermissionValue>
        </PermissionRow>
        <PermissionRow>
          <PermissionDescription>
            Change member roles or remove workspace members
          </PermissionDescription>
          <PermissionValue>
            Managers <DarkLockIcon />
          </PermissionValue>
        </PermissionRow>
        <PermissionRow>
          <PermissionDescription>
            Invite new workspace members
          </PermissionDescription>
          <PermissionValue>
            <DropdownSelect
              value={inviteOtherUsersToWorkspace}
              setValue={(value) => {
                if (isInviteMembersPermission(value)) {
                  setInviteOtherUsersToWorkspace(value)
                  void updateWorkspacePermissions(value)
                }
              }}
              optionMappings={inviteOtherUsersMapping}
            />
          </PermissionValue>
        </PermissionRow>
      </div>
    </Container>
  )
}

const isInviteMembersPermission = (
  value: string,
): value is InviteMembersPermission =>
  ["MANAGERS", "MEMBERS_AND_MANAGERS"].includes(value)

const Banner = tw.div`bg-gray-050 flex gap-5 mb-8 rounded-xl p-5`
const Container = tw.div`px-9 py-7`
const PermissionDescription = tw.div`font-medium py-6`
const PermissionValue = tw.div`flex items-center mr-4 text-gray-450`
const PermissionRow = tw.div`first:border-t border-b border-gray-250 flex justify-between items-center`

// Rather than uploading a new Lock icon or modifying the existing one, !important was used.
const DarkLockIcon = styled(LockIcon)`
  ${tw`ml-3.5`};

  g {
    opacity: 1 !important;
    stroke-width: 1.5px;
  }

  path {
    stroke: rgba(131, 138, 151, 1) !important;
  }
`

export default WorkspacePermissions
