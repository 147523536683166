import { isArray } from "lodash-es"
import { useEffect, useRef } from "react"
import { useDebouncedCallback } from "use-debounce"

import {
  BatchRecipient,
  CurrentGift,
  SendPageMode,
  useGlobalState,
} from "./GlobalState"
import { useCartRefresh } from "./hooks/cartRefresh"
import { getSharedFieldsFrom } from "./hooks/currentGiftSync"
import { daysFromNow, fourWeeksFromNow, sixWeeksFromNow } from "./utilities"

const LOCALSTORAGE_KEY = "__goody_send_autosave"

export default function SendAutosave() {
  const restoreAttempted = useRef(false)

  const [consumerCurrentGift, setConsumerCurrentGift] = useGlobalState(
    "consumerCurrentGift",
  )
  const [businessCurrentGift, setBusinessCurrentGift] = useGlobalState(
    "businessCurrentGift",
  )
  const [recipients, setRecipients] = useGlobalState("recipients")
  const [sendPageMode] = useGlobalState("sendPageMode")
  const cartRefresh = useCartRefresh()

  function autosave(
    consumerCurrentGift: CurrentGift,
    businessCurrentGift: CurrentGift,
    recipients: BatchRecipient[],
    sendPageMode: SendPageMode,
  ) {
    if (!restoreAttempted.current || sendPageMode === "updateGiftBatch") {
      return
    }

    localStorage.setItem(
      LOCALSTORAGE_KEY,
      JSON.stringify({
        consumerCurrentGift,
        businessCurrentGift,
        recipients,
        savedAt: new Date(),
        autosaveVersion: 1,
      }),
    )
  }

  useEffect(() => {
    // When loading with staff access, do not restore autosave and clear any
    // autosave that is already set to prevent confusion.
    if (window.location.hash === "#staff-access") {
      localStorage.removeItem(LOCALSTORAGE_KEY)
      return
    }

    const savedState = localStorage.getItem(LOCALSTORAGE_KEY)

    if (savedState) {
      const {
        currentGift,
        consumerCurrentGift,
        businessCurrentGift,
        recipients,
      }: {
        currentGift?: CurrentGift
        consumerCurrentGift?: CurrentGift
        businessCurrentGift?: CurrentGift
        recipients?: BatchRecipient[]
      } = parseWithDate(savedState)

      fixCurrentGift(currentGift)
      fixCurrentGift(consumerCurrentGift)
      fixCurrentGift(businessCurrentGift)

      const loadCurrentGift = (
        setCurrentGift:
          | typeof setBusinessCurrentGift
          | typeof setConsumerCurrentGift,
        currentGift: CurrentGift,
      ) => {
        // We need to make sure the shared fields are the same, or we get infinite loop from currentGiftSync
        setCurrentGift((val) => ({
          ...val,
          ...currentGift,
          ...(businessCurrentGift && getSharedFieldsFrom(businessCurrentGift)),
          businessSendStep: "start",
        }))
      }

      // Legacy route if we have old saved currentGift
      if (currentGift) {
        loadCurrentGift(setBusinessCurrentGift, currentGift)
      }
      if (businessCurrentGift) {
        loadCurrentGift(setBusinessCurrentGift, businessCurrentGift)
      }
      if (consumerCurrentGift) {
        loadCurrentGift(setConsumerCurrentGift, consumerCurrentGift)
      }

      // They all share the cart, so it doesn't matter which one
      cartRefresh(businessCurrentGift || consumerCurrentGift || currentGift)

      if (isArray(recipients)) {
        setRecipients(recipients)
      }
    }

    restoreAttempted.current = true
  }, [])

  const debouncedUpdateStorage = useDebouncedCallback(
    (
      consumerCurrentGift: CurrentGift,
      businessCurrentGift: CurrentGift,
      recipients: BatchRecipient[],
      sendPageMode: SendPageMode,
    ) => {
      autosave(
        consumerCurrentGift,
        businessCurrentGift,
        recipients,
        sendPageMode,
      )
    },
    500,
  )

  useEffect(() => {
    debouncedUpdateStorage(
      consumerCurrentGift,
      businessCurrentGift,
      recipients,
      sendPageMode,
    )
  }, [consumerCurrentGift, businessCurrentGift, recipients, sendPageMode])

  useEffect(() => {
    function onVisibilityChange() {
      if (document.visibilityState === "hidden") {
        autosave(
          consumerCurrentGift,
          businessCurrentGift,
          recipients,
          sendPageMode,
        )
      }
    }

    document.addEventListener("visibilitychange", onVisibilityChange)

    return () => {
      document.removeEventListener("visibilitychange", onVisibilityChange)
    }
  }, [consumerCurrentGift, businessCurrentGift, recipients, sendPageMode])

  return null
}

// https://stackoverflow.com/a/29971466
export function parseWithDate(jsonString: string): any {
  const reDateDetect = /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})/ // startswith: 2015-04-29T22:06:55
  return JSON.parse(jsonString, (key: any, value: any) => {
    if (typeof value == "string" && reDateDetect.exec(value)) {
      return new Date(value)
    }
    return value
  })
}

function fixCurrentGift(currentGift: CurrentGift | undefined) {
  fixDates(currentGift)
  fixCartImages(currentGift)
}

// Performs a number of fixes on dates to avoid a few classes of errors:
// 1. Expires At might have been in the past. This results in an error on send that "expire at cannot be in the past".
// 2. Expires At when set to "four weeks" might have been four weeks from save date, not today. This results in a mismatch between what the UI displays as 4 weeks and what is actually set as the Expires At.
// 3. Scheduled Send On might be in the past. This results in an error on send.
function fixDates(currentGift: CurrentGift | undefined) {
  if (currentGift) {
    if (currentGift.expiresAt) {
      if (currentGift.expiresAt <= new Date()) {
        // Expiry is in the past

        if (currentGift.expireAtOption === "none") {
          // If expiry was previously set to `none`, reset to default value.
          currentGift.expiresAt = null
        } else if (
          currentGift.expireAtOption === "custom" ||
          currentGift.expireAtOption === "fourWeeks" ||
          currentGift.expireAtOption === "sixWeeks"
        ) {
          // If expiry was previously set to `custom`, set the expire at option to 4 weeks.
          // If expiry was previously set to `fourWeeks`, no change needed to expire at option.
          currentGift.expireAtOption = "sixWeeks"
          currentGift.expiresAt = sixWeeksFromNow()
        }
      } else {
        // Expiry is set, but not in the past
        // If expire at option is `fourWeeks` or `sixWeeks`, set it to the proper value
        if (currentGift.expireAtOption === "fourWeeks") {
          currentGift.expiresAt = fourWeeksFromNow()
        } else if (currentGift.expireAtOption === "sixWeeks") {
          currentGift.expiresAt = sixWeeksFromNow()
        }
      }
    }

    if (currentGift.scheduledSendOnDate < new Date()) {
      currentGift.scheduledSendOnDate = daysFromNow(7)
    }
  }
}

// We changed the image format in a commit, so we might need a fix saved autosaves.
function fixCartImages(currentGift: CurrentGift | undefined) {
  currentGift?.cart?.forEach((item) => {
    if (typeof item.imageLarge === "string") {
      item.imageLarge = { url: item.imageLarge }
    }
    if (typeof item.imageThumb === "string") {
      item.imageThumb = { url: item.imageThumb }
    }
  })
}
