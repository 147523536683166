import React from "react"
import tw, { css, styled } from "twin.macro"

import { AlertBubble } from "../../assets/icons"
import Button from "../../common/Button"
import { buttonStyle } from "../../common/GradientButton"

import { GiftSwapTypeEnum } from "@/types/graphql-types"

interface Props {
  total: number
  swapType: GiftSwapTypeEnum
  onClickButton: () => void
  hasFlexGiftItem: boolean
}

const SWAP_COST_AMOUNT = 7500

export const SwapWarning = ({
  total,
  swapType,
  onClickButton,
  hasFlexGiftItem,
}: Props) => {
  if (swapType !== GiftSwapTypeEnum.swap_single || total < SWAP_COST_AMOUNT) {
    return null
  }

  return (
    <div tw={"flex flex-row items-stretch bg-primary-000 mb-4"}>
      <div tw={"w-1 bg-primary-500"} />
      <div tw={"flex-1 flex flex-row items-start"}>
        <div tw={"ml-5 mt-4"}>
          <SwapIconContainer>
            <AlertBubble />
          </SwapIconContainer>
        </div>
        <div tw={"flex flex-col m-4"}>
          <div tw={"font-medium leading-[1.4]"}>
            We suggest enabling
            {hasFlexGiftItem
              ? " multiple item selection "
              : " swapping for multiple items "}
            (prices shown) when your gift is over ${SWAP_COST_AMOUNT / 100}.
          </div>
          <div tw={"text-sm leading-[1.4] mt-3"}>
            This gives your recipient the option to
            {hasFlexGiftItem ? " select " : " swap for "} multiple items up to
            your gift value, instead of just one item. Available for US
            recipients only.
          </div>
          <Button tw={"mt-4 self-start"} onClick={onClickButton}>
            Enable {hasFlexGiftItem ? " choosing " : " swapping for "} multiple
            items (prices shown)
          </Button>
        </div>
      </div>
    </div>
  )
}

const SwapIconContainer = styled.div`
  ${tw`w-10 h-10 bg-white rounded-full flex flex-row items-center justify-center`}
  box-shadow: 0px 2.83186px 11.3274px rgba(35, 0, 108, 0.06), inset 0px -2.83186px 5.66372px rgba(47, 0, 146, 0.02), inset 0px -0.707965px 1.41593px rgba(33, 0, 103, 0.02);
`

const whiteButtonCss = css`
  ${buttonStyle}
  ${tw`bg-white border border-gray-200 rounded-lg text-base text-gray-600 font-normal cursor-pointer  h-[36px]`}
  box-shadow: 0px 2px 4px rgba(229, 231, 235, 0.3);
`

const WhiteButton = styled.div`
  ${whiteButtonCss}
`
