import pluralize from "pluralize"
import React from "react"
import tw, { styled } from "twin.macro"

import { ReactComponent as CaretIcon } from "./images/hr-integration/caret.svg"

interface ContactListItemProps {
  selected: boolean
  name: string
  contactsCount: number
  onClick: () => void
}

const ContactListItem: React.FC<ContactListItemProps> = ({
  selected,
  name,
  contactsCount,
  onClick,
}) => {
  return (
    <ContactListButton
      className={selected ? "selected" : undefined}
      onClick={onClick}
    >
      <div tw="flex lg:block gap-2 items-baseline lg:px-6 py-2 lg:py-3">
        <div className="name data-hj-suppress ph-no-capture fs-mask">
          <ContactListName name={name} />
        </div>
        <div className="contactsCount">
          {contactsCount}{" "}
          <span tw="hidden lg:inline">
            {pluralize("contact", contactsCount)}
          </span>
        </div>
      </div>
    </ContactListButton>
  )
}

const ContactListName: React.FC<{ name: string }> = ({ name }) => {
  if (name.includes(">")) {
    const nameArray = name.split(" > ")
    const teamHierarchy = nameArray.map((teamName) => {
      return (
        <>
          <div>{teamName}</div>
          <CaretIcon />
        </>
      )
    })
    return <TeamNameHierarchy>{teamHierarchy}</TeamNameHierarchy>
  } else {
    return <>{name}</>
  }
}

const ContactListButton = styled.button`
  ${tw`focus:outline-none transition-colors rounded-lg text-left transition-colors`}

  // insert separator lines
  &::before, &:last-child::after {
    ${tw`lg:border-t border-gray-100`}
    width: 100%;
    height: 0;
    content: " ";
    display: block;
  }

  .name {
    ${tw`text-black text-lg lg:text-base lg:text-gray-500`}
  }

  .contactsCount {
    ${tw`text-gray-400 lg:pt-1 text-sm`}
  }

  &:hover,
  &:focus-visible {
    .name {
      ${tw`lg:text-gray-700`}
    }

    .contactsCount {
      ${tw`lg:text-gray-500`}
    }
  }

  &.selected {
    ${tw`lg:bg-primary-050`}

    .name {
      ${tw`lg:font-medium lg:text-primary-500`}
    }

    .contactsCount {
      ${tw`lg:text-gray-600`}
    }

    &:focus-visible {
      ${tw`lg:text-primary-600`}
    }

    &::before,
    + ::before,
    &:last-child::after {
      ${tw`border-transparent`}
    }
  }
`

const TeamNameHierarchy = styled.div`
  ${tw`flex gap-2 items-center`}

  svg:last-of-type {
    ${tw`hidden`}
  }
`

export default ContactListItem
