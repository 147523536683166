import DialogModal from "@/common/DialogModal"

interface Props {
  open: boolean
  onClose: () => void
  withDomesticSupport?: boolean
}

const GlobalShippingCostModal = ({
  open,
  onClose,
  withDomesticSupport,
}: Props) => (
  <DialogModal open={open} onClose={onClose}>
    <DialogModal.Header variant="info" />
    <DialogModal.Title>Global shipping costs</DialogModal.Title>

    <DialogModal.Content tw="flex flex-col gap-4">
      <div>
        When using our global shipping service, the gift vendor ships the
        product to our warehouse, and we then ship it internationally to your
        recipient’s destination.
      </div>
      <div>
        In addition to the product’s shipping cost, there is a cost of up to $50
        for import duties, taxes, and freight. Goody covers any excess over $50.
        No US sales tax is charged.
      </div>
      {withDomesticSupport && (
        <div>
          <span tw="font-medium">Looking for a more inexpensive option? </span>
          Goody partners with brands in Canada for domestic shipping, which is
          cheaper and faster, and doesn’t incur import duties. See our gift
          options in the store.
        </div>
      )}
    </DialogModal.Content>

    <DialogModal.Actions>
      <DialogModal.Button variant="primary-new" onClick={onClose}>
        Got it
      </DialogModal.Button>
    </DialogModal.Actions>
  </DialogModal>
)

export default GlobalShippingCostModal
