import { gql } from "@apollo/client"

export const BASE_ORGANIZATION_FRAGMENT = gql`
  fragment BaseOrganization on Organization {
    id
    name
    subscription {
      id
      isTeamPlan
      isEe
    }
    currentMembership {
      id
      access
      role
    }
    quarterStartMonth
  }
`
