import React, { ReactElement } from "react"
import tw from "twin.macro"

import HowAutogiftingWorkOption1 from "./images/HowAutogiftingWorksOption1.png"
import HowAutogiftingWorkOption2 from "./images/HowAutogiftingWorksOption2.png"
import {
  CalendarPurpleBackground,
  GiftPurpleBackground,
} from "../../../assets/icons"
import {
  FlexCenterItems,
  GridCenterContainer,
} from "../components/PositionHelper"
import TitleContainer from "../components/TitleContainer"

const optionItems: OptionItemProps[] = [
  {
    icon: <GiftPurpleBackground />,
    title: "Send gifts or swag for employee milestones",
    description:
      "Whether you’re onboarding new hires or celebrating birthdays, employees get a beautiful digital unwrapping experience and a curated selection of gift options or swag.",
    image: HowAutogiftingWorkOption1,
    imagePosition: "left",
  },
  {
    icon: <CalendarPurpleBackground />,
    title: "Recognize work anniversaries and tenure levels",
    description:
      "Set different gift values based on tenure, like $50 for 1-4-year anniversaries and $150 for 5-year anniversaries.",
    image: HowAutogiftingWorkOption2,
    imagePosition: "right",
  },
]

const HowAutogiftingWorks: React.FC = () => (
  <FlexCenterItems tw="w-full">
    <div tw="px-5">
      <TitleContainer title="How Autogifting works" titleSize="lg" />
    </div>
    <FlexCenterItems tw="mx-auto pt-5 lg:pt-10 gap-20">
      {optionItems.map((args) => (
        <OptionItem key={args.title} {...args} />
      ))}
    </FlexCenterItems>
  </FlexCenterItems>
)

interface OptionItemProps {
  icon: ReactElement
  title: string
  description: string
  image: string
  imagePosition: "right" | "left"
}

const OptionItem: React.FC<OptionItemProps> = ({
  icon,
  title,
  description,
  image,
  imagePosition,
}) => {
  const imgIsOnTheLeft = imagePosition === "left"
  const imgIsOnTheRight = imagePosition === "right"
  return (
    <GridCenterContainer tw="max-w-screen-xl px-5 z-30">
      {imgIsOnTheLeft && <Image src={image} />}
      <div
        css={[
          tw`pt-7 lg:pt-0 max-w-[400px]`,
          imgIsOnTheLeft && tw`lg:justify-self-end`,
          imgIsOnTheRight && tw`lg:justify-self-start`,
        ]}
      >
        <div tw="pb-3"> {icon} </div>
        <TitleContainer
          title={
            <div tw="text-[28px] leading-[38px] lg:text-[42px] lg:leading-[50px]">
              {title}
            </div>
          }
          subtitle={
            <div tw="text-[20px] leading-[30px] lg:text-[22px] lg:leading-[34px]">
              {description}
            </div>
          }
          align="left"
        />
      </div>
      {imgIsOnTheRight && <Image src={image} />}
    </GridCenterContainer>
  )
}

const Image = tw.img`row-start-1 lg:row-start-auto z-30 w-[400px] object-contain lg:w-[520px]`

export default HowAutogiftingWorks
