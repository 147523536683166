import { Link } from "react-router-dom"
import tw, { css, styled } from "twin.macro"

const WhiteBackgroundBase = css`
  ${tw`rounded-lg py-4 px-7 font-medium hover:opacity-80 active:opacity-90 transition-colors border cursor-pointer`};
  border-color: #8e75ff;
  color: #8e75ff;

  &:hover {
    border-color: #c6baff;
  }

  &:active {
    border-color: #8e75ff;
  }
`

const WhiteBackgroundLink = styled(Link)`
  ${WhiteBackgroundBase}
`

export const WhiteBackgroundButton = styled.button`
  ${WhiteBackgroundBase}
`

export const WhiteBackgroundExternalLink = styled.a`
  ${WhiteBackgroundBase}
`

export default WhiteBackgroundLink
