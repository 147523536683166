import React, { useState } from "react"
import tw, { styled } from "twin.macro"

import HowItWorksVideoCTA from "./HowItWorksVideoCTA"
import SeeSampleGiftModal from "./SeeSampleGiftModal"
import { useGlobalState } from "../../common/GlobalState"
import PurpleGradientLink from "../../common/PurpleGradientLink"
import { generateRealmPath } from "../../common/realm"
import WhiteBackgroundLink, {
  WhiteBackgroundButton,
} from "../../common/WhiteBackgroundLink"

const Header = styled.h1`
  ${tw`text-center lg:text-left font-bold mb-3 text-3xl lg:text-4rem lg:leading-4.5rem`}
`

const Subheading = styled.h1`
  ${tw`text-xl lg:text-2xl text-gray-600 leading-normal lg:leading-9 text-center lg:text-left pb-4 lg:pr-12 m-auto lg:ml-0`}
`

const SignUpText = () => {
  return (
    <div tw="text-gray-600 text-center lg:text-left">
      Sign up and send your{" "}
      <span tw="text-gray-900 font-bold"> first gift free. </span>
    </div>
  )
}

const SignUpBtn = () => {
  const [signedIn] = useGlobalState("signedIn")

  const text = signedIn ? "Go to Goody for Business" : "Sign up — it’s free!"
  const path = signedIn ? "/send" : "/signup"
  const realm = signedIn ? "plus" : "business"

  return (
    <PurpleGradientLink
      tw="flex-none w-full lg:w-auto"
      to={generateRealmPath(realm, path)}
    >
      {text}
    </PurpleGradientLink>
  )
}

const BookADemoBtn = () => {
  return (
    <WhiteBackgroundLink
      tw="w-full lg:w-48 text-center lg:ml-4 lg:inline-block mt-4 lg:mt-0 font-bold"
      to={generateRealmPath("business", "/book-a-call")}
    >
      Get a Demo
    </WhiteBackgroundLink>
  )
}

const SeeSampleGiftModalButton = ({
  setIsSeeSampleGiftModalOpen,
}: {
  setIsSeeSampleGiftModalOpen: (isOpen: boolean) => void
}) => (
  <WhiteBackgroundButton
    tw="w-full lg:w-48 text-center lg:ml-4 lg:inline-block mt-4 lg:mt-0 font-bold"
    onClick={() => setIsSeeSampleGiftModalOpen(true)}
  >
    See a Sample Gift
  </WhiteBackgroundButton>
)

const CommonBtns = () => {
  const [isSeeSampleGiftModalOpen, setIsSeeSampleGiftModalOpen] =
    useState(false)

  return (
    <>
      <div tw="flex flex-col lg:flex-row items-center justify-start mt-6">
        <SignUpBtn />
        <SeeSampleGiftModalButton
          setIsSeeSampleGiftModalOpen={setIsSeeSampleGiftModalOpen}
        />
      </div>

      <div tw="flex flex-col lg:flex-row items-center justify-start mt-6">
        <HowItWorksVideoCTA />
      </div>
      <SeeSampleGiftModal
        isOpen={isSeeSampleGiftModalOpen}
        setIsOpen={setIsSeeSampleGiftModalOpen}
      />
    </>
  )
}

export { Header, Subheading, SignUpText, SignUpBtn, BookADemoBtn, CommonBtns }
