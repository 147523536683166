import Contacts from "./Contacts"
import { AddBalanceModalProvider } from "../common/hooks/addBalanceModal"

export const ContactsWrapper = () => {
  return (
    <AddBalanceModalProvider>
      <Contacts />
    </AddBalanceModalProvider>
  )
}
