import tw from "twin.macro"

import SendMethodButton from "./SendMethodButton"
import { useGiftData } from "../../common/hooks/giftData"
import { ReactComponent as DirectIcon } from "../images/method-direct.svg"
import { ReactComponent as EmailIcon } from "../images/method-email.svg"

import { useAfterMountDelay } from "@/common/hooks/afterMountDelay"
import { BatchSendMethod } from "@/types/graphql-types"

export default function SendMethodMenu({ sendV3 }: { sendV3?: boolean }) {
  const { currentGift, setSendMethod, setLandingPage } = useGiftData()

  const animationDone = useAfterMountDelay(600)

  return (
    <div
      tw="lg:mt-[-2.25rem] px-5 lg:px-0 pt-2 lg:pt-0"
      className={!animationDone ? "animated fadeInUpMedium" : undefined}
      css={[
        { animationDuration: "0.5s", animationTimingFunction: "ease-out" },
        sendV3 && tw`lg:mt-0 px-0 lg:px-0`,
      ]}
    >
      <div
        tw="max-w-[640px] mx-auto h-[4rem] lg:h-[4.5rem] flex flex-row items-stretch shadow-[0px 2px 6px 0px rgba(0, 0, 0, 0.05)] rounded-xl mb-2 relative"
        css={[sendV3 && tw`max-w-full`]}
      >
        <SendMethodButton
          icon={<EmailIcon />}
          name="Send via email or gift link"
          shortName="Send gift"
          selected={currentGift.sendMethod === null}
          position="left"
          onClick={() => {
            setSendMethod(null)
            setLandingPage({ sendNotifs: true })
          }}
        />
        <SendMethodButton
          icon={<DirectIcon />}
          name="Ship directly to address"
          shortName="Ship directly"
          selected={currentGift.sendMethod === BatchSendMethod.direct_send}
          position="right"
          onClick={() => {
            setSendMethod(BatchSendMethod.direct_send)
          }}
        />
      </div>
    </div>
  )
}
