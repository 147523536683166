import Section, {
  SectionContainer,
  SectionContent,
  SectionHeader,
  SectionLink,
  SectionPill,
} from "./Section"
import { ReactComponent as ChevronRight } from "../../assets/icons/chevron-right.svg"
import { generateRealmPath } from "../../common/realm"
import staticAssetUrl from "../../common/staticAssetUrl"

export default function Catalog() {
  return (
    <SectionContainer>
      <Section tw="bg-[#f1f5f5]">
        <SectionPill tw="bg-[#e1eded] text-[#3e7070]">Our Catalog</SectionPill>
        <SectionHeader tw="text-[#3e7070] max-w-[580px] mx-auto">
          Access a one‑of‑a‑kind collection of products.
        </SectionHeader>
        <SectionContent>
          <p>
            Integrate Goody’s curated marketplace of 300+ trendy brands, with
            options for every price point and occasion.
          </p>
        </SectionContent>
        <div tw="flex flex-row items-center pt-10 justify-center">
          <SectionLink
            to={generateRealmPath("business", "/browse")}
            tw="bg-[#3e7070] hover:bg-[#286464] pr-3"
          >
            Our collection
            <ChevronRight tw="stroke-current opacity-75" />
          </SectionLink>
        </div>
        <div tw="pt-10 -mx-3">
          <img
            src={staticAssetUrl("static/web/landing/api/brands-catalog.png")}
            alt=""
            tw="mx-auto w-[586px]"
          />
        </div>
      </Section>
    </SectionContainer>
  )
}
