import { ReactComponent as GlobalFlag } from "@/assets/icons/global.svg"
import staticAssetUrl from "@/common/staticAssetUrl"

interface Props {
  code: string
  size?: "s" | "m" | "l"
}

export const CountryFlag = ({ code, size = "m" }: Props) => {
  if (code.toUpperCase() === "GLOBAL") {
    return <GlobalFlag tw="text-gray-400" />
  }

  const src = staticAssetUrl(`static/flags/${size}/${code}.svg`)

  return (
    <img tw="rounded-sm text-gray-400" src={src} alt={`${code} country flag`} />
  )
}
