import PurpleGradientLink, {
  PurpleGradientExternalLink,
} from "../../../common/PurpleGradientLink"
import { generateRealmPath } from "../../../common/realm"
import staticAssetUrl from "../../../common/staticAssetUrl"
import { Header, Paragraph } from "../../common/SectionComponents"

const assetRoot = "landing/how_it_works/bulk_gifting"
const TrendyBrandsImage = staticAssetUrl(`${assetRoot}/trendyBrands.png`)
const BrandedSwagImage = staticAssetUrl(`${assetRoot}/brandedSwag.png`)
const CustomBoxesImage = staticAssetUrl(`${assetRoot}/customBoxes.png`)

const MemorableGifts = () => (
  <div
    tw="px-5 pb-12 pt-12 mx-auto flex flex-col justify-center items-center"
    style={{ maxWidth: "1200px" }}
  >
    <div tw="flex flex-col lg:flex-row max-w-[580px] lg:max-w-full">
      <div tw="flex-1 mb-12 xl:pr-0">
        <div tw="lg:pr-20 xl:pr-28 text-center">
          <Header>
            Memorable gifts your
            <br />
            recipients will really love.
          </Header>
          <Paragraph tw="leading-7 mb-12">
            Every product in Goody's collection is
            <br />
            thoughtfully curated and quality-tested.
          </Paragraph>
        </div>

        <div tw="flex flex-col lg:flex-row">
          <Category
            image={TrendyBrandsImage}
            header="300+ Trendy Brands"
            subheader="Our unique collection has options for every occasion and price point."
            buttonText="Browse Gifts"
            buttonUrl={generateRealmPath("business", "/business/browse")}
            external={false}
          />
          <Category
            image={BrandedSwagImage}
            header="Branded Swag"
            subheader="Send swag in bulk, with no order minimums."
            buttonText="Learn More"
            buttonUrl={generateRealmPath(
              "business",
              "/business/how-it-works/branded-swag",
            )}
            external={false}
          />
          <Category
            image={CustomBoxesImage}
            header="Custom Boxes"
            subheader="Work with us to build the bespoke gift box of your dreams."
            buttonText="Learn More"
            buttonUrl="https://go.ongoody.com/custom-gifts/"
            external={true}
          />
        </div>

        <div tw="text-center pt-12">
          <div tw="font-bold text-xl mb-4">Not sure what to send?</div>
          <div tw="text-gray-600 text-lg mb-1">
            Set a price and let your recipient choose from Goody's entire
            collection.
          </div>
          <a
            tw="text-purple-500 font-bold text-lg"
            target="_blank"
            rel="noopener noreferrer"
            href={generateRealmPath(
              "business",
              "/business/browse/category/gift-of-choice",
            )}
          >
            Gift of Choice.
          </a>
        </div>
      </div>
    </div>
  </div>
)

interface CategoryProps {
  image: string
  header: string
  subheader: string | React.ReactNode
  buttonText: string
  buttonUrl: string
  external: boolean
}

const Category = ({
  image,
  header,
  subheader,
  buttonText,
  buttonUrl,
  external,
}: CategoryProps) => (
  <div tw="rounded-lg border border-gray-300 overflow-hidden flex-1 my-6 sm:mx-2 text-center">
    <img src={image} tw="w-full h-auto" />
    <div tw="bg-white pt-6">
      <div tw="px-6">
        <div tw="font-bold text-2xl mb-4">{header}</div>
        <div tw="text-gray-600 text-lg">{subheader}</div>
      </div>
      <div tw="mt-6 w-full">
        {external ? (
          <PurpleGradientExternalLink
            tw="lg:text-lg px-10 w-full rounded-none"
            href={buttonUrl}
            target="_blank"
          >
            {buttonText}
          </PurpleGradientExternalLink>
        ) : (
          <PurpleGradientLink
            tw="lg:text-lg px-10 w-full rounded-none"
            to={buttonUrl}
          >
            {buttonText}
          </PurpleGradientLink>
        )}
      </div>
    </div>
  </div>
)

export default MemorableGifts
