import tw from "twin.macro"

import Image from "@/common/Image"

interface Props {
  onClick: (e: React.MouseEvent<HTMLElement>) => void
  icon: string
  disabled?: boolean
}

const IconButton = ({ icon, onClick, disabled }: Props) => (
  <div
    css={[
      tw`flex p-[10px] rounded-lg border border-transparent items-center justify-center transition ease-out duration-200`,
      disabled
        ? tw`cursor-default opacity-50 cursor-not-allowed scale-100`
        : tw`cursor-pointer hover:border-primary-new-150 hover:bg-primary-000 hover:shadow-0-2-4-gray-350-10 active:scale-95`,
    ]}
    onClick={!disabled ? onClick : undefined}
    className="payment-method-button"
  >
    <Image src={icon} tw="w-5 h-5" />
  </div>
)

export default IconButton
