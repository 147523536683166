import { Custom } from "./Custom"
import { CustomProducts } from "./CustomProducts"
import CustomStores from "./CustomStores"
import { useBrowseUrls } from "../StoreHelpers"

import useStoreContext from "@/store/hooks/useStoreContext"
import { onSelectProductIDType } from "@/store/types"

interface Props {
  onSelectProductID?: onSelectProductIDType
  isEmbeddedCustomStore: boolean
}

export const CustomFeaturesPages = ({
  onSelectProductID,
  isEmbeddedCustomStore,
}: Props) => {
  const { customPath } = useBrowseUrls()
  const { backgroundLocation } = useStoreContext()
  const currentUrlPath = customPath(backgroundLocation?.pathname)

  if (isEmbeddedCustomStore) {
    return <CustomProducts />
  }

  switch (currentUrlPath) {
    case "products":
      return <CustomProducts />
    case "stores":
      return <CustomStores onSelectCustomStoreProductID={onSelectProductID} />
    default:
      return <Custom onSelectCustomStoreProductID={onSelectProductID} />
  }
}
