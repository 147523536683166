import React, { useEffect, useState } from "react"
import Modal from "react-modal"
import Tilt from "react-parallax-tilt"
import tw from "twin.macro"

import {
  AutogiftConfigSection,
  AutogiftConfigSectionContent,
  AutogiftConfigSectionHeaderDescription,
  AutogiftConfigSectionHeaderTitle,
} from "./common"
import { ReactComponent as MessageIcon } from "../../assets/icons/autogift-section-message.svg"
import { useGlobalState } from "../../common/GlobalState"
import MessageTextAreaWithPersonalizationTags from "../../common/MessageTextAreaWithPersonalizationTags"
import { modalStyle } from "../../common/modal"
import SmallButton from "../../common/SmallButton"
import { useContacts } from "../../contacts/Contacts"
import CardModal from "../../send/CardModal"

const AutogiftSelectCardAndMessage = () => {
  const [currentAutogiftRule, setCurrentAutogiftRule] = useGlobalState(
    "currentAutogiftRule",
  )

  const [autogiftRuleCardsModalOpen, setAutogiftRuleCardsModalOpen] =
    useState(false)

  const [, setSelectedRecipientIndex] = useGlobalState("sendPageRecipientIndex")

  const { contacts } = useContacts(currentAutogiftRule?.contactListId)

  // Do not maintain cached selectedRecipientIndex used for the EmailPreviewModal
  useEffect(() => {
    setSelectedRecipientIndex(0)
  }, [contacts])

  return (
    <div>
      <AutogiftConfigSection>
        <AutogiftConfigSectionHeaderTitle>
          <MessageIcon />
          Card and message
        </AutogiftConfigSectionHeaderTitle>
        <AutogiftConfigSectionHeaderDescription>
          Enter a default digital card and message. You can customize each
          individual gift up until it’s sent (we’ll remind you 1 week before and
          24 hours before so you can make edits).
        </AutogiftConfigSectionHeaderDescription>
        <AutogiftConfigSectionContent tw="flex flex-row">
          <div
            style={{ minWidth: "22.8125rem" }}
            tw="border-r border-gray-200 w-2/3"
          >
            <div tw="pt-3 pl-6 border-b border-gray-200">
              <div
                tw="text-sm text-gray-400 font-medium mb-1"
                css={{ lineHeight: "17px" }}
              >
                From name
              </div>
              <div tw="mb-3">
                <input
                  tw="w-full p-0 focus:outline-none"
                  placeholder="Enter a name"
                  value={currentAutogiftRule?.fromName || ""}
                  onChange={(e) => {
                    setCurrentAutogiftRule({
                      ...currentAutogiftRule,
                      fromName: e.target.value,
                    })
                  }}
                />
              </div>
            </div>
            <div tw="pt-3 mx-5 border-gray-200 pb-4">
              <div
                tw="text-sm text-gray-400 font-medium mb-1"
                css={{ lineHeight: "17px" }}
              >
                Message
              </div>
              <MessageTextAreaWithPersonalizationTags
                value={currentAutogiftRule?.message}
                onChange={(e) => {
                  setCurrentAutogiftRule({
                    ...currentAutogiftRule,
                    message: e.target.value,
                  })
                }}
                setMessage={(message) =>
                  setCurrentAutogiftRule((currentAutogiftRule) => ({
                    ...currentAutogiftRule,
                    message: message,
                  }))
                }
              />
            </div>
          </div>
          <div>
            <div
              style={{ width: "10.3125rem" }}
              tw="lg:w-48 flex flex-col items-center p-4"
            >
              {currentAutogiftRule.card ? (
                <div>
                  <Tilt
                    scale={1.2}
                    transitionSpeed={2500}
                    tiltMaxAngleX={15}
                    tiltMaxAngleY={15}
                    css={{
                      zIndex: 10,
                    }}
                  >
                    <img
                      src={currentAutogiftRule?.card.image}
                      css={[
                        {
                          width: 125,
                          imageRendering: "-webkit-optimize-contrast",
                        },
                        tw`rounded-lg shadow-lg`,
                      ]}
                    />
                  </Tilt>
                  <div tw="mt-2 flex-1 w-full flex flex-row items-center justify-center">
                    <SmallButton
                      onClick={() => setAutogiftRuleCardsModalOpen(true)}
                      label={"Change"}
                    />
                  </div>
                </div>
              ) : (
                <div tw="flex-1 flex flex-col items-center justify-center">
                  <button
                    tw="border border-gray-200 rounded-lg flex flex-col items-center justify-center hover:border-primary-400 transition-colors"
                    style={{
                      width: 125,
                      height: 177,
                    }}
                    onClick={() => {
                      setAutogiftRuleCardsModalOpen(true)
                    }}
                  >
                    <div tw="text-sm text-gray-600">Select card</div>
                  </button>
                </div>
              )}
            </div>
          </div>
        </AutogiftConfigSectionContent>
      </AutogiftConfigSection>
      <Modal
        isOpen={autogiftRuleCardsModalOpen}
        closeTimeoutMS={500}
        onRequestClose={() => {
          setAutogiftRuleCardsModalOpen(false)
        }}
        onAfterClose={() => {}}
        shouldCloseOnOverlayClick={true}
        style={modalStyle}
      >
        <CardModal
          onSelectCard={(id, image) => {
            setCurrentAutogiftRule({
              ...currentAutogiftRule,
              card: {
                id,
                image,
              },
            })
            setAutogiftRuleCardsModalOpen(false)
          }}
          closeModal={() => {
            setAutogiftRuleCardsModalOpen(false)
          }}
          hideCustomCardRedirect={true}
        />
      </Modal>
    </div>
  )
}

export default AutogiftSelectCardAndMessage
