import { useQuery } from "@apollo/client"
import React, { useEffect, useRef, useState } from "react"
import { useHistory } from "react-router-dom"
import tw, { styled } from "twin.macro"

import FlexGift from "./flexGift/FlexGift"
import GiftCardDenominationSelector from "./GiftCardDenominationSelector"
import GiftCardInfo from "./GiftCardInfo"
import GlobalRelayProUpsellModal from "./GlobalRelayProUpsellModal"
import { PDP_RELATED_PRODUCTS } from "./graphql"
import { useGlobalShippingUpsell } from "./hooks/useGlobalShippingUpsell"
import PDPHeader from "./PDPHeader"
import ProductAttributes from "./ProductAttributes"
import ProductBrandDetails from "./ProductBrandDetails"
import ProductDetailsHeader from "./ProductDetailsHeader"
import ProductMoreProductsFromBrand from "./ProductMoreProductsFromBrand"
import ProductVariantsDisplay from "./ProductVariantsDisplay"
import {
  DEFAULT_COUNTRY,
  useShippingCountries,
} from "../providers/ShippingCountriesProvider"

import { Bag } from "@/assets/icons"
import { ReactComponent as CheckIcon } from "@/assets/icons/check-circle.svg"
import { ReactComponent as GiftsIcon } from "@/assets/icons/gifts.svg"
import AgeVerificationModal from "@/common/AgeVerificationModal"
import AlcoholShippingInfo from "@/common/AlcoholShippingInfo"
import { clickstreamEvent } from "@/common/clickstream"
import { useCartDrawer } from "@/common/contexts/cartDrawer"
import { useGlobalState } from "@/common/GlobalState"
import GradientButton, {
  GradientLink,
  buttonStyle,
} from "@/common/GradientButton"
import { useFeatureAccess } from "@/common/hooks/featureAccess"
import { useFeatureFlags } from "@/common/hooks/featureFlags"
import { useGiftCart } from "@/common/hooks/giftData"
import { useSegmentAvailable } from "@/common/hooks/segmentAvailable"
import { generateRealmPath } from "@/common/realm"
import { WithContext, withContext } from "@/common/requestContext"
import { validateVariants } from "@/common/validateVariants"
import { useCartAdd } from "@/store/CartAddContext"
import { ShippedAndSoldByBestBuy } from "@/store/components/ecommerce_integrations/ShippedAndSoldByBestBuy"
import OfflineProductFormModal from "@/store/components/OfflineProductFormModal"
import ProductImageGallery from "@/store/components/ProductImageGallery"
import QuantitySelector from "@/store/components/QuantitySelector"
import { SegmentUnavailable } from "@/store/components/SegmentUnavailable"
import { SwagIntegrationActionsButtons } from "@/store/components/SwagIntegration"
import FlexGiftSelection from "@/store/GiftOption/FlexGiftSelection"
import {
  CART_TAGS_STORE_V2,
  ProductData,
  useBrowseUrls,
} from "@/store/StoreHelpers"
import { onSelectProductIDType } from "@/store/types"
import { useRequestInternationalProduct } from "@/store/utils"
import {
  InternationalShippingTierEnum,
  flex_gift_product_price_type,
} from "@/types/graphql-types"
import {
  Store_PDP_ProductDetailsFragment,
  Store_PDP_RelatedProductsQuery,
  Store_PDP_RelatedProductsQueryVariables,
} from "@/types/graphql-types"

export interface Props {
  product: Store_PDP_ProductDetailsFragment
  scrollToTop?: () => void
  // Override the default (setCurrentGift) for when a product is selected.
  onSelectProductID?: onSelectProductIDType
  // Method to call after selecting a product.
  onAfterSelectProduct?: () => void
  loading: boolean
  setPDPProduct: (productPreviewData: ProductData) => void
  customStoreProductButton?: (productID: string) => React.ReactElement
  customStoreGiftCardButton?: (
    productID: string,
    giftCardAmount: number | null,
  ) => React.ReactElement
  useVirtualUrl?: boolean
  onClose?: () => void
  isGiftCardInCustomStore?: (
    productID: string,
    giftCardAmount: number | null,
  ) => boolean
  isEmbeddedCustomStore?: boolean
}

// Displays inner content of the PDP, common for all PDP contexts.
const PDPContent = ({
  product,
  scrollToTop,
  onSelectProductID,
  onAfterSelectProduct,
  setPDPProduct,
  customStoreProductButton,
  customStoreGiftCardButton,
  isGiftCardInCustomStore,
  useVirtualUrl,
  onClose,
  isEmbeddedCustomStore,
}: Props) => {
  const { brand, name, productImages } = product
  const [currentRealm] = useGlobalState("currentRealm")
  const { generatePdpUrl } = useBrowseUrls()
  const { addProduct, getProductInCart } = useGiftCart()
  const [storeSelections] = useGlobalState("storeSelections")
  const { hasFeatureFlag } = useFeatureFlags()
  const { hasFeature } = useFeatureAccess()
  const hasProPlan = hasFeature("pro_plan")
  const {
    openGlobalShippingUpsellModal,
    closeGlobalShippingUpsellModal,
    checkForGlobalShipping,
    shipsToUS,
  } = useGlobalShippingUpsell()

  const { popoverShippingCountry } = useShippingCountries()

  const isGiftCard = brand.name === "Gift Cards"
  const isFlexGift = product.isFlexGift
  const isFlexGiftWithTiers =
    isFlexGift &&
    product.swapStoreSettings?.priceType ===
      flex_gift_product_price_type.TIERS &&
    product.giftCollectionTiers

  const isVariableFlexGift =
    isFlexGift &&
    product.swapStoreSettings?.priceType ===
      flex_gift_product_price_type.VARIABLE

  const { openCart } = useCartDrawer()
  const { requestAddItem } = useCartAdd()
  const history = useHistory()

  const [selectedVariants, setSelectedVariants] = useState<string[] | null>(
    null,
  )
  const [offlineProductModalOpen, setOfflineProductModalOpen] = useState(false)

  const productInCart = getProductInCart({
    productId: product.id,
    variants: selectedVariants,
  })

  // Right now we are assuming that if we're using a virtual URL, then the cart UI is not available
  const shouldUseCart = !useVirtualUrl

  const { data: relatedProductsData, refetch } = useQuery<
    Store_PDP_RelatedProductsQuery,
    WithContext<Store_PDP_RelatedProductsQueryVariables>
  >(PDP_RELATED_PRODUCTS, {
    variables: withContext(
      {
        brandSlug: product.brand.slug,
        productSlug: product.slug,
        shippingCountry: popoverShippingCountry.code,
      },
      {
        ...(isEmbeddedCustomStore && { storeType: "custom_store" }),
      },
    ),
    // Hack to fix issue where this query appears to interfere with search results
    // Not clear how to fix this without just having this not affect cache
    fetchPolicy: "no-cache",
  })

  useEffect(() => {
    refetch({
      brandSlug: product.brand.slug,
      productSlug: product.slug,
    })
  }, [refetch, product.brand.slug, product.slug])

  useEffect(() => {
    clickstreamEvent("store.product", product.id)
  }, [product.id])

  const requestInternationalProduct = useRequestInternationalProduct(
    product.shippingCountriesFinal,
  )

  const primaryGiftOption =
    relatedProductsData?.productDetails?.brand?.primaryGiftOption

  const flexGiftTierSelectedPrice = useRef<number | null>(null)

  const [giftCardDenomination, setGiftCardDenomination] = useState<
    number | null
  >(null)
  const [giftCardDenominationValid, setGiftCardDenominationValid] =
    useState<boolean>(false)

  const handleSelect = () => {
    if (onSelectProductID) {
      // If callers sent an onSelectProductID, we are probably embedded in
      // another context. We pass that context the selected product ID instead
      // of setting it on currentGift. We assume there is no flex gift price
      // since flex gifts are typically not rendered as a PDP.
      onSelectProductID(
        product.id,
        isFlexGiftWithTiers && flexGiftTierSelectedPrice.current
          ? flexGiftTierSelectedPrice.current
          : storeSelections.flexGiftPrice && !giftCardDenomination
          ? storeSelections.flexGiftPrice
          : null,
        giftCardDenomination ? giftCardDenomination * 100 : null,
      )
    } else {
      const isGiftCard =
        product.brand.primaryGiftOption?.name.startsWith("Gift Cards")
      // When no onSelectProductID is provided, set the current gift and
      // open the cart.
      const globalRelayOption = hasFeatureFlag("global_partners")
        ? InternationalShippingTierEnum.full
        : InternationalShippingTierEnum.global_relay
      const giftCardOption = hasFeatureFlag("global_partners")
        ? InternationalShippingTierEnum.standard
        : InternationalShippingTierEnum.gift_cards
      const giftCardsEnabled =
        hasProPlan && hasFeatureFlag("global_partners") && isGiftCard

      addProduct({
        product: product,
        internationalShippingTier:
          popoverShippingCountry.code !== DEFAULT_COUNTRY.code && hasProPlan
            ? isGiftCard
              ? giftCardOption
              : globalRelayOption
            : InternationalShippingTierEnum.standard,
        settings: { giftCardsEnabled },
        giftOption: product.brand.primaryGiftOption ?? undefined,
        variants: selectedVariants,
        giftCardDenomination,
        giftCardCurrency: product.giftCardInfo?.currency,
        isGiftCard,
        cartTags: [CART_TAGS_STORE_V2],
        flexGiftPrice:
          isFlexGiftWithTiers && flexGiftTierSelectedPrice.current
            ? flexGiftTierSelectedPrice.current
            : undefined,
      })

      // HACK: Variable flex gifts skip onAfterSelectProduct to avoid opening the cart
      if (isVariableFlexGift) {
        // Go directly to the Send page
        history.push(generateRealmPath("plus", "/send"))
      } else if (onAfterSelectProduct) {
        onAfterSelectProduct()
      } else {
        openCart()
      }
    }
  }

  const onRequestSelect = () => {
    const variantsValid = validateVariants(selectedVariants, product, true)
    if (!variantsValid) {
      return
    }

    const globalCheck = hasFeatureFlag("shipping_config_v2")
      ? checkForGlobalShipping(product.shippingCountryGroup)
      : !requestInternationalProduct()
    if (globalCheck) return

    if (shouldUseCart) {
      requestAddItem({
        cartEligible: product.cartEligible,
        callback: handleSelect,
        itemName: `${product.brand.name} — ${product.name}`,
        shippingCountryGroup: product.shippingCountryGroup,
      })
    } else {
      handleSelect()
    }
  }

  const handleOfflineProductSelect = () => {
    setOfflineProductModalOpen(true)
  }

  const onOfflineProductModalClose = () => {
    setOfflineProductModalOpen(false)
  }

  const isSwagTemplate = !!product.swagIntegration?.id

  // TODO when Store V2 supports custom stores
  const isCurrentGiftCardInCustomStore = isGiftCardInCustomStore
    ? isGiftCardInCustomStore(product.id, giftCardDenomination)
    : false

  const { isAvailable: segmentAvailable } = useSegmentAvailable(
    product.brand.segments,
  )

  if (isVariableFlexGift) {
    return (
      <FlexGift
        product={product}
        onClose={onClose}
        onSendGift={onRequestSelect}
      />
    )
  }

  return (
    <>
      <PDPHeader
        onClose={onClose}
        shippingCountryGroup={product.shippingCountryGroup}
      />
      <GlobalRelayProUpsellModal
        open={openGlobalShippingUpsellModal}
        onClose={closeGlobalShippingUpsellModal}
        onAddToCart={onRequestSelect}
        shipsToUS={shipsToUS}
      />

      <div tw="pb-12">
        <div tw="flex flex-col lg:flex-row pb-7 lg:pb-0 w-full">
          <div
            tw="lg:w-[450px] xl:w-[500px] 2xl:w-[600px] lg:max-w-[600px]"
            css={[
              (isGiftCard || isFlexGift) &&
                tw`p-5 lg:p-0 lg:ml-10 lg:w-[360px] xl:w-[460px] 2xl:w-[560px]`,
            ]}
          >
            <ProductImageGallery
              productName={name}
              productImages={productImages}
              isGiftCard={isGiftCard}
              isFlexGift={isFlexGift}
              scalable={product.imagesScalable}
            />
            {isGiftCard && (
              /* Desktop */
              <div tw="hidden lg:block">
                <GiftCardInfo product={product} />
              </div>
            )}
          </div>
          <ProductDetails css={[isGiftCard && tw`pt-2`]}>
            <ProductDetailsHeader
              product={product}
              primaryGiftOption={primaryGiftOption}
              isGiftCard={isGiftCard}
            />
            <ProductVariantsDisplay
              product={product}
              useVirtualUrl={useVirtualUrl}
              selectedVariants={selectedVariants}
              setSelectedVariants={setSelectedVariants}
            />
            {isGiftCard && (
              <div tw="pt-6 max-w-[450px]">
                <GiftCardDenominationSelector
                  product={product}
                  isGiftCardInCustomStore={isCurrentGiftCardInCustomStore}
                  denomination={giftCardDenomination}
                  setDenomination={setGiftCardDenomination}
                  denominationValid={giftCardDenominationValid}
                  setDenominationValid={setGiftCardDenominationValid}
                />
              </div>
            )}
            {product.isFlexGift && (
              <div tw="max-w-[500px]">
                <FlexGiftSelection
                  priceType={product.swapStoreSettings?.priceType}
                  productID={product.id}
                  productPrice={product.price}
                  customDescription={product.subtitle}
                  tiers={product.giftCollectionTiers}
                  onGiftToTier={(tier) => {
                    flexGiftTierSelectedPrice.current = tier.priceMax
                    onRequestSelect()
                  }}
                  // TODO: If Store V2 is ramped, have this open a PDP somehow
                  setPDPProduct={() => {}}
                  disableTierProductButtons={true}
                />
              </div>
            )}
            <CTAContainer>
              <div tw="flex flex-col lg:flex-row items-stretch lg:items-center mt-5">
                {isGiftCard &&
                  giftCardDenominationValid &&
                  customStoreGiftCardButton &&
                  customStoreGiftCardButton(
                    product.id,
                    giftCardDenomination ? giftCardDenomination * 100 : null,
                  )}
                {!isGiftCard &&
                  customStoreProductButton &&
                  customStoreProductButton(product.id)}
                {isSwagTemplate && (
                  <GradientLink
                    tw="flex justify-center mb-5 lg:mb-0 lg:mr-6 pl-6 pr-8"
                    to={generateRealmPath(
                      "plus",
                      `/editor/${brand.slug}/${product.slug}`,
                    )}
                  >
                    <GiftsIcon tw="mr-5" />
                    Customize
                  </GradientLink>
                )}
                {!customStoreProductButton &&
                  !isSwagTemplate &&
                  !product.isOfflineProduct &&
                  (!productInCart ? (
                    <GradientButton
                      tw="flex justify-center mb-5 lg:mb-0 lg:mr-6 pl-6 pr-8 whitespace-nowrap"
                      onClick={onRequestSelect}
                      disabled={
                        (isGiftCard && !giftCardDenominationValid) ||
                        !segmentAvailable
                      }
                    >
                      <GiftsIcon tw="mr-5" />
                      {shouldUseCart ? "Add to bag" : "Send gift"}
                    </GradientButton>
                  ) : (
                    <button
                      onClick={openCart}
                      css={[buttonStyle, "height: 49px;"]}
                      tw={
                        "rounded-xl flex flex-row items-center justify-center text-2xl font-semibold text-primary-500 bg-primary-050 px-6"
                      }
                    >
                      <Bag tw={"stroke-current mr-4"} />
                      In bag
                    </button>
                  ))}
                {product.isOfflineProduct &&
                  !customStoreProductButton &&
                  !isSwagTemplate && (
                    <GradientButton
                      tw="flex justify-center mb-5 lg:mb-0 lg:mr-6 pl-6 pr-8"
                      onClick={handleOfflineProductSelect}
                    >
                      <GiftsIcon tw="mr-5" />
                      Get a quote
                    </GradientButton>
                  )}
                {!customStoreProductButton &&
                  !product.isOfflineProduct &&
                  (!productInCart ? (
                    <Requirements>
                      <div>
                        <div tw="flex mb-1">
                          <CheckIcon tw="mr-2 text-gray-400" />
                          No address needed
                        </div>
                        {currentRealm === "consumer" && (
                          <div tw="flex">
                            <CheckIcon tw="mr-2 text-gray-400" />
                            No payment up front
                          </div>
                        )}
                      </div>
                    </Requirements>
                  ) : (
                    productInCart.cartEligible && (
                      <div
                        tw={
                          "lg:ml-8 mt-4 lg:mt-0 pointer-events-auto flex flex-row justify-center"
                        }
                      >
                        <QuantitySelector
                          product={productInCart}
                          variants={selectedVariants}
                        />
                      </div>
                    )
                  ))}
                {!customStoreProductButton && product.isOfflineProduct && (
                  <Requirements>
                    <div>
                      <div tw="flex mb-1">
                        <CheckIcon tw="mr-2 text-gray-400" />
                        Warehousing available
                      </div>
                    </div>
                  </Requirements>
                )}
              </div>
              <ShippedAndSoldByBestBuy product={product} />
              {!customStoreProductButton && (
                <SwagIntegrationActionsButtons
                  product={product}
                  spaceSize="small"
                  justify="start"
                />
              )}
            </CTAContainer>
            <SegmentUnavailable segments={product.brand.segments} tw={"mt-6"} />
            {product.isAlcohol && product.shippingStates && (
              <AlcoholShippingInfo shippingStates={product.shippingStates} />
            )}
            {product?.productAttributes && (
              <ProductAttributes
                productAttributes={product?.productAttributes ?? []}
              />
            )}
            {isGiftCard && (
              /* Mobile */
              <div tw="lg:hidden">
                <GiftCardInfo product={product} />
              </div>
            )}
          </ProductDetails>
        </div>
        {!isGiftCard && !isFlexGift && (
          <>
            <ProductBrandDetails
              brand={product.brand}
              primaryGiftOption={primaryGiftOption}
            />
            <ProductMoreProductsFromBrand
              product={product}
              primaryGiftOption={primaryGiftOption}
              generatePdpUrl={generatePdpUrl}
              scrollToTop={scrollToTop}
              setPDPProduct={setPDPProduct}
            />
          </>
        )}
      </div>
      <AgeVerificationModal verifyAge={product.isAlcohol} />
      <OfflineProductFormModal
        modalOpen={offlineProductModalOpen}
        onClose={onOfflineProductModalClose}
        product={product}
        selectedVariants={selectedVariants}
      />
    </>
  )
}

const ProductDetails = tw.div`flex-1 pb-5 px-5 lg:px-12 pt-5 xl:pt-10`

const CTAContainer = tw.div`pt-6`

const Requirements = styled.div`
  ${tw`flex flex-col items-center relative text-gray-600 top-1 font-text`};
  font-size: 15px;
  line-height: 18px;
`

export default PDPContent
