import { gql } from "@apollo/client"

export const DELETE_SWAG_PRODUCT_MUTATION = gql`
  mutation DeleteSwagProduct($productId: ID!) {
    deleteSwagProduct(productId: $productId) {
      ok
      error
    }
  }
`
