import { formatPrice } from "../../common/format"

import { BalanceGiftBatch } from "@/types/graphql-types"

interface Props {
  balanceGiftBatch: BalanceGiftBatch
}

export default function ActivityGiftBatchFinalized({
  balanceGiftBatch,
}: Props) {
  if (balanceGiftBatch.acceptedGiftsCount > 0) {
    return (
      <div>
        <div tw="text-xl text-right tabular-nums">
          {formatPrice(balanceGiftBatch.acceptedAmount, true)}
        </div>
        <div tw="text-right text-gray-450">
          {balanceGiftBatch.acceptedGiftsCount} accepted
        </div>
      </div>
    )
  }

  return (
    <div tw="self-center">
      <div tw="text-right text-xl text-gray-300">&mdash;</div>
    </div>
  )
}
