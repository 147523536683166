import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import React from "react"
import tw, { styled } from "twin.macro"

import ChevronDown from "../../common/images/chevron-down.png"

interface FAQAccordionProps {
  sections: { question: string; answer: string | React.ReactNode }[]
}

const FAQAccordion = ({ sections }: FAQAccordionProps) => {
  return (
    <div tw="pt-16 mx-auto" style={{ maxWidth: "950px" }}>
      <h2 tw="text-2xl sm:text-4xl text-center font-medium mb-8 lg:mb-16">
        Frequently Asked Questions
      </h2>
      {sections.map(({ question, answer }) => (
        <AccordionSection question={question} answer={answer} key={question} />
      ))}
    </div>
  )
}

interface AccordionSectionProps {
  question: string
  answer: string | React.ReactNode
}

const AccordionSection = ({ question, answer }: AccordionSectionProps) => (
  <StyledAccordion
    disableGutters={true}
    elevation={0}
    square={true}
    sx={{ borderRadius: "0.75rem", "&:before": { display: "none" } }}
  >
    <AccordionSummary
      tw="bg-white"
      expandIcon={<img tw="w-5 h-5" src={ChevronDown} />}
    >
      <p tw="text-lg font-medium px-3 py-2">{question}</p>
    </AccordionSummary>
    <AccordionDetails sx={{ paddingTop: 0 }}>{answer}</AccordionDetails>
  </StyledAccordion>
)

const StyledAccordion = styled(Accordion)`
  ${tw`mb-4 hover:text-gray-600 bg-white font-text`}

  .MuiAccordionSummary-expandIconWrapper {
    ${tw`flex-shrink-0`}
  }

  .MuiAccordionDetails-root p {
    ${tw`px-3 text-lg pb-4`}
  }
`

export const PurpleLink = ({
  href,
  children,
}: {
  href: string
  children: React.ReactNode
}) => {
  return (
    <a
      tw="text-purple-500 font-medium"
      target="_blank"
      rel="noopener noreferrer"
      href={href}
    >
      {children}
    </a>
  )
}

export default FAQAccordion
