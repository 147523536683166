import { gql, useMutation } from "@apollo/client"
import React, { useState } from "react"

import { useScreenIfVisible, useSegmentPrefix } from "../common/analytics"
import AddPaymentMethodForm from "../common/billing/AddPaymentMethodForm"
import useAddPaymentMethod from "../common/billing/useAddPaymentMethod"
import { successToast } from "../common/toast"
import { SubmitButton } from "../common/UI"

import {
  AddPaymentMethod_CreatePaymentMethodMutation,
  AddPaymentMethod_CreatePaymentMethodMutationVariables,
} from "@/types/graphql-types"

interface Props {
  isOpen: boolean
  onComplete: () => void
}

const AddPaymentMethodModal = ({ isOpen, onComplete }: Props) => {
  const segmentPrefix = useSegmentPrefix()
  useScreenIfVisible(
    `${segmentPrefix}Payment Selector - Add Payment Method Modal`,
    isOpen,
  )

  const [createPaymentMethod] = useMutation<
    AddPaymentMethod_CreatePaymentMethodMutation,
    AddPaymentMethod_CreatePaymentMethodMutationVariables
  >(CREATE_PAYMENT_METHOD_MUTATION)

  const [addCardLoading, setAddCardLoading] = useState(false)

  const { formFields, setFormField, setFormFields, preSubmit } =
    useAddPaymentMethod()

  const handleSubmit = async (e: any) => {
    e.preventDefault()

    setAddCardLoading(true)

    const interimCardToken = await preSubmit()

    if (interimCardToken) {
      // Turn this interim card token to a payment method
      const graphqlResponse = await createPaymentMethod({
        variables: {
          cardInput: {
            interimCardToken,
            address1: formFields.address1,
            address2: formFields.address2,
            addressCity: formFields.city,
            addressState: formFields.state,
            addressPostalCode: formFields.postalCode,
            cardholderName: formFields.name,
          },
        },
      })

      const data = graphqlResponse?.data?.paymentMethodCreate

      if (data?.ok) {
        successToast("Added payment method.")
        onComplete()
      } else {
        if (data?.error) {
          alert(data.error)
        } else {
          alert("Sorry, an unexpected error occurred.")
        }
      }

      setAddCardLoading(false)
    } else {
      alert("Error saving card.")
      setAddCardLoading(false)
    }
  }

  return (
    <div className="modal-content modal-content-wide">
      <form tw="p-6" id="credit-card-form">
        <div tw="text-2xl font-medium text-primary-500 pb-6">
          Add payment card
        </div>
        <AddPaymentMethodForm
          formFields={formFields}
          setFormField={setFormField}
          setFormFields={setFormFields}
        />
        <div tw="h-6" />
        <SubmitButton
          className="primary"
          onClick={(e) => handleSubmit(e)}
          disabled={addCardLoading}
        >
          <div>Add payment card</div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#ffffff"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-arrow-right"
          >
            <line x1="5" y1="12" x2="19" y2="12" />
            <polyline points="12 5 19 12 12 19" />
          </svg>
        </SubmitButton>
      </form>
    </div>
  )
}

const CREATE_PAYMENT_METHOD_MUTATION = gql`
  mutation AddPaymentMethod_CreatePaymentMethod(
    $cardInput: PaymentMethodCreditCardInput!
  ) {
    paymentMethodCreate(cardInput: $cardInput) {
      ok
      error
    }
  }
`

export default AddPaymentMethodModal
