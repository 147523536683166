import React from "react"
import tw from "twin.macro"

import DirectSendRecipientErrors from "./DirectSendRecipientErrors"
import OnlyChargedForAcceptedGiftsNotice from "./OnlyChargedForAcceptedGiftsNotice"
import PriceEstimateTable from "./PriceEstimateTable"
import SendSummaryBalance from "./SendSummaryBalance"
import ApplyCreditButton from "../../common/ApplyCreditButton"
import { formatPriceOrNull } from "../../common/format"
import { useCurrentGift } from "../../common/hooks/currentGift"
import { BALANCE_PAYMENT_METHOD_ID } from "../../common/hooks/payment"

import { GiftPreviewButton } from "@/send/components/giftPreview/GiftPreviewButton"
import { useGiftPreview } from "@/send/hooks/giftPreview"
import { BatchSendMethod } from "@/types/graphql-types"
import { Send_PriceEstimateMutation } from "@/types/graphql-types"

interface Props {
  priceEstimate: Send_PriceEstimateMutation["priceEstimate"]
  isAlcohol?: boolean
  hideTax: boolean
  runPriceEstimate: (calculatedTaxEstimate?: boolean) => void
  priceEstimateLoading?: boolean
  priceEstimateIsCalculated?: boolean
  sendV3?: boolean
}

export default function SendSummary({
  priceEstimate,
  isAlcohol,
  hideTax,
  runPriceEstimate,
  priceEstimateLoading,
  priceEstimateIsCalculated,
  sendV3,
}: Props) {
  const [currentGift] = useCurrentGift()
  const { previewVisible } = useGiftPreview()

  const priceEstimateTable = (
    <PriceEstimateTable
      isAlcohol={isAlcohol}
      isPlus={true}
      priceEstimate={priceEstimate}
      hideTax={hideTax}
      runPriceEstimate={runPriceEstimate}
      priceEstimateLoading={priceEstimateLoading}
      priceEstimateIsCalculated={priceEstimateIsCalculated}
      sendV3={sendV3}
    />
  )

  const creditButton = (
    <div tw="w-full first:w-full mt-6">
      {(priceEstimate.totalPriceEstimate.creditAvailableToApply ?? 0) > 0 ? (
        <ApplyCreditButton
          creditOmitted={priceEstimate.totalPriceEstimate.creditApplied === 0}
          amount={formatPriceOrNull(
            priceEstimate.totalPriceEstimate.creditAvailableToApply ?? null,
          )}
          isBusinessSend={true}
        />
      ) : null}
    </div>
  )

  const giftPreviewButton = previewVisible && (
    <div tw="flex flex-row justify-center mt-6">
      <GiftPreviewButton location="Sidebar" />
    </div>
  )

  const directSendRecipientErrors = (
    <DirectSendRecipientErrors
      priceEstimate={priceEstimate}
      isDirectSend={currentGift.sendMethod === BatchSendMethod.direct_send}
      runPriceEstimate={runPriceEstimate}
      priceEstimateLoading={priceEstimateLoading}
      priceEstimateIsCalculated={priceEstimateIsCalculated}
    />
  )

  if (currentGift.autopayPaymentMethodID !== BALANCE_PAYMENT_METHOD_ID) {
    return (
      <div
        tw="lg:w-1/2 lg:px-4 mx-auto"
        css={[sendV3 && tw`lg:w-full w-full lg:px-0 text-[15px]`]}
      >
        {priceEstimateTable}
        {creditButton}
        {directSendRecipientErrors}
        {giftPreviewButton}
        <OnlyChargedForAcceptedGiftsNotice tw="flex-col" />
      </div>
    )
  }

  return (
    <div
      tw="flex flex-col items-center md:(flex-row border border-gray-150 rounded-xl shadow-min)"
      css={[sendV3 && tw`md:(flex-col border-none shadow-none) text-[15px]`]}
    >
      <div tw="flex-1 md:p-9" css={[sendV3 && tw`w-full md:p-0`]}>
        {priceEstimateTable}
        {creditButton}
        {directSendRecipientErrors}
        {giftPreviewButton}
        <OnlyChargedForAcceptedGiftsNotice tw="flex-col" />
      </div>
      {!sendV3 && (
        <div tw="md:border-r md:border-gray-150 self-stretch h-8 md:h-auto" />
      )}
      <SendSummaryBalance
        isAlcohol={isAlcohol}
        priceEstimate={priceEstimate}
        sendV3={sendV3}
      />
    </div>
  )
}
