import React from "react"
import tw, { styled } from "twin.macro"

import deliveryDelivered from "../../assets/icons/delivery-delivered.svg"
import deliveryPreparing from "../../assets/icons/delivery-preparing.svg"
import deliveryShipped from "../../assets/icons/delivery-shipped.svg"
import deliveredChevronMask from "../../assets/images/delivered-chevron-mask.png"
import preparingChevronMask from "../../assets/images/preparing-chevron-mask.png"

export interface DeliveryStats {
  preparing: number
  shipped: number
  delivered: number
}

interface Props {
  stats: DeliveryStats
}

// See AcceptanceChart for details on how this works.
const DeliveryChart: React.FC<Props> = ({ stats }) => {
  const { preparing, shipped, delivered } = stats

  // Uncomment for testing (and comment the above).
  // const preparing = 100;
  // const shipped = 0;
  // const delivered = 5;

  const total = preparing + shipped + delivered
  const firstHalfIsLargest = preparing > shipped + delivered

  return (
    <>
      <div css={[tw`flex flex-row overflow-hidden rounded-lg`]}>
        {preparing > 0 && (
          <PreparingSegment
            css={[
              tw`relative flex flex-row items-center`,
              {
                width: `${(preparing * 100) / total}%`,
                height: 40,
              },
            ]}
          >
            {preparing / total > 0.06 && (
              <div tw="px-3">
                <img src={deliveryPreparing} alt="Preparing" />
              </div>
            )}
          </PreparingSegment>
        )}
        <ShippedSegment
          css={[
            tw`overflow-hidden relative flex flex-row items-center`,
            {
              width: `${(shipped * 100) / total}%`,
              height: 40,
            },
          ]}
        >
          {shipped / total > 0.06 && (
            <div tw="px-3 pl-5">
              <img src={deliveryShipped} alt="Shipped" />
            </div>
          )}
        </ShippedSegment>
        {delivered > 0 && (
          <DeliveredSegment
            css={[
              tw`relative flex flex-row items-center justify-end`,
              {
                width: `${(delivered * 100) / total}%`,
                height: 40,
              },
            ]}
          >
            {delivered / total > 0.06 && (
              <div tw="px-3 text-white text-right">
                <img src={deliveryDelivered} alt="Delivered" />
              </div>
            )}
          </DeliveredSegment>
        )}
      </div>
      <div tw="pt-3 flex flex-row items-start">
        <div
          className="animate__animated animate__fadeInTopAbsolute"
          css={[
            tw`whitespace-nowrap text-green-500 text-left`,
            {
              animationDelay: "0.15s",
            },
            !firstHalfIsLargest && {
              minWidth: `${(preparing * 100) / total}%`,
            },
            firstHalfIsLargest && tw`flex-1`,
          ]}
        >
          <div tw="text-sm md:text-base pr-2" css={{ color: "#d28484" }}>
            {preparing} Preparing
          </div>
        </div>
        <div
          css={[
            tw`flex flex-row items-center`,
            firstHalfIsLargest && {
              minWidth: `${((shipped + delivered) * 100) / total}%`,
            },
            !firstHalfIsLargest && tw`flex-1`,
          ]}
        >
          <div
            className="animate__animated animate__fadeInTopAbsolute"
            css={[
              tw`whitespace-nowrap text-gray-400 text-left`,
              {
                animationDelay: "0.30s",
              },
              delivered >= shipped && {
                minWidth: `${(shipped * 100) / (delivered + shipped)}%`,
              },
              delivered < shipped && tw`flex-1`,
            ]}
          >
            <div tw="text-sm md:text-base pl-5 pr-2" css={{ color: "#af50a7" }}>
              {shipped} Shipped
            </div>
          </div>
          <div
            className="animate__animated animate__fadeInTopAbsolute"
            css={[
              tw`whitespace-nowrap text-gray-400 text-right`,
              {
                animationDelay: "0.45s",
              },
              delivered < shipped && {
                minWidth: `${(delivered * 100) / (delivered + shipped)}%`,
              },
              delivered >= shipped && tw`flex-1`,
            ]}
          >
            <div tw="text-sm md:text-base text-primary-700">
              {delivered} Delivered
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const PreparingSegment = styled.div`
  ${tw`relative`}
  background-color: #f0d6d6;
  z-index: 20;

  // This chevron appears off the right side of the segment to extend the color
  // of the segment over the shipped segment.
  &:after {
    content: "";
    background-image: url(${preparingChevronMask});
    background-repeat: no-repeat;
    position: absolute;
    right: -10px;
    top: 0;
    width: 10px;
    height: 40px;
  }
`

const ShippedSegment = styled.div`
  background: linear-gradient(90deg, #e4a1b8 20%, #b37dd5 100%);
  z-index: 10;
`

const DeliveredSegment = styled.div`
  ${tw`bg-primary-700`}
  z-index: 20;

  // This (inverted) appears off the left side of the segment to extend the
  // color of this segment over the shipped segment.
  &:before {
    content: "";
    background-image: url(${deliveredChevronMask});
    background-repeat: no-repeat;
    position: absolute;
    left: -10px;
    top: 0;
    width: 10px;
    height: 40px;
  }
`

export default DeliveryChart
