import React from "react"
import tw, { styled } from "twin.macro"

import { formatPrice } from "../../common/format"

interface Props {
  selectedPlan: any
  alternateIntervalPlan: any
  toggleInterval: any
}

const TeamsPlanSelector = ({
  selectedPlan,
  alternateIntervalPlan,
  toggleInterval,
}: Props) => {
  return (
    <div tw="relative mt-8 rounded-full bg-gray-100 flex flex-row items-stretch overflow-hidden">
      <IntervalSwitchButton
        tw="flex-1"
        selected={selectedPlan.interval === "yearly"}
        type="button"
        onClick={() => {
          if (selectedPlan.interval !== "yearly") {
            toggleInterval()
          }
        }}
      >
        Yearly –{" "}
        {selectedPlan.interval === "yearly"
          ? formatPrice(selectedPlan.monthlyPrice)
          : alternateIntervalPlan &&
            formatPrice(alternateIntervalPlan.monthlyPrice)}
        /month
      </IntervalSwitchButton>
      <IntervalSwitchButton
        tw="flex-1"
        selected={selectedPlan.interval === "monthly"}
        type="button"
        onClick={() => {
          if (selectedPlan.interval !== "monthly") {
            toggleInterval()
          }
        }}
      >
        Monthly –{" "}
        {selectedPlan.interval === "monthly"
          ? formatPrice(selectedPlan.monthlyPrice)
          : alternateIntervalPlan &&
            formatPrice(alternateIntervalPlan.monthlyPrice)}
        /month
      </IntervalSwitchButton>
      <IntervalSwitchBackground
        position={selectedPlan.interval === "yearly" ? 0 : 1}
      />
    </div>
  )
}

interface IntervalSwitchButtonProps {
  selected: boolean
}

const IntervalSwitchButton = styled.button<IntervalSwitchButtonProps>`
  ${tw`text-gray-500 py-3 z-10 hover:text-gray-700 transition-colors`}
  ${(props) => props.selected && tw`font-medium text-white hover:text-white`}
`

const IntervalSwitchBackground = styled.div<{ position: number }>`
  ${tw`bg-gradient-to-r from-gradient-updated-purple-medium to-gradient-updated-blue-medium absolute top-0 bottom-0 w-1/2 transition-all rounded-full`};
  z-index: 0;

  ${(props) =>
    props.position === 0 ? tw`translate-x-0` : tw`translate-x-full`}
`

export default TeamsPlanSelector
