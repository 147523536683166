import React, { ReactElement, useRef, useState } from "react"
import { useHistory } from "react-router-dom"
import tw, { css, styled } from "twin.macro"

import HeaderInternalLink from "./HeaderInternalLink"
import { useWindowSize } from "../../../common/hooks/useWindowSize"
import Chevron from "../images/chevron.svg"

interface DropdownMenuProps {
  name?: string
  content: ReactElement
  overrideTriggerElement?: ReactElement
  link?: string
}

const DropdownMenuItem: React.FC<DropdownMenuProps> = ({
  name,
  content,
  overrideTriggerElement,
  link,
}) => {
  if (overrideTriggerElement && name) {
    console.error(
      "Name and link will not be used if overrideTriggerElement is present",
    )
  }

  const [isResourcesOpen, setIsResourcesOpen] = useState(false)
  const windowSize = useWindowSize()
  const isMobile = windowSize.lessThen("lg")
  const history = useHistory()

  const desktopSetOpen = (value: boolean) =>
    !isMobile && setIsResourcesOpen(value)

  return (
    <div
      role="dropdown-header-menu"
      tw="relative flex flex-col lg:block"
      onClick={() => {
        if (!link || isMobile) {
          setIsResourcesOpen((prev) => !prev)
        }
      }}
      onMouseEnter={() => desktopSetOpen(true)}
      onMouseLeave={() => desktopSetOpen(false)}
    >
      {overrideTriggerElement ? (
        overrideTriggerElement
      ) : (
        <HeaderInternalLink
          as="button"
          tw="flex flex-row items-center lg:h-[4rem]"
          onClick={(e: React.MouseEvent) => {
            if (link && !isMobile) {
              e.preventDefault()
              history.push(link)
            }
          }}
        >
          <PageName>{name}</PageName>
          <div tw={"flex justify-end relative lg:absolute"}>
            <ChevronIcon
              tw="lg:hidden mr-2"
              src={Chevron}
              isOpen={isResourcesOpen}
            />
          </div>
        </HeaderInternalLink>
      )}
      <DropdownContainer
        isOpen={isResourcesOpen}
        setIsResourcesOpen={desktopSetOpen}
      >
        {content}
      </DropdownContainer>
    </div>
  )
}

const PageName = tw.div`text-xl transition-colors lg:text-[16px] xl:text-[17px] justify-start flex whitespace-nowrap`
const ChevronIcon = styled.img<{ isOpen: boolean }>`
  ${tw`lg:hidden transition-transform`}

  ${({ isOpen }) => !isOpen && `transform: scaleY(-1)`}
`

interface DropdownContainerProps {
  isOpen: boolean
  setIsResourcesOpen: (value: boolean) => void
}

export const DropdownContainer: React.FC<DropdownContainerProps> = ({
  isOpen,
  setIsResourcesOpen,
  children,
}) => {
  const contentSpace = useRef<HTMLDivElement>(null)
  const maxHeight = isOpen ? contentSpace.current?.scrollHeight ?? 0 : 0

  const windowSize = useWindowSize()
  const isMobile = windowSize.lessThen("lg")

  return (
    <ContainerPosition>
      <DropdownOuterContainer
        role="dropdown-header-content"
        ref={contentSpace}
        style={{ maxHeight: `${maxHeight}px` }}
        css={[
          !isMobile && isOpen && FadeInAnimation,
          isOpen ? tw`lg:block` : tw`lg:hidden`,
        ]}
      >
        <DropdownInnerContainer onMouseLeave={() => setIsResourcesOpen(false)}>
          {children}
        </DropdownInnerContainer>
      </DropdownOuterContainer>
    </ContainerPosition>
  )
}

const ContainerPosition = tw.div`
  lg:(
    absolute z-10
    w-max left-1/2 -translate-x-2/4
  )
`

const DropdownOuterContainer = tw.div`
  -mt-px lg:mt-0
  bg-light-grey w-full overflow-hidden
  duration-300 ease-in-out origin-top
  transition-[max-height]

  lg:(
    overflow-visible
    bg-white text-left text-black

    rounded-2xl	border border-solid border-[#ebecee]
    shadow-[0px 42px 44px rgba(20, 0, 0, 0.14)]

    transition-transform !max-h-[max-content]
  )
`
const FadeInAnimation = css`
  animation: fadeIn 0.3s ease;

  @keyframes fadeIn {
    from {
      transform: scale(0.9);
      opacity: 0;
    }

    to {
      transform: scale(1);
      opacity: 1;
    }
  }
`

const DropdownInnerContainer = tw.div`
  lg:(
    relative
    before:(
      absolute top-0 bg-white content-[""]
      -top-px	left-1/2 h-4 w-4
      border-t border-l border-solid border-[#ebecee]
      rotate-45	-translate-y-1/2 -translate-x-1/2
    )
  )
`

export default DropdownMenuItem
