import { useMutation } from "@apollo/client"
import { useEffect, useState } from "react"
import { Redirect, useParams } from "react-router-dom"

import { generateRealmPath } from "@/common/realm"
import { errorToast, successToast } from "@/common/toast"
import { Loader } from "@/common/UI"
import { GIFT_USER_APPROVAL_MUTATION } from "@/track/queries"
import {
  Gift_UserApprovalMutation,
  Gift_UserApprovalMutationVariables,
} from "@/types/graphql-types"

interface Params {
  giftBatchId: string
  giftId: string
  action: string
}

const ACTIONS = ["approve", "reject"]

const SmartLinkApproval = () => {
  const { giftBatchId, giftId, action } = useParams<Params>()
  const approved = action === "approve"
  const actionValid = ACTIONS.includes(action)
  const [success, setSuccess] = useState<boolean | undefined>()

  const [giftUserApproval] = useMutation<
    Gift_UserApprovalMutation,
    Gift_UserApprovalMutationVariables
  >(GIFT_USER_APPROVAL_MUTATION)

  const submitGiftApproval = async (approved: boolean) => {
    const res = await giftUserApproval({ variables: { id: giftId, approved } })
    setSuccess(res.data?.giftUserApproval?.ok)
    if (res.data?.giftUserApproval?.ok) {
      if (approved) {
        successToast("Gift approved")
      } else {
        errorToast("Gift rejected")
      }
    }
  }

  useEffect(() => {
    if (actionValid) {
      submitGiftApproval(approved)
    }
  }, [])

  if (!actionValid) {
    return (
      <div tw="top-0 left-0 right-0 bottom-0 flex items-center justify-center w-full flex-grow min-h-[calc(100vh - 12rem)] px-4">
        <div>
          This link is invalid. Check that it was copied correctly. If you need
          further assistance please{" "}
          <a
            href={`mailto:support@ongoody.com?subject=Issue with approval flow for gift ${giftId}`}
            tw="underline"
          >
            contact support
          </a>
          .
        </div>
      </div>
    )
  }

  if (success === true) {
    return (
      <Redirect
        to={generateRealmPath("plus", `/track/${giftBatchId}/recipients`)}
      />
    )
  } else if (success === false) {
    const action_adjective = approved ? "approving" : "rejecting"
    return (
      <div tw="top-0 left-0 right-0 bottom-0 flex items-center justify-center w-full flex-grow min-h-[calc(100vh - 12rem)]">
        Whoops, something went wrong {action_adjective} this gift. Please{" "}
        <a
          href={`mailto:support@ongoody.com?subject=Issue ${action_adjective} gift ${giftId}`}
          tw="mx-1 underline"
        >
          contact support
        </a>{" "}
        for assistance.
      </div>
    )
  }

  return (
    <div tw="top-0 left-0 right-0 bottom-0 flex items-center justify-center w-full flex-grow min-h-[calc(100vh - 12rem)]">
      <Loader />
    </div>
  )
}

export default SmartLinkApproval
