import { gql, useMutation } from "@apollo/client"
import React, { useState } from "react"
import { toast } from "react-hot-toast"
import tw, { styled } from "twin.macro"

import {
  AUTOGIFTS_QUERY,
  STANDARD_AUTOGIFT_RULE_FRAGMENT,
} from "./AutogiftIndex"
import { getDerivedTenureRange } from "./common"
import { useAutogiftData } from "./hooks"
import { ReactComponent as AutogiftIcon } from "../../assets/icons/autogift-white.svg"
import AgeVerificationModal, {
  ageAttested,
} from "../../common/AgeVerificationModal"
import { useGlobalState } from "../../common/GlobalState"
import GradientButton from "../../common/GradientButton"

import { AutogiftRuleStatus, GiftSwapTypeEnum } from "@/types/graphql-types"
import {
  AutogiftRuleProduct,
  AutogiftRule_AutogiftRuleUpdateMutation,
  AutogiftRule_AutogiftRuleUpdateMutationVariables,
} from "@/types/graphql-types"

interface AutogiftActionsProps {
  closeAutogiftRuleConfigScreen: () => void
}

const AutogiftActions: React.FC<AutogiftActionsProps> = ({
  closeAutogiftRuleConfigScreen,
}) => {
  const [currentAutogiftRule] = useGlobalState("currentAutogiftRule")
  const [displayAgeVerification, setDisplayAgeVerification] = useState(false)

  const [autogiftRuleUpdate, { loading }] = useMutation<
    AutogiftRule_AutogiftRuleUpdateMutation,
    AutogiftRule_AutogiftRuleUpdateMutationVariables
  >(UPDATE_AUTOGIFT_RULE_MUTATION)

  const { loadAutogiftRuleData } = useAutogiftData({})

  // Run a autogift rule mutation depending on the id and action
  const processAutogiftRule = async (activate: boolean) => {
    const { min, max } = getDerivedTenureRange(currentAutogiftRule)

    const update = autogiftRuleUpdate({
      variables: {
        autogiftRule: {
          id: currentAutogiftRule.id,
          contactListId: currentAutogiftRule.contactListId,
          eventType: currentAutogiftRule.eventType,
          expireAtOption: currentAutogiftRule.expireAtOption,
          productList: formatProductList(currentAutogiftRule.productList),
          cardId: currentAutogiftRule?.card?.id,
          fromName: currentAutogiftRule.fromName,
          message: currentAutogiftRule.message,
          swapEnabled:
            currentAutogiftRule.swapType !== GiftSwapTypeEnum.swap_disabled,
          alcoholAgeVerificationAttested: ageAttested(),
          autopayPaymentMethodId: currentAutogiftRule.autopayPaymentMethodID,
          autopayPaymentMethodName:
            currentAutogiftRule.autopayPaymentMethodName,
          maximumProductPrice: currentAutogiftRule.maximumProductPrice,
          tenureMin: min,
          tenureMax: max,
          anchorDateType: currentAutogiftRule.anchorDateType,
          anchorDateSendOption: currentAutogiftRule.anchorDateSendOption,
          anchorDateNumberOfDaysDelta:
            currentAutogiftRule.anchorDateNumberOfDaysDelta,
          internationalShippingTier:
            currentAutogiftRule.internationalShippingTier,
          settings: currentAutogiftRule.settings,
          swapType: currentAutogiftRule.swapType,
        },
        activate: activate,
      },
      refetchQueries: [
        {
          query: AUTOGIFTS_QUERY,
          variables: {
            contactListId: currentAutogiftRule.contactListId,
          },
        },
      ],
    })

    const updateWithToast = toast.promise(update, {
      loading: "Saving...",
      success: "Autogift saved",
      error: "Error saving autogift",
    })

    const autogiftRule = await updateWithToast

    // Check that the mutation completed successfully before proceeding
    if (!autogiftRule?.data?.autogiftRuleUpdate) {
      alert("An error occurred during update.")
      return
    }
    const res = autogiftRule.data.autogiftRuleUpdate

    if (res && res.ok) {
      // HACK: This scrolls all mounted MuiPapers
      const papers = document.querySelectorAll(".MuiPaper-root")
      for (let i = 0; i < papers.length; i++) {
        papers[i].scrollTo({
          top: 0,
          behavior: "smooth",
        })
      }

      // Restore the returned autogift rule so we have the latest data
      if (res.autogiftRule) {
        loadAutogiftRuleData(res.autogiftRule)
      }
    } else {
      if (res && res.errors && res.errors.length > 0) {
        if (
          res.errors.includes(
            "You have not yet attested to being over 21 years of age.",
          )
        ) {
          setDisplayAgeVerification(true)
        }
        alert(res.errors.join("\n"))
      }
    }
  }

  // Format product list for mutation by filtering the required keys for the price estimate
  // This is needed since the price estimates saved in global state have additional "typename"
  // keys that will fail the TS validation
  const formatProductList = (productList: AutogiftRuleProduct[]) => {
    return productList.map((product) => {
      const priceEstimateFiltered = {
        priceProduct: product.priceEstimate.priceProduct,
        priceShipping: product.priceEstimate.priceShipping,
        priceProcessingFee: product.priceEstimate.priceProcessingFee,
        priceEstTaxLow: product.priceEstimate.priceEstTaxLow,
        priceEstTaxHigh: product.priceEstimate.priceEstTaxHigh,
        priceEstTotalLow: product.priceEstimate.priceEstTotalLow,
        priceEstTotalHigh: product.priceEstimate.priceEstTotalHigh,
        isFlexGift: product.priceEstimate.isFlexGift,
      }

      return {
        productId: product.productId,
        priceEstimate: priceEstimateFiltered,
      }
    })
  }

  return (
    <div>
      {displayAgeVerification && <AgeVerificationModal verifyAge={true} />}
      <div
        css={{
          width: 530,
          paddingLeft: 60,
          paddingRight: 60,
        }}
      >
        <ActivateButton
          onClick={() => {
            processAutogiftRule(true)
          }}
          disabled={loading}
        >
          <div tw="flex items-center mr-3">
            <AutogiftIcon />
          </div>
          <div
            tw="flex items-center text-lg text-white font-medium"
            css={{ lineHeight: "130%", letterSpacing: "-0.3px" }}
          >
            {currentAutogiftRule.status.toLocaleLowerCase() ===
            AutogiftRuleStatus.PAUSED.toLocaleLowerCase()
              ? "Activate Autogift"
              : "Save Autogift"}
          </div>
        </ActivateButton>
        {currentAutogiftRule.status.toLocaleLowerCase() ===
          AutogiftRuleStatus.PAUSED.toLocaleLowerCase() && (
          <button
            tw="w-full transition-colors bg-gray-100 hover:bg-gray-150 focus:outline-none focus-visible:bg-gray-200 active:bg-gray-200 rounded-xl p-3 text-gray-500 text-center"
            css={{ lineHeight: "130%" }}
            onClick={() => {
              processAutogiftRule(false)
            }}
          >
            Save draft but don’t activate autogift
          </button>
        )}
      </div>
    </div>
  )
}

const ActivateButton = styled(GradientButton)`
  ${tw`flex justify-center items-center rounded-xl py-4 mb-3 w-full`};
`

const UPDATE_AUTOGIFT_RULE_MUTATION = gql`
  mutation AutogiftRule_AutogiftRuleUpdate(
    $autogiftRule: AutogiftRuleInput!
    $activate: Boolean!
  ) {
    autogiftRuleUpdate(autogiftRule: $autogiftRule, activate: $activate) {
      ok
      errors
      id
      autogiftRule {
        id
        ...StandardAutogiftRule
      }
    }
  }
  ${STANDARD_AUTOGIFT_RULE_FRAGMENT}
`

export default AutogiftActions
