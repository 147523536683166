import { Link } from "react-router-dom"

import CountriesUndelineText from "./components/CountriesUndelineText"
import GlobalProductsHeader from "./components/GlobalProductsHeader"

// import { useFeatureAccess } from "@/common/hooks/featureAccess";
import DialogModal from "@/common/DialogModal"
import { generateRealmPath } from "@/common/realm"

interface Props {
  open: boolean
  onClose: () => void
  withProUpsell: boolean
}

const GlobalShippingModal = ({ open, onClose, withProUpsell }: Props) => {
  return (
    <DialogModal open={open} onClose={onClose}>
      <GlobalProductsHeader />

      <DialogModal.Title>Global shipping to 140+ countries</DialogModal.Title>

      {withProUpsell ? (
        <DialogModal.Content tw="flex flex-col gap-4">
          <p>Your plan includes domestic shipping to Canada.</p>
          <p>
            Subscribe to Pro for global shipping to <CountriesUndelineText />
          </p>
          <p tw="text-gray-500 text-sm">
            Shipping, taxes, and duties vary by country.
          </p>
        </DialogModal.Content>
      ) : (
        <DialogModal.Content tw="flex flex-col gap-3">
          <p>
            Ship gifts to <CountriesUndelineText />
          </p>
          <p>
            <span tw="font-medium">You’re already subscribed to Pro.</span> Add
            your product to your bag and select “Full global catalog” under
            International when you send your gift.
          </p>
        </DialogModal.Content>
      )}

      {withProUpsell ? (
        <DialogModal.Actions>
          <Link to={generateRealmPath("plus", "/subscribe-pro")} tw="w-full">
            <DialogModal.Button variant="primary-new">
              Subscribe to Pro
            </DialogModal.Button>
          </Link>
        </DialogModal.Actions>
      ) : (
        <DialogModal.Actions tw="md:flex-col">
          <a
            href="https://intercom.help/goody/en/articles/5838916-does-goody-ship-internationally"
            rel="nofollow noopener noreferrer"
            target="_blank"
            tw="w-full"
          >
            <DialogModal.Button variant="primary-new">
              Learn more in the help center
            </DialogModal.Button>
          </a>
          <DialogModal.Button onClick={onClose}>Close</DialogModal.Button>
        </DialogModal.Actions>
      )}
    </DialogModal>
  )
}

export default GlobalShippingModal
