import React from "react"
import { useHistory } from "react-router-dom"

import Button from "./Button"

const BackButton = () => {
  const history = useHistory()

  return (
    <Button
      onClick={() => {
        history.goBack()
      }}
    >
      Go back
    </Button>
  )
}

export default BackButton
