import { gql } from "@apollo/client"

const UPDATE_PAYMENT_METHOD_MUTATION = gql`
  mutation UpdatePaymentMethod($paymentMethodId: ID!, $label: String) {
    paymentMethodUpdate(paymentMethodId: $paymentMethodId, label: $label) {
      ok
      error
    }
  }
`

export default UPDATE_PAYMENT_METHOD_MUTATION
