import { useGiftBatchSettings } from "./hooks"

import { Alert, AlertColor, OptionBase } from "./index"

import { useGlobalState } from "@/common/GlobalState"
import { useFeatureAccess } from "@/common/hooks/featureAccess"
import { useGiftCart } from "@/common/hooks/giftData"
import InternationalShippingOptions from "@/common/InternationalShippingOptions"
import InternationalShippingTierOptions from "@/internationalShipping/internationalShippingTierOptions"
import {
  GiftSwapTypeEnum,
  InternationalShippingTierEnum,
} from "@/types/graphql-types"

export const InternationalShipping = () => {
  const { settings, setSettings } = useGiftBatchSettings()
  const { cartQuantity } = useGiftCart()
  const [currentUser] = useGlobalState("user")

  const { hasFeature } = useFeatureAccess()

  const hasPro = hasFeature("pro_plan")

  const usingGlobalParters =
    settings.internationalShippingTier ===
      InternationalShippingTierEnum.standard ||
    settings.internationalShippingTier === InternationalShippingTierEnum.full

  const handleSetInternationalShippingTier = (
    newInternationShippingTier: InternationalShippingTierEnum,
  ) => {
    setSettings((currentSettings) => ({
      ...currentSettings,
      internationalShippingTier: newInternationShippingTier,
    }))
  }

  const handleSetGiftCardsEnabled = (giftCardsEnabled: boolean) => {
    setSettings((currentSettings) => ({
      ...currentSettings,
      settings: {
        ...currentSettings.settings,
        giftCardsEnabled,
      },
    }))
  }

  const currentUserIsNotGiftCreator =
    settings.userPublicId !== currentUser?.publicId

  const alert = (
    <Alert
      primaryMessage={
        "Only the creator of this gift, " +
        settings.userFullName +
        ", can edit this field."
      }
      color={AlertColor.yellow}
    />
  )

  if (settings.isDirectSend) {
    return null
  }

  return (
    <OptionBase
      name="International shipping"
      displayProTag={!usingGlobalParters && !hasPro}
      disabled={(!usingGlobalParters && !hasPro) || currentUserIsNotGiftCreator}
      alert={currentUserIsNotGiftCreator ? alert : null}
    >
      {usingGlobalParters ? (
        <InternationalShippingOptions
          internationalShippingTier={settings.internationalShippingTier}
          setInternationalShippingTier={handleSetInternationalShippingTier}
          giftCardsEnabled={settings.settings.giftCardsEnabled || false}
          setGiftCardsEnabled={handleSetGiftCardsEnabled}
          swapType={settings.swapType}
          disableGlobalTier={cartQuantity >= 2}
        />
      ) : (
        <InternationalShippingTierOptions
          object={settings}
          setInternationalShippingTier={handleSetInternationalShippingTier}
          disallowInternational={cartQuantity >= 2}
          showSwapMultipleWarning={
            settings.swapType === GiftSwapTypeEnum.swap_multiple
          }
          header={<></>}
        />
      )}
    </OptionBase>
  )
}
