import { ApolloClient, useApolloClient } from "@apollo/client"

import { EDITORIAL_QUERY } from "./graphql"

import {
  Store_EditorialQuery,
  Store_EditorialQueryVariables,
} from "@/types/graphql-types"

export function usePreloadHoliday() {
  const client = useApolloClient()

  const preloadAllEditorials = () => {
    for (const slug of HOLIDAY_SLUGS) {
      prefetchEditorial(client, slug)
    }
  }

  return {
    preloadAllEditorials,
  }
}

export const HOLIDAY_GUIDES = {
  "employee-holiday-gifts": "Employee gifts",
  "client-holiday-gifts": "Client gifts",
  "branded-holiday-gifts": "Custom gifts",
  "gifts-for-global-teams-edit": "Global gifts",
  "affordable-holiday-gifts": "Affordable gifts",
}

export const HOLIDAY_SLUGS = Object.keys(HOLIDAY_GUIDES)

const prefetchEditorial = async (client: ApolloClient<any>, slug: string) => {
  const res = await client.query<
    Store_EditorialQuery,
    Store_EditorialQueryVariables
  >({
    query: EDITORIAL_QUERY,
    variables: {
      slug,
    },
    fetchPolicy: "network-only",
  })

  return res.data.editorialItem
}
