import styled from "@emotion/styled"
import React from "react"
import tw from "twin.macro"

import { ReactComponent as Box } from "../../business/images/box.svg"
import { ReactComponent as Compass } from "../../business/images/compass.svg"
import { ReactComponent as Dropbox } from "../../business/images/dropbox.svg"
import { ReactComponent as Facebook } from "../../business/images/facebook.svg"
import { ReactComponent as Google } from "../../business/images/google.svg"
import { ReactComponent as KPMG } from "../../business/images/kpmg.svg"
import { ReactComponent as NEA } from "../../business/images/nea.svg"
import { ReactComponent as RampImage } from "../../business/images/ramp.svg"
import { ReactComponent as RO } from "../../business/images/ro.svg"
import { ReactComponent as Stripe } from "../../business/images/stripe.svg"

interface Props {
  title?: string
  variant?: "primary" | "secondary"
}

const Brands: React.FC<Props> = ({
  title = "Trusted by thousands of leading companies.",
  variant = "primary",
}) => {
  const Title = variant === "secondary" ? SecondaryTitle : PrimaryTitle

  return (
    <div tw="text-center mx-auto" style={{ maxWidth: "1200px" }}>
      <div tw="mb-12 mx-4">
        <Title>{title}</Title>
      </div>
      <GridContainer>
        <GridItem>
          <Facebook />
        </GridItem>
        <GridItem>
          <Google />
        </GridItem>
        <GridItem>
          <KPMG />
        </GridItem>
        <GridItem>
          <Stripe />
        </GridItem>
        <GridItem>
          <Box />
        </GridItem>
        <GridItem>
          <Compass />
        </GridItem>
        <GridItem>
          <NEA />
        </GridItem>
        <GridItem>
          <Dropbox />
        </GridItem>
        <GridItem>
          <RampImage />
        </GridItem>
        <GridItem>
          <RO />
        </GridItem>
      </GridContainer>
    </div>
  )
}

const PrimaryTitle: React.FC = ({ children }) => (
  <p tw="font-bold text-3xl">{children}</p>
)

const SecondaryTitle: React.FC = ({ children }) => (
  <p tw="font-bold text-lg leading-8 md:leading-6 text-dark-blue">{children}</p>
)

const GridContainer = styled.div`
  max-width: 640px;
  ${tw`grid grid-cols-2 lg:grid-cols-5 mx-auto`}

  @media (min-width: 1024px) {
    max-width: 1440px;
  }
`

const GridItem = styled.div`
  ${tw`flex items-center justify-center m-4 lg:m-8`}
`

export default Brands
