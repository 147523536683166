import tw, { styled } from "twin.macro"

import { CheckCircleWhite } from "../../../assets/icons"
import staticAssetUrl from "../../../common/staticAssetUrl"

const AwardImage = staticAssetUrl("landing/how_it_works/branded_swag/award.png")

const WeDontCompromiseOnQuality = () => (
  <OuterContainer>
    <div tw="max-w-[600px] lg:max-w-[960px] mx-auto flex flex-col lg:flex-row">
      <div tw="mb-12 lg:mr-16 lg:pt-12">
        <img src={AwardImage} tw="mx-auto h-auto w-[6rem]" />
      </div>
      <div>
        <h2 tw="lg:w-full mx-auto text-center lg:text-left text-white font-bold mb-8 text-[2rem] lg:text-[3rem] lg:leading-[3.5rem]">
          We don't compromise on quality.
        </h2>
        <div tw="flex mb-6 text-white text-xl">
          <CheckCircleWhite tw="flex-shrink-0 mr-4 mt-1" />
          <span>
            All products in Goody's swag collection are quality-tested to ensure
            they represent your brand well.
          </span>
        </div>
        <div tw="flex mb-6 text-white text-xl">
          <CheckCircleWhite tw="flex-shrink-0 mr-4 mt-1" />
          <span>
            Our team reviews swag orders before printing, and contacts you if
            anything is wrong.
          </span>
        </div>
      </div>
    </div>
  </OuterContainer>
)

const OuterContainer = styled.div`
  ${tw`px-5 py-20`}
  background: linear-gradient(90deg, #AE72FF 32.19%, #6B62FF 90.62%);
`

export default WeDontCompromiseOnQuality
