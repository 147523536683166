import { useLazyQuery, useMutation } from "@apollo/client"
import { useEffect, useState } from "react"
import { useIntercom } from "react-use-intercom"

import { PaymentMethod } from "@/common/PaymentMethodsManager"
import Alert from "@/common/payments/deactivateModal/Alert"
import CancelRadioBox from "@/common/payments/deactivateModal/CancelRadioBox"
import Confirm from "@/common/payments/deactivateModal/Confirm"
import ReplaceRadioBox from "@/common/payments/deactivateModal/ReplaceRadioBox"
import { DEACTIVATE_PAYMENT_METHOD_MUTATION } from "@/common/payments/graphql/deactivatePaymentMethodMutation"
import { PAYMENT_METHOD_DEPENDENCIES_QUERY } from "@/common/payments/graphql/paymentMethodDependenciesQuery"
import {
  PaymentMethodModalProps,
  PaymentMethodModalWrapper,
} from "@/common/payments/PaymentMethodModal"
import { bulletsLast4, humanizeDepenencies } from "@/common/payments/utils"
import {
  DeactivatePaymentMethodMutation,
  DeactivatePaymentMethodMutationVariables,
  PaymentMethodDependencies,
  PaymentMethodDependenciesQuery,
  PaymentMethodDependenciesQueryVariables,
} from "@/types/graphql-types"

const DeactivateModal = ({
  open,
  close,
  paymentMethod,
  refreshPaymentMethods,
  paymentMethods,
}: PaymentMethodModalProps & { paymentMethods: PaymentMethod[] }) => {
  const { show } = useIntercom()
  const [dependencies, setDependencies] = useState<PaymentMethodDependencies>()
  const [humanizedDepenencies, setHumanizedDependencies] = useState<string[]>(
    [],
  )
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState<string | null>()
  const [
    organizationSubscriptionMatchingCardNumber,
    setOrganizationSubscriptionMatchingCardNumber,
  ] = useState(false)
  const [replace, setReplace] = useState<boolean | undefined>()
  const selectablePaymentMethods = paymentMethods.filter(
    (pm) => pm.id != paymentMethod.id,
  )
  const replaceable = selectablePaymentMethods.length > 0
  const [replacementPaymentMethod, setReplacementPaymentMethod] =
    useState<PaymentMethod>(selectablePaymentMethods[0])

  const closeReset = async () => {
    if (success) {
      await refreshPaymentMethods()
    }
    close()
    setReplace(undefined)
  }

  const [
    loadPaymentMethodDependencies,
    { loading: loadingPaymentMethodDependencies },
  ] = useLazyQuery<
    PaymentMethodDependenciesQuery,
    PaymentMethodDependenciesQueryVariables
  >(PAYMENT_METHOD_DEPENDENCIES_QUERY, {
    variables: {
      paymentMethodId: paymentMethod.id,
    },
    onCompleted: (data) => {
      setDependencies(data.paymentMethodDependencies)
      setHumanizedDependencies(
        humanizeDepenencies(data.paymentMethodDependencies),
      )
    },
  })

  const [
    deactivatePaymentMethod,
    { loading: loadingDeactivatePaymentMethod, error: graphQlError },
  ] = useMutation<
    DeactivatePaymentMethodMutation,
    DeactivatePaymentMethodMutationVariables
  >(DEACTIVATE_PAYMENT_METHOD_MUTATION)
  const submit = async () => {
    const { data } = await deactivatePaymentMethod({
      variables: {
        paymentMethodId: paymentMethod.id,
        ...(replace && {
          replacementPaymentMethodId: replacementPaymentMethod?.id,
        }),
      },
    })
    setSuccess(!!data?.paymentMethodDeactivate.ok)
    setError(data?.paymentMethodDeactivate.error)
    setOrganizationSubscriptionMatchingCardNumber(
      !!data?.paymentMethodDeactivate
        .organizationSubscriptionMatchingCardNumber,
    )
  }

  useEffect(() => {
    if (open) loadPaymentMethodDependencies()
  }, [open])

  const HasDependencies = () => (
    <Confirm
      cta="Submit"
      ctaDisabled={loadingDeactivatePaymentMethod}
      onCtaClick={submit}
      onDenyClick={closeReset}
    >
      <div tw="flex flex-col gap-4">
        <div>
          <span tw="capitalize">{paymentMethod.cardBrand}</span>{" "}
          {bulletsLast4(paymentMethod)} is associated with:
        </div>
        <ul tw="list-disc list-inside">
          {humanizedDepenencies.map((dependency) => (
            <li>{dependency}</li>
          ))}
        </ul>
        <div>Please choose an option:</div>
        <ReplaceRadioBox
          checked={replace === true}
          replaceable={replaceable}
          onClick={() => setReplace(true)}
          selectablePaymentMethods={selectablePaymentMethods}
          paymentMethod={paymentMethod}
          replacementPaymentMethod={replacementPaymentMethod}
          setReplacementPaymentMethod={setReplacementPaymentMethod}
        />
        <CancelRadioBox
          checked={replace === false}
          onClick={() => setReplace(false)}
          dependencies={dependencies}
          humanizedDepenencies={humanizedDepenencies}
          paymentMethod={paymentMethod}
        />
      </div>
    </Confirm>
  )

  const Content = () => {
    if (success) {
      return (
        <Alert
          cta={
            organizationSubscriptionMatchingCardNumber
              ? "Contact support"
              : "Close"
          }
          onCta={organizationSubscriptionMatchingCardNumber ? show : closeReset}
        >
          <div tw="flex flex-col gap-4">
            <div>Payment method deleted.</div>
            {organizationSubscriptionMatchingCardNumber && (
              <div>
                <span tw="font-semibold">
                  Your organization may have a Team subscription using this
                  payment method.
                </span>{" "}
                To remove the payment method from the subscription, please
                contact support.
              </div>
            )}
          </div>
        </Alert>
      )
    }

    if (graphQlError || error) {
      return (
        <Alert cta="Contact support" onCta={show}>
          {graphQlError ? "An unexpected error occured." : error}
        </Alert>
      )
    }

    if (dependencies?.canDeactivate) {
      return (
        <Confirm
          cta="Yes, delete"
          ctaDisabled={loadingDeactivatePaymentMethod}
          onCtaClick={submit}
          onDenyClick={closeReset}
        >
          Are you sure you want to delete{" "}
          <span tw="capitalize">{paymentMethod.cardBrand}</span>{" "}
          {bulletsLast4(paymentMethod)}?
        </Confirm>
      )
    }

    if (dependencies?.failedBatchBillingGroups?.length) {
      return (
        <Alert cta="Contact support" onCta={show}>
          You have a failed payment that must be resolved before this payment
          method can be deleted.
        </Alert>
      )
    }

    return <HasDependencies />
  }

  return (
    <PaymentMethodModalWrapper open={open} close={closeReset}>
      <div tw="flex justify-between">
        <div tw="capitalize font-semibold text-xl leading-6">
          Delete {paymentMethod.cardBrand} {bulletsLast4(paymentMethod)}
        </div>
      </div>
      {loadingPaymentMethodDependencies ? (
        <div tw="text-gray-450">Loading...</div>
      ) : (
        <Content />
      )}
    </PaymentMethodModalWrapper>
  )
}

export default DeactivateModal
