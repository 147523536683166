import React from "react"
import tw from "twin.macro"

import { useGiftData } from "./hooks/giftData"
import { BlankPlus, Checkmark } from "../assets/icons"

const ApplyCreditButton = ({
  amount,
  creditOmitted,
  isBusinessSend,
}: {
  amount: string
  creditOmitted: boolean
  isBusinessSend?: boolean
}) => {
  const { setOmitCredit } = useGiftData()
  return (
    <div
      css={[
        tw`relative flex justify-center items-center px-3 py-[9px] bg-[#EDFAF1] rounded-md whitespace-nowrap flex-nowrap cursor-pointer transition-transform`,
        isBusinessSend
          ? tw`active:scale-[.98] hover:scale-[.98]`
          : tw`active:scale-95 hover:scale-95 lg:ml-[-12px]`,
      ]}
      onClick={() =>
        creditOmitted ? setOmitCredit(false) : setOmitCredit(true)
      }
    >
      <>
        {" "}
        {creditOmitted ? (
          <>
            <ButtonText>
              <BlankPlus tw="inline-block mr-2" />
              Apply credit
            </ButtonText>
            <span tw="text-[rgba(22, 163, 74, 0.6)] leading-5 font-normal text-base ml-3">
              {amount}
            </span>
          </>
        ) : (
          <ButtonText>
            <Checkmark tw="inline-block mr-2" />
            Credit applied
          </ButtonText>
        )}
      </>
    </div>
  )
}

const ButtonText = tw.span`text-[#16A34A] font-normal text-base leading-5 stroke-current flex-nowrap whitespace-nowrap`
export default ApplyCreditButton
