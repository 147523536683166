import { getAutogiftProductPrice } from "./getAutogiftProductPrice"

import {
  AutogiftRuleProduct,
  AutogiftRule_ProductsQuery,
} from "@/types/graphql-types"

type AutogiftRuleProductFragment = NonNullable<
  AutogiftRule_ProductsQuery["products"]
>[0]

const calculateProductDisplayPrice = (
  product: AutogiftRuleProductFragment,
  productList: AutogiftRuleProduct[],
) => {
  if (product.isFlexGift || product.isGiftCard) {
    return getAutogiftProductPrice(product.id, productList)
  }

  const shippingPrice = product.shippingPrice ? product.shippingPrice : 0
  return product.price + shippingPrice
}

export const processProductDisplayPrice = (
  product: AutogiftRuleProductFragment,
  productList: AutogiftRuleProduct[],
) => {
  const priceInCents = calculateProductDisplayPrice(product, productList)
  if (priceInCents !== null) {
    return (priceInCents / 100).toFixed(2)
  }
}
