import { Link } from "react-router-dom"
import tw, { styled } from "twin.macro"

import { ReactComponent as SnowflakeIcon } from "./images/gift-guide-snowflake.svg"
import { generateRealmPath } from "./realm"

export default function HolidayGiftGuideMenuItem() {
  return (
    <>
      <GuideButton to={generateRealmPath(null, "/browse/holiday-gifts")}>
        <div tw="pr-2 xl:pr-3">
          <span tw="whitespace-nowrap">Holiday Gifts</span>
        </div>
        <SnowflakeIcon tw="w-[28px] h-[28px] pr-1 block xl:block" />
      </GuideButton>
      <MobileGuideButton to={generateRealmPath(null, "/browse/holiday-gifts")}>
        <div tw="ml-2 pr-2">Holiday Gift Guide</div>
        <SnowflakeIcon />
      </MobileGuideButton>
    </>
  )
}

const MobileGuideButton = styled(Link)`
  ${tw`flex h-12 lg:hidden items-center backgroundColor[#eff3ef] hover:backgroundColor[#ebf0ea] active:backgroundColor[#e4ebe4] pl-4 pr-1 py-1 font-medium color[#53844f] fontSize[17px] transition-colors`};
`

const GuideButton = styled(Link)`
  ${tw`hidden lg:flex flex-row items-center backgroundColor[#eff3ef] hover:backgroundColor[#ebf0ea] active:backgroundColor[#e4ebe4] pl-4 pr-1 xl:pr-2 py-2 rounded-full font-medium color[#53844f] text-[17px] transition-colors`};
`
