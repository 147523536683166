import { useMutation } from "@apollo/client"
import { isNil } from "lodash-es"
import toast from "react-hot-toast"

import { REMOVE_CUSTOM_STORE_OPTION } from "../graphql"

import {
  Store_CustomStores_RemoveCustomStoreOptionMutation,
  Store_CustomStores_RemoveCustomStoreOptionMutationVariables,
} from "@/types/graphql-types"

export const useRemoveCustomStoreOption = (refetch?: Function) => {
  const [removeCustomStoreOptionMutation] = useMutation<
    Store_CustomStores_RemoveCustomStoreOptionMutation,
    Store_CustomStores_RemoveCustomStoreOptionMutationVariables
  >(REMOVE_CUSTOM_STORE_OPTION)

  const handleRemoveCustomStoreOption = async (
    customStoreId: string | null | undefined,
    giftOptionId: string | null,
    productId: string | null,
    customStoreOptionId?: string | null,
  ) => {
    if (isNil(customStoreId)) {
      return
    }

    const { data } = await removeCustomStoreOptionMutation({
      variables: {
        customStoreId: customStoreId,
        giftOptionId: giftOptionId,
        productId: productId,
        customStoreOptionId: customStoreOptionId,
      },
    })

    if (data?.customStoreRemoveOption?.ok) {
      !!refetch && refetch()
      toast.success("Removed")
    }
  }

  return { handleRemoveCustomStoreOption }
}
