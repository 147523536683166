import Modal from "react-modal"
import tw, { styled } from "twin.macro"

import { ReactComponent as CheckCircle } from "../../assets/icons/check-circle-alt.svg"
import { ReactComponent as XCircle } from "../../assets/icons/x-circle-alt.svg"
import { ReactComponent as XIcon } from "../../assets/icons/x-close.svg"
import { useGlobalState } from "../GlobalState"
import { level2ModalStyle } from "../modal"

const LearnAboutFullVsLimitedModal = () => {
  const [isOpen, setIsOpen] = useGlobalState(
    "isLearnAboutFullVsLimitedModalOpen",
  )

  const featureRows = [
    {
      title: "Access",
      full: "Full access to your organization's plan features",
      limited: "Can send gifts, but have limited access to full features",
    },
    {
      title: "Cost",
      full: "1 billable seat",
      limited: "Free",
    },
    {
      title: "Gift sending",
      fullIcon: <CheckCircle />,
      full: "Send or schedule unlimited gifts",
      limitedIcon: <CheckCircle />,
      limited: "Send or schedule unlimited gifts",
    },
    {
      title: (
        <span>
          Work
          <wbr />
          space access
        </span>
      ),
      fullIcon: <CheckCircle />,
      full: "View gifts sent by others in their workspace",
      limitedIcon: <CheckCircle />,
      limited: "View gifts sent by others in their workspace",
    },
    {
      fullIcon: <CheckCircle />,
      full: "International gifts",
      limitedIcon: <XCircle />,
      limited: "International gifts",
    },
    {
      fullIcon: <CheckCircle />,
      full: "Custom cards and logo branding",
      limitedIcon: <XCircle />,
      limited: "Custom cards and logo branding",
    },
    {
      fullIcon: <CheckCircle />,
      full: "Budgeting",
      limitedIcon: <XCircle />,
      limited: "Budgeting",
    },
    {
      fullIcon: <CheckCircle />,
      full: "Custom stores",
      limitedIcon: <XCircle />,
      limited: "Custom stores",
    },
    {
      fullIcon: <CheckCircle />,
      full: "Salesforce integration",
      limitedIcon: <XCircle />,
      limited: "Salesforce integration",
    },
    {
      fullIcon: <CheckCircle />,
      full: "Calendly integration",
      limitedIcon: <XCircle />,
      limited: "Calendly integration",
    },
  ]

  return (
    <Modal
      isOpen={isOpen}
      style={level2ModalStyle}
      closeTimeoutMS={500}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      onRequestClose={() => setIsOpen(false)}
    >
      <Container>
        <button
          tw="absolute top-4 right-4 sm:top-10 sm:right-10 text-gray-500"
          onClick={() => setIsOpen(false)}
        >
          <XIcon />
        </button>
        <h2 tw="text-lg sm:text-3xl text-center mb-8">
          About full vs. limited members
        </h2>
        <table tw="text-xs sm:text-base">
          <thead>
            <tr tw="flex">
              <TD />
              <TD tw="font-medium">Full Members</TD>
              <TD tw="font-medium">Limited Members</TD>
            </tr>
          </thead>
          <tbody>
            {featureRows.map(
              (
                { title, limited, limitedIcon = null, full, fullIcon = null },
                index,
              ) => (
                <tr tw="flex" key={`${title?.toString()} + ${index}`}>
                  <TD>{title}</TD>
                  <TD>
                    {fullIcon}
                    {full}
                  </TD>
                  <TD>
                    {limitedIcon}
                    {limited}
                  </TD>
                </tr>
              ),
            )}
          </tbody>
        </table>
      </Container>
    </Modal>
  )
}

const Container = styled.div`
  ${tw`py-12 px-2 sm:px-6 overflow-y-scroll rounded-2xl bg-white flex flex-col w-full sm:w-4/5 mx-auto relative`}
  max-height: 90vh;
  box-shadow: 0px 8px 48px rgba(0, 0, 0, 0.08);
`

const TD = styled.td`
  ${tw`first:flex-1 flex-2 first:font-medium border-r border-gray-200 px-2 py-4 sm:p-4 last:border-none flex`}
  vertical-align: top;

  svg {
    ${tw`flex-shrink-0 mr-2`}
  }
`

export default LearnAboutFullVsLimitedModal
