import { useQuery } from "@apollo/client"
import { compact, keyBy, pick, uniq } from "lodash-es"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useHistory } from "react-router-dom"

import { SHIPPING_COUNTRIES_QUERY } from "../graphql/ShippingCountriesQuery"

import { useToggle } from "@/common/hooks/useToogle"
import {
  ShippingCountriesQuery,
  ShippingCountriesQueryVariables,
  ShippingCountry,
  ShippingCountryGroupEnum,
} from "@/types/graphql-types"

type CountriesDispatch = React.Dispatch<React.SetStateAction<ShippingCountry>>
interface ContextProps {
  shippingCountries: ShippingCountry[]
  selectedShippingCountry: ShippingCountry
  setSelectedShippingCountry: CountriesDispatch
  popoverShippingCountry: ShippingCountry
  setPopoverShippingCountry: CountriesDispatch
  resetPopoverShippingCountry: () => void
  showOnlyDomesticShipping: boolean
  toogleShowOnlyDomesticShipping: () => void
  expandShippingCountries: (countryCodes: string[]) => ShippingCountry[]
  findCountryByCode: (code?: string) => ShippingCountry | undefined
}

export const DEFAULT_COUNTRY = {
  code: "US",
  name: "United States",
  groups: [ShippingCountryGroupEnum.domestic],
}
export const GLOBAL_COUNTRY = {
  code: "GLOBAL",
  name: "Global",
  groups: [ShippingCountryGroupEnum.global, ShippingCountryGroupEnum.gift_card],
}
const UNDEFINED_SET = () => undefined

const Context = React.createContext<ContextProps>({
  shippingCountries: [DEFAULT_COUNTRY],
  selectedShippingCountry: DEFAULT_COUNTRY,
  setSelectedShippingCountry: UNDEFINED_SET,
  popoverShippingCountry: DEFAULT_COUNTRY,
  setPopoverShippingCountry: UNDEFINED_SET,
  resetPopoverShippingCountry: UNDEFINED_SET,
  showOnlyDomesticShipping: false,
  toogleShowOnlyDomesticShipping: UNDEFINED_SET,
  expandShippingCountries: () => [],
  findCountryByCode: UNDEFINED_SET,
})

interface Props {
  children: React.ReactNode
}
export const ShippingCountriesProvider = ({ children }: Props) => {
  const history = useHistory()
  const searchParams = new URLSearchParams(history.location.search)

  const [selectedShippingCountry, setSelectedShippingCountry] =
    useState<ShippingCountry>(DEFAULT_COUNTRY)

  const [popoverShippingCountry, setPopoverShippingCountry] = useState(
    selectedShippingCountry,
  )

  const [
    showOnlyDomesticShipping,
    toogleShowOnlyDomesticShipping,
    setToogleShowOnlyDomesticShipping,
  ] = useToggle(searchParams.get("domestic") === "true")

  const { data } = useQuery<
    ShippingCountriesQuery,
    ShippingCountriesQueryVariables
  >(SHIPPING_COUNTRIES_QUERY)

  const shippingCountries = useMemo(
    () =>
      data?.shippingCountries
        ? [...data.shippingCountries, GLOBAL_COUNTRY]
        : [],
    [data],
  )

  const indexedCountries = useMemo(
    () => keyBy(shippingCountries, "code"),
    [shippingCountries],
  )

  const findCountryByCode = (code?: string) => indexedCountries[code || ""]

  const expandShippingCountries = useCallback(
    (countryCodes: string[]) => {
      return Object.values(pick(indexedCountries, countryCodes))
    },
    [indexedCountries],
  )

  const resetPopoverShippingCountry = useCallback(() => {
    setPopoverShippingCountry(selectedShippingCountry)
  }, [selectedShippingCountry])

  useEffect(() => resetPopoverShippingCountry(), [resetPopoverShippingCountry])

  useEffect(() => {
    if (
      searchParams.get("domestic") !== "true" ||
      !searchParams.get("country") ||
      !selectedShippingCountry.groups.includes(
        ShippingCountryGroupEnum.domestic,
      )
    ) {
      setToogleShowOnlyDomesticShipping(false)
    }
  }, [selectedShippingCountry, setToogleShowOnlyDomesticShipping])

  useEffect(() => {
    if (shippingCountries.length) {
      const country = findCountryByCode(
        searchParams.get("country")?.toUpperCase() || DEFAULT_COUNTRY.code,
      )
      setSelectedShippingCountry(country || shippingCountries[0])
    }
  }, [shippingCountries, indexedCountries])

  const selectedCountryCode = uniq(
    compact([popoverShippingCountry?.code, selectedShippingCountry?.code]),
  )[0]

  useEffect(() => {
    if (shippingCountries.length === 0) return

    if (selectedCountryCode === "US") {
      searchParams.delete("country")
    } else {
      searchParams.set("country", selectedCountryCode.toLowerCase())
    }

    if (showOnlyDomesticShipping && selectedCountryCode !== "US") {
      searchParams.set("domestic", "true")
    } else {
      searchParams.delete("domestic")
    }

    history.replace({
      pathname: history.location.pathname,
      search: searchParams.toString(),
    })
  }, [selectedCountryCode, history, showOnlyDomesticShipping])

  return (
    <Context.Provider
      value={{
        shippingCountries,
        selectedShippingCountry,
        setSelectedShippingCountry,
        popoverShippingCountry,
        setPopoverShippingCountry,
        resetPopoverShippingCountry,
        showOnlyDomesticShipping,
        toogleShowOnlyDomesticShipping,
        expandShippingCountries,
        findCountryByCode,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default ShippingCountriesProvider

export const useShippingCountries = () => React.useContext(Context)
