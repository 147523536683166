import React from "react"

export const BalanceMessage = () => (
  <div tw="flex flex-row justify-center mt-8">
    <div tw="text-center text-gray-600 max-w-[420px] leading-[20px]">
      Unused funds expire after one year of account inactivity and are
      non-refundable. Balance deposits are subject to the{" "}
      <a
        tw="hover:text-gray-500"
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.ongoody.com/static/terms/PlusTermsAndConditions.pdf"
      >
        Enterprise Service Agreement
      </a>
    </div>
  </div>
)
