import { useQuery } from "@apollo/client"
import React from "react"
import { Helmet } from "react-helmet-async"
import { useHistory } from "react-router-dom"

import AddCard from "./AddCard"
import CustomCard from "./CustomCard"
import { CUSTOM_CARDS_FETCH } from "./graphql"
import { useFeatureAccess } from "../common/hooks/featureAccess"
import { useGiftData } from "../common/hooks/giftData"
import { UpgradeToProMessageTag } from "../common/ProTag"
import { generateRealmPath } from "../common/realm"

import { Card } from "@/types/graphql-types"

const CustomCards: React.FC = () => {
  const { setCard } = useGiftData()

  const history = useHistory()
  const { hasFeature } = useFeatureAccess()

  const { data } = useQuery(CUSTOM_CARDS_FETCH)

  const cards = data?.me.customCards.map((card: Card) => {
    const useCard = () => {
      setCard({ id: card.id, image: card.imageLarge.url })
      history.push(generateRealmPath("plus", "/send"))
    }
    return (
      <CustomCard
        cardId={card.id}
        cardName={card.name}
        imageURL={card.imageLarge.url}
        useCard={useCard}
        key={card.id}
      />
    )
  })

  return (
    <div tw="container mx-auto bg-white">
      <Helmet>
        <title>Custom Cards – Goody for Business</title>
      </Helmet>
      <div tw="p-5 py-12">
        <div tw="flex">
          <h1 tw="font-medium text-primary-600 text-2xl">Custom Cards</h1>
        </div>
      </div>

      {hasFeature("pro_plan") ? (
        <div tw="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 border-t border-l border-primary-100">
          <AddCard />
          {cards}
        </div>
      ) : (
        <UpgradeToProMessageTag
          tw={"px-5"}
          message={"to upload custom cards"}
          feature={"custom_cards"}
        />
      )}
      <div tw="pb-72" />
    </div>
  )
}

export default CustomCards
