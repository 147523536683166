import tw from "twin.macro"

import { PaymentMethodButton } from "./PaymentMethodButton"
import { getPaymentMethodName } from "../common/hooks/payment"
import { imageForCardBrand } from "../common/payment"
import { PaymentMethod } from "../common/PaymentMethodsManager"

interface Props {
  paymentMethod: PaymentMethod
  selectedPaymentMethodID: string | null
  onSelect: (paymentMethod: PaymentMethod, paymentMethodName: string) => void
  showCardholderName?: boolean
}

// Option for use in a payment method selector
const PaymentMethodOption = ({
  paymentMethod,
  selectedPaymentMethodID,
  onSelect,
  showCardholderName,
}: Props) => {
  const selected = paymentMethod.id === selectedPaymentMethodID

  return (
    <PaymentMethodButton
      text={`•••• ${paymentMethod.last4}`}
      image={imageForCardBrand(paymentMethod.cardBrand)}
      selected={selected}
      onClick={(e) => {
        e.preventDefault()
        onSelect(paymentMethod, getPaymentMethodName(paymentMethod))
      }}
      afterText={
        showCardholderName && paymentMethod.cardholderName ? (
          <span css={selected ? tw`text-primary-600` : tw`text-gray-800`}>
            <span tw="px-2">·</span>
            {paymentMethod.cardholderName}
          </span>
        ) : undefined
      }
    />
  )
}

export default PaymentMethodOption
