import { useState } from "react"

import close from "@/assets/icons/close.svg"
import pencil from "@/assets/icons/pencil-with-eraser.svg"
import { PaymentMethod as PaymentMethodType } from "@/common/PaymentMethodsManager"
import DeactivateModal from "@/common/payments/DeactivateModal"
import EditLabelModal from "@/common/payments/EditLabelModal"
import IconButton from "@/common/payments/IconButton"
import PaymentMethod, {
  Props as PaymentMethodProps,
  cardImage,
} from "@/common/payments/PaymentMethod"
import { bulletsLast4 } from "@/common/payments/utils"
import { isPresent } from "@/common/utilities"

interface Props extends Omit<PaymentMethodProps, "label" | "image"> {
  paymentMethod: PaymentMethodType
  refreshPaymentMethods: () => Promise<void>
  paymentMethods: PaymentMethodType[]
}

const CreditCardPaymentMethod = ({
  paymentMethod,
  refreshPaymentMethods,
  paymentMethods,
  ...props
}: Props) => {
  const [editLabelModalOpen, setEditLabelModalOpen] = useState(false)
  const [deactivateModalOpen, setDeactivateModalOpen] = useState(false)

  return (
    <>
      <PaymentMethod
        label={
          isPresent(paymentMethod.label)
            ? paymentMethod.label
            : bulletsLast4(paymentMethod)
        }
        sublabel={
          isPresent(paymentMethod.label)
            ? bulletsLast4(paymentMethod)
            : undefined
        }
        image={cardImage(paymentMethod.cardBrand)}
        ghostButtons={true}
        {...props}
      >
        <IconButton
          icon={pencil}
          onClick={(e) => {
            e.stopPropagation()
            setEditLabelModalOpen(true)
          }}
        />
        <IconButton
          icon={close}
          onClick={(e) => {
            e.stopPropagation()
            setDeactivateModalOpen(true)
          }}
        />
      </PaymentMethod>
      <EditLabelModal
        open={editLabelModalOpen}
        close={() => setEditLabelModalOpen(false)}
        paymentMethod={paymentMethod}
        refreshPaymentMethods={refreshPaymentMethods}
      />
      <DeactivateModal
        open={deactivateModalOpen}
        close={() => setDeactivateModalOpen(false)}
        paymentMethod={paymentMethod}
        refreshPaymentMethods={refreshPaymentMethods}
        paymentMethods={paymentMethods}
      />
    </>
  )
}

export default CreditCardPaymentMethod
