import { useMutation } from "@apollo/client"
import React from "react"
import { Link } from "react-router-dom"
import tw, { styled } from "twin.macro"

import { ChevronLeft } from "./assets/icons"
import { UPDATE_GIFT_BATCH_MUTATION } from "./graphql"

import { gradientBg } from "@/assets/cdnAssets"
import { generateRealmPath } from "@/common/realm"
import GiftBatchInlineEditField from "@/track/components/giftBatch/GiftBatchInlineEditField"
import GiftBatchOpenInReportsButton from "@/track/components/giftBatch/GiftBatchOpenInReportsButton"
import GiftBatchResendButton from "@/track/components/giftBatch/GiftBatchResendButton"
import GiftBatchSubNav from "@/track/components/giftBatch/GiftBatchSubNav"
import {
  GiftBatch_GiftBatchUpdateSentBatchMutation,
  GiftBatch_GiftBatchUpdateSentBatchMutationVariables,
  Track_GiftBatchFragment,
} from "@/types/graphql-types"
interface Props {
  title: string
  loadingVisible: boolean
  giftBatchId: string
  giftBatch: Track_GiftBatchFragment | null | undefined
}
export default function GiftBatchHeader({
  title,
  loadingVisible,
  giftBatchId,
  giftBatch,
}: Props) {
  const [giftBatchUpdateSentBatch, { loading: giftBatchUpdateLoading }] =
    useMutation<
      GiftBatch_GiftBatchUpdateSentBatchMutation,
      GiftBatch_GiftBatchUpdateSentBatchMutationVariables
    >(UPDATE_GIFT_BATCH_MUTATION)

  const handleNameChange = async (value: string) => {
    if (giftBatch) {
      const result = await giftBatchUpdateSentBatch({
        variables: {
          giftBatch: {
            id: giftBatchId,
            batchName: value,
          },
        },
      })

      if (
        !result.data?.giftBatchUpdateSentBatch ||
        !result.data?.giftBatchUpdateSentBatch.ok
      ) {
        alert("An error occurred.")
      }
    }
  }

  return (
    <HeaderBackgroundContainer tw="pt-6 lg:pt-8">
      <HeaderContent>
        <div tw="flex">
          <Link
            to={generateRealmPath("plus", "/track/")}
            tw="inline-flex items-center text-gray-400"
          >
            <ChevronLeft />
            <span tw="ml-3">All gifts</span>
          </Link>
        </div>
        <HeaderTextRow>
          <HeaderText className="data-hj-suppress ph-no-capture fs-mask">
            {loadingVisible ? (
              <span tw="opacity-50">Loading…</span>
            ) : (
              <GiftBatchInlineEditField
                label={title}
                emptyValue={giftBatch?.batchNameFallback || ""}
                disabled={giftBatchUpdateLoading}
                initialValue={giftBatch?.batchName || ""}
                onSubmit={handleNameChange}
              />
            )}
          </HeaderText>
          <div tw="flex flex-row lg:ml-auto gap-4">
            <GiftBatchOpenInReportsButton giftBatchId={giftBatchId} />
            <GiftBatchResendButton giftBatchId={giftBatchId} />
          </div>
        </HeaderTextRow>
        <GiftBatchSubNav />
      </HeaderContent>
    </HeaderBackgroundContainer>
  )
}

const HeaderBackgroundContainer = styled.div`
  ${tw`bg-cover bg-top border border-primary-new-050`};

  background-image: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.56) 59.37%
    ),
    url(${gradientBg});
  background-size: cover;
`

const HeaderContent = tw.div`
  w-screen lg:max-w-[1024px] xl:max-w-[1280px] 2xl:max-w-[1440px] mx-auto px-6 lg:px-0 xl:px-6
`

const HeaderTextRow = tw.div`
  flex flex-col lg:flex-row items-start lg:items-center mt-[7px] mb-4
`

const HeaderText = tw.div`
  font-medium text-center text-black leading-normal text-2xl lg:text-[32px] mb-5 lg:mb-0
`
