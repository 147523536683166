import tw, { styled } from "twin.macro"

import swagMug from "./assets/swag-mug.jpg"
import swagNotebook from "./assets/swag-notebook.jpg"
import swagShirt from "./assets/swag-shirt.jpg"

import Image from "@/common/Image"

export const SwagProductsExamples = () => {
  return (
    <div tw="flex items-center justify-center mt-[-58px]">
      <ExampleImage
        src={swagShirt}
        tw="w-[84px] h-[84px] z-30 rounded-[12px] py-2.5 px-4"
      />
      <ExampleImage
        src={swagNotebook}
        tw="w-[74px] h-[74px] z-20 rounded-[10px] ml-[-18px] py-2.5 pl-6 pr-4"
      />
      <ExampleImage
        src={swagMug}
        tw="w-[64px] h-[64px] z-10 rounded-[8px] ml-[-15px] p-3 pr-2"
      />
    </div>
  )
}

const ExampleImage = styled(Image)`
  ${tw`bg-white backdrop-blur-md`}
  filter: drop-shadow(0px 0px 4px rgba(22, 58, 118, 0.03)) drop-shadow(0px 0px 24px rgba(22, 58, 118, 0.15));
`
