import React from "react"
import { ReactNode } from "react"
import { Link, useHistory } from "react-router-dom"
import tw from "twin.macro"

import { ReactComponent as ArrowRight } from "../../../assets/icons/arrow-right.svg"
import { ReactComponent as ChevronRight } from "../../../assets/icons/chevron-right.svg"
import GradientButton from "../../../common/GradientButton"
import RainbowHeading from "../../../common/RainbowHeading"
import { generateRealmPath } from "../../../common/realm"
import featureDetailsHandled from "../../images/feature-details-handled.png"
import featureEngage from "../../images/feature-engage.png"
import featureNoAddress from "../../images/feature-no-address.png"

interface FeatureProps {
  action?: ReactNode
  children: ReactNode
  image: string
  isReversed?: boolean
  title: string
  largeImage?: boolean
}

const featureImageStyles = {
  default: tw`relative lg:col-start-2 h-60 lg:h-full mb-10 pl-5`,
  reversed: tw`h-60 lg:h-full mb-10 pl-5`,
} as const

const Feature: React.FC<FeatureProps> = ({
  action,
  children,
  image,
  isReversed = false,
  title,
  largeImage,
}) => {
  const imageWrapperStyles = isReversed
    ? featureImageStyles.reversed
    : featureImageStyles.default
  return (
    <div tw="grid grid-cols-1 lg:grid-cols-2 grid-flow-row-dense mb-32 overflow-hidden 2xl:overflow-visible md:mx-8">
      <div css={imageWrapperStyles}>
        <img
          src={image}
          alt="Feature"
          css={[
            tw`absolute h-full max-h-96 lg:max-h-screen w-auto`,
            largeImage && tw`static lg:h-96`,
          ]}
          style={{ maxWidth: "unset" }}
        />
      </div>
      <div tw="px-5 xl:pl-0">
        <RainbowHeading tw="text-3xl lg:text-4xl leading-tight pb-8 font-light">
          {title}
        </RainbowHeading>
        <div
          css={[
            tw`text-lg lg:text-1.5xl text-black`,
            {
              lineHeight: "1.5",
            },
          ]}
        >
          {children}
        </div>
        {action && <div tw="pt-8 lg:pt-12">{action}</div>}
      </div>
    </div>
  )
}

interface FeatureLinkProps {
  children?: ReactNode
  to: string
}

const FeatureLink: React.FC<FeatureLinkProps> = ({ children, to }) => {
  return (
    <Link
      to={to}
      tw="text-primary-400 text-lg lg:text-1.5xl inline-flex items-center"
    >
      {children}
      <ChevronRight tw="ml-2 inline-block stroke-current" />
    </Link>
  )
}

interface StartButtonProps {
  onClick: () => void
  disabled?: boolean
}

const StartButton: React.FC<StartButtonProps> = ({ onClick, disabled }) => (
  <GradientButton onClick={onClick} disabled={disabled || false}>
    Get started
    <ArrowRight tw="ml-8 " />
  </GradientButton>
)

export const Features: React.FC = () => {
  const history = useHistory()

  return (
    <div>
      <Feature
        action={
          <FeatureLink to="/browse">Browse our gift collection</FeatureLink>
        }
        image={featureNoAddress}
        title="No address needed"
      >
        With just an email address or phone number, gift your entire contact
        list at once and it will ship right to their door.
        <br />
        <br />
        You’ll feel confident knowing your goody will always land in the hands
        of the recipient, no matter where they are working.
      </Feature>
      <Feature
        image={featureEngage}
        isReversed
        title="Impress recipients"
        largeImage={true}
      >
        Celebrate work anniversaries, promotions, birthdays, and show
        recognition for a job well done.
        <br />
        <br />
        Whatever the occasion, impress recipients with a delightful digital
        unwrapping experience. Add a personalized message to one of our well
        designed cards or upload your own custom card.
      </Feature>
      <Feature
        action={
          <StartButton
            onClick={() => {
              history.push(generateRealmPath("business", "/signup?via=ea"))
            }}
          />
        }
        image={featureDetailsHandled}
        title="We handle the details"
      >
        No more messy expense reports or tracking down items. Goody has you
        covered.
        <br />
        <br />
        Your recipients get automatic reminders and shipping updates sent
        directly to them and you'll get a consolidated receipt weekly for all
        the accepted gifts.
      </Feature>
    </div>
  )
}
