import { gql, useQuery } from "@apollo/client"
import { useMemo } from "react"

import { CategorySet } from "../categories/CategorySetToggle"

import { CategorySegment } from "@/types/graphql-types"
import {
  Store_CategoriesQuery,
  Store_CategoriesQueryVariables,
  Store_CategoryCommonFragment,
  Store_CategoryFragment,
} from "@/types/graphql-types"

interface Props {
  selectedCategorySet: CategorySet
  selectedCategorySlug: string | null
  selectedSubcategorySlug?: string | null
  isEmbeddedCustomStore: boolean
}

export const useCategories = ({
  selectedCategorySet,
  selectedCategorySlug,
  selectedSubcategorySlug,
  isEmbeddedCustomStore,
}: Props) => {
  const { data } = useQuery<
    Store_CategoriesQuery,
    Store_CategoriesQueryVariables
  >(CATEGORIES_QUERY, {
    variables: {
      segment: CategorySegment.PLUS,
    },
  })

  const categories: Store_CategoryFragment[] = useMemo(() => {
    if (selectedCategorySet === CategorySet.CATEGORIES && data?.categorySet) {
      return data?.categorySet
    } else if (data?.occasionSet) {
      return data?.occasionSet
    } else {
      return []
    }
  }, [data, selectedCategorySet])

  const allCategory = useMemo(
    () =>
      categories &&
      categories.filter((cat) => cat.name?.toLowerCase() === "all")[0],
    [categories],
  )
  const allCategoryIcon = useMemo(
    () =>
      allCategory &&
      allCategory.icon
        ?.replace(/fill="black"/g, `fill="currentColor"`)
        ?.replace(/stroke="black"/g, `stroke="currentColor"`),
    [allCategory?.icon],
  )

  const categoriesWithoutAll = useMemo(() => {
    const excludedNames = ["all"]

    if (isEmbeddedCustomStore) {
      excludedNames.push("branded swag")
    }

    return categories.filter(
      ({ name }) => !excludedNames.includes(name.toLowerCase()),
    )
  }, [categories, isEmbeddedCustomStore])

  const selectedCategory: Store_CategoryFragment | undefined = categories.find(
    (category) => {
      // If we have a selected category slug, use that
      if (selectedCategorySlug && category.slug === selectedCategorySlug) {
        return true
      }

      return false
    },
  )

  const selectedSubcategory: Store_CategoryCommonFragment | undefined =
    selectedCategory?.subcategories.find(
      (subcategory) => subcategory.slug === selectedSubcategorySlug,
    )

  const seoTitleString = () => {
    const selected = selectedSubcategory || selectedCategory
    const { seoTitle, pageHeading, name } = selected || {}
    return seoTitle || pageHeading || name
  }

  return {
    allCategory,
    allCategoryIcon,
    categoriesWithoutAll,
    selectedCategory,
    selectedSubcategory,
    seoTitle: seoTitleString(),
  }
}

export const CATEGORY_COMMON = gql`
  fragment Store_CategoryCommon on Category {
    id
    isAlcohol
    name
    slug
    pageHeading
    pageDescription
    seoTitle
  }
`

export const CATEGORY_FRAGMENT = gql`
  fragment Store_Category on Category {
    icon
    iconWidth
    isFeatured
    segment
    ...Store_CategoryCommon
    subcategories {
      ...Store_CategoryCommon
    }
  }
  ${CATEGORY_COMMON}
`

export const CATEGORIES_QUERY = gql`
  query Store_Categories($segment: CategorySegment) {
    categorySet: categories(
      segment: $segment
      set: CATEGORIES
      storePlatform: web
    ) {
      ...Store_Category
    }
    occasionSet: categories(
      segment: $segment
      set: OCCASIONS
      storePlatform: web
    ) {
      ...Store_Category
    }
  }
  ${CATEGORY_FRAGMENT}
`

export default useCategories
