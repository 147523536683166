import React from "react"

import DesktopImg from "./images/WhyAutogiftingDesktop.png"
import MobileImg from "./images/WhyAutogiftingMobile.png"
import { DesktopImage, MobileImage } from "../components/DropShaddowImage"
import { GridCenterContainer } from "../components/PositionHelper"
import TitleContainer from "../components/TitleContainer"

const WhyAutogifting: React.FC = () => (
  <GridCenterContainer>
    <div tw="max-w-xl px-5 lg:pl-10 lg:pr-14 lg:justify-self-end">
      <MobileImage tw="pb-5 object-center mx-auto" src={MobileImg} />
      <TitleContainer
        heading="Why Autogifting?"
        title="Build a culture of appreciation and belonging."
        subtitle={
          <div tw="pt-2 text-[#626366]">
            <div tw="pb-2">
              When it comes to recognizing employees’ birthdays and work
              anniversaries,
              <span tw="text-[#060D25] font-semibold">
                {" "}
                most companies do nothing.
              </span>
            </div>
            Or worse, they leave it to individual colleagues and managers —
            which makes{" "}
            <span tw="text-[#060D25] font-semibold">
              forgotten employees feel like losers in a workplace popularity
              contest.
            </span>
          </div>
        }
        align="left"
      />
    </div>
    <DesktopImage
      tw="object-contain lg:mr-10 xl:mr-auto h-[620px]"
      src={DesktopImg}
    />
  </GridCenterContainer>
)

export default WhyAutogifting
