import staticAssetUrl from "../../../common/staticAssetUrl"
import { Header, Paragraph } from "../../common/SectionComponents"
import EnvelopeIcon from "../icons/envelope.svg"

const OrderStatusImage = staticAssetUrl(
  "landing/how_it_works/bulk_gifting/OrderStatus.png",
)

const OrderStatuses = () => (
  <div
    tw="px-5 pb-12 pt-12 sm:pt-12 mx-auto flex flex-col justify-center items-center"
    style={{ maxWidth: "1200px" }}
  >
    <div tw="flex flex-col lg:flex-row max-w-[580px] lg:max-w-full">
      <div tw="flex-2 mb-12 lg:mb-0 xl:pr-0 lg:pt-12">
        <div tw="lg:pr-16">
          <img src={EnvelopeIcon} alt="icon" tw="mb-4 lg:mb-8" />
          <Header>
            Order statuses.
            <br />
            Thank you notes.
            <br />
            All in one place.
          </Header>
          <Paragraph>
            Simple yet powerful reporting shows the progress of your bulk
            gifting. Recipients' thank you notes go right into Goody.
          </Paragraph>
        </div>
      </div>
      <div tw="flex-1 mt-12 lg:mt-0 mb-24 lg:mb-0 lg:pr-8 relative">
        <img tw="w-full" src={OrderStatusImage} alt="OrderStatus" />
      </div>
    </div>
  </div>
)

export default OrderStatuses
