import ReactPlaceholder from "react-placeholder/lib"
import { NavLink } from "react-router-dom"
import tw from "twin.macro"

import { CustomProductPreview } from "./CustomProductPreview"
import { ProductPreviewPlaceholder } from "./ProductPreviewPlaceholder"
import { ReactComponent as ChevronRight } from "../../../assets/icons/chevron-right.svg"
import { generateRealmPath } from "../../../common/realm"
import { DelayedComponent } from "../../components/DelayedComponent"
import { ProductData } from "../../StoreHelpers"
import { useRestrictedProducts } from "../hooks/useRestrictedProducts"

interface Props {
  openPDP?: (product: ProductData) => void
}

export const CustomProductsPreviewList = ({ openPDP }: Props) => {
  // Using `cache-only` to fetch the preview list since the products
  // will be loaded from the `CustomCategoryItem` component.
  // This is an optimization so we don't request 2 queries to the server
  // for the same data.
  const { products, hasNextPage, loading } = useRestrictedProducts({
    fetchPolicy: "cache-only",
    pageSize: 4,
  })

  if (!loading && !products?.length) {
    return null
  }

  return (
    <div tw="mb-14">
      <h1 tw="mb-6 font-medium text-2xl">My products</h1>
      <div tw="grid grid-cols-1 md:grid-cols-2 lg:(grid-cols-5) gap-6">
        <DelayedComponent waitBeforeShow={100}>
          <ReactPlaceholder
            showLoadingAnimation={true}
            ready={!loading}
            customPlaceholder={<ProductPreviewPlaceholder items={3} />}
          >
            {products?.map((product) => (
              <CustomProductPreview
                key={product.id}
                product={product}
                onClick={openPDP}
              />
            ))}
            {hasNextPage && (
              <div tw="self-center w-32">
                <SeeAllButton
                  to={generateRealmPath("plus", "/browse/custom/products")}
                >
                  <span tw="font-medium text-base leading-5">See All</span>
                  <ChevronRight tw="stroke-current" />
                </SeeAllButton>
              </div>
            )}
          </ReactPlaceholder>
        </DelayedComponent>
      </div>
    </div>
  )
}

const SeeAllButton = tw(NavLink)`
  border border-gray-150 shadow-sm rounded-full text-primary-new-550
  p-4 flex gap-4 items-center justify-center whitespace-nowrap cursor-pointer

  hover:(border-primary-new-100)
  active:(
    border-primary-new-100 bg-primary-new-100
    bg-gradient-to-t from-primary-new-000 to-primary-new-000
  )
`
