import tw from "twin.macro"

import { SelectionGroupColorEnum } from "./SelectionGroup/types"

import { ReactComponent as CheckIcon } from "@/common/images/check.svg"

interface Props {
  selected: boolean
  onClick?: () => void
  color?: SelectionGroupColorEnum
}

const Checkbox = ({ selected, onClick, color, ...props }: Props) => {
  const baseStyle = tw`
    transition-all duration-100 cursor-pointer
    flex flex-row items-center justify-center
    h-5 w-5 p-0.5
    rounded shadow-inner-border-px-med
    bg-white group-active:bg-gray-050
  `

  const selectedStyle = selected
    ? color === SelectionGroupColorEnum.primaryNew
      ? tw`shadow-none bg-primary-new-600 group-active:bg-primary-new-700`
      : tw`shadow-none bg-primary-500 group-active:bg-primary-600`
    : null

  return (
    <div onClick={onClick} css={[baseStyle, selectedStyle]} {...props}>
      <CheckIcon tw="stroke-current text-white stroke-3" />
    </div>
  )
}

export default Checkbox
