import React, { useContext, useEffect, useState } from "react"

import { ReactComponent as Icon } from "./icons/expire.svg"
import OptionBase from "./OptionBase"
import DatePickerField from "../../../common/DatePickerField"
import { useGiftData } from "../../../common/hooks/giftData"
import { BALANCE_PAYMENT_METHOD_ID } from "../../../common/hooks/payment"
import {
  SelectionGroup,
  SelectionIndicator,
  SelectionLabel,
} from "../../../common/SelectionGroup"
import {
  formatDayWithDate,
  fourWeeksFromDate,
  fourWeeksFromNow,
  sixWeeksFromDate,
  sixWeeksFromNow,
} from "../../../common/utilities"
import { OptionsContext } from "../OptionsContext"

export default function Expire() {
  const { currentGift, isDirectSend } = useContext(OptionsContext)
  const { setExpireAtOption, setCustomExpiration } = useGiftData()
  const [showFourWeeks, setShowFourWeeks] = useState(false)

  const isUsingBalance =
    currentGift.autopayPaymentMethodID === BALANCE_PAYMENT_METHOD_ID

  // If we have a saved gift with four weeks, then we want to enable that option, otherwise, six weeks
  useEffect(() => {
    if (currentGift.expireAtOption === "fourWeeks") {
      setShowFourWeeks(true)
    }
  }, [currentGift.expireAtOption === "fourWeeks"])

  if (isDirectSend || currentGift.isSmartLink) {
    return null
  }

  return (
    <OptionBase
      name="Expire"
      icon={<Icon />}
      preview={
        currentGift.expireAtOption === "none"
          ? "Doesn’t expire"
          : currentGift.expireAtOption === "sixWeeks"
          ? `6 weeks ${
              currentGift.scheduledSendOnOption ? "from send date" : "from now"
            }`
          : currentGift.expireAtOption === "fourWeeks"
          ? `4 weeks ${
              currentGift.scheduledSendOnOption ? "from send date" : "from now"
            }`
          : formatDayWithDate(currentGift.customExpirationDate)
      }
    >
      <div>
        <div>
          <SelectionGroup
            onClick={() => setExpireAtOption("none")}
            disabled={isUsingBalance}
            unclickable={isUsingBalance}
          >
            <SelectionIndicator
              selected={currentGift.expireAtOption === "none"}
            />
            <SelectionLabel selected={currentGift.expireAtOption === "none"}>
              Doesn't expire
            </SelectionLabel>
          </SelectionGroup>
        </div>
        <div>
          {showFourWeeks ? (
            <SelectionGroup
              onClick={() => setExpireAtOption("fourWeeks")}
              tw="items-start"
            >
              <SelectionIndicator
                selected={currentGift.expireAtOption === "fourWeeks"}
              />
              <SelectionLabel
                selected={currentGift.expireAtOption === "fourWeeks"}
              >
                <div tw="pt-1">
                  Four weeks from{" "}
                  {!!currentGift.scheduledSendOnOption ? "send date" : "now"}
                </div>
                <div tw="text-gray-400">
                  {formatDayWithDate(
                    !!currentGift.scheduledSendOnOption
                      ? fourWeeksFromDate(currentGift.scheduledSendOnDate)
                      : fourWeeksFromNow(),
                  )}
                </div>
              </SelectionLabel>
            </SelectionGroup>
          ) : (
            <SelectionGroup
              onClick={() => setExpireAtOption("sixWeeks")}
              tw="items-start"
            >
              <SelectionIndicator
                selected={currentGift.expireAtOption === "sixWeeks"}
              />
              <SelectionLabel
                selected={currentGift.expireAtOption === "sixWeeks"}
              >
                <div tw="pt-1">
                  Six weeks from{" "}
                  {!!currentGift.scheduledSendOnOption ? "send date" : "now"}
                </div>
                <div tw="text-gray-400">
                  {formatDayWithDate(
                    !!currentGift.scheduledSendOnOption
                      ? sixWeeksFromDate(currentGift.scheduledSendOnDate)
                      : sixWeeksFromNow(),
                  )}
                </div>
              </SelectionLabel>
            </SelectionGroup>
          )}
        </div>
        <div>
          <SelectionGroup onClick={() => setExpireAtOption("custom")}>
            <SelectionIndicator
              selected={currentGift.expireAtOption === "custom"}
            />
            <SelectionLabel selected={currentGift.expireAtOption === "custom"}>
              Custom expiration date
            </SelectionLabel>
          </SelectionGroup>
        </div>
        <div tw="ml-8">
          {currentGift.expireAtOption === "custom" && (
            <DatePickerField
              date={currentGift.customExpirationDate}
              onDateChange={(date: Date) => setCustomExpiration(date)}
              numDisabledDaysAhead={3}
            />
          )}
        </div>
      </div>
      <div tw="pt-4 text-gray-500 text-sm font-text">
        The gift will expire at 11:59 PM Hawaii time on the given date. Expired
        gift cards and messages can be viewed, but the product can’t be viewed
        or accepted.
      </div>
    </OptionBase>
  )
}
