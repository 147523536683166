import { useMutation } from "@apollo/client"
import React, { useState } from "react"
import tw, { styled } from "twin.macro"

import { ReactComponent as AlertIcon } from "../images/alert-triangle.svg"
import { ReactComponent as MoreIcon } from "../images/more-horizontal.svg"

import giftPendingApprove from "@/assets/icons/gift-pending-approve.svg"
import giftPendingReject from "@/assets/icons/gift-pending-reject.svg"
import Button from "@/common/Button"
import { ReactComponent as CheckIcon } from "@/common/images/check.svg"
import { ReactComponent as LinkIcon } from "@/common/images/link.svg"
import {
  GIFT_BATCH_SMART_LINK_COUNTS,
  GIFT_USER_APPROVAL_MUTATION,
} from "@/track/queries"
import { GiftStatusWithApproval } from "@/track/shared"
import {
  Gift_UserApprovalMutation,
  Gift_UserApprovalMutationVariables,
} from "@/types/graphql-types"

interface DeliveryStatusProps {
  status: GiftStatusWithApproval
  giftId?: string | null
  giftBatchId?: string | null
}

const DeliveryStatus = ({
  status,
  giftId,
  giftBatchId,
}: DeliveryStatusProps) => {
  const [giftUserApproval, { loading }] = useMutation<
    Gift_UserApprovalMutation,
    Gift_UserApprovalMutationVariables
  >(GIFT_USER_APPROVAL_MUTATION)

  const [statusOverride, setStatusOverride] =
    useState<GiftStatusWithApproval | null>(null)

  const submitGiftApproval = async (approved: boolean) => {
    if (!giftId) {
      return
    }

    const res = await giftUserApproval({
      variables: { id: giftId, approved },
      refetchQueries: [
        {
          query: GIFT_BATCH_SMART_LINK_COUNTS,
          variables: {
            id: giftBatchId,
          },
        },
      ],
    })
    if (res.data?.giftUserApproval?.ok) {
      setStatusOverride(approved ? "approved" : "rejected")
    } else {
      alert("Sorry, an error occurred.")
    }
  }

  const statusToUse = statusOverride || status

  if (statusToUse === "pending_user_approval") {
    return (
      <div tw={"flex flex-row items-center gap-2.5 text-white mb-10 lg:mb-0"}>
        <StatusApprovalButton
          disabled={loading}
          tw="text-white border-none bg-[#059669] hover:bg-[#047857] active:bg-[#166534] hover:border-none active:border-none"
          onClick={() => submitGiftApproval(true)}
        >
          <img src={giftPendingApprove} alt={"Approve"} />
          Approve
        </StatusApprovalButton>
        <StatusApprovalButton
          disabled={loading}
          tw="text-gray-600"
          onClick={() => submitGiftApproval(false)}
        >
          <img src={giftPendingReject} alt={"Reject"} />
          Reject
        </StatusApprovalButton>
      </div>
    )
  } else {
    return (
      <DeliveryStatusPill className={statusToUse}>
        <DeliveryStatusIcon status={statusToUse} />
        <span>{statusToUse}</span>
      </DeliveryStatusPill>
    )
  }
}

const StatusApprovalButton = styled(Button)`
  ${tw`flex flex-row justify-center gap-2 px-4 py-2`}
`

const DeliveryStatusPill = styled.div`
  ${tw`inline-flex items-center py-1 pl-3 pr-5 mb-10 text-lg font-medium rounded-full lg:mb-0`}

  span::first-letter {
    ${tw`uppercase`}
  }

  &.delivered {
    ${tw`text-white bg-green-500`}
  }

  &.failed,
  &.expired,
  &.rejected {
    ${tw`bg-gray-200`}
  }

  &.self-send {
    ${tw`text-white bg-blue-500`}
  }

  &.sending,
  &.pending {
    ${tw`text-yellow-500 bg-yellow-100`}
  }

  &.sent,
  &.created,
  &.opened,
  &.accepted,
  &.shipped,
  &.approved {
    ${tw`text-green-500 bg-green-100`}
  }

  svg {
    ${tw`mr-2 stroke-current`}
  }
`

const DeliveryStatusIcon: React.FC<DeliveryStatusProps> = ({ status }) => {
  switch (status) {
    case "self-send":
      return <LinkIcon />
    case "sending":
    case "pending":
      return <MoreIcon />
    case "sent":
    case "created":
    case "opened":
    case "accepted":
    case "shipped":
    case "pending_user_approval":
    case "delivered":
    case "approved":
      return <CheckIcon />
    case "failed":
    case "expired":
    case "rejected":
      return <AlertIcon tw="opacity-70" />
  }
}

export default DeliveryStatus
