import { VariantsArray } from "./hooks/giftData"

interface ProductInterface {
  variants: {
    name: string
  }[]
  variantsNumSelectable?: number | null
}

export function validateVariants(
  selectedVariants: VariantsArray | null,
  product: ProductInterface,
  allowLetRecipientChoose: boolean,
) {
  if (selectedVariants !== null) {
    // If selectedVariants contains any value that doesn't match product variants,
    // display an error. Note that selectedVariants can include duplicates
    const variantNames = product.variants.map((v) => v.name)
    const invalidVariants = selectedVariants.filter(
      (v) => !variantNames.includes(v),
    )
    if (invalidVariants.length > 0) {
      alert(
        `You selected an invalid option. Please select a valid option${
          allowLetRecipientChoose ? ", or 'Let recipient choose'" : ""
        }.`,
      )
      return false
    }

    // Check for number of variants
    if (selectedVariants.length !== product.variantsNumSelectable) {
      alert(
        `You'll need to select exactly ${
          product.variantsNumSelectable
        } options${
          allowLetRecipientChoose
            ? ", or you can choose 'Let recipient choose'"
            : ""
        }.`,
      )
      return false
    }
  }

  return true
}
