import tw, { styled } from "twin.macro"

const VerticalPurpleBanner = styled.div<{
  compact?: boolean
}>`
  ${tw`relative text-white flex flex-col items-end`}

  &::before {
    ${tw`block absolute top-0 right-0 h-full w-full`}
    content: " ";
    background: linear-gradient(60deg, #641ec2 0%, #7e77ea 100%);

    @media only screen and (min-width: 1080px) {
      width: 50vw;
    }
  }

  > .text {
    ${tw`relative z-10 px-6 w-full pt-12 pb-8`}
    ${({ compact }) => (compact ? tw`lg:pb-0 lg:pt-12` : tw`lg:pb-12 lg:pt-24`)}

    > h1 {
      ${tw`text-3xl font-semibold`}

      ${({ compact }) => (compact ? tw`mb-6` : tw`mb-10`)}

      > small {
        ${tw`block text-lg text-purple-300`}
      }
    }

    > p {
      ${tw`text-lg lg:text-2xl text-purple-200`}

      > em {
        ${tw`not-italic text-white`}
      }
    }
  }

  > img {
    ${tw`relative z-10`}
  }
`

export default VerticalPurpleBanner
