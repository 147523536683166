import { gql } from "@apollo/client"

export const REMOVE_CUSTOM_STORE_OPTION = gql`
  mutation Store_CustomStores_RemoveCustomStoreOption(
    $customStoreId: ID!
    $productId: ID
    $giftOptionId: ID
    $customStoreOptionId: ID
  ) {
    customStoreRemoveOption(
      customStoreId: $customStoreId
      productId: $productId
      giftOptionId: $giftOptionId
      customStoreOptionId: $customStoreOptionId
    ) {
      ok
      errors
    }
  }
`
