import { ReactComponent as CheckIcon } from "../../assets/icons/check-circle.svg"
import { useCurrentGift } from "../../common/hooks/currentGift"
import {
  BALANCE_PAYMENT_METHOD_ID,
  CORPORATE_ACCOUNT_PAYMENT_METHOD_ID,
} from "../../common/hooks/payment"

import { BatchSendMethod } from "@/types/graphql-types"

const OnlyChargedForAcceptedGiftsNotice = ({ ...props }) => {
  const [currentGift] = useCurrentGift()

  if (
    !currentGift.paymentMethod ||
    currentGift.paymentMethod.id === BALANCE_PAYMENT_METHOD_ID ||
    currentGift.paymentMethod.id === CORPORATE_ACCOUNT_PAYMENT_METHOD_ID
  ) {
    return null
  }

  if (currentGift.sendMethod === BatchSendMethod.direct_send) {
    return null
  }

  return (
    <div
      tw="text-center pt-4 text-primary-new-600 flex justify-center items-center"
      {...props}
    >
      <CheckIcon tw="stroke-current mt-1 mr-2" />
      You will only be charged for gifts that are accepted
    </div>
  )
}

export default OnlyChargedForAcceptedGiftsNotice
