import "twin.macro"
import React, { useEffect, useState } from "react"
import tw from "twin.macro"
import { useDebounce } from "use-debounce"

import { SwapType } from "./FlexGiftSwapTypeSelector"

import { ReactComponent as ExpandIcon } from "@/assets/icons/expand.svg"
import { getEnvVars } from "@/common/environment"
import { useGlobalState } from "@/common/GlobalState"
import { ReactComponent as XIcon } from "@/common/images/x.svg"
import { Loader } from "@/common/UI"

interface Props {
  productID: string
  amount: number
  calculatorMode: boolean
  swapType: SwapType
}

// NOTE: iframe might crash if you try to mount and unmount it too much due to
// a Chrome bug, so only change location, don't mount/unmount it. This means
// we might render it for amounts that are too small (below minimum) but it's
// fine.
export default function FlexGiftPreview({
  productID,
  amount,
  calculatorMode,
  swapType,
}: Props) {
  const debouncedAmount = useDebounce(amount, 500)[0]

  const [currentUser] = useGlobalState("user")
  const [loading, setLoading] = useState(true)
  const params = new URLSearchParams()
  params.append("amount", `${debouncedAmount * 100}`)
  params.append("productID", `${productID}`)

  if (swapType === "multiple") {
    params.append("multiple", "true")
  }

  params.append(
    "senderName",
    `${currentUser?.firstName} ${currentUser?.lastName}`,
  )

  const previewUrlWithParams =
    getEnvVars().apiUrl + `/flex-preview?${params.toString()}`

  const [expanded, setExpanded] = useState(false)

  useEffect(() => {
    setLoading(true)
  }, [previewUrlWithParams])

  return (
    <>
      <div
        tw="hidden lg:block absolute inset-0 bg-[rgba(0, 0, 0, 0.5)] z-20 opacity-0 pointer-events-none transition-opacity"
        css={[expanded && tw`opacity-100 pointer-events-auto`]}
        onClick={() => {
          setExpanded(false)
        }}
      />
      <div
        tw="hidden lg:flex h-full flex-1 flex-col items-stretch bg-white z-30 transition-all"
        css={[expanded && (calculatorMode ? tw`-mt-96` : tw`-mt-48`)]}
      >
        <button
          onClick={() => {
            setExpanded(!expanded)
          }}
          tw="hover:bg-primary-new-000 active:bg-primary-new-050 transition-colors"
        >
          <div
            tw="h-[3px] opacity-30"
            css={{
              background: "linear-gradient(270deg, #D634FF 0%, #477CFF 100%);",
            }}
          />
          <div tw="flex flex-row items-center justify-center gap-4 relative">
            <div tw="py-4 text-center font-medium text-xl text-primary-500">
              Preview ${debouncedAmount} gifts
            </div>
            <div tw="absolute top-0 right-4 bottom-0 flex flex-row items-center justify-center">
              {expanded ? (
                <XIcon tw="w-5 h-5 stroke-current stroke-1.5 text-primary-500" />
              ) : (
                <ExpandIcon tw="w-5 h-5 stroke-current text-primary-500" />
              )}
            </div>
          </div>
        </button>
        <div tw="flex-1 relative">
          {loading ? (
            <div tw="w-full h-full flex justify-center z-10 absolute inset-0 bg-white opacity-50">
              <Loader tw="self-center" />
            </div>
          ) : null}
          {!expanded && (
            <button
              tw="w-full h-full flex justify-center z-10 absolute inset-0 bg-[rgba(240, 240, 240, 0.5)] opacity-0 hover:opacity-100 transition-all active:bg-[rgba(240, 240, 240, 0.3)]"
              onClick={() => {
                setExpanded(true)
              }}
            >
              <div tw="self-center bg-white px-5 py-3 rounded-full shadow-md flex flex-row items-center gap-3 border border-gray-100 text-primary-new-600">
                <ExpandIcon tw="w-5 h-5 stroke-current text-primary-new-500" />
                Click to expand
              </div>
            </button>
          )}
          <iframe
            title="Preview"
            onLoad={() => setLoading(false)}
            src={previewUrlWithParams}
            tw="w-full h-full"
          />
        </div>
      </div>
    </>
  )
}
