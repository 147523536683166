import Modal from "react-modal"
import tw, { styled } from "twin.macro"

import { BagGradient } from "@/assets/icons"
import infoCircle from "@/assets/icons/info-circle.svg"
import warningCircle from "@/assets/icons/warning-circle.svg"
import Button from "@/common/Button"
import { topModalStyle } from "@/common/modal"

type DialogVariant = "info" | "warning" | "cart" | "global"

interface Props {
  open: boolean
  onClose: () => void
  onAfterClose?: () => void
  children?: React.ReactNode
}

const DialogModal = ({ open, onClose, onAfterClose, children }: Props) => {
  return (
    <Modal
      isOpen={open}
      closeTimeoutMS={500}
      onRequestClose={onClose}
      onAfterClose={onAfterClose}
      shouldCloseOnOverlayClick={true}
      style={topModalStyle}
    >
      <div
        className="modal-content modal-content-medium"
        tw="text-center overflow-hidden"
      >
        {children}
      </div>
    </Modal>
  )
}

const DialogHeader = ({ variant }: { variant: DialogVariant }) => {
  switch (variant) {
    case "cart":
      return (
        <div tw="flex flex-col bg-white text-center w-full items-center p-6 pb-0">
          <CartGradientBackground />
          <CartLogoContainer>
            <BagGradient />
          </CartLogoContainer>
        </div>
      )
    default:
      return (
        <div
          tw="flex justify-center items-center h-18 overflow-hidden"
          css={[
            variant === "warning" && tw`bg-[#FEFCE8]`,
            variant === "info" && tw`bg-blue-50`,
          ]}
        >
          {variant === "info" && <img alt="Info Icon" src={infoCircle} />}
          {variant === "warning" && (
            <img alt="Warning Icon" src={warningCircle} />
          )}
        </div>
      )
  }
}

const CartLogoContainer = styled.div`
  ${tw`w-12 h-12 rounded-full flex items-center justify-center z-10`}

  background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.51) 100%);
  box-shadow:
    0px 3.39823px 13.5929px rgba(35, 0, 108, 0.06),
    inset 0px -3.39823px 6.79646px rgba(47, 0, 146, 0.02),
    inset 0px -0.849558px 1.69912px rgba(33, 0, 103, 0.02);
`

const CartGradientBackground = styled.div`
  ${tw`absolute left-0 top-0 right-0 h-[96px]`}

  background: linear-gradient(180deg, #f2f0ff 0%, rgba(242, 240, 255, 0) 100%);
  transform: matrix(-1, 0, 0, 1, 0, 0);
`

const DialogTitle = tw.div`mt-8 mb-5 px-8 text-xl font-semibold`

const DialogContent = tw.div`mb-8 px-8`

const DialogActions = tw.div`
  flex flex-col md:flex-row justify-center items-center
  gap-4 px-6 md:px-8 pb-8
`

const DialogButton = tw(Button)`
  flex justify-center items-center
  min-h-[40px] w-full text-gray-600
`

DialogModal.Header = DialogHeader
DialogModal.Title = DialogTitle
DialogModal.Content = DialogContent
DialogModal.Actions = DialogActions
DialogModal.Button = DialogButton

export default DialogModal
