import { BaseMeFragment, WorkspaceTtype } from "@/types/graphql-types"

export const INDIVIDUAL_ID = "individual"

export const isIndividual = (ttype: WorkspaceTtype) =>
  ttype === WorkspaceTtype.INDIVIDUAL

export const sortWorkspaces = (workspaces: BaseMeFragment["workspaces"]) => {
  return [...workspaces].sort((a, b) => {
    // We always want individual to be first
    if (a.ttype !== b.ttype) {
      return a.ttype < b.ttype ? -1 : 1
    }
    if (a.name < b.name) {
      return -1
    }
    if (a.name > b.name) {
      return 1
    }
    return 0
  })
}
