import React, { ReactNode } from "react"

import Container from "@/sites/App/Container"

interface SectionProps {
  title: string
  subtext?: ReactNode
  children: ReactNode
}

const Section = ({ title, subtext, children }: SectionProps) => (
  <Container tw="pt-18">
    <div tw="flex flex-col md:flex-row mx-6 lg:mx-8">
      <div tw="md:min-w-[240px] md:max-w-[240px] md:mr-8 mb-4 lg:mb-0">
        <div tw="font-medium text-2xl">{title}</div>
        {subtext && <div tw="mt-4">{subtext}</div>}
      </div>
      {children}
    </div>
  </Container>
)

export default Section
