import { ReactNode } from "react"
import { Autoplay, Navigation, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import tw, { styled } from "twin.macro"

import EffectCarousel from "./effect-carousel.esm"

import "swiper/css"
import "swiper/css/autoplay"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/virtual"

interface EffectCarouselComponentProps {
  slides: ReactNode[]
}

const EffectCarouselComponent = ({
  slides,
  ...props
}: EffectCarouselComponentProps) => {
  return (
    <StyledSwiper
      effect="carousel"
      modules={[Autoplay, Navigation, Pagination, EffectCarousel]}
      pagination={{ el: ".swiper-pagination" }}
      navigation={true}
      loop={true}
      slidesPerView={2}
      {...props}
    >
      {slides.map((slide, index) => (
        <StyledSwiperSlide key={`slide-${index}`}>
          <div tw="h-full" className="swiper-carousel-animate-opacity">
            <SlideContent>{slide}</SlideContent>
          </div>
        </StyledSwiperSlide>
      ))}
      <div className="swiper-pagination" />
    </StyledSwiper>
  )
}

const StyledSwiper = styled(Swiper)`
  ${tw`h-[39rem]`}

  .swiper-wrapper {
    ${tw`flex items-end`}
  }
`

const StyledSwiperSlide = styled(SwiperSlide)`
  ${tw`w-[640px] h-[380px] origin-top`}
`

const SlideContent = styled.div`
  ${tw`absolute left-0 bottom-0 w-full box-border`}
`

export default EffectCarouselComponent
