import React, { useState } from "react"

import Accordion from "./Accordion"
import Slide1 from "../images/slide-1.png"
import Slide2 from "../images/slide-2.png"
import Slide3 from "../images/slide-3.png"

const slideshowData: SlideData[] = [
  {
    title: "Recipients get a beautiful digital unwrapping experience.",
    content:
      "They’ll see your gift and message, select options like color or size if available, and enter their address.",
  },
  {
    title: "Recipients can swap for something else if they prefer.",
    content:
      "They can choose from a selection of equal or lower-priced options, making both you and them happy that they got something they liked.",
  },
  {
    title: "You only pay when they accept your gift.",
    content:
      "Send a gift today without any payment details. Once they accept, we’ll notify you to check out.",
  },
]

const FeatureSlideshow: React.FC = () => {
  const [currentSlide, setCurrentSlide] = useState<number>(0)

  return (
    <>
      <section tw="col-span-12 lg:col-span-6 md:pl-12 md:pr-12 lg:pr-8 lg:mb-12 relative w-full pb-12 md:pb-32 lg:pb-0">
        <img
          // Space holder since only absolute images causes container to have 0 height
          src={Slide1}
          tw="w-full lg:max-w-md xl:left-32 opacity-0"
          alt=""
        />
        <img
          src={Slide1}
          tw="absolute top-0 left-0 w-full lg:max-w-md xl:left-48 transition-opacity duration-500"
          css={[{ opacity: currentSlide === 0 ? 100 : 0 }]}
          alt=""
        />
        <img
          src={Slide2}
          tw="absolute top-0 left-0 w-full lg:max-w-md z-10 xl:left-48 transition-opacity duration-300"
          css={[
            { opacity: currentSlide === 1 ? 100 : 0 },
            { filter: "drop-shadow(0 10px 32px rgba(0, 0, 0, 0.05))" },
          ]}
          alt=""
        />
        <img
          src={Slide3}
          tw="absolute top-0 left-0 w-full lg:max-w-md z-20 xl:left-48 transition-opacity duration-300"
          css={[{ opacity: currentSlide === 2 ? 100 : 0 }]}
          alt=""
        />
      </section>
      <section tw="col-span-12 lg:col-span-6 pl-5 pr-5 md:pl-12 md:pr-12 lg:pr-8 mb-24 lg:mb-12 self-center lg:pb-24 lg:pr-32">
        <Accordion
          items={slideshowData}
          onExpand={(slideIndex) => setCurrentSlide(slideIndex)}
        />
      </section>
    </>
  )
}

export default FeatureSlideshow
