import tw from "twin.macro"

import { SwagIntegrationDeleteButton } from "./SwagIntegrationDeleteButton"
import { SwagIntegrationUpdateButton } from "./SwagIntegrationUpdateButton"

import { SwagIntegrationsActions } from "@/types/graphql-types"

interface Props {
  product: {
    id: string
    brand: { slug: string }
    slug: string
    swagIntegrationActions: SwagIntegrationsActions[]
  }
  justify?: "center" | "start"
  spaceSize?: "small" | "medium"
}

export const SwagIntegrationActionsButtons = ({
  product,
  justify = "center",
  spaceSize = "small",
}: Props) => {
  if (product.swagIntegrationActions.length === 0) return null

  const parseAction = (action: SwagIntegrationsActions) => {
    switch (action) {
      case SwagIntegrationsActions.delete:
        return (
          <SwagIntegrationDeleteButton key={action} productId={product.id} />
        )
      case SwagIntegrationsActions.update:
        return <SwagIntegrationUpdateButton key={action} product={product} />
      default:
        return null
    }
  }

  const justifyStyle = () => {
    switch (justify) {
      case "center":
        return tw`justify-center`
      case "start":
        return tw`justify-start`
    }
  }

  const spaceStyle = () => {
    switch (spaceSize) {
      case "small":
        return tw`gap-2 pt-5`
      case "medium":
        return tw`gap-5 py-5`
    }
  }

  return (
    <div css={[tw`hidden lg:flex items-center`, justifyStyle(), spaceStyle()]}>
      {product.swagIntegrationActions.map(parseAction)}
    </div>
  )
}
