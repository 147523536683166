import React from "react"

import Button from "@/common/Button"
import { Loader } from "@/common/UI"
import SamlSettings from "@/organization/settings/saml/SamlSettings"

interface Props {
  loading: boolean
  enabled: boolean
  isEditing: boolean
  handleSetup: () => void
  onSave: () => void
}
const SamlContent = ({
  loading,
  enabled,
  isEditing,
  handleSetup,
  onSave,
}: Props) => {
  return (
    <div tw="w-full">
      {loading ? (
        <div tw="ml-4">
          <Loader />
        </div>
      ) : isEditing ? (
        <div tw="w-full">
          <SamlSettings onSave={onSave} />
        </div>
      ) : enabled ? (
        <div>
          <div tw="text-gray-500 mb-4">SAML is enabled</div>
          <Button variant="default" tw="text-gray-600" onClick={handleSetup}>
            Edit SAML settings
          </Button>
        </div>
      ) : (
        <div>
          <Button variant="primary-new" onClick={handleSetup}>
            Set up SAML
          </Button>
        </div>
      )}
    </div>
  )
}

export default SamlContent
