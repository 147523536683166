import { useQuery } from "@apollo/client"
import tw from "twin.macro"

import BudgetingMembers from "./BudgetingMembers"
import { BudgetingBackground } from "./common"
import BudgetingHeader from "./components/BudgetingHeader"
import BudgetingHUD from "./components/BudgetingHUD"
import BudgetingOrganizationContext from "./components/BudgetingOrganizationContext"
import BudgetingQuarter from "./components/BudgetingQuarter"
import { BUDGETING_INDEX_QUERY } from "./graphql"
import TeamUpsell from "../../common/components/TeamUpsell"
import { useFeatureAccess } from "../../common/hooks/featureAccess"
import Container from "../../sites/App/Container"

import { Budgeting_IndexQuery } from "@/types/graphql-types"

export default function BudgetingIndex() {
  const { data, loading } = useQuery<Budgeting_IndexQuery>(
    BUDGETING_INDEX_QUERY,
  )
  const organizationBalance = data?.organization?.balanceAccount?.balance

  const { hasFeature } = useFeatureAccess()
  const hasBudgeting = hasFeature("budgeting")

  return (
    <BudgetingBackground>
      <Container>
        <div tw="pb-9 px-6">
          <BudgetingHeader
            title="Budgeting"
            subtitle="View balances, set recurring budgets, and transfer funds for each person in your organization."
          />
        </div>
      </Container>
      {!hasBudgeting && (
        <Container>
          <div tw="mb-20 -mt-12 lg:w-1/2 mx-auto">
            <TeamUpsell feature="budgeting" />
          </div>
        </Container>
      )}
      <div css={[!hasBudgeting && tw`opacity-50 pointer-events-none`]}>
        <BudgetingHUD
          organizationBalance={organizationBalance}
          budgetSummary={data?.organization?.budgetSummary}
          loading={loading}
        />
        <Container>
          <BudgetingOrganizationContext.Provider
            value={{ balance: organizationBalance }}
          >
            <BudgetingMembers />
          </BudgetingOrganizationContext.Provider>
          {data?.organization && (
            <BudgetingQuarter
              quarterStartMonth={data.organization.quarterStartMonth}
            />
          )}
        </Container>
      </div>
    </BudgetingBackground>
  )
}
