import { Metric, onFCP, onLCP } from "web-vitals"

import { track } from "@/common/analytics"

const trackVitals = ({
  name,
  value,
  rating,
  delta,
  id,
  entries,
  navigationType,
}: Metric) => {
  track(name, {
    value,
    rating,
    delta,
    id,
    entries,
    navigationType,
  })
}

onFCP(trackVitals)
onLCP(trackVitals)
