import tw, { styled } from "twin.macro"

export const CardContainer = styled.div`
  ${tw`border-r border-b border-primary-100 flex flex-col items-center justify-center relative`};
  height: 656px;

  @media (min-width: 1024px) and (max-width: 1279px) {
    height: 570px;
  }
`
