import React from "react"

import AllIntegrations from "./Integrations/AllIntegrations"
import Calendly from "./Integrations/Calendly"
import RealmRoute from "../common/RealmRoute"
import Container from "../sites/App/Container"

const Integrations = () => {
  return (
    <>
      <RealmRoute path="/account/integrations" exact realm="plus">
        <Container tw="px-5 pt-16" css={{ minHeight: "60vh" }}>
          <AllIntegrations />
        </Container>
      </RealmRoute>
      <RealmRoute path="/account/integrations/calendly" exact realm="plus">
        <Container tw="px-5 pt-16" css={{ minHeight: "60vh" }}>
          <Calendly />
        </Container>
      </RealmRoute>
    </>
  )
}

export default Integrations
