import "twin.macro"

import tw from "twin.macro"

import { SelectionGroupWithText } from "@/common/SelectionGroup/SelectionGroupWithText"
import { SelectionGroupColorEnum } from "@/common/SelectionGroup/types"
import { BrandValuesDisplay } from "@/types/graphql-types"

type Props = {
  brandValuesDisplay: BrandValuesDisplay
  setBrandValuesDisplay: (brandValuesDisplay: BrandValuesDisplay) => void
  color?: SelectionGroupColorEnum
}
export const BrandValuesDisplayOptions = ({
  brandValuesDisplay,
  setBrandValuesDisplay,
  color,
}: Props) => {
  return (
    <SelectionContainer>
      <SelectionGroupWithText
        selected={brandValuesDisplay === BrandValuesDisplay.options}
        text="In gift view (default)"
        onClick={() => setBrandValuesDisplay(BrandValuesDisplay.options)}
        color={color}
      />
      <SelectionGroupWithText
        selected={brandValuesDisplay === BrandValuesDisplay.options_and_list}
        text="In store and gift view"
        onClick={() =>
          setBrandValuesDisplay(BrandValuesDisplay.options_and_list)
        }
        color={color}
      />
    </SelectionContainer>
  )
}

const SelectionContainer = tw.div`flex flex-col gap-y-2`
