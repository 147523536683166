import parse, {
  DOMNode,
  Element,
  HTMLReactParserOptions,
  domToReact,
} from "html-react-parser"
import React, { ReactElement } from "react"

type Props = {
  html: string
  components?: Record<string, React.ComponentType<any>>
  componentOverrides?: Record<
    string,
    (originalComponent: React.ComponentType<any>) => React.ComponentType<any>
  >
}

const HTMLRenderer = ({
  html = "",
  components = {},
  componentOverrides = {},
}: Props) => {
  const resolvedComponents = Object.keys(componentOverrides).reduce(
    (acc, key) => {
      const Comp =
        components[key] || ((props) => React.createElement(key, props))

      const callable = componentOverrides as any
      const tmp = callable[key]!(Comp)
      acc[key] = tmp as any

      return acc
    },
    { ...components },
  )

  const parserOptions: HTMLReactParserOptions = {
    replace: (node: DOMNode) => {
      const { name, attribs, children: nodeChildren } = node as Element
      const children = nodeChildren
        ? domToReact(nodeChildren, parserOptions)
        : null

      const Comp = resolvedComponents[name]

      if (!Comp) return null

      return React.createElement(Comp, { name, ...attribs }, children)
    },
  }

  return parse(html, parserOptions) as ReactElement
}

export default HTMLRenderer
