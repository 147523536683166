import "react-day-picker/lib/style.css"

import React from "react"
import tw, { css, styled } from "twin.macro"

import Option from "./Option"
import { ReactComponent as GmailSmIcon } from "../assets/icons/gmail-sm.svg"
import { ReactComponent as GoodyIcon } from "../assets/icons/goody.svg"
import Alert from "../common/Alert"
import AuthorizeGmailModal, {
  useAuthorizeGmailModal,
} from "../common/AuthorizeGmailModal"
import { useGlobalState } from "../common/GlobalState"
import { useGiftData } from "../common/hooks/giftData"
import { ProPlanProtected } from "../common/ProPlanModal"

import { EmailDeliveryMethods } from "@/types/graphql-types"

const EmailDeliverySection = ({
  removePadding,
}: {
  removePadding?: boolean
}) => {
  const [currentUser] = useGlobalState("user")

  const { canSendEmailOnBehalf, setIsOpen: setGmailAuthorizationModalOpen } =
    useAuthorizeGmailModal()

  const { setEmailDeliveryMethod, currentGift } = useGiftData()

  const prohibitToEdit =
    !!currentGift.userPublicId &&
    currentGift.userPublicId !== currentUser?.publicId

  const setValue = (value: EmailDeliveryMethods) => {
    setEmailDeliveryMethod(value)
  }

  const fromName =
    currentGift.fromName ||
    [currentUser?.firstName, currentUser?.lastName].filter(Boolean).join(" ")

  return (
    <Option
      title="Email delivery"
      proFeature="send_via_gmail"
      removePadding={removePadding}
    >
      <>
        {prohibitToEdit && (
          <div tw={"pb-3 pt-2"}>
            <Alert text="Only the gift creator can edit the email delivery method." />
          </div>
        )}

        <RoundedSectionContainer disabled={prohibitToEdit}>
          <Button
            icon={"Goody"}
            disabled={prohibitToEdit}
            selected={currentGift.emailDeliveryMethod === "goody"}
            onClick={() =>
              !prohibitToEdit && setValue(EmailDeliveryMethods.goody)
            }
          >
            <>
              <h3>Email sent by Goody</h3>
              <p tw="font-text">
                Email will be from <br />
                {fromName} via Goody
              </p>
            </>
          </Button>

          <ProPlanProtected feature={"send_via_gmail"}>
            <Button
              icon={"Gmail"}
              disabled={prohibitToEdit}
              selected={currentGift.emailDeliveryMethod === "gmail"}
              onClick={() => {
                if (prohibitToEdit) return
                setValue(EmailDeliveryMethods.gmail)
                if (!canSendEmailOnBehalf) {
                  setGmailAuthorizationModalOpen(true)
                }
              }}
            >
              <>
                <h3>Sent from my own email</h3>
                <p tw="font-text">
                  Email will be from <br />
                  your own email address
                </p>
              </>
            </Button>
          </ProPlanProtected>
        </RoundedSectionContainer>
        <AuthorizeGmailModal
          onRequestClose={() =>
            !canSendEmailOnBehalf && setValue(EmailDeliveryMethods.goody)
          }
        />
      </>
    </Option>
  )
}

interface IButton {
  icon: string
  children: JSX.Element
  selected: boolean
  disabled: boolean
  onClick: () => void
}

const Button = ({ icon, disabled, children, selected, onClick }: IButton) => {
  return (
    <ButtonContainer disabled={disabled} selected={selected} onClick={onClick}>
      {icon === "Goody" && <GoodyIcon />}
      {icon === "Gmail" && <GmailSmIcon />}
      {children}
    </ButtonContainer>
  )
}

const ButtonContainer = styled.div<{ selected: boolean; disabled: boolean }>`
  ${tw`flex flex-col items-center justify-center h-full px-3 pb-6 text-center border border-gray-200 rounded-lg cursor-pointer pt-7 transition-colors active:bg-primary-000`}
  ${(props) => !props.disabled && tw`hover:border-primary-400`}
  border-width: 1.5px;

  h3 {
    margin-top: 8px;
    ${tw`text-gray-500 `}
  }

  p {
    ${tw`pt-2 text-sm text-gray-500 `}
  }

  ${(props) => props.disabled && tw`cursor-not-allowed `}
  ${(props) => props.selected && tw`border-primary-400 bg-primary-000 `}
  ${(props) =>
    props.selected &&
    css`
      h3 {
        ${tw`font-medium text-primary-500`}
        font-weight: 0
      }
    `}
`

const RoundedSectionContainer = styled.div<{ disabled: boolean }>`
  ${tw`py-2 sm:flex-col grid place-content-evenly md:grid-cols-2 gap-5 place-content-stretch`}
  ${(props) => props.disabled && tw`opacity-50 `}
`

export default EmailDeliverySection
