import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import tw from "twin.macro"

import { DiscardGiftButton, PageBanner } from "./components/PageBanner"
import { ReactComponent as ClockIconYellow } from "../assets/icons/clock-yellow-large.svg"
import StyledCloseIcon from "../common/components/StyledCloseIcon"
import ConfirmationModal from "../common/ConfirmationModal"
import { BatchRecipient, CurrentGift } from "../common/GlobalState"
import { generateRealmPath } from "../common/realm"
import { formatDayWithDateAndTime } from "../common/utilities"

interface Props {
  currentGift: CurrentGift
  onDiscardChanges: () => void
  eventRecipient?: BatchRecipient
}

const EditScheduledGiftHeader: React.FC<Props> = ({
  currentGift,
  onDiscardChanges,
  eventRecipient,
}) => {
  const history = useHistory()
  const [openConfirmationModal, setOpenConfirmationModal] =
    useState<boolean>(false)
  const currentBatchName = (currentGift: CurrentGift) => {
    return currentGift.batchName
      ? currentGift.batchName
      : currentGift.scheduledSendOnDate && currentGift.scheduledSendOnTime
      ? "Sending on " +
        formatDayWithDateAndTime(
          currentGift.scheduledSendOnDate,
          currentGift.scheduledSendOnTime,
        )
      : null
  }

  const openModal = () => {
    setOpenConfirmationModal(true)
  }

  const closeConfirmationModal = () => {
    setOpenConfirmationModal(false)
  }

  return (
    <>
      <div tw="p-6 pt-0">
        <PageBanner>
          <ClockIconYellow />
          <div tw="flex items-center">
            <div css={[{ color: "#BD8E00" }, tw`text-xl`]}>
              Editing scheduled gift —{" "}
              <span
                tw="text-gray-900 font-medium ml-1"
                className="data-hj-suppress ph-no-capture fs-mask"
              >
                {currentBatchName(currentGift)}
              </span>
            </div>
          </div>
          <DiscardGiftButton onClick={openModal}>
            <div tw="pr-4">Discard changes</div>
            <StyledCloseIcon />
          </DiscardGiftButton>
        </PageBanner>
      </div>
      <ConfirmationModal
        isOpen={openConfirmationModal}
        onConfirm={() => {
          onDiscardChanges()
          history.push(generateRealmPath("plus", "/track"))
        }}
        onCancel={closeConfirmationModal}
      />
    </>
  )
}

export default EditScheduledGiftHeader
