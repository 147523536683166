import React, { useEffect, useState } from "react"

import LoginModal from "./LoginModal"
import { useGlobalState } from "../../common/GlobalState"
import { useAuth, useCurrentWorkspace } from "../../common/hooks"
import VerifyModal from "../../common/VerifyModal"
import { useRealmRedirect } from "../shared/RealmToggle"

import { RealmTtype } from "@/types/graphql-types"

interface ContextProps {
  login: () => void
  logout: () => void
}
const LoginContext = React.createContext<ContextProps>({
  login: () => undefined,
  logout: () => undefined,
})

export const LoginProvider: React.FC = ({ children }) => {
  const [openLogin, setOpenLogin] = useGlobalState("openLogin")
  const [authOpen, setAuthOpen] = useState(false)
  const [authPhone, setAuthPhone] = useState("")
  const [verifyOpen, setVerifyOpen] = useState(false)

  const { onBusinessClick } = useRealmRedirect()
  const { resetCurrentWorkspace } = useCurrentWorkspace()
  const { handleLogout } = useAuth()

  useEffect(() => {
    if (openLogin) {
      setAuthOpen(true)

      setOpenLogin(false)
    }
  }, [openLogin, setOpenLogin])

  const login = () => setAuthOpen(true)
  const logout = () => {
    handleLogout({
      onSuccess: () => {
        window.location.href = "/"
      },
    })
  }

  return (
    <LoginContext.Provider value={{ login, logout }}>
      {children}
      <LoginModal
        isOpen={authOpen}
        setClosed={() => setAuthOpen(false)}
        onSuccess={(needsPhoneVerify) => {
          if (needsPhoneVerify) {
            setVerifyOpen(true)
          }
          resetCurrentWorkspace()
        }}
        authPhone={authPhone}
        setAuthPhone={setAuthPhone}
      />
      <VerifyModal
        isOpen={verifyOpen}
        setClosed={() => setVerifyOpen(false)}
        phone={authPhone}
        onSuccess={(me) => {
          if (me?.lastRealm === RealmTtype.PLUS) {
            onBusinessClick()
          }
        }}
      />
    </LoginContext.Provider>
  )
}

export const useLogin = () => React.useContext(LoginContext)
