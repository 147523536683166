import { gql } from "@apollo/client"

export const STANDARD_CONTACT_FRAGMENT = gql`
  fragment StandardContact on Contact {
    id
    firstName
    lastName
    title
    company
    phone
    email
    hasPendingAutogifts
    hrisName
    birthday {
      ...ContactScheduledEvent
    }
    workAnniversary {
      ...ContactScheduledEvent
    }
    startDate {
      ...ContactScheduledEvent
    }
    addedToHrisDate {
      ...ContactScheduledEvent
    }
    contactLists {
      id
      name
    }
  }

  fragment ContactScheduledEvent on ScheduledEvent {
    id
    year
    month
    day
  }
`

export const UPDATE_CONTACT_MUTATION = gql`
  mutation Contacts_UpdateContact($contact: ContactInput!, $listIds: [ID!]!) {
    contactUpdate(contact: $contact, listIds: $listIds, segment: PLUS) {
      ok
      error
      errorCode
      contact {
        ...StandardContact
      }
    }
  }
  ${STANDARD_CONTACT_FRAGMENT}
`

export const CONTACT_LISTS_QUERY = gql`
  query Contacts_ContactLists {
    workspace {
      contactLists {
        id
        name
        contactsCount
        hrisSynced
      }
      contactsCount
    }
  }
`

export const CONTACTS_QUERY = gql`
  query Contacts_Contacts(
    $listID: ID
    $limit: Int
    $page: Int
    $searchTerm: String
  ) {
    workspace {
      contactsCount(listId: $listID, searchTerm: $searchTerm)
      contacts(
        listId: $listID
        limit: $limit
        page: $page
        searchTerm: $searchTerm
      ) {
        ...StandardContact
      }
    }
  }
  ${STANDARD_CONTACT_FRAGMENT}
`
