// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Icon2IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Icon2Icon(props: Icon2IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      fill={"none"}
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 32 32"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={"M10.664 15.168a4.95 4.95 0 100-9.899 4.95 4.95 0 000 9.9z"}
        stroke={"#A0C7FF"}
        strokeWidth={"2"}
      ></path>

      <path
        d={
          "M2 27.896c0-6.874 4.124-8.571 9.623-8.571 4.85 0 8.63-4.027 9.454-8.75a2.373 2.373 0 012.356-1.924 2.419 2.419 0 012.381 2.83 15.782 15.782 0 01-5.054 9.147 2.05 2.05 0 00-.499 2.498 10.163 10.163 0 01.984 4.77m8.753-17.819a5.632 5.632 0 00-5.639-5.639"
        }
        stroke={"#A0C7FF"}
        strokeWidth={"2"}
      ></path>
    </svg>
  );
}

export default Icon2Icon;
/* prettier-ignore-end */
