import { ShipmentStatus } from "@/types/graphql-types"
import { TrackGiftBatchRecipientRequestItemFragment } from "@/types/graphql-types"

interface Props {
  gift: TrackGiftBatchRecipientRequestItemFragment["gift"]
}

// Displays shipments for the gift.
// If the gift has multiple shipments, we assume they are from different brands,
// though that might not actually be the case, but it's not a big deal if it
// isn't. When we display multiple shipments, we display each brand name above.
export default function TrackingDetails({ gift }: Props) {
  const showBrand = !!(gift && gift?.shipments?.length > 1)

  return (
    <div tw="pb-4 md:pb-0 text-gray-500 flex flex-col gap-4">
      {!gift?.shipments || gift.shipments.length === 0 ? (
        <div>Preparing for shipment</div>
      ) : (
        gift.shipments.map((shipment) => (
          <div tw="flex-1" key={shipment.id}>
            {showBrand && (
              <div tw="font-medium text-sm pb-[2px]">{shipment.brand.name}</div>
            )}
            <div>
              <a
                href={shipment.trackingUrl || "#"}
                target="_blank"
                rel="noreferrer noopener nofollow"
                tw="hover:text-gray-700 transition-colors"
              >
                {shipment.trackingCarrier} {shipment.trackingNumber}
              </a>
            </div>
            {/* ETA displays as Monday, June 1 */}
            {isInTransit(shipment.status) && shipment.deliveryEta && (
              <div tw="text-gray-400">
                {/* When there are multiple shipments, each might have a different
                status, so display "Shipped" in front of the ETA for each */}
                {showBrand && "Shipped, "}ETA {formatDate(shipment.deliveryEta)}
              </div>
            )}
            {shipment.deliveredAt && (
              <div tw="text-gray-400">
                Delivered {formatDate(shipment.deliveredAt)}
              </div>
            )}
          </div>
        ))
      )}
    </div>
  )
}

function formatDate(isoString: string) {
  const date = new Date(isoString)
  const options: Intl.DateTimeFormatOptions = {
    weekday: "long",
    month: "long",
    day: "numeric",
  }

  return date.toLocaleDateString("en-US", options)
}

function isInTransit(shipmentStatus: ShipmentStatus) {
  return (
    shipmentStatus === ShipmentStatus.pending ||
    shipmentStatus === ShipmentStatus.shipped ||
    shipmentStatus === ShipmentStatus.tracking
  )
}
