import { ReactComponent as TruckIcon } from "@/assets/icons/truck.svg"
import { formatPrice } from "@/common/format"
import GlobalShippingPriceExplanation from "@/store/components/shipping/GlobalShippingPriceExplanation"

interface Props {
  isFlexGift: boolean
  shippingPrice: number | null
  isGlobalShipping?: boolean
  withDomesticShippingSupport?: boolean
}

const ProductShippingLabel = ({
  isFlexGift,
  isGlobalShipping,
  withDomesticShippingSupport,
  shippingPrice,
}: Props) => {
  if (shippingPrice === null) return null

  const formattedPrice =
    shippingPrice === 0 ? "Free" : formatPrice(shippingPrice)
  const firstWord = isFlexGift ? "Shipping" : formattedPrice
  const secondWord = isFlexGift ? "included" : "shipping"

  return (
    <div tw="flex items-center text-gray-500 text-base sm:text-lg pt-[2px]">
      <div>
        <TruckIcon tw="mr-2 relative text-gray-350" />
      </div>
      <div>
        {!isGlobalShipping && (
          <>
            {firstWord} <span tw="hidden lg:inline-block">{secondWord}</span>
          </>
        )}
        {!isFlexGift && isGlobalShipping && (
          <GlobalShippingPriceExplanation
            formattedPrice={shippingPrice !== 0 && `${formattedPrice} shipping`}
            withDomesticSupport={withDomesticShippingSupport}
          />
        )}
      </div>
    </div>
  )
}

export default ProductShippingLabel
