import { useMutation } from "@apollo/client"
import React from "react"
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form"
import { toast } from "react-hot-toast"
import tw from "twin.macro"

import { ReactComponent as ArrowRight } from "../../../assets/icons/arrow-right.svg"
import GradientButton from "../../../common/GradientButton"
import Input from "../../../common/Input"
import { generateUUID } from "../../../common/utilities"
import { REFERRAL_INVITE_SEND } from "../../queries/referrals/ReferralInviteSend"

import { track } from "@/common/analytics"
import { ReferralInviteSendMutation } from "@/types/graphql-types"

interface ReferralInviteFormValues {
  emails: String[]
}

export const ReferralInviteForm = () => {
  const [referralInviteSend] =
    useMutation<ReferralInviteSendMutation>(REFERRAL_INVITE_SEND)
  const {
    control,
    handleSubmit,
    register,
    reset,
    formState: { isDirty, isValid },
  } = useForm<ReferralInviteFormValues>({
    defaultValues: {
      emails: Array(3).fill(""),
    },
  })
  const { fields } = useFieldArray({
    control,
    name: "emails",
  })

  const onSubmit: SubmitHandler<ReferralInviteFormValues> = async ({
    emails,
  }) => {
    track("Business - Account - Referrals - Workspace Invite - Click Invite")

    const { data } = await referralInviteSend({
      variables: { emails },
    })
    const resData = data?.referralInviteSend

    if (resData?.ok && resData?.processedCount && resData?.processedCount > 0) {
      toast.success(
        `${resData.processedCount} referral invite${
          resData.processedCount > 1 ? "s" : ""
        } sent.`,
      )
    } else {
      toast.error(`An error occurred. Some invites may not have been sent.`)
    }
    reset()
  }

  return (
    <ReferralInviteFormContainer id="invite-member-form">
      <div tw="relative flex w-full">
        <div tw="md:mr-24 w-full">
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            {fields.map((field, index) => (
              <InviteInputRow key={field.id}>
                <InviteInputRowInputs>
                  <Input
                    tw="max-w-sm"
                    {...register(`emails.${index}` as "emails.0")}
                    placeholder="Work email"
                    type="text"
                    // Disable autocomplete, at least the first time...
                    autoComplete={generateUUID()}
                    className="data-hj-suppress ph-no-capture fs-exclude"
                  />
                </InviteInputRowInputs>
              </InviteInputRow>
            ))}
            <div tw="flex items-center pt-4">
              <GradientButton
                disabled={!isDirty || !isValid}
                tw="inline-flex gap-10 mr-6"
              >
                Invite <ArrowRight />
              </GradientButton>
            </div>
          </form>
        </div>
      </div>
    </ReferralInviteFormContainer>
  )
}

const ReferralInviteFormContainer = tw.div`
  bg-gray-050 flex mb-12 p-9 pt-12 px-6 lg:px-12 text-gray-500 rounded-xl max-w-[50rem]
 `
const InviteInputRow = tw.div`mb-4 py-1 lg:py-0`
const InviteInputRowInputs = tw.div`grid grid-cols-1 gap-3 mb-1`
