import { gql } from "@apollo/client"

export const GET_AUTOGIFT_PRICE_ESTIMATE_MUTATION = gql`
  mutation AutogiftRuleSelectGifts_PriceEstimate(
    $productID: ID!
    $flexGiftPrice: Int
    $giftCardAmount: Int
  ) {
    priceEstimate(
      productId: $productID
      flexGiftPrice: $flexGiftPrice
      giftCardAmount: $giftCardAmount
      isPlus: true
      plusRecipients: 1
      omitCredit: true
    ) {
      ok
      error
      priceProduct
      priceShipping
      priceEstTaxLow
      priceEstTaxHigh
      priceEstTotalLow
      priceEstTotalHigh
      priceProcessingFee
      isFlexGift
    }
  }
`
