import { isNil } from "lodash-es"
import { Link } from "react-router-dom"
import tw from "twin.macro"

import { ReactComponent as BagIcon } from "../../assets/icons/bag.svg"
import { track } from "../../common/analytics"
import { useCartDrawer } from "../../common/contexts/cartDrawer"
import { useCurrentGift } from "../../common/hooks/currentGift"
import { useGiftCart } from "../../common/hooks/giftData"
import { useAnimatedDisplay } from "../../common/hooks/useAnimatedDisplay"
import { generateRealmPath } from "../../common/realm"
import GiftTemplatesMenu, {
  GiftTemplateType,
} from "../components/giftTemplates/GiftTemplatesMenu"
import { ReactComponent as UndoIcon } from "../images/giftTemplates/undo.svg"
import { ProductGift } from "../ProductGift"

import { Send_PriceEstimateMutation } from "@/types/graphql-types"

interface Props {
  priceEstimate?: Send_PriceEstimateMutation["priceEstimate"] | null
  setGiftMessage: (message: string) => void
}

// Render a product list that goes above the send summary.
// If price estimates are available, each product displays the associated price
// next to the product row. Brand shipping costs are not displayed here, but
// instead displayed in the price estimate table.
export default function ProductList({ priceEstimate, setGiftMessage }: Props) {
  const [currentGift] = useCurrentGift()
  const products = currentGift.cart
  const { openCart } = useCartDrawer()
  const { canAddProductToCart } = useGiftCart()

  const { open: templatesOpen, setOpen: setTemplatesOpen } =
    useAnimatedDisplay()

  const {
    setGiftTemplate: setGiftTemplateOnCart,
    resetGiftTemplate: resetGiftTemplateOnCart,
  } = useGiftCart()

  async function setGiftTemplate(giftTemplate: GiftTemplateType) {
    setGiftMessage(giftTemplate.message)
    setGiftTemplateOnCart(giftTemplate)

    track("Send - Set Gift Template", {
      giftTemplateId: giftTemplate.id,
      category: giftTemplate.category,
      subcategory: giftTemplate.subcategory,
      cardId: giftTemplate.card.id,
      message: giftTemplate.message,
      products: giftTemplate.products.map((product) => {
        return product.id
      }),
      giftCardAmount: giftTemplate.giftCardAmount,
      flexGiftPrice: giftTemplate.flexGiftPrice,
    })
  }

  async function resetTemplate() {
    resetGiftTemplateOnCart(setGiftMessage)
  }

  const usingTemplate = !!currentGift.giftTemplate

  return (
    <div tw="pb-8">
      <div tw="flex flex-col gap-4">
        {products.map((product, index) => (
          <ProductGift
            tw="w-full"
            product={product}
            key={product.id}
            productPrice={
              priceEstimate &&
              !isNil(priceEstimate.productPriceEstimates[index])
                ? priceEstimate.productPriceEstimates[index].priceProduct
                : null
            }
            clickOpensCart={true}
          />
        ))}
      </div>
      <div
        tw="pt-4 flex flex-row items-center justify-center text-[15px] -mx-2 gap-2"
        css={[usingTemplate && tw`justify-between gap-0`]}
      >
        <SecondaryButton onClick={() => openCart()}>
          <BagIcon tw="stroke-current w-4 h-4" />
          Edit bag
        </SecondaryButton>
        <span tw="text-gray-300">&middot;</span>
        {canAddProductToCart && (
          <SecondaryLink to={generateRealmPath("plus", "/browse")}>
            Add{usingTemplate ? "" : " items"}
          </SecondaryLink>
        )}
        {usingTemplate && (
          <>
            <span tw="text-gray-300">&middot;</span>
            <SecondaryButton onClick={() => resetTemplate()}>
              <UndoIcon tw="stroke-current w-4 h-4" />
              Reset template
            </SecondaryButton>
          </>
        )}
      </div>
      <div tw="relative z-[100]">
        <div
          tw="z-[1000] bg-[rgba(255, 255, 255, 0.8)] fixed inset-0 opacity-0 transition-opacity pointer-events-none backdrop-blur-none"
          css={[
            templatesOpen &&
              tw`pointer-events-auto opacity-100 backdrop-blur-sm`,
          ]}
          onClick={(e) => {
            if (e.target === e.currentTarget) {
              e.stopPropagation()
              setTemplatesOpen(false)
            }
          }}
        />
        <div
          tw="absolute right-[-0.5rem] w-[500px] max-w-[80vw] bg-[#f6faff] rounded-xl z-[1020] scale-90 opacity-0 pointer-events-none transition-all origin-top lg:(origin-[80% top])"
          css={[templatesOpen && tw`scale-100 opacity-100 pointer-events-auto`]}
        >
          <GiftTemplatesMenu
            onSelect={(giftTemplate: GiftTemplateType) => {
              setGiftTemplate(giftTemplate)
              setTemplatesOpen(false)
            }}
          />
        </div>
      </div>
    </div>
  )
}

const buttonBase =
  "px-2 py-1 rounded-lg hover:(bg-gray-200 text-gray-700) active:(scale-95) transition-all duration-100 ease-out text-gray-600 flex flex-row items-center gap-2"
const SecondaryButton = tw.button`${buttonBase}`
const SecondaryLink = tw(Link)`${buttonBase}`
