import { styled } from "twin.macro"

import ProductsExamples from "./ProductsExamples"
import globalBackground from "../assets/global-background.png"

import Image from "@/common/Image"

const GlobalProductsHeader = () => {
  return (
    <>
      <GradientBackgroundImage
        src={globalBackground}
        alt="Global background"
        tw="object-center object-cover h-48 w-full"
      />

      <ProductsExamples />
    </>
  )
}

const GradientBackgroundImage = styled(Image)`
  background: linear-gradient(
      180deg,
      rgba(192, 213, 238, 0.18) 81.32%,
      #c0d5ee 211.21%
    ),
    #fff;
`

export default GlobalProductsHeader
