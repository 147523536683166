import { useQuery } from "@apollo/client"
import React from "react"
import { Helmet } from "react-helmet-async"
import { useParams, useRouteMatch } from "react-router-dom"

import { GiftBatchSettingsProvider } from "./GiftBatchSettings/hooks"
import { GiftBatchSettings } from "./GiftBatchSettings/index"
import { getGiftBatchTitle } from "./utils"
import RealmRoute from "../../../common/RealmRoute"
import Recipients from "../../Recipients"

import GiftBatchHeader from "@/track/components/giftBatch/GiftBatchHeader"
import GiftBatchOverview from "@/track/components/giftBatch/GiftBatchOverview"
import { TRACK_WORKSPACE_GIFT_BATCH_QUERY } from "@/track/components/giftBatch/graphql/TrackWorkspaceGiftBatchQuery"
import {
  TrackWorkspaceGiftBatchQuery,
  TrackWorkspaceGiftBatchQueryVariables,
} from "@/types/graphql-types"

interface Params {
  giftBatchId: string
}

export const GiftBatchV2 = () => {
  const { giftBatchId } = useParams<Params>()
  const { data, loading, refetch } = useQuery<
    TrackWorkspaceGiftBatchQuery,
    TrackWorkspaceGiftBatchQueryVariables
  >(TRACK_WORKSPACE_GIFT_BATCH_QUERY, {
    variables: {
      id: giftBatchId,
    },
    fetchPolicy: "cache-and-network",
  })

  const loadingVisible = loading && !data
  const giftBatch = data?.workspace?.giftBatch
  const title = getGiftBatchTitle(giftBatch)

  const routeMatch = useRouteMatch()

  return (
    <>
      <Helmet>
        <title>
          {title !== "" ? title + " – " : ""}Gift Batch – Goody for Business
        </title>
      </Helmet>
      <GiftBatchHeader
        title={title}
        loadingVisible={loadingVisible}
        giftBatchId={giftBatchId}
        giftBatch={giftBatch}
      />
      <RealmRoute exact path={`${routeMatch.url}`} realm={"plus"}>
        <GiftBatchOverview
          giftBatchID={giftBatchId}
          giftBatch={giftBatch}
          data={data}
          loading={loading}
          loadingVisible={loadingVisible}
          refetch={refetch}
        />
      </RealmRoute>
      <RealmRoute exact path={`${routeMatch.url}/recipients`} realm={"plus"}>
        <Recipients giftBatchId={giftBatchId} />
      </RealmRoute>
      <RealmRoute exact path={`${routeMatch.url}/settings`} realm={"plus"}>
        <GiftBatchSettingsProvider>
          <GiftBatchSettings giftBatch={giftBatch} />
        </GiftBatchSettingsProvider>
      </RealmRoute>
    </>
  )
}
