interface NavTextProps {
  children: string
}
const NavText: React.FC<NavTextProps> = ({ children }) => (
  <div tw="flex flex-col items-center">
    <span tw="whitespace-nowrap">{children}</span>
    <span tw="font-medium h-0 invisible">{children}</span>
  </div>
)

export default NavText
