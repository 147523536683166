import { useEffect } from "react"

const FONTSTAND_CSS_URL =
  "https://webfonts.fontstand.com/WF-083229-8a4961abafa308b3fd06d5256d7136e0.css"

const Fontstand = () => {
  useEffect(() => {
    let link: HTMLLinkElement | null =
      document.querySelector("link[font-stand]")
    if (link) return

    link = document.createElement("link")
    link.href = FONTSTAND_CSS_URL
    link.type = "text/css"
    link.rel = "stylesheet"
    link.setAttribute("font-stand", "")
    document.head.appendChild(link)
  }, [])

  return null
}

export default Fontstand
