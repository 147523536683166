import React, { useState } from "react"

import { ReactComponent as SettingsIcon } from "../../assets/icons/autogift-config-settings.svg"
import { ReactComponent as UpcomingIcon } from "../../assets/icons/autogift-config-upcoming.svg"
import NavText from "../../track/components/NavText"
import Tabs, { TabButton } from "../../track/components/Tabs"

export type AutogiftConfigSwitchOption = "Upcoming" | "Settings"

interface AutogiftConfigSwitchProps {
  initialSelection: AutogiftConfigSwitchOption
  onSelectSwitch: (selection: AutogiftConfigSwitchOption) => void
}

const AutogiftConfigSwitch: React.FC<AutogiftConfigSwitchProps> = ({
  initialSelection,
  onSelectSwitch,
}) => {
  const [currentSelection, setCurrentSelection] = useState(initialSelection)

  const handleSwitchClick = (selection: AutogiftConfigSwitchOption) => {
    setCurrentSelection(selection)
    onSelectSwitch(selection)
  }

  return (
    <Tabs>
      <TabButton
        onClick={() => handleSwitchClick("Upcoming")}
        active={currentSelection === "Upcoming"}
      >
        <UpcomingIcon />
        <NavText>Upcoming</NavText>
      </TabButton>
      <TabButton
        onClick={() => handleSwitchClick("Settings")}
        active={currentSelection === "Settings"}
      >
        <SettingsIcon />
        <NavText>Settings</NavText>
      </TabButton>
    </Tabs>
  )
}

export default AutogiftConfigSwitch
