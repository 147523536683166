import { useEffect, useState } from "react"
import tw, { css, styled } from "twin.macro"

interface Props {
  cardImageUrl: string
  product1ImageUrl: string
  product2ImageUrl: string
  cardTimingOffset: number
  product1TimingOffset: number
  product2TimingOffset: number
  // How much to nudge the card relative to the center
  // The animation is offset on the x axis in the design, so to get the cards to
  // be centered, this must be set to the inverse of the offset
  cardNudge?: number
  className?: string
  // Is this on the right side? If so, sets transform origin
  isOnRightSide?: boolean
}

const CARD_FADE_OUT_DELAY_SECONDS = 3

// Shows a product animation with a card and two products
// First, it shows the card, and then it wipes to the first product
// After some seconds, it wipes to the second product
export default function HeroProductAnimation({
  cardImageUrl,
  product1ImageUrl,
  product2ImageUrl,
  cardTimingOffset,
  product1TimingOffset,
  product2TimingOffset,
  cardNudge,
  className,
  isOnRightSide,
}: Props) {
  const [canHover, setCanHover] = useState(false)
  const [scaleFactor, setScaleFactor] = useState(1)

  function calculateScaleFactor() {
    // The size of the animation is scaled to the user's screen size
    // If the user's screen is smaller than 1440px, up to 1120px,
    // scale this container down from 1440px = 100% to 1120px = 70%
    // gradually from 100% to 70%
    const factor = Math.min(
      1,
      0.7 + (0.3 * (window.innerWidth - 1120)) / (1440 - 1120),
    )

    setScaleFactor(factor)
  }

  useEffect(() => {
    calculateScaleFactor()
  }, [])

  // Calculate scale factor on resize
  useEffect(() => {
    window.addEventListener("resize", calculateScaleFactor)
    return () => window.removeEventListener("resize", calculateScaleFactor)
  }, [])

  // canHover gets triggered to true after cardTimingOffset + CARD_FADE_OUT_DELAY_SECONDS
  // This is to prevent the hover effect from showing before the card is fully faded out
  useEffect(() => {
    const timeout = setTimeout(
      () => {
        setCanHover(true)
      },
      (cardTimingOffset + CARD_FADE_OUT_DELAY_SECONDS) * 1000,
    )

    return () => clearTimeout(timeout)
  }, [cardTimingOffset])

  return (
    <AnimationContainer
      className={className}
      canHover={canHover}
      scaleFactor={scaleFactor}
      isOnRightSide={isOnRightSide || false}
    >
      <CardImage
        src={cardImageUrl}
        alt=""
        timingOffset={cardTimingOffset}
        cardNudge={cardNudge || 0}
      />
      <ProductImage
        timingOffset={product1TimingOffset}
        fadeOutAfter={product2TimingOffset}
        src={product1ImageUrl}
        alt=""
        tw="z-[1]"
      />
      <ProductImage
        timingOffset={product2TimingOffset}
        src={product2ImageUrl}
        alt=""
        tw="z-[2]"
      />
      <CardImageHover src={cardImageUrl} alt="" cardNudge={cardNudge || 0} />
    </AnimationContainer>
  )
}

const CardBase = css<{ cardNudge: number }>`
  ${tw`rounded-lg`}

  // This image should be centered within the container vertically and horizontally
  // Since the container size is known, we can position the image absolutely with known values
  // We don't want to use transform since we are going to affect its transform later
  position: absolute;
  top: 25px;
  left: ${(props) => props.cardNudge + 116}px;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.05);
  width: 148px;
  height: 209px;
`

const CardImage = styled.img<{ timingOffset: number; cardNudge: number }>`
  ${CardBase};

  z-index: 0;
  opacity: 0;
  transform: scale(0.8);

  // On entry, the card goes from 0 opacity to 1, and 80% scale to 100% scale
  // The easing is Ease In Out Back (0.68, -0.6, 0.32, 1.6)
  // The animation takes 0.8 seconds
  animation:
    entry 0.8s cubic-bezier(0.68, -0.6, 0.32, 1.6)
      ${(props) => props.timingOffset}s forwards,
    fadeOut 1s ease-in-out
      ${(props) => props.timingOffset + CARD_FADE_OUT_DELAY_SECONDS}s forwards;

  @keyframes entry {
    from {
      opacity: 0;
      transform: scale(0.8);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
`

// This special component shows the card when the container is hovered
// It only activates after the initial card animation has been completed, with
// a visibility definition on AnimationContainer
// We need a separate component to avoid interfering with the original card's
// animation directives
const CardImageHover = styled.img<{ cardNudge: number }>`
  ${CardBase};
  z-index: 4;
  opacity: 0;
  transform: scale(0.9);

  transition:
    opacity 0.3s ease-in-out,
    transform 0.3s ease-in-out;
`

const AnimationContainer = styled.div<{
  canHover: boolean
  isOnRightSide: boolean
  scaleFactor: number
}>`
  ${tw`w-[380px] h-[260px] relative`};

  // The hover image should be visible only when the container can be hovered
  ${CardImageHover} {
    visibility: ${(props) => (props.canHover ? "visible" : "hidden")};
  }

  &:hover {
    ${CardImageHover} {
      opacity: 1;
      transform: scale(1);

      // Use a custom Ease In Out Back easing for the hover effect,
      // but not the hover blur
      transition:
        opacity 0.3s cubic-bezier(0.68, -0.6, 0.32, 1.6),
        transform 0.3s cubic-bezier(0.68, -0.6, 0.32, 1.6);
    }
  }

  transform: scale(${(props) => props.scaleFactor});
  transform-origin: ${(props) => (props.isOnRightSide ? "right" : "left")};

  // Below 1120px, hide this, there's not enough space
  @media (max-width: 1120px) {
    display: none;
  }
`

const ProductImage = styled.img<{
  timingOffset: number
  fadeOutAfter?: number
}>`
  ${tw`rounded-xl absolute`}

  // This image should take up the entirety of the container
  width: 100%;
  height: 100%;
  object-fit: cover;

  // There is a wipe animation that moves a mask-image toward the right
  // The mask-image has three parts, each of which is the width of the image,
  // making the mask-image 3 times the width of the image
  // 1 2 3
  //
  // 3 is the starting position since the wipe is moving to the right.
  //
  // Stage 3 is a fully transparent section; this makes the image invisible
  // Stage 2 is a linear gradient from transparent to opaque; this makes the
  //   image appear from transparent to opaque with a wipe effect
  // Stage 1 is a fully opaque section; this makes the image fully visible
  //
  // The mask-image is animated to move to the right, revealing the image
  // The ending position shows stage 1, making the image fully visible
  mask-image: linear-gradient(
    to right,
    // Stage 1: opaque
    #000 0%,
    // Stage 2: opaque to transparent gradient
    #000 33.33%,
    transparent 66.66%,
    // Stage 3: transparent
    transparent 100%
  );
  mask-size: 300% 100%;

  // Starting positions
  mask-position: right 0;

  // The mask-image is animated to move to the right
  // The animation takes 1.2 seconds
  // The easing is Ease In Out Quad (0.5, 0, 0.5, 1)
  // The animation is delayed by the timing offset
  animation:
    wipe 1.2s cubic-bezier(0.5, 0, 0.5, 1) forwards,
    wipeTransform 1.2s cubic-bezier(0.5, 0, 0.5, 1) forwards;
  animation-delay: ${(props) => props.timingOffset}s;

  @keyframes wipe {
    from {
      mask-position: right 0;
    }
    to {
      mask-position: left 0;
    }
  }

  @keyframes wipeTransform {
    from {
      transform: translateX(-24px);
    }
    to {
      transform: translateX(0);
    }
  }

  // If a fade out is requested, fade out the image after the given delay
  // This is useful for the first product image fading out to lead into the
  // second product image
  ${(props) =>
    props.fadeOutAfter &&
    `
    animation: wipe 1.2s cubic-bezier(0.5, 0, 0.5, 1) ${props.timingOffset}s forwards, wipeTransform 1.2s cubic-bezier(0.5, 0, 0.5, 1) ${props.timingOffset}s forwards, fadeOut 1s ease-in-out ${props.fadeOutAfter}s forwards;
  `};

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
`
