import { useContext } from "react"

import { DismissedScreensContext } from "../contexts/dismissedScreens"

export const useDismissedScreensContext = () => {
  const context = useContext(DismissedScreensContext)

  if (context === undefined || context === null) {
    throw new Error(
      "useDismissedScreensContext must be used within DismissedScreensContext",
    )
  }

  return context
}
