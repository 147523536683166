import MainContainer from "./MainContainer"
import VerticalPurpleBanner from "./VerticalPurpleBanner"
import teamBubbles from "../images/team-bubbles.png"

const WorkspaceSetup: React.FC = ({ children }) => {
  return (
    <div tw="container mx-auto lg:grid grid-cols-8">
      <VerticalPurpleBanner tw="col-span-3">
        <div className="text">
          <h1>
            <small>Welcome</small>
            You're almost there
          </h1>
          <p>
            Set up your first workspace.
            <br />
            Workspaces let you gift collaboratively with your team and share
            activity.
          </p>
        </div>
        <img
          src={teamBubbles}
          alt="Workspace member bubbles"
          css={{ width: 387, maxWidth: "none" }}
          tw="mr-28 mt-10 mb-24 hidden lg:inline-block"
        />
      </VerticalPurpleBanner>
      <MainContainer tw="col-span-5">{children}</MainContainer>
    </div>
  )
}

export default WorkspaceSetup
