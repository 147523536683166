import {
  Identify as AmplitudeIdentify,
  identify as amplitudeIdentify,
  init as amplitudeInit,
  reset as amplitudeReset,
  setUserId as amplitudeSetUserId,
  track as amplitudeTrack,
} from "@amplitude/analytics-browser"
import {
  event as fullstoryEvent,
  identify as fullstoryIdentify,
  init as fullstoryInit,
  isInitialized as fullstoryIsInitialized,
  shutdown as fullstoryShutdown,
} from "@fullstory/browser"
import LogRocket from "logrocket"
import posthog from "posthog-js"
import { useEffect } from "react"
import { Statsig } from "statsig-react"

import { getEnvVars, isBot } from "./environment"
import { snakecaseKeys } from "./format"
import { useCurrentPathRealm } from "./realm"

export const STATSIG_OPTIONS = Object.freeze({
  environment: { tier: getEnvVars().statsigEnvironment },
})

const amplitudeApiKey = getEnvVars().amplitudeApiKey
if (amplitudeApiKey) {
  amplitudeInit(amplitudeApiKey)
}

const isDev = getEnvVars().name === "development"

let fullstoryInitialized = false

const fullstoryOrgId = getEnvVars().fullstoryOrgId
if (fullstoryOrgId && !fullstoryIsInitialized()) {
  fullstoryInit({ orgId: fullstoryOrgId })
  fullstoryInitialized = true
}

// To send live analytics in dev, toggle the comments below.
const sendLiveAnalytics = !isDev
// const sendLiveAnalytics = true;

const LOG_STYLE = "background: #eee; color: #555;"

type Properties = Record<string, any>

window._mfq = window._mfq || []

posthog.init("phc_Fr9NVouv5FPB9i5KHb0HcYXiQ1LabgSdKMhYhY1wdYi", {
  api_host: "https://us.posthog.com",
  session_recording: { blockSelector: ".pac-container" },
})

LogRocket.init("qtkqwi/goody", {
  dom: {
    privateClassNameBlocklist: ["ph-no-capture", "pac-container"],
  },
  network: {
    requestSanitizer: (request) => {
      request.headers = {}
      request.body = ""
      return request
    },
    responseSanitizer: (response) => {
      response.headers = {}
      response.body = ""
      return response
    },
  },
})

// Identify a user when the app loads or on login.
export function identify(userID: string, properties?: Properties) {
  const snakecaseProperties = properties ? snakecaseKeys(properties) : undefined

  if (isDev) {
    console.log(
      "%c Analytics Identify ",
      LOG_STYLE,
      userID,
      snakecaseProperties,
    )
  }
  if (!sendLiveAnalytics) return

  amplitudeSetUserId(userID)

  const amplitudeId = new AmplitudeIdentify()
  for (const key in snakecaseProperties) {
    amplitudeId.set(key, snakecaseProperties[key])
  }
  amplitudeIdentify(amplitudeId)

  Statsig.updateUser({
    userID,
    custom: {
      plus_enrolled_at: snakecaseProperties?.plus_enrolled_at,
    },
  })

  if (fullstoryInitialized) {
    fullstoryIdentify(userID, snakecaseProperties)
  }

  if (window.hj) {
    window.hj("identify", userID, snakecaseProperties)
  }

  if (window._mfq) {
    window._mfq.push(["setVariable", "Identify", userID])
  }

  posthog.identify(userID, snakecaseProperties)

  LogRocket.identify(userID, snakecaseProperties)
}

// Track an event.
export function track(name: string, properties?: Properties) {
  const snakecaseProperties = properties ? snakecaseKeys(properties) : undefined

  const nameWithPrefix = "Web - " + name

  if (isDev) {
    console.log(
      "%c Analytics Track ",
      LOG_STYLE,
      nameWithPrefix,
      snakecaseProperties,
    )
  }
  if (!sendLiveAnalytics) return

  amplitudeTrack(nameWithPrefix, snakecaseProperties)
  Statsig.logEvent(nameWithPrefix, undefined, snakecaseProperties)

  if (fullstoryInitialized) {
    fullstoryEvent(nameWithPrefix, snakecaseProperties || {})
  }

  funnelyticsTrack(nameWithPrefix, snakecaseProperties)

  if (window.hj) {
    window.hj("event", nameWithPrefix)
  }

  posthog.capture(nameWithPrefix, snakecaseProperties)

  LogRocket.track(nameWithPrefix, snakecaseProperties)
}

// Track a screen, a special type of event suffixed by - Screen.
export function screen(name: string, properties?: Properties) {
  track(name + " - Screen", properties)
}

// Reset on logout.
export function reset() {
  if (isDev) {
    console.log("%c Analytics Reset ", LOG_STYLE)
  }
  if (!sendLiveAnalytics) return

  amplitudeReset()
  Statsig.shutdown()

  if (fullstoryInitialized) {
    fullstoryShutdown()
  }

  posthog.reset()
}

// Hook to track a screen when it mounts.
export const useScreen = (name: string, properties?: Properties) => {
  useEffect(() => {
    screen(name, properties)
  }, [])
}

// Hook to track a screen when it mounts.
export const useScreenIfVisible = (
  name: string,
  visible: boolean,
  properties?: Properties,
) => {
  useEffect(() => {
    if (visible) {
      screen(name, properties)
    }
  }, [visible])
}

export const funnelyticsTrack = (event: string, data?: Properties) => {
  if (isBot() || !window.funnelytics) {
    return
  }

  window.funnelytics.events.trigger(event, data)
}

export const useSegmentPrefix = () => {
  const realm = useCurrentPathRealm()
  return realm === "consumer" ? "Consumer - " : "Business - "
}
