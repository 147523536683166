import React, { useState } from "react"

import CSVMatchColumns from "./CSVMatchColumns"
import { FIELDS, FieldType, getSuggestedField } from "./fields"
import DrawerCloseButton from "../../common/DrawerCloseButton"
import { isBlank } from "../../common/format"
import { BatchRecipient } from "../../common/GlobalState"
import { generateUUID } from "../../common/utilities"
import CSVUpload from "../CSVUpload"

interface Props {
  onClickClose: () => void
  onSetRecipients: (recipients: BatchRecipient[]) => void
}

type ImportedRows = string[][] | null
type ImportedRowHeaders = string[] | null
type MatchedFields = (null | FieldType)[] | null

// The CSV modal allows a sender to upload a CSV (or XLS/XLSX) file with a list
// of their recipients.
//
// If the matched fields are all known columns, i.e. we were able to
// automatically identify all of the columns in the CSV, we simply import the
// list of recipients and close the modal.
//
// Otherwise, if there are unknown columns in the CSV, we display a column
// matching UI to allow them to set the right columns.
export default function CSVModal({ onClickClose, onSetRecipients }: Props) {
  const [page, setPage] = useState<"upload" | "matchColumns">("upload")

  // These are the rows and headers that are imported from the CSV/XLSX.
  // Headers can be null if the document didn't have headers.
  const [importedRows, setImportedRows] = useState<ImportedRows>(null)
  const [importedRowHeaders, setImportedRowHeaders] =
    useState<ImportedRowHeaders>(null)

  // Array of fields in the index column that they were matched to. e.g.
  // if the first column is "First name", the first value in this array
  // will be "First name". If there is no match, the entry will be null.
  const [matchedFields, setMatchedFields] = useState<MatchedFields>(null)

  const setRowsFromUpload = (rows: string[][]) => {
    const headers = rows[0]
    const newMatchedFields = Array(headers.length)

    // Find the field we suggest for the header value
    headers.forEach((header, index) => {
      newMatchedFields[index] = getSuggestedField(String(header))
    })

    let finalRows = rows

    if (newMatchedFields.some((header) => !!header)) {
      // If we found at least one field, we assume there's a header row
      setImportedRowHeaders(headers)
      finalRows = rows.slice(1)
    } else {
      // If nothing found, we assume no header row and they have to match manually
      setImportedRowHeaders(null)
    }

    if (matchedFieldsContainsUnknownColumns(newMatchedFields)) {
      // Unknown columns: set rows and move to match columns page
      setImportedRows(finalRows)
      setMatchedFields(newMatchedFields)
      setPage("matchColumns")
    } else {
      // No unknown columns: set recipients directly
      onSetRecipients(generateRecipients(finalRows, newMatchedFields))
    }
  }

  const onSubmit = () => {
    if (!matchedFields) {
      alert("No matched fields")
      return
    }

    onSetRecipients(generateRecipients(importedRows, matchedFields))
  }

  return (
    <div
      className="modal-content modal-content-wide"
      tw="max-h-[90vh] overflow-y-auto"
    >
      <div tw="p-6">
        <div tw="flex flex-row items-start justify-between">
          <div tw="text-2xl font-medium text-primary-500 pb-6">
            Upload a CSV
          </div>
          <div tw="-mt-2 -mr-2">
            <DrawerCloseButton onClick={onClickClose} />
          </div>
        </div>
        {page === "upload" && <CSVUpload setRows={setRowsFromUpload} />}
        {page === "matchColumns" && (
          <CSVMatchColumns
            rows={importedRows}
            rowHeaders={importedRowHeaders}
            matchedFields={matchedFields}
            setMatchedFields={setMatchedFields}
            onContinue={onSubmit}
          />
        )}
      </div>
    </div>
  )
}

// Matched fields have unknown columns if there are some matched fields that
// are null (i.e. we were not able to automatically determine the type of the
// column).
function matchedFieldsContainsUnknownColumns(matchedFields: MatchedFields) {
  return !matchedFields || matchedFields.some((field) => field === null)
}

function generateRecipients(
  importedRows: ImportedRows,
  matchedFields: NonNullable<MatchedFields>,
) {
  // matchedFields contains an array where the array index is the column index
  // of the importedRows, and the value is the name of the matched field name
  // or null. The matched field name can be used to index FIELDS.
  //
  // This mapping just converts the string field names to the field info coming
  // from the FIELDS object. Then, for each row, we can just loop through each
  // column of the row and pull the field info from this array.
  const matchedFieldsInfo = matchedFields.map((fieldName) => {
    if (fieldName) {
      const fieldInfo = FIELDS.get(fieldName)

      if (fieldInfo) {
        return fieldInfo
      }
    }

    return null
  })

  return (
    (importedRows
      ?.map((row) => {
        let recipient: BatchRecipient = {
          id: generateUUID(),
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          errors: {},
        }

        // Loop through all matched fields. If they resolve to a valid field,
        // set the corresponding field on the recipient.
        for (const fieldIndex in matchedFields) {
          const fieldInfo = matchedFieldsInfo[fieldIndex]
          if (fieldInfo) {
            // inputName contains the field to write to recipient
            // row[fieldIndex] contains the contents of the row cell for this field

            const value = fieldInfo.toInputValue
              ? fieldInfo.toInputValue(row[fieldIndex])
              : row[fieldIndex]

            const topLevelField = fieldInfo.isMailingAddress
              ? "mailingAddress"
              : (fieldInfo.inputName as
                  | "firstName"
                  | "lastName"
                  | "email"
                  | "phone")

            if (topLevelField === "mailingAddress") {
              recipient["mailingAddress"] ||= {
                address1: "",
                address2: null,
                city: "",
                state: "",
                postalCode: "",
              }

              recipient["mailingAddress"][
                fieldInfo.inputName as
                  | "address1"
                  | "address2"
                  | "city"
                  | "state"
                  | "postalCode"
              ] = value
            } else {
              recipient[topLevelField] = value
            }
          }
        }

        if (
          isBlank(recipient.firstName) &&
          isBlank(recipient.lastName) &&
          isBlank(recipient.email) &&
          isBlank(recipient.phone)
        ) {
          return null
        }

        return recipient
      })
      .filter((el) => el !== null) as BatchRecipient[]) || []
  )
}
