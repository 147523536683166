import { AutogiftRuleProduct } from "@/types/graphql-types"

export const getAutogiftProductPrice = (
  productId: string,
  productList: AutogiftRuleProduct[],
) => {
  const selected_product = productList.find(
    (product) => product.productId === productId,
  )

  return selected_product?.priceEstimate?.priceProduct || null
}
