import { gql, useQuery } from "@apollo/client"
import React from "react"
import { Helmet } from "react-helmet-async"
import tw from "twin.macro"

import StoredValueTable from "./StoredValueTable"
import { getDateAsCurrentTimeZone } from "../common/format"

import { Organization_StoredValueMonthsQuery } from "@/types/graphql-types"

const StoredValue: React.FC = () => {
  const { data, loading } = useQuery<Organization_StoredValueMonthsQuery>(
    STORED_VALUE_MONTHS_QUERY,
  )

  const months = [
    ...(data?.organization?.storedValueTransactionsMonths || []),
  ].reverse() as string[]

  const [selectedMonth, setSelectedMonth] = React.useState<string | null>(null)

  return (
    <div>
      <Helmet>
        <title>Stored Value – Goody for Business</title>
      </Helmet>
      <div tw="container mx-auto bg-white min-h-[80vh]">
        <div tw="p-6">
          <div tw="py-6">
            <div tw="flex flex-col lg:flex-row items-start gap-6">
              <div tw="w-full lg:w-1/5">
                <h1 tw="font-medium text-3xl">Stored Value</h1>
                <div tw="pt-6">
                  {loading ? (
                    <div tw="text-gray-400">Loading...</div>
                  ) : (
                    months.map((month) => (
                      <button
                        key={month}
                        tw="w-full block text-left py-2 px-4 hover:bg-gray-100 active:scale-95 transition-all -ml-4 rounded-lg mb-px"
                        css={[
                          month === selectedMonth
                            ? tw`bg-primary-new-050 hover:bg-primary-new-050 text-primary-new-600`
                            : null,
                        ]}
                        onClick={() => setSelectedMonth(month)}
                      >
                        {getDateAsCurrentTimeZone(month).toLocaleString(
                          "default",
                          {
                            month: "long",
                            year: "numeric",
                          },
                        )}
                      </button>
                    ))
                  )}
                </div>
              </div>
              <div tw="flex-1">
                {selectedMonth ? (
                  <StoredValueTable month={selectedMonth} key={selectedMonth} />
                ) : (
                  <h1 tw="text-gray-400 text-3xl">Select a month</h1>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const STORED_VALUE_MONTHS_QUERY = gql`
  query Organization_StoredValueMonths {
    organization {
      id
      storedValueTransactionsMonths
    }
  }
`

export default StoredValue
