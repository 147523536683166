import React, { useEffect, useRef, useState } from "react"
import tw, { styled } from "twin.macro"

import trackEditIcon from "@/assets/icons/track-edit.svg"

const InlineEditLabel = styled.div``

const InlineEditButton = styled.button`
  ${tw`flex items-center justify-center bg-white w-8 h-8 rounded-lg transition-opacity transition-shadow absolute left-2 -top-4 opacity-0`}
  &:hover {
    box-shadow:
      0px 4px 4px rgba(0, 0, 0, 0.04),
      0px 8px 30px rgba(0, 0, 0, 0.06);
  }
`

const InlineEditInput = styled.input`
  ${tw`w-full font-medium rounded-lg bg-white p-2 py-1 focus:outline-none focus:ring ring-blue-500 ring-opacity-30 border border-transparent ml-[-18px]`}
`

const InlineEditContainer = styled.div`
  ${tw`flex items-center justify-center`}

  ${InlineEditInput} {
    ${tw`hidden`}
  }

  &:hover {
    ${InlineEditButton} {
      ${tw`opacity-100`}
    }
  }
  &.editing {
    ${tw`-my-1`}
    ${InlineEditLabel} {
      ${tw`hidden`}
    }
    ${InlineEditButton} {
      ${tw`hidden`}
    }
    ${InlineEditInput} {
      ${tw`block`}
    }
  }
`

interface Props {
  label: string
  emptyValue: string
  initialValue: string
  onSubmit: (value: string) => void
  disabled: boolean
}

const GiftBatchInlineEditField: React.FC<Props> = ({
  label,
  emptyValue,
  initialValue,
  onSubmit,
  disabled,
}) => {
  const [value, setValue] = useState("")
  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])
  const [displayLabel, setDisplayLabel] = useState("")
  useEffect(() => {
    setDisplayLabel(label)
  }, [label])
  const [editing, setEditing] = useState(false)
  const inputEl = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    if (editing && inputEl.current) {
      inputEl.current.focus()
    }
  }, [editing])

  const handleToggleEdit = () => {
    setEditing(!editing)
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === "Enter") {
      setDisplayLabel(value)
      handleToggleEdit()
      onSubmit(value)
    }
    if (e.key === "Escape") {
      handleCancel()
      handleToggleEdit()
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }

  const handleCancel = () => {
    setEditing(false)
    setValue(initialValue)
  }

  return (
    <InlineEditContainer className={editing ? "editing" : ""}>
      <InlineEditLabel>
        <span tw="inline-flex items-center">
          {displayLabel || emptyValue}
          <div tw="inline-flex relative w-0 h-0">
            <InlineEditButton onClick={handleToggleEdit}>
              <img src={trackEditIcon} alt="Edit" />
            </InlineEditButton>
          </div>
        </span>
      </InlineEditLabel>
      <InlineEditInput
        ref={inputEl}
        value={value}
        disabled={disabled}
        onBlur={handleCancel}
        onKeyUp={handleKeyPress}
        onChange={handleChange}
        className="data-hj-suppress ph-no-capture fs-mask"
      />
    </InlineEditContainer>
  )
}
export default GiftBatchInlineEditField
