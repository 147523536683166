import { gql } from "@apollo/client"

export const UPDATE_GIFT_BATCH_MUTATION = gql`
  mutation GiftBatchUpdateSentBatch(
    $giftBatch: GiftBatchUpdateSentBatchInput!
  ) {
    giftBatchUpdateSentBatch(giftBatchInfo: $giftBatch) {
      ok
    }
  }
`
