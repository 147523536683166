import React from "react"
import tw, { styled } from "twin.macro"

import PurpleGradientLink from "../../common/PurpleGradientLink"
import { generateRealmPath } from "../../common/realm"
import Triangles from "../assets/triangles.png"

const TrialUpgradePromo: React.FC = () => {
  return (
    <BoxContainer>
      <Box>
        <div tw="text-2xl font-medium" css={{ color: "#7f67ff" }}>
          Subscribe to Teams
        </div>
        <div tw="text-lg pt-3 text-gray-500">
          Convert your free trial to a paid plan. You’ll keep your remaining
          trial days.
        </div>
        <div tw="pt-8">
          <ul tw="list-disc pl-4 text-lg leading-relaxed">
            <li>
              <span tw="font-medium">Delight employees.</span> Automatic
              birthday and work anniversary gifts.
            </li>
            <li>
              <span tw="font-medium">Autogifting.</span> Significantly reduce
              manual work with gift automation.
            </li>
            <li>
              <span tw="font-medium">HR integrations.</span> Automatic
              onboarding and offboarding management.
            </li>
            <li>
              <span tw="font-medium">Retain, engage, and reward</span> employees
              at scale.
            </li>
          </ul>
          <div tw="pt-8">
            <PurpleGradientLink
              to={generateRealmPath("plus", "/subscribe-team")}
            >
              Subscribe
            </PurpleGradientLink>
          </div>
        </div>
      </Box>
    </BoxContainer>
  )
}

const BoxContainer = styled.div`
  ${tw`border border-gray-150 shadow-min rounded-xl mb-6`};
  background: linear-gradient(
      250deg,
      rgba(134, 105, 255, 0.39) -60%,
      rgba(255, 215, 251, 0) 60%
    ),
    #fff;
`

const Box = styled.div`
  ${tw`p-9`};
  background-image: url(${Triangles});
  background-repeat: no-repeat;
  background-position: bottom right;
`

export default TrialUpgradePromo
