import tw, { styled } from "twin.macro"

const ClickableCloserUnderlay = styled.div`
  ${tw`cursor-default fixed opacity-0 z-20`}
  height: 100vh;
  left: 0;
  top: 0;
  width: 100vw;
`

export default ClickableCloserUnderlay
