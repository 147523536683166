import React from "react"

interface BudgetOrganizationContextType {
  balance?: number | null
}

const BudgetingOrganizationContext =
  React.createContext<BudgetOrganizationContextType>({ balance: null })

export default BudgetingOrganizationContext
