import { formatPrice } from "../../common/format"

interface Props {
  totalPrice: number
  balance: number
  balancePercent: number
}

export default function SendSummaryBalanceChart({
  totalPrice,
  balance,
  balancePercent,
}: Props) {
  const remaining = balance - totalPrice

  return (
    <div>
      <div tw="bg-gray-150 h-1.5 rounded-xl">
        <div
          tw="bg-green-500 h-1.5 rounded-xl"
          css={{ width: `${Math.round(balancePercent)}%` }}
        ></div>
      </div>
      <div tw="flex flex-row items-start justify-between text-[15px] pt-1">
        <div tw="text-green-500">{Math.round(balancePercent)}%</div>
        <div tw="text-gray-450">
          {formatPrice(remaining, true)} remains after send
        </div>
      </div>
    </div>
  )
}
