import React, { useState } from "react"

import { formatPrice } from "../../common/format"
import Input from "../../common/Input"
import { Loader } from "../../common/UI"
import { SubscriptionPlan } from "../useSubscriptionPlanData"

import {
  Subscribe_UserSubscriptionPriceEstimateMutation,
  Subscriptions_PromotionCodeQuery,
} from "@/types/graphql-types"

type Promotion = Subscriptions_PromotionCodeQuery["promotionCode"]

interface Props {
  onChange: (code: string) => void
  code: string
  loading: boolean
  promotionCode?: Promotion | null
  discountAmount?: number | null
  priceEstimateWithPromo?: Subscribe_UserSubscriptionPriceEstimateMutation | null
  selectedPlan: SubscriptionPlan
}

const PromotionCode = ({
  onChange,
  loading,
  promotionCode,
  discountAmount,
  priceEstimateWithPromo,
  selectedPlan,
}: Props) => {
  const [showCodeInput, setShowCodeInput] = useState(false)

  const applyCode = (codeInput: string) => {
    onChange(codeInput)
  }

  const removeCode = async () => {
    onChange("")
  }

  if (!showCodeInput) {
    return (
      <div tw="pt-1 pb-2">
        <AddCodeButton onClick={() => setShowCodeInput(true)} />
      </div>
    )
  }

  return (
    <div>
      {promotionCode ? (
        <div tw="pb-8">
          <PromotionEstimate
            promotion={promotionCode}
            onRemove={removeCode}
            discountAmount={discountAmount}
            priceEstimateWithPromo={priceEstimateWithPromo}
            selectedPlan={selectedPlan}
          />
        </div>
      ) : (
        <div tw="pt-2.5 pb-0.5">
          <CodeInput onApply={applyCode} loading={loading} />
        </div>
      )}
    </div>
  )
}

export default PromotionCode

interface AddCodeButtonProps {
  onClick: () => void
}

const AddCodeButton = ({ onClick }: AddCodeButtonProps) => (
  <button
    onClick={onClick}
    tw="pb-7 text-primary-500 hover:text-primary-700 transition-colors"
    type="button"
  >
    Add code
  </button>
)

interface CodeInputProps {
  onApply: (code: string) => void
  loading: boolean
}

const CodeInput = ({ onApply, loading }: CodeInputProps) => {
  const [codeInput, setCodeInput] = useState("")
  const removeSpecialChars = (value: string) => value.replace(/[^\w-]/g, "")

  return (
    <div>
      <div tw="flex items-center">
        <Input
          placeholder="Code"
          type="text"
          value={codeInput}
          onChange={(e) => {
            setCodeInput(removeSpecialChars(e.target.value))
          }}
          disabled={loading}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              // Prevent hitting "enter" from submitting the form.
              e.preventDefault()
              onApply(codeInput)
            }
          }}
          autoFocus={true}
        />
        {loading ? (
          <div tw="pl-4">
            <Loader />
          </div>
        ) : (
          <button
            onClick={() => onApply(codeInput)}
            tw="pl-4 text-[#8368FF] font-semibold"
            type="button"
          >
            Apply
          </button>
        )}
      </div>
    </div>
  )
}

interface PromotionEstimateProps {
  promotion: Promotion
  onRemove: () => void
  discountAmount?: number | null
  priceEstimateWithPromo?: Subscribe_UserSubscriptionPriceEstimateMutation | null
  selectedPlan: SubscriptionPlan
}

const PromotionEstimate = ({
  promotion,
  onRemove,
  discountAmount,
  priceEstimateWithPromo,
  selectedPlan,
}: PromotionEstimateProps) => {
  const estimatedTax =
    priceEstimateWithPromo?.subscriptionPriceEstimate.estimatedTax !==
      undefined &&
    priceEstimateWithPromo?.subscriptionPriceEstimate.estimatedTax !== null
      ? `${formatPrice(
          priceEstimateWithPromo.subscriptionPriceEstimate.estimatedTax,
        )}`
      : "—"

  const estimatedTotal =
    priceEstimateWithPromo?.subscriptionPriceEstimate.estimatedTotal !==
    undefined
      ? `${formatPrice(
          priceEstimateWithPromo.subscriptionPriceEstimate.estimatedTotal,
        )}`
      : null

  const intervalSuffixText =
    selectedPlan.interval === "monthly" ? "month" : "year"

  return (
    <div tw="font-text leading-7">
      <div tw="flex flex-row justify-between items-start pb-1 text-primary-600">
        <div>
          {promotion.code} ({promotion.description})
          <button onClick={() => onRemove()} tw="pl-3 text-gray-400">
            Remove
          </button>
        </div>
        {discountAmount ? (
          <div>–{formatPrice(discountAmount)}</div>
        ) : (
          <div>-</div>
        )}
      </div>
      <div tw="flex flex-row justify-between items-start pb-1 text-primary-600">
        <div>First {intervalSuffixText} tax</div>
        <div>{estimatedTax}</div>
      </div>
      <div tw="flex flex-row justify-between items-start pb-1 text-primary-600">
        <div>First {intervalSuffixText} total</div>
        <div>{estimatedTotal}</div>
      </div>
    </div>
  )
}
