import { ComponentVariant, CopyEmailsState } from "./giftLinkTypes"

interface Props {
  recipientCount: number
  variant: ComponentVariant
  copyEmailsState: CopyEmailsState
  onEmailsPressCopy: () => void
}

export default function GiftLinkSub({
  variant,
  recipientCount,
  copyEmailsState,
  onEmailsPressCopy,
}: Props) {
  // Not relevant for gifts with only 1 recipient
  if (!recipientCount || recipientCount < 2) {
    return null
  }

  return (
    <>
      <div tw="pt-6 text-gray-600 text-center px-6">
        {variant === "postSend"
          ? "The link above will work for every recipient in your gift."
          : "Send this gift link over email, Slack, Teams, or elsewhere to remind recipients to open your gift."}
      </div>
      <div tw="pt-3 text-gray-600 text-center">
        <button
          onClick={onEmailsPressCopy}
          tw="py-1 px-3 text-gray-450 border border-gray-150 rounded-[6px] hover:(border-gray-300 text-gray-500) active:bg-gray-050 transition-colors"
        >
          {copyEmailsState === "idle"
            ? "Click to copy recipient emails to paste into BCC"
            : copyEmailsState === "loading"
            ? "Loading…"
            : copyEmailsState === "copied"
            ? "Copied!"
            : null}
        </button>
      </div>
    </>
  )
}
