import tw, { styled } from "twin.macro"

const Image = styled.img`
  ${tw`w-full rounded-t-[11px]`}
`

const FlexColumn = styled.div`
  ${tw`flex-auto flex flex-col`}
`

const Body = styled.div`
  ${tw`p-5`}
`

const Container = styled.div`
  ${tw`w-full relative flex flex-col transition-all border border-primary-100 hover:border-primary-150`}
  box-sizing: border-box;
  box-shadow: 0px 2px 8px rgba(79, 31, 137, 0.06);
  border-radius: 12px;
  max-width: 500px;

  // This container itself cannot be clicked, but it has a pseudo-element on the
  // Gift button that spans the entire size of the container (see relative
  // position directive above). This hover style makes it appear that the
  // conatiner itself is clickable, when the click event is really happening on
  // the Gift button.
  &:hover {
    box-shadow: 0px 8px 24px rgba(79, 31, 137, 0.06);

    // Highlight text in a darker color when hovered
    .hover-highlight {
      ${tw`text-primary-700`};
    }

    transform: translateY(-1px);
  }
`

const Description = styled.div`
  ${tw`mt-4 leading-[1.4] text-gray-500 text-[15px] font-text`}
`

const Name = styled.div.attrs({
  className: "hover-highlight",
})`
  ${tw`font-medium text-lg text-primary-500 transition-colors`};
`

const BrandName = styled.div.attrs({
  className: "hover-highlight",
})`
  ${tw`text-primary-500 transition-colors`};
`

const Price = styled.span.attrs({
  className: "hover-highlight",
})`
  ${tw`text-primary-500 text-lg transition-colors`};
`

const ShippingCost = styled.span`
  ${tw`opacity-50`};
`

export {
  Body,
  Container,
  Description,
  FlexColumn,
  Image,
  Name,
  Price,
  ShippingCost,
  BrandName,
}
