import React from "react"

import { OmniPaymentMethodsList } from "./useOmniPaymentMethods"
import newCard from "../../assets/images/payment-cards/new-card.svg"
import { PaymentMethodButton } from "../../send/PaymentMethodButton"
import PaymentMethodOption from "../../send/PaymentMethodOption"

interface Props {
  selectedPaymentMethodID: string | null
  paymentMethods?: OmniPaymentMethodsList
  setSelectedPaymentMethodID: (pmID: string | "NEW") => void
}

export default function OmniPaymentMethodSelector({
  selectedPaymentMethodID,
  paymentMethods,
  setSelectedPaymentMethodID,
}: Props) {
  return (
    <div
      tw="mb-4 rounded-xl border border-gray-150"
      css={{
        boxShadow: "0 1px 4px rgba(0, 0, 0, 0.06)",
      }}
    >
      {paymentMethods?.organization?.length ? (
        <div tw="p-4">
          <div tw="font-medium pb-4 text-gray-600">
            Organization payment methods
          </div>
          <div tw={"flex flex-col items-stretch gap-4"}>
            {paymentMethods.organization.map((pm) => (
              <PaymentMethodOption
                paymentMethod={pm}
                selectedPaymentMethodID={selectedPaymentMethodID}
                onSelect={() => setSelectedPaymentMethodID(pm.id)}
                showCardholderName={true}
                key={pm.id}
              />
            ))}
          </div>
        </div>
      ) : null}
      {paymentMethods?.organization?.length && paymentMethods?.me?.length ? (
        <div tw="border-b border-purple-100" />
      ) : null}
      {paymentMethods?.me?.length ? (
        <div tw="p-4">
          <div tw="flex flex-row items-center">
            <div tw="flex-1">
              {/* Don't show "My payment methods" header if no org payment
              methods would show, which means we don't need to demarcate. */}
              {paymentMethods?.organization?.length ? (
                <div tw="font-medium pb-4 text-gray-600">
                  My payment methods
                </div>
              ) : null}
              <div tw={"flex flex-col items-stretch gap-4"}>
                {paymentMethods.me.map((pm) => (
                  <PaymentMethodOption
                    paymentMethod={pm}
                    selectedPaymentMethodID={selectedPaymentMethodID}
                    onSelect={() => setSelectedPaymentMethodID(pm.id)}
                    key={pm.id}
                  />
                ))}
                <PaymentMethodButton
                  onClick={(e) => {
                    e.preventDefault()
                    setSelectedPaymentMethodID("NEW")
                  }}
                  image={
                    <img
                      src={newCard}
                      alt={"Add new credit card"}
                      tw={"w-[33px] h-[20px]"}
                    />
                  }
                  text={"Add new credit card"}
                />
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  )
}
