import React, { useState } from "react"
import tw, { css, styled } from "twin.macro"

import ContactBottomButtons from "./ContactBottomButtons"
import { ImportStep, getUpdatedText, resultUpdated } from "./shared"
import {
  ArrowRightSmall,
  ChevronSmall,
  ContactImportSuccess,
} from "../../../assets/icons"
import Button from "../../../common/Button"

import {
  ContactBatchUpdatePayload,
  ContactUploadResult,
} from "@/types/graphql-types"

interface Props {
  importStep: ImportStep
  goPrevious: () => void
  importPreview: {
    new: ContactUploadResult[]
    updated: ContactUploadResult[]
    unchanged: ContactUploadResult[]
  }
  runImport: () => Promise<ContactBatchUpdatePayload | null>
  isLoading: boolean
  onPressOpenList: (listId: string | null) => void
  importListId: string | null
}

// This needs to be up here or it complains that it is not declared early enough
const importReviewRowCss = css`
  ${tw`flex flex-row items-center px-6 border-t border-gray-200`}
  height: 68px;
`

const ContactRunImport: React.FC<Props> = ({
  importStep,
  importPreview,
  runImport,
  goPrevious,
  isLoading,
  onPressOpenList,
  importListId,
}) => {
  const [importCompleteData, setImportCompleteData] = useState<{
    addCount: number
    updateCount: number
    unchangedCount: number
  } | null>(null)

  // Showing and hiding updated contacts
  const [showUpdatedContacts, setShowUpdatedContacts] = useState(false)

  // This is the list the contacts were ACTUALLY added to. Needed for new lists.
  const [addListId, setAddListId] = useState<string | null>(null)

  const onClickImport = async () => {
    const batchUpdate = await runImport()
    if (batchUpdate?.ok) {
      const completeData = {
        addCount: 0,
        updateCount: 0,
        unchangedCount: 0,
      }
      batchUpdate?.results?.forEach((result) => {
        if (result.new) {
          completeData.addCount++
        } else if (resultUpdated(result)) {
          completeData.updateCount++
        } else {
          completeData.unchangedCount++
        }

        // All results should have the same list id
        if (result.addListId) {
          setAddListId(result.addListId)
        }
      })
      setImportCompleteData(completeData)
    } else {
      window.alert("Import failed. Please try again.")
    }
  }

  return importStep === "review-and-import" ? (
    <div>
      {!importCompleteData ? (
        <>
          <ReviewContainer tw={"mt-12 self-stretch rounded-xl flex flex-col"}>
            <div
              tw={"text-xl font-medium flex flex-row items-center mx-6"}
              css={"height: 68px;"}
            >
              <div>Review your import</div>
            </div>
            <div css={importReviewRowCss}>
              {importPreview.new.length} new contact
              {importPreview.new.length !== 1 ? "s" : ""} will be added
            </div>
            {importPreview.unchanged.length > 0 ? (
              <div css={importReviewRowCss}>
                {importPreview.unchanged.length} existing contact
                {importPreview.unchanged.length !== 1 ? "s are " : " is "}
                unchanged
              </div>
            ) : null}
            {importPreview.updated.length > 0 ? (
              <button
                onClick={() => {
                  setShowUpdatedContacts(!showUpdatedContacts)
                }}
                css={importReviewRowCss}
              >
                {importPreview.updated.length} existing contact
                {importPreview.updated.length !== 1 ? "s" : ""} will be updated
                <ChevronContainer rotated={showUpdatedContacts} tw={"ml-3.5"}>
                  <ChevronSmall />
                </ChevronContainer>
              </button>
            ) : (
              <div css={importReviewRowCss}>
                {importPreview.updated.length} existing contact
                {importPreview.updated.length !== 1 ? "s" : ""} will be updated
              </div>
            )}
            <div>
              <ImportReviewTable shown={showUpdatedContacts}>
                {importPreview.updated.map((update, index) => (
                  <React.Fragment key={index}>
                    <NameContainer>
                      {update.name && <div>{update.name}</div>}
                      {update.email && (
                        <div tw={"text-gray-500 text-sm"}>{update.email}</div>
                      )}
                    </NameContainer>
                    <ChangeContainer>
                      <div>{getUpdatedText(update)}</div>
                    </ChangeContainer>
                  </React.Fragment>
                ))}
              </ImportReviewTable>
            </div>
          </ReviewContainer>
          <ContactBottomButtons
            nextText={"Import"}
            isLoading={isLoading}
            onClickNext={onClickImport}
            onClickBack={goPrevious}
          />
        </>
      ) : (
        <div
          tw={"mt-12 flex flex-col items-center justify-center rounded-xl"}
          css={"height: 245px; background-color: #F9FAFB;"}
        >
          <div
            tw={"flex flex-row items-center text-xl font-medium"}
            css={"color: #27AE60;"}
          >
            <ContactImportSuccess />
            <span tw={"ml-4"}>Contacts imported</span>
          </div>
          <div tw={"text-gray-500"} css={"margin-top: 18px;"}>
            {importCompleteData?.addCount} new contacts added and{" "}
            {importCompleteData?.updateCount} updated
          </div>
          <div tw={"mt-12"}>
            <Button
              variant={"default"}
              onClick={() => onPressOpenList(addListId || importListId)}
            >
              <span css={"color: #828282;"}>
                Open {addListId || importListId ? "list" : "all contacts"}
              </span>
              <ArrowRightSmall tw={"ml-1"} />
            </Button>
          </div>
        </div>
      )}
    </div>
  ) : null
}

const ReviewContainer = styled.div`
  ${tw`border border-gray-100`};
  box-shadow:
    0 1px 4px rgba(0, 0, 0, 0.05),
    0 4px 16px rgba(0, 0, 0, 0.04);
`

const ImportReviewTable = styled.div<{ shown: boolean }>`
  ${tw`block px-6 pointer-events-none sm:grid items-stretch`};
  grid-template-columns: min-content 1fr;
  grid-auto-rows: 76px;
  transition-property: opacity;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
  ${({ shown }) => (shown ? tw`opacity-100` : tw`opacity-0 absolute`)};
`

const ChevronContainer = styled.div<{ rotated: boolean }>`
  transition-property: transform;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
  transform: rotate(${({ rotated }) => (rotated ? "0deg" : "-180deg")});
`

const NameContainer = styled.div`
  ${tw`border-t items-center border-gray-200 flex flex-col justify-center items-start pt-6 sm:pt-0`}
`

const ChangeContainer = styled.div`
  ${tw`text-gray-500 sm:border-t items-center border-gray-200 flex flex-col justify-center items-start py-6 sm:py-0`};
  @media (min-width: 640px) {
    padding-left: 3.75rem;
  }
`

export default ContactRunImport
