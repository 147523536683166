import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import { useState } from "react"
import Modal from "react-modal"

import { track, useSegmentPrefix } from "@/common/analytics"
import { getEnvVars } from "@/common/environment"
import { useGiftData } from "@/common/hooks/giftData"
import { modalStyle } from "@/common/modal"
import PaymentMethod, {
  Props as PaymentMethodProps,
  cardImage,
} from "@/common/payments/PaymentMethod"
import { useRouteHasTag } from "@/common/TaggedRoute"
import AddPaymentMethodModal from "@/send/AddPaymentMethodModal"

interface Props
  extends Omit<PaymentMethodProps, "onClick" | "selected" | "image"> {
  refreshPaymentMethods: () => Promise<void>
}

const stripe = loadStripe(getEnvVars().stripeApiKey)

const AddPaymentMethod = ({ refreshPaymentMethods, ...props }: Props) => {
  const [open, setOpen] = useState(false)
  const { setHasRecentlyAddedCreditCard } = useGiftData()
  const segmentPrefix = useSegmentPrefix()
  const isSendPage = useRouteHasTag("send")

  return (
    <>
      <PaymentMethod
        onClick={() => {
          setOpen(true)
          track(`${segmentPrefix}Payment Selector - Click Add Card`)
        }}
        selected={false}
        image={cardImage()}
        {...props}
      />
      <Modal
        isOpen={open}
        closeTimeoutMS={500}
        onRequestClose={() => setOpen(false)}
        shouldCloseOnEsc={false}
        shouldCloseOnOverlayClick={true}
        style={modalStyle}
      >
        <Elements stripe={stripe}>
          <AddPaymentMethodModal
            isOpen={open}
            onComplete={() => {
              refreshPaymentMethods()
              if (isSendPage) {
                track("Business - Send - Add Credit Card")
                setHasRecentlyAddedCreditCard(true)
              }
              setOpen(false)
            }}
          />
        </Elements>
      </Modal>
    </>
  )
}

export default AddPaymentMethod
