import React from "react"

import { useGiftBatchSettings } from "./hooks/giftBatchSettings"

import { OptionBase } from "./index"

export const BatchName = () => {
  const { settings, setSettings } = useGiftBatchSettings()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newBatchName = event.target.value

    setSettings((currentSettings) => ({
      ...currentSettings,
      batchName: newBatchName,
    }))
  }

  return (
    <OptionBase
      name={"Gift Name"}
      children={
        <div tw="rounded-lg px-4 py-[14px] border border-gray-200 text-black">
          <input
            tw="w-full p-0 focus:outline-none"
            className="data-hj-suppress ph-no-capture fs-mask"
            value={settings.batchName || ""}
            onChange={handleChange}
          />
        </div>
      }
      hint={
        "Optional. This is only used internally, and not displayed to gift recipients."
      }
    />
  )
}
