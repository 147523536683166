import React from "react"
import tw from "twin.macro"

import { formatPrice } from "../../common/format"

import { BalanceGiftBatchStatus } from "@/types/graphql-types"
import { BalanceGiftBatch } from "@/types/graphql-types"

interface Props {
  isOwnActivity: boolean
  balanceGiftBatch: BalanceGiftBatch
  giftBatchCancelPendingGifts: (id: string) => void
  giftBatchCancelScheduledBatch: (id: string) => void
}

export default function ActivityGiftBatchActions({
  isOwnActivity,
  balanceGiftBatch,
  giftBatchCancelPendingGifts,
  giftBatchCancelScheduledBatch,
}: Props) {
  const hasCancelAllGiftsAction =
    balanceGiftBatch.status === BalanceGiftBatchStatus.FINALIZED &&
    balanceGiftBatch.pendingGiftsCount > 0
  const hasCancelScheduledGiftBatchAction =
    balanceGiftBatch.status === BalanceGiftBatchStatus.SCHEDULED_PENDING
  const isCanceled = balanceGiftBatch.status === BalanceGiftBatchStatus.CANCELED

  // Only show actions if they exist, and if this is a user's own activity being shown.
  const hasActions =
    isOwnActivity &&
    (hasCancelAllGiftsAction || hasCancelScheduledGiftBatchAction || isCanceled)

  const handleCancelAllGifts = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()

    const res = window.confirm(
      `Are you sure you want to cancel all pending gifts?\n\nThis will return ${formatPrice(
        balanceGiftBatch.pendingAmount,
        true,
      )} to your balance.`,
    )

    if (res) {
      giftBatchCancelPendingGifts(balanceGiftBatch.giftBatchPublicId)
    }
  }

  const handleCancelScheduledGiftBatch = (
    e: React.MouseEvent<HTMLButtonElement>,
  ) => {
    e.stopPropagation()

    const res = window.confirm(
      `Are you sure you want to cancel this scheduled gift?\n\nThis will return ${formatPrice(
        balanceGiftBatch.amountReserved || 0,
        true,
      )} to your balance.`,
    )

    if (res) {
      giftBatchCancelScheduledBatch(balanceGiftBatch.giftBatchPublicId)
    }
  }

  if (!hasActions) {
    return null
  }

  return (
    <div tw="pb-4 px-4">
      {hasCancelAllGiftsAction && (
        <ActionButton onClick={handleCancelAllGifts}>
          Cancel pending gifts
        </ActionButton>
      )}
      {hasCancelScheduledGiftBatchAction && (
        <ActionButton onClick={handleCancelScheduledGiftBatch}>
          Cancel scheduled gift
        </ActionButton>
      )}
      {isCanceled && (
        <div tw="text-gray-500 px-1">
          This scheduled gift batch was canceled.
        </div>
      )}
    </div>
  )
}

const ActionButton = tw.button`
  py-1 px-3 rounded-full border border-gray-200 text-gray-500 font-text mr-3 transition-colors text-sm
  hover:(border-primary-new-200 text-primary-new-500)
  active:(bg-primary-new-000)
`
