import { generateRealmPath } from "../../../common/realm"
import FAQAccordion, { PurpleLink } from "../../components/FAQAccordion"

const FrequentlyAskedQuestions = () => {
  const sections = [
    {
      question: "Do I need a paid software plan to build a custom collection?",
      answer: (
        <>
          <p>
            Yes, custom collections are only available on our{" "}
            <PurpleLink
              href={generateRealmPath("business", "/business/pricing")}
            >
              Goody for Teams subscription plan.
            </PurpleLink>{" "}
          </p>
          <p>
            To learn more about our Teams plan and custom collections,{" "}
            <PurpleLink
              href={generateRealmPath("business", "/business/team-demo")}
            >
              book a call with us
            </PurpleLink>
            .
          </p>
        </>
      ),
    },
    {
      question:
        "Can I require people to pay for gifts in my custom collection?",
      answer: (
        <p>
          No, custom collections let you curate a set of gifts that recipients
          can choose from. It is not a digital store where users can enter a
          payment method and purchase gifts.
        </p>
      ),
    },
    {
      question:
        "Can I stock my custom collection with both swag and non-swag items?",
      answer: (
        <p>
          Absolutely! Many companies offer both, to provide alternative gift
          options for customers or employees who’d rather not receive swag.
        </p>
      ),
    },
    {
      question: "How am I billed for gifts that people accept?",
      answer: (
        <>
          <p>
            After you send your custom collection, your recipient will receive a
            digital notification. They’ll choose the gift from your collection
            that they want to accept.
          </p>
          <p>
            You’ll only be charged for the price of gifts that are accepted. If
            your recipient does not select a gift, then you will not be charged.
          </p>
        </>
      ),
    },
  ]

  return (
    <div tw="px-4 pb-24 bg-gray-050 pt-4 md:pt-12">
      <FAQAccordion sections={sections} />
    </div>
  )
}

export default FrequentlyAskedQuestions
