import { formatPrice } from "../../../common/format"
import { ReactComponent as ShippingIcon } from "../../../common/images/icon-shipping.svg"

import { flex_gift_product_price_type } from "@/types/graphql-types"
import { ProductListFragment } from "@/types/graphql-types"

interface Props {
  product: ProductListFragment
}

export const ShippingCost = ({ product }: Props) => {
  const freeShippingMinimumGiftOption =
    product.brand.freeShippingMinimum &&
    product.price &&
    product.price >= product.brand.freeShippingMinimum

  if (!product.brand.shippingPrice) {
    return null
  }

  let shippingLabel = formatPrice(product.brand.shippingPrice)

  if (
    product.isFlexGift &&
    product.swapStoreSettings?.priceType ===
      flex_gift_product_price_type.VARIABLE
  ) {
    shippingLabel = "Included"
  } else if (
    !freeShippingMinimumGiftOption &&
    product.brand.freeShippingMinimum &&
    product.brand.shippingPrice !== 0
  ) {
    shippingLabel = formatPrice(product.brand.shippingPrice) + " or free"
  } else if (
    product.brand.shippingPrice === 0 ||
    freeShippingMinimumGiftOption
  ) {
    shippingLabel = "Free"
  }

  return (
    <div tw="flex flex-row items-start justify-end pt-1 text-gray-400">
      <ShippingIcon tw="stroke-current text-gray-300 mt-1" />
      <div tw="pl-1.5">{shippingLabel}</div>
    </div>
  )
}
