import { BalanceTransactionFragment } from "@/types/graphql-types"

interface Props {
  item: BalanceTransactionFragment
}

export default function TransactionDetails({ item }: Props) {
  if (
    (item.action === "BUDGET_MODIFICATION" ||
      item.action === "TRANSFER_MANUAL") &&
    item.createdByUser
  ) {
    return (
      <div>
        <div tw="text-sm text-gray-500">Created by</div>
        {item.createdByUser}
      </div>
    )
  }

  return (
    <div tw="flex flex-col gap-2">
      {item.giftBatch && (
        <div tw="pb-2">
          <div tw="text-sm text-gray-500">Gift Batch {item.giftBatch?.id}</div>
          {item.giftBatch?.batchName}
        </div>
      )}
      {item.gift && (
        <div>
          <div tw="text-sm text-gray-500">Gift {item.gift.id}</div>
          <div>
            Recipient:{" "}
            {[item.gift.recipientFirstName, item.gift.recipientLastName]
              .filter(Boolean)
              .join(" ")}
          </div>
          {item.gift.senderName && <div>From: {item.gift.senderName}</div>}
        </div>
      )}
    </div>
  )
}
