import React from "react"
import tw, { styled } from "twin.macro"

import { WorkspaceReward } from "../../assets/icons"

interface Props {
  shrink?: boolean
}

const WorkspaceInvitePromo: React.FC<Props> = ({ shrink = false }) => {
  return (
    <GradientBorder
      shrink={shrink}
      style={{ boxShadow: "0px 2px 4px 0px rgba(228, 216, 244, 0.3)" }}
    >
      <div tw="bg-white rounded-[7px] flex font-text">
        <IconBackground>
          <WorkspaceReward
            style={{
              height: 40,
              width: 72,
              filter: "drop-shadow(0px 2px 4px #E4D8F480)",
            }}
          />
        </IconBackground>
        <div tw="pl-5 pt-4 pb-4 pr-7 text-[#776AF5] bg-white rounded-r-[7px] leading-tight">
          <div tw="pb-1 font-semibold text-lg">
            Earn $50 for each successful invitation
          </div>
          <div tw="flex flex-row font-normal text-sm opacity-70">
            <p tw="mr-1">
              Credit granted when workspace member sends their first gift. See
              full
            </p>
            <a
              tw="underline"
              href="/workspace-referral-terms"
              target="_blank"
              rel="noopener"
            >
              terms
            </a>
          </div>
        </div>
      </div>
    </GradientBorder>
  )
}

const GradientBorder = styled.div<{ shrink: boolean }>(({ shrink }) => [
  tw`p-px rounded-lg bg-gradient-to-r from-[rgba(70, 99, 251, 0.3)] to-[rgba(177, 91, 230, 0.3)]`,
  shrink && tw`md:w-max`,
])

const IconBackground = tw.div`
  flex items-center py-5 px-3 rounded-l-[7px]
  bg-gradient-to-r from-[rgba(70, 99, 251, 0.09)] to-[rgba(177, 91, 230, 0.09)]
`

export default WorkspaceInvitePromo
