import React from "react"

import staticAssetUrl from "@/common/staticAssetUrl"
import StoreSEO from "@/common/StoreSEO"
import { StackedItemContainer } from "@/common/UI"
import HolidayGiftGuide from "@/plasmic/HolidayGiftGuide"
import CategorySidebarFrame from "@/store/categories/CategorySidebarFrame"
import HolidayGiftGuideEmailGate from "@/store/components/HolidayGiftGuideScreen/HolidayGiftGuideEmailGate"
import { HolidayEmailGateProvider } from "@/store/components/HolidayGiftGuideScreen/hooks/useHolidayEmailGate"

const HolidayGiftGuideScreen = () => (
  <HolidayEmailGateProvider>
    <CategorySidebarFrame>
      <StackedItemContainer>
        <StoreSEO
          canonicalPath={window.location.pathname}
          metaDescription="Unwrap the Ultimate Holiday Gift Guide for businesses. 150+ inspired gift ideas, from employee appreciation to client gifts to branded swag."
          titlePrefix="2024 Holiday Gift Ideas For Employees, Clients & More"
          ogImage={staticAssetUrl(
            "static/gift-guides/holiday-2024/social-share-image.png",
          )}
        />
        <div tw="sm:p-4 lg:p-8">
          <HolidayGiftGuide />
        </div>
        <HolidayGiftGuideEmailGate />
      </StackedItemContainer>
    </CategorySidebarFrame>
  </HolidayEmailGateProvider>
)

export default HolidayGiftGuideScreen
