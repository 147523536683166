import tw, { styled } from "twin.macro"

const CountriesUndelineText = () => (
  <UnderlineText
    href="https://intercom.help/goody/en/articles/5838916-does-goody-ship-internationally"
    target="_blank"
    rel="noopener noreferrer"
  >
    140+ countries.
  </UnderlineText>
)

// Adding CSS because we are not in the latest version of tailwind.
// When we upgrade we can change this to:
// tw`underline-offset-4 decoration-primary-new-200`
const UnderlineText = styled.a`
  ${tw`text-primary-new-800 underline`}
  text-decoration-color: #CBC1FF; // primary-new-200
  text-underline-offset: 4px;
`

export default CountriesUndelineText
