import { toast } from "react-hot-toast"

import { track } from "@/common/analytics"
import DialogModal from "@/common/DialogModal"
import { useGiftData } from "@/common/hooks/giftData"
import {
  BALANCE_PAYMENT_METHOD_PAYLOAD,
  getPaymentMethodName,
} from "@/common/hooks/payment"

interface Props {
  isOpen: boolean
  displayReviewGiftModal: () => void
  onRequestClose: () => void
  onAfterClose?: () => void
}
export const CreditCardWarningModal = ({
  isOpen,
  displayReviewGiftModal,
  onRequestClose,
  onAfterClose,
}: Props) => {
  const { setPaymentMethod } = useGiftData()
  const handleUseCreditCardClick = () => {
    track("Info", {
      name: "Send - Balance Credit Card Warning - Click Use Credit Card",
    })

    displayReviewGiftModal()
    onRequestClose()
  }

  const handleSwitchToBalanceClick = () => {
    track("Info", {
      name: "Send - Balance Credit Card Warning - Click Switch to Balance",
    })

    setPaymentMethod(
      BALANCE_PAYMENT_METHOD_PAYLOAD,
      getPaymentMethodName(BALANCE_PAYMENT_METHOD_PAYLOAD),
    )
    toast.success("Updated payment method to Balance")

    displayReviewGiftModal()
    onRequestClose()
  }

  return (
    <DialogModal
      open={isOpen}
      onClose={onRequestClose}
      onAfterClose={onAfterClose}
    >
      <DialogModal.Header variant="warning" />
      <DialogModal.Title tw="px-12">
        You’re paying with a credit card, but you have enough Balance for this
        gift
      </DialogModal.Title>
      <DialogModal.Content tw="px-16 md:px-0">
        Do you want to switch to using Balance to pay for this gift?
      </DialogModal.Content>
      <DialogModal.Actions>
        <DialogModal.Button onClick={handleUseCreditCardClick}>
          No, use credit card
        </DialogModal.Button>
        <DialogModal.Button
          variant="primary-new"
          onClick={handleSwitchToBalanceClick}
        >
          Yes, switch to balance
        </DialogModal.Button>
      </DialogModal.Actions>
    </DialogModal>
  )
}
