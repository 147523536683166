import tw, { styled } from "twin.macro"

import { ProductImage } from "./ProductImage"
import { ProductPrice } from "./ProductPrice"
import { ShippingCost } from "./ShippingCost"
import { useBrowseUrls } from "../../StoreHelpers"
import { ShipsGloballyTag } from "../ShipsGloballyTag"

import { ProductListFragment } from "@/types/graphql-types"

interface Props {
  product: ProductListFragment
  scrollElemRef: React.RefObject<HTMLDivElement>
  onClick?: (product: ProductListFragment) => void
}

export const ProductListItem = ({ product, scrollElemRef, onClick }: Props) => {
  const { generatePdpUrl } = useBrowseUrls()

  return (
    <Container
      onClick={(e) => {
        if (onClick) {
          e.preventDefault()
          onClick(product)
          e.stopPropagation()
        }
      }}
      href={generatePdpUrl({
        productSlug: product.slug,
        brandSlug: product.brand.slug,
      })}
    >
      <ProductImage
        image={product?.productImages[0].imageLarge}
        scrollElemRef={scrollElemRef}
      />
      <div tw="flex flex-row items-start text-left p-5 lg:p-4">
        <h3 tw="flex-1">
          <div tw="font-medium text-lg text-primary-600">{product.name}</div>
          {product.subtitle && (
            <div tw="text-gray-600 pt-1 font-text text-sm">
              {product.subtitle}
            </div>
          )}
          <ShipsGloballyTag product={product} twStyle={tw`pt-3`} />
        </h3>
        <div tw="pl-8 text-right font-text">
          <ProductPrice product={product} />
          <ShippingCost product={product} />
        </div>
      </div>
    </Container>
  )
}

const Container = styled.a`
  ${tw`w-full lg:rounded-2xl transition-colors outline-none transition-all duration-300 ease-out pb-6 lg:pb-0 block cursor-pointer`};

  &:hover {
    box-shadow:
      0 1px 2px rgba(0, 0, 0, 0.04),
      0 4px 8px rgba(0, 0, 0, 0.06);

    &.selected {
      box-shadow:
        0 1px 4px rgba(0, 0, 0, 0.05),
        0 8px 16px rgba(0, 0, 0, 0.08);
    }
  }
`
