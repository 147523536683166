import React, { useEffect } from "react"
import { useLocation } from "react-router-dom"

import BasicContainer from "./components/BasicContainer"
import { CopiedHtmlContainer } from "./Ui"

const Terms: React.FC = () => {
  const { hash } = useLocation()

  useEffect(() => {
    if (hash !== "") {
      const id = hash.replace("#", "")
      const element = document.getElementById(id)
      if (element) {
        element.scrollIntoView()
      }
    }
  }, [hash])

  return (
    <BasicContainer title={"Terms of Use"}>
      <CopiedHtmlContainer className="legal-content">
        <div className="terms-intro w-richtext">
          <p>
            <strong>Effective Date</strong>: November 20, 2020
          </p>
          <p>Welcome to the Goody App!</p>
          <p>
            BY ACCESSING OR USING THE APP YOU ARE INDICATING THAT YOU HAVE READ
            AND UNDERSTAND THE TERMS, AND THAT YOU AGREE TO COMPLY WITH AND BE
            LEGALLY BOUND BY THE TERMS.
          </p>
          <p>
            If you are accepting or agreeing to the Terms on behalf of a company
            or other legal entity, you represent and warrant that you have the
            authority to act on behalf of and bind that entity to the Terms.
          </p>
          <p>
            IF YOU DO NOT AGREE TO THE TERMS, YOU MAY NOT ACCESS OR USE THE APP
            OR SERVICES.
          </p>
        </div>
        <ol role="list">
          <li className="terms-scroll-link">
            <a href="#section-1">
              <strong>ABOUT&nbsp;THE&nbsp;TERMS</strong>
            </a>
          </li>
          <li className="terms-scroll-link">
            <a href="#section-2">
              <strong>ABOUT&nbsp;THE&nbsp;APP</strong>
            </a>
          </li>
          <li className="terms-scroll-link">
            <a href="#section-3">
              <strong>ACCOUNTS</strong>
            </a>
          </li>
          <li className="terms-scroll-link">
            <a href="#section-4">
              <strong>PAYMENT&nbsp;TERMS</strong>
            </a>
          </li>
          <li className="terms-scroll-link">
            <a href="#section-5">
              <strong>USER CONDUCT GUIDELINES</strong>
            </a>
          </li>
          <li className="terms-scroll-link">
            <a href="#section-6">
              <strong>INTELLECTUAL PROPERTY OWNERSHIP</strong>
            </a>
          </li>
          <li className="terms-scroll-link">
            <a href="#section-7">
              <strong>THIRD PARTY TERMS</strong>
            </a>
          </li>
          <li className="terms-scroll-link">
            <a href="#section-8">
              <strong>
                WARRANTIES, DISCLAIMERS, LIMITATION OF LIABILITY &amp;
                INDEMNIFICATION
              </strong>
            </a>
          </li>
          <li className="terms-scroll-link">
            <a href="#section-9">
              <strong>GOVERNING LAW &amp; DISPUTE RESOLUTION</strong>
            </a>
          </li>
          <li className="terms-scroll-link">
            <a href="#section-10">
              <strong>MISCELLANEOUS</strong>
            </a>
          </li>
          <li className="terms-scroll-link">
            <a href="#section-11">
              <strong>REFERRALS</strong>
            </a>
          </li>
        </ol>
        <h2 id="section-1" className="legal-h1">
          1. ABOUT THE TERMS
        </h2>
        <div className="terms-rt w-richtext">
          <h2>1.1. Key Terms</h2>
          <p>As used in these Terms of Use:</p>
          <ul role="list">
            <li>
              “<strong>App</strong>” refers to any downloadable application
              (including, a mobile application) owned or operated by Goody,
              through which access to the Services is available. References to
              the “App” include any and all features, functionality, tools and
              content available on or through each such application.
            </li>
            <li>
              “<strong>App Content</strong>” refers to any and all videos, text,
              photos, information and other content included, provided or made
              available on or through the App, including all User Content except
              Your Content.
            </li>
            <li>
              “<strong>Goody</strong>,” “<strong>we</strong>,” or “
              <strong>us</strong>” refer to Goody Technologies, Inc. and our
              officers, directors, employees, contractors and agents.
            </li>
            <li>
              “<strong>Products</strong>” refers to the third party goods
              provided by Goody.
            </li>
            <li>
              “<strong>Recipient</strong>” refers to a User accessing the
              Services via the App to receive a Product from a Sender.
            </li>
            <li>
              “<strong>Sender</strong>” refers to a User accessing the Services
              via the App to send a Product to a Recipient.
            </li>
            <li>
              “<strong>Services</strong>” refers to the Product gifting services
              provided by Goody and its service providers, and any and all
              related services and promotions.
            </li>
            <li>
              “<strong>User Content</strong>” refers to text, photos,
              information and other content recorded, stored, shared or
              otherwise made available or caused to be made available by Users
              on or through the App.
            </li>
            <li>
              “<strong>Users</strong>” means any and all persons that access or
              use the App, including you. Users may be either a Sender or
              Receiver depending on how the Services are used. References to
              “access” and/or “use” of the App (and any variations thereof)
              include the acts of accessing or browsing the App, and accessing
              or using the Services.
            </li>
            <li>
              “<strong>Your Content</strong>” refers to User Content that is
              provided or made available or caused to be made available by you.
            </li>
          </ul>
          <h2>1.2. App Rules and Supplemental Terms</h2>
          <p>
            Your access to and use of the App is governed by the terms and
            conditions of these Terms of Service, our Privacy Policy, currently
            located on the App (as described in more detail below), any and all
            other policies and rules referenced herein, posted on the App, or
            otherwise communicated to users (the “<strong>App Rules</strong>”).
          </p>
          <p>
            Certain of the features, functionality, tools, content and
            promotions available on or through the App may be subject to
            additional or supplemental terms and conditions (“
            <strong>Supplemental Terms</strong>”). If you choose to access or
            use those features, functionality, tools or content or participate
            in those promotions, the applicable Supplemental Terms are also
            incorporated and deemed part of these Terms of Use.
          </p>
          <p>
            If there is a conflict between these Terms of Use and the
            Supplemental Terms, the Supplemental Terms will govern and control
            with respect to the applicable features, functionality, tools,
            content and promotions.
          </p>
          <p>
            PLEASE READ THESE TERMS OF USE, OUR PRIVACY POLICY, APP RULES AND
            ALL APPLICABLE SUPPLEMENTAL TERMS (COLLECTIVELY, THE “
            <strong>TERMS</strong>”) CAREFULLY, AS THEY CONTAIN TERMS AND
            CONDITIONS THAT IMPACT YOUR RIGHTS, OBLIGATIONS AND REMEDIES IN
            CONNECTION WITH YOUR USE OF THE APP. FOR EXAMPLE, THE TERMS INCLUDE:
          </p>
          <ul role="list">
            <li>
              INFORMATION ABOUT THE FUNCTIONAL LIMITATIONS OF THE APP AND/OR THE
              SERVICES;
            </li>
            <li>
              YOUR OBLIGATION TO COMPLY WITH ALL APPLICABLE LAWS AND
              REGULATIONS;
            </li>
            <li>LIMITATIONS OF OUR LIABILITY TO YOU; AND</li>
            <li>
              A REQUIREMENT THAT YOU PURSUE CLAIMS OR SEEK RELIEF AGAINST US
              (INCLUDING MONETARY, INJUNCTIVE, AND DECLARATORY RELIEF) ON AN
              INDIVIDUAL BASIS, RATHER THAN AS A PARTICIPANT IN ANY CLASS OR
              REPRESENTATIVE ACTION OR PROCEEDING.
            </li>
          </ul>
          <p>
            YOUR ACCESS TO AND USE OF THE APP AND SERVICES IS CONDITIONED ON
            YOUR ACCEPTANCE OF AND COMPLIANCE WITH THESE TERMS.
          </p>
          <h2>1.3. Amendment of Terms</h2>
          <p>
            Goody reserves the right, in its sole discretion, to amend the
            Terms, at any time and without prior notice, including to change,
            modify, add to, update or remove terms and conditions (collectively
            “amend” or “update”). If we choose to amend the Terms, we will
            update the Effective Date at the top of the Terms and post the
            updated version. We may also, at our option, choose to notify you by
            e-mail or another means. By continuing to use the App after we have
            posted an updated version of the Terms or otherwise notified you of
            an update, you are affirming that you agree to be bound by the
            amended Terms. This provision is subject to a few limitations in the
            “Dispute Resolution” section below. If the amended Terms are not
            acceptable to you, your only recourse is to stop using the App.
          </p>
          <p>
            No other modification, amendment, supplement of or to the Terms will
            be binding on Goody unless it is in writing and signed by an
            authorized representative of Goody.
          </p>
        </div>
        <h2 id="section-2" className="legal-h1">
          2. ABOUT&nbsp;THE&nbsp;APP
        </h2>
        <div className="terms-rt w-richtext">
          <h2>2.1. License to Use</h2>
          <p>
            Subject to your compliance with the Terms, Goody grants you a
            limited non-exclusive, non-transferable, non-assignable and
            non-sublicensable license to access and use the App and Services,
            including the right to download and install a copy of the App on
            each mobile device that you own or control, for your own personal
            use only, and not for use for any business purpose or commercial
            activity. This license is granted for the sole purpose of enabling
            you to use and enjoy the benefit of the App as provided by Goody, in
            the manner permitted by the Terms.
          </p>
          <h2>2.2. Set-Up and Operating Requirements</h2>
          <p>In order to use the Services, you must:</p>
          <ul role="list">
            <li>
              have internet access (e.g., WiFi or 3G /4G with a data plan);
            </li>
            <li>download the App on your smartphone or tablet;</li>
            <li>
              register for an Goody account (as set forth in more detail below
              in the “Accounts” section below);
            </li>
            <li>
              provide and maintain complete, accurate and up-to-date account
              information, including contact information for your Goody gift
              recipients.
            </li>
          </ul>
          <p>
            If any of the above requirements are not met, you will not be able
            to use some or all of the Services.
          </p>
          <h2>2.3. Acknowledgements</h2>
          <p>
            YOU AFFIRM THAT YOU HAVE READ THE FOREGOING AND ACKNOWLEDGE THAT:
          </p>
          <ul role="list">
            <li>
              GOODY DOES NOT AND CANNOT GUARANTEE THAT THE APP WILL BE
              CONTINUOUS OR ERROR-FREE.
            </li>
            <li>
              IF YOUR MOBILE OR DATA PLAN SERVICES OR ACCESS TO THE INTERNET OR
              OUR APP IS/ARE SUSPENDED, CANCELLED OR TERMINATED (E.G., AS A
              RESULT OF BILLING ISSUES OR OTHER BREACH), YOU MAY NOT BE ABLE TO
              USE SOME OR ALL OF THE APP.
            </li>
            <li>
              YOU ARE EXCLUSIVELY RESPONSIBLE FOR YOUR USE OF THE APP AND YOUR
              CONTENT. ABUSE OF THE SERVICES MAY SUBJECT YOU TO CIVIL AND
              CRIMINAL FINES AND PENALTIES.
            </li>
          </ul>
          <h2>2.4. Modifications and Updates to the App and Services</h2>
          <p>
            Goody reserves the right, in its sole discretion, to modify or
            discontinue offering the App and/or Services, in whole or in part,
            including any features, functionality, tools or content thereof, at
            any time, for any reason or no reason, with or without notice to
            you. We also retain the right to impose limits on your use and
            storage of Your/ the User Content at our sole discretion at any time
            without prior notice to you.
          </p>
          <p>
            We may from time to time develop and provide updates for the App,
            which may include upgrades, bug fixes, patches and other error
            corrections and/or new features, functionality, tools or content
            (collectively, “<strong>Updates</strong>”). Updates may also modify
            or delete features, functionality, tools or content in their
            entirety. Based on your mobile device settings, when your mobile
            device is connected to the internet either: (a) the Updates will
            automatically download and install; or (b) you may receive notice of
            or be prompted to download and install available Updates. Please
            promptly download and install all Updates. If you do not, portions
            of the App may not properly operate. All Updates will be deemed part
            of the App and be subject to all terms and conditions of the Terms.
          </p>
          <p>
            You agree that Goody has no obligation to provide any updates or to
            continue to provide or enable any particular features,
            functionality, tools or content, and will not be liable with respect
            to any such modifications, discontinuance or deletions.
          </p>
          <h2>2.5. International Users</h2>
          <p>
            The App is controlled and operated within the United States and is
            not intended for use outside of the United States. You are hereby
            prohibited from accessing or using the App from any territory where
            the App or any of the features, functionality, tools, content
            thereof, is illegal. If you choose to access the App from a location
            outside the United States, you do so at your own risk and you are
            solely responsible for compliance with applicable laws, rules and
            regulations, including export laws and any regulations and local
            laws regarding online conduct and content.
          </p>
        </div>
        <h2 id="section-3" className="legal-h1">
          3. ACCOUNTS
        </h2>
        <div className="terms-rt w-richtext">
          <h2>3.1. Account Registration</h2>
          <p>
            While certain App Content and Services are publicly available, you
            must register for a user account before you are able to use certain
            of the Services. You may register to create an account directly via
            the App.
          </p>
          <p>
            You may not register for an account on behalf of any person (other
            than yourself).
          </p>
          <p>
            No person or entity may have more than one active account at any
            given time.
          </p>
          <h2>3.2. Account Set-Up</h2>
          <p>
            Your account and account profile page will be created based upon the
            information you provide to us. You agree to provide complete,
            accurate and up-to-date information during the registration process
            and to update such information as necessary to ensure that it
            remains complete, accurate and up-to-date. Please note that the name
            and contact information that you submit when you register will be
            shared in accordance with your selections in your account settings
            when you share Your Content.
          </p>
          <p>
            When you create your account, you will be asked to provide and
            verify your phone number. You agree to immediately notify Goody of
            any unauthorized use of your account. You further acknowledge and
            agree that you will be solely responsible for any activities or
            actions on or through your account, whether or not you have
            authorized such activities or actions. Goody<strong> </strong>cannot
            and will not be liable for any loss or damage arising from your
            failure to comply with the above requirements.
          </p>
          <h2>3.3. Account Suspension and Cancellation</h2>
          <p>You may cancel your account at any time.</p>
          <p>
            We may, in our discretion, without liability to you and without
            limiting our other remedies, with or without prior notice and at any
            time, decide to (i) limit, suspend, deactivate or cancel your
            account and take technical and legal steps to prevent you from using
            our App and Services at any time for any reason, and (ii) screen or
            delay the posting or delivery of Your Content.
          </p>
          <p>
            Goody reserves the right to suspend or terminate your account or
            your access to the App if you create more than one account, or if
            any information provided during the registration process or
            thereafter is determined to be incomplete, inaccurate, outdated,
            deceptive or fraudulent.
          </p>
          <p>
            We reserve the right to cancel unconfirmed accounts or accounts that
            have been inactive for a prolonged period of time. We also reserve
            the right to cancel accounts of Users who fail to comply with the
            Terms, including the terms and conditions regarding User conduct, as
            set forth in the “User Conduct Guidelines” section below and
            elsewhere in the Terms.
          </p>
          <p>
            If your account is deactivated or cancelled, Goody will have the
            right, but not the obligation to delete Your Content.
          </p>
          <p>
            If Goody has suspended your account due to your actual or suspected
            breach of the Terms, such suspension will continue until the
            suspected breach is cured or otherwise resolved to Goody’s
            reasonable satisfaction.
          </p>
        </div>
        <h2 id="section-4" className="legal-h1">
          4. TERMS&nbsp;OF&nbsp;SALE
        </h2>
        <div className="terms-rt w-richtext">
          <h2>4.1. Products</h2>
          <p>
            Products shall be initially selected by Sender via the App.
            Recipient will have the option of accepting the originally selected
            Product, or an alternative Product of equal or lesser value. No
            Recipient is entitled to the receipt of any Product until Sender has
            confirmed the final order and the Product has shipped.
          </p>
          <h2>4.2. Prices</h2>
          <p>
            As the Sender, you acknowledge that the amount billed may vary due
            to promotional offers, the particular Products in each delivery or
            changes in applicable taxes, tax estimates or other charges, and you
            authorize us (or our third party-payment processor) to charge your
            payment method for the corresponding amount. We reserve the right to
            adjust prices as we may determine in our sole discretion, at any
            time and without notice. The shipment of Products to your designated
            Recipient after our delivery of such notice will confirm your
            acceptance of such changes, unless you cancel your order in advance
            of such shipment.
          </p>
          <h2>4.3. Payment/Credit Cards</h2>
          <p>
            YOU ARE RESPONSIBLE FOR PROVIDING GOODY WITH VALID CREDIT CARD OR
            PAYMENT ACCOUNT DETAILS AND FOR THE TIMELY PAYMENT OF ALL FEES.
            Please note that the payment information, including name and contact
            information, that you submit when you register may be used and
            shared with our payment processing services provider and otherwise
            used in accordance with our Privacy Policy. We may contact you via
            email regarding your account, for example, in connection with a
            problem with your credit card or payment account. In the event a
            Sender selects either an autopay or prepay selection, all orders
            shall be deemed confirmed upon Goody’s receipt of confirmation with
            the Recipient, and no further action will be required by Sender to
            confirm the order.
          </p>
          <h2>4.4. Orders; Refunds</h2>
          <p>
            All of our Products are subject to availability, and we reserve the
            right to impose quantity limits on any order, to reject all or part
            of an order, to discontinue offering certain Products and to
            substitute Products without prior notice. Orders placed by Senders
            may be changed or cancelled for an applicable refund until the time
            of shipment. Unless Goody agrees or states otherwise in writing, all
            other fees and charges are nonrefundable. Under no circumstances
            shall Recipients be entitled to refunds or credits for any
            unconfirmed or cancelled orders.
          </p>
          <h2>4.5. Shipping; Deliveries</h2>
          <p>
            As a Sender, you agree to pay any shipping and handling charges
            shown at the time you confirm an order. When you initially select a
            Product from our App, any taxes and shipping times shown are
            estimates only. Actual taxes and delivery dates may vary. All
            Products should be shipped to you personally and are not for resale
            or export.
          </p>
          <p>
            Any individual at the delivery address who accepts a delivery from
            us is presumed to be authorized to receive such delivery on behalf
            of the intended Recipient.
          </p>
          <h2>4.6. Sale of Alcohol</h2>
          <p>
            Goody may allow third parties to offer goods or services on the
            Website. Goody undertakes to be as accurate as possible with all
            information regarding the goods and services, including product
            descriptions, pricing, and images. However, Goody does not guarantee
            the accuracy or reliability of any product information, and you
            acknowledge and agree that you purchase such products at your own
            risk.
          </p>
          <p>
            We do not sell, offer to sell, or solicit sales of alcohol. Our
            Website enables you to search online for alcohol and other products
            available for sale by alcohol beverage licensees including
            retailers, wineries and other parties permitted to sell alcohol
            directly to consumers (“Licensees”). The service is not meant to
            facilitate any improper furnishing of inducements by any
            manufacturer, importer, supplier, wholesaler, or distributor of
            alcohol beverages to any retailer of alcoholic beverages or to
            facilitate any improper exclusionary practices by any alcohol
            beverage licensee. When you search for a product, the service shows
            you its availability, price, and other information based on your
            location and information provided by the Licensees that service your
            location. Our service may be limited or not available at all in some
            places due to local law or other restrictions.
          </p>
          <p>
            When you order alcohol, it is an offer to purchase from a Licensee.
            The total purchase amount (plus any additional service fees) may be
            authorized by the credit card company designated to make payment,
            but your offer is not accepted by the Licensee at that time. The
            Licensee reviews your order and decides whether to accept it. If the
            Licensee decides to accept your order, they will charge the payment
            method on file for the purchase amount (plus any additional service
            fees) and the retailer or a designated third party will arrange for
            the delivery, pick-up, or shipment.
          </p>
          <p>
            Title to, and ownership of, all alcohol beverages passes from a
            Licensee to the purchaser at the warehouse and state it is purchased
            in and the purchaser takes all responsibility for shipping it from
            the warehouse to his/her home state. By arranging for transportation
            of the alcohol beverages, the Licensee or a designated third party
            is providing a service to and acting on behalf of the purchaser. By
            utilizing this service, the purchaser is representing that he/she is
            acting in a fashion compliant with his/her local and state laws
            regarding the purchase, transportation, and delivery of alcohol
            beverages. The purchaser represents that he/she has obtained any
            required permission, paid any required fees, is working through
            properly licensed intermediaries where required, is legally entitled
            to take possession of alcohol beverages and is legally entitled to
            take quantities ordered and authorizes that he/she is 21 years of
            age.
          </p>
          <p>Orders and Delivery:</p>
          <p>
            Licensees are solely responsible for all activities related to the
            sale and provision of alcoholic beverages including but not limited
            to (a) selecting the products to be offered for sale via the Website
            (b) setting the purchase price of all products made available for
            sale via the Website (c) accepting or rejecting all customer orders,
            and (d) fulfilling all customer orders, including but not limited to
            completing any deliveries or shipments of orders, if applicable.
            Goody shall have no liability to you, any customer, or any other
            person for lost, unprocessed, or mishandled orders.
          </p>
          <p>
            Delivery is the sole responsibility of the Licensee or an authorized
            third-party company making deliveries or shipments on behalf of the
            Licensee. Goody is not responsible for the delivery of orders.
            Processing and delivery estimates are approximate, and Goody is not
            responsible for any delays in delivery. The shipper reserves the
            right to request a scan of any customer’s photo ID for age
            verification before shipping any item.
          </p>
          <h2>4.7. Third Party Charges and Mobile Alerts</h2>
          <p>
            You are solely responsible for any fees or charges incurred to
            access the App through an internet access provider or other third
            party, including without limitation data charges incurred if you are
            not connected to WiFi access, charges to receive SMS messages or
            other mobile access, which may be billed to you or deducted from
            your prepaid balance by your mobile provider. You agree that Goody
            is not liable in any way for any third party charges.
          </p>
        </div>
        <h2 id="section-5" className="legal-h1">
          5. USER CONDUCT GUIDELINES
        </h2>
        <div className="terms-rt w-richtext">
          <p>
            Any time you access or use the App, you are required to comply with
            our User conduct guidelines, as set forth below. You agree that you
            will access and use the App and Services for your personal use only.
          </p>
          <p>You are not authorized to access or use the App:</p>
          <ul role="list">
            <li>
              to impersonate any person or entity, or falsify or otherwise
              misrepresent your identity, credentials, affiliations or
              intentions;
            </li>
            <li>
              to collect, store or use any information from or about another
              User;
            </li>
            <li>to “stalk” or harass any other User;</li>
            <li>
              to distribute unsolicited commercial or bulk electronic
              communications (or, “spam”), chain letters or “pyramid” schemes;
            </li>
            <li>
              for political campaigning, recruiting votes or soliciting
              donations or other support for legislative or other initiatives;
            </li>
            <li>
              to systematically retrieve information or content to create or
              compile, directly or indirectly, in single or multiple downloads,
              a collection, compilation, database, directory or the like,
              whether by manual methods, through the use of bots, crawlers, or
              spiders, or otherwise;
            </li>
            <li>
              if you are not able to form legally binding contracts (for
              example, if you are under 18);
            </li>
            <li>
              if you are a person barred from receiving services under the laws
              of the United States or other applicable jurisdiction; or
            </li>
            <li>
              for any other purposes that are not expressly permitted by the
              Terms.
            </li>
          </ul>
          <p>Further, you may not:</p>
          <ul role="list">
            <li>
              access, copy, distribute, share, publish, use or store any App
              Content, including any information from or about any other User,
              for purposes that are inconsistent with our Privacy Policy, or
              otherwise violate the privacy rights or any other rights of other
              Users or any other third party, including by disclosing, selling,
              renting, distributing or exposing any App Content to a third
              party, using it for marketing purposes, or otherwise using it for
              any purposes unrelated to the App;
            </li>
            <li>
              access, copy, distribute, share, publish, use or store, or prepare
              derivative works from any App Content that belongs to Goody,
              another User or to a third party, including works covered by any
              copyrights, trademark, patent, or other intellectual property
              right, except with prior express permission of the person or
              entity party holding the rights to license such use;
            </li>
            <li>
              share or transfer your account to another party without our
              consent;
            </li>
            <li>
              circumvent our systems, policies, determinations as to your
              account status, including by attempting to access or use the App
              if your account has been suspended or cancelled or you have
              otherwise been temporarily or permanently prohibited or blocked
              from using the App;
            </li>
            <li>
              access, search, collect information from, or otherwise interact
              with the App by “scraping,” “crawling” or “spidering” the App, by
              the use of any software, device, script or robot, or by any other
              means (automated or otherwise) other than through the currently
              available, published interfaces that are provided by Goody, unless
              you have been specifically authorized to do so in a separate
              agreement with Goody;
            </li>
            <li>
              use, display, mirror or frame the App, or any feature,
              functionality, tool or content of the App, Goody’s name, any Goody
              trademark, logo or other proprietary information, without Goody’s
              express written consent;
            </li>
            <li>
              interfere with, disrupt, damage or compromise the App or our
              systems or the access of any User, host or network in any way,
              including through the use of viruses, cancel bots, Trojan horses,
              harmful code, flood pings, denial-of-service attacks, backdoors,
              packet or IP spoofing, forged routing or electronic mail address
              information or similar methods or technology or by overloading,
              flooding, spamming, mail-bombing the App or otherwise imposing an
              unreasonable or disproportionately large load on the App;
            </li>
            <li>
              access, tamper with or use non-public areas of any of the App,
              Goody’s computer systems, or the technical delivery systems of
              Goody’s providers;
            </li>
            <li>
              probe, scan, or test the vulnerability of any system or network of
              Goody or its providers, or breach or circumvent any security or
              authentication measures of such system or network;
            </li>
            <li>
              avoid, bypass, remove, deactivate, impair, descramble, or
              otherwise circumvent any technological measure implemented by
              Goody or any of Goody’s providers or any other third party to
              protect the App;
            </li>
            <li>
              forge any TCP/IP packet header or any part of the header
              information in any e-mail or posting, or in any way use the App to
              send altered, deceptive or false source-identifying information;
            </li>
            <li>
              attempt to decipher, decompile, disassemble or reverse engineer
              any of the code or software used to provide the App;
            </li>
            <li>
              export or re-export the App, except in compliance with the export
              control laws and regulations of any relevant jurisdictions;
            </li>
            <li>otherwise abuse the App or breach the Terms; or</li>
            <li>
              attempt to do any of the foregoing, or advocate, encourage or
              assist any third party in doing any of the foregoing.
            </li>
          </ul>
          <h2>5.1. Responsibility for User Content</h2>
          <p>
            Ultimately, all User Content, whether publicly posted or privately
            transmitted, is the sole responsibility of the User who originated
            such content. We do not endorse, support, represent or guarantee the
            completeness, truthfulness, accuracy, or reliability of any User
            Content or endorse any opinions expressed in such User Content. You
            understand that by using the App, you may be exposed to User Content
            that is offensive, harmful, inaccurate, misleading, fraudulent or
            otherwise inappropriate. Under no circumstances will Goody be liable
            in any way for any User Content, including, but not limited to, any
            errors or omissions in any User Content, or any loss or damage of
            any kind incurred as a result of any User Content.
          </p>
          <p>
            We may, but are not required to monitor or control the User Content
            captured, recorded, uploaded, streamed, shared or stored on or
            through the App, and we cannot take responsibility for such User
            Content. Any use or reliance on any User Content is at your own
            risk.
          </p>
          <h2>5.2. Reporting Misconduct</h2>
          <p>
            If you feel that another User has violated the Terms, abused the App
            or otherwise acted inappropriately, you may report the User to Goody
            at <a href="mailto:support@ongoody.com">support@ongoody.com</a>.
            Goody reserves the right, but assumes no obligation, to investigate
            and take appropriate action in response to such reports. Regardless
            of its action or inaction, in no event will Goody be liable for the
            acts or omissions of any User or any third party.
          </p>
          <p>
            If you are a California resident, you may also report complaints to
            the Complaint Assistance Unit of the Division of Consumer Services
            of the California Department of Consumer Affairs by contacting them
            in writing at 400 R Street, Sacramento, CA 95814, or by telephone at
            (800) 952-5210. See the “California Residents” subsection below for
            the full information required under California Civil Code §1789.3.
          </p>
          <h2>5.3. Investigations</h2>
          <p>
            Goody reserves the right to investigate and prosecute violations of
            any and all reports, complaints and claims, or otherwise suspected
            misconduct or violations of the law to the fullest extent of the
            law.
          </p>
          <p>
            Without limiting the foregoing, you acknowledge that Goody has the
            right, but not the obligation, at any time and without prior notice,
            to monitor access to or use of the App or Services by any User, to
            access, review, preserve and disclose any User Content, or to remove
            or disable access to any User Content, if we believe in good faith
            that it is reasonably necessary (i) to comply with any law or
            regulation or satisfy any legal process or governmental request (for
            example, a subpoena, warrant, order or other requirement of a court,
            administrative agency or other governmental body), (ii) to respond
            to claims asserted against Goody, (iii) to enforce and to ensure a
            User’s compliance with the Terms, including the investigation of
            potential violations, (iv) to conduct risk assessments, and prevent,
            detect and investigate incidents of fraud, security and technical
            issues, (v) to protect the rights, property or safety of Goody, its
            Users or members of the public, and (vi) for the purpose of
            operating and improving the App and Services (including for customer
            support purposes).
          </p>
        </div>
        <h2 id="section-6" className="legal-h1">
          6. INTELLECTUAL PROPERTY OWNERSHIP
        </h2>
        <div className="terms-rt w-richtext">
          <h2>6.1. The App</h2>
          <p>
            The App, Products and Services, including all features,
            functionality, tools and content thereof, is protected by copyright,
            trademark, patent and other laws of the United States and foreign
            countries. You acknowledge and agree that the App, Products and
            Services, and all intellectual property rights therein are the
            exclusive property of Goody and its licensors. You will not remove,
            alter or obscure any copyright, trademark, service mark, patent
            marking, or other proprietary rights notices incorporated in or
            accompanying the App or Services.
          </p>
          <p>
            Without limiting the foregoing, you acknowledge and agree that the
            trade names, logos, and other trademarks and service marks
            associated with Goody (the “<strong>Goody Marks</strong>”) are the
            property of Goody, and that you are not permitted to use the Goody
            Marks without our prior written consent.
          </p>
          <p>
            You may not use, copy, reproduce, distribute, license, sell,
            transfer, publish, post, publicly display, publicly perform,
            transmit, broadcast, adapt, modify, prepare derivative works based
            upon, or otherwise exploit any features, functionality, tools or
            content of the App or Services in any form or by any means, or
            sublicense the rights granted in the Terms, except as expressly
            permitted herein, without the prior written permission of Goody or
            the intellectual property owner, as applicable.
          </p>
          <p>
            This foregoing license is subject to modification or revocation at
            any time at Goody’s sole discretion.
          </p>
          <p>
            No licenses or rights are granted to you by implication or otherwise
            under any intellectual property rights owned or controlled by Goody
            or its licensors, except for the licenses and rights expressly
            granted in the Terms. All rights not expressly granted to you by the
            Terms are hereby reserved.
          </p>
          <h2>6.2. Your Content</h2>
          <p>
            By uploading, sharing or storing Your Content, you hereby grant
            Goody a worldwide, non-exclusive, transferable, sublicensable,
            royalty-free right and license to use, copy, reproduce, process,
            adapt, modify, distribute, post, broadcast, publicly perform,
            publish and display Your Content in any and all media as necessary
            to provide the Services to you, including, customer support
            services, and otherwise operate the App, including for product
            development and de-bugging purposes.
          </p>
          <p>
            We may modify or adapt Your Content in order to transmit, display or
            distribute it over computer networks and in various media and/or
            make changes to Your Content as are necessary to conform and adapt
            that content to any requirements or limitations of any networks,
            devices, services or media. You further grant us the right to use,
            copy, distribute, post, broadcast, publicly perform, publish and
            display your name and other information in connection with Your
            Content as described herein or elsewhere on the App, subject to any
            applicable data protection laws.
          </p>
          <p>
            Goody does not claim any ownership rights in Your Content and
            nothing in the Terms will be deemed to restrict any rights that you
            may have to use and exploit any such content, subject only to the
            licenses granted to Goody under these Terms.
          </p>
          <p>
            You acknowledge and agree that you are solely responsible for Your
            Content, and for any consequences thereof, including the use of your
            Content by other Users.
          </p>
          <p>
            You represent and warrant that you have all the rights, power and
            authority necessary to grant the rights granted herein to Your
            Content, and that neither Your Content, nor Goody’s use of Your
            Content (or any portion thereof) on or through the App or in
            connection with the Services will infringe, misappropriate or
            violate the rights of any person or entity, including patent,
            copyright, trademark, trade secret, moral rights, industrial rights,
            database rights or other proprietary or intellectual property
            rights, rights of publicity or privacy or data protection or
            contractual rights, or result in the violation of any applicable law
            or regulation.
          </p>
          <h2>6.3. Feedback</h2>
          <p>
            We welcome and encourage you to provide feedback, comments, ideas
            and suggestions for improvements, enhancements and modifications to
            the App (“<strong>Feedback</strong>”). You may submit Feedback by
            e-mailing us, at{" "}
            <a href="mailto:support@ongoody.com">support@ongoody.com</a>. You
            acknowledge and agree that all Feedback you give us (i) will be
            treated as non-confidential, and (ii) will be the sole and exclusive
            property of Goody. Without limiting the foregoing, you acknowledge
            that your Feedback may be disseminated or used by Goody or its
            affiliates for any purpose whatsoever, including developing,
            improving and marketing products. You hereby irrevocably transfer
            and assign to Goody all of your right, title, and interest in and to
            all Feedback, including all worldwide patent, copyright, trade
            secret, moral and other proprietary or intellectual property rights
            therein, and waive any moral rights you may have in such Feedback.
          </p>
          <p>
            You agree to sign and deliver such documents, and otherwise provide
            such assistance, as may reasonably be required from time to time to
            perfect Goody’s rights in such improvements, enhancements and
            modifications.
          </p>
        </div>
        <h2 id="section-7" className="legal-h1">
          7. THIRD PARTY TERMS
        </h2>
        <div className="terms-rt w-richtext">
          <h2>7.1. Users</h2>
          <p>
            Users are required by the Terms to provide accurate information and,
            although Goody may conduct certain limited checks and/or institute
            certain processes designed to help verify information provided by
            Users, we do not guarantee the completeness or accuracy of any
            information provided by any User, including the User’s purported
            identity, credentials or background.
          </p>
          <p>
            Any indication that a User has been “verified” (or similar language)
            only means that the User has completed a relevant verification
            process. It is not an endorsement, certification or guarantee by
            Goody about the User, including of the User’s identity, credentials
            or background. Any such description is intended only help you to
            evaluate and you make your own decisions about the identity and
            suitability of others whom you contact or interact with via the App.
          </p>
          <p>
            We recommend that you exercise due diligence when deciding to
            communicate or interact with another User, and we will not be
            responsible or liable for any damage or harm resulting from your
            interactions with other Users.
          </p>
          <h2>7.2. Links to Third-Party Websites and Services</h2>
          <p>
            The App may also provide links to third-party websites, resources or
            services. You acknowledge and agree that Goody is not responsible or
            liable for (i) the availability, terms or practices of such
            websites, resources or services, or (ii) the content, products or
            services available on or through such websites, resources or
            services, including that any information provided is complete,
            accurate or up-to-date. Links to such websites, resources or
            services do not imply any endorsement by Goody of such websites,
            resources or services or the content, products or services available
            on or through such websites, resources or services. You acknowledge
            sole responsibility for and assume all risk arising from your use of
            any such websites, resources or services or the content, products or
            services available on or through such websites or services.
          </p>
          <p>
            We will not be responsible or liable for any damage or harm
            resulting from your interactions with such websites or services, or
            the content, products or services available on or through such
            websites or services.
          </p>
          <p>
            Without limiting the foregoing, the App includes Google Maps
            features and content, which are subject to the then-current versions
            of: (1) Google Maps/Google Earth Additional Terms of Service at{" "}
            <a href="https://maps.google.com/help/terms_maps.html">
              https://maps.google.com/help/terms_maps.html
            </a>
            ; and (2) Google Privacy Policy at{" "}
            <a href="https://www.google.com/policies/privacy/">
              https://www.google.com/policies/privacy/
            </a>
            .
          </p>
          <h2>7.3. Apps Downloaded from Third Party App Stores</h2>
          <p>
            Our App may be available for download from third party app stores
            which are not owned or operated by Goody. When downloading,
            installing, accessing, using or browsing the App, you must comply
            with any applicable third party terms and conditions, including any
            End User License Agreement, your mobile device agreement or any
            wireless data service agreement, and ensure that your use of the App
            is not in violation of any such terms or conditions.
          </p>
          <p>
            Without limiting the foregoing, any Apps accessed through or
            downloaded from Apple's App Store (an "
            <strong>App Store Download</strong>") may only be used (i) on an
            Apple-branded product that runs the iOS (Apple's proprietary
            operating system), and (ii) as permitted by the "Usage Rules" set
            forth in the Apple App Store Terms and Conditions.
          </p>
          <p>
            Without limiting the foregoing, the following applies to App Store
            Downloads:
          </p>
          <ul role="list">
            <li>
              You acknowledge and agree that (i) these Terms are between you and
              Goody only, and not Apple, and (ii) Goody, not Apple, is solely
              responsible for the App Store Download and content thereof. Your
              use of the App Store Download must comply with the App Store Terms
              and Conditions.
            </li>
            <li>
              You acknowledge that Apple has no obligation whatsoever to furnish
              any maintenance and support services with respect to the App Store
              Download.
            </li>
            <li>
              In the event of any failure of the App Store Download to conform
              to any applicable warranty, you may notify Apple, and Apple will
              refund the purchase price for the App Store Download to you and to
              the maximum extent permitted by applicable law, Apple will have no
              other warranty obligation whatsoever with respect to the App Store
              Download. As between Goody and Apple, any other claims, losses,
              liabilities, damages, costs or expenses attributable to any
              failure to conform to any warranty will be the sole responsibility
              of Goody.
            </li>
            <li>
              You acknowledge that, as between Goody and Apple, Apple is not
              responsible for addressing any claims you have or any claims of
              any third party relating to the App Store Download or your
              possession and use of the App Store Download, including: (i)
              product liability claims; (ii) any claim that the App Store
              Download fails to conform to any applicable legal or regulatory
              requirement; and (iii) claims arising under consumer protection or
              similar legislation.
            </li>
            <li>
              You acknowledge that, in the event of any third-party claim that
              the App Store Download or your possession and use of that App
              Store Download infringes that third party's intellectual property
              rights, as between Goody and Apple, Goody, not Apple, will be
              solely responsible for the investigation, defense, settlement and
              discharge of any such intellectual property infringement claim to
              the extent required by the Terms.
            </li>
            <li>
              You acknowledge and agree that Apple and its subsidiaries, are
              third-party beneficiaries of the Terms as related to your license
              of the App Store Download, and that, upon your acceptance of the
              terms and conditions of the Terms, Apple will have the right (and
              will be deemed to have accepted the right) to enforce the Terms as
              related to your license of the App Store Download against you as a
              third party beneficiary thereof.
            </li>
            <li>
              Without limiting any other terms of the Terms, you must comply
              with all applicable third party terms and conditions when using
              the App Store Download.
            </li>
          </ul>
        </div>
        <h2 id="section-8" className="legal-h1">
          8. WARRANTIES, DISCLAIMERS, LIMITATION OF LIABILITY &amp;
          INDEMNIFICATION
        </h2>
        <div className="terms-rt w-richtext">
          <h2>8.1. Warranties by Users</h2>
          <p>You represent and warrant to Goody that:</p>
          <ol role="list">
            <li>
              you have the power and authority to accept and agree to the Terms;
            </li>
            <li>
              you own or control all of the rights necessary to grant the rights
              and licenses granted herein;
            </li>
            <li>
              you will not violate any federal, state or local laws, rules or
              regulations or infringe the rights of any third party, including,
              any intellectual property, privacy or publicity-related rights, in
              connection with Your Content or otherwise in connection with your
              access to or use of the App and Services;
            </li>
            <li>
              the exercise by Goody of the rights granted by you hereunder will
              not cause Goody to violate any applicable laws, rules or
              regulations, to infringe the rights of any third party; and
            </li>
            <li>
              all account information provided by you will be complete, accurate
              and up-to-date when provided, and updated as necessary to ensure
              that it remains complete, accurate and up-to-date.
            </li>
          </ol>
          <h2>8.2. Disclaimers</h2>
          <p>
            EXCEPT AS EXPRESSLY PROVIDED HEREIN, THE APP, PRODUCTS AND SERVICES
            ARE PROVIDED “AS IS” AND “AS AVAILABLE” WITHOUT WARRANTY OF ANY
            KIND, EXPRESS OR IMPLIED. TO THE MAXIMUM EXTENT NOT PROHIBITED BY
            APPLICABLE LAW, GOODY EXPRESSLY DISCLAIMS ANY AND ALL WARRANTIES,
            WHETHER EXPRESS, IMPLIED, ARISING OUT OF COURSE OF DEALING OR USAGE
            OF TRADE, INCLUDING WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
            PARTICULAR PURPOSE, NON-INFRINGEMENT OR TITLE OR RIGHTFUL CLAIM,
            WARRANTIES AS TO THE RELIABILITY OR AVAILABILITY OF THE APP,
            PRODUCTS OR SERVICES, OR THAT USE OF THE APP OR SERVICES WILL BE
            UNINTERRUPTED OR ERROR FREE, WARRANTIES AS TO THE COMPLETENESS,
            ACCURACY OR TIMELINESS OF ANY APP CONTENT.
          </p>
          <h2>8.3. Limitation of Liability</h2>
          <p>
            YOU ACKNOWLEDGE AND AGREE THAT, TO THE MAXIMUM EXTENT NOT PROHIBITED
            BY LAW:
          </p>
          <ul role="list">
            <li>
              THE ENTIRE RISK ARISING OUT OF OR RELATING TO YOUR USE OF THE APP
              IS AND REMAINS WITH YOU.
            </li>
            <li>
              WITHOUT LIMITING THE FOREGOING, GOODY DISCLAIMS ANY AND ALL
              LIABILITY RELATED TO (I) YOUR USE OF OR INABILITY TO USE THE APP,
              (II) THE ACTS OR OMISSIONS OF ANY OTHER USER OR ANY OTHER PERSONS
              WITH WHOM YOU COMMUNICATE OR INTERACT AS A RESULT OF YOUR USE OF
              THE APP, AND (III) ANY APP CONTENT ACCESSED, VIEWED OR DOWNLOADED
              IN CONNECTION WITH THE USE OF THE APP.
            </li>
            <li>
              YOU ACKNOWLEDGE AND AGREE THAT BY ACCESSING AND USING THE APP,
              SUBMITTING AND TRANSMITTING YOUR CONTENT, COMMUNICATING OR
              INTERACTING WITH OTHER USERS AND ACCESSING, VIEWING OR DOWNLOADING
              THE USER CONTENT OF OTHER USERS ARE DONE AT YOUR OWN DISCRETION
              AND RISK, AND YOU AND HEREBY RELEASE GOODY AND WAIVE ANY AND ALL
              CLAIMS AND CAUSES OF ACTION WITH RESPECT TO ANY DAMAGES CAUSED BY
              ANY OF THE FOREGOING.
            </li>
            <li>
              IN NO EVENT WILL GOODY BE LIABLE FOR ANY INDIRECT, INCIDENTAL,
              SPECIAL, EXEMPLARY, PUNITIVE OR CONSEQUENTIAL DAMAGES OF ANY KIND,
              OR DAMAGES FOR LOST REVENUES OR PROFITS, LOSS OF DATA OR LOSS OF
              GOODWILL, SERVICE INTERRUPTION, COMPUTER DAMAGE OR SYSTEM FAILURE,
              OR FOR ANY DAMAGES FOR PERSONAL OR BODILY INJURY OR EMOTIONAL
              DISTRESS ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT,
              WHETHER BASED IN WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE)
              OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT GOODY HAS BEEN
              INFORMED OF THE POSSIBILITY OF SUCH DAMAGE, EVEN IF A LIMITED
              REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL
              PURPOSE.
            </li>
            <li>
              CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES
              OR THE EXCLUSIVE OR LIMITATION OF CERTAIN DAMAGES AS SET FORTH IN
              THIS SECTION, SO THESE LIMITATIONS AND EXCLUSIONS APPLY TO YOU
              ONLY TO THE EXTENT PERMITTED BY APPLICABLE LAW. IN THE EVENT THAT
              THE FOREGOING LIMITATION OF LIABILITY IS DETERMINED BY A COURT OF
              COMPETENT JURISDICTION TO BE UNENFORCEABLE, GOODY’S AGGREGATE
              LIABILITY ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT
              SHALL BE LIMITED TO ONE HUNDRED U.S. DOLLARS (US$100).
            </li>
          </ul>
          <h2>8.4. Basis of the Bargain</h2>
          <p>
            YOU ACKNOWLEDGE AND AGREE THAT THE WARRANTY DISCLAIMERS AND
            LIMITATIONS OF LIABILITY SET FORTH ABOVE ARE ESSENTIAL ELEMENTS OF
            THE BASIS OF THE BARGAIN BETWEEN GOODY AND YOU, AND WILL SURVIVE AND
            APPLY EVEN IF YOUR REMEDIES ARE FOUND OR ALLEGED TO HAVE FAILED OF
            THEIR ESSENTIAL PURPOSE.
          </p>
          <h2>8.5. Indemnification</h2>
          <p>
            To the maximum extent not prohibited by applicable law, you agree to
            release, defend, indemnify, and hold Goody its parent, subsidiaries,
            affiliates, licensors and service providers, and its and their
            officers, directors, shareholders, agents, employees and
            representatives, harmless (collectively “indemnify” or any variation
            thereof) from and against any claims, liabilities, damages, losses,
            costs and expenses, including, any bodily injury, illness, death or
            damage to any real or personal property, or any other injuries,
            losses, or damages (whether compensatory, direct, incidental,
            consequential or otherwise) of any kind, and including reasonable
            legal fees and litigation expenses and costs, arising out of or
            relating to or in any way connected with (i) your access to or use
            of the App, including any and all features, functionality, tools,
            content and promotions available on and through the App, (ii) Your
            Content, (iii) any interactions with any other User, (iv) your
            breach of the Terms, including any violation of national, federal,
            state or local or other applicable laws, rules or regulations or any
            infringement or misappropriation of the rights of any third party,
            and (v) your gross negligence or willful misconduct.
          </p>
          <h2>8.6. Obligation to Defend.</h2>
          <p>
            You agree that, at Goody’s option, you will conduct the defense of
            any such claim or action; provided that, notwithstanding our
            election that you conduct the defense, (i) Goody may nevertheless
            participate in such defense or settlement negotiations and pay its
            own costs associated therewith, and (ii) you will not enter into any
            settlement or other compromise without the prior written approval of
            Goody (which approval shall not be unreasonably withheld), unless
            such settlement or other compromise includes a full and
            unconditional release of the relevant parties from all liabilities
            and other obligations in respect of such claim or action.
          </p>
          <h2>8.7. No Implied Indemnity.</h2>
          <p>
            No person or entity shall be entitled to any form of equitable or
            implied indemnification at any time, except as provided by the
            Terms.
          </p>
        </div>
        <h2 id="section-9" className="legal-h1">
          9. GOVERNING LAW &amp; DISPUTE RESOLUTION
        </h2>
        <div className="terms-rt w-richtext">
          <p>
            PLEASE READ THIS SECTION CAREFULLY. IT AFFECTS YOUR RIGHTS AND WILL
            HAVE A SUBSTANTIAL IMPACT ON HOW CLAIMS YOU AND Goody HAVE AGAINST
            EACH OTHER ARE RESOLVED.
          </p>
          <h2>9.1. Governing Law</h2>
          <p>
            The Terms shall be governed by and interpreted in accordance with
            the laws of the State of New York without regard to conflict of law
            principles.
          </p>
          <h2>9.2. Arbitration</h2>
          <p>
            Notwithstanding any contrary provision of these Terms, all disputes,
            claims, controversies and matters relating to or in connection with
            these Terms (or the breach thereof) or any transactions hereunder
            shall be settled by binding arbitration administered by the American
            Arbitration Association under its Commercial Arbitration Rules (“
            <strong>AAA Rules</strong>”), and judgment on the award rendered by
            the arbitrator(s) may be entered in any court having jurisdiction
            thereof. The arbitration shall take place in the State of New York
            before a single neutral arbitrator appointed in accordance with the
            AAA Rules and shall be conducted in the English language. All
            arbitrations shall be conducted and resolved on an individual basis
            and not a class-wide, multiple plaintiff or similar basis. No
            arbitration shall be consolidated with any other arbitration
            proceeding involving any other person or entity.
          </p>
          <h2>9.3. Jurisdiction and Venue</h2>
          <p>
            Subject to the above arbitration provisions, you and Goody agree
            that any and all disputes, claims and actions, at law or in equity,
            arising out of or relating to or in connection with the Terms or the
            breach, termination, enforcement, interpretation or validity
            thereof, or to the use of the App (collectively, “
            <strong>Disputes</strong>”) in the federal or state courts located
            in the State of New York and each of us agrees that such courts
            shall have exclusive jurisdiction and venue for any such actions,
            except that Goody retains the right to submit a Dispute to any court
            of competent jurisdiction. Goody also may seek injunctive or other
            equitable relief for breach of these Terms in any court of competent
            jurisdiction wherever located. You consent to the jurisdiction of
            and venue in such courts and waive any objection as to inconvenient
            forum. The prevailing party in any suit, action or proceeding,
            including any arbitration proceeding, will be entitled to recover
            its reasonable legal fees and costs and expenses from the other
            party.
          </p>
          <h2>
            9.4. Prohibition of Class and Representative Actions and
            Non-Individualized Relief
          </h2>
          <p>
            YOU AGREE THAT YOU MAY BRING CLAIMS AGAINST GOODY ONLY ON AN
            INDIVIDUAL BASIS AND HEREBY WAIVE THE RIGHT TO PARTICIPATE AS A
            PLAINTIFF OR CLASS MEMBER IN ANY CLASS ACTION OR REPRESENTATIVE
            PROCEEDING, TO THE MAXIMUM EXTENT NOT PROHIBITED BY APPLICABLE LAW.
            FURTHER, UNLESS BOTH YOU AND GOODY OTHERWISE AGREE IN WRITING, THE
            COURT MAY NOT CONSOLIDATE OR JOIN MORE THAN ONE PERSON’S CLAIMS, AND
            MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF CONSOLIDATED,
            REPRESENTATIVE, OR CLASS PROCEEDING.
          </p>
          <h2>9.5. Future Amendments to this Section</h2>
          <p>
            Both of us agree that if we make any amendment to this{" "}
            <strong>Dispute Resolution</strong> section (other than an amendment
            to any notice address or site link provided herein) in the future,
            that amendment will not apply to any claim that was filed in a legal
            proceeding against us prior to the effective date of the amendment.
            However, the amendment will apply to all other disputes or claims
            governed by this section that have arisen or may arise between you
            and Goody. We will notify you of amendments to this section by
            posting the amended Terms on the App. If you do not agree to the
            amended terms, you may close your account within 30 days and you
            will not be bound by the amended dispute resolution terms. If you do
            not have an account (or once you have closed your account, if
            applicable), you must cease using the App immediately. By rejecting
            any change, you are agreeing that you will arbitrate any Dispute
            between you and Goody in accordance with the provisions of this
            “Dispute Resolution” section as of the date you first accepted the
            Terms (or accepted any subsequent changes to the Terms).
          </p>
        </div>
        <h2 id="section-10" className="legal-h1">
          10. MISCELLANEOUS
        </h2>
        <div className="terms-rt w-richtext">
          <h2>10.1. Privacy</h2>
          <p>
            Our collection and use of information about Users is governed by our
            Privacy Policy. By accessing and using the App, you consent to the
            collection and use of this information, including the transfer of
            this information outside the United States and/or other countries,
            for storage, processing and use by Goody. As part of providing you
            the App, we may need to provide you with certain communications,
            such as service announcements and administrative messages. These
            communications are considered part of the Services, which you may
            not be able to opt-out from receiving.
          </p>
          <h2>10.2. Entire Agreement</h2>
          <p>
            These Terms, including these Terms of Use, our Privacy Policy, the
            applicable Supplemental Terms and any and all App Rules, constitute
            the entire and exclusive understanding and agreement between you and
            Goody regarding your access to and use of the App and supersede and
            replace any and all prior or contemporaneous oral or written
            understandings or agreements between you and Goody and regarding the
            subject matter hereof.
          </p>
          <h2>10.3. Assignment</h2>
          <p>
            You may not assign, transfer, delegate or sublicense any of your
            rights or obligations under the Terms, including by operation of law
            or merger or consolidation, without our express prior written
            consent, which may be granted or withheld in our sole discretion.
            Any attempted assignment, transfer, delegation or sublicense without
            the foregoing consent will be null and void. Goody may assign,
            transfer, delegate and/or sublicense our rights and obligations
            under the Terms, in whole or in part, in its sole discretion,
            without restriction.
          </p>
          <p>
            Subject to the foregoing, the Terms will bind and inure to the
            benefit of the parties, their successors and assigns.
          </p>
          <h2>10.4. No Agency</h2>
          <p>
            Except as otherwise expressly set forth herein, no agency,
            partnership, joint venture, employee-employer or
            franchiser-franchisee relationship is intended or created by the
            Terms.
          </p>
          <h2>10.5. Survival of Terms</h2>
          <p>
            Any provisions of the Terms that contemplate performance or
            observance subsequent to the expiration or termination of these
            Terms of Service shall survive such expiration or termination.
          </p>
          <h2>10.6. Notices</h2>
          <p>
            Any notices or other communications permitted or required hereunder,
            including those regarding modifications to the Terms, will be in
            writing and given by Goody (i) via e-mail (in each case to the
            address that you provide), or (ii) by posting to the App. For
            notices made by e-mail, the date on which such notice is transmitted
            will be deemed the date of receipt.
          </p>
          <h2>10.7. Waiver</h2>
          <p>
            Our failure to exercise any right or enforce any obligation under
            these Terms of Use or to take action with respect to a breach by you
            or others will not constitute a waiver of such right, obligation or
            breach. The waiver of any right, obligation or breach will be
            effective only if in writing and signed by a duly authorized
            representative of Goody. In addition, no waiver granted in any
            instance shall constitute a waiver in any other instance.
          </p>
          <h2>10.8. Remedies</h2>
          <p>
            Except as expressly set forth in the Terms, the exercise by either
            party of any of its remedies under the Terms will be without
            prejudice to its other remedies available under contract, at law, in
            equity or otherwise.
          </p>
          <h2>10.9. Severability</h2>
          <p>
            Except as otherwise provided in the Terms, if an arbitrator or a
            court of competent jurisdiction finds any provision of the Terms to
            be invalid, void or unenforceable, in whole or in part, for any
            reason, the offending provision will be enforced to the maximum
            extent permissible and will not affect the validity or
            enforceability of the remaining provisions, which will remain in
            full force and effect.
          </p>
          <h2>10.10. Headings</h2>
          <p>
            The headings in the Terms are for reference purposes only and do not
            limit or otherwise affect the meaning or interpretation of any of
            the provisions hereof.
          </p>
          <h2>10.11. Contact Us</h2>
          <p>
            If you have any questions or concerns, please contact Goody at{" "}
            <a href="mailto:support@ongoody.com">support@ongoody.com</a>.
          </p>
          <p>You can also write to us at:</p>
          <p>
            Goody Technologies, Inc.
            <br />
            9450 SW Gemini Dr
            <br />
            PMB 88760
            <br />
            Beaverton, OR 97008
          </p>
          <h2>10.12. California Residents</h2>
          <p>
            Pursuant to California Civil Code §1789.3, California residents are
            also entitled to the following specific consumer rights notice:
          </p>
          <p>
            Complaints regarding the App or Services or requests to receive
            further information regarding use of the App or Services may be sent
            to the above address or to{" "}
            <a href="mailto:support@ongoody.com">support@ongoody.com</a>.
          </p>
          <p>
            The Complaint Assistance Unit of the Division of Consumer Services
            of the California Department of Consumer Affairs may be contacted in
            writing at 1625 North Market Boulevard, Suite N112, Sacramento, CA
            95834 or by telephone at (916) 445-1245 or (800) 952-5210. Hearing
            impaired persons may call TDD (800)-326-2297 or TDD (916)-928-1227,
            see <a href="http://www.dca.ca.gov/">www.dca.ca.gov</a> for
            additional information.
          </p>
        </div>
        <h2 id="section-11" className="legal-h1">
          11. REFERRALS
        </h2>
        <div className="terms-rt w-richtext">
          <p>
            You can receive $50 in Goody credits ("Referral Bonus") for each one
            (1) new Goody for Business user you refer that results in a Goody
            gift send (“Successful Referral”) subject to discretional review by
            Goody Technologies, Inc. (“Goody”, “We”).
          </p>
          <p>For a referral to qualify, a referred user must:</p>
          <ul>
            <li>Not have previously created a Goody for Business account</li>
            <li>
              Create their Goody for Business account using a valid work email
            </li>
            <li>Not be added to paid seats on Goody Team accounts</li>
            <li>
              Send a Goody gift (minimum order value $35, inclusive of any
              applicable promotions) and successfully pay at checkout
            </li>
          </ul>
          <p>
            We may offer you additional ways to earn Referral Bonus via special
            promotional offers. You’ll find out more about the number of Goody
            credits you can earn and any other terms at the time of the offer.
          </p>
          <p>
            You’ll see the total Goody credits you’ve earned through referrals
            reflected in your Goody account on the Referrals tab.
          </p>
          <p>
            Credits granted as a Referral Bonus cannot be used toward alcohol or
            gift card purchases.
          </p>
          <p>
            Referral messages should be sent only through the Program as
            described in these Terms & Conditions. Please send your referrals
            only to eligible people, and limit your referrals to a reasonable
            number. Use only truthful, non-misleading statements that accurately
            reflect Goody's services and its Referral Program when sending a
            referral message. Referring persons must comply with all applicable
            laws, regulations and guidelines, including up-to-date “SPAM” laws.
            Bulk email distribution and any use of automated devices or third
            party services is prohibited. Any use of the Referral program that
            could constitute unsolicited commercial email or "spam" under any
            applicable law or regulation, or that otherwise violates any
            restriction imposed by Goody or is otherwise inappropriate as
            determined by Goody in its sole discretion, is expressly prohibited
            and will be grounds for immediate exclusion from the Program.
          </p>
          <p>
            Goody can only notify you once your account earns a Successful
            Referral. Your Goody account must be in good standing (no unpaid
            gift invoices, no outstanding subscription payments due, compliant
            with Goody’s{" "}
            <a href="https://www.ongoody.com/terms" tw="font-semibold">
              Terms of Use
            </a>
            ) at the time a Referral Bonus is deposited into your account to
            receive the Bonus. Existing Goody users are not able to refer
            themselves and create new accounts in order to claim referral
            credits. Goody may withdraw your ability to refer new users, and
            withhold awarding (or reclaim a previously awarded) any and all
            Referral Bonuses, if we believe, in our sole discretion, that you
            are engaged in fraud, misuse or abuse of this offer.
          </p>
          <p>
            Goody reserves the right to change or modify any of the terms and
            conditions of the Referral Program at any time and in our sole
            discretion. Any changes or modifications will be effective
            immediately upon posting of the revisions on ongoody.com, and you
            waive any right you may have to receive specific notice of such
            changes or modifications.
          </p>
        </div>
        <div>
          <br />
          <p>Copyright ©2023 Goody Technologies, Inc. All rights reserved.</p>
        </div>
      </CopiedHtmlContainer>
    </BasicContainer>
  )
}

export default Terms
