import { groupBy, keyBy, uniq } from "lodash-es"

import { GiftFragment, TrackPageGiftFragment } from "@/types/graphql-types"

export const useShipments = (gift: TrackPageGiftFragment | GiftFragment) => {
  const productFragmentsById = keyBy(
    gift.cartProducts?.map((cartProduct) => cartProduct.productFragment),
    "brandId",
  )

  const uniqBrandIds = uniq(
    gift.cartProducts?.map(
      (cartProduct) => cartProduct.productFragment.brandId,
    ),
  ) ?? [gift.productFragment.brandId]

  const shipmentsByBrandId = groupBy(
    gift.shipments,
    (shipment) => shipment.brand.id,
  )

  return {
    uniqBrandIds,
    shipmentsByBrandId,
    productFragmentsById,
  }
}
