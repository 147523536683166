import GoodyProductCommon from "./GoodyProductCommon"

interface Props {
  id: string
  price?: string
}

export function GoodyProductWithGiftOption({ id, price }: Props) {
  return <GoodyProductCommon id={id} withGiftOption={true} price={price} />
}
