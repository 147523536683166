import { gql, useMutation, useQuery } from "@apollo/client"
import { useState } from "react"
import { Link, useHistory } from "react-router-dom"

import Button from "../common/Button"
import { generateRealmPath } from "../common/realm"
import Container from "../sites/App/Container"

import {
  Organization_API_CommerceAppsQuery,
  Organization_API_CreateCommerceAppMutation,
  Organization_API_CreateCommerceAppMutationVariables,
} from "@/types/graphql-types"

export default function CommerceAPI() {
  const { data, loading } =
    useQuery<Organization_API_CommerceAppsQuery>(COMMERCE_APPS_QUERY)

  // create commerce application mutation:
  const [createCommerceApp, { loading: mutationLoading }] = useMutation<
    Organization_API_CreateCommerceAppMutation,
    Organization_API_CreateCommerceAppMutationVariables
  >(COMMERCE_APP_CREATE_MUTATION, {
    refetchQueries: [
      {
        query: COMMERCE_APPS_QUERY,
      },
    ],
  })

  const history = useHistory()

  const [newAppName, setNewAppName] = useState<string>("")

  const handleCreateNewApplication = async () => {
    const res = await createCommerceApp({
      variables: {
        name: newAppName,
      },
    })

    if (res.data?.commerceAppCreate?.ok) {
      setNewAppName("")
      history.push(
        generateRealmPath(
          "plus",
          `/organization/api/commerce/${res.data.commerceAppCreate.id}`,
        ),
      )
    } else {
      alert(res.data?.commerceAppCreate?.error || "Error creating application")
    }
  }

  return (
    <div>
      <Container>
        <div tw="p-6 pt-12">
          <div tw="flex flex-col md:flex-row items-start gap-12">
            <div tw="md:w-[30%]">
              <div tw="font-medium text-2xl pb-4">Commerce API</div>
              <div tw="pb-3">
                The Commerce API lets you sell Goody products in your own app,
                without handling any inventory, logistics, or payments.
              </div>
              <div>
                <a
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                  href="https://developer.ongoody.com/commerce-api/overview"
                  tw="text-primary-new-500 font-medium hover:text-primary-new-600 active:text-primary-new-700"
                >
                  API documentation
                </a>
              </div>
            </div>
            <div tw="flex-1">
              <div tw="font-medium text-xl pb-4">Commerce Applications</div>
              <div tw="pb-3">
                {!data && loading ? (
                  <div tw="text-gray-500">Loading&hellip;</div>
                ) : (
                  data?.organization && (
                    <div>
                      {data.organization.commerceApps.length === 0 ? (
                        <div tw="text-gray-500">
                          You don't have any commerce applications yet. Create
                          one below.
                        </div>
                      ) : (
                        <div tw="flex flex-col items-stretch gap-3">
                          {data.organization.commerceApps.map((application) => (
                            <Link
                              to={generateRealmPath(
                                "plus",
                                `/organization/api/commerce/${application.id}`,
                              )}
                              key={application.id}
                              tw="border border-gray-250 p-4 hover:bg-gray-050 active:bg-gray-100 flex flex-row items-center justify-between rounded-lg transition-colors"
                            >
                              <div tw="font-medium">{application.name}</div>
                              <div tw="text-gray-500">
                                Public App ID {application.publicAppId}
                              </div>
                            </Link>
                          ))}
                        </div>
                      )}
                    </div>
                  )
                )}
              </div>

              <div tw="font-medium text-xl pt-8 pb-4">
                New Commerce Application
              </div>
              <form
                tw="flex flex-row items-stretch gap-2 max-w-[400px]"
                onSubmit={(e) => {
                  e.preventDefault()
                  handleCreateNewApplication()
                }}
              >
                <input
                  tw="border border-gray-250 rounded-md p-2 px-4 w-full focus:border-primary-new-500 focus:outline-none transition-colors"
                  placeholder="Application name"
                  value={newAppName}
                  onChange={(e) => setNewAppName(e.target.value)}
                />
                <Button variant="updated" disabled={mutationLoading}>
                  Create
                </Button>
              </form>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

const COMMERCE_APPS_QUERY = gql`
  query Organization_API_CommerceApps {
    organization {
      commerceApps {
        id
        name
        publicAppId
      }
    }
  }
`

const COMMERCE_APP_CREATE_MUTATION = gql`
  mutation Organization_API_CreateCommerceApp($name: String!) {
    commerceAppCreate(name: $name) {
      ok
      error
      id
    }
  }
`
