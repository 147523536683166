import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import { useHistory } from "react-router-dom"
import tw from "twin.macro"

import CalendarEvents from "./CalendarEvents"
import Events from "./Events"
import RecentlyAccepted from "./RecentlyAccepted"
import { useGlobalState } from "../common/GlobalState"
import { useCurrentWorkspace } from "../common/hooks"
import { useFeatureAccess } from "../common/hooks/featureAccess"
import { generateRealmPath } from "../common/realm"
import TeamPlanUpsell from "../common/TeamPlanUpsell"

import { PlusEnrollmentStatus } from "@/types/graphql-types"

interface Props {
  visible: boolean
}

const Dashboard: React.FC<Props> = ({ visible }) => {
  const [enrollmentStatus] = useGlobalState("enrollmentStatus")
  const history = useHistory()

  const { hasFeature } = useFeatureAccess()
  const hasContacts = hasFeature("contacts")

  /**
   Whether the user has selected a date on the calendar manually.
   By default, this is false since no date is selected on component mount.
   Controls whether we display an indicator that the selected date has no
   events.
   */
  const [dateManuallySelected, setDateManuallySelected] = useState(false)

  // Date to render events list from - defaults to today
  const [eventListDate, setEventListDate] = useState(new Date())

  const { currentWorkspace } = useCurrentWorkspace()

  useEffect(() => {
    if (visible && enrollmentStatus === PlusEnrollmentStatus.NONE) {
      history.replace(generateRealmPath("business", "/signup"))
    }
  }, [enrollmentStatus, visible])

  if (enrollmentStatus === PlusEnrollmentStatus.NONE) {
    return null
  }

  if (!currentWorkspace) {
    return null
  }

  return (
    <div
      css={visible ? undefined : tw`hidden`}
      tw="lg:border-t border-primary-100"
    >
      {visible && (
        <Helmet>
          <title>Dashboard – Goody for Business</title>
        </Helmet>
      )}
      <div tw="pb-5 md:pb-12">
        <div
          tw="container p-3.5 pt-12 pb-24 mx-auto bg-white flex flex-col-reverse xl:flex-row xl:py-12 xl:pr-5 xl:pl-0"
          key={currentWorkspace.id}
        >
          {hasContacts ? (
            <Events
              anchorDate={eventListDate}
              dateManuallySelected={dateManuallySelected}
              workspaceId={currentWorkspace.id}
            />
          ) : (
            <TeamPlanUpsell eventPrefix="Dashboard - " />
          )}
          <CalendarEvents
            eventListDate={eventListDate}
            setEventListDate={setEventListDate}
            dateManuallySelected={dateManuallySelected}
            setDateManuallySelected={setDateManuallySelected}
            workspaceId={currentWorkspace.id}
          />
        </div>
      </div>
      <RecentlyAccepted />
    </div>
  )
}

export default Dashboard
