import { useMutation, useQuery } from "@apollo/client"
import React from "react"
import { toast } from "react-hot-toast"
import { Redirect, useHistory, useLocation } from "react-router-dom"
import tw, { styled } from "twin.macro"

import { GET_WORKSPACE_INVITE, WORKSPACE_INVITE_ACCEPT } from "./queries"
import { ReactComponent as ArrowRight } from "../assets/icons/arrow-right.svg"
import { useGlobalState } from "../common/GlobalState"
import GradientButton from "../common/GradientButton"
import { useCurrentWorkspace } from "../common/hooks"
import { generateRealmPath } from "../common/realm"
import { getRestoreToken } from "../signUp/restoreToken"
import {
  BackgroundGradientContainer,
  TopBoxPrimary,
  TopBoxSecondary,
} from "../signUp/SignUp"

import { PlusEnrollmentStatus } from "@/types/graphql-types"
import {
  GetWorkspaceInviteQuery,
  GetWorkspaceInviteQueryVariables,
  WorkspaceInviteAcceptMutation,
  WorkspaceInviteAcceptMutationVariables,
} from "@/types/graphql-types"

interface InvitationProps {}

const WorkspaceInvitation: React.FC<InvitationProps> = () => {
  const [acceptWorkspaceInvite, { loading }] = useMutation<
    WorkspaceInviteAcceptMutation,
    WorkspaceInviteAcceptMutationVariables
  >(WORKSPACE_INVITE_ACCEPT)
  const [signedIn] = useGlobalState("signedIn")
  const [enrollmentStatus] = useGlobalState("enrollmentStatus")
  const enrolled = signedIn && enrollmentStatus !== PlusEnrollmentStatus.NONE

  const history = useHistory()

  const { hash } = useLocation()
  let token = hash.split("#token=")[1]

  if (!token) {
    token = getRestoreToken() || ""

    if (token) {
      window.location.hash = "#token=" + token
    }
  }

  const workspaceInviteResult = useQuery<
    GetWorkspaceInviteQuery,
    GetWorkspaceInviteQueryVariables
  >(GET_WORKSPACE_INVITE, {
    variables: { workspaceInviteToken: token || "" },
  })
  const { updateCurrentWorkspace } = useCurrentWorkspace()

  const submit = async () => {
    if (!token) {
      return
    }

    const res = await acceptWorkspaceInvite({
      variables: {
        workspaceInviteToken: token,
      },
    })

    const resData = res.data?.workspaceInviteAccept

    if (resData?.ok) {
      if (resData?.joinedWorkspace) {
        updateCurrentWorkspace({
          id: resData.joinedWorkspace.id,
          name: resData.joinedWorkspace.name,
          ttype: resData.joinedWorkspace.ttype,
        })

        toast.success(
          `You've joined the ${resData.joinedWorkspace.name} workspace.`,
          {
            iconTheme: {
              primary: "#27ae60",
              secondary: "#fff",
            },
          },
        )
      } else {
        toast.success(`You've joined the workspace.`, {
          iconTheme: {
            primary: "#27ae60",
            secondary: "#fff",
          },
        })
      }

      history.push(generateRealmPath("plus", "/browse"))
    } else {
      if (resData?.error) {
        alert(resData.error)
      } else {
        alert("An unknown error occurred.")
      }
    }
  }

  if (!token || workspaceInviteResult.loading) {
    return null
  }

  const workspaceInviteData = workspaceInviteResult.data

  if (!workspaceInviteData || !workspaceInviteData.workspaceInvite) {
    return (
      <div tw="container mx-auto bg-white">
        <BackgroundGradientContainer>
          <TitleTextGradient tw="text-center pt-24 pb-12">
            Invite not found
          </TitleTextGradient>
          <InviteBanner
            content="Please ask the person who invited you to re-invite you."
            title="Invite not found"
          />
        </BackgroundGradientContainer>
      </div>
    )
  }

  const { workspaceName, isExpired, senderName } =
    workspaceInviteData.workspaceInvite

  if (isExpired) {
    return (
      <div tw="container mx-auto bg-white">
        <BackgroundGradientContainer>
          <TitleTextGradient tw="text-center pt-24 pb-12">
            Join {workspaceName}
          </TitleTextGradient>
          <InviteBanner
            content={`Please ask ${senderName} to re-invite you`}
            title="Sorry, this invite is expired"
          />
        </BackgroundGradientContainer>
      </div>
    )
  }

  if (workspaceInviteData?.workspaceInvite && !enrolled) {
    return (
      <Redirect to={generateRealmPath("business", `/signup#token=${token}`)} />
    )
  }

  return (
    <>
      <BackgroundGradientContainer>
        <TitleTextGradient tw="text-center pt-24 pb-12">
          Join {workspaceName}
        </TitleTextGradient>
      </BackgroundGradientContainer>
      <div tw="container mx-auto bg-white px-5">
        <div tw="lg:mx-48">
          <InviteBanner
            content={`Join the workspace to start sending gifts and collaborate with ${senderName}.`}
            title={`${senderName} invited you to the ${workspaceName} workspace`}
            showAccept={true}
            onAccept={submit}
            acceptLoading={loading}
          />
        </div>
      </div>
    </>
  )
}

interface InviteBannerProps {
  content: string
  title: string
  showAccept?: boolean
  onAccept?: () => void
  acceptLoading?: boolean
}

const InviteBanner = ({
  content,
  title,
  showAccept,
  onAccept,
  acceptLoading,
}: InviteBannerProps) => {
  return (
    <div
      tw="rounded-xl bg-white bg-opacity-50 relative -top-24"
      className="fadeInUpAbsolute animated animate__delay400 animate__signup"
    >
      <LeftTopBox tw="bg-white rounded-xl px-8 py-10 flex flex-col items-center">
        <TopBoxPrimary>{title}</TopBoxPrimary>
        <TopBoxSecondary>
          <p>{content}</p>
        </TopBoxSecondary>
        {showAccept && (
          <div tw="flex justify-center items-center mt-16">
            <GradientButton onClick={onAccept} disabled={acceptLoading}>
              Accept Invite
              <ArrowRight tw="ml-8" />
            </GradientButton>
          </div>
        )}
      </LeftTopBox>
    </div>
  )
}

const LeftTopBox = styled.div`
  box-shadow:
    0 4px 6px rgba(0, 0, 0, 0.06),
    0 8px 50px rgba(0, 0, 0, 0.08);
`

const TitleTextGradient = styled.div`
  background: linear-gradient(90deg, #d77093, #b37dd5);
  -webkit-text-fill-color: transparent;
  ${tw`text-5xl font-medium bg-clip-text`}
`

export default WorkspaceInvitation
