import React, { useMemo } from "react"
import tw, { styled } from "twin.macro"

interface AlcoholShippingInfoProps {
  smallWidth?: boolean
  shippingStates: string[]
}

const AlcoholShippingInfo = ({
  shippingStates,
  smallWidth = false,
}: AlcoholShippingInfoProps) => {
  const shippingStateLookup = useMemo(() => {
    const lookup: Record<string, boolean> = {}

    shippingStates.forEach((state) => {
      lookup[state] = true
    })

    return lookup
  }, [shippingStates])

  const [canShip, cannotShip] = useMemo(() => {
    const canShip: string[] = []
    const cannotShip: string[] = []

    states.forEach((state) => {
      if (shippingStateLookup[state]) {
        canShip.push(state)
      } else {
        cannotShip.push(state)
      }
    })

    // Bevvi doesn't always return the states in alphabetical order
    return [canShip.sort(), cannotShip.sort()]
  }, [shippingStateLookup])

  return (
    <Container small={smallWidth}>
      <div tw="mb-4 mt-4 text-gray-450 text-center px-1">
        <span tw="font-medium">Recipients can swap for a non-alcohol item</span>{" "}
        if they prefer a non-alcohol gift, or if their state is not eligible for
        shipping. Recipients must be 21 years of age or older to accept an
        alcohol gift.
      </div>
      <div tw="px-2">
        <CanShipText tw="font-normal text-gray-400">Cannot ship to</CanShipText>
        <hr tw="mb-5" />
        <StateGrid tw="text-gray-400" small={smallWidth}>
          {cannotShip.map((state) => (
            <div>{state}</div>
          ))}
        </StateGrid>
      </div>
    </Container>
  )
}

const CanShipText = tw.div`bg-white font-medium inline-block pr-3 relative text-gray-600 top-3`
const Container = styled.div<{ small?: boolean }>(({ small }) => [
  tw`mt-10`,
  small && tw`mt-4`,
])
const StateGrid = styled.div<{ small?: boolean }>(({ small }) => [
  tw`grid grid-cols-10 2xl:grid-cols-14 gap-y-2`,
  small && tw`2xl:grid-cols-10`,
])

const states = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "DC",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
]

export default AlcoholShippingInfo
