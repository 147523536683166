import { useEffect, useState } from "react"

interface DelayedComponentProps {
  waitBeforeShow: number
}

export const DelayedComponent: React.FC<DelayedComponentProps> = ({
  children,
  waitBeforeShow,
}) => {
  const [hidden, setHidden] = useState<boolean>(true)
  useEffect(() => {
    const timer = setTimeout(() => {
      setHidden(false)
    }, waitBeforeShow)

    return () => clearTimeout(timer)
  }, [])

  return hidden ? <></> : <>{children}</>
}
