/*
  Helper method to construct URLs for assets stored in S3.
  To add an asset, use the S3 browser located at
  https://admin.ongoody.com/admin/s3_browser
*/

const BASE_URL = "https://assets.ongoody.com/"

const staticAssetUrl = (path: string) => {
  const uri = new URL(path, BASE_URL)

  if (
    uri.pathname.endsWith(".png") ||
    uri.pathname.endsWith(".jpg") ||
    uri.pathname.endsWith(".jpeg")
  ) {
    uri.searchParams.set("auto", "webp")
  }

  return uri.toString()
}

export default staticAssetUrl
