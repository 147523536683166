import { ApolloClient } from "@apollo/client"

import { EDITORIAL_QUERY } from "@/store/editorial/graphql"
import {
  Store_EditorialQuery,
  Store_EditorialQueryVariables,
} from "@/types/graphql-types"

interface FetchEditorialItemParams {
  client: ApolloClient<Object>
  slug: string
  isPrefetch?: boolean
}

export const fetchEditorialItemBySlug = async ({
  client,
  slug,
}: FetchEditorialItemParams) => {
  const res = await client.query<
    Store_EditorialQuery,
    Store_EditorialQueryVariables
  >({
    query: EDITORIAL_QUERY,
    variables: {
      slug,
    },
  })

  return res?.data?.editorialItem
}
