import { Link } from "react-router-dom"

import { ReactComponent as ChevronLeft } from "../../../assets/icons/chevron-left.svg"

interface BudgetingHeaderProps {
  title: string
  subtitle: string
  backPath?: string
  backText?: string
}

export default function BudgetingHeader({
  title,
  subtitle,
  backPath,
  backText,
}: BudgetingHeaderProps) {
  return (
    <div tw="flex flex-row items-start py-9">
      <div tw="flex-1 -mt-2">
        {backPath && backText && (
          <Link
            to={backPath}
            tw="-ml-2 px-3 py-2 inline-flex flex-row items-center justify-center gap-1 rounded-lg hover:bg-primary-new-050 active:bg-primary-new-100 text-primary-new-500 transition-colors"
          >
            <ChevronLeft tw="stroke-current" />
            <div>{backText}</div>
          </Link>
        )}
      </div>
      <div tw="text-center">
        <div tw="font-semibold text-2xl">{title}</div>
        <div tw="text-gray-500 pt-3">{subtitle}</div>
      </div>
      <div tw="flex-1"></div>
    </div>
  )
}
