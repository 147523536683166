import staticAssetUrl from "../../../common/staticAssetUrl"
import Container from "../../../sites/App/Container"

const USE_CASES = [
  {
    image: `${staticAssetUrl("static/web/landing/api/automation-sales.png")}`,
    title: "Automate sales gifts using CRM data",
    width: 216,
  },
  {
    image: `${staticAssetUrl("static/web/landing/api/automation-loyalty.png")}`,
    title: "Build loyalty programs that reward top customers",
    width: 237,
  },
  {
    image: `${staticAssetUrl(
      "static/web/landing/api/automation-research.png",
    )}`,
    title: "Provide research incentives to survey participants",
    width: 215,
  },
]

export default function UseCases() {
  return (
    <div tw="pt-16 md:pt-24">
      <Container>
        <div tw="text-3xl md:text-[2.25rem] text-center pb-12 font-reckless-neue font-250">
          How you can use the Automation API
        </div>
        <div tw="flex flex-col md:flex-row items-stretch gap-8 mx-4">
          {USE_CASES.map((useCase) => (
            <div tw="flex-1 flex flex-col items-center min-h-[300px] bg-[#f1f5f5] p-4 rounded-2xl md:rounded-[2rem]">
              <div tw="flex-[2] flex flex-row items-center justify-center">
                <img src={useCase.image} css={{ width: useCase.width }} />
              </div>
              <div tw="flex-1 text-gray-600 font-medium text-xl px-8 pb-2 text-center">
                {useCase.title}
              </div>
            </div>
          ))}
        </div>
      </Container>
    </div>
  )
}
