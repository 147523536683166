import { gql } from "@apollo/client"

import { GIFT_SETTINGS_FRAGMENT } from "@/graphql/GiftSettingsFragment"

export const CUSTOM_STORE_BASE_FRAGMENT = gql`
  fragment CustomStoreBase on CustomStore {
    id
    name
    status
    subtitle
    internationalShippingTier
    minimumPrice
    maximumPrice
    brandValuesDisplay
    multipleSelectMode
    multipleSelectCount
    multipleSelectPrice
    settings {
      ...GiftSettings
    }
    desktopHeaderImage {
      url
    }
    mobileHeaderImage {
      url
    }
    product {
      id
      brand {
        id
        name
      }
      swapStoreSettings {
        priceType
      }
    }
    numProductsHidden
    hasCreditExcludedItems
    desktopHeaderImageThumb {
      url
    }
    mobileHeaderImageThumb {
      url
    }
    productPreviewList {
      name
      brandName
      image {
        url
      }
    }
  }
  ${GIFT_SETTINGS_FRAGMENT}
`
