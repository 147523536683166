import { Track_GiftBatchFragment } from "@/types/graphql-types"

export const giftBatchSentOn = (giftBatch: Track_GiftBatchFragment) => {
  let sentOnDateString = giftBatch.createdAt

  if (giftBatch.scheduledSendOn) {
    sentOnDateString = giftBatch.scheduledSendOn
  }

  const sentOnDate = new Date(sentOnDateString)
  return sentOnDate.toLocaleString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  })
}
