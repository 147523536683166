import { Drawer } from "@mui/material"
import React, { useState } from "react"

import close from "@/assets/icons/close.svg"
import Image from "@/common/Image"
import FilterListOption from "@/organization/receipts/FilterListOption"
import RemoveFilterButton from "@/organization/receipts/RemoveFilterButton"
import SearchInput from "@/organization/receipts/SearchInput"
import {
  OrganizationMembership,
  OrganizationMembershipStatus,
} from "@/types/graphql-types"

interface UserFilterDrawerProps {
  open: boolean
  onClose: (e: Object) => void
  members: Pick<OrganizationMembership, "member" | "status">[]
  userIds: Set<string>
  toggleUserId: (e?: string) => void
  clearUserIds: () => void
  loading: boolean
}

const UserFilterDrawer = ({
  open,
  onClose,
  members,
  userIds,
  toggleUserId,
  clearUserIds,
  loading,
}: UserFilterDrawerProps) => {
  const [search, setSearch] = useState<string>("")
  const searchFilter = ({
    member: { fullName },
  }: Pick<OrganizationMembership, "member" | "status">) =>
    fullName?.toLowerCase()?.includes(search)

  return (
    <Drawer anchor="left" open={open} onClose={onClose}>
      <div tw="flex flex-col min-w-[424px] my-9">
        <div tw="flex justify-between items-center gap-4 mb-10 mx-9">
          <div tw="font-medium text-2xl">Users</div>
          <Image src={close} tw="w-6 h-6 cursor-pointer" onClick={onClose} />
        </div>
        <div tw="flex items-center gap-4 mb-5 mx-9">
          <SearchInput
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <RemoveFilterButton onClick={clearUserIds} />
        </div>
        <div tw="flex flex-col items-center mx-[27px]">
          {loading ? (
            <div tw="mt-9 text-gray-400">Loading...</div>
          ) : (
            members.filter(searchFilter).map(({ member, status }) => (
              <FilterListOption
                key={member.id}
                selected={userIds.has(member.id ?? "")}
                active={status === OrganizationMembershipStatus.ACTIVE}
                onClick={() =>
                  typeof member.id == "string" && toggleUserId(member.id)
                }
              >
                {member.fullName}
              </FilterListOption>
            ))
          )}
        </div>
      </div>
    </Drawer>
  )
}

export default UserFilterDrawer
