import React, { MouseEventHandler, ReactNode } from "react"
import tw from "twin.macro"

import { ReactComponent as ChevronDown } from "@/assets/icons/chevron-down.svg"
import Button from "@/common/CommonButton"

interface FilterButtonProps {
  active: boolean
  icon: ReactNode
  onClick: MouseEventHandler<HTMLButtonElement>
  children: ReactNode
}

const FilterButton = ({
  active,
  icon,
  onClick,
  children,
}: FilterButtonProps) => {
  const iconColor = active ? tw`text-primary-new-150` : tw`text-gray-400`
  return (
    <Button
      variant={active ? "primaryNew" : "default"}
      leftIcon={<div css={[iconColor]}>{icon}</div>}
      rightIcon={<ChevronDown css={[iconColor, tw`h-5 w-5`]} />}
      onClick={onClick}
    >
      {children}
    </Button>
  )
}

export default FilterButton
