import React from "react"
import tw from "twin.macro"

import { ReactComponent as XIcon } from "../../assets/icons/x-close.svg"

interface Props {
  onClick: () => void
}

export default function ModalCloseButton({ onClick }: Props) {
  return (
    <CloseButton onClick={onClick}>
      <XIcon />
    </CloseButton>
  )
}

const CloseButton = tw.div`bg-white hover:bg-gray-050 border border-gray-150 shadow-[0px 4px 12px 0px rgba(0, 0, 0, 0.05)] cursor-pointer flex justify-center items-center rounded-full transition active:scale-90 p-2 -mr-3 -mt-2 -mb-2 -ml-2 text-gray-350 absolute top-7 right-7 z-10`
