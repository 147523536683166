import { track } from "./analytics"
import { gtmEvent } from "./gtm"

declare let window: {
  FS?: any
}

export type ProPlanFeature =
  | "upload_csv"
  | "custom_cards"
  | "recipients_1000"
  | "schedule_send"
  | "gift_expiration"
  | "landing_page"
  | "send_via_gmail"
  | "custom_logo"
  | "app_header"
  | "international_shipping"
  | "report_filters"
  | "send_link_multiple_anyone"
  | "gift_cards"

const trackProEvent = (event: string, params?: any) => {
  if (import.meta.env.MODE === "development") {
    console.log(
      `Tracking pro plan click: ${event}${
        params ? ": " + JSON.stringify(params) : ""
      }`,
    )
  }

  gtmEvent(event, params)
  if (window.FS) {
    window.FS.event(event, params)
  }
}

export const trackProFeatureClick = (feature: ProPlanFeature) => {
  trackProEvent("Business_ProPlan_ShowUpsell", { feature })
  track("Business - Show Pro Upsell", { feature })
}

export const trackProSubscribeClick = () => {
  trackProEvent("Business_ProPlan_Modal_ClickSubscribe")
}
