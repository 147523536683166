import tw, { TwStyle } from "twin.macro"

import { CountryFlag } from "@/common/CountryFlag"
import { useFeatureAccess } from "@/common/hooks/featureAccess"

interface Props {
  twStyle?: TwStyle
  onClick?: () => void
  showProUpsell?: boolean
}

export const GlobalShippingTag = ({
  twStyle,
  onClick,
  showProUpsell,
}: Props) => {
  const hasProPlan = useFeatureAccess().hasFeature("pro_plan")

  return (
    <div tw="flex flex-row">
      <div
        onClick={onClick}
        css={[twStyle, onClick && ClickableStyle]}
        tw="flex h-8"
      >
        <GlobalTag>
          <CountryFlag code="GLOBAL" size="m" />
          <span tw="text-sm font-medium">
            Ships globally
            {showProUpsell && !hasProPlan && " with Goody Pro"}
          </span>
        </GlobalTag>
      </div>
    </div>
  )
}

const Tag = tw.div`
  flex items-center justify-center gap-3 p-2
  border border-solid rounded-lg
`

const GlobalTag = tw(Tag)`
  text-gray-600 bg-gray-050 border-gray-200
`

const ClickableStyle = tw`
  cursor-pointer transition-all
  hover:(bg-gray-075 border-gray-250)
  active:(bg-gray-100 border-gray-250 scale-95)
`
