import "react-day-picker/lib/style.css"

import { gql, useQuery } from "@apollo/client"
import { compact, concat, isEmpty } from "lodash-es"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { DayModifiers } from "react-day-picker"
import DayPicker from "react-day-picker/DayPicker"
import tw, { styled } from "twin.macro"

import { ReactComponent as CalendarIcon } from "../assets/images/calendar/calendar-icon.svg"
import { ReactComponent as MonthArrowIcon } from "../assets/images/calendar/month-arrow.svg"

import { EventKind } from "@/types/graphql-types"
import {
  Dashboard_EventKindsQuery,
  Dashboard_EventKindsQueryVariables,
} from "@/types/graphql-types"

interface EventKindHash {
  [date: string]: EventKind[]
}

type UpcomingEvent = NonNullable<
  NonNullable<Dashboard_EventKindsQuery["me"]>["upcomingEvents"]
>[0]

const CalendarEvents: React.FC<{
  eventListDate: Date
  setEventListDate: (date: Date) => void
  dateManuallySelected: boolean
  setDateManuallySelected: (dateManuallySelected: boolean) => void
  workspaceId: string
}> = ({
  eventListDate,
  setEventListDate,
  dateManuallySelected,
  setDateManuallySelected,
  workspaceId,
}) => {
  // Calendar date to render from - by default renders events from previous, current, and next year
  const [calendarDate, setCalendarDate] = useState(new Date())

  const [events, setEvents] = useState<EventKindHash>({})

  const [visibleMonth, setVisibleMonth] = useState(new Date())

  const [showCalendar, setShowCalendar] = useState(false)

  const [fetchingEvents, setFetchingEvents] = useState(false)

  useEffect(() => {
    setFetchingEvents(true)
  }, [workspaceId])

  // Query to fetch current year
  const { data } = useQuery<
    Dashboard_EventKindsQuery,
    Dashboard_EventKindsQueryVariables
  >(EVENT_TYPES_QUERY, {
    variables: { anchorDate: offsetToLocalTimezone(calendarDate) },
    context: { workspaceId },
    fetchPolicy: "network-only",
  })

  // Query to fetch past year
  const oneYearAgo = moment(calendarDate).subtract(1, "year").toDate()
  const { data: pastYearData } = useQuery<
    Dashboard_EventKindsQuery,
    Dashboard_EventKindsQueryVariables
  >(EVENT_TYPES_QUERY, {
    variables: { anchorDate: offsetToLocalTimezone(oneYearAgo) },
    context: { workspaceId },
    fetchPolicy: "network-only",
  })

  // Query to fetch next year
  const nextYearOut = moment(calendarDate).add(1, "year").toDate()
  const { data: nextYearData } = useQuery<
    Dashboard_EventKindsQuery,
    Dashboard_EventKindsQueryVariables
  >(EVENT_TYPES_QUERY, {
    variables: { anchorDate: offsetToLocalTimezone(nextYearOut) },
    context: { workspaceId },
    fetchPolicy: "network-only",
  })

  useEffect(() => {
    // Store all non-empty events in events variable
    const eventKindData = compact(
      concat(
        concat(data?.me?.upcomingEvents, pastYearData?.me?.upcomingEvents),
        nextYearData?.me?.upcomingEvents,
      ),
    )

    // Used to show dots in calendar
    setEvents(formatEventsForCalendar(eventKindData))
  }, [
    data?.me?.upcomingEvents,
    pastYearData?.me?.upcomingEvents,
    nextYearData?.me?.upcomingEvents,
  ])

  /**
            Return events in the format below for rendering in the calendar
              {
                10/05/2023: ['BIRTHDAY'],
                10/15/2021: ['BIRTHDAY', 'WORK_ANNIVERSARY']
              }
          */
  function formatEventsForCalendar(data: UpcomingEvent[] | undefined | null) {
    let newEvents: EventKindHash = {}

    if (isEmpty(data)) {
      return newEvents
    }

    data?.forEach((value: UpcomingEvent | undefined | null) => {
      const dateKey = moment(value?.relevantRecurrence).format("MM/DD/YYYY")
      const eventKind = value?.kind

      if (eventKind) {
        if (newEvents.hasOwnProperty(dateKey)) {
          newEvents[dateKey].push(eventKind)
        } else {
          newEvents[dateKey] = [eventKind]
        }
      }
    })

    return newEvents
  }

  function rerenderCalendarAndEventsForDate(newDate: Date) {
    setEventListDate(newDate)
    setVisibleMonth(newDate)
    setDateManuallySelected(true)
  }

  function onDateChange(newDate: Date, todayClick: boolean = false) {
    /**
              Conditions for selecting a date in the Calendar and reloading events list
                - New date selected
                - Selecting a date for the first time (initially dateManuallySelected = false)
                - Today button is clicked and Calendar is on a different month
              */
    if (
      !moment(newDate).isSame(moment(eventListDate), "day") ||
      !dateManuallySelected ||
      (todayClick && !moment(newDate).isSame(moment(visibleMonth), "month"))
    ) {
      rerenderCalendarAndEventsForDate(newDate)
    }

    // If selected date is outside of loaded range, set Calendar date to render new range
    if (
      !moment(newDate).isBetween(
        moment(calendarDate).subtract(1, "year"),
        moment(calendarDate).add(2, "year"),
      )
    ) {
      setCalendarDate(newDate)
    }
  }

  function onMonthChange(month: Date) {
    setVisibleMonth(month)
  }

  function toggleCalendar() {
    setShowCalendar(!showCalendar)
  }

  return (
    <div tw="flex flex-col justify-center md:justify-start">
      <div tw="flex justify-between w-full pt-12 pb-4 px-1.5 items-center xl:hidden md:px-10">
        <div tw="text-3xl">Upcoming</div>
        <ShowCalendarButton toggleCalendar={toggleCalendar} />
      </div>
      <CalendarHolder className={showCalendar ? "visible" : "hidden"}>
        <DatePickerField
          month={visibleMonth}
          date={eventListDate}
          onDateChange={onDateChange}
          onMonthChange={onMonthChange}
          events={events}
          fetchingEvents={fetchingEvents}
          dateManuallySelected={dateManuallySelected}
        />
      </CalendarHolder>
    </div>
  )
}

export function offsetToLocalTimezone(date: Date) {
  const offsetDate = new Date(date.valueOf())
  offsetDate.setMinutes(date.getMinutes() - date.getTimezoneOffset())
  return offsetDate
}

interface ShowCalendarProps {
  toggleCalendar: () => void
}

const ShowCalendarButton: React.FC<ShowCalendarProps> = ({
  toggleCalendar,
}) => {
  return (
    <button
      tw="border border-primary-100 rounded-lg flex py-2 px-4 text-primary-400"
      onClick={() => toggleCalendar()}
    >
      <div tw="flex items-center">
        <CalendarIcon />
        <div tw="pl-3">Calendar</div>
      </div>
    </button>
  )
}

const WeekdayLabel = styled.div`
  ${tw`text-base`};

  color: #d1d5db;
`

const CalendarHolder = styled.div`
  ${tw`justify-center`};

  &.visible {
    ${tw`flex`};
  }

  &.hidden {
    ${tw`hidden`};

    @media only screen and (min-width: 1280px) {
      ${tw`flex`};
    }
  }
`

const StyledDayPicker = styled(DayPicker)`
  .DayPicker-wrapper {
    ${tw`rounded-xl pb-0`};
  }

  .DayPicker-Caption {
    ${tw`mb-0`};

    span {
      ${tw`text-xl leading-6 text-black`};
    }
  }

  .DayPicker-WeekdaysRow {
    ${tw`flex justify-around mb-2`};
    display: flex;
    justify-content: space-around;
    border-bottom: 1px solid #f3f4f6;
  }

  .DayPicker-NavBar {
    ${tw`w-full flex justify-between mt-0 items-center pt-5`};
  }

  .DayPicker-NavButton {
    ${tw`relative top-0 right-0`};
  }

  .DayPicker-Body {
    ${tw`table rounded-2xl overflow-hidden w-full lg:w-auto bg-white`};
  }

  .DayPicker-Month {
    ${tw`w-full lg:w-auto mx-0`};
    @media only screen and (min-width: 1024px) {
      min-width: 348px;
    }
  }

  .DayPicker-wrapper {
    box-shadow:
      0px 1px 2px rgba(79, 31, 137, 0.04),
      0px 0px 8px rgba(79, 31, 137, 0.04),
      0px 8px 12px rgba(79, 31, 137, 0.04);
  }

  &:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(
      .DayPicker-Day--selected
    ):not(.DayPicker-Day--outside) {
    div.day-component {
      ${tw`border-transparent box-border rounded-lg border`};

      transition: border-color 0.1s ease;
      -webkit-transition: border-color 0.1s ease;
      -moz-transition: border-color 0.1s ease;
      -o-transition: border-color 0.1s ease;
      -ms-transition: border-color 0.1s ease;
    }

    &:hover,
    &:focus-visible {
      background: none;

      div.day-component {
        ${tw`border-primary-100 box-border rounded-lg border bg-white`};
        border-color: #e4d8f4;

        box-shadow:
          0px 10px 20px rgba(79, 31, 137, 0.1),
          0px 1px 4px rgba(79, 31, 137, 0.03);
      }
    }
  }

  .DayPicker-Day {
    ${tw`border-transparent border box-border rounded-none align-top text-lg leading-5 p-0.5 w-12 h-12 focus:outline-none focus-visible:outline-none`};
    color: #4b5563;
  }
  .day-number {
    ${tw`w-8 h-8 flex items-center justify-center`};
  }

  .DayPicker-Day--outside {
    ${tw`opacity-50`};
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(
      .DayPicker-Day--outside
    ) {
    ${tw`font-normal bg-transparent`};
  }

  .DayPicker-Day--today {
    &:not(.DayPicker-Day--selected) {
      div.day-number {
        ${tw`font-normal py-2 px-3 border rounded-full border-primary-200 w-8 h-8 flex items-center justify-center`};
      }
    }
  }
`

const EventIndicator = styled.div`
  &.BIRTHDAY {
    ${tw`bg-primary-500`};
  }

  &.WORK_ANNIVERSARY {
    ${tw``};

    background-color: #2f80ed;
  }

  animation: opacityIn;
  animation-fill-mode: both;
  animation-timing-function: ease-out;
  animation-duration: 0.2s;
`

const DayComponent = styled.div`
  ${tw`md:px-3 md:pt-1 pb-0.5 border flex flex-col items-center border-transparent`};

  @media only screen and (min-width: 768px) {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
  }

  &.selected {
    ${tw`font-normal bg-primary-000 box-border rounded-lg text-primary-500 border-primary-200 border`};
    border-color: #e4d8f4;
  }

  &.WORK_ANNIVERSARY {
    ${tw``};

    background-color: #2f80ed;
  }
`

interface DatePickerFieldProps {
  month: Date
  date: Date
  onDateChange: (date: Date, todayClick?: boolean) => void
  onMonthChange: (month: Date) => void
  events: EventKindHash
  dateManuallySelected: boolean
  fetchingEvents: boolean
}

const DatePickerField: React.FC<DatePickerFieldProps> = ({
  month,
  date,
  onDateChange,
  onMonthChange,
  events,
  dateManuallySelected,
  fetchingEvents,
}) => {
  const renderDay = (day: Date) => {
    const dateNum = day.getDate()
    const formattedDate = moment(day).format("MM/DD/YYYY")

    const dateIndicator = (events: EventKindHash) => {
      return events[formattedDate] ? (
        <>
          {events[formattedDate].includes(EventKind.BIRTHDAY) && (
            <EventIndicator
              tw="rounded-full h-2 w-2 mx-px"
              className={EventKind.BIRTHDAY}
            />
          )}
          {events[formattedDate].includes(EventKind.WORK_ANNIVERSARY) && (
            <EventIndicator
              tw="rounded-full h-2 w-2 mx-px"
              className={EventKind.WORK_ANNIVERSARY}
            />
          )}
        </>
      ) : null
    }

    const selectedClass =
      moment(day).isSame(moment(date), "day") && dateManuallySelected
        ? "selected"
        : ""

    return (
      <DayComponent className={selectedClass + " day-component"}>
        <div className="day-number">{dateNum}</div>
        <div tw="h-2.5 md:h-5 w-auto flex items-center justify-center">
          {dateIndicator(events)}
        </div>
      </DayComponent>
    )
  }

  const handleDayClick = (day: Date, modifiers: DayModifiers) => {
    if (modifiers.disabled) {
      return false
    }

    onDateChange(day)
  }

  const handleMonthChange = (month: Date) => {
    onMonthChange(month)
  }

  return (
    <StyledDayPicker
      month={month}
      onDayClick={handleDayClick}
      onMonthChange={handleMonthChange}
      selectedDays={dateManuallySelected ? date : undefined}
      renderDay={renderDay}
      weekdayElement={<Weekday />}
      navbarElement={
        <Navbar
          date={date}
          onTodayClick={() => onDateChange(new Date(), true)}
        />
      }
      captionElement={({ date }) => <div />}
    />
  )
}

interface WeekdayProps {
  weekday?: number
  className?: string
  localeUtils?: Object
  locale?: string
}

const Weekday: React.FC<WeekdayProps> = ({
  weekday,
  className,
  localeUtils,
  locale,
}) => {
  const weekdayName = moment(weekday, "e").format("ddd")
  return (
    <WeekdayLabel className={className} title={weekdayName}>
      {weekdayName.slice(0, 1)}
    </WeekdayLabel>
  )
}

interface NavbarProps {
  month?: Date
  nextMonth?: Date
  previousMonth?: Date
  onPreviousClick?: () => void
  onNextClick?: () => void
  className?: string
  localeUtils?: Object
  onTodayClick: () => void
  date: Date
}

const Navbar: React.FC<NavbarProps> = ({
  month,
  nextMonth,
  previousMonth,
  onPreviousClick,
  onNextClick,
  className,
  localeUtils,
  onTodayClick,
  date,
}) => {
  return (
    <div className={className}>
      <MonthArrowButton
        tw="float-left ml-4"
        onClick={() => onPreviousClick && onPreviousClick()}
      >
        <MonthArrowIcon />
      </MonthArrowButton>
      <TodayButton
        tw="hidden md:flex"
        onClick={() => onTodayClick && onTodayClick()}
      >
        Today
      </TodayButton>
      <div>
        <CaptionElement date={month || date} />
      </div>
      <MonthArrowButton
        tw="float-right mr-4"
        onClick={() => onNextClick && onNextClick()}
      >
        <MonthArrowIcon tw="rotate-180" />
      </MonthArrowButton>
    </div>
  )
}

const MonthArrowButton = styled.button`
  ${tw`w-8 h-8 rounded-md flex justify-center items-center z-10 focus:outline-none`};

  color: #9ca3af;
  transition: background-color 0.2s ease-out;

  &:hover,
  &:focus-visible {
    background-color: #f3f4f6;
  }

  &:active {
    background-color: #eceef1;
  }
`

const TodayButton = styled.button`
  ${tw`text-sm pl-16 absolute float-left z-0 focus:outline-none`};

  color: #b7bcc5;
  transition: color 0.2s ease-out;

  &:hover,
  &:focus-visible {
    color: #6b7280;
  }
`

interface CaptionElementProps {
  date: Date
}

const CaptionElement: React.FC<CaptionElementProps> = ({ date }) => {
  const dateMoment = moment(date)
  return (
    <div className="DayPicker-Caption">
      <span tw="font-medium pr-1">{dateMoment.format("MMMM")}</span>
      <span tw="font-normal">{dateMoment.format("YYYY")}</span>
    </div>
  )
}

const EVENT_TYPES_QUERY = gql`
  query Dashboard_EventKinds($anchorDate: ISO8601Date) {
    me {
      id
      upcomingEvents(anchorDate: $anchorDate, segment: PLUS) {
        day
        month
        kind
        relevantRecurrence(anchorDate: $anchorDate)
      }
    }
  }
`

export default CalendarEvents
