import React, { useEffect, useState } from "react"
import { matchPath, useLocation } from "react-router-dom"
import tw from "twin.macro"

import HeaderLogo from "./HeaderLogo"
import MenuCta from "./MenuCta"
import MenuItems from "./MenuItems"
import { ReactComponent as MenuCloseIcon } from "../images/menu-close.svg"
import { ReactComponent as MenuIcon } from "../images/menu.svg"
import { useLogin } from "../LoginProvider"

interface Props {
  isSignedIn: boolean
  isBusiness: boolean
  businessMenuItems: MenuItem[]
  consumerMenuItems: MenuItem[]
}

const HeaderMenuBar: React.FC<Props> = ({
  isBusiness,
  isSignedIn,
  businessMenuItems,
  consumerMenuItems,
}) => {
  const { login, logout } = useLogin()
  const location = useLocation()
  const [menuIsOpen, setMenuIsOpen] = useState(false)

  useEffect(() => {
    closeMenu() // whenever the location changes
  }, [location])

  const toggleMenu = () => setMenuIsOpen((previous) => !previous)
  const closeMenu = () => setMenuIsOpen(false)

  const relevantMenuItems = isBusiness ? businessMenuItems : consumerMenuItems
  const activeMenuItem = relevantMenuItems.find((item) =>
    matchPath(location.pathname, { path: item.path }),
  )
  const pageName = activeMenuItem?.name || "Menu"

  return (
    <HeaderContainer>
      <MenuContainer
        css={[
          isBusiness && MenuContainerBusinessStyle,
          !isBusiness && tw`flex-row-reverse lg:flex-row`,
        ]}
      >
        <HeaderLogo />
        <HeaderTitle
          onClick={toggleMenu}
          tw="lg:hidden"
          css={[isBusiness && tw`w-auto text-[#1f2024]`]}
        >
          <OpenClosedIcon menuIsOpen={menuIsOpen} />
          {!isBusiness && <PageName>{pageName}</PageName>}
        </HeaderTitle>

        <MenuItemsContainer
          css={[menuIsOpen && tw`translate-y-0 scale-y-100 opacity-100`]}
        >
          <MenuItems
            isBusiness={isBusiness}
            signedIn={isSignedIn}
            menuItems={relevantMenuItems}
          />
          <MenuCta
            signedIn={isSignedIn}
            isBusiness={isBusiness}
            onLogoutClick={logout}
            onLoginClick={login}
          />
        </MenuItemsContainer>
      </MenuContainer>
    </HeaderContainer>
  )
}

const HeaderContainer = tw.div`
  fixed lg:static top-0 z-50
  w-screen 2xl:max-w-[1440px]
  mx-auto bg-white flex items-stretch
`

const MenuContainer = tw.div`
  w-full transition-shadow
  border-primary-100 order-last z-30
  px-5 h-14 lg:h-24
  flex items-center justify-between
`
const MenuContainerBusinessStyle = tw`
  border-b border-solid border-[rgba(31, 32, 36, 0.1)] lg:border-none
  h-[78px] lg:h-auto
`

const MenuItemsContainer = tw.div`
  absolute left-0 lg:static bg-white
  flex flex-col lg:flex-row flex-1 w-full
  justify-start lg:justify-between lg:items-center
  h-[93.5vh] lg:h-24

  transition-all duration-500 transform lg:transform-none
  -translate-y-full scale-y-50 top-full z-20
  opacity-50 lg:opacity-100
  overflow-y-auto lg:overflow-y-visible
`

const PageName = tw.div`text-xl transition-colors`
const HeaderTitle = tw.div` 
  flex flex-row items-center text-primary-600
  py-4 lg:py-0 w-full lg:w-max gap-2
`

const OpenClosedIcon: React.FC<{ menuIsOpen: boolean }> = ({ menuIsOpen }) => (
  <div tw="grid h-7 w-7">
    {menuIsOpen ? (
      <MenuCloseIcon tw="stroke-0" css={[SvgStyle]} />
    ) : (
      <MenuIcon tw="stroke-2" css={[SvgStyle]} />
    )}
  </div>
)

const SvgStyle = tw`
  stroke-current fill-current opacity-100 transition-opacity 
  w-auto place-self-center mt-[0.15rem]
`

export default HeaderMenuBar
