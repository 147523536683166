import { gql, useMutation } from "@apollo/client"
import { useState } from "react"
import { toast } from "react-hot-toast"
import tw, { styled } from "twin.macro"

import { ReactComponent as CanceledCalendar } from "../assets/icons/canceled-calendar.svg"

import { giftBatchUpdateMeetingSettingsMutation } from "@/types/graphql-types"

const MeetingNotRequiredButton = ({ giftBatchId }: { giftBatchId: string }) => {
  const [giftBatchUpdateMeetingSettings] =
    useMutation<giftBatchUpdateMeetingSettingsMutation>(UPDATE_MEETING_SETTINGS)
  const [successfullySetToNotRequired, setSuccessfullySetToNotRequired] =
    useState(false)

  const setMeetingToNotRequired = async () => {
    if (
      window.confirm(
        "Currently, recipients must schedule a meeting to accept a gift. If you disable this, recipients can accept gifts without scheduling a meeting.\n\n Do you want to disable the meeting requirement?",
      )
    ) {
      const response = await giftBatchUpdateMeetingSettings({
        variables: { giftBatchId: giftBatchId, giftSetting: "GIFT_FIRST" },
      })
      if (response.data?.giftBatchUpdateMeetingSettings?.ok) {
        toast.success("Scheduling a meeting is no longer required")
        setSuccessfullySetToNotRequired(true)
      }
    }
  }

  if (successfullySetToNotRequired) {
    return null
  }

  return (
    <Button onClick={setMeetingToNotRequired}>
      <CanceledCalendar tw="self-center mr-3 stroke-[#9CA3AF] w-4 h-4" />
      Disable meeting requirement
    </Button>
  )
}

const Button = styled.button`
  ${tw`inline-flex flex-row rounded-lg py-2 px-3 tracking-tight object-center text-gray-500 bg-white hover:text-primary-500 focus:outline-none active:bg-primary-000 leading-[100%]`}

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04), 0px 8px 37px rgba(0, 0, 0, 0.06);
  &:disabled {
    ${tw`cursor-auto opacity-50`}
  }
`

const UPDATE_MEETING_SETTINGS = gql`
  mutation giftBatchUpdateMeetingSettings(
    $giftBatchId: ID!
    $giftSetting: gift_meeting_setting!
  ) {
    giftBatchUpdateMeetingSettings(
      giftBatchId: $giftBatchId
      giftSetting: $giftSetting
    ) {
      ok
    }
  }
`

export default MeetingNotRequiredButton
