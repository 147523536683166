import { ReactNode } from "react"
import { NavLink } from "react-router-dom"
import tw, { styled } from "twin.macro"

interface Props {
  url: string
  title: string
  subtitle: string
  icon: ReactNode
}

export const CustomCreateButton = ({ url, title, subtitle, icon }: Props) => (
  <Button>
    <NavLink tw="flex gap-x-4" to={url}>
      <div tw="rounded-full border border-gray-150 w-12 h-12 flex-none self-center flex justify-center items-center">
        {icon}
      </div>
      <div tw="flex flex-col gap-y-1">
        <div tw="flex text-left text-xl">{title}</div>
        <div tw="flex text-left text-gray-450">{subtitle}</div>
      </div>
    </NavLink>
  </Button>
)

const Button = styled.button`
  ${tw`
    p-5 border border-gray-150 box-border rounded-xl
    hover:border-primary-200 active:bg-primary-000
    md:w-[26rem]
  `}
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);

  &:hover,
  &:active {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.06);
  }
`
