import { useEffect } from "react"

import InfoIcon from "./assets/icons/info-primary.svg"

import { formatPrice } from "@/common/format"
import { useGiftData } from "@/common/hooks/giftData"
import { MiniSelectedPaymentMethod } from "@/common/payments/miniPaymentMethodSelector/MiniSelectedPaymentMethod"
import { useTopUpUI } from "@/send/hooks/topUpUI"

export const TopUpPaymentSelectorForm = () => {
  const { topUpAmount, balance, estimatedGiftTotal } = useTopUpUI()
  const { setTopUpAmount } = useGiftData()

  useEffect(() => {
    if (topUpAmount) {
      setTopUpAmount(topUpAmount)
    }
  }, [topUpAmount])

  return (
    <div tw="rounded-lg bg-primary-new-050 w-full p-4">
      <div tw="flex mb-[2px] gap-4">
        <div tw="min-w-[20px]">
          <img src={InfoIcon} />
        </div>
        <div tw="flex flex-col items-start gap-[6px] text-left">
          <div tw="flex items-center font-semibold leading-5">
            <span tw="mr-1">
              {formatPrice(topUpAmount)} will be added to your balance using
            </span>
            <span tw="-my-1">
              <MiniSelectedPaymentMethod />
            </span>
          </div>
          {balance > 0 && (
            <div tw="text-gray-700 leading-5">
              Your balance covers {formatPrice(balance)} of your{" "}
              {formatPrice(estimatedGiftTotal)} total.
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
