import { format } from "date-fns"
import React, { useCallback, useState } from "react"
import { useDropzone } from "react-dropzone"
import tw, { css, styled } from "twin.macro"

import { CSVUpload, CSVUploadError } from "../../assets/icons"
import { isBlank } from "../format"

interface Props {
  setRows: (rows: string[][]) => void
}

// This component provides the drag and drop drop zone for importing a CSV or XLSX
const CSVDropZone: React.FC<Props> = ({ setRows }) => {
  const [validationError, setValidationError] = useState<string | null>(null)

  const lazyXLSX = import("xlsx")

  const uploadFile = async (file: File) => {
    await lazyXLSX.then(async ({ default: XLSX }) => {
      const workbook = XLSX.read(await file.arrayBuffer(), {
        type: "array",
        raw: true,
        cellDates: true,
      })

      // We always use the first sheet of an XLS/XLSX file
      const json = XLSX.utils.sheet_to_json<(string | number | Date)[]>(
        workbook.Sheets[workbook.SheetNames[0]],
        { header: 1, defval: "" }, // Missing items should be empty string
      )

      // Make sure we have rows, and rows with values in it
      if (json.some((row) => row.length > 0)) {
        setRows(
          json
            // Sometimes we have empty rows, so we need to filter them out
            .filter((row) => row.some((value) => !isBlank(String(value))))
            // We want all rows to be strings, so they're easier to use than the
            // default which is string | number
            .map((row) =>
              row.map((rowItem) =>
                rowItem instanceof Date
                  ? format(rowItem, "MM/dd/yyyy")
                  : String(rowItem),
              ),
            ),
        )
      } else {
        window.alert("File is empty!")
        // Reset error if it exists
        setValidationError(null)
      }
    })
  }

  const onDrop = useCallback((acceptedFiles: any[]) => {
    const file = acceptedFiles[0]
    if (file) {
      uploadFile(file)
    } else {
      setValidationError("Please provide a CSV, XLS, or XLSX file.")
    }
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ".csv, .xls, .xlsx",
  })

  return (
    <div tw={"flex flex-1"} {...getRootProps()}>
      <input {...getInputProps()} />
      <DropContainer
        css={
          isDragActive
            ? [tw`border-gray-500`, "background-color: #f9fafb;"]
            : [tw`border-gray-300`, hoverCss]
        }
      >
        {validationError ? (
          <>
            <CSVUploadError />
            <div tw={"mt-5 text-red-500 text-xl text-center"}>
              File is not a CSV
              <br />
              or XLSX
            </div>
          </>
        ) : (
          <>
            <CSVUpload />
            <div tw={"mt-5 text-gray-500 text-xl text-center"}>
              Drop file here
              <br />
              or click to select
            </div>
          </>
        )}
        <div tw={"mt-2 text-gray-400 text-sm text-center"}>CSV or XLSX</div>
      </DropContainer>
    </div>
  )
}

const hoverCss = css`
  &:hover {
    background-color: #f9fafb;
  }
`

const DropContainer = styled.div`
  ${tw`flex-1 self-stretch border-2 rounded-xl border-dashed items-center justify-center flex flex-col cursor-pointer`}
  ${hoverCss}
`

export default CSVDropZone
