import { useMemo } from "react"

import { formatPriceInternational } from "./format"
import { Product } from "./GlobalState"
import { useCurrentGift } from "./hooks/currentGift"

import { GIFT_CARDS_BRAND_NAME } from "@/store/utils"
import { flex_gift_product_price_type } from "@/types/graphql-types"

export const isGiftCard = (product: NonNullable<Product>) => {
  return product.brandName === GIFT_CARDS_BRAND_NAME
}

export const getProductName = (product: NonNullable<Product>) => {
  if (isGiftCard(product)) {
    return `${formatPriceInternational({
      nativeAmountInCents: product.giftCardAmount!,
      nativeCurrency: product.giftCardCurrency || "USD",
    })} Gift Card`
  } else if (product.isFlexGift) {
    if (
      (product.priceType === flex_gift_product_price_type.VARIABLE ||
        product.priceType === flex_gift_product_price_type.TIERS) &&
      product.flexGiftPrice
    ) {
      return `${product.name} – $${product.flexGiftPrice / 100}`
    }
  }
  return product.name
}

export const getProductImageThumb = (product: Product) => {
  if (product.isBook && product.bookData?.image) {
    return { url: product.bookData.image }
  }

  return product.imageThumb
}

export const getProductSubtitle = (product: NonNullable<Product>) => {
  if (isGiftCard(product)) {
    return product.name
  } else if (product.isBook && product?.bookData?.contributors) {
    return product.bookData.contributors
  } else {
    return product.brandName
  }
}

export const getProductPrice = (product: NonNullable<Product>) => {
  if (isGiftCard(product) && product.giftCardAmount) {
    return product.giftCardAmount
  } else if (
    product.isFlexGift &&
    (product.priceType === flex_gift_product_price_type.VARIABLE ||
      product.priceType === flex_gift_product_price_type.TIERS) &&
    product.flexGiftPrice
  ) {
    return product.flexGiftPrice
  }

  return product.priceProduct ?? 0
}

export const getProduct = <P>(currentGift: { cart: P[] }) => {
  return currentGift.cart[0] ?? null
}

export const useNumScheduledGiftMaxDaysAhead = () => {
  const [currentGift] = useCurrentGift()

  const isAllFlexGifts = useMemo(
    () => currentGift.cart.every((product) => product.isFlexGift),
    [currentGift.cart],
  )

  if (isAllFlexGifts) {
    return 365
  } else {
    return 8 * 7
  }
}
