import { useState } from "react"
import tw from "twin.macro"

import { formatPrice } from "@/common/format"
import { ReactComponent as ChevronDownIcon } from "@/common/images/chevron-down.svg"
import Dropdown from "@/store/components/Dropdown"

export type SwapType = "single" | "multiple"

interface Props {
  swapType: SwapType
  setSwapType: (val: SwapType) => void
  amount: number
}

export default function FlexGiftSwapTypeSelector({
  swapType,
  setSwapType,
  amount,
}: Props) {
  const [dropdownOpened, setDropdownOpened] = useState(false)
  const label =
    swapType === "single"
      ? "Recipients can select one gift"
      : "Recipients can select multiple gifts"

  return (
    <Dropdown
      opened={dropdownOpened}
      setOpened={(val: boolean) => setDropdownOpened(val)}
      active={false}
      label={"yoo"}
      trigger={
        <button
          tw="h-full px-4 py-2 rounded-xl bg-gray-075 hover:bg-gray-100 active:bg-gray-150 transition-colors flex flex-row items-center gap-4"
          onClick={() => setDropdownOpened(!dropdownOpened)}
        >
          {label}
          <ChevronDownIcon tw="stroke-current" />
        </button>
      }
    >
      <div tw="md:shadow-2xl m-5 md:m-0 z-50 bg-white rounded-xl border border-gray-200">
        <Option
          css={[tw`rounded-t-xl `, swapType === "single" && tw`bg-gray-075`]}
          onClick={() => {
            setSwapType("single")
            setDropdownOpened(false)
          }}
        >
          <div tw="font-medium">Recipients can select one gift</div>
          <div tw="text-gray-500 pt-px">Gift price is hidden</div>
        </Option>
        <div tw="border-t border-gray-200" />
        <Option
          css={[tw`rounded-b-xl`, swapType === "multiple" && tw`bg-gray-075`]}
          onClick={() => {
            setSwapType("multiple")
            setDropdownOpened(false)
          }}
        >
          <div tw="font-medium">
            Recipients can select multiple gifts up to{" "}
            {formatPrice(amount * 100)} total
          </div>
          <div tw="text-gray-500 pt-px">Gift price is shown</div>
        </Option>
      </div>
    </Dropdown>
  )
}

const Option = tw.button`p-4 text-left w-full hover:bg-gray-100 active:bg-gray-150 transition-colors`
