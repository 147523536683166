import { gql } from "@apollo/client"

export const WORKSPACE_FRAGMENT = gql`
  fragment Workspace on Workspace {
    id
    name
    members {
      id
      companyTitle
      email
      fullName
      role
      status
      userPublicId
      giftCount
      invitedByUserEmail
      invitedByUserFullName
      organizationMembershipId
      organizationAccess
    }
    pendingInvites {
      id
      email
      expiresAt
      isExpired
      senderEmail
      senderName
    }
    permissions {
      inviteMembersPermission
    }
    canLeaveWorkspace
    role
    isTeamPlan
  }
`
