import { gql, useQuery } from "@apollo/client"
import { useEffect, useState } from "react"

import { BALANCE_TRANSACTIONS_FRAGMENT } from "./components/BalanceTransactions"
import BalanceTransactions from "./components/BalanceTransactions"

import {
  Balance_TransactionLogQuery,
  Balance_TransactionLogQueryVariables,
} from "@/types/graphql-types"

export default function TransactionLog() {
  const [page, setPage] = useState(0)

  const { data, refetch } = useQuery<
    Balance_TransactionLogQuery,
    Balance_TransactionLogQueryVariables
  >(BALANCE_TRANSACTIONS_QUERY, {
    variables: {
      page: page + 1,
    },
  })

  useEffect(() => {
    if (refetch) {
      refetch({
        page: page + 1,
      })
    }
  }, [page, refetch])

  return (
    <div tw="px-8 pt-8">
      <BalanceTransactions
        page={page}
        totalCount={data?.me?.balanceAccount?.transactions?.totalCount}
        onChangePage={setPage}
        transactions={data?.me?.balanceAccount?.transactions?.items}
      />
    </div>
  )
}

const BALANCE_TRANSACTIONS_QUERY = gql`
  query Balance_TransactionLog($page: Int!) {
    me {
      id
      balanceAccount {
        id
        transactions(page: $page, perPage: 10) {
          totalCount
          items {
            ...BalanceTransaction
          }
        }
      }
    }
  }

  ${BALANCE_TRANSACTIONS_FRAGMENT}
`
