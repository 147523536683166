import BrandedSwagExample from "./assets/sample-branded-swag.png"
import { ReactComponent as ArrowRight } from "../../../assets/icons/arrow-right.svg"
import { StyledButtonLink } from "../../../common/Button"
import { useGlobalState } from "../../../common/GlobalState"
import { generateRealmPath } from "../../../common/realm"
import { UpsellContainer } from "../common"

export const CustomProductsUpsell = () => {
  const [currentRealm] = useGlobalState("currentRealm")
  return (
    <UpsellContainer>
      <div tw="order-last lg:order-1 lg:pl-16 lg:pt-16 p-6 py-0">
        <div tw="pb-3 lg:pb-6 self-center">
          <div tw="font-medium lg:text-2xl">Branded swag</div>
          <div tw="lg:text-2xl">Apparel, drinkware, home office, and more.</div>
        </div>
        <div tw="flex flex-row mb-4 lg:mb-16">
          <StyledButtonLink
            to={generateRealmPath(
              currentRealm,
              "/browse/category/branded-swag",
            )}
            className="updated"
          >
            Browse
            <ArrowRight />
          </StyledButtonLink>
        </div>
      </div>
      <img
        tw="p-4 2xl:p-0 w-full justify-self-center 2xl:justify-self-start self-center lg:order-2"
        src={BrandedSwagExample}
        alt=""
      />
    </UpsellContainer>
  )
}
