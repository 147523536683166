import { capitalize } from "lodash-es"
import React from "react"
import tw, { styled } from "twin.macro"

import { ReactComponent as DemoteIcon } from "../../../assets/icons/demote.svg"
import { ReactComponent as PromoteIcon } from "../../../assets/icons/promote.svg"
import { ReactComponent as XIcon } from "../../../assets/icons/x-gray.svg"
import {
  ActionsDropdownMenuItem,
  ActionsDropdownMenuPanel,
} from "../../../workspaces/Workspace"
import DropdownMenu from "../../DropdownMenu"
import { ReactComponent as MoreIcon } from "../../images/more-horizontal.svg"

import { OrganizationMembershipAccess } from "@/types/graphql-types"

interface IActionsButton {
  id: string
  access: OrganizationMembershipAccess
  isMember: boolean
  belongsToCurrentUser: boolean
  changeAccess: (newAccess: OrganizationMembershipAccess) => void
  onClickDemoteRole: (id: string) => void
  promoteRole: () => void
  onClickDeactivate?: () => void
  onlyDisplayOn: "mobile" | "desktop"
  groupLabel: "organization" | "workspace"
  higherRoleLabel: "Admin" | "Manager"
  invitedByUserEmail?: string | null
  invitedByUserFullName?: string | null
  isTeamPlan?: boolean
}

const ActionsDropdown = ({
  id,
  access,
  isMember,
  belongsToCurrentUser,
  changeAccess,
  onClickDemoteRole,
  onClickDeactivate,
  promoteRole,
  onlyDisplayOn,
  higherRoleLabel,
  invitedByUserFullName,
  invitedByUserEmail,
  groupLabel,
  isTeamPlan = false,
}: IActionsButton) => {
  if (belongsToCurrentUser) {
    return null
  }

  const workspacePlanConfirmationText =
    "This is a reminder that your account is charged a prorated amount for Admins and Full members added during your billing cycle."

  const onClickToggleAccess =
    (newAccess: OrganizationMembershipAccess) => () => {
      const changeAccessConfirmationCopy = {
        full: "Are you sure you want to promote this user to a full member?",
        limited:
          "Are you sure you want to demote this member to a limited member? They will lose their manager roles on any workspaces they are in.",
      }

      let confirmationText = changeAccessConfirmationCopy[newAccess]

      if (isTeamPlan && newAccess === OrganizationMembershipAccess.full) {
        confirmationText = workspacePlanConfirmationText
      }

      if (window.confirm(confirmationText)) {
        changeAccess(newAccess)
      }
    }

  const onClickPromoteRole = () => {
    let confirmationText = `Are you sure you want to promote this user to ${higherRoleLabel}?`
    if (isTeamPlan) {
      confirmationText = workspacePlanConfirmationText
    }

    if (window.confirm(confirmationText)) {
      promoteRole()
    }
  }

  const ToggleAccessButton = () => {
    let newAccess =
      access === OrganizationMembershipAccess.full
        ? OrganizationMembershipAccess.limited
        : OrganizationMembershipAccess.full

    const isPromoting = newAccess === OrganizationMembershipAccess.full

    return (
      <ActionsDropdownMenuItem onClick={onClickToggleAccess(newAccess)}>
        {isPromoting ? <PromoteIcon /> : <DemoteIcon />}
        {isPromoting ? "Promote to Full Member" : "Demote to Limited Member"}
      </ActionsDropdownMenuItem>
    )
  }

  const PromoteRoleButton = () => {
    if (!isMember) {
      return null
    }

    return (
      <ActionsDropdownMenuItem onClick={onClickPromoteRole}>
        <PromoteIcon /> Promote to {higherRoleLabel}
      </ActionsDropdownMenuItem>
    )
  }

  const DemoteRoleButton = () => {
    if (isMember) {
      return null
    }

    const accessText = isTeamPlan ? ` ${capitalize(access)} ` : " "

    return (
      <ActionsDropdownMenuItem onClick={() => onClickDemoteRole(id)}>
        <DemoteIcon /> Demote to{accessText}Member
      </ActionsDropdownMenuItem>
    )
  }

  const RemoveMemberButton = () => {
    return (
      <ActionsDropdownMenuItem onClick={onClickDeactivate}>
        <XIcon tw="stroke-current" />
        Remove from {groupLabel}
      </ActionsDropdownMenuItem>
    )
  }

  const InvitedByInfo = () => {
    if (invitedByUserEmail && invitedByUserFullName) {
      return (
        <InvitedByInfoContainer>
          <div>Invited by {invitedByUserFullName}</div>
          <div>({invitedByUserEmail})</div>
        </InvitedByInfoContainer>
      )
    } else {
      return null
    }
  }

  const Container =
    onlyDisplayOn === "mobile" ? ContainerForMobile : React.Fragment

  return (
    <Container>
      <DropdownMenu
        tw="absolute"
        trigger={
          <MoreIcon
            fill="black"
            tw="ml-4 "
            css={[onlyDisplayOn === "desktop" && tw`hidden! lg:block!`]}
          />
        }
      >
        <ActionsDropdownMenuPanel>
          <InvitedByInfo />
          <PromoteRoleButton />
          <ToggleAccessButton />
          <DemoteRoleButton />
          <RemoveMemberButton />
        </ActionsDropdownMenuPanel>
      </DropdownMenu>
    </Container>
  )
}

export default ActionsDropdown

const ContainerForMobile = styled.span`
  ${tw`absolute right-0`}

  nav {
    ${tw`right-5 top-2 lg:hidden`}
  }

  ul {
    ${tw`-mt-4`}
  }
`

const InvitedByInfoContainer = tw.div`pb-3 pt-1 px-4 text-gray-400`
