import tw, { styled } from "twin.macro"

import PurpleGradientLink from "../../../common/PurpleGradientLink"
import RainbowHeading from "../../../common/RainbowHeading"
import { generateRealmPath } from "../../../common/realm"
import cardHomesickCandlesSrc from "../images/card_homesick_candles.png"
import grazaImgSrc from "../images/graza.png"
import SquigglyCirclesLeftCorner from "../images/squiggly_circles_left_corner.svg"
import SwapOfChoice from "../images/swap_of_choice.png"

const GetStarted = () => {
  return (
    <div tw="px-5 lg:px-0 pb-12 pt-0 sm:pt-24 lg:pt-48 mx-auto flex flex-col justify-center items-center max-w-[1200px]">
      <div tw="flex lg:mb-12 max-w-[580px] lg:max-w-full flex-col lg:flex-row pt-12 lg:pt-0">
        <div tw="flex-3 mb-12 lg:mb-0 xl:pr-0 xl:pb-24">
          <div tw="lg:pr-14">
            <RainbowHeading tw="font-bold mb-4 text-2xl">
              Get Started
            </RainbowHeading>
            <div tw="text-left font-bold mb-4 text-4xl lg:text-[3rem] lg:leading-[3.5rem] lg:max-w-[36rem]">
              Build the gift experience of your dreams.
            </div>
            <div tw="text-xl lg:text-2xl text-gray-600 leading-normal lg:leading-9 text-left pb-4 lg:pr-12 lg:ml-0 lg:max-w-full pr-0">
              Create unlimited custom collections and stock them with gifts and
              swag from our entire catalog. To get started, book a quick demo
              with our team.
            </div>
            <div tw="text-lg lg:text-xl text-gray-600 leading-normal lg:leading-9 text-left pb-4 lg:pr-12 lg:ml-0 lg:max-w-full mb-4 lg:mb-8 pr-0">
              Custom gift collections are available on Goody for Teams plans.
            </div>
            <PurpleGradientLink
              tw="w-full lg:w-auto"
              to={generateRealmPath("business", "/business/team-demo")}
            >
              Book a Demo
            </PurpleGradientLink>
          </div>
        </div>

        <div tw="flex-2 lg:pl-16">
          <div tw="mt-12 lg:mt-0 mb-24 lg:pr-8 lg:pl-8 relative w-[80%] lg:w-full mx-auto">
            <img tw="w-full" src={SwapOfChoice} alt="SwapOfChoice" />
            <GrazaImg src={grazaImgSrc} />
            <CardHomesickCandlesImg src={cardHomesickCandlesSrc} />
            <SquigglyCirclesLeftCornerImg src={SquigglyCirclesLeftCorner} />
          </div>
        </div>
      </div>
    </div>
  )
}

const GrazaImg = styled.img`
  ${tw`
    absolute top-[-13%] right-[4%] w-[40%]
    lg:top-[-14%] lg:right-[-8%] lg:w-[50%]
  `}
  filter: drop-shadow(0px 42px 54px rgba(6, 13, 37, 0.06));
`

const CardHomesickCandlesImg = styled.img`
  ${tw`
    absolute bottom-[-8%] left-[8%] w-[40%]
    lg:bottom-[-10%] lg:left-[-16%] lg:w-[50%]
  `}
  filter: drop-shadow(0px 42px 54px rgba(6, 13, 37, 0.06));
`

const SquigglyCirclesLeftCornerImg = styled.img`
  ${tw`absolute top-[-9.5%] left-[-12%]`}
`

export default GetStarted
