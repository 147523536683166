import { gql, useMutation, useQuery } from "@apollo/client"

import OrganizationWebhooks from "./OrganizationWebhooks"
import Button, { StyledButtonLink } from "../common/Button"
import { generateRealmPath } from "../common/realm"
import Container from "../sites/App/Container"

import {
  Organization_API_CreateWebhookApplicationMutation,
  Organization_API_WebhookApplicationQuery,
} from "@/types/graphql-types"

// When this page is rendered, it checks if there is a webhook application
// created for the organization already. If it does, it displays the Webhooks
// component, which fetches the Svix dashboard URL and displays it. If not, it
// displays a button to "Set up webhooks", which creates a webhook application,
// refetches the webhook application query, which then displays the Webhooks
// component.
export default function AutomationAPI() {
  const { data, loading, refetch } =
    useQuery<Organization_API_WebhookApplicationQuery>(
      WEBHOOK_APPLICATION_QUERY,
    )
  const [createWebhookApplication, { loading: mutationLoading }] =
    useMutation<Organization_API_CreateWebhookApplicationMutation>(
      CREATE_WEBHOOK_APPLICATION_MUTATION,
    )

  async function onClickCreateApplication() {
    const res = await createWebhookApplication()
    if (res?.data?.webhookApplicationCreate?.ok) {
      refetch()
    } else {
      alert("An error occurred.")
    }
  }

  return (
    <Container>
      <div tw="p-6 pt-12">
        <div tw="flex flex-col md:flex-row items-start gap-12">
          <div tw="md:w-[30%]">
            <div tw="font-medium text-2xl pb-4">Automation API</div>
            <div tw="pb-3">
              The Automation API allows you to automate your gift sending, and
              access information about gifts in your organization
              programmatically.
            </div>
            <div tw="pb-3">
              With the Automation API, you can integrate gifting into virtually
              any app with custom code.
            </div>
            <div>
              <a
                target="_blank"
                rel="nofollow noopener noreferrer"
                href="https://developer.ongoody.com/automation-api/overview"
                tw="text-primary-new-500 font-medium hover:text-primary-new-600 active:text-primary-new-700"
              >
                API documentation
              </a>
            </div>
          </div>
          <div tw="flex-1">
            <div tw="font-medium text-xl pb-4">API Keys</div>
            <div tw="flex flex-row items-center gap-2 pb-3">
              <div>To create and manage API keys, go to</div>
              <StyledButtonLink
                to={generateRealmPath("plus", "/account/api-keys")}
                className="updated"
              >
                Account API Keys
              </StyledButtonLink>
            </div>

            <div tw="font-medium text-xl md:w-[15%] pt-8 pb-4">Webhooks</div>
            <div tw="pb-3">
              You can use webhooks to receive notifications about events on
              gifts in your organization at a specified URL. Webhooks are
              delivered by the Svix platform.
            </div>
            <div tw="pb-6">
              To view more information about how to set up webhooks and the
              available event types, visit{" "}
              <a
                href="https://developer.ongoody.com/automation-api/webhooks"
                target="_blank"
                rel="nofollow noopener noreferrer"
                tw="text-primary-new-550 hover:text-primary-new-600 active:text-primary-new-700"
              >
                Webhooks in our API documentation
              </a>
              .
            </div>
            <div tw="min-h-[30vh]">
              {loading ? (
                <div tw="text-gray-500">Loading&hellip;</div>
              ) : (
                data?.organization && (
                  <div>
                    {data.organization.hasWebhookApplication ? (
                      <OrganizationWebhooks />
                    ) : (
                      <div>
                        <div>
                          <Button
                            variant="updated"
                            disabled={mutationLoading}
                            onClick={onClickCreateApplication}
                          >
                            Set up webhooks
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

const WEBHOOK_APPLICATION_QUERY = gql`
  query Organization_API_WebhookApplication {
    organization {
      id
      hasWebhookApplication
    }
  }
`

const CREATE_WEBHOOK_APPLICATION_MUTATION = gql`
  mutation Organization_API_CreateWebhookApplication {
    webhookApplicationCreate {
      ok
      error
    }
  }
`
