import { gql, useQuery } from "@apollo/client"
import React from "react"
import { Redirect } from "react-router-dom"
import { styled } from "twin.macro"

import { SubscriptionCopy, SubscriptionForm } from "./components"
import { useGlobalState } from "../common/GlobalState"
import { generateRealmPath } from "../common/realm"
import { Loader } from "../common/UI"

import { Organization_Subscription_SegmentQuery } from "@/types/graphql-types"

const SubscribeTeam = () => {
  const [user] = useGlobalState("user")
  const { loading, data } = useQuery<Organization_Subscription_SegmentQuery>(
    ORGANIZATION_SEGMENT_QUERY,
  )

  if (!user) {
    return <Redirect to={generateRealmPath("business", "/signin")} />
  }

  if (!data?.organization || loading) {
    return (
      <div tw="container mx-auto bg-white flex items-center justify-center h-80">
        <Loader />
      </div>
    )
  }

  const organization = data.organization
  const subscription = organization.subscription

  if (
    subscription &&
    (!subscription.isTrialActive ||
      subscription.trialCardStatus === "CARD_ENTERED")
  ) {
    return (
      <Redirect to={generateRealmPath("plus", "/organization/subscription")} />
    )
  }

  const sizeSegment = organization.subscriptionSizeSegment
  let fullMemberCount = organization.adminCount ?? 0
  if (subscription?.isTrialActive) {
    fullMemberCount = organization.fullMemberCount
  }

  return (
    <GradientBackground>
      <div tw="container mx-auto">
        {loading ? (
          <div tw="flex items-center justify-center py-48 px-48">
            <Loader />
          </div>
        ) : (
          <>
            <div tw="block py-8 mx-8 lg:flex flex-row items-start lg:mx-32 lg:py-16">
              <SubscriptionCopy plan="team" segment={sizeSegment} />
              {sizeSegment != null && (
                <SubscriptionForm
                  segment={sizeSegment}
                  fullMemberCount={fullMemberCount}
                />
              )}
            </div>
          </>
        )}
      </div>
    </GradientBackground>
  )
}

const GradientBackground = styled.div`
  background: linear-gradient(75deg, #fffafc -8%, #f5f7ff 100%);
`

const ORGANIZATION_SEGMENT_QUERY = gql`
  query Organization_Subscription_Segment {
    organization {
      id
      adminCount
      fullMemberCount
      subscriptionSizeSegment
      subscription {
        id
        isTrialActive
        trialCardStatus
      }
    }
  }
`

export default SubscribeTeam
