import { useEffect, useState } from "react"
import tw, { TwStyle, styled } from "twin.macro"

import TextInputField from "./TextInputField"

type PasswordScore = 0 | 1 | 2 | 3 | 4

type PasswordInputFieldProps = {
  userInputs?: string[]
  error?: string
} & React.InputHTMLAttributes<HTMLInputElement>
const PasswordInputField: React.FC<PasswordInputFieldProps> = ({
  value,
  userInputs,
  ...inputProps
}) => {
  const val = value?.toString() ?? ""

  return (
    <TextInputField {...inputProps} type="password">
      {val !== "" ? (
        <PasswordStrengthIndicator password={val} userInputs={userInputs} />
      ) : null}
    </TextInputField>
  )
}

interface PasswordStrengthIndicatorProps {
  password: string
  userInputs?: string[]
}
const PasswordStrengthIndicator: React.FC<PasswordStrengthIndicatorProps> = ({
  password,
  userInputs,
}) => {
  const lazyZxcvbn = import("zxcvbn")
  const [score, setScore] = useState<PasswordScore>(0)

  useEffect(() => {
    if (password.length === 0) {
      setScore(0)
      return
    }

    lazyZxcvbn.then(({ default: zxcvbn }) => {
      const passwordScore = Math.max(
        zxcvbn(password, userInputs).score,
        1,
      ) as PasswordScore
      setScore(passwordScore)
    })
  }, [lazyZxcvbn, password, userInputs])

  return (
    <StyledPasswordStrengthIndicator score={score}>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
    </StyledPasswordStrengthIndicator>
  )
}

const scoreColors: Record<PasswordScore, TwStyle> = Object.freeze({
  0: tw`bg-gray-250`,
  1: tw`bg-red-500`,
  2: tw`bg-yellow-500`,
  3: tw`bg-green-400`,
  4: tw`bg-green-400`,
})

const StyledPasswordStrengthIndicator = styled.ul<{ score: PasswordScore }>`
  ${tw`flex items-center gap-1`}

  > li {
    ${tw`w-1.5 lg:w-6 h-1.5 rounded-full bg-gray-250 transition-colors`}

    &:first-child {
      ${({ score }) => score > 0 && scoreColors[score]}
    }

    &:nth-child(2) {
      ${({ score }) => score > 1 && scoreColors[score]}
    }

    &:nth-child(3) {
      ${({ score }) => score > 2 && scoreColors[score]}
    }

    &:nth-child(4) {
      ${({ score }) => score > 3 && scoreColors[score]}
    }
  }
`

export default PasswordInputField
