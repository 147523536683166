import React, { Suspense } from "react"

const AppPortalContainer = React.lazy(() => import("./AppPortalContainer"))

interface Props {
  url: string | null
}

export default function AppPortalContainerLoader({ url }: Props) {
  return (
    <div>
      <Suspense fallback={<div tw="text-gray-500">Loading&hellip;</div>}>
        <AppPortalContainer url={url} />
      </Suspense>
    </div>
  )
}
