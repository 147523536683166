import { gql, useMutation } from "@apollo/client"
import { useEffect } from "react"

import { BatchRecipient, useGlobalState } from "../common/GlobalState"
import { useGiftData } from "../common/hooks/giftData"
import { successToast } from "../common/toast"
import { generateUUID } from "../common/utilities"

import {
  Send_SalesforceValidateSendPayloadMutation,
  Send_SalesforceValidateSendPayloadMutationVariables,
} from "@/types/graphql-types"

export const FROM_SALESFORCE_STORAGE_NAME = "__goody__from_salesforce"

export const setFromSalesforce = () => {
  window.sessionStorage.setItem(FROM_SALESFORCE_STORAGE_NAME, "true")
}

export const unsetFromSalesforce = () => {
  window.sessionStorage.setItem(FROM_SALESFORCE_STORAGE_NAME, "false")
}

export const getFromSalesforce = () => {
  return window.sessionStorage.getItem(FROM_SALESFORCE_STORAGE_NAME) === "true"
}

export default function useSalesforceSend(
  fromSalesforce: boolean,
  isReady: boolean,
) {
  const [, setRecipients] = useGlobalState("recipients")

  const { setSalesforceData } = useGiftData()

  const [validateSendPayload] = useMutation<
    Send_SalesforceValidateSendPayloadMutation,
    Send_SalesforceValidateSendPayloadMutationVariables
  >(SALESFORCE_VALIDATE_SEND_PAYLOAD_MUTATION)

  useEffect(() => {
    if (fromSalesforce) {
      setFromSalesforce()
    }
  }, [fromSalesforce])

  function postCallback(payload: any) {
    window.parent.postMessage(payload, "*")
  }

  function postError(message: string) {
    postCallback({
      status: "FAIL",
      error_message: message,
    })
  }

  async function populateData(payload: any) {
    // TODO: Also save Salesforce User ID and Salesforce User Email somewhere
    const recips: BatchRecipient[] = payload.contacts.map((contact: any) => {
      return {
        id: generateUUID(),
        firstName: contact.salesforce_contact_first_name || "",
        lastName: contact.salesforce_contact_last_name || "",
        phone: contact.salesforce_contact_mobile_phone,
        email: contact.salesforce_contact_email,
        salesforceRecipientData: {
          salesforceContactTitle: contact.salesforce_contact_title,
          salesforceContactCompanyName: contact.salesforce_contact_company_name,
          salesforceContactId: contact.salesforce_contact_id,
          salesforceLeadId: contact.salesforce_lead_id,
          salesforceOpportunityId: contact.salesforce_opportunity_id,
        },
        errors: {},
      }
    })

    setRecipients(recips)
    setSalesforceData({
      authorization: payload.authorization,
      salesforceOrgId: payload.salesforce_org_id,
      salesforceUserId: payload.salesforce_user_id,
      salesforceUserEmail: payload.salesforce_user_email,
    })

    successToast(
      `Sending to ${recips.length} Salesforce recipient${
        recips.length !== 1 ? "s" : ""
      }`,
    )

    unsetFromSalesforce()
  }

  async function handleMessage(e: MessageEvent) {
    if (e.data?.authorization) {
      console.log("sfdc", e)
    }

    if (fromSalesforce && e.data && e.data.authorization) {
      const payload = e.data
      const authorization = payload.authorization
      const salesforceOrgID = payload.salesforce_org_id

      if (!authorization || !salesforceOrgID) {
        postError("Invalid authorization.")
        return
      }

      const res = await validateSendPayload({
        variables: {
          authorization,
          salesforceOrgID,
        },
      })

      if (res?.data?.salesforceValidateSendPayload.ok) {
        populateData(payload)
      } else {
        postError(
          res?.data?.salesforceValidateSendPayload?.error ??
            "Error validating data.",
        )
      }
    }
  }

  useEffect(() => {
    window.addEventListener("message", handleMessage)

    return () => {
      window.removeEventListener("message", handleMessage)
    }
  }, [])

  useEffect(() => {
    if (fromSalesforce && window.parent && isReady) {
      // Once component mounts, call status READY on the enclosing window.
      // This triggers Salesforce to post payload data.
      postCallback({ status: "READY" })
    }
  }, [isReady])
}

const SALESFORCE_VALIDATE_SEND_PAYLOAD_MUTATION = gql`
  mutation Send_SalesforceValidateSendPayload(
    $authorization: String!
    $salesforceOrgID: String!
  ) {
    salesforceValidateSendPayload(
      authorization: $authorization
      salesforceOrgId: $salesforceOrgID
    ) {
      ok
      error
    }
  }
`
