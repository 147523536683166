import React from "react"
import { Link, useLocation } from "react-router-dom"
import tw from "twin.macro"

import DropdownMenuItem from "./DropdownMenuItem"
import MainMenuItem from "./MainMenuItem"
import RealmToggle from "../../shared/RealmToggle"

import HolidayGiftGuideMenuItem from "@/common/HolidayGiftGuideMenuItem"
import { generateRealmPath } from "@/common/realm"

interface MainMenuProps {
  signedIn: boolean
  isBusiness: boolean
  menuItems: MenuItem[]
}
const MenuItems = ({ signedIn, isBusiness, menuItems }: MainMenuProps) => {
  const location = useLocation()
  const isSignup = location.pathname === "/business/signup"

  return (
    <MainMenuGroup tw="lg:ml-2 xl:ml-4">
      <RealmToggleContainer
        css={[isBusiness && tw`h-auto pt-0 mb-2`, tw`px-4`]}
      >
        <RealmToggle />
      </RealmToggleContainer>
      {!signedIn && isSignup && (
        <Link
          tw="ml-7 text-gray-500"
          to={generateRealmPath("business", "/signin")}
        >
          Sign in
        </Link>
      )}

      {!isSignup &&
        menuItems.map((item) => <MenuItem key={item.name} {...item} />)}
      {isBusiness && <HolidayGiftGuideMenuItem />}
    </MainMenuGroup>
  )
}

const MenuItem = (item: MenuItem) => {
  if (item.dropdownContent) {
    return (
      <DropdownMenuItem
        name={item.name}
        content={item.dropdownContent}
        link={item.path ?? undefined}
      />
    )
  }

  return (
    <MainMenuItem
      name={item.name}
      path={item.path}
      blockReclicks={item.blockReclicks}
      mobileOnly={item.mobileOnly}
    />
  )
}

const MainMenuGroup = tw.div`
  flex flex-col lg:flex-row lg:items-center
  pt-4 pb-8 lg:p-0 lg:gap-5 2xl:gap-7
`

const RealmToggleContainer = tw.div`
  lg:hidden h-16 self-start
  flex flex-row items-center
`

export default MenuItems
