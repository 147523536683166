import { styled } from "twin.macro"

import globalBackground from "../assets/global-background.png"

import { ReactComponent as ArrowRight } from "@/assets/icons/arrow-right.svg"
import { useGlobalState } from "@/common/GlobalState"
import { GradientLink } from "@/common/GradientButton"
import Image from "@/common/Image"
import { generateRealmPath } from "@/common/realm"

export const CountryListProUpsell = () => {
  const [user] = useGlobalState("user")

  const subscribePath = user
    ? generateRealmPath("plus", "/subscribe-pro")
    : generateRealmPath("business", "/signup?pro")

  return (
    <>
      <GradientBackground tw="relative block rounded-tl-xl">
        <GradientBackgroundImage
          src={globalBackground}
          alt="Global background"
          tw="object-center object-cover h-48 rounded-tl-xl relative"
        />
      </GradientBackground>

      <div tw="px-6 -mt-6">
        <h1 tw="text-xl font-semibold text-primary-new-600">
          Your plan includes gifting to Canada
        </h1>
        <p tw="text-base font-normal text-black pt-4 pb-6">
          Upgrade to Pro to send gifts and gift cards worldwide to 140+
          countries.
        </p>

        <GradientLink tw="flex px-4 py-2 gap-5 w-max" to={subscribePath}>
          <span tw="text-base font-normal	text-white">Upgrade to Pro</span>
          <ArrowRight />
        </GradientLink>
      </div>
    </>
  )
}

const GradientBackgroundImage = styled(Image)`
  mask-image: linear-gradient(180deg, transparent 0%, #f2f0ff 50%);
`
const GradientBackground = styled.div`
  background: linear-gradient(180deg, #f2f0ff 0%, #f2f0ff00 100%);
`
