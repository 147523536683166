import { useEffect } from "react"

import { useFeatureAccess } from "./featureAccess"
import { useSendReset } from "./send"

export const useFeatureEffects = () => {
  const { hasFeature } = useFeatureAccess()
  const { resetGiftState } = useSendReset()

  // If we have the pro plan - reset the state to 3 recipients and two week expiry
  useEffect(() => {
    if (hasFeature("pro_plan")) {
      resetGiftState()
    }
  }, [hasFeature("pro_plan")])
}
