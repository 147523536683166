import React from "react"
import Skeleton from "react-loading-skeleton"
import tw, { styled } from "twin.macro"

import AuthorInfo from "./Header/AuthorInfo"

import { Store_EditorialQuery } from "@/types/graphql-types"

export interface IEditorialItem {
  editorialItem?: NonNullable<Store_EditorialQuery["editorialItem"]>
}

const Header: React.FC<IEditorialItem> = ({ editorialItem }) => {
  return (
    <BorderContainer>
      <Container>
        <div tw="p-8 lg:w-[45%]">
          <Title>{editorialItem?.title || <Skeleton />}</Title>
          <Subtitle>{editorialItem?.subtitle || <Skeleton />}</Subtitle>
          {editorialItem?.author && (
            <AuthorInfo editorialItem={editorialItem} />
          )}
        </div>
        {editorialItem?.headerImageLarge ? (
          <img
            src={editorialItem.headerImageLarge.url}
            alt=""
            tw="w-full lg:w-[55%]"
            css={{
              aspectRatio: `${editorialItem.headerImageLarge.width} / ${editorialItem.headerImageLarge.height}`,
            }}
          />
        ) : (
          !editorialItem && <div style={{ height: 250 }} />
        )}
      </Container>
    </BorderContainer>
  )
}

const BorderContainer = styled.div`
  ${tw`overflow-hidden mb-6 lg:mt-6 lg:rounded-xl`};

  @media (min-width: 1024px) {
    background: linear-gradient(124.4deg, #aac2ff 0%, #fabbff 100%);
    padding: 1.5px;
  }
`

const Container = styled.div`
  ${tw`overflow-hidden flex-col-reverse flex lg:flex-row lg:items-center xl:col-span-2 text-primary-500 bg-white`}

  @media (min-width: 1024px) {
    border-radius: 10.5px; // Since this is inset 1.5px
  }
`

const Title = styled.h1`
  ${tw`text-4xl mb-4 pr-4 font-normal`}
  font-family: 'Canela Deck';
  font-weight: 400;
  font-size: 37px;
  line-height: 125%;

  background: linear-gradient(132.78deg, #4a7dff -9.05%, #b28eff 77.67%),
    linear-gradient(90deg, #9f73f7 0%, #7a68f6 100%), #a372df;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`

const Subtitle = styled.p`
  ${tw` pr-7 font-normal`}
  font-size: 18px;
  line-height: 150%;
  color: #000000;
  opacity: 0.6;
`

export default Header
