import { useHistory } from "react-router-dom"

import { BudgetingDepositContent } from "./BudgetingDepositContent"
import { BudgetingBackground } from "./common"
import BudgetingHeader from "./components/BudgetingHeader"
import Container from "../../sites/App/Container"

import { BalanceDepositAccount } from "@/types/graphql-types"

interface Props {
  donePath: string
  title: string
  subtitle: string
  accountType: BalanceDepositAccount
  backText?: string
}

export default function BudgetingDeposit({
  donePath,
  title,
  subtitle,
  accountType,
  backText,
}: Props) {
  const history = useHistory()

  return (
    <BudgetingBackground>
      <Container>
        <div tw="pb-9 px-6">
          <BudgetingHeader
            title={title}
            subtitle={subtitle}
            backPath={donePath}
            backText={backText}
          />
          <BudgetingDepositContent
            fullWidth={false}
            accountType={accountType}
            onSuccess={() => history.push(donePath)}
          />
        </div>
      </Container>
    </BudgetingBackground>
  )
}
