import React from "react"
import Toggle from "react-toggle"
import tw, { styled } from "twin.macro"
import "react-toggle/style.css"

interface Props {
  enabled: boolean
  setEnabled: (enabled: boolean) => void
}

export default function DirectSendNotificationToggle({
  enabled,
  setEnabled,
}: Props) {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log("///")
    console.log(e.target.checked)
    setEnabled(e.target.checked)
  }

  return (
    <ToggleContainer>
      <Toggle checked={enabled} icons={false} onChange={onChange} />
    </ToggleContainer>
  )
}

const ToggleContainer = styled.div`
  height: 24px;

  .react-toggle-track {
    width: 44px;
    height: 24px;
    ${tw`bg-gray-350`};
  }

  .react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    ${tw`bg-gray-400`};
  }

  .react-toggle:active:not(.react-toggle--disabled) .react-toggle-track {
    ${tw`bg-gray-500`};
  }

  .react-toggle--checked .react-toggle-track {
    background-color: #7d66ff !important;
  }

  .react-toggle-thumb {
    top: 3px;
    left: 3px;
    border: 0;
    width: 18px;
    height: 18px;
  }

  .react-toggle--checked .react-toggle-thumb {
    left: 22px;
  }

  .react-toggle--focus .react-toggle-thumb,
  .react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
    box-shadow: none;
  }
`
