import { isNil } from "lodash-es"
import React, { ReactNode } from "react"
import { Link } from "react-router-dom"
import tw, { styled } from "twin.macro"

import { useHolidayEmailGateUnsafe } from "../components/HolidayGiftGuideScreen/hooks/useHolidayEmailGate"

type variant = "red" | "pink" | "blue"

type Props = {
  className: string
  text: ReactNode
  to: string
  variant: variant
}

const HolidayGiftGuideButton = ({ className, text, to, variant }: Props) => {
  // NOTE: Context is not available inside the plasmic editor so we need to call it this way to avoid crashes.
  const { tryAccessGiftGuide } = useHolidayEmailGateUnsafe() ?? {}

  const onClick = (e: React.MouseEvent) => {
    if (isNil(tryAccessGiftGuide)) {
      return
    }

    if (!tryAccessGiftGuide()) {
      e.preventDefault()
    }
  }

  switch (variant) {
    case "red":
      return (
        <StyledLink
          onClick={onClick}
          to={to}
          className={className}
          tw="hover:(bg-[#B55365]) active:(bg-[#801A1A] border-[#801A1A]) bg-[#9D2322] text-white"
        >
          {text}
        </StyledLink>
      )
    case "blue":
      return (
        <StyledLink
          onClick={onClick}
          to={to}
          className={className}
          tw="hover:(bg-[#447FB5]) active:(bg-[#366B9C] border-[#366B9C]) bg-[#82A0BC] text-white"
        >
          {text}
        </StyledLink>
      )
    case "pink":
      return (
        <StyledLink
          onClick={onClick}
          to={to}
          className={className}
          tw="hover:(bg-[#ECBBC4]) active:(bg-[#9D2322] border-[#9D2322] text-white) bg-[#F8E1E1]"
        >
          {text}
        </StyledLink>
      )
  }

  return null
}

const StyledLink = styled(Link)`
  ${tw`transition-all border-2 border-white rounded-[3rem] py-3 min-w-[10rem] font-semibold text-center whitespace-nowrap`}
`

export default HolidayGiftGuideButton
