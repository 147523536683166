import tw from "twin.macro"

export const GridCenterContainer = tw.div`
  grid grid-cols-1 lg:grid-cols-2
  items-center justify-items-center
`

export const FlexCenterItems = tw.div`
  flex flex-col items-center
`
