import tw from "twin.macro"

import { CountryFlag } from "@/common/CountryFlag"
import { ShippingCountry } from "@/types/graphql-types"

interface Props {
  country: ShippingCountry
  selected: boolean
  onClick: (country: ShippingCountry) => void
  children?: React.ReactNode
}

export const CountrySelectorItem = ({
  country,
  selected,
  onClick,
  children,
}: Props) => {
  return (
    <Item
      css={[selected && tw`text-primary-500 font-medium`]}
      onClick={() => onClick(country)}
    >
      <CountryFlag code={country.code} size="m" />
      <h4>{country.name}</h4>
      {children}
    </Item>
  )
}

const Item = tw.button`
  flex flex-row gap-3.5 items-center py-1 px-1.5 w-full transition-colors
  text-black text-base font-normal hover:(text-primary-600)
`
