import tw from "twin.macro"

import { CountryGroup } from "./components/CountryGroup"
import { CountryListBaseProps } from "../../types"

const CountryListPopover = ({
  groupedCountries,
  selectedCountry,
  onSelectCountry,
  withProUpsell,
}: CountryListBaseProps) => {
  return (
    <div
      tw="bg-white absolute w-[500px] right-0 rounded-xl border border-gray-200 transition-colors z-[150]"
      css={[Carat]}
    >
      {["domestic", "global"].map(
        (group) =>
          groupedCountries[group]?.length > 0 && (
            <div
              key={group}
              tw="p-4"
              css={[group === "domestic" && tw`border-b border-gray-200`]}
            >
              <CountryGroup
                variant={group as "domestic" | "global"}
                countries={groupedCountries[group]}
                selectedCountry={selectedCountry}
                onSelectCountry={onSelectCountry}
                withProUpsell={group === "global" && withProUpsell}
              />
            </div>
          ),
      )}
    </div>
  )
}

const Carat = tw`
  before:(absolute top-[-9px] right-[20px] h-[17px] w-[17px] border-t border-l bg-white border-gray-200 rounded-tl-sm rotate-45)
`

export default CountryListPopover
