import "twin.macro"
import { generateRealmPath } from "@/common/realm"
import StartGiftButton from "@/send/components/startGift/StartGiftButton"

export default function StartGift() {
  return (
    <div tw="md:pb-8">
      <div tw="font-reckless-neue text-[2rem] py-8 text-center tracking-[-0.67px] text-gray-700">
        Start your gift
      </div>
      <div tw="flex flex-col md:flex-row items-stretch gap-4 md:gap-8">
        <StartGiftButton
          variant="browse"
          title="Browse the store"
          subtitle="Your recipient can still swap for something else if they prefer."
          to={generateRealmPath(null, "/browse")}
          clickEvent="Business - Send - Start Gift - Click Browse"
        />
        <StartGiftButton
          variant="giftOfChoice"
          title="Let recipient choose"
          subtitle="Set a budget and let your recipient choose a gift."
          to={generateRealmPath(null, "/browse/brands/goody/a-goody-gift")}
          clickEvent="Business - Send - Start Gift - Click Gift of Choice"
          showMostPopularBadge={true}
        />
        <StartGiftButton
          variant="swag"
          title="Custom swag"
          subtitle="Design merch with your own logo. No minimums on most products."
          to={generateRealmPath(null, "/browse/category/branded-swag")}
          clickEvent="Business - Send - Start Gift - Click Swag"
        />
      </div>
    </div>
  )
}
