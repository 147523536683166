import React from "react"
import { Link } from "react-router-dom"
import { useIntercom } from "react-use-intercom"

import GradientButton from "../../../common/GradientButton"

interface Props {
  children: React.ReactElement
  href: string
  fixed: string
  target: string
  external: string
}

const ActionContainer = ({
  children,
  isChat,
  href,
  target,
  external,
}: {
  children: React.ReactElement
  isChat: boolean
  external: boolean
  href: string
  target: string
}) => {
  const { show } = useIntercom()

  if (isChat) {
    return <div onClick={show}>{children}</div>
  } else if (target || external) {
    return (
      <a href={href} target={target}>
        {children}
      </a>
    )
  } else {
    return (
      <Link to={{ pathname: href, state: { allowRealmRedirect: true } }}>
        {children}
      </Link>
    )
  }
}

const CTABannerButton = ({ children, href, target, external }: Props) => {
  const isExternalLink = external === "true"

  return (
    <div tw={"mt-6 lg:mt-9 self-center w-max"}>
      <ActionContainer
        isChat={href === "open_chat"}
        href={href}
        external={isExternalLink}
        target={target}
      >
        <GradientButton
          tw={
            "flex rounded-[6.25rem] justify-center py-[11px] px-6 w-max self-center font-semibold tracking-[-.3px] text-lg leading-p-140 lg:text-xl lg:leading-p-140"
          }
        >
          {children}
        </GradientButton>
      </ActionContainer>
    </div>
  )
}

export default CTABannerButton
