import { useState } from "react"

import { ExpireAtOption } from "@/common/GlobalState"
import { createHookContext } from "@/common/hooks/createHookContext"
import { useFeatureFlags } from "@/common/hooks/featureFlags"
import { sixWeeksFromNowNoon } from "@/common/utilities"
import {
  GiftSwapTypeEnum,
  GiftsSettingsInput,
  InternationalShippingTierEnum,
  gift_meeting_setting,
} from "@/types/graphql-types"

export interface GiftBatchSettingsType {
  giftBatchId: string
  batchName: string | null
  customExpirationDate: Date
  expiresAt?: Date | null
  expireAtOption: ExpireAtOption
  hasCustomStore: boolean
  hasFlexGiftItem: boolean
  hasForceSwapEnabledItem: boolean
  internationalShippingTier: InternationalShippingTierEnum
  settings: GiftsSettingsInput
  isDirectSend: boolean
  isExpired: boolean
  isFlexGift: boolean
  isSmartLink: boolean
  isUsingBalance: boolean
  giftMeetingSetting: gift_meeting_setting
  pricePreTax: number
  swapType?: GiftSwapTypeEnum | null
  userFullName: string | null
  userPublicId: string | null
}

const useGiftBatchSettingsLocal = () => {
  const { hasFeatureFlag } = useFeatureFlags()
  const [settings, setSettings] = useState<GiftBatchSettingsType>({
    giftBatchId: "",
    batchName: null,
    expiresAt: null,
    expireAtOption: "none",
    customExpirationDate: sixWeeksFromNowNoon(),
    internationalShippingTier: hasFeatureFlag("global_partners")
      ? InternationalShippingTierEnum.disabled
      : InternationalShippingTierEnum.standard,
    settings: { giftCardsEnabled: false },
    isDirectSend: false,
    isExpired: false,
    isFlexGift: false,
    isSmartLink: false,
    isUsingBalance: false,
    hasCustomStore: false,
    hasFlexGiftItem: false,
    hasForceSwapEnabledItem: false,
    pricePreTax: 0,
    swapType: GiftSwapTypeEnum.swap_single,
    userFullName: null,
    userPublicId: null,
    giftMeetingSetting: gift_meeting_setting.NO_MEETING,
  })

  return {
    settings,
    setSettings,
  }
}

export const {
  Provider: GiftBatchSettingsProvider,
  useHook: useGiftBatchSettings,
} = createHookContext("GiftBatchSettings", useGiftBatchSettingsLocal)
