import {
  add,
  differenceInDays,
  differenceInWeeks,
  isAfter,
  isEqual,
  parseISO,
} from "date-fns"

import { Track_GiftBatchFragment } from "@/types/graphql-types"

export const getExpiresAtCopy = (giftBatch: Track_GiftBatchFragment) => {
  const expiresAtString = giftBatch.expiresAt

  if (expiresAtString === undefined || expiresAtString === null) {
    return "Doesn’t expire"
  }

  const expiresAt = parseISO(expiresAtString)

  if (giftBatch.isExpired) {
    return (
      "Expired on " +
      expiresAt.toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
      })
    )
  }

  const currentDate = new Date()
  const twoWeeksFromNow = add(currentDate, { weeks: 2 })

  if (
    isEqual(expiresAt, twoWeeksFromNow) ||
    isAfter(expiresAt, twoWeeksFromNow)
  ) {
    const weeksAway = differenceInWeeks(expiresAt, currentDate)
    return "Expires in " + weeksAway + " weeks"
  }

  const TwentyFourHoursFromNow = add(currentDate, { hours: 24 })

  if (
    isEqual(expiresAt, TwentyFourHoursFromNow) ||
    isAfter(expiresAt, TwentyFourHoursFromNow)
  ) {
    const daysAway = differenceInDays(expiresAt, currentDate)

    const multipleDays = isAfter(expiresAt, add(currentDate, { hours: 48 }))
    return "Expires in " + daysAway + " day" + (multipleDays ? "s" : "")
  }

  return "Expires today"
}
