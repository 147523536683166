import { createContext } from "react"

import { BatchRecipient, CurrentGift } from "../../common/GlobalState"

import {
  Send_GetUserQuery,
  Send_PriceEstimateMutation,
} from "@/types/graphql-types"

interface OptionsContextType {
  currentGift: CurrentGift
  recipients: BatchRecipient[]
  priceEstimate: Send_PriceEstimateMutation["priceEstimate"] | null
  isDirectSend: boolean
  calendarIntegration?:
    | NonNullable<Send_GetUserQuery["me"]>["calendarIntegration"]
    | null
}

export const OptionsContext = createContext<OptionsContextType>({
  currentGift: {} as CurrentGift,
  recipients: [],
  priceEstimate: null,
  isDirectSend: false,
  calendarIntegration: null,
})
