import { isNil } from "lodash-es"
import pluralize from "pluralize"
import React from "react"
import { NonUndefined } from "react-hook-form"
import Modal from "react-modal"

import { formatPrice, isBlank } from "@/common/format"
import { CurrentGift } from "@/common/GlobalState"
import { useGiftCart } from "@/common/hooks/giftData"
import {
  BALANCE_PAYMENT_METHOD_ID,
  CORPORATE_ACCOUNT_PAYMENT_METHOD_ID,
  getPaymentMethodName,
} from "@/common/hooks/payment"
import { modalStyle } from "@/common/modal"
import { imageForCardBrand } from "@/common/payment"
import { MiniPaymentMethodLabel } from "@/common/payments/miniPaymentMethodSelector/MiniPaymentMethodLabel"
import { PurpleGradientButton } from "@/common/PurpleGradientLink"
import DiamondPlusIcon from "@/send/components/modals/assets/icons/diamond-plus.svg"
import { useTopUpExperiment } from "@/send/hooks/topUpExperiment"
import { gift_meeting_setting } from "@/types/graphql-types"
interface Props {
  isOpen: boolean
  onSubmit: () => void
  onRequestClose: () => void
  onAfterClose?: () => void
  currentGift: CurrentGift
  isDirectSend: boolean
  hasRecipientWithContact: boolean
  potentialRecipientsCount: number
}
export const ReviewGiftsModal = ({
  isOpen,
  onSubmit,
  onRequestClose,
  onAfterClose,
  currentGift,
  isDirectSend,
  hasRecipientWithContact,
  potentialRecipientsCount,
}: Props) => {
  const { hasFlexGiftItem, hasCustomStore } = useGiftCart()
  const paymentMethod = currentGift.paymentMethod
  const { canExposeToTopUpExperiment, topUpAmount } = useTopUpExperiment()
  const topUpPaymentMethod = currentGift.topUpPaymentMethod

  const isUsingTopUp =
    paymentMethod?.id === BALANCE_PAYMENT_METHOD_ID &&
    canExposeToTopUpExperiment
  const displayTopUpUI = isUsingTopUp && !isNil(topUpPaymentMethod)

  const buildToFromString = () => {
    const recipientCount = (() => {
      if (currentGift.isGiftDispenser && currentGift.giftDispenserQuantity) {
        return currentGift.giftDispenserQuantity
      } else if (currentGift.isSmartLink && currentGift.smartLinkQuantity) {
        return currentGift.smartLinkQuantity
      } else {
        return potentialRecipientsCount
      }
    })()

    const to = pluralize(`${recipientCount} recipient`, recipientCount)
    const from = isDirectSend ? "Directly to Address" : currentGift.fromName
    const fromString = isBlank(from) ? " / Your name" : ` / ${from}`

    return to + fromString
  }

  const buildInternationalShippingString = () => {
    return {
      gift_cards: "Gift Cards Only",
      disabled: "US Only",
      global_partners: "Enabled",
      global_relay: "Enabled",
      standard: "Standard",
      full: "Global",
    }[currentGift.internationalShippingTier]
  }

  const buildSwapString = (hasFlexGiftItem: boolean) => {
    const selectText = hasFlexGiftItem ? "recipient can select" : "swap for"

    return {
      swap_disabled: "Disabled",
      swap_multiple: "Prices shown – " + selectText + " multiple items",
      swap_single: "Prices hidden – " + selectText + " one item",
    }[currentGift.swapType]
  }

  const buildPaymentMethodString = () => {
    if (paymentMethod === null) {
      return ""
    }

    const isCC = !(
      paymentMethod.id === BALANCE_PAYMENT_METHOD_ID ||
      paymentMethod.id === CORPORATE_ACCOUNT_PAYMENT_METHOD_ID
    )
    const image = isCC
      ? imageForCardBrand(paymentMethod.cardBrand)
      : imageForCardBrand("BALANCE")
    const text = isCC
      ? `•••• ${paymentMethod.last4}`
      : getPaymentMethodName(paymentMethod)

    return (
      <div tw="tabular-nums font-medium flex flex-row gap-3 items-center justify-start">
        <div>{image}</div>
        <div>{text}</div>
        <div>{buildTopUpPaymentMethodString()}</div>
      </div>
    )
  }

  const buildTopUpPaymentMethodString = () => {
    if (displayTopUpUI) {
      return (
        <div tw="flex flex-row items-center">
          <span tw="text-gray-450 mr-[11px]">+</span>
          <MiniPaymentMethodLabel paymentMethod={topUpPaymentMethod} />
        </div>
      )
    }

    return null
  }

  const buildTopUpPaymentBanner = () => {
    if (displayTopUpUI) {
      return (
        <div tw="flex flex-row items-center rounded-lg bg-primary-new-050 p-4 my-6">
          <img src={DiamondPlusIcon} tw="mr-3" />
          <span tw="font-medium mr-2">
            {formatPrice(topUpAmount)} will be added to your balance using
          </span>
          <MiniPaymentMethodLabel paymentMethod={topUpPaymentMethod} />
        </div>
      )
    }

    return null
  }

  const buildNotificationsString = () => {
    if (!currentGift.landingPageSendNotifs) {
      return "Disabled"
    }

    if (hasRecipientWithContact) {
      return "Enabled"
    }

    return "Enabled, but no valid phone or emails present. No notifications will be sent."
  }

  const buildConditionalStandardContent = () => {
    if (
      currentGift.giftCalendarSetting !== gift_meeting_setting.NO_MEETING &&
      currentGift.giftCalendarSetting !== null
    ) {
      defaultSendContent.push({
        title: "Meeting Required",
        content: {
          GIFT_FIRST: "Optional after acceptance",
          MEETING_REQUIRED: "Required before acceptance",
        }[currentGift.giftCalendarSetting],
      })
    }
  }

  const createDefaultSendContent = (hasFlexGiftItem: boolean) => {
    return [
      currentGift.isSmartLink && !isNil(currentGift.smartLinkQuantity)
        ? {
            title: "Maximum Gifts",
            content: currentGift.smartLinkQuantity,
          }
        : {
            title: "To / From",
            content: buildToFromString(),
          },
      {
        title: "Payment Method",
        content: buildPaymentMethodString(),
      },
      {
        title: "International Shipping",
        content: buildInternationalShippingString(),
      },
      !currentGift.isGiftDispenser && !currentGift.isSmartLink
        ? {
            title: "Recipient Notifications",
            content: buildNotificationsString(),
          }
        : undefined,
      !(isDirectSend || hasCustomStore)
        ? {
            title: hasFlexGiftItem ? "Gift Choice" : "Recipient Swap",
            content: buildSwapString(hasFlexGiftItem),
          }
        : undefined,
    ].filter((item): item is NonUndefined<typeof item> => item !== undefined)
  }

  const defaultSendContent = createDefaultSendContent(hasFlexGiftItem)

  buildConditionalStandardContent()

  const directSendContent = [
    {
      title: "To",
      content: buildToFromString(),
    },
    {
      title: "Payment Method",
      content: buildPaymentMethodString(),
    },
    {
      title: "Recipient Notifications",
      content: buildNotificationsString(),
    },
  ]

  const buildPrimaryButtonContent = () => {
    if (
      (!currentGift.landingPageSendNotifs && !isDirectSend) ||
      currentGift.isSmartLink
    ) {
      return "Get gift link"
    }

    if (currentGift.scheduledSendOnOption) {
      return "Schedule gift"
    }

    return "Send gift"
  }

  const modalContent = isDirectSend ? directSendContent : defaultSendContent
  const primaryButtonContent = buildPrimaryButtonContent()

  return (
    <Modal
      isOpen={isOpen}
      closeTimeoutMS={500}
      onRequestClose={() => {
        onRequestClose()
      }}
      onAfterClose={onAfterClose}
      shouldCloseOnOverlayClick={true}
      style={modalStyle}
    >
      <div className="modal-content modal-content-wide">
        <div tw="p-6">
          <div tw="text-2xl text-primary-500 pb-2">Review gifts</div>
          {modalContent.map((item) => (
            <div key={item.title} tw="pt-4">
              <div tw="font-medium text-primary-500">{item.title}</div>
              <div tw="font-medium mt-1">{item.content}</div>
            </div>
          ))}
          {buildTopUpPaymentBanner()}
          <div tw={"mt-6 flex flex-row gap-5"}>
            <PurpleGradientButton
              tw="flex-row gap-4 justify-center flex-grow"
              onClick={() => onSubmit()}
            >
              <div>{primaryButtonContent}</div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#ffffff"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-arrow-right"
              >
                <line x1="5" y1="12" x2="19" y2="12" />
                <polyline points="12 5 19 12 12 19" />
              </svg>
            </PurpleGradientButton>
            <button
              tw="border border-solid border-gray-200 rounded-lg py-4 leading-[20px] text-gray-500 w-1/4"
              onClick={() => onRequestClose()}
            >
              Go back
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
