import { track } from "@/common/analytics"
import CalendarIcon from "@/organization/settings/assets/icons/calendar.svg"
import Upsell from "@/organization/Upsell"
import UpsellSection from "@/organization/UpsellSection"

const OrganizationReceiptsUpsell = () => (
  <Upsell>
    <UpsellSection
      title="See a unified view of receipts across all users in your organization with the Team plan"
      subtext="Upgrade to the Team plan to see receipts across all users in your organization, plus more features like Salesforce integration, budgeting, and more."
      icon={CalendarIcon}
      cta="Book a call"
      onClick={() => {
        track("Organization - Organization Receipts Upsell - Click Book a Call")
        window.open(
          `${window.location.origin}/business/team-demo?campaign=goody-plus-website-organization-receipts`,
        )
      }}
    />
  </Upsell>
)

export default OrganizationReceiptsUpsell
