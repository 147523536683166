import { gql } from "@apollo/client"

export const DEACTIVATE_PAYMENT_METHOD_MUTATION = gql`
  mutation DeactivatePaymentMethod(
    $paymentMethodId: ID!
    $replacementPaymentMethodId: ID
  ) {
    paymentMethodDeactivate(
      paymentMethodId: $paymentMethodId
      replacementPaymentMethodId: $replacementPaymentMethodId
    ) {
      ok
      error
      organizationSubscriptionMatchingCardNumber
    }
  }
`
