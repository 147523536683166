import React from "react"

import { formatPrice } from "../../../common/format"
import GradientButton from "../../../common/GradientButton"

import { BalanceDepositFundingMethod } from "@/types/graphql-types"

interface Props {
  amountInCents: number | null
  fundingMethod: BalanceDepositFundingMethod
  loading: boolean
  onSubmit: (e: any) => void
}

export default function SubmitButton({
  amountInCents,
  fundingMethod,
  loading,
  onSubmit,
}: Props) {
  return (
    <div tw="text-center pt-5">
      <GradientButton disabled={!amountInCents || loading} onClick={onSubmit}>
        {fundingMethod === "CREDIT_CARD" ? "Add" : "Request invoice for"}{" "}
        {amountInCents ? formatPrice(amountInCents) : " funds"}
      </GradientButton>
    </div>
  )
}
