import React from "react"
import tw, { styled } from "twin.macro"

import { SendTabEdit, SendTabLink } from "@/assets/icons"

interface Props {
  isSmartLink: boolean
  setIsSmartLink: (isSmartLink: boolean) => void
}

const RecipientTypeHeader = ({ isSmartLink, setIsSmartLink }: Props) => {
  return (
    <Container>
      <RecipientTypeButton
        selected={!isSmartLink}
        onClick={() => setIsSmartLink(false)}
      >
        <SendTabEdit />
        Enter recipients
      </RecipientTypeButton>
      <RecipientTypeButton
        selected={isSmartLink}
        onClick={() => setIsSmartLink(true)}
      >
        <SendTabLink />
        Anyone can claim
      </RecipientTypeButton>
    </Container>
  )
}

const Container = styled.div`
  ${tw`lg:mb-0 mb-6 lg:border-none border border-gray-200 flex flex-row items-stretch h-[70px] rounded`};
  ${tw`rounded-t-lg lg:rounded-b-none rounded-b-lg bg-gray-075`};

  @media (min-width: 768px) {
    background: linear-gradient(0deg, white 50%, #f6f7f9 50%);
  }
`

const RecipientTypeButton = styled.button<{ selected: boolean }>`
  ${tw`flex-1 rounded-t-lg transition-all text-lg font-medium flex flex-row items-center justify-center gap-3 px-5`}
  ${tw`rounded-b-lg lg:rounded-b-none lg:first:rounded-br-lg lg:last:rounded-bl-lg `}
  ${({ selected }) =>
    selected ? tw`bg-white text-primary-600` : tw`bg-gray-075 text-gray-500`}
`

export default RecipientTypeHeader
