import tw, { styled } from "twin.macro"

interface StepProps {
  header: string
  body: string
}

interface HowItWorksProps {
  stepOne: StepProps
  stepTwo: StepProps
  stepThree: StepProps
}

const HowItWorks = ({
  stepOne,
  stepTwo,
  stepThree,
  ...props
}: HowItWorksProps) => (
  <div tw="bg-[#F8F8F8] px-5 pb-16 lg:pb-20" {...props}>
    <div tw="max-w-[1200px] mx-auto">
      <h2 tw="text-center font-bold mb-12 text-[2rem] lg:text-[3rem] lg:leading-[3.5rem] lg:mb-20">
        How It Works
      </h2>
      <div tw="flex flex-col lg:flex-row">
        <Step number={1} header={stepOne.header} body={stepOne.body} />
        <Step number={2} header={stepTwo.header} body={stepTwo.body} />
        <Step number={3} header={stepThree.header} body={stepThree.body} />
      </div>
    </div>
  </div>
)

interface StepComponentProps {
  number: number
  header: string
  body: string
}

const Step = ({ number, header, body }: StepComponentProps) => (
  <div tw="flex mb-8 max-w-[500px] mx-auto lg:mx-2 lg:flex-1">
    <Number>{number}</Number>
    <div>
      <div tw="text-2xl font-bold mb-3 mt-1">{header}</div>
      <div tw="text-gray-600 text-xl">{body}</div>
    </div>
  </div>
)

const Number = styled.div`
  ${tw`flex items-center justify-center flex-shrink-0 h-[42px] w-[42px] mr-4 font-bold text-2xl`}
  background: linear-gradient(90deg, #CAD7FF -2.08%, #E9EEFF 101.08%);
  border-radius: 50%;
`

export default HowItWorks
