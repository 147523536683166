import { useContext } from "react"

import { DeveloperModeContext } from "../contexts/developerMode"

export const useDeveloperModeContext = () => {
  const context = useContext(DeveloperModeContext)

  if (context === undefined || context === null) {
    throw new Error(
      "useDeveloperModeContext must be used within DeveloperModeContext",
    )
  }

  return context
}
