import { useMutation, useQuery } from "@apollo/client"
import { useHistory } from "react-router-dom"

import { EditPurpleIcon } from "@/assets/icons"
import { generateRealmPath } from "@/common/realm"
import { Loader } from "@/common/UI"
import {
  SWAG_EDIT_PRODUCT_MUTATION,
  SWAG_PRODUCT_QUERY,
} from "@/SwagIntegrations/graphql"
import {
  SwagIntegrationsEditProductMutation,
  SwagIntegrationsEditProductMutationVariables,
  SwagProductQuery,
  SwagProductQueryVariables,
} from "@/types/graphql-types"

interface Props {
  product: {
    brand: { slug: string }
    slug: string
  }
}

export const SwagIntegrationUpdateButton = ({ product }: Props) => {
  const history = useHistory()

  const { data, loading } = useQuery<
    SwagProductQuery,
    SwagProductQueryVariables
  >(SWAG_PRODUCT_QUERY, {
    fetchPolicy: "cache-and-network",
    variables: {
      productSlug: product.slug,
      brandSlug: product.brand.slug,
    },
  })
  const swagProduct = data?.productDetails?.swagProduct

  const [editSwagProductMutation, { loading: loadingMutation }] = useMutation<
    SwagIntegrationsEditProductMutation,
    SwagIntegrationsEditProductMutationVariables
  >(SWAG_EDIT_PRODUCT_MUTATION)

  const editProduct = async () => {
    if (!swagProduct) return

    const { data } = await editSwagProductMutation({
      variables: {
        providerType: swagProduct?.providerType,
        externalProductId: swagProduct?.externalProductId,
      },
    })

    if (data?.swagIntegrations.editProduct.ok) {
      history.push(
        generateRealmPath(
          "plus",
          `/editor/${product.brand.slug}/${product.slug}`,
        ),
      )
    }
  }

  const editLoading = loading || loadingMutation

  return (
    <button
      onClick={editProduct}
      disabled={editLoading}
      tw="flex gap-2 p-2 items-center text-gray-600 hover:text-[#A372DF] active:text-[#A372DF]"
    >
      {editLoading ? <Loader /> : <EditPurpleIcon width={20} height={20} />}
      Edit
    </button>
  )
}
