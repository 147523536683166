import React from "react"

import {
  PreviewButtonLocation,
  trackClickPreview,
  useGiftPreview,
} from "@/send/hooks/giftPreview"
import GiftPreview from "@/send/images/gift-preview.svg"

interface Props {
  location: PreviewButtonLocation
}

export const GiftPreviewButton = ({ location }: Props) => {
  const { openPreview } = useGiftPreview()

  const onClick = () => {
    openPreview()
    trackClickPreview({ location, type: "Gift" })
  }

  return (
    <button
      tw="z-10 bg-white rounded-lg border border-primary-new-200 flex flex-row items-center gap-2 pr-3 pl-2 py-2 h-[40px] text-primary-new-600 hover:(bg-primary-new-000) active:(bg-primary-new-050 scale-95 duration-100) transition-opacity leading-4"
      css={{
        boxShadow: "0px 1px 4px 0px rgba(68, 4, 179, 0.07)",
      }}
      onClick={onClick}
    >
      <img src={GiftPreview} alt={"Gift preview"} />
      <div>Preview gift</div>
    </button>
  )
}
