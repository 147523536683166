import React from "react"
import tw, { styled } from "twin.macro"

import { BarContainer, FullContainer } from "./common"

import HowItWorksAnimationLoader from "@/landing/business/HowItWorksSection/HowItWorksAnimationLoader"

interface Props {
  className?: string
  leftContent?: React.ReactNode
  rightContent?: React.ReactNode
}

// Displays the How It Works animations as well as the accompanying text
// The text is given as props on the left/right (Plasmic slots)
export default function HowItWorksSection({
  className,
  leftContent,
  rightContent,
}: Props) {
  return (
    <div className={className}>
      {/* Left content is displayed above on mobile */}
      <div tw="md:hidden pb-6">{leftContent}</div>
      <HowItWorksAnimationLoader />
      {/* Right content is displayed above on mobile */}
      <div tw="md:hidden pt-6">{rightContent}</div>
      {/* On desktop, show side-by-side under the animations */}
      <FullContainer tw="pt-10 hidden md:flex items-start">
        <TextContainer>{leftContent}</TextContainer>
        <BarContainer tw="h-0">{/* empty */}</BarContainer>
        <TextContainer>{rightContent}</TextContainer>
      </FullContainer>
    </div>
  )
}
const TextContainer = styled.div`
  ${tw`flex-1 w-full max-w-[560px]`};
`
