import { RefObject, forwardRef } from "react"
import tw, { styled } from "twin.macro"

import ChevronDown from "../../common/images/chevron-down.png"

import { OrganizationMembershipAccess } from "@/types/graphql-types"

const WorkspaceInviteAccessDropdown = forwardRef(({ ...props }, ref) => {
  const accesses = [
    OrganizationMembershipAccess.full,
    OrganizationMembershipAccess.limited,
  ]

  return (
    <SelectContainer tw="items-center relative bg-gray-200 rounded-3xl w-[fit-content]">
      <select
        tw="py-2 px-4 pr-8 appearance-none bg-transparent inline outline-none"
        {...props}
        ref={ref as RefObject<HTMLSelectElement>}
      >
        {accesses.map((access) => (
          <option value={access}>Invite as {access} members</option>
        ))}
      </select>
    </SelectContainer>
  )
})

const SelectContainer = styled.div`
  &::after {
    ${tw`flex items-center absolute stroke-current pointer-events-none right-2 top-1 bottom-0`}
    content: url("${ChevronDown}");
    scale: 0.65;
  }
`

export default WorkspaceInviteAccessDropdown
