import tw, { styled } from "twin.macro"

export const SendStepContainer = styled.div`
  ${tw`w-full border rounded-xl bg-white transition-border transition-shadow cursor-default text-left`}

  border: 2px solid #ECEEF1;
  box-shadow:
    0px 1px 4px rgba(0, 0, 0, 0.04),
    0px 6px 20px rgba(0, 0, 0, 0.04);

  &.step-active {
    ${tw`cursor-auto`}
    border: 2px solid rgba(198, 139, 203, 0.4);
    box-shadow:
      0px 1px 4px rgba(148, 17, 159, 0.04),
      0px 6px 20px rgba(148, 17, 159, 0.04);
  }
`
