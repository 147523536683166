import { useCallback, useEffect } from "react"

import { useGlobalState } from "../GlobalState"
import { generateRealmPath } from "../realm"

const IFRAME_IN_PROGRESS = "IN_PROGRESS"
const IFRAME_COMPLETED = "COMPLETED"
const IFRAME_REFRESHED_CLOSE_AUTH = "IFRAME_REFRESHED_CLOSE_AUTH"
const IFRAME_AUTH_LOCAL_STORAGE_KEY = "iFrameAuthStatus"

type AuthStatus = "IN_PROGRESS" | "COMPLETED" | "IFRAME_REFRESHED_CLOSE_AUTH"

const setIframeAuthStatus = (status: AuthStatus) => {
  localStorage.setItem(IFRAME_AUTH_LOCAL_STORAGE_KEY, status)
}

const getIframeAuthStatus = (): AuthStatus =>
  localStorage.getItem(IFRAME_AUTH_LOCAL_STORAGE_KEY) as AuthStatus

const resetIframeAuthStatus = () => {
  localStorage.removeItem(IFRAME_AUTH_LOCAL_STORAGE_KEY)
}

const iframeAuthenticationCompleted = (event: StorageEvent) => {
  if (event?.storageArea?.iFrameAuthStatus === IFRAME_COMPLETED) {
    setIframeAuthStatus(IFRAME_REFRESHED_CLOSE_AUTH)
    window.location.pathname = generateRealmPath("plus", "/salesforce-send")
  }
}

// Used for edge case where user stops iframe authentication flow then opens new tab and authenticates
// Placed everywhere auth is started
export const resetIframeAuthIfPresent = () => {
  if (!isInIframe() && getIframeAuthStatus()) {
    resetIframeAuthStatus()
  }
}

export const isInIframe = () => window.self !== window.top

/*
  iFrame Authentication Flow
  1. Load authentication page for social login in a new tab. Set the IFRAME_AUTH_LOCAL_STORAGE_KEY to in progress
  2. The user then completed the regular authentication path.
  3. Once the user is authenticated the now authenticated new tab sets the IFRAME_AUTH_LOCAL_STORAGE_KEY to completed and closes itself
  4. The iframe now nested within a window with the proper authentication cookie refreshes itself, now authenticated
 */

// Contains code for part 1 and 4 of the iframe authentication flow
// Part 1 and 4 are within startIframeAuthentication and used in OmniAuthButton.tsx in the onSubmit of the omniauth social login form
export const useIFrameAuthInitiationCallback = () => {
  const [signedIn] = useGlobalState("signedIn")

  const startIframeAuthentication = useCallback(() => {
    if (!signedIn && isInIframe()) {
      window.addEventListener("storage", iframeAuthenticationCompleted, true)
      setIframeAuthStatus(IFRAME_IN_PROGRESS)
    }
  }, [signedIn])

  return startIframeAuthentication
}

// If the current page is loaded from a completed iframe authentication then we set the iframe authentication status to complete and close the window
// This is part 3 of the authentication process and placed in App.tsx
export const useIframeCompletedCheck = () => {
  const [signedIn] = useGlobalState("signedIn")

  const closeWindowUsedForOmniAuth = (event: StorageEvent) => {
    if (getIframeAuthStatus() === IFRAME_REFRESHED_CLOSE_AUTH) {
      resetIframeAuthStatus()
      window.close()
    }
  }

  useEffect(() => {
    if (signedIn && getIframeAuthStatus() === IFRAME_IN_PROGRESS) {
      setIframeAuthStatus(IFRAME_COMPLETED)
      // This listener is only added if IFRAME_IN_PROGRESS was set.
      // So once the IFRAME_AUTH_LOCAL_STORAGE_KEY is removed we know the iframe has completed reloading and we can remove this window
      window.addEventListener("storage", closeWindowUsedForOmniAuth, true)
    }
  }, [signedIn])
}
