import { gql, useQuery } from "@apollo/client"
import { useEffect, useState } from "react"

import TabPanelContent from "./TabPanelContent"
import { useGlobalState } from "../../common/GlobalState"

import { Pricing_ExistingSubscriptionQuery } from "@/types/graphql-types"

export const STANDARD_BUSINESS_TAB_INDEX = 0
export const SMALL_BUSINESS_TAB_INDEX = 1

interface Props {
  upsellText?: boolean
}

const PricingTabs = ({ upsellText }: Props) => {
  const [isAnnualBilling, setIsAnnualBilling] = useState(true)
  // Temp: Fixed pricing
  const [tabIndex, setTabIndex] = useState(1)
  const [user] = useGlobalState("user")

  const switchTab = (index: number) => {
    document
      .querySelector('ul[role="tablist"]')
      ?.scrollIntoView({ behavior: "smooth" })
    setTabIndex(index)
  }

  const { data, refetch } = useQuery<Pricing_ExistingSubscriptionQuery>(
    EXISTING_SUBSCRIPTION_QUERY,
  )
  const isEnrolledInBusiness = !!data?.organization
  const organizationPlanSku = data?.organization?.subscription?.planSku || null
  const userPlanSku = data?.me?.subscription?.planSku || null

  useEffect(() => {
    refetch()
  }, [user])

  return (
    <TabPanelContent
      isAnnualBilling={isAnnualBilling}
      setIsAnnualBilling={setIsAnnualBilling}
      isSmallBusinessSection={false}
      switchTab={switchTab}
      isEnrolledInBusiness={isEnrolledInBusiness}
      organizationPlanSku={organizationPlanSku}
      userPlanSku={userPlanSku}
      upsellText={upsellText}
    />
  )
}

const EXISTING_SUBSCRIPTION_QUERY = gql`
  query Pricing_ExistingSubscription {
    organization {
      id
      subscription {
        id
        planSku
      }
    }
    me {
      id
      subscription {
        id
        planSku
      }
    }
  }
`

export default PricingTabs
