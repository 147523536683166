export const PENDING_GIFT_STATUSES = ["sending", "pending"] as const
export const FINISHED_GIFT_STATUSES = [
  "sent",
  "created",
  "opened",
  "accepted",
  "shipped",
  "delivered",
  "self-send",
  "failed",
  "pending_user_approval",
] as const
export const ALL_GIFT_STATUSES = [
  ...PENDING_GIFT_STATUSES,
  ...FINISHED_GIFT_STATUSES,
]

export type GiftStatus = (typeof ALL_GIFT_STATUSES)[number]

export type GiftStatusWithExpired = GiftStatus | "expired"

export type GiftStatusWithApproval =
  | GiftStatusWithExpired
  | "approved"
  | "rejected"
