import { gql, useMutation, useQuery } from "@apollo/client"
import { Drawer } from "@mui/material"
import pluralize from "pluralize"
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form"
import toast from "react-hot-toast"
import tw, { styled } from "twin.macro"

import { ReactComponent as RightArrowIcon } from "../../assets/icons/arrow-right.svg"
import { ReactComponent as XIcon } from "../../assets/icons/x-close.svg"
import Button from "../../common/Button"
import WorkspaceInviteAccessDropdown from "../../common/components/WorkspaceInviteAccessDropdown"
import Input from "../../common/Input"
import LearnAboutFullVsLimitedModalButton from "../../common/members/LearnAboutFullVsLimitedModalButton"
import { generateUUID } from "../../common/utilities"
import { isIndividual } from "../../common/workspaces"

import {
  OrganizationMembershipAccess,
  WorkspaceInviteCreateInput,
} from "@/types/graphql-types"
import {
  InviteMembersDrawerGetOrganizationWorkspacesQuery,
  InviteMembersDrawerWorkspaceInviteCreateMutation,
  InviteMembersDrawerWorkspaceInviteCreateMutationVariables,
} from "@/types/graphql-types"

interface Props {
  open: boolean
  setOpen: (state: boolean) => void
}

interface WorkspaceInviteFormValues {
  workspaceId: string
  workspaceInvites: WorkspaceInviteCreateInput[]
  access: OrganizationMembershipAccess
}

type InviteMembersWorkspaces = NonNullable<
  InviteMembersDrawerGetOrganizationWorkspacesQuery["organization"]
>["workspaces"]

const InviteMembersDrawer = ({ open, setOpen }: Props) => {
  const defaultWorkspaceInviteValue = Object.freeze({ email: "" })
  const defaultValues = Object.freeze({
    workspaceInvites: Array(3).fill(defaultWorkspaceInviteValue),
    access: OrganizationMembershipAccess.full,
  })
  const { data } =
    useQuery<InviteMembersDrawerGetOrganizationWorkspacesQuery>(GET_WORKSPACES)
  const [workspaceInvitesCreate] = useMutation<
    InviteMembersDrawerWorkspaceInviteCreateMutation,
    InviteMembersDrawerWorkspaceInviteCreateMutationVariables
  >(WORKSPACE_INVITE_CREATE)

  const {
    control,
    register,
    setError,
    setValue,
    clearErrors,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<WorkspaceInviteFormValues>({ defaultValues })

  const getSelectedWorkspaceId = (
    workspaces: InviteMembersWorkspaces | undefined,
  ) => {
    const notIndividualWorkspaces = workspaces?.filter(
      (workspace) => !isIndividual(workspace.ttype),
    )
    return notIndividualWorkspaces ? notIndividualWorkspaces[0].id : null
  }

  const workspaces = data?.organization?.workspaces
  const workspaceId = getSelectedWorkspaceId(workspaces)

  if (workspaceId) {
    setValue("workspaceId", workspaceId)
  }

  const { fields, append } = useFieldArray({
    control,
    name: "workspaceInvites",
  })

  const onSubmit: SubmitHandler<WorkspaceInviteFormValues> = async ({
    workspaceId,
    workspaceInvites,
    access,
  }) => {
    const res = await workspaceInvitesCreate({
      variables: { workspaceId: workspaceId, workspaceInvites, access },
      refetchQueries: [GET_WORKSPACES],
    })

    const resData = res.data?.workspaceInviteCreate
    const invitedCount = resData?.invitedCount

    if (invitedCount && invitedCount > 0) {
      toast.success(
        `${invitedCount} ${pluralize("invites", invitedCount)} sent.`,
      )
    }

    if (resData?.ok) {
      if (resData.errors && resData.errors.length > 0) {
        // Replace the array inputs with the errored inputs.
        reset({
          access: access,
          workspaceId: workspaceId,
          workspaceInvites: resData.errors.map((error) => {
            return {
              email: error.email || "",
            }
          }),
        })

        // Clear the existing errors, then set new errors by index.
        // We use the email as the 'single error field' for the row.
        for (let i = 0; i < resData.errors.length; i++) {
          const err = resData.errors[i]
          if (err.error) {
            setError(
              `workspaceInvites.${i}.email` as "workspaceInvites.0.email",
              {
                type: "manual",
                message: err.error,
              },
            )
          }
        }
      } else {
        closeDrawer()
      }
    } else {
      alert(
        "An unknown error occurred. Please contact us at support@ongoody.com",
      )
    }
  }

  const add5Rows = () => {
    append(Array(5).fill(defaultWorkspaceInviteValue), { shouldFocus: false })
  }

  const closeDrawer = () => {
    clearErrors()
    reset(defaultValues)
    setOpen(false)
  }

  return (
    <Drawer anchor="right" open={open} onClose={closeDrawer}>
      <DrawerContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <h2 tw="text-2xl mb-10 font-medium px-6 sm:px-14">Invite members</h2>
          <button
            tw="absolute top-14 right-14 text-gray-500"
            onClick={closeDrawer}
          >
            <XIcon />
          </button>

          <div tw="mb-10">
            <Subheader>
              <SubheaderNumber>1</SubheaderNumber>
              Add member emails
            </Subheader>
            <div tw="px-6 sm:px-14">
              {fields.map((field, index) => (
                <div tw="mb-3 py-1 lg:py-0" key={field.id}>
                  <div tw="grid grid-cols-1 gap-3 mb-1">
                    <EmailInput
                      hasError={Boolean(
                        errors?.workspaceInvites?.[index]?.email?.message,
                      )}
                      {...register(
                        `workspaceInvites.${index}.email` as "workspaceInvites.0.email",
                      )}
                      placeholder="Work email"
                      type="text"
                      // Disable autocomplete, at least the first time...
                      autoComplete={generateUUID()}
                      className="data-hj-suppress ph-no-capture fs-exclude"
                    />
                  </div>
                  {errors?.workspaceInvites?.[index]?.email?.message && (
                    <div tw="text-red-500 py-1 px-3 rounded-lg mb-1">
                      {errors.workspaceInvites[index].email?.message}
                    </div>
                  )}
                </div>
              ))}
              <button
                onClick={() => add5Rows()}
                tw="py-1 text-gray-500"
                type="button"
              >
                Add 5 rows
              </button>
            </div>
          </div>

          <div tw="mb-10">
            <Subheader>
              <SubheaderNumber>2</SubheaderNumber>
              Select workspace
            </Subheader>
            <div tw="px-6 sm:px-14">
              <div tw="rounded-xl border">
                {workspaces?.map((workspace) => (
                  <label
                    tw="cursor-pointer flex px-4 py-3 border-b last:border-b-0"
                    htmlFor={`select-workspace-${workspace.id}`}
                  >
                    <input
                      id={`select-workspace-${workspace.id}`}
                      type="radio"
                      value={workspace.id}
                      tw="mr-2"
                      {...register("workspaceId")}
                    />
                    {workspace.name}
                  </label>
                ))}
              </div>
            </div>
          </div>
          <div tw="px-6 sm:px-14">
            {data?.organization?.subscription?.isTeamPlan && (
              <>
                <WorkspaceInviteAccessDropdown {...register("access")} />
                <LearnAboutFullVsLimitedModalButton tw="mt-2" />
              </>
            )}
            <Button tw="mt-12" variant="primary">
              Send Invites
              <RightArrowIcon tw="ml-1" />
            </Button>
          </div>
        </form>
      </DrawerContent>
    </Drawer>
  )
}

const Subheader = styled.h4`
  ${tw`whitespace-nowrap text-primary-500 text-lg font-medium flex items-center mb-5`}

  &:before {
    ${tw`border-b border-primary-200 content-[""] w-[5rem]`}
  }

  &:after {
    ${tw`border-b border-primary-200 w-full ml-4 content-[""]`}
  }
`

const SubheaderNumber = styled.span`
  ${tw`text-white bg-primary-500 w-8 h-8 mx-4 inline-flex justify-center items-center flex-shrink-0`}

  border-radius: 50%;
`

const DrawerContent = styled.div`
  ${tw`py-14`}
  width: 34rem;
  max-width: 100vw;
`

const EmailInput = styled(Input)<{ hasError?: boolean }>(
  ({ hasError = false }) => [
    tw`max-w-sm`,
    hasError && tw`border border-red-500`,
  ],
)

const GET_WORKSPACES = gql`
  query InviteMembersDrawerGetOrganizationWorkspaces {
    organization {
      workspaces {
        id
        name
        ttype
      }
      subscription {
        isTeamPlan
      }
    }
  }
`

const WORKSPACE_INVITE_CREATE = gql`
  mutation InviteMembersDrawerWorkspaceInviteCreate(
    $workspaceId: ID!
    $workspaceInvites: [WorkspaceInviteCreateInput!]!
    $access: OrganizationMembershipAccess!
  ) {
    workspaceInviteCreate(
      workspaceId: $workspaceId
      workspaceInvites: $workspaceInvites
      access: $access
    ) {
      ok
      errors {
        email
        error
      }
      invitedCount
    }
  }
`

export default InviteMembersDrawer
