import React from "react"
import nl2br from "react-nl2br"
import tw, { styled } from "twin.macro"

import CartContents from "./CartContents"
import { SendStepContainer } from "./common"
import { ReactComponent as ArrowRight } from "../../../assets/icons/arrow-left.svg"
import { useGlobalState } from "../../../common/GlobalState"
import { useGiftData } from "../../../common/hooks/giftData"

import { BatchSendMethod } from "@/types/graphql-types"

interface Props {
  onBack: () => void
}

export default function PaymentHeader({ onBack }: Props) {
  const [consumerRecipient] = useGlobalState("consumerRecipient")
  const { currentGift } = useGiftData()

  const isDirectSend = currentGift.sendMethod === BatchSendMethod.direct_send
  const directSendNotifsDisabled =
    isDirectSend && currentGift.landingPageSendNotifs === false

  const editGiftButton = (
    <button
      tw="h-10 px-5 flex flex-row items-center justify-center border border-gray-150 rounded-full bg-white shadow-min hover:border-gray-250 active:bg-gray-050 text-gray-600 transition-colors"
      onClick={onBack}
    >
      Edit gift
    </button>
  )

  return (
    <div>
      <div tw="flex flex-row items-center gap-4">
        <button
          tw="text-[#c299a7] p-1.5 rounded-full bg-[#f4ebed] hover:bg-[#f1e4e7] active:bg-[#eddee1] transition-colors"
          onClick={onBack}
        >
          <ArrowRight tw="stroke-current" />
        </button>
        <div tw="text-2xl font-medium">
          Your gift for {consumerRecipient.name}
        </div>
      </div>
      <div tw="pt-5">
        <SendStepContainer tw="flex flex-col md:flex-row md:items-center overflow-visible">
          <div tw="flex-1 p-6 md:p-9 pointer-events-none">
            <CartContents cart={currentGift.cart} smallSize={true} />
          </div>
          {directSendNotifsDisabled ? (
            <div tw="pr-6">{editGiftButton}</div>
          ) : (
            <>
              <div tw="self-stretch border-b border-gray-100 md:border-b-0 md:border-r" />
              <div tw="flex-1 flex flex-row items-center p-6 md:p-9 gap-4 md:gap-3">
                <div tw="flex-1 text-gray-600">
                  {nl2br(currentGift.message)}
                </div>
                <div tw="md:-mr-16">
                  {currentGift.card && (
                    <CardImage src={currentGift.card.image} />
                  )}
                </div>
              </div>
            </>
          )}
        </SendStepContainer>
        {!directSendNotifsDisabled && (
          <div tw="w-full flex flex-row justify-center h-10 -mt-5">
            {editGiftButton}
          </div>
        )}
      </div>
    </div>
  )
}

const CardImage = styled.img`
  ${tw`rounded-xl w-[101px] h-[144px]`}
  filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.04))
    drop-shadow(0px 6px 20px rgba(0, 0, 0, 0.04));
`
