import { useMutation } from "@apollo/client"
import * as Sentry from "@sentry/react"
import React from "react"
import tw, { styled } from "twin.macro"

import AutogiftSelectGiftsDrawer from "./AutogiftSelectGiftsDrawer"
import {
  AutogiftConfigSection,
  AutogiftConfigSectionContent,
  AutogiftConfigSectionHeaderDescription,
  AutogiftConfigSectionHeaderTitle,
} from "./common"
import { ReactComponent as GiftIcon } from "../../assets/icons/autogift-section-gift.svg"
import { ReactComponent as PlusIcon } from "../../assets/icons/plus-purple.svg"
import { ReactComponent as XIcon } from "../../assets/icons/x-gray.svg"
import Button from "../../common/Button"
import { useGlobalState } from "../../common/GlobalState"
import { useAddBalanceModal } from "../../common/hooks/addBalanceModal"
import { AddBalanceModal } from "../../send/AddBalanceModal"
import { ProductImage } from "../../store/ProductImage"
import { GET_AUTOGIFT_PRICE_ESTIMATE_MUTATION } from "../graphql/getPriceEstimateMutation"
import { processProductDisplayPrice } from "../helpers/autogiftPriceCalculation"

import {
  AutogiftRuleProduct,
  AutogiftRuleSelectGifts_PriceEstimateMutation,
  AutogiftRuleSelectGifts_PriceEstimateMutationVariables,
  AutogiftRule_ProductsQuery,
} from "@/types/graphql-types"

interface AutogiftSelectGiftsProps {
  products: NonNullable<AutogiftRule_ProductsQuery["products"]>
  selectGiftsDrawerOpen: boolean
  setSelectGiftsDrawerOpen: (value: React.SetStateAction<boolean>) => void
}

const AutogiftSelectGifts = ({
  products,
  selectGiftsDrawerOpen,
  setSelectGiftsDrawerOpen,
}: AutogiftSelectGiftsProps) => {
  const [currentAutogiftRule, setCurrentAutogiftRule] = useGlobalState(
    "currentAutogiftRule",
  )
  const { addBalanceModalOpen, closeAddBalanceModal, defaultBalance } =
    useAddBalanceModal()

  const [priceEstimateMutation] = useMutation<
    AutogiftRuleSelectGifts_PriceEstimateMutation,
    AutogiftRuleSelectGifts_PriceEstimateMutationVariables
  >(GET_AUTOGIFT_PRICE_ESTIMATE_MUTATION)

  const removeGift = (productIdToRemove: string) => {
    let updatedProductList: AutogiftRuleProduct[] = []

    // loop through product list and all product ids except the product id matching the one to remove
    currentAutogiftRule.productList.map((autogiftProduct) => {
      if (autogiftProduct.productId !== productIdToRemove) {
        updatedProductList.push({
          ...autogiftProduct,
        })
      }
    })

    setCurrentAutogiftRule({
      ...currentAutogiftRule,
      productList: updatedProductList,
    })
  }

  const getPriceEstimate = async (
    productID: string,
    flexGiftPrice: number | null,
    giftCardAmount: number | null,
  ) => {
    const estimate = await priceEstimateMutation({
      variables: {
        productID: productID,
        flexGiftPrice: flexGiftPrice,
        giftCardAmount: giftCardAmount,
      },
    })

    if (estimate.data?.priceEstimate && estimate.data.priceEstimate.ok) {
      return estimate.data?.priceEstimate
    } else {
      alert(
        "An error occurred when generating a price estimate for the selected product.",
      )
      Sentry.captureException(
        new Error(
          `Error generating autogift product price estimate: ${estimate?.data?.priceEstimate?.error}`,
        ),
      )
      return null
    }
  }

  // Save product data to global autogift rule product list
  const saveAutogiftRuleProduct = async (
    productId: string,
    flexGiftPrice: number | null,
    giftCardAmount: number | null,
  ) => {
    const priceEstimateMutationResult = await getPriceEstimate(
      productId,
      flexGiftPrice,
      giftCardAmount,
    )

    if (
      priceEstimateMutationResult &&
      priceEstimateMutationResult.priceProduct !== null &&
      priceEstimateMutationResult.priceProduct !== undefined &&
      priceEstimateMutationResult.priceShipping !== null &&
      priceEstimateMutationResult.priceShipping !== undefined &&
      priceEstimateMutationResult.priceProcessingFee !== null &&
      priceEstimateMutationResult.priceProcessingFee !== undefined &&
      priceEstimateMutationResult.priceEstTaxLow !== null &&
      priceEstimateMutationResult.priceEstTaxLow !== undefined &&
      priceEstimateMutationResult.priceEstTaxHigh !== null &&
      priceEstimateMutationResult.priceEstTaxHigh !== undefined &&
      priceEstimateMutationResult.priceEstTotalLow !== null &&
      priceEstimateMutationResult.priceEstTotalLow !== undefined &&
      priceEstimateMutationResult.priceEstTotalHigh !== null &&
      priceEstimateMutationResult.priceEstTotalHigh !== undefined &&
      priceEstimateMutationResult.isFlexGift !== null &&
      priceEstimateMutationResult.isFlexGift !== undefined
    ) {
      const priceEstimate = {
        priceProduct: priceEstimateMutationResult.priceProduct,
        priceShipping: priceEstimateMutationResult.priceShipping,
        priceProcessingFee: priceEstimateMutationResult.priceProcessingFee,
        priceEstTaxLow: priceEstimateMutationResult.priceEstTaxLow,
        priceEstTaxHigh: priceEstimateMutationResult.priceEstTaxHigh,
        priceEstTotalLow: priceEstimateMutationResult.priceEstTotalLow,
        priceEstTotalHigh: priceEstimateMutationResult.priceEstTotalHigh,
        isFlexGift: priceEstimateMutationResult.isFlexGift,
      }

      const autogiftRuleProduct: AutogiftRuleProduct = {
        productId: productId,
        priceEstimate: priceEstimate,
      }

      setCurrentAutogiftRule((prev) => ({
        ...prev,
        productList: updateProductList(autogiftRuleProduct),
      }))
    } else {
      alert("Price estimate failed.  Cannot add product.")
    }
  }

  const updateProductList = (newProduct: AutogiftRuleProduct) => {
    const newProductList = currentAutogiftRule.productList.filter(
      (product) => product.productId !== newProduct.productId,
    )
    newProductList.push(newProduct)

    return newProductList
  }

  return (
    <div>
      <AutogiftConfigSection>
        <AutogiftConfigSectionHeaderTitle>
          <GiftIcon />
          Gift
        </AutogiftConfigSectionHeaderTitle>
        <AutogiftConfigSectionHeaderDescription>
          A gift will be randomly selected from this list. Add one or more gifts
          that you would like to give. Adding multiple gifts allows for backups
          in case one goes out of stock during the year.
        </AutogiftConfigSectionHeaderDescription>
        <AutogiftConfigSectionContent tw="p-6">
          <Button
            tw="w-full"
            onClick={() => {
              setSelectGiftsDrawerOpen(true)
            }}
          >
            <AddGiftButtonContainer>
              <div tw="flex flex-row">
                <div tw="flex justify-center items-center mr-2">
                  <PlusIcon />
                </div>
                <div
                  tw="text-primary-500 leading-5"
                  css={{ letterSpacing: "-0.01em" }}
                >
                  Add gift
                </div>
              </div>
            </AddGiftButtonContainer>
          </Button>
          {products && products.length > 0 && <div tw="h-3" />}
          {products?.filter(Boolean).map((product) => (
            <div tw="mt-3">
              <GiftContainer
                tw="flex flex-row items-center rounded-lg pr-4"
                css={{ backgroundColor: "#F6F7F9" }}
              >
                <div
                  tw="mr-3 overflow-hidden"
                  css={{
                    borderTopLeftRadius: 8,
                    borderBottomLeftRadius: 8,
                  }}
                >
                  {product.primaryImage?.imageThumb ? (
                    <ProductImage
                      alt={product.name}
                      image={product.primaryImage?.imageThumb}
                      scalable={product.imagesScalable}
                      addSpacing={product.isBook}
                      tw="w-14 h-14"
                    />
                  ) : (
                    <img
                      src={product.customStoreImageThumb?.url}
                      tw="object-cover w-14 h-14"
                    />
                  )}
                </div>
                <div tw="py-2">
                  <div tw="text-sm text-gray-400" css={{ lineHeight: "130%" }}>
                    {product.brandName}
                  </div>
                  <div tw="text-gray-700" css={{ lineHeight: "130%" }}>
                    {product.name.length > 35
                      ? product.name.substring(0, 35) + "..."
                      : product.name}
                  </div>
                </div>
                <div
                  tw="ml-auto text-sm text-black"
                  css={{ lineHeight: "130%" }}
                >
                  {product.status === "ACTIVE"
                    ? "$" +
                      processProductDisplayPrice(
                        product,
                        currentAutogiftRule.productList,
                      )
                    : "Unavailable"}
                </div>
                <div tw="flex items-center">
                  <RemoveGiftButton
                    onClick={() => {
                      removeGift(product.id)
                    }}
                  />
                </div>
              </GiftContainer>
            </div>
          ))}
        </AutogiftConfigSectionContent>
      </AutogiftConfigSection>
      <AutogiftSelectGiftsDrawer
        open={selectGiftsDrawerOpen}
        setOpen={setSelectGiftsDrawerOpen}
        onSelectProductID={saveAutogiftRuleProduct}
      />
      <AddBalanceModal
        isOpen={addBalanceModalOpen}
        close={closeAddBalanceModal}
        defaultBalance={defaultBalance}
      />
    </div>
  )
}

interface RemoveGiftBaseButtonProps {
  onClick: React.MouseEventHandler
  className?: string
}
const RemoveGiftBaseButton = ({
  onClick,
  className,
}: RemoveGiftBaseButtonProps) => (
  <button className={className} type="button" onClick={onClick}>
    <XIcon width={12} height={12} />
  </button>
)

const RemoveGiftButton = styled(RemoveGiftBaseButton)`
  ${tw`rounded hover:bg-gray-200 active:bg-gray-300 z-10 p-1`}
`

const GiftContainer = styled.div`
  &:hover ${RemoveGiftButton} {
    ${tw`opacity-100 scale-100 w-5 ml-2`}
  }

  ${RemoveGiftButton} {
    ${tw`opacity-0 transition-all duration-75 transform scale-0 w-0`}
  }
`

const AddGiftButtonContainer = styled.div`
  ${tw`w-full flex justify-center items-center rounded-lg py-1`};
`

export default AutogiftSelectGifts
