import React from "react"
import { NavLink, Redirect } from "react-router-dom"
import tw, { styled } from "twin.macro"

import AutomationAPI from "./AutomationAPI"
import Budgeting from "./budgeting/Budgeting"
import CommerceAPI from "./CommerceAPI"
import CommerceApp from "./CommerceApp"
import Integrations from "./Integrations"
import Members from "./Members"
import StoredValue from "./StoredValue"
import Subscription from "./Subscription"
import Workspaces from "./Workspaces"
import { ReactComponent as BuildingIcon } from "../assets/icons/building.svg"
import { useGlobalState } from "../common/GlobalState"
import { generateRealmPath } from "../common/realm"
import RealmRoute from "../common/RealmRoute"
import Container from "../sites/App/Container"
import { SubscriptionCancel } from "../subscriptions"

import Receipts from "@/organization/Receipts"
import Settings from "@/organization/Settings"
import { SamlSettingsProvider } from "@/organization/settings/saml/hooks/samlSettings"

const Organization: React.FC = () => {
  const [user] = useGlobalState("user")

  if (!user) {
    return <Redirect to={generateRealmPath("business", "/signin")} />
  }

  if (!user.isOrganizationAdmin) {
    return <Redirect to={generateRealmPath("plus", "/account")} />
  }

  return (
    <div tw="border-t border-primary-100">
      <Container tw="px-5">
        <div tw="pt-10">
          <div tw="flex flex-row items-center">
            <BuildingIcon style={{ width: 28, height: 28 }} />
            <div tw="pl-3 text-2xl font-medium">Manage Organization</div>
          </div>
        </div>
      </Container>
      <div tw="pt-4">
        <WorkspaceNavWrapper>
          <WorkspaceNav>
            <WorkspaceNavStartSpacer />
            <WorkspaceNavItem
              title="Subscription"
              to={generateRealmPath("plus", `/organization/subscription`)}
              exact
            >
              Subscription
            </WorkspaceNavItem>
            <WorkspaceNavItem
              title="Members"
              to={generateRealmPath("plus", `/organization/members`)}
              exact
            >
              Members
            </WorkspaceNavItem>
            <WorkspaceNavItem
              title="Integrations"
              to={generateRealmPath("plus", `/organization/integrations`)}
            >
              Integrations
            </WorkspaceNavItem>
            <WorkspaceNavItem
              title="Budgeting"
              to={generateRealmPath("plus", `/organization/budgeting`)}
            >
              Budgeting
            </WorkspaceNavItem>
            <WorkspaceNavItem
              title="Workspaces"
              to={generateRealmPath("plus", `/organization/workspaces`)}
            >
              Workspaces
            </WorkspaceNavItem>
            <WorkspaceNavItem
              title="Receipts"
              to={generateRealmPath("plus", `/organization/receipts`)}
            >
              Receipts
            </WorkspaceNavItem>
            <WorkspaceNavItem
              title="Automation API"
              to={generateRealmPath("plus", `/organization/api/automation`)}
            >
              Automation API
            </WorkspaceNavItem>
            <WorkspaceNavItem
              title="Commerce API"
              to={generateRealmPath("plus", `/organization/api/commerce`)}
            >
              Commerce API
            </WorkspaceNavItem>
            <WorkspaceNavItem
              title="Settings"
              to={generateRealmPath("plus", `/organization/settings`)}
            >
              Settings
            </WorkspaceNavItem>
            <WorkspaceNavEndSpacer />
          </WorkspaceNav>
        </WorkspaceNavWrapper>
      </div>
      <RealmRoute path="/organization" exact realm="plus">
        <Redirect
          to={generateRealmPath("plus", "/organization/subscription")}
        />
      </RealmRoute>
      <RealmRoute path="/organization/subscription" realm="plus">
        <Subscription />
      </RealmRoute>
      <RealmRoute path="/organization/subscription-cancel" realm="plus">
        <SubscriptionCancel />
      </RealmRoute>
      <RealmRoute path="/organization/members" realm="plus">
        <Members />
      </RealmRoute>

      <RealmRoute path="/organization/integrations" realm="plus">
        <Integrations />
      </RealmRoute>
      <RealmRoute path="/organization/budgeting" realm="plus">
        <Budgeting />
      </RealmRoute>
      <RealmRoute path="/organization/workspaces" realm="plus">
        <Workspaces />
      </RealmRoute>
      <RealmRoute path="/organization/receipts" realm="plus">
        <Receipts />
      </RealmRoute>
      <RealmRoute path="/organization/api/automation" realm="plus">
        <AutomationAPI />
      </RealmRoute>
      <RealmRoute path="/organization/api/commerce" realm="plus" exact>
        <CommerceAPI />
      </RealmRoute>
      <RealmRoute path="/organization/api/commerce/:id" realm="plus">
        <CommerceApp />
      </RealmRoute>
      <RealmRoute path="/organization/stored-value" realm="plus">
        <StoredValue />
      </RealmRoute>
      <RealmRoute path="/organization/settings" realm="plus">
        <SamlSettingsProvider>
          <Settings />
        </SamlSettingsProvider>
      </RealmRoute>
    </div>
  )
}

const WorkspaceNavWrapper = tw.div`whitespace-nowrap w-full overflow-y-auto h-16 lg:h-auto lg:overflow-y-visible z-10 border-b flex flex-col justify-end`
const WorkspaceNav = tw.nav`container mx-auto flex flex-row items-end whitespace-nowrap`
const WorkspaceNavStartSpacer = tw.div`pl-2 lg:pl-1`
const WorkspaceNavEndSpacer = tw.div`pr-2 lg:pr-8 flex-1`
const WorkspaceNavItem = styled(NavLink)`
  ${tw`relative inline-block p-4 pb-2 text-center text-gray-500 transition text-lg hover:text-primary-500 mb-0.5`};

  /**
    Reserves container space for bolded version of text. We need to
    pass in the link text to the "title" attribute.
   */
  &::before {
    ${tw`invisible block h-0 font-medium`};
    content: attr(title);
  }

  &::after {
    ${tw`absolute inline-block bg-gray-200 rounded-full opacity-0 left-4 right-4 transition`};
    bottom: -2px;
    content: "";
    height: 3px;
  }

  &:hover {
    &::after {
      ${tw`opacity-100 bg-primary-200`};
    }
  }

  &.active {
    ${tw`font-medium text-primary-500`};

    &::after {
      ${tw`opacity-100 bg-primary-500`};
    }
  }
`

export default Organization
