import * as Sentry from "@sentry/react"
import { compact, pick } from "lodash-es"
import { useCallback, useMemo } from "react"

import { useGiftCart } from "@/common/hooks/giftData"
import { findShippingGroupIntersection } from "@/common/utils/findShippingGroupIntersection"
import {
  GLOBAL_COUNTRY,
  useShippingCountries,
} from "@/store/providers/ShippingCountriesProvider"
import {
  GiftSwapTypeEnum,
  InternationalShippingTierEnum,
  ShippingCountryGroupEnum,
} from "@/types/graphql-types"

export const useGiftShippingCountries = () => {
  const { currentGift, shippingClasses, hasFlexGiftItem } = useGiftCart()
  const { shippingCountries, expandShippingCountries } = useShippingCountries()

  const tier = currentGift.internationalShippingTier
  const swapType = currentGift.swapType

  const domesticCountryCodes = useMemo(() => {
    return shippingCountries
      .filter((country) =>
        country.groups.includes(ShippingCountryGroupEnum.domestic),
      )
      .map((country) => country.code)
  }, [shippingCountries])

  const fullGlobalCountryCodes = useMemo(() => {
    return shippingCountries
      .filter((country) =>
        country.groups.includes(ShippingCountryGroupEnum.global),
      )
      .map((country) => country.code)
  }, [shippingCountries])

  const shippingGroup = useMemo(() => {
    try {
      const shippingCountryGroups = currentGift.cart.map(
        (product) => product.shippingCountryGroup,
      )
      return findShippingGroupIntersection(shippingCountryGroups)
    } catch (error) {
      // This is failing when the user already had a product added to the bag
      // that was saved before the new shipping country groups were added.
      Sentry.captureException(error)
      window.localStorage?.removeItem("__goody_send_autosave")
      return {}
    }
  }, [currentGift.cart])

  const shipsToCountries = useMemo(() => {
    if (hasFlexGiftItem) {
      if (tier === InternationalShippingTierEnum.standard) {
        return domesticCountryCodes
      }

      if (tier === InternationalShippingTierEnum.full) {
        return [...domesticCountryCodes, GLOBAL_COUNTRY.code]
      }
    }

    const shipToIntersection = pick(shippingGroup, shippingClasses)

    if (shipToIntersection?.globalRelay?.length > 0) {
      shipToIntersection.globalRelay = [GLOBAL_COUNTRY.code]
    }

    return Object.values(shipToIntersection).flat().sort().reverse()
  }, [
    shippingGroup,
    shippingClasses,
    domesticCountryCodes,
    hasFlexGiftItem,
    tier,
  ])

  const cartIsGloballyEligible = shippingGroup?.globalRelay?.length > 0

  const swapsToCountries = useMemo(() => {
    if (swapType === GiftSwapTypeEnum.swap_disabled || hasFlexGiftItem) {
      return []
    }

    const swapsToDomesticCountries = domesticCountryCodes.filter(
      (code) => !shipsToCountries.includes(code),
    )

    if (tier === InternationalShippingTierEnum.standard) {
      return swapsToDomesticCountries
    }

    if (
      tier === InternationalShippingTierEnum.full &&
      !cartIsGloballyEligible
    ) {
      return [...swapsToDomesticCountries, GLOBAL_COUNTRY.code]
    }

    return []
  }, [
    tier,
    swapType,
    cartIsGloballyEligible,
    shipsToCountries,
    domesticCountryCodes,
    hasFlexGiftItem,
  ])

  const shipsToGlobalCountries = useMemo(
    () =>
      (shippingGroup?.globalRelay ?? []).filter(
        (code) =>
          code !== GLOBAL_COUNTRY.code && !shipsToCountries.includes(code),
      ),
    [shippingGroup, shipsToCountries],
  )
  const swapsToGlobalCountries = useMemo(() => {
    return fullGlobalCountryCodes.filter(
      (code) =>
        code !== GLOBAL_COUNTRY.code && !shipsToGlobalCountries.includes(code),
    )
  }, [shipsToGlobalCountries, fullGlobalCountryCodes])

  const parseCountriesToSummaryText = useCallback(
    (countryCodes: string[]) => {
      return expandShippingCountries(countryCodes)
        .map((country) => (country.code === "US" ? "US" : country.name))
        .join(", ")
    },
    [expandShippingCountries],
  )

  const shippingSummaryText = useMemo(() => {
    switch (tier) {
      case InternationalShippingTierEnum.disabled:
        return "Disabled"
      case InternationalShippingTierEnum.gift_cards:
        return "International gift cards only"
      case InternationalShippingTierEnum.global_relay:
        return "Full global catalog"
      case InternationalShippingTierEnum.standard:
        return compact([
          parseCountriesToSummaryText(shipsToCountries),
          parseCountriesToSummaryText(swapsToCountries),
        ]).join(" + swap options to ")
      case InternationalShippingTierEnum.full:
        return "Global"
      default:
        return null
    }
  }, [tier, shipsToCountries, swapsToCountries, parseCountriesToSummaryText])

  return {
    shipsToCountries,
    swapsToCountries,
    shippingSummaryText,
    cartIsGloballyEligible,
    shipsToGlobalCountries,
    swapsToGlobalCountries,
  }
}
