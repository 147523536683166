import { gql, useMutation } from "@apollo/client"
import React, { useState } from "react"
import tw, { styled } from "twin.macro"

import { ArrowDownPink, Checkout } from "../../assets/icons"
import CheckoutBox from "../../common/components/CheckoutBox"
import GradientButton from "../../common/GradientButton"
import { useLocalStatePayment } from "../../common/hooks/payment"
import { imageForCardBrand } from "../../common/payment"
import PaymentMethodsModal from "../../send/PaymentMethodsModal"

import {
  GiftCheckout_GiftPayMutation,
  GiftCheckout_GiftPayMutationVariables,
} from "@/types/graphql-types"

interface Props {
  checkoutKey: string
  onManualCheckoutComplete: () => void
  onSavedPaymentCheckoutComplete: () => void
}

const CheckoutContainer: React.FC<Props> = ({
  checkoutKey,
  onManualCheckoutComplete,
  onSavedPaymentCheckoutComplete,
}) => {
  // Whether to show the checkout iframe.
  const [showCheckout, setShowCheckout] = useState(false)

  // Whether to show the checkout iframe using autoconfirm=true.
  //
  // This is used if we attempt to pay via card and the card requires 3DSecure.
  // As a result, this will trigger showing the checkout iframe with the URL
  // appended with &autoconfirm=true so the user can go through the 3DSecure
  // authorization process.
  //
  // Both this and showCheckout trigger showing the checkout. This is because if
  // we set setShowCheckout(true) separately, there might be timing issues that
  // might cause checkout to be shown before autoconfirm is set.
  const [showCheckoutWithAutoconfirm, setShowCheckoutWithAutoconfirm] =
    useState(false)

  const [paymentCardsModalOpen, setPaymentCardsModalOpen] = useState(false)

  const {
    selectedPaymentMethod,
    refreshPaymentMethods,
    setSelectedPaymentMethod,
    selectablePaymentMethods,
  } = useLocalStatePayment()

  const [giftPay, { loading: giftPayLoading }] = useMutation<
    GiftCheckout_GiftPayMutation,
    GiftCheckout_GiftPayMutationVariables
  >(GIFT_PAY)

  // Pay for a gift with a payment method.
  const payWithCard = async () => {
    if (!selectedPaymentMethod) {
      window.alert("A payment method is required.")
      return
    }

    const res = await giftPay({
      variables: {
        checkoutKey: checkoutKey,
        paymentMethodID: selectedPaymentMethod.id,
      },
    })

    if (res?.data?.giftPay) {
      if (res.data.giftPay.complete) {
        onSavedPaymentCheckoutComplete()
        refreshPaymentMethods()
      } else if (res.data.giftPay.requiresAction) {
        // We need to perform some card verification.
        // Show the browser and autoconfirm.
        setShowCheckoutWithAutoconfirm(true)
      } else {
        window.alert(
          "Unable to process payment. Please tap 'Pay with new card' above to complete checkout.",
        )
      }
    } else {
      window.alert(
        "Unable to process payment. Please contact us at support@ongoody.com. We're sorry for the inconvenience.",
      )
    }
  }

  return (
    <NextStepsContainer>
      <DownButton>
        <ArrowDownPink />
      </DownButton>
      <div tw={"text-xl text-gray-600 font-semibold mt-6"}>Next steps</div>
      <div tw="mt-3 text-xl text-center text-gray-600">
        You can complete checkout
        <br />
        and we’ll get your gift shipping.
      </div>
      {showCheckout || showCheckoutWithAutoconfirm ? (
        <CheckoutBox
          checkoutKey={checkoutKey}
          onCheckoutComplete={async () => {
            onManualCheckoutComplete()
            refreshPaymentMethods()
          }}
          autoconfirm={showCheckoutWithAutoconfirm}
        />
      ) : (
        <>
          {(selectablePaymentMethods?.length ?? 0) > 0 &&
          selectedPaymentMethod ? (
            <div tw="pt-6 flex flex-col items-center">
              <div>
                <button
                  tw="inline-flex items-center px-2 py-1 hover:bg-gray-150 active:bg-gray-200 transition-colors rounded-lg"
                  onClick={() => setPaymentCardsModalOpen(true)}
                >
                  {selectedPaymentMethod ? (
                    <>
                      {imageForCardBrand(selectedPaymentMethod.cardBrand)}
                      <div
                        tw="pl-2"
                        className="data-hj-suppress ph-no-capture fs-exclude"
                      >
                        {selectedPaymentMethod.last4}
                      </div>
                    </>
                  ) : (
                    <div>Select a payment method</div>
                  )}
                </button>
              </div>
              <CheckoutButton
                onClick={() => payWithCard()}
                tw="mt-4"
                disabled={giftPayLoading}
              >
                <Checkout />
                Pay with card
              </CheckoutButton>
              <div tw="mt-8">
                <button
                  tw="text-gray-600 hover:text-gray-800 transition-colors"
                  onClick={() => setShowCheckout(true)}
                >
                  Or use a different card
                </button>
              </div>
            </div>
          ) : (
            <CheckoutButton onClick={() => setShowCheckout(true)} tw="mt-8">
              <Checkout />
              Checkout
            </CheckoutButton>
          )}
        </>
      )}

      {selectedPaymentMethod && (
        <PaymentMethodsModal
          isOpen={paymentCardsModalOpen}
          autopayPaymentMethod={selectedPaymentMethod}
          close={() => setPaymentCardsModalOpen(false)}
          setPaymentMethod={setSelectedPaymentMethod}
          title="Select payment card"
        />
      )}
    </NextStepsContainer>
  )
}

const NextStepsContainer = styled.div`
  margin-top: -1px;
  ${tw`rounded-b-xl mx-auto flex flex-col items-center justify-center pb-6 bg-white border`};
  @media (min-width: 640px) {
    max-width: 480px;
  }
  border-color: #eceef1;
  box-shadow:
    0px 1px 4px rgba(125, 64, 200, 0.04),
    0px 6px 20px rgba(125, 64, 200, 0.06);
`

const CheckoutButton = styled(GradientButton)`
  ${tw`justify-center items-center flex text-xl px-9 hover:opacity-80 active:opacity-90 transition-opacity disabled:opacity-50 disabled:cursor-default`}

  height: 49px;

  & > svg {
    ${tw`mr-3`}
  }
`

const DownButton = styled.div`
  margin-top: -1.25rem;
  ${tw`h-10 w-10 bg-white rounded-full flex items-center justify-center`}
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.06), 0px 6px 20px rgba(0, 0, 0, 0.06);
`

const GIFT_PAY = gql`
  mutation GiftCheckout_GiftPay($checkoutKey: String!, $paymentMethodID: ID!) {
    giftPay(checkoutKey: $checkoutKey, paymentMethodId: $paymentMethodID) {
      complete
      requiresAction
    }
  }
`

export default CheckoutContainer
