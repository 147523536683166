import tw from "twin.macro"

import { ItemCountForm } from "./ItemCountForm"

import { useCustomStore } from "@/store/custom/hooks"
import { MaxPriceForm } from "@/store/custom/MultipleGiftSelection/MaxPriceForm"
import { MultipleSelectError } from "@/store/custom/MultipleGiftSelection/MultipleSelectError"
import { MultipleSelectModeEnum } from "@/types/graphql-types"

export const MultipleGiftSelection = () => {
  const { multipleSelectMode } = useCustomStore()

  return (
    <Container>
      <MultipleSelectError />
      {multipleSelectMode === MultipleSelectModeEnum.max_price ? (
        <MaxPriceForm />
      ) : (
        <ItemCountForm />
      )}
    </Container>
  )
}

const Container = tw.div`flex flex-col w-full items-center`
