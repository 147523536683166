import React from "react"

import {
  SubNav,
  SubNavButton,
  SubNavStartSpacer,
  SubNavWrapper,
} from "../../../common/components/SubNav"

export type MemberDetailTab = "overview" | "transactions"

interface Props {
  tab: MemberDetailTab
  setTab: (page: MemberDetailTab) => void
}

export default function MemberDetailNav({ tab, setTab }: Props) {
  return (
    <SubNavWrapper>
      <SubNav tw="md:justify-center">
        <SubNavStartSpacer />
        <SubNavButton
          title="Overview"
          className={tab === "overview" ? "active" : ""}
          onClick={() => setTab("overview")}
        >
          Overview
        </SubNavButton>
        <SubNavButton
          title="Transaction Log"
          className={tab === "transactions" ? "active" : ""}
          onClick={() => setTab("transactions")}
        >
          Transaction Log
        </SubNavButton>
      </SubNav>
    </SubNavWrapper>
  )
}
