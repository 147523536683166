import React from "react"

export default function Terms() {
  return (
    <div tw={"text-gray-500 mt-11 mx-auto px-8"} css={"max-width: 564px"}>
      By sending this gift, you agree to Goody’s{" "}
      <a
        href="https://www.ongoody.com/terms"
        target="_blank"
        rel="noopener noreferrer"
        tw="underline"
      >
        Terms of Use
      </a>{" "}
      and{" "}
      <a
        href="https://www.ongoody.com/privacy"
        target="_blank"
        rel="noopener noreferrer"
        tw="underline"
      >
        Privacy Policy
      </a>{" "}
      and agree to receive text messages with account or gift updates. Your
      phone number is shared with your gift recipient.
      <br />
      <br />
      Gifts only ship to the 50 U.S. states and D.C. Additional state
      restrictions may apply. Your recipient may opt to swap their gift for one
      of equal or lesser value.
    </div>
  )
}
