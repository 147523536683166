import "twin.macro"
import { useEffect } from "react"

import {
  DenominationButton,
  DenominationField,
  suggestedDenominations,
} from "./DenominationUI"

import { foreignCentsToUSDCents } from "@/common/forex"
import { formatPrice, formatPriceInternational } from "@/common/format"
import GiftCardProBanner from "@/store/GiftOption/GiftCardProBanner"
import { Store_PDP_ProductDetailsFragment } from "@/types/graphql-types"

interface Props {
  product: Store_PDP_ProductDetailsFragment
  isGiftCardInCustomStore: boolean
  denomination: number | null
  setDenomination: (denomination: number | null) => void
  denominationValid: boolean
  setDenominationValid: (isValid: boolean) => void
}

export default function GiftCardDenominationSelector({
  product,
  isGiftCardInCustomStore,
  denomination: selectedDenomination,
  setDenomination,
  denominationValid,
  setDenominationValid,
}: Props) {
  const {
    denominations,
    isSlidingScale,
    valueRestrictions,
    currency,
    currencyRate,
  } = product.giftCardInfo!
  const min = valueRestrictions?.min
  const max = valueRestrictions?.max

  const visibleDenominations = isSlidingScale
    ? suggestedDenominations(min!, max!)
    : denominations

  useEffect(() => {
    if (selectedDenomination === null) {
      const denominationToSet =
        visibleDenominations[2] ||
        visibleDenominations[1] ||
        visibleDenominations[0]
      if (denominationToSet) {
        setDenomination(denominationToSet)
        setDenominationValid(true)
      }
    }
  }, [])

  return (
    <div>
      {!isGiftCardInCustomStore && (
        <>
          <GiftCardProBanner variant="detail" />
          <ul tw="grid grid-cols-2 gap-3">
            {visibleDenominations.map((denomination) => (
              <li key={denomination}>
                <DenominationButton
                  selected={selectedDenomination === denomination}
                  onClick={() => {
                    setDenomination(denomination)
                    setDenominationValid(true)
                  }}
                  large={currency !== "USD"}
                >
                  <div>
                    {formatPriceInternational({
                      nativeCurrency: currency,
                      nativeAmountInCents: denomination * 100,
                    })}
                  </div>
                  {currency !== "USD" && (
                    <div tw="text-sm">
                      {formatPrice(
                        foreignCentsToUSDCents(
                          denomination * 100,
                          currencyRate,
                        ),
                      )}
                    </div>
                  )}
                </DenominationButton>
              </li>
            ))}
          </ul>
          {isSlidingScale && (
            <div tw="pt-4">
              <DenominationField
                min={min}
                max={max}
                setDenomination={setDenomination}
                setDenominationValid={setDenominationValid}
                selectedDenomination={selectedDenomination}
                currency={currency}
                currencyRate={currencyRate}
              />
              {valueRestrictions != null && (
                <small tw="block text-center text-base text-gray-400">
                  {formatPriceInternational({
                    nativeAmountInCents: Math.max(min!, 1) * 100,
                    nativeCurrency: currency,
                  })}
                  &nbsp;–&nbsp;
                  {formatPriceInternational({
                    nativeAmountInCents: max! * 100,
                    nativeCurrency: currency,
                  })}
                </small>
              )}
            </div>
          )}
        </>
      )}
      {!denominationValid && (
        <div tw="bg-red-100 text-red-700 text-sm p-2 rounded-lg mt-4 text-center">
          Please enter a valid amount.
        </div>
      )}
    </div>
  )
}
