import tw, { styled } from "twin.macro"

interface SelectionIndicatorProps {
  selected: boolean
  grayUnselected?: boolean
  color?: "default" | "primaryNew" | "blue" | "orange"
}

export const SelectionIndicator = styled.div<SelectionIndicatorProps>`
  ${tw`h-5 w-5 border border-gray-400 inline-block relative rounded-full mr-3 my-1.5 transition-colors flex-grow-0 flex-shrink-0`};

  flex-basis: 1.25rem;

  ${(props) =>
    props.selected &&
    tw`bg-gradient-to-r from-primary-600 to-primary-500 border-primary-500`}

  ${(props) =>
    props.color === "primaryNew" &&
    tw`from-primary-new-600 to-primary-new-500 border-primary-new-500`}
  
  ${(props) => props.color === "blue" && tw`border-blue-200`}
  ${(props) => props.color === "orange" && tw`border-[#f2d88d]`}
  
  ${(props) =>
    props.color === "blue" &&
    props.selected &&
    tw`from-blue-600 to-blue-500 border-blue-500`}
  
  ${(props) =>
    props.color === "orange" &&
    props.selected &&
    tw`from-[#cd6408] to-[#dd6c09] border-[#cd6408]`}
  
  ${(props) => props.grayUnselected && !props.selected && tw`border-gray-250`}

  &:after {
    background: #ffffff;
    bottom: 5px;
    border-radius: 4px;
    content: "";
    display: inline-block;
    left: 5px;
    height: 8px;
    position: absolute;
    width: 8px;
    transition:
      opacity 0.3s ease-out,
      transform 0.2s ease-out;
    opacity: ${(props) => (props.selected ? 1 : 0)};
    transform: scale(${(props) => (props.selected ? 1 : 0)});
  }
`

interface SelectionLabelProps {
  selected: boolean
  selectedMediumWeight?: boolean
  color?: "default" | "blue" | "orange"
}

export const SelectionLabel = styled.span<SelectionLabelProps>`
  ${tw`text-gray-600`};
  bottom: 4.5px;

  ${(props) => props.selected && tw`text-primary-600`};
  ${(props) => props.color === "blue" && props.selected && tw`text-blue-500`};
  ${(props) =>
    props.color === "orange" && props.selected && tw`text-orange-alt-500`};
  ${(props) => props.selectedMediumWeight && props.selected && tw`font-medium`};

  span {
    ${tw`font-normal block md:inline-block text-gray-500 md:ml-3`}

    &:before {
      ${tw`font-medium hidden md:inline mr-3`}

      content: '·';
    }
  }
`

export const SelectionGroup = styled.div<{
  disabled?: boolean
  unclickable?: boolean
}>`
  ${tw`inline-flex cursor-pointer flex-row items-center py-1 md:py-0`};

  ${({ disabled }) => (disabled ? tw`opacity-40` : null)};
  ${({ unclickable }) => (unclickable ? tw`pointer-events-none` : null)};

  &:hover {
    ${SelectionIndicator} {
      ${tw`border-primary-500 bg-primary-050`};
    }
  }

  &:active {
    ${SelectionIndicator} {
      ${tw`border-primary-500 bg-primary-200`};
    }
  }
`

interface SelectionProps {
  option: string
  isSelected: boolean
  onSelect: () => void
  disabled: boolean
}
export const Selection = ({
  option,
  isSelected,
  onSelect,
  disabled,
}: SelectionProps) => {
  return (
    <SelectionGroup onClick={onSelect} disabled={disabled}>
      <SelectionIndicator selected={isSelected} />
      <SelectionLabel selected={isSelected}>{option}</SelectionLabel>
    </SelectionGroup>
  )
}
