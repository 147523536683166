import { useGlobalState } from "../GlobalState"

export default () => {
  const [user] = useGlobalState("user")
  const [organization] = useGlobalState("organization")

  return (
    !user?.isFullOrganizationMember && organization?.subscription?.isTeamPlan
  )
}
