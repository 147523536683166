import { formatPrice } from "@/common/format"
import { useCustomStore } from "@/store/custom/hooks"
import {
  Alert,
  AlertColor,
} from "@/track/components/giftBatch/GiftBatchSettings/Alert"
import { MultipleSelectModeEnum } from "@/types/graphql-types"

export const CustomStoreWarning = () => {
  const {
    multipleSelectMode,
    productOverMaximumPrice,
    multipleSelectAttributeHasChanged,
  } = useCustomStore()

  const displayProductOverMaximumPrice =
    multipleSelectMode &&
    multipleSelectMode === MultipleSelectModeEnum.max_price &&
    !!productOverMaximumPrice

  const shouldDisplayAlert =
    displayProductOverMaximumPrice || multipleSelectAttributeHasChanged

  const getPrimaryMessage = () => {
    if (displayProductOverMaximumPrice) {
      return "You have an option that exceeds your maximum price"
    }

    if (multipleSelectAttributeHasChanged) {
      return "Updates to multiple gift settings only apply to new gifts"
    }

    return ""
  }

  const getSecondaryMessage = () => {
    if (displayProductOverMaximumPrice) {
      const formattedPrice = formatPrice(
        productOverMaximumPrice.priceWithShipping,
      )

      const productName = productOverMaximumPrice.name

      return (
        <>
          <div tw="mb-2">
            {productName} is {formattedPrice}, which exceeds the maximum you
            provided for multiple gift selection. It will not be shown in the
            gift list.
          </div>
          <div>
            To fix this, increase your maximum price to at least{" "}
            {formattedPrice}.
          </div>
        </>
      )
    }

    if (multipleSelectAttributeHasChanged) {
      return (
        "Updating multiple gift settings only affects new gifts sent with this custom store, " +
        "not past gifts already sent."
      )
    }

    return undefined
  }

  return (
    <div tw="mt-8">
      {shouldDisplayAlert && (
        <Alert
          color={AlertColor.yellow}
          primaryMessage={getPrimaryMessage()}
          secondaryMessage={getSecondaryMessage()}
        />
      )}
    </div>
  )
}
