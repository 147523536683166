import { ReactComponent as NavArrow } from "../../assets/icons/nav-arrow-left.svg"
import { getMonthNameForNumber } from "../../common/utilities"

interface Props {
  year: number
  month: number
  previousMonth: () => void
  nextMonth: () => void
  canGoPrevious: boolean
  canGoNext: boolean
}

export default function ActivityNav({
  previousMonth,
  nextMonth,
  month,
  year,
  canGoPrevious,
  canGoNext,
}: Props) {
  return (
    <div tw="py-9 pb-6 flex flex-row items-center justify-between max-w-[360px] mx-auto">
      <button
        tw="p-3 transition-opacity disabled:(pointer-events-none opacity-50)"
        onClick={previousMonth}
        className="group"
        disabled={!canGoPrevious}
      >
        <div tw="bg-gray-100 group-hover:bg-gray-150 group-active:bg-gray-200 transition-colors rounded-full">
          <NavArrow />
        </div>
      </button>
      <div tw="text-2xl font-semibold">
        {getMonthNameForNumber(month)} {year}
      </div>
      <button
        tw="p-3 transition-opacity disabled:(pointer-events-none opacity-50)"
        onClick={nextMonth}
        className="group"
        disabled={!canGoNext}
      >
        <div tw="bg-gray-100 group-hover:bg-gray-150 group-active:bg-gray-200 transition-colors rounded-full">
          <NavArrow tw="rotate-180" />
        </div>
      </button>
    </div>
  )
}
