import { gql } from "@apollo/client"

import { TRACK_GIFT_BATCH_FRAGMENT } from "./TrackGiftBatchFragment"

export const TRACK_WORKSPACE_GIFT_BATCH_QUERY = gql`
  query TrackWorkspaceGiftBatch($id: ID!) {
    workspace {
      giftBatch(id: $id) {
        ...Track_GiftBatch
      }
    }
  }
  ${TRACK_GIFT_BATCH_FRAGMENT}
`
