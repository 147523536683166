import tw, { styled } from "twin.macro"

import { gradientBg } from "@/assets/cdnAssets"
import { ReactComponent as SearchIcon } from "@/store/images/search.svg"

interface Props {
  onSubmit: () => void
  searchTerm: string
  setSearchTerm: (searchTerm: string) => void
}

export default function SearchHeader({
  onSubmit,
  searchTerm,
  setSearchTerm,
}: Props) {
  return (
    <>
      <BackgroundContainer />
      <div tw="-mt-8 h-16 flex flex-row items-center justify-center px-5">
        <div
          tw="h-16 w-full max-w-[440px] bg-white rounded-xl flex flex-row items-center gap-5 p-2 pl-5"
          css={{
            boxShadow:
              "0px 1px 4px 0px rgba(0, 0, 0, 0.06), 0px 4px 12px 0px rgba(0, 0, 0, 0.05)",
          }}
        >
          <SearchIcon tw="text-gray-600" />
          <input
            tw="flex-1 h-full text-sm text-gray-600 text-[19px] focus:outline-none"
            placeholder="Search"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault()
                e.stopPropagation()
                e.currentTarget.blur()
                onSubmit()
              }
            }}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            autoFocus
          />
          <button
            tw="h-full px-5 text-white bg-primary-new-600 rounded-lg hover:bg-primary-new-700 active:scale-95 transition-all"
            onClick={onSubmit}
          >
            Search
          </button>
        </div>
      </div>
    </>
  )
}

const BackgroundContainer = styled.div`
  ${tw`h-[84px] bg-no-repeat bg-cover bg-top`};
  background-image: url(${gradientBg});
`
