import { useState } from "react"

const useSet = <T>(): [
  Set<T>,
  {
    add: (element?: T) => void
    remove: (element?: T) => void
    toggle: (element?: T) => void
    clear: () => void
  },
] => {
  const [set, setSet] = useState<Set<T>>(new Set())

  const save = () => setSet(new Set([...set]))

  const add = (element?: T) => {
    if (typeof element == "undefined") return
    set.add(element)
    save()
  }

  const remove = (element?: T) => {
    if (typeof element == "undefined") return
    set.delete(element)
    save()
  }

  const toggle = (element?: T) => {
    if (typeof element == "undefined") return
    if (set.has(element)) {
      remove(element)
    } else {
      add(element)
    }
  }

  const clear = () => {
    setSet(new Set())
  }

  return [set, { add, remove, toggle, clear }]
}

export default useSet
