import gql from "graphql-tag"

import { client } from "./apollo"

import { PaymentMethodsManager_GetPaymentMethodsQuery } from "@/types/graphql-types"

export type PaymentMethod = {
  __typename?: "PaymentMethod"
  id: string
  cardBrand?: string | null
  last4?: string | null
  isDefault?: boolean
  cardholderName?: string | null
  externalLocationsAlcohol?: boolean
  token?: string
  label?: string | null
}

// Duplicated from client.

// Manages state related to the user's payment methods.
// Since fetching payment methods takes a bit of time (roundtrip to server,
// and roundtrip to Stripe from server), it introduces a significant delay when
// payment method info is required, e.g on the CheckoutSheet or determining if
// autopay should be available on StoreCheckoutSend. So, we cache it locally.
export class PaymentMethodsManager {
  static loading = false
  static loadedOnce = false

  static paymentMethods: PaymentMethod[] = []
  static paymentManagerKey: String = ""

  // Fetches and reloads payment methods from the server.
  // Call this when payment methods might have been updated.
  static async fetchPaymentMethods() {
    this.loading = true
    const res =
      await client.query<PaymentMethodsManager_GetPaymentMethodsQuery>({
        query: GET_PAYMENT_METHODS,
      })
    this.loading = false

    if (res?.data?.me?.paymentMethods) {
      this.paymentMethods = res.data.me.paymentMethods
      this.paymentManagerKey = res.data.me.paymentManagerKey
      this.loadedOnce = true
    }
  }

  // Get payment methods from cache.
  // Loads if not loaded yet.
  static async getPaymentMethods() {
    if (!this.loadedOnce) {
      await PaymentMethodsManager.fetchPaymentMethods()
    }

    return this.paymentMethods
  }

  // Get payment methods from cache if it's been loaded.
  static getPaymentMethodsWithoutFetch() {
    return this.paymentMethods
  }
}

const GET_PAYMENT_METHODS = gql`
  query PaymentMethodsManager_GetPaymentMethods {
    me {
      paymentManagerKey
      paymentMethods {
        id
        cardBrand
        last4
        isDefault
        externalLocationsAlcohol
        token
        label
      }
    }
    workspace {
      id
      invoicingEnabled
    }
  }
`
