import { isNil } from "lodash-es"
import React from "react"
import tw from "twin.macro"

import { MousePointerClickIcon } from "./assets/icons"
import { SettingBase } from "../index"

import { formatPrice } from "@/common/format"
import { SelectionGroupColorEnum } from "@/common/SelectionGroup/types"
import LoadingBox from "@/myGifts/LoadingBox"
import { useCustomStore } from "@/store/custom/hooks"
import { MultipleGiftSelection } from "@/store/custom/MultipleGiftSelection"
import XCircleIcon from "@/track/components/giftBatch/GiftBatchSettings/assets/icons/x-circle.svg"
import { MultipleSelectModeEnum } from "@/types/graphql-types"

interface Props {
  isExpandable?: boolean
  color?: SelectionGroupColorEnum
  alignPreviewName?: string
}

export const MultipleGiftSelectionSetting = ({
  isExpandable,
  color,
  alignPreviewName,
}: Props) => {
  const {
    multipleSelectMode,
    multipleSelectCount,
    multipleSelectPrice,
    hasRestrictedProductOrSetting,
  } = useCustomStore()

  const displayPreviewError =
    multipleSelectMode !== MultipleSelectModeEnum.disabled &&
    hasRestrictedProductOrSetting
  const previewText = () => {
    if (multipleSelectMode === MultipleSelectModeEnum.disabled) {
      return "Disabled (single gift)"
    }

    if (
      multipleSelectMode === MultipleSelectModeEnum.max_price &&
      !isNil(multipleSelectPrice)
    ) {
      return `Enabled (up to ${formatPrice(multipleSelectPrice)})`
    }

    if (multipleSelectMode === MultipleSelectModeEnum.item_count) {
      return `Enabled (${multipleSelectCount} gifts)`
    }
  }

  if (
    isNil(multipleSelectMode) ||
    isNil(multipleSelectCount) ||
    isNil(multipleSelectPrice)
  ) {
    return <LoadingBox />
  }

  return (
    <SettingBase
      name="Multiple gift selection"
      icon={<MousePointerClickIcon />}
      preview={
        <div
          tw="flex flex-row items-center gap-3"
          css={[alignPreviewName === "right" && tw`md:justify-end`]}
        >
          {displayPreviewError ? (
            <div tw="flex flex-row">
              <div tw="flex items-center justify-center">
                <img src={XCircleIcon} />
              </div>
              <div tw="text-[#B91C1C] ml-2">Error</div>
            </div>
          ) : (
            previewText()
          )}
        </div>
      }
      color={color}
      isExpandable={isExpandable}
      autoExpand={displayPreviewError}
    >
      <MultipleGiftSelection />
    </SettingBase>
  )
}
