import { useEffect, useState } from "react"

import { getProduct } from "@/common/gifts"
import { createHookContext } from "@/common/hooks/createHookContext"
import { useCurrentGift } from "@/common/hooks/currentGift"
import { usePaymentMethods } from "@/common/hooks/paymentMethods"
import { useBusinessSend } from "@/common/hooks/send"

export const useTopUpUILocal = () => {
  const [currentGift] = useCurrentGift()
  const { paymentMethods } = usePaymentMethods()
  const { priceEstimate } = useBusinessSend()
  const [isValidGiftSend, setIsValidGiftSend] = useState(false)

  const hasCCPaymentMethod = paymentMethods.length > 0
  const balance = priceEstimate?.effectiveBalanceData?.effectiveBalance ?? 0
  const estimatedGiftTotal =
    priceEstimate?.totalPriceEstimate.estGroupTotalHigh ?? 0

  const topUpAmount = (() => {
    return estimatedGiftTotal - balance
  })()

  const hasBalanceDeficit = topUpAmount > 0

  const canDisplayTopUpUI =
    hasCCPaymentMethod && hasBalanceDeficit && isValidGiftSend

  useEffect(() => {
    setIsValidGiftSend(
      !getProduct(currentGift)?.isAlcohol &&
        !currentGift.isGiftDispenser &&
        !currentGift.isSmartLink,
    )
  }, [currentGift.cart, currentGift.isGiftDispenser, currentGift.isSmartLink])

  return {
    canDisplayTopUpUI,
    topUpAmount,
    balance,
    estimatedGiftTotal,
  }
}

export const {
  Provider: TopUpUIProvider,
  useHook: useTopUpUI,
  useHookUnsafe: useTopUpUIUnsafe,
} = createHookContext("TopUpUI", useTopUpUILocal)
