import { useQuery } from "@apollo/client"
import React, { useState } from "react"

import { useCurrentWorkspace } from "../../common/hooks/workspaces"
import Pagination from "../../common/pagination/Pagination"
import { GradientHeader, GradientHeaderTextMain } from "../../common/UI"
import {
  GIFT_BATCHES_QUERY,
  GiftBatchBox,
  GiftBatches,
  perPage,
} from "../Index"

import {
  Track_GiftBatchesQuery,
  Track_GiftBatchesQueryVariables,
} from "@/types/graphql-types"

const IndividualBatches: React.FC = () => {
  const { currentWorkspace } = useCurrentWorkspace()
  const workspaceId = currentWorkspace?.id

  const [pageIndex, setPageIndex] = useState(0)

  const { data, loading, refetch } = useQuery<
    Track_GiftBatchesQuery,
    Track_GiftBatchesQueryVariables
  >(GIFT_BATCHES_QUERY, {
    fetchPolicy: "network-only",
    context: { workspaceId }, // Makes sure the query is run whenever the workspace changes.
    variables: {
      page: pageIndex + 1,
      perPage: perPage,
    },
  })

  const giftBatches = data?.workspace?.giftBatches || []
  const giftBatchesCount = data?.workspace?.giftBatchesCount || 0

  return (
    <>
      <GradientHeader tw="pb-20">
        <GradientHeaderTextMain>Gifts Sent</GradientHeaderTextMain>
      </GradientHeader>
      <GiftBatchBox>
        <GiftBatches
          loading={!data && loading}
          giftBatches={giftBatches}
          showUser={false}
          refetch={refetch}
        />
      </GiftBatchBox>
      <Pagination
        {...{
          setPageIndex,
          perPage,
          totalCount: giftBatchesCount,
          pageIndex,
        }}
      />
    </>
  )
}

export default IndividualBatches
