import { useMemo } from "react"

import { UtmParams } from "@/types/graphql-types"

const UTM_PARAMS = [
  "utm_source",
  "utm_medium",
  "utm_campaign",
  "utm_keyword",
  "utm_content",
] as const

const UTM_PREFIX = "__goody__"

// Prefix the key so we don't get clashes
const getParamKey = (param: string) => UTM_PREFIX + param

const toCamelCase = (param: string) =>
  param.replace(/(_\w)/g, (char) => char[1].toUpperCase())

export const persistUtmParams = (queryParams: URLSearchParams) => {
  if (!window.localStorage) {
    return
  }

  // List of params in the querystring, so we can remove later
  const paramList: string[] = []

  UTM_PARAMS.forEach((param) => {
    const value = queryParams.get(param)
    if (value) {
      const key = getParamKey(param)
      // We only use first touch, so if it exists we don't change it
      if (!window.localStorage.getItem(key)) {
        window.localStorage.setItem(key, value)
      }
      paramList.push(param)
    }
  })

  return paramList
}

export const useQueryParams = () => {
  return useMemo(
    () => new URLSearchParams(window.location.search ?? {}),
    [window.location.search],
  )
}

// Pull utm params from local API and convert to correct format
export const getUtmParms = () => {
  const params: any = {}
  UTM_PARAMS.forEach((param) => {
    const key = getParamKey(param)
    const value = window.localStorage.getItem(key)
    if (value) {
      params[toCamelCase(param)] = value
    }
  })
  return Object.values(params).length > 0 ? (params as UtmParams) : null
}

export const gtmPush = (params: any) => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(params)
}

export const gtmEvent = (event: string, params?: any) => {
  gtmPush({
    event,
    ...params,
  })
}
