import React, { ReactNode } from "react"
import { Link } from "react-router-dom"
import "twin.macro"

type variant = "red" | "pink" | "blue"

type Props = {
  className: string
  text: ReactNode
  to: string
  variant: variant
}

const HolidayGiftGuideButton = ({ className, text, to, variant }: Props) => {
  switch (variant) {
    case "red":
      return (
        <Link
          to={to}
          className={className}
          tw="active:(bg-[#801A1A] border-[#801A1A]) hover:(bg-[#B55365]) border-2 border-white rounded-3xl py-2 px-6 font-semibold bg-[#9D2322] text-white text-center"
        >
          {text}
        </Link>
      )
    case "blue":
      return (
        <Link
          to={to}
          className={className}
          tw="active:(bg-[#366B9C] border-[#366B9C]) hover:(bg-[#447FB5]) border-2 border-white rounded-3xl py-2 px-6 font-semibold bg-[#82A0BC] text-white text-center"
        >
          {text}
        </Link>
      )
    case "pink":
      return (
        <Link
          to={to}
          className={className}
          tw="active:(bg-[#9D2322] border-[#9D2322]) hover:(bg-[#ECBBC4]) border-2 border-white rounded-3xl py-2 px-6 font-semibold bg-[#F8E1E1] text-center"
        >
          {text}
        </Link>
      )
  }

  return null
}

export default HolidayGiftGuideButton
