import { gql, useMutation } from "@apollo/client"
import React from "react"
import tw from "twin.macro"

import {
  AUTOGIFTS_QUERY,
  STANDARD_AUTOGIFT_RULE_FRAGMENT,
} from "./AutogiftIndex"
import { useAutogiftData } from "./hooks"
import { useGlobalState } from "../../common/GlobalState"
import { successToast } from "../../common/toast"

import { AutogiftRuleStatus } from "@/types/graphql-types"
import {
  AutogiftRule_AutogiftRulePauseMutation,
  AutogiftRule_AutogiftRulePauseMutationVariables,
} from "@/types/graphql-types"

interface Props {
  closeAutogiftRuleConfigScreen: () => void
}

const AutogiftStatus: React.FC<Props> = ({ closeAutogiftRuleConfigScreen }) => {
  const [currentAutogiftRule] = useGlobalState("currentAutogiftRule")

  const { loadAutogiftRuleData } = useAutogiftData({})

  const [autogiftRulePause] = useMutation<
    AutogiftRule_AutogiftRulePauseMutation,
    AutogiftRule_AutogiftRulePauseMutationVariables
  >(PAUSE_AUTOGIFT_RULE_MUTATION)

  const pauseAutogift = async () => {
    if (currentAutogiftRule.id) {
      if (
        !window.confirm(
          "Are you sure you want to pause this autogift? No new gifts will be created, but existing gifts (created 30 days out) will still remain.",
        )
      ) {
        return
      }

      const pauseMutationResult = await autogiftRulePause({
        variables: {
          autogiftRuleId: currentAutogiftRule.id,
        },
        refetchQueries: [
          {
            query: AUTOGIFTS_QUERY,
            variables: {
              contactListId: currentAutogiftRule.contactListId,
            },
          },
        ],
      })

      // Check that the mutation completed successfully before proceeding
      if (!pauseMutationResult?.data?.autogiftRulePause) {
        alert("An error occurred during deactivation.")
        return
      }

      // Mutation completed, so continue with processing
      const res = pauseMutationResult?.data?.autogiftRulePause

      if (res && res.ok) {
        successToast("Paused autogift.")

        // HACK: This scrolls all mounted MuiPapers
        const papers = document.querySelectorAll(".MuiPaper-root")
        for (let i = 0; i < papers.length; i++) {
          papers[i].scrollTo({
            top: 0,
            behavior: "smooth",
          })
        }

        // Restore the returned autogift rule so we have the latest data
        if (res.autogiftRule) {
          loadAutogiftRuleData(res.autogiftRule)
        }
      } else {
        if (res.errors && res.errors.length > 0) {
          alert(res.errors.join("\n"))
        }
      }
    }
  }

  // Don't render this for newly created autogifts.
  if (!currentAutogiftRule.id) {
    return null
  }

  const isActive = currentAutogiftRule.status === AutogiftRuleStatus.ACTIVE

  return (
    <div tw="mb-4 flex flex-row items-center gap-4">
      <div
        css={[
          tw`gap-3 px-5 py-2 rounded-full flex flex-row items-center`,
          isActive
            ? tw`text-green-600 bg-green-100`
            : tw`text-red-600 bg-red-100`,
        ]}
      >
        <div
          css={[
            tw`w-2 h-2 rounded-full`,
            isActive ? tw`bg-green-600` : tw`bg-red-600`,
          ]}
        />
        {isActive ? "Active" : "Paused"}
      </div>
      {isActive ? (
        <button
          tw="text-gray-500 hover:text-gray-700 transition-colors"
          onClick={pauseAutogift}
        >
          Pause
        </button>
      ) : (
        <div tw="text-gray-500">
          You can review your settings and activate below.
        </div>
      )}
    </div>
  )
}

const PAUSE_AUTOGIFT_RULE_MUTATION = gql`
  mutation AutogiftRule_AutogiftRulePause($autogiftRuleId: ID!) {
    autogiftRulePause(autogiftRuleId: $autogiftRuleId) {
      ok
      errors
      id
      autogiftRule {
        ...StandardAutogiftRule
      }
    }
  }
  ${STANDARD_AUTOGIFT_RULE_FRAGMENT}
`

export default AutogiftStatus
