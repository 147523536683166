import { gql, useQuery } from "@apollo/client"
import { saveAs } from "file-saver"
import Papa from "papaparse"
import tw, { styled } from "twin.macro"

import Button from "../common/Button"
import {
  formatPrice,
  getDateAsCurrentTimeZone,
  isBlank,
} from "../common/format"

import { StoredValueTransactionTtype } from "@/types/graphql-types"
import {
  Organization_StoredValueQuery,
  Organization_StoredValueQueryVariables,
  StoredValueTransaction,
} from "@/types/graphql-types"

interface Props {
  month: string
}

export default function StoredValueTable({ month }: Props) {
  const { data, loading } = useQuery<
    Organization_StoredValueQuery,
    Organization_StoredValueQueryVariables
  >(STORED_VALUE_QUERY, {
    variables: {
      dateStart: month,
      dateEnd: lastDayOfMonth(month),
    },
  })

  const items = data?.organization?.storedValueTransactions?.items || []

  const downloadCSV = () => {
    const csv = getCSV(items)
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8" })
    saveAs(blob, `stored_value_transactions_${month}.csv`)
  }

  return (
    <>
      <div tw="flex flex-row items-start justify-between">
        <h1 tw="font-medium text-3xl pl-2">
          {getDateAsCurrentTimeZone(month).toLocaleString("default", {
            month: "long",
            year: "numeric",
          })}
        </h1>
        <Button onClick={downloadCSV}>Download CSV</Button>
      </div>
      <div tw="pt-8" />
      {loading ? (
        <div tw="text-gray-400 pl-2">Loading...</div>
      ) : (
        <TransactionTable tw="w-full">
          <thead>
            <tr>
              <th tw="text-left py-2 pl-2" style={{ width: "25%" }}>
                Date/Type
              </th>
              <th tw="text-left" style={{ width: "36%" }}>
                Description/Gift
              </th>
              <th tw="text-left" style={{ width: "15%" }}>
                Details
              </th>
              <th tw="text-right pr-2" style={{ width: "12%" }}>
                Difference
              </th>
              <th tw="text-right pr-2" style={{ width: "12%" }}>
                Resulting Total
              </th>
            </tr>
          </thead>
          <tbody>
            {items.map((item) => (
              <tr tw="border-t border-gray-100 py-2 hover:bg-gray-100 transition-colors">
                <TDCell tw="pl-2">
                  <div>{etDate(item.createdAt)} ET</div>
                  <div>
                    {item.ttype === StoredValueTransactionTtype.MANUAL
                      ? "SYSTEM"
                      : item.ttype}
                  </div>
                </TDCell>
                <TDCell>
                  {!isBlank(item.description) && (
                    <div tw="pb-2">{item.description}</div>
                  )}
                  {item.gift && (
                    <>
                      <div tw="pb-2">
                        <div tw="text-sm text-gray-500">Gift Batch ID</div>
                        {item.gift.giftBatchId}
                      </div>
                      <div>
                        <div tw="text-sm text-gray-500">Gift ID</div>
                        {item.gift.id}
                      </div>
                      {item.gift.senderName && (
                        <div tw="pt-2">
                          <div tw="text-sm text-gray-500">Sender</div>
                          {item.gift.senderName}
                        </div>
                      )}
                      <div tw="pt-2">
                        <div tw="text-sm text-gray-500">Cart</div>
                        {item.gift.cartSummary.map((summaryItem) => (
                          <div>{summaryItem}</div>
                        ))}
                      </div>
                    </>
                  )}
                </TDCell>
                <TDCell>
                  {item.gift && (
                    <div tw="pr-2">
                      <table tw="text-sm w-full">
                        <tr>
                          <td>Product</td>
                          <TDRightAlign>
                            {formatPrice(item.gift.amountProduct, true)}
                          </TDRightAlign>
                        </tr>
                        <tr>
                          <td>Shipping</td>
                          <TDRightAlign>
                            {formatPrice(item.gift.amountShipping, true)}
                          </TDRightAlign>
                        </tr>
                        <tr>
                          <td>Processing Fee</td>
                          <TDRightAlign>
                            {formatPrice(item.gift.amountProcessingFee, true)}
                          </TDRightAlign>
                        </tr>
                        {item.gift.amountCreditApplied > 0 ? (
                          <tr>
                            <td>Credit Applied</td>
                            <TDRightAlign>
                              {formatPrice(item.gift.amountCreditApplied, true)}
                            </TDRightAlign>
                          </tr>
                        ) : null}
                        {item.gift.amountTax ? (
                          <tr>
                            <td>Tax</td>
                            <TDRightAlign>
                              {formatPrice(item.gift.amountTax, true)}
                            </TDRightAlign>
                          </tr>
                        ) : null}
                        {item.gift.amountTotal ? (
                          <tr>
                            <td>Total</td>
                            <TDRightAlign>
                              {formatPrice(item.gift.amountTotal, true)}
                            </TDRightAlign>
                          </tr>
                        ) : null}
                      </table>
                    </div>
                  )}
                </TDCell>
                <TDCellRight css={[item.amountDiff > 0 && tw`text-green-600`]}>
                  {formatPrice(item.amountDiff, true)}
                </TDCellRight>
                <TDCellRight>{formatPrice(item.amountAfter, true)}</TDCellRight>
              </tr>
            ))}
          </tbody>
        </TransactionTable>
      )}
    </>
  )
}

const TransactionTable = styled.table`
  th {
    font-weight: 500;
  }
`
const TDCell = tw.td`py-2`
const TDRightAlign = tw.td`text-right`
const TDCellRight = tw.td`py-2 pr-2 text-right tabular-nums`

const STORED_VALUE_QUERY = gql`
  query Organization_StoredValue(
    $dateStart: ISO8601Date!
    $dateEnd: ISO8601Date!
  ) {
    organization {
      id
      storedValueTransactions(
        showAll: true
        dateStart: $dateStart
        dateEnd: $dateEnd
      ) {
        items {
          id
          ttype
          amountDiff
          amountBefore
          amountAfter
          description
          createdAt
          gift {
            id
            giftBatchId
            giftBatchName
            senderName
            amountProduct
            amountShipping
            amountProcessingFee
            amountCreditApplied
            amountTax
            amountTotal
            recipientFirstName
            recipientLastName
            cartSummary
          }
        }
        totalCount
      }
    }
  }
`

// Given an ISO date like 2021-01-01, return the last day of the month
function lastDayOfMonth(date: string) {
  const [year, month] = date.split("-")
  return new Date(Date.UTC(parseInt(year), parseInt(month), 0))
    .toISOString()
    .split("T")[0]
}

function getCSV(items: StoredValueTransaction[]) {
  return Papa.unparse({
    fields: [
      "ID",
      "Date",
      "Transaction Type",
      "Amount Diff",
      "Amount Before",
      "Amount After",
      "Description",
      "Gift ID",
      "Gift Batch ID",
      "Gift Batch Name",
      "Sender Name",
      "Amount Product",
      "Amount Shipping",
      "Amount Processing Fee",
      "Amount Credit Applied",
      "Amount Tax",
      "Amount Total",
      "Recipient First Name",
      "Recipient Last Name",
      "Cart Summary",
    ],
    data: items.map((i) => [
      i.id,
      etDate(i.createdAt),
      i.ttype,
      dollars(i.amountDiff),
      dollars(i.amountBefore),
      dollars(i.amountAfter),
      i.description,
      i.gift?.id,
      i.gift?.giftBatchId,
      i.gift?.giftBatchName,
      i.gift?.senderName,
      dollars(i.gift?.amountProduct),
      dollars(i.gift?.amountShipping),
      dollars(i.gift?.amountProcessingFee),
      dollars(i.gift?.amountCreditApplied),
      dollars(i.gift?.amountTax),
      dollars(i.gift?.amountTotal),
      i.gift?.recipientFirstName,
      i.gift?.recipientLastName,
      i.gift?.cartSummary?.join(", "),
    ]),
  })
}

function dollars(cents: number | undefined | null) {
  if (cents === undefined || cents === null) {
    return null
  }

  return (cents / 100).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
}

function etDate(date: Date) {
  return new Date(date).toLocaleString("en-US", {
    timeZone: "America/New_York",
  })
}
