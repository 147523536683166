import React, { useEffect, useState } from "react"
import Modal from "react-modal"
import tw, { styled } from "twin.macro"

import DrawerCloseButton from "../../common/DrawerCloseButton"
import { level2ModalStyle } from "../../common/modal"
import { HubSpot } from "../../common/Script"

interface Props {
  applyModalOpen: boolean
  onClose: () => void
}

export default function ApplyModal({ applyModalOpen, onClose }: Props) {
  const [hubSpotLoaded, setHubSpotLoaded] = useState(false)
  useEffect(() => {
    if (hubSpotLoaded && applyModalOpen) {
      // @ts-ignore
      window.hbspt.forms.create({
        region: "na1",
        portalId: "9308919",
        formId: "a0902847-6c80-4de8-aa02-3440b9879a24",
        target: "#commerce-api-form-container",
      })
    }
  }, [applyModalOpen, hubSpotLoaded])

  return (
    <>
      <HubSpot onLoad={() => setHubSpotLoaded(true)} />
      <Modal
        isOpen={applyModalOpen}
        style={level2ModalStyle}
        closeTimeoutMS={500}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        onRequestClose={onClose}
      >
        <Container className="modal-content-animated">
          <div tw="w-[550px] max-w-[100vw] p-8 min-h-[300px] max-h-[90vh] overflow-auto">
            <div tw="flex flex-row items-start justify-between gap-4">
              <div tw="text-[2rem] font-reckless-neue tracking-[-0.5px] pb-8 font-250 leading-tight">
                Get started with the Goody API
              </div>
              <div tw="">
                <DrawerCloseButton onClick={onClose} />
              </div>
            </div>
            <div id="commerce-api-form-container" />
          </div>
        </Container>
      </Modal>
    </>
  )
}

const Container = styled.div`
  ${tw`rounded-2xl bg-white flex flex-col overflow-hidden`}
  box-shadow: 0px 8px 48px rgba(0, 0, 0, 0.08);
`
