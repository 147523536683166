import { useEffect } from "react"
import { Link } from "react-router-dom"
import tw from "twin.macro"

import Button from "../../common/Button"
import CalendarGiftSettings from "../../common/components/CalendarGiftSettings"
import { useGiftData } from "../../common/hooks/giftData"
import { generateRealmPath } from "../../common/realm"

import {
  CalendarIntegration,
  gift_meeting_setting,
} from "@/types/graphql-types"

const CalendarMeetingOptions = ({
  calendarIntegration,
  defaultGiftSetting,
  sendV3,
}: {
  calendarIntegration?: CalendarIntegration | null
  defaultGiftSetting?: gift_meeting_setting | null
  sendV3?: boolean
}) => {
  const initialGiftSetting = defaultGiftSetting
    ? defaultGiftSetting
    : calendarIntegration?.giftSetting ?? gift_meeting_setting.NO_MEETING

  return (
    <div
      tw="px-6 py-5 border-t border-primary-100"
      css={[sendV3 && tw`px-0 py-0 border-none`]}
    >
      {calendarIntegration?.calendlyApiUrl ? (
        <CalendlyIntegrated
          defaultGiftSetting={initialGiftSetting}
          sendV3={sendV3}
        />
      ) : (
        <CalendlyNotIntegrated />
      )}
    </div>
  )
}

const CalendlyNotIntegrated = () => {
  return (
    <div tw={"flex flex-row"}>
      <div tw={" mr-6 flex items-center text-gray-450 font-semibold text-sm"}>
        More features
      </div>
      <Link to={generateRealmPath("plus", "/account/integrations/calendly")}>
        <Button tw={"border-gray-200 drop-shadow-none"}>
          Add meeting calendar
        </Button>
      </Link>
    </div>
  )
}

const CalendlyIntegrated = ({
  defaultGiftSetting,
  sendV3,
}: {
  defaultGiftSetting: gift_meeting_setting
  sendV3?: boolean
}) => {
  const { currentGift, updateGiftCalendarSetting } = useGiftData()

  useEffect(() => {
    if (!currentGift.giftCalendarSetting) {
      updateGiftCalendarSetting(defaultGiftSetting)
    }
  }, [])

  return (
    <div>
      {!sendV3 && (
        <div tw="font-medium text-gray-600 pb-4">Meeting calendar</div>
      )}
      <CalendarGiftSettings
        setGiftSetting={updateGiftCalendarSetting}
        giftSetting={currentGift.giftCalendarSetting ?? defaultGiftSetting}
      />
    </div>
  )
}

export default CalendarMeetingOptions
