import { gql, useMutation, useQuery } from "@apollo/client"
import { isEmpty } from "lodash-es"
import React, { FormEvent, useState } from "react"
import { toast } from "react-hot-toast"
import { Link } from "react-router-dom"
import { CSSTransition } from "react-transition-group"
import tw, { styled } from "twin.macro"

import ContactListItem from "./ContactListItem"
import { CUSTOMER_INTEGRATION_QUERY } from "./HRISIntegration"
import { ReactComponent as ExpanderIcon } from "./images/expander.svg"
import { ReactComponent as LinkIcon } from "./images/link.svg"
import { ReactComponent as BuildingIcon } from "../../assets/icons/building.svg"
import Button from "../../common/Button"
import { useFeatureAccess } from "../../common/hooks/featureAccess"
import { ReactComponent as PlusSvg } from "../../common/images/plus.svg"
import { generateRealmPath } from "../../common/realm"
import { ContactList } from "../lib"
import { CONTACT_LISTS_QUERY } from "../queries"

import {
  Contacts_ContactListCreateMutation,
  Contacts_ContactListCreateMutationVariables,
  CustomerIntegrationQuery,
} from "@/types/graphql-types"

interface ContactListsProps {
  lists: ContactList[]
  hrisLists: ContactList[]
  currentListId: string | null
  onSelectListId: (list: string | null) => void
}

const ContactsSidebar: React.FC<ContactListsProps> = ({
  currentListId,
  lists,
  hrisLists,
  onSelectListId,
}) => {
  const [contactsListOpen, setContactsListOpen] = useState(false)
  const toggleContactsList = () => setContactsListOpen((isOpen) => !isOpen)

  const [contactListCreate] = useMutation<
    Contacts_ContactListCreateMutation,
    Contacts_ContactListCreateMutationVariables
  >(CONTACT_LIST_CREATE_MUTATION)

  const onCreateList = async (e: FormEvent) => {
    e.preventDefault()

    const newListName = window.prompt("New contact list name:")

    if (newListName === null) {
      return
    }

    const res = await contactListCreate({
      variables: { name: newListName },
      refetchQueries: [{ query: CONTACT_LISTS_QUERY }],
    })

    if (res.data?.contactListCreate.ok) {
      toast.success("Created contact list")
    } else if (res.data?.contactListCreate?.errors) {
      toast.error(res.data.contactListCreate.errors.join(", "))
    }
  }

  const isCurrentList = (list: ContactList) => list.id === currentListId

  return (
    <Sidebar>
      <h2 tw="hidden lg:block font-medium text-2xl pt-7 pb-8 px-6">Contacts</h2>
      <ContactListsTrigger
        currentName={lists.find(isCurrentList)?.name || ""}
        toggleList={toggleContactsList}
      />
      <CSSTransition in={contactsListOpen} timeout={100}>
        <ContactLists>
          {lists.map((list) => {
            return (
              <ContactListItem
                selected={isCurrentList(list)}
                name={list.name}
                contactsCount={list.contactsCount}
                onClick={() => {
                  onSelectListId(list.id)
                }}
                key={list.id}
              />
            )
          })}
          {!isEmpty(hrisLists) && (
            <CompanyDirectoryContactLists>
              <div tw="bg-gray-100 rounded-md box-border py-1.5 px-3 mt-8 flex justify-between">
                <DirectoryHeader>Company Directory</DirectoryHeader>
                <BuildingIcon />
              </div>
              <div tw="flex flex-col py-2">
                {hrisLists.map((list) => {
                  return (
                    <ContactListItem
                      selected={isCurrentList(list)}
                      name={list.name}
                      contactsCount={list.contactsCount}
                      onClick={() => {
                        onSelectListId(list.id)
                      }}
                      key={list.id}
                    />
                  )
                })}
              </div>
            </CompanyDirectoryContactLists>
          )}
          <ConnectHRButton tw="my-4" className="hide-on-lg" />
        </ContactLists>
      </CSSTransition>
      <form tw="hidden lg:flex pt-8 pb-5 items-center" onSubmit={onCreateList}>
        <Button>
          <PlusIcon />
          New contact list
        </Button>
      </form>
      <ConnectHRButton tw="mb-16" className="show-on-lg" />
    </Sidebar>
  )
}

const Sidebar = styled.aside`
  ${tw`h-full lg:w-80 px-5 lg:pl-8 lg:pr-6 bg-white relative overflow-auto`}
  box-shadow: 0px 0 4px rgba(0, 0, 0, 0.05), 0 4px 32px rgba(0, 0, 0, 0.04);

  @media only screen and (min-width: 1024px) {
    clip-path: inset(0 -4rem 0 0);
  }
`

const ContactLists = styled.div`
  ${tw`hidden lg:flex flex-col py-2 lg:py-0 border-t lg:border-0 border-gray-200 transition-all duration-100 ease-out overflow-hidden`}

  &.enter,
  &.exit {
    ${tw`flex opacity-0 max-h-0`}
  }

  &.enter-active,
  &.enter-done {
    ${tw`flex opacity-100 max-h-screen`}
  }
`

const CompanyDirectoryContactLists = styled.div`
  ${tw`lg:flex flex-col transition-all duration-100 ease-out overflow-hidden`}

  &.enter,
  &.exit {
    ${tw`flex opacity-0 max-h-0`}
  }

  &.enter-active,
  &.enter-done {
    ${tw`flex opacity-100 max-h-screen`}
  }
`

const DirectoryHeader = styled.div`
  ${tw`text-sm font-medium`}

  color: #838A97;
`

const PlusIcon = styled(PlusSvg)`
  ${tw`h-5 w-5 text-gray-600`}
`

interface ContactListsTriggerProps {
  currentName: string
  toggleList: () => void
}
const ContactListsTrigger = ({
  currentName,
  toggleList,
}: ContactListsTriggerProps) => {
  return (
    <button
      onClick={toggleList}
      tw="flex lg:hidden text-lg w-full justify-between items-center h-16 gap-2"
    >
      <span tw="truncate">{currentName}</span>
      <div tw="text-sm text-primary-400 flex gap-2 items-center">
        Lists <ExpanderIcon />
      </div>
    </button>
  )
}

interface ConnectHRButtonProps {
  className?: string
}

const ConnectHRButton = ({ className }: ConnectHRButtonProps) => {
  const { hasFeature } = useFeatureAccess()
  const isHRConnected = useCustomerIntegrationQuery()

  if (!hasFeature("plus_hr_integration")) {
    return null
  }

  return (
    <Link to={generateRealmPath("plus", "/organization/hr")}>
      <ConnectHRToggleButton className={className}>
        <LinkIcon />
        {isHRConnected ? "HR Integration" : "Connect HR"}
      </ConnectHRToggleButton>
    </Link>
  )
}

const useCustomerIntegrationQuery = () => {
  const { data } = useQuery<CustomerIntegrationQuery>(
    CUSTOMER_INTEGRATION_QUERY,
  )

  return !!data?.me?.customerIntegration
}

const ConnectHRToggleButton = styled(Button)`
  &.hide-on-lg {
    ${tw`flex lg:hidden`}
  }

  &.show-on-lg {
    ${tw`hidden lg:flex`}
  }
`

const CONTACT_LIST_CREATE_MUTATION = gql`
  mutation Contacts_ContactListCreate($name: String!) {
    contactListCreate(name: $name) {
      ok
      errors
      id
    }
  }
`

export default ContactsSidebar
