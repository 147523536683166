import React from "react"
import tw, { styled } from "twin.macro"

import GradientButtonBg from "../images/gradient-button-bg.webp"

import { useAfterMountDelay } from "@/common/hooks/afterMountDelay"

interface Props {
  icon: React.ReactNode
  name: string
  shortName: string
  selected: boolean
  position: "left" | "right"
  onClick: () => void
}

// Renders a button that has an outer container (which acts as the border) and
// an inner container (which is the actual content of the button). The "border"
// container can either be a solid color (deselected) or a gradient (selected).
export default function SendMethodButton({
  icon,
  name,
  shortName,
  selected,
  position,
  onClick,
}: Props) {
  const animationDone = useAfterMountDelay(position === "left" ? 850 : 1100)

  return (
    <button
      tw="flex-1 text-gray-600 p-px"
      css={[
        selected && tw`text-primary-new-600 bg-cover z-[5] relative`,
        selected && {
          background: "linear-gradient(90deg, #EFD5F3 0%, #BFC5F7 100%)",
        },
        !selected && tw`bg-[#DFE2FB]`,
        position === "left" && tw`rounded-l-xl`,
        position === "right" && tw`rounded-r-xl -ml-px`,
      ]}
      onClick={onClick}
    >
      <ButtonInner
        selected={selected}
        css={[
          position === "left" && tw`rounded-l-[11px]`,
          position === "right" && tw`rounded-r-[11px]`,
        ]}
      >
        {/* The inner content animates in. */}
        <div
          tw="flex flex-row items-center justify-center gap-3"
          className={!animationDone ? "animated fadeInUpMedium" : undefined}
          css={{
            animationDelay: position === "left" ? "0.25s" : "0.5s",
            animationDuration: "0.5s",
            animationTimingFunction: "ease-out",
          }}
        >
          {icon}
          <div tw="flex flex-col items-center md:text-lg">
            <div css={[selected && tw`font-medium`]}>
              <span tw="hidden sm:inline-block">{name}</span>
              <span tw="sm:hidden">{shortName}</span>
            </div>
            {/* Reserve space for the bold text. */}
            <div tw="font-medium h-0 overflow-hidden">
              <span tw="hidden sm:inline-block">{name}</span>
              <span tw="sm:hidden">{shortName}</span>
            </div>
          </div>
        </div>
      </ButtonInner>
    </button>
  )
}

const ButtonInner = styled.div<{ selected: boolean }>`
  ${tw`px-2 flex flex-row items-center justify-center h-full w-full transition-all relative bg-white overflow-hidden`};

  // All inner buttons contain the gradient background in an after element, but
  // it is only displayed when selected. This allows for an opacity transition.
  &:after {
    ${tw`absolute inset-0 transition-all content-[""] opacity-0`};
    background: url(${GradientButtonBg}) no-repeat center center;
    background-size: cover;
    ${(props) => props.selected && tw`opacity-100`};
  }

  ${(props) => !props.selected && tw`hover:bg-[#FAFBFE] active:bg-[#F6F7FE]`};
`
