export const ShowVariantsIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" stroke="#E4D8F4" />
    <path
      d="M6.5 12L10.5 8L6.5 4"
      stroke="#D0B8ED"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
