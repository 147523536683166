import { ReactElement } from "react"
import tw from "twin.macro"

import { CharityIcon, InfoIcon } from "@/common/settings/assets/icons"

interface Props {
  message?: string | ReactElement
  useMultipleGiftMessage?: boolean
  shouldDisplayAddDonationMessage?: boolean
}

export const SettingInfo = ({
  message,
  useMultipleGiftMessage,
  shouldDisplayAddDonationMessage,
}: Props) => {
  const multipleGiftsMessage =
    "Multiple gifts aren’t supported for custom stores with gift cards, alcohol, or international shipping enabled."

  const primaryMessage = useMultipleGiftMessage ? multipleGiftsMessage : message

  return (
    <Container>
      {shouldDisplayAddDonationMessage && (
        <MessageContainer tw="mb-4">
          <div tw="mr-3">
            <CharityIcon />
          </div>
          <div>
            You can add $1, $5, and higher charity donation options to your
            store so recipients can use leftover dollars.
          </div>
        </MessageContainer>
      )}
      <MessageContainer>
        <div tw="mr-3">
          <InfoIcon />
        </div>
        <div>{primaryMessage}</div>
      </MessageContainer>
    </Container>
  )
}

const Container = tw.div`
  flex flex-col mt-[25px] p-4 rounded-[10px] border border-gray-100 bg-gray-050
`

const MessageContainer = tw.div`
  flex flex-row text-gray-500 text-sm font-text  
`
