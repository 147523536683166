import React from "react"
import { Helmet } from "react-helmet-async"
import tw, { css, styled } from "twin.macro"

const Careers: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Careers – Goody</title>
      </Helmet>
      <Top>
        <Title>Careers</Title>
      </Top>
      <Container>
        <Card>
          <TextSection>
            Looking to work with a group of talented and driven individuals who
            are passionate about creating delight?
            <br />
            <br />
            Let us know where you would be a good fit and drop us a note at{" "}
            <a tw="text-primary-500" href="mailto:support@ongoody.com">
              support@ongoody.com
            </a>
            .
          </TextSection>
        </Card>
      </Container>
    </>
  )
}

const Card = tw.div`bg-white flex flex-col items-stretch mb-24 py-12 md:py-20 rounded-2xl shadow-xl px-6 md:px-3.5 mx-5 md:mx-0 md:w-3/4 lg:w-7/12 xl:w-1/2`

const TextSection = styled.div`
  ${tw`mx-12 md:mx-20 text-xl text-gray-800`}
`

const Container = styled.div(() => [
  tw`container flex flex-col items-center mx-auto overflow-hidden relative -mt-36 `,
])

const Title = styled.h2`
  ${tw`bg-clip-text font-medium text-5xl text-transparent mb-36`};
  background-image: linear-gradient(
    77.96deg,
    rgba(215, 112, 147, 1),
    rgba(179, 125, 213, 1)
  );
`

const Top = styled.div`
  ${tw`flex items-center justify-center w-full text-center flex-col`};
  background: linear-gradient(
      90deg,
      rgba(228, 161, 184, 0.15) 30.58%,
      rgba(179, 125, 213, 0.15) 100%
    ),
    #ffffff;

  height: 400px;

  @media (max-width: 1024px) {
    height: 40vh;
  }
`

const iframeWideCss = css`
  ${tw`mx-9`};

  @media (min-width: 640px) {
    margin-left: 4.25rem;
    margin-right: 4.25rem;
  }
`

const iframeSkinnyCss = css`
  ${tw`mx-4`};

  @media (min-width: 640px) {
    margin-left: 3rem;
    margin-right: 3rem;
  }
`

export default Careers
