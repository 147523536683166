import React, { Dispatch, SetStateAction, useEffect, useState } from "react"

import { slugToCategoryName } from "./category"
import { WebflowBlogPost, mountUrl, setImageUrlVariation } from "./utils"

interface IAllPosts {
  posts: WebflowBlogPost[]
  goToPost: (
    e: React.MouseEvent<HTMLAnchorElement>,
    post: WebflowBlogPost,
  ) => void
  defaultCategorySlug?: string
}

interface IPostParams {
  post: WebflowBlogPost
  goToPost: (
    e: React.MouseEvent<HTMLAnchorElement>,
    post: WebflowBlogPost,
  ) => void
}

interface ICategory {
  name: string
  slug: string
}

const perPage = 6

const AllPosts = ({ posts, goToPost, defaultCategorySlug }: IAllPosts) => {
  const [pages, setPages] = useState<WebflowBlogPost[][]>()
  const [categories, setCategories] = useState<ICategory[]>([])
  const [currentCategory, setCurrentCategory] = useState<ICategory | null>(
    defaultCategorySlug
      ? {
          name: slugToCategoryName(defaultCategorySlug),
          slug: defaultCategorySlug,
        }
      : null,
  )
  const [currentPageIndex, setCurrentPageIndex] = useState<number>(0)
  const [postsCount, setPostsCount] = useState<number | null>(null)

  // Set pages once the category changes
  useEffect(() => {
    if (posts.length === 0) return

    let eligiblePosts = posts

    if (currentCategory) {
      eligiblePosts = posts.filter(
        (p) => p.category?.slug === currentCategory.slug,
      )
    }

    setPostsCount(eligiblePosts.length)

    setCurrentPageIndex(0)

    setPages(splitPages(eligiblePosts))
  }, [posts.length, currentCategory?.slug])

  // Set categories when posts are loaded
  useEffect(() => {
    if (posts.length != 0) setCategories(extractCategories(posts))
  }, [posts.length])

  return (
    <>
      <div className="content-section blog-main-content" tw="min-h-[700px]">
        <div className="container">
          <CategoryFilter
            categories={categories}
            currentCategory={currentCategory}
            setCurrentCategory={setCurrentCategory}
          />
          <div className="w-dyn-list">
            <div role="list" className="blog-list mix-container w-dyn-items">
              {pages &&
                pages[currentPageIndex].map((post) => (
                  <Post
                    key={post.slug}
                    goToPost={(e) => goToPost(e, post)}
                    post={post}
                  />
                ))}
            </div>
          </div>
          {pages && (
            <Pagination
              currentPageIndex={currentPageIndex}
              setCurrentPageIndex={setCurrentPageIndex}
              postsCount={postsCount}
              pages={pages}
            />
          )}
        </div>
      </div>
    </>
  )
}

const Post = ({ post, goToPost }: IPostParams) => {
  return (
    <div role="listitem" className={`mix w-dyn-item`}>
      <a
        href={mountUrl(post)}
        onClick={(e) => goToPost(e, post)}
        tw="cursor-pointer"
        className="blog-post-link-block w-inline-block"
      >
        {post.thumbnailUrl && (
          <img
            src={setImageUrlVariation(post.thumbnailUrl, 500)}
            alt={post.title}
            className="blog-post-image"
          />
        )}
        <h4 className="blog-post-heading">{post.title}</h4>
        <p className="read-label">{post.readLabel}</p>
      </a>
    </div>
  )
}

interface ICategoryFilter {
  categories: ICategory[]
  currentCategory: ICategory | null
  setCurrentCategory: Dispatch<SetStateAction<ICategory | null>>
}
const CategoryFilter = ({
  categories,
  currentCategory,
  setCurrentCategory,
}: ICategoryFilter) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState<Boolean>(false)

  return (
    <>
      <div
        className="filter-buttons-bar"
        style={{ textTransform: "uppercase" }}
      >
        <a
          tw="cursor-pointer"
          onClick={() => setCurrentCategory(null)}
          className={`filter-button w-button ${
            !currentCategory && "mixitup-control-active"
          }`}
        >
          ALL
        </a>
        {categories.map((c) => (
          <a
            key={c.slug}
            onClick={() => setCurrentCategory(c)}
            tw="cursor-pointer"
            className={`filter-button w-button ${
              currentCategory?.slug === c.slug && "mixitup-control-active "
            }`}
          >
            {c.name}
          </a>
        ))}
      </div>

      <div className="filter-buttons-mobile">
        <div
          data-hover="false"
          data-delay={0}
          className="blog-filter-mobile w-dropdown"
          style={{ zIndex: 901 }}
        >
          <div
            className="blog-filter-mobile-button w-dropdown-toggle"
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          >
            <div className="blog-filter-text">CATEGORIES</div>
          </div>
          {isDropdownOpen && (
            <nav
              className="blog-filter-dropdown-list w-dropdown-list w--open"
              style={{ zIndex: -1, display: "block" }}
            >
              <a
                onClick={() => setCurrentCategory(null)}
                className="blog-filter-dropdown-link w-dropdown-link"
                tw="cursor-pointer"
              >
                ALL
              </a>
              {categories.map((c) => (
                <a
                  key={c.slug}
                  onClick={() => setCurrentCategory(c)}
                  tw="cursor-pointer"
                  className={`blog-filter-dropdown-link w-dropdown-link ${
                    currentCategory?.slug === c.slug &&
                    "mixitup-control-active "
                  }`}
                >
                  {c.name}
                </a>
              ))}
            </nav>
          )}
        </div>
      </div>
    </>
  )
}

interface IPagination {
  currentPageIndex: number
  setCurrentPageIndex: Dispatch<SetStateAction<number>>
  postsCount: number | null
  pages: WebflowBlogPost[][]
}
const Pagination = ({
  currentPageIndex,
  setCurrentPageIndex,
  pages,
  postsCount,
}: IPagination) => {
  const lastPageIndex = pages.length - 1

  // Calculating the pages to display in the middle of the pagination
  const pagesIndexToDisplay: number[] = calculatePagesToDisplay(
    currentPageIndex,
    lastPageIndex,
  )

  return (
    <div className="controls-pagination">
      <div className="mixitup-page-list">
        <button
          type="button"
          disabled={currentPageIndex === 0}
          onClick={() => setCurrentPageIndex(currentPageIndex - 1)}
          className="mixitup-control mixitup-control-prev"
          data-page="prev"
        >
          «
        </button>

        {
          <PageBtn
            pageIndex={0}
            currentPageIndex={currentPageIndex}
            setCurrentPageIndex={setCurrentPageIndex}
          />
        }

        {!pagesIndexToDisplay.includes(0) &&
          !pagesIndexToDisplay.includes(1) && (
            <span className="mixitup-control mixitup-control-truncation-marker">
              …
            </span>
          )}

        {pagesIndexToDisplay.map((pageIndex) => {
          if (pageIndex < 1 || pageIndex === lastPageIndex || !pages[pageIndex])
            return null
          return (
            <PageBtn
              key={pageIndex}
              pageIndex={pageIndex}
              currentPageIndex={currentPageIndex}
              setCurrentPageIndex={setCurrentPageIndex}
            />
          )
        })}

        {lastPageIndex > 0 &&
          !pagesIndexToDisplay.includes(lastPageIndex) &&
          !pagesIndexToDisplay.includes(lastPageIndex - 1) && (
            <span className="mixitup-control mixitup-control-truncation-marker">
              …
            </span>
          )}

        {lastPageIndex > 0 && (
          <PageBtn
            pageIndex={lastPageIndex}
            currentPageIndex={currentPageIndex}
            setCurrentPageIndex={setCurrentPageIndex}
          />
        )}

        <button
          type="button"
          disabled={currentPageIndex === lastPageIndex}
          onClick={() => setCurrentPageIndex(currentPageIndex + 1)}
          className="mixitup-control mixitup-control-next mixitup-control-disabled"
        >
          »
        </button>
      </div>
      {postsCount && (
        <div className="mixitup-page-stats">
          {currentPageIndex * perPage + 1} to{" "}
          {currentPageIndex * perPage + pages[currentPageIndex].length} of{" "}
          {postsCount}
        </div>
      )}
    </div>
  )
}

interface IPage {
  pageIndex: number
  currentPageIndex: number
  setCurrentPageIndex: Dispatch<SetStateAction<number>>
}

const PageBtn = ({
  pageIndex,
  currentPageIndex,
  setCurrentPageIndex,
}: IPage) => {
  return (
    <button
      type="button"
      onClick={() => setCurrentPageIndex(pageIndex)}
      tw="transition-all"
      className={`mixitup-control mixitup-control-first ${
        pageIndex === currentPageIndex && "mixitup-control-active"
      }`}
    >
      {pageIndex + 1}
    </button>
  )
}

// Helpers
function splitPages(posts: WebflowBlogPost[]) {
  let rawPosts = [...posts]
  const pages = []

  while (rawPosts.length > 0) {
    const page = rawPosts.splice(0, perPage)
    pages.push(page)
  }

  return pages
}

const canonicalSort = [
  "Gift Guides",
  "Founder Q&A",
  "Our Community",
  "What's Goody",
]

function extractCategories(posts: WebflowBlogPost[]) {
  let categories: ICategory[] = []

  posts.forEach((post) => {
    if (!post.category) return
    if (post.category.name === "Company News") return

    if (!categories.some((c: ICategory) => c.slug === post.category?.slug)) {
      categories.push(post.category)
    }
  })

  categories = categories.slice().sort((a: ICategory, b: ICategory) => {
    if (
      canonicalSort.indexOf(a.name) < 0 ||
      canonicalSort.indexOf(b.name) < 0
    ) {
      return 100
    }
    return canonicalSort.indexOf(a.name) - canonicalSort.indexOf(b.name)
  })

  return categories
}

const calculatePagesToDisplay = (
  currentPageIndex: number,
  lastPageIndex: number,
) => {
  if (currentPageIndex === 0) {
    return [1, 2, 3]
  } else if (currentPageIndex >= lastPageIndex - 1) {
    return [lastPageIndex - 3, lastPageIndex - 2, lastPageIndex - 1]
  } else {
    return [currentPageIndex - 1, currentPageIndex, currentPageIndex + 1]
  }
}

export default AllPosts
