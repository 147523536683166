import React from "react"
import Confetti from "react-confetti"

import { MobileAppComponent, StatusButton } from "./SendComplete"
import { ArrowRightPink } from "../assets/icons"
import ConsumerContainer from "../common/components/ConsumerContainer"
import { generateRealmPath } from "../common/realm"

// Post consumer scheduled send page
const SendScheduled = () => {
  const content = (
    <>
      <div css={"z-index: -50;"}>
        <Confetti recycle={false} numberOfPieces={300} gravity={0.05} />
      </div>
      <div tw={"px-4 flex flex-col items-center"}>
        <div tw={"mt-16 text-5xl text-center font-bold"}>Gift scheduled!</div>
        <div
          tw={"text-gray-500 text-xl mt-4"}
          className="data-hj-suppress ph-no-capture fs-exclude"
        >
          We'll send your gift on your scheduled date.
        </div>
        <StatusButton
          to={generateRealmPath("consumer", "/my-gifts")}
          tw={
            "border flex flex-row items-center rounded-full justify-between pl-7 pr-4"
          }
        >
          My gifts
          <ArrowRightPink />
        </StatusButton>
        <div
          css={"max-width: 470px; margin-top: 4.5rem;"}
          tw={"w-full flex flex-col items-stretch lg:mb-24"}
        >
          <MobileAppComponent />
        </div>
      </div>
    </>
  )

  return (
    <ConsumerContainer
      title={"Gift Scheduled"}
      tw={"flex flex-col items-center"}
    >
      {content}
    </ConsumerContainer>
  )
}

export default SendScheduled
