import React from "react"
import { Link } from "react-router-dom"

import { ReactComponent as Logo } from "../../../assets/images/logo.svg"
import { useGlobalState } from "../../../common/GlobalState"
import { generateRealmPath } from "../../../common/realm"

const HeaderLogo = () => <LogoLink />
const LogoLink = () => {
  const [currentRealm] = useGlobalState("currentRealm")

  return (
    <Link
      to={generateRealmPath(currentRealm, "/")}
      tw="mr-4 pointer-events-auto"
    >
      <Logo />
    </Link>
  )
}

export default HeaderLogo
