import { formatPrice } from "../../common/format"

import { BalanceGiftBatchStatus } from "@/types/graphql-types"
import { BalanceGiftBatch } from "@/types/graphql-types"

interface Props {
  balanceGiftBatch: BalanceGiftBatch
}

export default function ActivityGiftBatchPending({ balanceGiftBatch }: Props) {
  if (
    balanceGiftBatch.status === BalanceGiftBatchStatus.SCHEDULED_PENDING &&
    balanceGiftBatch.amountReserved
  ) {
    return (
      <div>
        <div tw="text-xl text-right tabular-nums text-yellow-600">
          {formatPrice(balanceGiftBatch.amountReserved, true)}
        </div>
        <div tw="text-right text-gray-450 text-yellow-600">
          {balanceGiftBatch.scheduledRecipients} pending
        </div>
      </div>
    )
  }

  if (balanceGiftBatch.pendingGiftsCount > 0) {
    return (
      <div>
        <div tw="text-xl text-right tabular-nums text-yellow-600">
          {formatPrice(balanceGiftBatch.pendingAmount, true)}
        </div>
        <div tw="text-right text-gray-450 text-yellow-600">
          {balanceGiftBatch.pendingGiftsCount} pending
        </div>
      </div>
    )
  }

  return (
    <div tw="self-center">
      <div tw="text-right text-xl text-gray-300">&mdash;</div>
    </div>
  )
}
