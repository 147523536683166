import React, { ReactElement } from "react"
import tw, { TwStyle, styled } from "twin.macro"

import { ReactComponent as Birthdays } from "./images/cake.svg"
import { ReactComponent as WorkAnniversaries } from "./images/cakeNumberOne.svg"
import { ReactComponent as CompanyEvents } from "./images/companyEvent.svg"
import { ReactComponent as Holidays } from "./images/holiday.svg"
import { ReactComponent as Onboarding } from "./images/onboarding.svg"
import { ReactComponent as BrandedSwag } from "./images/shirt.svg"
import { ReactComponent as TopPerformers } from "./images/star.svg"
import { ReactComponent as RemoteBonding } from "./images/vacation.svg"
import { FlexCenterItems } from "../components/PositionHelper"
import TitleContainer from "../components/TitleContainer"

const GiftsOccasions: React.FC = () => {
  const occasions = [
    {
      color: tw`bg-[#0FCDE5]`,
      icon: <BrandedSwag />,
      text: "Branded swag",
    },
    {
      color: tw`bg-[#C47EF9]`,
      icon: <Holidays />,
      text: "Holidays",
    },
    {
      color: tw`bg-[#FF5AA1]`,
      icon: <CompanyEvents />,
      text: "Company events",
    },
    {
      color: tw`bg-[#887EF9]`,
      icon: <RemoteBonding />,
      text: "Remote bonding",
    },
    {
      color: tw`bg-[#C47EF9]`,
      icon: <TopPerformers />,
      text: "Top performers",
    },
    {
      color: tw`bg-[#FF5AA1]`,
      icon: <Onboarding />,
      text: "Onboarding",
    },
    {
      color: tw`bg-[#FE6C44]`,
      icon: <Birthdays />,
      text: "Birthdays",
    },
    {
      color: tw`bg-[#FFC857]`,
      icon: <WorkAnniversaries />,
      text: "Work anniversaries",
    },
  ]
  return (
    <div tw="mx-auto max-w-3xl">
      <div tw="mx-5 max-w-2xl lg:mx-auto">
        <TitleContainer
          heading="Gifts for all occasions"
          title="Employee gifts for any occasion (or just because)."
        />
      </div>
      <OccasionsGrid>
        {occasions.map((occasion) => (
          <OccasionItem key={occasion.text} {...occasion} />
        ))}
      </OccasionsGrid>
    </div>
  )
}

const OccasionsGrid = tw.div`
  grid grid-cols-2 md:grid-cols-4 justify-items-center
  mx-7 px-0.5 mt-11 gap-x-7 gap-y-5
`

interface OccasionItemProps {
  color: TwStyle
  icon: ReactElement
  text: string
}
const OccasionItem: React.FC<OccasionItemProps> = ({ color, icon, text }) => (
  <FlexCenterItems css={[color, tw`rounded-[20px]`]}>
    <IconPadding>
      <IconWrapper>{icon}</IconWrapper>
    </IconPadding>
    <IconTitle tw="mb-12 pb-1.5 mt-6 pt-0.5">{text}</IconTitle>
  </FlexCenterItems>
)

const IconTitle = tw.p`text-center text-base font-bold text-white`
const IconWrapper = tw.div`
  flex justify-center items-center
  bg-white rounded-full h-20 w-20
`
const IconPadding = styled.div`
  ${tw`mx-7 mt-5`}

  @media (min-width: 400px) {
    ${tw`mx-10 mt-9 sm:px-0.5`}
  }
`

export default GiftsOccasions
