import { CheckCircleBlue } from "../../../assets/icons"
import PurpleGradientLink from "../../../common/PurpleGradientLink"
import RainbowHeading from "../../../common/RainbowHeading"
import { generateRealmPath } from "../../../common/realm"
import staticAssetUrl from "../../../common/staticAssetUrl"

const GiftExampleImage = staticAssetUrl(
  "landing/how_it_works/branded_swag/giftExample.png",
)

const OnDemandSwag = () => (
  <div tw="bg-[#F8F8F8] px-5 py-16 lg:py-20">
    <div tw="max-w-[1200px] mx-auto flex flex-col lg:flex-row">
      <div tw="flex-1 mx-auto w-max max-w-full lg:pt-36">
        <RainbowHeading tw="font-bold mb-4 text-2xl">
          On-Demand Swag
        </RainbowHeading>
        <h2 tw="text-left font-bold mb-6 text-3xl md:text-[2.25rem] xl:text-[2.75rem] lg:leading-[3.5rem]">
          Send high-quality swag.
          <br />
          Pay only when they accept it.
        </h2>
        <div tw="flex items-center mb-2 text-gray-600 text-lg">
          <CheckCircleBlue tw="mr-4" />
          <span>Design and order swag on-demand, with no minimums.</span>
        </div>
        <div tw="flex items-center mb-6 text-gray-600 text-lg">
          <CheckCircleBlue tw="mr-4" />
          <span>Recipients provide their own sizes and shipping info</span>
        </div>
        <div tw="text-sm text-gray-600 text-sm mb-10">
          No software subscription required.
        </div>
        <PurpleGradientLink
          tw="lg:text-lg px-10 w-full sm:w-[14rem] lg:w-auto"
          to={generateRealmPath("business", "/business/signup")}
        >
          Get Started
        </PurpleGradientLink>
      </div>
      <div tw="flex-1">
        <img
          src={GiftExampleImage}
          tw="flex-1 max-w-[36rem] w-full mx-auto pt-20 lg:pt-0 lg:ml-16"
        />
      </div>
    </div>
  </div>
)

export default OnDemandSwag
