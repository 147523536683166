import { gql, useMutation } from "@apollo/client"
import { useEffect, useState } from "react"

import AppPortalContainerLoader from "./components/AppPortalContainerLoader"

import { Organization_API_FetchWebhookApplicationDashboardURLMutation } from "@/types/graphql-types"

// Loads the Svix webhook portal in an iframe. The URL is fetched from the
// backend.
export default function OrganizationWebhooks() {
  const [appPortalURL, setAppPortalURL] = useState<string | null>(null)
  const [fetchDashboardURL] =
    useMutation<Organization_API_FetchWebhookApplicationDashboardURLMutation>(
      FETCH_DASHBOARD_URL_MUTATION,
    )

  useEffect(() => {
    ;(async () => {
      const res = await fetchDashboardURL()
      if (
        res?.data?.webhookApplicationFetchDashboardUrl?.ok &&
        res?.data?.webhookApplicationFetchDashboardUrl?.url
      ) {
        setAppPortalURL(res.data.webhookApplicationFetchDashboardUrl.url)
      } else {
        alert(
          res?.data?.webhookApplicationFetchDashboardUrl?.error ||
            "Failed to load webhooks",
        )
      }
    })()
  }, [])

  return (
    <div tw="relative">
      <AppPortalContainerLoader url={appPortalURL} />
    </div>
  )
}

const FETCH_DASHBOARD_URL_MUTATION = gql`
  mutation Organization_API_FetchWebhookApplicationDashboardURL {
    webhookApplicationFetchDashboardUrl {
      ok
      url
      error
    }
  }
`
