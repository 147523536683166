import { formatPrice } from "../common/format"
import { useGlobalState } from "../common/GlobalState"

import { BudgetRecurrence } from "@/types/graphql-types"

export default function BalanceBudgetRule() {
  const [user] = useGlobalState("user")

  const budget = user?.budgetRule

  if (!budget || budget?.amount === 0) {
    return null
  }

  return (
    <div tw="pt-5 flex flex-row justify-center">
      <div tw="border border-gray-200 rounded-full px-5 py-1.5 flex flex-row items-center gap-3">
        <div tw="text-gray-450">Budget</div>
        <div tw="text-lg font-medium text-gray-600">
          {formatPrice(budget.amount)} per{" "}
          {budget.recurrence === BudgetRecurrence.MONTHLY ? "month" : "quarter"}
        </div>
      </div>
    </div>
  )
}
