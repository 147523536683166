import tw, { styled } from "twin.macro"

import { useCustomStore } from "@/store/custom/hooks"
import { MultipleSelectModeEnum } from "@/types/graphql-types"

interface Props {
  multipleSelectMode: MultipleSelectModeEnum
}

export const MultipleSelectTooltip = ({ multipleSelectMode }: Props) => {
  const { displayMultipleSelectTooltip } = useCustomStore()

  const multipleSelectModeDisplayName =
    multipleSelectMode === MultipleSelectModeEnum.item_count
      ? "number of gifts"
      : multipleSelectMode === MultipleSelectModeEnum.max_price
      ? "a maximum price"
      : ""

  const multipleSelectModeDescription =
    multipleSelectMode === MultipleSelectModeEnum.item_count
      ? "You can alternatively set a specific number of gifts that recipients can select."
      : multipleSelectMode === MultipleSelectModeEnum.max_price
      ? "You can alternatively set a maximum price and allow recipients to add any number of gifts adding up to that price."
      : ""

  const priceText =
    multipleSelectMode === MultipleSelectModeEnum.item_count
      ? "Prices are hidden."
      : multipleSelectMode === MultipleSelectModeEnum.max_price
      ? "Prices are shown."
      : ""

  return (
    <Container
      multipleSelectMode={multipleSelectMode}
      displayPopup={displayMultipleSelectTooltip}
    >
      <div tw="text-primary-new-600 font-medium mb-4">
        Set {multipleSelectModeDisplayName}
      </div>
      <div tw="leading-[22.4px]">
        {multipleSelectModeDescription}{" "}
        <span tw="font-semibold">{priceText}</span>
      </div>
    </Container>
  )
}

const Container = styled.div<{
  multipleSelectMode: MultipleSelectModeEnum
  displayPopup: boolean
}>`
  ${tw`absolute z-50 w-[384px] mt-[-60px] p-6 rounded-2xl bg-white border border-gray-150 transition-opacity`}
  ${({ multipleSelectMode }) =>
    multipleSelectMode === MultipleSelectModeEnum.max_price
      ? tw`ml-[344px]`
      : tw`ml-[332px]`}

  ${({ displayPopup }) => (displayPopup ? tw`opacity-100` : tw`opacity-0`)};
  ${({ displayPopup }) =>
    displayPopup ? tw`pointer-events-auto` : tw`pointer-events-none`};

  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.05);
`
