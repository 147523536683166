import React from "react"

import { useGiftData } from "./hooks/giftData"

const RemoveCreditButton = () => {
  const { setOmitCredit } = useGiftData()

  return (
    <span
      tw="text-gray-450 leading-5 font-normal text-base cursor-pointer whitespace-nowrap"
      onClick={() => setOmitCredit(true)}
    >
      · Remove
    </span>
  )
}

export default RemoveCreditButton
