import React from "react"
import { Helmet } from "react-helmet-async"

import HowGoodyIsDifferent from "./HowGoodyIsDifferent"
import BrandsCollection from "./images/BrandsCollection.png"
import HeroDesktop from "./images/HeroDesktop.png"
import HeroMobile from "./images/HeroMobile.png"
import WhyClientGifting from "./WhyClientGifting"
import YourCSTeamsSecretWeapon from "./YourCSTeamsSecretWeapon"
import { useGlobalState } from "../../../common/GlobalState"
import { generateRealmPath } from "../../../common/realm"
import Carousel from "../../business/Carousel"
import Brands from "../components/Brands"
import CallToAction from "../components/CallToAction"
import Divider from "../components/Divider"
import Hero from "../components/Hero"
import OurCollection from "../components/OurCollection"
import IntercomLogo from "../images/intercom_logo.png"

const ClientAppreciation: React.FC = () => {
  const [signedIn] = useGlobalState("signedIn")
  const goTo = (path: string) => generateRealmPath("business", path)

  return (
    <>
      <Helmet>
        <title>Client Appreciation — Goody for Business</title>
        <meta
          name="description"
          content="Customer gifts that drive relationships and retention. Send curated gifts that will make your clients feel valued and unique. No shipping address needed."
        />
      </Helmet>

      <Hero
        heading="Client Appreciation"
        title="Customer gifts that drive relationships and retention"
        subtitle={
          <div tw="text-dark-blue lg:text-[22px] lg:leading-[32px]">
            Send curated gifts that will make your clients feel valued and
            unique.
            <span tw="font-semibold"> No shipping address needed.</span>
          </div>
        }
        variant="how-it-works"
        primaryAction={{
          text: "Sign up - it's free!",
          linkTo: goTo("/signup"),
        }}
        secondaryAction={{
          text: "Get a Demo",
          linkTo: goTo("/team-demo"),
        }}
        image={{
          desktop: HeroDesktop,
          mobile: HeroMobile,
        }}
      />

      <div tw="mt-28">
        <Brands
          title="Thousands of companies use Goody for client appreciation"
          variant="secondary"
        />
      </div>

      <Divider tw="mt-16 md:mt-24 md:pt-0.5 mb-12 md:mb-20" />

      <div tw="mb-16 pb-2 md:mb-14 md:pb-0">
        <WhyClientGifting />
      </div>

      <HowGoodyIsDifferent linkTo={goTo("/how-it-works")} />

      <YourCSTeamsSecretWeapon />

      <div tw="mt-10 md:mt-0 md:mx-12 md:mb-20">
        <OurCollection
          title="Curated, on-trend gifts your clients will really love."
          description="Plus, find and support brands whose values your customers share, like BIPOC, LGBTQ+ or female-founded, social-impact-driven, or sustainable."
          brandsImage={BrandsCollection}
          primaryAction={{ linkTo: goTo("/browse") }}
        />
      </div>

      <Carousel
        testimonials={[
          {
            textHtml: (
              <>
                “Goody helped us build stronger champions within unengaged
                accounts, which is huge for us. With everyone inundated with
                emails, <b>Goody makes such a difference”</b>
              </>
            ),
            name: "Dale Bachman",
            title: "Enterprise Relationship Manager, Intercom",
            image: IntercomLogo,
          },
        ]}
      />

      <CallToAction signedIn={signedIn} />
      <Divider tw="mt-20" />
    </>
  )
}

export default ClientAppreciation
