import { track } from "@/common/analytics"
import CalendarIcon from "@/organization/settings/assets/icons/calendar.svg"
import Upsell from "@/organization/Upsell"
import UpsellSection from "@/organization/UpsellSection"

const BillingEmailUpsell = () => {
  return (
    <Upsell>
      <UpsellSection
        title="Add a billing contact email for all receipts across your organization’s users with the Team plan"
        subtext="Upgrade to the Team plan to add a billing contact for all receipts, plus more features like Salesforce integration, budgeting, and more."
        icon={CalendarIcon}
        cta="Book a call"
        onClick={() => {
          track("Organization - Billing Email Upsell - Click Book a Call")
          window.open(
            "https://www.ongoody.com/business/team-demo?campaign=goody-plus-website-billing-contact",
          )
        }}
      />
    </Upsell>
  )
}

export default BillingEmailUpsell
