import { gql } from "@apollo/client"

const organizationBundledReceipts = gql`
  query Organization_BundledReceipts(
    $page: Int
    $perPage: Int
    $fromDate: ISO8601DateTime
    $toDate: ISO8601DateTime
    $userIds: [ID!]
  ) {
    organization {
      bundledReceipts(
        page: $page
        perPage: $perPage
        fromDate: $fromDate
        toDate: $toDate
        userIds: $userIds
      ) {
        items {
          id
          amountNetCharges
          paymentMethodName
          closeDate
          status
          accessToken
          user {
            name
            email
          }
          isBatchBillingGroupFailed
        }
        totalCount
      }
    }
  }
`

export default organizationBundledReceipts
