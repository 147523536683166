import React, { useEffect, useState } from "react"

import WorkspaceInvitePromoButton from "./components/WorkspaceInvitePromoButton"
import WorkspaceMemberRow from "./components/WorkspaceMemberRow"
import WorkspaceInviteMemberForm from "./WorkspaceInviteMemberForm"
import { ReactComponent as UserPlusIcon } from "../assets/icons/user-plus.svg"
import Button from "../common/Button"
import { useQueryParams } from "../common/gtm"
import { Table } from "../common/members/Table"

import { BaseMeFragment, WorkspaceFragment } from "@/types/graphql-types"

interface WorkspaceMembersProps {
  currentUser: BaseMeFragment
  isManager: boolean
  isFullOrganizationMember: boolean
  workspace: WorkspaceFragment
  isTeamPlan?: boolean
}

const WorkspaceMembers = ({
  isManager,
  isFullOrganizationMember,
  workspace,
  currentUser,
  isTeamPlan = false,
}: WorkspaceMembersProps) => {
  const [showInviteForm, setShowInviteForm] = useState(false)
  const { members } = workspace

  const inviteMembers = () => {
    if (showInviteForm) {
      document
        .querySelector("#invite-member-form")
        ?.scrollIntoView({ behavior: "smooth", block: "center" })
    } else {
      setShowInviteForm(true)
    }
  }

  const hasInvite = useQueryParams().has("invite")

  useEffect(() => {
    if (hasInvite) {
      inviteMembers()
    }
  }, [])

  const tableHeaders = isManager
    ? ["Name", "Email", "Gifts Sent", "Role", ""]
    : ["Name", "Email"]

  return (
    <div>
      <WorkspaceInviteMemberForm
        workspace={workspace}
        isFullOrganizationMember={isFullOrganizationMember}
        onClose={() => setShowInviteForm(false)}
        visible={showInviteForm}
      />

      <div tw="py-9 px-6 lg:px-12 pb-4 flex flex-col md:flex-row gap-4">
        <div>
          <Button onClick={inviteMembers} variant="primary">
            <UserPlusIcon /> Invite members
          </Button>
        </div>
        {!currentUser?.hasPaidPlan && (
          <div>
            <WorkspaceInvitePromoButton onClick={inviteMembers} />
          </div>
        )}
      </div>

      <div tw="pb-9 px-6 lg:px-12">
        <Table gridTemplateColumns="3fr 3fr 2fr 2fr 2fr" headers={tableHeaders}>
          <>
            {members.map((member) => (
              <WorkspaceMemberRow
                {...member}
                isTeamPlan={isTeamPlan}
                currentUser={currentUser}
                workspace={workspace}
                isManager={isManager}
              />
            ))}
          </>
        </Table>
      </div>
    </div>
  )
}

export default WorkspaceMembers
