import tw from "twin.macro"

import ArrowRightLeftIcon from "./assets/icons/arrow-right-left.svg"

import { useCustomStore } from "@/store/custom/hooks"

interface Props {
  onClick: () => void
  label: string
}
export const MultipleSelectButton = ({ onClick, label }: Props) => {
  const { setDisplayMultipleSelectTooltip } = useCustomStore()

  return (
    <Container
      onClick={() => {
        onClick()
        setDisplayMultipleSelectTooltip(false)
      }}
      onMouseEnter={() => setDisplayMultipleSelectTooltip(true)}
      onMouseLeave={() => setDisplayMultipleSelectTooltip(false)}
    >
      <div tw="mr-2">
        <img src={ArrowRightLeftIcon} alt="Arrow right and left icon" />
      </div>
      <div tw="text-primary-new-550">{label}</div>
    </Container>
  )
}

const Container = tw.button`
  flex flex-row items-center rounded-full bg-primary-new-000 px-4 py-2
  hover:bg-primary-new-050 active:bg-primary-new-100 active:scale-95 transition-all
`
