import { gql } from "@apollo/client"

export const BUDGET_RULE_UPDATE = gql`
  mutation Budgeting_BudgetRuleUpdate(
    $organizationMembershipID: ID!
    $amount: Int!
    $recurrence: BudgetRecurrence!
  ) {
    budgetRuleUpdate(
      organizationMembershipId: $organizationMembershipID
      amount: $amount
      recurrence: $recurrence
    ) {
      ok
      error
    }
  }
`
