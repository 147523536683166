import { ComponentVariant } from "./giftLinkTypes"

interface Props {
  variant: ComponentVariant
  landingPageSendNotifs?: boolean | null
  recipientEmailsAvailable?: boolean | null
}

export default function GiftLinkHeader({
  variant,
  landingPageSendNotifs,
  recipientEmailsAvailable,
}: Props) {
  if (variant !== "postSend") {
    return null
  }

  return (
    <div tw="pt-2 pb-6 text-xl">
      {landingPageSendNotifs && recipientEmailsAvailable ? (
        <>
          <span tw="font-medium">For the highest open rate,</span> we also
          recommend sending this gift link in your own email.
        </>
      ) : (
        <span tw="font-medium">
          Copy this gift link into your own email or message:
        </span>
      )}
    </div>
  )
}
